import { trackPromise } from 'react-promise-tracker'
import { parseResponse } from '../../lib'
import {
  USER_CURRENT_MCE_ATTEMPTS, USER_MCE_ATTEMPT_DETAILS, DATA_REQUEST, DATA_SUCCESS, DATA_ERROR,
  RESET_CURRENT_ATTEMPT_DETAILS
} from '../types'

import log from 'loglevel'

const api = process.env.REACT_APP_API_URL

export const getUserCurrentMCEAttempts = () => {
  return async (dispatch, getState) => {
    const { userID, token } = getState().auth
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }

    const response = await trackPromise(window.fetch(`${api}/v1/user/${userID}/mce`, options))
    const parsedResponse = parseResponse(response, dispatch)
    if (!parsedResponse) { return false }

    if (parsedResponse.error) {
      log.info(response.status)
    } else {
      const { data } = await response.json()

      dispatch({ type: USER_CURRENT_MCE_ATTEMPTS, payload: data })
    }
  }
}

export const getUserMCEAttemptDetails = (attemptDetails, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { userID, token } = getState().auth

      const { attemptID, mceID, view = '', section = '' } = attemptDetails

      dispatch({ type: DATA_REQUEST, payload: { type: USER_MCE_ATTEMPT_DETAILS } })

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${api}/v1/user/${userID}/mce/${mceID}/attempt/${attemptID}`, options))
      const parsedResponse = parseResponse(response)

      if (parsedResponse && !parsedResponse.error) {
        const { data } = await response.json()
        dispatch({ type: DATA_SUCCESS, payload: { type: USER_MCE_ATTEMPT_DETAILS } })

        dispatch({
          type: USER_MCE_ATTEMPT_DETAILS,
          payload: data
        })

        fireSuccess(data, view, section)
      } else {
        dispatch({ type: DATA_ERROR, payload: { type: USER_MCE_ATTEMPT_DETAILS } })
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const postMCEAttempt = (attemptInfo, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '', userID } = getState().auth
      const { mceID, attemptCount } = attemptInfo

      const body = { attemptCount }

      if (!token) {
        fireFailure()
        return false
      }

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
      }

      const response = await trackPromise(window.fetch(`${api}/v1/user/${userID}/mce/${mceID}/attempt`, options))
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) {
        fireFailure()
      } else {
        const { data } = await response.json()
        const { attemptID = '', mceID = '' } = data
        fireSuccess(attemptID, mceID)
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const updateMCEAttempt = (attemptInfo, attemptID, mceID, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { userID, token } = getState().auth
      const { attemptStatus, answersToAdd, answersToUpdate, samplesToAdd, samplesToRemove } = attemptInfo
      // set default values that will allow for only partial 'attemptInfo' obj to be sent in to dispatch action
      const formatBody = {
        attemptStatus: attemptStatus || 'D',
        answersToAdd: answersToAdd || [],
        answersToUpdate: answersToUpdate || [],
        samplesToAdd: samplesToAdd || [],
        samplesToRemove: samplesToRemove || []
      }

      const body = JSON.stringify({ ...formatBody })

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      // artifactID is the ID for the specific Learning Experience Design to update
      const response = await trackPromise(window.fetch(`${api}/v1/user/${userID}/mce/${mceID}/attempt/${attemptID}`, options))
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) {
        fireFailure()
      } else {
        fireSuccess(attemptInfo)
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const resetCurrentAttemptDetails = () => {
  return async function (dispatch) {
    dispatch({ type: RESET_CURRENT_ATTEMPT_DETAILS })
  }
}
