import React from 'react'

import {
  Dialog, DialogActions, DialogContent, DialogTitle,
  Grid, Typography, IconButton, useTheme, Switch, withStyles, Button
} from '@material-ui/core'

import { CloseRounded } from '@material-ui/icons'

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 35,
    height: 20,
    padding: '.1em',
    margin: theme.spacing(1)
  },
  switchBaseLED: {
    padding: 3,
    color: theme.palette.purple.darkest,
    borderColor: theme.palette.grey.medium,
    '& + $track': {
      backgroundColor: theme.palette.grey.medium,
      opacity: 100
    },
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.purple.darkest,
      '& + $track': {
        backgroundColor: theme.palette.purple.light,
        borderColor: theme.palette.purple.medium,
        opacity: 100
      }
    },
    '&$disabled': {
      color: theme.palette.grey.medium,
      '& + $track': {
        backgroundColor: theme.palette.grey.light,
        borderColor: theme.palette.grey.light,
        opacity: '60% !important'
      }
    },
    '&$focusVisible $thumb': {
      color: '#1E274D'
    }
  },
  switchBaseLW: {
    padding: 3,
    color: '#025676',
    borderColor: theme.palette.grey.medium,
    '& + $track': {
      backgroundColor: theme.palette.grey.medium,
      opacity: 100
    },
    '&$checked': {
      transform: 'translateX(12px)',
      color: '#025676',
      '& + $track': {
        backgroundColor: theme.palette.purple.light,
        borderColor: theme.palette.purple.medium,
        opacity: 100
      }
    },
    '&$disabled': {
      color: theme.palette.grey.medium,
      '& + $track': {
        backgroundColor: theme.palette.grey.light,
        borderColor: theme.palette.grey.light,
        opacity: '60% !important'
      }
    },
    '&$focusVisible $thumb': {
      color: '#025676'
    }
  },
  switchBaseProj: {
    padding: 3,
    color: '#3d2b79',
    borderColor: theme.palette.grey.medium,
    '& + $track': {
      backgroundColor: theme.palette.grey.medium,
      opacity: 100
    },
    '&$checked': {
      transform: 'translateX(12px)',
      color: '#3d2b79',
      '& + $track': {
        backgroundColor: theme.palette.purple.light,
        borderColor: theme.palette.purple.medium,
        opacity: 100
      }
    },
    '&$disabled': {
      color: theme.palette.grey.medium,
      '& + $track': {
        backgroundColor: theme.palette.grey.light,
        borderColor: theme.palette.grey.light,
        opacity: '60% !important'
      }
    },
    '&$focusVisible $thumb': {
      color: '#3d2b79'
    }
  },
  thumbSize: {
    width: 15,
    height: 15,
    boxShadow: 'none'
  },
  track: {
    borderRadius: 13,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {},
  disabled: {},
  thumb: {},
  focusVisible: {}
}))(({ classes, ...props }) => {
  const { artifacttype } = props
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: artifacttype === 'Project' ? classes.switchBaseProj : artifacttype === 'Learner Work' ? classes.switchBaseLW : classes.switchBaseLED,
        thumb: classes.thumbSize,
        track: classes.track,
        checked: classes.checked,
        disabled: classes.disabled
      }}
      {...props}
    />
  )
})

const PublishArtifactModal = (props) => {
  const {
    publishModalOpen = false, setPublishModalOpen = () => { }, classes, artifactType = '',
    isPrivate = false, setIsPrivate = () => { }, efSubmit = false, setEfSubmit = () => { }, handleSaveArtifact = () => { }, styles,
    artifactIsDuplicate = false
  } = props
  const theme = useTheme()

  const handleModalCancel = () => {
    setPublishModalOpen(false)
  }

  const handleSave = (e) => {
    e.preventDefault()
    setPublishModalOpen(false)
    handleSaveArtifact(true)
  }
  return (
    <Dialog
      fullWidth
      maxWidth='xs'
      open={publishModalOpen}
      TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
      onClose={() => handleModalCancel()}
    >
      <DialogTitle disableTypography>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography
              variant='h4'
              style={{ textTransform: 'none', color: styles.mainColor, fontSize: '1.3rem' }}
            >
              Publish Artifact
            </Typography>
          </Grid>

          <Grid item>
            <IconButton style={{ padding: '0px' }} onClick={() => setPublishModalOpen(false)}>
              <CloseRounded className={classes.customizedButton} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid container direction='column' style={{ marginBottom: '2em' }}>
          <Grid item container direction='row'>
            <Grid item xs={10} container direction='column'>
              <Grid item>
                <Typography variant='h4' style={{ fontWeight: '400', fontSize: 18, color: artifactIsDuplicate ? theme.palette.grey.medium : 'black' }}>Submit Artifact to Ed Farm?</Typography>
              </Grid>
              <Grid item>
                <Typography variant='body1' style={{ color: artifactIsDuplicate ? theme.palette.grey.medium : theme.palette.grey.dark }}>{artifactIsDuplicate ? 'Duplicate artifacts cannot be submitted to Ed Farm.' : 'Artifact will be permanently set to public.'}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={2} container direction='column' alignItems='flex-end'>
              <IOSSwitch
                id='submit-artifact-toggle'
                artifacttype={artifactType}
                checked={efSubmit}
                disabled={artifactIsDuplicate}
                onClick={() => setEfSubmit(prev => !prev)}
              />
            </Grid>
          </Grid>

          <Grid item container direction='row' style={{ marginTop: '1.5em' }}>
            <Grid item xs={10} container direction='column'>
              <Grid item>
                <Typography variant='h4' style={{ fontWeight: '400', fontSize: 18 }}>Make Artifact Public?</Typography>
              </Grid>
              <Grid item>
                <Typography variant='body1' style={{ color: theme.palette.grey.dark }}>Allow other educators to view your artifact.</Typography>
              </Grid>
            </Grid>
            <Grid item xs={2} container direction='column' alignItems='flex-end'>
              <IOSSwitch
                id='artifact-public-toggle'
                artifacttype={artifactType}
                checked={!isPrivate || efSubmit}
                disabled={efSubmit}
                onClick={() => setIsPrivate(prev => !prev)}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant='contained' style={{ backgroundColor: styles.mainColor }} onClick={(e) => handleSave(e)}>Publish</Button>
      </DialogActions>
    </Dialog>
  )
}

export default PublishArtifactModal
