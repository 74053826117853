import React, { } from 'react'
import { SvgIcon } from '@material-ui/core'

const DenyIcon = (props) => {
  return (
    <SvgIcon viewBox='0 0 16.393 16.393' {...props}>
      <path
        id='deny-icon'
        d='M16.2,8a8.2,8.2,0,1,0,8.2,8.2A8.195,8.195,0,0,0,16.2,8Zm4.019,10.348a.4.4,0,0,1,0,.562l-1.309,1.305a.4.4,0,0,1-.562,0L16.2,18.047l-2.152,2.168a.4.4,0,0,1-.562,0l-1.305-1.309a.4.4,0,0,1,0-.562L14.346,16.2l-2.168-2.152a.4.4,0,0,1,0-.562l1.309-1.309a.4.4,0,0,1,.562,0L16.2,14.346l2.152-2.168a.4.4,0,0,1,.562,0l1.309,1.309a.4.4,0,0,1,0,.562L18.047,16.2Z'
        transform='translate(-8 -8)'
      />
    </SvgIcon>
  )
}

export default DenyIcon
