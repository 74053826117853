import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateUserData } from '../../../redux/actions'

import {
  Grid, Dialog, DialogActions, DialogContent, IconButton, Typography,
  MobileStepper, Button, Paper
} from '@material-ui/core'

import {
  CloseRounded
} from '@material-ui/icons'

import { makeStyles, useTheme } from '@material-ui/core/styles'

import stepOneImage from '../../../assets/onboarding2-step1.png'
import stepTwoImage from '../../../assets/onboarding2-step2.png'
import stepThreex1Image from '../../../assets/chalkboard-solid.svg'
import stepThreex2Image from '../../../assets/pencil-ruler-solid.svg'
import step4Image from '../../../assets/artifactWithWords.png'

const useStyles = makeStyles((theme) => ({
  paper: {
    overflowY: 'unset'
  },
  customizedButton: {
    position: 'absolute',
    left: '135%',
    top: '-185%',
    backgroundColor: 'none',
    color: 'white',
    stroke: 'white',
    strokeWidth: '2px',
    height: '2rem',
    width: '2rem'
  },
  purpleBtnRoot: {
    backgroundColor: '#293669',
    color: 'white',
    textTransform: 'none',
    borderRadius: '8px'
  },
  purpleBtnLabel: {
    margin: '.3em 1.8em',
    fontSize: 'small',
    fontWeight: '600'
  },
  purpleBtnLabel2: {
    margin: '.3em .4em',
    fontSize: 'small',
    fontWeight: '600'
  },
  dotStyle: {
    height: '18px',
    width: '18px',
    marginLeft: 0
  }
}))

const Onboard2Modal = (props) => {
  const { onboarding2Open, setOnboarding2Open } = props
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()

  const [activeStep, setActiveStep] = useState(0)

  const handleOnboarding2ModalClose = () => {
    setOnboarding2Open(false)
    dispatch(updateUserData('onboarding-latest', { onboardingStep: 2 }))
  }

  const handleNext = () => {
    if (activeStep < 3) { setActiveStep((prevActiveStep) => prevActiveStep + 1) }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const stepperContent = (step) => {
    switch (step) {
      // **** Step One **** //
      case 0:
        return (
          <>
            <Grid item container direction='row' justifyContent='center' style={{ marginTop: '3em' }}>
              <img src={stepOneImage} alt='step-one' style={{ width: '28rem' }} />
            </Grid>

            <Grid item container direction='row' justifyContent='center' style={{ marginTop: '4.5rem' }}>
              <Typography variant='h3'>Welcome to Ed Farm Learn!</Typography>
            </Grid>

            <Grid item container justifyContent='center' style={{ marginTop: '1rem', marginBottom: '4em' }}>
              <Typography variant='h5' style={{ fontWeight: '400', textAlign: 'center', lineHeight: '1.9' }}>Thank you for creating an account! Now you can create artifacts, find other educators, and share your best work with others.</Typography>
            </Grid>
          </>
        )
      case 1:
        // **** Step Two **** //
        return (
          <>
            <Grid item container direction='row' justifyContent='center'>
              <img src={stepTwoImage} alt='step-two' style={{ width: '20rem' }} />
            </Grid>

            <Grid item container direction='row' justifyContent='center' style={{ marginTop: '1.5rem' }}>
              <Typography variant='h3'>What is an artifact?</Typography>
            </Grid>

            <Grid item container justifyContent='center' style={{ marginTop: '1rem', paddingRight: '2em', paddingLeft: '2em', marginBottom: '6em' }}>
              <Typography variant='h5' style={{ fontWeight: '400', textAlign: 'center', lineHeight: '1.9' }}>Artifacts consist of high quality Learning Experience Designs and Student Work created by educators. Artifacts can include slides, documents, websites, photos, and videos.</Typography>
            </Grid>
          </>
        )
      case 2:
        // **** Step Three **** //
        return (
          <Grid container direction='column' style={{ marginBottom: '3em' }}>
            <Grid container item direction='row' justifyContent='center' style={{ marginBottom: '1em' }}>
              <Typography variant='h3'>Artifact Types</Typography>
            </Grid>
            <Grid container item direction='row' justifyContent='center' spacing={2}>
              <Grid container item xs={5} direction='column'>
                <Paper
                  elevation={0}
                  style={{
                    border: 'solid 1px #E3E3E3'
                  }}
                >
                  <Grid container item justifyContent='center' style={{ marginTop: '1.5em', marginBottom: '1em' }}>
                    <img src={stepThreex1Image} alt='step-three1' style={{ width: '3.5em' }} />
                  </Grid>
                  <Grid container item justifyContent='center'>
                    <Typography variant='h5' gutterBottom style={{ color: theme.palette.purple.darkest }}>Learning Experience Design</Typography>
                  </Grid>
                  <Grid container item justifyContent='center' style={{ paddingLeft: '1.5em', paddingRight: '1.5em', marginBottom: '3.5em' }}>
                    <Typography variant='h6' style={{ fontWeight: '400', textAlign: 'center', textTransform: 'none' }}>This is comparable to a lesson plan.</Typography>
                  </Grid>
                </Paper>
              </Grid>
              <Grid container item xs={5} direction='column'>
                <Paper
                  elevation={0}
                  style={{
                    border: 'solid 1px #E3E3E3'
                  }}
                >
                  <Grid container item justifyContent='center' style={{ marginTop: '1.5em', marginBottom: '1em' }}>
                    <img src={stepThreex2Image} alt='step-three1' style={{ width: '2.7em' }} />
                  </Grid>
                  <Grid container item justifyContent='center'>
                    <Typography variant='h5' gutterBottom style={{ color: theme.palette.purple.darkest }}>Learner Work</Typography>
                  </Grid>
                  <Grid container item justifyContent='center' style={{ paddingLeft: '1.5em', paddingRight: '1.5em', marginBottom: '3.6em' }}>
                    <Typography variant='h6' style={{ fontWeight: '400', textAlign: 'center', textTransform: 'none' }}>These are high quality examples of student work.</Typography>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        )
      case 3:
        // **** Step Four **** //
        return (
          <Grid container direction='column' style={{ marginBottom: '3em' }}>
            <Grid item container direction='row' justifyContent='center'>
              <img src={step4Image} alt='step-4' style={{ width: '26em' }} />
            </Grid>
            <Grid item container direction='row' justifyContent='center' style={{ marginTop: '1.5rem' }}>
              <Typography variant='h3'>How are artifacts helpful?</Typography>
            </Grid>

            <Grid item container justifyContent='center' style={{ marginTop: '1rem', paddingRight: '4em', paddingLeft: '4em' }}>
              <Typography variant='h5' style={{ fontWeight: '400', textAlign: 'center', lineHeight: '1.9' }}>Artifacts help educators see what their colleagues are successfully incorporating in their classrooms and workspaces, and gain inspiration.</Typography>
            </Grid>
          </Grid>
        )
      default:
        return 'Unknown step'
    }
  }

  return (
    <>
      <Dialog
        classes={{ paper: classes.paper }}
        fullWidth
        maxWidth='sm'
        open={onboarding2Open}
        onClose={() => handleOnboarding2ModalClose()}
      >
        <DialogActions style={{ paddingBottom: 0 }}>
          <IconButton onClick={() => handleOnboarding2ModalClose()}>
            <CloseRounded className={classes.customizedButton} />
          </IconButton>
        </DialogActions>

        <DialogContent style={{ paddingBottom: '1em' }}>
          <Grid item xs={12}>
            {stepperContent(activeStep)}
          </Grid>
          <Grid item xs={12} style={{ marginBottom: '1em' }}>
            <MobileStepper
              variant='dots'
              steps={4}
              position='static'
              activeStep={activeStep}
              style={{ justifyContent: 'space-around' }}
              classes={{ dot: classes.dotStyle }}
              nextButton={
                <Button
                  size='small'
                  variant='contained'
                  onClick={activeStep === 3 ? handleOnboarding2ModalClose : handleNext}
                  classes={{ root: classes.purpleBtnRoot, label: activeStep === 3 ? classes.purpleBtnLabel2 : classes.purpleBtnLabel }}
                >
                  {activeStep === 3 ? 'Let\'s Begin' : 'Next'}
                </Button>
              }
              backButton={
                <Button
                  size='small'
                  variant='contained'
                  onClick={handleBack}
                  disabled={activeStep === 0}
                  style={{ visibility: activeStep === 0 ? 'hidden' : '' }}
                  classes={{ root: classes.purpleBtnRoot, label: classes.purpleBtnLabel }}
                >
                  Back
                </Button>
              }
            />
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Onboard2Modal
