import React, { useState, useEffect } from 'react'

import {
  Grid, Paper, Typography, Button, IconButton, OutlinedInput, useTheme,
  Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fab
} from '@material-ui/core'

import {
  ListRounded as ListIcon, HelpOutlineRounded as HelpIcon
} from '@material-ui/icons'

import { makeStyles } from '@material-ui/core/styles'

import { ArtifactComment } from '../../tools'

const useStyles = makeStyles((theme) => ({
  inputPlaceholder: {
    '&::placeholder': {
      fontStyle: 'italic',
      color: 'black'
    }
  },
  InputAdornment: {
    'MuiTypography-colorTextSecondary': {
      color: 'black',
      fontSize: '16px',
      marginBottom: '.45em',
      marginRight: '.4em'
    }
  },
  addIcon: {
    fontSize: '26px',
    stroke: theme.palette.purple.darkest,
    strokeWidth: '2px'
  },
  paperRoot: {
    width: '80%',
    padding: '1.5em',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      '@media only screen and (orientation: landscape)': {
        marginLeft: '1em'
      }
    }
  }
}))

const LEDtwo = (props) => {
  const { ledInfo, setLEDInfo, styles, targetArtifactComments } = props

  const theme = useTheme()
  const classes = useStyles()

  const [modalOpen, setModalOpen] = useState(false)
  const [sectionComments, setSectionComments] = useState([])

  useEffect(() => {
    if (targetArtifactComments) {
      if (targetArtifactComments.length) {
        const tempArr = targetArtifactComments.reduce((arr, comment) => {
          if (comment) {
            if (comment.section === 'led2') {
              arr.push({ ...comment })
            }
          }
          return arr
        }, [])
        setSectionComments(tempArr)
      }
    }
  }, [targetArtifactComments])

  return (
    <>
      {/* Help Modal  */}
      <Dialog
        fullWidth
        maxWidth='sm'
        open={modalOpen}
        onClose={(e) => { e.preventDefault(); setModalOpen(false) }}
      >
        <DialogTitle>
          <Grid container direction='row' alignItems='center' justifyContent='flex-start' style={{ margin: '.5em 0em' }}>
            <Typography variant='h4' style={{ textTransform: 'none', fontSize: '20px' }}>Example: Learning Experience Design II</Typography>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          {/* Engage  */}
          <Grid item container direction='row' justifyContent='flex-start'>
            <Typography variant='h5'>Engage</Typography>
          </Grid>

          <Grid
            item
            container
            direction='row'
            justifyContent='flex-start'
            style={{
              marginTop: '1rem',
              padding: '1rem',
              border: '1px solid #d8d8d8',
              borderRadius: '5px'
            }}
          >
            <Typography variant='body1'>
              Students will be asked about what they know about Historically Black Colleges and
              Universities. Students will add their responses on the collaborative digital
              whiteboard, Jamboard. Students will view recruitment commercials from different
              HBCUs to discover strategies of digital advertising. A discussion about the media
              elements will be held.
            </Typography>
          </Grid>

          {/* Investigate  */}
          <Grid item container direction='row' justifyContent='flex-start' style={{ marginTop: '1.5rem' }}>
            <Typography variant='h5'>Investigate</Typography>
          </Grid>

          <Grid
            item
            container
            direction='row'
            justifyContent='flex-start'
            style={{
              marginTop: '1rem',
              padding: '1rem',
              border: '1px solid #d8d8d8',
              borderRadius: '5px'
            }}
          >
            <Typography variant='body1'>
              Students will explore the school's admissions process and requirements, tuition
              fees, student life, degree programs, distinguished graduates, pandemic protocols,
              and major initiatives.
            </Typography>
          </Grid>

          {/* Create  */}
          <Grid item container direction='row' justifyContent='flex-start' style={{ marginTop: '1.5rem' }}>
            <Typography variant='h5'>Create</Typography>
          </Grid>

          <Grid
            item
            container
            direction='row'
            justifyContent='flex-start'
            style={{
              marginTop: '1rem',
              padding: '1rem',
              border: '1px solid #d8d8d8',
              borderRadius: '5px'
            }}
          >
            <Typography variant='body1'>
              Students will design a digital ad using Clips to increase awareness of HBCUs
              historical relevance.
            </Typography>
          </Grid>

          {/* Act  */}
          <Grid item container direction='row' justifyContent='flex-start' style={{ marginTop: '1.5rem' }}>
            <Typography variant='h5'>Act</Typography>
          </Grid>

          <Grid
            item
            container
            direction='row'
            justifyContent='flex-start'
            style={{
              marginTop: '1rem',
              padding: '1rem',
              border: '1px solid #d8d8d8',
              borderRadius: '5px'
            }}
          >
            <Typography variant='body1'>
              Submit final product to Schoology, receive peer feedback, send to admissions
              counselors of their selected HBCUs.
            </Typography>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='primary' onClick={(e) => { e.preventDefault(); setModalOpen(false) }}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Main Content  */}
      <Paper className={classes.paperRoot}>
        {/* Header Info  */}
        <Grid item container direction='row' spacing={1} style={{ marginBottom: '1em' }}>
          <Grid item style={{ paddingTop: '2px' }}>
            <ListIcon style={{ fontSize: '28px', color: theme.palette.grey.dark }} />
          </Grid>
          <Grid item>
            <Typography variant='h4'>Learning Experience Design II</Typography>
          </Grid>
          <Grid item>
            <IconButton
              size='small'
              style={{ paddingTop: 0, paddingLeft: 0 }}
              onClick={(e) => { e.preventDefault(); setModalOpen(true) }}
            >
              <HelpIcon style={{ fontSize: '19px', color: theme.palette.purple.darkest }} />
            </IconButton>
          </Grid>
        </Grid>

        <Grid
          item
          container
          direction='row'
          alignItems='center'
          justifyContent='flex-start'
          style={{ marginTop: '1rem' }}
        >
          <Grid item>
            <Typography style={{ color: 'red', fontSize: '14px', marginRight: '.2em' }}>*</Typography>
          </Grid>
          <Grid item>
            <Typography variant='caption' style={{ color: theme.palette.purple.darkest }}>indicates a required field</Typography>
          </Grid>
        </Grid>

        {/* Engage  */}
        <Grid
          item
          container
          direction='row'
          alignItems='center'
          justifyContent='flex-start'
          style={{ marginTop: '.5rem' }}
        >
          <Grid item>
            <Typography style={{ color: 'red', fontSize: '14px', marginRight: '.2em' }}>*</Typography>
          </Grid>
          <Grid item>
            <Typography variant='body1' style={{ fontWeight: '600' }}>Engage</Typography>
          </Grid>
          <Grid item>
            <IconButton
              size='small'
              style={{ paddingTop: 0, paddingLeft: 5, paddingBottom: 5 }}
              onClick={(e) => { e.preventDefault(); setModalOpen(true) }}
            >
              <HelpIcon style={{ fontSize: '16px', color: theme.palette.purple.darkest }} />
            </IconButton>
          </Grid>
        </Grid>

        <Grid item container direction='row' alignItems='center'>
          <Typography
            variant='caption'
            style={{ color: theme.palette.grey.dark }}
          >
            Describe how you will engage students during the lesson in 3-5 sentences.
          </Typography>
        </Grid>

        <Grid item container direction='row' alignItems='center' style={{ marginTop: '1rem' }}>
          <OutlinedInput
            variant='outlined'
            fullWidth
            margin='dense'
            multiline
            rows={3}
            inputProps={{
              maxLength: 2000
            }}
            placeholder='Enter your response...'
            classes={{ input: classes.inputPlaceholder }}
            value={ledInfo.engageContent}
            onChange={(e) => setLEDInfo({ ...ledInfo, engageContent: e.target.value })}
          />
        </Grid>
        <Grid item>
          <Typography variant='caption' style={{ color: '#adadad', paddingLeft: '.3em' }}>{`${ledInfo && ledInfo.engageContent && ledInfo.engageContent.length ? ledInfo.engageContent.length : 0}/ 2000`}</Typography>
        </Grid>

        {/* Investigate  */}
        <Grid
          item
          container
          direction='row'
          alignItems='center'
          justifyContent='flex-start'
          style={{ marginTop: '1rem' }}
        >
          <Grid item>
            <Typography style={{ color: 'red', fontSize: '14px', marginRight: '.2em' }}>*</Typography>
          </Grid>
          <Grid item>
            <Typography variant='body1' style={{ fontWeight: '600' }}>Investigate</Typography>
          </Grid>
          <Grid item>
            <IconButton
              size='small'
              style={{ paddingTop: 0, paddingLeft: 5, paddingBottom: 5 }}
              onClick={(e) => { e.preventDefault(); setModalOpen(true) }}
            >
              <HelpIcon style={{ fontSize: '16px', color: theme.palette.purple.darkest }} />
            </IconButton>
          </Grid>
        </Grid>

        <Grid item container direction='row' alignItems='center'>
          <Typography
            variant='caption'
            style={{ color: theme.palette.grey.dark }}
          >
            Describe how the students will investigate the topic for further learning in 1 sentence.
          </Typography>
        </Grid>

        <Grid item container direction='row' alignItems='center' style={{ marginTop: '1rem' }}>
          <OutlinedInput
            variant='outlined'
            fullWidth
            margin='dense'
            multiline
            rows={3}
            placeholder='Enter your response...'
            classes={{ input: classes.inputPlaceholder }}
            value={ledInfo.investigateContent}
            inputProps={{
              maxLength: 2000
            }}
            onChange={(e) => setLEDInfo({ ...ledInfo, investigateContent: e.target.value })}
          />
        </Grid>
        <Grid item>
          <Typography variant='caption' style={{ color: '#adadad', paddingLeft: '.3em' }}>{`${ledInfo && ledInfo.investigateContent && ledInfo.investigateContent.length ? ledInfo.investigateContent.length : 0}/ 2000`}</Typography>
        </Grid>

        {/* Create  */}
        <Grid
          item
          container
          direction='row'
          alignItems='center'
          justifyContent='flex-start'
          style={{ marginTop: '1rem' }}
        >
          <Grid item>
            <Typography style={{ color: 'red', fontSize: '14px', marginRight: '.2em' }}>*</Typography>
          </Grid>
          <Grid item>
            <Typography variant='body1' style={{ fontWeight: '600' }}>Create</Typography>
          </Grid>
          <Grid item>
            <IconButton
              size='small'
              style={{ paddingTop: 0, paddingLeft: 5, paddingBottom: 5 }}
              onClick={(e) => { e.preventDefault(); setModalOpen(true) }}
            >
              <HelpIcon style={{ fontSize: '16px', color: theme.palette.purple.darkest }} />
            </IconButton>
          </Grid>
        </Grid>

        <Grid item container direction='row' alignItems='center'>
          <Typography
            variant='caption'
            style={{ color: theme.palette.grey.dark }}
          >
            Describe what students will create to further their learning experience.
          </Typography>
        </Grid>

        <Grid item container direction='row' alignItems='center' style={{ marginTop: '1rem' }}>
          <OutlinedInput
            variant='outlined'
            fullWidth
            margin='dense'
            multiline
            rows={3}
            placeholder='Enter your response...'
            classes={{ input: classes.inputPlaceholder }}
            value={ledInfo.createContent}
            inputProps={{
              maxLength: 2000
            }}
            onChange={(e) => setLEDInfo({ ...ledInfo, createContent: e.target.value })}
          />
        </Grid>
        <Grid item>
          <Typography variant='caption' style={{ color: '#adadad', paddingLeft: '.3em' }}>{`${ledInfo && ledInfo.createContent && ledInfo.createContent.length ? ledInfo.createContent.length : 0}/ 2000`}</Typography>
        </Grid>

        {/* Act  */}
        <Grid
          item
          container
          direction='row'
          alignItems='center'
          justifyContent='flex-start'
          style={{ marginTop: '1rem' }}
        >
          <Grid item>
            <Typography style={{ color: 'red', fontSize: '14px', marginRight: '.2em' }}>*</Typography>
          </Grid>
          <Grid item>
            <Typography variant='body1' style={{ fontWeight: '600' }}>Act</Typography>
          </Grid>
          <Grid item>
            <IconButton
              size='small'
              style={{ paddingTop: 0, paddingLeft: 5, paddingBottom: 5 }}
              onClick={(e) => { e.preventDefault(); setModalOpen(true) }}
            >
              <HelpIcon style={{ fontSize: '16px', color: theme.palette.purple.darkest }} />
            </IconButton>
          </Grid>
        </Grid>

        <Grid item container direction='row' alignItems='center'>
          <Typography
            variant='caption'
            style={{ color: theme.palette.grey.dark }}
          >
            Describe the final product of learning that will provide individual/group reflection,
            evaluate level of understanding, and provide and receive feedback in 1 sentence.
          </Typography>
        </Grid>

        <Grid item container direction='row' alignItems='center' style={{ marginTop: '1rem' }}>
          <OutlinedInput
            variant='outlined'
            fullWidth
            margin='dense'
            multiline
            rows={3}
            inputProps={{
              maxLength: 2000
            }}
            placeholder='Enter your response...'
            classes={{ input: classes.inputPlaceholder }}
            value={ledInfo.actContent}
            onChange={(e) => setLEDInfo({ ...ledInfo, actContent: e.target.value })}
          />
        </Grid>
        <Grid item>
          <Typography variant='caption' style={{ color: '#adadad', paddingLeft: '.3em' }}>{`${ledInfo && ledInfo.actContent && ledInfo.actContent.length ? ledInfo.actContent.length : 0}/ 2000`}</Typography>
        </Grid>

        {/* Comments */}
        {sectionComments && sectionComments.length !== 0 &&
          <>
            <Divider style={{ marginTop: '1em' }} />
            <Grid item container direction='column' style={{ marginBottom: '2em', marginTop: '1em' }}>
              <Grid item container direction='row' alignContent='center'>
                <Grid item>
                  <Typography variant='body1' style={{ fontWeight: '600' }}>Comments</Typography>
                </Grid>
                <Grid item style={{ marginLeft: '.5em' }}>
                  <Fab style={{ height: '15px', width: '15px', minHeight: '15px', backgroundColor: styles.mainColor, boxShadow: 'none' }}>
                    <Typography variant='body1' style={{ fontSize: '10px', color: 'white', fontWeight: 600 }}>{sectionComments ? sectionComments.length : 0}</Typography>
                  </Fab>
                </Grid>
              </Grid>
              <Grid item container direction='column' spacing={2} style={{ marginTop: '1em' }}>
                {sectionComments && sectionComments.length !== 0 &&
                  sectionComments.map(comment => {
                    return (
                      <ArtifactComment
                        key={comment.commentID}
                        classes={classes}
                        commentInfo={comment}
                        artifactID={comment.artifactID}
                        section='details'
                        styles={styles}
                        fromCreate
                      />
                    )
                  })}
              </Grid>
            </Grid>
          </>}

      </Paper>
    </>
  )
}

export default LEDtwo
