import React from 'react'
import { useSelector } from 'react-redux'
import {
  Grid, Typography, Button, useTheme
} from '@material-ui/core'
import { CheckCircleOutlineRounded, RadioButtonUncheckedRounded, HighlightOff } from '@material-ui/icons'
import TitleBannerBack from '../../../assets/colorCircles.svg'
import { mceSections, sanitizedHTML, renderSkeletons } from '../../../utils'
import { MCE_DETAILS } from '../../../redux/types'
import { workSampleTypes } from '../../../utils/variables'

const CredentialHeader = (props) => {
  const {
    classes, sectionStyles = {}, navHeight, mdScreen, mceView,
    handlePursuerAction = () => { }, isSummaryView = true, isPursuerView = false, isAssessorView = false,
    handleBackToDashboard = () => { }, currentSection, submissionReview = false, isResultsView = false,
    attemptDetailsForm = {}, checkProceedPermisison = () => {}
  } = props

  const theme = useTheme()
  const { mceDetails: { keyMethod = '', title = '' } = {} } = useSelector(state => state.microcredentials)
  const loadingState = useSelector(state => state.loadingState)

  // decide the color of the section buttons
  const getButtonColor = (part) => {
    const partKey = Object.keys(mceSections).find(key => mceSections[key] === part)
    if (currentSection === mceSections[partKey] && !submissionReview) {
      return sectionStyles[part].mainColor
    }
    return theme.palette.grey.medium
  }

  // Check for the appropriate icon to display next to the section buttons
  const getSectionIcon = (section) => {
    const checkSection = attemptDetailsForm[section]
    const completeIcon = <CheckCircleOutlineRounded />
    const incompleteIcon = <RadioButtonUncheckedRounded />
    const failedIcon = <HighlightOff />

    // if we have correctly aquired the section to be checked against
    if (checkSection && Object.keys(checkSection).length) {
      // when viewing fromt he pursuer perpective:
      if (isPursuerView) {
        // if every question or sample in the section has been submitted/answered, return a 'complete' icon
        if (checkSection.every(q => (q.questionID && q.questionAnswer) || (q.workSampleID && ((q.sampleType === workSampleTypes.LED && q.artifactID) || (q.sampleType === workSampleTypes.UPLOAD && q.fileName && q.fileType))))) {
          return completeIcon
        }
        return incompleteIcon
      }

      // when viewing from the assessor view:
      if (isAssessorView) {
        // if all questions/samples in the section have passed
        if (checkSection.every(q => q.assessorGrade)) {
          return completeIcon
        }

        // if some have not yet been graded
        if (checkSection.some(q => q.assessorGrade === null)) {
          return incompleteIcon
        }

        // if the section has failed
        if (checkSection.every(q => q.assessorGrade !== null) && checkSection.some(q => q.assessorGrade === 0)) {
          return failedIcon
        }
      }
    }

    return false
  }

  // decide which button set to render in the header (either the Part 1, Part 2 etc, or the start button)
  const renderButtons = (view) => {
    if (isSummaryView) {
      // when in the summary view render the start button
      return (
        <>
          <Grid container item xs={mdScreen ? null : 9} justifyContent='center' spacing={mdScreen ? 0 : 2}>
            <Grid item xs={mdScreen ? 4 : 3} style={{ marginBottom: mdScreen ? '1em' : 0, paddingLeft: mdScreen ? '0.5em' : 0 }}>
              <Button
                variant='contained'
                color='primary'
                className={classes.headerButtons}
                onClick={() => handlePursuerAction({})}
              >
                Start
              </Button>
            </Grid>
          </Grid>
        </>
      )
    } else {
      // otherwise render the 3 parts and review buttons
      return (
        <>
          {!isResultsView &&
            <>
              <Grid item container direction='row' justifyContent={mdScreen ? 'center' : 'flex-end'} xs={mdScreen ? null : 3} style={{ marginBottom: mdScreen ? '1em' : 0 }}>
                <Button
                  variant='contained'
                  style={{ backgroundColor: getButtonColor(mceSections.PART1) }}
                  className={classes.headerButtons}
                  onClick={() => checkProceedPermisison(mceSections.PART1)}
                  endIcon={getSectionIcon(mceSections.PART1)}
                >
                  Part 1
                </Button>
              </Grid>
              <Grid item container direction='row' justifyContent={mdScreen ? 'center' : 'flex-end'} xs={mdScreen ? null : 3} style={{ marginBottom: mdScreen ? '1em' : 0 }}>
                <Button
                  variant='contained'
                  style={{ backgroundColor: getButtonColor(mceSections.PART2) }}
                  className={classes.headerButtons}
                  onClick={() => checkProceedPermisison(mceSections.PART2)}
                  endIcon={getSectionIcon(mceSections.PART2)}
                >
                  Part 2
                </Button>
              </Grid>
              <Grid item container direction='row' justifyContent={mdScreen ? 'center' : 'flex-end'} xs={mdScreen ? null : 3} style={{ marginBottom: mdScreen ? '1em' : 0 }}>
                <Button
                  variant='contained'
                  style={{ backgroundColor: getButtonColor(mceSections.PART3) }}
                  className={classes.headerButtons}
                  onClick={() => checkProceedPermisison(mceSections.PART3)}
                  endIcon={getSectionIcon(mceSections.PART3)}
                >
                  Part 3
                </Button>
              </Grid>
            </>}
          <Grid item container direction='row' justifyContent='flex-end' xs={3}>
            <Button variant='contained' disabled={isResultsView} color='primary' onClick={() => checkProceedPermisison(mceSections.PART4)} classes={{ root: classes.headerButtons, disabled: classes.reviewResultsButton }}>{isResultsView ? 'Results' : 'Review'}</Button>
          </Grid>
        </>
      )
    }
  }

  // styline ternaries
  const summaryTextColorTernary = isSummaryView ? 'white' : theme.palette.grey.dark
  const summarySecondaryTextTernary = isSummaryView ? 'white' : theme.palette.purple.darkest

  return (
    <>
      {/* HEADER CONTENT */}
      <Grid
        container
        item
        direction='row'
        alignItems='center'
        className={classes.defaultPadding}
        style={{
          backgroundImage: isSummaryView ? `url(${TitleBannerBack})` : 'none',
          backgroundRepeat: 'none',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '100%',
          ...(isSummaryView ? { position: 'sticky', top: navHeight, zIndex: 10 } : {})
        }}
      >
        <Grid item container xs={mdScreen ? null : 7} alignItems='flex-start' direction='column' style={{ padding: '2em 2em 1em 0' }}>
          <Grid item container direction='row' alignItems='center'>
            <Grid item>
              <Typography variant='h1' style={{ fontWeight: 600, color: summarySecondaryTextTernary }}>
                {loadingState.type === MCE_DETAILS && loadingState.loading === true
                  ? (
                    <>
                      {renderSkeletons(1, 'wave', 40, 200)}
                    </>
                  )
                  : title}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container direction='column' style={{ marginTop: '.5em' }}>
            <Typography variant='body1' style={{ fontWeight: 600, color: summarySecondaryTextTernary }}>Key Method</Typography>
            {loadingState.type === MCE_DETAILS && loadingState.loading === true
              ? (
                <>
                  {renderSkeletons(1, 'wave', 40)}
                </>
              )
              : <div dangerouslySetInnerHTML={{ __html: `${sanitizedHTML(keyMethod)}` }} className={classes.keyMethod} style={{ color: summaryTextColorTernary }} />}
          </Grid>
          {!isSummaryView &&
            <Grid item style={{ marginTop: '.5em' }}>
              <Button
                variant='text'
                size='small'
                color='primary'
                className={classes?.backToSummaryButton}
                onClick={handleBackToDashboard}
              >
                Back to Dashboard
              </Button>
            </Grid>}
        </Grid>
        <Grid item xs={mdScreen ? null : 5} container alignItems='flex-end' justifyContent='flex-end' direction={mdScreen ? 'column' : 'row'}>
          {renderButtons(mceView)}
        </Grid>
      </Grid>

    </>
  )
}

export default CredentialHeader
