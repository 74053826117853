import React, { useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'

import {
  Grid, Card, CardHeader, CardContent,
  Typography, Button, useTheme, Avatar, Dialog, DialogActions,
  DialogContent, DialogTitle, OutlinedInput, Paper
} from '@material-ui/core'

import { getReviewArtifactStatus, updateArtifactStatus, updateReviewArtifact } from '../../../redux/actions'
import { NotificationToast } from '../tools'

import { getArtifactType } from '../../../lib'
import { statusTypes, defaultErrors } from '../../../utils'

const ReviewSideMenu = (props) => {
  const {
    classes, styles, authorInfo = { status: '' }, handleExitReview
  } = props
  const theme = useTheme()
  const dispatch = useDispatch()

  const [status, setStatus] = useState(null)

  const [openDenialModal, setOpenDenialModal] = useState(false)
  const [statusDisabled, setStatusDisabled] = useState(false)
  const [denialComment, setDenialComment] = useState('')

  const defaultReviewSideMenuErr = defaultErrors.reviewSideMenuErr
  const [errors, setErrors] = useState(defaultErrors)

  useMemo(() => { if (authorInfo.status) { setStatus(authorInfo.status) } }, [authorInfo.status])

  useMemo(() => {
    if (
      authorInfo.status === statusTypes.approved ||
      authorInfo.status === statusTypes.denied ||
      authorInfo.status === statusTypes.needsChanges
    ) {
      setStatusDisabled(true)
    } else {
      setStatusDisabled(false)
    }
  }, [authorInfo.status])

  const fireUpdateSuccess = (newStatus) => {
    NotificationToast(false, 'Successfully updated artifact status.')

    if (newStatus === statusTypes.needReview) {
      dispatch(updateReviewArtifact(statusTypes.needReview))
    } else {
      setStatus(newStatus)
      handleExitReview()
    }
  }

  const fireUpdateFailure = () => {
    NotificationToast(true, 'Unable to update artifact status.')
  }

  const fireAdminFailure = () => {
    NotificationToast(true, 'Another admin has assigned a status to this artifact!')
    const { artifactID: reviewArtifact } = authorInfo
    const { type } = getArtifactType(reviewArtifact)

    if (type && reviewArtifact) {
      dispatch(getReviewArtifactStatus(authorInfo.artifactID))
    }
  }

  const handleStatusUpdate = (newStatus) => {
    if (newStatus === statusTypes.denied) {
      if (!denialComment.length) { setErrors({ notComplete: true }); return false }
      handleResetDenialModal()
    }

    const artifactInfo = {
      artifactID: authorInfo.artifactID,
      authorID: authorInfo.userID,
      status: newStatus,
      ...(newStatus === statusTypes.denied && { denialComment: denialComment })
    }

    dispatch(updateArtifactStatus(artifactInfo, fireUpdateSuccess, fireUpdateFailure, fireAdminFailure))
  }

  const handleResetDenialModal = () => {
    setOpenDenialModal(false)
    setDenialComment('')
    setErrors(defaultReviewSideMenuErr)
  }

  return (
    <Grid item container sm={null} md={3} direction='column' alignContent={theme.breakpoints.down('sm') ? 'center' : 'flex-end'}>
      <Grid item container justifyContent='center'>
        <Card className={classes.sideMenu}>
          {/* Artifact Author/Teacher Information */}
          <CardHeader
            avatar={
              <Grid item container alignItems='flex-start'>
                <Avatar src={authorInfo ? authorInfo.profileAvatar : ''} />
              </Grid>
            }
            title={
              <Grid item container justifyContent='space-between' wrap='nowrap' className={classes.fixSpacing}>
                <Grid item xs={10} container justifyContent='flex-start' style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <Typography variant='h4' style={{ color: 'white', textTransform: 'none', textAlign: 'left' }}>
                    {authorInfo && authorInfo.name ? authorInfo.name : ''}
                  </Typography>
                </Grid>
              </Grid>
            }
            subheader={
              <Grid item container justifyContent='flex-start' alignItems='center'>
                <Typography variant='h6' style={{ color: 'white', textTransform: 'none', fontWeight: '400' }}>
                  {authorInfo && authorInfo.submitDate ? moment(authorInfo.submitDate).format('MM-DD-YYYY') : ''}
                </Typography>
              </Grid>
            }
            style={{
              backgroundColor: styles.mainColor
            }}
          />
          <CardContent style={{ backgroundColor: 'white', paddingBottom: '.8em' }}>
            {/* Author Details */}
            <Grid container direction='column' alignContent='flex-start' spacing={1}>
              <Grid item>
                <Typography variant='body1' style={{ fontWeight: '600' }}>{authorInfo && authorInfo.cohort ? authorInfo.cohort : 'Not available.'}</Typography>
              </Grid>
              <Grid item>
                <Typography variant='body1' style={{ fontWeight: '600' }}>{authorInfo && authorInfo.school ? authorInfo.school : 'Not available.'}</Typography>
              </Grid>
              <Grid item>
                <Typography variant='body1' style={{ fontWeight: '600' }}>{authorInfo && authorInfo.district ? authorInfo.district : 'Not available.'}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {(status === null || status === statusTypes.needReview) &&
        <Grid item container alignContent='center' direction='column'>
          <Grid item style={{ width: '70%', marginTop: '1em' }}>
            <Button
              variant='outlined'
              fullWidth
              className={classes.approveBtn}
              onClick={() => handleStatusUpdate(statusTypes.approved)}
              disabled={statusDisabled}
            >
              Approve
            </Button>
          </Grid>

          <Grid item style={{ width: '70%', marginTop: '1em' }}>
            <Button
              variant='outlined'
              fullWidth
              className={classes.denyBtn}
              onClick={() => setOpenDenialModal(true)}
              disabled={statusDisabled}
            >
              Deny
            </Button>
          </Grid>

          <Grid item style={{ width: '70%', marginTop: '2em' }}>
            <Button
              variant='contained'
              fullWidth
              color='primary'
              style={{ backgroundColor: styles.mainColor }}
              onClick={() => handleStatusUpdate(statusTypes.needsChanges)}
            >
              Request Changes
            </Button>
          </Grid>
        </Grid>}
      {(status !== null && status !== statusTypes.needReview) &&
        <Grid item container alignContent='center' direction='column'>
          <Grid item style={{ marginTop: '1em', width: '70%' }}>
            <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: 600 }}>
              {status !== statusTypes.needsChanges
                ? (
                  `${status === statusTypes.approved ? 'Approved' : 'Denied'} on ${authorInfo && authorInfo.statusUpdated ? moment(authorInfo.statusUpdated * 1000).format('MM-DD-YYYY') : ''}`
                )
                : (
                  `Changes Requested on ${authorInfo && authorInfo.statusUpdated ? moment(authorInfo.statusUpdated * 1000).format('MM-DD-YYYY') : ''}`
                )}
            </Typography>
          </Grid>
          {status === statusTypes.denied &&
            <Grid item container direction='column' style={{ width: '70%' }}>
              <Grid item style={{ marginTop: '1em' }}>
                <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: 600 }}>Reason for Denial</Typography>
              </Grid>
              <Grid item style={{ marginTop: '.5em' }}>
                <Paper elevation={0} style={{ backgroundColor: theme.palette.grey.lighter, border: `1px solid ${theme.palette.grey.light}`, width: '100%', padding: '.5em' }}>
                  <Typography variant='body1' style={{ fontWeight: 400 }}>{authorInfo.status === statusTypes.denied && authorInfo.denialComment ? authorInfo.denialComment : denialComment}</Typography>
                </Paper>
              </Grid>
            </Grid>}
          <Grid item>
            <Button
              variant='text'
              style={{ textTransform: 'none', fontWeight: 600, textDecoration: 'underline', paddingLeft: 0 }}
              onClick={() => handleStatusUpdate(statusTypes.needReview)}
            >
              Review Again
            </Button>
          </Grid>
        </Grid>}

      {/* ----------Deny Artifact Modal--------- */}
      <Dialog
        open={openDenialModal}
        onClose={handleResetDenialModal}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle disableTypography>
          <Typography variant='h4' style={{ margin: '.5em' }}>Reason for Denial</Typography>
        </DialogTitle>
        <DialogContent
          dividers
        >
          <Grid container direction='column' style={{ margin: '1em', paddingRight: '2em' }}>
            <Grid item container direction='column' style={{ marginTop: 0 }}>
              <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}>Please provide a reason for denying the artifact.</Typography>
              <OutlinedInput
                fullWidth
                margin='dense'
                size='small'
                multiline
                rows={6}
                value={denialComment}
                onChange={(e) => setDenialComment(e.target.value)}
                placeholder='Enter reason...'
                classes={{ input: classes.inputPlaceholder }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container direction='column'>
            <Grid item container direction='column' alignContent='flex-end'>
              {errors.notComplete &&
                <Typography variant='caption' style={{ color: 'red' }}>* You must enter a reason for denial to save. </Typography>}
            </Grid>
            <Grid container item direction='row' justifyContent='flex-end'>
              <Button
                variant='outlined'
                color='primary'
                style={{
                  margin: '.5em 1em',
                  fontWeight: '600'
                }}
                onClick={handleResetDenialModal}
              >
                Cancel
              </Button>

              <Button
                variant='contained'
                color='primary'
                style={{
                  margin: '.5em 1em',
                  fontWeight: '600'
                }}
                onClick={() => handleStatusUpdate(statusTypes.denied)}
              >
                Save
              </Button>
            </Grid>
          </Grid>

        </DialogActions>
      </Dialog>
    </Grid>
  )
}

export default ReviewSideMenu
