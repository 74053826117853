import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FileDrop } from 'react-file-drop'
import { withRouter } from 'react-router-dom'
import { chunk } from 'lodash'

// MUI
import {
  Grid, AppBar, Toolbar, Button, Typography, SvgIcon, Stepper, Step, StepLabel, StepConnector,
  Paper, TextField, FormControl, FormGroup, FormControlLabel, Checkbox, Avatar, Fade, Select, MenuItem,
  IconButton, Tooltip, OutlinedInput, InputAdornment
} from '@material-ui/core'

// MUI Icons
import { FiberManualRecord, CheckCircleRounded, Error as ErrorIcon, CheckCircle as CheckIcon } from '@material-ui/icons'
import AssignmentIndRoundedIcon from '@material-ui/icons/AssignmentIndRounded'

import { withStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

// Assets
import smileIcon from '../../assets/smile-icon.svg'
import schoolIcon from '../../assets/school-icon.svg'
import gradCap from '../../assets/years-teaching.svg'
import gradesIcon from '../../assets/grades.svg'
import subjectsIcon from '../../assets/subjects.svg'

import { BaseToast } from '../ui/tools'

import { onboardingStepOne, getOnboardingData, getSchoolsAndDistricts, getInFellowTypes, logout } from '../../redux/actions'

import { uploadType, uploadFileFormatter, userRoleIDs, inFellowOtherTypeID, validInputCheck } from '../../utils'

// General Styling
const useStyles = makeStyles((theme) => ({
  purpleBtnRoot: {
    backgroundColor: '#293669',
    margin: 'auto',
    color: 'white',
    textTransform: 'none',
    borderRadius: '8px'
  },
  logoutButton: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  searchInput: {
    paddingLeft: '8px',
    backgroundColor: 'white'
  },
  statusSelect: {
    padding: '8px 0 8px 8px',
    color: theme.palette.grey.dark
  },
  purpleBtnLabel: {
    margin: '.3em 1.5em',
    fontSize: 'smaller'
  },
  paperShadow: {
    boxShadow: '1px 1px 8px 1px rgba(0, 0, 0, 0.07)'
  },
  checkboxLabel: {
    fontSize: '14px'
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#e3e3e3',
    '$.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)'
    }
  },
  checkedIcon: {
    backgroundColor: '#293669',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#293669'
    }
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  border: {
    borderBottom: '1px solid lightgray',
    width: '100%'
  },
  text: {
    marginRight: '1rem',
    marginLeft: '1rem',
    color: 'grey'
  },
  defaultAvatars: {
    marginBottom: '1rem',
    marginLeft: '1rem',
    marginRight: '1rem',
    width: '80px',
    height: '80px',
    cursor: 'pointer',
    backgroundColor: '#E3E3E3',
    '&:hover': {
      border: '2px solid #293669',
      boxSizing: 'border-box'
    }
  },
  inputLabel: {
    color: 'grey'
  },
  autocompleteRoot: {
    padding: '0 !important'
  },

  stepperInnovateView: {
    '& :nth-child(4)': {
      display: 'none'
    },
    '& :nth-child(5)': {
      display: 'none'
    }
  },

  stepperAdminTeacherView: {
    '& :nth-child(6)': {
      display: 'none'
    }
  },

  mediaCheck: {
    fill: theme.palette.success.main
  },
  mediaError: {
    fill: theme.palette.error.main
  },

  otherTypeEntrance: {
    opacity: 1,
    animation: '$fade-in 0.5s ease-in-out'
  },

  '@keyframes fade-in': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  }
}))

// Stepper Connector
const CustomConnector = withStyles({
  alternativeLabel: {
    top: 11,
    left: 'calc(-50% + 7px)',
    right: 'calc(50% + 5px)'
  },
  active: {
    '& $line': {
      backgroundColor: '#293669'
    }
  },
  completed: {
    '& $line': {
      backgroundColor: '#293669'
    }
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#e3e3e3',
    borderRadius: 1
  }
})(StepConnector)

// Stepper Icon Style
const useIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center'
  },
  active: {
    color: '#293669'
  },
  circle: {
    width: 17,
    height: 17,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
    zIndex: 1
  },
  completed: {
    color: '#293669',
    zIndex: 1,
    fontSize: 20
  }
})

// Stepper's Step Names
const getSteps = () => {
  return ['User Nickname', 'School Location', 'Teaching Experience', 'Grades Taught', 'Subjects Taught', 'Innovation Fellow Role', 'Teacher Avatar']
}

// Icon Render for Stepper (circle or checkmark)
const StepIcon = (props) => {
  const classes = useIconStyles()
  const { active, completed } = props

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active
      })}
    >
      {completed ? <CheckCircleRounded className={classes.completed} /> : <Grid className={classes.circle} container justifyContent='center' alignContent='center'>{active && <FiberManualRecord style={{ color: 'white', fontSize: '11px' }} />}</Grid>}
    </div>
  )
}

// SVG path for EdFarm logo
const EdFarmLogoIcon = (props) => {
  return (
    <SvgIcon {...props} xmlns='http://www.w3.org/2000/svg' version='1.1' x='0' y='0' viewBox='0 0 1228 256' fontSize='medium' style={{ width: 'auto' }}>
      <path fill='#4ac0e1' d='M0 5.8h316.3v19.5H0z' className='st0' />
      <path fill='#b94298' d='M90.7 51.3H407v19.5H90.7z' className='st2' />
      <path fill='#7bc24d' d='M0 96.8h316.3v19.5H0z' />
      <path fill='#f78c28' d='M90.7 142.3H407v19.5H90.7z' />
      <path fill='#4ac0e1' d='M0 187.8h316.3v19.5H0z' className='st0' />
      <path fill='#b94298' d='M90.7 233.2h90.7v18H90.7z' className='st2' />
    </SvgIcon>
  )
}

// ********* Main Component *********** //
const OnboardingP1 = (props) => {
  const { roleID = '' } = useSelector(state => state.auth)
  const innovateRole = userRoleIDs.INNOVATION_FELLOW

  const steps = getSteps()
  const classes = useStyles()
  const dispatch = useDispatch()

  const grades = useSelector(state => state.auth.grades)
  const subjects = useSelector(state => state.auth.subjects)
  const schoolsData = useSelector(state => state.auth.schools)
  const avatarPaths = useSelector(state => state.auth.defaultAvatars)
  const profileID = useSelector(state => state.auth.profileID)

  const {
    adminSchools: { schools: Schools = [], districtOptions: Districts = [] } = {}
  } = useSelector(state => state.admin)

  const [activeStep, setActiveStep] = useState(0)

  const [tempImage, setTempImage] = useState('')
  const [imageUploaded, setImageUploaded] = useState(false)

  const [areaEntered, setAreaEntered] = useState(false)

  const [schoolName, setSchoolName] = useState('')
  const [schoolTotalUsers, setSchoolTotalUsers] = useState('')

  // District Options and Selected District, defaults to the first ditrict in the array
  const districtOptions = !Districts ? [] : Districts
  const [selectedDistrict, setSelectedDistrict] = useState('')

  // School options (based on ditrict) and the selected school, defaults to the schools of the first district in the array
  const schoolsListIndex = Schools && Schools.length && Districts && Districts.length ? Schools.findIndex(x => x.schoolDistrictID === Districts[0].schoolDistrictID) : -1
  const [schoolOptions, setSchoolOptions] = useState(schoolsListIndex !== -1 ? [...Schools[schoolsListIndex].schools] : [])
  const [selectedSchool, setSelectedSchool] = useState('')

  const [fellowTypeOptions, setFellowTypeOptions] = useState([])
  const [inFellowTypeID, setInFellowTypeID] = useState('')

  const defaultForm = {
    nickName: '',
    yearsTeaching: '',
    schoolID: '',
    gradesTaught: [],
    subjectsTaught: [],
    avatarImage: '',
    isCustom: false,
    inFellowTypeID: '',
    inFellowTypeOther: ''
  }

  useEffect(() => {
    dispatch(getOnboardingData())
  }, [dispatch])

  useEffect(() => {
    dispatch(getSchoolsAndDistricts({ allResults: true }))
  }, [dispatch])

  const fetchInFellowTypes = useCallback(async () => {
    const fellowTypes = await dispatch(getInFellowTypes())

    if (fellowTypes) {
      setFellowTypeOptions(fellowTypes)
    }
  }, [dispatch])

  useEffect(() => {
    fetchInFellowTypes()
  }, [fetchInFellowTypes])

  const [newForm, setNewForm] = useState(defaultForm)

  // Checks if the selected inFellow type is 'Other'
  const inFellowTypeOtherCheck = Boolean(inFellowTypeID && inFellowTypeID.inFellowTypeID && inFellowTypeID.inFellowTypeID === inFellowOtherTypeID)

  // Handles checking 'Other' input for special characters and only blank spaces
  const { specialChars, onlyBlankSpaces } = validInputCheck
  const specialCharactersCheck = specialChars.test(newForm.inFellowTypeOther)
  const onlyBlankSpaceCheck = onlyBlankSpaces.test(newForm.inFellowTypeOther)

  // Step Action Handlers
  const handleNameChange = (value) => {
    const tempForm = { ...newForm }
    setNewForm({
      ...tempForm,
      nickName: value
    })
  }

  const handleYearsChange = (value) => {
    const tempForm = { ...newForm }
    if (Number(value) > 100) {
      setNewForm({ ...tempForm, yearsTeaching: 100 })
    } else if (Number(value) < 0 || !Number(value)) {
      setNewForm({ ...tempForm, yearsTeaching: '' })
    } else {
      setNewForm({ ...tempForm, yearsTeaching: value })
    }
  }

  const handleGradesChange = (value) => {
    const tempArray = [...newForm.gradesTaught]
    const index = tempArray.indexOf(value)

    if (index === -1) { tempArray.push(value) } else { tempArray.splice(index, 1) }

    setNewForm({ ...newForm, gradesTaught: tempArray })
  }

  const handleSubjectsChange = (value) => {
    const tempArray = [...newForm.subjectsTaught]
    const index = tempArray.indexOf(value)

    if (index === -1) { tempArray.push(value) } else { tempArray.splice(index, 1) }

    setNewForm({ ...newForm, subjectsTaught: tempArray })
  }

  const handleDistrictChange = (value) => {
    setSelectedDistrict(value)
    setNewForm({
      ...newForm,
      schoolDistrictID: value.schoolDistrictID
    })

    const districtsSchoolsIndex = Schools.findIndex(x => x.schoolDistrictID === value.schoolDistrictID)

    if (districtsSchoolsIndex !== -1) {
      const districtsSchools = Schools[districtsSchoolsIndex].schools

      // Sets the district to the selected option, and repopulates the school options to the new district
      setSchoolOptions(districtsSchools)

      // If the current selected school is not in the new district, then reset it
      const schoolIndex = [...districtsSchools].findIndex(x => x.schoolID === selectedSchool.schoolID)
      if (schoolIndex === -1) {
        setSelectedSchool('')
      }
    } else {
      setSchoolOptions([])
      setSelectedSchool('')
    }
  }

  const handleSchoolChange = (value) => {
    const index = value && schoolOptions && Boolean(schoolOptions.length) ? schoolOptions.findIndex(x => x.schoolID === value.schoolID) : -1
    const dataIndex = value && schoolsData && Boolean(schoolsData.length) ? schoolsData.findIndex(x => x.schoolID === value.schoolID) : -1

    if (index !== -1) {
      setSelectedSchool(value)
      setNewForm({
        ...newForm,
        schoolID: value.schoolID
      })
      setSchoolName(value.schoolName)
      if (dataIndex !== -1) {
        setSchoolTotalUsers(schoolsData[dataIndex].userTotal)
      }
    } else {
      // Teacher either did not teach a school or school id could not be found
      setSelectedSchool(0)
    }
  }

  // Checks if the selected value exists and is valid. If it is then set the user's inFellowType to the one they selected, else set the type to an empty string.
  const handleInFellowTypeChange = (value) => {
    if (value && value.inFellowTypeID && value.inFellowTypeID !== '') {
      setInFellowTypeID(value)

      // inFellowTypeOther will be set to it's current input if the selected type is 'Other', otherwise it will be set to an empty string
      setNewForm({
        ...newForm,
        inFellowTypeID: value.inFellowTypeID,
        inFellowTypeOther: value.inFellowTypeID === inFellowOtherTypeID ? newForm.inFellowTypeOther : ''
      })
    } else {
      setInFellowTypeID('')
    }
  }

  // Handles input for the 'Other' fellow type
  const handleInFellowOtherInput = (value) => {
    const tempForm = { ...newForm }
    setNewForm({
      ...tempForm,
      inFellowTypeOther: value
    })
  }

  // Handles verbiage concerning how many users are or aren't at a school. Also added a message if the user didn't select a school.
  const handleSchoolTotalUsers = (total) => {
    if (schoolName !== '') {
      if (total === 0) {
        return `Welcome! You are the first user at ${schoolName}.`
      }
      if (total > 1) {
        return `Awesome! There are ${total} other ${schoolName} fellows using this app.`
      } else {
        return `Awesome! There is ${total} other ${schoolName} fellow using this app.`
      }
    } else {
      return `Welcome! You are now a ${selectedDistrict.districtName} fellow.`
    }
  }

  const [fileSizeError, setFileSizeError] = useState(false)
  const [fileExtensionError, setFileExtensionError] = useState(false)

  const fileErrorReset = () => { setFileSizeError(false); setFileExtensionError(false) }

  const handleImageUpload = async (e, file) => {
    e.preventDefault()

    // Reset errors on initial upload to clear out previous
    fileErrorReset()

    const handleFileSizeError = (error) => setFileSizeError(error)
    const handleFileExtensionError = (error) => setFileExtensionError(error)

    const type = uploadType.PROFILE_IMAGE
    const attachments = []
    const imageResult = await uploadFileFormatter(file, type, attachments, handleFileSizeError, handleFileExtensionError)

    if (imageResult) {
      const { imageData, fileExtension } = imageResult

      const tempForm = { ...newForm }
      setNewForm({ ...tempForm, avatarImage: imageData, avatarImageType: fileExtension, isCustom: true })
      setTempImage(imageResult.imageData)
      setImageUploaded(true)
      fileErrorReset()
    }
  }

  const handleDefaultAvatarSelect = (avatar) => {
    // get avatar key from full path
    const avatarPath = avatar.split('/')
    const avatarKey = `${avatarPath[avatarPath.length - 2]}/${avatarPath[avatarPath.length - 1]}`
    const tempForm = { ...newForm }
    setNewForm({ ...tempForm, avatarImage: avatarKey, avatarImageType: 'png', isCustom: false })
    setTempImage(avatar)
    setImageUploaded(true)
    fileErrorReset()
  }

  const handleAvatarReset = () => {
    const tempForm = { ...newForm }
    setNewForm({ ...tempForm, avatarImage: '', avatarImageType: '', isCustom: false })
    setTempImage('')
    setImageUploaded(false)
    setAreaEntered(false)
  }

  const handleUploadEnter = () => { setAreaEntered(true) }

  const handleUploadLeave = () => { setAreaEntered(false) }

  /*
  Handles which step the user will be directed to next in the onboarding process based on their roleID. If the user is an Innovation Fellow and they are on step 2 in the process, after clicking the next button they will skip the next 2 steps and land on step 5. If they are an Admin or Teacher Fellow, and they are on step 4, they will skip over step 5 and instead be directed to the last step.
  */
  const handleNext = () => {
    let newIndex = 1
    if (typeof activeStep !== 'undefined') {
      if (activeStep === 2 && roleID === innovateRole) {
        newIndex = 3
      }
      if (activeStep === 4 && roleID !== innovateRole) {
        newIndex = 2
      }
      setActiveStep(activeStep + newIndex)
    }
  }

  const handleEnter = (e) => {
    if (e.key) {
      if (e.key.toUpperCase() === 'ENTER') {
        let newIndex = 1
        if (typeof activeStep !== 'undefined') {
          if (activeStep === 2 && roleID === innovateRole) {
            newIndex = 3
          }
          if (activeStep === 4 && roleID !== innovateRole) {
            newIndex = 2
          }
          setActiveStep(activeStep + newIndex)
        }
      }
    }
  }

  const handleOnboardOne = () => {
    dispatch(onboardingStepOne({ ...newForm, yearsTeaching: (isNaN(newForm.yearsTeaching) || newForm.yearsTeaching === '') ? 0 : newForm.yearsTeaching }, fireOnboardSuccess, fireOnboardFailure))
  }

  const fireOnboardSuccess = async () => {
    await BaseToast(false, 'Successful onboarding', 'Get ready to be introduced to the app!')
    props.history.push({ pathname: '/profile', search: `?user=${profileID}` })
  }

  const fireOnboardFailure = async () => {
    await BaseToast(true, 'Failed to onboard user!', 'Sorry, there was an error filling out your information. Go back through the steps and make sure you have filled out all required information.')
  }

  // Content for each index of the Stepper
  const getStepContent = (step) => {
    switch (step) {
      // **** Name Input **** //
      case 0:
        return (
          <Grid container direction='column'>
            <Grid item container justifyContent='center' alignContent='center'>
              <img src={smileIcon} alt='Smile Icon' />
            </Grid>
            <Grid item container direction='column' justifyContent='center' alignContent='center' style={{ marginTop: '1em', marginBottom: '.5em' }}>
              <Typography variant='h4' style={{ fontWeight: 'bold', textAlign: 'center', color: '#293669' }} gutterBottom>Let's get acquainted</Typography>
              <Typography variant='h5' style={{ textAlign: 'center', marginTop: '1em' }} gutterBottom>Thanks for joining Ed Farm's Digital Fellows! We're glad to have you!</Typography>
            </Grid>
            <Grid item container direction='column' justifyContent='center' alignContent='center' style={{ marginTop: '1em' }}>
              <Paper elevation={1} classes={{ elevation1: classes.paperShadow }} style={{ paddingRight: '5em', paddingLeft: '5em' }}>
                <Grid item container direction='column' justifyContent='center' alignContent='center' style={{ marginTop: '1em', marginBottom: '1em' }}>
                  <Typography variant='h5' style={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '1em', color: '#293669' }} gutterBottom>What would you like to be called?</Typography>
                  <TextField
                    variant='outlined'
                    placeholder='Name'
                    size='small'
                    style={{ marginBottom: '2em' }}
                    value={newForm.name}
                    onChange={(e) => handleNameChange(e.target.value)}
                    onKeyDown={newForm.nickName !== '' ? handleEnter : () => { }}
                  />
                  <Button disabled={newForm.nickName === ''} onClick={handleNext} variant='contained' classes={{ root: classes.purpleBtnRoot, label: classes.purpleBtnLabel }}>Next</Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )
      case 1:
        // **** School Location **** //
        return (
          <Grid container direction='column'>
            <Grid item container justifyContent='center' alignContent='center'>
              <img src={schoolIcon} alt='School Icon' />
            </Grid>
            <Grid item container direction='column' justifyContent='center' alignContent='center' style={{ marginTop: '1em', marginBottom: '.5em' }}>
              <Typography variant='h5' style={{ textAlign: 'center' }} gutterBottom>{`Great, ${newForm.nickName}! We've got a few more questions.`}</Typography>
            </Grid>
            <Grid item container direction='column' justifyContent='center' alignContent='center' style={{ marginTop: '1em' }}>
              <Paper elevation={1} classes={{ elevation1: classes.paperShadow }} style={{ paddingRight: '5em', paddingLeft: '5em' }}>
                <Grid item container direction='column' justifyContent='center' alignContent='center' style={{ marginTop: '1em', marginBottom: '1em' }} spacing={3}>
                  <Typography variant='h5' style={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '1em', color: '#293669' }} gutterBottom>Where do you work?</Typography>
                  <Select
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    displayEmpty
                    classes={{ root: classes.searchInput, selectMenu: classes.statusSelect }}
                    defaultValue=''
                    value={selectedDistrict}
                    onChange={(e) => handleDistrictChange(e.target.value)}
                    style={{ padding: 0, marginBottom: '1em' }}
                    renderValue={(selected) => !selected ? 'Choose District...' : selected.districtName}
                  >
                    <MenuItem value='' disabled>Choose District...</MenuItem>
                    {districtOptions && districtOptions.length &&
                      districtOptions.map(district => {
                        return (
                          <MenuItem key={district.schoolDistrictID} value={district}>{district.districtName}</MenuItem>
                        )
                      })}
                  </Select>
                  {selectedDistrict !== '' &&
                    <Select
                      variant='outlined'
                      margin='dense'
                      fullWidth
                      defaultValue=''
                      classes={{ root: classes.searchInput, selectMenu: classes.statusSelect }}
                      displayEmpty
                      value={selectedSchool}
                      onChange={(e) => handleSchoolChange(e.target.value)}
                      style={{ padding: 0 }}
                      disabled={schoolOptions.length === 0}
                      renderValue={(selected) => {
                        /* A conditional used to determine which rendered value to show based on: 1. whether there are selections to choose from, 2. if the user selected the 'I do not work at a school' option, or 3. if they selected one of the available schools.
                        */
                        if (typeof selected === 'string' && Boolean(!selected.length)) {
                          if (schoolOptions.length) {
                            return 'Choose School...'
                          } else {
                            return 'None Available'
                          }
                        } else if (typeof selected === 'number' && Boolean(selected === 0)) {
                          return 'I do not work at a school'
                        } else {
                          if (selected.schoolName) {
                            return selected.schoolName
                          }
                          return ''
                        }
                      }}
                    >
                      <MenuItem value='' disabled>Choose School...</MenuItem>
                      <MenuItem value={0}>I do not work at a school</MenuItem>
                      {schoolOptions && schoolOptions.length &&
                        schoolOptions.map(school => {
                          return (
                            <MenuItem key={school.schoolID} value={school}>{school.schoolName}</MenuItem>
                          )
                        })}
                    </Select>}
                  <Grid item container direction='row' justifyContent='center'>
                    <Button disabled={selectedDistrict === ''} onClick={handleNext} variant='contained' classes={{ root: classes.purpleBtnRoot, label: classes.purpleBtnLabel }}>Next</Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )
      case 2:
        // **** Years Teaching **** //
        return (
          <Grid container direction='column'>
            <Grid item container justifyContent='center' alignContent='center'>
              <img src={gradCap} alt='Years Teaching Cap' />
            </Grid>
            <Grid item container direction='column' justifyContent='center' alignContent='center' style={{ marginTop: '1.5em', marginBottom: '.5em' }}>
              <Typography
                variant='h5'
                style={{ textAlign: 'center' }}
                gutterBottom
              >
                {handleSchoolTotalUsers(schoolTotalUsers)}
              </Typography>
            </Grid>
            <Grid item container direction='column' justifyContent='center' alignContent='center' style={{ marginTop: '1em' }}>
              <Paper elevation={1} classes={{ elevation1: classes.paperShadow }} style={{ paddingRight: '5em', paddingLeft: '5em' }}>
                <Grid item container direction='column' justifyContent='center' alignContent='center' style={{ marginTop: '1em', marginBottom: '1em' }}>
                  <Typography variant='h5' style={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '1em', color: '#293669' }} gutterBottom>How many years have you been working as an educator?</Typography>
                  <FormControl margin='dense' style={{ marginTop: '0' }}>
                    <TextField
                      variant='outlined'
                      size='small'
                      value={newForm.yearsTeaching}
                      placeholder='Years'
                      onChange={(e) => handleYearsChange(e.target.value)}
                      onKeyDown={newForm.yearsTeaching !== 0 ? handleEnter : () => { }}
                      style={{ margin: 'auto', marginBottom: '2em', maxWidth: '6em' }}
                      type='number'
                      InputProps={{ inputProps: { min: 0, max: 100 } }}
                      label='Years'
                      InputLabelProps={{ className: classes.inputLabel }}
                    />
                  </FormControl>
                  <Grid item container direction='row' justifyContent='center'>
                    <Button
                      variant='contained'
                      onClick={handleNext}
                      classes={{ root: classes.purpleBtnRoot, label: classes.purpleBtnLabel }}
                      disabled={newForm.yearsTeaching === 0 || newForm.yearsTeaching === ''}
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )
      case 3:
        // **** Grades Taught **** //
        return (
          <Grid container direction='column'>
            <Grid item container justifyContent='center' alignContent='center'>
              <img src={gradesIcon} alt='Grades Taught' />
            </Grid>
            <Grid item container direction='column' justifyContent='center' alignContent='center' style={{ marginTop: '1.5em', marginBottom: '.5em' }}>
              <Typography variant='h5' style={{ textAlign: 'center' }} gutterBottom>{`Congratulations on ${newForm.yearsTeaching} years of teaching!`}</Typography>
            </Grid>
            <Grid item container direction='column' justifyContent='center' alignContent='center' style={{ marginTop: '1em' }}>
              <Paper elevation={1} classes={{ elevation1: classes.paperShadow }} style={{ paddingRight: '1em', paddingLeft: '1em', width: '50%' }}>
                <Grid item container direction='column' justifyContent='center' alignContent='center' style={{ marginTop: '1em', marginBottom: '1em' }}>
                  <Typography variant='h5' style={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '1em', color: '#293669' }} gutterBottom>What grade(s) do you teach?</Typography>
                  <Grid container justifyContent='center' item direction='row' style={{ marginBottom: '1em' }}>
                    <Grid container alignContent='center' item direction='column' xs={3}>
                      <FormGroup>
                        {grades.slice(0, 4).map((grade, i) => {
                          return (
                            <FormControlLabel
                              key={`checkbox ${grade.gradeID} ${i}`}
                              control={
                                <Checkbox size='small' checked={newForm.gradesTaught.includes(grade.gradeID)} disableRipple icon={<span className={classes.icon} />} checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />} />
                              }
                              label={grade.gradeName}
                              value={grade.gradeID}
                              onChange={(e) => handleGradesChange(grade.gradeID)}
                              classes={{ label: classes.checkboxLabel }}
                              style={{ marginBottom: '.5em' }}
                            />
                          )
                        }
                        )}
                      </FormGroup>
                    </Grid>

                    <Grid container alignContent='center' item direction='column' xs={3}>
                      <FormGroup>
                        {grades.slice(4, 8).map((grade, i) => {
                          return (
                            <FormControlLabel
                              key={`checkbox ${grade.gradeID} ${i}`}
                              control={
                                <Checkbox size='small' checked={newForm.gradesTaught.includes(grade.gradeID)} disableRipple icon={<span className={classes.icon} />} checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />} />
                              }
                              label={grade.gradeName}
                              value={grade.gradeID}
                              onChange={(e) => handleGradesChange(grade.gradeID)}
                              classes={{ label: classes.checkboxLabel }}
                              style={{ marginBottom: '.5em' }}
                            />
                          )
                        }
                        )}
                      </FormGroup>
                    </Grid>

                    <Grid container alignContent='center' item direction='column' xs={3}>
                      <FormGroup>
                        {grades.slice(8, 12).map((grade, i) => {
                          return (
                            <FormControlLabel
                              key={`checkbox ${grade.gradeID} ${i}`}
                              control={
                                <Checkbox
                                  size='small'
                                  disableRipple
                                  checked={newForm.gradesTaught.includes(grade.gradeID)}
                                  icon={<span className={classes.icon} />}
                                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                />
                              }
                              label={grade.gradeName}
                              value={grade.gradeID}
                              onChange={(e) => handleGradesChange(grade.gradeID)}
                              classes={{ label: classes.checkboxLabel }}
                              style={{ marginBottom: '.5em' }}
                            />
                          )
                        }
                        )}
                      </FormGroup>
                    </Grid>

                    <Grid container alignContent='center' item direction='column' xs={3}>
                      <FormGroup>
                        {grades.slice(12, 16).map((grade, i) => {
                          return (
                            <FormControlLabel
                              key={`checkbox ${grade.gradeID} ${i}`}
                              control={
                                <Checkbox
                                  size='small'
                                  disableRipple
                                  checked={newForm.gradesTaught.includes(grade.gradeID)}
                                  icon={<span className={classes.icon} />}
                                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                />
                              }
                              label={grade.gradeName}
                              value={grade.gradeID}
                              onChange={(e) => handleGradesChange(grade.gradeID)}
                              classes={{ label: classes.checkboxLabel }}
                              style={{ marginBottom: '.5em' }}
                            />
                          )
                        }
                        )}
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid item container direction='row' justifyContent='center'>
                    <Button onClick={handleNext} variant='contained' classes={{ root: classes.purpleBtnRoot, label: classes.purpleBtnLabel }}>Next</Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )

      case 4:
        // **** Subjects Taught **** //
        return (
          <Grid container direction='column'>
            <Grid item container justifyContent='center' alignContent='center'>
              <img src={subjectsIcon} alt='Grades Taught' />
            </Grid>
            <Grid item container direction='column' justifyContent='center' alignContent='center' style={{ marginTop: '1.5em', marginBottom: '.5em' }}>
              <Typography variant='h5' style={{ textAlign: 'center' }} gutterBottom>{`Congratulations on ${newForm.yearsTeaching} years of teaching!`}</Typography>
            </Grid>
            <Grid item container direction='column' justifyContent='center' alignContent='center' style={{ marginTop: '1em' }}>
              <Paper elevation={1} classes={{ elevation1: classes.paperShadow }} style={{ paddingRight: '1em', paddingLeft: '1em', width: '50%' }}>
                <Grid item container direction='column' justifyContent='center' alignContent='center' style={{ marginTop: '1em', marginBottom: '1em' }}>
                  <Typography variant='h5' style={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '1em', color: '#293669' }} gutterBottom>What subjects do you teach?</Typography>
                  <Grid container item direction='column' style={{ marginBottom: '1em' }}>
                    {/* Chunk the subjects array into sub arrays of 4, then map through the subarrays in grid items of 3 to keep an even look */}
                    {chunk(subjects, 4).map((subArr, i) => {
                      return (
                        <Grid key={`onboarding-subjects-subArray-${i}`} container alignContent='flex-start' item direction='row'>
                          {subArr.map((subject) => {
                            const { subjectName = '', subjectID = '' } = subject
                            // Truncate long Subject names
                            const subLabel = subjectName && subjectName.length && subjectName.length > 15 ? `${subjectName.substring(0, 13)}...` : subjectName
                            return (
                              <Grid item xs={3} key={`checkbox ${subjectID} ${i}`}>
                                {/* Provide a tooltip for any truncated subject names */}
                                <Tooltip title={subLabel && subLabel.length >= 15 ? subjectName : ''}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        size='small'
                                        disableRipple
                                        checked={newForm.subjectsTaught.includes(subjectID)}
                                        icon={<span className={classes.icon} />}
                                        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                      />
                                    }
                                    label={subLabel}
                                    value={subjectID}
                                    onChange={(e) => handleSubjectsChange(subjectID)}
                                    classes={{ label: classes.checkboxLabel }}
                                    style={{ marginBottom: '.5em', wordBreak: 'break-word' }}
                                  />
                                </Tooltip>
                              </Grid>
                            )
                          }
                          )}
                        </Grid>
                      )
                    })}
                  </Grid>
                  <Grid item container direction='row' justifyContent='center'>
                    <Button onClick={handleNext} variant='contained' classes={{ root: classes.purpleBtnRoot, label: classes.purpleBtnLabel }}>Next</Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )

      case 5:
        // **** Innovation Fellow Roles **** //
        return (
          <Grid container direction='column'>
            <Grid item container justifyContent='center' alignContent='center'>
              <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '111px', width: '111px', backgroundColor: 'rgba(246,229,241,0.4)', borderRadius: '50%' }}>
                <AssignmentIndRoundedIcon style={{ height: '70px', width: '70px', color: '#b94197' }} />
              </span>
            </Grid>
            <Grid item container direction='column' justifyContent='center' alignContent='center' style={{ marginTop: '1.5em', marginBottom: '.5em' }}>
              <Typography variant='h5' style={{ textAlign: 'center' }} gutterBottom>What is your role as an Innovation Fellow?</Typography>
            </Grid>
            <Grid item container direction='column' justifyContent='center' alignContent='center' style={{ marginTop: '1em' }}>
              <Paper elevation={1} classes={{ elevation1: classes.paperShadow }} style={{ paddingRight: '1em', paddingLeft: '1em', width: '50%' }}>
                <Grid item container direction='column' justifyContent='center' alignContent='center' style={{ marginTop: '1em', marginBottom: '1em' }} spacing={1}>
                  <Typography variant='h5' style={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '1em', color: '#293669' }} gutterBottom>Choose from the options below:</Typography>
                  <Select
                    variant='outlined'
                    margin='dense'
                    displayEmpty
                    autoWidth
                    classes={{ root: classes.searchInput, selectMenu: classes.statusSelect }}
                    defaultValue=''
                    value={inFellowTypeID}
                    onChange={(e) => handleInFellowTypeChange(e.target.value)}
                    style={{ padding: 0, margin: 'auto auto 0.8em auto', width: '14em' }}
                    renderValue={(selected) => !selected ? 'Choose your role...' : !selected.inFellowTypeName ? '' : selected.inFellowTypeName}

                  >
                    <MenuItem value='' disabled>Choose Role...</MenuItem>
                    {fellowTypeOptions && fellowTypeOptions.length &&
                      fellowTypeOptions.map(fellow => {
                        const { inFellowTypeID = '', inFellowTypeName = '' } = fellow
                        return (
                          <MenuItem key={inFellowTypeID} value={fellow}>{inFellowTypeName}</MenuItem>
                        )
                      })}
                  </Select>

                  {/* If the user chose the 'Other' fellow type, render the input field for them to create the role name. If the name is empty, contains only blank spaces, or any special characters it will not be sent through and will visually show them their error via an endAdornment icon and red outline */}
                  {inFellowTypeOtherCheck &&
                    <>
                      <FormControl className={classes.otherTypeEntrance} margin='dense' style={{ marginTop: 0, marginBottom: 0 }}>
                        <OutlinedInput
                          variant='outlined'
                          size='small'
                          type='text'
                          placeholder='Enter other role...'
                          style={{ margin: 'auto auto 0.5em auto', width: '14em' }}
                          value={newForm.inFellowTypeOther}
                          error={Boolean(newForm.inFellowTypeOther && newForm.inFellowTypeOther.length && (specialCharactersCheck || onlyBlankSpaceCheck))}
                          onChange={(e) => handleInFellowOtherInput(e.target.value)}
                          endAdornment={
                            <InputAdornment position='end' variant='outlined'>
                              {
                                newForm.inFellowTypeOther && Boolean(newForm.inFellowTypeOther.length)

                                  ? specialCharactersCheck || onlyBlankSpaceCheck
                                    ? (<ErrorIcon className={classes.mediaError} />)
                                    : (<CheckIcon className={classes.mediaCheck} />)
                                  : (<></>)
                              }
                            </InputAdornment>
                          }
                        />
                      </FormControl>

                      {/* Returns the specific error as small red text if the user's 'Other' input has only blank spaces or any special characters */}
                      {newForm.inFellowTypeOther && Boolean(newForm.inFellowTypeOther.length) && (onlyBlankSpaceCheck || specialCharactersCheck) &&
                        <Grid item container direction='row' justifyContent='center' style={{ marginTop: 0, padding: 0, marginBottom: '0.5em' }}>
                          <Typography
                            variant='caption'
                            style={{ color: 'red' }}
                          >
                            {
                              onlyBlankSpaceCheck ? '* Role name cannot contain only spaces.'
                                : specialCharactersCheck ? '* Role name cannot contain special characters.' : ''
                            }
                          </Typography>
                        </Grid>}
                    </>}
                  <Grid item container direction='row' justifyContent='center'>
                    {/* Disable button if there is no selected type or if 'Other' has been selected and it's input is not valid */}
                    <Button disabled={inFellowTypeID === '' || (inFellowTypeID.inFellowTypeID === inFellowOtherTypeID && (specialCharactersCheck || onlyBlankSpaceCheck || newForm.inFellowTypeOther === ''))} onClick={handleNext} variant='contained' classes={{ root: classes.purpleBtnRoot, label: classes.purpleBtnLabel }}>Next</Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )

      case 6:
        // **** Avatar **** //
        return (
          <Grid container direction='column'>
            <Grid item container direction='column' justifyContent='center' alignContent='center' style={{ marginTop: '1.5em', marginBottom: '.5em' }}>
              <Typography variant='h4' style={{ fontWeight: 'bold', textAlign: 'center', color: '#293669' }} gutterBottom>You've made it!</Typography>
              <Typography variant='h5' style={{ textAlign: 'center' }} gutterBottom>One last thing, please upload a photo so your other educators can see who you are!</Typography>
            </Grid>
            <Grid item container direction='column' justifyContent='center' alignContent='center' style={{ marginTop: '2em' }}>
              <Fade
                in={!imageUploaded}
                exit={false}
                unmountOnExit
              >
                <div>
                  <Grid item container direction='row' justifyContent='center'>
                    <Grid
                      item
                      xs={6}
                      style={{
                        border: areaEntered ? '2px dashed #293669' : '2px dashed #adadad',
                        borderRadius: '5px',
                        width: '600',
                        color: 'black',
                        padding: '3rem'
                      }}
                    >
                      <FileDrop
                        onFrameDragEnter={(e) => handleUploadEnter()}
                        onFrameDragLeave={(e) => handleUploadLeave()}
                        onDrop={(files, e) => handleImageUpload(e, files)}
                      >
                        <Grid item container xs={12} justifyContent='center' style={{ marginTop: '.3em' }}>
                          <Typography variant='h6' style={{ fontWeight: '600', textTransform: 'none' }}>Drag and Drop Here</Typography>
                        </Grid>

                        <Grid item xs={8} style={{ marginTop: '.5rem', marginBottom: '.5rem', marginLeft: 'auto', marginRight: 'auto' }}>
                          <Grid item className={classes.container}>
                            <Grid item className={classes.border} />
                            <Typography variant='h5' className={classes.text}>or</Typography>
                            <Grid item className={classes.border} />
                          </Grid>
                        </Grid>

                        <Grid item container xs={12} justifyContent='center'>
                          <input
                            accept='image/*'
                            className={classes.input}
                            style={{ display: 'none' }}
                            id='raised-button-file'
                            multiple
                            type='file'
                            onChange={(e) => handleImageUpload(e, e.target.files)}
                          />
                          <label htmlFor='raised-button-file'>
                            <Grid item container direction='row'>
                              <Button
                                variant='outlined'
                                style={{ borderColor: '#29356a', color: '#29356a', fontWeight: '600' }}
                                component='span'
                              >
                                Choose Photo
                              </Button>
                            </Grid>
                            <Grid item container direction='row' style={{ marginTop: '.5rem' }}>
                              <Typography variant='caption' style={{ color: 'grey', fontSize: '.8rem' }}>Max file size of 3MB.</Typography>
                            </Grid>
                          </label>
                        </Grid>
                        {fileSizeError &&
                          <Grid item container direction='row' justifyContent='center'>
                            <Typography variant='caption' style={{ color: 'red' }}>The selected file is over the max file size of 3MB.</Typography>
                          </Grid>}

                        {fileExtensionError &&
                          <Grid item container direction='row' justifyContent='center'>
                            <Typography variant='caption' style={{ color: 'red' }}>The selected file type is not allowed.</Typography>
                          </Grid>}

                      </FileDrop>
                    </Grid>
                  </Grid>

                  <Grid item container style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '2rem' }} sm={6}>
                    <Grid item container direction='row' justifyContent='center'>
                      {chunk(avatarPaths, 6).map((subArr, i) => {
                        return (
                          <Grid item container direction='row' justifyContent='center' spacing={1} key={`default-avatar-${i}`}>
                            {subArr.map((avatarPath, i) => {
                              return (
                                <Grid item xs={4} sm={2} key={`default-avatar-${i}`}>
                                  <Avatar
                                    src={avatarPath}
                                    className={classes.defaultAvatars}
                                    onClick={(e) => { handleDefaultAvatarSelect(avatarPath) }}
                                  />
                                </Grid>
                              )
                            })}
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>
                </div>
              </Fade>

              <Fade
                in={imageUploaded}
                exit={false}
                unmountOnExit
              >
                <div>
                  <Grid item container xs={12} justifyContent='center'>
                    <Avatar
                      src={tempImage}
                      style={{
                        marginBottom: '5px',
                        width: '175px',
                        height: '175px',
                        backgroundColor: '#E3E3E3'
                      }}
                    />
                  </Grid>
                  <Grid item container xs={12} justifyContent='center'>
                    <Button
                      color='primary'
                      variant='text'
                      style={{ textTransform: 'none', fontWeight: '600', color: '#29356a', fontSize: '16px' }}
                      onClick={(e) => {
                        e.preventDefault()
                        handleAvatarReset()
                      }}
                    >
                      Change Photo
                    </Button>
                  </Grid>
                </div>
              </Fade>

              <Grid item container direction='row' justifyContent='center'>
                <Button
                  variant='contained'
                  disabled={!imageUploaded}
                  style={{ marginTop: '1rem' }}
                  onClick={handleOnboardOne}
                  classes={{ root: classes.purpleBtnRoot, label: classes.purpleBtnLabel }}
                >
                  Get Started
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )
      default:
        return 'Unknown step'
    }
  }

  // ************ Main Render ************ //
  return (
    <>
      <AppBar style={{ backgroundColor: '#e3e3e3' }} elevation={0}>
        <Toolbar>
          <Grid container direction='row' justifyContent='space-between'>
            <IconButton classes={{ root: classes.logoutButton }} onClick={() => dispatch(logout())}>
              <EdFarmLogoIcon />
            </IconButton>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid container direction='row' justifyContent='center' style={{ marginTop: '12vh' }}>
        <Grid item xs={8}>
          <Stepper className={roleID && roleID === innovateRole ? classes.stepperInnovateView : classes.stepperAdminTeacherView} alternativeLabel activeStep={activeStep} connector={<CustomConnector />}>
            {steps.map((label, i) => (
              <Step key={label}>
                <StepLabel StepIconComponent={StepIcon} />
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={12}>
          {steps && activeStep === steps.length
            ? (<Grid><Typography>All Done!</Typography></Grid>)
            : (<Grid>{getStepContent(activeStep)}</Grid>)}
        </Grid>
      </Grid>
    </>
  )
}

export default withRouter(OnboardingP1)
