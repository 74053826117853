import { trackPromise } from 'react-promise-tracker'
import { parseResponse, responseTypes, formValidationTypes } from '../../lib'
import {
  GET_USER_DETAILS, UPDATE_EMAIL, UPDATE_ONBOARD_LATEST, UPDATE_IMAGE,
  DATA_REQUEST, DATA_SUCCESS, DATA_ERROR
} from '../types'

import log from 'loglevel'
import moment from 'moment'

// UI Tech Debt: Removed api variable that wasn't being used anywhere

const api = process.env.REACT_APP_API_URL
const apiversion = process.env.REACT_APP_API_VERSION

export const getUserDetails = (profileID, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    const { token = '', profileAvatarPath: authAvatar, profileID: authProfileID } = getState().auth

    if (!token) {
      dispatch({ type: DATA_ERROR, payload: { type: GET_USER_DETAILS } })
      fireFailure()
      return false
    }

    dispatch({ type: DATA_REQUEST, payload: { type: GET_USER_DETAILS } })

    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }

    const response = await trackPromise(window.fetch(`${api}/v1/user/${profileID}`, options))
    const parsedResponse = parseResponse(response, dispatch)
    if (!parsedResponse) { return false }

    if (parsedResponse.error) {
      log.info(response.status)

      dispatch({ type: DATA_ERROR, payload: { type: GET_USER_DETAILS } })
    } else {
      const { data = {} } = await response.json()

      dispatch({ type: DATA_SUCCESS, payload: { type: GET_USER_DETAILS } })

      if (data && data.profileImagePath) {
        data.profileImagePath = `${data.profileImagePath}?${moment().unix()}`
      } else {
        data.profileImagePath = null
      }

      if (data && data.profileAvatarPath) {
        data.profileAvatarPath = `${data.profileAvatarPath}?${moment().unix()}`

        if (profileID === authProfileID) {
          data.authProfileAvatarPath = `${data.profileAvatarPath}?${moment().unix()}`
        } else {
          data.authProfileAvatarPath = authAvatar
        }
      } else {
        // *** Note for changes made in Navbar.js ***
        /* Previously, profileAvatarPath in Navbar.js was being pulled from userDetails, but now we are pulling the profileAvatarPath from auth. The reasoning for this is because when visiting the profile of another user who did not have an avatar, it was setting your own user's avatar to null, which means it was hitting this block whenever that happened */
        data.profileAvatarPath = null
      }

      const {
        userID = '', fullName = '', nickName = '', roleID = '', roleName = '', inFellowTypeID = '', inFellowTypeName = '', emailAddress = '',
        yearsTeaching = 0, summary = '', profileAvatarKey = '', profileImageKey = '', profileImagePath = '',
        profileAvatarPath = '', schoolID = '', schoolName = '', schoolDistrictID = '', districtName = '', facebookProfile = '', linkedInProfile = '',
        instagramProfile = '', twitterProfile = '', subjectsList = [], gradesList = [], certificationIDs = [],
        certList = [], skillsList = [], techList = [], authProfileAvatarPath, deletedAt = 0
      } = data

      const payload = {
        userID,
        fullName,
        nickName,
        roleID,
        roleName,
        inFellowTypeID,
        inFellowTypeName,
        emailAddress,
        yearsTeaching,
        summary,
        profileAvatarKey,
        profileImageKey,
        profileImagePath,
        profileAvatarPath,
        authProfileAvatarPath,
        schoolID,
        schoolName,
        schoolDistrictID,
        districtName,
        facebookProfile,
        linkedInProfile,
        instagramProfile,
        twitterProfile,
        subjectsList,
        gradesList,
        certificationIDs,
        certList,
        skillsList,
        techList,
        deletedAt
      }

      dispatch({ type: GET_USER_DETAILS, payload })
    }
  }
}

// this redux action can be used to update several pieces of user data depending on the type passed in
// currently being used on the password reset modal with type 'reset-password'
export const updateUserData = (type, data, fireNotification = () => { }, fireValidation = () => { }) => {
  return async function (dispatch, getState) {
    const { token, userID } = getState().auth

    const body = {
      type: type,
      data: data
    }

    const options = {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(body)
    }

    // 403 if current password doesn't match for type reset-password
    const response = await trackPromise(window.fetch(`${api}/${apiversion}/user/${userID}`, options))
    const parsedResponse = parseResponse(response, dispatch)
    if (!parsedResponse) { return false }

    if (parsedResponse.type === responseTypes.FORBIDDEN) {
      if (type === 'reset-password') { fireValidation(formValidationTypes.PASSWORD_MATCH_ERROR) }
    } else if (parsedResponse.type === responseTypes.SUCCESS) {
      fireNotification(parsedResponse)

      switch (type) {
        case 'onboarding-latest': {
          dispatch({
            type: UPDATE_ONBOARD_LATEST,
            payload: data.onboardingStep
          })

          break
        }
        case 'update-image': {
          dispatch({
            type: UPDATE_IMAGE,
            payload: data.avatar ? data.avatar : null
          })

          break
        }
        default: break
      }
    } else {
      fireValidation(formValidationTypes.PASSWORD_UPDATE_ERROR)
    }
  }
}

export const updateUserEmail = (userName, fireSuccess) => {
  return async function (dispatch, getState) {
    const { userID, token, profileID = null } = getState().auth
    log.warn(userName)

    const body = {
      type: 'update-email',
      data: {
        emailAddress: userName, profileID
      }
    }

    const options = {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(body)
    }

    const response = await trackPromise(window.fetch(`${api}/v1/user/${userID}`, options))
    const parsedResponse = parseResponse(response, dispatch)

    if (!parsedResponse || parsedResponse.error || parsedResponse.type !== responseTypes.SUCCESS) {
      fireSuccess(false)
    } else {
      dispatch({
        type: UPDATE_EMAIL,
        payload: userName
      })
      fireSuccess(true)
    }
  }
}

export const getUsersList = (fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = () => { }) => {
    try {
      const { token = '', profileID = '' } = getState().auth
      const userID = profileID

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/user/${userID}/userslist`, options))
      const parsedResponse = parseResponse(response)

      if (parsedResponse && !parsedResponse.error) {
        const data = await response.json()

        fireSuccess(data.data)
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const deleteUser = (userID, fireDeleteSuccess = () => { }, fireDeleteFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token } = getState().auth

      const options = {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${api}/v1/user/${userID}`, options))
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) {
        fireDeleteFailure()
      } else {
        fireDeleteSuccess()
      }
    } catch (err) {
      console.error(err)
      fireDeleteFailure()
    }
  }
}
