import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  Grid, Paper, Typography, Button, IconButton,
  useTheme, TextField, Fade, Card, CardHeader, CardContent, CardActions, Fab,
  Divider, Popover, useMediaQuery
} from '@material-ui/core'

import Autocomplete from '@material-ui/lab/Autocomplete'

import {
  ListRounded as ListIcon, HelpOutlineRounded as HelpIcon,
  AddRounded as AddIcon, Attachment as AttachmentIcon
} from '@material-ui/icons'

import { makeStyles, withStyles } from '@material-ui/core/styles'

import { getSignedS3Url, uploadToSignedS3Url, getAboutEditData, createLW } from '../../../../redux/actions'

import LearnerWorkModal from '../LWForms/LearnerWorkModal'
import { ArtifactComment, ConfirmToast, GradesCheck } from '../../tools'

import { uploadType, uploadFileFormatter, ValidateUrl } from '../../../../utils'

import { deleteArtifact } from '../../../../redux/actions/artifacts'
import moment from 'moment'

const ModifiedGradesIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='50'
      height='50'
      viewBox='0 0 111 111'
    >
      <g transform='translate(-620 -124)'>
        <circle className='a' cx='50' cy='50' r='50' transform='translate(628 124)' fill='#F3F5FB' />
        <g transform='translate(654 147)'>
          <path className='b' d='M33,9a9,9,0,1,0-9,9A9,9,0,0,0,33,9ZM21.9,22.6C16.337,19.2,7.327,18.262,2.794,18A2.594,2.594,0,0,0,0,20.544V41.432a2.578,2.578,0,0,0,2.483,2.536c4.093.215,12.374,1,18.1,3.884a1.305,1.305,0,0,0,1.92-1.113V23.677a1.247,1.247,0,0,0-.6-1.074ZM45.206,18c-4.533.257-13.543,1.193-19.1,4.6a1.271,1.271,0,0,0-.6,1.09V46.736a1.309,1.309,0,0,0,1.926,1.116c5.722-2.88,14-3.667,18.091-3.881A2.58,2.58,0,0,0,48,41.434V20.544A2.6,2.6,0,0,0,45.206,18Z' fill='#29356A' />
        </g>
      </g>
    </svg>
  )
}

const ModifiedChalkboardIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='50'
      height='50'
      viewBox='0 0 111 111'
    >
      <g transform='translate(-628 -124)'>
        <circle className='a' cx='50' cy='50' r='50' transform='translate(628 124)' fill='#F3F5FB' />
        <g transform='translate(645 149)'>
          <path className='b' d='M19.5,33a2.145,2.145,0,0,0-.662.1A12.367,12.367,0,0,1,15,33.75a12.378,12.378,0,0,1-3.839-.648A2.139,2.139,0,0,0,10.5,33,10.5,10.5,0,0,0,0,43.558,4.488,4.488,0,0,0,4.5,48h21A4.488,4.488,0,0,0,30,43.558,10.5,10.5,0,0,0,19.5,33ZM15,30a9,9,0,1,0-9-9A9,9,0,0,0,15,30ZM55.5,0h-36A4.582,4.582,0,0,0,15,4.649V9a11.864,11.864,0,0,1,6,1.669V6H54V33H48V27H36v6H28.851a11.949,11.949,0,0,1,3.721,6H55.5A4.582,4.582,0,0,0,60,34.351V4.649A4.582,4.582,0,0,0,55.5,0Z' transform='translate(0.002)' fill='#29356A' />
        </g>
      </g>
    </svg>
  )
}

const CustomAutocomplete = withStyles({
  inputRoot: {
    '&&[class*="MuiOutlinedInput-root"] $input': {
      padding: '.5rem'
    }
  },
  input: {}
})(Autocomplete)

const useStyles = makeStyles((theme) => ({
  InputAdornment: {
    'MuiTypography-colorTextSecondary': {
      color: 'black',
      fontSize: '16px',
      marginBottom: '.45em',
      marginRight: '.4em'
    }
  },
  addIcon: {
    fontSize: '26px',
    stroke: theme.palette.purple.darkest,
    strokeWidth: '2px'
  },
  autocompleteRoot: {
    '& input::placeholder': {
      fontStyle: 'italic',
      color: 'black'
    }
  },
  inputPlaceholder: {
    '&::placeholder': {
      fontStyle: 'italic',
      color: 'black'
    }
  },
  addButtonIcon: {
    marginRight: 0
  },
  activeContainer: {
    border: `1px dashed ${theme.palette.grey.medium}`,
    padding: '1.5em',
    borderRadius: theme.shape.borderRadius,
    width: '100%'
  },
  documentContainer: {
    border: `1px solid ${theme.palette.grey.medium}`,
    // padding: 'em',
    borderRadius: theme.shape.borderRadius
  },
  paperRoot: {
    width: '80%',
    padding: '1.5em',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      '@media only screen and (orientation: landscape)': {
        marginLeft: '1em'
      }
    }
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  border: {
    borderBottom: '1px solid lightgray',
    width: '100%'
  },
  text: {
    marginRight: '1rem',
    marginLeft: '1rem',
    color: 'grey'
  }
}))

const LEDLearnerWork = (props) => {
  const {
    newLearnerWorkForm, setNewLearnerWorkForm,
    selectedLearnerWork, setSelectedLearnerWork,
    defaultLearnerWorkForm = {},
    ledInfo, setLEDInfo, learnerWorkCheck,
    setNewlyCreatedLearnerWorkID, styles, targetArtifactComments, onUpdateEdit = () => { },
    isForMCE = false
  } = props

  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()

  const smScreenDown = useMediaQuery(theme.breakpoints.down('sm'))

  const gradesArray = useSelector(state => state.profileData.grades)
  const subjects = useSelector(state => state.profileData.subjects)

  const learnerWorkOptions = useSelector(state => state.artifacts.userLWOptions)
  const { userID, token } = useSelector(state => state.auth)

  // state for new learner work modal being open
  const [modalOpen, setModalOpen] = useState(false)

  // state to check if new lw is being set as selected
  const [newLW, setNewLW] = useState(false)

  // check if creating new lw failed and set error text if true
  const [createError, setCreateError] = useState(false)

  const [sectionComments, setSectionComments] = useState([])

  // Local state controlling border color for drag and drop file upload
  const [areaEntered, setAreaEntered] = useState(false)

  const [linkInput, setLinkInput] = useState('')

  useEffect(() => {
    if (targetArtifactComments) {
      if (targetArtifactComments.length) {
        const tempArr = targetArtifactComments.reduce((arr, comment) => {
          if (comment) {
            if (comment.section === 'lw') {
              arr.push({ ...comment })
            }
          }
          return arr
        }, [])
        setSectionComments(tempArr)
      }
    }
  }, [targetArtifactComments])

  // open new learner work modal and get grades & subjects if not already stored in redux
  const handleNewLearnerWorkModal = () => {
    setModalOpen(true)
    if ((gradesArray && gradesArray.length === 0) || (subjects && subjects.length === 0)) { dispatch(getAboutEditData()) }
  }

  const [selectionLoading, setSelectionLoading] = useState(false)

  // Part of this is to split a concatenated string of grade values for learner work if they exist (also set grades to blank array if null)
  const handleLearnerWorkSelect = async (event, value) => {
    if (value) {
      const { isDraft, isPrivate, submissionStatus } = value

      const publishedArt = !isDraft && !isPrivate && submissionStatus === 'NA'
      const approvedArt = !isDraft && !isPrivate && submissionStatus === 'APPROVED'

      // Check to see if the selected value is a non-public artifact
      if (publishedArt || approvedArt) {
        // It is public, so continue as normal
        if (value.grades === null) { value.grades = [] }
        setSelectedLearnerWork(value); setLEDInfo({ ...ledInfo, learnerWorkID: value.artifactID })
      } else {
        // It is not public so make sure they are ok with that
        setSelectionLoading(true)
        const notpublicCheck = await ConfirmToast({
          msg: 'Artifact is not Viewable to the Public.',
          txt: 'This selection can only be seen by you. Do you wish to continue?'
        })
        if (notpublicCheck) {
          setSelectionLoading(false)
          if (value.grades === null) { value.grades = [] }
          setSelectedLearnerWork(value); setLEDInfo({ ...ledInfo, learnerWorkID: value.artifactID })
        } else {
          setSelectionLoading(false)
          setSelectedLearnerWork(null); setLEDInfo({ ...ledInfo, learnerWorkID: '' })
        }
      }
    }
    if (!value) { setSelectedLearnerWork(null); setLEDInfo({ ...ledInfo, learnerWorkID: '' }) }
  }

  const [gradesOpen, setGradesOpen] = useState(false)

  // updates string value in learnerWorkLinks array on associated index
  const handleLearnerWorkLinksChange = (value, position) => {
    const tempLinks = [...newLearnerWorkForm.links]
    tempLinks[position] = value
    setNewLearnerWorkForm({ ...newLearnerWorkForm, links: tempLinks })
  }

  const [linkInputOpen, setLinkInputOpen] = useState(false)
  const [linkFormatError, setLinkFormatError] = useState(false)

  const resetLinkInput = () => {
    setLinkFormatError(false)
    setLinkInputOpen(false)
    setLinkInput('')
  }

  // adds blank string to array which creates a new input in the mapping
  const handleAddLearnerWorkLink = (input) => {
    if (input && typeof input === 'string' && input.length) {
      const validUrl = ValidateUrl(input.trim())
      if (!validUrl) { setLinkFormatError(true); return false }
      const tempArray = [...newLearnerWorkForm.links]
      tempArray.push(input)
      setNewLearnerWorkForm({ ...newLearnerWorkForm, links: tempArray })
      resetLinkInput()
    }
  }

  // removes string from array on associated index which also removes input from the mapping
  const handleRemoveLearnerWorkLink = (index) => {
    const tempArray = [...newLearnerWorkForm.links]
    tempArray.splice(index, 1)
    setNewLearnerWorkForm({ ...newLearnerWorkForm, links: tempArray })
  }

  const [fileSizeError, setFileSizeError] = useState(false)
  const [fileExtensionError, setFileExtensionError] = useState(false)
  const [fileUploadError, setUploadError] = useState(false)

  const fileErrorReset = () => { setFileSizeError(false); setFileExtensionError(false); setUploadError(false) }

  const handleImageUpload = async (e, file) => {
    e.preventDefault()

    // Reset errors on initial upload to clear out previous
    fileErrorReset()

    const handleFileSizeError = (error) => setFileSizeError(error)
    const handleFileExtensionError = (error) => setFileExtensionError(error)

    const type = uploadType.ARTIFACT_RESOURCE
    const attachments = newLearnerWorkForm.attachments
    const resourceResult = await uploadFileFormatter(file, type, attachments, handleFileSizeError, handleFileExtensionError)

    if (resourceResult) {
      if (resourceResult.newFileMeta && typeof resourceResult.newFileMeta === 'object') {
        // console.log(resourceResult.newFileMeta)
        const { uploadURL: signedUrl } = await getSignedS3Url({ uploadID: resourceResult.newFileMeta.attachmentID, type: resourceResult.newFileMeta.contentType, userID, token, uploadType: resourceResult.newFileMeta.uploadType })
        // console.log(signedUrl)
        if (signedUrl && typeof signedUrl === 'string') {
          // upload direct to s3
          if (file && file.length) {
            const [fileData] = file
            const success = await uploadToSignedS3Url({ file: fileData, signedUrl, type: resourceResult.newFileMeta.contentType })
            // console.log(success)
            if (success) {
              setNewLearnerWorkForm({ ...newLearnerWorkForm, attachments: resourceResult.tempArr })
            } else {
              setUploadError(true)
            }
          } else {
            setUploadError(true)
          }
        } else {
          setUploadError(true)
        }
      }
      setAreaEntered(false)
    }
  }

  // display state for grades and subject when creating new lw
  const [gradesValue, setGradesValue] = useState([])
  const [subjectValue, setSubjectValue] = useState('')

  // set selected grades array of IDs to newLearnerWorkForm for creating new lw and gradesValue for display
  const handleGradesChange = (options) => {
    setGradesValue(options)
    const tempArray = []
    options.map(e => tempArray.push(e.gradeID))
    setNewLearnerWorkForm({ ...newLearnerWorkForm, grades: tempArray })
    setGradesOpen(false)
  }

  // set subjectID to newLearnerWorkForm for creating new lw and subject object to subjectValue for display
  const handleSubjectChange = (value) => {
    setNewLearnerWorkForm({ ...newLearnerWorkForm, coreSubjectID: value.subjectID })
    setSubjectValue(value)
  }

  // cleanup function to reset state on new learner work modal close
  const handleModalClose = () => {
    setModalOpen(false)
    fileErrorReset()
    setCreateError(false)
    resetLinkInput()
  }

  /* function to handle creating new learner work ---
    needs to send newLearnerWorkForm, set selectedLearnerWork for display, reset state on success */
  const handleCreateNewLearnerWork = () => {
    const tempObject = { ...newLearnerWorkForm }
    tempObject.links = tempObject.links.filter(item => item)
    tempObject.completionTime = moment().valueOf(tempObject.completionTime)
    dispatch(createLW(tempObject, fireSuccess, fireFailure))
  }

  // set learner work ID returned to LED object and cleanup state if successful
  const fireSuccess = (learnerWorkID) => {
    const tempLWForm = { ...newLearnerWorkForm }
    setLEDInfo({ ...ledInfo, learnerWorkID: learnerWorkID })
    setSelectedLearnerWork({ ...tempLWForm, grades: gradesArray.map(x => x.gradeName).join(', ') })
    setNewLW(true)
    setNewlyCreatedLearnerWorkID(learnerWorkID)
    handleModalClose()
  }

  // set state value to true if creating new lw fails to show error text
  const fireFailure = () => { setCreateError(true) }

  // DELETE POPOVER ELEMENTS (FOR WHEN REMOVING AN LW CONNECTED TO AN isForMCE LED)
  const [deleteAnchorEl, setDeleteAnchorEl] = useState(null)
  const handleDeletePopover = (e) => {
    setDeleteAnchorEl(e.currentTarget)
  }
  const handleDeletePopClose = () => {
    setDeleteAnchorEl(null)
  }
  const openDeletePopover = Boolean(deleteAnchorEl)
  const deletePopoverID = openDeletePopover ? 'Delete-popover' : undefined
  // POPOVER END

  // reset the local state items regarding the LW
  const resetLEDLearnerWork = () => {
    handleDeletePopClose()
    setGradesValue([])
    setSubjectValue('')
    setSelectedLearnerWork(null)
    setLEDInfo({ ...ledInfo, learnerWorkID: '' })
    setNewLearnerWorkForm(defaultLearnerWorkForm)
    setNewLW(false)
  }

  // delete confirmation that fully deletes a LW (only used when working with an isForMCE LED)
  const handleDeleteMCELW = () => {
    const { learnerWorkID } = ledInfo
    dispatch(deleteArtifact(learnerWorkID))
    resetLEDLearnerWork()
  }

  // reset state when selected learner work is removed to select another one
  const handleRemoveSelectedLW = (e) => {
    if (isForMCE) {
      return handleDeletePopover(e)
    }
    resetLEDLearnerWork()
  }

  const handleUploadEnter = () => { setAreaEntered(true) }

  const handleUploadLeave = () => { setAreaEntered(false) }

  /* gradesArrayNew creates an array of grade names from newly created lw object while gradesArrayExisting splits the string that lists grade names of an existing lw into an array */
  const gradesArrayNew = gradesValue && Boolean(gradesValue.length) ? gradesValue.map(grade => grade.gradeName) : []
  const gradesArrayExisting = selectedLearnerWork && selectedLearnerWork.grades && Boolean(selectedLearnerWork.grades.length) ? selectedLearnerWork.grades.split(',') : ''

  // Uses a mapping object to check which category an artifact's grade(s) fall under (Pre-K, Kindergarten, Elementary, Middle, etc...) based on the grade name
  const learnerWorkGradesCheck = (lw) => {
    // Determines which case to run based on whether the LW is new or existing
    switch (lw) {
      case 'NEW': {
        return GradesCheck(gradesValue, gradesArrayNew)
      }

      case 'EXISTING': {
        return GradesCheck(selectedLearnerWork.grades, gradesArrayExisting)
      }

      default: return 'N/A'
    }
  }

  /* If the led lw is new then read from the length of the newLearnerWorkForm.attachments to set how many attachment(s) there are, if there are any. And if it's an existing lw, read from the length of the selectedLearnerWork.attachmentCount. Else, if there are no attachments in either, set the attachment count to 0. */
  const lwAttachmentLengthCheck = newLW === true
    ? newLearnerWorkForm.attachments && newLearnerWorkForm.attachments.length
      ? newLearnerWorkForm.attachments.length
      : 0
    : selectedLearnerWork && selectedLearnerWork.attachmentCount
      ? selectedLearnerWork.attachmentCount
      : 0

  return (
    <>
      {/* New Learner Work Modal */}
      <LearnerWorkModal
        classes={classes}
        theme={theme}
        modalOpen={modalOpen}
        linkInput={linkInput}
        setLinkInput={setLinkInput}
        linkInputOpen={linkInputOpen}
        setLinkInputOpen={setLinkInputOpen}
        gradesArray={gradesArray}
        subjects={subjects}
        gradesOpen={gradesOpen}
        gradesValue={gradesValue}
        subjectValue={subjectValue}
        setGradesOpen={setGradesOpen}
        fileSizeError={fileSizeError}
        fileExtensionError={fileExtensionError}
        fileUploadError={fileUploadError}
        createError={createError}
        learnerWorkCheck={learnerWorkCheck}
        handleImageUpload={handleImageUpload}
        handleModalClose={handleModalClose}
        handleGradesChange={handleGradesChange}
        newLearnerWorkForm={newLearnerWorkForm}
        handleSubjectChange={handleSubjectChange}
        setNewLearnerWorkForm={setNewLearnerWorkForm}
        handleCreateNewLearnerWork={handleCreateNewLearnerWork}
        handleLearnerWorkLinksChange={handleLearnerWorkLinksChange}
        handleAddLearnerWorkLink={handleAddLearnerWorkLink}
        handleRemoveLearnerWorkLink={handleRemoveLearnerWorkLink}
        areaEntered={areaEntered}
        handleUploadEnter={handleUploadEnter}
        handleUploadLeave={handleUploadLeave}
        onUpdateEdit={onUpdateEdit}
        setAreaEntered={setAreaEntered}
        fileErrorReset={fileErrorReset}
        linkFormatError={linkFormatError}
        resetLinkInput={resetLinkInput}
      />

      {/* Main Content  */}
      <Paper className={classes.paperRoot}>
        {/* Header Info  */}
        <Grid item container direction='row' alignItems='center'>
          <ListIcon style={{ fontSize: '28px', color: theme.palette.grey.dark, marginRight: '.3em' }} />
          <Typography variant='h4'>Learner Work</Typography>
          <IconButton
            onClick={(e) => { e.preventDefault() }}
          >
            <HelpIcon style={{ fontSize: '20px', color: theme.palette.purple.darkest }} />
          </IconButton>
        </Grid>

        {
          selectedLearnerWork === null
            ? (
              <>
                {/* Help Text */}
                <Grid
                  item
                  container
                  direction='row'
                  alignItems='center'
                  justifyContent='flex-start'
                  style={{ marginTop: '.5rem' }}
                >
                  <Grid item>
                    <Typography variant='body1' style={{ fontWeight: '600' }}>
                      {
                        learnerWorkOptions && learnerWorkOptions.length > 0
                          ? ('Select learner work that you\'d like to link to this learning experience.')
                          : ('No Learner Work created.')
                      }
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item container direction='row' alignItems='center'>
                  <Typography
                    variant='caption'
                    style={{ color: theme.palette.grey.dark }}
                  >
                    {
                      learnerWorkOptions && learnerWorkOptions.length > 0
                        ? ('Each learning experience design can only be linked to one learner work.')
                        : ('Click "New Learner Work" below to create your first learner work and link it to this learning experience design.')
                    }
                  </Typography>
                </Grid>
              </>
            ) : (<></>)
        }

        {
          Boolean(selectedLearnerWork) && typeof selectedLearnerWork === 'object'
            ? (
              <>
                <Grid item container direction='row' alignItems='center' justifyContent='center' style={{ marginTop: '2rem' }}>
                  <Fade in={selectedLearnerWork !== null} style={{ transitionDelay: '50ms' }}>
                    <Card style={{ width: smScreenDown ? '100%' : '36%' }}>
                      <CardHeader
                        title={
                          <Grid item container justifyContent='flex-start'>
                            <Typography variant='h4' style={{ color: 'white', textTransform: 'none', textAlign: 'left' }}>
                              {selectedLearnerWork && selectedLearnerWork.learnerWorkTitle ? selectedLearnerWork.learnerWorkTitle : ''}
                            </Typography>
                          </Grid>
                        }
                        style={{
                          backgroundColor: '#1A7FA4',
                          alignItems: 'flex-start'
                        }}
                      />
                      <Grid container justifyContent='center' style={{ backgroundColor: theme.palette.purple.lightest, padding: '.2em 0' }}>
                        <Typography
                          variant='body1'
                          style={{
                            color: theme.palette.purple.darkest,
                            fontWeight: '600',
                            textTransform: 'uppercase',
                            fontSize: '14px',
                            letterSpacing: '0em',
                            marginTop: '.5rem'
                          }}
                        >
                          Learner Work
                        </Typography>
                      </Grid>
                      <CardContent>
                        <Grid container direction='column'>
                          <Grid item container direction='row' justifyContent='center' style={{ margin: '.5em 0' }}>
                            <Grid item container direction='column' justifyContent='center' alignContent='center' xs={6}>
                              <Grid item container justifyContent='center'>
                                <ModifiedGradesIcon />
                              </Grid>
                              <Typography
                                variant='h6'
                                style={{ textAlign: 'center', color: theme.palette.black, textTransform: 'none' }}
                              >
                                {
                                  newLW === true
                                    ? (
                                      learnerWorkGradesCheck('NEW')
                                    ) : (
                                      learnerWorkGradesCheck('EXISTING')
                                    )
                                }
                              </Typography>
                            </Grid>
                            <Grid item container direction='column' justifyContent='center' xs={6}>
                              <Grid item container justifyContent='center'>
                                <ModifiedChalkboardIcon />
                              </Grid>
                              <Typography
                                variant='h6'
                                style={{ textAlign: 'center', color: theme.palette.black, textTransform: 'none' }}
                              >
                                {
                                  newLW === true ? (subjectValue && subjectValue.subjectName ? subjectValue.subjectName : '')
                                    : (selectedLearnerWork && selectedLearnerWork.subjectName ? selectedLearnerWork.subjectName : '')
                                }
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item container direction='column' style={{ margin: '1em 0 0 1em' }}>
                            <Grid item container direction='row' alignItems='center'>
                              <ListIcon style={{ fontSize: '28px', color: theme.palette.grey.dark, marginRight: '.3em' }} />
                              <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>Type of Work</Typography>
                            </Grid>
                            <Grid item container style={{ marginTop: '.1em' }}>
                              <Typography variant='h5' style={{ color: theme.palette.black, textTransform: 'none', wordBreak: 'break-word' }}>
                                {selectedLearnerWork && selectedLearnerWork.workType ? selectedLearnerWork.workType : ''}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item container direction='column' style={{ margin: '1em 0 0 1em' }}>
                            <Grid item container direction='row' alignItems='center'>
                              <AttachmentIcon style={{ fontSize: '28px', color: theme.palette.grey.dark, marginRight: '.3em' }} />
                              <Typography
                                variant='h6'
                                style={{ color: theme.palette.grey.dark, textTransform: 'none' }}
                              >
                                {lwAttachmentLengthCheck} Attachments
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>

                      <CardActions>
                        <Grid item container direction='row' justifyContent='center'>
                          <Button
                            variant='text'
                            style={{
                              color: theme.palette.purple.darkest,
                              textTransform: 'none',
                              fontWeight: 600,
                              textDecoration: 'underline',
                              fontSize: '.9rem'
                            }}
                            classes={{ startIcon: classes.addButtonIcon }}
                            onClick={(e) => { e.preventDefault(); handleRemoveSelectedLW(e) }}
                          >
                            {`${!isForMCE ? 'Remove' : 'Delete'} Learner Work`}
                          </Button>
                        </Grid>
                      </CardActions>
                    </Card>
                  </Fade>
                </Grid>
              </>
            ) : (
              <>
                {!isForMCE &&
                  <Grid item container direction='row' alignItems='center' style={{ marginTop: '1rem' }}>
                    {learnerWorkOptions && learnerWorkOptions.length > 0 &&
                      <>
                        {/* Select */}
                        <CustomAutocomplete
                          id='school-select'
                          size='small'
                          options={learnerWorkOptions}
                          onChange={handleLearnerWorkSelect}
                          clearOnBlur
                          clearOnEscape
                          getOptionLabel={(learnerWork) => learnerWork && learnerWork.learnerWorkTitle ? `${learnerWork.learnerWorkTitle}` : ''}
                          inputValue={selectedLearnerWork ? selectedLearnerWork.learnerWorkTitle : ''}
                          loading={selectionLoading}
                          style={{ width: '100%' }}
                          renderInput={
                            (params) =>
                              <TextField
                                {...params}
                                variant='outlined'
                                placeholder='Select Learner Work'
                                style={{ marginBottom: '2rem' }}
                                classes={{ root: classes.autocompleteRoot }}
                              />
                          }
                        />
                      </>}
                  </Grid>}

                {/* New Learner Work Button */}
                <Grid item container direction='row' alignItems='center' style={{ marginTop: '1rem' }}>
                  <Button
                    variant='text'
                    startIcon={
                      <AddIcon className={classes.addIcon} />
                    }
                    style={{
                      color: theme.palette.purple.darkest,
                      textTransform: 'none',
                      fontWeight: 600
                    }}
                    classes={{ startIcon: classes.addButtonIcon }}
                    onClick={(e) => { e.preventDefault(); handleNewLearnerWorkModal() }}
                  >
                    New Learner Work
                  </Button>
                </Grid>
              </>
            )
        }

        {sectionComments && sectionComments.length !== 0 &&
          <>
            <Divider style={{ marginTop: '2em' }} />
            <Grid item container direction='column' style={{ marginBottom: '2em', marginTop: '1em' }}>
              <Grid item container direction='row' alignContent='center'>
                <Grid item>
                  <Typography variant='body1' style={{ fontWeight: '600' }}>Comments</Typography>
                </Grid>
                <Grid item style={{ marginLeft: '.5em' }}>
                  <Fab style={{ height: '15px', width: '15px', minHeight: '15px', backgroundColor: styles.mainColor, boxShadow: 'none' }}>
                    <Typography variant='body1' style={{ fontSize: '10px', color: 'white', fontWeight: 600 }}>{sectionComments ? sectionComments.length : 0}</Typography>
                  </Fab>
                </Grid>
              </Grid>
              <Grid item container direction='column' spacing={2} style={{ marginTop: '1em' }}>
                {sectionComments && sectionComments.length !== 0 &&
                  sectionComments.map(comment => {
                    return (
                      <ArtifactComment
                        key={comment.commentID}
                        classes={classes}
                        commentInfo={comment}
                        artifactID={comment.artifactID}
                        section='details'
                        styles={styles}
                        fromCreate
                      />
                    )
                  })}
              </Grid>
            </Grid>
          </>}

      </Paper>

      {/* isForMCE REMOVE LW CONFIRMATION POPOVER */}
      <Popover
        id={deletePopoverID}
        open={openDeletePopover}
        anchorEl={deleteAnchorEl}
        onClose={handleDeletePopClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Grid container direction='column' style={{ padding: '1em' }}>
          <Grid item container direction='column' justifyContent='flex-start'>
            <Typography variant='h5' style={{ color: theme.palette.purple.darkest, textTransform: 'none', fontWeight: 600 }}>Are You Sure You Want To Delete This Learner Work?</Typography>
            <Typography gutterBottom style={{ fontWeight: 600 }}>This action will permanently delete this artifact, and cannot be undone.</Typography>
          </Grid>
          <Grid item container justifyContent='flex-end' style={{ marginTop: '.5em' }}>
            <Grid item>
              <Button
                variant='text'
                style={{
                  textTransform: 'none',
                  fontWeight: 600,
                  color: theme.palette.purple.darkest
                }}
                onClick={() => handleDeletePopClose()}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                size='small'
                style={{
                  backgroundColor: 'red',
                  color: 'white',
                  textTransform: 'none',
                  fontWeight: 900
                }}
                onClick={() => handleDeleteMCELW()}
              >
                Delete Learner Work
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Popover>
    </>
  )
}

export default LEDLearnerWork
