import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import {
  Grid, Paper, Typography, Divider, IconButton, Button,
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, Accordion, AccordionSummary, Fade
} from '@material-ui/core'

import { Autocomplete, Skeleton } from '@material-ui/lab'

import { useTheme, makeStyles } from '@material-ui/core/styles'

import { AddRounded, ExpandMore, SearchRounded, CloseRounded, KeyboardReturnRounded } from '@material-ui/icons'
import EditIcon from '../../../assets/edit-icon.svg'

import { getSkillsList, resetSkillsList, updateUserSkills, getUserDetails } from '../../../redux/actions'
import { GET_SKILLS_LIST, GET_USER_DETAILS } from '../../../redux/types'

import { NotificationToast } from '../tools'

const useStyles = makeStyles((theme) => ({
  addIcon: {
    color: theme.palette.purple.darkest,
    backgroundColor: theme.palette.purple.lightest,
    padding: '.1em',
    border: `2px dashed ${theme.palette.grey.medium}`,
    borderRadius: '5px'
  },
  editIcon: {
    padding: '0 1em 0 0',
    '&:hover': {
      backgroundColor: 'transparent !important'
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0'
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '.5em'
    }
  },
  addSkillsBtn: {
    textTransform: 'none',
    fontWeight: '600',
    fontSize: '.9rem',
    color: theme.palette.purple.darkest
  },
  accordionHeader: {
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start'
    }
  },
  skillChips: {
    '&:disabled': {
      backgroundColor: theme.palette.purple.lightest,
      color: theme.palette.purple.darkest,
      fontWeight: '600',
      padding: '.2em 14px',
      borderRadius: '5px',
      margin: '.5em'
    }
  },
  skillChipAction: {
    backgroundColor: theme.palette.purple.lightest,
    color: theme.palette.purple.darkest,
    textTransform: 'none',
    fontWeight: '600',
    boxShadow: 'none',
    borderRadius: '5px',
    margin: '.5em'
  },
  skillsHeader: {
    '@media only screen and (min-width: 640px) and (max-height: 360px)': {
      fontSize: '16px'
    }
  },
  customizedButton: {
    // position: 'absolute',
    // left: '135%',
    // top: '-130%',
    backgroundColor: 'none',
    color: 'grey',
    stroke: 'grey',
    strokeWidth: '2px',
    height: '2rem',
    width: '2rem',
    '&:hover': {
      stroke: '#616161'
    }
  },
  animateHelperText: {
    animation: '$pulse 1.2s ease-in-out'
  },
  '@keyframes pulse': {
    '0%': {
      textShadow: 'none'
    },
    '40%': {
      color: 'red',
      textShadow: '.1px .1px 2px red'
    },
    '100%': {
      textShadow: 'none'
    }
  }
}))

// Initializes timer for "Press Enter" helper text
let timer

const UserSkills = (props) => {
  const { matches, userSkills, allSkills, canEdit, editMode, userProfileID, loadingState, modalTypes, modalType, removeModalType, editSkillsOpen, setEditSkillsOpen, navHeight = 0 } = props
  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()

  const { type, loading } = loadingState

  const [expandAccor, setExpandAccor] = useState(false)
  const [showSkillsInput, setShowSkillsInput] = useState(false)

  // correlate with accessibility prompts for adding skills ("press enter", "unsaved skills")
  const [showHelperText, setShowHelperText] = useState(false)
  const [showUnsavedSkillsText, setShowUnsavedSkillsText] = useState(false)

  // Forces the autocomplete component to rerender (thereby reseting the selections)
  const [resetComplete, setResetComplete] = useState(false)

  // The length of this input (from the autocomplete) controls when the popper opens
  const [input, setInput] = useState('')

  useEffect(() => {
    if (modalType) {
      if (!editSkillsOpen && modalType === modalTypes.SKILLS) {
        setEditSkillsOpen(true)
      }
    }
  }, [modalType, editSkillsOpen, modalTypes, setEditSkillsOpen])

  const unsavedSkillsCheck = useMemo(() => {
    if (editSkillsOpen && showSkillsInput) {
      if (input && input !== '') {
        return true
      } else {
        setShowUnsavedSkillsText(false)
        return false
      }
    }
  }, [input, editSkillsOpen, showSkillsInput])

  const defaultSkillsForm = {
    currentSkillsToAdd: [],
    newSkillsToAdd: [],
    skillsToDelete: []
  }

  // Object to be sent through dispatch
  const [skillsForm, setSkillsForm] = useState(defaultSkillsForm)

  // Local object for display
  const [displaySkills, setDisplaySkills] = useState([])

  // Error fired if the user tries to add more than the alloted 5 skills per user
  const [lengthError, setLengthError] = useState(false)

  // When the modal opens, take all the user's current picks to the display array
  const handleModalEnter = (skills) => {
    const tempArray = []
    if (skills && skills.length) {
      skills.forEach((skill) => {
        tempArray.push(skill.skillName)
      })
    }

    if (userSkills && Boolean(userSkills.length)) {
      setShowSkillsInput(true)
    } else {
      setShowSkillsInput(false)
    }

    setDisplaySkills(tempArray)
  }

  const skillIsNotString = (option, tempAllArrays) => {
    const { tempDisplayArray, tempCurrentAddArray, tempDeleteArray } = tempAllArrays

    // Index of the option within the array of displayed buttons
    const disIndex = tempDisplayArray.indexOf(option.skillName)
    // Index of the option within the currentSkillsToAdd array
    const currentAddIndex = tempCurrentAddArray.findIndex(x => x.skillID === option.skillID)
    // Index of the option within the array of skills to delete
    const deletIndex = tempDeleteArray.indexOf(option.skillID)

    // If not currently in the display array, add the skill name to the array of skills to display
    if (disIndex === -1) {
      tempDisplayArray.push(option.skillName)
    }
    // If not currently in the array of current skills to add, add in the skillID
    if (currentAddIndex === -1) {
      if (option.skillName !== undefined || option.skillName !== '') {
        tempCurrentAddArray.push(option.skillID)
      }
    }

    // If the user had previously chosen to delete this skill and adds iot back in before saving, remove it from the array of skills to delete
    if (deletIndex !== -1) {
      tempDeleteArray.splice(deletIndex, 1)
    }
    return tempAllArrays
  }

  // set length error state value to true if max # of skills reached
  useMemo(() => {
    const value = Boolean(displaySkills && displaySkills.length === 5)
    if (value) {
      setLengthError(value)
    }
  }, [displaySkills])

  const handleOptionSelect = (option) => {
    // Force the autocomplete to rerender which resets it's selected options
    setResetComplete(prev => !prev)
    setInput('')

    // Temporary Arrays
    let allTempArrays = {
      tempDisplayArray: [...displaySkills],
      tempCurrentAddArray: [...skillsForm.currentSkillsToAdd],
      tempNewArray: [...skillsForm.newSkillsToAdd],
      tempDeleteArray: [...skillsForm.skillsToDelete]
    }

    const {
      tempDisplayArray,
      tempCurrentAddArray,
      tempNewArray,
      tempDeleteArray
    } = allTempArrays

    // Check to be sure user is not adding more than 5 skills
    if (tempDisplayArray && tempDisplayArray.length <= 4) {
      setLengthError(false)

      //  If input is a string and is a current skillName in db, find skill obj
      const existingSkillName = allSkills.find(skill => skill.skillName === option)

      // If the option is not currently a skill in the DB it will be a string
      if (typeof option === 'string' && !existingSkillName) {
        //  Check if string already exists in the array of new skills to add
        const newIndex = tempNewArray.indexOf(option)

        // If not already in the array of names to add, then add it
        if (newIndex === -1) {
          tempNewArray.push(option)
        }
        // if not currently in the array of names to display, then add it
        const stringDisIndex = tempDisplayArray.indexOf(option)
        if (stringDisIndex === -1) {
          tempDisplayArray.push(option)
        }
      } else {
        // Otherwise, if you supply an valid option that is not a string, it should be an object { skillID: '', skillName: '' }
        // or if option already existed as a skillName
        const skill = !existingSkillName ? option : existingSkillName
        const newArrays = skillIsNotString(skill, allTempArrays)
        allTempArrays = newArrays
      }

      // Set the temp arrays to state
      setDisplaySkills(tempDisplayArray)
      setSkillsForm({
        ...skillsForm,
        currentSkillsToAdd: tempCurrentAddArray,
        newSkillsToAdd: tempNewArray,
        skillsToDelete: tempDeleteArray
      })

      // hide prompts after skill successfully added
      setShowHelperText(false)
      setShowUnsavedSkillsText(false)
    } else {
      setLengthError(true)
    }
  }

  const handleDeleteSkill = (skill) => {
    // Force the rerender of the autocomplete component
    setResetComplete(prev => !prev)
    // index of the skill name in the display array
    const disIndex = displaySkills.indexOf(skill)
    // Index of the name in the newSkillsToAddArray
    const newIndex = skillsForm.newSkillsToAdd.indexOf(skill)

    // The index of the skillName in the array of all Skills
    const index = allSkills.findIndex(x => x.skillName === skill)
    // The skillID of the selected skill name
    const idToRemove = allSkills[index]

    // Temp Arrays
    const tempCurrentArray = [...skillsForm.currentSkillsToAdd]
    const tempNewArray = [...skillsForm.newSkillsToAdd]
    const tempDeleteArray = [...skillsForm.skillsToDelete]
    const tempDisArray = [...displaySkills]

    // First Remove the selected skill from the display
    tempDisArray.splice(disIndex, 1)

    if (newIndex === -1) {
      // The selected skill is not a new skill and will have an ID in the allSkills array

      // Remove the selected skillID from the currentSkillsToAdd array
      const skillIndex = tempCurrentArray.indexOf(idToRemove.skillID)
      tempCurrentArray.splice(skillIndex, 1)

      // If the id is not already in the list of id's to delete, add it in
      if (tempDeleteArray.indexOf(idToRemove.skillID) === -1) {
        // Add the selectedID to the id's to remove array
        tempDeleteArray.push(idToRemove.skillID)
      }
    } else {
      // The selected skill does not yet have an id and is in the new skills array

      // Remove the name from the newSkillsToAdd
      tempNewArray.splice(newIndex, 1)
    }

    if (lengthError) {
      setLengthError(false)
    }

    // Set the new arrays to state
    setDisplaySkills(tempDisArray)
    setSkillsForm({
      currentSkillsToAdd: tempCurrentArray,
      newSkillsToAdd: tempNewArray,
      skillsToDelete: tempDeleteArray
    })
  }

  // Check to see if the user selected an option from the popup or typed in a new skill
  const handleOptionName = (option) => {
    if (typeof option === 'string') {
      return option
    } else {
      return option.skillName
    }
  }

  const handleClose = () => {
    removeModalType()
    setInput('')
    setSkillsForm(defaultSkillsForm)
    setDisplaySkills([])
    setLengthError(false)
    setEditSkillsOpen(false)
    dispatch(resetSkillsList())
    setShowSkillsInput(false)
    setShowHelperText(false)
    setShowUnsavedSkillsText(false)
  }

  const fireSuccess = () => {
    handleClose()
    dispatch(getUserDetails(userProfileID, () => { }, () => NotificationToast(true, 'Failed to get user details!')))
  }

  const handleSubmit = () => {
    const { currentSkillsToAdd, newSkillsToAdd, skillsToDelete } = skillsForm
    if (unsavedSkillsCheck) {
      setShowUnsavedSkillsText(true)
    } else {
      dispatch(updateUserSkills(currentSkillsToAdd, newSkillsToAdd, skillsToDelete, fireSuccess))
      setShowUnsavedSkillsText(false)
    }
  }

  const handleSkillsModalOpen = (e) => {
    e.stopPropagation()
    dispatch(getSkillsList())
    setEditSkillsOpen(true)
    setShowHelperText(false)
    setShowUnsavedSkillsText(false)
  }

  const handleKeyUp = (e) => {
    clearTimeout(timer)

    if (showSkillsInput && e.target.value && e.target.value.length !== 0) {
      timer = setTimeout(() => {
        setShowHelperText(true)
      }, 1700)
    } else {
      setShowHelperText(false)
    }
  }

  // ******************************** MAIN RENDER ********************************************

  return (
    <>
      {
        type === GET_USER_DETAILS && loading === true
          ? (
            <>
              <Grid container style={{ margin: matches ? '1em 2em 1em 1em' : '0 1em 1em 1em' }}>
                <Paper style={{ width: '100%' }}>
                  <Grid item container direction='row' alignItems='center' justifyContent='space-between' style={{ margin: '.2em .5em', padding: '.5rem' }} xs={12}>
                    <Skeleton animation='wave' width={100} />
                  </Grid>
                  <Divider />
                  <Grid item container direction='row' style={{ margin: '.5em 0' }}>
                    <Skeleton animation='wave' width={370} style={{ marginLeft: '1rem' }} />
                    <Skeleton animation='wave' width={200} style={{ marginLeft: '1rem' }} />
                  </Grid>
                </Paper>
              </Grid>
            </>
          ) : (
            <>
              <Grid container style={{ margin: matches ? '1em 2em 1em 1em' : '0 1em 1em 1em' }}>
                <Accordion expanded={matches || expandAccor} style={{ width: '100%' }}>
                  <AccordionSummary expandIcon={<ExpandMore style={{ display: matches ? 'none' : 'flex' }} />} style={{ height: '3.2em', minHeight: 0, cursor: matches ? 'default' : 'pointer' }} onClick={() => setExpandAccor(prev => !prev)}>
                    <Grid item container direction='row' alignItems='center' className={classes.accordionHeader} xs={12}>
                      <Typography variant='h4' className={classes.skillsHeader}>Top 5 Skills</Typography>
                      {canEdit && editMode && userSkills && userSkills.length !== 0 &&
                        <IconButton className={classes.editIcon} onClick={(e) => handleSkillsModalOpen(e)}>
                          <img src={EditIcon} alt='edit-icon' />
                        </IconButton>}
                    </Grid>
                  </AccordionSummary>
                  <Divider />
                  <Grid item container direction='row' style={{ margin: '.5em 0' }}>
                    {userSkills && userSkills.length !== 0
                      ? (
                        <Grid item container direction='row' style={{ padding: '.5em' }}>
                          {userSkills.map((skill, i) => {
                            return (
                              <Button variant='contained' disabled className={classes.skillChips} key={`skill ${skill.skillID}`}>{skill.skillName}</Button>
                            )
                          })}
                        </Grid>
                      )
                      : (
                        canEdit && editMode
                          ? (
                            <IconButton onClick={(e) => handleSkillsModalOpen(e)}>
                              <AddRounded className={classes.addIcon} />
                            </IconButton>

                          )
                          : (
                            <Grid item container justifyContent='center' style={{ textAlign: 'center', margin: '1em' }}>
                              <Typography variant='h6' style={{ textTransform: 'none', color: theme.palette.purple.darker }}>This user has not chosen any skills yet.</Typography>
                            </Grid>
                          )
                      )}
                  </Grid>
                </Accordion>
              </Grid>
            </>
          )
      }

      {/* Edit Skills Modal */}
      <Dialog
        fullWidth
        maxWidth='sm'
        open={editSkillsOpen}
        style={{ marginTop: navHeight }}
        onClose={handleClose}
        TransitionProps={{
          onEnter: () => handleModalEnter(userSkills)
        }}
      >
        {
          type === GET_SKILLS_LIST && loading === true
            ? (
              <>
                <DialogTitle disableTypography>
                  <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                    <Grid item>
                      <Typography
                        variant='h4'
                        style={{ textTransform: 'none', color: theme.palette.purple.darkest, fontSize: '1.3rem' }}
                      >
                        Edit Skills
                      </Typography>
                    </Grid>

                    <Grid item>
                      <IconButton style={{ padding: '0px' }} onClick={(e) => { e.preventDefault(); handleClose() }}>
                        <CloseRounded className={classes.customizedButton} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </DialogTitle>

                <DialogContent dividers>
                  <Grid container direction='column' style={{ margin: '1em' }}>
                    <Grid item container direction='column' style={{ marginBottom: '1em', paddingRight: '2em' }}>
                      <Skeleton animation='wave' width={515} height={65} style={{ marginTop: '-1rem' }} />
                      <Skeleton animation='wave' width={300} height={30} />
                    </Grid>
                    <Grid container direction='column' style={{ marginTop: '1em' }}>
                      <Skeleton animation='wave' width={100} style={{ marginTop: '-.5rem' }} />
                      <Skeleton animation='wave' width={250} height={60} style={{ marginBottom: '-1rem' }} />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Skeleton animation='wave' width={75} height={55} style={{ marginTop: '-.5rem' }} />
                </DialogActions>
              </>
            ) : (
              <>
                <DialogTitle disableTypography>
                  <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                    <Grid item>
                      <Typography
                        variant='h4'
                        style={{ textTransform: 'none', color: theme.palette.purple.darkest, fontSize: '1.3rem' }}
                      >
                        Edit Skills
                      </Typography>
                    </Grid>

                    <Grid item>
                      <IconButton style={{ padding: '0px' }} onClick={(e) => { e.preventDefault(); handleClose() }}>
                        <CloseRounded className={classes.customizedButton} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </DialogTitle>

                <DialogContent dividers style={{ overflow: 'hidden' }}>
                  <Grid container direction='column' style={{ margin: '1em' }}>
                    <Grid item container direction='column' style={{ marginBottom: '1em', paddingRight: '2em' }}>
                      <Typography
                        style={{ marginTop: '.2em', color: `${theme.palette.grey.dark}`, fontWeight: 600 }}
                        variant='body2'
                      >
                        Add up to 5 skills (Examples: Critical Thinking, Computer Technology, Drawing)
                      </Typography>
                      <Grid item container direction='column' justifyContent='center'>
                        {lengthError &&
                          <Typography style={{ transition: '0.5s', marginTop: '.2em', color: 'red', fontWeight: 600 }} variant='caption'>You may only add 5 skills at a time.</Typography>}

                        {showHelperText &&
                          <Fade unmountOnExit in={Boolean(showHelperText && input)} style={{ transitionDelay: '50ms' }}>
                            <Typography
                              className={
                                (showHelperText && showUnsavedSkillsText && Boolean(input && input !== ''))
                                  ? classes.animateHelperText : ''
                              }
                              style={{
                                marginTop: '.2em',
                                color: `${theme.palette.grey.dark}`,
                                fontWeight: 500
                              }}
                              variant='body2'
                            >
                            Press Enter <KeyboardReturnRounded style={{ fontSize: '20px', margin: '0 4px -6px 4px' }} /> to add current skill
                            </Typography>
                          </Fade>}
                      </Grid>

                      {showSkillsInput
                        ? (
                          <Autocomplete
                            freeSolo
                            disableClearable
                            key={resetComplete}
                            debug
                            disabled={lengthError}
                            options={allSkills}
                            getOptionLabel={(option) => handleOptionName(option)}
                            onKeyUp={(e) => handleKeyUp(e)}
                            onChange={(e, newValue) => handleOptionSelect(newValue)}
                            open={input && input.length >= 3 ? !false : false}
                            noOptionsText='No Skills Available'
                            style={{ visibility: showSkillsInput ? 'visible' : 'hidden' }}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  {...params}
                                  placeholder='Add your top 5 skills'
                                  style={{
                                    fontSize: '14px',
                                    fontFamily: 'Source Sans Pro',
                                    width: '100%',
                                    padding: 0
                                  }}
                                  margin='dense'
                                  variant='outlined'
                                  onChange={(e) => setInput(e.target.value)}
                                  value={input}
                                  InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    startAdornment: (
                                      <SearchRounded style={{ color: theme.palette.grey.dark, padding: '0em .3em' }} />
                                    )
                                  }}
                                />
                              )
                            }}
                          />
                        ) : (
                          <>
                            <Grid item container direction='row' alignItems='center' style={{ marginTop: '1rem' }}>
                              <Button
                                className={classes.addSkillsBtn}
                                startIcon={<AddRounded style={{ color: theme.palette.purple.darkest }} />}
                                onClick={() => { setShowSkillsInput(true) }}
                              >
                                  Add Skills
                              </Button>
                            </Grid>
                          </>
                        )}
                    </Grid>

                    {displaySkills && displaySkills.length !== 0 &&
                      <Grid container direction='column' style={{ marginTop: '1em' }}>
                        <Typography variant='body1' style={{ color: theme.palette.black, fontWeight: '600' }}>{`${displaySkills && displaySkills.length ? displaySkills.length : 0} selected`}</Typography>
                        <Grid container item direction='row'>
                          {displaySkills.map((skill, i) => {
                            return (
                              <Button
                                key={`Display skill ${i}`}
                                variant='contained'
                                className={classes.skillChipAction}
                                onClick={() => handleDeleteSkill(skill)}
                                endIcon={
                                  <CloseRounded style={{ fontSize: '16px' }} />
                                }
                              >
                                {skill}
                              </Button>
                            )
                          })}
                        </Grid>
                      </Grid>}
                  </Grid>
                </DialogContent>
                <DialogActions>
                  {showUnsavedSkillsText && (input && input !== '') &&
                    <>
                      <Grid
                        item
                        container
                        direction='row'
                        variant='body2'
                        justifyContent='flex-end'
                      >
                        <Typography styles={{ color: theme.palette.grey.dark, fontWeight: 600 }}>
                          You have unsaved skills! Exit anyway?
                          <span>
                            <Button
                              variant='text'
                              color='primary'
                              style={{ textTransform: 'none', margin: '0 5px', color: theme.palette.purple.darkest, fontWeight: 600 }}
                              onClick={handleClose}
                            >
                            Exit
                            </Button>
                          </span>
                        </Typography>
                      </Grid>
                    </>}
                  <Button disabled={Boolean(showUnsavedSkillsText && input && input !== '')} variant='contained' color='primary' onClick={handleSubmit}>Save</Button>
                </DialogActions>
              </>
            )
        }
      </Dialog>
    </>
  )
}

export default UserSkills
