import React, { useState, useEffect } from 'react'

import { useSelector } from 'react-redux'
import {
  Grid, Typography, Paper, IconButton, useTheme, OutlinedInput,
  Button, makeStyles, Dialog, DialogTitle, DialogContent, DialogActions, Divider,
  Tooltip, Fab, Link, Popover
} from '@material-ui/core'

import { Save, AddRounded, AttachFile, DeleteOutlined, DescriptionOutlined, HelpOutlineRounded, Clear, CloseRounded, Settings } from '@material-ui/icons'

import { FileDrop } from 'react-file-drop'
import { v4 as uuidv4 } from 'uuid'

import { ArtifactComment, NotificationToast } from '../../tools'

import { uploadType, uploadFileFormatter, ValidateUrl } from '../../../../utils'
import { getSignedS3Url, uploadToSignedS3Url } from '../../../../redux/actions'

const useStyles = makeStyles((theme) => ({
  addButtonIcon: {
    marginRight: 0
  },
  activeContainer: {
    border: `1px dashed ${theme.palette.grey.medium}`,
    padding: '1.5em',
    borderRadius: theme.shape.borderRadius
  },
  documentContainer: {
    border: `1px solid ${theme.palette.grey.medium}`,
    // padding: 'em',
    borderRadius: theme.shape.borderRadius
  },
  addIcon: {
    fontSize: '26px',
    // stroke: theme.palette.purple.darkest,
    strokeWidth: '2px',
    marginRight: '.2em'
  },
  addLinkBtn: {
    color: theme.palette.purple.darkest,
    textTransform: 'none',
    fontWeight: 600
  },
  paperRoot: {
    width: '80%',
    padding: '1.5em',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      '@media only screen and (orientation: landscape)': {
        marginLeft: '1em'
      }
    }
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  border: {
    borderBottom: '1px solid lightgray',
    width: '100%'
  },
  text: {
    marginRight: '1rem',
    marginLeft: '1rem',
    color: 'grey'
  }
}))

const ModifiedEditIcon = (props) => {
  const { color } = props
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20' height='18.337'
      viewBox='0 0 24 21.337'
    >
      <path
        id='edit-regular_1_'
        data-name='edit-regular (1)'
        d='M16.763,14.3,18.1,12.966a.335.335,0,0,1,.571.238v6.058a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V4.595a2,2,0,0,1,2-2H13.4a.335.335,0,0,1,.238.571L12.3,4.5a.33.33,0,0,1-.238.1H2V19.262H16.667V14.533A.328.328,0,0,1,16.763,14.3Zm6.525-8.409L12.346,16.833l-3.767.417a1.721,1.721,0,0,1-1.9-1.9L7.1,11.583,18.038.641a2.432,2.432,0,0,1,3.446,0l1.8,1.8a2.441,2.441,0,0,1,0,3.45ZM19.171,7.178,16.75,4.757,9.009,12.5l-.3,2.721,2.721-.3Zm2.7-3.321-1.8-1.8a.435.435,0,0,0-.617,0L18.167,3.345l2.421,2.421,1.288-1.288A.444.444,0,0,0,21.871,3.857Z'
        transform='translate(0 0.075)'
        fill={color}
      />
    </svg>
  )
}

const UploadPhoto = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='30'
      height='30'
      viewBox='0 0 475.078 475.077'
    >
      <g>
        <g>
          <path
            d='M467.081,327.767c-5.321-5.331-11.797-7.994-19.411-7.994h-121.91c-3.994,10.657-10.705,19.411-20.126,26.262
            c-9.425,6.852-19.938,10.28-31.546,10.28h-73.096c-11.609,0-22.126-3.429-31.545-10.28c-9.423-6.851-16.13-15.604-20.127-26.262
            H27.408c-7.612,0-14.083,2.663-19.414,7.994C2.664,333.092,0,339.563,0,347.178v91.361c0,7.61,2.664,14.089,7.994,19.41
            c5.33,5.329,11.801,7.991,19.414,7.991h420.266c7.61,0,14.086-2.662,19.41-7.991c5.332-5.328,7.994-11.8,7.994-19.41v-91.361
            C475.078,339.563,472.416,333.099,467.081,327.767z M360.025,423.978c-3.621,3.617-7.905,5.428-12.854,5.428
            s-9.227-1.811-12.847-5.428c-3.614-3.613-5.421-7.898-5.421-12.847s1.807-9.236,5.421-12.847c3.62-3.613,7.898-5.428,12.847-5.428
            s9.232,1.814,12.854,5.428c3.613,3.61,5.421,7.898,5.421,12.847S363.638,420.364,360.025,423.978z M433.109,423.978
            c-3.614,3.617-7.898,5.428-12.848,5.428c-4.948,0-9.229-1.811-12.847-5.428c-3.613-3.613-5.42-7.898-5.42-12.847
            s1.807-9.236,5.42-12.847c3.617-3.613,7.898-5.428,12.847-5.428c4.949,0,9.233,1.814,12.848,5.428
            c3.617,3.61,5.427,7.898,5.427,12.847S436.729,420.364,433.109,423.978z'
            fill='#4C5788'
          />
          <path
            d='M109.632,173.59h73.089v127.909c0,4.948,1.809,9.232,5.424,12.847c3.617,3.613,7.9,5.427,12.847,5.427h73.096
            c4.948,0,9.227-1.813,12.847-5.427c3.614-3.614,5.421-7.898,5.421-12.847V173.59h73.091c7.997,0,13.613-3.809,16.844-11.42
            c3.237-7.422,1.902-13.99-3.997-19.701L250.385,14.562c-3.429-3.617-7.706-5.426-12.847-5.426c-5.136,0-9.419,1.809-12.847,5.426
            L96.786,142.469c-5.902,5.711-7.233,12.275-3.999,19.701C96.026,169.785,101.64,173.59,109.632,173.59z'
            fill='#4C5788'
          />
        </g>
      </g>
    </svg>
  )
}

const ModifiedLinkIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      height='18'
      width='18'
      fill='none'
      stroke='#66666e'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
      className='feather feather-link'
    >
      <path d='M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71' />
      <path d='M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71' />
    </svg>
  )
}

const ArtifactResources = (props) => {
  const { ledInfo, setLEDInfo, onUpdateEdit = () => { }, styles, targetArtifactComments } = props
  const theme = useTheme()
  const classes = useStyles()
  const { attachments = [] } = ledInfo

  const [exampleOpen, setExampleOpen] = useState(false)
  const [linkInputOpen, setLinkInputOpen] = useState(true)
  const [linkInput, setLinkInput] = useState('')
  const [linkInputError, setLinkInputError] = useState(false)
  const [linkFormatError, setLinkFormatError] = useState(false)
  const [linksList, setLinksList] = useState([])
  const [sectionComments, setSectionComments] = useState([])

  // ******* Attachments Editor Dialogue *******
  // Error object, contains the default states of each error that could occur when trying to rename an attachment
  const errorOptions = {
    inputBlank: false,
    inputContainsSpecialChars: false,
    lengthCheck: false
  }
  const [nameError, setNameError] = useState(errorOptions)
  const [attachmentEditorOpen, setAttachmentEditorOpen] = useState(false)
  const [attachmentRenameInput, setAttachmentRenameInput] = useState('')
  const [editAttachmentID, setEditAttachmentID] = useState('')
  const [editFileType, setEditFileType] = useState('')

  // ***** Attachments Popover elements *******
  const [attachmentAnchorEl, setAttachmentAnchorEl] = useState(null)
  // Fires when popover opens. Sets local state for attachment name, id, and file type, anchors the popover to current target
  const handleAttachmentPopover = (e, attachmentID, attachmentName, fileType) => {
    setEditFileType(fileType)
    setAttachmentRenameInput(attachmentName)
    setEditAttachmentID(attachmentID)
    setAttachmentAnchorEl(e.currentTarget)
  }
  const handleAttachmentPopClose = () => {
    setAttachmentAnchorEl(null)
  }
  const openAttachmentPopover = Boolean(attachmentAnchorEl)
  const attachmentPopoverID = openAttachmentPopover ? 'attachment-popover' : undefined

  // Opens the attachment editor modal
  const handleOpenAttachmentEditor = () => {
    handleAttachmentPopClose()
    setAttachmentEditorOpen(true)
  }

  // Resets local state when editor modal is closed or saved
  const handleAttachmentEditorReset = () => {
    setAttachmentEditorOpen(false)
    setNameError(errorOptions)
    setAttachmentRenameInput('')
    setEditAttachmentID('')
  }

  // Local state controlling border color for drag and drop file upload
  const [areaEntered, setAreaEntered] = useState(false)

  const { userID, token } = useSelector(state => state.auth)

  useEffect(() => {
    if (targetArtifactComments) {
      if (targetArtifactComments.length) {
        const tempArr = targetArtifactComments.reduce((arr, comment) => {
          if (comment) {
            if (comment.section === 'resources') {
              arr.push({ ...comment })
            }
          }
          return arr
        }, [])
        setSectionComments(tempArr)
      }
    }
  }, [targetArtifactComments])

  const handleInputChange = (value) => {
    setLinkInput(value)
  }

  useEffect(() => {
    if (ledInfo && ledInfo.links && ledInfo.links.length) { setLinksList(ledInfo.links) }
  }, [ledInfo])

  const handleLinkAdd = () => {
    if (!linkInputOpen) {
      setLinkInputOpen(true)
      handleOpenLinkInput()
      if (linkInputError) {
        setLinkInputError(false)
      }
    } else if (linkInputOpen && linkInput !== '') {
      const validUrl = ValidateUrl(linkInput.trim())
      if (!validUrl) {
        setLinkFormatError(true)
        return false
      }
      handleOpenLinkInput()
    }
  }

  const handleOpenLinkInput = () => {
    const tempArr = [...linksList]
    const uuid = uuidv4().replace(/-/g, '')
    tempArr.push({ linkID: `LI${uuid}`, link: linkInput })

    setLinksList(tempArr)
    setLEDInfo({ ...ledInfo, links: tempArr })
    setLinkInput('')
    setLinkInputOpen(false)
    setLinkInputError(false)
    setLinkFormatError(false)

    if (linkInputOpen && linkInput === '') {
      setLinkInputError(true)
    }
  }

  const handleRemoveLink = (linkID) => {
    const tempArr = [...linksList]
    const newLinks = tempArr.filter((linkInfo) => linkInfo.linkID !== linkID)
    setLinksList(newLinks)
    setLEDInfo({ ...ledInfo, links: newLinks })
    if ((tempArr && tempArr.length === 0) || newLinks.length === 0) {
      setLinkInputOpen(true)
    }
    onUpdateEdit(linkID, 'links')
  }

  const checkLinkLength = () => {
    return Boolean(linkInput.length)
  }

  const [fileSizeError, setFileSizeError] = useState(false)
  const [fileExtensionError, setFileExtensionError] = useState(false)
  const [fileUploadError, setUploadError] = useState(false)

  const fileErrorReset = () => { setFileSizeError(false); setFileExtensionError(false); setUploadError(false) }

  const handleImageUpload = async (e, file) => {
    e.preventDefault()

    // Reset errors on initial upload to clear out previous
    fileErrorReset()

    const handleFileSizeError = (error) => setFileSizeError(error)
    const handleFileExtensionError = (error) => setFileExtensionError(error)

    const type = uploadType.ARTIFACT_RESOURCE
    const resourceResult = await uploadFileFormatter(file, type, attachments, handleFileSizeError, handleFileExtensionError)

    if (resourceResult) {
      if (resourceResult.newFileMeta && typeof resourceResult.newFileMeta === 'object') {
        const { uploadURL: signedUrl } = await getSignedS3Url({ uploadID: resourceResult.newFileMeta.attachmentID, type: resourceResult.newFileMeta.contentType, userID, token, uploadType: resourceResult.newFileMeta.uploadType })

        if (signedUrl && typeof signedUrl === 'string') {
          // upload direct to s3
          if (file && file.length) {
            const [fileData] = file
            const success = await uploadToSignedS3Url({ file: fileData, signedUrl, type: resourceResult.newFileMeta.contentType })

            if (success) {
              setLEDInfo({ ...ledInfo, attachments: resourceResult.tempArr })
            } else {
              setUploadError(true)
            }
          } else {
            setUploadError(true)
          }
        } else {
          setUploadError(true)
        }
      }
      setAreaEntered(false)
    }
  }

  const handleRemoveAttachment = (attachmentID) => {
    if (!attachmentID) { return false }

    const tempArr = [...attachments]
    const newAttachments = tempArr.filter((attachmentInfo) => attachmentInfo.attachmentID !== attachmentID)
    setLEDInfo({ ...ledInfo, attachments: newAttachments })
    onUpdateEdit(attachmentID, 'attachments')
    fileErrorReset()
    setAreaEntered(false)

    // Prevents popover menu from opening on other attachments after deletion of selected attachment
    handleAttachmentPopClose()
  }

  // Handles renaming the attachment after hitting the save button
  const handleRenameAttachment = () => {
    // Variables that contain error checks
    const inputBlank = attachmentRenameInput === ''
    const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/
    const inputContainsSpecialChars = specialChars.test(attachmentRenameInput)
    const lengthCheck = Boolean(attachmentRenameInput.length > 50)

    // If the input contains any of the errors defined above, return whichever error(s) occured
    if (inputBlank || inputContainsSpecialChars || lengthCheck) {
      return setNameError({ inputBlank, inputContainsSpecialChars, lengthCheck })
    }

    // We get a temporary copy of whatever is in attachments, then search for the index of the newly created attachment that hasn't been saved in the database yet
    const tempArr = [...attachments]
    const attachmentIndex = tempArr.findIndex(x => x.attachmentID === editAttachmentID)

    if (attachmentIndex !== -1) {
      // Remove leading and trailing space and replace middle spaces with an underscore
      const sanitizedName = attachmentRenameInput.trim().replace(/(\s)/g, '_')
      // Adding the file type back to the attachment name in order to display attachment img preview
      tempArr[attachmentIndex].fileName = `${sanitizedName}.${editFileType}`
      setLEDInfo({ ...ledInfo, attachments: tempArr })
    } else {
      // Should not happen
      NotificationToast(true, 'Unable to update attachment')
    }
    handleAttachmentEditorReset()
  }

  const handleUploadEnter = () => { setAreaEntered(true) }

  const handleUploadLeave = () => { setAreaEntered(false) }

  return (
    <Paper className={classes.paperRoot}>
      <Grid container direction='column'>
        {/* Step Header */}
        <Grid container item direction='row' spacing={1} style={{ marginBottom: '1em' }}>
          <Grid item>
            <ModifiedLinkIcon />
          </Grid>
          <Grid item>
            <Typography variant='h4'>Resources</Typography>
          </Grid>
          <Grid item>
            <IconButton size='small' style={{ paddingTop: 0, paddingLeft: 0 }} onClick={() => setExampleOpen(true)}>
              <HelpOutlineRounded style={{ fontSize: '19px', color: theme.palette.purple.darkest }} />
            </IconButton>
          </Grid>
        </Grid>

        <Grid item container direction='column'>
          <Grid item>
            <Typography variant='body1' style={{ fontWeight: '600' }}>Documents</Typography>
            <Typography variant='caption' style={{ color: theme.palette.grey.dark }}>Upload any useful resources for understanding the learning experience design. Examples: workbooks, Presentations, PDFs</Typography>
          </Grid>
          <Grid item container direction='column' style={{ marginTop: '1em' }}>
            {attachments && attachments.length !== 0
              ? (
                // If we have already chosen at least one file:
                <Grid container direction='column' style={{ marginBottom: '2em' }}>
                  <Grid item container direction='row' style={{ marginBottom: '.4em' }}>
                    <Grid item>
                      <AttachFile style={{ fontSize: '14px', transform: 'rotate(45deg)', color: theme.palette.grey.dark, marginRight: '.3em' }} />
                    </Grid>
                    <Grid item>
                      <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: 600 }}>{`${attachments.length} attachment(s)`}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container item direction='row' justifyContent='center' spacing={1} style={{ padding: '.3em .5em', marginBottom: '2em' }}>
                    {/* Document cards */}
                    {attachments.map((attachmentInfo, i) => {
                      const { fileName: attachmentName = '', attachmentID = '' } = attachmentInfo
                      const fileType = attachmentName.slice(attachmentName.lastIndexOf('.') + 1)
                      // Extracting just the name from the attachmentName
                      const currentName = attachmentName.substring(0, attachmentName.indexOf('.'))
                      return (
                        <Grid
                          key={`attachment ${i}`}
                          item
                          container
                          direction='row'
                          xs={5}
                          sm={4}
                          md={3}
                          lg={2}
                          className={classes.documentContainer}
                          style={{
                            marginRight: '.5em',
                            marginBottom: '.4em',
                            border: '2px solid #adadad',
                            borderRadius: '5px'
                          }}
                        >
                          <Grid item container justifyContent='flex-end'>
                            <Tooltip enterDelay={300} placement='top' title='Edit Attachment' arrow>
                              <Settings
                                style={{ cursor: 'pointer', fontSize: '18px', color: theme.palette.grey.dark, margin: '0.2em' }}
                                aria-describedby={attachmentPopoverID}
                                onClick={(e) => handleAttachmentPopover(e, attachmentID, currentName, fileType)}
                              />
                            </Tooltip>
                          </Grid>

                          {/* Attachment options popover */}
                          <Popover
                            id={attachmentPopoverID}
                            open={openAttachmentPopover}
                            anchorEl={attachmentAnchorEl}
                            onClose={handleAttachmentPopClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right'
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right'
                            }}
                          >
                            <Grid container direction='column' style={{ padding: '.3em 1em' }}>
                              <Grid item container justifyContent='flex-start'>
                                <Button
                                  startIcon={
                                    <ModifiedEditIcon color={theme.palette.purple.darkest} />
                                  }
                                  style={{
                                    textTransform: 'none',
                                    color: theme.palette.purple.darkest,
                                    fontWeight: '600'
                                  }}
                                  onClick={() => handleOpenAttachmentEditor()}
                                >
                                  Rename
                                </Button>
                              </Grid>
                              <Grid item container justifyContent='flex-start'>
                                <Button
                                  startIcon={
                                    <DeleteOutlined style={{ color: theme.palette.purple.darkest }} />
                                  }
                                  style={{
                                    textTransform: 'none',
                                    color: theme.palette.purple.darkest,
                                    fontWeight: '600'
                                  }}
                                  onClick={() => handleRemoveAttachment(editAttachmentID)}
                                >
                                  Remove
                                </Button>
                              </Grid>
                            </Grid>
                          </Popover>

                          <Grid container item justifyContent='center' style={{ marginBottom: '.5em' }}>
                            <Tooltip enterDelay={300} placement='top' title={attachmentInfo && attachmentInfo.filePath ? 'View Attachment' : ''} arrow>
                              <Link href={attachmentInfo.filePath} target='_blank' rel='noopener'>
                                {
                                  attachmentInfo.filePath && attachmentInfo.filePath.length && (fileType === 'jpg' || fileType === 'png')
                                    ? (
                                      <>
                                        <img src={attachmentInfo.filePath} style={{ height: '50px' }} alt='attachment' />
                                      </>
                                    ) : (
                                      <>
                                        <DescriptionOutlined style={{ fontSize: '50px', color: theme.palette.purple.darkest }} />
                                      </>
                                    )
                                }
                              </Link>
                            </Tooltip>
                          </Grid>

                          <Grid item container direction='row' justifyContent='flex-start' alignItems='center' style={{ margin: '.2em .3em' }}>
                            <Grid item style={{ paddingTop: '.2em', marginRight: '.2em' }}>
                              <Link href={attachmentInfo.filePath} target='_blank' rel='noopener'>
                                <DescriptionOutlined style={{ fontSize: '15px', color: theme.palette.purple.darkest }} />
                              </Link>
                            </Grid>
                            <Tooltip title={attachmentName}>
                              <Grid item>
                                <Tooltip title={attachmentName && attachmentName.length && attachmentName.length > 10 ? attachmentName : ''}>
                                  <Typography
                                    variant='caption' style={{ color: theme.palette.grey.dark, fontWeight: 600 }}
                                  >
                                    {
                                      attachmentInfo && attachmentInfo.filePath
                                        ? (
                                          <Link href={attachmentInfo.filePath} target='_blank' rel='noopener'>
                                            {attachmentName && attachmentName.length && attachmentName.length > 10 ? `${attachmentName.substring(0, 10)}...` : attachmentName}
                                          </Link>
                                        ) : (
                                          <>
                                            {attachmentName && attachmentName.length && attachmentName.length > 10 ? `${attachmentName.substring(0, 10)}...` : attachmentName}
                                          </>
                                        )
                                    }
                                  </Typography>
                                </Tooltip>
                              </Grid>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      )
                    })}

                    {/* Upload-New card */}
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      md={4}
                      lg={3}
                      style={{
                        border: areaEntered ? '2px dashed #293669' : '2px dashed #adadad',
                        borderRadius: '5px',
                        width: '600',
                        color: 'black',
                        marginBottom: '.4em'
                      }}
                    >
                      <FileDrop
                        onFrameDragEnter={(e) => handleUploadEnter()}
                        onFrameDragLeave={(e) => handleUploadLeave()}
                        onDrop={(files, e) => handleImageUpload(e, files)}
                      >
                        <Grid item container justifyContent='center' style={{ paddingTop: '.4em' }}>
                          <UploadPhoto />
                        </Grid>

                        <Grid item container justifyContent='center'>
                          <Typography variant='h6' style={{ fontWeight: '600', textTransform: 'none' }}>Drag and Drop Here</Typography>
                        </Grid>

                        <Grid item xs={8} style={{ marginTop: '.5rem', marginBottom: '.5rem', marginLeft: 'auto', marginRight: 'auto' }}>
                          <Grid item className={classes.container}>
                            <Grid item className={classes.border} />
                            <Typography variant='h5' className={classes.text}>or</Typography>
                            <Grid item className={classes.border} />
                          </Grid>
                        </Grid>

                        <Grid item container justifyContent='center' style={{ marginBottom: '.4em' }}>
                          <input
                            accept='audio/mp3, audio/wav, image/*, video/*, .ppt, .pptx, .doc, .docx, .pdf, .xls, .xlsx, .pages, .key, .numbers, .txt, .mkv, .avi'
                            style={{ display: 'none' }}
                            id='raised-led-button-file'
                            // multiple
                            type='file'
                            onChange={(e) => handleImageUpload(e, e.target.files)}
                          />
                          <label htmlFor='raised-led-button-file'>
                            <Button
                              variant='outlined'
                              style={{ borderColor: '#29356a', color: '#29356a', fontWeight: '700', padding: '7px 12px' }}
                              component='span'
                            >
                              Browse Files
                            </Button>
                          </label>
                        </Grid>
                        {fileSizeError &&
                          <Grid item container direction='row' justifyContent='center'>
                            <Typography variant='caption' style={{ color: 'red' }}>The selected file is over the max file size.</Typography>
                          </Grid>}

                        {fileExtensionError &&
                          <Grid item container direction='row' justifyContent='center'>
                            <Typography variant='caption' style={{ color: 'red' }}>The selected file type is not allowed.</Typography>
                          </Grid>}

                        {fileUploadError &&
                          <Grid item container direction='row' justifyContent='center'>
                            <Typography variant='caption' style={{ color: 'red' }}>There was an error uploading your file. Please try again.</Typography>
                          </Grid>}

                      </FileDrop>
                    </Grid>

                  </Grid>
                  <Divider />
                </Grid>
              )
              : (
                // If no files have been chosen yet:
                <div>
                  <Grid item container direction='row' justifyContent='center'>
                    <Grid
                      item
                      xs={12}
                      style={{
                        border: areaEntered ? '2px dashed #293669' : '2px dashed #adadad',
                        borderRadius: '5px',
                        width: '600',
                        color: 'black',
                        padding: '3rem'
                      }}
                    >
                      <FileDrop
                        onFrameDragEnter={(e) => handleUploadEnter()}
                        onFrameDragLeave={(e) => handleUploadLeave()}
                        onDrop={(files, e) => handleImageUpload(e, files)}
                      >
                        <Grid item container xs={12} justifyContent='center'>
                          <UploadPhoto />
                        </Grid>

                        <Grid item container xs={12} justifyContent='center' style={{ marginTop: '.3em' }}>
                          <Typography variant='h6' style={{ fontWeight: '600', textTransform: 'none' }}>Drag and Drop Here</Typography>
                        </Grid>

                        <Grid item xs={8} style={{ marginTop: '.5rem', marginBottom: '.5rem', marginLeft: 'auto', marginRight: 'auto' }}>
                          <Grid item className={classes.container}>
                            <Grid item className={classes.border} />
                            <Typography variant='h5' className={classes.text}>or</Typography>
                            <Grid item className={classes.border} />
                          </Grid>
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: '.3em' }}>
                          <input
                            accept='audio/mp3, audio/wav, image/*, video/*, .ppt, .pptx, .doc, .docx, .pdf, .xls, .xlsx, .pages, .key, .numbers, .txt, .mkv, .avi'
                            style={{ display: 'none' }}
                            id='raised-button-file'
                            // multiple
                            type='file'
                            onChange={(e) => handleImageUpload(e, e.target.files)}
                          />
                          <label htmlFor='raised-button-file'>
                            <Grid item container direction='row' justifyContent='center'>
                              <Button
                                variant='outlined'
                                style={{ borderColor: '#29356a', color: '#29356a', fontWeight: '700', padding: '7px 12px' }}
                                component='span'
                              >
                                Browse Files
                              </Button>
                            </Grid>
                          </label>
                        </Grid>
                        {fileSizeError &&
                          <Grid item container direction='row' justifyContent='center'>
                            <Typography variant='caption' style={{ color: 'red' }}>The selected file is over the max file size.</Typography>
                          </Grid>}

                        {fileExtensionError &&
                          <Grid item container direction='row' justifyContent='center'>
                            <Typography variant='caption' style={{ color: 'red' }}>The selected file type is not allowed.</Typography>
                          </Grid>}

                        {fileUploadError &&
                          <Grid item container direction='row' justifyContent='center'>
                            <Typography variant='caption' style={{ color: 'red' }}>There was an error uploading your file. Please try again.</Typography>
                          </Grid>}
                      </FileDrop>
                    </Grid>
                  </Grid>
                </div>
              )}
          </Grid>
        </Grid>

        {/* Links */}
        <Grid item container direction='column' style={{ marginTop: '1em' }}>
          <Grid item container direction='column'>
            <Typography variant='body1' style={{ fontWeight: '600' }}>Links</Typography>
            <Typography variant='caption' style={{ color: theme.palette.grey.dark }}>Add any useful links for understanding the learning experience design. Examples: online lesson inspiration, videos</Typography>
            {linkInputError &&
              <Typography variant='caption' style={{ color: 'red' }}>*Input cannot be blank.</Typography>}
          </Grid>
          {linksList && linksList.length !== 0 &&
            <Grid item container direction='column' style={{ width: '100%', marginTop: '1.5em' }}>
              {linksList.map((linkInfo, i) => {
                const { linkID = '', link: linkName = '' } = linkInfo
                return (
                  <Grid
                    key={`link-number-${linkID}`}
                    item
                    container
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    style={{ padding: '0 1rem' }}
                  >
                    <Grid item xs={11}>
                      <Typography
                        style={{
                          color: theme.palette.purple.darkest,
                          textDecoration: 'underline',
                          textTransform: 'none',
                          fontWeight: 600,
                          justifyContent: 'space-between',
                          padding: '6px 0 6px 8px',
                          wordBreak: 'break-word'
                        }}
                      >
                        {linkName}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Tooltip title='Click to Remove Link'>
                        <IconButton onClick={() => handleRemoveLink(linkID)}>
                          <DeleteOutlined style={{ fontSize: '20px', color: theme.palette.grey.dark }} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>}
          {(linkInputOpen || (linksList && linksList.length === 0)) &&
            <Grid container direction='row' wrap='nowrap'>
              <Grid
                item
                xs={12}
                style={{ paddingRight: '.5em', margin: '.5em 0' }}
              >
                <OutlinedInput
                  fullWidth
                  variant='outlined'
                  margin='dense'
                  value={linkInput}
                  inputProps={{
                    maxLength: 256
                  }}
                  onChange={(e) => handleInputChange(e.target.value)}
                />
                {linkFormatError &&
                  <Typography variant='caption' style={{ color: 'red' }}>*Please enter valid url format.</Typography>}
              </Grid>
              <Grid item style={{ padding: '0', margin: '0' }}>
                <IconButton onClick={handleLinkAdd} disabled={!linkInput && !linksList.length}>
                  <Save style={{ color: checkLinkLength() ? theme.palette.purple.darkest : '' }} />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton onClick={() => { handleInputChange(''); setLinkInputOpen(false) }} disabled={!linkInput && !linksList.length}>
                  <Clear style={{ color: !linkInput && !linksList.length ? theme.palette.purple.darkest : '' }} />
                </IconButton>
              </Grid>
            </Grid>}
          {!linkInputOpen &&
            <Grid item style={{ marginTop: '.5em' }}>
              <Button
                variant='text'
                startIcon={
                  <AddRounded className={classes.addIcon} />
                }
                className={classes.addLinkBtn}
                disabled={!linkInput && !linksList.length}
                classes={{ startIcon: classes.addButtonIcon }}
                onClick={() => setLinkInputOpen(true)}
              >
                Add Link
              </Button>
            </Grid>}
        </Grid>

        {/* Comments */}
        {sectionComments && sectionComments.length !== 0 &&
          <>
            <Divider />
            <Grid item container direction='column' style={{ marginBottom: '2em', marginTop: '1em' }}>
              <Grid item container direction='row' alignContent='center'>
                <Grid item>
                  <Typography variant='body1' style={{ fontWeight: '600' }}>Comments</Typography>
                </Grid>
                <Grid item style={{ marginLeft: '.5em' }}>
                  <Fab style={{ height: '15px', width: '15px', minHeight: '15px', backgroundColor: styles.mainColor, boxShadow: 'none' }}>
                    <Typography variant='body1' style={{ fontSize: '10px', color: 'white', fontWeight: 600 }}>{sectionComments ? sectionComments.length : 0}</Typography>
                  </Fab>
                </Grid>
              </Grid>
              <Grid item container direction='column' spacing={2} style={{ marginTop: '1em' }}>
                {sectionComments && sectionComments.length !== 0 &&
                  sectionComments.map(comment => {
                    return (
                      <ArtifactComment
                        key={comment.commentID}
                        classes={classes}
                        commentInfo={comment}
                        artifactID={comment.artifactID}
                        section='details'
                        styles={styles}
                        fromCreate
                      />
                    )
                  })}
              </Grid>
            </Grid>
          </>}

      </Grid>

      {/* Example Dialog  */}
      <Dialog
        open={exampleOpen}
        onClose={() => setExampleOpen(false)}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle disableTypography>
          <Typography variant='h4' style={{ margin: '.5em' }}>Example: Resources</Typography>
        </DialogTitle>
        <DialogContent
          dividers
        >
          <Grid container direction='column' style={{ margin: '1em' }}>
            <Grid item container direction='column'>
              <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}>Documents</Typography>
              <Typography variant='body1' style={{ fontWeight: '400', marginTop: '.5em', textDecoration: 'underline', color: theme.palette.purple.darkest }}>StudentFellowArtifact.pdf</Typography>
              <Typography variant='body1' style={{ fontWeight: '400', marginTop: '.5em', textDecoration: 'underline', color: theme.palette.purple.darkest }}>HBCU Digital Ad Workbook.pages</Typography>
            </Grid>
            <Grid item container direction='column' style={{ marginTop: '2em' }}>
              <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}>Links</Typography>
              <Typography variant='body1' style={{ fontWeight: '400', marginTop: '.5em', textDecoration: 'underline', color: theme.palette.purple.darkest }}>https://jamboard.google.com/viewer</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='primary'
            style={{
              margin: '.5em 1em',
              fontWeight: '600'
            }}
            onClick={() => setExampleOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Attachment Editor Dialog  */}
      <Dialog
        open={attachmentEditorOpen}
        onClose={() => handleAttachmentEditorReset()}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle disableTypography>
          <Grid container direction='row' justifyContent='space-between' alignItems='center' style={{ margin: '0.5em 0' }}>
            <Grid item>
              <Typography
                variant='h4'
                style={{ textTransform: 'none', color: theme.palette.purple.darkest, fontSize: '1.3rem' }}
              >
                Rename Attachment
              </Typography>
            </Grid>

            <Grid item>
              <IconButton style={{ padding: '0px' }} onClick={() => handleAttachmentEditorReset()}>
                <CloseRounded className={classes.customizedButton} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent
          dividers
          style={{ padding: '19px 40px 0px 10px', overflowX: 'hidden', borderBottom: 'none' }}
        >
          <Grid container direction='column' style={{ margin: '0.5em 1em' }}>
            <Grid item container direction='column' style={{ marginTop: 0 }}>
              <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}>Attachment Name</Typography>
              <OutlinedInput
                margin='dense'
                size='small'
                value={attachmentRenameInput}
                placeholder='Enter new attachment name...'
                classes={{ input: classes.inputPlaceholder }}
                onChange={(e) => setAttachmentRenameInput(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container direction='column'>
            <Grid item container direction='column' alignContent='flex-end'>
              {nameError.inputContainsSpecialChars &&
                <Typography variant='caption' style={{ color: 'red', margin: '0.2em 1em 0' }}>* Name cannot contain special characters. </Typography>}
              {nameError.inputBlank &&
                <Typography variant='caption' style={{ color: 'red', margin: '0.2em 1em 0' }}>* Input cannot be blank. </Typography>}
              {nameError.lengthCheck &&
                <Typography variant='caption' style={{ color: 'red', margin: '0.2em 1em 0' }}>* Input cannot exceed 50 characters. </Typography>}
            </Grid>
            <Grid container item direction='row' justifyContent='flex-end'>
              <Button
                variant='contained'
                color='primary'
                style={{
                  fontWeight: '600',
                  margin: '0.5em 1em 0.8em'
                }}
                onClick={handleRenameAttachment}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}

export default ArtifactResources
