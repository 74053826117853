import React, { useState } from 'react'
import {
  Grid, Typography, Button, useTheme, IconButton, FormControlLabel, Checkbox,
  Paper, Chip, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions
} from '@material-ui/core'
import {
  CheckCircleOutlineRounded, HighlightOffRounded,
  CloseRounded
} from '@material-ui/icons'

import { sanitizedHTML } from '../../../utils/functions'

const defaultBlankMessage = '<p>None</p>'

const BuilderSummarySection = (props) => {
  const { versionForm = {}, classes = {}, setActiveStep = () => {}, handlePublishMCE = () => {} } = props
  const theme = useTheme()
  const {
    title,
    stackName,
    isDraft,
    keyMethod,
    methodComponents,
    supportResearch,
    resources,
    displayP1 = [],
    displayP2 = [],
    displayP3 = []
  } = versionForm

  // section completion boolean checks
  const genInfoComplete = Boolean(keyMethod.length && methodComponents.length && supportResearch.length && resources.length)
  const p1Complete = Boolean(displayP1.length > 0 && displayP1.every(q => q.question.length && q.evalCriteria.length))
  const p2Complete = Boolean(displayP2.length > 0 && displayP2.every(ws => ws.title?.length && ws.title.trim().length && ws.sampleDesc?.length && ws.rubricRows?.length && ws.rubricRows?.every(rr => rr?.proficientText?.length && rr?.basicText.length) && ws.rubricRows?.some(rr => rr.developingText?.length)))
  const p3Complete = Boolean(displayP3.length > 0 && displayP3.every(q => q.question.length && q.evalCriteria.length))

  // button disabled checks
  const saveDraftDisabled = Boolean(!title || !title.length || !title.trim().length || !stackName || !stackName.length || !stackName.trim().length)
  const publishDisabled = Boolean(saveDraftDisabled || !genInfoComplete || !p1Complete || !p2Complete || !p3Complete)

  // determine the display chip properties based on each section's level of completion
  const getCompletionChip = (bool) => {
    const label = bool ? 'Complete' : 'Incomplete'
    const icon = bool ? <CheckCircleOutlineRounded style={{ color: 'white' }} /> : <HighlightOffRounded style={{ color: '#d80505' }} />
    const variant = bool ? 'default' : 'outlined'
    const className = bool ? classes.completeChip : classes.incompleteChip

    return (
      <Chip size='small' variant={variant} label={`Section ${label}`} deleteIcon={icon} onDelete={() => {}} classes={{ deletable: className }} />
    )
  }

  // confirm save modal
  const [saveCofirmOpen, setSaveConfirmOpen] = useState(false)
  const [activeVersionChecked, setActiveVersionChecked] = useState(false)

  const handleSaveConfirmOpen = () => {
    setSaveConfirmOpen(true)
  }

  const handleSaveClose = () => {
    setSaveConfirmOpen(false)
    setActiveVersionChecked(false)
  }

  // publish/save action
  const handleSave = () => {
    const activeBool = isDraft ? true : activeVersionChecked
    handlePublishMCE(isDraft, activeBool)
    handleSaveClose()
  }

  return (
    <Grid item container direction='column'>
      {/* Header */}
      <Grid item container direction='row'>
        <Grid item container direction='column'>
          <Grid item>
            <Typography variant='h5' style={{ lineHeight: 1, fontSize: 18 }}>Credential Summary</Typography>
          </Grid>
          <Grid item>
            <Typography variant='subtitle2'>Review Full Details</Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* Body */}
      <Grid item container direction='column' style={{ marginTop: '.5em' }} spacing={5}>
        {/* Instructional text and save buttons */}
        <Grid item container direction='row' className={classes.summarySectionBlock}>
          <Grid item container direction='row' xs={6}>
            <Typography style={{ width: '80%', wordBreak: 'break-word' }}>Please review the details of the micro-credential you have built below. If all required fields have been completed, you can choose to save. Otherwise, if incomplete, or if you would like to make further changes, please return to the section you would like to modify.</Typography>
          </Grid>
          <Grid item container direction='row' xs={6} justifyContent='flex-end' alignItems='flex-start'>
            {Boolean(isDraft) &&
              <Grid item>
                <Tooltip arrow enterDelay={750} enterNextDelay={750} title={saveDraftDisabled ? 'Must have title and stack to save as draft.' : ''}>
                  <span>
                    <Button
                      disabled={saveDraftDisabled}
                      variant='outlined'
                      color='primary'
                      style={{ borderWidth: '2px', fontWeight: 600, marginRight: '1em' }}
                      onClick={() => handlePublishMCE(false)}
                    >
                      Save Draft
                    </Button>
                  </span>
                </Tooltip>
              </Grid>}
            <Grid item>
              <Tooltip arrow enterDelay={750} enterNextDelay={750} title={publishDisabled ? 'Missing required information.' : ''}>
                <span>
                  <Button
                    variant='contained'
                    disabled={publishDisabled}
                    color='primary'
                    style={{ fontWeight: 600, padding: '6px 2em' }}
                    onClick={() => handleSaveConfirmOpen()}
                  >
                    {isDraft ? 'Publish' : 'Save Version'}
                  </Button>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>

        {/* General Information */}
        <Grid item container direction='column' className={classes.summarySectionBlock} spacing={2}>
          {/* Section header and completion chip */}
          <Grid item container direction='row' justifyContent='space-between'>
            <Grid item container direction='row' xs={8} alignItems='baseline'>
              <Typography variant='h5' className={classes.summarySectionHeader}>General Information</Typography>
              <Button onClick={() => setActiveStep(0)}>
                <Typography variant='caption' style={{ color: theme.palette.purple.darkest, fontWeight: 600, textTransform: 'none', padding: 0, textDecoration: 'underline' }}>Return to Section</Typography>
              </Button>
            </Grid>
            <Grid item container xs={4} justifyContent='flex-end'>
              {getCompletionChip(genInfoComplete)}
            </Grid>
          </Grid>

          <Grid item container direction='row' spacing={4} className={classes.summarySectionContent}>
            <Grid item container xs={6} direction='column' spacing={1}>
              <Grid item container direction='column'>
                <Typography variant='body1' gutterBottom className={classes.summarySectionLabel}>Key Method</Typography>
                <Paper elevation={0} className={classes.summarySectionPaper}>
                  <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(`'${keyMethod || defaultBlankMessage}'`) }} className={classes.dangerHtmlSections} />
                </Paper>
              </Grid>
              <Grid item container direction='column'>
                <Typography variant='body1' gutterBottom className={classes.summarySectionLabel}>Method Components</Typography>
                <Paper elevation={0} className={classes.summarySectionPaper}>
                  <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(`'${methodComponents || defaultBlankMessage}'`) }} className={classes.dangerHtmlSections} />
                </Paper>
              </Grid>

              <Grid item container direction='column'>
                <Typography variant='body1' gutterBottom className={classes.summarySectionLabel}>Resources</Typography>
                <Paper elevation={0} className={classes.summarySectionPaper}>
                  <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(`'${resources || defaultBlankMessage}'`) }} className={classes.dangerHtmlSections} />
                </Paper>
              </Grid>
            </Grid>
            <Grid item container xs={6} direction='column'>
              <Grid item container direction='column'>
                <Typography variant='body1' gutterBottom className={classes.summarySectionLabel}>Support Research</Typography>
                <Paper elevation={0} className={classes.summarySectionPaper}>
                  <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(`'${supportResearch || defaultBlankMessage}'`) }} className={classes.dangerHtmlSections} />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Part 1 Overview */}
        <Grid item container direction='column' className={classes.summarySectionBlock} spacing={2}>
          <Grid item container direction='row' justifyContent='space-between'>
            <Grid item container direction='row' xs={8} alignItems='baseline'>
              <Typography variant='h5' className={classes.summarySectionHeader}>Overview Questions</Typography>
              <Button onClick={() => setActiveStep(1)}>
                <Typography variant='caption' style={{ color: theme.palette.purple.darkest, fontWeight: 600, textTransform: 'none', padding: 0, textDecoration: 'underline' }}>Return to Section</Typography>
              </Button>
            </Grid>
            <Grid item container xs={4} justifyContent='flex-end'>
              {getCompletionChip(p1Complete)}
            </Grid>
          </Grid>

          <Grid item container direction='column' spacing={4} className={classes.summarySectionContent}>
            {!displayP1.length &&
              <Grid item container direction='column' justifyContent='center' alignItems='center' style={{ margin: '2em auto' }}>
                <Grid item>
                  <Typography style={{ fontWeight: 600, color: theme.palette.grey.dark }}>No Questions Have Been Added.</Typography>
                </Grid>
                <Grid item>
                  <Typography style={{ color: theme.palette.grey.dark }}>Please return to this section to add questions.</Typography>
                </Grid>
              </Grid>}
            {displayP1.map((qu, qi) => {
              const { questionID = '', tempID = '', question = '', evalCriteria = '', answerLimit = 400 } = qu
              const apparentOrder = qi + 1
              return (
                <Grid key={`summary-question-P1-${questionID}-${tempID}`} item container direction='column' spacing={1}>
                  <Grid item container direction='row' spacing={3}>
                    <Grid item container direction='column' xs={8}>
                      <Typography variant='body1' gutterBottom className={classes.summarySectionLabel}>{`${apparentOrder}. Question:`}</Typography>
                      <Paper elevation={0} className={classes.summarySectionPaper}>
                        <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(question) }} className={classes.dangerHtmlSections} />
                      </Paper>
                    </Grid>
                    <Grid item container direction='column' xs={4} justifyContent='center'>
                      <Typography variant='body1' gutterBottom className={classes.summarySectionSubLabel}>Answer Limit</Typography>
                      <Chip label={answerLimit} size='small' style={{ backgroundColor: theme.palette.purple.darkest, color: 'white', fontWeight: 600, marginRight: 'auto' }} />
                    </Grid>
                  </Grid>
                  <Grid item container direction='column'>
                    <Typography variant='body1' gutterBottom className={classes.summarySectionSubLabel}>Evaluation Criteria</Typography>
                    <Paper elevation={0} className={classes.summarySectionPaper} style={{ maxWidth: '75%' }}>
                      <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(evalCriteria) }} className={classes.dangerHtmlSections} />
                    </Paper>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        </Grid>

        {/* Part 2 Samples */}
        <Grid item container direction='column' className={classes.summarySectionBlock} spacing={2}>
          <Grid item container direction='row' justifyContent='space-between'>
            <Grid item container direction='row' xs={8} alignItems='baseline'>
              <Typography variant='h5' className={classes.summarySectionHeader}>Work Samples</Typography>
              <Button onClick={() => setActiveStep(2)}>
                <Typography variant='caption' style={{ color: theme.palette.purple.darkest, fontWeight: 600, textTransform: 'none', padding: 0, textDecoration: 'underline' }}>Return to Section</Typography>
              </Button>
            </Grid>
            <Grid item container xs={4} justifyContent='flex-end'>
              {getCompletionChip(p2Complete)}
            </Grid>
          </Grid>

          <Grid item container direction='column' spacing={4} className={classes.summarySectionContent}>
            {!displayP2.length &&
              <Grid item container direction='column' justifyContent='center' alignItems='center' style={{ margin: '2em auto' }}>
                <Grid item>
                  <Typography style={{ fontWeight: 600, color: theme.palette.grey.dark }}>No Work Samples Have Been Added.</Typography>
                </Grid>
                <Grid item>
                  <Typography style={{ color: theme.palette.grey.dark }}>Please return to this section to add samples.</Typography>
                </Grid>
              </Grid>}
            {displayP2.map((ws, wsi) => {
              const { workSampleID = '', tempID: tempWsID = '', title: sampleTitle = '', sampleDesc = '', sampleType = 'L', rubricRows = [] } = ws
              const apparentOrder = wsi + 1
              return (
                <Grid key={`summary-question-P1-${workSampleID}-${tempWsID}`} item container direction='row' spacing={3}>
                  <Grid item container direction='column' xs={6} spacing={2}>
                    <Grid item container direction='row'>
                      <Grid item container direction='column' xs={8}>
                        <Typography variant='body1' gutterBottom className={classes.summarySectionLabel}>{`${apparentOrder}. Sample Title:`}</Typography>
                        <Paper elevation={0} className={classes.summarySectionPaper}>
                          <Typography variant='body1' style={{ color: theme.palette.grey.dark }}>{sampleTitle}</Typography>
                        </Paper>
                      </Grid>
                      <Grid item container direction='column' xs={4} alignItems='center'>
                        <Typography variant='body1' gutterBottom className={classes.summarySectionLabel}>Sample Type</Typography>
                        <Chip label={sampleType === 'L' ? 'Learning Experience Design' : 'Upload'} size='small' style={{ backgroundColor: theme.palette.purple.darkest, color: 'white', fontWeight: 600 }} />
                      </Grid>
                    </Grid>
                    <Grid item container direction='column'>
                      <Typography variant='body1' gutterBottom className={classes.summarySectionLabel}>Sample Description</Typography>
                      <Paper elevation={0} className={classes.summarySectionPaper}>
                        <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(sampleDesc) }} className={classes.dangerHtmlSections} />
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid item container direction='column' xs={6}>
                    <Grid item container direction='column'>
                      <Typography variant='body1' gutterBottom className={classes.summarySectionLabel}>Grading Rubric</Typography>
                      <Paper elevation={0} className={classes.summarySectionPaper}>
                        <Grid item container direction='column' spacing={1}>
                          <Grid item container direction='row' justifyContent='space-around'>
                            <Grid item>
                              <Typography variant='body1' className={classes.summarySectionSubLabel}>Proficient</Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant='body1' className={classes.summarySectionSubLabel}>Basic</Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant='body1' className={classes.summarySectionSubLabel}>Developing</Typography>
                            </Grid>
                          </Grid>
                          {!rubricRows.length &&
                            <Grid item container direction='row' style={{ margin: '.5em' }} justifyContent='center' alignItems='center'>
                              <Typography variant='caption' style={{ color: theme.palette.grey.medium }}>No rows have been added yet.</Typography>
                            </Grid>}

                          {rubricRows.map(r => {
                            const { rubricID = '', tempID: tempRuID = '', proficientText = '', basicText = '', developingText = '' } = r
                            return (
                              <Grid key={`ws-${workSampleID}-${rubricID}-${tempRuID}-rubric-row`} item container direction='row' style={{ marginBottom: '.5em' }} justifyContent='space-around'>
                                <Grid item xs={4} style={{ padding: '0 .5em .5em .5em' }}>
                                  <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(proficientText) }} className={classes.dangerHtmlSections} />
                                </Grid>
                                <Grid item xs={4} style={{ padding: '0 .5em .5em .5em' }}>
                                  <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(basicText) }} className={classes.dangerHtmlSections} />
                                </Grid>
                                <Grid item xs={4} style={{ padding: '0 .5em .5em .5em' }}>
                                  {!developingText || !developingText.length
                                    ? <Typography variant='body1' style={{ color: theme.palette.grey.dark }}>None</Typography>
                                    : <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(developingText) }} className={classes.dangerHtmlSections} />}
                                </Grid>
                              </Grid>
                            )
                          })}
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        </Grid>

        {/* Part 3 Refelection */}
        <Grid item container direction='column' className={classes.summarySectionBlock} spacing={2}>
          <Grid item container direction='row' justifyContent='space-between'>
            <Grid item container direction='row' xs={8} alignItems='baseline'>
              <Typography variant='h5' className={classes.summarySectionHeader}>Reflection Questions</Typography>
              <Button onClick={() => setActiveStep(3)}>
                <Typography variant='caption' style={{ color: theme.palette.purple.darkest, fontWeight: 600, textTransform: 'none', padding: 0, textDecoration: 'underline' }}>Return to Section</Typography>
              </Button>
            </Grid>
            <Grid item container xs={4} justifyContent='flex-end'>
              {getCompletionChip(p3Complete)}
            </Grid>
          </Grid>

          <Grid item container direction='column' spacing={4} className={classes.summarySectionContent}>
            {!displayP3.length &&
              <Grid item container direction='column' justifyContent='center' alignItems='center' style={{ margin: '2em auto' }}>
                <Grid item>
                  <Typography style={{ fontWeight: 600, color: theme.palette.grey.dark }}>No Questions Have Been Added.</Typography>
                </Grid>
                <Grid item>
                  <Typography style={{ color: theme.palette.grey.dark }}>Please return to this section to add questions.</Typography>
                </Grid>
              </Grid>}
            {displayP3.map((qu, qi) => {
              const { questionID = '', tempID = '', answerLimit = 400, question = '', evalCriteria = '' } = qu
              const apparentOrder = qi + 1
              return (
                <Grid key={`summary-question-P3-${questionID}-${tempID}`} item container direction='column' spacing={1}>
                  <Grid item container direction='row' spacing={3}>
                    <Grid item container direction='column' xs={8}>
                      <Typography variant='body1' gutterBottom className={classes.summarySectionLabel}>{`${apparentOrder}. Question:`}</Typography>
                      <Paper elevation={0} className={classes.summarySectionPaper}>
                        <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(question) }} className={classes.dangerHtmlSections} />
                      </Paper>
                    </Grid>
                    <Grid item container direction='column' xs={4} justifyContent='center'>
                      <Typography variant='body1' gutterBottom className={classes.summarySectionSubLabel}>Answer Limit</Typography>
                      <Chip label={answerLimit} size='small' style={{ backgroundColor: theme.palette.purple.darkest, color: 'white', fontWeight: 600, marginRight: 'auto' }} />
                    </Grid>
                  </Grid>
                  <Grid item container direction='column'>
                    <Typography variant='body1' gutterBottom className={classes.summarySectionSubLabel}>Evaluation Criteria</Typography>
                    <Paper elevation={0} className={classes.summarySectionPaper} style={{ maxWidth: '75%' }}>
                      <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(evalCriteria) }} className={classes.dangerHtmlSections} />
                    </Paper>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Grid>

      {/* CONFIRM SAVE DIALOG */}
      <Dialog
        open={saveCofirmOpen}
        onClose={() => { setSaveConfirmOpen(false) }}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>
          <Grid item container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid item xs={11} container direction='row' alignItems='center'>
              <Typography variant='h4' style={{ textTransform: 'none', marginRight: '1em', fontSize: 22 }}>{isDraft ? 'Publish Mircro-Credential' : 'Save New Version'}</Typography>
            </Grid>
            {/* Close Modal Trigger */}
            <Grid item container direction='row' xs={1} justifyContent='flex-end'>
              <IconButton size='small' onClick={() => { handleSaveClose(false) }}>
                <CloseRounded />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid item container direction='column' spacing={1}>
            {isDraft
              ? (
                <Grid item container direction='column'>
                  <Typography style={{ fontSize: 18, color: theme.palette.purple.darkest, fontWeight: 600 }}>Are you ready to publish this Micro Credential?</Typography>
                  <Typography>This action will make it immediately available to pursuers. If you are not ready for for users to attempt this credential yet, please close this modal and choose to save as a draft instead.</Typography>
                </Grid>
              )
              : (
                <>
                  <Grid item container direction='column'>
                    <Typography gutterBottom style={{ fontSize: 18, color: theme.palette.purple.darkest, fontWeight: 600 }}>Are you ready to save the new version of this micro-credential?</Typography>
                    <Typography gutterBottom>This action will create a new record in this micro-credentials history. Choose below if you would like to make these edits immediately available to pursuers as the active version.*</Typography>
                    <Typography>If you do not wish to make these edits visible to new pursuers just yet, you can choose to do so later from the Change Log menu.</Typography>
                  </Grid>
                  <Grid item container direction='row'>
                    <FormControlLabel
                      label={
                        <Typography style={{ fontWeight: 600 }}>Make Active Version?</Typography>
                      }
                      control={<Checkbox />}
                      checked={activeVersionChecked}
                      onClick={() => setActiveVersionChecked(!activeVersionChecked)}
                    />
                  </Grid>
                  <Grid item container direction='column'>
                    <Typography variant='caption'>*Pursuers who are actively attempting this micro-credential will not see any new edits.</Typography>
                  </Grid>
                </>
              )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid item container direction='row' justifyContent='flex-end'>
            <Button style={{ fontWeight: 600, textTransform: 'none' }} onClick={() => { handleSaveClose() }}>Cancel</Button>
            <Button variant='contained' color='primary' style={{ fontWeight: 600 }} onClick={() => { handleSave() }}>{isDraft ? 'Publish' : 'Save'}</Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

export default BuilderSummarySection
