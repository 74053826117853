import React, { } from 'react'
import { SvgIcon } from '@material-ui/core'

const ModifiedLedIcon = (props) => {
  return (
    <SvgIcon viewBox='0 0 68.782 55.026' {...props}>
      <path
        data-name='modified-led-icon'
        id='modified-led-icon'
        d='M10.317,6.878H58.465v37.83h6.878V4.3a4.3,4.3,0,0,0-4.3-4.3H7.738a4.3,4.3,0,0,0-4.3,4.3v40.41h6.878ZM67.063,48.148H51.587V41.269H30.952v6.878H1.72A1.719,1.719,0,0,0,0,49.867v3.439a1.719,1.719,0,0,0,1.72,1.72H67.063a1.719,1.719,0,0,0,1.72-1.72V49.867A1.719,1.719,0,0,0,67.063,48.148Z'
      />
    </SvgIcon>
  )
}

export default ModifiedLedIcon
