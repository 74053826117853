import React, {} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Typography, Button, Chip, useMediaQuery } from '@material-ui/core'

import { setActiveConversationUsers } from '../../../../redux/actions'

const ButtonArea = (props) => {
  const { theme, loadNewView, viewTypes, currentForm, setCurrentForm } = props

  const dispatch = useDispatch()

  const smScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const { drafts = [], conversations = [] } = useSelector(state => state.websocket)

  const handleDraftView = async () => {
    try {
      if (drafts && Boolean(drafts.length)) {
        const match = conversations.find(conversation => conversation.conversationID === drafts[0].conversationID)

        if (match) {
          const draftConversationID = match.conversationID

          // set active conversation to first in draft list, along with user information
          dispatch(setActiveConversationUsers([match.userListDetails[0]]))

          // load correct view and use ID in url params
          loadNewView(viewTypes.DRAFT, draftConversationID)
          if (currentForm === 'message-area') setCurrentForm('conversation-area')
        }
      }
    } catch (err) {
      // add in notification toast
      console.error(err)
    }
  }

  const handleComposeView = () => {
    loadNewView(viewTypes.COMPOSE)

    if (smScreen) setCurrentForm('message-area')
  }

  // position: absolute ?

  return (
    <>
      {/* Action Buttons */}
      <Grid
        item
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginBottom: smScreen ? '1em' : '2em' }}
      >

        {/* NEW MESSAGE */}
        <Grid item>
          <Chip
            style={{
              backgroundColor: theme.palette.purple.darkest
            }}
            onClick={(e) => { e.preventDefault(); handleComposeView() }}
            label={<Typography variant='body1' style={{ fontWeight: 600, color: 'white' }}>New Message</Typography>}
          />

        </Grid>

        {/* DRAFTS */}
        <Grid item>
          <Button
            onClick={(e) => { e.preventDefault(); handleDraftView() }}
            disabled={drafts.length === 0}
          >
            <Typography
              variant='body1'
              style={{
                color: drafts.length === 0 ? theme.palette.grey.medium : theme.palette.purple.darkest,
                fontWeight: 600,
                textTransform: 'none'
              }}
            >
              {`Drafts(${drafts.length})`}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default ButtonArea
