import { trackPromise } from 'react-promise-tracker'
import { parseResponse } from '../../lib'
import {
  GET_STANDARDS_LIST,
  DATA_REQUEST, DATA_SUCCESS, DATA_ERROR
} from '../types'

import log from 'loglevel'

// UI Tech Debt: Removed api variable that wasn't being used anywhere

const api = process.env.REACT_APP_API_URL

export const getStandardsList = () => {
  return async (dispatch, getState) => {
    dispatch({ type: DATA_REQUEST, payload: { type: GET_STANDARDS_LIST } })
    const { token } = getState().auth

    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }

    const response = await trackPromise(window.fetch(`${api}/v1/artifacts/standards`, options))
    const parsedResponse = parseResponse(response, dispatch)
    if (!parsedResponse) { return false }

    if (parsedResponse.error) {
      log.info(response.status)

      dispatch({ type: DATA_ERROR, payload: { type: GET_STANDARDS_LIST } })
    } else {
      const { data } = await response.json()

      dispatch({ type: DATA_SUCCESS, payload: { type: GET_STANDARDS_LIST } })

      dispatch({ type: GET_STANDARDS_LIST, payload: data })
    }
  }
}
