// *** Avatar Display Logic // Used when no avatar path is found ***
// Generate background color using users name
const stringToColor = (string) => {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.substr(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

// Take the users name to generate a background color and display first two letters
const stringAvatar = (name = '', path = false, unreadMessages = false) => {
  return {
    style: {
      backgroundColor: path ? '#efefef' : stringToColor(name),
      boxShadow: unreadMessages ? 'rgb(231 230 230) 1px 2px 2px 2px' : 'none'
    },
    children: name.substring(0, 2)
  }
}

export default stringAvatar
