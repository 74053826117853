import React from 'react'
import moment from 'moment'
import { Grid, Avatar, Typography, Button, useMediaQuery } from '@material-ui/core'
import { LocalLibraryRounded } from '@material-ui/icons'
import { artifactTypes } from '../../../utils'

const SmallChalkboardIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 -10 70 70'
    >
      <path
        className='b'
        d='M19.5,33a2.145,2.145,0,0,0-.662.1A12.367,12.367,0,0,1,15,33.75a12.378,12.378,0,0,1-3.839-.648A2.139,2.139,0,0,0,10.5,33,10.5,10.5,0,0,0,0,43.558,4.488,4.488,0,0,0,4.5,48h21A4.488,4.488,0,0,0,30,43.558,10.5,10.5,0,0,0,19.5,33ZM15,30a9,9,0,1,0-9-9A9,9,0,0,0,15,30ZM55.5,0h-36A4.582,4.582,0,0,0,15,4.649V9a11.864,11.864,0,0,1,6,1.669V6H54V33H48V27H36v6H28.851a11.949,11.949,0,0,1,3.721,6H55.5A4.582,4.582,0,0,0,60,34.351V4.649A4.582,4.582,0,0,0,55.5,0Z'
        transform='translate(0.002)'
        fill='#66666e'
      />
    </svg>
  )
}

const LedDuplicateInfo = (props) => {
  const { duplicate = {}, theme, handleArtifactViewChange = () => { } } = props
  const smScreen = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm))

  const {
    title = '', createdAt = 0, ledGradeName = '',
    profileAvatarPath = '', subjectName = ''
  } = duplicate

  return (
    <Grid item container direction='row' alignItems='center' style={{ marginBottom: '.5em', padding: '0 .5em' }}>
      <Grid item container direction='row' alignItems='center' xs={8} sm={9}>
        <Grid item xs={2} sm={1}>
          <Avatar src={profileAvatarPath} style={{ height: '24px', width: '24px' }} />
        </Grid>
        <Grid item xs={10} sm={4}>
          <Button
            variant='text'
            onClick={(e) => { handleArtifactViewChange(e, artifactTypes.LED, duplicate.ledID, duplicate.profileID) }}
            style={{ padding: 0, fontSize: '16px', fontWeight: 600, textTransform: 'none', color: theme.palette.purple.darkest }}
          >
            {`${title.substring(0, 25)}${title.length > 25 ? '...' : ''}`}
          </Button>
        </Grid>
        {!smScreen &&
          <>
            <Grid item container direction='row' alignItems='center' xs={4}>
              <LocalLibraryRounded style={{ color: theme.palette.grey.dark, fontSize: '16px', paddingRight: '.5em' }} />
              <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: 600 }}>{ledGradeName}</Typography>
            </Grid>
            <Grid item container direction='row' alignItems='center' xs={3}>
              <SmallChalkboardIcon />
              <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: 600, paddingLeft: '.5em' }}>{subjectName}</Typography>
            </Grid>
          </>}
      </Grid>
      <Grid item container justifyContent='flex-end' style={{ paddingRight: '1em' }} xs={4} sm={3}>
        <Typography variant='body1' style={{ color: theme.palette.grey.dark }}>{moment(createdAt * 1000).fromNow()}</Typography>
      </Grid>
    </Grid>
  )
}

export default LedDuplicateInfo
