import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  Grid, Typography, useTheme, Button, OutlinedInput, Table, TableRow, TableSortLabel, TableContainer, TableCell, TableHead,
  TableBody, IconButton, Paper, Select, MenuItem, Dialog, DialogContent, DialogActions, DialogTitle
} from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import {
  AddRounded,
  SearchRounded,
  DeleteOutline
} from '@material-ui/icons'

import { districtsList, createDistrict, updateDistrict, deleteDistrict } from '../../../../redux/actions'

import { NotificationToast, ConfirmToast } from '../../tools'

import { isEqual } from 'lodash'

import States from '../../../../utils/states.json'

const ModifiedEditIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20' height='18.337'
      viewBox='0 0 24 21.337'
    >
      <path
        id='edit-regular_1_'
        data-name='edit-regular (1)'
        d='M16.763,14.3,18.1,12.966a.335.335,0,0,1,.571.238v6.058a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V4.595a2,2,0,0,1,2-2H13.4a.335.335,0,0,1,.238.571L12.3,4.5a.33.33,0,0,1-.238.1H2V19.262H16.667V14.533A.328.328,0,0,1,16.763,14.3Zm6.525-8.409L12.346,16.833l-3.767.417a1.721,1.721,0,0,1-1.9-1.9L7.1,11.583,18.038.641a2.432,2.432,0,0,1,3.446,0l1.8,1.8a2.441,2.441,0,0,1,0,3.45ZM19.171,7.178,16.75,4.757,9.009,12.5l-.3,2.721,2.721-.3Zm2.7-3.321-1.8-1.8a.435.435,0,0,0-.617,0L18.167,3.345l2.421,2.421,1.288-1.288A.444.444,0,0,0,21.871,3.857Z'
        transform='translate(0 0.075)'
        fill='#24890E'
      />
    </svg>
  )
}

const AdminDistricts = (props) => {
  const {
    classes, filter, defaultFilter, setFilter, districts, totalDistricts, activeButton
  } = props
  const theme = useTheme()
  const dispatch = useDispatch()

  useEffect(() => {
    if (filter && defaultFilter && activeButton === 'districts') {
      if (!isEqual(filter, defaultFilter)) {
        dispatch(districtsList(filter))
      }
    }
  }, [dispatch, filter, defaultFilter, activeButton])

  // ********************* New/Edit District logic: ******************** //
  const onOpen = async () => {
    setCreateOpen(true)
  }

  const defaultNewDistrictForm = {
    districtName: '',
    address: '',
    address2: '',
    city: '',
    stateCode: '',
    postalCode: 0
  }
  const [newDistrictForm, setNewDistrictForm] = useState(defaultNewDistrictForm)

  const [createOpen, setCreateOpen] = useState(false)
  const [editID, setEditID] = useState('')

  const handleOpenModal = async (districtInfo) => {
    if (districtInfo) {
      const { districtID, districtName, city, stateCode } = districtInfo
      setEditID(districtID)
      setNewDistrictForm({ ...newDistrictForm, districtName, city, stateCode })
    } else {
      setEditID('')
    }
    onOpen()
  }

  // ******************************** Table Data Logic: ******************************* //

  const createData = (districtID, districtName, city, stateCode) => {
    return { districtID, districtName, city, stateCode }
  }

  // Data rows for the displayed table
  const [rows, setRows] = useState([])

  // Take the districts array and format it into the data rows
  useEffect(() => {
    if (districts) {
      if (districts.length) {
        const newRows = []
        districts.forEach(district => { newRows.push(createData(district.schoolDistrictID, district.districtName, district.city, district.stateCode)) })
        setRows(newRows)
      } else {
        setRows([])
      }
    }
  }, [districts])

  const headCells = [
    { id: 'districtName', label: 'District' },
    { id: 'districtCity', label: 'City' },
    { id: 'districtState', label: 'State' }
  ]

  // ******************************* Pagination logic: ******************************* //
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const handleChangePage = (event, value) => {
    if (value >= 0) {
      setPage(value - 1)
      setFilter({
        ...filter,
        sortCount: rowsPerPage,
        page: value
      })
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: event.target.value
    })
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalDistricts - page * rowsPerPage)

  // **************************** Column Sort Logic ************************ //
  const [orderBy, setOrderBy] = useState('')
  const [order, setOrder] = useState('asc')

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: rowsPerPage,
      sortDirection: isAsc ? 'DESC' : 'ASC',
      sortType: property
    })
  }

  const handleCreateModalClose = async () => {
    setCreateOpen(false)
    setNewDistrictForm(defaultNewDistrictForm)
    setEditID('')
    setPage(0)
    // await dispatch(districtsList())
  }

  // ********************** Save & Edit District Logic *********************** //

  const handleSaveNewDistrict = () => {
    const districtInfo = { ...newDistrictForm }

    if (editID === '') {
      dispatch(createDistrict(districtInfo, fireSaveSuccess, fireSaveFailure))
    } else {
      dispatch(updateDistrict(editID, districtInfo, fireUpdateSuccess, fireUpdateFailure))
    }
  }

  const fireSaveSuccess = () => {
    handleCreateModalClose()
    NotificationToast(false, 'Sucessfully Added District')
    setFilter({ ...filter })
  }

  const fireSaveFailure = () => { NotificationToast(true, 'Failed To Add District') }

  const fireUpdateSuccess = () => {
    handleCreateModalClose()
    NotificationToast(false, 'Sucessfully Updated District')
    setFilter({ ...filter })
  }

  const fireUpdateFailure = () => { NotificationToast(true, 'Failed To Update District') }

  // ********************** Delete District Logic ********************** //

  const fireDeleteSuccess = () => {
    setFilter({ ...filter })
  }

  const fireDeleteFailure = () => {
    NotificationToast(true, 'There was an error deleting the selected district.')
  }

  const handleDeleteDistrict = async (id) => {
    const toastMessage = {
      msg: 'Are you sure you wish to delete this district?',
      txt: 'This action is permanent and cannot be undone.'
    }
    const verifyDelete = await ConfirmToast(toastMessage)

    if (verifyDelete) {
      dispatch(deleteDistrict(id, fireDeleteSuccess, fireDeleteFailure))
    }
  }

  const getStateOptions = () => {
    const items = []
    for (const [key, value] of Object.entries(States)) {
      items.push(<MenuItem id={key} key={key} value={key}>{value}</MenuItem>)
    }
    return items
  }

  // ********************** Search Logic *********************** //
  const [searchInput, setSearchInput] = useState('')

  const handleSearch = (e) => {
    setSearchInput(e.target.value)
    if (searchInput && searchInput.length >= 3) {
      // Previously, issues were occuring if the user was on a page that was greater than 1 and made a search. The page is now being reset beforehand to avoid that.
      setPage(0)
      setFilter({
        ...filter,
        page: 1,
        sortCount: rowsPerPage,
        searchPhrase: searchInput
      })
    }

    if (e.target.value === '') {
      setPage(0)
      // Set the sortCount to current rowsPerPage instead of the default
      setFilter({
        ...defaultFilter,
        sortCount: rowsPerPage
      })
    }
  }

  const isEnabled = newDistrictForm && newDistrictForm.districtName !== '' && newDistrictForm.city !== '' && newDistrictForm.stateCode !== ''

  return (
    <Grid container direction='column'>

      {/* ----------Add District Modal--------- */}
      <Dialog
        open={createOpen}
        onClose={() => handleCreateModalClose()}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle disableTypography>
          <Typography variant='h4' style={{ margin: '.5em' }}>{editID ? 'Edit District' : 'Add District'}</Typography>
        </DialogTitle>
        <DialogContent
          dividers
        >
          <Grid container direction='column' style={{ margin: '1em', paddingRight: '2em' }}>
            {/* DISTRICT NAME */}
            <Grid item container direction='row' style={{ marginTop: 0 }}>
              <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}>District Name <span style={{ color: 'red', fontSize: '14px' }}>*</span></Typography>
              <OutlinedInput
                fullWidth
                margin='dense'
                size='small'
                value={newDistrictForm ? newDistrictForm.districtName : ''}
                onChange={(e) => setNewDistrictForm({ ...newDistrictForm, districtName: e.target.value })}
                placeholder='Enter district name...'
                classes={{ input: classes.inputPlaceholder }}
              />
            </Grid>

            <Grid item container direction='row' justifyContent='space-between' style={{ marginTop: '1rem' }}>
              {/* DISTRICT CITY */}
              <Grid item xs={7}>
                <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}>City <span style={{ color: 'red', fontSize: '14px' }}>*</span></Typography>
                <OutlinedInput
                  fullWidth
                  margin='dense'
                  size='small'
                  value={newDistrictForm ? newDistrictForm.city : ''}
                  onChange={(e) => setNewDistrictForm({ ...newDistrictForm, city: e.target.value })}
                  placeholder='Enter city...'
                  classes={{ input: classes.inputPlaceholder }}
                />
              </Grid>

              {/* DISTRICT STATE */}
              <Grid item xs={4}>
                <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}>State <span style={{ color: 'red', fontSize: '14px' }}>*</span></Typography>
                <Select
                  variant='outlined'
                  size='small'
                  value={newDistrictForm ? newDistrictForm.stateCode : ''}
                  onChange={(e) => setNewDistrictForm({ ...newDistrictForm, stateCode: e.target.value })}
                  classes={{ root: classes.searchInput, selectMenu: classes.statusSelect }}
                  style={{ padding: 0, width: '100%' }}
                >
                  {getStateOptions()}
                </Select>
              </Grid>
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            color='primary'
            style={{
              margin: '.5em 1em',
              fontWeight: '600'
            }}
            onClick={(e) => { e.preventDefault(); handleCreateModalClose() }}
          >
            Cancel
          </Button>

          <Button
            variant='contained'
            color='primary'
            style={{
              margin: '.5em 1em',
              fontWeight: '600'
            }}
            disabled={!isEnabled}
            onClick={(e) => { e.preventDefault(); handleSaveNewDistrict() }}
          >
            Save
          </Button>

        </DialogActions>
      </Dialog>

      {/* ---------------------- Main Component Area ----------------------- */}
      <Grid item container style={{ marginBottom: '2em' }}>
        {/* Page Title */}
        <Grid item container xs={9} justifyContent='flex-start'>
          <Typography variant='h4' style={{ fontSize: '20px' }}>Districts</Typography>
        </Grid>
        <Grid item container xs={3} justifyContent='flex-end'>
          <Button
            color='primary'
            variant='contained'
            style={{ fontWeight: '600' }}
            startIcon={
              <AddRounded className={classes.addIcon} />
            }
            onClick={onOpen}
          >
            Add District
          </Button>
        </Grid>
      </Grid>

      {/* Top Pagination display and search input */}
      <Grid item container direction='row' style={{ marginBottom: '1em', marginTop: '1em' }}>
        <Grid item container alignContent='flex-end' xs={7}>
          <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none', fontWeight: 400, fontSize: '16px' }}>
            Displaying {totalDistricts === 0 ? 0 : (page * rowsPerPage) + 1} to {rows && page * rowsPerPage + rowsPerPage > totalDistricts ? totalDistricts : page * rowsPerPage + rowsPerPage} of {totalDistricts}
          </Typography>
        </Grid>
        <Grid item container justifyContent='flex-end' xs={5}>
          <OutlinedInput
            className={classes.searchInput}
            size='small'
            margin='dense'
            fullWidth
            inputProps={{ style: { border: 'none', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '5px' } }}
            placeholder='Search...'
            classes={{ input: classes.inputPlaceholder }}
            startAdornment={
              <SearchRounded style={{ color: theme.palette.grey.dark, fontSize: '20px' }} />
            }
            value={searchInput}
            onChange={(e) => handleSearch(e)}
          />
        </Grid>
      </Grid>

      {/* Data Table */}
      <Grid item container direction='column' style={{ marginBottom: '1em' }}>
        <TableContainer elevation={0} style={{ border: `solid 1px ${theme.palette.grey.medium}` }} component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: theme.palette.grey.lighter }}>
              <TableRow>
                {headCells.map(header => {
                  return (
                    <TableCell
                      key={header.id}
                      align='left'
                      style={{ padding: '16px' }}
                      sortDirection={orderBy === header.id ? 'asc' : false}
                    >
                      <TableSortLabel
                        active={orderBy === header.id}
                        direction={orderBy === header.id ? order : 'asc'}
                        onClick={() => handleRequestSort(header.id)}
                      >
                        <Typography variant='h5' style={{ color: theme.palette.grey.dark }}>{header.label}</Typography>
                        {orderBy === header.id ? (
                          <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  )
                })}
                <TableCell align='left' style={{ padding: '16px' }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow key={`index-${row.districtID}`}>
                    <TableCell>
                      <Typography variant='body1' style={{ fontWeight: 600 }}>{row.districtName}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body1' style={{ fontWeight: 600 }}>{row.city}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body1' style={{ fontWeight: 600 }}>{row.stateCode}</Typography>
                    </TableCell>
                    <TableCell align='right'>
                      <Grid item container direction='row' justifyContent='flex-end'>
                        <IconButton
                          className={classes.editIcon}
                          onClick={() => handleOpenModal(row)}
                        >
                          <ModifiedEditIcon />
                        </IconButton>
                        <IconButton
                          className={classes.deleteIcon}
                          onClick={() => handleDeleteDistrict(row.districtID)}
                        >
                          <DeleteOutline />
                        </IconButton>
                      </Grid>
                    </TableCell>
                  </TableRow>
                )
              })}
              {/* When the data has reached its length: */}
              {emptyRows > 0 && (
                <TableRow style={{ height: 10 }}>
                  <TableCell colSpan={5}>
                    <Typography variant='body1' style={{ color: theme.palette.grey.medium, textAlign: 'center' }}>End of List</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* Bottom Pagination Controls */}
      <Grid item container direction='row' style={{ marginBottom: '4em' }}>
        {/* Rows Per page selection */}
        <Grid item container direction='row' xs={6} justifyContent='flex-start'>
          <Grid item style={{ marginRight: '.2em', display: 'flex', alignItems: 'flex-end' }}>
            <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>Display</Typography>
          </Grid>
          <Grid item style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Select
              variant='outlined'
              size='small'
              defaultValue={10}
              value={rowsPerPage}
              onChange={(e) => handleChangeRowsPerPage(e)}
              classes={{ root: classes.searchInput, selectMenu: classes.statusSelect }}
              style={{ padding: 0 }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </Grid>
          <Grid item style={{ marginLeft: '.4em', display: 'flex', alignItems: 'flex-end' }}>
            <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>entries</Typography>
          </Grid>
        </Grid>
        {/* Pages/Pagination Display */}
        <Grid item container xs={6} justifyContent='flex-end'>
          <Pagination
            color='primary'
            classes={{ root: classes.pagination }}
            count={totalDistricts ? Math.ceil(totalDistricts / rowsPerPage) : 0}
            page={page === 0 ? 1 : page + 1}
            onChange={handleChangePage}
            shape='rounded'
          />
        </Grid>
      </Grid>

    </Grid>
  )
}

export default AdminDistricts
