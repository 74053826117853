import React, { useState, useRef, useEffect } from 'react'
import queryString from 'query-string'
import { useDispatch, useSelector } from 'react-redux'

import {
  Grid, makeStyles, useTheme, useMediaQuery
} from '@material-ui/core'

import NavBar from '../ui/tools/NavBar'
import ExploreFeaturedResources from '../ui/explore/ExploreFeaturedResources'
import ExploreArtifactTabs from '../ui/explore/ExploreArtifactTabs'

import { getExploreArtifacts, getAboutEditData } from '../../redux/actions'

import { SocialModal } from '../ui/modals'

const useStyles = makeStyles((theme) => ({
  headerBack: {
    background: 'linear-gradient(180deg, rgba(199,86,23,1) 0%, rgba(224,108,34,1) 42%, rgba(230,123,42,1) 100%)'
  },
  exploreFabs: {
    height: '1.75em',
    width: '1.75em',
    backgroundColor: '#FE8B42',
    color: 'white',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#c35e1de0 !important',
      borderRadius: '50%'
    }
  },
  inactiveTab: {
    marginRight: '1em',
    textTransform: 'none',
    fontWeight: 600,
    color: theme.palette.grey.dark,
    fontSize: '18px'
  },
  activeTab: {
    marginRight: '1em',
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '18px',
    borderBottom: `3px solid ${theme.palette.purple.darkest}`
  },
  filterLabel: {
    fontWeight: '600',
    color: theme.palette.grey.dark,
    fontSize: '16px',
    marginRight: '.5em'
  },
  filterSelect: {
    fontSize: '16px',
    fontWeight: '600',
    color: theme.palette.grey.dark,
    fontFamily: 'Source Sans Pro',
    paddingRight: '0px !important'
  },
  sectionNavIcons: {
    height: '1.5em',
    width: '1.5em',
    color: 'white',
    backgroundColor: theme.palette.grey.medium,
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: '#ababab !important',
      borderRadius: '50%'
    }
  },
  bannerImageStyle: {
    height: '9em',
    maxHeight: '9em',
    width: '100%',
    maxWidth: 'inherit',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },
  efrSubHeader: {
    display: 'none'
  },
  regularSubHeader: {
    marginRight: '0',
    marginBottom: '0'
  },
  artCardListItemItem: {
    display: 'flex',
    alignItems: 'stretch',
    overflow: 'visible'
  }
}))

const ExploreView = (props) => {
  const parsedProps = queryString.parse(props.location.search)
  const profileID = parsedProps.user
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()

  // Grades and subjects { id:, name:} used for filtering
  const grades = useSelector(state => state.profileData.grades)
  const subjects = useSelector(state => state.profileData.subjects)

  const likedArtifacts = useSelector(state => state.auth.likedArtifacts)
  const savedArtifacts = useSelector(state => state.auth.savedArtifacts)

  const xsScreenBelow = useMediaQuery(theme.breakpoints.down(375))
  const xsScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const mdScreen = useMediaQuery(theme.breakpoints.down('md'))
  const lgScreen = useMediaQuery(theme.breakpoints.down('lg'))

  // Used for the scroll buttons to navigate between cards
  const edFarmFeaturedRef = useRef(null)
  const subjectsFeaturedRef = useRef(null)
  const newFeaturedRef = useRef(null)

  const scroll = (refType, scrollOffset) => {
    refType.current.scrollLeft += scrollOffset
  }

  // local state for social display
  const [socialType, setSocialType] = useState('')
  const [socialModalOpen, setSocialModalOpen] = useState(false)
  const [socialArtifactInfo, setSocialArtifactInfo] = useState({})

  // Get grades, subjects, standards to format results from artifact details
  useEffect(() => {
    dispatch(getAboutEditData())
  }, [dispatch])

  const handleUserProfileView = (id, callBack) => {
    callBack()
    props.history.push({
      pathname: '/profile',
      search: `?user=${id}`
    })
  }

  const [navRef, setNavRef] = useState(null)

  const navHeight = navRef ? navRef.clientHeight : 0

  return (
    <div style={{ paddingBottom: '4em', backgroundColor: theme.palette.grey.lightest, minHeight: '100vh !important' }}>
      <NavBar location={parsedProps} profileID={profileID} setNavRef={setNavRef} navHeight={navHeight} />

      <Grid container direction='column' style={{ marginTop: '4em', overflow: 'scroll' }}>
        {/* Modal to Display Social Information */}
        <SocialModal
          isOpen={socialModalOpen}
          artifactDetails={socialArtifactInfo}
          actionType={socialType}
          onModalClose={() => { setSocialModalOpen(false); setSocialType(''); setSocialArtifactInfo({}) }}
          handleUserProfileView={handleUserProfileView}
        />

        {/* ---------- Explore Header ---------- */}
        <Grid container item direction='column' className={classes.headerBack}>
          <ExploreFeaturedResources
            classes={classes}
            scroll={scroll}
            edFarmFeaturedRef={edFarmFeaturedRef}
            smScreen={smScreen}
            xsScreen={xsScreen}
            mdScreen={mdScreen}
            lgScreen={lgScreen}
            xsScreenBelow={xsScreenBelow}
          />
        </Grid>

        {/* ------------- Explore Content ------------- */}
        <Grid container item direction='column' style={{ padding: '2.5em 2em' }}>
          <ExploreArtifactTabs
            classes={classes}
            theme={theme}
            grades={grades}
            subjects={subjects}
            savedArtifacts={savedArtifacts}
            likedArtifacts={likedArtifacts}
            xsScreenBelow={xsScreenBelow}
            xsScreen={xsScreen}
            smScreen={smScreen}
            mdScreen={mdScreen}
            lgScreen={lgScreen}
            subjectsFeaturedRef={subjectsFeaturedRef}
            newFeaturedRef={newFeaturedRef}
            scroll={scroll}
            socialModalOpen={socialModalOpen}
            setSocialModalOpen={setSocialModalOpen}
            setSocialType={setSocialType}
            setSocialArtifactInfo={setSocialArtifactInfo}
            getExploreArtifacts={getExploreArtifacts}
          />

        </Grid>
      </Grid>
    </div>
  )
}

export default ExploreView
