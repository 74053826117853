import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { usePromiseTracker } from 'react-promise-tracker'
import MiniArtifactCard from '../MiniArtifactCard'

import {
  Grid, Typography, Paper, ImageList, ImageListItem, useTheme, useMediaQuery,
  Divider, Fab
} from '@material-ui/core'

import { ArtifactComment, PublishButton } from '../../tools'

const SolidChalkboardModifiedIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='26' height='16' viewBox='0 0 68.782 55.026'>
      <path data-name='chalkboard-solid' d='M10.317,6.878H58.465v37.83h6.878V4.3a4.3,4.3,0,0,0-4.3-4.3H7.738a4.3,4.3,0,0,0-4.3,4.3v40.41h6.878ZM67.063,48.148H51.587V41.269H30.952v6.878H1.72A1.719,1.719,0,0,0,0,49.867v3.439a1.719,1.719,0,0,0,1.72,1.72H67.063a1.719,1.719,0,0,0,1.72-1.72V49.867A1.719,1.719,0,0,0,67.063,48.148Z' fill='#66666e' />
    </svg>
  )
}

const ProjectItems = (props) => {
  const {
    classes, selectedLEDs, selectedLWs, setSelectedLWs, setSelectedLEDs,
    styles, targetArtifactComments, currentlyLinkedLEDs, currentlyLinkedLWs,
    isDraft = false, artifactNotCompleteCheck = false, setPublishModalOpen = () => { }
  } = props

  const theme = useTheme()
  const xsScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const { promiseInProgress } = usePromiseTracker()

  const { userLWOptions: lwOptions = [], userLEDOptions: ledOptions = [] } = useSelector(state => state.artifacts)

  const [userLWs, setUserLWs] = useState([])
  const [userLEDs, setUserLEDs] = useState([])
  const [sectionComments, setSectionComments] = useState([])

  useEffect(() => {
    if (targetArtifactComments) {
      if (targetArtifactComments.length) {
        const tempArr = targetArtifactComments.reduce((arr, comment) => {
          if (comment) {
            if (comment.section === 'items') {
              arr.push({ ...comment })
            }
          }
          return arr
        }, [])
        setSectionComments(tempArr)
      }
    }
  }, [targetArtifactComments])

  useMemo(() => { if (lwOptions && Boolean(lwOptions.length)) { setUserLWs(lwOptions) } }, [lwOptions])
  useMemo(() => { if (ledOptions && Boolean(ledOptions.length)) { setUserLEDs(ledOptions) } }, [ledOptions])

  return (
    <Paper className={classes.paperRoot}>
      <Grid container direction='column'>
        {/* Step Header */}
        <Grid container item direction='row' spacing={1} style={{ marginBottom: '1em' }}>
          <Grid item style={{ paddingTop: '8px' }}>
            <SolidChalkboardModifiedIcon />
          </Grid>
          <Grid item>
            <Typography variant='h4'>Project Items</Typography>
          </Grid>
        </Grid>
        <Grid item container style={{ marginBottom: '.5em' }}>
          <Typography variant='body1'>Add learner experience designs and/or learner work examples to your project.</Typography>
        </Grid>

        {/* LED Selection */}
        <Grid item container direction='column' style={{ marginTop: '2em' }}>
          <Grid item container justifyContent='space-between' direction='row'>
            <Grid item>
              <Typography variant='body1' style={{ fontWeight: '600' }}>Learning Experience Designs</Typography>
            </Grid>
            <Grid item>
              <Typography variant='body1'>{`${selectedLEDs && selectedLEDs.length ? selectedLEDs.length : 0} selected`}</Typography>
            </Grid>
          </Grid>
          {/* Card Render */}
          {promiseInProgress
            ? (
              <Typography>Loading</Typography>
            )
            : (
              userLEDs && userLEDs.length
                ? (
                  <ImageList direction='row' style={{ flexWrap: 'nowrap', display: 'flex', flexDirection: 'row', marginTop: '1em', overflowX: 'scroll', width: '100%', scrollBehavior: 'smooth' }} cols={xsScreen ? 1 : 3}>
                    {userLEDs.sort((a, b) => { return currentlyLinkedLEDs.indexOf(b.artifactID) - currentlyLinkedLEDs.indexOf(a.artifactID) }).map(artifact => {
                      return (
                        <ImageListItem key={artifact.artifactID} style={{ height: 'inherit', maxWidth: xsScreen ? '85%' : 'auto', padding: '.5em' }} classes={{ item: classes.artCardListItemItem }}>
                          <MiniArtifactCard
                            type={artifact.artifactType}
                            artifactInfo={artifact}
                            selectedLEDs={selectedLEDs}
                            setSelectedLEDs={setSelectedLEDs}
                            selectedLWs={selectedLWs}
                            setSelectedLWs={setSelectedLWs}
                          />
                        </ImageListItem>
                      )
                    })}
                  </ImageList>
                )
                : (
                  <Typography variant='body1' style={{ color: theme.palette.grey.medium, textAlign: 'center', marginTop: '1em' }}>You have not created any Learning Experience Designs</Typography>
                )
            )}
        </Grid>

        {/* LW Selection */}
        <Grid item container direction='column' style={{ marginTop: '2em' }}>
          <Grid item container justifyContent='space-between' direction='row'>
            <Grid item>
              <Typography variant='body1' style={{ fontWeight: '600' }}>Learner Work</Typography>
            </Grid>
            <Grid item>
              <Typography variant='body1'>{`${selectedLWs && selectedLWs.length ? selectedLWs.length : 0} selected`}</Typography>
            </Grid>
          </Grid>
          {/* Card Render */}
          {userLWs && userLWs.length
            ? (
              <ImageList direction='row' style={{ flexWrap: 'nowrap', display: 'flex', flexDirection: 'row', marginTop: '1em', overflowX: 'scroll', width: '100%', scrollBehavior: 'smooth' }} cols={xsScreen ? 1 : 3}>
                {userLWs.sort((a, b) => { return currentlyLinkedLWs.indexOf(b.artifactID) - currentlyLinkedLWs.indexOf(a.artifactID) }).map(artifact => {
                  return (
                    <ImageListItem key={artifact.artifactID} style={{ height: 'inherit', maxWidth: xsScreen ? '85%' : 'auto', padding: '.5em' }} classes={{ item: classes.artCardListItemItem }}>
                      <MiniArtifactCard
                        type={artifact.artifactType}
                        artifactInfo={artifact}
                        selectedLEDs={selectedLEDs}
                        setSelectedLEDs={setSelectedLEDs}
                        selectedLWs={selectedLWs}
                        setSelectedLWs={setSelectedLWs}
                      />
                    </ImageListItem>
                  )
                })}
              </ImageList>
            )
            : (
              <Typography variant='body1' style={{ color: theme.palette.grey.medium, textAlign: 'center', marginTop: '1em' }}>You have not created any Learner Work artifacts.</Typography>
            )}
        </Grid>

        {/* Comments */}
        {sectionComments && sectionComments.length !== 0 &&
          <>
            <Divider />
            <Grid item container direction='column' style={{ marginBottom: '2em', marginTop: '1em' }}>
              <Grid item container direction='row' alignContent='center'>
                <Grid item>
                  <Typography variant='body1' style={{ fontWeight: '600' }}>Comments</Typography>
                </Grid>
                <Grid item style={{ marginLeft: '.5em' }}>
                  <Fab style={{ height: '15px', width: '15px', minHeight: '15px', backgroundColor: styles.mainColor, boxShadow: 'none' }}>
                    <Typography variant='body1' style={{ fontSize: '10px', color: 'white', fontWeight: 600 }}>{sectionComments ? sectionComments.length : 0}</Typography>
                  </Fab>
                </Grid>
              </Grid>
              <Grid item container direction='column' spacing={2} style={{ marginTop: '1em' }}>
                {sectionComments && sectionComments.length !== 0 &&
                  sectionComments.map(comment => {
                    return (
                      <ArtifactComment
                        key={comment.commentID}
                        classes={classes}
                        commentInfo={comment}
                        artifactID={comment.artifactID}
                        section='items'
                        styles={styles}
                        fromCreate
                      />
                    )
                  })}
              </Grid>
            </Grid>
          </>}

      </Grid>

      {/* Publish Button (only appears while artifact is still a draft) */}
      <PublishButton
        styles={styles}
        theme={theme}
        classes={classes}
        isDraft={isDraft}
        artifactNotCompleteCheck={artifactNotCompleteCheck}
        setPublishModalOpen={setPublishModalOpen}
      />
    </Paper>
  )
}

export default ProjectItems
