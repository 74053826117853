/* eslint no-undef: off */

import React, { createContext, useRef, useState } from 'react'
// import WebSocket from 'ws'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import {
  setMessages, updateConversation, updateDrafts, setActiveSocket
} from '../../../../redux/actions'

import { createGuid, prefixTypes } from './guid'

const websocketBase = process.env.REACT_APP_WEBSOCKET_URL
const WebsocketContext = createContext(null)

// build onto this
const WebsocketErrors = {
  CONNECTION_FAILED: 'CONNECTION_FAILED',
  MESSAGE_SOCKET_MISSING: 'MESSAGE_SOCKET_MISSING',
  MESSAGE_SOCKET_CLOSED: 'MESSAGE_SOCKET_CLOSED',
  MESSAGE_FAILURE: 'MESSAGE_FAILURE',
  CONVERSATION_UPDATE_FAILURE: 'CONVERSATION_UPDATE_FAILURE'
}

const WebsocketActions = {
  CONNECT_SOCKET: 'CONNECT_SOCKET',
  SEND_MESSAGE: 'SEND_MESSAGE',
  CHANGE_CONVERSATION: 'CHANGE_CONVERSATION',
  FETCH_MESSAGES: 'FETCH_MESSAGES',
  SAVE_MESSAGE_DRAFT: 'SAVE_MESSAGE_DRAFT',
  DELETE_MESSAGE_DRAFT: 'DELETE_MESSAGE_DRAT'
}

export { WebsocketContext, WebsocketErrors, WebsocketActions }

const WebsocketProvider = ({ children }) => {
  const dispatch = useDispatch()

  // Websocket Provider Properties
  const clientProperties = {
    socket: null,
    connectSocket: () => { },
    sendMessage: () => { },
    changeConversation: () => { },
    closeConnection: () => { },
    fetchMessages: () => { },
    handleDrafts: () => { }
  }

  const clientPolicy = {
    reconnectionAttempts: 3,
    reconnectionDelay: 2000,
    timeout: 10000
  }

  const [websocketClient, setWebsocketClient] = useState({ ...clientProperties })
  const [currentConversationID, setCurrentConversationID] = useState(null)
  const [connectionAttemptsRemaining, setConnectionAttemptsRemaining] = useState(clientPolicy.reconnectionAttempts)

  const {
    token: wsToken = '', userID: wsUser = '', fullName: wsFullName = ''
  } = useSelector(state => state.auth)

  const socketConnecting = useRef(Boolean(false))

  const socketPromise = (conversationID) => {
    return new Promise((resolve, reject) => {
      const ws = new WebSocket(`${websocketBase}?conversationID=${conversationID}&userID=${wsUser}&auth=${wsToken}`)

      if (ws && typeof ws !== 'undefined') {
        // *** OPEN socket event handler, fires on connection ***
        ws.onopen = (e) => {
          // Check to be sure the socket is fully connected
          if (ws && Boolean(ws.readyState)) { resolve(ws) } else {
            resolve(false)
          }

          // once open, if the socket closure code is not 0, reset it
          // if (socketClosedCode) { dispatch(setSocketClosedCode(0)) }
        }

        // ERROR socket event handler
        ws.onerror = (e) => {
          console.error('Error with websocket:\n', e)
          // Only time we shouldn't have a client is if there's no connected socket
          closeConnection()
          setTimeout(function () {
            attemptConnection(currentConversationID || conversationID)
          }, 1500)
        }

        // CLOSE socket event listener
        ws.addEventListener('close', (e) => {
          // close event-generated code
          const closeCode = e ? e.code : 0
          const closeReason = e?.reason
          console.warn(`Socket Closed, code: ${closeCode}`)
          console.warn(`reason: ${closeReason}`)

          // When socket closes, reset message/socket redux state, and dispatch the code/reason for the closure
          closeConnection()
          // attempt to re-connect the socket
          setTimeout(function () {
            // Allow for intentional disconnect
            if (closeCode && closeCode !== 1005) {
              attemptConnection(currentConversationID || conversationID)
            }
          }, 1500)
        })

        // MESSAGE socket event listener
        ws.addEventListener('message', (e) => {
          // Parse the event information
          if (e && e.data) {
            const { data = {} } = e
            const eventData = JSON.parse(data)

            // If the action type is MESSAGE_FETCH
            if (eventData && eventData.event === 'message_fetch') {
              // Items returned from the event data contain the 50 most recent messages in dynamo
              // This call also removed any 'unread messages' from the SQL database

              // Set these messages to redux
              dispatch(setMessages(eventData))
            }

            if (eventData && eventData.event === 'conversation_change') {
            }

            // If the user is saving a draft, call the update drafts call with the 'save' action type
            if (eventData && eventData.event === 'save_draft') {
              dispatch(updateDrafts(eventData, 'save'))
            }

            // If the user is deleting a draft, call the update drafts call with the 'delete' action type
            if (eventData && eventData.event === 'delete_draft') {
              dispatch(updateDrafts(eventData, 'delete'))
            }

            if (eventData && eventData.event === 'channel_message') {
              const { content: Content = '', conversationID = '', name: Name = '', event = 'channel_message', userID = '', messageID = '', createdAt = 0 } = eventData
              const payload = { Content, conversationID, Name, event, userID, messageID, createdAt }

              dispatch(updateConversation(payload))
            }
          }
        })
      } else {
        console.error('no websocket object')
        resolve(false)
      }
    })
  }

  const establishConnection = async (conversationID, fireSuccess = () => { }, fireFailure = () => { }) => {
    try {
      console.info('Starting connection to socket:', conversationID)

      // If we are not currently connecting a socket
      if (!socketConnecting.current) {
        // Set socket connecting to prevent multiple connection attempts
        socketConnecting.current = true

        // don't instantiate more than one socket connection
        if (websocketClient.socket && websocketClient.socket.readyState && websocketClient.socket.readyState === 1) {
          // If we already have a connection but the closure code is not 0, reset it
          console.log('already had a connection')
          socketConnecting.current = false
          return true
        }

        // Create a new connection
        const newSocket = await socketPromise(conversationID)

        // Be sure we let the local ref know we are done loading our socket connection
        socketConnecting.current = false

        if (newSocket) {
          clientProperties.socket = newSocket; setWebsocketClient({ ...websocketClient, socket: newSocket })

          fireSuccess()
          return newSocket
        } else {
          console.warn('the socket was unable to establish a connection. exiting now')
          return false
        }
      } else {
        console.warn('there is an ongoing connection attempt in progress. exiting the current loop')
        return false
      }
    } catch (err) {
      console.error('Error connecting to socket:\n', JSON.stringify(err, null, 2))
      fireFailure(WebsocketErrors.CONNECTION_FAILED)
      return false
    }
  }

  const attemptConnection = async (conversationID) => {
    let isConnected = websocketClient.socket !== null && websocketClient.socket.readyState && websocketClient.socket.readyState === 1
    // Close out the socket and restart it if there is no connection in state. We can get granular with this though
    if (!isConnected) { console.warn('clearing out current connection before attempting action'); closeConnection() }

    if (conversationID) {
      console.log('applying connection attempt')
      console.log('attempts remaining: ' + connectionAttemptsRemaining)
      console.log('connected: ' + isConnected)
      const socket = await establishConnection(conversationID, () => { }, () => { })
        .catch(err => { console.error(err); setConnectionAttemptsRemaining(connectionAttemptsRemaining - 1); return false })
      if (socket) {
        // break loop
        console.log('breaking from loop to continue with actions')
        isConnected = true
        setConnectionAttemptsRemaining(clientPolicy.reconnectionAttempts)
        // continue
      } else {
        // return, decrement attempts
        console.log('continuing loop with retry policy in effect')
        setConnectionAttemptsRemaining(connectionAttemptsRemaining - 1)
        await new Promise(resolve => setTimeout(resolve, clientPolicy.reconnectionDelay))
        // continue
      }
      // }
    } else {
      console.warn('did not receive a valid conversationID')
      return false
    }

    // we haven't connected in our allotted amount of attempts
    if (!isConnected && !connectionAttemptsRemaining) {
      console.warn('Out of retry policies. Exiting connection attempt')
      return false
    } else {
      // return valid connection status
      return true
    }
  }

  // runs the intended action through the websocket connection if one exists; otherwise, runs through the retry policy, then attempts to execute
  const runPolicy = async (conversationID, policyAction, policyPayload) => {
    setCurrentConversationID(conversationID)
    // Close out the socket and restart it if there is no connection in state. We can get granular with this though
    const validConnection = await attemptConnection(conversationID)
      .catch(err => { console.error(err); return false })
    if (!validConnection) { console.error('could not establish a valid connection to the websocket server'); return false }

    switch (policyAction) {
      case WebsocketActions.CONNECT_SOCKET: { break }
      case WebsocketActions.SEND_MESSAGE: {
        const {
          messageID, message, allRecipients, conversationID: payloadID,
          fireSuccess = () => { }, fireFailure = () => { }
        } = policyPayload

        const payload = {
          messageID,
          action: 'sendMessage',
          content: message,
          conversationID: payloadID,
          userID: wsUser,
          recipients: allRecipients,
          name: wsFullName
        }
        if (!websocketClient.socket) { return false }
        websocketClient.socket.send(JSON.stringify(payload))

        updateLog(payload, fireSuccess, fireFailure)
        break
      }
      case WebsocketActions.SAVE_MESSAGE_DRAFT: {
        const {
          message, conversationID: payloadID
        } = policyPayload

        const payload = {
          action: 'saveDraft',
          content: message,
          conversationID: payloadID,
          userID: wsUser,
          name: wsFullName
        }
        if (!websocketClient.socket) { return false }
        websocketClient.socket.send(JSON.stringify(payload))
        break
      }
      case WebsocketActions.DELETE_MESSAGE_DRAFT: {
        const {
          conversationID: payloadID
        } = policyPayload
        console.log(conversationID)

        const payload = {
          action: 'deleteDraft',
          conversationID: payloadID,
          userID: wsUser
        }
        if (!websocketClient.socket) { return false }
        websocketClient.socket.send(JSON.stringify(payload))
        break
      }
      case WebsocketActions.FETCH_MESSAGES: {
        const payload = {
          ...policyPayload,
          action: 'loadHistory'
        }
        if (!websocketClient.socket) { return false }
        // trigger the message socket handler for 'message_fetch'
        websocketClient.socket.send(JSON.stringify(payload))
        break
      }
      case WebsocketActions.CHANGE_CONVERSATION: {
        const { conversationID: payloadID } = policyPayload
        const payload = {
          action: 'subscribeChannel',
          content: { payloadID }
        }
        if (!websocketClient.socket) { return false }
        websocketClient.socket.send(JSON.stringify(payload))
        break
      }
      default: break
    }
  }

  // *** Trigger socket close
  const closeConnection = () => {
    socketConnecting.current = false
    // If redux state still active change it to false
    // closes an open websocket connection
    if (!websocketClient.socket) {
      console.warn('Did not detect an active connection when attempting to close')
    } else {
      websocketClient.socket.close() // emit('sendMessage', JSON.stringify(payload))
      setWebsocketClient({ ...websocketClient, socket: null })
    }
    // reset active socket and trigger a reconnect
    dispatch(setActiveSocket({}))
  }

  const connectSocket = async (conversationID, fireSuccess = () => { }, fireFailure = () => { }) => {
    try {
      await runPolicy(conversationID, WebsocketActions.CONNECT_SOCKET, { fireSuccess, fireFailure })
      return true
    } catch (err) {
      console.error(err)
      fireFailure(WebsocketErrors.CONNECTION_FAILED)
    }
  }

  // Trigger socket message send
  const sendMessage = (conversationID, message, allRecipients, fireSuccess = () => { }, fireFailure = () => { }) => {
    try {
      const messageID = createGuid(prefixTypes.MESSAGES)
      runPolicy(
        conversationID, WebsocketActions.SEND_MESSAGE,
        { messageID, conversationID, message, allRecipients, fireSuccess, fireFailure }
      )
    } catch (err) {
      console.error(err)
      fireFailure(WebsocketErrors.MESSAGE_FAILURE)
    }
  }

  // Save or Delete Messages for a user based on the saveDraftBool
  const handleDrafts = (conversationID, message) => {
    try {
      if (message) {
        // If the message provided has length, save a draft
        if (message.length) {
          runPolicy(
            conversationID, WebsocketActions.SAVE_MESSAGE_DRAFT,
            { conversationID, message }
          )
        } else {
          // if not, the user either sent the message or deleted their input, so delete draft instead
          runPolicy(
            conversationID, WebsocketActions.DELETE_MESSAGE_DRAFT,
            { conversationID }
          )
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const updateLog = (payload, fireSuccess = () => { }, fireFailure = () => { }) => {
    fireSuccess()

    const { content: Content = '', name: Name = '' } = payload
    const createdAt = moment().unix()

    dispatch(
      updateConversation(
        { ...payload, Content: JSON.stringify(Content), Name, createdAt, deletedAt: 0, isLocal: true },
        () => { },
        () => fireFailure(WebsocketErrors.CONVERSATION_UPDATE_FAILURE)
      )
    )
  }

  // Trigger dynamo message fetch
  const fetchMessages = (conversationID, userID, LastEvaluatedKey) => {
    try {
      runPolicy(conversationID, WebsocketActions.FETCH_MESSAGES, { conversationID, userID, LastEvaluatedKey })
    } catch (err) {
      console.error(err)
    }
  }

  const changeConversation = (conversationID) => {
    try {
      runPolicy(conversationID, WebsocketActions.CHANGE_CONVERSATION, { conversationID })
    } catch (err) {
      console.error(err)
    }
  }

  if (!clientProperties.socket) {
    websocketClient.connectSocket = connectSocket
    websocketClient.sendMessage = sendMessage
    websocketClient.changeConversation = changeConversation
    websocketClient.closeConnection = closeConnection
    websocketClient.fetchMessages = fetchMessages
    websocketClient.handleDrafts = handleDrafts
  }

  return (
    <WebsocketContext.Provider value={websocketClient}>
      {children}
    </WebsocketContext.Provider>
  )
}

export default WebsocketProvider
