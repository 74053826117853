import React, { useCallback, useRef, useState, useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Editable, withReact, useSlate, Slate, ReactEditor } from 'slate-react'
import {
  Editor,
  createEditor,
  Transforms
} from 'slate'

import { IconButton, ButtonGroup, Grid } from '@material-ui/core'
import { FormatBold, FormatItalic, FormatUnderlined, SendRounded, LinkRounded } from '@material-ui/icons'
import { NotificationToast } from '../../tools'

import log from '../../../../utils/logger'

const initialValue = [
  {
    type: 'paragraph',
    children: [
      { text: '' }
    ]
  }
]

let draftTimer

// SLATE DOCS
// --- ReactEditor Focus => https://docs.slatejs.org/libraries/slate-react#focus-editor-reacteditor
// --- Transforms => https://docs.slatejs.org/concepts/04-transforms

const SlateEditor = (props) => {
  const {
    activeConversationID, view, theme, onSendMessage = () => { }, setMessageError = () => { },
    offlineStatus = false, messageError, onHandleDrafts = () => { }, smScreen = false, editorOpen = false,
    conversationInactive = false
  } = props

  const { drafts = [] } = useSelector(state => state.websocket)

  const [value, setValue] = useState([...initialValue])

  const [inputFocused, setInputFocused] = useState(false)
  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])

  const editorRef = useRef()
  useMemo(() => {
    if (!editorRef.current) editorRef.current = withReact(createEditor())
  }, [])

  const editor = editorRef.current

  const disableButtons = Boolean(conversationInactive || (view && view === 'compose') || offlineStatus || (editor && !editor.selection) || !inputFocused)

  // conversation ID tied with editor value
  // --- if the active conversation ID is different, the useMemo bellow will clear the local value state, then set this ref to the active conversation ID
  const editorConversationID = useRef(activeConversationID)

  const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
      Editor.removeMark(editor, format)
    } else {
      const selection = Editor.string(editor, editor.selection)
      if (selection === '' && format === 'link') {
        // this won't work well; warn the user
        NotificationToast(true, 'You must select text in order to use the link button.', true)
        return false
      }
      Editor.addMark(editor, format, true)
    }
  }

  const isMarkActive = (editor, format) => {
    if (!editor || !format) {
      return false
    }
    const marks = Editor.marks(editor)

    return marks ? marks[format] === true : false
  }

  const Element = ({ attributes, children, element }) => {
    if (attributes && children && children.length) {
      return <p {...attributes}>{children}</p>
    }
  }

  const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.placeholder) {
      // We can access the placeholder from here, currently this
      leaf.bold = false
      leaf.italic = false
      leaf.underline = false
      leaf.link = false
    }

    if (leaf.bold) {
      children = <strong>{children}</strong>
    }

    if (leaf.italic) {
      children = <em>{children}</em>
    }

    if (leaf.underline) {
      children = <u>{children}</u>
    }

    if (leaf.link) {
      children = <a href={children}>{children}</a>
    }

    return <span {...attributes}>{children}</span>
  }

  const MarkButton = ({ format, icon }) => {
    const editor = useSlate()
    return (
      <IconButton
        size='small'
        disabled={disableButtons}
        style={{ backgroundColor: isMarkActive(editor, format) ? theme.palette.grey.medium : 'transparent' }}
        onMouseDown={event => {
          event.preventDefault()
          toggleMark(editor, format)
        }}
      >
        {icon}
      </IconButton>
    )
  }

  const clearErrors = useCallback(() => {
    if (messageError) { setMessageError(false) }
  }, [messageError, setMessageError])

  const clearEditor = useCallback(() => {
    try {
      if (JSON.stringify(value) !== JSON.stringify(initialValue)) {
        // If we are sending a message be sure to clear the draft timeout
        if (draftTimer) {
          clearTimeout(draftTimer)
        }
        const startPoint = { path: [0, 0], offset: 0 }
        editor.selection = { anchor: startPoint, focus: startPoint } // clean up selection
        editor.history = { redos: [], undos: [] } // clean up history
        setValue([...initialValue]) // reset state
        Transforms.delete(editor, { at: { anchor: Editor.start(editor, []), focus: Editor.end(editor, []) } }) // delete from the startpoint to the endpoint of the editor

        // remove styling marks
        Editor.removeMark(editor, 'bold'); Editor.removeMark(editor, 'italic')
        Editor.removeMark(editor, 'underline'); Editor.removeMark(editor, 'link')
      }
    } catch (err) {
      console.error(err)
    }
  }, [value, editor])

  const resetEditor = () => {
    try {
      if (messageError) { setMessageError(false) }

      // If we are sending a message be sure to clear the draft timeout
      if (draftTimer) {
        clearTimeout(draftTimer)
      }

      // default start value of the editor
      if (JSON.stringify(value) !== JSON.stringify(initialValue)) {
        const startPoint = { path: [0, 0], offset: 0 }
        editor.selection = { anchor: startPoint, focus: startPoint } // clean up selection
        editor.history = { redos: [], undos: [] } // clean up history
        setValue([...initialValue]) // reset state
        Transforms.delete(editor, { at: { anchor: Editor.start(editor, []), focus: Editor.end(editor, []) } }) // delete from the startpoint to the endpoint of the editor

        // remove styling marks
        Editor.removeMark(editor, 'bold'); Editor.removeMark(editor, 'italic')
        Editor.removeMark(editor, 'underline'); Editor.removeMark(editor, 'link')
      }
    } catch (err) {
      // This means the algorithm did not work :/
      console.error(err)
    }
  }

  // Input of Slate Editor Change
  const handleValueChange = (value) => {
    if (value) {
      const selection = Editor.string(editor, editor.selection)
      if (!selection || selection === '') {
        const isActive = isMarkActive(editor, 'link')
        if (isActive) {
          Editor.removeMark(editor, 'link')
        }
      }
    }
    setValue(value)
  }

  // sends message if "Enter" btn pressed, inserts line break if "Shift" + "Enter" pressed simultaneously
  // clear the draft timeout while the user is actively typing
  const handleKeyDown = (e, value, resetEditor) => {
    if (inputFocused && !e.shiftKey && e.key === 'Enter') {
      e.preventDefault()
      handleSendMessage(value, resetEditor)
    }
    if (draftTimer) {
      clearTimeout(draftTimer)
    }
  }

  // Check to see if the user has a valid input value
  const checkStringValue = () => {
    const tempContent = [...value]

    if (tempContent && tempContent.length) {
      // Check if text content for each child only contains whitespace
      const containsOnlyWhitespace = tempContent.every((content) => {
        const checkText = content.children.every((child) => { return /^\s*$/.test(child.text) })
        return checkText
      })

      // Return false if there is no content and only whitespace from above check
      if (containsOnlyWhitespace) { return false }

      const evaluatedContent = tempContent.reduce((arr, textValue = {}) => {
        const { children = [] } = textValue

        // Checks to make sure that every single children has been evaluated for empty content
        const childText = children.reduce((childArr, childInfo) => {
          if (childInfo && Boolean(childInfo.text)) { childArr.push(childInfo.text) }

          return childArr
        }, []).join('')

        if (typeof childText === 'string' && Boolean(childText)) { arr.push(textValue) }

        return arr
      }, [])

      return evaluatedContent
    }
  }

  // On key up event, check to see if we have value and fire a timout to save draft
  // This timeout is cleared if they resume typing within 5 seconds
  const handleKeyUp = () => {
    const hasValue = checkStringValue()

    clearTimeout(draftTimer)

    if (hasValue) {
      if (hasValue.length) {
        setValue([...hasValue])

        draftTimer = setTimeout(() => {
          if (hasValue) {
            onHandleDrafts(hasValue)
          }
        }, 5000)
      } else {
        onHandleDrafts(hasValue)
      }
    } else {
      onHandleDrafts([])
    }
  }

  const handleSendMessage = (value, resetEditor = () => { }) => {
    // Only accept line breaks if text follows
    const evaluatedContent = checkStringValue()

    // check if msg is empty; prevent sending and show warning if so
    if (evaluatedContent && Boolean(evaluatedContent.length)) {
      // Redefine value with empties taken out
      setValue([...evaluatedContent])

      onSendMessage(evaluatedContent, resetEditor)
      onHandleDrafts([])
    } else {
      NotificationToast(true, 'Messages cannot be empty.', true)
    }
  }

  // reset the editor when the active conversation ID has changed
  useMemo(() => {
    if (activeConversationID && activeConversationID !== null && activeConversationID !== editorConversationID.current) {
      clearErrors()
      clearEditor()

      editorConversationID.current = activeConversationID
    }
  }, [activeConversationID, editorConversationID, clearErrors, clearEditor])

  // check through draft conversation list to find matching ID with active conversation
  const checkDrafts = useCallback(() => {
    const conversationDraft = drafts.find((draft) => draft.conversationID === activeConversationID)

    // if a matching draft conversation is found, parse the content and set it to the value and editor
    if (conversationDraft) {
      const parsedDraftValue = JSON.parse(conversationDraft.Content)

      if (parsedDraftValue) { setValue(parsedDraftValue); editor.children = parsedDraftValue }
    }
  }, [drafts, activeConversationID, editor])

  // if draft list exists and has length, check if active conversation ID matches any in list, parse draftValue if true, then set that to editor value
  useMemo(() => {
    if (activeConversationID && activeConversationID !== null && activeConversationID === editorConversationID.current && drafts && drafts.length && drafts.length > 0) {
      checkDrafts()
    }
  }, [activeConversationID, drafts, checkDrafts])

  // Clean up state items on convey unmount
  useEffect(() => {
    // Reset to default values
    const handleCleanUp = () => {
      setValue([...initialValue])
      setInputFocused(false)

      // Clear the draft timer when component is unmounted:
      if (draftTimer) {
        clearTimeout(draftTimer)
      }
    }

    return () => handleCleanUp()
  }, [])

  // Chris 2/24/22
  // Handle focusing the slate editor on mobile sized screens when it is opened
  useMemo(() => {
    // Only trigger when we have the editor, and we are on small screens, and editor open state is true
    if (editor && smScreen && editorOpen) {
      // Check if editor is focused
      const isFocused = ReactEditor.isFocused(editor)

      try {
        if (!isFocused) {
          // Check if editor selection is null before setting anchor and focus points
          if (!editor.selection) {
            // Check if conversation has draft
            const conversationDraft = drafts.find((draft) => draft.conversationID === activeConversationID)

            if (!conversationDraft) {
              // Set anchor and focus to start if no draft is present for conversation
              const startPoint = { path: [0, 0], offset: 0 }
              const target = { anchor: startPoint, focus: startPoint }

              Transforms.select(editor, target)
            } else {
              // Set anchor and focus, then move to last word if draft is present for conversation
              Transforms.select(editor, Editor.end(editor, []))
              Transforms.move(editor, { distance: 'last', unit: 'word' })
            }

            // If for some reason the editor has a selection, copy and select
          } else {
            // Restore selection
            const previousSelection = Object.assign({}, editor.selection)

            Transforms.select(editor, previousSelection)
          }

          // Chris 2/24/22 --- Previously manually inserting nodes for editor selection, but this shouldn't be necessary
          // Transforms.insertNodes(editor, { children: [{ text: '' }] })

          // Set input focused state to true
          setInputFocused(true)

          // Access function from react  to focus editor instance
          ReactEditor.focus(editor)
        } else {
          // Move selection on already focused editor
          Transforms.move(editor, { unit: 'line', edge: 'focus' })
        }
      } catch (err) {
        log.error(err)
        return err
      }
    }
  }, [editor, editorOpen, smScreen, setInputFocused, activeConversationID, drafts])

  return (
    <Grid item container direction='column' style={{ width: '100%', justify: 'end', alignItems: 'stretch', position: 'relative' }}>
      <Slate editor={editor} value={value} onChange={(value) => handleValueChange(value)}>
        <Editable
          renderElement={renderElement}
          readOnly={conversationInactive}
          autoFocus
          onBlur={(e) => e.preventDefault()}
          onClick={() => setInputFocused(true)}
          onKeyDown={(e, editor, next) => handleKeyDown(e, value, resetEditor)}
          onKeyUp={handleKeyUp}
          renderLeaf={renderLeaf}
          placeholder={conversationInactive ? 'This message thread is no longer active.' : 'Type your message here...'}
          style={{ height: '22vh', maxHeight: '25vh !important', overflowY: 'auto', maxWidth: '100%' }}
        />
        <Grid item container direction='row' justifyContent='space-between' alignItems='center' style={{ backgroundColor: theme.palette.grey.lightest, position: 'sticky', bottom: 0 }}>
          <ButtonGroup style={{ display: 'block' }}>
            <MarkButton format='bold' icon={<FormatBold fontSize='small' />} />
            <MarkButton format='italic' icon={<FormatItalic fontSize='small' />} />
            <MarkButton format='underline' icon={<FormatUnderlined fontSize='small' />} />
            <MarkButton format='link' icon={<LinkRounded fontSize='small' />} />
          </ButtonGroup>
          <IconButton
            disabled={disableButtons}
            onClick={() => handleSendMessage(value, resetEditor)}
          >
            <SendRounded fontSize='small' style={{ color: disableButtons ? theme.palette.grey.medium : theme.palette.pink.dark }} />
          </IconButton>
        </Grid>
      </Slate>
    </Grid>
  )
}

export default SlateEditor
