import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Router, Route, Redirect, Switch } from 'react-router-dom'

import { ThemeProvider } from '@material-ui/core/styles'
import theme from '../ui/styles/MuiTheme'
import queryString from 'query-string'
import { debounce } from 'lodash'

// Component Views
import Login from '../views/Login'
import Profile from '../views/Profile'
import OnboardingP1 from '../views/Onboard1'
import AdminView from '../views/Admin'
import Validation from '../views/Validation'
import AwaitingApproval from '../views/AwaitingApproval'
import NotFound from '../views/NotFound'
import PasswordReset from '../views/PasswordReset'
import CheckEmail from '../views/CheckEmail'
import ArtifactView from '../views/ArtifactView'
import NotificationsView from '../views/Notifications'
import ExploreView from '../views/ExploreView'
import MessageInbox from '../views/MessageInbox'
import CredentialView from '../views/CredentialView'
import CredentialDashboard from '../views/CredentialDashboard'
import MCEBuilderView from '../views/MCEBuilderView'
import PageUnavailable from '../views/PageUnavailable'

// local Tools and utils
import { History, pageUnavailableTypes } from '../../utils'
import LoadingIndicator from '../ui/tools/LoadingIndicator'
import { LoginToast } from '../ui/tools/Toasts'
import log from '../../utils/logger'

import { removeErrors, logout } from '../../redux/actions/auth'

import LogRocket from 'logrocket'

const Routes = props => {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const authObject = useSelector(state => state.auth)

  useEffect(() => {
    // Will listen for a user to logout and will fire an event accross browser tabs to logout those as well
    /* NOTES:
      1) this will logout any user open in a tab, not just the one that chose to log out
      2) this does not apply across browsers, just single browser tabs
    */

    const crossTabLogoutAction = (e) => {
      if (e.oldValue && e.newValue) {
        const oldValue = JSON.parse(e.oldValue)
        const oldToken = oldValue.token ? JSON.parse(oldValue.token) : ''

        const newValue = JSON.parse(e.newValue)
        const newToken = newValue.token ? JSON.parse(newValue.token) : ''

        if (e.key && e.key === 'persist:auth') {
          if (newToken === '' && newToken !== oldToken) {
            dispatch(logout())
          }
        }
      }
    }

    const debounceCrossTabLogout = debounce(crossTabLogoutAction, 1000)

    window.addEventListener('storage', debounceCrossTabLogout)
    return () => {
      window.removeEventListener('storage', debounceCrossTabLogout)
    }
  }, [dispatch])

  useEffect(() => {
    if (authObject.error) {
      (LoginToast())
      dispatch(removeErrors())
    }
  }, [authObject.error, dispatch])

  useEffect(() => {
    // Allow LogRocket to mark the user's session if the user exists
    if (authObject.fullName && authObject.emailAddress) {
      if (process.env.REACT_APP_LOGROCKET_ID) {
        LogRocket.identify(process.env.REACT_APP_LOGROCKET_ID, {
          name: authObject.fullName,
          email: authObject.emailAddress
        })
      }
    }
  }, [authObject])

  const onboardingStep = useSelector(state => state.auth.onboardingStep)
  const profileID = useSelector(state => state.auth.profileID)
  const userDetails = useSelector(state => state.userDetails)

  const findDefaultRoute = (props) => {
    // log.debug(props)
    // Here, we check to see whether routing info has been stored in state (from a link the user attempted to visit but was not authenticated to access). If it does, then we redirect using params in state
    if (props && props.location && props.location.state && props.location.state.from) {
      return <Redirect to={{ pathname: props.location.state.from.pathname, search: props.location.state.from.search }} />
    }
    if (!authObject.userID) {
      log.warn('no identified user')
      return <Redirect to='/error' />
    }
    if (!authObject.isValidated) {
      // The code is hitting this block after log in for an unconfirmed email, but is not redirecting.
      return (<Redirect to='/confirm' />)
    }

    if (!authObject.isAccountApproved) {
      return <Redirect to='/awaiting' />
    }
    if (onboardingStep === 0) {
      return <Redirect to='/onboardp1' />
    } else if (onboardingStep > 0 && profileID) {
      return <Redirect to={{ pathname: '/explore' }} />
    } else {
      // If for whatever reason we can't determine what onboarding step they are on, just redirect to explore
      log.warn('Could not find default page')
      return <Redirect to={{ pathname: '/explore' }} />
    }
  }

  return (
    <>
      <LoadingIndicator />
      <ThemeProvider theme={theme}>
        <Router history={History}>
          <Switch>
            <Route
              path='/'
              exact
              render={(props) => {
                const parsed = queryString.parse(props.location.search)
                if (isAuthenticated) {
                  return findDefaultRoute(props)
                } else {
                  // This is tying the login and register forms to the root path. it defaults to login. The queryString is handled in Login.js to render the correct form.
                  return (
                    <Login location={Object.keys(parsed).length ? props.location : { pathname: '/', search: '?form=register' }} history={props.history} match={props.match} />
                  )
                }
              }}
            />
            <Route
              path='/login' exact render={(props) => {
                if (!isAuthenticated) {
                  return (<Redirect to={{ pathname: '/', search: '?form=login' }} history={props.history} match={props.match} />)
                } else {
                  return findDefaultRoute(props)
                }
              }}
            />
            <Route
              path='/register' exact render={(props) => {
                if (!isAuthenticated) {
                  return (<Redirect to={{ pathname: '/', search: '?form=register' }} history={props.history} match={props.match} />)
                } else {
                  return findDefaultRoute(props)
                }
              }}
            />
            <Route
              path='/profile'
              exact
              render={(props) => {
                const parsed = queryString.parse(props.location.search)
                if (isAuthenticated) {
                  if (!parsed.user || parsed.user === '') {
                    // If a user isn't specified, redirect to the personal profile
                    return <Redirect to={{ pathname: '/profile', search: `?user=${profileID}` }} />
                  }
                  if (!authObject.isValidated || !authObject.isAccountApproved) {
                    return findDefaultRoute(props)
                  }
                  if (userDetails?.deletedAt) { return <PageUnavailable pageType={pageUnavailableTypes.USER_UNAVAILABLE} history={props.history} /> }
                  return <Profile />
                } else {
                  return <Redirect to={{ pathname: '/', search: '?form=login' }} />
                }
              }}
            />
            <Route
              path='/onboardp1'
              exact
              render={() => ((isAuthenticated && onboardingStep === 0) ? <OnboardingP1 />
                : (isAuthenticated && onboardingStep > 0) ? <Redirect to={{ pathname: '/profile' }} />
                  : (isAuthenticated && !onboardingStep) ? <Redirect to={{ pathname: '/profile' }} />
                    : <Redirect to={{ pathname: '/', search: '?form=login' }} />)}
            />
            <Route path='/artifact' exact render={() => (isAuthenticated ? <ArtifactView /> : <Redirect to={{ pathname: '/', search: '?form=login' }} />)} />
            <Route path='/messages' exact render={(props) => (isAuthenticated ? <MessageInbox {...props} /> : <Redirect to={{ pathname: '/', search: '?form=login' }} />)} />
            <Route path='/explore' exact render={(props) => (isAuthenticated ? <ExploreView history={props.history} location={props.location} /> : <Redirect to={{ pathname: '/', search: '?form=login' }} />)} />
            <Route path='/credential-builder' exact render={(props) => (isAuthenticated ? <MCEBuilderView history={props.history} location={props.location} /> : <Redirect to={{ pathname: '/', search: '?form=login' }} />)} />
            <Route
              path='/microcredentials'
              exact
              render={(props) => {
                const parsed = queryString.parse(props.location.search)
                if (isAuthenticated) {
                  if (!parsed || !parsed.view || parsed.view === 'dashboard' || (parsed.view !== 'dashboard' && !parsed.mce)) {
                    return <CredentialDashboard history={props.history} location={props.location} />
                  }
                  return <CredentialView history={props.history} location={props.location} />
                } else {
                  return <Redirect to={{ pathname: '/', search: '?form=login' }} />
                }
              }}
            />
            <Route path='/admin' exact render={(props) => (isAuthenticated ? <AdminView history={props.history} location={props.location} /> : <Redirect to={{ pathname: '/', search: '?form=login' }} />)} />
            <Route path='/notifications' exact render={(props) => (isAuthenticated ? <NotificationsView history={props.history} location={props.location} /> : <Redirect to={{ pathname: '/', search: '?form=login' }} />)} />
            <Route path='/awaiting' exact render={() => (isAuthenticated ? <AwaitingApproval /> : <Redirect to={{ pathname: '/', search: '?form=login' }} />)} />
            {/* The below path works when manually entered but does not load through the findDefaultRoute function as expected */}
            <Route path='/confirm' exact render={(props) => (isAuthenticated ? <CheckEmail /> : <Redirect to={{ pathname: '/', search: '?form=login' }} />)} />
            <Route path='/error' exact render={() => (isAuthenticated ? <NotFound /> : <Redirect to={{ pathname: '/', search: '?form=login' }} />)} />
            <Route
              path='/validate' exact render={(props) => {
                log.debug(props)
                const parsedProps = queryString.parse(props.location.search)
                if (parsedProps?.profileID && parsedProps?.validationKey) {
                  // If the user clicked the email validation link, route as usual
                  return <Validation {...props} />
                } else {
                  // If the user did not click the validation link from email, then redirect to login
                  return <Redirect to={{ pathname: '/', search: '?form=login' }} />
                }
              }}
            />
            <Route path='/password' exact render={(props) => <PasswordReset {...props} />} />
            <Route path='*' exact render={() => (isAuthenticated ? <NotFound /> : <Redirect to={{ pathname: '/', search: '?form=login' }} />)} />
          </Switch>
        </Router>
      </ThemeProvider>
    </>
  )
}

export default Routes
