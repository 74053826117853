import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { withRouter } from 'react-router-dom'

import {
  Grid, AppBar, Toolbar, IconButton, Avatar,
  Paper, Popper, Grow, ClickAwayListener, List, ListItem,
  ListItemText, ListItemIcon, Button, Divider, Badge, Drawer
} from '@material-ui/core'

import {
  ExitToApp as LogoutIcon,
  ThreeSixty as ResetIcon, Notifications, Security,
  Person,
  Search as SearchIcon,
  MailRounded, Style
} from '@material-ui/icons'

import {
  logout, getNotificationsSmallBatch,
  resetUserNotificationsLarge, getArtifactDetails, setReviewArtifact,
  getUserLEDOptions, getProfileEditData, getUserLWOptions,
  setExploreScrollPosition, setExploreActiveTab, setActiveConversationID,
  pollConversations
} from '../../../redux/actions'
import Logo from '../../../assets/edFarmLogo.png'

import { makeStyles, useTheme } from '@material-ui/core/styles'

import PasswordResetModal from '../modals/PasswordResetModal'
import NotificationsPopper from './NotificationsPopper'
import SearchBarNav from '../Search/SearchBarNav'

import { NotificationToast } from '../tools'

import { notifications as EdfarmNotifications } from '../../../lib/notifications'

import moment from 'moment'

const pageViews = {
  EXPLORE: 'explore',
  MESSAGES: 'messages',
  MCE: 'microcredentials'
}

const useStyles = makeStyles((theme) => ({
  navBar: {
    backgroundColor: theme.palette.purple.darkest
  },
  icon: {
    height: '2rem',
    width: '2rem'
  },
  iconBadge: {
    backgroundColor: '#0D0C22'
  },
  mailIconBadge: {
    backgroundColor: '#4AC0E1',
    fontWeight: 600
  },
  notificationIcon: {
    fontSize: theme.spacing(1.5),
    backgroundColor: theme.palette.purple.darkest,
    border: `3px solid ${theme.palette.purple.darkest}`,
    borderRadius: '50%',
    padding: theme.spacing(0.75),
    margin: theme.spacing(0, 1.75, 0, 2.25),
    fill: theme.palette.background.paper
  },
  horizontalIcon: {
    fontSize: theme.spacing(4.5),
    color: theme.palette.purple.darkest,
    margin: theme.spacing(0, 1.4, 0, 1.875)
  },
  resubmitIcon: {
    fontSize: theme.spacing(2.375),
    margin: theme.spacing(0, 1.6, 0, 2.125),
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.purple.darkest,
    borderRadius: '50%',
    padding: theme.spacing(0.75)
  },
  popPaper: {
    minWidth: '18em'
  },
  betaBadge: {
    borderColor: `${theme.palette.pink.dark} !important`
  }
}))

const NavBar = (props) => {
  const { location, setNavRef = () => { }, navHeight = 0, fireNewMessagesSuccess = () => { } } = props

  /* Previously the authProfileAvatarPath was being pulled from state.userDetails, but now we are using the one in auth. The reasoning for this is because when visiting the profile of another user who did not have an avatar, it was setting your own user's avatar to null, and therefore displaying the default 'no avatar' image in the navbar. Using the authProfileAvatarPath in auth prevents this from happening. (Refer to user.js in redux actions for why it was being set to null). */
  const {
    profileID, roleID: userRole, userID: authUserID, profileAvatarPath: authProfileAvatarPath
  } = useSelector(state => state.auth)

  const { userNotificationsSmall: userNotifications = [], notificationCount } = useSelector(state => state.userDetails)
  const { unreadMessages = 0 } = useSelector(state => state.websocket)

  // Saved scroll position for explore all posts
  const savedExploreScrollPosition = useSelector(state => state.select.exploreScrollPosition)
  const activeConversationID = useSelector(state => state.websocket)

  // Active explore tab
  const activeTab = useSelector(state => state.select.exploreActiveTab)

  const adminRole = 'RO1f0da5ee348c41f4ac73ec54762dbe16'
  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'))
  const mdPortrait = useMediaQuery(`${theme.breakpoints.only('md')} and (orientation: portrait)`)
  const pathname = location.pathname

  const [navMenuOpen, setNavMenuOpen] = useState(false)
  const [navAnchor, setNavAnchor] = useState(null)

  const [passwordResetModalOpen, setPasswordResetModalOpen] = useState(false)
  const [notiAnchorEl, setNotiAnchorEl] = useState(null)
  const [notificationsArray, setNotificationsArray] = useState([])

  // Local refs to manage re-renders with interval checks
  const checkingMessages = useRef(false)
  const checkingNotifications = useRef(false)

  // Check the Notifications every 10 seconds
  useEffect(() => {
    if (notiAnchorEl === null) {
      if (!checkingNotifications.current && Boolean(pathname && pathname !== '/notifications')) {
        checkingNotifications.current = true
        const interval = setInterval(() => {
          dispatch(getNotificationsSmallBatch())
        }, 10000)
        return () => { clearInterval(interval); checkingNotifications.current = false }
      }
    }
  }, [dispatch, pathname, notiAnchorEl])

  // Check the unread messages every 5 seconds
  useEffect(() => {
    if (!checkingMessages.current) {
      checkingMessages.current = true
      const interval = setInterval(() => {
        dispatch(pollConversations(fireNewMessagesSuccess, clearPoll))
      }, 5000)
      const clearPoll = () => { clearInterval(interval); checkingMessages.current = false }
      return clearPoll
    }
  }, [dispatch, fireNewMessagesSuccess])

  const handleNotificationPopOpen = (e) => {
    setNotiAnchorEl(e.currentTarget)
  }

  const handleNotificationPopClose = () => {
    setNotiAnchorEl(null)
    setNotificationsArray([])
  }

  const openNotificationPop = Boolean(notiAnchorEl)
  const notificationPopoverID = openNotificationPop ? 'notification-popover' : undefined

  const handleNavMenuChange = (e) => {
    setNavAnchor(navAnchor ? null : e.currentTarget)
    setNavMenuOpen(!navMenuOpen)
  }

  const handleClickAway = () => {
    if (navMenuOpen) {
      setNavMenuOpen(false)
      setNavAnchor(null)
    }
    if (pathname === '/notifications') {
      dispatch(resetUserNotificationsLarge())
    }
    if (activeConversationID) { dispatch(setActiveConversationID(null)) }
  }

  const handlePasswordResetModal = () => {
    setPasswordResetModalOpen(!passwordResetModalOpen)
    handleClickAway()
  }

  const handleExploreReset = () => {
    // Reset scroll position for explore view if it's not 0
    if (savedExploreScrollPosition !== 0) { dispatch(setExploreScrollPosition(0)) }

    // Set explore tab back to featured if it's not already on there when moving from explore page
    if (activeTab !== 'all-posts') { dispatch(setExploreActiveTab('all-posts')) }
  }

  const handleAdminView = () => {
    handleExploreReset()
    handleClickAway()

    props.history.push({
      pathname: '/admin',
      search: '?tab=teacher-fellows'
    })
  }

  const handleProfileView = () => {
    handleExploreReset()
    handleClickAway()

    props.history.push({
      pathname: '/profile',
      search: `?user=${profileID}`
    })
  }

  const defaultFilter = {
    creatorID: profileID,
    sortCount: null,
    offset: 0
  }

  const getProjectOptions = () => {
    dispatch(getUserLWOptions({ ...defaultFilter, type: 'lw' }, () => { }, () => NotificationToast(true, 'Failed to get Learner Works for Project creation!')))
    dispatch(getUserLEDOptions({ ...defaultFilter, type: 'led' }, () => { }, () => NotificationToast(true, 'Failed to get Learner Experience Design for Project creation!')))
  }

  const handleArtifactView = (notification) => {
    /*  NOTE: authorID here refers to the author of the artifact the notification is referring to, not the notification generator */
    const {
      artifactID, authorID, authorProfileID, artifactType, artifactNotificationID, actionType
    } = notification
    let searchPath = ''

    // First check to see if they are dealing with a public comment
    if (!actionType || actionType === EdfarmNotifications.ARTIFACT_COMMENT || actionType === EdfarmNotifications.USER_ARTIFACT_COMMENT || actionType === EdfarmNotifications.ARTIFACT_LIKED || actionType === EdfarmNotifications.ARTIFACT_SAVED) {
      // route to public view for comments
      props.history.push({
        pathname: '/artifact',
        search: `?public=${artifactType}&user=${authorProfileID}&artifact=${artifactID}`
      })
      return true
    }
    // If the user has created the artifact in the notification, take them to their edit view
    if (authorID === authUserID) {
      searchPath = `?create=${artifactType}&submission=yes&editing=true`
    } else if (userRole === adminRole) {
      // Otherwise, check to be sure the user is an admin, and then take them to the review page for that artifact
      searchPath = `?review=${artifactType}`
      // Fetches the details needed for the review side menu
      dispatch(setReviewArtifact(true, artifactNotificationID))
    }

    // Fetch all the needed information to edit an artifact
    if (artifactID) {
      if (artifactType && artifactType === 'Learning Experience Design') { getUserLWOptions({ ...defaultFilter, type: 'lw' }) }

      if (artifactType && artifactType === 'Learner Work') {
        const defaultLEDFilter = { creatorID: profileID, type: 'led', sortCount: null, offset: 0 }
        dispatch(getUserLEDOptions(defaultLEDFilter))
      }

      if (artifactType && artifactType === 'Project') { getProjectOptions() }

      dispatch(getProfileEditData(() => { }, () => NotificationToast(true, 'Failed to get profile edit data!')))
    }

    handleExploreReset()

    // Push the user to the correct path
    props.history.push({
      pathname: '/artifact',
      search: searchPath
    })

    dispatch(getArtifactDetails(artifactID, '', artifactType, () => { }, () => { }, ''))
  }

  const handleChangeView = (view) => {
    handleExploreReset()
    handleClickAway()
    const searchVal = view === pageViews.MCE ? '?view=dashboard' : ''
    props.history.push({
      pathname: `/${view}`,
      search: searchVal
    })
  }

  const [toggleDrawer, setToggleDrawer] = useState(false)

  useEffect(() => {
    return () => {
      setNavMenuOpen(false)
      setNavAnchor(null)
      setPasswordResetModalOpen(false)
      setNotiAnchorEl(false)
      setNotificationsArray(false)
      checkingMessages.current = false
      checkingNotifications.current = false
    }
  }, [])

  return (
    <>

      <PasswordResetModal passwordResetModalOpen={passwordResetModalOpen} setPasswordResetModalOpen={setPasswordResetModalOpen} navHeight={navHeight} />

      <AppBar style={{ zIndex: 1400 }} ref={setNavRef}>
        <Toolbar className={classes.navBar}>
          <Grid item container justifyContent='flex-start' style={{ alignItems: 'center' }} xs={7} sm={5} md={3}>
            <Button
              onClick={() => { handleChangeView(pageViews.EXPLORE) }}
            >
              <img src={Logo} style={{ maxWidth: '5em' }} alt='edFarmLogo' />
            </Button>
            <IconButton onClick={() => { handleChangeView(pageViews.MESSAGES) }}>
              <Badge badgeContent={unreadMessages} color='primary' invisible={Boolean((pathname && pathname === '/messages') || !unreadMessages)} overlap='circular' classes={{ colorPrimary: classes.mailIconBadge }}>
                <MailRounded style={{ fontSize: '36px', color: 'white', paddingTop: '.1em' }} />
              </Badge>
            </IconButton>

            <IconButton onClick={() => { handleChangeView(pageViews.MCE) }}>
              <Style style={{ fontSize: '36px', color: 'white' }} />
            </IconButton>

            {/* Menu Popper */}
            <Popper
              open={navMenuOpen}
              anchorEl={navAnchor}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <ClickAwayListener onClickAway={handleClickAway}>
                  <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                  >
                    <Paper style={{ marginRight: '2em' }}>
                      <List style={{ paddingTop: 0 }}>
                        {userRole === adminRole && pathname !== '/admin' &&
                          <>
                            <ListItem
                              button
                              onClick={(e) => { e.preventDefault(); handleAdminView() }}
                            >
                              <ListItemIcon>
                                <Security />
                              </ListItemIcon>
                              <ListItemText primary='Admin' />
                            </ListItem>

                            <Divider />
                          </>}
                        <>
                          <ListItem
                            button
                            onClick={(e) => { e.preventDefault(); handleProfileView() }}
                          >
                            <ListItemIcon>
                              <Person />
                            </ListItemIcon>
                            <ListItemText primary='Profile' />
                          </ListItem>

                          <Divider />
                        </>
                        <ListItem
                          button
                          onClick={(e) => { e.preventDefault(); handlePasswordResetModal() }}
                        >
                          <ListItemIcon>
                            <ResetIcon />
                          </ListItemIcon>
                          <ListItemText primary='Reset Password' />
                        </ListItem>

                        <Divider />

                        <ListItem button onClick={() => dispatch(logout())}>
                          <ListItemIcon>
                            <LogoutIcon />
                          </ListItemIcon>
                          <ListItemText primary='Logout' />
                        </ListItem>
                      </List>
                    </Paper>
                  </Grow>
                </ClickAwayListener>
              )}
            </Popper>

            {/* Notifications Popper  */}
            <NotificationsPopper
              classes={classes}
              notificationsArray={notificationsArray}
              userNotifications={userNotifications}
              notificationPopoverID={notificationPopoverID}
              openNotificationPop={openNotificationPop}
              notiAnchorEl={notiAnchorEl}
              handleNotificationPopClose={handleNotificationPopClose}
              profileID={profileID}
              setNotificationsArray={setNotificationsArray}
              handleArtifactView={handleArtifactView}
              navHeight={navHeight}
            />
          </Grid>

          <Grid item container justifyContent={xsDown ? 'center' : 'flex-end'} xs={1} sm={4} md={7} lg={9}>
            {/* Display a drawer container for the search bar when in x-small mobile screens */}
            {xsDown
              ? (
                <>
                  <SearchIcon onClick={() => setToggleDrawer(prev => !prev)} style={{ fontSize: '36px', color: 'white' }} />
                  <Drawer anchor='top' open={toggleDrawer} onClose={() => setToggleDrawer(false)}>
                    <Grid container justifyContent='center' style={{ marginTop: navHeight, padding: '1.5em 0 1.5em 0', backgroundColor: theme.palette.purple.darkest }}>
                      <SearchBarNav setToggleDrawer={setToggleDrawer} xsDown={xsDown} />
                    </Grid>
                  </Drawer>
                </>
              )
              : (
                <SearchBarNav />
              )}
          </Grid>

          <Grid item container direction='row' justifyContent='flex-end' alignItems='center' xs={4} sm={3} md={2}>
            <Grid item container xs={6} sm={5} justifyContent='flex-end'>
              <IconButton onClick={(e) => handleNotificationPopOpen(e)}>
                <Badge badgeContent={!notificationCount ? 0 : notificationCount} invisible={!notificationCount || notificationCount === 0} color='primary' overlap='circular' classes={{ colorPrimary: classes.iconBadge }}>
                  <Notifications style={{ color: 'white', fontSize: mdPortrait ? '1em' : '1.5em' }} />
                </Badge>
              </IconButton>
            </Grid>
            <Grid item container xs={6} sm={5} justifyContent='flex-end'>
              <IconButton onClick={(e) => handleNavMenuChange(e)}>
                {/* Using moment to get the latest path from cloudfront */}
                <Avatar src={`${!authProfileAvatarPath ? '' : `${authProfileAvatarPath}?${moment.now()}`}`} />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default withRouter(NavBar)
