import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import PublicSideMenu from '../ui/artifacts/PublicSideMenu'
import SocialModal from '../ui/modals/SocialModal'

import queryString from 'query-string'
import { v4 as uuidv4 } from 'uuid'

import { Grid, Slide, AppBar, Toolbar, Button, Paper, Dialog, DialogActions, DialogContent, DialogTitle, Typography, IconButton } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { LoopRounded, CloseRounded } from '@material-ui/icons'
import {
  getUserDetails, getUserLEDOptions, getUserLEDs, getProfileEditData, getUserLWOptions, getArtifactDetails,
  getUserLearnerWork, getSocialDetails, resetTargetLED, resetTargetProject, resetTargetLearnerWork, resetTargetEFR,
  getArtifactComments, setExploreScrollPosition, setExploreActiveTab, updateLEDActiveStatus
} from '../../redux/actions'
import { artifactTypes, featuredLEDActions } from '../../utils'
import PublicArtifactContent from '../ui/artifacts/PublicArtifactContent'

const PublicArtifact = (props) => {
  const {
    theme, classes, artifactType, styles, authorDetails,
    reviewInfo, setReviewInfo, typeLoaded, uiLoading, duplicateArtifactForm,
    returnToMCEAttempt = () => {}
  } = props

  const parsedProps = queryString.parse(props.location.search)
  const dispatch = useDispatch()

  // Redux State for Artifact Social Details
  const { savedArtifacts, likedArtifacts, profileID } = useSelector(state => state.auth)

  // Saved scroll position for explore all posts
  const savedExploreScrollPosition = useSelector(state => state.select.exploreScrollPosition)

  // The artifact Author's profileID
  const creatorID = parsedProps.user

  // The bool value for if a artifact is being viewed from the mce process
  const isForMCE = parsedProps.isForMCE ? JSON.parse(parsedProps.isForMCE) : false

  // The artifact being viewed
  const currentArtifactID = parsedProps.artifact

  // The artifact Type of the ID being viewed
  const artType = parsedProps.public

  const {
    forkedFrom = null, isPrivate = 0, isDraft = 0, submissionStatus = 'NA', isForSubmission = 0
  } = reviewInfo

  // Checks to see if the artifact is publicly available. If it is, then show the duplication button, otherwise hide it.
  const publicArtifactCheck = currentArtifactID && currentArtifactID !== 'unavailable' && reviewInfo && isPrivate !== 1 && isDraft !== 1 && (isForSubmission === 0 || submissionStatus === 'APPROVED' || submissionStatus === 'NA')

  // 1st level artifact formatting (this isn't even my final form)
  const [formattedInfo, setFormattedInfo] = useState({})

  // Final form of artifact data
  const [artDetails, setArtDetails] = useState({})

  // If the artifact attempted to be viewed is set to private or otherwise returns an empty array, this 'unavailable' param will catch and display an error message
  const handlePublicFetchFailure = useCallback((formatType) => {
    props.history.push({
      pathname: '/artifact',
      search: `?public=${formatType}&user=${creatorID}&artifact=unavailable`
    })
  }, [props, creatorID])

  // Project specific dispatch's with their own filter
  const getProjectOptions = useCallback(() => {
    const defaultFilter = {
      creatorID,
      sortCount: null,
      offset: 0
    }

    dispatch(getUserLWOptions({ ...defaultFilter, type: 'lw' }, () => {}, () => { }))
    dispatch(getUserLEDOptions({ ...defaultFilter, type: 'led' }, () => {}, () => { }))
  }, [dispatch, creatorID])

  const updateLEDFeaturedStatus = (artifactID, currentSelectedStatus, successFunc = () => {}) => {
    const { selectedOnly, removeSelected } = featuredLEDActions
    const action = currentSelectedStatus ? removeSelected : selectedOnly
    dispatch(updateLEDActiveStatus(artifactID, action, successFunc))
  }

  // All the dispatches needed for fetching the Author's information
  const getAuthorInformation = useCallback(async () => {
    dispatch(getProfileEditData(() => { }, () => { }))
    dispatch(getUserDetails(creatorID, () => { }, () => { }))
    if (artifactType === artifactTypes.LW) {
      dispatch(getUserLEDs(creatorID))
    }

    if (artifactType === artifactTypes.PRO) {
      dispatch(getUserLEDs(creatorID))
      dispatch(getUserLearnerWork(creatorID))
    }
  }, [dispatch, creatorID, artifactType])

  // All the dispatches needed to build and format the artifact information
  const getArtifactInformation = useCallback(async () => {
    if (currentArtifactID && currentArtifactID !== 'unavailable') {
      if (artType) {
        dispatch(getArtifactComments(currentArtifactID))
      }
      const originalInfo = await dispatch(getArtifactDetails(currentArtifactID, '', artType, () => { }, handlePublicFetchFailure, ''))
      if (originalInfo) {
        if (artType && artType === artifactTypes.LW) {
          const defaultLEDFilter = { creatorID, type: 'led', sortCount: null, offset: 0 }
          dispatch(getUserLEDOptions(defaultLEDFilter))
        }

        if (artType && artType === artifactTypes.PRO) { getProjectOptions() }
      }
    }
    setTimeout(() => {
      uiLoading.current = false
    }, 500)
  }, [currentArtifactID, artType, uiLoading, creatorID, dispatch, getProjectOptions, handlePublicFetchFailure])

  // On first page load grab the artifactID in the params and fetch/format all its deets
  useEffect(() => {
    if (currentArtifactID && reviewInfo && Object.keys(reviewInfo).length === 0 && !uiLoading.current) {
      uiLoading.current = true
      getArtifactInformation()
    }
  }, [currentArtifactID, formattedInfo, getArtifactInformation, reviewInfo, uiLoading])

  // Grab the provided author details and fetch their deets too
  useMemo(async () => {
    let authorLoading = false
    if (creatorID && !authorLoading) {
      authorLoading = true
      await getAuthorInformation().then(() => { authorLoading = false })
    }
  }, [creatorID, getAuthorInformation])

  const resetInfo = (type) => {
    const { LED, LW, PRO, EFR } = artifactTypes
    switch (type) {
      case LED: {
        dispatch(resetTargetLED())
        break
      }
      case LW: {
        dispatch(resetTargetLearnerWork())
        break
      }
      case PRO: {
        dispatch(resetTargetProject())
        break
      }
      case EFR: {
        dispatch(resetTargetEFR())
        break
      }
      default: return console.log('No artifact type found')
    }
  }

  // Go to the artifact creator's profile
  const handleProfileView = (id) => {
    if (artifactType) {
      resetInfo(artifactType)
    }

    if (savedExploreScrollPosition !== 0) { dispatch(setExploreScrollPosition(0)); dispatch(setExploreActiveTab('all-posts')) }

    props.history.push({
      pathname: '/profile',
      search: `?user=${!id ? creatorID : id}`
    })
  }

  // Uses the history to handle backing through nested artifacts, and unavailable artifact or exiting out of public view entirely
  const handleExit = async () => {
    if (!isForMCE) {
      props.history.goBack()
    } else {
      returnToMCEAttempt(currentArtifactID)
    }

    if (artifactType) {
      resetInfo(artifactType)
    }

    setTimeout(() => {
      const path = queryString.parse(props.history.location.search)
      // If path contains create, take user back to profile
      if (path && path.create) {
        props.history.push(`/profile?user=${profileID}`)
      }

      setReviewInfo({})
    }, [200])
  }

  const fireCommentSuccess = async () => {
    await dispatch(getArtifactComments(currentArtifactID))
  }

  // Sets the form for a duplicated artifact and routes to the create view for an artifact
  const handleDuplicateArtifact = (artInfo) => {
    // Information of the artifact to be duplicated
    const {
      ISTEStandards = [], actContent = '', artifactID = '', artifactType = '', completionTime = 0,
      coreSubjectID = '', createContent = '', engageContent = '', grades = [], investigateContent = '',
      overview = '', secondarySubjectID = '', stateStandards = '', targets = [], techNeeded = '', title = '', workType = ''
    } = artInfo

    // The name of the original author
    const { fullName = '' } = authorDetails

    // Values to be reassigned in the duplicate artifact
    const defaultDuplicateTitle = `${fullName}'s Original Artifact: "${title}"`
    const duplicateForkedFrom = artifactID
    let duplicateTargetArray = []

    // Give the targets (is available) a new target ID to be associated with the new duplicate artifact
    if (targets && targets.length) {
      duplicateTargetArray = targets.reduce((arr, target) => {
        if (target) {
          const { targetName = '' } = target
          const uuid = uuidv4().replace(/-/g, '')
          arr.push({ targetID: `LT${uuid}`, targetName })
        }
        return arr
      }, [])
    }

    // Format the new details, without the original artifact's social/user-linked details
    const duplicateDetails = {
      title: defaultDuplicateTitle,
      forkedFrom: duplicateForkedFrom,
      targets: duplicateTargetArray,
      ISTEStandards,
      actContent,
      createContent,
      engageContent,
      investigateContent,
      artifactType,
      completionTime,
      coreSubjectID,
      secondarySubjectID,
      grades,
      overview,
      stateStandards,
      techNeeded,
      workType
    }

    // Set that to a ref (reads in the create artifact view)
    duplicateArtifactForm.current = duplicateDetails

    // Filter needed for dispatch, using the auth user's ID and not the original artifact's author ID
    const defaultFilter = {
      creatorID: profileID,
      sortCount: null,
      offset: 0
    }

    // Fetch the auth user's LW's
    dispatch(getUserLWOptions({ ...defaultFilter, type: 'lw' }))

    // Route to the create artifact view
    props.history.push({
      pathname: '/artifact',
      search: `?create=${artifactTypes.LED}&submission=no&draft=true`
    })
  }

  // local state for social display
  const [socialType, setSocialType] = useState('')
  const [socialModalOpen, setSocialModalOpen] = useState(false)
  const [socialArtifactInfo, setSocialArtifactInfo] = useState({})

  const [confirmDuplicateModalOpen, setConfirmDuplicateModalOpen] = useState(false)

  useEffect(() => {
    return () => {
      setFormattedInfo({})
      setArtDetails({})
      setSocialArtifactInfo({})
      setSocialModalOpen(false)
      setSocialType('')
      setConfirmDuplicateModalOpen(false)
      uiLoading.current = false
    }
  }, [uiLoading])

  return (
    <>
      {/* Modal to Display Social Information */}
      <SocialModal
        isOpen={socialModalOpen}
        artifactDetails={socialArtifactInfo}
        actionType={socialType}
        onModalClose={() => { setSocialModalOpen(false); setSocialType(''); setSocialArtifactInfo({}) }}
        handleUserProfileView={handleProfileView}
      />

      {/* Confirm Duplication Modal */}
      <Dialog
        fullWidth
        maxWidth='xs'
        open={confirmDuplicateModalOpen}
        onClose={() => setConfirmDuplicateModalOpen(false)}
      >
        <DialogTitle disableTypography>
          <Grid container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid item>
              <Typography
                variant='h4'
                style={{ textTransform: 'none', color: styles.mainColor, fontSize: '1.3rem' }}
              >
                Duplicate Artifact
              </Typography>
            </Grid>

            <Grid item>
              <IconButton style={{ padding: '0px' }} onClick={() => setConfirmDuplicateModalOpen(false)}>
                <CloseRounded className={classes.customizedButton} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Grid container direction='column' style={{ marginBottom: '.5em' }}>
            <Typography variant='h4' style={{ color: theme.palette.grey.dark, fontWeight: 400 }}>
              {`Are you sure you wish to duplicate "${reviewInfo.title}"?`}
            </Typography>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={(e) => { e.preventDefault(); setConfirmDuplicateModalOpen(false) }}
            style={{
              color: styles.mainColor,
              textTransform: 'none',
              textDecoration: 'underline',
              fontWeight: '600',
              fontSize: '.9rem'
            }}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            style={{ backgroundColor: styles.mainColor }}
            onClick={() => handleDuplicateArtifact(reviewInfo)}
          >
            Duplicate
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container direction={theme.breakpoints.down('sm') ? 'row' : 'column'} justifyContent='center' style={{ marginTop: '5em', marginBottom: '6em' }} spacing={4}>

        <Grid item container sm={null} md={3} direction='column' alignContent={theme.breakpoints.down('sm') ? 'center' : 'flex-end'}>
          {/* Artifact Creator Information */}
          <PublicSideMenu
            classes={classes}
            styles={styles}
            authorDetails={authorDetails}
            handleProfileView={handleProfileView}
            handleExit={handleExit}
            creatorID={creatorID}
            artifactType={artifactType}
            artifactTypes={artifactTypes}
            isForMCE={isForMCE}
          />
        </Grid>

        <Grid item container sm={null} md={9} className={classes.formContainer}>
          {/* Artifact Content */}
          <Slide
            in={typeLoaded || Boolean(currentArtifactID && currentArtifactID === 'unavailable')}
            exit={false}
            unmountOnExit
            appear
            mountOnEnter
            direction='left'
          >
            {artDetails && Object.keys(artDetails.length !== 0)
              ? (
                <div style={{ width: '100%' }}>
                  <PublicArtifactContent
                    classes={classes}
                    artifactType={artifactType}
                    styles={styles}
                    reviewInfo={reviewInfo}
                    creatorID={creatorID}
                    formattedInfo={formattedInfo}
                    setFormattedInfo={setFormattedInfo}
                    currentArtifactID={currentArtifactID}
                    publicArtifactCheck={publicArtifactCheck}
                    setReviewInfo={setReviewInfo}
                    artDetails={artDetails}
                    setArtDetails={setArtDetails}
                    handlePublicFetchFailure={handlePublicFetchFailure}
                    artifactLiked={likedArtifacts && likedArtifacts.length && likedArtifacts.includes(currentArtifactID)}
                    artifactSaved={savedArtifacts && savedArtifacts.length && savedArtifacts.includes(currentArtifactID)}
                    onSocialButtonClick={(status) => { dispatch(getSocialDetails(currentArtifactID)); setSocialType(status); setSocialArtifactInfo(reviewInfo); setSocialModalOpen(true) }}
                    socialModalOpen={socialModalOpen}
                    setActionType={setSocialType}
                    fireCommentSuccess={fireCommentSuccess}
                    authorDetails={authorDetails}
                    updateLEDFeaturedStatus={updateLEDFeaturedStatus}
                    uiLoading={uiLoading}
                    isForMCE={isForMCE}
                  />
                </div>
              )
              : (
                <div style={{ width: '100%' }}>
                  <Paper className={classes.paperRoot}>
                    <Grid item container direction='column' style={{ height: '35em' }}>
                      <Skeleton variant='rect' width='50%' height={20} animation='wave' style={{ marginBottom: '2em' }} />
                      <Grid item container direction='row'>
                        <Grid item xs={3} container direction='row' alignItems='center'>
                          <Skeleton variant='circle' width={60} height={60} animation='wave' />
                          <Skeleton variant='text' width={80} height={40} animation='wave' style={{ marginLeft: '.5em' }} />
                        </Grid>
                        <Grid item xs={3} container direction='row' alignItems='center'>
                          <Skeleton variant='circle' width={60} height={60} animation='wave' />
                          <Skeleton variant='text' width={80} height={40} animation='wave' style={{ marginLeft: '.5em' }} />
                        </Grid>
                        <Grid item xs={3} container direction='row' alignItems='center'>
                          <Skeleton variant='circle' width={60} height={60} animation='wave' />
                          <Skeleton variant='text' width={80} height={40} animation='wave' style={{ marginLeft: '.5em' }} />
                        </Grid>
                      </Grid>
                      <Grid item container direction='column' style={{ marginTop: '2em' }}>
                        <Skeleton variant='text' width='30%' height={40} animation='wave' />
                        <Skeleton variant='text' width='40%' height={40} animation='wave' style={{ marginBottom: '1em' }} />
                      </Grid>
                      <Grid item container direction='column' style={{ marginTop: '2em' }}>
                        <Skeleton variant='rect' width='80%' height={80} animation='wave' />
                        <Skeleton variant='rect' width='80%' height={80} animation='wave' style={{ marginBottom: '1em', marginTop: '1em' }} />
                        <Skeleton variant='text' width='40%' height={40} animation='wave' style={{ marginBottom: '1em' }} />
                      </Grid>
                      <Grid item container direction='row'>
                        <Skeleton variant='text' width={20} height={20} animation='wave' />
                        <Skeleton variant='text' width={20} height={20} animation='wave' style={{ marginLeft: '.2em' }} />
                        <Skeleton variant='text' width={20} height={20} animation='wave' style={{ marginLeft: '.2em' }} />
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              )}
          </Slide>
        </Grid>
      </Grid>

      <AppBar style={{ top: 'auto', bottom: 0 }}>
        <Toolbar style={{ backgroundColor: 'white', padding: '.5em' }}>
          {/* Adding 50px to the right to allow for the size of the new embedded jira help icon which cannot be edited */}
          <Grid container direction='row' justifyContent='flex-end' style={{ marginRight: '50px' }}>
            {/* Duplication button (does not render when artifact is already a duplicate or is a private LED) */}
            {!isForMCE && typeLoaded && reviewInfo && publicArtifactCheck && !forkedFrom && artifactType === artifactTypes.LED &&
              <Grid item className={classes.saveExitBtn}>
                <Button
                  variant='outlined'
                  startIcon={<LoopRounded />}
                  onClick={() => setConfirmDuplicateModalOpen(true)}
                  style={{
                    marginRight: '.3em',
                    fontWeight: '600',
                    padding: '.5em 1em',
                    color: styles.mainColor,
                    borderColor: styles.mainColor
                  }}
                >
                  Duplicate Artifact
                </Button>
              </Grid>}
            <Grid item className={classes.saveExitBtn}>
              <Button
                variant='contained'
                style={{
                  marginRight: '.3em',
                  fontWeight: '600',
                  padding: '.5em 2em',
                  color: 'white',
                  backgroundColor: styles.mainColor
                }}
                onClick={() => handleExit(parsedProps)}
              >
                Exit
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default withRouter(PublicArtifact)
