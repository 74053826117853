import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  Grid, Typography, Paper, Divider, useTheme, Button, RadioGroup, Radio,
  FormControlLabel
} from '@material-ui/core'

import { Assignment, ArrowBackIosRounded } from '@material-ui/icons'

import { getProfileEditData } from '../../../../redux/actions'
import { NotificationToast } from '../../tools'
import SummaryDetails from './SummaryViews/SummaryDetails'
import LEDCardDesignBuilder from './SummaryViews/LEDCardDesignBuilder'

import LEDDisplayDetails from '../LEDDisplayDetails'

const summaryViews = {
  SUMMARY: 'SUMMARY',
  DESIGN: 'DESIGN',
  PREVIEW: 'PREVIEW'
}

const ArtifactSummary = (props) => {
  const {
    ledInfo, selectedLearnerWork, setCurrentForm, classes, styles, setLEDInfo = () => { },
    targetArtifactComments, setPublishModalOpen = () => { }, handleSaveArtifact = () => { },
    isDraft = false, isForMCE = false, artifactNotCompleteCheck = false
  } = props

  const gradesData = useSelector(state => state.profileData.grades)
  const subjects = useSelector(state => state.profileData.subjects)
  const standards = useSelector(state => state.artifacts.standards)

  const { targetArtifactLED: { forkedFrom = '', originalArtifact = {} } } = useSelector(state => state.artifacts)

  const [sectionComments, setSectionComments] = useState([])
  const [summaryView, setSummaryView] = useState(summaryViews.SUMMARY)
  const [artifactPreviewInfo, setArtifactPreviewInfo] = useState({})

  const publicPreview = summaryView && summaryView === summaryViews.PREVIEW
  const designPreview = summaryView && summaryView === summaryViews.DESIGN

  const handleViewChange = (e) => {
    e.preventDefault()
    setSummaryView(e.target.value)
  }

  useEffect(() => {
    if (targetArtifactComments) {
      if (targetArtifactComments.length) {
        const tempArr = targetArtifactComments.reduce((arr, comment) => {
          if (comment) {
            if (comment.section === 'notes') {
              arr.push({ ...comment })
            }
          }
          return arr
        }, [])
        setSectionComments(tempArr)
      }
    }
  }, [targetArtifactComments])

  const {
    grades,
    coreSubjectID
  } = ledInfo

  const theme = useTheme()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!subjects || !subjects.length || !gradesData || !gradesData.length) {
      dispatch(getProfileEditData(() => { }, () => NotificationToast(true, 'Failed to get profile edit data!')))
    }
  }, [dispatch, subjects, gradesData])

  useEffect(() => { return setArtifactPreviewInfo({}) }, [])

  const getValue = useCallback((type) => {
    switch (type) {
      case 'grades': {
        const gradeNames = grades.reduce((arr, grade) => {
          const index = gradesData.findIndex(x => x.gradeID === grade)

          if (index !== -1) {
            const gradeName = gradesData[index].gradeName

            if (gradeName) {
              const name = publicPreview || designPreview ? gradeName : gradeName.replace('Grade', '')
              arr.push(name)
            }
          }

          return arr
        }, [])

        const isPublic = (publicPreview ? gradeNames : gradeNames.join(', '))

        return isPublic
      }
      case 'core-subject': {
        const index = subjects.findIndex(x => x.subjectID === coreSubjectID)
        return subjects[index].subjectName
      }
      case 'secondary-subject': {
        const index = subjects.findIndex(x => x.subjectID === ledInfo.secondarySubjectID)
        return subjects[index].subjectName
      }
      case 'ISTE-standards': {
        const standardsArr = ledInfo.ISTEStandards.reduce((arr, standard) => {
          const index = standards.findIndex(x => x.standardID === standard)
          if (index !== -1) {
            const standardName = standards[index].standardName

            if (standardName) {
              arr.push(standardName)
            }
          }
          return arr
        }, [])
        return standardsArr.join(', ')
      }
      default: return ''
    }
  }, [coreSubjectID, grades, gradesData, ledInfo, publicPreview, standards, subjects, designPreview])

  // Reformatting ledInfo for use in LEDDisplayDetails (making it similar to formattedInfo)
  useMemo(() => {
    if (!artifactNotCompleteCheck) {
      // Build object with common properties
      const previewObj = {
        ...ledInfo,
        ISTEStandards: ledInfo.ISTEStandards && ledInfo.ISTEStandards.length ? getValue('ISTE-standards').split(',') : '',
        coreSubject: getValue('core-subject'),
        grades: getValue('grades')
      }
      // Only add LW props to object if user has selected LW
      if (selectedLearnerWork) {
        setArtifactPreviewInfo({
          ...previewObj,
          learnerWorkTitle: selectedLearnerWork.title,
          learnerWorkID: selectedLearnerWork.artifactID
        })
      } else {
        setArtifactPreviewInfo({ ...previewObj })
      }
    }
  }, [artifactNotCompleteCheck, getValue, ledInfo, selectedLearnerWork])

  const getSummaryView = (view) => {
    switch (view) {
      case summaryViews.SUMMARY: {
        return (
          <SummaryDetails
            classes={classes}
            styles={styles}
            sectionComments={sectionComments}
            setCurrentForm={setCurrentForm}
            getValue={getValue}
            ledInfo={ledInfo}
            selectedLearnerWork={selectedLearnerWork}
            isDraft={isDraft}
            artifactNotCompleteCheck={artifactNotCompleteCheck}
            setPublishModalOpen={setPublishModalOpen}
            isForMCE={isForMCE}
            handleSaveArtifact={handleSaveArtifact}
          />
        )
      }
      case summaryViews.DESIGN: {
        return (
          <LEDCardDesignBuilder
            styles={styles}
            classes={classes}
            ledInfo={ledInfo}
            setLEDInfo={setLEDInfo}
            gradeNames={getValue('grades')}
          />
        )
      }
      case summaryViews.PREVIEW: {
        return (
          <LEDDisplayDetails
            styles={styles}
            formattedInfo={artifactPreviewInfo}
            publicPreview={publicPreview}
            artifactType={ledInfo.artifactType}
          />
        )
      }
      default: {
        return (
          <SummaryDetails
            classes={classes}
            styles={styles}
            sectionComments={sectionComments}
            setCurrentForm={setCurrentForm}
            getValue={getValue}
            ledInfo={ledInfo}
            selectedLearnerWork={selectedLearnerWork}
            isDraft={isDraft}
            artifactNotCompleteCheck={artifactNotCompleteCheck}
            setPublishModalOpen={setPublishModalOpen}
          />
        )
      }
    }
  }

  return (
    <Paper className={classes.paperRoot}>
      <Grid container direction='column'>

        {/* Step Header */}
        <Grid container item direction='row' justifyContent='space-between' alignContent='center'>
          <Grid container item direction='row' xs={7} spacing={1} style={{ marginBottom: '.5em' }}>
            {/* Icon only displays when not in Public Preview */}
            {!publicPreview &&
              <Grid item>
                <Assignment style={{ color: theme.palette.grey.dark }} />
              </Grid>}
            <Grid item>
              <Typography variant='h4' style={{ wordBreak: 'break-word' }}>{publicPreview ? ledInfo.title : 'Summary & Submit'}</Typography>
            </Grid>
          </Grid>

          {/* Public Preview Button */}
          {ledInfo && !artifactNotCompleteCheck && publicPreview &&
            <Grid container item direction='row' alignItems='flex-start' justifyContent='flex-end' xs={5} style={{ marginBottom: '.5em' }}>
              <Button
                variant='outlined'
                size='small'
                color='secondary'
                startIcon={<ArrowBackIosRounded style={{ fontSize: '14px' }} />}
                style={{ textTransform: 'none', fontWeight: '900', margin: '0 0 auto 0' }}
                onClick={() => setSummaryView(summaryViews.SUMMARY)}
              >
                Exit Preview
              </Button>
            </Grid>}
        </Grid>

        {forkedFrom && originalArtifact &&
          <Grid item container direction='row'>
            <Typography variant='caption' style={{ color: theme.palette.grey.dark, fontWeight: 600, marginRight: '.2em' }}>
              {`Inspired by ${originalArtifact.deletedAt ? 'another Ed Farm User' : originalArtifact.fullName}'s`}
            </Typography>

            <Typography variant='caption' style={{ color: theme.palette.purple.darkest, fontWeight: 600 }}>
              {`"${originalArtifact.title.substring(0, 55)}${originalArtifact.title.length > 55 ? '...' : ''}"`}
            </Typography>
          </Grid>}

        {!publicPreview && !isForMCE &&
          <>
            <Grid item container direction='row' style={{ marginBottom: '.5em' }}>
              <RadioGroup row onChange={handleViewChange}>
                <FormControlLabel value={summaryViews.SUMMARY} control={<Radio size='small' checked={summaryView && summaryView === summaryViews.SUMMARY} />} label='Summary' />
                <FormControlLabel value={summaryViews.DESIGN} control={<Radio size='small' checked={summaryView && summaryView === summaryViews.DESIGN} />} label='Design' />
                <FormControlLabel value={summaryViews.PREVIEW} control={<Radio size='small' checked={summaryView && summaryView === summaryViews.PREVIEW} />} label='Public Preview' />
              </RadioGroup>
            </Grid>
            <Divider />
          </>}

        {/* Fetch and display the correct component based on the chosen summary view */}
        {getSummaryView(summaryView)}

      </Grid>
    </Paper>
  )
}

export default ArtifactSummary
