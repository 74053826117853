import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import {
  Grid, Paper, Typography, Divider, IconButton, Button,
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, Accordion, AccordionSummary, Fade
} from '@material-ui/core'

import { Autocomplete, Skeleton } from '@material-ui/lab'

import { useTheme, makeStyles } from '@material-ui/core/styles'
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CloseRounded from '@material-ui/icons/CloseRounded'
import EditIcon from '../../../assets/edit-icon.svg'

import { NotificationToast } from '../tools'

import { getTechList, resetTechList, updateUserTech, getUserDetails } from '../../../redux/actions'
import { GET_TECH_LIST, GET_USER_DETAILS } from '../../../redux/types'

import { v4 as uuidv4 } from 'uuid'
import { KeyboardReturnRounded } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  addIcon: {
    color: theme.palette.purple.darkest,
    backgroundColor: theme.palette.purple.lightest,
    padding: '.1em',
    border: `2px dashed ${theme.palette.grey.medium}`,
    borderRadius: '5px'
  },
  editIcon: {
    padding: '0 1em 0 0',
    '&:hover': {
      backgroundColor: 'transparent !important'
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0'
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '.5em'
    }
  },
  accordionHeader: {
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start'
    }
  },
  techChips: {
    '&:disabled': {
      backgroundColor: theme.palette.purple.lightest,
      color: theme.palette.purple.darkest,
      fontWeight: '600',
      padding: '.2em 14px',
      borderRadius: '5px',
      margin: '.5em'
    }
  },
  techChipAction: {
    backgroundColor: theme.palette.purple.lightest,
    color: theme.palette.purple.darkest,
    textTransform: 'none',
    fontWeight: '600',
    boxShadow: 'none',
    borderRadius: '5px',
    margin: '.5em'
  },
  addFavTechBtn: {
    color: 'primary',
    margin: '.5em',
    width: '12rem',
    backgroundColor: 'none',
    alignItems: 'center'
  },
  customizedButton: {
    // position: 'absolute',
    // left: '135%',
    // top: '-130%',
    backgroundColor: 'none',
    color: 'grey',
    stroke: 'grey',
    strokeWidth: '2px',
    height: '2rem',
    width: '2rem',
    '&:hover': {
      stroke: '#616161'
    }
  },
  animateHelperText: {
    animation: '$pulse 1.2s ease-in-out'
  },
  '@keyframes pulse': {
    '0%': {
      textShadow: 'none'
    },
    '40%': {
      color: 'red',
      textShadow: '.1px .1px 2px red'
    },
    '100%': {
      textShadow: 'none'
    }
  }
}))

// Initializes timer for "Press Enter" helper text
let timer

const UserTech = (props) => {
  const { matches, userTech = [], allTech = [], canEdit, editMode, userProfileID, loadingState, modalType, modalTypes, removeModalType, editTechOpen, setEditTechOpen, navHeight = 0 } = props
  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()

  const { type, loading } = loadingState

  const [expandAccor, setExpandAccor] = useState(false)
  const [showTechInput, setShowTechInput] = useState(false)

  // Looks for a modal param and open the edit tech modal if requested via the param
  useEffect(() => {
    if (modalType) {
      if (!editTechOpen && modalType === modalTypes.TECH) {
        setEditTechOpen(true)
      }
    }
  }, [modalType, editTechOpen, modalTypes, setEditTechOpen])

  // Forces the autocomplete component to rerender (thereby reseting the selections)
  const [resetComplete, setResetComplete] = useState(false)

  // The length of this input (from the autocomplete) controls when the popper opens
  const [input, setInput] = useState('')

  // Local object for display
  const [displayTech, setDisplayTech] = useState([])

  // Error fired if the user tries to add more than the alloted 5 skills per user
  const [lengthError, setLengthError] = useState(false)

  // Display 'press enter' dialogue after a few seconds of user typing
  const [showHelperText, setShowHelperText] = useState(false)

  // Display unsaved tech prompt + additional exit button
  const [showUnsavedTechText, setShowUnsavedTechText] = useState(false)

  // When the modal opens, take all the user's current picks to the display array
  const handleModalEnter = () => {
    if (userTech && Boolean(userTech.length)) {
      setShowTechInput(true)
    } else {
      setShowTechInput(false)
    }
    setDisplayTech([...userTech])
  }

  // check if user has tech tool typed, but not added to list properly
  const unsavedTechCheck = useMemo(() => {
    if (editTechOpen && showTechInput) {
      if (input && input !== '') {
        return true
      } else {
        setShowUnsavedTechText(false)
        return false
      }
    }
  }, [input, editTechOpen, showTechInput])

  useMemo(() => {
    const value = Boolean(displayTech && displayTech.length === 10)

    if (value) {
      setLengthError(value)
    }
  }, [displayTech])

  const handleOptionSelect = (option) => {
    // Force the autocomplete to rerender which resets it's selected options
    setResetComplete(prev => !prev)
    setInput('')

    // Hide all helper text/prompts when a tech tool is selected
    setShowHelperText(false)
    setShowUnsavedTechText(false)

    // Temporary Arrays
    const tempDisplayArray = [...displayTech]

    // Check to be sure user is not adding more than 10 tech
    if (tempDisplayArray && tempDisplayArray.length <= 9) {
      const techToDisplay = searchTech(option, tempDisplayArray)
      setDisplayTech(techToDisplay)
    } else {
      setLengthError(true)
    }
  }

  const searchTech = (option = '', techShown = []) => {
    // Check first if option is an option from all the existing tech
    if (option && option.toolID && option.toolName) {
      // Check if tech is current displayed
      const techExists = techShown.find(
        (techInfo) => techInfo.toolID === option.toolID ||
          Boolean(techInfo.toolName.toLowerCase().localeCompare(option.toolName.toLowerCase()) === 0)
      )

      if (!techExists) {
        // If tech does not exist in current tech, add to existing tech
        techShown.push({ ...option })
        return techShown
      } else {
        // If tech does exist in current tech, removed from existing tech
        return techShown
      }
    } else {
      // Check if current option is anywhere in existing tech
      const techExists = techShown.find(
        (techInfo) => Boolean(techInfo.toolName.toLowerCase().localeCompare(option.toLowerCase()) === 0)
      )

      if (!techExists) {
        // If tech does not exist, add to existing tech
        techShown.push({ toolID: uuidv4().replace(/-/g, ''), toolName: option })
        return techShown
      } else {
        // If tech does exist, remove from existing tech
        return techShown
      }
    }
  }

  const handleDeleteTech = (techID = '') => {
    // Force the rerender of the autocomplete component
    setResetComplete(prev => !prev)

    if (techID && displayTech && Boolean(displayTech.length)) {
      const tempTech = [...displayTech]
      const newUserTech = tempTech.filter((techInfo) => techInfo.toolID !== techID)

      if (newUserTech.length === 0) {
        setShowTechInput(false)
      }
      setDisplayTech(newUserTech)
    }

    if (lengthError) {
      setLengthError(false)
    }
  }

  // Check to see if the user selected an option from the popup or typed in a new tech
  const handleOptionName = (option) => {
    if (typeof option === 'string') {
      return option
    } else {
      return option.toolName
    }
  }

  const handleClose = () => {
    removeModalType()
    setInput('')
    setDisplayTech([])
    setEditTechOpen(false)
    setShowTechInput(false)
    dispatch(resetTechList())
    setShowTechInput(false)
    setShowHelperText(false)
    setShowUnsavedTechText(false)
    setLengthError(false)
  }

  const fireSuccess = () => {
    handleClose()
    dispatch(getUserDetails(userProfileID, () => { }, () => NotificationToast(true, 'Failed to get user details!')))
  }

  const fireFailure = () => NotificationToast(true, 'Failed to update tech!')

  const handleSubmit = () => {
    if (unsavedTechCheck) {
      setShowUnsavedTechText(true)
    } else {
      const techToAdd = displayTech.reduce((newTech, techInfo) => {
        if (techInfo && techInfo.toolID && techInfo.toolName) {
          const { toolID, toolName } = techInfo

          const techExists = userTech.find(
            (tech) => tech.toolID === toolID || Boolean(tech.toolName.toLowerCase().localeCompare(toolName.toLowerCase()) === 0)
          )

          if (!techExists) {
            const isTechOption = allTech.find(
              (tech) => tech.toolID === toolID || Boolean(tech.toolName.toLowerCase().localeCompare(toolName.toLowerCase()) === 0)
            )

            if (isTechOption && isTechOption.toolID) {
              newTech.currentTechToAdd.push(isTechOption.toolID)
            } else {
              newTech.newTechToAdd.push(toolName)
            }
          }
        }

        return newTech
      }, { currentTechToAdd: [], newTechToAdd: [] })

      const techToDelete = userTech.reduce((oldTech, techInfo) => {
        if (techInfo && techInfo.toolID && techInfo.toolName) {
          const { toolID, toolName } = techInfo

          if (displayTech && Boolean(displayTech.length)) {
            const techExists = displayTech.find(
              (tech) => tech.toolID === techInfo.toolID ||
                Boolean(tech.toolName.toLowerCase().localeCompare(toolName.toLowerCase()) === 0)
            )

            if (!techExists) { oldTech.techToDelete.push(toolID) }
          } else {
            oldTech.techToDelete.push(toolID)
          }
        }

        return oldTech
      }, { techToDelete: [] })

      const techForm = { ...techToAdd, ...techToDelete }
      dispatch(updateUserTech(techForm, fireSuccess, fireFailure))
      handleClose()
    }
  }

  const handleTechModalOpen = (e) => {
    e.stopPropagation()
    dispatch(getTechList())
    setEditTechOpen(true)
    setShowHelperText(false)
    setShowUnsavedTechText(false)
  }

  const handleKeyUp = (e) => {
    clearTimeout(timer)

    if (showTechInput && e.target.value && e.target.value.length !== 0) {
      timer = setTimeout(() => {
        setShowHelperText(true)
      }, 1700)
    } else {
      setShowHelperText(false)
    }
  }

  // ******************************** MAIN RENDER ********************************************
  return (
    <>
      {
        type === GET_USER_DETAILS && loading === true
          ? (
            <>
              <Grid container style={{ margin: matches ? '1em 2em 1em 1em' : '0 1em 1em 1em' }}>
                <Paper style={{ width: '100%' }}>
                  <Grid item container direction='row' alignItems='center' justifyContent='space-between' style={{ margin: '.2em .5em', padding: '.5rem' }} xs={12}>
                    <Skeleton animation='wave' width={100} />
                  </Grid>
                  <Divider />
                  <Grid item container direction='row' style={{ margin: '.5em 0' }}>
                    <Skeleton animation='wave' width={370} style={{ marginLeft: '1rem' }} />
                    <Skeleton animation='wave' width={200} style={{ marginLeft: '1rem' }} />
                  </Grid>
                </Paper>
              </Grid>
            </>
          ) : (
            <>
              <Grid container style={{ margin: matches ? '1em 2em 1em 1em' : '0 1em 1em 1em' }}>
                <Accordion expanded={matches || expandAccor} style={{ width: '100%' }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon style={{ display: matches ? 'none' : 'flex' }} />} style={{ height: '3.2em', minHeight: 0, cursor: matches ? 'default' : 'pointer' }} onClick={() => setExpandAccor(prev => !prev)}>
                    <Grid item container direction='row' alignItems='center' className={classes.accordionHeader} xs={12}>
                      <Typography variant='h4'>Fave Tech</Typography>
                      {canEdit && editMode && userTech && userTech.length !== 0 &&
                        <IconButton className={classes.editIcon} onClick={(e) => handleTechModalOpen(e)}>
                          <img src={EditIcon} alt='edit-icon' />
                        </IconButton>}
                    </Grid>
                  </AccordionSummary>
                  <Divider />
                  <Grid item container direction='row' style={{ margin: '.5em 0' }}>
                    {userTech && userTech.length !== 0
                      ? (
                        <Grid item container direction='row' style={{ padding: '.5em' }}>
                          {userTech.map((tech, i) => {
                            return (
                              <Button variant='contained' disabled className={classes.techChips} key={`tech ${tech.toolID}`}>{tech.toolName}</Button>
                            )
                          })}
                        </Grid>
                      )
                      : (
                        canEdit && editMode
                          ? (
                            <IconButton onClick={(e) => handleTechModalOpen(e)}>
                              <AddRoundedIcon className={classes.addIcon} />
                            </IconButton>
                          )
                          : (
                            <Grid item container justifyContent='center' style={{ textAlign: 'center', margin: '1em' }}>
                              <Typography variant='h6' style={{ textTransform: 'none', color: theme.palette.purple.darker }}>This user has not chosen any tech tools yet.</Typography>
                            </Grid>
                          )
                      )}
                  </Grid>
                </Accordion>
              </Grid>
            </>
          )
      }

      {/* Edit Tech Modal */}
      <Dialog
        fullWidth
        maxWidth='sm'
        open={editTechOpen}
        style={{ marginTop: navHeight }}
        onClose={handleClose}
        TransitionProps={{
          onEnter: () => handleModalEnter()
        }}
      >
        {
          type === GET_TECH_LIST && loading === true
            ? (
              <>
                <DialogTitle disableTypography>
                  <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                    <Grid item>
                      <Typography
                        variant='h4'
                        style={{ textTransform: 'none', color: theme.palette.purple.darkest, fontSize: '1.3rem' }}
                      >
                        Edit Fave Tech
                      </Typography>
                    </Grid>

                    <Grid item>
                      <IconButton style={{ padding: '0px' }} onClick={(e) => { e.preventDefault(); handleClose() }}>
                        <CloseRounded className={classes.customizedButton} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </DialogTitle>

                <DialogContent dividers>
                  <Grid container direction='column' style={{ margin: '1em' }}>
                    <Grid item container direction='column' style={{ marginBottom: '1em', paddingRight: '2em' }}>
                      <Skeleton animation='wave' width={515} height={65} style={{ marginTop: '-1rem' }} />
                      <Skeleton animation='wave' width={300} height={30} />
                    </Grid>
                    <Grid container direction='column' style={{ marginTop: '1em' }}>
                      <Skeleton animation='wave' width={100} style={{ marginTop: '-.5rem' }} />
                      <Skeleton animation='wave' width={250} height={60} style={{ marginBottom: '-1rem' }} />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Skeleton animation='wave' width={75} height={55} style={{ marginTop: '-.5rem' }} />
                </DialogActions>
              </>
            ) : (
              <>
                <DialogTitle disableTypography>
                  <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                    <Grid item>
                      <Typography
                        variant='h4'
                        style={{ textTransform: 'none', color: theme.palette.purple.darkest, fontSize: '1.3rem' }}
                      >
                        Edit Fave Tech
                      </Typography>
                    </Grid>

                    <Grid item>
                      <IconButton style={{ padding: '0px' }} onClick={(e) => { e.preventDefault(); handleClose() }}>
                        <CloseRounded className={classes.customizedButton} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </DialogTitle>

                <DialogContent dividers style={{ overflow: 'hidden' }}>
                  <Grid container direction='column' style={{ margin: '1em' }}>
                    <Typography style={{ marginTop: '.2em', color: `${theme.palette.grey.dark}`, fontWeight: 550 }} variant='body2'>Add up to 10 tech tools (Examples: Zoom, Clips, and Garageband)</Typography>
                    <Grid item container direction='column' style={{ marginBottom: '1em', paddingRight: '2em' }}>

                      {lengthError &&
                        <Typography style={{ marginTop: '.2em', color: 'red', fontWeight: 600 }} variant='caption'>You may only add 10 tech tools at a time.</Typography>}

                      {showHelperText &&
                        <Fade unmountOnExit in={Boolean(showHelperText && input)} style={{ transitionDelay: '50ms' }}>
                          <Typography
                            className={
                              (showHelperText && showUnsavedTechText && Boolean(input && input !== ''))
                                ? classes.animateHelperText : ''
                            }
                            style={{
                              marginTop: '.2em',
                              color: `${theme.palette.grey.dark}`,
                              fontWeight: 500,
                              visibility: showHelperText ? 'visible' : 'hidden'
                            }}
                            variant='body2'
                          >
                              Press Enter <KeyboardReturnRounded style={{ fontSize: '20px', margin: '0 4px -6px 4px' }} /> to add current tech tool
                          </Typography>
                        </Fade>}

                      {showTechInput
                        ? (
                          <Autocomplete
                            freeSolo
                            disableClearable
                            key={resetComplete}
                            debug
                            disabled={lengthError}
                            options={allTech}
                            getOptionLabel={(option) => handleOptionName(option)}
                            onKeyUp={(e) => handleKeyUp(e)}
                            onChange={(e, newValue) => handleOptionSelect(newValue)}
                            open={input && input.length >= 3 ? !false : false}
                            noOptionsText='No Tech Available'
                            renderInput={(params) => {
                              return (
                                <TextField
                                  {...params}
                                  placeholder='Add your favorite tech tools'
                                  style={{
                                    fontSize: '14px',
                                    fontFamily: 'Source Sans Pro',
                                    width: '100%',
                                    padding: 0
                                  }}
                                  margin='dense'
                                  variant='outlined'
                                  onChange={(e) => setInput(e.target.value)}
                                  value={input}
                                  InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    startAdornment: (
                                      <SearchRoundedIcon style={{ color: theme.palette.grey.dark, padding: '0em .3em' }} />
                                    )
                                  }}
                                />
                              )
                            }}
                          />
                        ) : (
                          <>
                            <Grid item container direction='row' alignItems='center' style={{ marginTop: '1rem' }}>
                              <Button
                                startIcon={<AddRoundedIcon style={{ color: theme.palette.purple.darkest }} />}
                                style={{ textTransform: 'none', fontWeight: '600', fontSize: '.9rem', color: theme.palette.purple.darkest, display: showTechInput ? 'none' : 'flex' }}
                                onClick={() => { setShowTechInput(true) }}
                              >
                                Add Fave Tech
                              </Button>
                            </Grid>
                          </>
                        )}
                    </Grid>
                    {displayTech && displayTech.length !== 0 &&
                      <Grid container direction='column' style={{ marginTop: '1em' }}>
                        <Typography variant='body1' style={{ color: theme.palette.black, fontWeight: '600' }}>{`${displayTech && displayTech.length ? displayTech.length : 0} selected`}</Typography>
                        <Grid container item direction='row'>
                          {displayTech.map((techInfo) => {
                            const { toolName, toolID } = techInfo
                            return (
                              <Button
                                key={`Display tech ${toolID}`}
                                variant='contained'
                                className={classes.techChipAction}
                                onClick={() => handleDeleteTech(toolID)}
                                endIcon={
                                  <CloseRounded style={{ fontSize: '16px' }} />
                                }
                              >
                                {toolName}
                              </Button>
                            )
                          })}
                        </Grid>
                      </Grid>}
                  </Grid>
                </DialogContent>
                <DialogActions>
                  {showUnsavedTechText && input && input !== '' &&
                    <>
                      <Grid
                        item
                        container
                        direction='row'
                        variant='body2'
                        justifyContent='flex-end'
                      >
                        <Typography styles={{ color: theme.palette.grey.dark, fontWeight: 600 }}>
                          You have unsaved tech tools! Exit anyway?
                          <span>
                            <Button
                              variant='text'
                              color='primary'
                              style={{ textTransform: 'none', margin: '0 5px', color: theme.palette.purple.darkest, fontWeight: 600 }}
                              onClick={handleClose}
                            >
                            Exit
                            </Button>
                          </span>
                        </Typography>
                      </Grid>
                    </>}
                  <Button variant='contained' color='primary' onClick={handleSubmit}>Save</Button>
                </DialogActions>
              </>
            )
        }
      </Dialog>
    </>
  )
}

export default UserTech
