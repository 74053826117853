import React, { useState, useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import {
  Grid, Typography, Paper, useTheme, Button, IconButton, useMediaQuery, Avatar,
  Popover, CircularProgress
} from '@material-ui/core'

import { EditRounded, CloseRounded, OpenInNewRounded } from '@material-ui/icons'
import { sanitizedHTML, renderSkeletons, artifactTypes, defaultTargetArtifactLED } from '../../../../utils'
import { NotificationToast } from '../../tools'
import { MCE_DETAILS, SET_TARGET_ARTIFACT_LED } from '../../../../redux/types'
import RubricRow from './RubricRow'
import AssessorComment from './AssessorComment'
import MCSectionGrading from './MCSectionGrading'
import MCEExistingLEDModal from './MCEExistingLEDModal'
import {
  updateMCEAttempt, getArtifactDetails, getUserLWOptions, getProfileEditData,
  getUserArtifacts, resetCurrentAttemptDetails, getSignedS3Url, uploadToSignedS3Url, getStandardsList
} from '../../../../redux/actions'
import { cloneDeep } from 'lodash'
import { workSampleTypes, mceSections } from '../../../../utils/variables'
import { uploadType, uploadFileFormatter } from '../../../../utils/uploadFunctions'

const rubricHeaders = {
  PROFICIENT: 'Proficient',
  BASIC: 'Basic',
  DEVELOPING: 'Developing'
}

const rubricValues = {
  PROFICIENT: 'P',
  BASIC: 'B',
  DEVELOPING: 'D'
}

const WorkSampleTemplate = (props) => {
  const {
    isSummaryView = true, submissionReview = false, isAssessorView = false,
    questionDetails = {}, classes = {}, attemptDetailsForm = {}, setAttemptDetailsForm = () => { },
    handleSaveAttempt = () => { }, setOpenLEDModal = () => { }, isPursuerView = false, isResultsView = false,
    handleGradeQuestion = () => { }, currentStyle = {}, currentSection, modifyComment = () => { }
  } = props
  const theme = useTheme()
  const dispatch = useDispatch()
  const mdScreen = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md))
  const loadingState = useSelector(state => state.loadingState)
  const { profileID = '', userID, token } = useSelector(state => state.auth)

  const { rubricRows = [], sampleType = workSampleTypes.LED, sampleDesc = '', sampleOrder = 1, title = '', workSampleID = '' } = questionDetails
  const currentSample = attemptDetailsForm?.P2?.find(x => x.workSampleID === workSampleID)
  const { title: artifactTitle = '', attemptSampleID = '', artifactID = '', isDraft = 0, fileName = '', fileType = '', filePath = null, rubricResponses = [], assessorGrade = null } = currentSample || {}
  // ************* STYLING CONDITIONALS ************* //
  // *********************************************** //

  // check to see if there is a sample currently associated with the sample
  const sampleUploaded = Boolean(attemptSampleID)

  const isLEDType = Boolean(sampleType && sampleType === workSampleTypes.LED)
  const isUploadType = Boolean(sampleType && sampleType === workSampleTypes.UPLOAD)

  const attemptSampleTitle = sampleType === workSampleTypes.LED ? artifactTitle : `${fileName}.${fileType}`

  // decide the styling of the buttons accessible to the pursuer
  const uploadButtonProps = { variant: 'contained', color: 'secondary', size: 'small', style: { fontWeight: 600 } }
  const uploadPaperStyle = {
    backgroundColor: !sampleUploaded ? 'transparent' : theme.palette.pink.dark,
    border: !sampleUploaded ? `solid 1px ${theme.palette.grey.medium}` : 'none',
    borderRadius: 3,
    padding: !sampleUploaded ? '1em' : '.5em'
  }

  // decide if we can render the upload buttons
  const renderUploadButtons = Boolean(!isSummaryView && !submissionReview && !isAssessorView && !sampleUploaded)

  // see if the rubric rows contain an auto fail row and isolate the ids
  const autoFailRowIDs = rubricRows.filter(x => x.autoFail).map(y => y.rubricID) || []
  // see if the developing text for an autofail row has been selected by an assessor
  const autoFailed = Boolean(autoFailRowIDs.length && rubricResponses.length && autoFailRowIDs.every(x => {
    const responseIndex = rubricResponses.findIndex(y => y.rubricID === x)
    if (responseIndex !== -1 && rubricResponses[responseIndex].assessorSelection === rubricValues.DEVELOPING) { return true } else { return false }
  }))

  // util to modify local array when samples are removed or added
  const modifyCurrentSampleArr = (newObj) => {
    const clonedAttemptForm = cloneDeep(attemptDetailsForm)

    // find the index of the sample that was removed in the attempt sample form
    const sampleIndex = clonedAttemptForm.P2.findIndex(x => x.workSampleID === workSampleID)

    // if we find it, replace it with the new one
    if (sampleIndex !== -1) {
      clonedAttemptForm.P2[sampleIndex] = newObj
    }

    // set the new, manipulated form to state
    setAttemptDetailsForm(clonedAttemptForm)
  }

  // Toast if the sample add/delete fails
  const modifyFailureFunc = () => {
    NotificationToast(true, 'Unable to modify sample. Please try again later.')
  }

  // *********************************************** //

  // ************** EXISTING LED MODAL  LOGIC******************* //
  // *********************************************************** //

  const [existingLEDOpen, setExistingLEDOpen] = useState(false)
  const [selectedExistingLED, setSelectedExistingLED] = useState('')
  const [loadingArts, setLoadingArts] = useState(false)

  // success for fetching a user's current LEDs
  const fetchLEDSuccess = () => {
    setExistingLEDOpen(true)
    setLoadingArts(false)
  }

  // failure for fetching user's current LED
  const fetchLEDSFailure = () => {
    NotificationToast(true, 'Unable to fetch existing artifacts.')
    setLoadingArts(false)
  }

  // Open the existing artifact modal
  const handleOpenExisting = () => {
    // let the UI know we are loading
    setLoadingArts(true)
    // reset the current selected LED
    setSelectedExistingLED('')

    // set a filter to fetch only LEDs(type), no drafts (forcePublic), but still fetch private artifacts (isForMCEAttempt)
    const filter = {
      creatorID: profileID,
      forcePublic: true,
      isForMCEAttempt: true,
      type: 'led'
    }

    dispatch(getUserArtifacts(filter, fetchLEDSuccess, fetchLEDSFailure))
  }

  // Close the existing artifact modal
  const handleCloseExisting = () => {
    setSelectedExistingLED('')
    setExistingLEDOpen(false)
    if (loadingArts) { setLoadingArts(false) }
  }

  // *********************************************** //

  // ************************ DELETE POPOVER ELEMENTS ************************* //
  // ************************************************************************** //

  const [warningAnchorEl, setWarningAnchorEl] = useState(null)
  const handleWarningPopover = (e) => {
    setWarningAnchorEl(e.currentTarget)
  }
  const handleWarningPopClose = () => {
    setWarningAnchorEl(null)
  }
  const openWarningPopover = Boolean(warningAnchorEl)
  const warningPopoverID = openWarningPopover ? 'warning-popover' : undefined
  // *********************************************** //

  // ********************* REMOVE A SAMPLE UPLOAD/ARTIFACT LOGIC ************************** //
  // ************************************************************************************** //

  // Once a sample has been removed isolate and reset the object within the attempt form and set to state
  const removeSuccessFunc = () => {
    // deep clone the full attempt form as well as the 'current sample' object
    const clonedCurrentAttSample = cloneDeep(currentSample)

    // use the base obj values for the majority of the new obj, but reset the attempt specific values to null
    const newAttSampleObj = {
      ...clonedCurrentAttSample,
      attemptSampleID: null,
      fileName: null,
      fileType: null,
      artifactID: null,
      s3Key: null,
      title: null,
      filePath: null,
      isDraft: 1
    }

    modifyCurrentSampleArr(newAttSampleObj)
  }

  // Dispatch a full update to remove/delete the selected sample
  const removeAttemptSample = () => {
    handleWarningPopClose()
    const { attemptID, mceID } = attemptDetailsForm
    const removeBody = {
      samplesToRemove: [{
        attemptSampleID
      }]
    }

    dispatch(updateMCEAttempt(removeBody, attemptID, mceID, removeSuccessFunc, modifyFailureFunc))
  }
  // *********************************************** //

  // ********************** GRADE RUBRIC LOGIC ************************* //
  // ******************************************************************* //

  // auto fail the work sample if the assessor selected the all developing text in any auto fail rubric rows
  useMemo(() => {
    if (autoFailed && (assessorGrade === null || assessorGrade)) {
      handleGradeQuestion(mceSections.PART2, workSampleID, 0)
    }
  }, [autoFailed, handleGradeQuestion, assessorGrade, workSampleID])

  const handleGradeRow = (id, value) => {
    // find the details of the row in the local attenpt form
    const tempRows = [...rubricResponses]
    const tempRow = tempRows.find(x => x.rubricID === id)

    // find the details of the row in the MCE details
    const rubricDetailRow = rubricRows.find(x => x.rubricID === id)
    const { autoFail = 0 } = rubricDetailRow

    // see if the assessor selected the developing text in an autofail row
    const autoFailSelected = Boolean(value === rubricValues.DEVELOPING && autoFail)
    // see if the assessor had previously selected the auto fail developing text but has not changed it
    const autoFailUnselected = Boolean(autoFailed && autoFail && value !== rubricValues.DEVELOPING)

    // if the selected row is already in the local attempt responses, modify its value directly
    if (tempRow) {
      // if the assessor has selected a value that was already checked, reset the value to null
      if (tempRow.assessorSelection && tempRow.assessorSelection === value) {
        tempRow.assessorSelection = null
      } else {
        // otherwise set it to the new value
        tempRow.assessorSelection = value
      }
    } else {
      // otherwise, it was not in the responses previsouly and we need to add it to the array
      tempRows.push({ rubricID: id, assessorSelection: value })
    }

    // if the assessor is modyfying an autofail row and has either newly selected the developing text or is unselecting the developing text
    if (autoFailSelected || autoFailUnselected) {
      // check to see if all autofail rows have had their developing text now selected
      const allAutoFailsSelected = autoFailRowIDs.every(x => {
        const responseIndex = tempRows.findIndex(y => y.rubricID === x)
        return Boolean(responseIndex !== -1 && tempRows[responseIndex].assessorSelection === rubricValues.DEVELOPING)
      })

      // if this selection met the all auto failed conditions, then run through all the rubric rows and set them to developing
      if (allAutoFailsSelected) {
        // go through each of the mce details rows for this sample
        rubricRows.forEach(r => {
          // look to see if the current itterations's rubricID is already in the local attempt responses
          const resRow = tempRows.find(x => x.rubricID === r.rubricID)

          // if it is not, add it in with the default value
          if (!resRow) {
            tempRows.push({ rubricID: r.rubricID, assessorSelection: rubricValues.DEVELOPING })
          } else {
            // otherwise, as long as it is not the id of the row we just modified above, find and modify the assessor selection directly
            if (r.rubricID !== id) {
              tempRows[tempRows.findIndex(x => x.rubricID === r.rubricID)].assessorSelection = rubricValues.DEVELOPING
            }
          }
        })
      }
    }

    // modify the sample with the new responses array
    const tempSample = { ...currentSample, rubricResponses: tempRows }
    // find the index of the sample
    const sampleIndex = attemptDetailsForm.P2.findIndex(x => x.workSampleID === tempSample.workSampleID)

    // as long as we have the correct index
    if (sampleIndex !== -1) {
      const tempP2 = [...attemptDetailsForm.P2]
      // set the new sample obj to the part 2 of the attempt form
      tempP2[sampleIndex] = tempSample
      setAttemptDetailsForm({ ...attemptDetailsForm, P2: tempP2 })
    }
  }
  // *********************************************** //

  // ********************** GRADE SAMPLE LOGIC ************************* //
  // ******************************************************************* //
  const handleGrade = (newGrade) => {
    const questionID = workSampleID
    const checkedSame = assessorGrade === newGrade
    const gradeValue = assessorGrade !== null && checkedSame ? null : newGrade
    handleGradeQuestion(mceSections.PART2, questionID, gradeValue)
  }

  const itemGraded = assessorGrade !== null
  // *********************************************** //

  // ************** HANDLERS TO MOVE TO ARTIFACT PROCESS ********** //
  // ************************************************************** //

  // handle moving to the artifact creation/edit process
  const handleMCELed = (editBool = false) => {
    const { attemptID = '', mceID = '' } = attemptDetailsForm
    const draftParam = isDraft !== null ? Boolean(isDraft) : true

    const params = {
      ...(submissionReview ? { public: artifactTypes.LED } : { create: artifactTypes.LED }),
      isForMCE: true,
      mce: mceID,
      attempt: attemptID,
      sample: workSampleID,
      ...(submissionReview ? { artifact: artifactID } : { submission: 'no', draft: draftParam }),
      ...(editBool && { editing: true })
    }

    const searchParams = new URLSearchParams(params).toString()
    props.history.push({
      pathname: '/artifact',
      search: `?${searchParams}`
    })

    // reset the current redux state when leaving th epage so the use effects will catch on the way back and reset the form to the new details
    dispatch(resetCurrentAttemptDetails())
  }

  // save the current answers and move to the artifact view
  const updateAndMoveToLed = (edit) => {
    handleSaveAttempt(false, false)
    handleMCELed(edit)
  }

  // open a new tab with the link to the upload type sample
  const handlePreviewUpload = (e) => {
    e.preventDefault()
    window.open(filePath, '_blank')
  }

  // decide which view to preview the work sample based on the sample type
  const handleSamplePreview = (e) => {
    // if not in the pursuer view
    if (submissionReview || isAssessorView) {
      // if led, fetch details of the artifact and open preview modal
      if (isLEDType) {
        handleFetchArtifactDetails(artifactID)
      }

      // otherwise, as long as we have a file path, open a new tab
      if (isUploadType && filePath) {
        handlePreviewUpload(e)
      }
    }
    return false
  }

  // function for successful fetch of sample artifact details
  const fireDetailSuccess = () => {
    if (!submissionReview && isPursuerView) {
      updateAndMoveToLed(true)
    } else {
      setOpenLEDModal(true)
    }
  }

  // function to handle notifying the user of a bad call to fetch the artifact details
  const fireDetailFailure = () => {
    NotificationToast(true, 'Something went wrong. Please try again later.')
  }

  // when editing an existing artifact, pull the necessary details before navigating to the artifact page
  const handleFetchArtifactDetails = (artifactID, dupeBool = false) => {
    const profilePath = `/profile?user=${profileID}`

    // If the user has chosen to use an existing artifact (denoted by 'dupeBool') we need to first fetch and manipulate the details of the existing artifact to use as a duplicate
    // otherwise we can move straight to the artifact creation process
    const successFunc = !dupeBool ? fireDetailSuccess : handleDuplicateArtifact

    // basic filter to fetch the user LW options (!! NOTE: THIS WILL PROBABLY NEED TO BE ADJUSTED !!!)
    const defaultFilter = {
      creatorID: profileID,
      forcePublic: true,
      isForMCEAttempt: true
    }

    // if we have an artifactID in the arguments, fetch the needed LW and user data and any current artifact details
    // passing in dupeBool to the details call will allow for a seperate successfunc to be returned from the detail call
    if (artifactID) {
      dispatch(getUserLWOptions({ ...defaultFilter, type: 'lw' }))
      dispatch(getProfileEditData(() => { }, () => NotificationToast(true, 'Failed to get profile edit data!')))
      dispatch(getArtifactDetails(artifactID, profilePath, artifactTypes.LED, successFunc, fireDetailFailure, '', dupeBool))
      dispatch(getStandardsList())
    }
  }

  // If the user is duplicating an existing artifact, manipulate the details to use as a base for the new artifact
  const handleDuplicateArtifact = (data) => {
    // set the modal to close
    setExistingLEDOpen(false)

    // if artifact data was returned from the redux action, use it as a base
    if (data) {
      // Information of the artifact to be duplicated
      const {
        ISTEStandards = [], actContent = '', artifactID = '', artifactType = '', completionTime = 0,
        coreSubjectID = '', createContent = '', engageContent = '', grades = [], investigateContent = '',
        overview = '', secondarySubjectID = '', stateStandards = '', targets = [], techNeeded = '', title = '', workType = ''
      } = data

      // Assign the original artifactID as the 'forkedFrom' value
      const duplicateForkedFrom = artifactID
      // init a new target array
      let duplicateTargetArray = []

      // Give the targets (if available) a new target ID to be associated with the new duplicate artifact
      if (targets && targets.length) {
        duplicateTargetArray = targets.reduce((arr, target) => {
          if (target) {
            const { targetName = '' } = target
            const uuid = uuidv4().replace(/-/g, '')
            arr.push({ targetID: `LT${uuid}`, targetName })
          }
          return arr
        }, [])
      }

      // Format the new details, without the original artifact's social/user-linked details
      const duplicateDetails = {
        ...defaultTargetArtifactLED,
        title,
        forkedFrom: duplicateForkedFrom,
        targets: duplicateTargetArray,
        ISTEStandards,
        actContent,
        createContent,
        engageContent,
        investigateContent,
        artifactType,
        completionTime,
        coreSubjectID,
        secondarySubjectID,
        grades,
        overview,
        stateStandards,
        techNeeded,
        workType
      }

      // now set these new details as the target artifact before moving to the artifact creation process
      // (allows for the details to be accessible in the artifact process, while still considering the artifact as a 'new' artifact)
      dispatch({ type: SET_TARGET_ARTIFACT_LED, payload: duplicateDetails })
      updateAndMoveToLed()
    }
  }

  // *********************************************** //

  // ********************** UPLOAD SAMPLE LOGIC ************************* //

  const [fileSizeError, setFileSizeError] = useState(false)
  const [fileExtensionError, setFileExtensionError] = useState(false)
  const [fileUploadError, setUploadError] = useState(false)

  // Modify the local array with the new upload sample obj
  const fireAddSuccess = (info) => {
    const { samplesToAdd: [{ attemptSampleID: newID, fileName: newName, fileType: newType, filePath: newPath }] } = info
    const clonedCurrentAttSample = cloneDeep(currentSample)

    const newAttSampleObj = {
      ...clonedCurrentAttSample,
      attemptSampleID: newID,
      fileName: newName,
      fileType: newType,
      artifactID: null,
      filePath: newPath
    }

    modifyCurrentSampleArr(newAttSampleObj)
  }

  // reset all upload related errors
  const fileErrorReset = () => { setFileSizeError(false); setFileExtensionError(false); setUploadError(false) }

  // handle the upload of the selected resource to s3
  const handleImageUpload = async (e, file) => {
    e.preventDefault()

    // Reset errors on initial upload to clear out previous
    fileErrorReset()

    // check for errors within the sample types/size and format for upload
    const type = uploadType.MCE_SAMPLE_UPLOAD
    const resourceResult = await uploadFileFormatter(file, type, [], setFileSizeError, setFileExtensionError)

    // if the formatter returned no errors, continue
    if (resourceResult) {
      const { resourceName, fileExtension, contentType, resourceID, uploadType } = resourceResult

      // fetch the full s3 upload url
      const { uploadURL: signedUrl, cloudFrontPath = '' } = await getSignedS3Url({ uploadID: resourceID, type: contentType, userID, token, uploadType })

      if (signedUrl && typeof signedUrl === 'string') {
        // upload direct to s3
        if (file && file.length) {
          const [fileData] = file
          const success = await uploadToSignedS3Url({ file: fileData, signedUrl, type: contentType, uploadType })

          if (success) {
            // once successful, fully update the db with new upload
            const { attemptID, mceID } = attemptDetailsForm
            const addBody = {
              samplesToAdd: [{
                attemptSampleID: resourceID,
                workSampleID,
                fileName: resourceName,
                fileType: fileExtension,
                filePath: cloudFrontPath
              }]
            }

            return dispatch(updateMCEAttempt(addBody, attemptID, mceID, fireAddSuccess, modifyFailureFunc))
          }
        }
      }
    }

    // if unsuccessful or if the formatter returned errors, set the upload error
    return setUploadError(true)
  }

  return (
    <>
      {Boolean(isAssessorView || isResultsView) &&
        <MCSectionGrading
          itemGraded={itemGraded}
          question={currentSample}
          handleGrade={handleGrade}
          currentSection={currentSection}
          submissionReview={submissionReview}
          isAssessorView={isAssessorView}
          rubricRows={rubricRows}
          rubricResponses={rubricResponses}
          autoFailed={autoFailed}
          isResultsView={isResultsView}
        />}
      <Grid
        item
        container
        direction='column'
        spacing={5}
        style={{
          backgroundColor: isSummaryView ? 'transparent' : currentStyle?.secondaryColor,
          padding: isSummaryView ? '0' : '2em',
          borderRadius: 5,
          marginTop: '.5em',
          marginBottom: '1em'
        }}
      >
        <Grid container item direction='row' spacing={6}>
          {/* WORK SAMPLE DETAILS */}
          <Grid item xs={mdScreen ? null : 4} container direction='column' justifyContent='center' spacing={2}>
            <Grid item>
              <Typography variant='body1' style={{ fontSize: 16, fontWeight: 600, textAlign: 'left' }}>{sampleOrder}. {title}</Typography>
              {loadingState.type === MCE_DETAILS && loadingState.loading === true
                ? (
                  <>
                    {renderSkeletons(4, 'wave', 40)}
                  </>
                )
                : <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(sampleDesc) }} className={classes.sampleDescHTML} />}
            </Grid>
            {!isSummaryView &&
              <Grid item container direction='column' spacing={2}>
                <Grid item style={{ maxWidth: '-webkit-fill-available' }}>
                  <Paper elevation={!sampleUploaded ? 0 : 2} style={{ ...uploadPaperStyle, ...((submissionReview || isAssessorView) && { cursor: 'pointer' }) }} onClick={(e) => handleSamplePreview(e)}>
                    {!sampleUploaded &&
                      <Grid item container direction='row' justifyContent='center' alignItems='center'>
                        <Typography style={{ fontWeight: 600, color: theme.palette.grey.medium }}>None Selected</Typography>
                      </Grid>}
                    {sampleUploaded &&
                      <Grid item container direction='row' justifyContent='space-between' alignItems='center'>
                        <Grid item container direction='row' xs={7}>
                          <Grid item style={{ width: 'inherit' }}>
                            <Typography noWrap style={{ fontWeight: 600, fontSize: 16, color: 'white', maxWidth: 'inherit' }}>{attemptSampleTitle}</Typography>
                          </Grid>
                          {Boolean(isLEDType && artifactID && isDraft) &&
                            <Grid item style={{ width: 'inherit' }}>
                              <Typography variant='caption' noWrap style={{ fontWeight: 600, color: '#ffffffcc' }}>Draft</Typography>
                            </Grid>}
                        </Grid>
                        {Boolean(!submissionReview && isPursuerView) &&
                          <Grid item container direction='row' xs={5} justifyContent='flex-end' alignItems='center'>
                            {isLEDType &&
                              <Grid item style={{ paddingRight: '.5em' }}>
                                <IconButton size='small' onClick={() => { handleFetchArtifactDetails(artifactID) }}>
                                  <Avatar style={{ backgroundColor: '#ffffff3d', height: 24, width: 24 }}>
                                    <EditRounded style={{ color: 'white', fontSize: 16 }} />
                                  </Avatar>
                                </IconButton>
                              </Grid>}
                            {isUploadType &&
                              <Grid item style={{ paddingRight: '.5em' }}>
                                <IconButton size='small' onClick={(e) => { handlePreviewUpload(e) }}>
                                  <Avatar style={{ backgroundColor: '#ffffff3d', height: 24, width: 24 }}>
                                    <OpenInNewRounded style={{ color: 'white', fontSize: 16 }} />
                                  </Avatar>
                                </IconButton>
                              </Grid>}
                            <Grid item>
                              <IconButton size='small' onClick={(e) => { handleWarningPopover(e) }}>
                                <Avatar style={{ backgroundColor: '#ffffff3d', height: 24, width: 24 }}>
                                  <CloseRounded style={{ color: 'white', fontSize: 16 }} />
                                </Avatar>
                              </IconButton>
                            </Grid>
                          </Grid>}
                      </Grid>}
                  </Paper>
                </Grid>
                {renderUploadButtons &&
                  <Grid item container direction='row' justifyContent='flex-end' spacing={2}>
                    {isLEDType &&
                      <>
                        <Grid item>
                          <Button {...uploadButtonProps} onClick={(e) => { e.preventDefault(); updateAndMoveToLed() }}>Create New</Button>
                        </Grid>
                        <Grid item>
                          <Button {...uploadButtonProps} onClick={() => handleOpenExisting()}>
                            {loadingArts && <CircularProgress size={18} style={{ color: 'white', margin: '.2em' }} />}
                            {!loadingArts && 'Use Existing'}
                          </Button>
                        </Grid>
                      </>}
                    {isUploadType &&
                      <>
                        <input
                          accept='audio/mp3, audio/wav, image/*, video/*, .ppt, .pptx, .doc, .docx, .pdf, .xls, .xlsx, .pages, .key, .numbers, .txt, .mkv, .avi'
                          style={{ display: 'none' }}
                          id={`${workSampleID}-upload-button-file`}
                          type='file'
                          onChange={(e) => handleImageUpload(e, e.target.files)}
                        />
                        <label htmlFor={`${workSampleID}-upload-button-file`}>
                          <Grid item>
                            <Button {...uploadButtonProps} component='span'>Upload</Button>
                          </Grid>
                        </label>
                      </>}
                  </Grid>}
                {fileSizeError &&
                  <Grid item container direction='row' justifyContent='center'>
                    <Typography variant='caption' style={{ color: 'red' }}>The selected file is over the max file size.</Typography>
                  </Grid>}

                {fileExtensionError &&
                  <Grid item container direction='row' justifyContent='center'>
                    <Typography variant='caption' style={{ color: 'red' }}>The selected file type is not allowed.</Typography>
                  </Grid>}

                {fileUploadError &&
                  <Grid item container direction='row' justifyContent='center'>
                    <Typography variant='caption' style={{ color: 'red' }}>There was an error uploading your file. Please try again.</Typography>
                  </Grid>}
              </Grid>}
          </Grid>

          <Grid item xs={mdScreen ? 12 : 8} container direction='column' style={{ padding: mdScreen ? '1em 0' : '1em 2em' }}>
            {/* RUBRIC */}
            <Grid item container direction='column' xs={mdScreen ? 12 : null}>
              <Paper style={{ height: '100%', backgroundColor: 'white', borderRadius: 4, padding: '.5em' }}>
                {/* RUBRIC HEADER ROW */}
                <Grid item container direction='row' style={{ padding: '1em' }}>
                  {Object.values(rubricHeaders).map((header, i) => {
                    return (
                      <Grid key={`${i}-rubric-header-${header}`} item container xs={4} justifyContent='center' alignItems='flex-start'>
                        <Paper elevation={3} style={{ padding: mdScreen ? '.2em .5em' : '.2em 4em', borderRadius: 2, backgroundColor: theme.palette.pink.dark }}>
                          <Typography variant='body1' style={{ color: 'white', fontWeight: 600 }}>{header}</Typography>
                        </Paper>
                      </Grid>
                    )
                  })}
                </Grid>
                {/*  MAPPED ROWS OF RUBRIC */}
                {rubricRows.map((row) => {
                  const { rubricID } = row
                  const formRow = rubricResponses?.find(x => x.rubricID === rubricID)
                  return (
                    <RubricRow
                      key={`${rubricID}-rubricRow`}
                      {...row}
                      formRow={formRow}
                      autoFailed={autoFailed}
                      autoFailRowIDs={autoFailRowIDs}
                      submissionReview={submissionReview}
                      isAssessorView={isAssessorView}
                      isResultsView={isResultsView}
                      handleGradeRow={handleGradeRow}
                      classes={classes}
                      isSummaryView={isSummaryView}
                    />
                  )
                })}
              </Paper>
            </Grid>
          </Grid>

          {/* CHOOSE EXISTING MODAL */}
          <MCEExistingLEDModal
            classes={classes}
            existingLEDOpen={existingLEDOpen}
            handleCloseExisting={handleCloseExisting}
            selectedExistingLED={selectedExistingLED}
            setSelectedExistingLED={setSelectedExistingLED}
            handleFetchArtifactDetails={handleFetchArtifactDetails}
          />

          {/* DELETE POPOVER */}
          <Popover
            id={warningPopoverID}
            open={openWarningPopover}
            anchorEl={warningAnchorEl}
            onClose={handleWarningPopClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            <Grid container direction='column' style={{ padding: '1em' }}>
              <Grid item container direction='column' justifyContent='flex-start'>
                <Typography variant='h5' style={{ color: theme.palette.purple.darkest, textTransform: 'none', fontWeight: 600 }}>Are You Sure You Want To Delete This Sample?</Typography>
                <Typography gutterBottom style={{ fontWeight: 600 }}>This action will permanently delete this sample, and cannot be undone.</Typography>
              </Grid>
              <Grid item container justifyContent='flex-end' style={{ marginTop: '.5em' }}>
                <Grid item>
                  <Button
                    variant='text'
                    style={{
                      textTransform: 'none',
                      fontWeight: 600,
                      color: theme.palette.purple.darkest
                    }}
                    onClick={() => handleWarningPopClose()}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant='contained'
                    size='small'
                    style={{
                      backgroundColor: 'red',
                      color: 'white',
                      textTransform: 'none',
                      fontWeight: 900
                    }}
                    onClick={() => removeAttemptSample()}
                  >
                    Delete Sample
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Popover>
        </Grid>

        {/* COMMENT BOX */}
        {Boolean(!isPursuerView || (isPursuerView && currentSample?.lastAttemptComment)) &&
          <AssessorComment
            currentStyle={currentStyle}
            isAssessorView={isAssessorView}
            defaultOpen={Boolean((!isPursuerView && currentSample?.attemptComment) || (isPursuerView && currentSample?.lastAttemptComment))}
            modifyQuestionComment={modifyComment}
            currentQuestion={currentSample}
            handleGradeQuestion={handleGradeQuestion}
            questionSection={mceSections.PART2}
            isPursuerView={isPursuerView}
          />}
      </Grid>
    </>
  )
}

export default withRouter(WorkSampleTemplate)
