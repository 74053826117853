import { trackPromise } from 'react-promise-tracker'
import { parseResponse } from '../../lib'
import {
  GET_ASSESSOR_ASSIGNMENTS, UPDATE_ASSESSOR_STATUS
} from '../types'

const api = process.env.REACT_APP_API_URL

export const getCurrentMCEAssignments = (fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    const { userID, token, isAssessor } = getState().auth

    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }

    const response = await trackPromise(window.fetch(`${api}/v1/assessor/${userID}/attempt`, options), 'fetching-assessor-assignments')
    const parsedResponse = parseResponse(response, dispatch)
    if (!parsedResponse) { return false }

    if (parsedResponse.error) {
      fireFailure()
    } else {
      const { data } = await response.json()

      // if the user's assessor status has changed, update the auth object
      if ((data?.isAssessor || data.isAssessor === 0) && isAssessor !== data.isAssessor) {
        dispatch({
          type: UPDATE_ASSESSOR_STATUS,
          payload: Boolean(data.isAssessor)
        })
      }

      // if the user is an assessor, update their assigmnet list, or set it to blank array
      dispatch({
        type: GET_ASSESSOR_ASSIGNMENTS,
        payload: data?.assignments || []
      })

      fireSuccess()
    }
  }
}

export const updateAssessorAttemptStatus = (attemptID, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    const { userID, token } = getState().auth

    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }

    const response = await trackPromise(window.fetch(`${api}/v1/assessor/${userID}/attempt/${attemptID}`, options))
    const parsedResponse = parseResponse(response, dispatch)
    if (!parsedResponse) { return false }

    if (parsedResponse.error) {
      fireFailure()
    } else {
      fireSuccess()
    }
  }
}

export const updateAssessorGrade = (attemptInfo, attemptID, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    const { userID, token } = getState().auth
    const body = JSON.stringify(attemptInfo)

    const options = {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body
    }

    const response = await trackPromise(window.fetch(`${api}/v1/assessor/${userID}/attempt/${attemptID}`, options))
    const parsedResponse = parseResponse(response, dispatch)
    if (!parsedResponse || parsedResponse.error) {
      fireFailure()
      return false
    } else {
      fireSuccess()
    }
  }
}
