import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'

import {
  Grid, Paper, Typography,
  Popover, Button,
  makeStyles, useTheme
} from '@material-ui/core'

import {
  Repeat
} from '@material-ui/icons'

import { LedDuplicateInfo } from '../../tools'

import { getArtifactDetails } from '../../../../redux/actions'

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    width: '80%',
    padding: '1.5em',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      '@media only screen and (orientation: landscape)': {
        marginLeft: '1em'
      }
    }
  }
}))

const ArtifactDuplications = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()

  const parsedProps = queryString.parse(props.location.search)

  const { targetArtifactLED: { duplicates = [] } } = useSelector(state => state.artifacts)

  // ProfileID for selected duplication
  const [targetProfileID, setTargetProfileID] = useState('')
  const [targetDuplicateType, setTargetDuplicateType] = useState('')
  const [targetDuplicateArtifactID, setTargetDuplicateArtifactID] = useState('')
  const [targetDuplicateProfileID, setTargetDuplicateProfileID] = useState('')

  // Confirm popper related elements --- //
  const [confirmAnchorEl, setConfirmAnchorEl] = useState(null)

  const handleConfirmPopover = (e, artType, artifactID, profileID) => {
    setConfirmAnchorEl(e.currentTarget)
    setTargetDuplicateType(artType)
    setTargetDuplicateArtifactID(artifactID)
    setTargetDuplicateProfileID(profileID)
  }
  const handleConfirmPopClose = () => {
    setConfirmAnchorEl(null)
    setTargetDuplicateType('')
    setTargetDuplicateArtifactID('')
    setTargetDuplicateProfileID('')
  }
  const openConfirmPopover = Boolean(confirmAnchorEl)
  // ----- *** ----- //

  // Set selected target duplicate artifact users profileID and dispatch action to get details for artifact, then handle routing
  const dispatchChange = async (artType, artifactID, artifactProfileID) => {
    setTargetProfileID(artifactProfileID)
    await dispatch(getArtifactDetails(artifactID, '', artType, handlePublicFetchSuccess, handlePublicFetchFailure, ''))
  }

  // Handle routing the user to a nested artifact and retrieve those new details
  const handleArtifactViewChange = async (e, artType, artifactID, artifactProfileID) => {
    // Check if user is on the create view to show confirmation popover for moving away from view
    if (parsedProps && parsedProps.create) {
      handleConfirmPopover(e, artType, artifactID, artifactProfileID)
    // Otherwise, go ahead and take user to selected duplicate artifact
    } else {
      dispatchChange(artType, artifactID, artifactProfileID)
    }
  }

  // Confirmation function to dispatch view change
  const confirmMoveToDuplicate = () => {
    handleConfirmPopClose()
    dispatchChange(targetDuplicateType, targetDuplicateArtifactID, targetDuplicateProfileID)
  }

  // If the selected artifact is Private the call will fail, we catch this with the unavailable param and display a relevant message
  const handlePublicFetchFailure = (formatType) => {
    props.history.push({
      pathname: '/artifact',
      search: `?public=${formatType}&user=${targetProfileID}&artifact=unavailable`
    })
  }

  const handlePublicFetchSuccess = (formatType, submission, draft, artifactID, profileID) => {
    setTimeout(() => {
      props.history.push({
        pathname: '/artifact',
        search: `?public=${formatType}&user=${targetProfileID}&artifact=${!artifactID ? 'unavailable' : artifactID}`
      })
    }, 250)
  }

  return (
    <>
      {/* Main Content  */}
      <Paper className={classes.paperRoot}>
        {/* Header Info  */}
        <Grid item container direction='row' alignItems='center' style={{ marginBottom: '2rem' }}>
          <Repeat style={{ fontSize: '28px', color: theme.palette.grey.dark, marginRight: '.3em' }} />
          <Typography variant='h4'>Artifact Duplications</Typography>
        </Grid>

        <Grid item container direction='row'>
          {/* List all of the artifacts that have been duplicated from this original artifact */}
          {duplicates && duplicates.length !== 0
            ? (
              <>
                {duplicates.map(duplicate => {
                  const { ledID = '' } = duplicate
                  return (
                    <LedDuplicateInfo
                      key={`led-duplicate-${ledID}`}
                      duplicate={duplicate}
                      theme={theme}
                      handleArtifactViewChange={handleArtifactViewChange}
                    />
                  )
                })}
              </>
            )
            : (
              <Grid container justifyContent='center' item style={{ marginBottom: '.5em' }}>
                <Typography variant='body1' style={{ color: theme.palette.grey.medium }}>There are no duplications of this artifact yet.</Typography>
              </Grid>
            )}
        </Grid>
      </Paper>

      <Popover
        id={openConfirmPopover ? 'confirm-popover' : undefined}
        open={openConfirmPopover}
        anchorEl={confirmAnchorEl}
        onClose={handleConfirmPopClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <Grid container direction='column' style={{ padding: '1em' }}>
          <Typography
            variant='h4'
            style={{ color: theme.palette.purple.darkest }}
            gutterBottom
          >
            Are you sure you want to move away from editing the current artifact?
          </Typography>

          <Typography variant='body1' gutterBottom>Any changes made will not be saved.</Typography>

          <Grid item container direction='row' justifyContent='flex-end' alignItems='center' style={{ marginTop: '.5em' }}>
            <Button onClick={handleConfirmPopClose} style={{ marginRight: '.3em', textTransform: 'none', fontWeight: '600' }}>Cancel</Button>
            <Button variant='contained' color='primary' onClick={(e) => { e.preventDefault(); confirmMoveToDuplicate() }}>Confirm</Button>
          </Grid>
        </Grid>
      </Popover>
    </>
  )
}

export default withRouter(ArtifactDuplications)
