import React from 'react'
import {
  AppBar, Toolbar, Grid, Typography, IconButton, useTheme
} from '@material-ui/core'

import { ChevronLeft } from '@material-ui/icons'

const BuilderNav = (props) => {
  const {
    setAppBarRef = () => {}, exitToAdmin = () => {}
  } = props

  const theme = useTheme()

  return (
    <>
      <AppBar style={{ zIndex: 10 }} ref={setAppBarRef}>
        <Toolbar style={{ backgroundColor: theme.palette.purple.darkest }}>
          <Grid container direction='row'>
            <Grid item xs={10} sm={8} md={6} container direction='row' alignContent='center' style={{ paddingLeft: '1em' }}>
              <Grid item>
                <IconButton onClick={(e) => { e.preventDefault(); exitToAdmin() }}>
                  <ChevronLeft style={{ color: 'white' }} />
                </IconButton>
              </Grid>
              <Grid item style={{ marginLeft: '.5em', display: 'flex', alignItems: 'center' }}>
                <Typography variant='h4' style={{ color: 'white' }}>Micro-Credential Builder</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default BuilderNav
