import React, { useEffect, useState, useMemo, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import {
  createLED,
  updateArtifact,
  createLW,
  deleteArtifact,
  createProject,
  resetUserLEDs,
  resetTargetEFR,
  updateArtifactResubmit,
  insertNewEFR,
  updateEFRDetails,
  deleteEFR
} from '../../redux/actions'

import {
  useTheme, Grid, Typography,
  Slide, Button, Popover
} from '@material-ui/core'

import ArtifactDetails from '../ui/artifacts/ArtifactDetails'
import ArtifactResources from '../ui/artifacts/LEDForms/ArtifactResources'
import ArtifactSummary from '../ui/artifacts/LEDForms/ArtifactSummary'
import LEDone from '../ui/artifacts/LEDForms/LEDone'
import LEDtwo from '../ui/artifacts/LEDForms/LEDtwo'
import LEDLearnerWork from '../ui/artifacts/LEDForms/LEDLearnerWork'
import ArtifactDuplications from '../ui/artifacts/LEDForms/ArtifactDuplications'
import CreateSideMenu from '../ui/artifacts/CreateSideMenu'
import EFRDetails from '../ui/artifacts/EdFarmResourceForms/EFRDetails'

import ProjectItems from '../ui/artifacts/ProjectForms/ProjectItems'

import { isEqual } from 'lodash'
import { BaseToast, NotificationToast, ArtifactCancelModal } from '../ui/tools/index'
import { PublishArtifactModal, SubmitToEdFarmModal } from '../ui/modals'
import {
  artifactTypes, efrSubTypes, ValidateUrl, statusTypes,
  defaultTargetArtifactLED, defaultTargetArtifactLW, defaultTargetArtifactPRO, defaultTargetArtifactEFR,
  defaultEditLEDinfo, defaultEditLWInfo, defaultEditProInfo
} from '../../utils'

import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'

const CreateArtifact = (props) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const {
    forms = [], styles = {}, classes = {}, artifactType = '', setArtifactStatus = () => { }, handleArtifactSectionUpdate = () => { },
    artifactCancelModalOpen = false, setArtifactCancelModalOpen = () => { }, artifactStatus = '', defaultDuplicateForm, duplicateArtifactForm,
    returnToMCEAttempt
  } = props

  const parsedProps = queryString.parse(props.location.search)
  const [efSubmit, setEfSubmit] = useState(Boolean(parsedProps.submission && parsedProps.submission === 'yes'))

  // determine if user has clicked to edit existing artifact
  const isEditing = parsedProps && parsedProps.editing ? Boolean(parsedProps.editing) : false
  const isDraft = parsedProps && parsedProps.draft ? JSON.parse(parsedProps.draft) : false

  const isForMCE = parsedProps && parsedProps.isForMCE ? Boolean(parsedProps.isForMCE) : false

  // number of currently selected artifact form
  const [currentForm, setCurrentForm] = useState(0)
  // state for setting error message when name is missing and required fields are missing
  const [nameError, setNameError] = useState(false)
  const [missingFieldsError, setMissingFieldsError] = useState(false)
  const [resourceLinkInvalid, setResourceLinkInvalid] = useState(false)
  const [missingAttachmentsError, setMissingAttachmentsError] = useState(false)

  // Modal that populates on first creation of an artifact
  const [publishModalOpen, setPublishModalOpen] = useState(false)

  const [efSubmitModalOpen, setEFSubmitModalOpen] = useState(false)

  const [isPrivate, setIsPrivate] = useState(efSubmit ? !true : true)

  const resubmitConfirmed = useRef(false)

  /* LEARNING EXPERIENCE DESIGN STATE (Start) */
  // default object for learning experience design info
  const defaultLEDInfo = useMemo(() => ({
    ...defaultTargetArtifactLED,
    isPrivate: efSubmit ? 0 : 1,
    isForSubmission: efSubmit ? 1 : 0,
    targets: [{ targetID: 'base', targetName: '' }]
  }), [efSubmit])

  // state to update when creating new or editing existing learning experience design
  const [ledInfo, setLEDInfo] = useState(defaultLEDInfo)
  const [editInfo, setEditInfo] = useState(defaultEditLEDinfo)

  // If the artifact is a duplicate then set the ledInfo to the duplicated information and reset the form.
  useMemo(() => {
    if (!isEqual(defaultDuplicateForm, duplicateArtifactForm.current)) {
      if (defaultDuplicateForm !== duplicateArtifactForm.current) {
        const tempCopy = { ...ledInfo, ...duplicateArtifactForm.current }
        setLEDInfo(tempCopy)
        setIsPrivate(false)
        duplicateArtifactForm.current = defaultDuplicateForm
      }
    }
  }, [duplicateArtifactForm, defaultDuplicateForm, ledInfo])

  // ID for a new LW created during the LED process -- This is used to delete that LW if the LED process is canceled
  const [newlyCreatedLearnerWorkID, setNewlyCreatedLearnerWorkID] = useState('')

  /* LEARNER WORK STATE (Start) */
  // default object for learner work info
  const defaultNewLearnerWorkForm = useMemo(() => ({
    ...defaultTargetArtifactLW,
    isPrivate: efSubmit ? 0 : 1,
    isForSubmission: efSubmit ? 1 : 0,
    isForMCE: isForMCE ? 1 : 0
  }), [efSubmit, isForMCE])

  const { ledList, userLWOptions, targetArtifactLED = defaultLEDInfo, targetArtifactLW = defaultNewLearnerWorkForm, targetArtifactEFR, targetArtifactPRO } = useSelector(state => state.artifacts)
  /* LEARNING EXPERIENCE DESIGN STATE (End) */

  // state to update when creating new or editing existing learner work
  const [newLearnerWorkForm, setNewLearnerWorkForm] = useState(defaultNewLearnerWorkForm)
  const [editLWInfo, setEditLWInfo] = useState(defaultEditLWInfo)

  // object containing info for selected learner work when linking to LED
  const [selectedLearnerWork, setSelectedLearnerWork] = useState(null)

  /* LEARNER WORK STATE (End) */

  /* PROJECT STATE (Start) */
  // default object for project info
  const defaultProjectInfo = useMemo(() => ({
    ...defaultTargetArtifactPRO,
    isPrivate: efSubmit ? 0 : 1,
    isForSubmission: efSubmit ? 1 : 0,
    isDraft: 1
  }), [efSubmit])

  // state to update when creating new or editing existing project
  const [newProjectForm, setNewProjectForm] = useState(defaultProjectInfo)
  const [selectedLEDs, setSelectedLEDs] = useState([])
  const [currentlyLinkedLEDs, setCurrentlyLinkedLEDs] = useState([])
  const [selectedLWs, setSelectedLWs] = useState([])
  const [currentlyLinkedLWs, setCurrentlyLinkedLWs] = useState([])
  /* PROJECT STATE (End) */

  /* ------ ED FARM RESOURCE BASE LOGIC START ----- */

  const defaultEFRForm = useMemo(() => ({
    ...defaultTargetArtifactEFR
  }), [])
  const [newEFRForm, setNewEFRForm] = useState(defaultEFRForm)

  // Formatted dates for the submission form
  const [eventDate, setEventDate] = useState(null)
  const [eventStartTime, setEventStartTime] = useState(null)
  const [eventEndTime, setEventEndTime] = useState(null)

  // Moment objects for the date picker values
  const [startTime, setStartTime] = useState(null)
  const [endTime, setEndTime] = useState(null)
  const [displayEventDate, setDisplayEventDate] = useState(null)

  const formMissingDetailsCheck = newEFRForm.resourceName !== '' && newEFRForm.resourceAudience !== '' &&
  newEFRForm.resourceDescription !== '' && newEFRForm.resourceType !== '' && newEFRForm.resourceLink !== ''

  const efrResourceLinkValid = ValidateUrl(newEFRForm.resourceLink)

  /* ----- ED FARM RESOURCE BASE LOGIC END ------ */

  /* CHECKS FOR REQUIRED FIELDS (Start) */
  // check if required fields have been filled in on details section of learning experience design
  const artifactDetailsCheck = ledInfo.title !== '' && ledInfo.grades.length > 0 && ledInfo.completionTime !== 0 &&
    ledInfo.workType !== '' && ledInfo.techNeeded !== '' && ledInfo.coreSubjectID !== '' && ledInfo.completionTime !== ''

  // check if required fields have been filled in on led one section of learning experience design
  const ledOneCheck = ledInfo.overview !== '' && ledInfo.targets && ledInfo.targets.length !== 0 &&
    ledInfo.targets.reduce((arr, targetInfo) => {
      if (targetInfo && targetInfo.targetName) { arr.push(targetInfo.targetName) }
      return arr
    }, []).join('') !== ''

  // check if required fields have been filled in on led two section of learning experience design
  const ledTwoCheck = ledInfo.engageContent !== '' && ledInfo.investigateContent !== '' &&
    ledInfo.createContent !== '' && ledInfo.actContent !== ''

  // check if required fields have been filled in for learner work
  const learnerWorkCheck = newLearnerWorkForm.learnerWorkTitle !== '' && newLearnerWorkForm.grades.length > 0 &&
    newLearnerWorkForm.dateCompleted !== '' && newLearnerWorkForm.workType !== '' && newLearnerWorkForm.coreSubjectID !== ''

  const projectCheck = newProjectForm.projectTitle !== '' && newProjectForm.projectDescription !== '' && newProjectForm.projectLength !== 0
  /* CHECKS FOR REQUIRED FIELDS (End) */

  useEffect(() => {
    if (!parsedProps || parsedProps === {}) {
      props.history.push({ pathname: '/error' })
    }
  }, [parsedProps, props.history])

  const [targetArtifactComments, setTargetArtifactCommments] = useState([])

  // Format artifact object for page display when editing using current artifact info pulled from db and stored in redux
  useEffect(() => {
    if ((isEditing || isForMCE) && targetArtifactLED && artifactType === 'Learning Experience Design') {
      if (isEqual(ledInfo, defaultLEDInfo)) {
        const tempCopy = Object.assign({}, targetArtifactLED)

        if (tempCopy && tempCopy.attachments) {
          tempCopy.attachments = tempCopy.attachments.reduce((arr, attachmentInfo) => {
            if (attachmentInfo && attachmentInfo.attachmentID && attachmentInfo.fileName) {
              const { attachmentID = '', file = '', fileName = '', filePath = '', fileType = '' } = attachmentInfo
              arr.push({ attachmentID, file, fileName, filePath, fileType })
            }
            return arr
          }, [])
        }
        setIsPrivate(tempCopy.isPrivate === 0 ? false : !false)
        setLEDInfo({ ...tempCopy })
      }
    }
  }, [isEditing, targetArtifactLED, artifactType, defaultLEDInfo, ledInfo, isForMCE])

  useEffect(() => {
    if (isEditing) {
      let newComments = []
      const learnerWorkID = ledInfo.learnerWorkID
      if (artifactType === 'Learning Experience Design' && targetArtifactLED) {
        const { commentList } = targetArtifactLED
        newComments = commentList
      }
      if (ledInfo && userLWOptions && learnerWorkID && learnerWorkID !== '' && isEditing && selectedLearnerWork === null) {
        const lwObject = userLWOptions.find(lw => lw.artifactID === learnerWorkID)
        if (lwObject) {
          setSelectedLearnerWork(lwObject)
        }
      }

      if (artifactType === 'Learner Work' && targetArtifactLW) {
        const { commentList } = targetArtifactLW
        newComments = commentList
      }

      if (artifactType === 'Project' && targetArtifactPRO) {
        const { commentList } = targetArtifactPRO
        newComments = commentList
      }

      if (newComments && newComments.length) {
        setTargetArtifactCommments(newComments)
      }
    }
  }, [targetArtifactLED, targetArtifactLW, targetArtifactPRO, isEditing, artifactType, ledInfo, userLWOptions, selectedLearnerWork])

  useEffect(() => {
    if (isEditing && targetArtifactPRO && artifactType === 'Project') {
      if (isEqual(newProjectForm, defaultProjectInfo)) {
        const tempCopy = Object.assign({}, targetArtifactPRO)
        setNewProjectForm({ ...tempCopy })
        setSelectedLEDs(tempCopy.projectLEDs)
        setCurrentlyLinkedLEDs(tempCopy.projectLEDs)
        setSelectedLWs(tempCopy.projectLWs)
        setCurrentlyLinkedLWs(tempCopy.projectLWs)
        setMissingAttachmentsError(false)
        setIsPrivate(tempCopy.isPrivate === 0 ? false : !false)
      }
    }
  }, [isEditing, targetArtifactPRO, artifactType, defaultProjectInfo, newProjectForm])

  useEffect(() => {
    if (isEditing && targetArtifactLW && artifactType === 'Learner Work') {
      if (isEqual(newLearnerWorkForm, defaultNewLearnerWorkForm)) {
        const tempCopy = Object.assign({}, targetArtifactLW)

        if (tempCopy && tempCopy.links) {
          tempCopy.links = tempCopy.links.reduce((arr, linkInfo) => {
            if (linkInfo && linkInfo.link && linkInfo.linkID) {
              const { link, linkID } = linkInfo
              arr.push({ link, linkID })
            }
            return arr
          }, [])
        }

        if (tempCopy && tempCopy.attachments) {
          tempCopy.attachments = tempCopy.attachments.reduce((arr, attachmentInfo) => {
            if (attachmentInfo && attachmentInfo.attachmentID && attachmentInfo.fileName) {
              const { attachmentID = '', file = '', fileName = '', filePath = '', fileType = '' } = attachmentInfo
              arr.push({ attachmentID, file, fileName, filePath, fileType })
            }
            return arr
          }, [])
        }

        setIsPrivate(tempCopy.isPrivate === 0 ? false : !false)
        setNewLearnerWorkForm({ ...tempCopy })
      }
    }
  }, [targetArtifactLW, isEditing, artifactType, defaultNewLearnerWorkForm, newLearnerWorkForm])

  useEffect(() => {
    if (isEditing && artifactType === artifactTypes.EFR && targetArtifactEFR) {
      if (isEqual(newEFRForm, defaultEFRForm)) {
        const tempCopy = Object.assign({}, targetArtifactEFR)
        const { startDate, endDate } = tempCopy

        if (tempCopy && tempCopy.resourceType === efrSubTypes.EFR_EVENT) {
          // convert timestamps from the api into moment objects
          const momentEventDate = moment(startDate * 1000)
          const momentStartTime = moment(startDate * 1000)
          const momentEndTime = moment(endDate * 1000)

          // Format the moment objects for the submission form
          setEventDate(momentEventDate.format('l'))
          setEventStartTime(momentStartTime.format('HH:mm'))
          setEventEndTime(momentEndTime.format('HH:mm'))

          // Set the date picker values to the moment objects
          setStartTime(momentStartTime)
          setEndTime(momentEndTime)
          setDisplayEventDate(momentEventDate)
        }
        setNewEFRForm({ ...tempCopy, bannerUpdated: false })
      }
    }
  }, [isEditing, artifactType, targetArtifactEFR, defaultEFRForm, newEFRForm])

  // updates string value in learningTargetsArray on associated index
  const handleLearningTargetsChange = (value, position) => {
    const { targets } = { ...ledInfo }
    const tempTargets = [...targets]
    tempTargets[position].targetName = value
    setLEDInfo({ ...ledInfo, targets: tempTargets })
  }

  const handleTargetAdd = () => {
    const { targets } = { ...ledInfo }
    const tempArray = [...targets]
    const uuid = uuidv4().replace(/-/g, '')
    tempArray.push({ targetID: `LT${uuid}`, targetName: '' })

    // Check to make sure that you can add no more than 5 total targets
    if (ledInfo && targets && targets.length <= 4) {
      setLEDInfo({ ...ledInfo, targets: tempArray })
    }
  }

  const handleTargetRemove = (targetID = '') => {
    const tempArray = [...ledInfo.targets]
    const newTargets = tempArray.filter((targetInfo) => targetInfo.targetID !== targetID)
    setLEDInfo({ ...ledInfo, targets: newTargets })

    if (isEditing && targetID && targetArtifactLED && targetArtifactLED.targets && targetArtifactLED.targets.length) {
      if (
        editInfo && editInfo.targetsToDelete &&
        targetArtifactLED.targets.find((targetInfo) => targetInfo.targetID === targetID)
      ) {
        const tempTargets = [...editInfo.targetsToDelete]
        const removeID = tempTargets.find((target) => target === targetID)

        if (removeID) {
          const newTargets = tempTargets.filter((target) => target !== targetID)
          setEditInfo({ ...editInfo, targetsToDelete: newTargets })
        } else {
          tempTargets.push(targetID)
          setEditInfo({ ...editInfo, targetsToDelete: tempTargets })
        }
      }
    }
  }

  // Reset state on exit.
  const handleReset = (type, ledID = null) => {
    setCurrentForm(0)
    setNameError(false)
    setSelectedLearnerWork(null)
    setMissingFieldsError(false)
    setResourceLinkInvalid(false)
    setMissingAttachmentsError(false)
    setArtifactStatus('')
    if (!isForMCE) {
      props.history.goBack()
    } else {
      returnToMCEAttempt(ledID)
    }

    // In the case of a an EFR being edited from explore, this reset is needed to trigger new details being fetched when returning to the public view
    const { EFR } = artifactTypes
    if (type === EFR) { dispatch(resetTargetEFR()) }

    if (ledList !== []) dispatch(resetUserLEDs())
  }

  // Delete popper related elements (Start) ****
  const [deleteAnchorEl, setDeleteAnchorEl] = useState(null)

  const handleDeletePopover = (e) => {
    setDeleteAnchorEl(e.currentTarget)
  }
  const handleDeletePopClose = () => {
    setDeleteAnchorEl(null)
  }
  const openDeletePopover = Boolean(deleteAnchorEl)
  // Delete popper related elements (End) ****

  // Function for confirming to cancel creating or editing an artifact. Delete LW if one was created on LED.
  const handleCancelConfirm = () => {
    setArtifactCancelModalOpen(false)
    const { LED, LW, PRO } = artifactTypes

    // If editing, handle notes / comments section on artifact being seen / hidden
    if (isEditing) {
      const artifactID = artifactType === LED ? ledInfo.artifactID : artifactType === LW ? newLearnerWorkForm.artifactID : artifactType === PRO ? newProjectForm.artifactID : newEFRForm.resourceID
      const exitingArtifact = true
      handleArtifactSectionUpdate(currentForm, artifactID, exitingArtifact)
    }

    // Delete newly created LearnerWork during LED process if canceling creating / editing LED
    if (artifactType === LED) {
      if (newlyCreatedLearnerWorkID !== '') {
        dispatch(deleteArtifact(newlyCreatedLearnerWorkID, () => { handleReset(artifactType) }, () => { handleReset(artifactType) }))
      } else {
        if (!isForMCE) {
          props.history.goBack()
        } else {
          returnToMCEAttempt()
        }
      }
    } else {
      props.history.goBack()
    }
  }

  // ***** Handles Saving EFRs specifically ***** //

  const fireEFRCreateFail = () => {
    NotificationToast(true, 'Failed to create resource!')
  }
  const fireEFRUpdateFailure = () => {
    NotificationToast(true, 'Failed to update resource!')
  }

  // Checks that the provided dates are valid
  const dateErrorsCheck = (date, startTime, endTime) => {
    const currentTime = moment().startOf('day').unix()
    const selectedDay = moment(date).startOf('day').unix()

    if (selectedDay < currentTime) {
      NotificationToast(true, 'Event date cannot fall before the current date.')
      return false
    }

    if (endTime <= startTime) {
      NotificationToast(true, 'End time cannot be before start time.')
      return false
    }

    return true
  }

  const EFRNotCompleteCheck = artifactType && artifactType === artifactTypes.EFR &&
    ((!formMissingDetailsCheck) ||
      (newEFRForm.resourceType === efrSubTypes.EFR_EVENT &&
        (!eventDate || eventDate === 0 || !eventStartTime || eventStartTime === 0 || !eventEndTime || eventEndTime === 0)))

  // Create or Update an EFR
  const handleEFRSubmit = async () => {
    if (!formMissingDetailsCheck) {
      return setMissingFieldsError(true)
    }

    // Format the values provided by the inputs into a single time stamp for start and end time
    const startUnix = moment(`${eventDate} ${eventStartTime}`).unix()
    const endUnix = moment(`${eventDate} ${eventEndTime}`).unix()

    // If we are creating/updating an event, verify that we have dates and that the dates are valid
    if (newEFRForm.resourceType === efrSubTypes.EFR_EVENT) {
      if (!eventDate || eventDate === 0 || !eventStartTime || eventStartTime === 0 || !eventEndTime || eventEndTime === 0) {
        return setMissingFieldsError(true)
      }
      const checkDates = await dateErrorsCheck(eventDate, startUnix, endUnix)
      if (!checkDates) {
        return false
      }
    }

    // Be sure we have a valid resource link
    if (!efrResourceLinkValid) { return setResourceLinkInvalid(true) }

    // Set the form to use use the validated dates, or 0 if not an event subtype
    const newForm = {
      ...newEFRForm,
      startDate: eventDate && eventDate !== 0 ? startUnix : 0,
      endDate: eventDate && eventDate !== 0 ? endUnix : 0
    }

    if (isEditing) {
      // Update an existing resource
      const resourceID = newForm.artifactID
      dispatch(updateEFRDetails(resourceID, newForm, fireEFRUpdateSuccess, fireEFRUpdateFailure))
    } else {
      // create a new resource
      dispatch(insertNewEFR(newForm, fireSuccess, fireEFRCreateFail))
    }
  }

  /*
    Save and exit artifact creation process as long as a name is provided and set isDraft to 1.
    Currently has condition to handle 'learner work' but there's no option to save and exit one.
  */

  const artifactNoNameCheck = artifactType &&
    ((artifactType === 'Learning Experience Design' && ledInfo && ledInfo.title === '') ||
      (artifactType === 'Learner Work' && newLearnerWorkForm && newLearnerWorkForm.learnerWorkTitle === '') ||
        (artifactType === 'Project' && newProjectForm && newProjectForm.projectTitle === '') ||
          (artifactType === 'Ed Farm Resource' && newEFRForm && newEFRForm.resourceName === ''))

  const artifactNotCompleteCheck = artifactType &&
  ((artifactType === 'Learning Experience Design' && (artifactDetailsCheck === false || ledOneCheck === false || ledTwoCheck === false)) ||
    (artifactType === 'Learner Work' && learnerWorkCheck === false) ||
      (artifactType === 'Project' && (projectCheck === false || (!selectedLEDs.length && !selectedLWs.length))))

  const handleSaveArtifact = (firstPublish = false) => {
    const commonDetails = {
      isDraft: firstPublish ? 0 : isDraft ? 1 : 0,
      isPrivate: efSubmit ? 0 : isPrivate ? 1 : 0,
      isForSubmission: efSubmit ? 1 : 0,
      isForMCE: isForMCE ? 1 : 0
    }

    if (artifactType && artifactType === artifactTypes.EFR) {
      handleEFRSubmit()
    } else {
      // NOTE: this probably needs to be set specifically by either a state item or ref when hitting the 'resubmit to edfarm' button
      // As is, this will most likely reset any approved/denied artifact as needReview
      const allowForResubmit = artifactStatus && artifactStatus !== statusTypes.needReview && !isDraft && !isForMCE

      const newEditInfo = formatArtifact(artifactType)

      const successFunc = firstPublish ? fireSuccess : fireUpdateSuccess
      const failFunc = firstPublish ? fireFailure : fireUpdateFailure

      if (!isDraft && artifactNotCompleteCheck) { return setMissingFieldsError(true) }

      if (artifactType === 'Learning Experience Design') {
        const tempLedInfo = { ...ledInfo, ...commonDetails }

        if (isEditing) {
          const artifactInfo = { ...tempLedInfo, ...newEditInfo }

          setEditInfo({ ...newEditInfo })

          if (artifactInfo.artifactID) {
            const exitingArtifact = true

            handleArtifactSectionUpdate(currentForm, artifactInfo.artifactID, exitingArtifact)

            if (allowForResubmit) {
              dispatch(updateArtifactResubmit(artifactInfo.artifactID, artifactInfo, successFunc, failFunc))
            } else {
              dispatch(updateArtifact(artifactInfo.artifactID, artifactInfo, successFunc, failFunc))
            }
          } else {
            // Should not happen
            NotificationToast(false, 'Could not locate artifact!', true)
          }
        } else {
          tempLedInfo.links = tempLedInfo.links.reduce((arr, linkInfo) => {
            if (linkInfo && linkInfo.link) { arr.push(linkInfo.link) }
            return arr
          }, [])
          tempLedInfo.targets = tempLedInfo.targets.reduce((arr, targetInfo) => {
            if (targetInfo && targetInfo.targetName) { arr.push(targetInfo.targetName) }
            return arr
          }, [])
          dispatch(createLED(tempLedInfo, successFunc, failFunc))
        }
      }

      if (artifactType === 'Learner Work') {
        const tempLWForm = { ...newLearnerWorkForm, ...commonDetails }

        if (isEditing) {
          const artifactInfo = { ...tempLWForm, ...newEditInfo }

          const artifactID = artifactInfo.artifactID

          setEditLWInfo({ ...newEditInfo })

          if (artifactID) {
            const exitingArtifact = true
            handleArtifactSectionUpdate(currentForm, artifactID, exitingArtifact)

            if (allowForResubmit) {
              dispatch(updateArtifactResubmit(artifactID, artifactInfo, successFunc, failFunc))
            } else {
              dispatch(updateArtifact(artifactID, artifactInfo, successFunc, failFunc))
            }
          } else {
            // Should not happen
            NotificationToast(false, 'Could not locate artifact.', true)
          }
        } else {
          tempLWForm.links = tempLWForm.links.filter(item => item)
          tempLWForm.dateCompleted = moment().unix(tempLWForm.dateCompleted)
          tempLWForm.ledIDsToAdd = selectedLEDs
          dispatch(createLW(tempLWForm, successFunc, failFunc))
        }
      }

      if (artifactType === 'Project') {
        // NOTE: may need to look into this after new functionality is fully implemented and removal of bottom nav is done
        // if (!selectedLEDs.length && !selectedLWs.length) { return setMissingAttachmentsError(true) }

        const tempProjForm = {
          ...newProjectForm,
          ...commonDetails
        }

        if (isEditing) {
          const artifactInfo = {
            ...tempProjForm,
            ...newEditInfo
          }

          if (artifactInfo.artifactID) {
            const exitingArtifact = true
            handleArtifactSectionUpdate(currentForm, artifactInfo.artifactID, exitingArtifact)

            if (allowForResubmit) {
              dispatch(updateArtifactResubmit(artifactInfo.artifactID, artifactInfo, successFunc, failFunc))
            } else {
              dispatch(updateArtifact(artifactInfo.artifactID, artifactInfo, successFunc, failFunc))
            }
          } else {
            // Should not happen
            NotificationToast(false, 'Could not locate artifact!', true)
          }
        } else {
          const newProjForm = {
            ...tempProjForm,
            projectLEDs: selectedLEDs,
            projectLWs: selectedLWs
          }

          dispatch(createProject(newProjForm, successFunc, failFunc))
        }
      }
    }
  }

  const handleEdit = (resourceID = '', resourceType = '') => {
    if (!resourceID || !resourceType || !isEditing) { return false }

    if (artifactType === 'Learning Experience Design') {
      const { links: ledLinks = [], attachments: ledAttachments = [] } = targetArtifactLED

      switch (resourceType) {
        case 'links': {
          if (ledLinks && ledLinks.length && editInfo) {
            if (
              editInfo && editInfo.linksToDelete &&
              targetArtifactLED.links.find((linkInfo) => linkInfo.linkID === resourceID)
            ) {
              const tempLinks = [...editInfo.linksToDelete]
              const removeID = tempLinks.find((link) => link === resourceID)

              if (removeID) {
                const newTargets = tempLinks.filter((link) => link !== resourceID)
                setEditInfo({ ...editInfo, linksToDelete: newTargets })
              } else {
                tempLinks.push(resourceID)
                setEditInfo({ ...editInfo, linksToDelete: tempLinks })
              }
            }
          }
          break
        }
        case 'attachments': {
          if (ledAttachments && ledAttachments.length && editInfo) {
            if (
              editInfo && editInfo.attachmentsToDelete &&
              targetArtifactLED.attachments.find((attachmentInfo) => attachmentInfo.attachmentID === resourceID)
            ) {
              const tempAttachments = [...editInfo.attachmentsToDelete]
              const removeID = tempAttachments.find((attachment) => attachment === resourceID)

              if (removeID) {
                const newAttachments = tempAttachments.filter((attachment) => attachment !== resourceID)
                setEditInfo({ ...editInfo, attachmentsToDelete: newAttachments })
              } else {
                tempAttachments.push(resourceID)
                setEditInfo({ ...editInfo, attachmentsToDelete: tempAttachments })
              }
            }
          }

          break
        }
        default: break
      }
    }

    if (artifactType === 'Learner Work') {
      const { links: lwLinks = [], attachments: lwAttachments = [] } = targetArtifactLW

      switch (resourceType) {
        case 'links': {
          if (lwLinks && lwLinks.length && editLWInfo) {
            if (
              editLWInfo && editLWInfo.linksToDelete &&
              targetArtifactLW.links.find((linkInfo) => linkInfo.linkID === resourceID)
            ) {
              const tempLinks = [...editLWInfo.linksToDelete]
              const removeID = tempLinks.find((link) => link === resourceID)

              if (removeID) {
                const newTargets = tempLinks.filter((link) => link !== resourceID)
                setEditLWInfo({ ...editLWInfo, linksToDelete: newTargets })
              } else {
                tempLinks.push(resourceID)
                setEditLWInfo({ ...editLWInfo, linksToDelete: tempLinks })
              }
            }
          }
          break
        }
        case 'attachments': {
          if (lwAttachments && lwAttachments.length && editLWInfo) {
            if (
              editLWInfo && editLWInfo.attachmentsToDelete &&
              targetArtifactLW.attachments.find((attachmentInfo) => attachmentInfo.attachmentID === resourceID)
            ) {
              const tempAttachments = [...editLWInfo.attachmentsToDelete]
              const removeID = tempAttachments.find((attachment) => attachment === resourceID)

              if (removeID) {
                const newAttachments = tempAttachments.filter((attachment) => attachment !== resourceID)
                setEditLWInfo({ ...editLWInfo, attachmentsToDelete: newAttachments })
              } else {
                tempAttachments.push(resourceID)
                setEditLWInfo({ ...editLWInfo, attachmentsToDelete: tempAttachments })
              }
            }
          }

          break
        }
        default: break
      }
    }
  }

  // Notify user that artifact has been updated and take back to profile.
  const fireUpdateSuccess = (ledID) => {
    NotificationToast(false, 'Successfully updated artifact!')
    handleReset(artifactType, ledID)
  }

  // Notify user that artifact failed to update.
  const fireUpdateFailure = () => NotificationToast(true, 'Failed to update artifact!')

  const formatArtifact = (artifactType) => {
    // handle formatting arrays when editing a Learning Experience Design
    if (artifactType === 'Learning Experience Design') {
      const { targets = [], links = [], grades = [], attachments = [], ISTEStandards = [] } = ledInfo
      const {
        targets: ledTargets = [], links: ledLinks = [], grades: ledGrades = [], attachments: ledAttachments = [],
        ISTEStandards: ledStandards = []
      } = targetArtifactLED

      const newEditInfo = { ...editInfo }
      newEditInfo.isPrivate = efSubmit ? 0 : isPrivate ? 1 : 0
      newEditInfo.targetsToAdd = []
      newEditInfo.targetsToUpdate = []
      newEditInfo.linksToAdd = []
      newEditInfo.linksToUpdate = []
      newEditInfo.gradesToAdd = []
      newEditInfo.gradesToDelete = []
      newEditInfo.attachmentsToAdd = []
      newEditInfo.attachmentsToUpdate = []
      newEditInfo.ISTEStandardsToAdd = []
      newEditInfo.ISTEStandardsToDelete = []

      if (targets && targets.length && ledTargets && Array.isArray(ledTargets)) {
        const newTargets = []
        const updateTargets = []

        targets.forEach((targetInfo) => {
          if (targetInfo && targetInfo.targetID && targetInfo.targetName) {
            const { targetID, targetName } = targetInfo
            const hasArtifact = ledTargets.find((target) => target.targetID === targetID)

            if (!hasArtifact) {
              newTargets.push(targetName)
            } else {
              if (hasArtifact && hasArtifact.targetName && targetInfo.targetName && targetInfo.targetName !== hasArtifact.targetName) {
                updateTargets.push({ ...hasArtifact, targetName: targetInfo.targetName })
              }
            }
          }
        })

        if (newTargets && newTargets.length) { newEditInfo.targetsToAdd = newTargets }
        if (updateTargets && updateTargets.length) { newEditInfo.targetsToUpdate = updateTargets }
      }

      if (links && links.length && ledLinks && Array.isArray(ledLinks)) {
        const newLinks = []
        const updateLinks = []

        links.forEach((linkInfo) => {
          if (linkInfo && linkInfo.linkID && linkInfo.link) {
            const { linkID, link: linkName } = linkInfo
            const hasLink = ledLinks.find((link) => link.linkID === linkID)

            if (!hasLink) {
              newLinks.push(linkName)
            } else {
              if (hasLink && hasLink.link && linkInfo.link && linkInfo.link !== hasLink.link) {
                updateLinks.push({ ...hasLink })
              }
            }
          }
        })

        if (newLinks && newLinks.length) { newEditInfo.linksToAdd = newLinks }
        if (updateLinks && updateLinks.length) { newEditInfo.linksToUpdate = updateLinks }
      }

      if (grades && grades.length && ledGrades && Array.isArray(ledGrades)) {
        const newGrades = []
        const deleteGrades = []

        grades.forEach((gradeID = '') => {
          if (gradeID) {
            const hasGrade = ledGrades.find((gradeInfo) => gradeInfo === gradeID)
            if (!hasGrade) { newGrades.push(gradeID) }
          }
        })

        ledGrades.forEach((gradeID = '') => {
          if (gradeID) {
            const hasGrade = grades.find((gradeInfo) => gradeInfo === gradeID)
            if (!hasGrade) { deleteGrades.push(gradeID) }
          }
        })

        if (newGrades && newGrades.length) { newEditInfo.gradesToAdd = newGrades }
        if (deleteGrades && deleteGrades.length) { newEditInfo.gradesToDelete = deleteGrades }
      }

      if (attachments && attachments.length && ledAttachments && Array.isArray(ledAttachments)) {
        const newAttachments = []
        const updateAttachments = []

        attachments.forEach((attachmentInfo) => {
          if (attachmentInfo && attachmentInfo.attachmentID) {
            const hasAttachment = ledAttachments.find((attachment) => attachment.attachmentID === attachmentInfo.attachmentID)

            if (!hasAttachment) {
              newAttachments.push({ ...attachmentInfo })
            } else {
              // Update attachment name if the file name doesn't match the current one
              if (hasAttachment && hasAttachment.fileName && attachmentInfo.fileName && attachmentInfo.fileName !== hasAttachment.fileName) {
                updateAttachments.push({ ...hasAttachment, fileName: attachmentInfo.fileName })
              }
            }
          }
        })

        if (newAttachments && newAttachments.length) { newEditInfo.attachmentsToAdd = newAttachments }
        // If there is an updated attachment and it is not empty, update name in the api
        if (updateAttachments && updateAttachments.length) { newEditInfo.attachmentsToUpdate = updateAttachments }
      }

      if (ISTEStandards && ledStandards && Array.isArray(ledStandards)) {
        const newStandards = []
        const deleteStandards = []

        // If the edited form has ISTEStandards, compare it with the original artifact form
        if (ISTEStandards.length) {
          // For each standard, check to see if it was already in the original list, if not, the user has added to the array
          ISTEStandards.forEach((standardID = '') => {
            if (standardID) {
              const hasStandard = ledStandards.find((standardInfo) => standardInfo === standardID)
              if (!hasStandard) { newStandards.push(standardID) }
            }
          })
        } else {
          // If the edit form had no standards, check to see if the original form had standards
          if (ledStandards.length) {
            // If so, then the user has deleted all of the original standards
            newEditInfo.ISTEStandardsToDelete = ledStandards
          }
        }

        // If the original form had standrs, compare it with the array from the edit form.
        ledStandards.forEach((standardID = '') => {
          if (standardID) {
            // If the edit form has indices but a specific index from the edit form is not in the original array, the user has deleted a standard
            if (ISTEStandards.length) {
              const hasStandard = ISTEStandards.find((standardInfo) => standardInfo === standardID)
              if (!hasStandard) { deleteStandards.push(standardID) }
            }
          }
        })

        if (newStandards && newStandards.length) { newEditInfo.ISTEStandardsToAdd = newStandards }
        if (deleteStandards && deleteStandards.length) { newEditInfo.ISTEStandardsToDelete = deleteStandards }
      }

      return newEditInfo
    }

    // handle formatting arrays when editing Learner Work
    if (artifactType === 'Learner Work') {
      const { links = [], grades = [], attachments = [] } = newLearnerWorkForm
      const {
        links: lwLinks = [], grades: lwGrades = [], attachments: lwAttachments = []
      } = targetArtifactLW

      const newEditInfo = { ...editLWInfo }
      newEditInfo.isPrivate = efSubmit ? 0 : isPrivate ? 1 : 0
      newEditInfo.gradesToAdd = []
      newEditInfo.gradesToDelete = []
      newEditInfo.linksToAdd = []
      newEditInfo.linksToUpdate = []
      newEditInfo.attachmentsToAdd = []
      newEditInfo.attachmentsToUpdate = []

      if (links && links.length && lwLinks && Array.isArray(lwLinks)) {
        const newLinks = []
        const updateLinks = []

        links.forEach((linkInfo) => {
          if (linkInfo && linkInfo.linkID && linkInfo.link) {
            const { linkID, link: linkName } = linkInfo
            const hasLink = lwLinks.find((link) => link.linkID === linkID)

            if (!hasLink) {
              newLinks.push(linkName)
            } else {
              if (hasLink && hasLink.link && linkInfo.link && linkInfo.link !== hasLink.link) {
                updateLinks.push({ ...hasLink })
              }
            }
          }
        })

        if (newLinks && newLinks.length) { newEditInfo.linksToAdd = newLinks }
        if (updateLinks && updateLinks.length) { newEditInfo.linksToUpdate = updateLinks }
      }

      if (grades && grades.length && lwGrades && Array.isArray(lwGrades)) {
        const newGrades = []
        const deleteGrades = []

        grades.forEach((gradeID = '') => {
          if (gradeID) {
            const hasGrade = lwGrades.find((gradeInfo) => gradeInfo === gradeID)
            if (!hasGrade) { newGrades.push(gradeID) }
          }
        })

        lwGrades.forEach((gradeID = '') => {
          if (gradeID) {
            const hasGrade = grades.find((gradeInfo) => gradeInfo === gradeID)
            if (!hasGrade) { deleteGrades.push(gradeID) }
          }
        })

        if (newGrades && newGrades.length) { newEditInfo.gradesToAdd = newGrades }
        if (deleteGrades && deleteGrades.length) { newEditInfo.gradesToDelete = deleteGrades }
      }

      if (attachments && attachments.length && lwAttachments && Array.isArray(lwAttachments)) {
        const newAttachments = []
        const updateAttachments = []

        attachments.forEach((attachmentInfo) => {
          if (attachmentInfo && attachmentInfo.attachmentID) {
            const hasAttachment = lwAttachments.find((attachment) => attachment.attachmentID === attachmentInfo.attachmentID)
            if (!hasAttachment) {
              newAttachments.push({ ...attachmentInfo })
            } else {
              // Update attachment name if the file name doesn't match the current one
              if (hasAttachment && hasAttachment.fileName && attachmentInfo.fileName && attachmentInfo.fileName !== hasAttachment.fileName) {
                updateAttachments.push({ ...hasAttachment, fileName: attachmentInfo.fileName })
              }
            }
          }
        })

        if (newAttachments && newAttachments.length) { newEditInfo.attachmentsToAdd = newAttachments }
        // If there is an updated attachment and it is not empty, update name in the api
        if (updateAttachments && updateAttachments.length) { newEditInfo.attachmentsToUpdate = updateAttachments }
      }

      return newEditInfo
    }

    if (artifactType === 'Project') {
      const { projectLEDs, projectLWs } = targetArtifactPRO
      const addedLeds = []
      const deletedLeds = []
      const addedLws = []
      const deletedLws = []

      const newEditInfo = { ...defaultEditProInfo }
      newEditInfo.isPrivate = efSubmit ? 0 : isPrivate ? 1 : 0
      newEditInfo.ledsToAdd = []
      newEditInfo.ledsToDelete = []
      newEditInfo.lwsToAdd = []
      newEditInfo.lwsToDelete = []

      if (selectedLEDs && projectLEDs && Array.isArray(projectLEDs)) {
        selectedLEDs.forEach(id => {
          const hasLED = projectLEDs.includes(id)
          if (!hasLED) {
            addedLeds.push(id)
          }
        })

        projectLEDs.forEach(id => {
          const hasLED = selectedLEDs.includes(id)
          if (!hasLED) {
            deletedLeds.push(id)
          }
        })
      }

      if (selectedLWs && projectLWs && Array.isArray(projectLWs)) {
        selectedLWs.forEach(id => {
          const hasLW = projectLWs.includes(id)
          if (!hasLW) {
            addedLws.push(id)
          }
        })

        projectLWs.forEach(id => {
          const hasLW = selectedLWs.includes(id)
          if (!hasLW) {
            deletedLws.push(id)
          }
        })
      }

      if (addedLeds && addedLeds.length) { newEditInfo.ledsToAdd = addedLeds }
      if (addedLws && addedLws.length) { newEditInfo.lwsToAdd = addedLws }

      if (deletedLeds && deletedLeds.length) { newEditInfo.ledsToDelete = deletedLeds }
      if (deletedLws && deletedLws.length) { newEditInfo.lwsToDelete = deletedLws }

      return newEditInfo
    }
  }

  const fireSuccess = (ledID) => { handleReset(artifactType, ledID) }

  const fireEFRUpdateSuccess = (fireNotification) => {
    handleReset()
    if (fireNotification) {
      NotificationToast(false, 'Image will update shortly, please refresh if image does not update!', true)
    }
  }

  const fireFailure = () => { BaseToast(true, 'Failed to create artifact.', 'Sorry, there was an error creating this artifact. Go back through the steps and make sure you have filled out all required information.') }

  const handleDelete = (e) => {
    if (artifactType === artifactTypes.LED) { dispatch(deleteArtifact(ledInfo.artifactID, fireSuccess, fireDeleteFailure)) }

    if (artifactType === artifactTypes.LW) { dispatch(deleteArtifact(newLearnerWorkForm.artifactID, fireSuccess, fireDeleteFailure)) }

    if (artifactType === artifactTypes.PRO) { dispatch(deleteArtifact(newProjectForm.artifactID, fireSuccess, fireDeleteFailure)) }

    if (artifactType === artifactTypes.EFR) { dispatch(deleteEFR(newEFRForm.artifactID, fireSuccess, fireDeleteFailure)) }
  }

  const fireDeleteFailure = () => { NotificationToast(true, 'Failed to delete artifact.') }

  const handleNext = (e) => {
    e.preventDefault()
    if (isEditing) {
      const artifactID = artifactType === 'Learning Experience Design' ? ledInfo.artifactID : artifactType === 'Learner Work' ? newLearnerWorkForm.artifactID : newProjectForm.artifactID
      handleArtifactSectionUpdate(currentForm, artifactID)
    }
    if (currentForm === 0 && artifactType === 'Project') {
      setCurrentForm(6)
    } else {
      setCurrentForm((prevForm) => currentForm === 3 && isForMCE ? prevForm + 2 : prevForm + 1)
    }

    // Set selected Learner Work using linked learnerWorkID from learning experience design when editing if there is one.
    const learnerWorkID = ledInfo.learnerWorkID
    if (ledInfo && userLWOptions && learnerWorkID && learnerWorkID !== '' && isEditing && selectedLearnerWork === null) {
      const lwObject = userLWOptions.find(lw => lw.artifactID === learnerWorkID)
      setSelectedLearnerWork(lwObject)
    }
  }

  const handleBack = (e) => {
    e.preventDefault()
    if (currentForm === 6 && artifactType === 'Project') {
      setCurrentForm(0)
    } else {
      setCurrentForm((prevForm) => currentForm === 5 && isForMCE ? prevForm - 2 : prevForm - 1)
    }
  }

  const [newComments, setNewComments] = useState({})

  const handleMenuClick = (form, artifactID) => {
    const clickedAwayFrom = currentForm
    const duplicationsView = artifactType === artifactTypes.LED && currentForm === 7

    if (newComments && newComments !== {} && !duplicationsView) {
      handleArtifactSectionUpdate(clickedAwayFrom, artifactID)
      if (artifactType !== artifactTypes.LED && clickedAwayFrom === forms.indexOf('artifact-details')) {
        handleArtifactSectionUpdate('notes', artifactID)
      }
    }

    // Set selected Learner Work using linked learnerWorkID from learning experience design when editing if there is one.
    const learnerWorkID = ledInfo.learnerWorkID
    if (form === 'learner-work' && ledInfo && userLWOptions && learnerWorkID && learnerWorkID !== '' && isEditing && selectedLearnerWork === null) {
      const lwObject = userLWOptions.find(lw => lw.artifactID === learnerWorkID)
      setSelectedLearnerWork(lwObject)
    }

    setCurrentForm(forms.indexOf(form))
  }

  const getErrorText = () => {
    if (missingAttachmentsError || resourceLinkInvalid || missingFieldsError) {
      return (
        <Grid item container direction='row' justifyContent='center' style={{ paddingTop: '1em', width: '70%' }}>
          <Typography variant='caption' style={{ color: 'red' }}>
            {missingAttachmentsError && '* You must attach one or more artifacts to this Project before submission.'}
            {resourceLinkInvalid && '* Provided Link is Invalid'}
            {missingFieldsError && '* Missing required fields.'}
          </Typography>
        </Grid>
      )
    } else {
      return ''
    }
  }

  return (
    <>
      <ArtifactCancelModal
        classes={classes}
        theme={theme}
        isEditing={isEditing}
        artifactType={artifactType}
        artifactCancelModalOpen={artifactCancelModalOpen}
        setArtifactCancelModalOpen={setArtifactCancelModalOpen}
        handleCancelConfirm={handleCancelConfirm}
        handleSaveAndExit={handleSaveArtifact}
        nameError={nameError}
        setNameError={setNameError}
        artifactNoNameCheck={artifactNoNameCheck}
        isForMCE={isForMCE}
      />

      <SubmitToEdFarmModal
        efSubmit={efSubmit}
        setEfSubmit={setEfSubmit}
        isPrivate={isPrivate}
        setIsPrivate={setIsPrivate}
        setEFSubmitModalOpen={setEFSubmitModalOpen}
        efSubmitModalOpen={efSubmitModalOpen}
        classes={classes}
        styles={styles}
        resubmitConfirmed={resubmitConfirmed}
        artifactStatus={artifactStatus}
      />

      <PublishArtifactModal
        publishModalOpen={publishModalOpen}
        setPublishModalOpen={setPublishModalOpen}
        classes={classes}
        artifactType={artifactType}
        isPrivate={isPrivate}
        setIsPrivate={setIsPrivate}
        efSubmit={efSubmit}
        setEfSubmit={setEfSubmit}
        handleEFRSubmit={handleEFRSubmit}
        handleSaveArtifact={handleSaveArtifact}
        styles={styles}
        artifactIsDuplicate={Boolean(ledInfo && ledInfo.forkedFrom && ledInfo.forkedFrom.length)}
      />

      <Grid container direction={theme.breakpoints.down('sm') ? 'row' : 'column'} justifyContent='center' style={{ marginTop: '5em', marginBottom: '6em' }} spacing={4}>

        {/* Side Menu */}
        <CreateSideMenu
          classes={classes}
          forms={forms}
          currentForm={currentForm}
          artifactType={artifactType}
          newProjectForm={newProjectForm}
          learnerWorkCheck={learnerWorkCheck}
          artifactDetailsCheck={artifactDetailsCheck}
          formMissingDetailsCheck={formMissingDetailsCheck}
          ledOneCheck={ledOneCheck}
          ledTwoCheck={ledTwoCheck}
          efSubmit={efSubmit}
          styles={styles}
          ledInfo={ledInfo}
          isEditing={isEditing}
          handleDeletePopover={handleDeletePopover}
          isPrivate={isPrivate}
          setIsPrivate={setIsPrivate}
          isDraft={isDraft}
          artifactStatus={artifactStatus}
          setEFSubmitModalOpen={setEFSubmitModalOpen}
          handleSaveAndExit={handleSaveArtifact}
          handleNext={handleNext}
          handleBack={handleBack}
          artifactNoNameCheck={artifactNoNameCheck}
          EFRNotCompleteCheck={EFRNotCompleteCheck}
          handleMenuClick={handleMenuClick}
          newComments={newComments}
          setNewComments={setNewComments}
          getErrorText={getErrorText}
          resubmitConfirmed={resubmitConfirmed}
          selectedLWs={selectedLWs}
          selectedLEDs={selectedLEDs}
          setCurrentForm={setCurrentForm}
          isForMCE={isForMCE}
        />

        {/* Transition Step Componants */}
        <Grid item container sm={null} md={9} className={classes.formContainer}>
          {/* Artifact Details */}
          <Slide
            in={Boolean(currentForm === 0 && artifactType !== artifactTypes.EFR)}
            exit={false}
            unmountOnExit
            appear
            mountOnEnter
            direction='left'
          >
            <div style={{ width: '100%' }}>
              <ArtifactDetails
                submit={efSubmit}
                ledInfo={ledInfo}
                setLEDInfo={setLEDInfo}
                newLearnerWorkForm={newLearnerWorkForm}
                setNewLearnerWorkForm={setNewLearnerWorkForm}
                newProjectForm={newProjectForm}
                setNewProjectForm={setNewProjectForm}
                artifactType={artifactType}
                onUpdateEdit={handleEdit}
                setCurrentForm={setCurrentForm}
                isEditing={isEditing}
                styles={styles}
                selectedLEDs={selectedLEDs}
                setSelectedLEDs={setSelectedLEDs}
                editLWInfo={editLWInfo}
                setEditLWInfo={setEditLWInfo}
                targetArtifactComments={targetArtifactComments}
                artifactNotCompleteCheck={artifactNotCompleteCheck}
                isDraft={isDraft}
                setPublishModalOpen={setPublishModalOpen}
                publishModalOpen={publishModalOpen}
              />
            </div>
          </Slide>

          {/* LED One */}
          <Slide
            in={currentForm === 1}
            exit={false}
            unmountOnExit
            appear
            mountOnEnter
            direction='left'
          >
            <div style={{ width: '100%' }}>
              <LEDone
                handleLearningTargetsChange={handleLearningTargetsChange}
                onTargetAdd={handleTargetAdd}
                onTargetRemove={handleTargetRemove}
                ledInfo={ledInfo}
                setLEDInfo={setLEDInfo}
                styles={styles}
                targetArtifactComments={targetArtifactComments}
              />
            </div>
          </Slide>

          {/* LED Two */}
          <Slide
            in={currentForm === 2}
            exit={false}
            unmountOnExit
            appear
            mountOnEnter
            direction='left'
          >
            <div style={{ width: '100%' }}>
              <LEDtwo
                ledInfo={ledInfo}
                setLEDInfo={setLEDInfo}
                styles={styles}
                targetArtifactComments={targetArtifactComments}
              />
            </div>
          </Slide>

          {/* LED Artifact Resources */}
          <Slide
            in={currentForm === 3}
            exit={false}
            unmountOnExit
            appear
            mountOnEnter
            direction='left'
          >
            <div style={{ width: '100%' }}>
              <ArtifactResources
                submit={efSubmit}
                ledInfo={ledInfo}
                setLEDInfo={setLEDInfo}
                onUpdateEdit={handleEdit}
                styles={styles}
                targetArtifactComments={targetArtifactComments}
              />
            </div>
          </Slide>

          {/* LED Learner Work */}
          <Slide
            in={currentForm === 4}
            exit={false}
            unmountOnExit
            appear
            mountOnEnter
            direction='left'
          >
            <div style={{ width: '100%' }}>
              <LEDLearnerWork
                selectedLearnerWork={selectedLearnerWork}
                setSelectedLearnerWork={setSelectedLearnerWork}
                ledInfo={ledInfo}
                setLEDInfo={setLEDInfo}
                newLearnerWorkForm={newLearnerWorkForm}
                defaultLearnerWorkForm={defaultNewLearnerWorkForm}
                setNewLearnerWorkForm={setNewLearnerWorkForm}
                learnerWorkCheck={learnerWorkCheck}
                setNewlyCreatedLearnerWorkID={setNewlyCreatedLearnerWorkID}
                styles={styles}
                targetArtifactComments={targetArtifactComments}
                onUpdateEdit={handleEdit}
                isForMCE={isForMCE}
              />
            </div>
          </Slide>

          {/* LED Summary */}
          <Slide
            in={currentForm === 5}
            exit={false}
            unmountOnExit
            appear
            mountOnEnter
            direction='left'
          >
            <div style={{ width: '100%' }}>
              <ArtifactSummary
                submit={efSubmit}
                ledInfo={ledInfo}
                setLEDInfo={setLEDInfo}
                selectedLearnerWork={selectedLearnerWork}
                setCurrentForm={setCurrentForm}
                classes={classes}
                styles={styles}
                targetArtifactComments={targetArtifactComments}
                setPublishModalOpen={setPublishModalOpen}
                isDraft={isDraft}
                artifactNotCompleteCheck={artifactNotCompleteCheck}
                isForMCE={isForMCE}
                handleSaveArtifact={handleSaveArtifact}
              />
            </div>
          </Slide>

          {/* Project Items */}
          <Slide
            in={currentForm === 6}
            exit={false}
            unmountOnExit
            appear
            mountOnEnter
            direction='left'
          >
            <div style={{ width: '100%' }}>
              <ProjectItems
                newProjectForm={newProjectForm}
                setNewProjectForm={setNewProjectForm}
                artifactType={artifactType}
                classes={classes}
                selectedLEDs={selectedLEDs}
                selectedLWs={selectedLWs}
                setSelectedLEDs={setSelectedLEDs}
                setSelectedLWs={setSelectedLWs}
                styles={styles}
                targetArtifactComments={targetArtifactComments}
                currentlyLinkedLEDs={currentlyLinkedLEDs}
                currentlyLinkedLWs={currentlyLinkedLWs}
                artifactNotCompleteCheck={artifactNotCompleteCheck}
                isDraft={isDraft}
                setPublishModalOpen={setPublishModalOpen}
              />
            </div>
          </Slide>

          {/* LED Duplications */}
          <Slide
            in={currentForm === 7}
            exit={false}
            unmountOnExit
            appear
            mountOnEnter
            direction='left'
          >
            <div style={{ width: '100%' }}>
              <ArtifactDuplications />
            </div>
          </Slide>

          {/* Ed Farm Resources Details */}
          <Slide
            in={artifactType === artifactTypes.EFR}
            exit={false}
            unmountOnExit
            appear
            mountOnEnter
            direction='left'
          >
            <div style={{ width: '100%' }}>
              <EFRDetails
                artifactType={artifactType}
                classes={classes}
                styles={styles}
                setNewEFRForm={setNewEFRForm}
                newEFRForm={newEFRForm}
                eventDate={eventDate}
                eventStartTime={eventStartTime}
                eventEndTime={eventEndTime}
                setEventDate={setEventDate}
                setEventStartTime={setEventStartTime}
                setEventEndTime={setEventEndTime}
                startTime={startTime}
                endTime={endTime}
                displayEventDate={displayEventDate}
                setStartTime={setStartTime}
                setEndTime={setEndTime}
                setDisplayEventDate={setDisplayEventDate}
                artifactNotCompleteCheck={EFRNotCompleteCheck}
                isDraft={isDraft}
                handleSubmit={handleEFRSubmit}
                setPublishModalOpen={setPublishModalOpen}
                publishModalOpen={publishModalOpen}
                resourceLinkInvalid={resourceLinkInvalid}
              />
            </div>
          </Slide>
        </Grid>
      </Grid>

      <Popover
        id={openDeletePopover ? 'delete-popover' : undefined}
        open={openDeletePopover}
        anchorEl={deleteAnchorEl}
        onClose={handleDeletePopClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <Grid container direction='column' style={{ padding: '1em' }}>
          <Typography
            variant='h4'
            style={{ color: theme.palette.purple.darkest }}
            gutterBottom
          >
            Are you sure you want to delete this artifact?
          </Typography>

          <Typography variant='body1' gutterBottom>Any associated artifacts will be un-linked.</Typography>
          <Typography variant='caption' gutterBottom style={{ color: 'red' }}>This action cannot be undone.</Typography>

          <Grid item container direction='row' justifyContent='flex-end' alignItems='center' style={{ marginTop: '.5em' }}>
            <Button onClick={handleDeletePopClose} style={{ marginRight: '.3em', textTransform: 'none', fontWeight: '600' }}>Cancel</Button>
            <Button variant='contained' color='primary' onClick={(e) => { e.preventDefault(); handleDelete() }}>Confirm</Button>
          </Grid>
        </Grid>
      </Popover>

    </>
  )
}

export default withRouter(CreateArtifact)
