import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Paper } from '@material-ui/core'
import { ConversationList, NoConversations, DraftInbox } from './ConversationViews'

import {
  getConversations, setActiveConversationUsers
} from '../../../../redux/actions'

/* ********** Switch the view within the "Conversation Area" based on the view state ************* //
    Uses the 'view' object and reads the 'conversation' key to determine the desired view
    --- TO DO: create a loading/skeleton to render as the 'default' of the switch case
    --- this will need to be accompanied by a timeout that sets the view to 'NO Conversations.js' if no other state is set after a period of time
*/

const ConversationArea = (props) => {
  const {
    theme, smScreen, view, viewTypes, loadNewView,
    activeConversationID,
    fetchNew,
    parsedProps, classes,
    messagesInitialLoad, setFetchNew,
    handleConfirmPopover, setDeleteDraftID,
    setCurrentForm,
    editorOpen, setEditorOpen, deviceScrnSize
  } = props
  const dispatch = useDispatch()

  // The conversation list from redux
  const { conversations } = useSelector(state => state.websocket)
  const [newConversationID, setNewConversationID] = useState(null)
  const shouldRedirect = useRef(Boolean(false))
  const heightCheck = smScreen && deviceScrnSize && deviceScrnSize.height && deviceScrnSize.width && deviceScrnSize.height > 0 && deviceScrnSize.width > 0 && deviceScrnSize.height > deviceScrnSize.width

  // Get conversation array
  useEffect(() => {
    // If the activeID has not yet been set, get the coversation list
    if (!activeConversationID) { dispatch(getConversations()) }
  }, [dispatch, activeConversationID])

  useMemo(() => {
    // If the active conversation has already been set BUT the 5 sec poll came back true, fetch the conversations again
    if (activeConversationID && activeConversationID.length && fetchNew) {
      dispatch(getConversations())
      setFetchNew(false)
    }
  }, [dispatch, activeConversationID, fetchNew, setFetchNew])

  // Look to see if there is an active conversation and a conversation list
  useEffect(() => {
    if (
      !activeConversationID && conversations && Boolean(conversations.length) && typeof setActiveConversationUsers === 'function'
    ) {
      const [{ conversationID, userListDetails } = {}] = conversations

      dispatch(setActiveConversationUsers(userListDetails))
      setNewConversationID(conversationID)
      if (!shouldRedirect.current) { shouldRedirect.current = true }
    } else {
      if (shouldRedirect.current) { shouldRedirect.current = false; setNewConversationID(null) }
    }
  }, [
    activeConversationID, conversations, dispatch
  ])

  // Use the view config object to decide which component should render in the 'conversation' area.
  const conversationView = view && view.conversation ? view.conversation : ''

  const getConversationView = (view) => {
    switch (view) {
      case 'list': {
        return (
          <ConversationList
            theme={theme}
            smScreen={smScreen}
            loadNewView={loadNewView}
            viewTypes={viewTypes}
            activeConversationID={activeConversationID}
            view={view}
            parsedProps={parsedProps}
            classes={classes}
            messagesInitialLoad={messagesInitialLoad}
            fetchNew={fetchNew}
            setFetchNew={setFetchNew}
            setCurrentForm={setCurrentForm}
            editorOpen={editorOpen}
            setEditorOpen={setEditorOpen}
            deviceScrnSize={deviceScrnSize}
          />
        )
      }
      case 'drafts': {
        return (
          <DraftInbox
            theme={theme}
            smScreen={smScreen}
            loadNewView={loadNewView}
            viewTypes={viewTypes}
            activeConversationID={activeConversationID}
            view={view}
            parsedProps={parsedProps}
            classes={classes}
            messagesInitialLoad={messagesInitialLoad}
            handleConfirmPopover={handleConfirmPopover}
            setDeleteDraftID={setDeleteDraftID}
            setCurrentForm={setCurrentForm}
            editorOpen={editorOpen}
            setEditorOpen={setEditorOpen}
          />
        )
      }
      default:
        return (
          <NoConversations
            theme={theme}
            loadNewView={loadNewView}
            viewTypes={viewTypes}
            classes={classes}
          />
        )
    }
  }

  return (
    <>
      {
        shouldRedirect.current && newConversationID && <Redirect to={`/messages?view=message&conversationID=${newConversationID}`} />
      }
      <Paper elevation={smScreen ? 0 : 1} style={{ height: heightCheck ? deviceScrnSize.height / 1.5 : '100%', padding: '1em', overflow: 'hidden scroll' }}>
        {getConversationView(conversationView)}
      </Paper>
    </>
  )
}

export default ConversationArea
