import {
  SET_EXPLORE_ACTIVE_TAB,
  SET_EXPLORE_SCROLL_POSITION,
  LOGOUT,
  SET_SEARCH_EXPLORE_FILTER
} from '../types'

// UI Tech Debt: Removed defaultFilter import from variables.js that no longer existed, along with various unused types and unused state

const initialState = {
  exploreActiveTab: 'all-posts',
  exploreScrollPosition: 0,
  searchExploreFilter: {
    type: '',
    search: ''
  }
}

const selectReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_EXPLORE_ACTIVE_TAB: return {
      ...state,
      exploreActiveTab: action.payload
    }
    case SET_EXPLORE_SCROLL_POSITION: return {
      ...state,
      exploreScrollPosition: action.payload
    }
    case SET_SEARCH_EXPLORE_FILTER: return {
      ...state,
      searchExploreFilter: {
        type: action.payload.type,
        search: action.payload.search
      }
    }
    case LOGOUT: return {
      ...initialState
    }
    default: return { ...state }
  }
}

export default selectReducer
