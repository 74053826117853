import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { Grid, Typography, Avatar, useTheme, makeStyles } from '@material-ui/core'
import { CheckCircleRounded, ErrorRounded, SwapHorizontalCircleRounded, CancelRounded, DescriptionOutlined, DeviceHub } from '@material-ui/icons'

import ArtifactNav from '../ui/tools/ArtifactNav'
import CreateArtifact from './CreateArtifact'
import ReviewArtifact from './ReviewArtifact'
import PublicArtifact from './PublicArtifact'

import { defaultTargetArtifactLW, defaultTargetArtifactLED, defaultTargetArtifactPRO, defaultTargetArtifactEFR, artifactTypes, userRoleIDs, mceViews } from '../../utils/variables'
import EFRLogo from '../../assets/EFlogoAvatar.png'

import {
  getAboutEditData,
  getStandardsList,
  updateArtifactSectionComments,
  getArtifactDetails,
  setExploreScrollPosition,
  setExploreActiveTab,
  resetTargetEFR,
  resetTargetLearnerWork,
  resetTargetLED,
  resetTargetProject,
  updateMCEAttempt
} from '../../redux/actions'
import { isEqual } from 'lodash'

const useStyles = makeStyles((theme) => ({
  nextButton: {
    paddingRight: '1em',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0
    }
  },
  statusIcon: {
    height: '12px',
    width: '12px',
    backgroundColor: theme.palette.purple.dark
  },
  saveExitBtn: {
    justifyContent: 'flex-end',
    paddingRight: '2em',
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0
    }
  },
  saveIcon: {
    marginRight: 8,
    [theme.breakpoints.down('md')]: {
      marginRight: 3
    }
  },
  sideMenu: {
    width: '70%',
    height: 'fit-content',
    alignContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 'fit-content',
      margin: '0 .5em',
      display: 'flex',
      flexDirection: 'column'
    }
  },
  sideMenuButtons: {
    width: '70%',
    height: 'fit-content',
    alignContent: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: '0 .5em',
      display: 'flex',
      flexDirection: 'row'
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '1em'
    }
  },
  sideMenuNavArrowDiv: {
    [theme.breakpoints.between(
      theme.breakpoints.values.md, theme.breakpoints.values.lg
    )]: {
      marginTop: '1em'
    }
  },
  sideMenuNavigationArrows: {
    height: 35,
    width: 35,
    [theme.breakpoints.down('md')]: {
      height: 30,
      width: 30
    },
    '&:disabled': {
      backgroundColor: `${theme.palette.grey.medium} !important`
    }
  },
  sideMenuSaveAndExitButton: {
    fontWeight: 600,
    padding: '.5em .5em',
    height: 35,
    borderWidth: '2px',
    marginLeft: 0,
    minWidth: '120px',
    '&:disabled': {
      borderColor: `${theme.palette.grey.dark} !important`,
      color: `${theme.palette.grey.dark} !important`
    }
  },
  formContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0 .5em'
    }
  },
  paperRoot: {
    width: '80%',
    padding: '1.5em',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      '@media only screen and (orientation: landscape)': {
        marginLeft: '1em'
      }
    }
  },
  approveBtn: {
    border: 'solid 2px green',
    color: 'green',
    fontWeight: 600
  },
  changesBtn: {
    border: 'solid 2px yellow',
    color: 'yellow',
    fontWeight: 600
  },
  denyBtn: {
    border: 'solid 2px red',
    color: 'red',
    fontWeight: 600
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  border: {
    borderBottom: '1px solid lightgray',
    width: '100%'
  },
  text: {
    marginRight: '1rem',
    marginLeft: '1rem',
    color: 'grey'
  },
  featuredResourceImage: {
    width: '100%',
    height: '100%',
    '& .MuiAvatar-img': {
      maxHeight: '25rem !important',
      objectFit: 'contain !important'
    }
  },
  bannerImageStyle: {
    height: 'auto',
    maxHeight: '50vh',
    width: 'auto',
    maxWidth: '100%'
  },
  defaultBannerImageStyle: {
    height: 'auto',
    maxHeight: '40vh',
    width: '100%',
    maxWidth: '100%'
  },
  optionsMenuLockIcons: {
    fontSize: '14px',
    marginRight: '8px',
    marginLeft: '-4px'
  },
  paper: {
    overflowY: 'unset'
  },
  publishArtifactButton: {
    fontWeight: '600',
    padding: '.5em 2.5em',
    '&:disabled': {
      backgroundColor: `${theme.palette.grey.light} !important`,
      color: '#babac1'
    }
  },
  customizedButton: {
    backgroundColor: 'none',
    color: 'grey',
    stroke: 'grey',
    strokeWidth: '2px',
    height: '2rem',
    width: '2rem',
    '&:hover': {
      stroke: '#616161'
    }
  },
  buttonActive: {
    color: theme.palette.black,
    boxShadow: 'none',
    fontWeight: '600',
    backgroundColor: theme.palette.purple.lighter,
    height: '2em',
    borderRadius: '5px',
    marginRight: '.5em',
    '&:hover': {
      color: theme.palette.black,
      boxShadow: 'none',
      fontWeight: '600',
      backgroundColor: theme.palette.purple.light,
      height: '2em',
      borderRadius: '5px',
      marginRight: '.5em'
    }
  },
  buttonInactive: {
    color: theme.palette.black,
    boxShadow: 'none',
    fontWeight: '600',
    height: '2em',
    borderRadius: '5px',
    textTransform: 'none',
    marginRight: '.5em',
    '&:hover': {
      color: theme.palette.black,
      boxShadow: 'none',
      fontWeight: '600',
      height: '2em',
      borderRadius: '5px',
      textTransform: 'none',
      backgroundColor: 'white',
      marginRight: '.5em'
    }
  },
  buttonFab: {
    fontSize: '10px !important',
    fontWeight: 'bold',
    height: '20px',
    width: '20px',
    minHeight: '20px',
    color: 'white',
    backgroundColor: theme.palette.purple.darkest,
    boxShadow: 'none',
    '&:hover': {
      fontSize: '10px !important',
      fontWeight: 'bold',
      height: '20px',
      width: '20px',
      minHeight: '20px',
      color: 'white',
      backgroundColor: theme.palette.purple.darkest,
      boxShadow: 'none'
    }
  },
  linkButtons: {
    textTransform: 'none',
    textAlign: 'left',
    color: theme.palette.purple.darkest,
    fontWeight: '600',
    fontSize: '15px',
    textDecoration: 'underline',
    wordBreak: 'break-all',
    padding: 0
  },
  artCardListItemItem: {
    display: 'flex',
    alignItems: 'stretch',
    overflow: 'visible'
  },
  navButtonRow: {
    marginTop: '.5em',
    [theme.breakpoints.down('md')]: {
      marginTop: '2em'
    }
  }
}
))

const ModifiedLEDIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 68.782 55.026'>
      <path data-name='chalkboard-solid' d='M10.317,6.878H58.465v37.83h6.878V4.3a4.3,4.3,0,0,0-4.3-4.3H7.738a4.3,4.3,0,0,0-4.3,4.3v40.41h6.878ZM67.063,48.148H51.587V41.269H30.952v6.878H1.72A1.719,1.719,0,0,0,0,49.867v3.439a1.719,1.719,0,0,0,1.72,1.72H67.063a1.719,1.719,0,0,0,1.72-1.72V49.867A1.719,1.719,0,0,0,67.063,48.148Z' fill='#FFFFFF' />
    </svg>
  )
}

const ModifiedLearnerWorkIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 55 55'>
      <path data-name='pencil-ruler-solid' d='M11.754,26.206,26.21,11.751,21.471,7.012l-6.626,6.626a.851.851,0,0,1-1.2,0l-1.2-1.2a.851.851,0,0,1,0-1.2L19.063,4.6,15.449.989a3.407,3.407,0,0,0-4.819,0L.993,10.626a3.408,3.408,0,0,0,0,4.819ZM53.483,13.659a5.16,5.16,0,0,0,0-7.3L48.622,1.5a5.162,5.162,0,0,0-7.3,0L36.38,6.444,48.54,18.6ZM33.949,8.876l-31.9,31.9L.03,52.316a2.28,2.28,0,0,0,2.641,2.638l11.542-2.024,31.9-31.9ZM54,39.537l-3.614-3.614-6.626,6.626a.851.851,0,0,1-1.2,0l-1.2-1.2a.853.853,0,0,1,0-1.2l6.626-6.626-4.742-4.742L28.776,43.228,39.541,53.991a3.407,3.407,0,0,0,4.819,0L54,44.355A3.407,3.407,0,0,0,54,39.537Z' transform='translate(0.005 0.01)' fill='white' />
    </svg>
  )
}

const ArtifactView = (props) => {
  const parsedProps = queryString.parse(props.location.search)
  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()

  const { targetArtifactLED, targetArtifactLW, targetArtifactPRO, targetArtifactEFR } = useSelector(state => state.artifacts)

  // Saved scroll position for explore all posts
  const savedExploreScrollPosition = useSelector(state => state.select.exploreScrollPosition)

  // Control cancel creating / editing artifact modal in CreateArtifact component
  const [artifactCancelModalOpen, setArtifactCancelModalOpen] = useState(false)

  const creatingArtifact = parsedProps && parsedProps.create ? Boolean(parsedProps.create) : false
  const reviewingArtifact = parsedProps && parsedProps.review ? Boolean(parsedProps.review) : false
  const publicArtifact = parsedProps && parsedProps.public ? Boolean(parsedProps.public) : false
  const isEditing = parsedProps && parsedProps.editing ? Boolean(parsedProps.editing) : false

  const forms = ['artifact-details', 'LED1', 'LED2', 'resources', 'learner-work', 'summary', 'project-items']

  // currently selected artifact type ('learning experience design' // 'learner work' // 'project')
  const artifactType = !parsedProps.create ? !parsedProps.review ? parsedProps.public : parsedProps.review : parsedProps.create

  // Provides the admin's information
  const UserDetails = useSelector(state => state.userDetails)

  const { profileAvatarPath: authProfileAvatarPath, profileID: authProfileID, roleID: authUserRole } = useSelector(state => state.auth)

  const currentArtifactID = parsedProps.artifact

  // NOTE (Chris - 6/5/24) -> typeLoaded is currently returning false after hot reload which causes the component to not render.
  // NOTE (Chris - 6/5/24) -> This is happening because target artifact info is being reset in redux in useEffect below.
  // A check to be sure all editing information has loaded before rendering the create artifact component
  const typeLoaded = artifactType &&
    (
      (artifactType === artifactTypes.LED && targetArtifactLED && !isEqual(targetArtifactLED, defaultTargetArtifactLED)) ||
      (artifactType === artifactTypes.LW && targetArtifactLW && !isEqual(targetArtifactLW, defaultTargetArtifactLW)) ||
      (artifactType === artifactTypes.PRO && !isEqual(targetArtifactPRO, defaultTargetArtifactPRO)) ||
      (artifactType === artifactTypes.EFR && targetArtifactEFR && !isEqual(targetArtifactEFR, defaultTargetArtifactEFR))
    )

  const uiLoading = useRef(false)

  // Duplication Refs and state
  const defaultDuplicateForm = useMemo(() => {
    return {
      title: '',
      forkedFrom: '',
      targets: [],
      ISTEStandards: [],
      actContent: '',
      createContent: '',
      engageContent: '',
      investigateContent: '',
      artifactType: '',
      completionTime: 0,
      coreSubjectID: '',
      secondarySubjectID: '',
      grades: [],
      overview: '',
      stateStandards: '',
      techNeeded: '',
      workType: ''
    }
  }, [])

  const duplicateArtifactForm = useRef({ ...defaultDuplicateForm })

  /* Artifact is available to view if:
  -- The artifact attempting to be viewed is not an EFR
  -- Or if an EFR:
      -- it is not attempting to be edited/created/reviewed
      -- it is being edited and the user is an Admin
  */
  const artifactAvailableCheck = artifactType &&
    (artifactType !== artifactTypes.EFR ||
      (artifactType === artifactTypes.EFR && authUserRole === userRoleIDs.ADMIN_ROLE) ||
      (artifactType === artifactTypes.EFR && publicArtifact)
    )

  // Callback fired when an admin has chosen to edit an artifact from a public view click event
  const checkForEFRDetails = useCallback(async () => {
    // double check the user has permisisons to edit an EFR
    if (artifactAvailableCheck && isEditing) {
      // first check to be sure we have details in the target artifact info object
      if (!isEqual(targetArtifactEFR && defaultTargetArtifactEFR)) {
        const { artifactID } = targetArtifactEFR

        // Then double check that the information we have is the correct info for the ID in the params
        if (currentArtifactID) {
          if (artifactID !== currentArtifactID) {
            // if not get the correct details
            await dispatch(getArtifactDetails(currentArtifactID, '', artifactTypes.EFR, () => { }, () => { }, ''))
          }
        }
      } else {
        // If we do not have any artifactInfo at all for the desired ID, then fetch it now
        await dispatch(getArtifactDetails(currentArtifactID, '', artifactTypes.EFR, () => { }, () => { }, ''))
      }
    }
  }, [dispatch, targetArtifactEFR, artifactAvailableCheck, isEditing, currentArtifactID])

  // If we are looking at an EFR type, check to be sure we don't need to fetch new details to edit with (if applicable)
  useEffect(() => {
    if (artifactType && artifactType === artifactTypes.EFR) {
      checkForEFRDetails()
    }
  }, [checkForEFRDetails, artifactType])

  // Get grades, subjects, standards to format results from artifact details
  useEffect(() => {
    dispatch(getAboutEditData())
    dispatch(getStandardsList())
  }, [dispatch])

  // Styles for artifact pages
  const artifactStyles = useMemo(() => ({
    LED: {
      mainColor: theme.palette.purple.darkest,
      darkerColor: '#1E274D',
      inactiveText: theme.palette.purple.dark,
      bannerIcon: <ModifiedLEDIcon />
    },
    LW: {
      mainColor: '#177FA5',
      darkerColor: '#025676',
      inactiveText: '#5bbce0',
      bannerIcon: <ModifiedLearnerWorkIcon />
    },
    PROJ: {
      mainColor: '#5D40BE',
      darkerColor: '#3d2b79',
      inactiveText: '#ae9ce6',
      bannerIcon: <DeviceHub />
    },
    EFR: {
      mainColor: '#B41515',
      darkerColor: '#8D0707',
      inactiveText: '#FFD0D0',
      bannerIcon: <DescriptionOutlined />
    }
  }), [theme])

  // State for currently set style for artifact page
  const [styles, setStyles] = useState(artifactStyles.LED)

  // Function to set style of page depending on artifact type
  const getTypeStyle = useCallback((artifactType) => {
    switch (artifactType) {
      case 'Learning Experience Design':
        return setStyles(artifactStyles.LED)
      case 'Learner Work':
        return setStyles(artifactStyles.LW)
      case 'Project':
        return setStyles(artifactStyles.PROJ)
      case 'Ed Farm Resource':
        return setStyles(artifactStyles.EFR)
      default:
        return setStyles(artifactStyles.LED)
    }
  }, [artifactStyles])

  // Set page style on load using currently selected artifact type
  useEffect(() => {
    if (parsedProps && parsedProps.review) {
      getTypeStyle(parsedProps.review)
    } else if (parsedProps && parsedProps.create) {
      getTypeStyle(parsedProps.create)
    } else {
      getTypeStyle(parsedProps.public)
    }
  }, [parsedProps, getTypeStyle])

  // Sets the local state information to whichever target artifact type is required.
  const [reviewInfo, setReviewInfo] = useState({})
  const [artifactStatus, setArtifactStatus] = useState('')

  // If reviewing (from an Admin perspective) or viewing a public artifact, set the review information and set the status of the artifact to the banner
  useEffect(() => {
    // Check for parsedProps and artifactType
    if (parsedProps && artifactType) {
      // Check for specific artifactTypes and target artifact objects
      // If true, set the artifact status is submission status exists on the target artifact
      // Also set review info if parsed props review or public are true
      if (artifactType === artifactTypes.LED && targetArtifactLED) {
        const { submissionStatus } = targetArtifactLED
        if (submissionStatus && submissionStatus !== null) setArtifactStatus(submissionStatus)
        setReviewInfo(targetArtifactLED)
      }

      if (artifactType === artifactTypes.LW && targetArtifactLW) {
        const { submissionStatus } = targetArtifactLW
        if (submissionStatus && submissionStatus !== null) setArtifactStatus(submissionStatus)
        setReviewInfo(targetArtifactLW)
      }

      if (artifactType === artifactTypes.PRO && targetArtifactPRO) {
        const { submissionStatus } = targetArtifactPRO
        if (submissionStatus && submissionStatus !== null) setArtifactStatus(submissionStatus)
        setReviewInfo(targetArtifactPRO)
      }

      if (artifactType === artifactTypes.EFR && targetArtifactEFR) {
        const { submissionStatus } = targetArtifactEFR
        if (submissionStatus && submissionStatus !== null) setArtifactStatus(submissionStatus)
        setReviewInfo(targetArtifactEFR)
      }
    }
  }, [parsedProps, targetArtifactPRO, targetArtifactLED, targetArtifactLW, targetArtifactEFR, artifactType])

  // If editing (from a auth user perspective), get the submisison status, if applicable, or determine if still a draft
  useEffect(() => {
    if (parsedProps) {
      if (parsedProps.create && artifactType) {
        if (isEditing) {
          if (artifactType === artifactTypes.LED && targetArtifactLED.isForSubmission === 1) {
            if (targetArtifactLED.isDraft === 1) {
              setArtifactStatus('DRAFT')
            } else {
              setArtifactStatus(targetArtifactLED.submissionStatus)
            }
          }

          if (artifactType === artifactTypes.LW && targetArtifactLW.isForSubmission === 1) {
            if (targetArtifactLW.isDraft === 1) {
              setArtifactStatus('DRAFT')
            } else {
              setArtifactStatus(targetArtifactLW.submissionStatus)
            }
          }
          if (artifactType === artifactTypes.PRO && targetArtifactPRO.isForSubmission === 1) {
            if (targetArtifactPRO.isDraft === 1) {
              setArtifactStatus('DRAFT')
            } else {
              setArtifactStatus(targetArtifactPRO.submissionStatus)
            }
          }
        }
      }
    }
  }, [parsedProps, artifactType, isEditing, targetArtifactPRO, targetArtifactLED, targetArtifactLW])

  // The state of the banner in the nav bar
  const [statusBanner, setStatusBanner] = useState('')

  // Callback that is used when a artifact is for submission, to return a styled banner in the top nav
  const getStatusBanner = useCallback((status) => {
    switch (status) {
      case 'NEEDS_REVIEW':
        return (
          <>
            <Grid item style={{ marginLeft: '.5em', display: 'flex', alignItems: 'center' }}>
              <ErrorRounded style={{ fontSize: '14px', color: styles.inactiveText }} />
            </Grid>
            <Grid item style={{ marginLeft: '.3em', display: 'flex', alignItems: 'center' }}>
              <Typography variant='body1' style={{ fontWeight: 600, color: styles.inactiveText }}>Needs Review</Typography>
            </Grid>
          </>
        )
      case null:
        return (
          <>
            <Grid item style={{ marginLeft: '.5em', display: 'flex', alignItems: 'center' }}>
              <ErrorRounded style={{ fontSize: '14px', color: styles.inactiveText }} />
            </Grid>
            <Grid item style={{ marginLeft: '.3em', display: 'flex', alignItems: 'center' }}>
              <Typography variant='body1' style={{ fontWeight: 600, color: styles.inactiveText }}>Needs Review</Typography>
            </Grid>
          </>
        )
      case 'APPROVED':
        return (
          <>
            <Grid item style={{ marginLeft: '.5em', display: 'flex', alignItems: 'center' }}>
              <CheckCircleRounded style={{ fontSize: '14px', color: styles.inactiveText }} />
            </Grid>
            <Grid item style={{ marginLeft: '.3em', display: 'flex', alignItems: 'center' }}>
              <Typography variant='body1' style={{ fontWeight: 600, color: styles.inactiveText }}>Approved</Typography>
            </Grid>
          </>
        )
      case 'DENIED':
        return (
          <>
            <Grid item style={{ marginLeft: '.5em', display: 'flex', alignItems: 'center' }}>
              <CancelRounded style={{ fontSize: '14px', color: styles.inactiveText }} />
            </Grid>
            <Grid item style={{ marginLeft: '.3em', display: 'flex', alignItems: 'center' }}>
              <Typography variant='body1' style={{ fontWeight: 600, color: styles.inactiveText }}>Denied</Typography>
            </Grid>
          </>
        )
      case 'NEEDS_CHANGES':
        return (
          <>
            <Grid item style={{ marginLeft: '.5em', display: 'flex', alignItems: 'center' }}>
              <SwapHorizontalCircleRounded style={{ fontSize: '14px', color: styles.inactiveText }} />
            </Grid>
            <Grid item style={{ marginLeft: '.3em', display: 'flex', alignItems: 'center' }}>
              <Typography variant='body1' style={{ fontWeight: 600, color: styles.inactiveText }}>Changes Requested</Typography>
            </Grid>
          </>
        )
      case 'DRAFT':
        return (
          <>
            <Grid item style={{ marginLeft: '.5em', display: 'flex', alignItems: 'center' }}>
              <ErrorRounded style={{ fontSize: '14px', color: styles.inactiveText }} />
            </Grid>
            <Grid item style={{ marginLeft: '.3em', display: 'flex', alignItems: 'center' }}>
              <Typography variant='body1' style={{ fontWeight: 600, color: styles.inactiveText }}>Draft</Typography>
            </Grid>
          </>
        )
      default:
        return ''
    }
  }, [styles.inactiveText])

  // Checks to see if a staus has been set (only happens when an artifact isForSubmission or is being reviewed), and gets the banner content
  useEffect(() => {
    if (artifactStatus !== null || artifactStatus !== '') {
      setStatusBanner(getStatusBanner(artifactStatus))
    }
  }, [artifactStatus, artifactType, getStatusBanner])

  const formatSection = (form) => {
    switch (form) {
      case 'artifact-details':
        return 'details'
      case 'LED1':
        return 'led1'
      case 'LED2':
        return 'led2'
      case 'resources':
        return form
      case 'learner-work':
        return 'lw'
      case 'summary':
        return 'notes'
      case 'project-items':
        return 'items'
      default: return ''
    }
  }

  const fireUpdateSectionSuccess = (artifactID, exitingArtifact) => {
    if (exitingArtifact === false) { dispatch(getArtifactDetails(artifactID, '', artifactType, () => { }, () => { }, '')) }
  }

  const handleArtifactSectionUpdate = (sectionSeen, artifactID, exitingArtifact = false) => {
    const sectionInfo = {
      artifactID: artifactID,
      artifactSection: sectionSeen !== 'notes' ? formatSection(forms[sectionSeen]) : sectionSeen
    }
    dispatch(updateArtifactSectionComments(sectionInfo, fireUpdateSectionSuccess, () => { }, exitingArtifact))
  }

  // Routes the user back to their profile if not editing or reviewing
  const handleProfileView = () => {
    if (publicArtifact) {
      if (savedExploreScrollPosition !== 0) { dispatch(setExploreScrollPosition(0)); dispatch(setExploreActiveTab('all-posts')) }

      props.history.push({
        pathname: '/profile',
        search: `?user=${authProfileID}`
      })
    }
  }

  // URL params populated when entering the artifact process from the MCE process
  const attemptID = parsedProps && parsedProps.attempt ? parsedProps.attempt : null
  const mceID = parsedProps && parsedProps.mce ? parsedProps.mce : null
  const sampleID = parsedProps && parsedProps.sample ? parsedProps.sample : null

  // return to the microcredential route for the current attempt
  const fireMCEUpdateSuccess = () => {
    const viewParam = publicArtifact ? 'public' : 'create'
    props.history.push({
      pathname: '/microcredentials',
      search: `?view=${mceViews.PURSUE}&mce=${mceID}&attempt=${attemptID}&fromArtifact=${viewParam}`
    })
  }

  // When creating a new isForMCE artifact, we need to be sure we update the attempt with the new ledID
  const returnToMCEAttempt = (ledID) => {
    // if we made a new LED and not updating an old one, then be sure to update the mce attempt with the new LED
    if (ledID && !isEditing) {
      // if we received an ledID from the createLED call, then build the samplesToAdd body for the attempt update
      const updateBody = {
        samplesToAdd: [{
          workSampleID: sampleID,
          artifactID: ledID
        }]
      }

      return dispatch(updateMCEAttempt(updateBody, attemptID, mceID, fireMCEUpdateSuccess))
    }

    fireMCEUpdateSuccess()
  }

  // NOTE (Chris - 6/5/24) -> This useEffect is clearing target artifacts in redux on hot reload causing components to not render.
  useEffect(() => {
    return () => {
      if (artifactType === artifactTypes.LED) dispatch(resetTargetLED())
      if (artifactType === artifactTypes.LW) dispatch(resetTargetLearnerWork())
      if (artifactType === artifactTypes.PRO) dispatch(resetTargetProject())
      if (artifactType === artifactTypes.EFR) dispatch(resetTargetEFR())
      setReviewInfo({})
      setArtifactStatus('')
      setArtifactCancelModalOpen(false)
      duplicateArtifactForm.current = { ...defaultDuplicateForm }
      uiLoading.current = false
    }
  }, [artifactType, dispatch, defaultDuplicateForm])

  return (
    <>
      <ArtifactNav
        styles={styles}
        parsedProps={parsedProps}
        artifactType={artifactType}
        isEditing={isEditing}
        reviewInfo={reviewInfo}
        creatingArtifact={creatingArtifact}
        reviewingArtifact={reviewingArtifact}
        publicArtifact={publicArtifact}
        statusBanner={statusBanner}
        authProfileAvatarPath={authProfileAvatarPath}
        handleProfileView={handleProfileView}
        setArtifactCancelModalOpen={setArtifactCancelModalOpen}
      />

      {/* -------- Renders either the create, review, or public view based on the params ------ */}
      {artifactAvailableCheck &&
        <>
          {creatingArtifact && (!isEditing || (isEditing && typeLoaded)) &&
            <CreateArtifact
              forms={forms}
              styles={styles}
              classes={classes}
              artifactType={artifactType}
              setArtifactStatus={setArtifactStatus}
              artifactStatus={artifactStatus}
              handleArtifactSectionUpdate={handleArtifactSectionUpdate}
              artifactCancelModalOpen={artifactCancelModalOpen}
              setArtifactCancelModalOpen={setArtifactCancelModalOpen}
              duplicateArtifactForm={duplicateArtifactForm}
              defaultDuplicateForm={defaultDuplicateForm}
              returnToMCEAttempt={returnToMCEAttempt}
            />}

          {reviewingArtifact &&
            <ReviewArtifact
              classes={classes}
              theme={theme}
              styles={styles}
              reviewInfo={reviewInfo}
              artifactType={artifactType}
              setArtifactStatus={setArtifactStatus}
            />}
          {publicArtifact &&
            <PublicArtifact
              theme={theme}
              classes={classes}
              artifactType={artifactType}
              styles={styles}
              authorDetails={UserDetails}
              reviewInfo={reviewInfo}
              setReviewInfo={setReviewInfo}
              typeLoaded={typeLoaded}
              uiLoading={uiLoading}
              duplicateArtifactForm={duplicateArtifactForm}
              defaultDuplicateForm={defaultDuplicateForm}
              returnToMCEAttempt={returnToMCEAttempt}
            />}
        </>}

      {!artifactAvailableCheck &&
        <Grid item container direction='column' justifyContent='center' alignItems='center' style={{ margin: '10em', paddingRight: '20em' }}>
          <Grid item>
            <Avatar src={EFRLogo} style={{ height: '5em', width: '5em' }} />
          </Grid>
          <Grid item>
            <Typography variant='h4' style={{ fontWeight: 600, color: theme.palette.grey.dark, textAlign: 'center' }}>Oh no!</Typography>
          </Grid>
          <Grid item>
            <Typography variant='subtitle1' style={{ fontWeight: 400, color: theme.palette.grey.dark, textAlign: 'center', textTransform: 'none' }}>Looks like you do not have permission to view this page.</Typography>
          </Grid>
          <Grid item>
            <Typography
              variant='h6'
              style={{ fontWeight: 400, color: theme.palette.grey.dark, textAlign: 'center', textTransform: 'none' }}
            >
              Click <a href='/' style={{ fontWeight: 600, textDecoration: 'underline' }}>here</a> to return to your profile.
            </Typography>
          </Grid>
        </Grid>}
    </>
  )
}

export default withRouter(ArtifactView)
