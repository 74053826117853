import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
  cardBack: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '6px'
  },
  innerAvatar: {
    visibility: 'visible',
    backgroundColor: theme.palette.grey.lightest,
    marginLeft: '.5em'
  }
}))

// NOTE: renders a loading skeleton in the user section of the search results popper
//  --- Parent Component: ui/Search/SearchPopper.js
const SearchUserLoadingSkel = (props) => {
  const { smScreen } = props
  const classes = useStyles()

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item container direction={smScreen ? 'column' : 'row'} spacing={3}>
        <Grid item xs={smScreen ? null : 6} style={{ paddingRight: '1em' }}>
          <Skeleton variant='rect' width='100%' height='6em' animation='wave' className={classes.cardBack}>
            <Skeleton variant='circle' animation='wave' height='4em' width='4em' className={classes.innerAvatar} />
          </Skeleton>
        </Grid>
        <Grid item xs={smScreen ? null : 6} style={{ paddingRight: '1em' }}>
          <Skeleton variant='rect' width='100%' height='6em' animation='wave' className={classes.cardBack}>
            <Skeleton variant='circle' animation='wave' height='4em' width='4em' className={classes.innerAvatar} />
          </Skeleton>
        </Grid>
      </Grid>
      <Grid item container direction={smScreen ? 'column' : 'row'} spacing={3}>
        <Grid item xs={smScreen ? null : 6} style={{ paddingRight: '1em' }}>
          <Skeleton variant='rect' width='100%' height='6em' animation='wave' className={classes.cardBack}>
            <Skeleton variant='circle' animation='wave' height='4em' width='4em' className={classes.innerAvatar} />
          </Skeleton>
        </Grid>
        <Grid item xs={smScreen ? null : 6} style={{ paddingRight: '1em' }}>
          <Skeleton variant='rect' width='100%' height='6em' animation='wave' className={classes.cardBack}>
            <Skeleton variant='circle' animation='wave' height='4em' width='4em' className={classes.innerAvatar} />
          </Skeleton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SearchUserLoadingSkel
