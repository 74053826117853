import React from 'react'

import { Grid, Typography, makeStyles } from '@material-ui/core'
import { Person as UserIcon } from '@material-ui/icons'
import theme from '../../styles/MuiTheme'

/* NOTES: Message displayed when no results are found for any users in a global search
  -- Parent Component: ui/Search/ResultAreas/UserResults.js

  In depth render tree loacated in SearchBarNav.js
*/

const useStyles = makeStyles({
  noUserResultsIcon: {
    color: theme.palette.purple.darkest,
    fontSize: '35px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px'
    }
  },
  noUserResultsIconGrid: {
    [theme.breakpoints.down('sm')]: {
      height: '1.5em',
      width: '1.5em'
    },
    backgroundColor: '#DFE4F8',
    borderRadius: '50%',
    height: '3em',
    width: '3em'
  }
})

const NoUserResults = () => {
  const classes = useStyles()

  return (
    <div style={{ backgroundColor: `${theme.palette.purple.lightest}75`, padding: '1em' }}>
      <Grid item container direction='row' justifyContent='center' style={{ marginBottom: '1em' }}>
        <Grid item container className={classes.noUserResultsIconGrid} alignContent='center' justifyContent='center'>
          <UserIcon className={classes.noUserResultsIcon} />
        </Grid>
      </Grid>

      <Grid item container direction='row' justifyContent='center'>
        <Typography variant='h5' style={{ color: theme.palette.purple.darkest }}>No Results Found</Typography>
      </Grid>

      <Grid item container direction='row' justifyContent='center'>
        <Typography style={{ textAlign: 'center' }}>There were no users who matched your criteria.</Typography>
      </Grid>
    </div>
  )
}

export default NoUserResults
