import React, { useState, useEffect } from 'react'

import {
  Grid, Paper, Typography, Button, IconButton,
  OutlinedInput, Input, InputAdornment, useTheme,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Divider, Zoom, Fab, Tooltip
} from '@material-ui/core'

import {
  ListRounded as ListIcon, HelpOutlineRounded as HelpIcon,
  AddRounded as AddIcon, Clear as DeleteIcon
} from '@material-ui/icons'

import { makeStyles } from '@material-ui/core/styles'

import { ArtifactComment } from '../../tools'

const useStyles = makeStyles((theme) => ({
  inputPlaceholder: {
    '&::placeholder': {
      fontStyle: 'italic',
      color: 'black'
    }
  },
  InputAdornment: {
    'MuiTypography-colorTextSecondary': {
      color: 'black',
      fontSize: '16px',
      marginBottom: '.45em',
      marginRight: '.4em'
    }
  },
  addIcon: {
    fontSize: '26px',
    stroke: theme.palette.purple.darkest,
    strokeWidth: '2px'

  },
  removeIcon: {
    fontSize: '26px',
    stroke: theme.palette.grey.medium,
    strokeWidth: '2px'
  },
  paperRoot: {
    width: '80%',
    padding: '1.5em',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      '@media only screen and (orientation: landscape)': {
        marginLeft: '1em'
      }
    }
  },

  targetButton: {
    color: theme.palette.purple.darkest
  }
}))

const LEDone = (props) => {
  const {
    handleLearningTargetsChange = () => { }, onTargetAdd = () => { }, onTargetRemove = () => {},
    ledInfo, setLEDInfo, styles, targetArtifactComments
  } = props

  const theme = useTheme()
  const classes = useStyles()

  // Storing logic to check if current learning targets are less than the max limit
  const learningTargetsLimit = ledInfo && ledInfo.targets && ledInfo.targets.length <= 4

  const [modalOpen, setModalOpen] = useState(false)
  const [sectionComments, setSectionComments] = useState([])

  useEffect(() => {
    if (targetArtifactComments) {
      if (targetArtifactComments.length) {
        const tempArr = targetArtifactComments.reduce((arr, comment) => {
          if (comment) {
            if (comment.section === 'led1') {
              arr.push({ ...comment })
            }
          }
          return arr
        }, [])
        setSectionComments(tempArr)
      }
    }
  }, [targetArtifactComments])

  return (
    <>
      {/* Help Modal  */}
      <Dialog
        fullWidth
        maxWidth='sm'
        open={modalOpen}
        onClose={(e) => { e.preventDefault(); setModalOpen(false) }}
      >
        <DialogTitle>
          <Grid container direction='row' alignItems='center' justifyContent='flex-start' style={{ margin: '.5em 0em' }}>
            <Typography variant='h4' style={{ textTransform: 'none', fontSize: '20px' }}>Example: Learning Experience Design I</Typography>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          {/* Overview + Purpose  */}
          <Grid item container direction='row' justifyContent='flex-start'>
            <Typography variant='h5'>Overview + Purpose</Typography>
          </Grid>

          <Grid
            item
            container
            direction='row'
            justifyContent='flex-start'
            style={{
              marginTop: '1rem',
              padding: '1rem',
              border: '1px solid #d8d8d8',
              borderRadius: '5px'
            }}
          >
            <Typography variant='body1'>
              Student Fellows will develop informative digital ads to create understanding, encourage interest, and improve
              application and attendance rates at Historically Black Colleges and Universities (HBCUs).
            </Typography>
          </Grid>

          {/* Learning Targets  */}
          <Grid item container direction='row' justifyContent='flex-start' style={{ marginTop: '1.5rem' }}>
            <Typography variant='h5'>Learning Targets</Typography>
          </Grid>

          <Grid item container direction='row' justifyContent='flex-start' style={{ marginTop: '1rem' }}>
            <Typography variant='body1'>I can develop understanding of Historically Black Colleges and Universities.</Typography>
          </Grid>
          <Divider />
          <Grid item container direction='row' justifyContent='flex-start' style={{ marginTop: '1rem' }}>
            <Typography variant='body1'>I can share my knowledge of HBCUs with others.</Typography>
          </Grid>
          <Divider />
          <Grid item container direction='row' justifyContent='flex-start' style={{ marginTop: '1rem' }}>
            <Typography variant='body1'>I can use the mark-up tool to add a variety of visual elements to a photo.</Typography>
          </Grid>
          <Divider />
          <Grid item container direction='row' justifyContent='flex-start' style={{ marginTop: '1rem' }}>
            <Typography variant='body1'>I can create a digital ad using Clips and iMovie to develop awareness of HBCUs.</Typography>
          </Grid>
          <Divider />

          {/* State Standards  */}
          <Grid item container direction='row' justifyContent='flex-start' style={{ marginTop: '1.5rem' }}>
            <Typography variant='h5'>State Standards</Typography>
          </Grid>

          <Grid
            item
            container
            direction='row'
            justifyContent='flex-start'
            style={{
              marginTop: '1rem',
              padding: '1rem',
              border: '1px solid #d8d8d8',
              borderRadius: '5px'
            }}
          >
            <Typography variant='body1'>
              R5, R6
            </Typography>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='primary' onClick={(e) => { e.preventDefault(); setModalOpen(false) }}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Main Content  */}
      <Paper className={classes.paperRoot}>
        {/* Header Info  */}
        <Grid item container direction='row' spacing={1} style={{ marginBottom: '1em' }}>
          <Grid item style={{ paddingTop: '2px' }}>
            <ListIcon style={{ fontSize: '28px', color: theme.palette.grey.dark }} />
          </Grid>
          <Grid item>
            <Typography variant='h4'>Learning Experience Design I</Typography>
          </Grid>
          <Grid item>
            <IconButton
              size='small'
              style={{ paddingTop: 0, paddingLeft: 0 }}
              onClick={(e) => { e.preventDefault(); setModalOpen(true) }}
            >
              <HelpIcon style={{ fontSize: '19px', color: theme.palette.purple.darkest }} />
            </IconButton>
          </Grid>
        </Grid>

        <Grid
          item
          container
          style={{ marginBottom: '.5rem' }}
        >
          <Typography variant='caption'><span style={{ color: 'red', fontSize: '14px' }}>*</span> indicates a required field.</Typography>
        </Grid>

        {/* Overview + Purpose  */}
        <Grid
          item
          container
          direction='row'
          alignItems='center'
          justifyContent='flex-start'
          style={{ marginTop: '.5rem' }}
        >
          <Grid item>
            <Typography style={{ color: 'red', fontSize: '14px', marginRight: '.2em' }}>*</Typography>
          </Grid>
          <Grid item>
            <Typography variant='body1' style={{ fontWeight: '600' }}>Overview + Purpose</Typography>
          </Grid>
          <Grid item>
            <IconButton
              size='small'
              style={{ paddingTop: 0, paddingLeft: 5, paddingBottom: 5 }}
              onClick={(e) => { e.preventDefault(); setModalOpen(true) }}
            >
              <HelpIcon style={{ fontSize: '16px', color: theme.palette.purple.darkest }} />
            </IconButton>
          </Grid>
        </Grid>

        <Grid item container direction='row' alignItems='center'>
          <Typography
            variant='caption'
            style={{ color: theme.palette.grey.dark }}
          >
            Describe what is being learned and why it is important in 1-2 sentences.
          </Typography>
        </Grid>

        <Grid item container direction='row' alignItems='center' style={{ marginTop: '1rem' }}>
          <OutlinedInput
            variant='outlined'
            fullWidth
            margin='dense'
            multiline
            rows={3}
            placeholder='Enter your response...'
            classes={{ input: classes.inputPlaceholder }}
            value={ledInfo.overview}
            inputProps={{
              maxLength: 512
            }}
            onChange={(e) => setLEDInfo({ ...ledInfo, overview: e.target.value })}
          />
        </Grid>
        <Grid item>
          <Typography variant='caption' style={{ color: '#adadad', paddingLeft: '.3em' }}>{`${ledInfo && ledInfo.overview && ledInfo.overview.length ? ledInfo.overview.length : 0}/512`}</Typography>
        </Grid>

        {/* Learning Targets */}
        <Grid
          item
          container
          direction='row'
          alignItems='center'
          justifyContent='flex-start'
          style={{ marginTop: '1.5rem' }}
        >
          <Grid item>
            <Typography style={{ color: 'red', fontSize: '14px', marginRight: '.2em' }}>*</Typography>
          </Grid>
          <Grid item>
            <Typography variant='body1' style={{ fontWeight: '600' }}>Learning Targets</Typography>
          </Grid>
          <Grid item>
            <IconButton
              size='small'
              style={{ paddingTop: 0, paddingLeft: 5, paddingBottom: 5 }}
              onClick={(e) => { e.preventDefault(); setModalOpen(true) }}
            >
              <HelpIcon style={{ fontSize: '16px', color: theme.palette.purple.darkest }} />
            </IconButton>
          </Grid>
        </Grid>

        <Grid item container direction='row' alignItems='center'>
          <Typography
            variant='caption'
            style={{ color: theme.palette.grey.dark }}
          >
            Provide up to 5 student friendly statements showcasing what they should learn from the learning experience.
          </Typography>
        </Grid>

        {ledInfo && ledInfo.targets && Boolean(ledInfo.targets.length) &&
          ledInfo.targets.map((targetInfo, index) => {
            const { targetID, targetName } = targetInfo

            return (
              <Zoom in={index !== 'undefined'} key={`${targetID}`} style={{ transitionDelay: index === 0 ? 'none' : '50ms' }}>
                <Grid
                  item
                  container
                  direction='row'
                  alignItems='center'
                  style={{ marginTop: '1rem' }}
                >
                  <Input
                    variant='outlined'
                    fullWidth
                    margin='dense'
                    placeholder='your response...'
                    classes={{ input: classes.inputPlaceholder }}
                    value={targetName}
                    inputProps={{
                      maxLength: 256
                    }}
                    onChange={(e) => { e.stopPropagation(); handleLearningTargetsChange(e.target.value, [index]) }}
                    startAdornment={<InputAdornment position='start'>I can</InputAdornment>}
                    endAdornment={
                      index > 0
                        ? (
                          <>
                            <IconButton
                              onClick={(e) => { e.preventDefault(); onTargetRemove(targetID) }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </>
                        ) : (<></>)
                    }
                  />
                </Grid>
              </Zoom>
            )
          })}

        <Grid item container direction='row' alignItems='center' style={{ marginTop: '1rem' }}>
          <Tooltip title='Cannot exceed more than 5 learning targets' disableHoverListener={learningTargetsLimit} arrow placement='right'>
            <span>
              <Button
                className={classes.targetButton}
                variant='text'
                startIcon={<AddIcon className={learningTargetsLimit ? classes.addIcon : classes.removeIcon} />}
                style={{ textTransform: 'none', fontWeight: '600', fontSize: '.9rem' }}
                onClick={(e) => { e.preventDefault(); onTargetAdd() }}
                disabled={!learningTargetsLimit}
              >
                Add additional learning target
              </Button>
            </span>
          </Tooltip>
        </Grid>

        {/* State Standards */}
        <Grid
          item
          container
          direction='row'
          alignItems='center'
          justifyContent='flex-start'
          style={{ marginTop: '1.5rem' }}
        >
          <Grid item>
            <Typography variant='body1' style={{ fontWeight: '600' }}>State Standards</Typography>
          </Grid>
          <Grid item>
            <IconButton
              size='small'
              style={{ paddingTop: 0, paddingLeft: 5, paddingBottom: 5 }}
              onClick={(e) => { e.preventDefault(); setModalOpen(true) }}
            >
              <HelpIcon style={{ fontSize: '16px', color: theme.palette.purple.darkest }} />
            </IconButton>
          </Grid>
        </Grid>

        <Grid item container direction='row' alignItems='center'>
          <Typography
            variant='caption'
            style={{ color: theme.palette.grey.dark }}
          >
            Add state standards that will be learned in this experience. Please separate standards with a comma.
          </Typography>
        </Grid>

        <Grid item container direction='row' alignItems='center' style={{ marginTop: '1rem' }}>
          <OutlinedInput
            variant='outlined'
            fullWidth
            margin='dense'
            placeholder='Enter your response...'
            classes={{ input: classes.inputPlaceholder }}
            value={ledInfo.stateStandards}
            inputProps={{
              maxLength: 256
            }}
            onChange={(e) => setLEDInfo({ ...ledInfo, stateStandards: e.target.value })}
          />
        </Grid>
        <Grid item>
          <Typography variant='caption' style={{ color: '#adadad', paddingLeft: '.3em' }}>{`${ledInfo && ledInfo.stateStandards && ledInfo.stateStandards.length ? ledInfo.stateStandards.length : 0}/ 256`}</Typography>
        </Grid>

        {/* Comments */}
        {sectionComments && sectionComments.length !== 0 &&
          <>
            <Divider />
            <Grid item container direction='column' style={{ marginBottom: '2em', marginTop: '1em' }}>
              <Grid item container direction='row' alignContent='center'>
                <Grid item>
                  <Typography variant='body1' style={{ fontWeight: '600' }}>Comments</Typography>
                </Grid>
                <Grid item style={{ marginLeft: '.5em' }}>
                  <Fab style={{ height: '15px', width: '15px', minHeight: '15px', backgroundColor: styles.mainColor, boxShadow: 'none' }}>
                    <Typography variant='body1' style={{ fontSize: '10px', color: 'white', fontWeight: 600 }}>{sectionComments ? sectionComments.length : 0}</Typography>
                  </Fab>
                </Grid>
              </Grid>
              <Grid item container direction='column' spacing={2} style={{ marginTop: '1em' }}>
                {sectionComments && sectionComments.length !== 0 &&
                  sectionComments.map(comment => {
                    return (
                      <ArtifactComment
                        key={comment.commentID}
                        classes={classes}
                        commentInfo={comment}
                        artifactID={comment.artifactID}
                        section='details'
                        styles={styles}
                        fromCreate
                      />
                    )
                  })}
              </Grid>
            </Grid>
          </>}
      </Paper>
    </>
  )
}

export default LEDone
