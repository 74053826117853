import React from 'react'
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography, IconButton, Button, useTheme
} from '@material-ui/core'
import { CloseRounded } from '@material-ui/icons'

const MCENavgiagteExitModal = (props) => {
  const {
    openNavigationConfirm = false, setOpenNavigationConfirm = () => {}, classes = {}, isAssessorView = false,
    isPursuerView = false, handleNavigateConfirm = () => {}
  } = props

  const theme = useTheme()

  return (
    <Dialog
      open={openNavigationConfirm}
      fullWidth
      maxWidth='sm'
      onClose={() => { setOpenNavigationConfirm(false) }}
    >
      <DialogTitle disableTypography>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography
              variant='h4'
              style={{ textTransform: 'none', color: theme.palette.purple.darkest, fontSize: '1.3rem' }}
            >
              Are you sure you want to exit this page?
            </Typography>
          </Grid>
          <Grid item>
            <IconButton style={{ padding: '0px' }} onClick={(e) => { e.preventDefault(); setOpenNavigationConfirm(false) }}>
              <CloseRounded className={classes.customizedButton} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ paddingTop: 0, marginBottom: '2em' }}>
        <Grid container item direction='column'>
          <Typography>{`${isAssessorView ? 'Your progress will not be saved.' : 'Be sure you have saved any changes before navigating away from this page.'}`}</Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item container direction='row' justifyContent='flex-end'>
          <Button
            variant='text'
            style={{ color: theme.palette.purple.darkest, fontWeight: 600, textTransform: 'none', marginRight: '1em' }}
            onClick={(e) => { e.preventDefault(); handleNavigateConfirm(false) }}
          >
            Exit without Saving
          </Button>

          {isPursuerView &&
            <Button
              variant='contained'
              size='small'
              style={{ backgroundColor: theme.palette.purple.darkest, color: 'white', fontWeight: 600, textRansform: 'none' }}
              onClick={(e) => { e.preventDefault(); handleNavigateConfirm(true) }}
            >
              Save and Exit
            </Button>}
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default MCENavgiagteExitModal
