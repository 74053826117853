import React from 'react'
import { useSelector } from 'react-redux'
import {
  Grid, Typography, Paper, useTheme, Button, Divider
} from '@material-ui/core'
import { handleExternalLinkClick, defaultTargetArtifactLED } from '../../../../../utils'
import { ArtifactComment, PublishButton } from '../../../tools'

const SummaryDetails = (props) => {
  const {
    classes = {}, styles, sectionComments = [], setCurrentForm = () => {}, getValue = () => {}, ledInfo = { ...defaultTargetArtifactLED },
    selectedLearnerWork, setPublishModalOpen = () => { }, handleSaveArtifact = () => {},
    isDraft = false, isForMCE = false, artifactNotCompleteCheck = false
  } = props
  const theme = useTheme()
  const subjects = useSelector(state => state.profileData.subjects)

  const {
    title,
    grades,
    completionTime,
    workType,
    techNeeded,
    coreSubjectID,
    secondarySubjectID,
    overview,
    engageContent,
    investigateContent,
    createContent
  } = ledInfo

  return (
    <Grid container item direction='column' style={{ marginTop: '1em', paddingRight: '2em' }}>
      {/* Notes */}
      {sectionComments && sectionComments.length !== 0 &&
        <>
          <Grid item container direction='column' style={{ marginBottom: '2em' }}>
            <Grid item container direction='row' alignContent='center'>
              <Grid item>
                <Typography variant='body1' style={{ fontWeight: '600' }}>Notes</Typography>
              </Grid>
            </Grid>
            <Grid item container direction='column' spacing={2} style={{ marginTop: '1em' }}>
              {sectionComments && sectionComments.length !== 0 &&
                sectionComments.map(comment => {
                  return (
                    <ArtifactComment
                      key={comment.commentID}
                      classes={classes}
                      commentInfo={comment}
                      artifactID={comment.artifactID}
                      section='notes'
                      styles={styles}
                      fromCreate
                    />
                  )
                })}
            </Grid>
          </Grid>
        </>}

      {/* **************** Artifact Details ***************** */}
      <Paper elevation={0} style={{ backgroundColor: theme.palette.purple.lightest, padding: '1em', width: '100%' }}>
        <Grid container item direction='row'>
          {/* Left Side Column */}
          <Grid item container direction='column' xs={3} sm={2}>
            <Grid item>
              <Typography variant='h5' style={{ fontWeight: '600' }}>Artifact Details</Typography>
            </Grid>
            <Grid item>
              <Button
                variant='text'
                size='small'
                style={{
                  color: theme.palette.purple.darkest,
                  textTransform: 'none',
                  fontWeight: 600,
                  justifyContent: 'flex-start',
                  paddingLeft: 0
                }}
                onClick={(e) => { e.preventDefault(); setCurrentForm(0) }}
              >
                Edit
              </Button>
            </Grid>
          </Grid>

          <Divider flexItem variant='middle' orientation='vertical' />

          {/* Right Side Column */}
          <Grid item container direction='column' xs={8} sm={9} spacing={3}>

            {/* Name */}
            <Grid item container direction='column'>
              <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>* What's the name of your artifact?</Typography>
              <Typography
                variant='body1'
                style={{
                  color: title !== '' ? theme.palette.black : 'red',
                  fontWeight: '600',
                  fontSize: '13px',
                  wordBreak: 'break-word'
                }}
              >
                {title !== '' ? title : 'Incomplete'}
              </Typography>
            </Grid>

            {/* Grades */}
            <Grid item container direction='column'>
              <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>* Grade(s)?</Typography>
              <Typography
                variant='body1'
                style={{
                  color: grades && grades.length !== 0 ? theme.palette.black : 'red',
                  fontWeight: '600',
                  fontSize: '13px'
                }}
              >
                {grades && grades.length !== 0 ? getValue('grades') : 'Incomplete'}
              </Typography>
            </Grid>

            {/* Completion Time */}
            <Grid item container direction='column'>
              <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>* Completion Time</Typography>
              <Typography
                variant='body1'
                style={{
                  color: completionTime === 0 ? 'red' : completionTime === '' ? 'red' : theme.palette.black,
                  fontWeight: '600',
                  fontSize: '13px'
                }}
              >
                {completionTime === 0 ? 'Incomplete' : completionTime === '' ? 'Incomplete' : `${completionTime} weeks`}
              </Typography>
            </Grid>

            {/* Type of Work */}
            <Grid item container direction='column'>
              <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>* Type of Work</Typography>
              <Typography
                variant='body1'
                style={{
                  color: workType !== '' ? theme.palette.black : 'red',
                  fontWeight: '600',
                  fontSize: '13px',
                  textTransform: 'capitalize',
                  wordBreak: 'break-word'
                }}
              >
                {workType !== '' ? workType === 'both' ? 'Individual & Group' : workType : 'Incomplete'}
              </Typography>
            </Grid>

            {/* Technology Needed */}
            <Grid item container direction='column'>
              <Typography
                variant='body1'
                style={{ color: theme.palette.grey.dark, fontWeight: '600' }}
              >
                * Technology Needed
              </Typography>
              <Typography
                variant='body1'
                style={{
                  color: techNeeded !== '' ? theme.palette.black : 'red',
                  fontWeight: '600',
                  fontSize: '13px',
                  wordBreak: 'break-word'
                }}
              >
                {techNeeded !== '' ? techNeeded : 'Incomplete'}
              </Typography>
            </Grid>

            {/* Core Subject */}
            <Grid item container direction='column'>
              <Typography
                variant='body1'
                style={{
                  color: theme.palette.grey.dark,
                  fontWeight: '600'
                }}
              >
                    * Core Subject
              </Typography>
              <Typography variant='body1' style={{ color: coreSubjectID && coreSubjectID !== '' ? theme.palette.black : 'red', fontWeight: '600', fontSize: '13px' }}>
                {subjects && subjects.length !== 0 && coreSubjectID && coreSubjectID !== '' ? getValue('core-subject') : 'Incomplete'}
              </Typography>
            </Grid>

            {/* Secondary Subject */}
            <Grid item container direction='column'>
              <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>Secondary Subject</Typography>
              <Typography variant='body1' style={{ color: theme.palette.black, fontWeight: '600', fontSize: '13px' }}>
                {subjects && subjects.length !== 0 && secondarySubjectID && secondarySubjectID !== '' ? getValue('secondary-subject') : 'N/A'}
              </Typography>
            </Grid>

            {/* ISTE Standards */}
            <Grid item container direction='column'>
              <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>ISTE Standards</Typography>
              <Typography
                variant='body1'
                style={{
                  color: theme.palette.black,
                  fontWeight: '600',
                  fontSize: '13px'
                }}
              >
                {ledInfo && ledInfo.ISTEStandards && ledInfo.ISTEStandards.length !== 0 ? getValue('ISTE-standards') : 'N/A'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      {/* **************** LED 1 ***************** */}
      <Paper elevation={0} style={{ backgroundColor: theme.palette.purple.lightest, padding: '1em', width: '100%', marginTop: '1em' }}>
        <Grid container item direction='row'>
          {/* Left Side Column */}
          <Grid item container direction='column' xs={3} sm={2}>
            <Grid item>
              <Typography variant='h5' style={{ fontWeight: '600' }}>Learning Experience Design I</Typography>
            </Grid>
            <Grid item>
              <Button
                variant='text'
                size='small'
                style={{
                  color: theme.palette.purple.darkest,
                  textTransform: 'none',
                  fontWeight: 600,
                  justifyContent: 'flex-start',
                  paddingLeft: 0
                }}
                onClick={(e) => { e.preventDefault(); setCurrentForm(1) }}
              >
                Edit
              </Button>
            </Grid>
          </Grid>

          <Divider flexItem variant='middle' orientation='vertical' />

          {/* Right Column */}
          <Grid item container direction='column' xs={8} sm={9} spacing={3}>
            {/* Overview */}
            <Grid item container direction='column'>
              <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>* Overview + Purpose</Typography>
              <Typography variant='body1' style={{ color: overview !== '' ? theme.palette.black : 'red', fontWeight: '600', fontSize: '13px', wordBreak: 'break-word' }}>{overview !== '' ? overview : 'Incomplete'}</Typography>
            </Grid>

            {/* Learning Targets */}
            <Grid item container direction='column'>
              <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>* Learning Targets</Typography>
              {ledInfo && ledInfo.targets && ledInfo.targets.length !== 0
                ? (
                  ledInfo.targets.map((targetInfo) => {
                    const { targetID = '', targetName = '' } = targetInfo
                    return (
                      <Typography
                        key={`learning target ${targetID}`}
                        variant='body1'
                        style={{
                          color: targetName !== '' ? theme.palette.black : 'red',
                          fontWeight: '600',
                          fontSize: '13px',
                          wordBreak: 'break-word'
                        }}
                      >
                        {targetName === '' ? 'Incomplete' : `I can ${targetName}`}
                      </Typography>
                    )
                  })
                )
                : (
                  <Typography variant='body1' style={{ color: 'red', fontWeight: '600', fontSize: '13px' }}>Incomplete</Typography>
                )}
            </Grid>

            {/* State Standards */}
            <Grid item container direction='column'>
              <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>State Standards</Typography>
              <Typography
                variant='body1'
                style={{ color: theme.palette.black, fontWeight: '600', fontSize: '13px', wordBreak: 'break-word' }}
              >
                {ledInfo.stateStandards !== '' ? ledInfo.stateStandards : 'N/A'}
              </Typography>
            </Grid>

          </Grid>
        </Grid>
      </Paper>

      {/* **************** LED 2 ***************** */}
      <Paper elevation={0} style={{ backgroundColor: theme.palette.purple.lightest, padding: '1em', width: '100%', marginTop: '1em' }}>
        <Grid container item direction='row'>
          {/* Left Side Column */}
          <Grid item container direction='column' xs={3} sm={2}>
            <Grid item>
              <Typography variant='h5' style={{ fontWeight: '600' }}>Learning Experience Design II</Typography>
            </Grid>
            <Grid item>
              <Button
                variant='text'
                size='small'
                style={{
                  color: theme.palette.purple.darkest,
                  textTransform: 'none',
                  fontWeight: 600,
                  justifyContent: 'flex-start',
                  paddingLeft: 0
                }}
                onClick={(e) => { e.preventDefault(); setCurrentForm(2) }}
              >
                Edit
              </Button>
            </Grid>
          </Grid>

          <Divider flexItem variant='middle' orientation='vertical' />

          {/* Right Side Column */}
          <Grid item container direction='column' xs={8} sm={9} spacing={3}>
            {/* Engage */}
            <Grid item container direction='column'>
              <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>* Engage</Typography>
              <Typography
                variant='body1'
                style={{
                  color: engageContent !== '' ? theme.palette.black : 'red',
                  fontWeight: '600',
                  fontSize: '13px',
                  wordBreak: 'break-word'
                }}
              >
                {engageContent !== '' ? engageContent : 'Incomplete'}
              </Typography>
            </Grid>

            {/* Investigate */}
            <Grid item container direction='column'>
              <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>* Investigate</Typography>
              <Typography
                variant='body1'
                style={{
                  color: investigateContent !== '' ? theme.palette.black : 'red',
                  fontWeight: '600',
                  fontSize: '13px',
                  wordBreak: 'break-word'
                }}
              >
                {investigateContent !== '' ? investigateContent : 'Incomplete'}
              </Typography>
            </Grid>

            {/* Create */}
            <Grid item container direction='column'>
              <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>* Create</Typography>
              <Typography
                variant='body1'
                style={{
                  color: createContent !== '' ? theme.palette.black : 'red',
                  fontWeight: '600',
                  fontSize: '13px',
                  wordBreak: 'break-word'
                }}
              >
                {createContent !== '' ? createContent : 'Incomplete'}
              </Typography>
            </Grid>

            {/* Act */}
            <Grid item container direction='column'>
              <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>Act</Typography>
              <Typography
                variant='body1'
                style={{
                  color: theme.palette.black,
                  fontWeight: '600',
                  fontSize: '13px',
                  wordBreak: 'break-word'
                }}
              >
                {ledInfo.actContent !== '' ? ledInfo.actContent : 'N/A'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      {/* **************** Resources ***************** */}
      <Paper elevation={0} style={{ backgroundColor: theme.palette.purple.lightest, padding: '1em', width: '100%', marginTop: '1em' }}>
        <Grid container item direction='row'>
          {/* Left Side Column */}
          <Grid item container direction='column' xs={3} sm={2}>
            <Grid item>
              <Typography variant='h5' style={{ fontWeight: '600' }}>Resources</Typography>
            </Grid>
            <Grid item>
              <Button
                variant='text'
                size='small'
                style={{
                  color: theme.palette.purple.darkest,
                  textTransform: 'none',
                  fontWeight: 600,
                  justifyContent: 'flex-start',
                  paddingLeft: 0
                }}
                onClick={(e) => { e.preventDefault(); setCurrentForm(3) }}
              >
                Edit
              </Button>
            </Grid>
          </Grid>

          <Divider flexItem variant='middle' orientation='vertical' />

          {/* Right Side Column */}
          <Grid item container direction='column' xs={8} sm={9} spacing={3}>
            {/* Documents */}
            <Grid item container direction='column'>
              <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>Documents</Typography>
              {ledInfo && ledInfo.attachments && ledInfo.attachments.length !== 0
                ? (
                  ledInfo.attachments.map((attachment, i) => {
                    return (
                      <Typography
                        key={`attachment ${i}`}
                        variant='body1'
                        style={{
                          color: theme.palette.purple.darkest,
                          fontWeight: '600',
                          fontSize: '13px',
                          textDecoration: 'underline'
                        }}
                      >
                        {attachment.fileName}
                      </Typography>
                    )
                  })

                )
                : (
                  <Typography variant='body1' style={{ color: theme.palette.black, fontWeight: '600', fontSize: '13px' }}>N/A</Typography>
                )}
            </Grid>

            {/* Links */}
            <Grid item container direction='column'>
              <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>Links</Typography>
              {ledInfo && ledInfo.links && ledInfo.links.length !== 0
                ? (
                  ledInfo.links.map((linkInfo, i) => {
                    const { linkID, link: linkName } = linkInfo
                    return (
                      <Typography
                        key={`link-${linkID}`}
                        variant='body1'
                        onClick={() => handleExternalLinkClick(linkName)}
                        style={{
                          color: theme.palette.purple.darkest,
                          fontWeight: '600',
                          fontSize: '13px',
                          textDecoration: 'underline',
                          wordBreak: 'break-word',
                          cursor: 'pointer'
                        }}
                      >
                        {linkName}
                      </Typography>
                    )
                  })

                )
                : (
                  <Typography variant='body1' style={{ color: theme.palette.black, fontWeight: '600', fontSize: '13px' }}>N/A</Typography>
                )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      {/* **************** Learner Work ***************** */}
      {
        !isForMCE &&
          <Paper elevation={0} style={{ backgroundColor: theme.palette.purple.lightest, padding: '1em', width: '100%', marginTop: '1em' }}>
            <Grid container item direction='row'>
              {/* Left Side Column */}
              <Grid item container direction='column' xs={3} sm={2}>
                <Grid item>
                  <Typography variant='h5' style={{ fontWeight: '600' }}>Learner Work</Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant='text'
                    size='small'
                    style={{
                      color: theme.palette.purple.darkest,
                      textTransform: 'none',
                      fontWeight: 600,
                      justifyContent: 'flex-start',
                      paddingLeft: 0
                    }}
                    onClick={(e) => { e.preventDefault(); setCurrentForm(4) }}
                  >
                    Edit
                  </Button>
                </Grid>
              </Grid>

              <Divider flexItem variant='middle' orientation='vertical' />

              {/* Right Side Column */}
              <Grid item container direction='column' xs={8} sm={9} spacing={3}>
                {/* LW artifact */}
                <Grid item container direction='column'>
                  <Typography variant='body1' style={{ color: theme.palette.black, fontWeight: '600', fontSize: '13px' }}>
                    {selectedLearnerWork && selectedLearnerWork !== null && selectedLearnerWork.learnerWorkTitle ? selectedLearnerWork.learnerWorkTitle : 'N/A'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
      }

      {/* Publish Button (only appears while artifact is still a draft) */}
      <PublishButton
        styles={styles}
        theme={theme}
        classes={classes}
        isDraft={isDraft}
        artifactNotCompleteCheck={artifactNotCompleteCheck}
        setPublishModalOpen={setPublishModalOpen}
        isForMCE={isForMCE}
        handleSaveArtifact={handleSaveArtifact}
      />

    </Grid>
  )
}

export default SummaryDetails
