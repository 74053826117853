import { artifactTypes, esRecordType as prefixTypes } from '../utils'

const getArtifactType = (guid) => {
  switch (guid.substring(0, 2)) {
    case prefixTypes.PR:
      return { type: artifactTypes.PROJ, id: 'projectID' }
    case prefixTypes.LW:
      return { type: artifactTypes.LW, id: 'learnerWorkID' }
    case prefixTypes.LE:
      return { type: artifactTypes.LED, id: 'ledID' }
    case prefixTypes.EF:
      return { type: artifactTypes.EFR, id: 'resourceID' }
    default:
      return { type: null, id: null }
  }
}

export { getArtifactType }
