import { processImage } from '../lib'
import { v4 as uuidv4 } from 'uuid'

/** Images (Avatar / Profile Banner/ Certification Images / EFR Featured Photo) **/
// --- Allowed Extensions: jpg, jpeg, png, gif
// --- Max Size: 2mb
export const profileImageMaxSize = 2097152
export const maxAspectRatio = 2.5
export const minAspectRatio = 1

const profileImgFileExtArr = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/gif'
]

/** Resources (Artifacts) **/
// --- Allowed Extensions: jpg, jpeg, png, gif, tiff, bmp, svg, eps, pdf,
// ----------------------- doc, docx, odt, xls, xlsx, ods, ppt, pptx, txt, pages, numbers, key
// --- Max Size: 128mb
export const artifactResourceMaxSize = 134217728

const validResourceDocFormat = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.apple.pages',
  'application/x-iwork-pages-sffpages',
  'application/vnd.apple.numbers',
  'application/x-iwork-numbers-sffnumbers',
  'application/x-iwork-keynote-sffkey',
  'text/plain',
  'audio/mp3',
  'audio/mpeg',
  'audio/wav',
  '/pages',
  '/key',
  '/numbers'
]

const validResourceImageFormats = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/tiff',
  'image/bmp',
  'image/svg+xml',
  'image/x-eps',
  'image/heic',
  'image/heif'
]

const validVideoFormats = [
  'video/mp4',
  'video/quicktime',
  'video/x-ms-wmv',
  'video/x-ms-wmv',
  'video/x-msvideo',
  'video/x-msvideo',
  'video/x-matroska'
]
/** Images (Artifacts) \\ Currently combined into resources // **/
// --- Allowed Extensions: jpg, jpeg, png, gif, tiff, bmp, svg, eps, pdf
// --- Max Size: 128mb
export const artifactImageMaxSize = 134217728

export const artifactImageFileExtensions = {
  JPG: 'jpg',
  JPEG: 'jpeg',
  PNG: 'png',
  GIF: 'gif',
  HEIC: 'heic',
  HEIF: 'heif',
  TIFF: 'tiff',
  BMP: 'bmp',
  SVG: 'svg',
  EPS: 'eps',
  MP4: 'mp4',
  MOV: 'mov',
  WMV: 'wmv',
  AVI: 'avi',
  MKV: 'mkv'
}

/** Documents (Artifacts) \\ Currently combined into resources // **/
// --- Allowed Extensions: doc, docx, odt, xls, xlsx, ods, ppt, pptx, txt, pages, numbers, key
// --- Max Size: 128mb
export const artifactDocumentMaxSize = 134217728

/** Video (Artifacts) \\ WE ARE CURRENTLY NOT HANDLING VIDEO // **/
// --- Allowed Extensions: mp4, mov, wmv, avi, mkv
// --- Max Size: 2048MB (2GB)
// --- Length @ 2GB: 4K - 24min, 1080P 1.5 Hours, 480P 17 Hours
export const artifactVideoMaxSize = 2147483648

export const uploadType = {
  PROFILE_IMAGE: 'PROFILE_IMAGE',
  ARTIFACT_RESOURCE: 'ARTIFACT_RESOURCE',
  MCE_SAMPLE_UPLOAD: 'MCE_SAMPLE_UPLOAD'
}

// Gets the s3 formatted contentType for the file attachment
export const getContentType = (type) => {
  switch (type) {
    // Image Types
    case 'jpeg': {
      return 'image/jpeg'
    }
    case 'jpg': {
      return 'image/jpeg'
    }
    case 'png': {
      return 'image/png'
    }
    case 'gif': return 'image/gif'
    case 'heic': {
      return 'image/heic'
    }
    case 'heif': {
      return 'image/heif'
    }
    // Pdf type
    case 'pdf': {
      return 'application/pdf'
    }
    // Microsoft Types
    case 'doc': {
      return 'application/msword'
    }
    case 'docx': {
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    }
    case 'ppt': {
      return 'application/vnd.ms-powerpoint'
    }
    case 'pptx': {
      return 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    }
    case 'xls': {
      return 'application/vnd.ms-excel'
    }
    case 'xlsx': {
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
    // Apple Document Types
    case 'pages': {
      return 'application/x-iwork-pages-sffpages'
    }
    case 'key': {
      return 'application/x-iwork-keynote-sffkey'
    }
    case 'numbers': {
      return 'application/x-iwork-numbers-sffnumbers'
    }
    // Microsoft Types
    case 'msword': {
      return 'application/msword'
    }
    case 'vnd.openxmlformats-officedocument.wordprocessingml.document': {
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    }
    case 'vnd.ms-powerpoint': {
      return 'application/vnd.ms-powerpoint'
    }
    case 'vnd.openxmlformats-officedocument.presentationml.presentation': {
      return 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    }
    case 'vnd.ms-excel': {
      return 'application/vnd.ms-excel'
    }
    case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
    // Apple Document Types
    case 'x-iwork-pages-sffpages': {
      return 'application/vnd.apple.pages'
    }
    case 'x-iwork-keynote-sffkey': {
      return 'application/vnd.apple.keynote'
    }
    case 'x-iwork-numbers-sffnumbers': {
      return 'application/vnd.apple.numbers'
    }
    // Plain Text
    case 'plain': {
      return 'text/plain'
    }
    case 'mp4': {
      return 'video/mp4'
    }
    case 'quicktime': {
      return 'video/quicktime'
    }
    case 'wmv': {
      return 'video/x-ms-wmv'
    }
    case 'x-ms-wmv': {
      return 'video/x-ms-wmv'
    }
    case 'avi': {
      return 'video/x-msvideo'
    }
    case 'x-msvideo': {
      return 'video/x-msvideo'
    }
    case 'x-matroska': {
      return 'video/x-matroska'
    }
    case 'wav': {
      return 'audio/wav'
    }
    case 'mp3': {
      return 'audio/mp3'
    }
    case 'mpeg': {
      return 'audio/mpeg'
    }
    default: return false
  }
}

/**
 * @property {array} file - array containing data, type, size
 * @property {string} type - upload type (PROFILE_IMAGE, ARTIFACT_RESOURCE, MCE_SAMPLE_UPLOAD)
 * @property {array} attachments - array of current attachments for artifact resources
 * @property {function} setFileSizeError - set file size error
 * @property {function} setFileExtensionError - set file extension error
 *
 * @returns base64 and type for file
 */
export const uploadFileFormatter = async (file, type, attachments = [], handleFileSizeError = () => { }, handleFileExtensionError = () => { }) => {
  try {
    if (file && file.length > 0) {
      const [fileData] = file
      const { size: fileSize = 0, name: fileName = '', type: fileType } = fileData

      // file extension type for uploaded file
      let fileExtension = fileType

      const iWorkExtensions = ['.pages', '.key', '.numbers']

      iWorkExtensions.forEach(ext => {
        if (fileName.endsWith(ext)) {
          fileExtension = ext.replace('.', '/')
        }
      })

      // size is too large if the upload type matches, is over the max upload size, and is an allowed extension
      const imageSizeCheck = type === uploadType.PROFILE_IMAGE && fileSize > profileImageMaxSize
      // extension is wrong if the upload type matches and it is not an allowed extension
      const imageExtensionCheck = type === uploadType.PROFILE_IMAGE && !profileImgFileExtArr.includes(fileExtension)

      // size is too large if the upload type matches, is over the max upload size, and is an allowed extension
      const resourceSizeCheck = (type === uploadType.ARTIFACT_RESOURCE || type === uploadType.MCE_SAMPLE_UPLOAD) &&
        ((validResourceDocFormat.includes(fileExtension) && fileSize > artifactResourceMaxSize) ||
          (validVideoFormats.includes(fileExtension) && fileSize > artifactVideoMaxSize) ||
          (validResourceImageFormats.includes(fileExtension) && fileSize > artifactImageMaxSize))

      // extension is wrong if the upload type matches and it is not an allowed extension
      const resourceExtensionCheck = (type === uploadType.ARTIFACT_RESOURCE || type === uploadType.MCE_SAMPLE_UPLOAD) && ![...validResourceDocFormat, ...validVideoFormats, ...validResourceImageFormats].includes(fileExtension)

      // if the size checks fail, then display an error
      if (imageSizeCheck || resourceSizeCheck) {
        handleFileSizeError(true)
        return false
      }

      // if the extension checks fail, then display an error
      if (imageExtensionCheck || resourceExtensionCheck) {
        handleFileExtensionError(true)
        return false
      }

      fileExtension = fileExtension.split('/')[1]
      // uuid generated for uploading new artifact resources
      const uuid = uuidv4().replace(/-/g, '')

      switch (type) {
        case uploadType.PROFILE_IMAGE: {
          const { imageData, imageWidth, imageHeight } = await processImage(fileData)

          const newAspect = imageWidth / imageHeight

          if (newAspect >= maxAspectRatio && newAspect <= minAspectRatio) {
            return false
          }

          return { imageData, fileExtension }
        }
        case uploadType.ARTIFACT_RESOURCE: {
          const tempArr = [...attachments]
          const attachmentID = `AT${uuid}`

          const newFileMeta = { fileName, fileExtension, contentType: getContentType(fileExtension), attachmentID, uploadType: type }

          tempArr.push(newFileMeta)

          return { tempArr, newFileMeta }
        }
        case uploadType.MCE_SAMPLE_UPLOAD: {
          const resourceID = `AS${uuid}`

          return ({ resourceName: fileName, fileExtension, contentType: getContentType(fileExtension), resourceID, uploadType: type })
        }
        default: return false
      }
    }
  } catch (err) {
    console.error(err)
  }
}
