import {
  USER_CURRENT_MCE_ATTEMPTS, USER_MCE_ATTEMPT_DETAILS, LOGOUT,
  RESET_CURRENT_ATTEMPT_DETAILS
} from '../types'
import { defaultAttemptDetailsForm } from '../../utils/variables'
import { cloneDeep } from 'lodash'

const initialState = {
  currentAttempts: [],
  currentAttemptDetails: cloneDeep(defaultAttemptDetailsForm)
}

const mceAttemptsReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_CURRENT_MCE_ATTEMPTS: return {
      ...state,
      currentAttempts: action.payload
    }
    case USER_MCE_ATTEMPT_DETAILS: return {
      ...state,
      currentAttemptDetails: action.payload
    }
    case RESET_CURRENT_ATTEMPT_DETAILS: return {
      ...state,
      currentAttemptDetails: cloneDeep(defaultAttemptDetailsForm)
    }
    case LOGOUT: return {
      ...initialState
    }
    default: return state
  }
}

export default mceAttemptsReducer
