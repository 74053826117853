import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import edFarmLogo from '../../assets/edFarmLogo.png'
import teacherImg from '../../assets/teacher.svg'
// Assets
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { getApprovalStatus, logout } from '../../redux/actions'
import { formValidationTypes } from '../../lib'

// MUI
import {
  Grid, Typography
} from '@material-ui/core'
import { NotificationToast, BaseToast } from '../ui/tools'

// themes are set in app.js wrapping routes with theme provider
const useStyles = makeStyles((theme) => ({
  heading: {
    color: theme.palette.purple.darkest
  },
  darkBackground: {
    backgroundColor: theme.palette.purple.darkest
  }
}))

const AwaitingApproval = props => {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()

  const authObject = useSelector(state => state.auth)
  const { isAuthenticated, isValidated, isAccountApproved } = authObject

  useEffect(() => { dispatch(getApprovalStatus(fireSuccess, fireNotification)) }, [dispatch])

  useEffect(() => {
    const checkApproval = async () => {
      if (isAccountApproved) {
        // route back to confirmation needed page
        await BaseToast(false,
          'Account Approved!',
          'Congratulations, your account has been approved! Click to start enjoying Ed Farm Learn.')
        props.history.push({
          pathname: '/'
        })
      }
    }
    checkApproval()
  }, [isAccountApproved, props.history])

  const checkStatus = () => {
    dispatch(getApprovalStatus(fireSuccess, fireNotification))
  }

  const fireSuccess = () => {
    NotificationToast(false, 'Acquired approval status')
  }

  const fireNotification = (errorType) => {
    if (errorType === formValidationTypes.DDOS_ERROR) { NotificationToast(true, 'Wait a few more seconds, then try again.') }
    if (errorType === formValidationTypes.SEND_ERROR) { NotificationToast(true, 'There was a problem checking your approval status. Please wait for a moment and try again.') }
  }

  const handleReturnClick = () => {
    if (isAuthenticated) {
      if (!isValidated || !isAccountApproved) {
        dispatch(logout())
      }
    } else {
      props.history.push('/')
    }
  }
  // ************ Main Render ************ //
  return (
    <>
      <Grid container direction='row'>
        <Grid item xs={4} className={classes.darkBackground} style={{ height: '100vh', width: '100%' }}>
          <Grid container direction='column' style={{ justifyContent: 'space-between', height: '100vh' }}>
            <Grid item style={{ width: '100%', marginLeft: '2em', marginTop: '2em' }}>
              <Grid item>
                <img src={edFarmLogo} alt='logo' style={{ maxHeight: '3.7em' }} />
              </Grid>
              <Grid item style={{ marginTop: '3em' }} container xs={10}>
                <Typography variant='h1' style={{ color: 'white', fontWeight: '600' }}>Gain inspiration and learn from other</Typography>
                <Typography variant='h1' style={{ color: 'white', fontWeight: '600' }}>Teacher Fellows</Typography>
              </Grid>
            </Grid>
            <Grid item container alignContent='flex-end'>
              <Grid item container style={{ width: '100%', height: 'auto' }}>
                {/* TODO: Will not display with overhang on large screen */}
                <img style={{ height: '100%', width: '150%', marginLeft: '-7em' }} src={teacherImg} alt='Teacher Fellow' />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={8}>
          <Grid item container direction='column' alignContent='center' justifyContent='center' style={{ margin: '0 9em 3em', paddingRight: '20em', marginTop: '6vh' }}>
            <Grid item container direction='row' alignItems='center' justifyContent='center' style={{ display: 'flex', marginTop: '25vh', marginLeft: '10vw' }}>
              <Grid item container direction='column'>
                <Grid item xs={9}>
                  <Typography variant='h2'>Awaiting Ed Farm approval</Typography>
                </Grid>
                <Grid item container style={{ marginTop: '2em' }} direction='column'>
                  <Typography variant='body1'>Thank you for signing up for Ed Farm! The Ed Farm Department of Learning is reviewing your request for account creation.</Typography>
                </Grid>
                <Grid item container style={{ marginTop: '2em' }} direction='column'>
                  <Typography variant='body1'>We'll email you within 48 hours with our decision</Typography>
                </Grid>
                <Grid item container style={{ marginTop: '2em' }} direction='column'>
                  <Typography variant='body1'><span style={{ fontWeight: '600', color: theme.palette.purple.darkest, cursor: 'pointer' }} onClick={() => checkStatus()}>Click here</span> to refresh your approval status.</Typography>
                </Grid>
                <Grid item container style={{ marginTop: '2em' }} direction='column'>
                  {/* TODO: Need to change this to ed farm preferred email address and change to real click handler */}
                  <Typography variant='body1' gutterBottom>If you have any questions, please contact our support team.</Typography>
                  <Typography variant='body1'>Click <span style={{ fontWeight: '600', color: theme.palette.purple.darkest, cursor: 'pointer' }} onClick={handleReturnClick}>here</span> to return to the login page.</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default withRouter(AwaitingApproval)
