import { trackPromise } from 'react-promise-tracker'
import { parseResponse } from '../../lib'
import {
  GET_SKILLS_LIST, RESET_SKILLS_LIST,
  DATA_REQUEST, DATA_SUCCESS, DATA_ERROR
} from '../types'

import log from 'loglevel'

// UI Tech Debt: Removed api variable that wasn't being used anywhere

const api = process.env.REACT_APP_API_URL

export const getSkillsList = () => {
  return async (dispatch, getState) => {
    const { token } = getState().auth

    dispatch({ type: DATA_REQUEST, payload: { type: GET_SKILLS_LIST } })

    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }

    const response = await trackPromise(window.fetch(`${api}/v1/skills`, options))
    const parsedResponse = parseResponse(response, dispatch)
    if (!parsedResponse) { return false }

    if (parsedResponse.error) {
      log.info(response.status)

      dispatch({ type: DATA_ERROR, payload: { type: GET_SKILLS_LIST } })
    } else {
      const { data } = await response.json()

      dispatch({ type: DATA_SUCCESS, payload: { type: GET_SKILLS_LIST } })

      dispatch({ type: GET_SKILLS_LIST, payload: data })
    }
  }
}

export const updateUserSkills = (currentSkillsToAdd, newSkillsToAdd, skillsToDelete, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { userID, token } = getState().auth

      // currentSkillsToAdd: array of skillIDs
      // newSkillsToAdd: array of names
      // skillsToDelete: array of skillIDs to do delete
      const body = { currentSkillsToAdd, newSkillsToAdd, skillsToDelete }
      const totalAdd = currentSkillsToAdd.length + newSkillsToAdd.length

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
      }

      // The user can only add 5 skills at a time.
      if (totalAdd <= 5) {
        const response = await trackPromise(window.fetch(`${api}/v1/user/${userID}/skills`, options))
        const parsedResponse = parseResponse(response, dispatch)
        if (!parsedResponse) { return false }

        if (parsedResponse.error) {
          log.info(response.status)
          fireFailure()
        } else {
          fireSuccess()
        }
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export function resetSkillsList () {
  return async function (dispatch) {
    dispatch({ type: RESET_SKILLS_LIST })
  }
}
