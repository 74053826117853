import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { SocialIconSection } from '../../ui/tools'
import { artifactTypes } from '../../../utils'

import {
  Grid, Card, Divider, useTheme
} from '@material-ui/core'

import DefaultCardView from './ArtifactCardViews/DefaultCardView'
import LEDCardView from './ArtifactCardViews/LEDCardView'

import { setExploreScrollPosition, postArtifactSave, deleteArtifactSave } from '../../../redux/actions'
// Cloudfront url path
const cloudfrontPath = process.env.REACT_APP_CLOUD_FRONT_BASE
// LED Background bucket
const ledBgPrefix = process.env.REACT_APP_AWS_S3_DEFAULT_LED_BG_PREFIX

const templateArtifact = {
  fullName: '',
  learnerWorkID: '',
  nickName: '',
  createdAt: 0,
  subjectName: '',
  techNeeded: '',
  title: '',
  artifactID: '',
  likeCount: 0,
  saveCount: 0,
  publicCommentCount: 0,
  grades: [],
  ledTitles: [],
  isDraft: 0,
  isPrivate: 0,
  submissionStatus: ''
}

const ArtifactCard = (props) => {
  const {
    classes, type, artifactInfo = templateArtifact, canEditArtifact = false, onArtifactEdit = () => { },
    editMode, onSocialButtonClick = () => { console.warn('this view does not yet support social interaction.') },
    artifactLiked = false, artifactSaved = false, viewSaved = false, fromExplore = false, noSocial = false
  } = props
  const dispatch = useDispatch()
  const theme = useTheme()

  const [displaySaveCount, setDisplaySaveCount] = useState(0)

  const {
    grades = '',
    publicCommentCount = 0, likeCount = 0, saveCount = 0,
    isDraft = 0, isPrivate, submissionStatus, profileID = '',
    LEDBackgroundPath = '', backgroundKey
  } = artifactInfo

  const checkedPath = !LEDBackgroundPath ? `${cloudfrontPath}/${ledBgPrefix}/${!backgroundKey ? 'circles-default' : backgroundKey}` : LEDBackgroundPath

  /* ------ Banner Type Checks ------- */
  // Artifact is complete but not viewable to the public
  const privateCheck = isDraft === 0 && isPrivate === 1

  // Artifact complete and viewable to the public, but was not submitted to ed farm
  const publishedCheck = isPrivate === 0 && submissionStatus === 'NA' && !isDraft

  // Artifact is complete and submitted to edFarm (note: if the artifact has been submitted but not reviewed the submission status comes back as null)
  const statusCheck = canEditArtifact && (!submissionStatus || (artifactInfo.submissionStatus && (artifactInfo.submissionStatus !== 'NA')))

  // Splits string that lists current artifact grade names into an array
  const gradesArray = grades && Boolean(grades.length) ? grades.split(',') : ''

  // Handles routing the user to a public/read only view of an artifact
  const handlePublicView = async (type) => {
    const position = window.pageYOffset
    dispatch(setExploreScrollPosition(position))

    const { artifactID } = artifactInfo
    const formatType =
      type === 'LED' ? artifactTypes.LED
        : type === 'LW' ? artifactTypes.LW
          : type === 'PRO' ? artifactTypes.PRO
            : artifactTypes.EFR

    props.history.push({
      pathname: '/artifact',
      search: `?public=${formatType}&user=${profileID}&artifact=${artifactID}`
    })
  }

  const handleSaveArtifact = async (e, artifact) => {
    e.preventDefault()
    e.stopPropagation()

    const { artifactID: artID, authorID = '' } = artifact

    // If the artifact has already been 'saved' by the user
    if (artifactSaved) {
      await dispatch(deleteArtifactSave(artID, handleDeleteSaveSuccess))
    }

    // The artifact has not been 'saved'
    if (!artifactSaved) {
      await dispatch(postArtifactSave({ artifactID: artID, authorID }, handleSaveSuccess))
    }
  }

  const handleSaveSuccess = () => {
    const newCount = displaySaveCount + 1

    if (newCount >= 0) { setDisplaySaveCount(newCount) } else { setDisplaySaveCount(0) }
  }

  const handleDeleteSaveSuccess = () => {
    const newCount = displaySaveCount - 1

    if (newCount >= 0) { setDisplaySaveCount(newCount) } else { setDisplaySaveCount(0) }
  }

  const getTypeStyle = (artifactType) => {
    switch (artifactType) {
      case 'LED':
        return theme.palette.pink.dark
      case 'LW':
        return '#1A7FA4'
      case 'PRO':
        return '#5D40BE'
      case 'EFR':
        return '#B41515'
      default:
        return theme.palette.pink.dark
    }
  }

  const disableAllButtons = (isPrivate || isDraft || (!isDraft && (!submissionStatus || (submissionStatus !== 'APPROVED' && submissionStatus !== 'NA'))))

  return (
    <Card elevation={2} style={{ width: '100%', height: 'inherit', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <div
        style={{
          cursor: !editMode || viewSaved ? 'pointer' : 'default',
          backgroundImage: type === 'LED' && checkedPath ? `url(${checkedPath})` : '',
          backgroundRepeat: 'none',
          backgroundSize: 'cover',
          height: '100%'
        }}
        onClick={!editMode || viewSaved ? () => handlePublicView(type) : () => { return false }}
      >
        {type && type === 'LED'
          ? (
            <LEDCardView
              editMode={editMode}
              canEditArtifact={canEditArtifact}
              classes={classes}
              statusCheck={statusCheck}
              privateCheck={privateCheck}
              publishedCheck={publishedCheck}
              artifactInfo={artifactInfo}
              gradesArray={gradesArray}
              onArtifactEdit={onArtifactEdit}
              type={type}
              getTypeStyle={getTypeStyle}
            />
          )
          : (
            <DefaultCardView
              artifactInfo={artifactInfo}
              type={type}
              gradesArray={gradesArray}
              handleSaveArtifact={handleSaveArtifact}
              disableAllButtons={disableAllButtons}
              classes={classes}
              statusCheck={statusCheck}
              onArtifactEdit={onArtifactEdit}
              canEditArtifact={canEditArtifact}
              editMode={editMode}
              getTypeStyle={getTypeStyle}
            />
          )}
      </div>
      <Divider />
      {!noSocial &&
        <Grid container direction='row' style={{ padding: '0.5em', paddingLeft: '1em' }} alignItems='center'>
          <SocialIconSection
            commentCount={publicCommentCount}
            artifactType={type}
            likeCount={likeCount}
            saveCount={saveCount}
            onSocialButtonClick={onSocialButtonClick}
            artifactInfo={artifactInfo}
            classes={classes}
            artifactLiked={artifactLiked}
            artifactSaved={artifactSaved}
            handlePublicView={handlePublicView}
            isPrivate={isPrivate}
            submissionStatus={submissionStatus}
            isDraft={isDraft}
            type={type}
            fromExplore={fromExplore}
          />
        </Grid>}
    </Card>
  )
}

export default withRouter(ArtifactCard)
