import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import LoadingScreen from '../ui/tools/LoadingScreen'
import configureStore from '../../redux/store'
import './App.css'
import Routes from './Routes'
import LogRocket from 'logrocket'
import CacheBuster from 'react-cache-buster'
import { version } from '../../../package.json'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

const App = () => {
  const { store, persistor } = configureStore()
  // Initialize LogRocket if not in development mode
  if (process.env.REACT_APP_LOGROCKET_ID) {
    LogRocket.init(process.env.REACT_APP_LOGROCKET_ID)
    // Once the key is set, start a new recording session
    LogRocket.startNewSession()
  }

  return (
    <CacheBuster
      version={version}
      isEnabled
      isVerboseMode={false}
      currentVersion={version}
    >
      <Provider store={store}>
        <PersistGate loading={<LoadingScreen />} persistor={persistor}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Routes />
          </MuiPickersUtilsProvider>
        </PersistGate>
      </Provider>
    </CacheBuster>
  )
}

export default App
