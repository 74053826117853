import { DATA_REQUEST, DATA_SUCCESS, DATA_ERROR, LOADING_STATE_RESET } from '../types'

const initialState = {
  type: '',
  loading: false,
  error: false
}

const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_REQUEST: return {
      ...state,
      type: action.payload.type,
      loading: true,
      error: false
    }
    case DATA_SUCCESS: return {
      ...state,
      type: '',
      loading: false,
      error: false
    }
    case DATA_ERROR: return {
      ...state,
      type: action.payload.type,
      loading: false,
      error: true
    }
    case LOADING_STATE_RESET: return {
      ...state,
      type: '',
      loading: false,
      error: false
    }
    default: return state
  }
}

export default loadingReducer
