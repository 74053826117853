import React from 'react'
import { chunk } from 'lodash'

import { Grid } from '@material-ui/core'

// *** Child Components:
import NoUserResults from '../NoResults/NoUserResults'
import SearchUserCard from '../ResultCards/SearchUserCard'

/* NOTE: If results are found, maps through results, otherwise renders no results component
  -- Parent Component: ui/Search/SearchPopper.js
    -- Child Components:
      - ../NoResults/NoUserResults
      - ../ResultCards/SearchUserCard
*/

const UserResults = (props) => {
  // Props:
  // SearchPopper.js : smScreen, userResults (deconstructed from searchResults passed to the parent from SearchBarNav.js)
  // SearchBarNav.js: handleReset
  const { smScreen, userResults, handleReset, xsScreen, handleNoResultsViewAll } = props

  return (
    <>
      {/* If results were found in the api call, render the user cards for each */}
      {userResults && Boolean(userResults.length)
        ? (
          <Grid container direction='column' spacing={2}>
            {/* chunk the results into two arrays of two results each (if more than 4 results are returned, a 'view all' button will render in the parent component) */}
            {chunk(userResults, 2).map((chunkArr, i) => {
              return (
                <Grid key={`user-arr-${i}`} item container direction={smScreen ? 'column' : 'row'} spacing={3}>
                  {chunkArr.map(user => {
                    return (
                      <Grid key={`user-result-${user.profileID}`} item xs={smScreen ? null : 6} style={{ paddingRight: '.5em' }}>
                        <SearchUserCard user={user} smScreen={smScreen} xsScreen={xsScreen} handleReset={handleReset} />
                      </Grid>
                    )
                  })}
                </Grid>
              )
            })}
          </Grid>
        )
        : (
          // otherwise display no results message
          <NoUserResults handleNoResultsViewAll={handleNoResultsViewAll} />
        )}
    </>
  )
}

export default UserResults
