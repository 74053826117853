import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

import { Grid, Slide, AppBar, Toolbar, Button } from '@material-ui/core'

import ReviewSideMenu from '../ui/reviewArtifacts/ReviewSideMenu'
import ReviewLED from '../ui/reviewArtifacts/ReviewLED'
import ReviewLW from '../ui/reviewArtifacts/ReviewLW'
import ReviewProject from '../ui/reviewArtifacts/ReviewProject'

import { resetTargetLED, resetTargetLearnerWork, resetTargetProject, getUserLearnerWork, getUserLEDs, resetReviewArtifact } from '../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'

const ReviewArtifact = (props) => {
  const {
    classes, theme, styles, reviewInfo,
    artifactType, setArtifactStatus
  } = props
  const dispatch = useDispatch()

  // This is the information provided from the table in Admin Artifacts
  const authorInfo = useSelector(state => state.admin.reviewArtifact)

  // Fetch's the artifact author's lw's in order to grab the name of any asscociated lw's with the LED
  useEffect(() => {
    if (authorInfo) {
      if (authorInfo.profileID) {
        if (artifactType === 'Learning Experience Design') {
          dispatch(getUserLearnerWork(authorInfo.profileID))
        }
        if (artifactType === 'Learner Work') {
          dispatch(getUserLEDs(authorInfo.profileID))
        }

        if (artifactType === 'Project') {
          dispatch(getUserLEDs(authorInfo.profileID))
          dispatch(getUserLearnerWork(authorInfo.profileID))
        }
      }
    }
  }, [authorInfo, artifactType, dispatch])

  // Reset the redux storage
  const handleReset = (type) => {
    switch (type) {
      case 'Learning Experience Design': return dispatch(resetTargetLED())
      case 'Learner Work': return dispatch(resetTargetLearnerWork())
      case 'Project': return dispatch(resetTargetProject())
      default: return false
    }
  }

  const handleReviewReset = () => {
    dispatch(resetReviewArtifact())
  }

  const [formattedInfo, setFormattedInfo] = useState({})

  // Direct the user back to admin on exit
  const handleExitReview = () => {
    handleReset(artifactType)
    handleReviewReset()
    setArtifactStatus('')
    setFormattedInfo({})
    props.history.push({
      pathname: '/admin',
      search: '?tab=artifacts'
    })
  }

  return (
    <>
      <Grid container direction={theme.breakpoints.down('sm') ? 'row' : 'column'} justifyContent='center' style={{ marginTop: '5em', marginBottom: '6em' }} spacing={4}>
        {/* Side Menu */}
        <ReviewSideMenu
          classes={classes}
          artifactType={artifactType}
          styles={styles}
          authorInfo={authorInfo}
          handleExitReview={handleExitReview}
          setArtifactStatus={setArtifactStatus}
          handleReset={handleReset}
        />
        <Grid item container sm={null} md={9} className={classes.formContainer}>
          <Slide
            in={artifactType === 'Learning Experience Design'}
            exit={false}
            unmountOnExit
            appear
            mountOnEnter
            direction='left'
          >
            <div style={{ width: '100%' }}>
              <ReviewLED
                classes={classes}
                artifactType={artifactType}
                styles={styles}
                reviewInfo={reviewInfo}
                formattedInfo={formattedInfo}
                setFormattedInfo={setFormattedInfo}
              />
            </div>
          </Slide>

          <Slide
            in={artifactType === 'Learner Work'}
            exit={false}
            unmountOnExit
            appear
            mountOnEnter
            direction='left'
          >
            <div style={{ width: '100%' }}>
              <ReviewLW
                classes={classes}
                artifactType={artifactType}
                styles={styles}
                reviewInfo={reviewInfo}
                formattedInfo={formattedInfo}
                setFormattedInfo={setFormattedInfo}
              />
            </div>
          </Slide>

          <Slide
            in={artifactType === 'Project'}
            exit={false}
            unmountOnExit
            appear
            mountOnEnter
            direction='left'
          >
            <div style={{ width: '100%' }}>
              <ReviewProject
                classes={classes}
                artifactType={artifactType}
                styles={styles}
                reviewInfo={reviewInfo}
                formattedInfo={formattedInfo}
                setFormattedInfo={setFormattedInfo}
              />
            </div>
          </Slide>
        </Grid>
      </Grid>

      <AppBar style={{ top: 'auto', bottom: 0 }}>
        <Toolbar style={{ backgroundColor: 'white', padding: '.5em' }}>
          {/* Adding 50px to the right to allow for the size of the new embedded jira help icon which cannot be edited */}
          <Grid container direction='row' style={{ marginRight: '50px' }}>
            <Grid item container direction='row' className={classes.saveExitBtn}>
              <Grid item>
                <Button
                  variant='outlined'
                  style={{
                    marginRight: '.3em',
                    fontWeight: '600',
                    padding: '.5em 2em',
                    color: styles.mainColor,
                    borderColor: styles.mainColor
                  }}
                  onClick={handleExitReview}
                >
                  Exit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default withRouter(ReviewArtifact)
