import React from 'react'
import clsx from 'clsx'
import { Stepper, Step, StepLabel, useTheme, Typography, makeStyles, withStyles, StepConnector } from '@material-ui/core'
import {
  Assessment, Assignment, PostAdd, AddToPhotos, Ballot
} from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  stepIconRoot: {
    backgroundColor: '#b1b7d3',
    zIndex: 1,
    color: '#fff',
    width: 60,
    height: 60,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  stepActive: {
    backgroundColor: theme.palette.purple.darkest,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
  },
  stepCompleted: {
    backgroundImage: `linear-gradient( 136deg, ${theme.palette.logo.orange} 0%, ${theme.palette.pink.dark} 20%, ${theme.palette.purple.darkest} 100%)`
  }
}))

const StyledConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 28
  },
  active: {
    '& $line': {
      backgroundColor: theme.palette.purple.darkest
    }
  },
  completed: {
    '& $line': {
      backgroundImage: `linear-gradient( 136deg, ${theme.palette.purple.darkest} 40%, ${theme.palette.pink.dark} 100%)`
    }
  },
  line: {
    height: 8,
    border: 0,
    backgroundColor: '#b1b7d3',
    borderRadius: 1
  }
}))(StepConnector)

const StyledStepIcon = (props) => {
  const classes = useStyles()
  const { active, completed } = props

  const icons = {
    1: <Assignment />,
    2: <PostAdd />,
    3: <AddToPhotos />,
    4: <Assessment />,
    5: <Ballot />
  }

  return (
    <div
      className={clsx(classes.stepIconRoot, {
        [classes.stepActive]: active,
        [classes.stepCompleted]: completed
      })}
    >
      {icons[String(props.icon)]}
    </div>
  )
}

const BuilderStepper = (props) => {
  const { steps, activeStep, setActiveStep = () => {} } = props
  const theme = useTheme()
  return (
    <Stepper alternativeLabel activeStep={activeStep} connector={<StyledConnector />} style={{ backgroundColor: 'transparent' }}>
      {steps.map((label, i) => (
        <Step key={label}>
          <StepLabel StepIconComponent={StyledStepIcon} onClick={() => setActiveStep(i)}>
            <Typography variant='h6' style={{ color: theme.palette.purple.darkest, textTransform: 'none' }}>{label}</Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

export default BuilderStepper
