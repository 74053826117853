import {
  GET_CONVERSATIONS, SET_TEMP_CONVERSATIONS, UPDATE_DRAFT_CONVERSATIONS,
  SET_CONVERSATION, SET_CURRENT_CONNECTION, SET_MESSAGES, SET_SOCKET, UPDATE_CONVERSATION, UPDATE_CONVERSATIONS,
  SET_SOCKET_CLOSED_CODE, SET_UNREAD_MESSAGES, SET_ACTIVE_CONVERSATION_USERS,
  LOGOUT
} from '../types'

// UI Tech Debt: Removed draftConversations in initialState and GET_DRAFT_CONVERSATIONS

const initialState = {
  conversations: [],
  conversationTotal: 0,
  draftConversationTotal: 0,
  unreadMessages: 0,
  activeConversationID: null,
  activeConversationUsers: [],
  messages: [],
  LastEvaluatedKey: null,
  socketConnected: false,
  socketClosedCode: 0,
  activeSocket: {},
  drafts: []
}

const websocketReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CONVERSATION: return {
      ...state,
      messages: action.payload
    }
    case GET_CONVERSATIONS: return {
      ...state,
      conversationTotal: action.payload.conversationTotal,
      conversations: action.payload.conversationList,
      draftConversationTotal: action.payload.draftConversationTotals,
      drafts: action.payload.drafts
    }
    case UPDATE_CONVERSATIONS: return {
      ...state,
      conversations: action.payload.conversationList
    }
    case UPDATE_DRAFT_CONVERSATIONS: return {
      ...state,
      drafts: action.payload.drafts
    }
    case SET_TEMP_CONVERSATIONS: return {
      ...state,
      conversations: action.payload.conversationList
    }
    case SET_CONVERSATION: return {
      ...state,
      activeConversationID: action.payload,
      messages: [],
      LastEvaluatedKey: null
    }
    case SET_ACTIVE_CONVERSATION_USERS: return {
      ...state,
      activeConversationUsers: action.payload
    }
    case SET_MESSAGES: return {
      ...state,
      messages: action.payload.messages,
      LastEvaluatedKey: action.payload.LastEvaluatedKey
    }
    case SET_SOCKET: return {
      ...state,
      socketConnected: action.payload.setSocket
    }
    case SET_SOCKET_CLOSED_CODE: return {
      ...state,
      socketClosedCode: action.payload
    }
    case SET_CURRENT_CONNECTION: return {
      ...state,
      activeSocket: action.payload
    }
    case SET_UNREAD_MESSAGES: return {
      ...state,
      unreadMessages: action.payload
    }
    case LOGOUT: return {
      ...initialState,
      error: action.payload.error || false
    }
    default: return state
  }
}

export default websocketReducer
