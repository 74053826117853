import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { Grid, Typography, Avatar, Chip } from '@material-ui/core'

import { viewTypes, viewConfig } from './utils'

import ConversationArea from './ConversationList/ConversationArea'
import MessageArea from './MessageContent/MessageArea'

import { setActiveConversationUsers } from '../../../redux/actions'

/* // ****** Main/Base Convey Component: ******** //
  -  Renders:
        - left side 'conversation area'
        - right side 'message area'
  - state change logic should be handled here and passed into the areas via a 'view' component
  - theme is also defined here and passed into all children
  - this component has a 'navHeight' prop passed from it's parent to allow it to render underneath a nav bar
*/

const ConveyMessage = (props) => {
  const {
    parsedProps, classes, theme, smScreen,
    activeConversationID,
    storedView,
    navHeight = 0, fetchNew, setFetchNew,
    uiLoading, messagesInitialLoad
  } = props

  const dispatch = useDispatch()

  const { conversations = [], unreadMessages = 0, socketClosedCode = 0, messages = [] } = useSelector(state => state.websocket)

  // View is set via the param to a config object (located in Convey/utils/variables.js) and dicates which components load
  const view = !storedView ? viewConfig.DEFAULT : viewConfig[storedView.toUpperCase()]

  // Checks to see if the header should change when in 'draft' view
  const draftCheck = view && view.conversation === 'drafts'

  const positionCheck = conversations && !conversations.length

  // Confirm popper related elements (Start)
  // These are used to control and set a confirmation popover for deleting drafts inside of MessageChain to be able to access ws handleDrafts function
  // *** deleteDraftID is set on draft card. It is the conversationID you are selecting to delete and is used in MessageChain to determine which to delete.
  const [deleteDraftID, setDeleteDraftID] = useState('')
  // ** confirmAnchorEl is an anchor element that is set to the delete draft button on draft card when clicked
  const [confirmAnchorEl, setConfirmAnchorEl] = useState(null)

  // This is passed down to DraftConversationCard to set the anchor element
  const handleConfirmPopover = (e) => {
    setConfirmAnchorEl(e.currentTarget)
  }

  // Sets the popover in MessageChain to be open when anchor element is set in DraftConversationCard
  const openConfirmPopover = Boolean(confirmAnchorEl)
  // Confirm popper related elements (End)

  // pushes the correct view into the url params
  const loadNewView = useCallback((newView, conversationID) => {
    if (newView && conversationID && typeof conversationID !== 'undefined') {
      if (!messagesInitialLoad.current) { messagesInitialLoad.current = true }
      props.history.replace({
        search: `?view=${newView.toLowerCase()}&conversationID=${conversationID}`
      })
    } else if (newView) {
      props.history.replace({
        search: `?view=${newView.toLowerCase()}`
      })
    }
  }, [messagesInitialLoad, props.history])

  // Clean up state items on convey unmount
  useEffect(() => {
    // Reset to default values
    const handleCleanUp = () => {
      setDeleteDraftID('')
      setConfirmAnchorEl(null)
    }

    return () => handleCleanUp()
  }, [setConfirmAnchorEl, setDeleteDraftID])

  // Checks the currently displayed message thread to be sure that conversation was not hidden on another tab
  const localStorageUpdateCheck = useCallback(() => {
    if (conversations && messages) {
      if (messages.length) {
        // If the message's conversationID is no longer in the list of available conversations,
        // grab the first conversation in the list and set it to the active conversation in the params,
        // and it's users to the local display
        const firstMessageID = messages[0].conversationID
        if (!conversations.length || conversations.findIndex(x => x.conversationID === firstMessageID) === -1) {
          if (conversations.length) {
            const { conversationID, userListDetails } = conversations[0]
            dispatch(setActiveConversationUsers(userListDetails))
            loadNewView(viewTypes.MESSAGE, conversationID)
          } else {
            loadNewView(viewTypes.DEFAULT)
          }
        }
      }
    }
  }, [conversations, messages, dispatch, loadNewView])

  // Don't know that I love how often this will fire...
  useMemo(() => {
    localStorageUpdateCheck()
  }, [localStorageUpdateCheck])

  // If the user has hidden all their messages, send them to the no messages view, if new messages come in, send them back to the inbox
  useMemo(() => {
    if (parsedProps) {
      const { view: propView } = parsedProps

      if (propView && propView === 'message') {
        if (!conversations || (conversations && !conversations.length)) {
          loadNewView(viewTypes.DEFAULT)
        }
      }

      if (propView && propView === 'default') {
        if (conversations && conversations.length) {
          loadNewView(viewTypes.MESSAGE)
        }
      }
    }
  }, [loadNewView, parsedProps, conversations])
  return (
    <Grid
      item
      container
      direction={smScreen ? 'column' : 'row'}
      style={{ paddingTop: navHeight, position: positionCheck ? 'fixed' : 'static'/*, height: !smScreen ? '100vh' : 'auto' */ }}
    >
      {/* Left Message/Conversation Menu */}
      <Grid item container direction='column' xs={12} md={3} style={{ backgroundColor: theme.palette.grey.lighter, padding: '1.5em' }}>
        {/* Message Page Header */}
        <Grid item container direction='row' alignItems='center' style={smScreen ? { paddingTop: '1em' } : { padding: 0, margin: '0' }}>
          <Typography variant='h1' style={{ fontWeight: 900 }}>
            {draftCheck ? 'Drafts' : 'Messages'}
          </Typography>
          {Boolean(unreadMessages && unreadMessages > 0) &&
            <Avatar style={{ height: '1.5em', marginLeft: '.5em', width: '1.5em', backgroundColor: theme.palette.aqua }}>
              <Typography variant='h5' style={{ fontWeight: 600 }}>{unreadMessages}</Typography>
            </Avatar>}
        </Grid>
        {/* Beta Badge */}
        <Chip
          size='small'
          variant='outlined'
          label='BETA'
          color='primary'
          style={{
            height: '14px',
            fontSize: '8px',
            fontWeight: 600,
            color: theme.palette.purple.darkest,
            margin: '0 auto .5em 0',
            borderRadius: '3px'
          }}
          classes={{ colorPrimary: classes.betaBadge }}
        />

        {/* Conversation List */}
        <Grid item container className={classes.convoAreaGrid} direction='column'>
          <ConversationArea
            theme={theme}
            smScreen={smScreen}
            view={view}
            storedView={storedView}
            viewTypes={viewTypes}
            loadNewView={loadNewView}
            activeConversationID={activeConversationID}
            fetchNew={fetchNew}
            setFetchNew={setFetchNew}
            parsedProps={parsedProps}
            classes={classes}
            uiLoading={uiLoading}
            messagesInitialLoad={messagesInitialLoad}
            handleConfirmPopover={handleConfirmPopover}
            setDeleteDraftID={setDeleteDraftID}
          />
        </Grid>
      </Grid>

      {/* Right Message Chain */}
      <Grid item container direction='column' xs={12} md={9} style={{ padding: smScreen ? '.5em' : '1.5em' }}>
        <MessageArea
          theme={theme}
          smScreen={smScreen}
          view={view}
          storedView={storedView}
          viewTypes={viewTypes}
          loadNewView={loadNewView}
          activeConversationID={activeConversationID}
          parsedProps={parsedProps}
          classes={classes}
          uiLoading={uiLoading}
          socketClosedCode={socketClosedCode}
          messagesInitialLoad={messagesInitialLoad}
          confirmAnchorEl={confirmAnchorEl}
          setConfirmAnchorEl={setConfirmAnchorEl}
          openConfirmPopover={openConfirmPopover}
          deleteDraftID={deleteDraftID}
          setDeleteDraftID={setDeleteDraftID}
        />
      </Grid>
    </Grid>
  )
}

export default withRouter(ConveyMessage)
