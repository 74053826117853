import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import { withRouter } from 'react-router-dom'

import { Grid, Slide, Typography, Chip, Avatar } from '@material-ui/core'

import { viewTypes, viewConfig } from './utils'

import ConversationArea from './ConversationList/ConversationArea'
import MessageArea from './MessageContent/MessageArea'

import { setActiveConversationUsers } from '../../../redux/actions'

/* // ****** Main/Base Convey Component: ******** //
  -  Renders:
        - left side 'conversation area'
        - right side 'message area'
  - state change logic should be handled here and passed into the areas via a 'view' component
  - theme is also defined here and passed into all children
  - this component has a 'navHeight' prop passed from it's parent to allow it to render underneath a nav bar

*/

const ConveyMessageMobile = (props) => {
  const {
    parsedProps, classes, theme, smScreen,
    activeConversationID,
    storedView,
    navHeight = 0, fetchNew, setFetchNew,
    uiLoading, messagesInitialLoad
  } = props

  const dispatch = useDispatch()

  const { conversations = [], unreadMessages = 0, socketClosedCode = 0, messages = [] } = useSelector(state => state.websocket)

  // used to swap the conversation or message area and transition them correctly with mui slide component
  const [currentForm, setCurrentForm] = useState('conversation-area')
  const [activeForm, setActiveForm] = useState('conversation-area')

  // used to control if the slate editor is visible or collapsed on mobile
  const [editorOpen, setEditorOpen] = useState(false)

  // View is set via the param to a config object (located in Convey/utils/variables.js) and dicates which components load
  const view = !storedView ? viewConfig.DEFAULT : viewConfig[storedView.toUpperCase()]

  // Checks to see if the header should change when in 'draft' view
  const draftCheck = view && view.conversation === 'drafts'

  const [openIconRef, setOpenIconRef] = useState(null)
  const [deviceScrnSize, setDeviceScrnSize] = useState({ width: window.innerWidth, height: window.innerHeight })

  // Checks to see if the header should change when in 'draft' view
  // const draftCheck = view && view.conversation === 'drafts'

  const positionCheck = conversations && !conversations.length

  /* Listening to screen resizing in a smaller screen (mobile) to manually determine the height and width of the screen size. The event listener is removed in the cleanup function */
  const resizeScreen = () => {
    setDeviceScrnSize({ width: window.innerWidth, height: window.innerHeight })
  }

  // Fire debounce action after 250ms/ ignore react-hooks dependency array warning, does not play well with lodash debounce
  // eslint-disable-next-line
  const debounceResize = useCallback(debounce(resizeScreen, 250, { leading: true, trailing: false }), [])

  // On mount mobile view listen for resize, on unmount remove event listener
  useEffect(() => {
    window.addEventListener('resize', debounceResize)

    return () => {
      window.removeEventListener('resize', debounceResize)
    }
  }, [debounceResize])

  // pushes the correct view into the url params
  const loadNewView = useCallback((newView, conversationID) => {
    if (newView && conversationID && typeof conversationID !== 'undefined') {
      if (!messagesInitialLoad.current) { messagesInitialLoad.current = true }
      props.history.replace({
        search: `?view=${newView.toLowerCase()}&conversationID=${conversationID}`
      })
    } else if (newView) {
      props.history.replace({
        search: `?view=${newView.toLowerCase()}`
      })
    }
  }, [messagesInitialLoad, props.history])

  const localStorageUpdateCheck = useCallback(() => {
    if (conversations && messages) {
      if (messages.length) {
        const firstMessageID = messages[0].conversationID
        if (!conversations.length || conversations.findIndex(x => x.conversationID === firstMessageID) === -1) {
          if (conversations.length) {
            const { conversationID, userListDetails } = conversations[0]
            dispatch(setActiveConversationUsers(userListDetails))
            loadNewView(viewTypes.MESSAGE, conversationID)
          } else {
            loadNewView(viewTypes.DEFAULT)
          }
        }
      }
    }
  }, [conversations, messages, dispatch, loadNewView])

  useMemo(() => {
    localStorageUpdateCheck()
  }, [localStorageUpdateCheck])

  // Clean up state items on convey unmount
  useEffect(() => {
    // Reset to default values
    const handleCleanUp = () => {
      setCurrentForm('conversation-area')
      setActiveForm('conversation-area')
      setOpenIconRef(null)
    }

    return () => handleCleanUp()
  }, [])

  return (
    <div
      style={{
        backgroundColor: currentForm === 'conversation-area' ? theme.palette.purple.darkest : '',
        position: 'static',
        width: '100%',
        height: deviceScrnSize !== 0 && deviceScrnSize.height > deviceScrnSize.width ? `${deviceScrnSize.height}px` : '100vh',
        overflow: deviceScrnSize.height > deviceScrnSize.width ? 'hidden' : 'hidden scroll'
      }}
    >
      <Grid
        item
        container
        direction='column'
        style={{ paddingTop: navHeight, position: positionCheck ? 'fixed' : 'static' }}
      >
        {currentForm === 'conversation-area' &&
          <>
            <Grid item container direction='row' alignItems='center' style={{ paddingTop: '1em', paddingLeft: '1.5em' }}>
              <Typography variant='h1' style={{ fontWeight: 900, color: theme.palette.grey.lightest }}>
                {draftCheck ? 'Drafts' : 'Messages'}
              </Typography>
              {Boolean(unreadMessages && unreadMessages > 0) &&
                <Avatar style={{ height: '1.5em', marginLeft: '.5em', width: '1.5em', backgroundColor: theme.palette.aqua }}>
                  <Typography variant='h5' style={{ fontWeight: 600 }}>{unreadMessages}</Typography>
                </Avatar>}
            </Grid>

            <Grid item container direction='row' style={{ paddingLeft: '1.5em' }}>
              <Chip
                size='small'
                variant='outlined'
                label='BETA'
                style={{
                  height: '14px',
                  fontSize: '8px',
                  fontWeight: 600,
                  color: theme.palette.grey.lightest,
                  margin: '0 auto .5em 0',
                  borderRadius: '3px',
                  borderColor: theme.palette.grey.lightest
                }}
              />
            </Grid>
          </>}

        {/* MESSAGE / CONVERSATION AREAS */}
        <Grid item container direction='column' style={{ padding: smScreen ? '1em' : '1.5em' }}>
          <Slide
            in={currentForm === 'message-area'}
            onEntered={() => setActiveForm('message-area')}
            exit={false}
            unmountOnExit
            appear
            mountOnEnter
            timeout={{
              enter: activeForm !== 'message-area' ? 100 : 0,
              exit: 10
            }}
            direction='left'
          >
            <div>
              <MessageArea
                theme={theme}
                smScreen={smScreen}
                view={view}
                storedView={storedView}
                viewTypes={viewTypes}
                loadNewView={loadNewView}
                activeConversationID={activeConversationID}
                parsedProps={parsedProps}
                classes={classes}
                uiLoading={uiLoading}
                socketClosedCode={socketClosedCode}
                messagesInitialLoad={messagesInitialLoad}
                currentForm={currentForm}
                setCurrentForm={setCurrentForm}
                editorOpen={editorOpen}
                setEditorOpen={setEditorOpen}
                openIconRef={openIconRef}
                setOpenIconRef={setOpenIconRef}
              />
            </div>
          </Slide>

          <Slide
            in={currentForm === 'conversation-area'}
            onEntered={() => setActiveForm('conversation-area')}
            exit={false}
            unmountOnExit
            appear
            mountOnEnter
            timeout={{
              enter: 500,
              exit: 10
            }}
            direction='right'
          >
            <div style={{ height: '' }}>
              <ConversationArea
                theme={theme}
                smScreen={smScreen}
                view={view}
                storedView={storedView}
                viewTypes={viewTypes}
                loadNewView={loadNewView}
                activeConversationID={activeConversationID}
                fetchNew={fetchNew}
                setFetchNew={setFetchNew}
                parsedProps={parsedProps}
                classes={classes}
                uiLoading={uiLoading}
                messagesInitialLoad={messagesInitialLoad}
                setCurrentForm={setCurrentForm}
                editorOpen={editorOpen}
                setEditorOpen={setEditorOpen}
                deviceScrnSize={deviceScrnSize}
              />
            </div>
          </Slide>

        </Grid>
      </Grid>
    </div>
  )
}

export default withRouter(ConveyMessageMobile)
