import { trackPromise } from 'react-promise-tracker'
const api = process.env.REACT_APP_API_URL

/**
 * Fetches a signed url for direct upload into s3
 * @param {Object} props container object
 * @param {String} props.attachmentID attachment id generated upon upload
 * @param {String} props.type file type
 * @returns signed url
 */
export const getSignedS3Url = async (props) => {
  try {
    const { uploadID, type, userID, token, uploadType } = props
    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ attachment: { uploadID, type, uploadType } })
    }
    const response = await trackPromise(window.fetch(`${api}/v1/user/${userID}/uploadURL`, options))
    if (!response || response.status !== 200) { return false }
    const { data } = await response.json()

    return data
  } catch (err) {
    console.error(err)
    return false
  }
}

/**
 * Fetches a signed url for direct upload into s3
 * @param {Object} props container object
 * @param {String} props.file binary file data
 * @param {String} props.signedUrl signed url returned from s3
 * @param {String} props.type Content-Type conforming to s3 standard
 * @returns signed url
 */
export const uploadToSignedS3Url = async (props) => {
  try {
    const { file, signedUrl, type } = props

    const options = {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': type,
        'x-amz-acl': 'public-read'
      },
      body: file
    }
    const response = await trackPromise(window.fetch(signedUrl, options))
    if (!response || response.status !== 200) { return false }
    return true
  } catch (err) {
    console.error(err)
    return false
  }
}
