import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import {
  Grid, Typography, useTheme, Table, TableBody, TableContainer, TableCell, TableHead, TableRow,
  Paper, TableSortLabel, Select, MenuItem, Chip, Button,
  OutlinedInput
} from '@material-ui/core'

import { Pagination } from '@material-ui/lab'

import {
  SearchRounded, AddRounded
} from '@material-ui/icons'

import { adminPageOptions } from '../../../../utils'

import { getMicroCredentialList, getMCEDetails } from '../../../../redux/actions'

import { isEqual } from 'lodash'

const AdminMicroCredentials = (props) => {
  const { classes, mceList = [], totalMCEs = 0, filter = {}, setFilter = () => { }, defaultFilter = {}, activeButton } = props
  const theme = useTheme()
  const dispatch = useDispatch()

  // ******************** Data Creation ******************** //

  // If the filter was changed, fetch the fellows with the new filter
  useEffect(() => {
    if (filter && defaultFilter && activeButton === adminPageOptions.MICRO_CREDENTIALS) {
      if (!isEqual(filter, defaultFilter)) {
        dispatch(getMicroCredentialList(filter))
      }
    }
  }, [dispatch, filter, defaultFilter, setFilter, activeButton])

  // Data rows for the displayed table
  const [rows, setRows] = useState([])

  const originStatusOptions = {
    DRAFT: {
      label: 'Draft',
      variant: 'outlined',
      styles: { borderColor: theme.palette.grey.medium, color: theme.palette.grey.medium }
    },
    ACTIVE: {
      label: 'Active',
      variant: 'default',
      styles: { backgroundColor: theme.palette.pink.dark, color: 'white' }
    },
    DEACTIVATED: {
      label: 'Deactivated',
      variant: 'outlined',
      styles: { borderColor: theme.palette.grey.medium, color: theme.palette.grey.dark }
    }
  }

  useEffect(() => {
    if (mceList) {
      if (mceList.length) {
        const newRows = []
        mceList.forEach(mce => {
          const { mceID, omceID, mceTitle, stackID, stackName, versionNum, isActiveVersion, mceCreatedAt, mceUpdatedAt, activeStack, isDraft, deactivatedAt = null } = mce
          newRows.push({ mceID, omceID, mceTitle, stackID, stackName, activeStack, versionNum, isDraft, deactivatedAt, activeRevisionDate: mceCreatedAt, isActiveVersion, mceUpdatedAt })
        })
        setRows(newRows)
      } else {
        setRows([])
      }
    }
  }, [mceList])

  // Column Headers
  const headCells = [
    { id: 'mceTitle', label: 'Title', align: 'left' },
    { id: 'stackName', label: 'Stack', align: 'center' },
    { id: 'originStatus', label: 'Status', align: 'center' },
    { id: 'versionNum', label: 'Active Revision', align: 'center' },
    { id: 'mostRecentRevisionDate', label: 'Last Updated', align: 'center' },
    { id: 'edit', label: '', align: 'left' }
  ]

  // ********************* Pagination Logic: **************** //
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const handleChangePage = (event, value) => {
    if (value >= 0) {
      setPage(value - 1)
      setFilter({
        ...filter,
        sortCount: rowsPerPage,
        page: value
      })
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: event.target.value
    })
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalMCEs - page * rowsPerPage)

  // ******************** Column Sort Logic **************** //

  const [orderBy, setOrderBy] = useState('')
  const [order, setOrder] = useState('desc')

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: rowsPerPage,
      sortDirection: isAsc ? 'DESC' : 'ASC',
      sortType: property
    })
  }

  // ********************** Search Logic *********************** //
  const [searchInput, setSearchInput] = useState('')

  const handleSearch = (e) => {
    setSearchInput(e.target.value)
    if (searchInput && searchInput.length >= 3) {
      setPage(0)
      setFilter({
        ...filter,
        page: 1,
        sortCount: rowsPerPage,
        searchPhrase: searchInput
      })
    }

    if (e.target.value === '') {
      setPage(0)
      // Set the sortCount to current rowsPerPage instead of the default
      setFilter({
        ...defaultFilter,
        sortCount: rowsPerPage
      })
    }
  }

  const handleNavigation = (mceID) => {
    const revisionParam = Boolean(mceID)
    const mceParam = mceID ? `&mce=${mceID}` : ''
    window.scrollTo(0, 0)
    props.history.push({
      pathname: '/credential-builder',
      search: `?revision=${revisionParam}${mceParam}`
    })
  }

  // ***************** Builder Logic ************************* //
  const navigateToBuilder = (mceID = null) => {
    if (mceID) {
      dispatch(getMCEDetails({ mceID, includeHistoryBool: true, noDispatchBool: false }, handleNavigation))
    } else {
      handleNavigation()
    }
  }

  return (
    <Grid container direction='column'>
      <Grid item container style={{ marginBottom: '2em' }}>
        {/* Page Title */}
        <Grid item container xs={9} justifyContent='flex-start'>
          <Typography variant='h4' style={{ fontSize: '20px' }}>Micro-Credentials</Typography>
        </Grid>
        <Grid item container xs={3} justifyContent='flex-end'>
          <Button
            color='primary'
            variant='contained'
            style={{ fontWeight: '600' }}
            startIcon={
              <AddRounded className={classes.addIcon} />
            }
            onClick={() => navigateToBuilder()}
          >
            Create New
          </Button>
        </Grid>
      </Grid>

      {/* Top Pagination display and search input */}
      <Grid item container direction='row' style={{ marginBottom: '1em' }}>
        <Grid item container alignContent='flex-end' xs={7}>
          <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none', fontWeight: 400, fontSize: '16px' }}>
            Displaying {totalMCEs === 0 ? 0 : (page * rowsPerPage) + 1} to {rows && page * rowsPerPage + rowsPerPage > totalMCEs ? totalMCEs : page * rowsPerPage + rowsPerPage} of {totalMCEs}
          </Typography>
        </Grid>
        <Grid item container justifyContent='flex-end' xs={5}>
          <OutlinedInput
            className={classes.searchInput}
            size='small'
            margin='dense'
            fullWidth
            inputProps={{ style: { border: 'none', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '5px' } }}
            placeholder='Search...'
            classes={{ input: classes.inputPlaceholder }}
            startAdornment={
              <SearchRounded style={{ color: theme.palette.grey.dark, fontSize: '20px' }} />
            }
            value={searchInput}
            onChange={(e) => handleSearch(e)}
          />
        </Grid>
      </Grid>

      {/* Data Table */}
      <Grid item container direction='column' style={{ marginBottom: '1em' }}>
        <TableContainer elevation={0} style={{ border: `solid 1px ${theme.palette.grey.medium}` }} component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: theme.palette.grey.lighter }}>
              <TableRow>
                {/* Sortable Table Column Headers */}
                {headCells.map((header, index) => {
                  return (
                    <TableCell
                      key={index}
                      align={header.align}
                      style={{ padding: '16px' }}
                      sortDirection={orderBy === header.id ? 'asc' : false}
                    >
                      <TableSortLabel
                        active={orderBy === header.id}
                        direction={orderBy === header.id ? order : 'asc'}
                        onClick={() => handleRequestSort(header.id)}
                      >
                        <Typography variant='h5' style={{ color: theme.palette.grey.dark, textAlign: header.align }}>{header.label}</Typography>
                        {orderBy === header.id ? (
                          <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  )
                })}
                <TableCell align='left' style={{ padding: '16px' }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                const { mceID, mceTitle, stackName, activeStack, versionNum, isDraft, deactivatedAt, activeRevisionDate: mceCreatedAt, mceUpdatedAt } = row
                const originStatus = isDraft ? originStatusOptions.DRAFT : deactivatedAt ? originStatusOptions.DEACTIVATED : originStatusOptions.ACTIVE
                const inactiveOrigin = Boolean(isDraft || deactivatedAt)
                const formatActiveRevisionDate = inactiveOrigin ? 'N/A' : moment(mceCreatedAt * 1000).format('L')
                const formatLastUpdatedDate = moment(mceUpdatedAt * 1000).format('L')

                return (
                  <TableRow key={`credential-row-${mceID}`}>
                    <TableCell>
                      <Typography variant='body1' style={{ fontWeight: 600, color: inactiveOrigin ? theme.palette.grey.dark : theme.palette.grey.darker }}>{mceTitle}</Typography>
                    </TableCell>
                    <TableCell>
                      <Grid item container direction='column'>
                        <Typography variant='caption' style={{ fontWeight: 600, color: !activeStack ? theme.palette.grey.medium : inactiveOrigin ? theme.palette.grey.dark : theme.palette.grey.darker }}>{stackName}</Typography>
                        {!activeStack && <Typography variant='caption' style={{ fontWeight: 400, color: theme.palette.grey.medium }}>Inactive Stack</Typography>}
                      </Grid>
                    </TableCell>
                    <TableCell align='center'>
                      <Chip size='small' variant={originStatus?.variant} label={originStatus?.label} style={{ ...originStatus?.styles, fontWeight: 600, width: '-webkit-fill-available' }} />
                    </TableCell>
                    <TableCell align='center'>
                      <Grid item container direction='column'>
                        {!inactiveOrigin && <Typography variant='caption' style={{ fontWeight: 600 }}>{`Version ${versionNum}`}</Typography>}
                        <Typography variant='caption' style={{ fontWeight: 400, color: inactiveOrigin ? theme.palette.grey.medium : theme.palette.grey.darker }}>{formatActiveRevisionDate}</Typography>
                      </Grid>
                    </TableCell>
                    <TableCell align='center'>
                      <Typography variant='body1'>{formatLastUpdatedDate}</Typography>
                    </TableCell>
                    <TableCell align='center'>
                      <Button variant='contained' color='primary' style={{ fontWeight: 600, textTransform: 'none' }} onClick={() => navigateToBuilder(mceID)}>Revise</Button>
                    </TableCell>
                  </TableRow>
                )
              })}
              {/* Data Array has reached it's length: */}
              {emptyRows > 0 && (
                <TableRow style={{ height: 10 }}>
                  <TableCell colSpan={12}>
                    <Typography variant='body1' style={{ color: theme.palette.grey.medium, textAlign: 'center' }}>{totalMCEs === 0 ? 'No Results' : 'End of List'}</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* Bottom Pagination Controls */}
      <Grid item container direction='row' style={{ marginBottom: '4em' }}>
        {/* Rows per Page Selection */}
        <Grid item container direction='row' xs={6} justifyContent='flex-start'>
          <Grid item style={{ marginRight: '.2em', display: 'flex', alignItems: 'flex-end' }}>
            <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>Display</Typography>
          </Grid>
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <Select
              variant='outlined'
              size='small'
              defaultValue={10}
              value={rowsPerPage}
              onChange={(e) => handleChangeRowsPerPage(e)}
              classes={{ root: classes.searchInput, selectMenu: classes.statusSelect }}
              style={{ padding: 0 }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </Grid>
          <Grid item style={{ marginLeft: '.4em', display: 'flex', alignItems: 'flex-end' }}>
            <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>entries</Typography>
          </Grid>
        </Grid>
        {/* Pagination/ Page Selection */}
        <Grid item container xs={6} justifyContent='flex-end'>
          <Pagination
            color='primary'
            classes={{ root: classes.pagination }}
            count={totalMCEs ? Math.ceil(totalMCEs / rowsPerPage) : 0}
            page={page === 0 ? 1 : page + 1}
            onChange={handleChangePage}
            shape='rounded'
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withRouter(AdminMicroCredentials)
