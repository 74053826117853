import React, { useRef } from 'react'
import {
  Typography, Grid
} from '@material-ui/core'
import { Editor } from '@tinymce/tinymce-react'

const BuilderGeneralSection = (props) => {
  const { versionForm, setVersionForm = () => {}, editorInitDefaults = {}, scriptSrc = {} } = props

  // Editor refs
  const keyMethodRef = useRef(null)
  const methodRef = useRef(null)
  const resourceRef = useRef(null)
  const researchRef = useRef(null)

  const editorKeys = {
    KEY_METHOD: {
      key: 'keyMethod',
      ref: keyMethodRef
    },
    METHODS: {
      key: 'methodComponents',
      ref: methodRef
    },
    RESOURCES: {
      key: 'resources',
      ref: resourceRef
    },
    SUPPORT_RESEARCH: {
      key: 'supportResearch',
      ref: researchRef
    }
  }

  // change the content of a section based on the selected ref
  const handleContentChange = (value, editorKey) => {
    const key = editorKey.key
    const ref = editorKey.ref
    if (key && Object.keys(versionForm).includes(key)) {
      if ((!value.length || (ref.current && ref.current.getContent({ format: 'text' }).trim().length))) {
        setVersionForm({ ...versionForm, [key]: value })
      }
    }
  }

  return (
    <Grid container direction='column'>
      {/* Header */}
      <Grid item container direction='row'>
        <Grid item container direction='column'>
          <Grid item>
            <Typography variant='h5' style={{ lineHeight: 1, fontSize: 18 }}>Credential Details</Typography>
          </Grid>
          <Grid item>
            <Typography variant='subtitle2'>General Information</Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* Inputs */}
      <Grid item container direction='column' style={{ marginTop: '1em' }} spacing={5}>
        <Grid item container direction='column'>
          <Typography gutterBottom variant='h5' style={{ paddingLeft: '5px' }}>Key Method</Typography>
          <Editor
            {...scriptSrc}
            onInit={(evt, editor) => { keyMethodRef.current = editor }}
            value={versionForm?.keyMethod}
            init={{
              height: 150,
              ...editorInitDefaults
            }}
            onEditorChange={(value, editor) => { handleContentChange(value, editorKeys.KEY_METHOD) }}
          />
        </Grid>

        <Grid item container direction='column'>
          <Typography gutterBottom variant='h5' style={{ paddingLeft: '5px' }}>Method Components</Typography>
          <Editor
            {...scriptSrc}
            onInit={(evt, editor) => { methodRef.current = editor }}
            value={versionForm?.methodComponents}
            init={{
              height: 250,
              ...editorInitDefaults
            }}
            onEditorChange={(value, editor) => { handleContentChange(value, editorKeys.METHODS) }}
          />
        </Grid>

        <Grid item container direction='column'>
          <Typography gutterBottom variant='h5' style={{ paddingLeft: '5px' }}>Resources</Typography>
          <Editor
            {...scriptSrc}
            onInit={(evt, editor) => { resourceRef.current = editor }}
            value={versionForm?.resources}
            init={{
              height: 300,
              ...editorInitDefaults
            }}
            onEditorChange={(value, editor) => { handleContentChange(value, editorKeys.RESOURCES) }}
          />
        </Grid>

        <Grid item container direction='column'>
          <Typography gutterBottom variant='h5' style={{ paddingLeft: '5px' }}>Support Research</Typography>
          <Editor
            {...scriptSrc}
            onInit={(evt, editor) => { researchRef.current = editor }}
            value={versionForm?.supportResearch}
            init={{
              height: 350,
              ...editorInitDefaults
            }}
            onEditorChange={(value, editor) => { handleContentChange(value, editorKeys.SUPPORT_RESEARCH) }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BuilderGeneralSection
