import React from 'react'
import { usePromiseTracker } from 'react-promise-tracker'
import Loader from 'react-loader-spinner'

const LoadingIndicator = props => {
  const { promiseInProgress } = usePromiseTracker()
  return (promiseInProgress &&
    <div
      style={{
        position: 'fixed',
        zIndex: 1051,
        background: 'black',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: 0.6
      }}
    >
      <Loader type='ThreeDots' color='#B94197' height={100} width={100} />
    </div>
  )
}

export default LoadingIndicator
