import React, { useRef } from 'react'

import {
  Grid, Typography, Button, OutlinedInput, Dialog, DialogContent, DialogActions, DialogTitle, Avatar
} from '@material-ui/core'

import { Replay as ReplayIcon } from '@material-ui/icons'

import { UploadPhotoIcon } from '../../custom'
import { ImageCroppingTool } from '../../tools'
import Logo from '../../../../assets/edFarmLogo.png'

const AdminCertModal = (props) => {
  const {
    classes, newCertForm, setNewCertForm = () => { }, handleCancel = () => { }, handleConfirmPopover = () => { }, handleImageUpload = () => { },
    handleSave = () => { }, editID, editingCert, errors, fileExtensionError, fileSizeError, createOpen,
    crop, setCrop, completedCrop, setCompletedCrop,
    setCroppedCertImage = () => { },
    isImageChanged, setIsImageChanged = () => { }, setIsCropChanged = () => { }, navHeight
  } = props

  const defaultCertImage = 'default-certifications/default'

  const previewCanvasRef = useRef(null)

  const imageRender = () => {
    if ((newCertForm && newCertForm.originalCertImage && newCertForm.certImageKey !== defaultCertImage) ||
      (newCertForm && newCertForm.originalCertImage && newCertForm.certImageKey === defaultCertImage && isImageChanged === true)) {
      return (
        <>
          <Avatar style={{ marginRight: '1.5rem', height: '55px', width: '55px' }}>
            <canvas
              ref={previewCanvasRef}
              style={{
                objectFit: 'cover',
                width: '100%',
                height: '100%'
              }}
            />
          </Avatar>
        </>
      )
    } else if (editID && newCertForm && newCertForm.originalCertImage) {
      return (
        <>
          <Avatar src={newCertForm.originalCertImage} style={{ marginRight: '1.5rem', height: '50px', width: '50px' }} />
        </>
      )
    } else {
      return (
        <>
          <Avatar src={Logo} style={{ marginRight: '1.5rem', height: '50px', width: '50px' }} />
        </>
      )
    }
  }

  return (
    <Dialog
      open={createOpen}
      onClose={() => { handleCancel() }}
      fullWidth
      maxWidth='sm'
      style={{ marginTop: navHeight }}
    >
      <DialogTitle disableTypography>
        <Typography variant='h4' style={{ margin: '.5em' }}>{`${editID === '' ? 'Add' : 'Edit'} Certification`}</Typography>
      </DialogTitle>
      <DialogContent
        dividers
        style={{ overflowY: 'auto' }}
      >
        <Grid item container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item container direction='column' sm={6} style={{ marginTop: '.5rem', marginBottom: '.5rem' }}>
            <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}>Certification Name <span style={{ color: 'red', fontSize: '14px' }}>*</span></Typography>
            <OutlinedInput
              fullWidth
              margin='dense'
              size='small'
              value={newCertForm ? newCertForm.certName : ''}
              onChange={(e) => setNewCertForm({ ...newCertForm, certName: e.target.value })}
              placeholder='Enter certification name...'
              classes={{ input: classes.inputPlaceholder }}
            />
          </Grid>

          <Grid item container direction='column' sm={5} style={{ marginTop: '.5rem', marginBottom: '.5rem' }}>
            <Grid item container direction='row' justifyContent='space-between' alignItems='center'>
              <Typography gutterBottom variant='body1' style={{ fontWeight: '600', marginLeft: '0.5rem' }}>Logo</Typography>
              <Typography variant='caption' style={{ color: 'grey', fontSize: '.8rem' }}>Max file size of 3MB.</Typography>
            </Grid>
            <Grid item container direction='row' justifyContent='space-between' alignItems='stretch'>
              {imageRender()}

              {
                newCertForm && newCertForm.originalCertImage === ''
                  ? (
                    <>
                      <input
                        accept='image/*'
                        className={classes.input}
                        style={{ display: 'none' }}
                        id='raised-button-file'
                        multiple
                        type='file'
                        onChange={(e) => handleImageUpload(e, e.target.files)}
                      />
                      <label htmlFor='raised-button-file'>
                        <Grid item container direction='row'>
                          <Button
                            startIcon={<UploadPhotoIcon className={classes.uploadIcon} />}
                            variant='outlined'
                            style={{ borderColor: '#29356a', color: '#29356a', fontWeight: '600' }}
                            component='span'
                          >
                            Upload Logo
                          </Button>
                        </Grid>
                      </label>
                    </>
                  ) : (
                    <>
                      <Button
                        startIcon={<ReplayIcon />}
                        variant='outlined'
                        style={{ borderColor: '#29356a', color: '#29356a', fontWeight: '600' }}
                        component='span'
                        onClick={(e) => { e.preventDefault(); setNewCertForm({ ...newCertForm, originalCertImage: '' }); setIsImageChanged(true) }}
                      >
                        Change Logo
                      </Button>
                    </>
                  )
              }
            </Grid>
          </Grid>

          {fileSizeError &&
            <Grid item container direction='row' justifyContent='flex-end'>
              <Typography variant='caption' style={{ color: 'red' }}>The selected file is over the max file size of 3MB.</Typography>
            </Grid>}

          {fileExtensionError &&
            <Grid item container direction='row' justifyContent='flex-end'>
              <Typography variant='caption' style={{ color: 'red' }}>The selected file type is not allowed.</Typography>
            </Grid>}
        </Grid>

        {/* Cropping component goes here */}
        {
          (newCertForm && newCertForm.originalCertImage && newCertForm.certImageKey !== defaultCertImage) ||
            (newCertForm && newCertForm.originalCertImage && newCertForm.certImageKey === defaultCertImage && isImageChanged === true)
            ? (
              <Grid item container direction='row' justifyContent='center'>
                <ImageCroppingTool
                  newCertForm={newCertForm}
                  setNewCertForm={setNewCertForm}
                  uploadedImage={newCertForm.originalCertImage}
                  previewCanvasRef={previewCanvasRef}
                  setCompletedCrop={setCompletedCrop}
                  completedCrop={completedCrop}
                  crop={crop}
                  setCrop={setCrop}
                  setCroppedCertImage={setCroppedCertImage}
                  setIsCropChanged={setIsCropChanged}
                />
              </Grid>) : null
        }

      </DialogContent>

      <DialogActions>
        {errors.notComplete &&
          <Typography variant='caption' style={{ color: 'red' }}>* Certification Name is required to submit. </Typography>}

        <Button
          variant='outlined'
          color='primary'
          style={{
            margin: '.5em 1em',
            fontWeight: '600'
          }}
          onClick={(e) => { e.preventDefault(); handleCancel() }}
        >
          Cancel
        </Button>

        {editingCert && editID !== '' &&
          <Button
            variant='contained'
            className={classes.deleteButton}
            style={{
              margin: '.5em 1em',
              fontWeight: '600'
            }}
            onClick={(e) => { e.preventDefault(); handleConfirmPopover(e) }}
          >
            Delete
          </Button>}

        <Button
          variant='contained'
          color='primary'
          style={{
            margin: '.5em 1em',
            fontWeight: '600'
          }}
          onClick={(e) => { e.preventDefault(); handleSave() }}
        >
          Save
        </Button>

      </DialogActions>
    </Dialog>
  )
}

export default AdminCertModal
