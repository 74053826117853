export const GET_ARTIFACTS = 'GET_ARTIFACTS'
export const GET_USER_LEARNER_WORK = 'GET_USER_LEARNER_WORK'
export const GET_USER_LEDS = 'GET_USER_LEDS'
export const RESET_TARGET_ARTIFACT_LED = 'RESET_TARGET_ARTIFACT_LED'
export const RESET_TARGET_ARTIFACT_LW = 'RESET_TARGET_ARTIFACT_LW'
export const RESET_TARGET_ARTIFACT_PRO = 'RESET_TARGET_ARTIFACT_PRO'
export const RESET_TARGET_ARTIFACT_EFR = 'RESET_TARGET_ARTIFACT_EFR'
export const SET_TARGET_ARTIFACT_LW = 'SET_TARGET_ARTIFACT_LW'
export const SET_TARGET_ARTIFACT_LED = 'SET_TARGET_ARTIFACT_LED'
export const SET_TARGET_ARTIFACT_PRO = 'SET_TARGET_ARTIFACT_PRO'
export const SET_TARGET_ARTIFACT_EFR = 'SET_TARGET_ARTIFACT_EFR'
export const SET_PROFILE_PATH = 'SET_PROFILE_PATH'
export const SET_LED_OPTIONS = 'SET_LED_OPTIONS'
export const SET_LW_OPTIONS = 'SET_LW_OPTIONS'
export const RESET_OPTIONS = 'RESET_OPTIONS'
export const SET_ARTIFACT_SAVES = 'SET_ARTIFACT_SAVES'
export const SET_ARTIFACT_LIKES = 'SET_ARTIFACT_LIKES'
export const SET_ARTIFACT_DUPLICATES = 'SET_ARTIFACT_DUPLICATES'
export const SET_ARTIFACT_COMMENT_LIST = 'SET_ARTIFACT_COMMENT_LIST'
