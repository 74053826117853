import {
  GET_MCE_LIST, MCE_DETAILS, USER_CURRENT_MCE_ATTEMPTS, USER_MCE_ATTEMPT_DETAILS, LOGOUT,
  RESET_CURRENT_ATTEMPT_DETAILS, RESET_CURRENT_MCE_DETAILS, GET_MCE_STACKS
} from '../types'
import { defaultAttemptDetailsForm, defaultMCEDetails } from '../../utils/variables'
import { cloneDeep } from 'lodash'

const initialState = {
  mceList: [],
  mceStacks: [],
  mceDetails: cloneDeep(defaultMCEDetails),
  currentAttempts: [],
  currentAttemptDetails: cloneDeep(defaultAttemptDetailsForm)
}

const mceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MCE_LIST: return {
      ...state,
      mceList: action.payload
    }
    case GET_MCE_STACKS: return {
      ...state,
      mceStacks: action.payload
    }
    case MCE_DETAILS: return {
      ...state,
      mceDetails: action.payload
    }
    case USER_CURRENT_MCE_ATTEMPTS: return {
      ...state,
      currentAttempts: action.payload
    }
    case USER_MCE_ATTEMPT_DETAILS: return {
      ...state,
      currentAttemptDetails: action.payload
    }
    case RESET_CURRENT_ATTEMPT_DETAILS: return {
      ...state,
      currentAttemptDetails: cloneDeep(defaultAttemptDetailsForm)
    }
    case RESET_CURRENT_MCE_DETAILS: return {
      ...state,
      mceDetails: cloneDeep(defaultMCEDetails)
    }
    case LOGOUT: return {
      ...initialState
    }
    default: return state
  }
}

export default mceReducer
