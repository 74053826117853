import React from 'react'
import {
  Grid, IconButton, Typography, useTheme, Tooltip
} from '@material-ui/core'
import {
  Cancel, CheckCircle, HighlightOff, CheckCircleOutline
} from '@material-ui/icons'
import { mceSections } from '../../../../utils'

const gradeValues = {
  PASS: 1,
  FAIL: 0
}

const MCSectionGrading = (props) => {
  const {
    question = {}, handleGrade = () => {}, itemGraded = false, currentSection = {}, submissionReview = false,
    rubricResponses = [], rubricRows = null, isAssessorView = false, autoFailed = false, isResultsView = false
  } = props
  const theme = useTheme()

  const passColor = question?.assessorGrade && question?.assessorGrade !== 0 ? '#478d05' : theme.palette.grey.light
  const failColor = !question?.assessorGrade ? '#d32508' : theme.palette.grey.light

  const rubricCheck = Boolean(rubricResponses && Array.isArray(rubricResponses) && rubricRows && Array.isArray(rubricRows))
  const rubricRowsGraded = rubricCheck && rubricResponses.length ? rubricResponses.reduce((acc, row) => row.assessorSelection !== null ? acc + 1 : acc, 0) : null
  const rubricFullyGraded = Boolean(rubricCheck && rubricRowsGraded === rubricRows.length)

  const disableIcons = Boolean(submissionReview || isResultsView || (isAssessorView && rubricCheck && !rubricFullyGraded) || autoFailed)

  return (
    <>
      <Grid item container direction='row' justifyContent='flex-end' alignItems='center' xs={currentSection === mceSections.PART2 || currentSection === mceSections.PART4 ? null : 4}>
        <Tooltip arrow placement='top' enterDelay={750} enterNextDelay={750} title={isAssessorView && rubricCheck && !rubricFullyGraded ? 'You must choose a selection for each row of the rubric to grade this sample.' : ''}>
          <Grid item>
            <IconButton size='small' onClick={() => handleGrade(gradeValues.FAIL)} disabled={disableIcons}>
              {itemGraded ? <Cancel style={{ fontSize: 36, color: failColor }} /> : <HighlightOff style={{ fontSize: 36 }} />}
            </IconButton>
          </Grid>
        </Tooltip>

        <Tooltip arrow placement='top' enterDelay={750} enterNextDelay={750} title={isAssessorView && rubricCheck && !rubricFullyGraded ? 'You must choose a selection for each row of the rubric to grade this sample.' : ''}>
          <Grid item>
            <IconButton size='small' onClick={() => handleGrade(gradeValues.PASS)} disabled={disableIcons}>
              {itemGraded ? <CheckCircle style={{ fontSize: 36, color: passColor }} /> : <CheckCircleOutline style={{ fontSize: 36 }} />}
            </IconButton>
          </Grid>
        </Tooltip>
      </Grid>
      {Boolean(isAssessorView && rubricCheck) &&
        <Grid container item direction='row' justifyContent='flex-end' style={{ marginTop: '.5em' }}>
          <Typography variant='h6' style={{ color: rubricFullyGraded ? theme.palette.grey.dark : 'red', textTransform: 'none' }}>{`Rubric Rows Graded: ${rubricRowsGraded || 0} of ${rubricRows.length}`}</Typography>
        </Grid>}
    </>
  )
}

export default MCSectionGrading
