import React, { useState } from 'react'

import {
  Grid, Typography, Card, useTheme,
  Popover, Button
} from '@material-ui/core'

import LEDCardView from './ArtifactCardViews/LEDCardView'
import DefaultCardView from './ArtifactCardViews/DefaultCardView'

// Cloudfront url path
const cloudfrontPath = process.env.REACT_APP_CLOUD_FRONT_BASE
// LED Background bucket
const ledBgPrefix = process.env.REACT_APP_AWS_S3_DEFAULT_LED_BG_PREFIX

const templateArtifact = {
  fullName: '',
  learnerWorkID: '',
  nickName: '',
  createdAt: 0,
  subjectName: '',
  techNeeded: '',
  title: '',
  artifactID: '',
  likeCount: 0,
  commentCount: 0,
  grades: []
}

const MiniArtifactCard = (props) => {
  const {
    type, artifactType = '', artifactInfo = templateArtifact, selectedLEDs, setSelectedLEDs, selectedLWs, setSelectedLWs,
    editLWInfo = {}, setEditLWInfo, currentlyLinkedLEDs = [], linkedLearnerWorkID = null
  } = props
  const theme = useTheme()

  const {
    grades = '', isDraft = 1, isPrivate = 1, submissionStatus, LEDBackgroundPath = ''
  } = artifactInfo

  const checkedPath = !LEDBackgroundPath ? `${cloudfrontPath}/${ledBgPrefix}/circles-default` : LEDBackgroundPath
  const publishedArt = !isDraft && !isPrivate && submissionStatus === 'NA'
  const approvedArt = !isDraft && !isPrivate && submissionStatus === 'APPROVED'

  const id = artifactInfo.artifactID
  const isSelected = Boolean((selectedLEDs && selectedLEDs.includes(id)) || (selectedLWs && selectedLWs.includes(id)))

  // Confirm popper related elements (Start) ****
  const [confirmAnchorEl, setConfirmAnchorEl] = useState(null)

  const handleConfirmPopover = (e) => {
    setConfirmAnchorEl(e.currentTarget)
  }
  const handleConfirmPopClose = () => {
    setConfirmAnchorEl(null)
  }
  const openConfirmPopover = Boolean(confirmAnchorEl)
  // Confirm popper related elements (End) ****

  // Not Public popper related elements (Start) ****
  const [notPublicEl, setNotPublicEl] = useState(null)

  const handleNotPublicPopover = (e) => {
    setNotPublicEl(e.currentTarget)
  }
  const handleNotPublicClose = () => {
    setNotPublicEl(null)
  }
  const openNotPublicPopover = Boolean(notPublicEl)
  // Confirm popper related elements (End) ****

  const handleSelection = (e, artifactID, linkedID) => {
    if (artifactType === 'Learner Work') {
      const prefix = artifactID.slice(0, 2)
      if (prefix === 'LE') {
        const tempLEDArr = [...selectedLEDs]
        const index = tempLEDArr.indexOf(artifactID)

        const ledIDsToAdd = [...editLWInfo.ledIDsToAdd]
        const addStats = ledIDsToAdd.indexOf(artifactID)
        const ledIDsToDelete = [...editLWInfo.ledIDsToDelete]
        const deleteStats = ledIDsToDelete.indexOf(artifactID)

        if (index === -1 && linkedID !== null) {
          handleConfirmPopover(e)
        } else if (index === -1 && linkedID === null) {
          tempLEDArr.push(artifactID)

          if (addStats === -1) {
            ledIDsToAdd.push(artifactID)
          }
          if (deleteStats > -1) {
            ledIDsToDelete.splice(deleteStats, 1)
          }

          setSelectedLEDs(tempLEDArr)
          setEditLWInfo({ ...editLWInfo, ledIDsToAdd, ledIDsToDelete })
        } else {
          tempLEDArr.splice(index, 1)

          if (addStats > -1) {
            ledIDsToAdd.splice(addStats, 1)
          }
          if (deleteStats === -1 && currentlyLinkedLEDs && currentlyLinkedLEDs.length && currentlyLinkedLEDs.includes(artifactID)) {
            ledIDsToDelete.push(artifactID)
          }

          setSelectedLEDs(tempLEDArr)
          setEditLWInfo({ ...editLWInfo, ledIDsToAdd, ledIDsToDelete })
        }
      }
    } else {
      const prefix = artifactID.slice(0, 2)
      if (prefix === 'LE') {
        const tempLEDArr = [...selectedLEDs]
        const index = tempLEDArr.indexOf(artifactID)

        if (index !== -1) {
          tempLEDArr.splice(index, 1)
        } else {
          tempLEDArr.push(artifactID)
        }
        setSelectedLEDs(tempLEDArr)
      }

      if (prefix === 'LW') {
        const tempLWArr = [...selectedLWs]
        const index = tempLWArr.indexOf(artifactID)
        if (index !== -1) {
          tempLWArr.splice(index, 1)
        } else {
          tempLWArr.push(artifactID)
        }
        setSelectedLWs(tempLWArr)
      }
    }
  }

  const handleArtifactNotPublicCheck = (e, artID, linkID) => {
    if (publishedArt || approvedArt) {
      handleSelection(e, artID, linkID)
    } else {
      handleNotPublicPopover(e)
    }
  }

  const getTypeStyle = (artifactType) => {
    switch (artifactType) {
      case 'LED': {
        if ((selectedLEDs && selectedLEDs.length === 0) || isSelected) {
          return theme.palette.pink.dark
        } else {
          return '#c579b0'
        }
      }
      case 'LW': {
        if ((selectedLWs && selectedLWs.length === 0) || isSelected) {
          return '#1A7FA4'
        } else {
          return '#6ea6bb'
        }
      }
      default:
        return theme.palette.pink.dark
    }
  }

  // Render either the LED design or the usual design based on artifact type
  const getArtifactSpecifics = (type) => {
    switch (type) {
      case 'LED': {
        return (
          <LEDCardView
            classes={{}}
            artifactInfo={artifactInfo}
            gradesArray={gradesArray}
            type={type}
            getTypeStyle={getTypeStyle}
            handleSelection={handleSelection}
            handleArtifactNotPublicCheck={handleArtifactNotPublicCheck}
            isSelected={isSelected}
            noneSelected={Boolean(selectedLEDs && selectedLEDs.length === 0)}
            miniCard
          />
        )
      }
      case 'LW': {
        return (
          <DefaultCardView
            artifactInfo={artifactInfo}
            type={type}
            gradesArray={gradesArray}
            classes={{}}
            getTypeStyle={getTypeStyle}
            handleSelection={handleSelection}
            handleArtifactNotPublicCheck={handleArtifactNotPublicCheck}
            isSelected={isSelected}
            miniCard
          />
        )
      }
      default: {
        return (
          <Grid item container direction='row' alignItems='center'>
            <Typography variant='h5' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>Artifact Information Unavailable.</Typography>
          </Grid>
        )
      }
    }
  }

  // Splits string that lists current artifact grade names into an array
  const gradesArray = grades && Boolean(grades.length) ? grades.split(',') : ''

  return (
    <>
      <Card style={{ width: '100%', height: 'inherit', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <div
          style={{
            backgroundImage: type === 'LED' && checkedPath ? `url(${checkedPath})` : '',
            backgroundRepeat: 'none',
            backgroundSize: 'cover',
            height: 'inherit'
          }}
        >
          {getArtifactSpecifics(type)}
        </div>
      </Card>

      {/* Confirmation Popover to alert that an LED is linked to another LW on LW page */}
      <Popover
        id={openConfirmPopover ? 'confirm-popover' : undefined}
        open={openConfirmPopover}
        anchorEl={confirmAnchorEl}
        onClose={handleConfirmPopClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Grid container direction='column' style={{ padding: '1em' }}>
          <Typography
            variant='h4'
            style={{ color: theme.palette.purple.darkest }}
            gutterBottom
          >
            This Learning Experience Design is already linked to another Learner Work.
          </Typography>

          <Typography variant='body1' gutterBottom>Would you like to remove it to link to this Learner Work?</Typography>

          <Grid item container direction='row' justifyContent='flex-end' alignItems='center' style={{ marginTop: '.5em' }}>
            <Button onClick={handleConfirmPopClose} style={{ marginRight: '.3em', textTransform: 'none', fontWeight: '600' }}>Cancel</Button>
            <Button
              variant='contained'
              color='primary'
              onClick={(e) => { e.preventDefault(); handleSelection(e, id, null); handleConfirmPopClose() }}
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </Popover>

      {/* Not Public Popover to alert that a selected artifact will not be viewable to the public is attached */}
      <Popover
        id={openNotPublicPopover ? 'not-public-popover' : undefined}
        open={openNotPublicPopover}
        anchorEl={notPublicEl}
        onClose={handleNotPublicClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Grid container direction='column' style={{ padding: '1em' }}>
          <Typography
            variant='h4'
            style={{ color: theme.palette.purple.darkest }}
            gutterBottom
          >
            Selection Not Viewable to the Public.
          </Typography>

          <Typography variant='body1' gutterBottom>This artifact can only be seen by you. Do you still want to attach it?</Typography>

          <Grid item container direction='row' justifyContent='flex-end' alignItems='center' style={{ marginTop: '.5em' }}>
            <Button onClick={handleNotPublicClose} style={{ marginRight: '.3em', textTransform: 'none', fontWeight: '600' }}>Cancel</Button>
            <Button
              variant='contained'
              color='primary'
              onClick={(e) => { e.preventDefault(); handleSelection(e, id, linkedLearnerWorkID); handleNotPublicClose() }}
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </>
  )
}

export default MiniArtifactCard
