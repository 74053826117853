import React from 'react'
import {
  Typography, Grid, Paper, Tooltip, useTheme, useMediaQuery, makeStyles, Badge, Chip
} from '@material-ui/core'
import moment from 'moment'
import { mceStatusLabels, mceViews, attemptStatuses } from '../../../../utils/variables'
import { sanitizedHTML } from '../../../../utils/functions'
import AchievedCrown from '../../../../assets/crown.png'

const useStyles = makeStyles((theme) => ({
  keyMethod: {
    lineHeight: 1.3,
    color: theme.palette.grey.darkest,
    '& p': {
      marginBlockStart: 0,
      marginBlockEnd: 0,
      overflow: 'hidden',
      textAlign: 'left',
      lineHeight: 1.3,
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      fontSize: 14
    }
  },
  starBadge: {
    display: 'flex'
  },
  badgeAnchor: {
    top: 3,
    right: 0,
    backgroundImage: `url(${AchievedCrown})`,
    backgroundRepeat: 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: 'transparent',
    transform: 'rotate(314deg) scale(1) translate(50%, -50%)',
    height: 28,
    minWidth: 28
  },
  cardSubText: {
    color: theme.palette.grey.dark,
    fontWeight: 400,
    overflow: 'hidden',
    textAlign: 'center',
    lineHeight: 1.3,
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical'
  }
}))

const DashboardMCECard = (props) => {
  const { mce: { title, keyMethod, attemptStatus, attemptCount = 0, mceID, completedAt = null }, handleViewChange = () => { } } = props
  const theme = useTheme()
  const mdScreenUp = useMediaQuery(theme.breakpoints.up('md'))
  const classes = useStyles()

  const disableCard = Boolean(attemptStatus)
  const mceAcheived = Boolean(attemptStatus && attemptStatus === attemptStatuses.PASS)

  const getCardText = () => {
    let text = ''
    if (attemptStatus) {
      if (!mceAcheived && attemptStatus !== attemptStatuses.FAIL) {
        text = mceStatusLabels[attemptStatus] || 'Attempted'
      } else if (mceAcheived) {
        if (completedAt) {
          text = `Completed on ${moment(completedAt * 1000).format('MM-DD-YYYY')}`
        } else {
          text = ''
        }
      } else {
        text = `${3 - attemptCount} Attempt(s) Remaining`
      }

      return (
        <Typography
          variant='body1'
          className={classes.cardSubText}
        >
          {text}
        </Typography>)
    }

    return (
      <div dangerouslySetInnerHTML={{ __html: `${sanitizedHTML(keyMethod)}` }} className={classes.keyMethod} />
    )
  }

  return (
    <Grid item xs={mdScreenUp ? 3 : null} style={{ display: 'flex' }}>
      <Badge badgeContent='' invisible={!attemptStatus || !mceAcheived} color='primary' classes={{ root: classes.starBadge, anchorOriginTopRightRectangle: classes.badgeAnchor }} />
      <Paper style={{ width: 'fit-content', minWidth: '-webkit-fill-available', padding: '1em', opacity: !attemptStatus || mceAcheived ? 1 : '.8', border: `solid 2px ${mceAcheived ? '#f4c10c' : !attemptStatus ? theme.palette.grey.light : theme.palette.grey.light}`, cursor: disableCard ? 'auto' : 'pointer' }} onClick={() => disableCard ? false : handleViewChange(mceViews.SUMMARY, mceID)}>
        <Grid item container direction='column'>
          {mceAcheived && <Chip label='Complete!' size='small' style={{ color: 'white', backgroundColor: '#faa002', fontWeight: 600, margin: '0 3em .5em 3em' }} />}
          <Tooltip arrow placement='top' title={title} enterDelay={1200} enterNextDelay={1200}>
            <Typography noWrap variant='body1' style={{ color: attemptStatus && !mceAcheived ? theme.palette.grey.darkest : theme.palette.purple.darkest, fontSize: 16, fontWeight: 600, textAlign: 'center', width: '-webkit-fill-available' }}>{title}</Typography>
          </Tooltip>
          {getCardText()}
          {!attemptStatus && <Chip label='View Summary...' size='small' style={{ backgroundColor: theme.palette.pink.dark, color: 'white', fontWeight: 600, marginTop: '.5em' }} />}
        </Grid>
      </Paper>
    </Grid>
  )
}

export default DashboardMCECard
