/* eslint new-cap: off */

import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  Grid, List, Card, CardHeader, CardContent, ListItem, ListItemText, ListItemIcon,
  Typography, Button, Switch, withStyles, useTheme, Badge, Tooltip, Accordion, AccordionSummary,
  AccordionDetails, IconButton, useMediaQuery
} from '@material-ui/core'

import {
  RadioButtonUncheckedRounded, CheckCircleOutlineRounded, DeleteOutline, ExpandMore,
  SettingsRounded, ExitToAppRounded, LockOpenRounded, LockRounded, LoopRounded, ArrowForwardRounded,
  ArrowBackRounded, SaveRounded, Repeat, SaveAlt
  // Cached, Loop
} from '@material-ui/icons'

import { artifactTypes, statusTypes, formattedDetails } from '../../../utils'

import moment from 'moment'

// PDF Template
import LEDtoPDFTemplate from '../tools/LEDtoPDFTemplate'

// PDF conversion tools
// *** https://www.npmjs.com/package/jspdf *** //
import jsPDF from 'jspdf'
// *** https://www.npmjs.com/package/html2canvas *** //
import html2canvas from 'html2canvas'

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 25,
    height: 16,
    padding: '.1em',
    margin: theme.spacing(1)
  },
  switchBaseLED: {
    padding: 3,
    '&$checked': {
      transform: 'translateX(9px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: 'white',
        opacity: 100
      }
    },
    '&$disabled': {
      color: theme.palette.purple.dark,
      '& + $track': {
        backgroundColor: `${theme.palette.purple.dark} !important`,
        borderColor: `${theme.palette.purple.dark} !important`,
        opacity: '60% !important'
      }
    },
    '&$focusVisible $thumb': {
      color: '#1E274D'
    }
  },
  switchBaseLW: {
    padding: 3,
    '&$checked': {
      transform: 'translateX(9px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: 'white',
        opacity: 1
      }
    },
    '&$disabled': {
      color: '#5bbce0',
      '& + $track': {
        backgroundColor: '#5bbce0 !important',
        borderColor: '#5bbce0 !important',
        opacity: '60% !important'
      }
    },
    '&$focusVisible $thumb': {
      color: '#025676'
    }
  },
  switchBaseProj: {
    padding: 3,
    '&$checked': {
      transform: 'translateX(9px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: 'white',
        opacity: 1
      }
    },
    '&$disabled': {
      color: '#ae9ce6',
      '& + $track': {
        backgroundColor: '#ae9ce6 !important',
        borderColor: '#ae9ce6 !important',
        opacity: '60% !important'
      }
    },
    '&$focusVisible $thumb': {
      color: '#3d2b79'
    }
  },
  thumbLED: {
    width: 10,
    height: 10,
    color: '#1E274D'
  },
  thumbLW: {
    width: 10,
    height: 10,
    color: '#025676'
  },
  thumbPROJ: {
    width: 10,
    height: 10,
    color: '#3d2b79'
  },
  track: {
    borderRadius: 13,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {},
  disabled: {},
  thumb: {},
  focusVisible: {}
}))(({ classes, ...props }) => {
  const { artifacttype } = props
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: artifacttype === 'Project' ? classes.switchBaseProj : artifacttype === 'Learner Work' ? classes.switchBaseLW : classes.switchBaseLED,
        thumb: artifacttype === 'Project' ? classes.thumbPROJ : artifacttype === 'Learner Work' ? classes.thumbLW : classes.thumbLED,
        track: classes.track,
        checked: classes.checked,
        disabled: classes.disabled
      }}
      {...props}
    />
  )
})

const CreateSideMenu = (props) => {
  const {
    classes, selectedLWs, selectedLEDs, forms, currentForm, artifactType, newProjectForm,
    learnerWorkCheck, efSubmit, styles, ledInfo,
    isEditing, handleDeletePopover, artifactDetailsCheck, ledOneCheck, ledTwoCheck,
    isPrivate, setIsPrivate, formMissingDetailsCheck, isDraft = false, isForMCE = false,
    artifactStatus = '', setEFSubmitModalOpen = () => { }, handleSaveAndExit = () => { },
    handleNext = () => { }, handleBack = () => { }, artifactNoNameCheck = false,
    EFRNotCompleteCheck = false, handleMenuClick = () => { }, newComments = {},
    setNewComments = () => { }, getErrorText = () => { }, resubmitConfirmed,
    setCurrentForm = () => { }
  } = props

  const theme = useTheme()

  const smScreen = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm))

  // Redux Items needed to translate the ID's provided in the artifact information into readable names
  const { grades = [], subjects = [] } = useSelector(state => state.profileData)
  const { learnerWorkList, standards } = useSelector(state => state.artifacts)

  // Formatted ledInfo for LED PDF export
  const formattedInfo = useMemo(() => formattedDetails(ledInfo, grades, subjects, standards, learnerWorkList), [ledInfo, grades, subjects, standards, learnerWorkList])

  const resourcesCheck = ledInfo.attachments.length > 0

  const projectDetailsCheck = newProjectForm.projectTitle !== '' && newProjectForm.projectDescription !== '' && newProjectForm.projectLength !== 0

  /* (While creating a new project) - after successfully adding either LEDs or LWs in the project items step, the radio button was not updating correctly using the original projectItemsCheck, so I changed it so that it would update correctly. */
  const projectItemsCheck = selectedLWs.length !== 0 || selectedLEDs.length !== 0

  const ledLearnerWorkCheck = ledInfo.learnerWorkID !== ''

  const { targetArtifactLED, targetArtifactLW, targetArtifactPRO } = useSelector(state => state.artifacts)

  const [currentArtifactID, setCurrentArtifactID] = useState('')

  useEffect(() => {
    if (artifactType) {
      if (artifactType === 'Learning Experience Design') {
        if (targetArtifactLED) {
          if (targetArtifactLED.unviewedComments) {
            setNewComments(targetArtifactLED.unviewedComments)
            setCurrentArtifactID(targetArtifactLED.artifactID)
          }
        }
      }

      if (artifactType === 'Learner Work') {
        if (targetArtifactLW) {
          if (targetArtifactLW.unviewedComments) {
            setNewComments(targetArtifactLW.unviewedComments)
            setCurrentArtifactID(targetArtifactLW.artifactID)
          }
        }
      }

      if (artifactType === 'Project') {
        if (targetArtifactPRO) {
          if (targetArtifactPRO.unviewedComments) {
            setNewComments(targetArtifactPRO.unviewedComments)
            setCurrentArtifactID(targetArtifactPRO.artifactID)
          }
        }
      }
    }
  }, [artifactType, targetArtifactLED, targetArtifactLW, targetArtifactPRO, setNewComments])

  // Submit to EdFarm Button Text and disabled Checks
  const allowForResubmit = artifactStatus && artifactStatus !== statusTypes.needReview && !isDraft
  const buttonDisabled = isDraft || artifactStatus === statusTypes.needReview || (efSubmit && !allowForResubmit) || (efSubmit && allowForResubmit && resubmitConfirmed.current === true) || ledInfo.forkedFrom
  const artifactUnderReview = artifactStatus && artifactStatus === statusTypes.needReview
  const artifactDuplicationsView = artifactType === artifactTypes.LED && currentForm === 7
  const disableBackButton = Boolean(typeof currentForm !== 'undefined' && (currentForm === 0 || (artifactType && artifactDuplicationsView)))
  const disableNextButton = artifactType && ((artifactType !== artifactTypes.PRO && currentForm === forms.length - 2) || artifactDuplicationsView || (artifactType === artifactTypes.PRO && currentForm === 6))
  const disableSaveandExitButton = artifactType && ((artifactType === artifactTypes.EFR && EFRNotCompleteCheck) || (artifactType !== artifactTypes.EFR && artifactNoNameCheck))
  const artifactIsProjectOrLED = artifactType && artifactType !== artifactTypes.LW && artifactType !== artifactTypes.EFR
  const duplicateTooltipText = `${isDraft ? 'Artifact must be complete.' : 'View others\' duplicates of this artifact'}`
  const buttonNotAvailable = efSubmit || isDraft

  // ******************** PDF Export Logic ***************** //

  // The information passed into the pdf component
  const [pdfInfo, setPDFInfo] = useState({})
  const { fullName = '', schoolName = '', districtName = '', profileAvatarPath = '' } = useSelector(state => state.auth)

  // Build and generate PDF export
  const handleExportPDF = async (info) => {
    const {
      ISTEStandards = [], actContent = '', createContent = '', engageContent = '', investigateContent = '',
      completionTime = 0, coreSubject = '', grades = [], learnerWorkTitle = '', overview = '', stateStandards = '',
      targets = [], techNeeded = '', title = '', workType = ''
    } = info

    const now = moment().unix()
    const splitStrings = profileAvatarPath ? profileAvatarPath.split('?') : []
    const amendedPath = splitStrings[0] + '?_' + now.toString()
    const authorAvatarImg = <img alt='author-profile-avatar' src={amendedPath} crossOrigin='true' style={{ height: 40, width: 40, borderRadius: '50%', objectFit: 'contain' }} />

    // The information expected in the PDF template
    // Some content is temporarily sliced for better visibility, this may update at a late time to include full text
    const exportInfo = {
      ISTEStandards,
      actContent: actContent.length > 175 ? `${actContent.slice(0, 174)}...` : actContent,
      createContent: createContent.length > 175 ? `${createContent.slice(0, 174)}...` : createContent,
      engageContent: engageContent.length > 175 ? `${engageContent.slice(0, 174)}...` : engageContent,
      investigateContent: investigateContent.length > 175 ? `${investigateContent.slice(0, 174)}...` : investigateContent,
      completionTime,
      coreSubject,
      grades,
      learnerWorkTitle,
      overview: overview.length > 450 ? `${overview.slice(0, 449)}...` : overview,
      stateStandards,
      targets,
      techNeeded,
      title,
      workType,
      authorFullName: fullName,
      authorSchoolName: schoolName,
      authorDistrictName: districtName,
      authorAvatarImg
    }

    // Set the formatted PDF info to state
    setPDFInfo(exportInfo)

    // Generate a title for the new document based on the title of the LED
    const pdfExportTitle = `${title.replace(/\s/g, '_')}.pdf`

    // Allow for the state to have time to update before proceeding
    setTimeout(() => {
      // Allow the template to be accessible from the DOM
      document.getElementById('led-pdf-template').hidden = false

      // Take the element and converto to a letter size, portrait pdf
      html2canvas(document.getElementById('led-pdf-template'), {
        scale: 3,
        proxy: profileAvatarPath,
        useCORS: true
      })
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/jpeg')

          const pdf = new jsPDF({
            orientation: 'p',
            unit: 'in',
            format: 'letter'
          })
          const imageProps = pdf.getImageProperties(imgData)
          const pdfWidth = pdf.internal.pageSize.getWidth()
          const pdfHeight = pdf.internal.pageSize.getHeight()
          const imgWidth = imageProps.width
          const imgHeight = imageProps.height

          const scale = Math.max(pdfHeight / imgHeight, pdfWidth / imgWidth)
          const finalWidth = imgWidth * scale
          const finalHeight = imgHeight * scale
          // const leftMargin = (pdfWidth - finalWidth) / 2

          pdf.addImage(imgData, 'JPEG', 0, 0, finalWidth, finalHeight)
          pdf.save(pdfExportTitle)
        })

      // Return the element to hidden
      document.getElementById('led-pdf-template').hidden = true
    }, 500)
  }

  const getSubmitBtnTooltip = () => {
    // Artifact is not a duplicate
    if (!ledInfo.forkedFrom) {
      // Submission Under Review
      if (artifactUnderReview) {
        return 'Your artifact is currently under review'
      }
      // Re-Submit to Ed Farm
      if (allowForResubmit && !resubmitConfirmed.current) {
        return 'Re-Submit your Artifact to Ed Farm for review'
      }
      // Submission is a draft
      if (isDraft) {
        return 'Drafts cannot be submitted to Ed Farm'
      }
      // User has chosen to (re)submit to ed farm but has not saved
      if (efSubmit && (!artifactStatus || artifactStatus === '' || resubmitConfirmed.current)) {
        return 'Please save to complete the submission process.'
      }
    } else {
      // Artifact is a duplicate
      return 'Duplicates cannot be submitted to Ed Farm'
    }

    // Default/Fallback Submit to Ed Farm Message
    return 'Submit your Artifact to Ed Farm for review'
  }

  // Called on unmount to ensure the pdfInfo is cleared
  useEffect(() => {
    return () => setPDFInfo({})
  }, [])

  return (
    <>
      <Grid
        item
        container
        sm={null}
        md={3}
        justifyContent={smScreen ? 'center' : 'flex-end'}
        style={{ position: 'relative' }}
      >
        <Grid item container direction='column' alignItems='center' style={{ position: 'sticky', top: '6em', height: 'fit-content' }}>
          <Card className={classes.sideMenu}>
            {/* Menu Step Guide */}
            <CardHeader
              title={
                <List>
                  {artifactType === artifactTypes.EFR
                    ? (
                      <ListItem button>
                        <ListItemIcon style={{ minWidth: '30px' }}>
                          {
                            formMissingDetailsCheck
                              ? <CheckCircleOutlineRounded style={{ color: 'white', fontSize: '20px' }} />
                              : <RadioButtonUncheckedRounded style={{ color: styles.inactiveText, fontSize: '20px' }} />
                          }
                        </ListItemIcon>
                        <ListItemText>
                          <Typography variant='h5' style={{ color: 'white', fontWeight: '600' }}>Resource Details</Typography>
                        </ListItemText>
                      </ListItem>
                    )
                    : (
                      <ListItem button onClick={(e) => { e.preventDefault(); handleMenuClick('artifact-details', currentArtifactID) }}>
                        <Badge
                          color='secondary'
                          variant='dot'
                          badgeContent=' '
                          overlap='circular'
                          anchorOrigin={{
                            horizontal: 'left',
                            vertical: 'top'
                          }}
                          invisible={!newComments || (artifactType === 'Learning Experience Design' && !newComments.detailComments) || (artifactType !== 'Learning Experience Design' && !newComments.notesComments && !newComments.detailComments)}
                        >
                          <ListItemIcon style={{ minWidth: '30px' }}>
                            {
                              artifactDetailsCheck === true || (artifactType === 'Learner Work' && learnerWorkCheck === true) || (artifactType === 'Project' && projectDetailsCheck === true)
                                ? <CheckCircleOutlineRounded style={{ color: 'white', fontSize: '20px' }} />
                                : <RadioButtonUncheckedRounded style={{ color: styles.inactiveText, fontSize: '20px' }} />
                            }
                          </ListItemIcon>
                        </Badge>
                        <ListItemText>
                          <Typography variant='h5' style={{ color: currentForm === forms.indexOf('artifact-details') ? 'white' : styles.inactiveText, fontWeight: '600' }}>{artifactType !== 'Project' ? 'Artifact Details' : 'Project Details'}</Typography>
                        </ListItemText>
                      </ListItem>
                    )}

                  {artifactType === 'Project' &&
                    <>
                      <ListItem button onClick={() => handleMenuClick('project-items', currentArtifactID)}>
                        <Badge
                          color='secondary'
                          variant='dot'
                          badgeContent=' '
                          overlap='circular'
                          anchorOrigin={{
                            horizontal: 'left',
                            vertical: 'top'
                          }}
                          invisible={!newComments || (!newComments.lwsComments && !newComments.ledsComments)}
                        >
                          <ListItemIcon style={{ minWidth: '30px' }}>
                            {
                              projectItemsCheck
                                ? <CheckCircleOutlineRounded style={{ color: 'white', fontSize: '20px' }} />
                                : <RadioButtonUncheckedRounded style={{ color: styles.inactiveText, fontSize: '20px' }} />
                            }
                          </ListItemIcon>
                        </Badge>
                        <ListItemText>
                          <Typography variant='h5' style={{ color: currentForm === forms.indexOf('project-items') ? 'white' : styles.inactiveText, fontWeight: '600' }}>Project Items</Typography>
                        </ListItemText>
                      </ListItem>
                    </>}

                  {artifactType === 'Learning Experience Design' &&
                    <>
                      <ListItem button onClick={() => handleMenuClick('LED1', currentArtifactID)}>
                        <Badge
                          color='secondary'
                          variant='dot'
                          badgeContent=' '
                          overlap='circular'
                          anchorOrigin={{
                            horizontal: 'left',
                            vertical: 'top'
                          }}
                          invisible={!newComments || !newComments.led1Comments}
                        >
                          <ListItemIcon style={{ minWidth: '30px' }}>
                            {
                              ledOneCheck
                                ? <CheckCircleOutlineRounded style={{ color: 'white', fontSize: '20px' }} />
                                : <RadioButtonUncheckedRounded style={{ color: styles.inactiveText, fontSize: '20px' }} />
                            }
                          </ListItemIcon>
                        </Badge>
                        <ListItemText>
                          <Typography variant='h5' style={{ color: currentForm === forms.indexOf('LED1') ? 'white' : styles.inactiveText, fontWeight: '600' }}>Learning Experience Design I</Typography>
                        </ListItemText>
                      </ListItem>

                      <ListItem button onClick={() => handleMenuClick('LED2', currentArtifactID)}>
                        <Badge
                          color='secondary'
                          variant='dot'
                          badgeContent=' '
                          overlap='circular'
                          anchorOrigin={{
                            horizontal: 'left',
                            vertical: 'top'
                          }}
                          invisible={!newComments || !newComments.led2Comments}
                        >
                          <ListItemIcon style={{ minWidth: '30px' }}>
                            {
                              ledTwoCheck === true
                                ? <CheckCircleOutlineRounded style={{ color: 'white', fontSize: '20px' }} />
                                : <RadioButtonUncheckedRounded style={{ color: styles.inactiveText, fontSize: '20px' }} />
                            }
                          </ListItemIcon>
                        </Badge>
                        <ListItemText>
                          <Typography variant='h5' style={{ color: currentForm === forms.indexOf('LED2') ? 'white' : styles.inactiveText, fontWeight: '600' }}>Learning Experience Design II</Typography>
                        </ListItemText>
                      </ListItem>

                      <ListItem button onClick={() => handleMenuClick('resources', currentArtifactID)}>
                        <Badge
                          color='secondary'
                          variant='dot'
                          badgeContent=' '
                          overlap='circular'
                          anchorOrigin={{
                            horizontal: 'left',
                            vertical: 'top'
                          }}
                          invisible={!newComments || !newComments.resourcesComments}
                        >
                          <ListItemIcon style={{ minWidth: '30px' }}>
                            {
                              resourcesCheck === true
                                ? <CheckCircleOutlineRounded style={{ color: 'white', fontSize: '20px' }} />
                                : <RadioButtonUncheckedRounded style={{ color: styles.inactiveText, fontSize: '20px' }} />
                            }
                          </ListItemIcon>
                        </Badge>
                        <ListItemText>
                          <Typography variant='h5' style={{ color: currentForm === forms.indexOf('resources') ? 'white' : styles.inactiveText, fontWeight: '600' }}>Resources</Typography>
                        </ListItemText>
                      </ListItem>

                      <ListItem button onClick={() => handleMenuClick('learner-work', currentArtifactID)}>
                        <Badge
                          color='secondary'
                          variant='dot'
                          badgeContent=' '
                          overlap='circular'
                          anchorOrigin={{
                            horizontal: 'left',
                            vertical: 'top'
                          }}
                          invisible={!newComments || !newComments.lwComments}
                        >
                          <ListItemIcon style={{ minWidth: '30px' }}>
                            {
                              ledLearnerWorkCheck === true
                                ? <CheckCircleOutlineRounded style={{ color: 'white', fontSize: '20px' }} />
                                : <RadioButtonUncheckedRounded style={{ color: styles.inactiveText, fontSize: '20px' }} />
                            }
                          </ListItemIcon>
                        </Badge>
                        <ListItemText>
                          <Typography variant='h5' style={{ color: currentForm === forms.indexOf('learner-work') ? 'white' : styles.inactiveText, fontWeight: '600' }}>Learner Work</Typography>
                        </ListItemText>
                      </ListItem>

                      <ListItem button onClick={() => handleMenuClick('summary', currentArtifactID)}>
                        <Badge
                          color='secondary'
                          variant='dot'
                          badgeContent=' '
                          overlap='circular'
                          anchorOrigin={{
                            horizontal: 'left',
                            vertical: 'top'
                          }}
                          invisible={!newComments || !newComments.notesComments}
                        >
                          <ListItemIcon style={{ minWidth: '30px' }}>
                            {
                              artifactDetailsCheck === true && ledOneCheck === true && ledTwoCheck === true
                                ? <CheckCircleOutlineRounded style={{ color: 'white', fontSize: '20px' }} />
                                : <RadioButtonUncheckedRounded style={{ color: styles.inactiveText, fontSize: '20px' }} />
                            }
                          </ListItemIcon>
                        </Badge>
                        <ListItemText>
                          <Typography variant='h5' style={{ color: currentForm === forms.indexOf('summary') ? 'white' : styles.inactiveText, fontWeight: '600' }}>{`Summary ${isDraft ? '& Submit' : ''}`}</Typography>
                        </ListItemText>
                      </ListItem>
                    </>}
                </List>
              }
              style={{
                backgroundColor: styles.mainColor
              }}
            />

            {!isForMCE &&
              <CardContent style={{ backgroundColor: styles.darkerColor, padding: '0 16px' }}>
                {/* Options Expanded Menu */}
                <Accordion elevation={0} style={{ backgroundColor: styles.darkerColor }}>
                  <AccordionSummary expandIcon={<ExpandMore style={{ color: styles.inactiveText }} />} style={{ padding: 0 }}>
                    {/* Accordion Header */}
                    <Grid container direction='row' alignItems='center'>
                      <Grid item container xs={2}>
                        <SettingsRounded style={{ color: 'white', fontSize: '16px' }} />
                      </Grid>
                      <Grid item container xs={10}>
                        <Typography variant='body1' style={{ color: 'white', textTransform: 'none', fontWeight: '600', fontSize: '16px' }}>Options</Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  {/* Accordion Options */}
                  <AccordionDetails style={{ padding: 0 }}>
                    <Grid container direction='column' alignContent='center'>
                      {artifactType !== artifactTypes.EFR &&
                        <>
                          {/* Private/Public Switch */}
                          <Tooltip
                            enterNextDelay={1500}
                            enterDelay={1500}
                            title={
                              <Typography style={{ fontSize: '12px', textAlign: 'center' }}>
                                {efSubmit ? 'Artifacts must be public to submit to Ed Farm.' : isDraft ? 'Artifact must be complete before status can be changed.' : 'Choose whether or not your artifact is visible to other users'}
                              </Typography>
                            }
                            arrow
                          >
                            <Grid item container direction='row' alignItems='center'>
                              <Grid item container direction='row' alignItems='center' xs={10} style={{ padding: '6px 8px' }}>
                                <Grid item container xs={2}>
                                  {isPrivate ? <LockRounded className={classes.optionsMenuLockIcons} style={{ color: buttonNotAvailable ? styles.inactiveText : 'white' }} /> : <LockOpenRounded className={classes.optionsMenuLockIcons} style={{ color: buttonNotAvailable ? styles.inactiveText : 'white' }} />}
                                </Grid>
                                <Grid item container xs={10}>
                                  <Typography variant='body1' style={{ color: buttonNotAvailable ? styles.inactiveText : 'white', textTransform: 'none', fontWeight: '600' }}>Public Artifact</Typography>
                                </Grid>
                              </Grid>
                              <Grid item style={{ justifyContent: 'end' }} xs={2}>
                                <IOSSwitch
                                  id='private-artifact-switch'
                                  artifacttype={artifactType}
                                  disabled={Boolean(buttonNotAvailable)}
                                  checked={!isPrivate && !isDraft}
                                  onClick={() => setIsPrivate(prev => !prev)}
                                />
                              </Grid>
                            </Grid>
                          </Tooltip>

                          {/* Submit to Ed Farm Button */}
                          <Tooltip enterNextDelay={1500} enterDelay={1500} title={<Typography style={{ fontSize: '12px', textAlign: 'center' }}>{getSubmitBtnTooltip()}</Typography>} arrow>
                            <Grid item container direction='row' alignItems='center'>
                              <Button
                                startIcon={
                                  allowForResubmit ? <LoopRounded style={{ color: buttonDisabled ? styles.inactiveText : 'white', fontSize: '16px' }} /> : <ExitToAppRounded style={{ color: buttonDisabled ? styles.inactiveText : 'white', fontSize: '16px' }} />
                                }
                                disabled={Boolean(buttonDisabled)}
                                onClick={() => setEFSubmitModalOpen(true)}
                              >
                                <Typography
                                  variant='body1'
                                  style={{ color: buttonDisabled ? styles.inactiveText : 'white', textTransform: 'none', fontWeight: '600' }}
                                >
                                  {artifactUnderReview || resubmitConfirmed.current ? 'Submission Under Review' : allowForResubmit && !resubmitConfirmed.current ? 'Re-Submit to Ed Farm?' : 'Submit to Ed Farm?'}
                                </Typography>
                              </Button>
                            </Grid>
                          </Tooltip>
                        </>}

                      {/* Export LED Button  */}
                      {artifactType && artifactType === artifactTypes.LED &&
                        <Tooltip enterNextDelay={1500} enterDelay={1500} title={<Typography style={{ fontSize: '12px', textAlign: 'center' }}>Export Artifact Summary to PDF</Typography>} arrow>
                          <Grid item container direction='row' alignItems='center'>
                            <Button
                              style={{ color: 'white', textTransform: 'none', fontWeight: '600' }}
                              startIcon={<SaveAlt />}
                              onClick={(e) => { e.preventDefault(); handleExportPDF(formattedInfo) }}
                            >
                              Export Artifact
                            </Button>
                          </Grid>
                        </Tooltip>}

                      {/* Duplications Button */}
                      {artifactType === artifactTypes.LED && targetArtifactLED && !targetArtifactLED.forkedFrom &&
                        <>
                          <Tooltip enterNextDelay={1500} enterDelay={1500} title={<Typography style={{ fontSize: '12px', textAlign: 'center' }}>{duplicateTooltipText}</Typography>} arrow>
                            <Grid item container direction='row' alignItems='center'>
                              <Button
                                style={{ color: isDraft ? styles.inactiveText : 'white', textTransform: 'none', fontWeight: '600' }}
                                startIcon={<Repeat />}
                                disabled={Boolean(isDraft)}
                                onClick={(e) => { e.preventDefault(); setCurrentForm(7) }}
                              >
                                <Typography
                                  variant='body1'
                                  style={{ color: isDraft ? styles.inactiveText : 'white', textTransform: 'none', fontWeight: '600' }}
                                >
                                  Artifact Duplications
                                </Typography>
                              </Button>
                            </Grid>
                          </Tooltip>
                        </>}

                      {/* Delete Artifact Button  */}
                      <Tooltip enterNextDelay={1500} enterDelay={1500} title={<Typography style={{ fontSize: '12px', textAlign: 'center' }}>Permanently delete this artifact</Typography>} arrow>
                        <Grid item container direction='row' alignItems='center' style={{ marginBottom: '.3em' }}>
                          <Button
                            style={{ color: !isEditing ? styles.inactiveText : 'white', textTransform: 'none', fontWeight: '600' }}
                            startIcon={<DeleteOutline />}
                            onClick={(e) => { e.preventDefault(); handleDeletePopover(e) }}
                            disabled={!isEditing}
                          >
                            Delete Artifact
                          </Button>
                        </Grid>
                      </Tooltip>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </CardContent>}
          </Card>

          <Grid item container direction='row' justifyContent='center' alignItems='center' style={{ marginTop: '1em' }} className={classes.sideMenuButtons}>

            <Grid item container direction='column' md={12} lg={7} justifyContent={artifactType && artifactType !== artifactTypes.LED ? 'flex-start' : 'center'}>
              <Grid item container direction='row' justifyContent='space-around' style={{ marginTop: '.5em' }}>
                <Tooltip
                  enterDelay={1500}
                  enterNextDelay={1500}
                  arrow
                  title={
                    <Typography style={{ fontSize: '12px', textAlign: 'center' }}>
                      {artifactNoNameCheck ? artifactType && artifactType === artifactTypes.EFR ? 'Resources must be completed before saving.' : 'Must include artifact name to save.' : 'Save current changes and return to previous page'}
                    </Typography>
                  }
                >
                  <span>
                    <Button
                      variant='outlined'
                      style={{ color: styles.mainColor, borderColor: styles.mainColor }}
                      startIcon={<SaveRounded style={{ margin: 0 }} />}
                      classes={{ root: classes.sideMenuSaveAndExitButton, startIcon: classes.saveIcon }}
                      onClick={() => handleSaveAndExit()}
                      disabled={Boolean(disableSaveandExitButton)}
                    >
                      Save & Exit
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>

            {artifactIsProjectOrLED &&
              <Grid item container direction='column' md={12} lg={5} justifyContent='space-around' style={{ width: '60%' }}>
                <Grid item container direction='row' justifyContent='space-around' className={classes.navButtonRow}>
                  <IconButton
                    size='medium'
                    disabled={Boolean(disableBackButton)}
                    style={{ color: 'white', backgroundColor: styles.mainColor }}
                    className={classes.sideMenuNavigationArrows}
                    onClick={(e) => handleBack(e)}
                  >
                    <ArrowBackRounded style={{ fontSize: 20 }} />
                  </IconButton>

                  <IconButton
                    style={{ color: 'white', backgroundColor: styles.mainColor }}
                    className={classes.sideMenuNavigationArrows}
                    disabled={Boolean(disableNextButton)}
                    size='medium'
                    onClick={(e) => handleNext(e)}
                  >
                    <ArrowForwardRounded style={{ fontSize: 20 }} />
                  </IconButton>
                </Grid>
              </Grid>}

          </Grid>

          <>
            {getErrorText()}
          </>

        </Grid>
      </Grid>
      {/* Hidden component accessed with export pdf function */}
      <div id='led-pdf-template' hidden>
        <LEDtoPDFTemplate ledInfo={pdfInfo} />
      </div>
    </>
  )
}

export default CreateSideMenu
