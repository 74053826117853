/* eslint no-useless-escape: off */

import {
  GET_CONVERSATIONS, SET_CONVERSATION, SET_MESSAGES, SET_SOCKET, SET_CURRENT_CONNECTION,
  SET_TEMP_CONVERSATIONS, UPDATE_CONVERSATION, SET_SOCKET_CLOSED_CODE, SET_UNREAD_MESSAGES, UPDATE_DRAFT_CONVERSATIONS, SET_ACTIVE_CONVERSATION_USERS
} from '../types'
import { parseResponse } from '../../lib'
import moment from 'moment'

/* UI Tech Debt: Removed api variable that wasn't being used anywhere, trackPromise import which wasn't needed anymore, and getDraftConversations action which
wasn't being used anywhere */

const api = process.env.REACT_APP_WEBSOCKET_API
const apiversion = process.env.REACT_APP_API_VERSION

export const getConversations = (fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = () => { }) => {
    try {
      const { token = '', userID = '' } = getState().auth
      const { activeConversationID = null, conversations = [] } = getState().websocket

      // const { draft = false } = draftObj

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      // If draft is passed in as true, set path to retrieve draft conversations only
      // const draftPath = draft ? '/drafts' : ''

      const response = await window.fetch(`${api}/${apiversion}/conversation/user/${userID}`, options)
      const parsedResponse = parseResponse(response)

      if (parsedResponse && !parsedResponse.error) {
        const {
          data: {
            conversations: { conversationTotal = 0, draftConversationTotals = 0, conversationList = [] },
            drafts = []
          } = {}
        } = await response.json()

        const payload = { conversationTotal, draftConversationTotals, conversationList, drafts }

        // Check if active conversation exists in payload list
        const checkPayloadList = conversationList.find((conversationInfo) => conversationInfo.conversationID === activeConversationID)

        // Check if active conversation exists in redux list
        const checkReduxList = conversations.find((conversationInfo) => conversationInfo.conversationID === activeConversationID)

        // If the active conversation does not exist in the payload list, but does exist in the redux list, add it to the start of the payload list
        // This will keep a new conversation without messages in the list if the user is currently on it (ex: User is in the middle of typing first message in new conversation)
        if (!checkPayloadList && checkReduxList) {
          conversationList.unshift(checkReduxList)
        }

        dispatch({ type: GET_CONVERSATIONS, payload })

        const firstConversation = conversationList && conversationList.length ? conversationList[0] : null
        fireSuccess(firstConversation)

        return conversationList
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

// Used when selecting a user
// Will return a conversationID used to send / fetch messages
export const getConversationID = (IDs, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { userID, token = '' } = getState().auth

      const tempIDs = [...IDs, userID]

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ userIDs: tempIDs })
      }

      const response = await window.fetch(`${api}/${apiversion}/conversation/user/${userID}`, options)
      const parsedResponse = parseResponse(response)

      if (parsedResponse && !parsedResponse.error) {
        const { data } = await response.json()

        const conversationID = data.conversationID
        fireSuccess(conversationID)

        return conversationID
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const hideConversation = (conversationID, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { userID, token = '' } = getState().auth

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ conversationID })
      }

      const response = await window.fetch(`${api}/${apiversion}/conversation/user/${userID}`, options)
      const parsedResponse = parseResponse(response)

      if (parsedResponse && !parsedResponse.error) {
        // If we have successfully hidden a conversation via the api/db call, we need to remove it from local storage as well, so no other tab/windows in a browser contain the hidden conversation
        // Parse the local storage down until you reach the conversations array
        const localStoreConversationList = JSON.parse(JSON.parse(window.localStorage.getItem('persist:root')).websocket).conversations

        // Find the object of the conversation that was hidden
        const hiddenConversation = localStoreConversationList.find(x => x.conversationID === conversationID)

        // Remove it from the list. This action triggers an event listener in MessageInbox.js
        window.localStorage.removeItem(localStoreConversationList, JSON.stringify(hiddenConversation))

        fireSuccess()
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const setActiveConversationID = (conversationID) => {
  return (dispatch) => {
    dispatch({
      type: SET_CONVERSATION,
      payload: conversationID
    })
  }
}

// called on a 5 second timer and returns a number value if the user has any new/unread messages
export const pollConversations = (fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { userID, token = '' } = getState().auth
      const { unreadMessages = 0 } = getState().websocket

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await window.fetch(`${api}/${apiversion}/conversation/new/user/${userID}`, options)
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        const { data } = await response.json()
        dispatch({ type: SET_UNREAD_MESSAGES, payload: data })

        // Return the data (integer) from the call, and the current integer fo unread messages in redux
        fireSuccess(data, unreadMessages)
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

// Set messages for current conversation in redux
export const setMessages = (payload) => {
  return async (dispatch, getState) => {
    const { messages } = getState().websocket
    const { items = [], LastEvaluatedKey, conversationID } = payload
    // if we get messages back in the payload then append them...otherwise just stick with what we've got

    const filteredMessages = messages.filter((message) => message.conversationID === conversationID)

    const newMessages = items.reduce((arr, messageInfo) => {
      const doesExist = filteredMessages.findIndex(
        (info) => info.messageID === messageInfo.messageID && info.userID === messageInfo.userID
      ) !== -1

      if (!doesExist) { arr.push({ ...messageInfo }) }
      return arr
    }, [])

    const allMessages = newMessages && Boolean(newMessages.length) ? newMessages.concat(filteredMessages) : filteredMessages

    await dispatch({
      type: SET_MESSAGES,
      payload: {
        messages: allMessages,
        LastEvaluatedKey: LastEvaluatedKey || null
      }
    })
  }
}

// update the redux drafts per actionType (save or delete)
export const updateDrafts = (event, actionType) => {
  return async (dispatch, getState) => {
    const { drafts = [] } = getState().websocket

    // Deep clone the current array of drafts in redux
    const currentDrafts = [...drafts]
    // Deconstruct the event payload
    const { Content = '', conversationID = '', Name = '', userID = '', updatedAt = 0 } = event

    // If the user is deleting a draft:
    if (actionType === 'delete') {
      // find the index of the conversation the drfat is being deleted from
      const deletedDraft = currentDrafts.findIndex(x => x.conversationID === conversationID)

      // Splice it from the cloned array:
      if (deletedDraft !== -1) {
        currentDrafts.splice(deletedDraft, 1)
      }
    }

    // If the user is saving a draft:
    if (actionType === 'save') {
      const payload = { Content, conversationID, Name, userID, updatedAt }

      // Find the index of the draft to be added
      const newDraft = currentDrafts.findIndex(x => x.conversationID === conversationID)

      // If it is not in the array already (eg, the user has no existing draft for this conversation)
      if (newDraft === -1) {
        // add it to the cloned array
        currentDrafts.push(payload)
      } else {
        // If the array already contains a draft for this conversation, take out the old one and replace it with the new
        // (this occurs when a user has updated the content of a draft)
        currentDrafts.splice(newDraft, 1, payload)
      }
    }

    // Update the redux state foir drafts with the modified cloned array
    await dispatch({
      type: UPDATE_DRAFT_CONVERSATIONS,
      payload: {
        drafts: currentDrafts
      }
    })
  }
}

// Chris: Update conversation list in redux with newest current message content for current conversation
export const setConversations = (conversationList, fireSuccess = () => { }) => {
  return async (dispatch) => {
    await dispatch({ type: SET_TEMP_CONVERSATIONS, payload: { conversationList } })
    fireSuccess()
    return true
  }
}

// Update array of active conversation users when switching conversations
export const setActiveConversationUsers = (users) => {
  return async (dispatch) => {
    await dispatch({ type: SET_ACTIVE_CONVERSATION_USERS, payload: users })
    return true
  }
}

// Update redux state with the ws socket status
export const setSocket = (payload) => {
  return async (dispatch) => {
    await dispatch({
      type: SET_SOCKET,
      payload: {
        setSocket: payload
      }
    })
  }
}

// Set messages for current conversation in redux
export const setActiveSocket = (payload) => {
  return async (dispatch) => {
    await dispatch({
      type: SET_CURRENT_CONNECTION,
      payload
    })
  }
}

// Set messages for current conversation in redux
export const setSocketClosedCode = (payload) => {
  return async (dispatch) => {
    await dispatch({
      type: SET_SOCKET_CLOSED_CODE,
      payload
    })
  }
}

// Might not need this function, back up tho
export const updateConversation = (payload, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = () => { }) => {
    try {
      const { messages, conversations } = getState().websocket
      const tempMessages = [...messages]

      const checkForCorrectConversation = tempMessages && (!tempMessages.length || (tempMessages.length && tempMessages[0].conversationID === payload.conversationID))

      if (payload && payload.conversationID && checkForCorrectConversation) {
        if (tempMessages && Array.isArray(tempMessages)) {
          if (payload.event && payload.event === 'channel_message') {
            const localIndex = tempMessages.findIndex((messageInfo) => {
              // TODO check to see if there's a better way to check for duplicate messages, such as updating the api to send a boolean if it is paginating results
              if (messageInfo) {
                // changing the comparison to double check the messageID with local info
                const { messageID } = messageInfo
                if (payload.messageID === messageID) {
                  return true
                }
              }
              return false
            })
            if (localIndex === -1) {
              // push the new message to the array
              tempMessages.push({ ...payload })
            } else { return false }
          } else { tempMessages.push({ ...payload }) }
        }

        // *** LOGIC TO UPDATE CONVERSATION LIST (START) *** //
        const tempConversations = [...conversations]
        // Find index for conversation in conversation list
        const conversationIndex = tempConversations.findIndex(conversation => conversation.conversationID === payload.conversationID)
        // Find full object for matching conversation
        const conversationMatch = tempConversations.find(conversation => conversation.conversationID === payload.conversationID)

        // Build object for conversation with updated data and replace in redux if index is found
        if (conversationIndex !== -1) {
          const newConversation = {
            mostRecentMessageContent: payload.Content,
            mostRecentMessageID: payload.messageID,
            conversationID: payload.conversationID,
            unreadMessages: conversationMatch.unreadMessages,
            userListDetails: conversationMatch.userListDetails,
            mostRecentIsDraft: conversationMatch.mostRecentIsDraft,
            mostRecentCreatedAt: moment().unix()
          }

          tempConversations.splice(conversationIndex, 1)

          tempConversations.unshift(newConversation)

          // Set updated conversation list in redux
          dispatch(setConversations(tempConversations))
        }
        // *** LOGIC TO UPDATE CONVERSATION LIST (END) *** //

        dispatch({ type: UPDATE_CONVERSATION, payload: tempMessages })
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}
