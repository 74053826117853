import React, { } from 'react'
import { SvgIcon } from '@material-ui/core'

const ModifiedLwIcon = (props) => {
  return (
    <SvgIcon viewBox='0 0 55 55' {...props}>
      <path
        data-name='modified-lw-icon'
        id='modified-lw-icon'
        d='M11.754,26.206,26.21,11.751,21.471,7.012l-6.626,6.626a.851.851,0,0,1-1.2,0l-1.2-1.2a.851.851,0,0,1,0-1.2L19.063,4.6,15.449.989a3.407,3.407,0,0,0-4.819,0L.993,10.626a3.408,3.408,0,0,0,0,4.819ZM53.483,13.659a5.16,5.16,0,0,0,0-7.3L48.622,1.5a5.162,5.162,0,0,0-7.3,0L36.38,6.444,48.54,18.6ZM33.949,8.876l-31.9,31.9L.03,52.316a2.28,2.28,0,0,0,2.641,2.638l11.542-2.024,31.9-31.9ZM54,39.537l-3.614-3.614-6.626,6.626a.851.851,0,0,1-1.2,0l-1.2-1.2a.853.853,0,0,1,0-1.2l6.626-6.626-4.742-4.742L28.776,43.228,39.541,53.991a3.407,3.407,0,0,0,4.819,0L54,44.355A3.407,3.407,0,0,0,54,39.537Z'
        transform='translate(0.005 0.01)'
      />
    </SvgIcon>
  )
}

export default ModifiedLwIcon
