/* eslint new-cap: off */

import React, { useEffect, useCallback, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

import { getArtifactDetails, postArtifactSave, deleteArtifactSave, getArtifactComments, getUserDetails } from '../../../redux/actions'
import { artifactTypes, formattedDetails, efrSubTypesFormat, gradeCategoriesFormat, efrSubTypes, userRoleIDs, handleExternalLinkClick } from '../../../utils'
import { SocialIconSection, ArtifactComment, LedDuplicateInfo, ExpandedGrades } from '../tools'
import EFRDefaultImage from '../../../assets/EFRDefaultPhoto.png'

import {
  Grid, Typography, Paper, useTheme, Divider, Link, Button, Fab, Tooltip, IconButton,
  Checkbox, withStyles, Chip, useMediaQuery
} from '@material-ui/core'

import {
  BookmarkBorderRounded, AccessTime,
  SentimentVeryDissatisfied, TurnedInRounded, AddComment, CalendarTodayRounded,
  ListAltRounded, PeopleRounded, SaveAlt, StarRounded, StarBorderRounded, EmojiObjectsOutlined
} from '@material-ui/icons'

import LEDDisplayDetails from './LEDDisplayDetails'

// PDF Template
import LEDtoPDFTemplate from '../tools/LEDtoPDFTemplate'

// PDF conversion tools
// *** https://www.npmjs.com/package/jspdf *** //
import jsPDF from 'jspdf'
// *** https://www.npmjs.com/package/html2canvas *** //
import html2canvas from 'html2canvas'

const CustomCheckbox = withStyles((theme) => ({
  root: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: theme.spacing(0.5)
  },
  checked: {
    color: '#29356a'
  },
  indeterminate: {
    color: 'rgba(0, 0, 0, 0.54)'
  }
}))((props) => <Checkbox color='default' {...props} />)

const ModifiedChalkboardIcon = (props) => {
  const { styles } = props
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='40'
      viewBox='0 0 111 111'
    >
      <g transform='translate(-628 -124)'>
        <circle className='a' cx='50' cy='50' r='50' transform='translate(628 124)' fill={`${styles.mainColor}12`} />
        <g transform='translate(645 149)'>
          <path
            className='b'
            d='M19.5,33a2.145,2.145,0,0,0-.662.1A12.367,12.367,0,0,1,15,33.75a12.378,12.378,0,0,1-3.839-.648A2.139,2.139,0,0,0,10.5,33,10.5,10.5,0,0,0,0,43.558,4.488,4.488,0,0,0,4.5,48h21A4.488,4.488,0,0,0,30,43.558,10.5,10.5,0,0,0,19.5,33ZM15,30a9,9,0,1,0-9-9A9,9,0,0,0,15,30ZM55.5,0h-36A4.582,4.582,0,0,0,15,4.649V9a11.864,11.864,0,0,1,6,1.669V6H54V33H48V27H36v6H28.851a11.949,11.949,0,0,1,3.721,6H55.5A4.582,4.582,0,0,0,60,34.351V4.649A4.582,4.582,0,0,0,55.5,0Z'
            transform='translate(0.002)'
            fill={styles.mainColor}
          />
        </g>
      </g>
    </svg>
  )
}

const ModifiedEditIcon = (props) => {
  const { color } = props
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20' height='18.337'
      viewBox='0 0 24 21.337'
    >
      <path
        id='edit-regular_1_'
        data-name='edit-regular (1)'
        d='M16.763,14.3,18.1,12.966a.335.335,0,0,1,.571.238v6.058a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V4.595a2,2,0,0,1,2-2H13.4a.335.335,0,0,1,.238.571L12.3,4.5a.33.33,0,0,1-.238.1H2V19.262H16.667V14.533A.328.328,0,0,1,16.763,14.3Zm6.525-8.409L12.346,16.833l-3.767.417a1.721,1.721,0,0,1-1.9-1.9L7.1,11.583,18.038.641a2.432,2.432,0,0,1,3.446,0l1.8,1.8a2.441,2.441,0,0,1,0,3.45ZM19.171,7.178,16.75,4.757,9.009,12.5l-.3,2.721,2.721-.3Zm2.7-3.321-1.8-1.8a.435.435,0,0,0-.617,0L18.167,3.345l2.421,2.421,1.288-1.288A.444.444,0,0,0,21.871,3.857Z'
        transform='translate(0 0.075)'
        fill={`${color}`}
      />
    </svg>
  )
}

const PublicArtifactContent = (props) => {
  const {
    classes = {}, artifactType = '', reviewInfo = {}, setReviewInfo = () => { }, styles = {}, formattedInfo = {}, setFormattedInfo = () => { },
    creatorID = '', currentArtifactID = '', publicArtifactCheck = false, artDetails = {}, setArtDetails = {}, onSocialButtonClick = () => { console.warn('this view does not yet support social interaction.') },
    artifactLiked = false, artifactSaved = false, fireCommentSuccess = () => { }, authorDetails = {}, updateLEDFeaturedStatus = () => { }, uiLoading,
    isForMCE = false
  } = props

  const theme = useTheme()
  const dispatch = useDispatch()

  const mdScreenDown = useMediaQuery(theme.breakpoints.down('md'))

  // ******************************* Data Formatting ******************************* //

  const { roleID: authUserRole } = useSelector(state => state.auth)
  const { targetArtifactLED } = useSelector(state => state.artifacts)
  const localInfo = isForMCE ? targetArtifactLED : reviewInfo

  const adminCheck = authUserRole === userRoleIDs.ADMIN_ROLE

  // Redux Items needed to translate the ID's provided in the artifact information into readable names
  const { grades = [], subjects = [] } = useSelector(state => state.profileData)
  const standards = useSelector(state => state.artifacts.standards)
  const { learnerWorkList, ledList, artifactCommentList: commentList } = useSelector(state => state.artifacts)

  const formatInfo = useMemo(() => formattedDetails(localInfo, grades, subjects, standards, learnerWorkList, ledList), [localInfo, grades, subjects, standards, learnerWorkList, ledList])

  // Formats the information original returned from the reviewInfo into readable text
  useEffect(() => {
    if ((!isForMCE && localInfo && localInfo.artifactID === currentArtifactID) || (isForMCE && localInfo && localInfo.artifactID)) {
      if (artifactType && artifactType !== artifactTypes.EFR) {
        setFormattedInfo(formatInfo)
      } else {
        setFormattedInfo(localInfo)
      }
    }
  }, [localInfo, currentArtifactID, setFormattedInfo, formatInfo, artifactType, isForMCE])

  const getEventTimeFormat = (startTime, endTime) => {
    if (startTime && endTime && startTime > 0 && endTime > 0) {
      return `${moment(startTime * 1000).format('hh:mm A')} - ${moment(endTime * 1000).format('hh:mm A')}`
    } else {
      return 'N/A'
    }
  }

  // Additional formatting specific to the styling of the public view
  const smallLineInfo = useMemo(() => ({
    LED: {
      title: formattedInfo.title,
      gradeName: '',
      coreSubjectName: '',
      smallLineTitle_1: 'Technology Needed:',
      smallLineContent_1: formattedInfo.techNeeded,
      smallLineTitle_2: 'ISTE Standards:',
      smallLineContent_2: formattedInfo && formattedInfo.ISTEStandards && formattedInfo.ISTEStandards.length ? formattedInfo.ISTEStandards.join(', ') : 'N/A'
    },
    LW: {
      title: formattedInfo.learnerWorkTitle,
      gradeName: '',
      coreSubjectName: '',
      smallLineTitle_1: 'Type of Work:',
      smallLineContent_1: formattedInfo.workType,
      smallLineTitle_2: 'Learning Experience Design:',
      smallLineContent_2: ''
    },
    PRO: {
      title: formattedInfo.projectTitle,
      smallLineTitle_1: 'Project Length:',
      smallLineContent_1: `${formattedInfo.projectLength} weeks`,
      smallLineTitle_2: 'Project Description:',
      smallLineContent_2: formattedInfo.projectDescription
    },
    EFR: {
      title: formattedInfo.resourceName,
      smallLineTitle_1: <CalendarTodayRounded style={{ fontSize: '16px', color: 'black' }} />,
      smallLineContent_1: formattedInfo.startDate && formattedInfo.startDate > 0 ? moment(formattedInfo.startDate * 1000).format('MMM. Do, YYYY') : 'N/A',
      smallLineTitle_2: <AccessTime style={{ fontSize: '16px', color: 'black', display: 'flex' }} />,
      smallLineContent_2: formattedInfo && formattedInfo.startDate && formattedInfo.endDate ? getEventTimeFormat(formattedInfo.startDate, formattedInfo.endDate) : 'N/A'
    }

  }), [formattedInfo])

  // Continued Formating that takes the specific artifact details and put them into matching objects
  const getPublicArtifactDetails = useCallback((type) => {
    switch (type) {
      case artifactTypes.LED: {
        return smallLineInfo.LED
      }
      case artifactTypes.LW: {
        return smallLineInfo.LW
      }
      case artifactTypes.PRO: {
        return smallLineInfo.PRO
      }
      case artifactTypes.EFR: {
        return smallLineInfo.EFR
      }
      default: {
        return ({
          title: '',
          smallLineTitle_1: '',
          smallLineContent_1: '',
          smallLineTitle_2: '',
          smallLineContent_2: ''
        })
      }
    }
  }, [smallLineInfo])

  // Callback for useeffect to be able to get all the formatted results before loading
  const findDetails = useCallback(async (type) => {
    if (type) {
      const details = await getPublicArtifactDetails(type)

      // Set everything to the Final Form
      if (details) {
        setArtDetails(details)
      }
    }
  }, [getPublicArtifactDetails, setArtDetails])

  // Fetches and fully formats all relevant artifact information
  useEffect(() => {
    if (formattedInfo && Object.keys(formattedInfo).length !== 0) {
      findDetails(artifactType)
    }
  }, [artifactType, formattedInfo, findDetails])

  const {
    likeCount = 0, saveCount = 0, publicCommentCount = 0, isPrivate = 0, isDraft = 0, submissionStatus = 'NA',
    adminSelected = 0, duplicates = [], originalArtifact = null, forkedFrom = ''
  } = localInfo

  // Handle routing the user to a nested artifact and retrieve those new details
  const handleArtifactViewChange = async (e, artType, artifactID, artifactProfileID) => {
    uiLoading.current = true
    setSocialViewType(socialTypes.COMMENTS)
    setReviewInfo({})

    await dispatch(getArtifactDetails(artifactID, '', artType, handlePublicFetchSuccess, handlePublicFetchFailure, artifactProfileID))
  }

  // If the selected artifact is Private the call will fail, we catch this with the unavailable param and display a relevant message
  const handlePublicFetchFailure = (formatType) => {
    let profID = creatorID

    if (originalArtifact) {
      const { profileID = '' } = originalArtifact
      profID = profileID
    }

    props.history.push({
      pathname: '/artifact',
      search: `?public=${formatType}&user=${profID}&artifact=unavailable`
    })
    setTimeout(() => {
      uiLoading.current = false
    }, 250)
  }

  const handlePublicFetchSuccess = (formatType, submission, draft, artifactID, profileID) => {
    // Get user details and update redux state for display
    dispatch(getUserDetails(profileID, () => { }, () => { }))

    /* Bringing in the correct comments after being directed to the original artifact from the duplicate. Previously, comments for an original artifact were not being dispatched at all after clicking the "inspired by" button in a duplicate. */
    dispatch(getArtifactComments(artifactID))

    props.history.push({
      pathname: '/artifact',
      search: `?public=${formatType}&user=${profileID}&artifact=${!artifactID ? 'unavailable' : artifactID}`
    })

    setTimeout(() => {
      uiLoading.current = false
    }, 250)
  }

  const getOriginalDetails = () => {
    const {
      artifactAvailable = 0, fullName = '',
      ledID = '', title = '', profileID = '', deletedAt = 0
    } = originalArtifact

    const userDisplayName = deletedAt ? 'another Ed Farm User' : fullName

    return (
      <Typography variant='caption' style={{ color: theme.palette.grey.dark, fontWeight: 600 }}>
        {`Inspired by ${userDisplayName}'s `}
        <span>
          <Button
            variant='text'
            disabled={!artifactAvailable}
            onClick={(e) => handleArtifactViewChange(e, artifactTypes.LED, ledID, profileID)}
            style={{
              textTransform: 'none',
              fontSize: '12px',
              fontWeight: 600,
              padding: 0,
              color: artifactAvailable ? theme.palette.purple.darkest : theme.palette.grey.dark,
              fontStyle: 'italic'
            }}
          >
            {`"${title.substring(0, 55)}${title.length > 55 ? '...' : ''}"`}
          </Button>
        </span>
      </Typography>
    )
  }

  const [featureSelected, setFeatureSelected] = useState(false)

  useEffect(() => {
    if (adminSelected) {
      setFeatureSelected(true)
    }
  }, [adminSelected])

  const chipStyleOptions = useMemo(() => ({
    checked: {
      icon: <StarRounded style={{ fontSize: '12px', color: 'white', marginLeft: '6px' }} />,
      labelColor: 'white',
      chipVarient: 'default',
      chipColorProperty: 'backgroundColor'
    },
    unchecked: {
      icon: <StarBorderRounded style={{ fontSize: '12px', color: theme.palette.purple.darkest, marginLeft: '6px' }} />,
      labelColor: theme.palette.purple.darkest,
      chipVarient: 'outlined',
      chipColorProperty: 'borderColor'
    }
  }), [theme])

  const nominateChipStyle = useMemo(() => { if (featureSelected) { return chipStyleOptions.checked } return chipStyleOptions.unchecked }, [featureSelected, chipStyleOptions])

  // Redirects an admin user to the edit view
  const handleEFRAdminEdit = async () => {
    const { artifactID } = formattedInfo

    props.history.push({
      pathname: '/artifact',
      search: `?create=${artifactTypes.EFR}&submission=no&editing=true&artifact=${artifactID}`
    })
  }

  const handleSaveArtifact = async (e, artifact) => {
    e.preventDefault()
    e.stopPropagation()

    const { artifactID: artID, authorID = '' } = artifact

    // If the artifact has already been 'saved' by the user
    if (artifactSaved) {
      await dispatch(deleteArtifactSave(artID))
    }

    // The artifact has not been 'saved'
    if (!artifactSaved) {
      await dispatch(postArtifactSave({ artifactID: artID, authorID }))
    }
  }

  // ************************ Comment Logic ****************** //

  // Controls the open/close state for individual sections
  const defaultCommentState = {
    public: false
  }

  const [commentState, setCommentState] = useState(defaultCommentState)

  // The social view types available
  const socialTypes = {
    COMMENTS: 'comments',
    DUPLICATES: 'duplicates'
  }

  // The maximum duplicates shown before a 'view all' button renders
  const defaultDuplicateLength = 3

  // Switch between social view types
  const [socialViewType, setSocialViewType] = useState(socialTypes.COMMENTS)
  // Set the number of duplicates to view at a time
  const [duplicateListLength, setDuplicateListLength] = useState(defaultDuplicateLength)

  // Switch views and reset the 'duplicates to view' length
  const handleSocialTypeChange = (type) => {
    setSocialViewType(type)
    setDuplicateListLength(defaultDuplicateLength)
  }

  // Render the correct display for the selected social view type
  const getSocialTypeDisplay = (type) => {
    switch (type) {
      case socialTypes.COMMENTS: {
        return (
          <Grid item container direction='column' alignContent='flex-start' style={{ marginTop: '1em', marginBottom: '2em' }}>
            {commentList && commentList.length !== 0
              ? (
                <>
                  {/* List of the current comments associated with the artifact */}
                  {commentList.map((comment, i) => {
                    return (
                      <Grid key={`${comment.commentID} - ${i}`} container item style={{ marginBottom: '.5em' }}>
                        <ArtifactComment
                          key={comment.commentID}
                          artifactID={formattedInfo.artifactID}
                          commentState={commentState}
                          setCommentState={setCommentState}
                          styles={styles}
                          classes={classes}
                          commentInfo={comment}
                          section='public'
                          fromPublic
                          fireSuccess={fireCommentSuccess}
                        />
                      </Grid>
                    )
                  })}
                </>
              )
              : (
                // If no comments are associated with the artifact, display an empty message
                <Grid container justifyContent='center' item style={{ marginBottom: '.5em' }}>
                  <Typography variant='body1' style={{ color: theme.palette.grey.medium }}>There are no comments for this artifact.</Typography>
                </Grid>
              )}
            <>
              {/* If the user is typing a comment, display the comment input box, else display the add comment button */}
              {commentState && commentState.public
                ? (
                  <ArtifactComment
                    artifactID={formattedInfo.artifactID}
                    commentState={commentState}
                    setCommentState={setCommentState}
                    styles={styles}
                    classes={classes}
                    section='public'
                    fromPublic
                    fireSuccess={fireCommentSuccess}
                  />
                )
                : (
                  <Grid container item justifyContent='flex-start' style={{ marginTop: '2em' }}>
                    <Button
                      variant='contained'
                      style={{ margin: 'auto', marginLeft: 0, fontWeight: '600', backgroundColor: styles.mainColor }}
                      startIcon={
                        <AddComment />
                      }
                      onClick={() => setCommentState({ ...commentState, public: true })}
                    >
                      Add Comment
                    </Button>
                  </Grid>
                )}
            </>
          </Grid>
        )
      }
      case socialTypes.DUPLICATES: {
        return (
          <Grid item container direction='column' alignContent='flex-start' style={{ marginTop: '1em', marginBottom: '2em' }}>
            {/* List all of the artifacts that have been duplicated from this original artifact */}
            {duplicates && duplicates.length !== 0
              ? (
                <>
                  {duplicates.slice(0, duplicateListLength).map(duplicate => {
                    const { ledID = '' } = duplicate
                    return (
                      <LedDuplicateInfo
                        key={`led-duplicate-${ledID}`}
                        duplicate={duplicate}
                        theme={theme}
                        handleArtifactViewChange={handleArtifactViewChange}
                      />
                    )
                  })}
                  {/* Button to allow for more than the default list length of duplicates to be viewed, if available. */}
                  {duplicates.length > defaultDuplicateLength && duplicateListLength <= defaultDuplicateLength &&
                    <Grid item container direction='row'>
                      <Grid item xs={3}>
                        <Button style={{ color: theme.palette.purple.darkest, textTransform: 'none', fontWeight: 600 }} onClick={() => setDuplicateListLength(duplicates.length)}>View All</Button>
                      </Grid>
                    </Grid>}
                </>
              )
              : (
                <Grid container justifyContent='center' item style={{ marginBottom: '.5em' }}>
                  <Typography variant='body1' style={{ color: theme.palette.grey.medium }}>There are no duplications of this artifact yet.</Typography>
                </Grid>
              )}
          </Grid>
        )
      }
      default: {
        return (
          <Grid container justifyContent='center' item style={{ marginBottom: '.5em' }}>
            <Typography variant='body1' style={{ color: theme.palette.grey.medium }}>Something went wrong. please refresh the page to try again.</Typography>
          </Grid>
        )
      }
    }
  }

  // ******************** PDF Export Logic ***************** //

  // The information passed into the pdf component
  const [pdfInfo, setPDFInfo] = useState({})

  // Build and generate PDF export
  const handleExportPDF = async () => {
    const {
      ISTEStandards = [], actContent = '', createContent = '', engageContent = '', investigateContent = '',
      completionTime = 0, coreSubject = '', grades = [], learnerWorkTitle = '', overview = '', stateStandards = '',
      targets = [], techNeeded = '', title = '', workType = ''
    } = formattedInfo

    const { fullName = '', schoolName = '', districtName = '', profileAvatarPath = '' } = authorDetails

    const now = moment().unix()
    const splitStrings = profileAvatarPath ? profileAvatarPath.split('?') : []
    const amendedPath = splitStrings[0] + '?_' + now.toString()
    const authorAvatarImg = <img alt='author-profile-avatar' src={amendedPath} crossOrigin='true' style={{ height: 40, width: 40, borderRadius: '50%', objectFit: 'contain' }} />

    // The information expected in the PDF template
    // Some content is temporarily sliced for better visibility, this may update at a late time to include full text
    const exportInfo = {
      ISTEStandards,
      actContent: actContent.length > 175 ? `${actContent.slice(0, 174)}...` : actContent,
      createContent: createContent.length > 175 ? `${createContent.slice(0, 174)}...` : createContent,
      engageContent: engageContent.length > 175 ? `${engageContent.slice(0, 174)}...` : engageContent,
      investigateContent: investigateContent.length > 175 ? `${investigateContent.slice(0, 174)}...` : investigateContent,
      completionTime,
      coreSubject,
      grades,
      learnerWorkTitle,
      overview: overview.length > 450 ? `${overview.slice(0, 449)}...` : overview,
      stateStandards,
      targets,
      techNeeded,
      title,
      workType,
      authorFullName: fullName,
      authorSchoolName: schoolName,
      authorDistrictName: districtName,
      authorAvatarImg
    }

    // Set the formatted PDF info to state
    setPDFInfo(exportInfo)

    // Generate a title for the new document based on the title of the LED
    const pdfExportTitle = `${title.replace(/\s/g, '_')}.pdf`

    // Allow for the state to have time to update before proceeding
    setTimeout(() => {
      // Allow the template to be accessible from the DOM
      document.getElementById('led-pdf-template').hidden = false

      // Take the element and converto to a letter size, portrait pdf
      html2canvas(document.getElementById('led-pdf-template'), {
        scale: 3,
        proxy: profileAvatarPath,
        useCORS: true
      })
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/jpeg')

          const pdf = new jsPDF({
            orientation: 'p',
            unit: 'in',
            format: 'letter'
          })
          const imageProps = pdf.getImageProperties(imgData)
          const pdfWidth = pdf.internal.pageSize.getWidth()
          const pdfHeight = pdf.internal.pageSize.getHeight()
          const imgWidth = imageProps.width
          const imgHeight = imageProps.height

          const scale = Math.max(pdfHeight / imgHeight, pdfWidth / imgWidth)
          const finalWidth = imgWidth * scale
          const finalHeight = imgHeight * scale
          // const leftMargin = (pdfWidth - finalWidth) / 2

          pdf.addImage(imgData, 'JPEG', 0, 0, finalWidth, finalHeight)
          pdf.save(pdfExportTitle)
        })

      // Return the element to hidden
      document.getElementById('led-pdf-template').hidden = true
    }, 500)
  }

  // Called on unmount to ensure the pdfInfo is cleared
  useEffect(() => {
    setPDFInfo({})
  }, [])

  /* The below const checks the various conditions to be sure an artifact can be viewed publically.
  If there is a general error in the results from the api:
   - the url params for artifactID will read as unavailable
  If viewing another users artifact:
   - if that artifact is private or a draft, no artifactID is returned  from the api call
   - therefore making the url params for artifactID 'null' and the user will see the unavailable message
  If viewing your own artifact from a public view:
   - If that artifact returns with a draft or private value of 1 it will show the unavailable message
   - If the artifact was for submission but has not been approved it will show the unavailable message
  */

  const subTypeCheck = artifactType && artifactType !== artifactTypes.LED && (artifactType !== artifactTypes.EFR || (artifactType === artifactTypes.EFR && formattedInfo && formattedInfo.resourceType === efrSubTypes.EFR_EVENT))

  const disableAllButtons = (isPrivate || isDraft || (!isDraft && (!submissionStatus || (submissionStatus !== 'APPROVED' && submissionStatus !== 'NA'))))

  const tooltipText = `${featureSelected ? 'This artifact has been nominated' : 'Nominate this artifact'} to be featured in Explore.`

  // Handles whether grades are collapsed if there are more than 1
  const [gradesOpen, setGradesOpen] = useState(false)

  return (
    <>
      <Paper className={classes.paperRoot} elevation={isForMCE ? 0 : 3}>
        <Grid container direction='column'>
          {/* First check to be sure we have a valid artifactID loaded, if not display an error message */}
          {publicArtifactCheck
            ? (
              <>
                {/* Artifact Title */}
                <Grid container item direction='row' spacing={1} style={{ marginBottom: forkedFrom ? 0 : '1em' }}>
                  <Grid item xs={artifactType && artifactType === artifactTypes.LED ? 8 : 11} alignItems='center' container direction='row'>
                    <Grid item>
                      <Typography gutterBottom variant='h4' style={{ wordBreak: 'break-word' }}>{artDetails ? artDetails.title : ''}</Typography>
                    </Grid>
                    {!isForMCE &&
                      <Grid item>
                        <CustomCheckbox
                          onClick={(e) => { if (formattedInfo && formattedInfo.artifactID) { handleSaveArtifact(e, formattedInfo) } }}
                          icon={<BookmarkBorderRounded style={{ fontSize: '20px', cursor: 'pointer', color: 'black' }} />}
                          checkedIcon={<TurnedInRounded style={{ fontSize: '20px', cursor: 'pointer', color: 'black' }} />}
                          checked={Boolean(artifactSaved && !disableAllButtons)}
                          disabled={Boolean(disableAllButtons)}
                        />
                      </Grid>}
                  </Grid>

                  {/* LED specific action buttons */}
                  {!isForMCE && artifactType && artifactType === artifactTypes.LED &&
                    <Grid item container xs={4} justifyContent='flex-end' direction='row'>

                      {/* (Admin Only) button to set adminSelected status of LED */}
                      {adminCheck &&
                        <Tooltip arrow title={tooltipText}>
                          <Chip
                            icon={nominateChipStyle.icon}
                            label={
                              <Typography
                                style={{
                                  fontWeight: 600,
                                  color: nominateChipStyle.labelColor,
                                  fontSize: 12
                                }}
                              >
                                {`Nominate${featureSelected ? 'd' : ''}`}
                              </Typography>
                            }
                            variant={nominateChipStyle.chipVarient}
                            size='small'
                            style={{
                              [nominateChipStyle.chipColorProperty]: theme.palette.purple.darkest,
                              marginRight: '.5em'
                            }}
                            onClick={() => updateLEDFeaturedStatus(currentArtifactID, adminSelected, () => setFeatureSelected(!featureSelected))}
                          />
                        </Tooltip>}
                      {/* Button to export LED to PDF */}
                      <Chip
                        icon={<SaveAlt style={{ fontSize: '12px', color: theme.palette.purple.darkest, marginLeft: '6px' }} />}
                        label={
                          <Typography
                            style={{
                              fontWeight: 600,
                              color: theme.palette.purple.darkest,
                              fontSize: 12
                            }}
                          >
                            Export
                          </Typography>
                        }
                        variant='outlined'
                        size='small'
                        style={{
                          borderColor: theme.palette.purple.darkest
                        }}
                        onClick={handleExportPDF}
                      />
                    </Grid>}

                  {artifactType && artifactType === artifactTypes.EFR && adminCheck &&
                    <Grid item xs={1} container justifyContent='flex-end' direction='row'>
                      <IconButton onClick={handleEFRAdminEdit}>
                        <ModifiedEditIcon color={styles.mainColor} />
                      </IconButton>
                    </Grid>}
                </Grid>

                {forkedFrom && !isForMCE &&
                  <Grid item container direction='row' alignItems='center' style={{ marginBottom: '1.5em' }}>
                    <EmojiObjectsOutlined style={{ fontSize: '14px', color: theme.palette.grey.dark }} />
                    {getOriginalDetails()}
                  </Grid>}

                {/* Removed the main portion of the public LED summary page from this file. This removed content has been redesigned/changed and is now contained in the LEDDisplayDetails component below. The only LED sections that remain in this file are the title, the nominate/export buttons, and the social section (comments, social icons, and list of duplicates if there are any). */}
                {artifactType && artifactType === artifactTypes.LED &&
                  <LEDDisplayDetails
                    styles={styles}
                    formattedInfo={formattedInfo}
                    creatorID={creatorID}
                    handleArtifactViewChange={handleArtifactViewChange}
                    artifactType={artifactType}
                    isForMCE={isForMCE}
                  />}
                {/*  Icon Artifact Details */}
                {artifactType && artifactType !== artifactTypes.PRO && artifactType !== artifactTypes.LED &&
                  <Grid item container direction='row'>
                    {artifactType !== artifactTypes.EFR
                      ? (
                        <>
                          <Grid item xs={12} sm={4} container direction='row' justifyContent='flex-start'>
                            <ExpandedGrades
                              formattedInfo={formattedInfo}
                              artifactType={artifactType}
                              gradesOpen={gradesOpen}
                              setGradesOpen={setGradesOpen}
                              grades={formattedInfo.grades}
                              styles={styles}
                            />
                          </Grid>

                          <Grid item xs={12} sm={4} container direction='row' justifyContent='flex-start'>
                            <Grid item container direction='column'>
                              <Grid item container direction='row' alignItems='center'>
                                <Grid item xs={1}>
                                  <ModifiedChalkboardIcon styles={styles} />
                                </Grid>
                                <Grid item xs={11} style={{ paddingBottom: '.3em' }}>
                                  <Typography variant='h6' style={{ textTransform: 'none', color: 'black', fontWeight: '600', paddingLeft: mdScreenDown ? '1.5em' : '1em' }}>{formattedInfo && formattedInfo.coreSubjectName ? formattedInfo.coreSubjectName : 'N/A'}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={4} container direction='row' justifyContent='flex-start'>
                            <Grid item container direction='column'>
                              <Grid item container direction='row' alignItems='center'>
                                <Grid item xs={1}>
                                  <AccessTime style={{ color: styles.mainColor, fontSize: 24, backgroundColor: `${styles.mainColor}12`, borderRadius: '50%', padding: '.2em' }} />
                                </Grid>
                                <Grid item xs={11}>
                                  <Typography variant='h6' style={{ textTransform: 'none', color: 'black', fontWeight: '600', paddingLeft: mdScreenDown ? '1.5em' : '1em' }}>
                                    {moment(formattedInfo.dateCompleted * 1000).format('MM-DD-YYYY')}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      )
                      : (
                        <>
                          <Grid item xs={3} container direction='row' justifyContent='flex-start' alignItems='center'>
                            <Grid item>
                              <ListAltRounded style={{ color: styles.mainColor, fontSize: 24, backgroundColor: `${styles.mainColor}12`, borderRadius: '50%', padding: '.2em' }} />
                            </Grid>
                            <Grid item style={{ paddingBottom: '.3em' }}>
                              <Typography variant='h6' style={{ textTransform: 'none', color: 'black', fontWeight: '600', paddingLeft: '.5em' }}>
                                {formattedInfo && formattedInfo.resourceType ? efrSubTypesFormat[formattedInfo.resourceType] : 'N/A'}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={3} container direction='row' justifyContent='flex-start' alignItems='center'>
                            <Grid item>
                              <PeopleRounded style={{ color: styles.mainColor, fontSize: 24, backgroundColor: `${styles.mainColor}12`, borderRadius: '50%', padding: '.2em' }} />
                            </Grid>
                            <Grid item style={{ paddingBottom: '.3em' }}>
                              <Typography variant='h6' style={{ textTransform: 'none', color: 'black', fontWeight: '600', paddingLeft: '.5em' }}>{formattedInfo && formattedInfo.resourceAudience ? gradeCategoriesFormat[formattedInfo.resourceAudience] : 'N/A'}</Typography>
                            </Grid>
                          </Grid>
                        </>
                      )}
                  </Grid>}

                {/* Small Detail Lines */}
                {subTypeCheck &&
                  <Grid item container direction='column' style={{ marginTop: '1.5em' }}>
                    <Grid
                      item
                      container
                      direction='row'
                      alignItems='center'
                    >
                      <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: 600 }}>{artDetails ? artDetails.smallLineTitle_1 : ''}</Typography>
                      <Typography gutterBottom variant='h6' style={{ textTransform: 'none', color: 'black', fontWeight: '600', paddingLeft: '.5em', wordBreak: 'break-word' }}>{artDetails ? artDetails.smallLineContent_1 : ''}</Typography>
                    </Grid>
                    <Grid item container direction='row' alignItems='center'>
                      <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: 600 }}>{artDetails ? artDetails.smallLineTitle_2 : ''}</Typography>
                      {artifactType && artifactType !== artifactTypes.LW &&
                        <Typography variant='h6' style={{ textTransform: 'none', color: 'black', fontWeight: artifactType === artifactTypes.EFR ? '400' : '600', paddingLeft: '.5em' }}>{artDetails ? artDetails.smallLineContent_2 : 'N/A'}</Typography>}
                    </Grid>
                    {artifactType && artifactType === artifactTypes.LW &&
                      <Grid item container direction='column' alignContent='flex-start'>
                        {formattedInfo && formattedInfo.ledIDs && formattedInfo.ledIDs.length !== 0
                          ? (
                            formattedInfo.ledIDs.map((led, i) => {
                              const { ledID = '', ledName = '' } = led
                              return (
                                <Button
                                  key={`led-${i}-${ledID}`}
                                  variant='text'
                                  style={{ textTransform: 'none', color: styles.mainColor, textDecoration: 'underline', justifyContent: 'flex-start', fontWeight: '600', padding: 0 }}
                                  onClick={(e) => { e.preventDefault(); handleArtifactViewChange(e, artifactTypes.LED, led.ledID, creatorID) }}
                                >
                                  {ledName}
                                </Button>
                              )
                            })
                          )
                          : (
                            <Typography variant='h6' style={{ textTransform: 'none', color: 'black', fontWeight: '600' }}>N/A</Typography>
                          )}
                      </Grid>}
                  </Grid>}

                <Grid container item direction='column' style={{ marginTop: '.5em', paddingRight: '2em' }}>

                  {artifactType && artifactType === artifactTypes.PRO &&
                    <Paper elevation={0} style={{ backgroundColor: theme.palette.grey.lighter, padding: '1em', width: '100%', marginTop: '1em' }}>
                      <Grid container item direction='row'>
                        {/* Left Side Column */}
                        <Grid item container direction='column' xs={3} sm={2}>
                          <Grid item>
                            <Typography variant='h5' style={{ fontWeight: '600' }}>Project Items</Typography>
                          </Grid>
                        </Grid>

                        <Divider flexItem variant='middle' orientation='vertical' />

                        {/* Right Side Column */}
                        <Grid item container direction='column' xs={8} sm={9} spacing={3}>
                          {/* LEDs */}
                          <Grid item container direction='column'>
                            <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>* Learning Experience Designs</Typography>
                            {formattedInfo && formattedInfo.projectLEDs && formattedInfo.projectLEDs.length !== 0
                              ? (
                                formattedInfo.projectLEDs.map((led) => {
                                  const { ledID, title } = led
                                  return (
                                    <Button
                                      key={`link-${ledID}`}
                                      variant='text'
                                      style={{ textTransform: 'none', color: styles.mainColor, textDecoration: 'underline', justifyContent: 'flex-start', fontWeight: '600', fontSize: '13px', padding: 0 }}
                                      onClick={(e) => { e.preventDefault(); handleArtifactViewChange(e, artifactTypes.LED, ledID, creatorID) }}
                                    >
                                      {title}
                                    </Button>
                                  )
                                })

                              )
                              : (
                                <Typography variant='body1' style={{ color: theme.palette.black, fontWeight: '600', fontSize: '13px' }}>No information available</Typography>
                              )}
                          </Grid>

                          {/* LWs */}
                          <Grid item container direction='column'>
                            <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>* Learner Works</Typography>
                            {formattedInfo && formattedInfo.projectLWs && formattedInfo.projectLWs.length !== 0
                              ? (
                                formattedInfo.projectLWs.map((lw) => {
                                  const { learnerWorkID, learnerWorkTitle: title } = lw
                                  return (
                                    <Button
                                      key={`link-${learnerWorkID}`}
                                      variant='text'
                                      style={{ textTransform: 'none', color: styles.mainColor, textDecoration: 'underline', justifyContent: 'flex-start', fontWeight: '600', fontSize: '13px', padding: 0 }}
                                      onClick={(e) => { e.preventDefault(); handleArtifactViewChange(e, artifactTypes.LW, learnerWorkID, creatorID) }}
                                    >
                                      {title}
                                    </Button>
                                  )
                                })

                              )
                              : (
                                <Typography variant='body1' style={{ color: theme.palette.black, fontWeight: '600', fontSize: '13px' }}>No information available.</Typography>
                              )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>}

                  {artifactType && artifactType === artifactTypes.LW &&
                    <>
                      {/* **************** Resources ***************** */}
                      <Paper elevation={0} style={{ backgroundColor: theme.palette.grey.lighter, padding: '1em', width: '100%', marginTop: '1em' }}>
                        <Grid container item direction='row'>
                          {/* Left Side Column */}
                          <Grid item container direction='column' xs={3} sm={2}>
                            <Grid item>
                              <Typography variant='h5' style={{ fontWeight: '600' }}>Resources</Typography>
                            </Grid>
                          </Grid>

                          <Divider flexItem variant='middle' orientation='vertical' />

                          {/* Right Side Column */}
                          <Grid item container direction='column' xs={8} sm={9} spacing={3}>
                            {/* Documents */}
                            <Grid item container direction='column'>
                              <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>Documents</Typography>
                              {formattedInfo && formattedInfo.attachments && formattedInfo.attachments.length !== 0
                                ? (
                                  formattedInfo.attachments.map((attachment) => {
                                    return (
                                      <Typography
                                        key={`attachment-${attachment.attachmentID}`}
                                        variant='body1'
                                        style={{ color: theme.palette.purple.darkest, fontWeight: '600', fontSize: '13px', textDecoration: 'underline' }}
                                      >
                                        <Link href={attachment.filePath} target='_blank' rel='noopener'>{attachment.fileName}</Link>
                                      </Typography>
                                    )
                                  })

                                )
                                : (
                                  <Typography variant='body1' style={{ color: theme.palette.black, fontWeight: '600', fontSize: '13px' }}>N/A</Typography>
                                )}
                            </Grid>

                            {/* Links */}
                            <Grid item container direction='column'>
                              <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>Links</Typography>
                              {formattedInfo && formattedInfo.links && formattedInfo.links.length !== 0
                                ? (
                                  formattedInfo.links.map((linkInfo) => {
                                    const { linkID, link: linkName } = linkInfo
                                    return (
                                      <Button
                                        key={`link-${linkID}`}
                                        variant='text'
                                        className={classes.linkButtons}
                                        onClick={async () => await handleExternalLinkClick(linkName)}
                                      >
                                        {linkName}
                                      </Button>
                                    )
                                  })

                                )
                                : (
                                  <Typography variant='body1' style={{ color: theme.palette.black, fontWeight: '600', fontSize: '13px' }}>N/A</Typography>
                                )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    </>}
                </Grid>
                {!isForMCE &&
                  <>
                    {artifactType && artifactType !== artifactTypes.EFR
                      ? (
                        <>
                          {/* Comment Section for artifacts that are not EFRs */}
                          <Grid item xs={5} container direction='row' style={{ marginTop: '1em', marginBottom: '1em' }} alignItems='center' justifyContent='flex-start'>
                            <SocialIconSection
                              commentCount={publicCommentCount}
                              likeCount={likeCount}
                              saveCount={saveCount}
                              onSocialButtonClick={onSocialButtonClick}
                              artifactInfo={localInfo}
                              classes={classes}
                              artifactLiked={artifactLiked}
                              artifactSaved={artifactSaved}
                              publicView
                            />
                          </Grid>

                          {/* Artifact Comments and LED Duplications */}
                          <Divider />

                          {/* Buttons to choose which  social display (comments or duplicates) the user wishes to view */}
                          <Grid item container direction='row' style={{ marginBottom: '1em', marginTop: '2em' }}>
                            {/* Comments Button */}
                            <Grid item xs={4} sm={2} container direction='row' alignContent='center'>
                              <Button
                                variant={socialViewType === socialTypes.COMMENTS ? 'contained' : 'text'}
                                className={socialViewType === socialTypes.COMMENTS ? classes.buttonActive : classes.buttonInactive}
                                onClick={() => handleSocialTypeChange(socialTypes.COMMENTS)}
                                endIcon={
                                  <Fab component='div' size='small' className={classes.buttonFab}>
                                    {commentList ? commentList.length : 0}
                                  </Fab>
                                }
                              >
                                Comments
                              </Button>
                            </Grid>
                            {/* If artifact is an original, display a button to view any duplicates of the artifact */}
                            {artifactType === artifactTypes.LED && !forkedFrom &&
                              <Grid item xs={4} sm={2} container direction='row' alignContent='center'>
                                <Button
                                  variant={socialViewType === socialTypes.DUPLICATES ? 'contained' : 'text'}
                                  className={socialViewType === socialTypes.DUPLICATES ? classes.buttonActive : classes.buttonInactive}
                                  onClick={() => handleSocialTypeChange(socialTypes.DUPLICATES)}
                                  endIcon={
                                    <Fab component='div' size='small' className={classes.buttonFab}>
                                      {duplicates ? duplicates.length : 0}
                                    </Fab>
                                  }
                                >
                                  Duplicates
                                </Button>
                              </Grid>}

                          </Grid>

                          {/* Display either the comments or duplicate sections (default to only comments if artifact is not an original) */}
                          {getSocialTypeDisplay(socialViewType)}
                        </>
                      )
                      : (
                        <>
                          {/* EFR Resource Link */}
                          <Grid item container direction='column' style={{ marginBottom: '.5em', marginTop: '1em' }}>
                            <Grid item xs={12} container justifyContent='center' style={{ width: '100%' }}>
                              {formattedInfo.bannerImagePath && formattedInfo.bannerImagePath !== ''
                                ? (
                                  <img src={formattedInfo.bannerImagePath} alt='featured-resource' className={classes.bannerImageStyle} />
                                )
                                : (
                                  <img src={EFRDefaultImage} alt='default-featured-resource' className={classes.defaultBannerImageStyle} />
                                )}
                            </Grid>
                            <Grid item container style={{ marginTop: '2em' }}>
                              <Typography style={{ wordBreak: 'break-word' }}>{formattedInfo && formattedInfo.resourceDescription ? formattedInfo.resourceDescription : ''}</Typography>
                            </Grid>
                            <Grid container item justifyContent='flex-start' style={{ marginTop: '2em' }}>
                              <Tooltip arrow title={`${formattedInfo && formattedInfo.resourceLink ? formattedInfo.resourceLink : ''}`}>
                                <Button
                                  variant='contained'
                                  style={{ margin: 'auto', marginLeft: 0, fontWeight: '600', backgroundColor: styles.mainColor }}
                                  onClick={async () => await handleExternalLinkClick(formattedInfo.resourceLink)}
                                >
                                  {formattedInfo && formattedInfo.resourceType && formattedInfo.resourceType === efrSubTypes.EFR_EVENT ? 'RSVP' : 'Learn More'}
                                </Button>
                              </Tooltip>
                            </Grid>
                          </Grid>

                          {/* Comments */}
                          <Divider />
                          <Grid item container direction='column' style={{ marginBottom: '2em', marginTop: '1em' }}>
                            <Grid item container direction='row' alignContent='center'>
                              <Grid item>
                                <Typography variant='body1' style={{ fontWeight: '600' }}>Comments</Typography>
                              </Grid>
                              <Grid item style={{ marginLeft: '.5em' }}>
                                <Fab style={{ height: '15px', width: '15px', minHeight: '15px', backgroundColor: styles.mainColor, boxShadow: 'none' }}>
                                  <Typography variant='body1' style={{ fontSize: '10px', color: 'white', fontWeight: 600 }}>{commentList ? commentList.length : 0}</Typography>
                                </Fab>
                              </Grid>
                            </Grid>
                            <Grid item container direction='column' alignContent='flex-start' style={{ marginTop: '1em', marginBottom: '.5em' }}>

                              {commentList && commentList.length !== 0
                                ? (
                                  <>
                                    {commentList.map((comment, i) => {
                                      return (
                                        <Grid key={`${comment.commentID} - ${i}`} container item style={{ marginBottom: '.5em' }}>
                                          <ArtifactComment
                                            key={comment.commentID}
                                            artifactID={formattedInfo.artifactID}
                                            commentState={commentState}
                                            setCommentState={setCommentState}
                                            styles={styles}
                                            classes={classes}
                                            commentInfo={comment}
                                            section='public'
                                            fromPublic
                                            fireSuccess={fireCommentSuccess}
                                          />
                                        </Grid>
                                      )
                                    })}
                                  </>
                                )
                                : (
                                  <Grid container justifyContent='center' item style={{ marginBottom: '.5em' }}>
                                    <Typography variant='body1' style={{ color: theme.palette.grey.medium }}>There are no comments for this artifact.</Typography>
                                  </Grid>
                                )}
                              <>
                                {commentState && commentState.public
                                  ? (
                                    <ArtifactComment
                                      artifactID={formattedInfo.artifactID}
                                      commentState={commentState}
                                      setCommentState={setCommentState}
                                      styles={styles}
                                      classes={classes}
                                      section='public'
                                      fromPublic
                                      fireSuccess={fireCommentSuccess}
                                    />
                                  )
                                  : (
                                    <Grid container item justifyContent='flex-start' style={{ marginTop: '2em' }}>
                                      <Button
                                        variant='contained'
                                        style={{ margin: 'auto', marginLeft: 0, fontWeight: '600', backgroundColor: styles.mainColor }}
                                        startIcon={
                                          <AddComment />
                                        }
                                        onClick={() => setCommentState({ ...commentState, public: true })}
                                      >
                                        Add Comment
                                      </Button>
                                    </Grid>
                                  )}
                              </>
                            </Grid>
                          </Grid>
                        </>
                      )}
                  </>}
              </>
            )
            : (
              <Grid item container direction='column' justifyContent='center' alignItems='center' style={{ height: '60vh' }}>
                <Grid item>
                  <SentimentVeryDissatisfied style={{ color: theme.palette.grey.medium, marginBottom: '.5em', fontSize: '40px' }} />
                </Grid>
                <Grid item>
                  <Typography variant='h3' style={{ color: theme.palette.grey.medium, textAlign: 'center' }}>Sorry!</Typography>
                  <Typography variant='h4' style={{ color: theme.palette.grey.medium }}>This artifact is currently not viewable to the public.</Typography>
                </Grid>
              </Grid>
            )}

        </Grid>
      </Paper>

      {/* Hidden component accessed with export pdf function */}
      <div id='led-pdf-template' hidden>
        <LEDtoPDFTemplate ledInfo={pdfInfo} />
      </div>
    </>
  )
}

export default withRouter(PublicArtifactContent)
