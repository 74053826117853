import React from 'react'
import { withRouter } from 'react-router-dom'
import { Card, CardHeader, CardContent, Grid, Typography, Avatar, useTheme, Button, useMediaQuery } from '@material-ui/core'
import {
  PeopleRounded, VideocamRounded, CalendarTodayRounded, EventAvailableRounded
} from '@material-ui/icons'

import {
  efrSubTypesFormat, efrSubTypes, gradeCategoriesFormat,
  esRecordTypeFormat
} from '../../../../utils'

import EFRLogo from '../../../../assets/EFlogoAvatar.png'

/* NOTES: Card to display resource information retruned from a global search
  -- Parent Component: ui/Search/ResultAreas/ResourceResults.js

  In depth render tree loacated in SearchBarNav.js
*/
const SearchResourceCard = (props) => {
  // Props:
  // - SearchBarNav.js: handleReset
  // - ResourceResults.js: resource
  const { resource, handleReset } = props

  const {
    title, description, er_resourceType: resourceType, er_resourceAudience: resourceAudience, objectID,
    userProfileID: profileID, userFullName, profileAvatarPath, userUnavailable
  } = resource

  const isUserUnavailable = Boolean(userUnavailable)

  const theme = useTheme()

  const xsLand = useMediaQuery(theme.breakpoints.down('sm'))

  // Click handler to direct the user to the profile of the resource author
  const viewAuthorProfile = (id) => {
    if (id) {
      handleReset()
      props.history.push({
        pathname: '/profile',
        search: `?user=${id}`
      })
    }
  }

  // Click handler to direct the user to the public view of an artifact
  const handlePublicView = async (authorID, artifactID) => {
    const formatType = esRecordTypeFormat.EF

    props.history.push({
      pathname: '/artifact',
      search: `?public=${formatType}&user=${authorID}&artifact=${artifactID}`
    })
  }

  // Display the correct icon based on the resource type
  const getTypeIcon = (type) => {
    switch (type) {
      case efrSubTypes.EFR_EVENT: {
        return <CalendarTodayRounded style={{ color: 'white', paddingRight: '.2em', fontSize: '20px' }} />
      }
      case efrSubTypes.EFR_WEBINAR: {
        return <EventAvailableRounded style={{ color: 'white', paddingRight: '.2em', fontSize: '20px' }} />
      }
      case efrSubTypes.EFR_VIDEO: {
        return <VideocamRounded style={{ color: 'white', paddingRight: '.2em', fontSize: '20px' }} />
      }
      default: return <CalendarTodayRounded style={{ color: 'white', paddingRight: '.2em', fontSize: '20px' }} />
    }
  }

  return (
    <Card style={{ width: '100%' }}>
      <CardHeader
        avatar={
          <Avatar src={EFRLogo} style={{ backgroundColor: 'white', height: '20px', width: '20px', marginRight: '0' }} />
        }
        title={
          <Grid container direction='row'>
            <Grid container item xs={xsLand ? 11 : 6} justifyContent='flex-start'>
              <Typography variant='h5' style={{ color: 'white', textTransform: 'none', fontWeight: 600 }}>{!title ? '' : title.length > 20 ? `${title.slice(0, 19)}...` : title}</Typography>
            </Grid>
            <Grid container item xs={xsLand ? 1 : 6} justifyContent='flex-end' alignItems='center' style={{ paddingRight: '.3em' }}>
              {resourceType && getTypeIcon(resourceType)}
              <Typography variant='h6' style={{ color: 'white', textTransform: 'none', fontWeight: 400, display: xsLand ? 'none' : 'visible' }}>{!resourceType ? '' : efrSubTypesFormat[resourceType]}</Typography>
            </Grid>
          </Grid>
        }
        style={{
          padding: '.3em .5em .3em .7em',
          backgroundColor: '#B41515',
          cursor: 'pointer'
        }}
        onClick={() => handlePublicView(profileID, objectID)}
      />
      <CardContent
        style={{
          padding: '.2em .5em .5em .5em'
        }}
      >
        <Grid container direction='column'>
          <Grid item>
            <Typography
              variant='caption'
              style={{ color: theme.palette.grey.dark }}
            >
              {!description ? '' : description.length > 50 ? `${description.slice(0, 49)}...` : description}
            </Typography>
          </Grid>
          <Grid item container direction='row' style={{ marginTop: '.7em' }}>
            <Grid item xs={4} container direction='row' alignItems='center' style={{ cursor: isUserUnavailable ? 'default' : 'pointer' }}>
              <Avatar src={isUserUnavailable || !profileAvatarPath ? '' : profileAvatarPath} style={{ height: '18px', width: '18px', marginRight: '.3em' }} />
              <Button
                variant='text'
                size='small'
                style={{ padding: 0, justifyContent: 'flex-start' }}
                onClick={() => isUserUnavailable ? false : viewAuthorProfile(profileID)}
                disabled={isUserUnavailable}
              >
                <Typography
                  variant='body1'
                  style={{ color: theme.palette.purple.darkest, fontWeight: 600, textTransform: 'none', fontSize: '12px' }}
                >
                  {isUserUnavailable || !userFullName ? 'Ed Farm User' : userFullName}
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={4} container direction='row' alignItems='center'>
              <PeopleRounded style={{ fontSize: '18px', marginRight: '.3em', color: theme.palette.grey.dark }} />
              <Typography
                variant='body1'
                style={{ color: theme.palette.grey.dark, fontWeight: 600, fontSize: '12px' }}
              >
                {!resourceAudience ? '' : gradeCategoriesFormat[resourceAudience]}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default withRouter(SearchResourceCard)
