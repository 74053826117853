import { trackPromise } from 'react-promise-tracker'
import { parseResponse } from '../../lib'
import {
  GET_PROFILE_EDIT_DATA, GET_ABOUT_EDIT_DATA, UPDATE_AVATAR, UPDATE_USER_PROFILE,
  RESET_ABOUT_EDIT_DATA, RESET_PROFILE_EDIT_DATA,
  DATA_REQUEST, DATA_SUCCESS, DATA_ERROR, UPDATE_AUTH_AVATAR
} from '../types'

import log from 'loglevel'
import moment from 'moment'

// UI Tech Debt: removed unused API variables,

const api = process.env.REACT_APP_API_URL
const cloudfrontPath = process.env.REACT_APP_CLOUD_FRONT_BASE

// gets schools and default avatars to populate choices when editing profile
export const getProfileEditData = (fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { userID, token } = getState().auth

      if (!token) {
        dispatch({ type: DATA_ERROR, payload: { type: GET_PROFILE_EDIT_DATA } })
        fireFailure()
        return false
      }

      dispatch({ type: DATA_REQUEST, payload: { type: GET_PROFILE_EDIT_DATA } })

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${api}/v1/profile/${userID}/edit/options`, options))
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) {
        log.info(response.status)

        dispatch({ type: DATA_ERROR, payload: { type: GET_PROFILE_EDIT_DATA } })
      } else {
        const { data } = await response.json()

        dispatch({ type: DATA_SUCCESS, payload: { type: GET_PROFILE_EDIT_DATA } })

        dispatch({ type: GET_PROFILE_EDIT_DATA, payload: data })
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

// gets subjects and grades to populate choices when editing about section
export const getAboutEditData = () => {
  return async (dispatch, getState) => {
    const { userID, token } = getState().auth

    dispatch({ type: DATA_REQUEST, payload: { type: GET_ABOUT_EDIT_DATA } })

    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }

    const response = await trackPromise(window.fetch(`${api}/v1/profile/${userID}/about/options`, options))
    const parsedResponse = parseResponse(response, dispatch)
    if (!parsedResponse) { return false }

    if (parsedResponse.error) {
      log.info(response.status)

      dispatch({ type: DATA_ERROR, payload: { type: GET_ABOUT_EDIT_DATA } })
    } else {
      const { data } = await response.json()

      dispatch({ type: DATA_SUCCESS, payload: { type: GET_ABOUT_EDIT_DATA } })

      dispatch({ type: GET_ABOUT_EDIT_DATA, payload: data })
    }
  }
}

export const updateUserProfile = (profileInfo, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { userID, token } = getState().auth

      const {
        certifications, avatar, avatarType, isAvatarChanged, isCustomAvatar, nickName, schoolID, schoolDistrictID,
        certificationsToAdd, certificationsToDelete
      } = profileInfo

      const body = JSON.stringify({
        avatar, avatarType, certificationsToAdd, certificationsToDelete, isAvatarChanged, isCustomAvatar, nickName, schoolID, schoolDistrictID
      })

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/v1/user/${userID}/profile`, options))
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) {
        fireFailure()
      } else {
        dispatch({
          type: UPDATE_USER_PROFILE,
          payload: { certifications, nickName, schoolID }
        })

        if (isAvatarChanged && !isCustomAvatar) {
          setTimeout(() => {
            dispatch({
              type: UPDATE_AVATAR,
              payload: avatar ? `${cloudfrontPath}/${avatar}` : ''
            })
            dispatch({
              type: UPDATE_AUTH_AVATAR,
              payload: avatar ? `${cloudfrontPath}/${avatar}` : ''
            })
          }, 5000)
        }

        fireSuccess()

        if (isAvatarChanged && isCustomAvatar) {
          fireSuccess()
          setTimeout(() => {
            dispatch({ type: UPDATE_AVATAR, payload: avatar ? `${cloudfrontPath}/avatar/${userID}?${moment().add(5, 'minute').unix()}` : '' })
            dispatch({ type: UPDATE_AUTH_AVATAR, payload: avatar ? `${cloudfrontPath}/avatar/${userID}?${moment().add(5, 'minute').unix()}` : '' })
          }, 5000)
        }
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const resetProfileEditData = () => {
  return async function (dispatch) {
    dispatch({ type: RESET_PROFILE_EDIT_DATA })
  }
}

export const updateAboutSection = (updateData, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { userID, token } = getState().auth

      const body = updateData

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
      }

      const response = await trackPromise(window.fetch(`${api}/v1/user/${userID}/about`, options))
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) {
        log.info(response.status)
        fireFailure()
      } else {
        fireSuccess()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const resetAboutEditData = () => {
  return async function (dispatch) {
    dispatch({ type: RESET_ABOUT_EDIT_DATA })
  }
}
