import { LOGIN, LOGOUT, UPDATE_EMAIL, UPDATE_ONBOARD_LATEST, GET_APPROVAL_STATUSES, GET_ONBOARDING_DATA, REMOVE_ERROR, UPDATE_AUTH_AVATAR, ADD_LIKE, REMOVE_LIKE, ADD_SAVE, REMOVE_SAVE, UPDATE_ASSESSOR_STATUS } from '../types'

// UI Tech Debt: moved onboarding into auth reducer and deleted it's respective file

const initialState = {
  token: '',
  userID: '',
  roleID: '',
  superAdmin: 0,
  inFellowTypeID: '',
  fullName: '',
  nickName: '',
  emailAddress: '',
  onboardingStep: null,
  isActive: null,
  isValidated: null,
  isAccountApproved: null,
  isAccountDenied: null,
  isAuthenticated: false,
  approvedDate: null,
  deniedDate: null,
  waitTime: null,
  error: false,
  profileID: '',
  profileAvatarPath: '',
  likedArtifacts: [],
  savedArtifacts: [],
  grades: [],
  subjects: [],
  schools: [],
  defaultAvatars: [],
  isAssessor: false
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN: return {
      ...state,
      token: action.payload.token,
      userID: action.payload.userID,
      roleID: action.payload.roleID,
      superAdmin: action.payload.superAdmin,
      inFellowTypeID: action.payload.inFellowTypeID,
      fullName: action.payload.fullName,
      nickName: action.payload.nickName,
      emailAddress: action.payload.emailAddress,
      onboardingStep: action.payload.onboardingStep,
      isActive: action.payload.isActive,
      isValidated: action.payload.isValidated,
      isAccountApproved: action.payload.isAccountApproved,
      isAuthenticated: true,
      profileID: action.payload.profileID,
      profileAvatarPath: action.payload.profileAvatarPath,
      likedArtifacts: action.payload.likedArtifacts,
      savedArtifacts: action.payload.savedArtifacts,
      isAssessor: action.payload.isAssessor
    }
    case LOGOUT: return {
      ...initialState,
      error: action.payload.error || false
    }
    case REMOVE_ERROR: return {
      ...state,
      error: false
    }
    case UPDATE_EMAIL: return {
      ...state,
      emailAddress: action.payload
    }
    case UPDATE_ONBOARD_LATEST: return {
      ...state,
      onboardingStep: action.payload
    }
    case UPDATE_AUTH_AVATAR: return {
      ...state,
      profileAvatarPath: action.payload
    }
    case UPDATE_ASSESSOR_STATUS: return {
      ...state,
      isAssessor: action.payload
    }
    case GET_APPROVAL_STATUSES: return {
      ...state,
      isAccountDenied: action.payload.isAccountDenied,
      isAccountApproved: action.payload.isAccountApproved,
      isValidated: action.payload.isValidated,
      deniedDate: action.payload.deniedDate,
      approvedDate: action.payload.approvedDate,
      waitTime: action.payload.waitTime,
      roleID: action.payload.roleID
    }
    case GET_ONBOARDING_DATA: return {
      ...state,
      grades: action.payload.grades,
      subjects: action.payload.subjects,
      schools: action.payload.schools,
      defaultAvatars: action.payload.defaultAvatars
    }
    case ADD_LIKE: {
      return {
        ...state,
        likedArtifacts: action.payload
      }
    }
    case REMOVE_LIKE: {
      return {
        ...state,
        likedArtifacts: action.payload
      }
    }
    case ADD_SAVE: {
      return {
        ...state,
        savedArtifacts: action.payload
      }
    }
    case REMOVE_SAVE: {
      return {
        ...state,
        savedArtifacts: action.payload
      }
    }
    default: return state
  }
}

export default authReducer
