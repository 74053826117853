import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { isEqual } from 'lodash'

import {
  Grid, Typography, useTheme, OutlinedInput, Table, TableBody, TableContainer, TableCell, TableHead, TableRow,
  Paper, Avatar, Select, MenuItem, TableSortLabel, IconButton, Tooltip
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import { SearchRounded, Settings } from '@material-ui/icons'
import { adminPageOptions, updateUserActionTypes } from '../../../../utils'
import { ValidateEmail } from '../../../../lib'
import { NotificationToast } from '../../tools'
import { updateUserEmailAndValidation } from '../../../../redux/actions'

import DeleteUserPopover from './DeleteUserPopover'
import UpdateUserDialog from './UpdateUserDialog'

const AdminUserList = (props) => {
  const {
    classes, allAdminUsers, totalAdminUsers, filter, setFilter, defaultFilter, getAdminUserList, activeButton, confirmAnchorEl, setConfirmAnchorEl = () => { },
    handleConfirmPopover, openConfirmPopover, handleConfirmDeleteUser, selectedAction, setSelectedAction = () => { }
  } = props
  const theme = useTheme()
  const [now] = useState(moment().unix())
  const dispatch = useDispatch()
  const { superAdmin = 0 } = useSelector(state => state.auth)

  // ******************** Data Creation ******************** //

  // If the filter was changed, fetch the fellows with the new filter
  useEffect(() => {
    if (filter && defaultFilter && activeButton === 'admin-users') {
      if (!isEqual(filter, defaultFilter)) {
        dispatch(getAdminUserList(filter))
      }
    }
  }, [dispatch, filter, defaultFilter, getAdminUserList, setFilter, activeButton])

  // Formats the array returned from the dispatch for the data table
  const createData = (avatar = '', userID, name, emailAddress) => {
    return { avatar, userID, name, emailAddress }
  }

  // Data rows for the displayed table
  const [rows, setRows] = useState([])
  const [editRow, setEditRow] = useState('')

  const updateRow = (fellow, newEmailAddress) => {
    const newRows = [...rows]
    const index = newRows.findIndex(x => x.userID === fellow)
    const oldRow = newRows[index]

    const newRow = {
      ...oldRow,
      emailAddress: newEmailAddress || oldRow?.emailAddress
    }

    newRows[index] = newRow

    setRows(newRows)
  }

  useEffect(() => {
    if (allAdminUsers) {
      if (allAdminUsers.length) {
        const newRows = []
        allAdminUsers.forEach(admin => {
          const { profileAvatarPath, userID, name, emailAddress } = admin
          newRows.push(createData(profileAvatarPath, userID, name, emailAddress))
        })
        setRows(newRows)
      } else {
        setRows([])
      }
    }
  }, [allAdminUsers])

  // Column Headers
  const headCells = [
    { id: 'name', label: 'Name', align: 'left' }
  ]

  // ********************* Pagination Logic: **************** //
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const handleChangePage = (event, value) => {
    if (value >= 0) {
      setPage(value - 1)
      setFilter({
        ...filter,
        sortCount: rowsPerPage,
        page: value
      })
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: event.target.value
    })
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalAdminUsers - page * rowsPerPage)

  // ******************** Column Sort Logic **************** //

  const [orderBy, setOrderBy] = useState('')
  const [order, setOrder] = useState('desc')

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: rowsPerPage,
      sortDirection: isAsc ? 'DESC' : 'ASC',
      sortType: property
    })
  }

  // ********************** Search Logic *********************** //
  const [searchInput, setSearchInput] = useState('')

  const handleSearch = (e) => {
    setSearchInput(e.target.value)
    if (searchInput && searchInput.length >= 3) {
      // Previously, issues were occuring if the user was on a page that was greater than 1 and made a search. The page is now being reset beforehand to avoid that.
      setPage(0)
      setFilter({
        ...filter,
        page: 1,
        sortCount: rowsPerPage,
        searchPhrase: searchInput
      })
    }

    if (e.target.value === '') {
      setPage(0)
      // Set the sortCount to current rowsPerPage instead of the default
      setFilter({
        ...defaultFilter,
        sortCount: rowsPerPage
      })
    }
  }

  const [modalOpen, setModalOpen] = useState(false)

  const [newEmailAddress, setNewEmailAddress] = useState('')

  // Confirm Delete User handlers //
  const handleConfirmPopClose = () => {
    if (modalOpen) { handleCloseModal() }
    if (editRow) { setEditRow('') }
    setConfirmAnchorEl(null)
    setSelectedAction(updateUserActionTypes.UPDATE_EMAIL)
  }

  const handleOpenModal = (userRow) => {
    if (superAdmin) {
      setEditRow(userRow)
      setNewEmailAddress(userRow?.emailAddress)

      setModalOpen(true)
    }
  }

  const handleCloseModal = () => {
    setEditRow('')
    setModalOpen(false)
  }

  const isEmailValid = useMemo(() => {
    const isValid = ValidateEmail(newEmailAddress) && !isEqual(newEmailAddress, editRow?.emailAddress)

    return isValid
  }, [newEmailAddress, editRow])

  const handleSaveEmailChange = () => {
    const userID = editRow?.userID || null
    const oldEmailAddress = editRow?.emailAddress || null

    // newEmailAddress is valid and newEmailAddress does not equal oldEmailAddress
    if (superAdmin && userID && newEmailAddress && oldEmailAddress && isEmailValid) {
      dispatch(updateUserEmailAndValidation(userID, newEmailAddress, oldEmailAddress, fireUpdateEmailSuccess, fireUpdateEmailFailure))
    }
  }

  const fireUpdateEmailSuccess = (userID, newEmailAddress) => {
    handleCloseModal()
    updateRow(userID, newEmailAddress)
    NotificationToast(false, 'Successfully Updated User\'s Email')
  }

  const fireUpdateEmailFailure = (message) => { NotificationToast(true, message) }

  return (
    <>
      <Grid container direction='column'>
        <Grid item container style={{ marginBottom: '2em' }}>
          {/* Page Title */}
          <Typography variant='h4' style={{ fontSize: '20px' }}>Admin Users</Typography>
        </Grid>

        {/* Top Pagination display and search input */}
        <Grid item container direction='row' style={{ marginBottom: '1em' }}>
          <Grid item container alignContent='flex-end' xs={7}>
            <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none', fontWeight: 400, fontSize: '16px' }}>
              Displaying {totalAdminUsers === 0 ? 0 : (page * rowsPerPage) + 1} to {rows && page * rowsPerPage + rowsPerPage > totalAdminUsers ? totalAdminUsers : page * rowsPerPage + rowsPerPage} of {totalAdminUsers}
            </Typography>
          </Grid>
          <Grid item container justifyContent='flex-end' xs={5}>
            <OutlinedInput
              className={classes.searchInput}
              size='small'
              margin='dense'
              fullWidth
              inputProps={{ style: { border: 'none', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '5px' } }}
              placeholder='Search...'
              classes={{ input: classes.inputPlaceholder }}
              startAdornment={
                <SearchRounded style={{ color: theme.palette.grey.dark, fontSize: '20px' }} />
              }
              value={searchInput}
              onChange={(e) => handleSearch(e)}
            />
          </Grid>
        </Grid>

        {/* Data Table */}
        <Grid item container direction='column' style={{ marginBottom: '1em' }}>
          <TableContainer elevation={0} style={{ border: `solid 1px ${theme.palette.grey.medium}` }} component={Paper}>
            <Table>
              <TableHead style={{ backgroundColor: theme.palette.grey.lighter }}>
                <TableRow>
                  {/* Sortable Table Column Headers */}
                  {headCells.map(header => {
                    return (
                      <TableCell
                        key={header.id}
                        align={header.align}
                        style={{ padding: '16px' }}
                        sortDirection={orderBy === header.id ? 'asc' : false}
                      >
                        <TableSortLabel
                          active={orderBy === header.id}
                          direction={orderBy === header.id ? order : 'asc'}
                          onClick={() => handleRequestSort(header.id)}
                        >
                          <Typography variant='h5' style={{ color: theme.palette.grey.dark }}>{header.label}</Typography>
                          {orderBy === header.id ? (
                            <span className={classes.visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    )
                  })}
                  {Boolean(superAdmin) &&
                    <>
                      <TableCell align='left' style={{ padding: '16px' }} />
                      <TableCell align='left' />
                    </>}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  return (
                    <TableRow key={`index-${row.userID}`}>
                      <TableCell>
                        <Grid item container direction='row' alignItems='center'>
                          <Avatar src={`${row.avatar}?${now}`} className={classes.avatarSize} />
                          <Typography variant='body1' style={{ marginLeft: '.5em', fontWeight: 600 }}>{row.name}</Typography>
                        </Grid>
                      </TableCell>

                      <TableCell align='right' />

                      {Boolean(superAdmin) &&
                        <TableCell align='right'>
                          <Tooltip title='Advanced'>
                            <IconButton
                              className={classes.editIcon}
                              style={{ color: '#29356a' }}
                              onClick={(e) => handleOpenModal(row)}
                            >
                              <Settings />
                            </IconButton>
                          </Tooltip>
                        </TableCell>}
                    </TableRow>
                  )
                })}
                {/* Data Array has reached it's length: */}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 10 }}>
                    <TableCell colSpan={5}>
                      <Typography variant='body1' style={{ color: theme.palette.grey.medium, textAlign: 'center' }}>{totalAdminUsers === 0 ? 'No Results' : 'End of List'}</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* Bottom Pagination Controls */}
        <Grid item container direction='row' style={{ marginBottom: '4em' }}>
          {/* Rows per Page Selection */}
          <Grid item container direction='row' xs={6} justifyContent='flex-start'>
            <Grid item style={{ marginRight: '.2em', display: 'flex', alignItems: 'flex-end' }}>
              <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>Display</Typography>
            </Grid>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                variant='outlined'
                size='small'
                defaultValue={10}
                value={rowsPerPage}
                onChange={(e) => handleChangeRowsPerPage(e)}
                classes={{ root: classes.searchInput, selectMenu: classes.statusSelect }}
                style={{ padding: 0 }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </Grid>
            <Grid item style={{ marginLeft: '.4em', display: 'flex', alignItems: 'flex-end' }}>
              <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>entries</Typography>
            </Grid>
          </Grid>
          {/* Pagination/ Page Selection */}
          <Grid item container xs={6} justifyContent='flex-end'>
            <Pagination
              color='primary'
              classes={{ root: classes.pagination }}
              count={totalAdminUsers ? Math.ceil(totalAdminUsers / rowsPerPage) : 0}
              page={page === 0 ? 1 : page + 1}
              onChange={handleChangePage}
              shape='rounded'
            />
          </Grid>
        </Grid>
      </Grid>

      <DeleteUserPopover
        confirmAnchorEl={confirmAnchorEl}
        setConfirmAnchorEl={setConfirmAnchorEl}
        openConfirmPopover={openConfirmPopover}
        handleConfirmDeleteUser={handleConfirmDeleteUser}
        userType={adminPageOptions.ADMIN_USERS}
        handleConfirmPopClose={handleConfirmPopClose}
        classes={classes}
      />

      {Boolean(superAdmin) &&
        <UpdateUserDialog
          classes={classes}
          theme={theme}
          editRow={editRow}
          modalOpen={modalOpen}
          handleCloseModal={handleCloseModal}
          handleConfirmPopover={handleConfirmPopover}
          newEmailAddress={newEmailAddress}
          setNewEmailAddress={setNewEmailAddress}
          handleSaveEmailChange={handleSaveEmailChange}
          isEmailValid={isEmailValid}
          selectedAction={selectedAction}
          setSelectedAction={setSelectedAction}
        />}
    </>
  )
}

export default AdminUserList
