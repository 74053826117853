import React from 'react'
import { withRouter } from 'react-router-dom'

import {
  Grid, Card, CardHeader, CardContent,
  Typography, Button, useTheme, Avatar
} from '@material-ui/core'

import BlankAvatar from '../../../assets/blank-person.jpeg'

const ModifiedGradCapIcon = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='50'
      height='50'
      viewBox='0 0 111 111'
    >
      <path
        className='b'
        d='M77.8,75.148,42.929,64.435a9.986,9.986,0,0,0-5.849,0L2.212,75.148a2.943,2.943,0,0,0,0,5.7l6.079,1.867a9.85,9.85,0,0,0-2.235,5.862A3.857,3.857,0,0,0,5.737,95.2l-3.191,14.36A2,2,0,0,0,4.5,112h7.014a2,2,0,0,0,1.952-2.434L10.272,95.2a3.847,3.847,0,0,0-.225-6.565,5.9,5.9,0,0,1,2.586-4.59L37.079,91.56a10,10,0,0,0,5.849,0L77.8,80.848a2.943,2.943,0,0,0,0-5.7ZM44.1,95.384a13.994,13.994,0,0,1-8.2,0L17.777,89.815,16,104c0,4.419,10.745,8,24,8s24-3.581,24-8L62.231,89.814Z'
        transform='translate(-0.005 -63.997)'
        fill={props.color}
      />
    </svg>
  )
}

const ModifiedGradesIcon = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='60'
      height='60'
      viewBox='0 0 111 111'
    >
      <path
        className='b'
        d='M33,9a9,9,0,1,0-9,9A9,9,0,0,0,33,9ZM21.9,22.6C16.337,19.2,7.327,18.262,2.794,18A2.594,2.594,0,0,0,0,20.544V41.432a2.578,2.578,0,0,0,2.483,2.536c4.093.215,12.374,1,18.1,3.884a1.305,1.305,0,0,0,1.92-1.113V23.677a1.247,1.247,0,0,0-.6-1.074ZM45.206,18c-4.533.257-13.543,1.193-19.1,4.6a1.271,1.271,0,0,0-.6,1.09V46.736a1.309,1.309,0,0,0,1.926,1.116c5.722-2.88,14-3.667,18.091-3.881A2.58,2.58,0,0,0,48,41.434V20.544A2.6,2.6,0,0,0,45.206,18Z'
        fill={props.color}
      />
    </svg>
  )
}

const PublicSideMenu = (props) => {
  const {
    classes, styles, authorDetails = {}, handleProfileView, artifactType, artifactTypes,
    isForMCE = false, handleExit = () => { }
  } = props

  const theme = useTheme()
  const isDeletedUser = artifactType && artifactType === artifactTypes.EFR && Boolean(authorDetails.deletedAt)

  const exitPublic = () => {
    if (!isForMCE) {
      handleProfileView()
    } else {
      handleExit()
    }
  }

  return (
    <Grid item container justifyContent='center'>
      <Card className={classes.sideMenu}>
        {/* Artifact Author/Teacher Information */}
        {!isForMCE && authorDetails && Object.keys(authorDetails).length &&
          <CardHeader
            avatar={
              <Grid item container alignItems='flex-start'>
                <Avatar src={authorDetails ? isDeletedUser ? BlankAvatar : authorDetails.profileAvatarPath : ''} />
              </Grid>
            }
            title={
              <Grid item container justifyContent='space-between' wrap='nowrap' className={classes.fixSpacing}>
                <Grid item xs={10} container justifyContent='flex-start' style={{ paddingtop: 0, paddingBottom: 0 }}>
                  <Typography variant='h4' style={{ color: 'white', textTransform: 'none', textAlign: 'left' }}>
                    {authorDetails ? isDeletedUser ? 'Ed Farm User' : authorDetails.fullName : ''}
                  </Typography>
                </Grid>
              </Grid>
            }
            subheader={
              <Grid item container justifyContent='flex-start' alignItems='center'>
                <Typography variant='h6' style={{ color: 'white', textTransform: 'none', fontWeight: '400' }}>
                  {authorDetails ? artifactType && artifactType === artifactTypes.EFR ? 'Ed Farm' : authorDetails.schoolName : ''}
                </Typography>
              </Grid>
            }
            style={{
              backgroundColor: styles.mainColor
            }}
          />}
        <CardContent style={{ backgroundColor: 'white', paddingBottom: '.8em' }}>
          {/* Author Details */}
          {!isForMCE && artifactType && artifactType !== artifactTypes.EFR &&
            <Grid container direction='column' style={{ marginTop: '.5em' }} alignContent='flex-start'>
              <Grid item container direction='row' justifyContent='space-between'>
                <Grid item xs={3}>
                  <ModifiedGradCapIcon color={theme.palette.grey.dark} />
                </Grid>
                <Grid item xs={9}>
                  <Typography variant='body1' style={{ fontWeight: '600' }}>{`${authorDetails ? authorDetails.yearsTeaching : '0'} yrs`}</Typography>
                </Grid>
              </Grid>
              <Grid item container direction='row'>
                <Grid item xs={3}>
                  <ModifiedGradesIcon color={theme.palette.grey.dark} />
                </Grid>
                <Grid item xs={9}>
                  <Typography variant='body1' style={{ fontWeight: '600' }}>
                    {authorDetails && authorDetails.gradesList && authorDetails.gradesList.length && authorDetails.gradesList.length !== 0 ? authorDetails.gradesList.length < 1 ? authorDetails.gradesList[0].gradeName : 'Multiple Grades' : 'NA'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>}
          <Button
            variant={!isForMCE ? 'outlined' : 'contained'}
            fullWidth
            style={{
              fontWeight: '600',
              ...(isForMCE ? { backgroundColor: styles.mainColor } : { borderColor: styles.mainColor }),
              color: isForMCE ? 'white' : styles.mainColor
            }}
            onClick={(e) => { e.preventDefault(); exitPublic() }}
          >
            {!isForMCE ? 'View Profile' : 'Back'}
          </Button>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default withRouter(PublicSideMenu)
