import React from 'react'
import { withRouter } from 'react-router-dom'
import { Grid, Typography, Paper, Avatar, useTheme, Chip, makeStyles, Tooltip } from '@material-ui/core'
import { PersonRounded, SchoolRounded, LocalLibraryRounded, QueryBuilderRounded } from '@material-ui/icons'
import LedExploreBack from '../../../../assets/ledExploreBackSVG.svg'
import EdfarmLogo from '../../../../assets/edFarmLogo.png'
import { artifactTypes } from '../../../../utils'
import { GradesCheck } from '../../tools'

const useStyles = makeStyles((theme) => ({
  chipRoot: {
    backgroundColor: theme.palette.purple.lighter,
    color: theme.palette.purple.darkest,
    fontSize: 12,
    margin: '3px'
  },
  chipIcon: {
    height: '14px',
    width: '14px'
  }
}))

const LEDExploreCard = (props) => {
  const { smScreen, mdScreen, lgScreen, resource } = props
  const theme = useTheme()
  const classes = useStyles()

  const {
    title = '', description: overview = '', techNeeded = '', fullName = '', profileAvatarPath = '', subjectName = '', grades = '', workType = '',
    completionTime = 0, artifactID = '', profileID = ''
  } = resource

  const truncateText = (attribute, length) => {
    if (attribute) {
      if (attribute.length && attribute.length > length) {
        const returnedLength = length - 1
        return `${attribute.substring(0, returnedLength)}...`
      }
      return attribute
    }
    return 'N/A'
  }

  // Splits string that lists current artifact grade names into an array
  const gradesArray = grades && Boolean(grades.length) ? grades.split(',') : ''

  // Handles routing the user to a public/read only view of an artifact
  const handlePublicView = async () => {
    const formatType = artifactTypes.LED

    props.history.push({
      pathname: '/artifact',
      search: `?public=${formatType}&user=${profileID}&artifact=${artifactID}`
    })
  }

  const chipArr = [
    { chipType: 'subjectName', chipText: subjectName, chipIcon: <SchoolRounded style={{ color: theme.palette.purple.darkest, fontSize: '14px' }} /> },
    { chipType: 'grades', chipText: GradesCheck(grades, gradesArray), chipIcon: <LocalLibraryRounded style={{ color: theme.palette.purple.darkest, fontSize: '14px' }} /> },
    { chipType: 'workType', chipText: workType, chipIcon: <PersonRounded style={{ color: theme.palette.purple.darkest, fontSize: '14px' }} /> },
    { chipType: 'completionTime', chipText: `${completionTime} Week${completionTime === 1 ? '' : 's'}`, chipIcon: <QueryBuilderRounded style={{ color: theme.palette.purple.darkest, fontSize: '14px' }} /> }
  ]

  return (
    <Paper
      style={{
        padding: '1em',
        display: 'flex',
        cursor: 'pointer',
        // Image should not appear on small screens
        backgroundImage: mdScreen ? 'none' : `url(${LedExploreBack})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: mdScreen ? '60vw' : '100%'
      }}
      onClick={handlePublicView}
      elevation={4}
    >
      <Grid container direction='column' style={{ minHeight: 'fit-content', minWidth: 'fit-content', width: lgScreen ? '40vw' : '30vw' }}>
        {/* Header */}
        {!mdScreen &&
          <Grid container item direction='row' justifyContent='flex-end' alignContent='center'>
            <Grid item>
              <Typography variant='h4' style={{ color: 'white', fontWeight: 600 }}>Featured Artifact</Typography>
            </Grid>
            <Grid item>
              <img src={EdfarmLogo} alt='edfarm-logo' style={{ height: '24px', width: '24px', marginLeft: '.2em' }} />
            </Grid>
          </Grid>}

        <Grid
          container
          item
          direction={mdScreen ? 'column' : 'row'}
        >
          {/* Left Side Details of resource */}
          <Grid item container direction='column' style={{ marginTop: mdScreen ? '.5em' : '0' }} justifyContent='space-between' xs={null} sm={null} md={null} lg={6}>

            {/* Artifact Type Chip */}
            <Grid item container diretion='row'>
              <Chip label='Learning Experience Design' size='small' style={{ backgroundColor: theme.palette.pink.dark, color: 'white', fontWeight: 600 }} />
            </Grid>

            {/* Artifact Content Preview */}
            <Grid item container diretion='column'>
              {/* Artifact title */}
              <Grid item container style={{ margin: '0 0 .3em 0' }}>
                <Tooltip
                  placement='top'
                  title={title && title.length > 40 ? (
                    <Typography>{title}</Typography>
                  ) : ''}
                >
                  <Typography variant='h3' style={{ color: theme.palette.purple.darkest, fontWeight: 600, fontSize: 28, wordBreak: 'break-word' }}>{truncateText(title, smScreen ? 22 : 40)}</Typography>
                </Tooltip>
              </Grid>

              {/* Small Screen Artifact Detail Chips */}
              {mdScreen &&
                <div style={{ display: 'flex', margin: '.3em 0', flexWrap: 'wrap' }}>
                  {chipArr.map(chip => {
                    const { chipIcon = '', chipText = '', chipType = '' } = chip
                    return (
                      <Chip key={chipType} label={chipText} size='small' icon={chipIcon} classes={{ root: classes.chipRoot, iconSmall: classes.chipIcon }} />
                    )
                  })}
                </div>}

              {/* Artifact Overview */}
              <Grid item container direction='column'>
                <Grid item>
                  <Typography variant='body1' style={{ color: '#4f4f56', fontWeight: 600 }}>Overview and Purpose:</Typography>
                </Grid>
                <Grid item>
                  <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: 400, wordBreak: 'break-word' }}>
                    {truncateText(overview, smScreen ? 60 : 125)}
                  </Typography>
                </Grid>
              </Grid>

              {/* Artifact Tech Needed */}
              <Grid item container direction='column' style={{ margin: '.3em 0' }}>
                <Grid item>
                  <Typography variant='body1' style={{ color: '#4f4f56', fontWeight: 600 }}>Technology Needed:</Typography>
                </Grid>
                <Grid item>
                  <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: 400, wordBreak: 'break-word' }}>
                    {truncateText(techNeeded, smScreen ? 45 : 75)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* Artifact Author Information */}
            <Grid item container direction='row' alignItems='center' style={{ margin: '.6em 0 .2em 0' }}>
              {/* Avatar */}
              <Grid item xs={3} sm={2} container>
                <Avatar src={profileAvatarPath} style={{ height: 40, width: 40 }} />
              </Grid>
              {/* Name and school or District Information */}
              <Grid item xs={9} sm={10} container direction='column'>
                <Grid item>
                  <Typography variant='h4' style={{ textTransform: 'none', color: 'black', lineHeight: 1, fontSize: 16 }}>{truncateText(fullName, smScreen ? 35 : 45)}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Right Side HIDE ON SMALL SCREEN */}
          {!mdScreen &&
            <Grid
              item
              container
              direction='column'
              xs={null}
              sm={null}
              md={6}
              lg={6}
              justifyContent='center'
              alignItems='center'
              style={{ padding: '1em 0' }}
            >
              <Paper style={{ padding: '1em' }}>
                <Grid container direction='column'>
                  {/* Top Row */}
                  <Grid item container direction='row' justifyContent='center' style={{ padding: '0 .5em' }}>
                    {/* Subjects */}
                    <Grid item container direction='column' alignItems='center' style={{ padding: '.5em 1.5em .5em 1em' }} xs={6}>
                      <Grid item>
                        <SchoolRounded style={{ fontSize: '32px', padding: '.2em', backgroundColor: theme.palette.purple.lighter, color: theme.palette.purple.darkest, borderRadius: '50%' }} />
                      </Grid>
                      <Grid item>
                        <Typography variant='h6' style={{ color: 'black', textTransform: 'none', fontWeight: 600, textAlign: 'center' }}>{truncateText(subjectName, 18)}</Typography>
                      </Grid>
                    </Grid>
                    {/* Grades */}
                    <Grid item container direction='column' alignItems='center' style={{ padding: '.5em 1em .5em 1.5em' }} xs={6}>
                      <Grid item>
                        <LocalLibraryRounded style={{ fontSize: '32px', padding: '.2em', backgroundColor: theme.palette.purple.lighter, color: theme.palette.purple.darkest, borderRadius: '50%' }} />
                      </Grid>
                      <Grid item>
                        <Typography variant='h6' style={{ color: 'black', textTransform: 'none', fontWeight: 600, textAlign: 'center' }}>{GradesCheck(grades, gradesArray)}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Bottom Row */}
                  <Grid item container direction='row' justifyContent='center' style={{ padding: '.5em' }}>
                    <Grid item container direction='column' alignItems='center' style={{ padding: '.5em 1.5em 0 1em' }} xs={6}>
                      {/* Work Type */}
                      <Grid item>
                        <PersonRounded style={{ fontSize: '32px', padding: '.2em', backgroundColor: theme.palette.purple.lighter, color: theme.palette.purple.darkest, borderRadius: '50%' }} />
                      </Grid>
                      <Grid item>
                        <Typography variant='h6' style={{ color: 'black', textTransform: 'capitalize', fontWeight: 600, textAlign: 'center' }}>{!workType ? 'N/A' : workType}</Typography>
                      </Grid>
                    </Grid>
                    <Grid item container direction='column' alignItems='center' style={{ padding: '.5em 1em 0 1.5em' }} xs={6}>
                      {/* Work Time */}
                      <Grid item>
                        <QueryBuilderRounded style={{ fontSize: '32px', padding: '.2em', backgroundColor: theme.palette.purple.lighter, color: theme.palette.purple.darkest, borderRadius: '50%' }} />
                      </Grid>
                      <Grid item>
                        <Typography variant='h6' style={{ color: 'black', textTransform: 'none', fontWeight: 600, textAlign: 'center' }}>{`${completionTime} Week${completionTime === 1 ? '' : 's'}`}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>}

        </Grid>
      </Grid>
    </Paper>
  )
}

export default withRouter(LEDExploreCard)
