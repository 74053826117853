import { gradeNameCategories as gradeCategories } from '../../../utils'

// Uses a mapping object to check which category an artifact's grade(s) fall under (Pre-K, Kindergarten, Elementary, Middle, etc...) based on the grade name
const GradesCheck = (grades, gradesArray) => {
  if (grades) {
    const keys = Object.keys(gradeCategories)
    const currentGradeCategories = []

    if (gradesArray && gradesArray.length) {
      if (gradesArray.length === 1) {
        return gradesArray[0]
      }

      keys.forEach(key => {
        if (Array.isArray(gradeCategories[key])) {
          if (gradeCategories[key].some(x => gradesArray.includes(x))) {
            currentGradeCategories.push(key)
          }
        }
      })

      // If all the grades fall under only one category, return that, otherwise if there are mixed grade categories return 'multiple grades'
      if (currentGradeCategories.length) {
        if (currentGradeCategories.length === 1) {
          if (currentGradeCategories.includes('Pre_School')) {
            return currentGradeCategories[0].replace(/_/g, '-')
          } else {
            return currentGradeCategories[0].replace(/_/g, ' ')
          }
        } else {
          return 'Multiple Grades'
        }
      }
    }
  }

  return 'N/A'
}

export default GradesCheck
