import React from 'react'
import { useSelector } from 'react-redux'
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography,
  Select, IconButton, Button, useTheme, MenuItem
} from '@material-ui/core'
import {
  CloseRounded
} from '@material-ui/icons'

const MCEExistingLEDModal = (props) => {
  const {
    classes = {}, existingLEDOpen = false, handleCloseExisting = () => {}, selectedExistingLED = '', setSelectedExistingLED = () => {},
    handleFetchArtifactDetails = () => {}
  } = props

  const theme = useTheme()
  const { artifactList = [] } = useSelector(state => state.artifacts)

  return (
    <Dialog
      open={existingLEDOpen}
      fullWidth
      maxWidth='sm'
      onClose={() => { handleCloseExisting() }}
    >
      <DialogTitle disableTypography>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography
              variant='h4'
              style={{ textTransform: 'none', color: theme.palette.purple.darkest, fontSize: '1.3rem' }}
            >
              Choose Existing LED
            </Typography>
          </Grid>

          <Grid item>
            <IconButton style={{ padding: '0px' }} onClick={(e) => { e.preventDefault(); handleCloseExisting() }}>
              <CloseRounded className={classes.customizedButton} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ paddingTop: 0 }}>
        <Grid container item direction='column'>
          <Typography>Use the menu below to select one of your existing Learning Experience Designs to use as base for your work sample upload. You will have the opportunity to make changes to the artifact after your selection.</Typography>
        </Grid>
        <Grid item container direction='row' style={{ margin: '1em 0' }}>
          <Select
            fullWidth
            variant='outlined'
            margin='dense'
            displayEmpty
            defaultValue=''
            value={selectedExistingLED}
            onChange={(e) => setSelectedExistingLED(e.target.value)}
            renderValue={(selected) => !selected ? 'Choose artifact...' : selected.title}

          >
            <MenuItem value='' disabled>Choose artifact...</MenuItem>
            {artifactList && artifactList.length &&
              artifactList.map(artifact => {
                const { artifactID = '', title = '' } = artifact
                return (
                  <MenuItem key={artifactID} value={artifact}>{title}</MenuItem>
                )
              })}
          </Select>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item container direction='row' justifyContent='flex-end'>
          <Button
            variant='text'
            style={{ color: theme.palette.purple.darkest, fontWeight: 600, textTransform: 'none', marginRight: '1em' }}
            onClick={(e) => { e.preventDefault(); handleCloseExisting() }}
          >
            Cancel
          </Button>

          <Button
            variant='contained'
            disabled={!selectedExistingLED}
            size='small'
            style={{ backgroundColor: theme.palette.purple.darkest, color: 'white', fontWeight: 600, textRansform: 'none' }}
            onClick={(e) => { e.preventDefault(); handleFetchArtifactDetails(selectedExistingLED.artifactID, true) }}
          >
            Select and Continue
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default MCEExistingLEDModal
