import Swal from 'sweetalert2'

const toastConfig = Swal.mixin({
  toast: true,
  position: 'top',
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})
export const Toast = (error, message, question = false) => {
  if (question !== true) {
    toastConfig.fire({
      icon: error ? 'error' : 'success',
      title: message
    })
  } else {
    toastConfig.fire({
      icon: 'warning',
      title: message
    })
  }
}

const notificationToastConfig = Swal.mixin({
  toast: true,
  position: 'top',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})
export const NotificationToast = (error, message, question = false) => {
  if (question !== true) {
    notificationToastConfig.fire({
      icon: error ? 'error' : 'success',
      title: message,
      iconColor: error ? undefined : '#4C5788'
    })
  } else {
    notificationToastConfig.fire({
      icon: 'warning',
      title: message
    })
  }
}

const loginToastConfig = Swal.mixin({
  toast: true,
  position: 'top',
  showConfirmButton: false,
  timer: 4000
})
export const LoginToast = () => {
  loginToastConfig.fire({
    icon: 'error',
    title: 'Token expired, please log in again.'
  })
}

export const SSOLoginToast = (message) => {
  loginToastConfig.fire({
    icon: 'error',
    title: !message ? 'Something went wrong. Please try again.' : message
  })
}

const baseToastConfig = Swal.mixin({
  showConfirmButton: true
})
export const BaseToast = async (error, message, text, question = false) => {
  if (question) {
    return baseToastConfig.fire({
      icon: 'warning',
      title: message,
      text: text,
      confirmButtonText: 'Okay'
    })
  } else {
    return baseToastConfig.fire({
      icon: error ? 'error' : 'success',
      title: message,
      text: text,
      confirmButtonText: 'Okay'
    })
  }
}

export const ConfirmToast = async (msg) => {
  const { value, dismiss } = await Swal.fire({
    title: msg.msg,
    text: msg.txt ? msg.txt : '',
    icon: 'question',
    showCancelButton: true,
    cancelButtonText: 'Quit',
    cancelButtonColor: '#97b557',
    confirmButtonText: 'Confirm'
  })
  if (value) {
    return true
  } else if (dismiss) {
    return false
  }
}
