import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { isEqual } from 'lodash'
import clsx from 'clsx'

import {
  Grid, Typography, useTheme, FormGroup, FormControlLabel, Checkbox, Button, OutlinedInput, Paper, Tooltip, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, TableSortLabel, IconButton,
  Select, MenuItem
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import { DeleteOutline, SearchRounded } from '@material-ui/icons'

import { getFeaturedLEDOptions, updateLEDActiveStatus } from '../../../../redux/actions/admin'
import { ConfirmToast } from '../../tools'
import { featuredLEDActions, artifactTypes } from '../../../../utils'

const FeaturedLEDs = (props) => {
  const { classes, featuredLEDs, totalLEDs, filter, setFilter, defaultFilter, activeButton } = props
  const theme = useTheme()
  const dispatch = useDispatch()

  /* removeSelected: adminSelected AND activeFeatured false
  selectedOnly: adminSelected true, activeFeatured false
  activateSelected: activeFeatured true (only for LEDs where adminSelected already true) */

  const { removeSelected, selectedOnly, activateSelected } = featuredLEDActions

  useEffect(() => {
    if (filter && defaultFilter && activeButton === 'featured-leds') {
      if (!isEqual(filter, defaultFilter)) {
        dispatch(getFeaturedLEDOptions(filter))
      }
    }
  }, [dispatch, filter, defaultFilter, activeButton])

  const [rows, setRows] = useState([])
  // Take the featuredLEDs array and format it into the data rows
  useEffect(() => {
    if (featuredLEDs) {
      if (featuredLEDs.length) {
        const newRows = []
        featuredLEDs.forEach(led => {
          newRows.push(led)
        })
        setRows(newRows)
      } else {
        setRows([])
      }
    }
  }, [featuredLEDs])

  const headCells = [
    { id: 'title', label: 'LED Name', align: 'left' },
    { id: 'userFullName', label: 'Created By', align: 'left' },
    { id: 'createdAt', label: 'Date Created', align: 'left' },
    { id: 'activeFeatured', label: 'Active', align: 'left' }
  ]

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const handleChangePage = (event, value) => {
    if (value >= 0) {
      setPage(value - 1)
      setFilter({
        ...filter,
        sortCount: rowsPerPage,
        page: value
      })
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: event.target.value
    })
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalLEDs - page * rowsPerPage)

  // Success func fired when removing featuredLED from the table (completely)
  const fireRemoveSuccess = (artifactID) => {
    // Remove selected artifact from list of LED's
    const tempLEDs = [...rows].filter((artifactInfo) => artifactInfo.artifactID !== artifactID)

    // Set the rows to the new list
    setRows(tempLEDs)

    const tempFilter = { ...filter }

    // If removing the artifact takes length to 0; need to change the page
    if (!tempLEDs.length) {
      const newPageValue = page > 0 ? page : 1
      setTimeout(() => {
        // Give half second for ES and SQL to update
        handleChangePage(null, newPageValue)
      }, 500)
    } else {
      // Otherwise, trigger page to rerender in order to update pagination numbers
      setTimeout(() => {
        // Give half second for ES and SQL to update
        setFilter(tempFilter)
      }, 500)
    }
  }

  const fireToggleActivateSuccess = (artifactID, activeValue) => {
    const newRows = [...rows]

    // Get index of updated LED
    const artifactIndex = newRows.findIndex(x => x.artifactID === artifactID)
    const oldRow = newRows[artifactIndex]
    const newRow = { ...oldRow, activeFeatured: activeValue }

    // Set newRows to include row with updated "activeFeatured" value
    newRows[artifactIndex] = newRow
    setRows(newRows)
  }

  const handleRemoveSelected = async (artifactID, activeFeatured) => {
    const toastMessage = {
      msg: 'Are you sure you want to remove this artifact from the list of nominated LED\'s?',
      txt: 'This will not delete the artifact.'
    }
    const confirm = await ConfirmToast(toastMessage)

    if (confirm) {
      dispatch(updateLEDActiveStatus(artifactID, removeSelected, fireRemoveSuccess))
    }
  }

  const handleActivateSelected = (artifactID, activeFeatured) => {
    if (activeFeatured) {
      dispatch(updateLEDActiveStatus(artifactID, selectedOnly, fireToggleActivateSuccess))
    } else if (!activeFeatured) {
      dispatch(updateLEDActiveStatus(artifactID, activateSelected, fireToggleActivateSuccess))
    }
  }

  const [orderBy, setOrderBy] = useState('')
  const [order, setOrder] = useState('')

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: rowsPerPage,
      sortDirection: isAsc ? 'desc' : 'asc',
      sortType: property
    })
  }

  const [searchInput, setSearchInput] = useState('')

  const handleSearch = (e) => {
    setSearchInput(e.target.value)
    if (searchInput && searchInput.length >= 3) {
      // Previously, issues were occuring if the user was on a page that was greater than 1 and made a search. The page is now being reset beforehand to avoid that.
      setPage(0)
      setFilter({
        ...filter,
        page: 1,
        sortCount: rowsPerPage,
        searchPhrase: searchInput
      })
    }

    if (e.target.value === '') {
      setPage(0)
      // Set the sortCount to current rowsPerPage instead of the default
      setFilter({
        ...defaultFilter,
        sortCount: rowsPerPage
      })
    }
  }

  // Handles routing the user to a public/read only view of an artifact
  const handleLEDPublicView = (artifact) => {
    const { artifactID, profileID } = artifact
    props.history.push({
      pathname: '/artifact',
      search: `?public=${artifactTypes.LED}&user=${profileID}&artifact=${artifactID}`
    })
  }

  // Handles routing the user to the LED author's profile
  const handleAuthorProfileView = (profileID) => {
    props.history.push({
      pathname: '/profile',
      search: `?user=${profileID}`
    })
  }

  return (
    <Grid container direction='column'>
      <Grid item container style={{ marginBottom: '2em' }}>
        {/* Page Title */}
        <Grid item container xs={9} justifyContent='flex-start'>
          <Typography variant='h4' style={{ fontSize: '20px' }}>Featured Learning Experience Designs</Typography>
        </Grid>
      </Grid>

      <Grid item container direction='row' style={{ marginBottom: '1em' }}>
        <Grid item container aligncontent='flex-end' xs={7}>
          <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none', fontWeight: 400, fontSize: '16px' }}>
            Displaying {totalLEDs === 0 ? 0 : (page * rowsPerPage) + 1} to {rows && page * rowsPerPage + rowsPerPage > totalLEDs ? totalLEDs : page * rowsPerPage + rowsPerPage} of {totalLEDs}
          </Typography>
        </Grid>
        <Grid>
          <OutlinedInput
            size='small'
            margin='dense'
            inputProps={{ style: { border: 'none', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '5px' } }}
            placeholder='Search...'
            classes={{ input: classes.inputPlaceholder }}
            startAdornment={
              <SearchRounded style={{ color: theme.palette.grey.dark, fontSize: '20px' }} />
            }
            onChange={e => handleSearch(e)}
          />
        </Grid>
      </Grid>

      <Grid item container direction='column' style={{ marginBottom: '1em' }}>
        <TableContainer elevation={0} style={{ border: `solid 1px ${theme.palette.grey.medium}` }} component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: theme.palette.grey.lighter }}>
              <TableRow>
                {headCells.map(header => {
                  return (
                    <TableCell
                      key={header.id}
                      align={header.align}
                      style={{ padding: '16px' }}
                    >
                      <TableSortLabel
                        active={orderBy === header.id}
                        direction={orderBy === header.id ? order : 'asc'}
                        onClick={() => handleRequestSort(header.id)}
                      >
                        <Typography variant='h5' style={{ color: theme.palette.grey.dark }}>{header.label}</Typography>
                        {orderBy === header.id ? (
                          <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  )
                })}
                <TableCell align='left' style={{ padding: '16px' }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow key={`index-${row.artifactID}`}>
                    <TableCell>
                      <Grid item container direction='row' alignItems='center'>
                        <Tooltip
                          placement='top'
                          title={row.title && row.title.length > 25 ? (
                            <Typography>{row.title}</Typography>
                          ) : ''}
                        >
                          <Button
                            variant='text'
                            color='primary'
                            style={{ textTransform: 'none', textDecoration: 'underline', fontWeight: 600, textAlign: 'left' }}
                            onClick={() => handleLEDPublicView(row)}
                          >
                            {row.title && row.title.length > 25 ? `${row.title.substring(0, 25)}...` : row.title}
                          </Button>
                        </Tooltip>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid item container direction='row' alignItems='center'>
                        <Button
                          variant='text'
                          color='primary'
                          onClick={() => handleAuthorProfileView(row.profileID)}
                          style={{ textTransform: 'none', fontWeight: 600, textAlign: 'left' }}
                        >
                          {row.fullName}
                        </Button>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body1' style={{ fontWeight: 400 }}>{moment.unix(row.createdAt).format('MM/DD/YYYY')}</Typography>
                    </TableCell>
                    <TableCell align='center'>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Tooltip title={`${row.activeFeatured ? 'Remove from' : 'Add to'} featured section of Explore`}>
                              <Checkbox
                                size='small'
                                disableRipple
                                checked={row.activeFeatured}
                                icon={<span className={classes.icon} />}
                                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                              />
                            </Tooltip>
                          }
                          onChange={() => handleActivateSelected(row.artifactID, row.activeFeatured)}
                          classes={{ label: classes.checkboxLabel }}
                          style={{ marginBottom: '.2em' }}
                        />
                      </FormGroup>
                    </TableCell>
                    <TableCell align='center'>
                      <IconButton
                        className={classes.editIcon}
                        style={{ color: 'red' }}
                        onClick={() => handleRemoveSelected(row.artifactID, row.activeFeatured)}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 10 }}>
                  <TableCell colSpan={5}>
                    <Typography variant='body1' style={{ color: theme.palette.grey.medium, textAlign: 'center' }}>{totalLEDs === 0 ? 'No Results' : 'End of List'}</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* Bottom Pagination Controls */}
      <Grid item container direction='row' style={{ marginBottom: '4em' }}>
        {/* Rows per Page Selection */}
        <Grid item container direction='row' xs={6} justifyContent='flex-start'>
          <Grid item style={{ marginRight: '.2em', display: 'flex', alignItems: 'flex-end' }}>
            <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>Display</Typography>
          </Grid>
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <Select
              variant='outlined'
              size='small'
              defaultValue={10}
              value={rowsPerPage}
              onChange={(e) => handleChangeRowsPerPage(e)}
              classes={{ root: classes.searchInput, selectMenu: classes.statusSelect }}
              style={{ padding: 0 }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </Grid>
          <Grid item style={{ marginLeft: '.4em', display: 'flex', alignItems: 'flex-end' }}>
            <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>entries</Typography>
          </Grid>
        </Grid>
        {/* Pagination/Page Selection */}
        <Grid item container xs={6} justifyContent='flex-end'>
          <Pagination
            color='primary'
            classes={{ root: classes.pagination }}
            count={totalLEDs ? Math.ceil(totalLEDs / rowsPerPage) : 0}
            page={page === 0 ? 1 : page + 1}
            onChange={handleChangePage}
            shape='rounded'
          />
        </Grid>
      </Grid>
    </Grid>

  )
}

export default withRouter(FeaturedLEDs)
