import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { isEqual } from 'lodash'
import {
  Grid, Typography, useTheme, OutlinedInput, Table, TableBody, TableContainer, TableCell, TableHead, TableRow,
  Paper, Button, Select, MenuItem, TableSortLabel
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import { SearchRounded } from '@material-ui/icons'

import { updateApproval, getCohorts } from '../../../redux/actions'
import { NotificationToast } from '../tools'
import { userRoles } from '../../../utils'

const SignUps = (props) => {
  const { classes, defaultFilter, filter, setFilter, pendingUsers, totalPending, getSignUps, activeButton } = props
  const theme = useTheme()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCohorts())
  }, [dispatch])

  // ************* Data Creation Logic *************** //

  // If the filter was changed, fetch the pending Users with the new filter
  useEffect(() => {
    if (filter && defaultFilter && activeButton === 'sign-ups') {
      if (!isEqual(filter, defaultFilter)) {
        dispatch(getSignUps(filter))
      }
    }
  }, [dispatch, filter, defaultFilter, getSignUps, setFilter, activeButton])

  // Data rows for the displayed table
  const [rows, setRows] = useState([])

  // Take the pendingUsers array and format it into the data rows
  useEffect(() => {
    if (pendingUsers) {
      if (pendingUsers.length) {
        const newRows = pendingUsers.reduce((arr, userInfo = {}) => {
          if (userInfo && userInfo.userID) {
            const { userID, name, emailAddress: email, pendingSince } = userInfo
            const pendingDate = moment(pendingSince * 1000).format('L')

            arr.push({ userID, name, email, pendingDate })
          }
          return arr
        }, [])
        setRows(newRows)
      } else {
        setRows([])
      }
    }
  }, [pendingUsers])

  // Column Headers
  const headCells = [
    { id: 'name', label: 'Name' },
    { id: 'emailAddress', label: 'Email' },
    { id: 'createdAt', label: 'Pending Since' },
    { id: 'roleName', label: 'Role Assignment' }
  ]

  // ************* Pagination Logic: *****************//
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const handleChangePage = (event, value) => {
    if (value >= 0) {
      setPage(value - 1)
      setFilter({
        ...filter,
        sortCount: rowsPerPage,
        page: value
      })
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: event.target.value
    })
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalPending - page * rowsPerPage)

  // ******************** Column Sort Logic ********************** //

  const [orderBy, setOrderBy] = useState('')
  const [order, setOrder] = useState('desc')

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: rowsPerPage,
      sortDirection: isAsc ? 'DESC' : 'ASC',
      sortType: property
    })
  }

  // ********************** Search Logic *********************** //
  const [searchInput, setSearchInput] = useState('')

  const handleSearch = (e) => {
    setSearchInput(e.target.value)
    if (searchInput && searchInput.length >= 3) {
      // Previously, issues were occuring if the user was on a page that was greater than 1 and made a search. The page is now being reset beforehand to avoid that.
      setPage(0)
      setFilter({
        ...filter,
        page: 1,
        sortCount: rowsPerPage,
        searchPhrase: searchInput
      })
    }

    if (e.target.value === '') {
      setPage(0)
      // Set the sortCount to current rowsPerPage instead of the default
      setFilter({
        ...defaultFilter,
        sortCount: rowsPerPage
      })
    }
  }

  // ********************** Update Status Logic ********************** //

  // ******** An array of { userID: '', roleKey: '' } to help the select components to behave indepently by associating a particular status to a particular userID
  const [selectedRoles, setSelectedRoles] = useState([])

  // ******** This checks to see if the admin is adding, updating, or removing a role assignment and updating the selectedRoles to match
  const handleSetUserRole = (id, key) => {
    const tempRoleList = [...selectedRoles]

    // --- See if anyone is in the list at all
    if (tempRoleList.length) {
      // - Look for the user in the current role list
      const userIndex = tempRoleList.findIndex(x => x.userID === id)

      // -- If the user is in the list already
      if (userIndex !== -1) {
        // - check if removing or changing role
        if (!key || key === '') {
          // - admin is unassigning the user a role, so remove them from the array
          tempRoleList.slice(userIndex, 1)
        } else {
          // - the admin is changing the current selection for that user

          // - Be sure the supplied key exists
          if (!Object.keys(userRoles).includes(key)) {
            return console.warn('No such role')
          } else {
            // as long as the key exists, re-assign the role for that user
            tempRoleList[userIndex].roleKey = key
          }
        }
      } else {
        // - The user is not in the list so add them and the selected role
        tempRoleList.push({ userID: id, roleKey: key })
      }
    } else {
      // --- No one is in the list so go ahead and add the user and selected role
      tempRoleList.push({ userID: id, roleKey: key })
    }
    setSelectedRoles(tempRoleList)
  }

  const fireSuccess = (fellowID) => {
    dispatch(getSignUps({ ...filter }))
    NotificationToast(false, 'User\'s Status successfully updated.')

    const tempUsers = [...rows].filter((userInfo) => userInfo.userID !== fellowID)
    const tempSelectedRoles = [...selectedRoles].filter((roleInfo) => roleInfo.userID !== fellowID)

    setRows(tempUsers)
    setSelectedRoles(tempSelectedRoles)
  }

  const fireFailure = () => {
    return NotificationToast(true, 'Unable to Update User\'s Approval Status', true)
  }

  // Format the selected information for the user and dispatch
  const handleUserAssignment = (status, fellowID) => {
    // Use the selected Roles array to find the right role to assign to the user
    const userRoleIndex = selectedRoles.findIndex(x => x.userID === fellowID)

    // There is no assigned role when attempting to approve a user
    if (userRoleIndex === -1 && status) {
      return NotificationToast(true, 'User must be assigned a role to be approved', true)
    } else {
      // Grad that user's assigned role, or if they are being denied set it to a blank string
      const userRoleKey = status ? selectedRoles[userRoleIndex].roleKey : ''

      const approvalInfo = {
        fellowID,
        approvalStatus: status,
        roleKey: userRoleKey
      }

      dispatch(updateApproval(approvalInfo, fireSuccess, fireFailure))
    }
  }

  /* Returns the needed value to correctly display the selected choice for a particular user in the Select Component
  (this allows the components in each row to behave independently) */
  const getUserSelectDisplay = (id) => {
    if (selectedRoles && selectedRoles.length) {
      const userIndex = selectedRoles.findIndex(x => x.userID === id)

      if (userIndex !== -1) {
        return selectedRoles[userIndex].roleKey
      }
      return ''
    } else {
      return ''
    }
  }

  return (
    <Grid container direction='column'>
      <Grid item container style={{ marginBottom: '2em' }}>
        {/* Page Title */}
        <Typography variant='h4' style={{ fontSize: '20px' }}>Sign Ups</Typography>
      </Grid>

      {/* Top Pagination display and search input */}
      <Grid item container direction='row' style={{ marginBottom: '1em' }}>
        <Grid item container alignContent='flex-end' xs={7}>
          <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none', fontWeight: 400, fontSize: '16px' }}>
            Displaying {totalPending === 0 ? 0 : (page * rowsPerPage) + 1} to {rows && page * rowsPerPage + rowsPerPage > totalPending ? totalPending : page * rowsPerPage + rowsPerPage} of {totalPending}
          </Typography>
        </Grid>
        <Grid item container justifyContent='flex-end' xs={5}>
          <OutlinedInput
            className={classes.searchInput}
            size='small'
            margin='dense'
            fullWidth
            inputProps={{ style: { border: 'none', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '5px' } }}
            placeholder='Search...'
            classes={{ input: classes.inputPlaceholder }}
            startAdornment={
              <SearchRounded style={{ color: theme.palette.grey.dark, fontSize: '20px' }} />
            }
            value={searchInput}
            onChange={(e) => handleSearch(e)}
          />
        </Grid>
      </Grid>

      {/* Data Table */}
      <Grid item container direction='column' style={{ marginBottom: '1em' }}>
        <TableContainer elevation={0} style={{ border: `solid 1px ${theme.palette.grey.medium}` }} component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: theme.palette.grey.lighter }}>
              <TableRow>
                {headCells.map(header => {
                  return (
                    <TableCell
                      key={header.id}
                      align='left'
                      style={{ padding: '16px' }}
                      sortDirection={orderBy === header.id ? 'asc' : false}
                    >
                      <TableSortLabel
                        active={orderBy === header.id}
                        direction={orderBy === header.id ? order : 'asc'}
                        onClick={() => handleRequestSort(header.id)}
                      >
                        <Typography variant='h5' style={{ color: theme.palette.grey.dark }}>{header.label}</Typography>
                        {orderBy === header.id ? (
                          <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  )
                })}
                <TableCell align='left' style={{ padding: '16px' }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow key={`index-${row.userID}`}>
                    <TableCell>
                      <Typography variant='body1' style={{ fontWeight: 600 }}>{row.name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body1'>{row.email}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body1'>{row.pendingDate}</Typography>
                    </TableCell>
                    <TableCell>
                      <Select
                        variant='outlined'
                        // id={row.userID}
                        margin='dense'
                        fullWidth
                        displayEmpty
                        classes={{ root: classes.searchInput, selectMenu: classes.statusSelect }}
                        value={getUserSelectDisplay(row.userID)}
                        onChange={(e) => handleSetUserRole(row.userID, e.target.value)}
                        style={{ padding: 0 }}
                        defaultValue=''
                        // renderValue={(selected) => !selected || selected}
                      >
                        <MenuItem value='' disabled>Choose User Role...</MenuItem>
                        {userRoles && Object.keys(userRoles).length &&
                          Object.entries(userRoles).map(([key, role]) => {
                            return (
                              <MenuItem key={`role-${key}`} value={key}>{role}</MenuItem>
                            )
                          })}
                      </Select>
                    </TableCell>
                    <TableCell align='right'>
                      <Grid item container direction='row' justifyContent='space-between'>
                        <Button
                          variant='outlined'
                          className={classes.aprroveButton}
                          onClick={() => handleUserAssignment(true, row.userID)}
                        >
                          Approve
                        </Button>
                        <Button
                          variant='outlined'
                          className={classes.denyButton}
                          onClick={() => handleUserAssignment(false, row.userID)}
                        >
                          Deny
                        </Button>
                      </Grid>
                    </TableCell>
                  </TableRow>
                )
              })}
              {/* When the array has reached it's length: */}
              {emptyRows > 0 && (
                <TableRow style={{ height: 10 }}>
                  <TableCell colSpan={4}>
                    <Typography variant='body1' style={{ color: theme.palette.grey.medium, textAlign: 'center' }}>End of List</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* Bottom Pagination Controls */}
      <Grid item container direction='row' style={{ marginBottom: '4em' }}>
        {/* Rows per Page selection */}
        <Grid item container direction='row' xs={6} justifyContent='flex-start'>
          <Grid item style={{ marginRight: '.2em', display: 'flex', alignItems: 'flex-end' }}>
            <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>Display</Typography>
          </Grid>
          <Grid item style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Select
              variant='outlined'
              size='small'
              defaultValue={10}
              value={rowsPerPage}
              onChange={(e) => handleChangeRowsPerPage(e)}
              classes={{ root: classes.searchInput, selectMenu: classes.statusSelect }}
              style={{ padding: 0 }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </Grid>
          <Grid item style={{ marginLeft: '.4em', display: 'flex', alignItems: 'flex-end' }}>
            <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>entries</Typography>
          </Grid>
        </Grid>
        {/* Pagination/Pages selection */}
        <Grid item container xs={6} justifyContent='flex-end'>
          <Pagination
            color='primary'
            classes={{ root: classes.pagination }}
            count={totalPending ? Math.ceil(totalPending / rowsPerPage) : 0}
            page={page === 0 ? 1 : page + 1}
            onChange={handleChangePage}
            shape='rounded'
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SignUps
