import {
  GET_ARTIFACTS, GET_USER_LEDS, GET_USER_LEARNER_WORK,
  SET_PROFILE_PATH, SET_TARGET_ARTIFACT_LED, SET_TARGET_ARTIFACT_LW,
  RESET_TARGET_ARTIFACT_LED, RESET_TARGET_ARTIFACT_LW, RESET_TARGET_ARTIFACT_PRO,
  SET_LED_OPTIONS, SET_LW_OPTIONS, SET_TARGET_ARTIFACT_PRO,
  SET_ARTIFACT_LIKES, SET_ARTIFACT_SAVES, SET_ARTIFACT_DUPLICATES,
  SET_ARTIFACT_COMMENT_LIST, SET_TARGET_ARTIFACT_EFR, RESET_TARGET_ARTIFACT_EFR, GET_STANDARDS_LIST, LOGOUT, UPDATE_BANNER_IMAGE
} from '../types'

import { defaultTargetArtifactLED, defaultTargetArtifactLW, defaultTargetArtifactPRO, defaultTargetArtifactEFR } from '../../utils/variables'

// UI Tech Debt: Removed UPDATE_TARGET_ARTIFACT_LED, UPDATE_TARGET_ARTIFACT_LW, and UPDATE_TARGET_ARTIFACT_PRO
// UI Tech Debt: moved standards into artifacts reducer and deleted it's respective file

const initialState = {
  artifactList: [],
  artifactTotals: {
    ledCount: 0,
    lwCount: 0,
    proCount: 0,
    resourceCount: 0
  },
  userLWOptions: [],
  userLEDOptions: [],
  ledList: [],
  learnerWorkList: [],
  targetArtifactLED: defaultTargetArtifactLED,
  targetArtifactLW: defaultTargetArtifactLW,
  targetArtifactPRO: defaultTargetArtifactPRO,
  targetArtifactEFR: defaultTargetArtifactEFR,
  profilePath: '',
  artifactLikeList: [],
  artifactSaveList: [],
  artifactDuplicateList: [],
  artifactCommentList: [],
  standards: []
}

const artifactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ARTIFACTS: return {
      ...state,
      artifactList: action.payload.artifacts,
      artifactTotals: action.payload.artifactTotals
    }
    case SET_TARGET_ARTIFACT_LED: return {
      ...state,
      targetArtifactLED: {
        actContent: action.payload.actContent,
        createContent: action.payload.createContent,
        engageContent: action.payload.engageContent,
        forkedFrom: action.payload.forkedFrom,
        investigateContent: action.payload.investigateContent,
        overview: action.payload.overview,
        secondarySubjectID: action.payload.secondarySubjectID,
        secondarySubjectName: action.payload.secondarySubjectName,
        completionTime: action.payload.completionTime,
        coreSubjectID: action.payload.coreSubjectID,
        coreSubjectName: action.payload.coreSubjectName,
        createdAt: action.payload.createdAt,
        isDraft: action.payload.isDraft,
        isForSubmission: action.payload.isForSubmission,
        artifactID: action.payload.artifactID,
        learnerWorkID: action.payload.learnerWorkID,
        artifactType: action.payload.artifactType,
        isPrivate: action.payload.isPrivate,
        stateStandards: action.payload.stateStandards,
        techNeeded: action.payload.techNeeded,
        title: action.payload.title,
        authorID: action.payload.authorID,
        workType: action.payload.workType,
        grades: action.payload.grades,
        targets: action.payload.targets,
        ISTEStandards: action.payload.ISTEStandards,
        attachments: action.payload.attachments,
        links: action.payload.links,
        commentList: action.payload.commentList,
        submissionStatus: action.payload.submissionStatus,
        unviewedComments: action.payload.unviewedComments,
        likeCount: action.payload.likeCount,
        saveCount: action.payload.saveCount,
        publicCommentCount: action.payload.publicCommentCount,
        adminSelected: action.payload.adminSelected,
        activeFeatured: action.payload.activeFeatured,
        duplicates: action.payload.duplicates,
        originalArtifact: action.payload.originalArtifact,
        backgroundKey: action.payload.backgroundKey,
        backgroundObj: action.payload.backgroundObj,
        LEDBackgroundPath: action.payload.LEDBackgroundPath,
        learnerWorkDetails: action.payload.learnerWorkDetails
      }
    }
    case RESET_TARGET_ARTIFACT_LED: return {
      ...state,
      targetArtifactLED: { ...defaultTargetArtifactLED }
    }
    case SET_TARGET_ARTIFACT_LW: return {
      ...state,
      targetArtifactLW: {
        artifactID: action.payload.artifactID,
        authorID: action.payload.authorID,
        learnerWorkTitle: action.payload.learnerWorkTitle,
        workType: action.payload.workType,
        notes: action.payload.notes,
        dateCompleted: action.payload.dateCompleted,
        coreSubjectID: action.payload.coreSubjectID,
        coreSubjectName: action.payload.coreSubjectName,
        isPrivate: action.payload.isPrivate,
        artifactType: action.payload.artifactType,
        grades: action.payload.grades,
        attachments: action.payload.attachments,
        links: action.payload.links,
        commentList: action.payload.commentList,
        submissionStatus: action.payload.submissionStatus,
        unviewedComments: action.payload.unviewedComments,
        ledIDs: action.payload.ledIDs,
        isDraft: action.payload.isDraft,
        isForSubmission: action.payload.isForSubmission,
        likeCount: action.payload.likeCount,
        saveCount: action.payload.saveCount,
        publicCommentCount: action.payload.publicCommentCount
      }
    }
    case RESET_TARGET_ARTIFACT_LW: return {
      ...state,
      targetArtifactLW: { ...defaultTargetArtifactLW }
    }
    case SET_TARGET_ARTIFACT_PRO: return {
      ...state,
      targetArtifactPRO: {
        artifactID: action.payload.artifactID,
        authorID: action.payload.authorID,
        projectTitle: action.payload.projectTitle,
        projectDescription: action.payload.projectDescription,
        projectLength: action.payload.projectLength,
        artifactType: action.payload.artifactType,
        projectLEDs: action.payload.projectLEDs,
        projectLWs: action.payload.projectLWs,
        commentList: action.payload.commentList,
        isPrivate: action.payload.isPrivate,
        submissionStatus: action.payload.submissionStatus,
        unviewedComments: action.payload.unviewedComments,
        isDraft: action.payload.isDraft,
        isForSubmission: action.payload.isForSubmission,
        likeCount: action.payload.likeCount,
        saveCount: action.payload.saveCount,
        publicCommentCount: action.payload.publicCommentCount
      }
    }
    case RESET_TARGET_ARTIFACT_PRO: return {
      ...state,
      targetArtifactPRO: { ...defaultTargetArtifactPRO }
    }
    case SET_TARGET_ARTIFACT_EFR: return {
      ...state,
      targetArtifactEFR: {
        artifactID: action.payload.artifactID,
        authorID: action.payload.authorID,
        artifactType: action.payload.artifactType,
        resourceName: action.payload.resourceName,
        resourceType: action.payload.resourceType,
        resourceAudience: action.payload.resourceAudience,
        resourceLink: action.payload.resourceLink,
        workType: action.payload.workType,
        resourceDescription: action.payload.resourceDescription,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        creatorName: action.payload.creatorName,
        isPrivate: action.payload.isPrivate,
        isForSubmission: action.payload.isForSubmission,
        creatorProfileID: action.payload.creatorProfileID,
        creatorAvatarPath: action.payload.creatorAvatarPath,
        createdAt: action.payload.createdAt,
        updatedAt: action.payload.updatedAt,
        updatedBy: action.payload.updatedBy,
        editorName: action.payload.editorName,
        editorAvatarPath: action.payload.editorAvatarPath,
        editorProfileID: action.payload.editorProfileID,
        bannerImagePath: action.payload.bannerImagePath,
        isDraft: action.payload.isDraft,
        isActive: action.payload.isActive,
        commentList: action.payload.commentList
      }
    }
    case UPDATE_BANNER_IMAGE: return {
      ...state,
      targetArtifactEFR: {
        ...state.targetArtifactEFR,
        bannerImagePath: action.payload
      }
    }
    case RESET_TARGET_ARTIFACT_EFR: return {
      ...state,
      targetArtifactEFR: { ...defaultTargetArtifactEFR }
    }
    case GET_USER_LEDS: return {
      ...state,
      ledList: action.payload
    }
    case GET_USER_LEARNER_WORK: return {
      ...state,
      learnerWorkList: action.payload
    }
    case GET_STANDARDS_LIST: return {
      ...state,
      standards: action.payload
    }
    case SET_PROFILE_PATH: return {
      ...state,
      profilePath: action.payload
    }
    case SET_LW_OPTIONS: return {
      ...state,
      userLWOptions: action.payload
    }
    case SET_LED_OPTIONS: return {
      ...state,
      userLEDOptions: action.payload
    }
    case SET_ARTIFACT_LIKES: return {
      ...state,
      artifactLikeList: action.payload
    }
    case SET_ARTIFACT_SAVES: return {
      ...state,
      artifactSaveList: action.payload
    }
    case SET_ARTIFACT_DUPLICATES: return {
      ...state,
      artifactDuplicateList: action.payload
    }
    case SET_ARTIFACT_COMMENT_LIST: return {
      ...state,
      artifactCommentList: action.payload
    }
    case LOGOUT: return {
      ...initialState,
      error: action.payload.error || false
    }
    default: return state
  }
}

export default artifactsReducer
