import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { chunk } from 'lodash'
import { uploadType, uploadFileFormatter } from '../../../utils'
import { NotificationToast } from '../tools'

import {
  Grid, Typography, Dialog, DialogActions, DialogContent, DialogTitle,
  Button, Avatar, IconButton, Divider, darken, Popover
} from '@material-ui/core'

import { CloseRounded, CameraAltRounded, PersonRounded, SaveRounded, CancelRounded } from '@material-ui/icons'

const TrashIconMod = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='20'
      viewBox='0 0 28 32'
    >
      <path
        id='trash-alt-regular_2_'
        data-name='trash-alt-regular (2)'
        d='M16.75,26h1.5a.75.75,0,0,0,.75-.75V11.75a.75.75,0,0,0-.75-.75h-1.5a.75.75,0,0,0-.75.75v13.5A.75.75,0,0,0,16.75,26ZM27,5H21.849L19.724,1.456A3,3,0,0,0,17.151,0h-6.3A3,3,0,0,0,8.277,1.456L6.151,5H1A1,1,0,0,0,0,6V7A1,1,0,0,0,1,8H2V29a3,3,0,0,0,3,3H23a3,3,0,0,0,3-3V8h1a1,1,0,0,0,1-1V6A1,1,0,0,0,27,5ZM10.74,3.182A.375.375,0,0,1,11.062,3h5.875a.375.375,0,0,1,.322.182L18.351,5h-8.7ZM23,29H5V8H23ZM9.75,26h1.5a.75.75,0,0,0,.75-.75V11.75a.75.75,0,0,0-.75-.75H9.75a.75.75,0,0,0-.75.75v13.5A.75.75,0,0,0,9.75,26Z'
        transform='translate(0 0)'
        fill='#29356a'
      />
    </svg>
  )
}

const EditAvatarModal = (props) => {
  const { classes, theme, userDetails, isOpen, setOpenAvatarModal, handleDispatchSave, navHeight = 0 } = props

  const { defaultAvatars = [] } = useSelector(state => state.profileData)

  // The user's avatar
  const profileAvatar = userDetails && userDetails.profileAvatarPath ? userDetails.profileAvatarPath : ''
  const [newImage, setNewImage] = useState(profileAvatar)

  const [newImageType, setNewImageType] = useState('')
  const [isCustom, setIsCustom] = useState(false)

  // the four modal views
  const contentViewTypes = useMemo(() => ({
    image: 'image',
    emptyImage: 'emptyImage',
    avatar: 'avatar',
    saveOrCancel: 'saveOrCancel'
  }), [])

  // The current view of the modal
  const [contentView, setContentView] = useState(contentViewTypes.image)

  // The content of the button types
  const buttonTypes = useMemo(() => ({
    upload: {
      copy: 'Upload Photo',
      icon: <CameraAltRounded />,
      handlerProp: contentViewTypes.image
    },
    avatar: {
      copy: 'Choose Avatar',
      icon: <PersonRounded />,
      handlerProp: contentViewTypes.avatar
    },
    delete: {
      copy: 'Delete',
      icon: <TrashIconMod />,
      handlerProp: contentViewTypes.emptyImage
    },
    save: {
      copy: 'Save?',
      icon: <SaveRounded />,
      handlerProp: 'save'
    },
    cancel: {
      copy: 'Cancel',
      icon: <CancelRounded />,
      handlerProp: 'cancel'
    }
  }), [contentViewTypes])

  // The content to render the default avatars
  const AvatarContent = () => (
    chunk(defaultAvatars, 6).map((subArr, i) => {
      return (
        <Grid item container direction='row' justifyContent='center' spacing={1} key={`default-avatar-${i}`}>
          {subArr.map((avatarPath, i) => {
            return (
              <Grid item xs={4} sm={2} key={`default-avatar-${i}`}>
                <IconButton
                  style={{
                    padding: theme.spacing(0.5),
                    width: '100%',
                    '&:hover': {
                      backgroundColor: darken('#efefef', 0.10)
                    }
                  }}
                  onClick={() => handleDefaultSelect(avatarPath)}
                >
                  <div
                    style={{
                      backgroundImage: `url(${avatarPath})`,
                      width: '100%',
                      height: 0,
                      paddingTop: '100%',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      borderRadius: '50%'
                    }}
                  />
                </IconButton>
              </Grid>
            )
          })}
        </Grid>
      )
    }))

  // The exact settings for the modal content based on the desired view
  const getContentOptions = (view) => {
    switch (view) {
      case contentViewTypes.emptyImage:
        return ({
          content: <Avatar src='' style={{ height: '9em', width: '9em' }} />,
          buttons: [buttonTypes.upload, buttonTypes.avatar]
        })
      case contentViewTypes.image:
        return ({
          content: <Avatar src={newImage} style={{ height: '9em', width: '9em' }} />,
          buttons: [buttonTypes.upload, buttonTypes.avatar, buttonTypes.delete]
        })
      case contentViewTypes.avatar:
        return ({
          content: <AvatarContent />,
          buttons: [buttonTypes.upload, buttonTypes.delete]
        })
      case contentViewTypes.saveOrCancel:
        return ({
          content: <Avatar src={newImage} style={{ height: '9em', width: '9em' }} />,
          buttons: [buttonTypes.save, buttonTypes.cancel, buttonTypes.delete]
        })
      default: return ({
        content: '',
        buttons: []
      })
    }
  }

  // Based on the current view, decide the spacing of the buttons in the modal actions
  const buttonSpacing = contentView && getContentOptions(contentView).buttons && getContentOptions(contentView).buttons.length && getContentOptions(contentView).buttons.length > 2 ? 4 : 6

  // ****** Popover handlers/state *********** //
  const [deleteAnchorEl, setDeleteAnchorEl] = useState(null)

  const openDeletePopover = Boolean(deleteAnchorEl)

  // Open the popover
  const handleDeletePopover = (e) => {
    setDeleteAnchorEl(e.currentTarget)
  }

  // Reset popover
  const handleDeletePopClose = () => {
    setDeleteAnchorEl(null)
  }

  // Local Error State
  const [fileSizeError, setFileSizeError] = useState(false)
  const [fileExtensionError, setFileExtensionError] = useState(false)

  const fileErrorReset = () => { setFileSizeError(false); setFileExtensionError(false) }

  // Select a default avatar
  const handleDefaultSelect = (path) => {
    if (isCustom) { setIsCustom(false) }
    setNewImage(path)
    setContentView(contentViewTypes.saveOrCancel)
  }

  // Select a custom avatar
  const handleImageUpload = async (e, file) => {
    e.preventDefault()

    // Reset errors on initial upload to clear out previous
    fileErrorReset()

    // error handlers
    const handleFileSizeError = (error) => setFileSizeError(error)
    const handleFileExtensionError = (error) => setFileExtensionError(error)

    const type = uploadType.PROFILE_IMAGE
    const attachments = []
    const imageResult = await uploadFileFormatter(file, type, attachments, handleFileSizeError, handleFileExtensionError)

    if (imageResult) {
      const { imageData, fileExtension } = imageResult
      if (!isCustom) setIsCustom(true)
      setNewImage(imageData)
      setNewImageType(fileExtension)
      setContentView(contentViewTypes.saveOrCancel)
    }
  }

  // Confirm Delete and switch views trigger popover close
  const handleDelete = () => {
    handleDeletePopClose()
    setContentView(contentViewTypes.emptyImage)
    handleSaveImage(true)
  }

  const fireSaveFailure = () => {
    NotificationToast(true, 'Unable to update image, please try again.', true)
  }

  const fireImageSuccess = () => {
    handleModalClose()
    NotificationToast(false, 'Image will update shortly, please refresh if image does not update!', true)
  }

  // Save the new Avatar selection
  const handleSaveImage = (deleteImageBool) => {
    let avatarKey = ''

    const { nickName = '', schoolID = '', schoolDistrictID = '', certList = [] } = userDetails

    if (!deleteImageBool && newImage && !isCustom) {
      const avatarArr = newImage.split('/')
      avatarKey = avatarArr.slice(avatarArr.length - 2).join('/')
    }

    const newImagePath = newImage && isCustom ? newImage : newImage && !isCustom ? avatarKey : userDetails.profileAvatarPath

    const profileInfo = {
      avatar: deleteImageBool ? '' : newImagePath,
      avatarType: deleteImageBool ? '' : newImageType,
      isCustomAvatar: isCustom,
      isAvatarChanged: newImage !== '' || deleteImageBool,
      nickName: nickName,
      schoolID: !schoolID ? '' : schoolID,
      schoolDistrictID: schoolDistrictID,
      certificationsToAdd: [],
      certificationsToDelete: [],
      certifications: certList
    }

    handleDispatchSave(profileInfo, fireImageSuccess, fireSaveFailure)
  }

  // Cancel the new selection and return to the current avatar
  const handleCancel = () => {
    setNewImage(profileAvatar)
    setContentView(contentViewTypes.image)
  }

  // Switch between modal views
  const handleViewChange = (handlerType, e) => {
    switch (handlerType) {
      // Open the Delete Popover
      case contentViewTypes.emptyImage: {
        handleDeletePopover(e)
        break
      }
      // Switch to the default avatars
      case contentViewTypes.avatar: {
        setContentView(handlerType)
        break
      }
      // Save the new Image
      case 'save': {
        handleSaveImage()
        break
      }
      // Cancel the new selection
      case 'cancel': {
        handleCancel()
        break
      }
      default: return () => { }
    }
  }

  const handleModalClose = () => {
    setOpenAvatarModal(false)
    setContentView(contentViewTypes.image)
    setNewImage('')
    fileErrorReset()
    setIsCustom(false)
  }

  return (
    <Dialog
      fullWidth
      maxWidth='xs'
      style={{ marginTop: navHeight }}
      open={isOpen}
      TransitionProps={{
        onEnter: () => { setNewImage(profileAvatar) }
      }}
      disableEnforceFocus
    >
      {/* Confirm Delete Image Popover */}
      <Popover
        id={openDeletePopover ? 'delete-popover' : undefined}
        open={openDeletePopover}
        anchorEl={deleteAnchorEl}
        onClose={handleDeletePopClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <Grid container direction='column' style={{ padding: '1em', width: '15em', maxWidth: '15em' }}>
          <Typography
            variant='h4'
            style={{ color: theme.palette.purple.darkest }}
            gutterBottom
          >
            Are you sure you'd like to delete your profile photo?
          </Typography>

          <Grid item container direction='row' justifyContent='flex-end' alignItems='center' style={{ marginTop: '.5em' }}>
            <Button onClick={handleDeletePopClose} style={{ marginRight: '.3em', textTransform: 'none', fontWeight: '600' }}>Cancel</Button>
            <Button variant='contained' color='primary' onClick={(e) => { e.preventDefault(); handleDelete() }}>Delete</Button>
          </Grid>
        </Grid>
      </Popover>

      {/* Modal Header */}
      <DialogTitle disableTypography>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography
              variant='h4'
              style={{ textTransform: 'none', color: theme.palette.purple.darkest, fontSize: '1.3rem' }}
            >
              Profile Image
            </Typography>
          </Grid>

          <Grid item>
            <IconButton style={{ padding: '0px' }} onClick={() => handleModalClose()}>
              <CloseRounded className={classes.customizedButton} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <Divider />

      {/* Modal Content */}
      <DialogContent>
        <Grid
          item
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          style={{ padding: '1em 0' }}
        >
          {getContentOptions(contentView).content}
        </Grid>
        {fileSizeError &&
          <Grid item container direction='row' justifyContent='center'>
            <Typography variant='caption' style={{ color: 'red' }}>The selected file is over the max file size of 3MB.</Typography>
          </Grid>}

        {fileExtensionError &&
          <Grid item container direction='row' justifyContent='center'>
            <Typography variant='caption' style={{ color: 'red' }}>The selected file type is not allowed.</Typography>
          </Grid>}
      </DialogContent>

      <Divider />

      {/* Modal Buttons */}
      <DialogActions>
        <Grid item container direction='row'>
          {contentView &&
            getContentOptions(contentView).buttons.map((button, i) => {
              const { copy = '', icon = '', handlerProp = contentViewTypes.emptyImage } = button
              const uploadButton = handlerProp === contentViewTypes.image ? 'raised-button-file' : null
              return (
                <Grid key={`${i}-${handlerProp}`} item container justifyContent='center' xs={buttonSpacing}>
                  <input
                    accept='image/*'
                    style={{ display: 'none' }}
                    id={uploadButton}
                    multiple
                    type='file'
                    onChange={(e) => { handleImageUpload(e, e.target.files) }}
                  />
                  <label htmlFor={uploadButton ? 'raised-button-file' : ''}>
                    <Button
                      startIcon={icon}
                      style={{
                        color: theme.palette.purple.darkest,
                        textTransform: 'none',
                        fontWeight: 600
                      }}
                      onClick={(e) => { if (!uploadButton) { handleViewChange(handlerProp, e) } }}
                      component='span'
                    >
                      {copy}
                    </Button>
                  </label>
                </Grid>
              )
            })}

        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default EditAvatarModal
