import React, { useState, useEffect, useReducer, useRef, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroller'
import { isEqual } from 'lodash'

// MUI
import {
  Grid,
  Paper,
  Typography,
  Button,
  Divider,
  CircularProgress,
  Popover,
  Select,
  MenuItem,
  OutlinedInput,
  Fab,
  useTheme,
  makeStyles
} from '@material-ui/core'

import {
  BookmarkRounded,
  BookmarkBorderRounded,
  DescriptionOutlined as DescriptionOutlinedIcon,
  SearchRounded as SearchRoundedIcon,
  AddRounded as AddRoundedIcon,
  ExpandMoreRounded as ExpandMoreRoundedIcon,
  DeviceHub as DeviceHubIcon
} from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'

// Local Imports
import { GET_USER_DETAILS } from '../../../redux/types'
import {
  getArtifactDetails,
  getUserArtifacts,
  getProfileEditData,
  getUserLEDOptions,
  getUserLWOptions,
  getAboutEditData,
  getSocialDetails
} from '../../../redux/actions'

import { NotificationToast } from '../tools'
import { ModifiedLedIcon, ModifiedLwIcon } from '../custom'

import ArtifactCard from '../artifacts/ArtifactCard'
import SocialModal from '../modals/SocialModal'

import { artifactAbbrev, userRoleIDs } from '../../../utils'

import queryString from 'query-string'

const useStyles = makeStyles((theme) => ({
  buttonFab: {
    fontSize: '10px !important',
    fontWeight: 'bold',
    height: '20px',
    width: '20px',
    minHeight: '20px',
    color: 'white',
    backgroundColor: theme.palette.purple.darkest,
    boxShadow: 'none',
    '&:hover': {
      fontSize: '10px !important',
      fontWeight: 'bold',
      height: '20px',
      width: '20px',
      minHeight: '20px',
      color: 'white',
      backgroundColor: theme.palette.purple.darkest,
      boxShadow: 'none'
    }
  },
  animateCreateButtonStyles: {
    boxShadow: '0 0 0 0 rgba(0, 0, 0, 1)',
    transform: 'scale(1)',
    animation: '$pulse 2s infinite'
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(0, 0, 0, 0.7)'
    },
    '70%': {
      transform: 'scale(1)',
      boxShadow: '0 0 0 10px rgba(0, 0, 0, 0)'
    },
    '100%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)'
    }
  },
  disabledButtonFab: {
    fontSize: '10px !important',
    fontWeight: 'bold',
    height: '20px',
    width: '20px',
    minHeight: '20px',
    color: 'white',
    backgroundColor: theme.palette.grey.medium,
    boxShadow: 'none',
    '&:hover': {
      fontSize: '10px !important',
      fontWeight: 'bold',
      height: '20px',
      width: '20px',
      minHeight: '20px',
      color: 'white',
      backgroundColor: theme.palette.grey.medium,
      boxShadow: 'none'
    }
  },
  buttonActive: {
    color: theme.palette.black,
    boxShadow: 'none',
    fontWeight: '600',
    backgroundColor: theme.palette.purple.lighter,
    height: '2em',
    borderRadius: '5px',
    marginRight: '.5em',
    '&:hover': {
      color: theme.palette.black,
      boxShadow: 'none',
      fontWeight: '600',
      backgroundColor: theme.palette.purple.light,
      height: '2em',
      borderRadius: '5px',
      marginRight: '.5em'
    }
  },
  savedButtonActive: {
    color: 'white',
    boxShadow: 'none',
    fontWeight: '600',
    backgroundColor: theme.palette.pink.dark,
    height: '2em',
    borderRadius: '5px',
    marginRight: '.5em',
    '&:hover': {
      color: 'white',
      boxShadow: 'none',
      fontWeight: '600',
      backgroundColor: theme.palette.pink.dark,
      height: '2em',
      borderRadius: '5px',
      marginRight: '.5em'
    }
  },
  buttonInactive: {
    color: theme.palette.black,
    boxShadow: 'none',
    fontWeight: '600',
    height: '2em',
    borderRadius: '5px',
    textTransform: 'none',
    marginRight: '.5em',
    '&:hover': {
      color: theme.palette.black,
      boxShadow: 'none',
      fontWeight: '600',
      height: '2em',
      borderRadius: '5px',
      textTransform: 'none',
      backgroundColor: 'white',
      marginRight: '.5em'
    }
  },
  filterLabel: {
    fontWeight: '600',
    color: theme.palette.grey.dark,
    fontSize: '14px',
    marginRight: '.2em'
  },
  filterSelect: {
    fontSize: '14px',
    fontWeight: '600',
    color: theme.palette.grey.dark,
    fontFamily: 'Source Sans Pro',
    paddingRight: '0px !important'
  },
  avatarSize: {
    height: '1.5em',
    width: '1.5em'
  },
  fixSpacing: {
    width: '100%',
    margin: 'auto'
  },
  editBtn: {
    padding: theme.spacing(0.5),
    color: theme.palette.background.paper,
    width: theme.spacing(3.5),
    height: theme.spacing(3.5)
  },
  noArtifactText: {
    color: theme.palette.black,
    padding: '0 15em',
    [theme.breakpoints.down('md')]: {
      padding: '0 13em'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 6em'
    }
  },
  artifactContainer: {
    justifyContent: 'flex-start',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  socialNumbersButtonRoot: {
    padding: 0,
    minWidth: 40,
    color: theme.palette.grey.dark,
    fontWeight: 600,
    '&:disabled': {
      color: theme.palette.grey.medium
    }
  },
  socialNumbersButtonLabel: {
    fontWeight: 600
  },
  socialIconsHighlight: {
    fontSize: '20px',
    color: theme.palette.purple.dark
  },
  bannerImageStyle: {
    height: '9em',
    maxHeight: '9em',
    width: '100%',
    maxWidth: 'inherit',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },
  ledIcon: {
    fontSize: `${theme.spacing(2.25)}px !important`
  },
  lwIcon: {
    fontSize: `${theme.spacing(2.25)}px !important`
  },
  efrSubHeader: {
    display: 'none'
  },
  regularSubHeader: {
    marginRight: '0',
    marginBottom: '0'
  }
}))

// Local types
const dispatchTypes = {
  SET_ARTIFACTS: 'SET_ARTIFACTS',
  RESET_ARTIFACTS: 'RESET_ARTIFACTS'
}

// custom reducer for setting the local value of artifacts to display
const reducer = (state, action) => {
  switch (action.type) {
    case dispatchTypes.SET_ARTIFACTS:
      // if no new artifacts, return
      if (!action.payload || !action.payload.length) {
        return { ...state }
      } else {
        const tempArray = state.displayedArtifacts.concat(action.payload)
        return {
          displayedArtifacts: tempArray
        }
      }
    case dispatchTypes.RESET_ARTIFACTS:
      // intentionally set the displayed arty's back to [] (used when filtering)
      return { displayedArtifacts: [] }
    default:
      return { ...state }
  }
}

const UserArtifacts = (props) => {
  const {
    matches, loadingState, userDetails, handleArtifactWalkthrough, currentUser, editMode,
    canEdit, filter, setFilter, artifactFilter,
    modalTypes, modalType, removeModalType
  } = props

  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()

  const { profileID = '', roleID = '', savedArtifacts = [], likedArtifacts = [] } = useSelector((state) => state.auth)

  const parsedProps = queryString.parse(props.location.search)
  const creatorID = parsedProps.user

  const { artifactList, artifactTotals: { ledCount = 0, proCount = 0, lwCount = 0, resourceCount = 0 } = {} } = useSelector((state) => state.artifacts)
  const grades = useSelector((state) => state.profileData.grades)
  const subjects = useSelector((state) => state.profileData.subjects)

  // Get grades, subjects, standards to format results from artifact details
  useEffect(() => {
    dispatch(getAboutEditData())
  }, [dispatch])

  const { type, loading } = loadingState

  // To determine if we are currently viewing saved artifacts or not
  const [viewSaved, setViewSaved] = useState(false)

  const [localState, localDispatch] = useReducer(reducer, { displayedArtifacts: [] })

  // Variables needed for the infinite scroll feature
  const [hasMore, setHasMore] = useState(true)

  const defaultOffset = 12
  const [artifactOffset, setArtifactOffset] = useState(defaultOffset)
  const offsetRef = useRef(defaultOffset)

  const filterRef = useRef(filter)

  // Refs for updating artifacts and ui updates
  const artifactUpdateRef = useRef(false)
  const uiUpdateInProgressRef = useRef(false)

  // Reset the local displayed artifacts to prevent duplicates
  const awaitLocalReset = useCallback(async () => {
    await localDispatch({ type: dispatchTypes.RESET_ARTIFACTS, payload: [] })
    uiUpdateInProgressRef.current = false
  }, [])

  // Callback that will reset the artifact display then fetch the artifact with the current filter
  const userArtifactDispatch = useCallback(
    (currentFilter) => {
      // Reset our offset since we'll be using a fresh list
      setArtifactOffset(defaultOffset)
      const resetLocal = awaitLocalReset()

      if (resetLocal) {
        dispatch(
          getUserArtifacts(
            currentFilter,
            () => { },
            () => NotificationToast(true, 'Failed to get user artifacts!')
          )
        )
      }
    },
    [awaitLocalReset, dispatch]
  )

  // If the filter has changed trigger another artifact fetch
  useEffect(() => {
    if (!isEqual(filter, filterRef.current)) {
      // Go ahead and set the ui update to true
      uiUpdateInProgressRef.current = true

      // Fetch the arts with the new filter
      userArtifactDispatch(filter)

      // set that new filter to the ref
      filterRef.current = filter
    }
  }, [filter, userArtifactDispatch])

  // Initial artifact fetch
  useEffect(() => {
    // Go ahead and set the ui update to true
    uiUpdateInProgressRef.current = true
    userArtifactDispatch(filterRef.current)
  }, [dispatch, userArtifactDispatch])

  useEffect(() => {
    // On page load, prevent multiple state updates from firing the dispatch
    if (!artifactUpdateRef.current) {
      artifactUpdateRef.current = true
      return true
    }

    // if the list from the api has no items, then don't fetch more
    const artsToDisplay = Boolean(artifactList && artifactList.length)
    const extraArts = Boolean(artifactList && artifactList.length && artifactList.length > offsetRef.current)
    setHasMore(extraArts)
    if (artsToDisplay) {
      localDispatch({ type: dispatchTypes.SET_ARTIFACTS, payload: artifactList.slice(0, offsetRef.current) })
    } else {
      localDispatch({ type: dispatchTypes.RESET_ARTIFACTS, payload: [] })
      uiUpdateInProgressRef.current = false
    }
    // give a slight buffer to the ui update ref to prevent excessive fetching
    setTimeout(() => {
      uiUpdateInProgressRef.current = false
    }, 1500)
  }, [artifactList])

  // Looks for a modal param and open the edit tech modal if requested via the param
  useEffect(() => {
    if (modalType) {
      if (modalType === modalTypes.ARTIFACT) {
        window.scrollTo(0, 0)
        setTimeout(() => {
          removeModalType()
        }, 5000)
      }
    }
  }, [modalType, modalTypes, removeModalType])

  // Question Popover elements
  const [questionAnchorEl, setQuestionAnchorEl] = useState(null)

  const handleQuestionPopClose = () => {
    setQuestionAnchorEl(null)
  }
  const openQuestionPopover = Boolean(questionAnchorEl)
  const questionPopoverID = openQuestionPopover ? 'question-popover' : undefined

  // Create Popover elements
  const [createAnchorEl, setCreateAnchorEl] = useState(null)
  const handleCreatePopover = (e) => {
    setCreateAnchorEl(e.currentTarget)
  }
  const handleCreatePopClose = () => {
    setCreateAnchorEl(null)
  }
  const openCreatePopover = Boolean(createAnchorEl)
  const createPopoverID = openCreatePopover ? 'create-popover' : undefined

  const defaultFilter = {
    creatorID,
    sortCount: null,
    offset: 0
  }
  const handleOpenCreator = (type) => {
    handleCreatePopClose()

    if (type === 'Learning Experience Design') {
      dispatch(getUserLWOptions({ ...defaultFilter, type: 'lw' }))
    }
    if (type === 'Learner Work') {
      const defaultLEDFilter = { creatorID, type: 'led', sortCount: null, offset: 0 }
      dispatch(getUserLEDOptions(defaultLEDFilter))
    }
    if (type === 'Project') {
      getProjectOptions()
    }

    handleArtifactWalkthrough(type, 'no', false, true)
  }

  const getProjectOptions = () => {
    dispatch(
      getUserLWOptions(
        { ...defaultFilter, type: 'lw' },
        () => { },
        () => NotificationToast(true, 'Failed to get Learner Works for Project creation!')
      )
    )
    dispatch(
      getUserLEDOptions(
        { ...defaultFilter, type: 'led' },
        () => { },
        () => NotificationToast(true, 'Failed to get Learner Experience Design for Project creation!')
      )
    )
  }

  // take artifactID and type to dispatch action for getting full details
  const handleArtifactEdit = (artifactID, type) => {
    let profilePath = ''
    if (props.location && props.location.pathname && props.location.search) {
      const { pathname, search } = props.location
      profilePath = `${pathname}${search}`
    } else {
      if (profileID) {
        profilePath = `/profile?user=${profileID}`
      }
    }

    if (artifactID) {
      if (type && type === 'Learning Experience Design') {
        dispatch(getUserLWOptions({ ...defaultFilter, type: 'lw' }))
      }

      if (type && type === 'Learner Work') {
        const defaultLEDFilter = { creatorID, type: 'led', sortCount: null, offset: 0 }
        dispatch(getUserLEDOptions(defaultLEDFilter))
      }

      if (type && type === 'Project') {
        getProjectOptions()
      }

      dispatch(
        getProfileEditData(
          () => { },
          () => NotificationToast(true, 'Failed to get profile edit data!')
        )
      )
      dispatch(getArtifactDetails(artifactID, profilePath, type, fireDetailSuccess, fireDetailFailure, ''))
    }
  }

  const fireDetailSuccess = (artifactType = '', submissionType = '', isDraft = false) => {
    if (artifactType && submissionType) {
      handleArtifactWalkthrough(artifactType, submissionType, true, isDraft)
    } else {
      // Should not happen
      NotificationToast(false, 'Could not find artifact type', true)
    }
  }

  const fireDetailFailure = () => NotificationToast(true, 'Failed to get artifact information')

  // **************************** Filter Logic ****************************** //

  // Artifact Type
  const [chosenType, setChosenType] = useState('all')

  // Open Type Select
  const [typeOpen, setTypeOpen] = useState(false)

  // Grades Select and Values
  const [gradesOpen, setGradesOpen] = useState(false)
  const [gradesValue, setGradesValue] = useState([])

  // Search Select and Values
  const [subjectsOpen, setSubjectsOpen] = useState(false)
  const [subjectsValue, setSubjectsValue] = useState([])

  // Searchbar input
  const [searchInput, setSearchInput] = useState('')
  const handleReset = () => {
    setViewSaved(false)
    setTypeOpen(false)
    setGradesValue([])
    setGradesOpen(false)
    setSubjectsValue([])
    setSubjectsOpen(false)
    setChosenType('all')
    setSearchInput('')
  }

  // Reset All Filters
  const handleFilterReset = () => {
    handleReset()
    setFilter({ ...artifactFilter })
    uiUpdateInProgressRef.current = false
  }

  // Handler for the searchbar
  const handleSearchArtifacts = async (e) => {
    const input = e.target.value
    // Prevent the ui from reloading before the search results can return
    if (uiUpdateInProgressRef.current) {
      return false
    } else {
      // First set the user's input as the value
      setSearchInput(input)

      // Take a (very partial)second to fetch
      // Let the UI know we are fetchin
      // If the user has typed at least 4 characters:
      if (input.length >= 4) {
        uiUpdateInProgressRef.current = true
        handleFilterChange('searchPhrase', input)
      }
      // If the user has deleted their search input, reset the artifactList:
      if (input === '') {
        uiUpdateInProgressRef.current = true
        handleFilterChange('searchPhrase', '')
      }
    }
  }

  // Handler for filtering by type/grades/subjects and/or a searchPhrase greater than 4 chars
  const handleFilterChange = async (filterKey, options) => {
    // Let the UI know we are doing things
    uiUpdateInProgressRef.current = true

    let filterVal = []

    // need to handle saved via another action that initially sets the filter to default but with userAction set,

    // Filtering by grades or subjects:
    if (filterKey !== 'type' && filterKey !== 'searchPhrase') {
      // The options value is '' when the user has removed their selection or set it to 'All'
      if (options.includes('')) {
        // Reset the select value display
        if (filterKey === 'grades') {
          setGradesValue([])
        }
        if (filterKey === 'subjects') {
          setSubjectsValue([])
        }
      } else {
        // We are filtering by something specific, and need to reduce the objects sent in from the select to an array of IDs
        const gradeIDs = options.reduce((arr, grade) => {
          if (grade) {
            arr.push(grade.gradeID)
          }
          return arr
        }, [])

        const subIDs = options.reduce((arr, sub) => {
          if (sub) {
            arr.push(sub.subjectID)
          }
          return arr
        }, [])

        // Set the select display value to the original array { gradeID, gradeName }, and set the filter val to the reduced array
        if (filterKey === 'grades') {
          setGradesValue(options)
          filterVal = gradeIDs
        } else {
          setSubjectsValue(options)
          filterVal = subIDs
        }
      }
    } else {
      // Filtering by searchPhrase or Type
      if (filterKey !== 'searchPhrase') {
        // If filtering by Type, we need to set the select display value
        setChosenType(options)

        // Clear grades and subjects from menus when switching to Resources
        if (options === artifactAbbrev.EFR) {
          setGradesValue([])
          setSubjectsValue([])
        }
      }

      filterVal = options.toLowerCase()
    }

    // Make sure the select menu's close
    setTypeOpen(false)
    setSubjectsOpen(false)
    setGradesOpen(false)

    // Set the filter to whatever the current filter is, and add in the new key/values
    if (options !== artifactAbbrev.EFR) {
      setFilter({
        ...filter,
        [filterKey]: filterVal
      })
    } else {
      // Clear grades and subjects when switching to Resources
      setFilter({ ...filter, grades: [], subjects: [], type: filterVal })
    }

    uiUpdateInProgressRef.current = false
  }

  // ************* Select Display Formatting ************* //

  const renderSelectsValue = (selectType, selected) => {
    if (!selected || selected.length === 0) {
      return 'All'
    } else {
      const newList = []

      selected.forEach((index) => {
        newList.push(selectType === 'grade' ? index.gradeName.replace('Grade', '') : index.subjectName)
      })
      return newList.join(', ')
    }
  }

  // This is the call for the infinite scroll to fetch more artifacts
  const fetchMore = async (e) => {
    if (uiUpdateInProgressRef.current) {
      console.log('limiting this call while a promise is in progress')
      return false
    }
    // -- "Gretchen, stop trying to make 'fetch' happen, it's not going to happen."
    // If the length of data has met or exceeded the length of the returned artifacts, then display the end message
    if (localState && localState.displayedArtifacts && localState.displayedArtifacts.length < 12) {
      setHasMore(false)
      await Promise.resolve()
    } else {
      uiUpdateInProgressRef.current = true
      setTimeout(() => {
        // if the list from the api has less than the max number, then there are no more to fetch
        const offset = artifactOffset + 12
        if (artifactList && artifactList.length < offset) {
          setHasMore(false)
        }
        localDispatch({ type: dispatchTypes.SET_ARTIFACTS, payload: artifactList.slice(artifactOffset, offset) })
        setArtifactOffset(offset)
        uiUpdateInProgressRef.current = false
      }, 1000)
    }
  }

  const [socialType, setSocialType] = useState('')
  const [socialModalOpen, setSocialModalOpen] = useState(false)
  const [socialArtifactInfo, setSocialArtifactInfo] = useState({})

  const handleUserProfileView = (id, callBack) => {
    callBack()
    props.history.push({
      pathname: '/profile',
      search: `?user=${id}`
    })
  }

  const handleViewSaved = () => {
    setViewSaved(!viewSaved)
    if (viewSaved) {
      handleFilterReset()
    } else {
      setFilter({
        ...filter,
        userAction: savedArtifacts
      })
    }
  }

  const artNullCheck = ledCount != null && lwCount != null && proCount != null && resourceCount != null
  const allArtsTotal = artNullCheck ? ledCount + lwCount + proCount + resourceCount : 0

  return (
    <>
      {type === GET_USER_DETAILS && loading === true ? (
        <>
          <Grid container direction='column' style={{ margin: matches ? '0 0 1em 3em' : '0 0 1em 1em' }}>
            <Paper style={{ width: '100%', overflow: 'hidden' }}>
              <Grid container direction='column'>
                <Grid container item direction={matches ? 'row' : 'column'} style={{ margin: '.5em 1em .5em 1em' }}>
                  <Grid item container xs={matches ? 2 : null} alignItems='center'>
                    <Skeleton animation='wave' width={100} height={60} />
                  </Grid>
                  <Grid
                    item
                    container
                    xs={matches ? 5 : null}
                    alignItems='center'
                    justifyContent='flex-start'
                    style={{ marginTop: matches ? null : '.5em' }}
                  >
                    <Skeleton animation='wave' width={250} height={45} />
                  </Grid>
                </Grid>

                <Divider />

                <Grid item container direction={matches ? 'row' : 'column'} style={{ margin: '1.5em 1em' }}>
                  <Grid item container direction='row' xs={matches ? 9 : null}>
                    <Skeleton animation='wave' width={395} height={50} />
                  </Grid>

                  <Grid
                    item
                    container
                    xs={matches ? 3 : null}
                    justifyContent={matches ? 'flex-end' : 'center'}
                    style={{ paddingRight: '3em', marginTop: matches ? null : '.5em' }}
                  >
                    <Skeleton animation='wave' width={150} height={50} />
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            <Grid style={{ textAlign: 'center', marginTop: '3em' }}>
              <Grid item container direction='column' justifyContent='center' alignItems='center'>
                <Skeleton animation='wave' width={60} height={100} style={{ marginTop: '-1rem' }} />
                <Skeleton animation='wave' width={200} height={30} />
                <Skeleton animation='wave' width={250} height={30} />
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container direction='column' style={{ margin: matches ? '0 0 1em 3em' : '0 0 1em 1em' }}>
            <Paper style={{ width: '100%', overflow: 'hidden' }}>
              <Grid container direction='column'>
                <Grid container item xs={12} direction='column' style={{ margin: '.5em 1em' }}>
                  {canEdit && editMode && (
                    <Grid item container xs={null} sm={2} md={2} alignItems='center'>
                      <Button
                        variant='contained'
                        color='primary'
                        style={{ fontWeight: '600', padding: '.5em 1.5em' }}
                        className={modalType && modalType === modalTypes.ARTIFACT ? classes.animateCreateButtonStyles : ''}
                        startIcon={<AddRoundedIcon />}
                        aria-describedby={createPopoverID}
                        onClick={handleCreatePopover}
                      >
                        Create
                      </Button>
                    </Grid>
                  )}
                  <Grid
                    item
                    container
                    alignItems='center'
                    direction='row'
                    justifyContent={matches ? 'space-between' : 'center'}
                    style={{ marginTop: '1em', paddingLeft: '.5em', paddingRight: '2em' }}
                  >
                    <Grid item style={{ justifyContent: matches ? 'flex-start' : 'center' }}>
                      <Button
                        variant={chosenType === 'all' && !viewSaved ? 'contained' : 'text'}
                        className={chosenType === 'all' && !viewSaved ? classes.buttonActive : classes.buttonInactive}
                        onClick={() => handleFilterChange('type', 'all')}
                        disabled={viewSaved}
                        endIcon={
                          <Fab component='div' size='small' className={!viewSaved ? classes.buttonFab : classes.disabledButtonFab}>
                            {allArtsTotal}
                          </Fab>
                        }
                      >
                        All
                      </Button>
                      <Button
                        variant={chosenType === artifactAbbrev.LED && !viewSaved ? 'contained' : 'text'}
                        className={chosenType === artifactAbbrev.LED && !viewSaved ? classes.buttonActive : classes.buttonInactive}
                        onClick={() => handleFilterChange('type', artifactAbbrev.LED)}
                        disabled={Boolean(ledCount && ledCount === 0) || viewSaved}
                        endIcon={
                          <Fab
                            component='div'
                            size='small'
                            className={ledCount && ledCount !== 0 && !viewSaved ? classes.buttonFab : classes.disabledButtonFab}
                          >
                            {!ledCount ? 0 : ledCount}
                          </Fab>
                        }
                      >
                        Learning Experience Designs
                      </Button>
                      <Button
                        variant={chosenType === artifactAbbrev.LW && !viewSaved ? 'contained' : 'text'}
                        className={chosenType === artifactAbbrev.LW && !viewSaved ? classes.buttonActive : classes.buttonInactive}
                        onClick={() => handleFilterChange('type', artifactAbbrev.LW)}
                        disabled={Boolean(lwCount && lwCount === 0) || viewSaved}
                        endIcon={
                          <Fab
                            component='div'
                            size='small'
                            className={lwCount && lwCount !== 0 && !viewSaved ? classes.buttonFab : classes.disabledButtonFab}
                          >
                            {!lwCount ? 0 : lwCount}
                          </Fab>
                        }
                      >
                        Learner Work
                      </Button>
                      <Button
                        variant={chosenType === artifactAbbrev.PRO && !viewSaved ? 'contained' : 'text'}
                        className={chosenType === artifactAbbrev.PRO && !viewSaved ? classes.buttonActive : classes.buttonInactive}
                        onClick={() => handleFilterChange('type', artifactAbbrev.PRO)}
                        disabled={Boolean(proCount && proCount === 0) || viewSaved}
                        endIcon={
                          <Fab
                            component='div'
                            size='small'
                            className={proCount && proCount !== 0 && !viewSaved ? classes.buttonFab : classes.disabledButtonFab}
                          >
                            {!proCount ? 0 : proCount}
                          </Fab>
                        }
                      >
                        Projects
                      </Button>
                      {/* check if the user being viewed (not necessarily the auth user) before loading a resource filter option */}
                      {userDetails && userDetails.roleID && userDetails.roleID === userRoleIDs.ADMIN_ROLE && (
                        <Button
                          variant={chosenType === artifactAbbrev.EFR && !viewSaved ? 'contained' : 'text'}
                          className={chosenType === artifactAbbrev.EFR && !viewSaved ? classes.buttonActive : classes.buttonInactive}
                          onClick={() => handleFilterChange('type', artifactAbbrev.EFR)}
                          disabled={
                            Boolean(resourceCount && resourceCount === 0) || viewSaved
                          }
                          endIcon={
                            <Fab
                              component='div'
                              size='small'
                              className={resourceCount && resourceCount !== 0 && !viewSaved ? classes.buttonFab : classes.disabledButtonFab}
                            >
                              {!resourceCount ? 0 : resourceCount}
                            </Fab>
                          }
                        >
                          Resources
                        </Button>
                      )}
                    </Grid>
                    {canEdit && editMode && (
                      <Grid item>
                        <Button
                          variant={viewSaved ? 'contained' : 'outlined'}
                          className={viewSaved ? classes.savedButtonActive : classes.buttonInactive}
                          onClick={handleViewSaved}
                          endIcon={
                            viewSaved ? (
                              <BookmarkRounded style={{ color: 'white' }} />
                            ) : (<BookmarkBorderRounded style={{ color: theme.palette.pink.dark }} />)
                          }
                        >
                          Saved Artifacts
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                {/* Question Popover */}
                <Popover
                  id={questionPopoverID}
                  open={openQuestionPopover}
                  anchorEl={questionAnchorEl}
                  onClose={handleQuestionPopClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                >
                  <Grid container direction='column' style={{ padding: '1em' }}>
                    <Typography variant='h4' gutterBottom style={{ color: theme.palette.purple.darkest, margin: '.5em 0' }}>
                      How do I...
                    </Typography>
                    <Typography variant='h5' gutterBottom style={{ color: theme.palette.purple.darkest }}>
                      Duplicate an artifact
                    </Typography>
                    <Typography variant='h5' gutterBottom style={{ color: theme.palette.purple.darkest }}>
                      Create an artifact
                    </Typography>
                  </Grid>
                </Popover>

                {/* Create Popover */}
                <Popover
                  id={createPopoverID}
                  open={openCreatePopover}
                  anchorEl={createAnchorEl}
                  onClose={handleCreatePopClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <Grid container direction='column' style={{ padding: '1em' }}>
                    {roleID && roleID === userRoleIDs.ADMIN_ROLE && (
                      <Grid item container justifyContent='flex-start'>
                        <Button
                          startIcon={<DescriptionOutlinedIcon />}
                          style={{
                            textTransform: 'none',
                            color: theme.palette.purple.darkest,
                            fontWeight: '600'
                          }}
                          onClick={() => handleArtifactWalkthrough('Ed Farm Resource', 'no', false, true)}
                        >
                          Ed Farm Resource
                        </Button>
                      </Grid>
                    )}
                    <Grid item container justifyContent='flex-start'>
                      <Button
                        startIcon={<ModifiedLedIcon className={classes.ledIcon} />}
                        style={{
                          textTransform: 'none',
                          color: theme.palette.purple.darkest,
                          fontWeight: '600'
                        }}
                        onClick={() => handleOpenCreator('Learning Experience Design')}
                      >
                        Learning Experience Design
                      </Button>
                    </Grid>
                    <Grid item container justifyContent='flex-start'>
                      <Button
                        startIcon={<ModifiedLwIcon className={classes.lwIcon} />}
                        style={{
                          textTransform: 'none',
                          color: theme.palette.purple.darkest,
                          fontWeight: '600'
                        }}
                        onClick={() => handleOpenCreator('Learner Work')}
                      >
                        Learner Work
                      </Button>
                    </Grid>
                    <Grid item container justifyContent='flex-start'>
                      <Button
                        startIcon={<DeviceHubIcon />}
                        style={{
                          textTransform: 'none',
                          color: theme.palette.purple.darkest,
                          fontWeight: '600'
                        }}
                        onClick={() => handleOpenCreator('Project')}
                      >
                        Project
                      </Button>
                    </Grid>
                  </Grid>
                </Popover>
                <Divider />

                {/* Filter Items */}
                <Grid item container direction='row' justifyContent='flex-end' style={{ paddingRight: '.5em', marginTop: '.5em' }}>
                  <Button
                    variant='text'
                    style={{ textTransform: 'none', color: theme.palette.grey.dark, fontWeight: 600, fontSize: '12px' }}
                    onClick={handleFilterReset}
                  >
                    Clear All
                  </Button>
                </Grid>
                <Grid item container direction={matches ? 'row' : 'column'} style={{ margin: '.2em 1em 1.5em 1em' }}>
                  <Grid item container direction='row' xs={null} sm={7}>
                    {/* Type Filter */}
                    <Grid item style={{ marginRight: '1em' }}>
                      <Typography variant='caption' className={classes.filterLabel}>
                        Type:
                      </Typography>
                      <Select
                        value={chosenType}
                        open={typeOpen}
                        onOpen={() => !typeOpen ? setTypeOpen(true) : false}
                        onClose={() => setTypeOpen(false)}
                        onChange={(e) => handleFilterChange('type', e.target.value)}
                        IconComponent={() =>
                          <ExpandMoreRoundedIcon
                            style={{ fontSize: '16px', color: theme.palette.grey.dark, cursor: 'pointer' }}
                            onClick={() => !typeOpen ? setTypeOpen(true) : false}
                          />}
                        classes={{ root: classes.filterSelect }}
                        disableUnderline
                      >
                        <MenuItem value='all'>All</MenuItem>
                        <MenuItem disabled={ledCount === 0} value={artifactAbbrev.LED}>
                          Learning Experience Design
                        </MenuItem>
                        <MenuItem disabled={lwCount === 0} value={artifactAbbrev.LW}>
                          Learner Work
                        </MenuItem>
                        <MenuItem disabled={proCount === 0} value={artifactAbbrev.PRO}>
                          Project
                        </MenuItem>
                        {/* check if the user being viewed (not necessarily the auth user) before loading a resource filter option */}
                        {userDetails && userDetails.roleID && userDetails.roleID === userRoleIDs.ADMIN_ROLE && (
                          <MenuItem disabled={resourceCount === 0} value={artifactAbbrev.EFR}>
                            Resources
                          </MenuItem>
                        )}
                      </Select>
                    </Grid>

                    {/* Grade Filter */}
                    <Grid item style={{ marginRight: matches ? '1em' : '.5em' }}>
                      <Typography variant='caption' className={classes.filterLabel}>
                        Grade(s):
                      </Typography>
                      <Select
                        open={gradesOpen}
                        onOpen={() => !gradesOpen ? setGradesOpen(true) : false}
                        onClose={() => setGradesOpen(false)}
                        value={gradesValue}
                        displayEmpty
                        onChange={(e) => handleFilterChange('grades', e.target.value)}
                        IconComponent={() => (
                          <ExpandMoreRoundedIcon
                            style={{ fontSize: '16px', color: theme.palette.grey.dark, cursor: 'pointer' }}
                            onClick={() => !gradesOpen ? setGradesOpen(true) : false}
                          />
                        )}
                        classes={{ root: classes.filterSelect }}
                        disableUnderline
                        multiple
                        defaultValue=''
                        renderValue={(selected) => renderSelectsValue('grade', selected)}
                      >
                        <MenuItem disabled={chosenType && chosenType === artifactAbbrev.EFR} value=''>
                          All
                        </MenuItem>
                        {grades &&
                          grades.map((grade) => {
                            const { gradeID, gradeName } = grade
                            return (
                              <MenuItem disabled={chosenType && chosenType === artifactAbbrev.EFR} key={`grade ${gradeID}`} value={grade}>
                                {gradeName.replace('Grade', '')}
                              </MenuItem>
                            )
                          })}
                      </Select>
                    </Grid>

                    {/* Subject Filter */}
                    <Grid item style={{ marginRight: matches ? '1em' : '.5em' }}>
                      <Typography variant='caption' className={classes.filterLabel}>
                        Subject(s):
                      </Typography>
                      <Select
                        open={subjectsOpen}
                        onOpen={() => !subjectsOpen ? setSubjectsOpen(true) : false}
                        onClose={() => setSubjectsOpen(false)}
                        value={subjectsValue}
                        displayEmpty
                        onChange={(e) => handleFilterChange('subjects', e.target.value)}
                        IconComponent={() => (
                          <ExpandMoreRoundedIcon
                            style={{ fontSize: '16px', color: theme.palette.grey.dark, cursor: 'pointer' }}
                            onClick={() => !subjectsOpen ? setSubjectsOpen(true) : false}
                          />
                        )}
                        classes={{ root: classes.filterSelect }}
                        disableUnderline
                        multiple
                        defaultValue=''
                        renderValue={(selected) => renderSelectsValue('subject', selected)}
                      >
                        <MenuItem disabled={chosenType && chosenType === artifactAbbrev.EFR} value=''>
                          All
                        </MenuItem>
                        {subjects &&
                          subjects.map((subject) => {
                            const { subjectID, subjectName } = subject
                            return (
                              <MenuItem disabled={chosenType && chosenType === artifactAbbrev.EFR} key={`subject ${subjectID}`} value={subject}>
                                {subjectName}
                              </MenuItem>
                            )
                          })}
                      </Select>
                    </Grid>
                  </Grid>

                  {/* Search Bar */}
                  <Grid
                    item
                    container
                    xs={null}
                    sm={5}
                    justifyContent={matches ? 'flex-end' : 'center'}
                    style={{ paddingRight: '3em', marginTop: matches ? null : '.5em', height: 'fit-content' }}
                  >
                    <OutlinedInput
                      placeholder='Search...'
                      style={{
                        fontSize: '14px',
                        fontFamily: 'Source Sans Pro',
                        padding: 0
                      }}
                      inputProps={{
                        style: {
                          padding: 0
                        }
                      }}
                      value={searchInput}
                      onChange={(e) => handleSearchArtifacts(e)}
                      size='small'
                      startAdornment={<SearchRoundedIcon style={{ color: theme.palette.grey.dark, padding: '0em .3em' }} />}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Paper>

            <Grid
              style={{
                height: '82vh',
                overflowY: 'auto',
                textAlign: 'center',
                marginTop: localState.displayedArtifacts && localState.displayedArtifacts.length ? '1em' : '3em'
              }}
            >
              {localState && localState.displayedArtifacts && localState.displayedArtifacts.length ? (
                <InfiniteScroll
                  pageStart={0}
                  loadMore={fetchMore}
                  hasMore={hasMore}
                  loader={hasMore && <CircularProgress key='loader' style={{ marginTop: '1.5em' }} />}
                  useWindow={false}
                  initialLoad={false}
                >
                  <Grid item container direction='row' justifyContent='center'>
                    {localState.displayedArtifacts.map((artifact) => {
                      const { authorID = '', artifactID } = artifact
                      const canEditArtifact = authorID && currentUser ? authorID === currentUser : false

                      return (
                        <Grid
                          item
                          key={`artifact-${artifactID}`}
                          style={{ height: 'inherit', padding: '.5em' }}
                          container
                          xs={10}
                          sm={9}
                          md={6}
                          lg={4}
                        >
                          <ArtifactCard
                            type={artifact.artifactType}
                            classes={classes}
                            userDetails={userDetails}
                            artifactInfo={artifact}
                            canEditArtifact={canEditArtifact}
                            editMode={editMode}
                            viewSaved={viewSaved}
                            onArtifactEdit={handleArtifactEdit}
                            artifactLiked={likedArtifacts && likedArtifacts.length && likedArtifacts.includes(artifact.artifactID)}
                            artifactSaved={savedArtifacts && savedArtifacts.length && savedArtifacts.includes(artifact.artifactID)}
                            onSocialButtonClick={(status) => {
                              dispatch(getSocialDetails(artifact.artifactID))
                              setSocialType(status)
                              setSocialArtifactInfo(artifact)
                              setSocialModalOpen(true)
                            }}
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                  {!hasMore && (
                    <Typography variant='body1' style={{ color: '#b9bbbd', margin: '1em' }}>
                      Reached End of Artifact List
                    </Typography>
                  )}
                </InfiniteScroll>
              ) : (
                <Grid item container direction='column' justifyContent='center' alignItems='center'>
                  {uiUpdateInProgressRef.current ? (
                    <CircularProgress />
                  ) : (
                    <>
                      {canEdit ? (
                        <>
                          <DescriptionOutlinedIcon style={{ color: theme.palette.purple.darkest, fontSize: '3em' }} />

                          <Button
                            style={{
                              textTransform: 'none',
                              fontWeight: '600',
                              textDecoration: 'underline',
                              color: theme.palette.black,
                              fontSize: '15px'
                            }}
                            onClick={() => handleOpenCreator('Learning Experience Design')}
                          >
                            {isEqual(filter, artifactFilter) ? 'Create your first artifact.' : 'No Results Found'}
                          </Button>

                          <Typography variant='body1' className={classes.noArtifactText}>
                            {isEqual(filter, artifactFilter)
                              ? 'Create an artifact to submit high-quality work to Ed Farm and share helpful information with other teachers.'
                              : 'No artifacts matched your search. Please refresh the page or try another filter.'}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <DescriptionOutlinedIcon style={{ color: theme.palette.purple.darkest, fontSize: '3em' }} />

                          <Typography variant='body1' className={classes.noArtifactText} style={{ marginTop: '1rem' }}>
                            This user has no public artifacts.
                          </Typography>
                        </>
                      )}
                    </>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      )}
      {/* Modal to Display Social Information */}
      <SocialModal
        isOpen={socialModalOpen}
        artifactDetails={socialArtifactInfo}
        actionType={socialType}
        handleUserProfileView={handleUserProfileView}
        onModalClose={() => {
          setSocialModalOpen(false)
          setSocialType('')
          setSocialArtifactInfo({})
        }}
      />
    </>
  )
}

export default withRouter(UserArtifacts)
