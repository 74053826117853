import React from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/actions'

import edFarmLogo from '../../assets/edFarmLogo.png'
import teacherImg from '../../assets/teacher.svg'
// Assets
import { makeStyles } from '@material-ui/core/styles'

// MUI
import {
  Grid, Typography
} from '@material-ui/core'

import { ValidateEmailForm } from '../ui/forms'

// themes are set in app.js wrapping routes with theme provider
const useStyles = makeStyles((theme) => ({
  heading: {
    color: theme.palette.purple.darkest
  },
  darkBackground: {
    backgroundColor: theme.palette.purple.darkest
  }
}))

const CheckEmail = props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const authObject = useSelector(state => state.auth)
  const { isAuthenticated, isValidated, isAccountApproved } = authObject

  const handleReturnClick = () => {
    if (isAuthenticated) {
      if (!isValidated || !isAccountApproved) {
        dispatch(logout())
      }
    } else {
      props.history.push('/')
    }
  }
  // ************ Main Render ************ //
  return (
    <>
      <Grid container direction='row'>
        <Grid item xs={4} className={classes.darkBackground} style={{ height: '100vh', width: '100%' }}>
          <Grid container direction='column' style={{ justifyContent: 'space-between', height: '100vh' }}>
            <Grid item style={{ width: '100%', marginLeft: '2em', marginTop: '2em' }}>
              <Grid item>
                <img src={edFarmLogo} alt='logo' style={{ maxHeight: '3.7em' }} />
              </Grid>
              <Grid item style={{ marginTop: '3em' }} container xs={10}>
                <Typography variant='h1' style={{ color: 'white', fontWeight: '600' }}>Gain inspiration and learn from other</Typography>
                <Typography variant='h1' style={{ color: 'white', fontWeight: '600' }}>Teacher Fellows</Typography>
              </Grid>
            </Grid>
            <Grid item container alignContent='flex-end'>
              <Grid item container style={{ width: '100%', height: 'auto' }}>
                {/* TODO: Will not display with overhang on large screen */}
                <img style={{ height: '100%', width: '150%', marginLeft: '-7em' }} src={teacherImg} alt='Teacher Fellow' />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={8}>
          <Grid item container direction='column' alignContent='center' justifyContent='center' style={{ margin: '0 9em 3em', paddingRight: '20em', marginTop: '6vh' }}>
            <ValidateEmailForm handleReturnClick={handleReturnClick} />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default withRouter(CheckEmail)
