import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { isEqual } from 'lodash'

import {
  Grid, Typography, useTheme, OutlinedInput, Table, TableBody, TableContainer, TableCell, TableHead, TableRow,
  Paper, Avatar, IconButton, Select, MenuItem, TableSortLabel, Tooltip
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import { SearchRounded, SaveOutlined, Settings, Cancel } from '@material-ui/icons'

import { getSchoolsAndDistricts, getCohorts, updateFellowConfig, updateUserEmailAndValidation } from '../../../../redux/actions'
import { NotificationToast } from '../../tools'

import { ModifiedEditIcon } from '../../custom'
import { adminPageOptions, updateUserActionTypes } from '../../../../utils'
import { ValidateEmail } from '../../../../lib'

import DeleteUserPopover from './DeleteUserPopover'
import UpdateUserDialog from './UpdateUserDialog'

const InnovationFellows = (props) => {
  const {
    classes, filter, setFilter, defaultFilter, activeButton, totalIfFellows, innovationFellows, getInnovationFellows, confirmAnchorEl,
    setConfirmAnchorEl = () => { }, handleConfirmPopover, openConfirmPopover, handleConfirmDeleteUser, selectedAction, setSelectedAction = () => { }
  } = props

  const theme = useTheme()
  const [now] = useState(moment().unix())
  const dispatch = useDispatch()
  const { superAdmin = 0 } = useSelector(state => state.auth)

  // ******************** Data Creation ******************** //

  // If the filter was changed, fetch the fellows with the new filter
  useEffect(() => {
    if (filter && defaultFilter && activeButton === adminPageOptions.INNOVATION_FELLOWS) {
      if (!isEqual(filter, defaultFilter)) {
        dispatch(getInnovationFellows(filter))
      }
    }
  }, [dispatch, filter, defaultFilter, getInnovationFellows, setFilter, activeButton])

  // Formats the array returned from the dispatch for the data table
  const createData = (avatar, userID, name, emailAddress, schoolID, school, schoolDistrictID, district, cohortID, cohort) => {
    if (cohort === null) {
      cohort = 'N/A'
    }
    if (schoolID === null) {
      school = 'N/A'
    }
    return { avatar, userID, name, emailAddress, schoolID, school, schoolDistrictID, district, cohortID, cohort }
  }

  // Data rows for the displayed table
  const [rows, setRows] = useState([])

  // Take the innovationFellows array and format it into the data rows
  useEffect(() => {
    if (innovationFellows) {
      if (innovationFellows.length) {
        const newRows = []
        innovationFellows.forEach(fellow => {
          const { profileAvatarPath, userID, name, emailAddress, schoolID = '', schoolName, schoolDistrictID, districtName, cohortID, cohortName } = fellow
          newRows.push(createData(profileAvatarPath, userID, name, emailAddress, schoolID, schoolName, schoolDistrictID, districtName, cohortID, cohortName))
        })
        setRows(newRows)
      } else {
        setRows([])
      }
    }
  }, [innovationFellows])

  const headCells = [
    { id: 'name', label: 'Name', align: 'center' },
    { id: 'schoolName', label: 'School', align: 'left' },
    { id: 'districtName', label: 'District', align: 'left' },
    { id: 'cohortName', label: 'Cohort', align: 'left' }
  ]

  // ********************* Pagination Logic: **************** //
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const handleChangePage = (event, value) => {
    if (value >= 0) {
      setPage(value - 1)
      setFilter({
        ...filter,
        sortCount: rowsPerPage,
        page: value
      })
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: event.target.value
    })
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalIfFellows - page * rowsPerPage)

  // ****************** Edit Row Logic: *********************** //

  // Redux State
  const {
    adminSchools: { schools: Schools = [], districtOptions: Districts = [] } = {}, adminCohorts: { cohorts: Cohorts = [] } = {}
  } = useSelector(state => state.admin)

  // Set the user row into edit mode when populated with the user's id
  const [editRow, setEditRow] = useState('')

  // District Options and Selected District, defaults to the first ditrict in the array
  const districtOptions = !Districts ? [] : Districts
  const [selectedDistrict, setSelectedDistrict] = useState(Districts && Districts.length ? Districts[0] : '')

  // School options (based on ditrict) and the selected school, defaults to the schools of the first district in the array
  const [schoolOptions, setSchoolOptions] = useState(Schools && Schools.length ? [...Schools[0].schools] : [])
  const [selectedSchool, setSelectedSchool] = useState('')

  // Cohort Options and the selected cohort
  const cohortOptions = Cohorts && Cohorts.length ? [...Cohorts] : []
  const [selectedCohort, setSelectedCohort] = useState('')

  // The information needed for the dispatch
  const defaultEditUserForm = {
    fellowID: '',
    schoolID: '',
    schoolDistrictID: '',
    cohortID: ''
  }

  const [editForm, setEditForm] = useState(defaultEditUserForm)

  // Initially get schools and cohorts
  useEffect(() => {
    dispatch(getSchoolsAndDistricts({ allResults: true }))
    dispatch(getCohorts({ allResults: true }))
  }, [dispatch])

  // When user selects to edit a row, set the district and schools for that user as the selected values
  const handleEditRow = (row) => {
    // reset the values of the selects and the edit form between clicking the edit button, this will account for if the user did not save the previous row they clicked
    handleReset()
    // Opens the edit view for the desired user
    setEditRow(row.userID)

    // If the user has already been assigned a cohort, set that to the selected cohort
    if (row.cohortID && row.cohort !== 'N/A') {
      const cohortIndex = cohortOptions.findIndex(x => x.cohortID === row.cohortID)
      if (cohortIndex !== -1) {
        setSelectedCohort(cohortOptions[cohortIndex])
      } else {
        setSelectedCohort('')
      }
    } else {
      // If the user has not had a cohort assigned to them yet, then set it blank
      setSelectedCohort('')
    }

    // If the user has a district already assigned to them, then populate it as the selected district, and populate the school options to those of that district
    if (row.schoolDistrictID) {
      const districtIndex = districtOptions.findIndex(x => x.schoolDistrictID === row.schoolDistrictID)
      if (districtIndex !== -1) {
        const newDistrict = districtOptions[districtIndex]
        setSelectedDistrict(newDistrict)

        const districtsSchoolsIndex = Schools.findIndex(x => x.schoolDistrictID === row.schoolDistrictID)
        const districtsSchools = districtsSchoolsIndex !== -1 ? Schools[districtsSchoolsIndex].schools : []
        setSchoolOptions(districtsSchools)
        if (row.schoolID) {
          const schoolIndex = [...districtsSchools].findIndex(x => x.schoolID === row.schoolID)
          if (schoolIndex !== -1) {
            setSelectedSchool([...districtsSchools][schoolIndex])
          } else {
            setSelectedSchool('')
          }
        }
      } else {
        // If no district assigned set the dropdowns to default
        setSelectedDistrict('')
        setSchoolOptions([])
        setSelectedSchool('')
      }
    }

    // Set the edit form to the correct user being updated
    setEditForm({
      ...editForm,
      fellowID: row.userID
    })
  }

  // Confirm Delete User handlers //
  const handleConfirmPopClose = () => {
    if (modalOpen) { handleCloseModal() }
    if (editRow) { setEditRow('') }
    setConfirmAnchorEl(null)
    setSelectedAction(updateUserActionTypes.UPDATE_EMAIL)
  }

  // MUI select handlers
  const handleDistrictChange = (value) => {
    setSelectedDistrict(value)
    setEditForm({
      ...editForm,
      schoolDistrictID: value.schoolDistrictID
    })

    const districtsSchoolsIndex = Schools.findIndex(x => x.schoolDistrictID === value.schoolDistrictID)
    if (districtsSchoolsIndex !== -1) {
      const districtsSchools = Schools[districtsSchoolsIndex].schools
      // Sets the district to the selected option, and repopulates the school options to the new district
      setSchoolOptions(districtsSchools)

      // If the current selected school is not in the new district, then reset it
      const schoolIndex = [...districtsSchools].findIndex(x => x.schoolID === selectedSchool.schoolID)
      if (schoolIndex === -1) {
        setSelectedSchool('')
      }
    } else {
      // District does not have any schools
      setSchoolOptions([])
      setSelectedSchool('')
    }
  }

  const handleSchoolChange = (value) => {
    const index = schoolOptions.findIndex(x => x.schoolID === value.schoolID)

    if (index !== -1) {
      setSelectedSchool(value)
      setEditForm({
        ...editForm,
        schoolID: value.schoolID
      })
    } else {
      // Should not happen
      console.log('Can\'t find school id')
    }
  }

  const handleCohortSelect = (value) => {
    const index = cohortOptions.findIndex(x => x.cohortID === value.cohortID)

    if (index !== -1) {
      setSelectedCohort(value)
      setEditForm({
        ...editForm,
        cohortID: value.cohortID
      })
    } else {
      console.log('no such cohort')
    }
  }

  const handleReset = () => {
    setEditForm(defaultEditUserForm)
    setSelectedDistrict('')
    setSchoolOptions([])
    setSelectedSchool('')
  }

  const updateRow = (fellow, type, newEmailAddress = null) => {
    const newRows = [...rows]
    const index = newRows.findIndex(x => x.userID === fellow)
    const oldRow = newRows[index]

    let newRow = { ...oldRow, emailAddress: newEmailAddress || oldRow?.emailAddress }

    if (type === updateUserActionTypes.UPDATE_USER) {
      const { schoolID = '', schoolName = 'N/A' } = selectedSchool
      const { schoolDistrictID, districtName } = selectedDistrict
      const { cohortID, cohortName } = selectedCohort

      newRow = {
        ...newRow,
        schoolID,
        school: schoolName,
        schoolDistrictID,
        district: districtName,
        cohortID,
        cohort: cohortName
      }
    }

    newRows[index] = newRow
    setRows(newRows)

    // Reset the edit form to the default
    handleReset()
  }

  // Once the user has been successfully updated, alert and repopulate the updated row with the new information
  const fireSuccess = (fellow) => {
    NotificationToast(false, 'Sucessfully Updated User')

    // Update Rows with new information
    updateRow(fellow, updateUserActionTypes.UPDATE_USER)
  }

  const fireFailure = () => {
    NotificationToast(true, 'Unable to Update User')
    handleReset()
  }

  // // Handle Save Edits
  const handleSaveRow = (row) => {
    // Set the form for the api
    const { schoolID } = selectedSchool
    const { schoolDistrictID } = selectedDistrict
    const { cohortID } = selectedCohort

    // If all the information has not been supplied then stop the save and alert the user
    const schoolDistrictNotChosen = Boolean(districtOptions.length) && !schoolDistrictID
    if (schoolDistrictNotChosen || !cohortID) {
      return NotificationToast(false, 'Please supply all information before saving.', true)
    } else {
      // Else be certain all fields are populated (this accounts for if only one or two fields have changed)
      const finalEditForm = {
        ...editForm,
        cohortID: editForm.cohortID === '' ? cohortID : editForm.cohortID,
        schoolID: editForm.schoolID === '' ? !schoolID ? '' : schoolID : editForm.schoolID,
        schoolDistrictID: editForm.schoolDistrictID === '' ? schoolDistrictID : editForm.schoolDistrictID
      }
      // Update the db
      dispatch(updateFellowConfig(finalEditForm, fireSuccess, fireFailure))

      // Exit edit-view for the row
      setEditRow('')
    }
  }

  // ******************** Column Sort Logic **************** //

  const [orderBy, setOrderBy] = useState('')
  const [order, setOrder] = useState('desc')

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: rowsPerPage,
      sortDirection: isAsc ? 'DESC' : 'ASC',
      sortType: property
    })
  }

  // ********************** Search Logic *********************** //
  const [searchInput, setSearchInput] = useState('')

  const handleSearch = (e) => {
    setSearchInput(e.target.value)
    if (searchInput && searchInput.length >= 3) {
      // Previously, issues were occuring if the user was on a page that was greater than 1 and made a search. The page is now being reset beforehand to avoid that.
      setPage(0)
      setFilter({
        ...filter,
        page: 1,
        sortCount: rowsPerPage,
        searchPhrase: searchInput
      })
    }

    if (e.target.value === '') {
      setPage(0)
      // Set the sortCount to current rowsPerPage instead of the default
      setFilter({
        ...defaultFilter,
        sortCount: rowsPerPage
      })
    }
  }

  const [modalOpen, setModalOpen] = useState(false)

  const [newEmailAddress, setNewEmailAddress] = useState('')

  const handleOpenModal = (userRow) => {
    if (superAdmin) {
      setEditRow(userRow)
      setNewEmailAddress(userRow?.emailAddress)

      setModalOpen(true)
    }
  }

  const handleCloseModal = () => {
    setEditRow('')
    setModalOpen(false)
  }

  const isEmailValid = useMemo(() => {
    const isValid = ValidateEmail(newEmailAddress) && !isEqual(newEmailAddress, editRow?.emailAddress)

    return isValid
  }, [newEmailAddress, editRow])

  const handleSaveEmailChange = () => {
    const userID = editRow?.userID || null
    const oldEmailAddress = editRow?.emailAddress || null

    // newEmailAddress is valid and newEmailAddress does not equal oldEmailAddress
    if (superAdmin && userID && newEmailAddress && oldEmailAddress && isEmailValid) {
      dispatch(updateUserEmailAndValidation(userID, newEmailAddress, oldEmailAddress, fireUpdateEmailSuccess, fireUpdateEmailFailure))
    }
  }

  const fireUpdateEmailSuccess = (userID, newEmailAddress) => {
    handleCloseModal()
    updateRow(userID, updateUserActionTypes.UPDATE_EMAIL, newEmailAddress)
    NotificationToast(false, 'Successfully Updated User\'s Email')
  }

  const fireUpdateEmailFailure = (message) => { NotificationToast(true, message) }

  return (
    <>
      <Grid container direction='column'>
        <Grid item container style={{ marginBottom: '2em' }}>
          {/* Page Title */}
          <Typography variant='h4' style={{ fontSize: '20px' }}>Innovation Fellows</Typography>
        </Grid>

        {/* Top Pagination display and search input */}
        <Grid item container direction='row' style={{ marginBottom: '1em' }}>
          <Grid item container alignContent='flex-end' xs={7}>
            <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none', fontWeight: 400, fontSize: '16px' }}>
              Displaying {totalIfFellows === 0 ? 0 : (page * rowsPerPage) + 1} to {rows && page * rowsPerPage + rowsPerPage > totalIfFellows ? totalIfFellows : page * rowsPerPage + rowsPerPage} of {totalIfFellows}
            </Typography>
          </Grid>
          <Grid item container justifyContent='flex-end' xs={5}>
            <OutlinedInput
              className={classes.searchInput}
              size='small'
              margin='dense'
              fullWidth
              inputProps={{ style: { border: 'none', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '5px' } }}
              placeholder='Search...'
              classes={{ input: classes.inputPlaceholder }}
              startAdornment={
                <SearchRounded style={{ color: theme.palette.grey.dark, fontSize: '20px' }} />
              }
              value={searchInput}
              onChange={(e) => handleSearch(e)}
            />
          </Grid>
        </Grid>

        {/* Data Table */}
        <Grid item container direction='column' style={{ marginBottom: '1em' }}>
          <TableContainer elevation={0} style={{ border: `solid 1px ${theme.palette.grey.medium}` }} component={Paper}>
            <Table>
              <TableHead style={{ backgroundColor: theme.palette.grey.lighter }}>
                <TableRow>
                  {/* Sortable Table Column Headers */}
                  {headCells.map(header => {
                    return (
                      <TableCell
                        key={header.id}
                        align={header.align}
                        style={{ padding: '16px' }}
                        sortDirection={orderBy === header.id ? 'asc' : false}
                      >
                        <TableSortLabel
                          active={orderBy === header.id}
                          direction={orderBy === header.id ? order : 'asc'}
                          onClick={() => handleRequestSort(header.id)}
                        >
                          <Typography variant='h5' style={{ color: theme.palette.grey.dark }}>{header.label}</Typography>
                          {orderBy === header.id ? (
                            <span className={classes.visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    )
                  })}
                  <TableCell align='left' style={{ padding: '16px' }} />
                  {Boolean(superAdmin) && <TableCell align='left' />}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  return (
                    editRow === row.userID
                      ? (
                        // Row is being edited
                        <TableRow key={`edit-index-${row.userID}`}>
                          <TableCell>
                            <Grid item container direction='row' alignItems='center'>
                              <Avatar src={`${row.avatar}?${now}`} className={classes.avatarSize} />
                              <Typography variant='body1' style={{ marginLeft: '.5em', fontWeight: 600 }}>{row.name}</Typography>
                            </Grid>
                          </TableCell>
                          <TableCell>
                            <Select
                              variant='outlined'
                              margin='dense'
                              fullWidth
                              classes={{ root: classes.searchInput, selectMenu: classes.statusSelect }}
                              displayEmpty
                              value={selectedSchool}
                              onChange={(e) => handleSchoolChange(e.target.value)}
                              style={{ padding: 0 }}
                              disabled={schoolOptions.length === 0}
                              renderValue={(selected) => !selected ? '--' : selected.schoolName}
                            >
                              <MenuItem value='' disabled>Choose School...</MenuItem>
                              {districtOptions && districtOptions.length && schoolOptions && schoolOptions.length &&
                                schoolOptions.map(school => {
                                  return (
                                    <MenuItem key={school.schoolID} value={school}>{school.schoolName}</MenuItem>
                                  )
                                })}
                            </Select>
                          </TableCell>
                          <TableCell>
                            <Select
                              variant='outlined'
                              margin='dense'
                              fullWidth
                              displayEmpty
                              classes={{ root: classes.searchInput, selectMenu: classes.statusSelect }}
                              defaultValue=''
                              value={selectedDistrict}
                              onChange={(e) => handleDistrictChange(e.target.value)}
                              style={{ padding: 0 }}
                              renderValue={(selected) => !selected ? 'Choose District...' : selected.districtName}
                            >
                              <MenuItem value={{}} disabled>Choose District...</MenuItem>
                              {districtOptions && districtOptions.length &&
                                districtOptions.map(district => {
                                  return (
                                    <MenuItem key={district.schoolDistrictID} value={district}>{district.districtName}</MenuItem>
                                  )
                                })}
                            </Select>
                          </TableCell>
                          <TableCell>
                            <Select
                              variant='outlined'
                              margin='dense'
                              fullWidth
                              displayEmpty
                              classes={{ root: classes.searchInput, selectMenu: classes.statusSelect }}
                              value={selectedCohort}
                              onChange={(e) => handleCohortSelect(e.target.value)}
                              style={{ padding: 0 }}
                              defaultValue=''
                              renderValue={(selected) => !selected || selectedCohort === '' ? 'Choose Cohort...' : selected.cohortName}
                            >
                              <MenuItem value='' disabled>Choose Cohort...</MenuItem>
                              {cohortOptions && cohortOptions.length &&
                                cohortOptions.map(cohort => {
                                  return (
                                    <MenuItem key={cohort.cohortID} value={cohort}>{cohort.cohortName}</MenuItem>
                                  )
                                })}
                            </Select>
                          </TableCell>
                          <TableCell align='right'>
                            <Tooltip title='Save'>
                              <IconButton
                                className={classes.editIcon}
                                onClick={() => handleSaveRow(row)}
                              >
                                <SaveOutlined style={{ color: theme.palette.purple.darkest }} />
                              </IconButton>
                            </Tooltip>
                          </TableCell>

                          {Boolean(superAdmin) &&
                            <TableCell align='right'>
                              <Tooltip title='Cancel'>
                                <IconButton
                                  className={classes.editIcon}
                                  style={{ color: '#e24646' }}
                                  onClick={(e) => { setEditRow(''); handleReset() }}
                                >
                                  <Cancel />
                                </IconButton>
                              </Tooltip>
                            </TableCell>}

                        </TableRow>
                      )
                      : (
                        // Row is not being edited
                        <TableRow key={`index-${row.userID}`}>
                          <TableCell>
                            <Grid item container direction='row' alignItems='center'>
                              <Avatar src={`${row.avatar}?${now}`} className={classes.avatarSize} />
                              <Typography variant='body1' style={{ marginLeft: '.5em', fontWeight: 600 }}>{row.name}</Typography>
                            </Grid>
                          </TableCell>
                          <TableCell>
                            <Typography variant='body1'>{row.school}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant='body1'>{row.district}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant='body1'>{row.cohort}</Typography>
                          </TableCell>
                          <TableCell align='right'>
                            <Tooltip title='Edit'>
                              <IconButton
                                className={classes.editIcon}
                                onClick={() => handleEditRow(row)}
                              >
                                <ModifiedEditIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>

                          {Boolean(superAdmin) &&
                            <TableCell align='right'>
                              <Tooltip title='Advanced'>
                                <IconButton
                                  className={classes.editIcon}
                                  style={{ color: '#29356a' }}
                                  onClick={(e) => handleOpenModal(row)}
                                >
                                  <Settings />
                                </IconButton>
                              </Tooltip>
                            </TableCell>}

                        </TableRow>
                      )
                  )
                })}
                {/* Data Array has reached it's length: */}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 10 }}>
                    <TableCell colSpan={5}>
                      <Typography variant='body1' style={{ color: theme.palette.grey.medium, textAlign: 'center' }}>{totalIfFellows === 0 ? 'No Results' : 'End of List'}</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* Bottom Pagination Controls */}
        <Grid item container direction='row' style={{ marginBottom: '4em' }}>
          {/* Rows per Page Selection */}
          <Grid item container direction='row' xs={6} justifyContent='flex-start'>
            <Grid item style={{ marginRight: '.2em', display: 'flex', alignItems: 'flex-end' }}>
              <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>Display</Typography>
            </Grid>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                variant='outlined'
                size='small'
                defaultValue={10}
                value={rowsPerPage}
                onChange={(e) => handleChangeRowsPerPage(e)}
                classes={{ root: classes.searchInput, selectMenu: classes.statusSelect }}
                style={{ padding: 0 }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </Grid>
            <Grid item style={{ marginLeft: '.4em', display: 'flex', alignItems: 'flex-end' }}>
              <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>entries</Typography>
            </Grid>
          </Grid>
          {/* Pagination/ Page Selection */}
          <Grid item container xs={6} justifyContent='flex-end'>
            <Pagination
              color='primary'
              classes={{ root: classes.pagination }}
              count={totalIfFellows ? Math.ceil(totalIfFellows / rowsPerPage) : 0}
              page={page === 0 ? 1 : page + 1}
              onChange={handleChangePage}
              shape='rounded'
            />
          </Grid>
        </Grid>
      </Grid>

      <DeleteUserPopover
        confirmAnchorEl={confirmAnchorEl}
        setConfirmAnchorEl={setConfirmAnchorEl}
        openConfirmPopover={openConfirmPopover}
        handleConfirmDeleteUser={handleConfirmDeleteUser}
        userType={adminPageOptions.INNOVATION_FELLOWS}
        handleConfirmPopClose={handleConfirmPopClose}
        classes={classes}
        modalOpen={modalOpen}
        handleCloseModal={handleCloseModal}
      />

      {Boolean(superAdmin) &&
        <UpdateUserDialog
          classes={classes}
          theme={theme}
          editRow={editRow}
          modalOpen={modalOpen}
          handleCloseModal={handleCloseModal}
          handleConfirmPopover={handleConfirmPopover}
          newEmailAddress={newEmailAddress}
          setNewEmailAddress={setNewEmailAddress}
          handleSaveEmailChange={handleSaveEmailChange}
          isEmailValid={isEmailValid}
          selectedAction={selectedAction}
          setSelectedAction={setSelectedAction}
        />}
    </>
  )
}

export default InnovationFellows
