import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { isEqual } from 'lodash'
import {
  Grid, Typography, useTheme, OutlinedInput, Table, TableBody, TableContainer, TableCell, TableHead, TableRow,
  Paper, TableSortLabel, Select, MenuItem, Avatar, Button, Dialog, DialogContent, DialogActions, DialogTitle,
  TextField, Chip,
  IconButton, Tooltip
} from '@material-ui/core'
import { Autocomplete, Pagination } from '@material-ui/lab'
import {
  SearchRounded, AddRounded, CancelRounded,
  DeleteOutline
} from '@material-ui/icons'
import { adminPageOptions, explorerTypes } from '../../../../utils'
import { NotificationToast } from '../../tools'
import {
  getAssessorsList, getExploreArtifacts, addNewAssessors,
  removeAssessor
} from '../../../../redux/actions'

// add assessor modal: typing debounce timer init
let timer

const AdminMCEAssessors = (props) => {
  const { classes, totalAssessors = 0, mceAssessors = [], filter = {}, setFilter = () => {}, defaultFilter = {}, activeButton } = props
  const theme = useTheme()
  const [now] = useState(moment().unix())
  const dispatch = useDispatch()
  const [loadingUsers, setLoadingUsers] = useState(false)

  // ********* ASSESSOR ADD/REMOVE LOGIC *************** //
  const [addAssessorOpen, setAddAssessorOpen] = useState(false)
  const [allUsers, setAllUsers] = useState([])
  const [newAssessors, setNewAssessors] = useState([])

  // autocomplete opts for add assessor modal
  const allOpts = useMemo(() => {
    // use the list of currently selected asssessors (can be an empty array) as the base for the opts
    let currentOpts = [...newAssessors]

    // if we have fetched all users from the filtered es call
    if (allUsers) {
      // concat the two array, removing any duplicates
      const newOpts = currentOpts.concat(allUsers.filter(user => currentOpts.findIndex(x => x.userID === user.userID) < 0))
      currentOpts = newOpts
    }

    // return the full opts list
    return currentOpts
  }, [newAssessors, allUsers])

  // add assessor modal: fetch the list of available users from the es record
  const fetchAllUsers = useCallback(async (filter) => {
    const defaultFilter = filter || { explorerType: explorerTypes.USERS }
    setLoadingUsers(true)
    return dispatch(getExploreArtifacts(defaultFilter))
  }, [dispatch])

  // add assessor modal: async callback to handle filtering by search text if the user has typed anything in the input (value)
  const getAssessorListOptions = useCallback(async (value) => {
    // defaulted filter is just the explorer type with empty search
    const filter = { explorerType: explorerTypes.USERS, search: '' }

    // if we have a value of 3 char or more, add it to the filter
    if (value && value.length >= 3) {
      filter.search = value
    }

    // use this filter to fetch current users in es that match
    const allUsersList = await fetchAllUsers(filter)

    // once the user list has been retrieved, reduce the information into smaller objects and set to state
    if (allUsersList) {
      const { posts = [] } = allUsersList
      let userList = []
      const userArr = posts.reduce((arr, user) => {
        if (user) {
          const { userID, profileAvatarPath, fullName, profileID, roleID, roleName, cohortName, districtName, schoolName, nickName } = user
          arr.push({ userID, profileAvatarPath, fullName, profileID, roleID, roleName, cohortName, districtName, schoolName, nickName })
        }
        return arr
      }, [])

      userList = userArr

      // double check that any currently selected (but not yet added) assessors are also in the user list
      if (newAssessors && newAssessors.length) {
        newAssessors.forEach(assessor => {
          if (!userList.find(x => x.userID === assessor.userID)) {
            userList.push(assessor)
          }
        })
      }

      // set these users to state
      setAllUsers(userList)
    }
    // let the autocomplete know we are done
    setLoadingUsers(false)
  }, [fetchAllUsers, newAssessors])

  // add assessor modal: debounced search while the user is typing in the autocomplete input
  const handleTimedSearch = (e, val) => {
    e.preventDefault()
    clearTimeout(timer)
    timer = setTimeout(() => {
      getAssessorListOptions(val)
    }, 1000)
  }

  // open the dialog and fetch the current users
  const handleAddAssessorOpen = async () => {
    setAddAssessorOpen(true)
    getAssessorListOptions()
  }

  // close the modal and reset state items
  const handleAddAssessorClose = () => {
    if (loadingUsers) { setLoadingUsers(false) }
    setAddAssessorOpen(false)
    setAllUsers([])
    setNewAssessors([])
  }

  // remove an assessor chip from the currently selected array
  const handleRemoveChip = (id) => {
    const tempNewAss = [...newAssessors]
    const removeIndex = tempNewAss.findIndex(x => x.userID === id)

    if (removeIndex !== -1) {
      tempNewAss.splice(removeIndex, 1)
    }
    setNewAssessors(tempNewAss)
  }

  // success function for both add and delete functions
  const fireSuccess = (action) => {
    let text = 'Added New Assessor(s)'
    if (action) { text = 'Removed Assessor' }
    NotificationToast(false, `Sucessfully ${text}`)
    dispatch(getAssessorsList())
  }

  // failure function for both add and delete functions
  const fireFailure = (action) => {
    let text = 'Add New Assessor(s)'
    if (action) { text = 'Remove Assessor' }
    NotificationToast(true, `Unable to ${text}`)
  }

  // save the new assessors
  const handleSaveNewAssessors = () => {
    // filter the assessor array into just the IDs of the selected users
    const idsArr = newAssessors.map(x => x.userID)

    if (idsArr.length) {
      dispatch(addNewAssessors(idsArr, fireSuccess, fireFailure))
    }

    handleAddAssessorClose()
  }

  // ******* ADD ASSESSOR LOGIC END ********//

  // remove a single assessor
  const handleRemoveAssessor = (id) => {
    if (id) {
      dispatch(removeAssessor(id, fireSuccess, fireFailure))
    }
  }

  // ******************** Data Creation ******************** //

  // If the filter was changed, fetch the fellows with the new filter
  useEffect(() => {
    if (filter && defaultFilter && activeButton === adminPageOptions.MCE_ASSESSORS) {
      if (!isEqual(filter, defaultFilter)) {
        dispatch(getAssessorsList(filter))
      }
    }
  }, [dispatch, filter, defaultFilter, setFilter, activeButton])

  // Formats the array returned from the dispatch for the data table
  const createData = (assessorID, districtName, fullName, profileAvatarPath, profileID, roleID, roleName, schoolDistrictID, schoolID, schoolName) => {
    return { assessorID, districtName, fullName, profileAvatarPath, profileID, roleID, roleName, schoolDistrictID, schoolID, schoolName }
  }

  // Data rows for the displayed table
  const [rows, setRows] = useState([])

  useEffect(() => {
    if (mceAssessors) {
      if (mceAssessors.length) {
        const newRows = []
        mceAssessors.forEach(assessor => {
          const { assessorID, districtName, name, profileAvatarPath, profileID, roleID, roleName, schoolDistrictID, schoolID, schoolName } = assessor
          newRows.push(createData(assessorID, districtName, name, profileAvatarPath, profileID, roleID, roleName, schoolDistrictID, schoolID, schoolName))
        })
        setRows(newRows)
      } else {
        setRows([])
      }
    }
  }, [mceAssessors])

  // Column Headers
  const headCells = [
    { id: 'fullName', label: 'Name', align: 'left' },
    { id: 'roleName', label: 'Role', align: 'left' },
    { id: 'districtName', label: 'District', align: 'left' },
    { id: 'schoolName', label: 'School', align: 'left' }
  ]

  // ********************* Pagination Logic: **************** //
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const handleChangePage = (event, value) => {
    if (value >= 0) {
      setPage(value - 1)
      setFilter({
        ...filter,
        sortCount: rowsPerPage,
        page: value
      })
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: event.target.value
    })
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalAssessors - page * rowsPerPage)

  // ******************** Column Sort Logic **************** //

  const [orderBy, setOrderBy] = useState('')
  const [order, setOrder] = useState('desc')

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: rowsPerPage,
      sortDirection: isAsc ? 'DESC' : 'ASC',
      sortType: property
    })
  }

  // ********************** Search Logic *********************** //
  const [searchInput, setSearchInput] = useState('')

  const handleSearch = (e) => {
    setSearchInput(e.target.value)
    if (searchInput && searchInput.length >= 3) {
      // Previously, issues were occuring if the user was on a page that was greater than 1 and made a search. The page is now being reset beforehand to avoid that.
      setPage(0)
      setFilter({
        ...filter,
        page: 1,
        sortCount: rowsPerPage,
        searchPhrase: searchInput
      })
    }

    if (e.target.value === '') {
      setPage(0)
      // Set the sortCount to current rowsPerPage instead of the default
      setFilter({
        ...defaultFilter,
        sortCount: rowsPerPage
      })
    }
  }

  return (
    <Grid container direction='column'>

      <Dialog
        open={addAssessorOpen}
        onClose={() => handleAddAssessorClose()}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle disableTypography>
          <Typography variant='h4' style={{ margin: '.5em' }}>Add Assessor</Typography>
        </DialogTitle>
        <DialogContent
          dividers
        >
          <Grid container direction='column'>
            <Grid item container direction='column' style={{ margin: '1em', marginBottom: '1.5rem' }}>
              <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}>Choose a User:</Typography>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchRounded className={classes.searchSvg} />
                </div>
                <Autocomplete
                  fullWidth
                  value={newAssessors}
                  multiple
                  loading={loadingUsers}
                  loadingText='Loading Options...'
                  defaultValue={[]}
                  onChange={(e, users) => { e.preventDefault(); setNewAssessors(users) }}
                  options={allOpts}
                  filterSelectedOptions
                  disableClearable
                  onInputChange={(e, newValue) => handleTimedSearch(e, newValue)}
                  getOptionLabel={(option) => option && option.fullName ? option.fullName : ''}
                  renderOption={(option, { selected }) => (
                    <Grid container direction='row' justifyContent='center'>
                      <Grid item xs={1} container direction='column'>
                        <Avatar src={`${option?.profileAvatarPath}?${now}`} className={classes.avatarSize} />
                      </Grid>
                      <Grid item xs={11} container direction='column'>
                        <Typography style={{ fontWeight: 600 }}>{option.fullName}<span style={{ fontWeight: 400 }}>{option?.nickName ? ` (${option?.nickName})` : ''}</span></Typography>
                        <Typography style={{ fontStyle: 'italic', fontSize: 12 }}>{option.roleName}</Typography>
                      </Grid>
                    </Grid>
                  )}
                  classes={{
                    inputRoot: classes.inputInput,
                    input: classes.inputRoot
                  }}
                  renderTags={(value, getTagProps) => {
                    return value.map((option, index) => (
                      <Chip
                        key={`user-chip-${index}`}
                        label={option.fullName}
                        {...getTagProps({ index })}
                        onDelete={() => { handleRemoveChip(option?.userID) }}
                        deleteIcon={<CancelRounded style={{ color: 'white' }} />}
                        style={{
                          backgroundColor: theme.palette.purple.darkest,
                          color: 'white'
                        }}
                      />
                    ))
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      placeholder='Search for a user...'
                    />
                  )}
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            color='primary'
            style={{
              margin: '.5em 1em',
              fontWeight: '600'
            }}
            onClick={(e) => { e.preventDefault(); handleAddAssessorClose() }}
          >
            Cancel
          </Button>

          <Button
            variant='contained'
            color='primary'
            style={{
              margin: '.5em 1em',
              fontWeight: '600'
            }}
            onClick={(e) => { e.preventDefault(); handleSaveNewAssessors() }}
          >
            Save
          </Button>

        </DialogActions>
      </Dialog>

      <Grid item container style={{ marginBottom: '2em' }}>
        {/* Page Title */}
        <Grid item container xs={9} justifyContent='flex-start'>
          <Typography variant='h4' style={{ fontSize: '20px' }}>Assessors</Typography>
        </Grid>
        <Grid item container xs={3} justifyContent='flex-end'>
          <Button
            color='primary'
            variant='contained'
            style={{ fontWeight: '600' }}
            startIcon={
              <AddRounded className={classes.addIcon} />
            }
            onClick={() => handleAddAssessorOpen()}
          >
            Add Assessor
          </Button>
        </Grid>
      </Grid>

      {/* Top Pagination display and search input */}
      <Grid item container direction='row' style={{ marginBottom: '1em' }}>
        <Grid item container alignContent='flex-end' xs={7}>
          <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none', fontWeight: 400, fontSize: '16px' }}>
            Displaying {totalAssessors === 0 ? 0 : (page * rowsPerPage) + 1} to {rows && page * rowsPerPage + rowsPerPage > totalAssessors ? totalAssessors : page * rowsPerPage + rowsPerPage} of {totalAssessors}
          </Typography>
        </Grid>
        <Grid item container justifyContent='flex-end' xs={5}>
          <OutlinedInput
            className={classes.searchInput}
            size='small'
            margin='dense'
            fullWidth
            inputProps={{ style: { border: 'none', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '5px' } }}
            placeholder='Search...'
            classes={{ input: classes.inputPlaceholder }}
            startAdornment={
              <SearchRounded style={{ color: theme.palette.grey.dark, fontSize: '20px' }} />
            }
            value={searchInput}
            onChange={(e) => handleSearch(e)}
          />
        </Grid>
      </Grid>

      {/* Data Table */}
      <Grid item container direction='column' style={{ marginBottom: '1em' }}>
        <TableContainer elevation={0} style={{ border: `solid 1px ${theme.palette.grey.medium}` }} component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: theme.palette.grey.lighter }}>
              <TableRow>
                {/* Sortable Table Column Headers */}
                {headCells.map(header => {
                  return (
                    <TableCell
                      key={header.id}
                      align={header.align}
                      style={{ padding: '16px' }}
                      sortDirection={orderBy === header.id ? 'asc' : false}
                    >
                      <TableSortLabel
                        active={orderBy === header.id}
                        direction={orderBy === header.id ? order : 'asc'}
                        onClick={() => handleRequestSort(header.id)}
                      >
                        <Typography variant='h5' style={{ color: theme.palette.grey.dark }}>{header.label}</Typography>
                        {orderBy === header.id ? (
                          <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  )
                })}
                <TableCell align='left' style={{ padding: '16px' }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                const { assessorID, fullName, profileAvatarPath, schoolName, districtName, roleName } = row
                return (
                  <TableRow key={`index-${assessorID}`}>
                    <TableCell>
                      <Grid item container direction='row' alignItems='center'>
                        <Avatar src={`${profileAvatarPath}?${now}`} className={classes.avatarSize} />
                        <Typography variant='body1' style={{ marginLeft: '.5em', fontWeight: 600 }}>{fullName}</Typography>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body1' style={{ marginLeft: '.5em', fontWeight: 600 }}>{roleName}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body1' style={{ marginLeft: '.5em', fontWeight: 600 }}>{districtName}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body1' style={{ marginLeft: '.5em', fontWeight: 600 }}>{schoolName}</Typography>
                    </TableCell>
                    <TableCell>
                      <Tooltip arrow placement='top' title='Remove user as a Micro-Credential Assessor' enterDelay={1000} enterNextDelay={1000}>
                        <IconButton size='small' onClick={() => handleRemoveAssessor(assessorID)}>
                          <DeleteOutline color='error' />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )
              })}
              {/* Data Array has reached it's length: */}
              {emptyRows > 0 && (
                <TableRow style={{ height: 10 }}>
                  <TableCell colSpan={12}>
                    <Typography variant='body1' style={{ color: theme.palette.grey.medium, textAlign: 'center' }}>{totalAssessors === 0 ? 'No Results' : 'End of List'}</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* Bottom Pagination Controls */}
      <Grid item container direction='row' style={{ marginBottom: '4em' }}>
        {/* Rows per Page Selection */}
        <Grid item container direction='row' xs={6} justifyContent='flex-start'>
          <Grid item style={{ marginRight: '.2em', display: 'flex', alignItems: 'flex-end' }}>
            <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>Display</Typography>
          </Grid>
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <Select
              variant='outlined'
              size='small'
              defaultValue={10}
              value={rowsPerPage}
              onChange={(e) => handleChangeRowsPerPage(e)}
              classes={{ root: classes.searchInput, selectMenu: classes.statusSelect }}
              style={{ padding: 0 }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </Grid>
          <Grid item style={{ marginLeft: '.4em', display: 'flex', alignItems: 'flex-end' }}>
            <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>entries</Typography>
          </Grid>
        </Grid>
        {/* Pagination/ Page Selection */}
        <Grid item container xs={6} justifyContent='flex-end'>
          <Pagination
            color='primary'
            classes={{ root: classes.pagination }}
            count={totalAssessors ? Math.ceil(totalAssessors / rowsPerPage) : 0}
            page={page === 0 ? 1 : page + 1}
            onChange={handleChangePage}
            shape='rounded'
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AdminMCEAssessors
