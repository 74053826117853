import { useEffect } from 'react'
// import { useSelector } from 'react-redux'
import { Grid, Typography } from '@material-ui/core'
import { HourglassEmptyRounded, SwapHorizontalCircle, CheckCircleRounded, CancelRounded } from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'
import { ConfirmToast } from '../components/ui/tools'
const Domains = require('./domains')
const defaultPrefix = process.env.REACT_APP_AWS_S3_DEFAULT_AVATAR_PREFIX
const avatarDistributionURL = process.env.REACT_APP_CLOUD_FRONT_BASE

const statusStyleObj = {
  NEEDS_REVIEW: {
    text: 'Needs Review',
    icon: HourglassEmptyRounded
  },
  NEEDS_CHANGES: {
    text: 'Changes Requested',
    icon: SwapHorizontalCircle
  },
  APPROVED: {
    text: 'Approved',
    icon: CheckCircleRounded
  },
  DENIED: {
    text: 'Denied',
    icon: CancelRounded
  }
}

// Formats a banner for an artifact based on that artifacts submission status
export const getFormatStatus = (dbStatus, textColor) => {
  let styleObj = statusStyleObj.NEEDS_REVIEW
  if (dbStatus && dbStatus !== 'NA') { styleObj = statusStyleObj[dbStatus] }

  const { icon: StatIcon, text = '' } = styleObj

  return (
    <Grid item container direction='row' alignItems='center'>
      <StatIcon style={{ fontSize: '16px', color: textColor, marginRight: '.2em' }} />
      <Typography variant='h6' style={{ color: textColor, textTransform: 'none', fontWeight: '400' }}>{text}</Typography>
    </Grid>
  )
}

export const formatLEDBackgroundObj = (key) => {
  const splitKeys = key && typeof key === 'string' ? key.split('-') : ['circles', 'default']

  let backgroundObj = {
    color: 'default',
    type: 'circles'
  }

  if (splitKeys.length && splitKeys.length === 2) {
    backgroundObj = {
      type: splitKeys[0],
      color: splitKeys[1]
    }
  }

  return backgroundObj
}

export const ValidateEmail = (email) => {
  const isValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
  return isValid
}

export const ValidateUrl = (url) => {
  const isValid = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/gm.test(url)
  if (isValid) {
    let hasDomain = false
    Domains.forEach((domain) => {
      if (url.includes(`.${domain}`)) {
        hasDomain = true
      }
    })
    if (hasDomain) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

export const handleExternalLinkClick = async (linkUrl) => {
  // Asking if user wants to leave Ed Farm
  const toastMessage = {
    msg: 'Heads Up!',
    txt: 'Following this link opens a new browser tab and sends you to a website outside of Ed Farm. Do you wish to continue?'
  }

  const confirmMessage = await ConfirmToast(toastMessage)
  let newUrl = `//${linkUrl}`

  if (linkUrl.indexOf('://') > 0 || linkUrl.indexOf('//') === 0) {
    newUrl = linkUrl
  }

  if (confirmMessage) {
    window.open(newUrl, '_blank')
  }
  return false
}

export const buildUserAvatarPath = (profileAvatarKey, userID) => {
  const [avatarKey] = profileAvatarKey.split('/').slice(-1)
  if (profileAvatarKey.includes(defaultPrefix)) {
    const avatarPath = `${avatarDistributionURL}/${defaultPrefix}/${avatarKey}`
    return avatarPath
  } else {
    if (avatarKey === null) {
      return null
    } else if (avatarKey === 'null') {
      return null
    } else {
      const avatarPath = `${avatarDistributionURL}/avatar/${userID}`
      return avatarPath
    }
  }
}

export const formattedDetails = (reviewInfo, grades, subjects, standards, learnerWorkList, ledList) => {
  const tempCopy = Object.assign({}, reviewInfo)

  // Targets
  if (tempCopy && tempCopy.targets) {
    tempCopy.targets = tempCopy.targets.reduce((arr, targetInfo) => {
      if (targetInfo && targetInfo.targetID && targetInfo.targetName) {
        const { targetID, targetName } = targetInfo
        arr.push({ targetID, targetName })
      }
      return arr
    }, [])
  }

  // Links
  if (tempCopy && tempCopy.links) {
    tempCopy.links = tempCopy.links.reduce((arr, linkInfo) => {
      if (linkInfo && linkInfo.link && linkInfo.linkID) {
        const { link, linkID } = linkInfo
        arr.push({ link, linkID })
      }
      return arr
    }, [])
  }

  // Attached Documents
  if (tempCopy && tempCopy.attachments) {
    tempCopy.attachments = tempCopy.attachments.reduce((arr, attachmentInfo) => {
      if (attachmentInfo && attachmentInfo.attachmentID && attachmentInfo.fileName) {
        const { attachmentID = '', file = '', fileName = '', filePath = '', fileType = '' } = attachmentInfo
        arr.push({ attachmentID, file, fileName, filePath, fileType })
      }
      return arr
    }, [])
  }

  // Grades
  if (tempCopy && tempCopy.grades && tempCopy.grades.length) {
    tempCopy.grades = tempCopy.grades.reduce((arr, gradeID) => {
      if (grades && grades.length) {
        const index = grades.findIndex(x => x.gradeID === gradeID)
        if (index !== -1) {
          const gradeName = grades[index].gradeName
          arr.push(gradeName)
        }
      }
      return arr
    }, [])
  }

  // ISTE Standards
  if (tempCopy && tempCopy.ISTEStandards && tempCopy.ISTEStandards.length) {
    tempCopy.ISTEStandards = tempCopy.ISTEStandards.reduce((arr, standardID) => {
      if (standards && standards.length) {
        const index = standards.findIndex(x => x.standardID === standardID)
        if (index !== -1) {
          const standardName = standards[index].standardName
          arr.push(standardName)
        }
      }
      return arr
    }, [])
  }

  // Core Subject
  if (tempCopy && tempCopy.coreSubjectID) {
    if (subjects && subjects.length) {
      const index = subjects.findIndex(x => x.subjectID === tempCopy.coreSubjectID)
      if (index !== -1) {
        tempCopy.coreSubject = subjects[index].subjectName
      }
    }
  }

  // Secondary Subject
  if (tempCopy && tempCopy.secondarySubjectID) {
    if (subjects && subjects.length) {
      const index = subjects.findIndex(x => x.subjectID === tempCopy.secondarySubjectID)
      if (index !== -1) {
        tempCopy.secondarySubject = subjects[index].subjectName
      }
    }
  }

  // Linked LED's
  if (tempCopy.ledIDs) {
    if (ledList && ledList.length) {
      tempCopy.ledIDs = tempCopy.ledIDs.reduce((arr, led) => {
        const index = ledList.findIndex(x => x.ledID === led)
        if (index !== -1) {
          const ledName = ledList[index].title
          arr.push({ ledID: led, ledName })
        }

        return arr
      }, [])
    }
  }

  // Asscociated LEDs
  if (tempCopy && tempCopy.projectLEDs && tempCopy.projectLEDs.length) {
    if (ledList && ledList.length) {
      const ledNames = []
      tempCopy.projectLEDs.forEach(led => {
        const index = ledList.findIndex(x => x.ledID === led)
        if (index !== -1) {
          const { ledID, title } = ledList[index]
          ledNames.push({ title, ledID })
        }
      })
      tempCopy.projectLEDs = ledNames
    }
  }

  // Asscociated LWs
  if (tempCopy && tempCopy.projectLWs && tempCopy.projectLWs.length) {
    if (learnerWorkList && learnerWorkList.length) {
      const lwNames = []
      tempCopy.projectLWs.forEach(lw => {
        const index = learnerWorkList.findIndex(x => x.learnerWorkID === lw)
        if (index !== -1) {
          const { learnerWorkID, learnerWorkTitle } = learnerWorkList[index]
          lwNames.push({ learnerWorkID, learnerWorkTitle })
        }
      })
      tempCopy.projectLWs = lwNames
    }
  }

  return { ...tempCopy }
}

export const useDebounceEffect = (fn, waitTime, deps
) => {
  useEffect(() => {
    const t = setTimeout(() => {
      fn.apply(undefined, deps)
    }, waitTime)

    return () => {
      clearTimeout(t)
    }
  }, [deps, fn, waitTime])
}

export const canvasPreview = (image, canvas, crop) => {
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    throw new Error('No 2d context')
  }

  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

  ctx.scale(pixelRatio, pixelRatio)
  ctx.imageSmoothingQuality = 'high'

  const cropX = crop.x * scaleX
  const cropY = crop.y * scaleY

  const centerX = image.naturalWidth / 2
  const centerY = image.naturalHeight / 2

  ctx.save()

  // 3) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY)
  // 2) Move the origin to the center of the original position
  ctx.translate(centerX, centerY)
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY)
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  )

  const base64Image = canvas.toDataURL('image/jpeg')

  ctx.restore()

  return base64Image
}

export const sanitizedHTML = (html) => {
  if (html && html.length > 0) {
    const modifiedHtml = html.replaceAll('href', 'target="blank" rel="noopener" $&')

    return modifiedHtml.substring(1, modifiedHtml.length - 1).replaceAll('<p><strong>&nbsp;</strong></p>', '')
  } else {
    return '<p>Not Available</p>'
  }
}

export const renderSkeletons = (mapLength = 1, animation = 'wave', height = 40, width) => {
  return (
    [...Array(mapLength).keys()].map(i => <Skeleton key={`skeleton-${i}-index`} animation={animation} height={height} width={width} />)
  )
}

export const createTempID = () => {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const length = 8
  let randomString = ''
  for (let i = length; i > 0; --i) randomString += chars[Math.floor(Math.random() * chars.length)]
  return randomString
}

export const formatStringForDisplay = (str) => {
  if (str) {
    str = `${!str.startsWith('\'') ? '\'' : ''}${str}${!str.endsWith('\'') ? '\'' : ''}`
  }

  return str || ''
}

export const formatStringForEditor = (str) => {
  if (str) {
    const formattedCheck = Boolean(!str.startsWith('\'') && !str.endsWidth('\''))

    if (!formattedCheck) {
      str = str.substring(1, str.length - 1)
    }
  }

  return str || ''
}

export const createGradient = (direction, color1, color2) => `linear-gradient(to ${direction}, ${color1}, ${color2})`
