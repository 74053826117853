import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'

import {
  Grid, TextField, InputLabel, Button, Typography, OutlinedInput, FormControl, IconButton
} from '@material-ui/core'

import { Visibility, VisibilityOff } from '@material-ui/icons'

import googleIcon from '../../../../assets/google-logo.png'
import { loginForms } from '../../../../utils/variables'

const MainLogin = (props) => {
  const {
    classes, email, setEmail, password, setPassword, setCurrentForm,
    handleFormEnter, handleLogin, errors, resetErrors, resetInputs,
    handleGoogleLogin
  } = props

  const [showPassword, setShowPassword] = useState(false)

  return (
    <>
      <Grid item container direction='row' alignItems='center' className={classes.loginFormContainer} justifyContent='center'>
        <Grid item xs={8}>
          <Grid item container direction='row' justifyContent='center'>
            <Typography variant='h2'>Log In</Typography>
          </Grid>
        </Grid>

        <Grid item xs={10} sm={8} style={{ marginTop: '2rem' }}>
          <Grid item container direction='row'>
            <Button
              variant='outlined'
              style={{ borderColor: '#29356a', color: '#29356a', fontWeight: '600' }}
              onClick={() => handleGoogleLogin()}
              fullWidth
              startIcon={<img src={googleIcon} alt='google-icon' />}
            >
              Log In or Sign Up with Google
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={10} sm={8} style={{ marginTop: '2rem' }}>
          <Grid item className={classes.container}>
            <Grid item className={classes.border} />
            <Typography variant='h5' className={classes.text}>Or</Typography>
            <Grid item className={classes.border} />
          </Grid>
        </Grid>

        <Grid item xs={10} sm={8} style={{ marginTop: '2rem' }}>
          <InputLabel>Email</InputLabel>
          <TextField
            id='login-email'
            variant='outlined'
            size='small'
            fullWidth
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleFormEnter}
          />
        </Grid>

        <Grid item xs={10} sm={8} style={{ marginTop: '2rem' }}>
          <InputLabel>Password</InputLabel>
          <FormControl fullWidth margin='dense'>
            <OutlinedInput
              id='login-password'
              variant='outlined'
              size='small'
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleFormEnter}
              inputProps={{ style: { border: 'none' } }}
              endAdornment={
                <IconButton
                  aria-label='toggle password visibility'
                  style={{ padding: '8px' }}
                  tabIndex='-1'
                  onClick={(e) => {
                    e.preventDefault()
                    setShowPassword(!showPassword)
                  }}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              }
            />
          </FormControl>
        </Grid>

        {errors.fieldsMissingError &&
          <Grid item container direction='column' xs={10} sm={8} style={{ marginTop: '1rem' }}>
            <Typography
              variant='caption'
              style={{ color: 'red' }}
            >
              * Please be sure you have provided an email and password.
            </Typography>
          </Grid>}

        {errors.userAlreadyLoggedIn &&
          <Grid item container direction='column' xs={10} sm={8} style={{ marginTop: '1rem' }}>
            <Typography
              variant='caption'
              style={{ color: 'red' }}
            >
              * This account is already logged in. Please refresh the page.
            </Typography>
          </Grid>}

        <Grid item xs={10} sm={8} style={{ marginTop: '2rem' }}>
          <Button
            // disabled
            fullWidth
            color='primary'
            variant='contained'
            onClick={(e) => {
              e.preventDefault()
              handleLogin()
            }}
          >
            Log In
          </Button>
        </Grid>

        <Grid item xs={10} sm={8} style={{ marginTop: '2rem' }}>
          <Grid item container direction='row' justifyContent='center'>
            <Button
              color='primary'
              variant='text'
              style={{ textTransform: 'none', fontWeight: '600', color: '#29356a', fontSize: '16px' }}
              onClick={(e) => {
                e.preventDefault()
                setCurrentForm(loginForms.FORGOT_PASSWORD)
                resetErrors()
                resetInputs()
              }}
            >
              Forgot your password?
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default withRouter(MainLogin)
