
import {
  GET_ARTIFACTS, GET_USER_LEARNER_WORK, GET_USER_LEDS,
  SET_PROFILE_PATH, SET_TARGET_ARTIFACT_LED, SET_TARGET_ARTIFACT_LW, SET_LED_OPTIONS, SET_LW_OPTIONS, SET_TARGET_ARTIFACT_PRO,
  RESET_TARGET_ARTIFACT_LED, RESET_TARGET_ARTIFACT_LW, RESET_TARGET_ARTIFACT_PRO, SET_ARTIFACT_LIKES, SET_ARTIFACT_SAVES,
  ADD_LIKE, REMOVE_LIKE, ADD_SAVE, REMOVE_SAVE, SET_TARGET_ARTIFACT_EFR, RESET_TARGET_ARTIFACT_EFR,
  SET_ARTIFACT_COMMENT_LIST
} from '../types'
import { trackPromise } from 'react-promise-tracker'
import { parseResponse } from '../../lib'
import log from '../../utils/logger'
import { artifactAbbrev, formatLEDBackgroundObj } from '../../utils'
import { cloneDeep } from 'lodash'

// UI Tech Debt: Removed api variable that wasn't being used anywhere

const api = process.env.REACT_APP_API_URL
const apiversion = process.env.REACT_APP_API_VERSION

// get full list of artifacts for a specific user
export const getUserArtifacts = (artifactFilter, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { profileID, token = '' } = getState().auth
      const tempFilter = cloneDeep(artifactFilter)

      if (!token) {
        fireFailure()
        return false
      }

      // Set the filter to show the private view if the author is viewing his/her own artys
      if (tempFilter.creatorID && tempFilter.creatorID === profileID) {
        // Check for a key indicating the user is intentionally wanting to see their public profile
        if (tempFilter.forcePublic) {
          tempFilter.isViewPrivate = false
        } else {
          tempFilter.isViewPrivate = true
        }
      }

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ filter: tempFilter })
      }

      const response = await window.fetch(`${api}/${apiversion}/user/${profileID}/artifact`, options)
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) {
        fireFailure()
      } else {
        const { data } = await response.json()
        const { artifacts } = data

        // IF the artifact is an LED, give it a default background color option
        artifacts.map(art => {
          if (art.artifactType === artifactAbbrev.LED) {
            art.backgroundObj = {
              color: 'default',
              type: 'circles'
            }

            // If the artifact has a backgroundKey, split and set the object to use the selected key
            if (art.backgroundKey) {
              const { backgroundKey } = art
              art.backgroundObj = formatLEDBackgroundObj(backgroundKey)
            }
          }
          return art
        })

        dispatch({
          type: GET_ARTIFACTS,
          payload: data
        })
        fireSuccess()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

// Get social metrics for a specific artifact
export const getSocialDetails = (artifactID, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      if (!token) {
        fireFailure()
        return false
      }

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const likePromise = window.fetch(`${api}/${apiversion}/artifact/${artifactID}/likes`, options)
      const savePromise = window.fetch(`${api}/${apiversion}/artifact/${artifactID}/saves`, options)

      // TODO add in duplicate numbers here when added
      // Get the promise results after all resolve
      const [likeResults, saveResults] = await Promise.all([likePromise, savePromise])
      const parsedLikeResponse = parseResponse(likeResults, dispatch)
      const parsedSaveResponse = parseResponse(saveResults, dispatch)
      if (!parsedLikeResponse) { return false }
      if (!parsedSaveResponse) { return false }

      if (parsedLikeResponse.error) {
        console.error('There was an error fetching likes. ')
        dispatch({
          type: SET_ARTIFACT_LIKES,
          payload: []
        })
      } else {
        const { data: likeData } = await likeResults.json()
        dispatch({
          type: SET_ARTIFACT_LIKES,
          payload: likeData
        })
      }

      if (parsedSaveResponse.error) {
        console.error('There was an error fetching saves. ')
        dispatch({
          type: SET_ARTIFACT_SAVES,
          payload: []
        })
      } else {
        const { data: saveData } = await saveResults.json()

        dispatch({
          type: SET_ARTIFACT_SAVES,
          payload: saveData
        })
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const getArtifactComments = (artifactID, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      if (!token) {
        fireFailure()
        return false
      }

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/artifact/${artifactID}/comments`, options))
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) {
        fireFailure()
      } else {
        const { data } = await response.json()

        dispatch({
          type: SET_ARTIFACT_COMMENT_LIST,
          payload: data
        })
        fireSuccess()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const insertArtifactComment = (commentInfo, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth
      const { artifactID, comment } = commentInfo

      const body = { comment }

      if (!token) {
        fireFailure()
        return false
      }

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/artifact/${artifactID}/comment`, options))
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) {
        fireFailure()
      } else {
        fireSuccess()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const updateArtifactComment = (commentInfo, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth
      const { artifactID, commentID, comment } = commentInfo

      const body = { comment }

      if (!token) {
        fireFailure()
        return false
      }

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/artifact/${artifactID}/comment/${commentID}`, options))
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) {
        fireFailure()
      } else {
        fireSuccess()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const deleteArtifactComment = (commentInfo, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth
      const { artifactID, commentID, comment } = commentInfo

      const body = { comment }

      if (!token) {
        fireFailure()
        return false
      }

      const options = {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/artifact/${artifactID}/comment/${commentID}`, options))
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) {
        fireFailure()
      } else {
        fireSuccess()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const getUserLEDOptions = (artifactFilter, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { profileID, token } = getState().auth
      const tempFilter = cloneDeep(artifactFilter)

      // Set the filter to show the private view if the author is viewing his/her own artys
      if (tempFilter.creatorID && tempFilter.creatorID === profileID) {
        tempFilter.isViewPrivate = true
      }

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ filter: tempFilter })
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/user/${profileID}/artifact`, options))
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) {
        fireFailure()
      } else {
        const { data: { artifacts = [] } = {} } = await response.json()

        // IF the artifact is an LED, give it a default background color option
        artifacts.map(art => {
          art.backgroundObj = {
            color: 'default',
            type: 'circles'
          }

          // If the artifact has a backgroundKey, split and set the object to use the selected key
          if (art.backgroundKey) {
            const { backgroundKey } = art
            art.backgroundObj = formatLEDBackgroundObj(backgroundKey)
          }
          return art
        })

        dispatch({
          type: SET_LED_OPTIONS,
          payload: artifacts
        })
        fireSuccess()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const getUserLWOptions = (artifactFilter, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { profileID, token } = getState().auth
      const tempFilter = cloneDeep(artifactFilter)

      // Set the filter to show the private view if the author is viewing his/her own artys
      if (tempFilter.creatorID && tempFilter.creatorID === profileID) {
        // Check for a key indicating the user is intentionally wanting to see their public profile
        if (tempFilter.forcePublic) {
          tempFilter.isViewPrivate = false
        } else {
          tempFilter.isViewPrivate = true
        }
      }

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ filter: tempFilter })
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/user/${profileID}/artifact`, options))
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) {
        fireFailure()
      } else {
        const { data: { artifacts = [] } = {} } = await response.json()
        const formattedArtifacts = await artifacts.map(artifact => {
          artifact.learnerWorkTitle = artifact.title
          return artifact
        })
        dispatch({
          type: SET_LW_OPTIONS,
          payload: formattedArtifacts
        })
        fireSuccess()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

// get full list of details for a specific artifact
export const getArtifactDetails = (artifactID = '', profilePath = '', type = '', fireSuccess = () => { }, fireFailure = () => { }, profileID = '', dupeMCEBool) => {
  return async (dispatch, getState) => {
    try {
      const { userID, token } = getState().auth

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/user/${userID}/artifact/${artifactID}`, options))
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) {
        fireFailure()
      } else {
        const { data } = await response.json()

        const { orderedRes, projectInfo } = data

        if ((orderedRes && orderedRes.length !== 0) || (projectInfo && projectInfo.length !== 0 && projectInfo[0].length !== 0)) {
          const [artifactInfo] = orderedRes || projectInfo

          const commentList = type === 'Ed Farm Resource' ? data.commentList : data.adminCommentList
          const unviewedComments = data.unviewedComments

          if (artifactInfo && artifactInfo.targets && artifactInfo.targets.length && artifactInfo.ledID) {
            artifactInfo.targets = artifactInfo.targets.reduce((arr, targetInfo) => {
              if (targetInfo && targetInfo.targetID && targetInfo.targetName) {
                arr.push({ ...targetInfo, artifactID: artifactInfo.ledID })
              }
              return arr
            }, [])
          }

          if (artifactInfo && artifactInfo.links && artifactInfo.links.length && artifactInfo.ledID) {
            artifactInfo.links = artifactInfo.links.reduce((arr, linkInfo) => {
              if (linkInfo && linkInfo.linkID && linkInfo.linkID) {
                arr.push({ ...linkInfo, artifactID: artifactInfo.ledID })
              }
              return arr
            }, [])
          }

          if (artifactInfo && artifactInfo.attachments && artifactInfo.attachments.length && artifactInfo.ledID) {
            artifactInfo.attachments = artifactInfo.attachments.reduce((arr, attachmentInfo) => {
              if (attachmentInfo && attachmentInfo.attachmentID) {
                arr.push({ ...attachmentInfo, artifactID: artifactInfo.ledID })
              }
              return arr
            }, [])
          }

          if (type === 'Learning Experience Design') {
            const {
              actContent = '', createContent = '', engageContent = '', forkedFrom = '', investigateContent = '',
              overview = '', secondarySubjectID = '', secondarySubjectName = '', completionTime = 0, coreSubjectID = '', coreSubjectName = '', createdAt = 0, learnerWorkID = '',
              isDraft = 0, isForSubmission = 0, ledID: artifactID = '', artifactType = 'Learning Experience Design',
              isPrivate = 0, stateStandards = '', techNeeded = '', title = '', userID = '', workType = '',
              grades = [], targets = [], ISTEStandards = [], attachments = [], links = [], submissionStatus = 'NEEDS_REVIEW', denialComment = '',
              likeCount = 0, saveCount = 0, publicCommentCount = 0, adminSelected = 0, activeFeatured = 0, duplicates = [], originalArtifact = null, backgroundKey = 'circles-default',
              LEDBackgroundPath = '', learnerWorkDetails = null
            } = artifactInfo

            const tempGrades = grades.reduce((arr, grade) => {
              if (grade && grade.gradeID) {
                arr.push(grade.gradeID)
              }
              return arr
            }, [])

            const tempStandards = ISTEStandards.reduce((arr, standard) => {
              if (typeof standard === 'string') {
                arr.push(standard)
              } else {
                const { standardID } = standard
                arr.push(standardID)
              }
              return arr
            }, [])

            let backgroundObj = {
              color: 'default',
              type: 'circles'
            }

            backgroundObj = formatLEDBackgroundObj(backgroundKey)

            const payload = {
              actContent: !actContent ? '' : actContent,
              createContent: !createContent ? '' : createContent,
              engageContent: !engageContent ? '' : engageContent,
              forkedFrom: !forkedFrom ? '' : forkedFrom,
              investigateContent: !investigateContent ? '' : investigateContent,
              overview: !overview ? '' : overview,
              secondarySubjectID: !secondarySubjectID ? '' : secondarySubjectID,
              secondarySubjectName: !secondarySubjectName ? '' : secondarySubjectName,
              completionTime,
              coreSubjectID,
              coreSubjectName,
              createdAt,
              isDraft,
              isForSubmission,
              artifactID,
              learnerWorkID: !learnerWorkID ? '' : learnerWorkID,
              isPrivate,
              stateStandards,
              techNeeded,
              title,
              authorID: userID,
              workType,
              artifactType,
              grades: tempGrades,
              targets,
              ISTEStandards: tempStandards,
              attachments,
              links,
              commentList,
              submissionStatus,
              unviewedComments,
              denialComment,
              likeCount,
              saveCount,
              publicCommentCount,
              adminSelected,
              activeFeatured,
              duplicates,
              originalArtifact,
              backgroundKey,
              backgroundObj,
              LEDBackgroundPath,
              learnerWorkDetails
            }

            dispatch({ type: SET_TARGET_ARTIFACT_LED, payload })

            dispatch({ type: SET_PROFILE_PATH, payload: profilePath })

            // Check to see if we are fetching details specifically for a duplicate (used in the mce pursuer process)
            if (dupeMCEBool) {
              // if we are, simply return the payload
              fireSuccess(payload)
            } else {
              fireSuccess(artifactType, isForSubmission ? 'yes' : 'no', isDraft, artifactID, profileID)
            }
          }

          if (type === 'Learner Work') {
            const {
              learnerWorkID: artifactID = '', userID = '', learnerWorkTitle = '', workType = '', notes = '',
              dateCompleted = 0, coreSubjectID = '', coreSubjectName = '', isPrivate = 0, artifactType = 'Learner Work', isForSubmission = true,
              grades = [], attachments = [], links = [], ledIDs = [], submissionStatus = 'NEEDS_REVIEW', denialComment = '',
              likeCount = 0, saveCount = 0, publicCommentCount = 0
            } = artifactInfo

            const tempGrades = grades.reduce((arr, grade) => {
              if (grade && grade.gradeID) {
                arr.push(grade.gradeID)
              }
              return arr
            }, [])

            const tempLedIDs = ledIDs.reduce((arr, id) => {
              if (id && id.ledID) {
                arr.push(id.ledID)
              }
              return arr
            }, [])

            const payload = {
              artifactID,
              authorID: userID,
              learnerWorkTitle,
              workType,
              notes: !notes ? '' : notes,
              dateCompleted,
              coreSubjectID,
              coreSubjectName,
              isPrivate,
              isForSubmission,
              artifactType,
              grades: tempGrades,
              attachments,
              links,
              commentList,
              submissionStatus,
              unviewedComments,
              ledIDs: tempLedIDs,
              denialComment,
              likeCount,
              saveCount,
              publicCommentCount
            }

            dispatch({ type: SET_TARGET_ARTIFACT_LW, payload })

            fireSuccess(artifactType, isForSubmission ? 'yes' : 'no', '', artifactID, profileID)
          }

          if (type === 'Project') {
            const {
              artifactType = 'Project',
              isForSubmission = true,
              artifactID = '',
              projectTitle = '',
              projectLength = 0,
              projectDesc = '',
              isDraft = 0,
              isPrivate = 0,
              lwAttachments = '',
              ledAttachments = '',
              submissionStatus = 'NEEDS_REVIEW',
              denialComment = '',
              saveCount,
              likeCount,
              publicCommentCount
            } = artifactInfo[0]

            const payload = {
              artifactID,
              authorID: userID,
              artifactType,
              projectTitle,
              projectDescription: projectDesc,
              projectLength,
              projectLEDs: ledAttachments ? ledAttachments.split(',') : [],
              projectLWs: lwAttachments ? lwAttachments.split(',') : [],
              isDraft,
              isPrivate,
              isForSubmission,
              commentList,
              submissionStatus,
              unviewedComments,
              denialComment,
              likeCount,
              saveCount,
              publicCommentCount
            }

            dispatch({ type: SET_TARGET_ARTIFACT_PRO, payload })
            fireSuccess(type, isForSubmission ? 'yes' : 'no', isDraft, artifactID, profileID)
          }

          if (type === 'Ed Farm Resource') {
            const {
              resourceID: artifactID = '', createdBy: userID = '', creatorName = '', creatorProfileID = '', creatorAvatarPath = '', resourceName = '', workType = '', resourceDescription = '',
              startDate = 0, endDate = 0, artifactType = 'Ed Farm Resource', createdAt = 0, updatedAt = 0, updatedBy = '', editorName = '', editorProfileID = '', editorAvatarPath = '',
              resourceType = '', resourceAudience = '', resourceLink = '', bannerImagePath = '', isDraft = 0, isActive = 0, isPrivate = 0
            } = artifactInfo

            const payload = {
              artifactID,
              authorID: userID,
              artifactType,
              resourceName,
              resourceType,
              resourceAudience,
              resourceLink,
              workType,
              resourceDescription,
              startDate,
              endDate,
              creatorName,
              isPrivate,
              isForSubmission: 0,
              creatorProfileID,
              creatorAvatarPath,
              createdAt,
              updatedAt,
              updatedBy,
              editorName,
              editorProfileID,
              editorAvatarPath,
              bannerImagePath,
              isDraft,
              isActive,
              commentList
            }

            dispatch({ type: SET_TARGET_ARTIFACT_EFR, payload })

            fireSuccess('Ed Farm Resource', 'no', '', artifactID, profileID)
          }

          dispatch({ type: SET_PROFILE_PATH, payload: profilePath })
        } else {
          if (type === 'Learning Experience Design') { dispatch(resetTargetLED()) }
          if (type === 'Learner Work') { dispatch(resetTargetLearnerWork()) }
          if (type === 'Project') { dispatch(resetTargetProject()) }
          if (type === 'Ed Farm Resource') { dispatch(resetTargetEFR()) }
          fireFailure(type)
        }
        return false
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

// Reset stored Learning Experience Design info used for edit
export const resetTargetLED = () => {
  return async function (dispatch) {
    dispatch({ type: RESET_TARGET_ARTIFACT_LED })
  }
}

// Reset stored Learner Work info used for edit
export const resetTargetLearnerWork = () => {
  return async function (dispatch) {
    dispatch({ type: RESET_TARGET_ARTIFACT_LW })
  }
}

// Reset stored Project info used for edit
export const resetTargetProject = () => {
  return async function (dispatch) {
    dispatch({ type: RESET_TARGET_ARTIFACT_PRO })
  }
}

// Reset stored EFR info used for edit
export const resetTargetEFR = () => {
  return async function (dispatch) {
    dispatch({ type: RESET_TARGET_ARTIFACT_EFR })
  }
}

// Get list of Learning Experience Design titles & IDs for user
export const getUserLEDs = (alternateUserID, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { profileID: userID, token } = getState().auth

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/user/${!alternateUserID ? userID : alternateUserID}/artifact/led/options`, options))
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) {
        fireFailure()
      } else {
        const { data } = await response.json()

        dispatch({
          type: GET_USER_LEDS, payload: data
        })
        fireSuccess()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

// Clear out stored list of user LEDs
export const resetUserLEDs = () => {
  return async function (dispatch) {
    dispatch({ type: GET_USER_LEDS, payload: [] })
  }
}

// Get list of Learner Work info for user
export const getUserLearnerWork = (alternateUserID, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = () => { }) => {
    try {
      const { profileID: userID, token } = getState().auth

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/user/${!alternateUserID ? userID : alternateUserID}/artifact/lw/options`, options))
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) {
        fireFailure()
      } else {
        const { data } = await response.json()

        dispatch({
          type: GET_USER_LEARNER_WORK, payload: data
        })
        fireSuccess()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

// Clear out stored list of user Learner Work
export const resetUserLearnerWork = () => {
  return async function (dispatch) {
    dispatch({ type: GET_USER_LEARNER_WORK, payload: [] })
  }
}

export const createLED = (ledInfo, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { userID, token } = getState().auth

      /*
        ledInfo object data for api
        // isPrivate: bit
        // title: string
        // forkedFrom: string
        // completionTime: int
        // workType: string
        // coreSubjectID: string
        // secondarySubjectID: string
        // learnerWorkID: string
        // engageContent: string
        // investigateContent: string
        // createContent: string
        // actContent: string
        // overview: string
        // techNeeded: string
        // stateStandards: string
        // isDraft: bit
        // isForSubmission: bit
        // targets: array
        // ISTEStandards: array
        // grades: array
        // attachments: array
        // links: array
        // backgroundKey: str
      */

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(ledInfo)
      }

      const response = await trackPromise(window.fetch(`${api}/v1/user/${userID}/artifact/led`, options))
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) {
        log.info(response.status)
        fireFailure()
      } else {
        const { data } = await response.json()
        const { ledID } = data
        fireSuccess(ledID)
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const createLW = (lwInfo, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { userID, token } = getState().auth

      /*
        lwInfo object data for api
        // learnerWorkTitle: string
        // workType: string
        // coreSubjectID: string
        // notes: string
        // dateCompleted: int
        // grades: array
        // attachments: array
        // links: array
        // artifactID: string (ID for LED to link newly created LW to if provided)
      */

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(lwInfo)
      }

      const response = await trackPromise(window.fetch(`${api}/v1/user/${userID}/artifact/lw`, options))
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) {
        log.info(response.status)
        fireFailure()
      } else {
        const { data } = await response.json()

        fireSuccess(data.learnerWorkID)
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const createProject = (projInfo, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { userID, token } = getState().auth
      /*
        projInfo object data for api
        // projectTitle: string
        // projectDescription: string
        // projectLength: tinyInt
        // projectLEDs: array
        // projectLWs: array
        // isDraft: 0
        // isPrivate: 1
      */

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(projInfo)
      }

      const response = await trackPromise(window.fetch(`${api}/v1/user/${userID}/artifact/project`, options))
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) {
        log.info(response.status)
        fireFailure()
      } else {
        const { data } = await response.json()

        fireSuccess(data.projectID)
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

// update existing learning experience design using ID and new info
export const updateArtifact = (artifactID, artifactInfo, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { userID, token } = getState().auth

      const body = JSON.stringify({ ...artifactInfo })

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      // artifactID is the ID for the specific Learning Experience Design to update
      const response = await trackPromise(window.fetch(`${api}/${apiversion}/user/${userID}/artifact/${artifactID}`, options))
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) {
        fireFailure()
      } else {
        fireSuccess()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const updateArtifactResubmit = (artifactID, artifactInfo, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { userID, token } = getState().auth

      const { authorID = '' } = artifactInfo
      const isAuthor = Boolean(userID === authorID)

      const body = JSON.stringify({ ...artifactInfo, isAuthor })

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      // artifactID is the ID for the specific Learning Experience Design to update
      const response = await trackPromise(window.fetch(`${api}/${apiversion}/user/${userID}/artifact/${artifactID}/resubmit`, options))
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) {
        fireFailure()
      } else {
        fireSuccess()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

// delete existing learning experience design using ID
export const deleteArtifact = (artifactID, fireDeleteSuccess = () => { }, fireDeleteFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { userID, token } = getState().auth

      const options = {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      // artifactID is the ID for the specific Learning Experience Design to delete
      const response = await trackPromise(window.fetch(`${api}/v1/user/${userID}/artifact/${artifactID}`, options))
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) {
        log.info(response.status)
        fireDeleteFailure()
      } else {
        fireDeleteSuccess()
      }
    } catch (err) {
      console.error(err)
      fireDeleteFailure()
    }
  }
}

export const postArtifactLike = (artifactInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { userID, token } = getState().auth
      const { artifactID, authorID } = artifactInfo
      const isAuthor = Boolean(userID === authorID)

      const body = JSON.stringify({ userID, authorID, isAuthor })

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await window.fetch(`${api}/v1/artifact/${artifactID}/likes`, options)
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) {
        log.info(response.status)
        fireFailure()
      } else {
        const { data } = await response.json()

        dispatch({
          type: ADD_LIKE,
          payload: data
        })
        fireSuccess()
      }
    } catch (err) {
      fireFailure()
      return false
    }
  }
}

export const deleteArtifactLike = (artifactID, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { userID, token } = getState().auth

      const options = {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ userID: userID })
      }

      const response = await window.fetch(`${api}/v1/artifact/${artifactID}/likes`, options)
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) {
        log.info(response.status)
        fireFailure()
      } else {
        const { data } = await response.json()
        dispatch({
          type: REMOVE_LIKE,
          payload: data
        })
        fireSuccess()
      }
    } catch (err) {
      fireFailure()
      return false
    }
  }
}

export const postArtifactSave = (artifactInfo, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { userID, token } = getState().auth
      const { artifactID, authorID } = artifactInfo
      const isAuthor = Boolean(userID === authorID)

      const body = JSON.stringify({ userID, authorID, isAuthor })

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await window.fetch(`${api}/v1/artifact/${artifactID}/saves`, options)
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) {
        log.info(response.status)
        fireFailure()
      } else {
        const { data } = await response.json()
        dispatch({
          type: ADD_SAVE,
          payload: data
        })
        fireSuccess()
      }
    } catch (err) {
      fireFailure()
      return false
    }
  }
}

export const deleteArtifactSave = (artifactID, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { userID, token } = getState().auth

      const options = {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ userID: userID })
      }

      const response = await window.fetch(`${api}/v1/artifact/${artifactID}/saves`, options)
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) {
        log.info(response.status)
        fireFailure()
      } else {
        const { data } = await response.json()
        dispatch({
          type: REMOVE_SAVE,
          payload: data
        })
        fireSuccess()
      }
    } catch (err) {
      fireFailure()
      return false
    }
  }
}
