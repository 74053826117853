import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { isEqual } from 'lodash'

import {
  Grid, Typography, useTheme, OutlinedInput, Table, TableBody, TableContainer, TableCell, TableHead, TableRow,
  Paper, IconButton, Select, MenuItem, TableSortLabel, Button, Avatar, Tooltip, Dialog, DialogTitle, DialogActions, DialogContent,
  FormGroup, FormControlLabel, Checkbox
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import { SearchRounded, DeleteOutline, AddRounded } from '@material-ui/icons'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { SingleDatePicker } from 'react-dates'

import { updateEFRActiveStatus, deleteEFR } from '../../../../redux/actions/admin'
import { NotificationToast, ConfirmToast } from '../../tools'
import { artifactTypes } from '../../../../utils'
import { ModifiedEditIcon } from '../../custom'

const FeaturedResources = (props) => {
  const { classes, featuredResources, totalResources, filter, setFilter, defaultFilter, getEFRAdminList, activeButton, navHeight = 0 } = props
  const theme = useTheme()
  const dispatch = useDispatch()

  // ******************** Data Creation ******************** //

  // If the filter was changed, fetch the fellows with the new filter
  useEffect(() => {
    if (filter && defaultFilter && activeButton === 'featured-resources') {
      if (!isEqual(filter, defaultFilter)) {
        dispatch(getEFRAdminList(filter))
      }
    }
  }, [dispatch, filter, defaultFilter, getEFRAdminList, setFilter, activeButton])

  // Data rows for the displayed table
  const [rows, setRows] = useState([])

  // Take the teacherFellows array and format it into the data rows
  // Added handling for 'No results' in the 'else' block
  useEffect(() => {
    if (featuredResources) {
      if (featuredResources.length) {
        const newRows = []
        featuredResources.forEach(fellow => {
          newRows.push(fellow)
        })
        setRows(newRows)
      } else {
        setRows([])
      }
    }
  }, [featuredResources])

  // Column Headers
  const headCells = [
    { id: 'resourceName', label: 'Resource Name', align: 'center' },
    { id: 'activeStartDate', label: 'Active Start Date', align: 'center' },
    { id: 'activeEndDate', label: 'Active End Date', align: 'center' },
    { id: 'creatorName', label: 'Created By', align: 'center' },
    { id: 'isActive', label: 'Active', align: 'center' }
  ]

  // ********************* Pagination Logic: **************** //
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const handleChangePage = (event, value) => {
    if (value >= 0) {
      setPage(value - 1)
      setFilter({
        ...filter,
        sortCount: rowsPerPage,
        page: value
      })
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: event.target.value
    })
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalResources - page * rowsPerPage)

  // ****************** Edit Row Logic: *********************** //

  // resource infromation of the row selected to be editied
  const [editRow, setEditRow] = useState('')

  // Value of the selected date range
  const [startDateValue, setStartDateValue] = useState(null)
  const [endDateValue, setEndDateValue] = useState(null)

  // Set when a user selects to manually deactivate a resource (only available on a currently active EFR)
  const [deactivateResource, setDeactivateResource] = useState(false)

  // Component Prop values
  const [singleStartFocusedInput, setSingleStartFocused] = useState(null)
  const [singleEndFocusedInput, setSingleEndFocused] = useState(null)

  // --- Specific to the calendar props for the date picker:
  const [modalRef, setModalRef] = useState(null)
  const handleSingleStartFocus = (focus) => {
    setSingleStartFocused(focus.focused)
  }

  const handleSingleEndFocus = (focus) => {
    setSingleEndFocused(focus.focused)
  }

  const outsideRange = () => false

  // ------ end calendar props

  // Selects active start and end dates
  const handleStartDateChange = (value) => {
    setStartDateValue(value)
  }

  const handleEndDateChange = (value) => {
    setEndDateValue(value)
  }

  // Reset the resource end edit values
  const handleReset = () => {
    setDeactivateResource(false)
    setStartDateValue(null)
    setEndDateValue(null)
    setEditRow('')
  }

  // Open the edit modal and set the information according to the selected row
  const handleEditMode = (row) => {
    const { activeStartDate, activeEndDate } = row

    // Populate the date picker with the resources' active period if it already has a range set
    if (activeStartDate && activeEndDate) {
      const newStart = moment(activeStartDate * 1000)
      const newEnd = moment(activeEndDate * 1000)
      setStartDateValue(newStart)
      setEndDateValue(newEnd)
    } else {
      // If no range has been already selected be sure we clear the display values
      setStartDateValue(null)
      setEndDateValue(null)
    }
    // Opens the modal and set the resource information to state
    setEditRow(row)
  }

  // Once updated, fetch the list again
  const fireSaveSuccess = () => {
    dispatch(getEFRAdminList())
  }

  // Use the dates and information selected by the user and format them for the API or provided error messages if selections are not valid
  const handleSaveFeaturedDate = (featuredID) => {
    // Roll back the selected time and current time of day to timestamps for the start of the day

    // Begining of selected START Date
    const unixStartAM = moment(startDateValue).startOf('day').unix()

    // Begining of selected END date
    const unixEndAM = moment(endDateValue).startOf('day').unix()
    // End of selected END date
    const unixEndPM = moment(endDateValue).endOf('day').unix()

    // Begining of CURRENT day
    const currentDateAM = moment().startOf('day').unix()

    // If attempting to save new information outside of deactivating a resource, and do not have both a start and end date, fail the request
    if (!deactivateResource) {
      if (!startDateValue || !endDateValue) {
        return NotificationToast(true, 'Must have both start and end dates selected.')
      }
      // If attempting to set a start date before an end date, fail the request
      if (unixStartAM > unixEndAM) {
        return NotificationToast(true, 'The end date cannot fall before the start date.')
      }

      // If attempting to set an active period that has already ended, fail the request
      if (unixEndAM < currentDateAM) {
        return NotificationToast(true, 'The end date cannot fall before the current date.')
      }

      // If attempting to set a featured period within the same 24 hour period, fail the request
      if (unixStartAM === unixEndAM) {
        return NotificationToast(true, 'Start and end dates cannot be the same.')
      }
    }

    // Format the information for the API
    const featuredInfo = {
      featuredResourceID: !featuredID ? '' : featuredID,
      activeStart: unixStartAM,
      activeEnd: unixEndPM,
      removeFeatured: deactivateResource
    }

    // Dispatch and reset values
    dispatch(updateEFRActiveStatus(editRow.resourceID, featuredInfo, fireSaveSuccess))
    handleReset()
  }

  // Delete a resource (must confirm they understand the action cannot be undone)
  const deleteResource = async (resourceID) => {
    const toastMessage = {
      msg: 'Are you sure you wish to delete this Resource?',
      txt: 'This action will permanently remove this artifact and cannot be undone.'
    }
    const confirm = await ConfirmToast(toastMessage)

    if (confirm) {
      dispatch(deleteEFR(resourceID, fireSaveSuccess))
    }
  }

  // ******************** Column Sort Logic **************** //

  const [orderBy, setOrderBy] = useState('')
  const [order, setOrder] = useState('')

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: rowsPerPage,
      sortDirection: isAsc ? 'DESC' : 'ASC',
      sortType: property
    })
  }

  // ********************** Search Logic *********************** //
  const [searchInput, setSearchInput] = useState('')

  const handleSearch = (e) => {
    setSearchInput(e.target.value)
    if (searchInput && searchInput.length >= 3) {
      // Previously, issues were occuring if the user was on a page that was greater than 1 and made a search. The page is now being reset beforehand to avoid that.
      setPage(0)
      setFilter({
        ...filter,
        page: 1,
        sortCount: rowsPerPage,
        searchPhrase: searchInput
      })
    }

    if (e.target.value === '') {
      setPage(0)
      // Set the sortCount to current rowsPerPage instead of the default
      setFilter({
        ...defaultFilter,
        sortCount: rowsPerPage
      })
    }
  }

  // ********************** Routing Logic ********************* //

  // Handles routing the user to a public/read only view of an artifact
  const handleEFRPublicView = (row) => {
    const { resourceID, profileID } = row
    props.history.push({
      pathname: '/artifact',
      search: `?public=${artifactTypes.EFR}&user=${profileID}&artifact=${resourceID}`
    })
  }

  // Handles routing the user to the EFR author's profile
  const handleAuthorProfileView = (profileID) => {
    props.history.push({
      pathname: '/profile',
      search: `?user=${profileID}`
    })
  }

  // Handles routing the admin user to create form for EFR
  const handleCreateEFR = () => {
    props.history.push({
      pathname: '/artifact',
      search: `?create=${artifactTypes.EFR}&submission=no`
    })
  }

  return (
    <Grid container direction='column'>

      {/* Edit Resource Modal */}
      <Dialog
        open={Boolean(editRow !== '')}
        style={{ marginTop: navHeight }}
        onClose={handleReset}
        fullWidth
        maxWidth='sm'
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle disableTypography>
          <Typography variant='h4' style={{ margin: '.5em', color: theme.palette.purple.darkest }}>Edit Resource Status</Typography>
        </DialogTitle>
        <DialogContent
          ref={setModalRef}
          dividers
          style={{
            minHeight: singleEndFocusedInput || singleStartFocusedInput ? modalRef ? modalRef.clientHeight + 334 : 'auto' : 'auto'
          }}
        >
          <Grid container direction='column' style={{ margin: '.5em 1em 1em 1em', paddingRight: '3em' }}>
            <Grid container item direction='column' style={{ marginBottom: '.5em' }}>
              <Typography gutterBottom variant='h5' style={{ textTransform: 'none', color: theme.palette.purple.darkest, fontWeight: 600 }}>Resource Name:</Typography>
              <Typography gutterBottom variant='h5' style={{ textTransform: 'none', fontWeight: 400 }}>{editRow.resourceName}</Typography>
            </Grid>
            <Grid container item direction='column'>
              <Grid item container direction='column'>
                <Grid item>
                  <Typography gutterBottom variant='h5' style={{ textTransform: 'none', color: theme.palette.purple.darkest, fontWeight: 600 }}>Currently Active?</Typography>
                </Grid>
                <Grid item>
                  <Typography variant='h6' style={{ textTransform: 'none', fontWeight: 600 }}>{editRow.isActive ? 'Yes' : 'No'}</Typography>
                </Grid>
              </Grid>
              {/* Deactivate Resource Option (only available if the resource is currently active) */}
              {Boolean(editRow.isActive) &&
                <Grid item>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={deactivateResource}
                          size='small'
                          onClick={() => setDeactivateResource(!deactivateResource)}
                        />
                      }
                      label='Deactivate Resource'
                    />
                  </FormGroup>
                </Grid>}
            </Grid>

            {/* Start Date selection (will disable if the user has selected to deactivate the resource) */}
            <Grid item container direction='row' style={{ marginTop: '1em' }}>
              <Grid item container direction='column' xs={6} style={{ paddingRight: '1em' }}>
                <Typography variant='h6' style={{ textTransform: 'none', color: deactivateResource ? theme.palette.grey.medium : theme.palette.purple.darkest, fontWeight: 600 }}>Active From:</Typography>
                <SingleDatePicker
                  small
                  id='SingleDatePickerStartInput'
                  placeholder={deactivateResource ? '--' : 'Select Start Date'}
                  showClearDate
                  disabled={deactivateResource}
                  isOutsideRange={outsideRange}
                  date={!deactivateResource ? startDateValue : null}
                  onDateChange={date => handleStartDateChange(date)}
                  focused={singleStartFocusedInput}
                  onFocusChange={(focused) => handleSingleStartFocus(focused)}
                  numberOfMonths={1}
                  block
                />
              </Grid>

              {/* End Date selection (will disable if the user has selected to deactivate the resource) */}
              <Grid item container direction='column' xs={6} style={{ paddingRight: '1em' }}>
                <Typography variant='h6' style={{ textTransform: 'none', color: deactivateResource ? theme.palette.grey.medium : theme.palette.purple.darkest, fontWeight: 600 }}>Active To:</Typography>
                <SingleDatePicker
                  small
                  id='SingleDatePickerEndInput'
                  placeholder={deactivateResource ? '--' : 'Select End Date'}
                  showClearDate
                  disabled={deactivateResource}
                  isOutsideRange={outsideRange}
                  date={!deactivateResource ? endDateValue : null}
                  onDateChange={date => handleEndDateChange(date)}
                  focused={singleEndFocusedInput}
                  onFocusChange={(focused) => handleSingleEndFocus(focused)}
                  numberOfMonths={1}
                  block
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        {/* Cancel or Save action buttons */}
        <DialogActions>
          <Button
            variant='outlined'
            color='primary'
            style={{
              margin: '.5em 1em',
              fontWeight: '600'
            }}
            onClick={(e) => { e.preventDefault(); handleReset() }}
          >
            Cancel
          </Button>

          <Button
            variant='contained'
            color='primary'
            style={{
              margin: '.5em 1em',
              fontWeight: '600'
            }}
            onClick={(e) => { e.preventDefault(); handleSaveFeaturedDate(editRow.featuredResourceID) }}
          >
            Save
          </Button>

        </DialogActions>
      </Dialog>

      <Grid item container style={{ marginBottom: '2em' }}>
        {/* Page Title */}
        <Grid item container xs={9} justifyContent='flex-start'>
          <Typography variant='h4' style={{ fontSize: '20px' }}>Featured Resources</Typography>
        </Grid>
        <Grid item container xs={3} justifyContent='flex-end'>
          <Button
            color='primary'
            variant='contained'
            style={{ fontWeight: '600' }}
            startIcon={
              <AddRounded className={classes.addIcon} />
            }
            onClick={handleCreateEFR}
          >
            Add Resource
          </Button>
        </Grid>
      </Grid>

      {/* Top Pagination display and search input */}
      <Grid item container direction='row' style={{ marginBottom: '1em' }}>
        <Grid item container alignContent='flex-end' xs={7}>
          <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none', fontWeight: 400, fontSize: '16px' }}>
            Displaying {totalResources === 0 ? 0 : (page * rowsPerPage) + 1} to {rows && page * rowsPerPage + rowsPerPage > totalResources ? totalResources : page * rowsPerPage + rowsPerPage} of {totalResources}
          </Typography>
        </Grid>
        <Grid item container justifyContent='flex-end' xs={5}>
          <OutlinedInput
            className={classes.searchInput}
            size='small'
            margin='dense'
            fullWidth
            inputProps={{ style: { border: 'none', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '5px' } }}
            placeholder='Search...'
            classes={{ input: classes.inputPlaceholder }}
            startAdornment={
              <SearchRounded style={{ color: theme.palette.grey.dark, fontSize: '20px' }} />
            }
            value={searchInput}
            onChange={(e) => handleSearch(e)}
          />
        </Grid>
      </Grid>

      {/* Data Table */}
      <Grid item container direction='column' style={{ marginBottom: '1em' }}>
        <TableContainer elevation={0} style={{ border: `solid 1px ${theme.palette.grey.medium}` }} component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: theme.palette.grey.lighter }}>
              <TableRow>
                {/* Sortable Table Column Headers */}
                {headCells.map(header => {
                  return (
                    <TableCell
                      key={header.id}
                      align={header.align}
                      style={{ padding: '16px' }}
                      sortDirection={orderBy === header.id ? 'asc' : false}
                    >
                      <TableSortLabel
                        active={orderBy === header.id}
                        direction={orderBy === header.id ? order : 'asc'}
                        onClick={() => handleRequestSort(header.id)}
                      >
                        <Typography variant='h5' style={{ color: theme.palette.grey.dark }}>{header.label}</Typography>
                        {orderBy === header.id ? (
                          <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  )
                })}
                <TableCell align='left' style={{ padding: '16px' }} />
                <TableCell align='left' style={{ padding: '16px' }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow key={`index-${row.resourceID}`}>
                    <TableCell>
                      <Grid item container direction='row' alignItems='center'>
                        <Tooltip
                          placement='top'
                          title={row.resourceName && row.resourceName.length > 25 ? (
                            <Typography>
                              {row.resourceName}
                            </Typography>
                          ) : ''}
                        >
                          <Button
                            variant='text'
                            color='primary'
                            style={{ textTransform: 'none', textDecoration: 'underline', fontWeight: 600, textAlign: 'left' }}
                            onClick={() => handleEFRPublicView(row)}
                          >
                            {row.resourceName && row.resourceName.length > 25 ? `${row.resourceName.substring(0, 25)}...` : row.resourceName}
                          </Button>
                        </Tooltip>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant='body1'
                        style={{ color: row.activeStartDate ? 'black' : theme.palette.grey.medium, fontWeight: row.isActive ? 600 : 400 }}
                      >
                        {row.activeStartDate ? moment(row.activeStartDate * 1000).format('MM/DD/YYYY') : '--/--/----'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant='body1'
                        style={{ color: row.activeEndDate ? 'black' : theme.palette.grey.medium, fontWeight: row.isActive ? 600 : 400 }}
                      >
                        {row.activeEndDate ? moment(row.activeEndDate * 1000).format('MM/DD/YYYY') : '--/--/----'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Grid item container direction='row' alignItems='center'>
                        <Avatar src={row?.authorDeletedAt > 0 ? '' : row.profileAvatarPath} className={classes.avatarSize} />
                        <Button
                          variant='text'
                          color='primary'
                          onClick={() => row?.authorDeletedAt > 0 ? false : handleAuthorProfileView(row.profileID)}
                          style={{ textTransform: 'none', fontWeight: 600, textAlign: 'left', cursor: row?.authorDeletedAt ? 'default' : 'pointer' }}
                          disabled={row?.authorDeletedAt > 0}
                        >
                          {row?.authorDeletedAt > 0 ? 'Ed Farm User' : row.creatorName}
                        </Button>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body1' style={{ fontWeight: row.isActive ? 600 : 400 }}>{row.isActive ? 'Yes' : 'No'}</Typography>
                    </TableCell>
                    <TableCell align='right'>
                      <IconButton
                        className={classes.editIcon}
                        onClick={() => handleEditMode(row)}
                      >
                        <ModifiedEditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align='right'>
                      <IconButton
                        className={classes.editIcon}
                        style={{ color: 'red' }}
                        onClick={() => deleteResource(row.resourceID)}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
              {/* Data Array has reached it's length: */}
              {emptyRows > 0 && (
                <TableRow style={{ height: 10 }}>
                  <TableCell colSpan={5}>
                    <Typography variant='body1' style={{ color: theme.palette.grey.medium, textAlign: 'center' }}>{totalResources === 0 ? 'No Results' : 'End of List'}</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* Bottom Pagination Controls */}
      <Grid item container direction='row' style={{ marginBottom: '4em' }}>
        {/* Rows per Page Selection */}
        <Grid item container direction='row' xs={6} justifyContent='flex-start'>
          <Grid item style={{ marginRight: '.2em', display: 'flex', alignItems: 'flex-end' }}>
            <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>Display</Typography>
          </Grid>
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <Select
              variant='outlined'
              size='small'
              defaultValue={10}
              value={rowsPerPage}
              onChange={(e) => handleChangeRowsPerPage(e)}
              classes={{ root: classes.searchInput, selectMenu: classes.statusSelect }}
              style={{ padding: 0 }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </Grid>
          <Grid item style={{ marginLeft: '.4em', display: 'flex', alignItems: 'flex-end' }}>
            <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>entries</Typography>
          </Grid>
        </Grid>
        {/* Pagination/ Page Selection */}
        <Grid item container xs={6} justifyContent='flex-end'>
          <Pagination
            color='primary'
            classes={{ root: classes.pagination }}
            count={totalResources ? Math.ceil(totalResources / rowsPerPage) : 0}
            page={page === 0 ? 1 : page + 1}
            onChange={handleChangePage}
            shape='rounded'
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withRouter(FeaturedResources)
