import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resendValidationEmail, updateUserEmail } from '../../../redux/actions'

import {
  Grid, TextField, Typography, Button
} from '@material-ui/core'

import { ValidateEmail } from '../../../utils/functions'

import { useTheme } from '@material-ui/core/styles'
import log from '../../../utils/logger'
import { defaultErrors } from '../../../utils'

const ValidateEmailForm = (props) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { handleReturnClick } = props

  const authObject = useSelector(state => state.auth)
  const { emailAddress } = authObject

  const [email, setEmail] = useState(emailAddress)
  const [resetEmail, setResetEmail] = useState(false)

  const defaultValEmailFormErr = defaultErrors.validateEmailFormErr

  const [errors, setErrors] = useState(defaultValEmailFormErr)
  const [changeEmailSuccess, setChangeEmailSuccess] = useState({ sent: false, success: false })
  const [resendSuccess, setResendSuccess] = useState({ sent: false, success: false })

  const errorCheck = (emailAddress) => {
    if (!emailAddress) {
      setErrors({
        ...defaultValEmailFormErr,
        fieldsMissingError: true
      })
    } else if (!ValidateEmail(emailAddress)) {
      setErrors({
        ...defaultValEmailFormErr,
        emailValidError: true
      })
    } else {
      setErrors(defaultValEmailFormErr)
      handleResendEmail(emailAddress)
    }
    // return false
  }

  const handleChangeEmail = (emailAddress) => {
    log.warn(emailAddress)
    dispatch(updateUserEmail(emailAddress, fireChangeEmailSuccess))
  }

  const handleResendEmail = (emailAddress) => {
    dispatch(resendValidationEmail(emailAddress, fireResendResponse))
  }

  // note: this should be handled differently in a future tech debt ticket
  const fireChangeEmailSuccess = (value) => {
    setChangeEmailSuccess({
      sent: true,
      success: value
    })
  }

  // note: working with what was here previously for now, but this should be handled differently in a future tech debt ticket
  const fireResendResponse = (errValue) => {
    setResendSuccess({
      sent: true,
      success: !errValue
    })
  }

  return (
    <>
      <Grid item container direction='row' alignItems='center' justifyContent='center' style={{ display: 'flex', marginTop: '25vh', marginLeft: '10vw' }}>
        <Grid item container direction='column'>
          <Grid item xs={9}>
            <Typography variant='h2'>Check your email to verify.</Typography>
          </Grid>
          <Grid item container style={{ marginTop: '2em' }} direction='column'>
            <Typography variant='body1'>Please click the link in the verification email sent to <span style={{ fontWeight: '600', color: theme.palette.purple.darkest }}>{emailAddress}</span></Typography>
            <Typography variant='body1'>If this email is incorrect you can <span onClick={() => setResetEmail(true)} style={{ fontWeight: '600', color: theme.palette.purple.darkest, cursor: 'pointer' }}>edit here.</span></Typography>
          </Grid>
          {errors.emailValidError &&
            <Typography variant='caption' style={{ color: 'red' }}>* Invalid email. Please check your formatting and try again.</Typography>}
          <Grid item style={{ marginTop: '2em' }}>
            {resetEmail
              ? (
                <Grid item xs={9}>
                  <TextField
                    variant='outlined'
                    size='small'
                    fullWidth
                    type='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Grid item container justifyContent='flex-end' style={{ marginTop: '1em' }}>
                    <Button
                      variant='text'
                      style={{ color: theme.palette.purple.darkest, fontWeight: '600', textTransform: 'none', fontFamily: 'Source Sans Pro', marginRight: '.5em' }}
                      onClick={() => setResetEmail(false)}
                    >
                      Cancel
                    </Button>
                    <Button variant='contained' color='primary' onClick={() => handleChangeEmail(email)}>Update Email</Button>
                  </Grid>
                  {changeEmailSuccess.sent &&
                    <Typography style={{ fontWeight: '600', color: changeEmailSuccess.success ? theme.palette.purple.darkest : 'red' }}>{changeEmailSuccess.success === true ? 'Email updated. Please check your email for additional instructions' : 'Unable to update email. Please contact Ed Farm support for help with this issue.'}</Typography>}
                </Grid>
              )
              : (
                <>
                  <Grid>
                    <Typography variant='body1'>Didn't receive it? <span style={{ fontWeight: '600', color: theme.palette.purple.darkest, cursor: 'pointer' }} onClick={() => errorCheck(email)}>Resend email</span></Typography>
                  </Grid>
                  {resendSuccess.sent &&
                    <Typography style={{ fontWeight: '600', color: resendSuccess.success ? theme.palette.purple.darkest : 'red' }}>Email {resendSuccess?.success ? 'sent successfully!' : 'unable to send.'}</Typography>}
                </>
              )}
          </Grid>
          <Grid item container style={{ marginTop: '2em' }} direction='column'>
            <Typography variant='body1'>Click <span style={{ fontWeight: '600', color: theme.palette.purple.darkest, cursor: 'pointer' }} onClick={handleReturnClick}>here</span> to return to the login page.</Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default ValidateEmailForm
