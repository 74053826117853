import React, { useState } from 'react'
import {
  Grid, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions,
  IconButton, Select, MenuItem, FormControlLabel, Switch, Popover
} from '@material-ui/core'
import {
  CloseRounded
} from '@material-ui/icons'

const DeleteCohortModal = (props) => {
  const {
    classes, deleteCohortModalOpen = false, handleCancel = () => { }, allCohorts, setDeleteCohortInfo = () => { },
    deleteCohortInfo, handleDeleteCohort = () => { }
  } = props

  const [confirmDeleteCohortOpen, setConfirmDeleteCohortOpen] = useState(false)
  const [confirmDeleteCohortAnchor, setConfirmDeleteCohortAnchor] = useState(null)

  const openConfirmDeleteCohort = (e) => {
    setConfirmDeleteCohortOpen(true)
    setConfirmDeleteCohortAnchor(e.currentTarget)
  }

  const closeConfirmDeleteCohort = () => {
    setConfirmDeleteCohortOpen(false)
    setConfirmDeleteCohortAnchor(null)
  }

  const handleConfirmDeleteCohort = () => {
    handleDeleteCohort()
    closeConfirmDeleteCohort()
  }

  const availableCohorts = allCohorts?.filter(cohort => cohort.cohortID !== deleteCohortInfo?.cohortID)
  const errorCheck = Boolean(deleteCohortInfo?.participantCount) && !deleteCohortInfo?.replacementCohort

  const renderDialogContent = () => {
    if (!deleteCohortInfo?.isActive && !deleteCohortInfo?.participantCount) {
      return (
        <Grid item container direction='column'>
          <Typography variant='h4' gutterBottom style={{ fontWeight: '600', color: 'red' }}>Are you sure?</Typography>
          <Typography gutterBottom variant='h6' style={{ fontWeight: '500', textTransform: 'none' }}>
            This action will permanently delete this cohort and cannot be undone. Click 'Confirm' to proceed or 'Cancel' to exit without changes.
          </Typography>
        </Grid>
      )
    } else {
      return (
        <>
          <Grid item container direction='column' spacing={1}>
            <Grid item>
              <Typography variant='h5' style={{ color: 'red' }}>
                Heads Up!
              </Typography>
            </Grid>
            <Grid item>
              {Boolean(deleteCohortInfo?.isActive) && Boolean(deleteCohortInfo?.participantCount) ? (
                <Typography gutterBottom variant='body2'>
                  This cohort is currently active and has participants. To proceed with deletion, you must select a replacement cohort to transfer the participants. While setting the replacement cohort as active is optional, you will need to designate a new active cohort at a later time if you choose not to do so now.
                </Typography>
              ) : (
                <>
                  {Boolean(deleteCohortInfo?.isActive) && (
                    <Typography gutterBottom variant='body2'>
                      This is currently the active cohort. Selecting a replacement cohort will transfer the active status. If no replacement is selected, you will need to set a new active cohort later.
                    </Typography>
                  )}
                  {Boolean(deleteCohortInfo?.participantCount) && (
                    <Typography gutterBottom variant='body2'>
                      This cohort has participants. You must select a replacement cohort to move these participants to.
                    </Typography>
                  )}
                </>
              )}
            </Grid>
            <Grid item>
              <Select
                variant='outlined'
                margin='dense'
                fullWidth
                displayEmpty
                classes={{ root: classes.searchInput, selectMenu: classes.statusSelect }}
                value={deleteCohortInfo?.replacementCohort || ''}
                onChange={(e) => setDeleteCohortInfo({ ...deleteCohortInfo, replacementCohort: e.target.value })}
                style={{ padding: 0 }}
                required={Boolean(deleteCohortInfo?.participantCount)}
              >
                <MenuItem value='' disabled>Choose Cohort...</MenuItem>
                {availableCohorts && availableCohorts.length > 0 &&
                  availableCohorts.map((cohort) => (
                    <MenuItem key={`cohort-${cohort.cohortID}`} value={cohort.cohortID}>
                      {cohort.cohortName}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            {Boolean(deleteCohortInfo?.isActive) && deleteCohortInfo?.replacementCohort && (
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={deleteCohortInfo?.makeActive || false}
                      onChange={(e) => setDeleteCohortInfo({ ...deleteCohortInfo, makeActive: e.target.checked })}
                      color='primary'
                    />
                  }
                  label={
                    <Typography variant='body2'>
                      Set as the new active cohort? (Optional).
                    </Typography>
                  }
                />
              </Grid>
            )}
          </Grid>
        </>
      )
    }
  }

  return (
    <>
      <Dialog
        open={deleteCohortModalOpen}
        onClose={handleCancel}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle disableTypography>
          <Grid container direction='row' justifyContent='space-between' alignItems='center' style={{ padding: '0.3em' }} spacing={2}>
            <Grid item>
              <Typography
                variant='h4'
              >
                Delete Cohort
              </Typography>
            </Grid>

            <Grid item>
              <IconButton style={{ padding: '0px' }} onClick={(e) => { e.preventDefault(); handleCancel() }}>
                <CloseRounded />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent
          dividers
        >
          <Grid container direction='column' style={{ padding: '0.4em' }}>
            {renderDialogContent()}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container direction='column'>
            <Grid container item direction='row' justifyContent='flex-end'>
              <Button
                variant='outlined'
                color='primary'
                style={{
                  margin: '.5em 1em',
                  fontWeight: '600'
                }}
                onClick={handleCancel}
              >
                Cancel
              </Button>

              <Button
                variant='contained'
                style={{
                  backgroundColor: errorCheck ? '#d3d6d9' : 'red',
                  color: errorCheck ? '#66666e' : 'white',
                  fontWeight: 600,
                  margin: '.5em 1em'
                }}
                disabled={errorCheck}
                onClick={(e) => Boolean(deleteCohortInfo?.isActive) && !deleteCohortInfo?.makeActive ? openConfirmDeleteCohort(e) : handleConfirmDeleteCohort()}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>

        </DialogActions>
        <Popover
          open={confirmDeleteCohortOpen}
          anchorEl={confirmDeleteCohortAnchor}
          onClose={closeConfirmDeleteCohort}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <Grid container direction='column' style={{ padding: '1em' }}>
            <Typography
              variant='h4'
              style={{ color: 'red' }}
              gutterBottom
            >
              Confirm Deletion
            </Typography>

            <Typography variant='body1' gutterBottom>An active cohort is required when approving sign-ups. Are you sure you want to proceed without selecting a new one? <br /> You can set a new active cohort at a later time if you choose not to do so now.</Typography>

            <Grid item container direction='row' justifyContent='flex-end' alignItems='center' style={{ marginTop: '.5em' }}>
              <Button onClick={closeConfirmDeleteCohort} style={{ marginRight: '.3em', textTransform: 'none', fontWeight: '600' }}>Cancel</Button>
              <Button
                variant='outlined'
                color='primary'
                style={{
                  margin: '.5em 1em',
                  fontWeight: '600',
                  borderColor: 'red',
                  color: 'red'
                }}
                onClick={(e) => { e.preventDefault(); handleConfirmDeleteCohort() }}
              >Confirm
              </Button>
            </Grid>
          </Grid>
        </Popover>
      </Dialog>
    </>
  )
}

export default DeleteCohortModal
