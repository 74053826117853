import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  Dialog, DialogTitle, DialogContent,
  Grid, Typography, Button, IconButton,
  Divider, Avatar,
  makeStyles, useTheme
} from '@material-ui/core'

import {
  Replay as ReplayIcon, Check as CheckIcon, CloseRounded as CloseIcon
} from '@material-ui/icons'

import { FileDrop } from 'react-file-drop'
import { NotificationToast } from '../tools'

import { updateUserData } from '../../../redux/actions'

import { uploadType, uploadFileFormatter } from '../../../utils'

const useStyles = makeStyles((theme) => ({
  fixSpacing: {
    width: '100%',
    margin: 'auto'
  },
  dialogContent: {
    padding: theme.spacing(2, 3)
  },
  inactiveContainer: {
    border: '2px dashed #adadad',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius
  },
  activeContainer: {
    border: '2px dashed #293669',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius
  },
  profileImage: {
    width: 'auto',
    height: 'auto',
    '& .MuiAvatar-img': {
      maxHeight: '25rem !important',
      objectFit: 'contain !important'
    }
  },
  customizedButton: {
    backgroundColor: 'none',
    color: 'grey',
    stroke: 'grey',
    strokeWidth: '2px',
    height: '2rem',
    width: '2rem',
    '&:hover': {
      stroke: '#616161'
    }
  },
  paper: {
    overflowY: 'unset'
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  border: {
    borderBottom: '1px solid lightgray',
    width: '100%'
  },
  text: {
    marginRight: '1rem',
    marginLeft: '1rem',
    color: 'grey'
  }
}))

const ImageModal = (props) => {
  const { isOpen, onImageClose, userDetails, navHeight, currentUserID } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()

  const [isUploading, setIsUploading] = useState(false)
  const [newImage, setNewImage] = useState('')
  const [newImageType, setNewImageType] = useState('')

  // Ensures that we have the current user before setting the correct profileImagePath
  useEffect(() => {
    if (currentUserID && userDetails.userID && userDetails.userID === currentUserID) {
      if (userDetails.profileImagePath) {
        setNewImage(userDetails.profileImagePath)
      }
    }
  }, [currentUserID, userDetails.userID, userDetails.profileImagePath])

  const [fileSizeError, setFileSizeError] = useState(false)
  const [fileExtensionError, setFileExtensionError] = useState(false)

  const fileErrorReset = () => { setFileSizeError(false); setFileExtensionError(false) }

  const handleImageUpload = async (e, file) => {
    e.preventDefault()

    // Reset errors on initial upload to clear out previous
    fileErrorReset()

    const handleFileSizeError = (error) => setFileSizeError(error)
    const handleFileExtensionError = (error) => setFileExtensionError(error)

    const type = uploadType.PROFILE_IMAGE
    const attachments = []
    const imageResult = await uploadFileFormatter(file, type, attachments, handleFileSizeError, handleFileExtensionError)

    if (imageResult) {
      const { imageData, fileExtension } = imageResult
      setNewImage(imageData)
      setNewImageType(fileExtension)
      setIsUploading(false)
    }
  }

  const handleImageSave = () => {
    dispatch(updateUserData('update-image', { avatar: newImage, avatarType: newImageType }, fireSuccess, fireFailure))
  }

  const fireSuccess = () => {
    fileErrorReset()
    NotificationToast(false, 'Successfully updated user account image!')
  }

  const fireFailure = () => {
    NotificationToast(true, 'Failed to update user account image!')
  }

  const handleClose = () => {
    fileErrorReset()
    onImageClose()
  }

  const isSaveEnabled = userDetails.profileImagePath ? newImage !== userDetails.profileImagePath : true

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      style={{ zIndex: 11, marginTop: navHeight }}
      open={isOpen}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle disableTypography>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography
              variant='h4'
              style={{ textTransform: 'none', color: theme.palette.purple.darkest, fontSize: '1.3rem' }}
            >
              Edit Profile Image
            </Typography>
          </Grid>

          <Grid item>
            <IconButton style={{ padding: '0px' }} onClick={handleClose}>
              <CloseIcon className={classes.customizedButton} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />

      <DialogContent className={classes.dialogContent}>
        <Grid container direction='row'>

          <Grid item xs={12}>
            <Grid container direction='row'>
              <Grid container item direction='column' spacing={1} className={classes.fixSpacing}>

                <Grid item container direction='row'>
                  <Grid item xs={12}>
                    {
                      newImage ? (
                        <Avatar src={newImage} variant='rounded' className={classes.profileImage} />
                      ) : (
                        <FileDrop
                          onFrameDragEnter={() => setIsUploading(true)}
                          onFrameDragLeave={() => setIsUploading(false)}
                          onDrop={(files, e) => handleImageUpload(e, files)}
                        >
                          <Grid
                            item
                            xs={12}
                            style={{
                              border: isUploading ? '2px dashed #293669' : '2px dashed #adadad',
                              borderRadius: '5px',
                              width: '600',
                              color: 'black',
                              padding: '3rem'
                            }}
                          >

                            <Grid item container xs={12} justifyContent='center' style={{ marginTop: '.3em' }}>
                              <Typography variant='h6' style={{ fontWeight: '600', textTransform: 'none' }}>Drag and Drop Here</Typography>
                            </Grid>

                            <Grid item xs={8} style={{ marginTop: '.5rem', marginBottom: '.5rem', marginLeft: 'auto', marginRight: 'auto' }}>
                              <Grid item className={classes.container}>
                                <Grid item className={classes.border} />
                                <Typography variant='h5' className={classes.text}>or</Typography>
                                <Grid item className={classes.border} />
                              </Grid>
                            </Grid>

                            <Grid item>
                              <input
                                accept='image/*'
                                className={classes.input}
                                style={{ display: 'none' }}
                                id='raised-button-file'
                                multiple
                                type='file'
                                onChange={(e) => handleImageUpload(e, e.target.files)}
                              />
                              <label htmlFor='raised-button-file'>
                                <Grid item container direction='row'>
                                  <Button
                                    variant='outlined'
                                    style={{ borderColor: '#29356a', color: '#29356a', fontWeight: '600' }}
                                    component='span'
                                  >
                                    Choose Photo
                                  </Button>
                                </Grid>
                                <Grid item container direction='row' justifyContent='center' style={{ marginTop: '.5rem' }}>
                                  <Typography variant='caption' style={{ color: 'grey', fontSize: '.8rem' }}>Max file size of 3MB.</Typography>
                                </Grid>
                              </label>
                            </Grid>
                            {fileSizeError &&
                              <Grid item container direction='row' justifyContent='center'>
                                <Typography variant='caption' style={{ color: 'red' }}>The selected file is over the max file size of 3MB.</Typography>
                              </Grid>}

                            {fileExtensionError &&
                              <Grid item container direction='row' justifyContent='center'>
                                <Typography variant='caption' style={{ color: 'red' }}>The selected file type is not allowed.</Typography>
                              </Grid>}
                          </Grid>
                        </FileDrop>
                      )
                    }
                  </Grid>
                </Grid>

                <Grid container item direction='row' justifyContent='center' spacing={2} className={classes.fixSpacing}>
                  <Grid item>
                    <Button
                      variant='text'
                      color='primary'
                      style={{ fontWeight: 600, textTransform: 'capitalize' }}
                      startIcon={<ReplayIcon />}
                      onClick={() => { setNewImage(''); fileErrorReset() }}
                      disabled={newImage === ''}
                    >
                      Reset
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      variant='text'
                      color='primary'
                      style={{ fontWeight: 600, textTransform: 'capitalize' }}
                      onClick={handleImageSave}
                      endIcon={<CheckIcon />}
                      disabled={!isSaveEnabled}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </DialogContent>

    </Dialog>
  )
}

export default ImageModal
