import React, { } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Grid, Button, Typography, IconButton, CircularProgress,
  ImageList, ImageListItem, Grow, Divider
} from '@material-ui/core'

import {
  ImportantDevices, NavigateNextRounded, NavigateBeforeRounded, Assistant
} from '@material-ui/icons'

import { getSocialDetails } from '../../../../redux/actions'

import { modalTypes, userRoleIDs } from '../../../../utils'

const ModifiedChalkboardIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='70'
      height='70'
      viewBox='0 0 111 111'
    >
      <g transform='translate(-628 -124)'>
        <circle className='a' cx='50' cy='50' r='50' transform='translate(628 124)' fill='#29356A' />
        <g transform='translate(645 149)'>
          <path
            className='b'
            d='M19.5,33a2.145,2.145,0,0,0-.662.1A12.367,12.367,0,0,1,15,33.75a12.378,12.378,0,0,1-3.839-.648A2.139,2.139,0,0,0,10.5,33,10.5,10.5,0,0,0,0,43.558,4.488,4.488,0,0,0,4.5,48h21A4.488,4.488,0,0,0,30,43.558,10.5,10.5,0,0,0,19.5,33ZM15,30a9,9,0,1,0-9-9A9,9,0,0,0,15,30ZM55.5,0h-36A4.582,4.582,0,0,0,15,4.649V9a11.864,11.864,0,0,1,6,1.669V6H54V33H48V27H36v6H28.851a11.949,11.949,0,0,1,3.721,6H55.5A4.582,4.582,0,0,0,60,34.351V4.649A4.582,4.582,0,0,0,55.5,0Z'
            transform='translate(0.002)'
            fill='#F3F5FB'
          />
        </g>
      </g>
    </svg>
  )
}

const ExploreFeatured = ({ props }) => {
  const {
    classes, theme, loading, scroll,
    xsScreenBelow, xsScreen, smScreen, mdScreen, lgScreen,
    ArtifactCard, likedArtifacts, savedArtifacts,
    featuredSections, subjectsFeaturedRef, newFeaturedRef,
    socialModalOpen, setSocialType, setSocialModalOpen, setSocialArtifactInfo,
    handleChooseModal
  } = props

  const dispatch = useDispatch()

  const { subjectsList = [] } = useSelector(state => state.userDetails)
  const { roleID = '' } = useSelector(state => state.auth)

  const userHasNoSubjects = subjectsList && !subjectsList.length

  const userIsIFRole = roleID && roleID === userRoleIDs.INNOVATION_FELLOW

  return (
    <Grid>
      {/* ----- Because You Teach: Subject (not displayed when user is an IF) ----- */}
      {!userIsIFRole &&
        <Grid item container direction='column' style={{ maxWidth: '95vw' }}>
          <Grid item container direction='row' justifyContent='space-between' style={{ paddingRight: '2em' }}>
            <Grid item xs={xsScreenBelow ? 12 : 7} sm={9} container alignItems='flex-end' direction='row'>
              <Grid item container alignContent='center' justifyContent='center' style={{ backgroundColor: theme.palette.purple.darkest, borderRadius: '50%', height: '2em', width: '2em', marginRight: '.5em' }}>
                <ImportantDevices style={{ color: 'white', fontSize: '22px' }} />
              </Grid>
              <Typography variant='h3' style={{ color: theme.palette.purple.darkest, textTransform: 'none', fontWeight: 600, marginRight: '1em' }}>Subjects You Teach</Typography>
              <Typography variant='h5' style={{ color: theme.palette.grey.dark, fontWeight: 600, textTransform: 'none' }}>Take a look at some of the artifacts related to the subjects you teach.</Typography>
            </Grid>
            <Grid item container justifyContent='flex-end' xs={xsScreenBelow ? 12 : 5} sm={3} style={{ paddingRight: '1rem' }}>
              <Grid item container direction='row' justifyContent='flex-end'>
                <Grid item style={{ marginRight: '1.5rem' }}>
                  <IconButton className={classes.sectionNavIcons} onClick={(e) => scroll(subjectsFeaturedRef, xsScreen ? -320 : -300)}>
                    <NavigateBeforeRounded style={{ fontSize: '2.2rem' }} />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton className={classes.sectionNavIcons} onClick={(e) => scroll(subjectsFeaturedRef, xsScreen ? 320 : 300)}>
                    <NavigateNextRounded style={{ fontSize: '2.2rem' }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Section Content/Results */}
          <Grid item container direction='row'>
            {/* Card Render */}
            {loading
              ? (
                <Grow
                  in={loading}
                  {...(loading ? { timeout: 1000 } : {})}
                >
                  <Grid item container direction='row' justifyContent='center' alignContent='center' style={{ margin: '8em 0' }}>
                    <CircularProgress />
                  </Grid>
                </Grow>
              )
              : (
                featuredSections && featuredSections.subjects && featuredSections.subjects.length
                  ? (
                    <ImageList
                      direction='row'
                      ref={subjectsFeaturedRef}
                      style={{
                        flexWrap: 'nowrap',
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '1em',
                        overflowX: 'scroll',
                        width: '100%',
                        scrollBehavior: 'smooth',
                        padding: '.5em'
                      }}
                      cols={xsScreen ? 1 : smScreen ? 2 : mdScreen ? 4 : lgScreen ? 5 : 6}
                    >
                      {featuredSections.subjects.map((artifact, i) => {
                        const current = Boolean(i === featuredSections.subjects.findIndex(x => x.artifactID === artifact.artifactID))
                        return (
                          <Grow
                            in={current}
                            style={{ transformOrigin: '0 0 0' }}
                            {...(current ? { timeout: 200 * i } : {})}
                            key={artifact.artifactID}
                          >
                            <ImageListItem style={{ height: 'inherit', maxWidth: xsScreen ? '85%' : 'auto', padding: '.5em' }} classes={{ item: classes.artCardListItemItem }}>
                              <ArtifactCard
                                type={artifact.artifactType}
                                artifactInfo={artifact}
                                canEditArtifact={false}
                                classes={classes}
                                fromExplore
                                artifactLiked={likedArtifacts && likedArtifacts.length && likedArtifacts.includes(artifact.artifactID)}
                                artifactSaved={savedArtifacts && savedArtifacts.length && savedArtifacts.includes(artifact.artifactID)}
                                onSocialButtonClick={(status) => { dispatch(getSocialDetails(artifact.artifactID)); setSocialType(status); setSocialArtifactInfo(artifact); setSocialModalOpen(true) }}
                                socialModalOpen={socialModalOpen}
                                setActionType={setSocialType}
                              />
                            </ImageListItem>
                          </Grow>
                        )
                      })}
                    </ImageList>
                  )
                  : (
                    <Grid container direction='column' justifyContent='center' alignItems='center' style={{ margin: '4em' }}>
                      <ModifiedChalkboardIcon />
                      <Button
                        variant='text'
                        style={{ textTransform: 'none', fontSize: '16px', textDecoration: 'underline', color: theme.palette.purple.darkest, fontWeight: 600 }}
                        onClick={() => handleChooseModal(modalTypes.ABOUT)}
                      >
                        {userHasNoSubjects ? 'Looks like you do not have any subjects in your profile yet.' : 'Oh no!'}
                      </Button>
                      <Typography variant='body1' style={{ color: theme.palette.grey.dark }}>
                        {userHasNoSubjects ? 'Would you like to receive helpful recommendations based on the primary subjects that you teach?' : 'Sorry! Looks like no one has created any artifacts related to the subjects in your profile.'}
                      </Typography>
                      <Typography variant='body1' style={{ color: theme.palette.grey.dark }}>
                        {userHasNoSubjects ? 'Please select a subject on your profile so you can start getting recommendations.' : 'Try selecting more subjects, or get started creating artifacts to share with other educators!'}
                      </Typography>
                    </Grid>
                  )
              )}
          </Grid>
        </Grid>}

      <Divider variant='middle' style={{ margin: '2em 0 3em 0', display: userIsIFRole ? 'none' : 'flex' }} />

      {/* ----- What's New ----- */}
      <Grid item container direction='column' style={{ maxWidth: '95vw' }}>
        {/* Section Header/ Nav */}
        <Grid item container direction='row' justifyContent='space-between'>
          <Grid item xs={xsScreenBelow ? 12 : 7} sm={9} container alignItems='flex-end' direction='row'>
            <Grid item container alignContent='center' justifyContent='center' style={{ backgroundColor: theme.palette.purple.darkest, borderRadius: '50%', height: '2em', width: '2em', marginRight: '.5em' }}>
              <Assistant style={{ color: 'white', fontSize: '22px' }} />
            </Grid>
            <Typography variant='h3' style={{ color: theme.palette.purple.darkest, textTransform: 'none', fontWeight: 600, marginRight: '1em' }}>What's New</Typography>
            <Typography variant='h5' style={{ color: theme.palette.grey.dark, fontWeight: 600, textTransform: 'none' }}>Check out some of the latest artifacts.</Typography>
          </Grid>
          <Grid item container justifyContent='flex-end' xs={xsScreenBelow ? 12 : 5} sm={3} style={{ paddingRight: '3rem' }}>
            <Grid item container direction='row' justifyContent='flex-end'>
              <Grid item style={{ marginRight: '1.5rem' }}>
                <IconButton className={classes.sectionNavIcons} onClick={(e) => scroll(newFeaturedRef, xsScreen ? -320 : -300)}>
                  <NavigateBeforeRounded style={{ fontSize: '2.2rem' }} />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton className={classes.sectionNavIcons} onClick={(e) => scroll(newFeaturedRef, xsScreen ? 320 : 300)}>
                  <NavigateNextRounded style={{ fontSize: '2.2rem' }} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Section Content/Results */}
        <Grid item container direction='row' style={{ marginBottom: '5em' }}>
          {loading
            ? (
              <Grow
                in={loading}
                {...(loading ? { timeout: 1000 } : {})}
              >
                <Grid item container direction='row' justifyContent='center' alignContent='center' style={{ margin: '8em 0' }}>
                  <CircularProgress />
                </Grid>
              </Grow>
            )
            : (
              featuredSections && featuredSections.new && featuredSections.new.length
                ? (
                  <ImageList
                    direction='row'
                    ref={newFeaturedRef}
                    style={{ flexWrap: 'nowrap', display: 'flex', flexDirection: 'row', marginTop: '1em', overflowX: 'scroll', width: '100%', scrollBehavior: 'smooth', padding: '1em' }}
                    cols={xsScreen ? 1 : smScreen ? 2 : mdScreen ? 4 : lgScreen ? 5 : 6}
                  >
                    {featuredSections.new.map((artifact, i) => {
                      const current = Boolean(i === featuredSections.new.findIndex(x => x.artifactID === artifact.artifactID))
                      return (
                        <Grow
                          key={artifact.artifactID}
                          in={current}
                          style={{ transformOrigin: '0 0 0' }}
                          {...(current ? { timeout: 300 * i } : {})}
                        >
                          <ImageListItem style={{ height: 'inherit', maxWidth: xsScreen ? '85%' : 'auto', padding: '.5em' }} classes={{ item: classes.artCardListItemItem }}>
                            <ArtifactCard
                              type={artifact.artifactType}
                              artifactInfo={artifact}
                              canEditArtifact={false}
                              classes={classes}
                              fromExplore
                              artifactLiked={likedArtifacts && likedArtifacts.length && likedArtifacts.includes(artifact.artifactID)}
                              artifactSaved={savedArtifacts && savedArtifacts.length && savedArtifacts.includes(artifact.artifactID)}
                              onSocialButtonClick={(status) => { dispatch(getSocialDetails(artifact.artifactID)); setSocialType(status); setSocialArtifactInfo(artifact); setSocialModalOpen(true) }}
                            />
                          </ImageListItem>
                        </Grow>
                      )
                    })}
                  </ImageList>
                )
                : (
                  <Grid container direction='row' justifyContent='center' alignContent='center' style={{ margin: '4em 0' }}>
                    <Typography variant='body1' style={{ color: theme.palette.grey.medium, textAlign: 'center' }}>No Results Found.</Typography>
                  </Grid>
                )
            )}
        </Grid>
      </Grid>

    </Grid>
  )
}

export default ExploreFeatured
