import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import {
  Grid, Typography, useTheme, OutlinedInput, Table, TableBody, TableContainer, TableCell, TableHead, TableRow,
  Paper, TableSortLabel, Select, MenuItem, IconButton, Button, Dialog, DialogActions, DialogContent, DialogTitle,
  TextField, Tooltip, Chip
} from '@material-ui/core'

import { Autocomplete, Pagination } from '@material-ui/lab'

import {
  SearchRounded, AddCircle, GetApp
} from '@material-ui/icons'

import { adminPageOptions, attemptStatuses } from '../../../../utils'

import { getEvaluationsList, assignEvaluationAssessor, getAssessorsList, getEvaluationExports } from '../../../../redux/actions'

import { isEqual } from 'lodash'

import { NotificationToast } from '../../tools'

// import { ModifiedEditIcon } from '../custom'

// Helper function to format headers from camelCase to regular format
const formatCSVHeader = (header) => {
  const words = header.match(/[A-Z][a-z]+|[a-z]+/g) || []
  const formattedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  return formattedWords.join(' ')
}

const AdminMCEEvaluations = (props) => {
  const { classes, mceEvaluations = [], totalEvaluations = 0, filter = {}, setFilter = () => { }, defaultFilter = {}, activeButton } = props
  const theme = useTheme()
  const dispatch = useDispatch()
  const {
    adminMCEAssessors: { assessors = [] } = {}
  } = useSelector(state => state.admin)

  const { superAdmin = 0 } = useSelector(state => state.auth)

  // decide the styling of the attempt status chip
  const statusLabels = {
    [attemptStatuses.SUBMITTED]: {
      label: 'Pending',
      variant: 'outlined',
      styles: { borderColor: theme.palette.grey.medium, color: theme.palette.grey.medium }
    },
    [attemptStatuses.UNDER_REVIEW]: {
      label: 'Under Review',
      variant: 'default',
      styles: { backgroundColor: theme.palette.grey.medium, color: theme.palette.grey.dark }
    },
    [attemptStatuses.PASS]: {
      label: 'Passed',
      variant: 'default',
      styles: { backgroundColor: theme.palette.purple.darkest, color: 'white' }
    },
    [attemptStatuses.FAIL]: {
      label: 'Not Passed',
      variant: 'outlined',
      styles: { borderColor: theme.palette.grey.medium, color: theme.palette.grey.dark }
    }
  }

  const { userID: authUserID } = useSelector(state => state.auth)

  const [addAssessorOpen, setAddAssessorOpen] = useState(false)

  const [loadingUsers, setLoadingUsers] = useState(false)

  const [selectedAssessor, setSelectedAssessor] = useState(null)
  const [selectedAttempt, setSelectedAttempt] = useState({})

  const allUsers = useMemo(() => {
    let userArr = []
    if (assessors && assessors.length > 0) {
      userArr = assessors.reduce((arr, user) => {
        if (user) {
          const { assessorID, name } = user
          // if the user in the list is also the author of the selected attempt, do not allow them in the selectable options
          if (selectedAttempt?.userID !== assessorID) {
            arr.push({ assessorID, name })
          }
        }
        return arr
      }, [])
    }
    return userArr
  }, [assessors, selectedAttempt])

  const fetchAllUsers = () => {
    setLoadingUsers(true)
    const finished = () => { setLoadingUsers(false) }
    dispatch(getAssessorsList({ fetchAll: true }, finished, finished))
  }

  // open the dialog and fetch the current users
  const handleAddAssessorOpen = (attempt) => {
    setAddAssessorOpen(true)
    setSelectedAttempt(attempt)

    fetchAllUsers()
  }

  // ******************** Data Creation ******************** //

  // If the filter was changed, fetch the fellows with the new filter
  useEffect(() => {
    if (filter && defaultFilter && activeButton === adminPageOptions.MCE_EVALUATIONS) {
      if (!isEqual(filter, defaultFilter)) {
        dispatch(getEvaluationsList(filter))
      }
    }
  }, [dispatch, filter, defaultFilter, setFilter, activeButton])

  // Data rows for the displayed table
  const [rows, setRows] = useState([])

  useEffect(() => {
    if (mceEvaluations) {
      if (mceEvaluations.length) {
        const newRows = []
        mceEvaluations.forEach(evaluation => {
          const { attemptID, attemptShortID, mceRevisionDate, mceUpdatedDate, versionNum, mceTitle, userID, dateSubmitted, attemptStatus, attemptCount, assessorName, assessorID, authorName, emailAddress } = evaluation
          newRows.push({ attemptID, attemptShortID, mceRevisionDate, mceUpdatedDate, versionNum, mceTitle, userID, dateSubmitted, attemptStatus, attemptCount, assessorName, assessorID, authorName, emailAddress })
        })
        setRows(newRows)
      } else {
        setRows([])
      }
    }
  }, [mceEvaluations])

  // Column Headers
  const headCells = [
    { id: 'attemptShortID', label: 'ID', align: 'left' },
    { id: 'mceTitle', label: 'Micro-Credential', align: 'left' },
    { id: 'mceRevisionDate', label: 'Credential Version', align: 'left' },
    { id: 'dateSubmitted', label: 'Attempt Submitted', align: 'left' },
    { id: 'attemptCount', label: 'Attempt #', align: 'center' },
    { id: 'authorName', label: 'Pursuer', align: 'left' },
    { id: 'attemptStatus', label: 'Status', align: 'center' },
    { id: 'assessorName', label: 'Assessor', align: 'left' }
  ]

  // ********************* Pagination Logic: **************** //
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const handleChangePage = (event, value) => {
    if (value >= 0) {
      setPage(value - 1)
      setFilter({
        ...filter,
        sortCount: rowsPerPage,
        page: value
      })
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: event.target.value
    })
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalEvaluations - page * rowsPerPage)

  // ******************** Column Sort Logic **************** //

  const [orderBy, setOrderBy] = useState('')
  const [order, setOrder] = useState('desc')

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: rowsPerPage,
      sortDirection: isAsc ? 'DESC' : 'ASC',
      sortType: property
    })
  }

  // ********************** Search Logic *********************** //
  const [searchInput, setSearchInput] = useState('')

  const handleSearch = (e) => {
    setSearchInput(e.target.value)
    if (searchInput && searchInput.length >= 3) {
      // Previously, issues were occuring if the user was on a page that was greater than 1 and made a search. The page is now being reset beforehand to avoid that.
      setPage(0)
      setFilter({
        ...filter,
        page: 1,
        sortCount: rowsPerPage,
        searchPhrase: searchInput
      })
    }

    if (e.target.value === '') {
      setPage(0)
      // Set the sortCount to current rowsPerPage instead of the default
      setFilter({
        ...defaultFilter,
        sortCount: rowsPerPage
      })
    }
  }

  const handleResetAssessor = () => {
    setAddAssessorOpen(false)
    setSelectedAttempt({})
    setSelectedAssessor(null)
  }

  const handleAssignAssessor = async (attemptID, assessorID) => {
    dispatch(assignEvaluationAssessor(attemptID, assessorID, handleAssignSuccess, handleAssignFailure))
  }

  const handleAssignSuccess = () => {
    handleResetAssessor()
    NotificationToast(false, 'Sucessfully Assigned Assessor')
    dispatch(getEvaluationsList())
  }

  const handleAssignFailure = () => {
    NotificationToast(true, 'Unable to Assign Assessor')
  }

  // ********************** CSV Export Logic *********************** //

  // Converts data pulled from fetchAllMCEAttempts into CSV format
  const handleConvertToCSV = (data) => {
    if (!data.length) { return false }

    // Formats the header row
    const headers = Object.keys(data[0])
    const formattedHeaders = headers.map(formatCSVHeader)
    const csvRows = []

    // Create the header row
    csvRows.push(formattedHeaders.join(','))

    // Create the data rows
    for (const row of data) {
      const values = headers.map((header) => {
        const escaped = String(row[header]).replace(/,/g, '\\,')
        return `"${escaped}"`
      })
      csvRows.push(values.join(','))
    }

    return csvRows.join('\n')
  }

  // Downloads formatted data as a CSV file
  const handleDownloadCSVFile = (csvData, filename) => {
    try {
      const blob = new window.Blob([csvData], { type: 'text/csv;charset=utf-8' })
      const url = URL.createObjectURL(blob)

      if (url) {
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.setAttribute('download', filename)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(url)

        NotificationToast(false, 'Sucessfully Downloaded MCE Attempts')
      } else {
        fireCSVFailure()
      }
    } catch (error) {
      console.error('Error downloading CSV file:', error)
      fireCSVFailure()
    }
  }

  // Success function for fetchAllMCEAttempts that converts and downloads data as a CSV file
  const fireConvertAndDownloadCSV = (data) => {
    const csvData = handleConvertToCSV(data)
    if (csvData) { handleDownloadCSVFile(csvData, 'microcredential-attempts.csv') } else { fireCSVFailure() }
  }

  // Fires if anything goes wrong during the process
  const fireCSVFailure = () => {
    NotificationToast(true, 'Unable to Download MCE Attempts')
  }

  // Fetches all MCE attempts in order to download them as a CSV file
  const handleFetchAllMCEAttempts = async () => { dispatch(getEvaluationExports(fireConvertAndDownloadCSV, fireCSVFailure)) }

  return (
    <Grid container direction='column'>
      <Dialog
        open={addAssessorOpen}
        onClose={handleResetAssessor}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle disableTypography>
          <Typography variant='h4' style={{ margin: '.5em' }}>Add Assessor</Typography>
        </DialogTitle>
        <DialogContent
          dividers
        >
          <Grid container direction='column'>
            <Grid item container direction='column' style={{ marginBottom: '1.5rem' }}>
              <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}>Choose a User:</Typography>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchRounded className={classes.searchSvg} />
                </div>
                <Autocomplete
                  fullWidth
                  loading={loadingUsers}
                  loadingText='Loading Options...'
                  value={selectedAssessor}
                  options={allUsers}
                  getOptionLabel={(option) => option && option.name ? option.name : ''}
                  getOptionSelected={(option) => option && option.assessorID === selectedAssessor.assessorID}
                  onChange={(e, user) => setSelectedAssessor(user)}
                  classes={{
                    inputRoot: classes.inputInput,
                    input: classes.inputRoot
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      placeholder='Search for a user...'
                    />
                  )}
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            color='primary'
            style={{
              margin: '.5em 1em',
              fontWeight: '600'
            }}
            onClick={(e) => { e.preventDefault(); handleResetAssessor() }}
          >
            Cancel
          </Button>

          <Button
            variant='contained'
            color='primary'
            disabled={!selectedAssessor}
            style={{
              margin: '.5em 1em',
              fontWeight: '600'
            }}
            onClick={(e) => { e.preventDefault(); handleAssignAssessor(selectedAttempt.attemptID, selectedAssessor.assessorID) }}
          >
            Save
          </Button>

        </DialogActions>
      </Dialog>

      <Grid item container style={{ marginBottom: '2em' }} alignItems='center'>
        {/* Page Title */}
        <Grid item container xs={superAdmin ? 9 : 12} justifyContent='flex-start'>
          <Typography variant='h4' style={{ fontSize: '20px' }}>Evaluations</Typography>
        </Grid>
        {/* Download All MCE Attempts Button (Super Admins Only) */}
        {Boolean(superAdmin) &&
          <Grid item container xs={3} justifyContent='flex-end'>
            <Button
              color='primary'
              variant='contained'
              style={{ fontWeight: '600' }}
              startIcon={
                <GetApp style={{ marginTop: '0.1em' }} />
              }
              onClick={handleFetchAllMCEAttempts}
            >
              Download All
            </Button>
          </Grid>}
      </Grid>

      {/* Top Pagination display and search input */}
      <Grid item container direction='row' style={{ marginBottom: '1em' }}>
        <Grid item container alignContent='flex-end' xs={7}>
          <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none', fontWeight: 400, fontSize: '16px' }}>
            Displaying {totalEvaluations === 0 ? 0 : (page * rowsPerPage) + 1} to {rows && page * rowsPerPage + rowsPerPage > totalEvaluations ? totalEvaluations : page * rowsPerPage + rowsPerPage} of {totalEvaluations}
          </Typography>
        </Grid>
        <Grid item container justifyContent='flex-end' xs={5}>
          <OutlinedInput
            className={classes.searchInput}
            size='small'
            margin='dense'
            fullWidth
            inputProps={{ style: { border: 'none', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '5px' } }}
            placeholder='Search...'
            classes={{ input: classes.inputPlaceholder }}
            startAdornment={
              <SearchRounded style={{ color: theme.palette.grey.dark, fontSize: '20px' }} />
            }
            value={searchInput}
            onChange={(e) => handleSearch(e)}
          />
        </Grid>
      </Grid>

      {/* Data Table */}
      <Grid item container direction='column' style={{ marginBottom: '1em' }}>
        <TableContainer elevation={0} style={{ border: `solid 1px ${theme.palette.grey.medium}` }} component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: theme.palette.grey.lighter }}>
              <TableRow>
                {/* Sortable Table Column Headers */}
                {headCells.map((header, index) => {
                  return (
                    <TableCell
                      key={index}
                      align={header.align}
                      style={{ padding: '16px' }}
                      sortDirection={orderBy === header.id ? 'asc' : false}
                    >
                      <TableSortLabel
                        active={orderBy === header.id}
                        direction={orderBy === header.id ? order : 'asc'}
                        onClick={() => handleRequestSort(header.id)}
                      >
                        <Typography variant='h5' style={{ color: theme.palette.grey.dark }}>{header.label}</Typography>
                        {orderBy === header.id ? (
                          <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  )
                })}
                <TableCell align='left' style={{ padding: '16px' }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                const { attemptShortID, mceRevisionDate, mceUpdatedDate, versionNum, userID, mceTitle, dateSubmitted, attemptStatus, attemptCount, assessorName, assessorID, authorName = '', emailAddress } = row
                const formatSubmitDate = moment(dateSubmitted * 1000).format('L')
                const revisionDate = !mceUpdatedDate ? mceRevisionDate : mceUpdatedDate
                const formatRevisionDate = moment(revisionDate * 1000).format('L')
                const adminIsAuthor = Boolean(userID === authUserID)
                const attemptPassed = Boolean(attemptStatus === attemptStatuses.PASS)
                const labelChipOpts = statusLabels[attemptStatus] || { label: 'N/A', backgroundColor: theme.palette.grey.medium }
                const authorText = attemptPassed ? authorName : adminIsAuthor ? 'You' : 'Hidden'

                return (
                  <TableRow key={`index-${index}`}>
                    <TableCell>
                      <Typography variant='body1' style={{ fontWeight: 600, color: theme.palette.grey.darker }}>{attemptShortID}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body1' style={{ fontWeight: 600 }}>{mceTitle}</Typography>
                    </TableCell>
                    <TableCell>
                      <Grid item container direction='column'>
                        <Typography variant='caption' style={{ fontWeight: 600 }}>{`Version ${versionNum}`}</Typography>
                        <Typography variant='caption' style={{ fontWeight: 400 }}>{formatRevisionDate}</Typography>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body1' style={{ fontWeight: 400 }}>{formatSubmitDate}</Typography>
                    </TableCell>
                    <TableCell align='center'>
                      <Typography variant='body1' style={{ fontWeight: 600 }}>{attemptCount}</Typography>
                    </TableCell>
                    <TableCell>
                      <Grid item container direction='column'>
                        <Typography variant='body1' style={{ fontWeight: 600, color: attemptPassed ? 'black' : theme.palette.grey.medium }}>{authorText}</Typography>
                        {attemptPassed && <Typography variant='caption' style={{ fontWeight: 400 }}>{emailAddress}</Typography>}
                      </Grid>
                    </TableCell>
                    <TableCell align='center'>
                      <Chip size='small' variant={labelChipOpts.variant} label={labelChipOpts.label} style={{ ...labelChipOpts.styles, fontWeight: 600, width: '-webkit-fill-available' }} />
                    </TableCell>
                    <TableCell colSpan={!assessorName ? 0 : 2}>
                      <Typography variant='body1' style={{ fontWeight: assessorID ? 400 : 600 }}>{assessorName || 'Not Assigned'}</Typography>
                    </TableCell>
                    {!assessorID && !assessorName &&
                      <TableCell colSpan={2}>
                        <Tooltip arrow enterDelay={750} enterNextDelay={750} title={adminIsAuthor ? 'You cannot assign an assessor to your own attempt.' : ''}>
                          <span>
                            <IconButton
                              className={classes.editIcon}
                              style={{ color: adminIsAuthor ? theme.palette.grey.medium : theme.palette.purple.darkest }}
                              onClick={(e) => { e.preventDefault(); handleAddAssessorOpen(row) }}
                              disabled={adminIsAuthor}
                            >
                              <AddCircle />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </TableCell>}

                  </TableRow>
                )
              })}
              {/* Data Array has reached it's length: */}
              {emptyRows > 0 && (
                <TableRow style={{ height: 10 }}>
                  <TableCell colSpan={12}>
                    <Typography variant='body1' style={{ color: theme.palette.grey.medium, textAlign: 'center' }}>{totalEvaluations === 0 ? 'No Results' : 'End of List'}</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* Bottom Pagination Controls */}
      <Grid item container direction='row' style={{ marginBottom: '4em' }}>
        {/* Rows per Page Selection */}
        <Grid item container direction='row' xs={6} justifyContent='flex-start'>
          <Grid item style={{ marginRight: '.2em', display: 'flex', alignItems: 'flex-end' }}>
            <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>Display</Typography>
          </Grid>
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <Select
              variant='outlined'
              size='small'
              defaultValue={10}
              value={rowsPerPage}
              onChange={(e) => handleChangeRowsPerPage(e)}
              classes={{ root: classes.searchInput, selectMenu: classes.statusSelect }}
              style={{ padding: 0 }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </Grid>
          <Grid item style={{ marginLeft: '.4em', display: 'flex', alignItems: 'flex-end' }}>
            <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>entries</Typography>
          </Grid>
        </Grid>
        {/* Pagination/ Page Selection */}
        <Grid item container xs={6} justifyContent='flex-end'>
          <Pagination
            color='primary'
            classes={{ root: classes.pagination }}
            count={totalEvaluations ? Math.ceil(totalEvaluations / rowsPerPage) : 0}
            page={page === 0 ? 1 : page + 1}
            onChange={handleChangePage}
            shape='rounded'
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AdminMCEEvaluations
