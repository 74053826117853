import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'

import {
  Grid, Typography, useTheme, Button, OutlinedInput, Table, TableRow, TableSortLabel, TableContainer, TableCell, TableHead,
  TableBody, IconButton, Paper, Select, MenuItem, Dialog, DialogContent, DialogActions, DialogTitle, TextField
} from '@material-ui/core'
import { Autocomplete, Pagination } from '@material-ui/lab'
import {
  AddRounded,
  SearchRounded,
  DeleteOutline
} from '@material-ui/icons'

import { NotificationToast, ConfirmToast } from '../../tools'

import { createSchool, updateSchool, deleteSchool } from '../../../../redux/actions'

const ModifiedEditIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20' height='18.337'
      viewBox='0 0 24 21.337'
    >
      <path
        id='edit-regular_1_'
        data-name='edit-regular (1)'
        d='M16.763,14.3,18.1,12.966a.335.335,0,0,1,.571.238v6.058a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V4.595a2,2,0,0,1,2-2H13.4a.335.335,0,0,1,.238.571L12.3,4.5a.33.33,0,0,1-.238.1H2V19.262H16.667V14.533A.328.328,0,0,1,16.763,14.3Zm6.525-8.409L12.346,16.833l-3.767.417a1.721,1.721,0,0,1-1.9-1.9L7.1,11.583,18.038.641a2.432,2.432,0,0,1,3.446,0l1.8,1.8a2.441,2.441,0,0,1,0,3.45ZM19.171,7.178,16.75,4.757,9.009,12.5l-.3,2.721,2.721-.3Zm2.7-3.321-1.8-1.8a.435.435,0,0,0-.617,0L18.167,3.345l2.421,2.421,1.288-1.288A.444.444,0,0,0,21.871,3.857Z'
        transform='translate(0 0.075)'
        fill='#24890E'
      />
    </svg>
  )
}

const AdminSchools = (props) => {
  const {
    classes, filter, setFilter, defaultFilter, activeButton, getSchoolsAndDistricts, schools, totalSchools
  } = props
  const theme = useTheme()
  const dispatch = useDispatch()

  const defaultSelectedDistrict = { schoolDistrictID: '', districtName: '' }
  const [selectedDistrict, setSelectedDistrict] = useState(defaultSelectedDistrict)

  const [fullDistrictsList, setFullDistrictsList] = useState([])

  const {
    adminSchools: { districtOptions: Districts = [] } = {}
  } = useSelector(state => state.admin)

  // If the filter was changed, fetch the schools and districts with the new filter
  useEffect(() => {
    if (filter && defaultFilter && activeButton === 'schools') {
      if (!isEqual(filter, defaultFilter)) {
        dispatch(getSchoolsAndDistricts(filter))
      }
    }
  }, [dispatch, filter, setFilter, defaultFilter, activeButton, getSchoolsAndDistricts])

  // ********************* New/Edit School logic: ******************** //

  const onOpen = async () => {
    await dispatch(getSchoolsAndDistricts({ allResults: true }))

    const newDitrictsList = !Districts ? [] : Districts
    setFullDistrictsList(newDitrictsList)
    setCreateOpen(true)
  }
  const defaultSchoolForm = {
    schoolName: '',
    address: '',
    address2: '',
    city: '',
    stateCode: '',
    postalCode: 0
  }
  const [newSchoolForm, setNewSchoolForm] = useState(defaultSchoolForm)

  const [createOpen, setCreateOpen] = useState(false)
  const [editID, setEditID] = useState('')

  const handleOpenModal = async (schoolInfo) => {
    if (schoolInfo) {
      const { schoolID, schoolName, districtID: schoolDistrictID, districtName } = schoolInfo
      setEditID(schoolID)
      setNewSchoolForm({ ...newSchoolForm, schoolName })
      setSelectedDistrict({ schoolDistrictID, districtName })
    } else {
      setEditID('')
    }
    onOpen()
  }

  // ******************************** Table Data Logic: ******************************* //

  const createData = (districtID, districtName, schools) => {
    return { districtID, districtName, ...schools }
  }

  // Data rows for the displayed table
  const [rows, setRows] = useState([])

  // Take the teacherFellows array and format it into the data rows
  useEffect(() => {
    if (schools) {
      if (schools.length) {
        const newRows = []
        schools.forEach(school => {
          const { schoolDistrictID, districtName, schools } = school
          schools.forEach(singleSchool => { newRows.push(createData(schoolDistrictID, districtName, singleSchool)) })
        })
        setRows(newRows)
      } else {
        setRows([])
      }
    }
  }, [schools])

  const headCells = [
    { id: 'schoolName', label: 'School Name' },
    { id: 'districtName', label: 'District' }
  ]

  // ******************************* Pagination logic: ******************************* //
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const handleChangePage = (event, value) => {
    if (value >= 0) {
      setPage(value - 1)
      setFilter({
        ...filter,
        sortCount: rowsPerPage,
        page: value
      })
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: event.target.value
    })
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalSchools - page * rowsPerPage)

  // **************************** Column Sort Logic ************************ //

  const [orderBy, setOrderBy] = useState('')
  const [order, setOrder] = useState('asc')

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
    // setFilter({
    //   ...filter,
    //   page: 1,
    //   sortCount: rowsPerPage,
    //   sortDirection: isAsc ? 'DESC' : 'ASC',
    //   sortType: property
    // })
  }

  const handleCreateModalClose = async () => {
    setCreateOpen(false)
    setNewSchoolForm(defaultSchoolForm)
    setSelectedDistrict(defaultSelectedDistrict)
    setEditID('')
    setPage(0)
    await dispatch(getSchoolsAndDistricts())
  }

  // ********************** Save New School Logic *********************** //

  const handleSaveNewSchools = () => {
    const schoolInfo = { schoolDistrictID: selectedDistrict.schoolDistrictID, ...newSchoolForm }

    if (editID === '') {
      dispatch(createSchool(schoolInfo, fireSaveSuccess, fireSaveFailure))
    } else {
      schoolInfo.schoolID = editID
      dispatch(updateSchool(schoolInfo, fireUpdateSuccess, fireUpdateFailure))
    }
  }

  const fireSaveSuccess = () => {
    handleCreateModalClose()
    NotificationToast(false, 'Sucessfully Added School')
    dispatch(getSchoolsAndDistricts())
  }

  const fireSaveFailure = () => { NotificationToast(true, 'Failed To Add School') }

  const fireUpdateSuccess = () => {
    handleCreateModalClose()
    NotificationToast(false, 'Sucessfully Updated School')
    dispatch(getSchoolsAndDistricts())
  }

  const fireUpdateFailure = () => { NotificationToast(true, 'Failed To Update School') }

  // ********************** Delete School ********************** //

  const fireDeleteSuccess = () => {
    // Fires the dispatch to pull the new list
    dispatch(getSchoolsAndDistricts())
  }

  const fireDeleteFailure = () => {
    NotificationToast(true, 'There was an error deleting the selected school.')
  }

  const handleDeleteSchool = async (id) => {
    const toastMessage = {
      msg: 'Are you sure you wish to delete this school?',
      txt: 'This action is permanent and cannot be undone.'
    }
    const verifyDelete = await ConfirmToast(toastMessage)

    if (verifyDelete) {
      dispatch(deleteSchool(id, fireDeleteSuccess, fireDeleteFailure))
    }
  }

  // ********************** Search Logic *********************** //
  const [searchInput, setSearchInput] = useState('')

  const handleSearch = (e) => {
    setSearchInput(e.target.value)
    if (searchInput && searchInput.length >= 3) {
      // Previously, issues were occuring if the user was on a page that was greater than 1 and made a search. The page is now being reset beforehand to avoid that.
      setPage(0)
      setFilter({
        ...filter,
        page: 1,
        sortCount: rowsPerPage,
        searchPhrase: searchInput
      })
    }

    if (e.target.value === '') {
      setPage(0)
      // Set the sortCount to current rowsPerPage instead of the default
      setFilter({
        ...defaultFilter,
        sortCount: rowsPerPage
      })
    }
  }

  const isEnabled = newSchoolForm && newSchoolForm.schoolName !== '' && selectedDistrict && selectedDistrict.districtID !== ''

  return (
    <Grid container direction='column'>

      {/* ----------Add School Modal--------- */}
      <Dialog
        open={createOpen}
        onClose={() => handleCreateModalClose()}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle disableTypography>
          <Typography variant='h4' style={{ margin: '.5em' }}>{editID ? 'Edit School' : 'Add School'}</Typography>
        </DialogTitle>
        <DialogContent
          dividers
        >
          <Grid container direction='column' style={{ margin: '1em', paddingRight: '2em' }}>
            {/* DISTRICT */}
            <Grid item container direction='column' style={{ marginBottom: '1.5rem' }}>
              <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}>District <span style={{ color: 'red', fontSize: '14px' }}>*</span></Typography>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchRounded className={classes.searchSvg} />
                </div>
                <Autocomplete
                  fullWidth
                  value={selectedDistrict}
                  onChange={(e, district) => setSelectedDistrict(district)}
                  // inputValue={}
                  // onInputChange={(e, val) => }
                  options={fullDistrictsList}
                  getOptionLabel={(option) => option && option.districtName ? option.districtName : ''}
                  getOptionSelected={(option) => option && option.districtID === selectedDistrict.districtID}
                  classes={{
                    inputRoot: classes.inputInput,
                    input: classes.inputRoot
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      placeholder='Search for district'
                    />
                  )}
                />
              </div>
            </Grid>

            {/* SCHOOL NAME */}
            <Grid item container direction='column' style={{ marginTop: 0 }}>
              <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}>School Name <span style={{ color: 'red', fontSize: '14px' }}>*</span></Typography>
              <OutlinedInput
                fullWidth
                margin='dense'
                size='small'
                value={newSchoolForm ? newSchoolForm.schoolName : ''}
                onChange={(e) => setNewSchoolForm({ ...newSchoolForm, schoolName: e.target.value })}
                placeholder='Enter school name...'
                classes={{ input: classes.inputPlaceholder }}
              />
            </Grid>

            {/* ADDRESS */}
            {/* <Grid item container direction='column' style={{ marginTop: '1rem' }}>
              <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}>Address</Typography>
              <OutlinedInput
                fullWidth
                margin='dense'
                size='small'
                value={newSchoolForm ? newSchoolForm.address : ''}
                onChange={(e) => setNewSchoolForm({ ...newSchoolForm, address: e.target.value })}
                placeholder='Enter address...'
                classes={{ input: classes.inputPlaceholder }}
              />
            </Grid> */}

            {/* ADDRESS 2 */}
            {/* <Grid item container direction='column' style={{ marginTop: '1rem' }}>
              <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}>Address 2</Typography>
              <OutlinedInput
                fullWidth
                margin='dense'
                size='small'
                value={newSchoolForm ? newSchoolForm.address2 : ''}
                onChange={(e) => setNewSchoolForm({ ...newSchoolForm, address2: e.target.value })}
                placeholder='Enter address...'
                classes={{ input: classes.inputPlaceholder }}
              />
            </Grid> */}

            {/* ADD ANOTHER SCHOOL */}
            {/* <Grid item style={{ marginTop: '.5em' }}>
              <Button
                variant='text'
                startIcon={
                  <AddRounded className={classes.addNewIcon} />
                }
                className={classes.addAnotherBtn}
              >
                Add another school
              </Button>
            </Grid> */}

          </Grid>
        </DialogContent>
        <DialogActions>
          {/* {errors.noName &&
            <Typography variant='caption' style={{ color: 'red' }}>* School Name is required to submit. </Typography>} */}
          <Button
            variant='outlined'
            color='primary'
            style={{
              margin: '.5em 1em',
              fontWeight: '600'
            }}
            onClick={(e) => { e.preventDefault(); handleCreateModalClose() }}
          >
            Cancel
          </Button>

          <Button
            variant='contained'
            color='primary'
            style={{
              margin: '.5em 1em',
              fontWeight: '600'
            }}
            disabled={!isEnabled}
            onClick={(e) => { e.preventDefault(); handleSaveNewSchools() }}
          >
            Save
          </Button>

        </DialogActions>
      </Dialog>

      {/* ---------------------- Main Component Area ----------------------- */}
      <Grid item container style={{ marginBottom: '2em' }}>
        {/* Page Title */}
        <Grid item container xs={9} justifyContent='flex-start'>
          <Typography variant='h4' style={{ fontSize: '20px' }}>Schools</Typography>
        </Grid>
        <Grid item container xs={3} justifyContent='flex-end'>
          <Button
            color='primary'
            variant='contained'
            style={{ fontWeight: '600' }}
            startIcon={
              <AddRounded className={classes.addIcon} />
            }
            onClick={onOpen}
          >
            Add School
          </Button>
        </Grid>
      </Grid>

      {/* Top Pagination display and search input */}
      <Grid item container direction='row' style={{ marginBottom: '1em', marginTop: '1em' }}>
        <Grid item container alignContent='flex-end' xs={7}>
          <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none', fontWeight: 400, fontSize: '16px' }}>
            Displaying {totalSchools === 0 ? 0 : (page * rowsPerPage) + 1} to {rows && page * rowsPerPage + rowsPerPage > totalSchools ? totalSchools : page * rowsPerPage + rowsPerPage} of {totalSchools}
          </Typography>
        </Grid>
        <Grid item container justifyContent='flex-end' xs={5}>
          <OutlinedInput
            className={classes.searchInput}
            size='small'
            margin='dense'
            fullWidth
            inputProps={{ style: { border: 'none', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '5px' } }}
            placeholder='Search...'
            classes={{ input: classes.inputPlaceholder }}
            startAdornment={
              <SearchRounded style={{ color: theme.palette.grey.dark, fontSize: '20px' }} />
            }
            value={searchInput}
            onChange={(e) => handleSearch(e)}
          />
        </Grid>
      </Grid>

      {/* Data Table */}
      <Grid item container direction='column' style={{ marginBottom: '1em' }}>
        <TableContainer elevation={0} style={{ border: `solid 1px ${theme.palette.grey.medium}` }} component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: theme.palette.grey.lighter }}>
              <TableRow>
                {headCells.map(header => {
                  return (
                    <TableCell
                      key={header.id}
                      align='left'
                      style={{ padding: '16px' }}
                      sortDirection={orderBy === header.id ? 'asc' : false}
                    >
                      <TableSortLabel
                        active={orderBy === header.id}
                        direction={orderBy === header.id ? order : 'asc'}
                        onClick={() => handleRequestSort(header.id)}
                      >
                        <Typography variant='h5' style={{ color: theme.palette.grey.dark }}>{header.label}</Typography>
                        {orderBy === header.id ? (
                          <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  )
                })}
                <TableCell align='left' style={{ padding: '16px' }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow key={`index-${row.schoolID}`}>
                    <TableCell>
                      <Typography variant='body1' style={{ fontWeight: 600 }}>{row.schoolName}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body1' style={{ fontWeight: 600 }}>{row.districtName}</Typography>
                    </TableCell>
                    <TableCell align='right'>
                      <Grid item container direction='row' justifyContent='flex-end'>
                        <IconButton
                          className={classes.editIcon}
                          onClick={() => handleOpenModal(row)}
                        >
                          <ModifiedEditIcon />
                        </IconButton>
                        <IconButton
                          className={classes.deleteIcon}
                          onClick={() => handleDeleteSchool(row.schoolID)}
                        >
                          <DeleteOutline />
                        </IconButton>
                      </Grid>
                    </TableCell>
                  </TableRow>
                )
              })}
              {/* When the data has reached its length: */}
              {emptyRows > 0 && (
                <TableRow style={{ height: 10 }}>
                  <TableCell colSpan={5}>
                    <Typography variant='body1' style={{ color: theme.palette.grey.medium, textAlign: 'center' }}>End of List</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* Bottom Pagination Controls */}
      <Grid item container direction='row' style={{ marginBottom: '4em' }}>
        {/* Rows Per page selection */}
        <Grid item container direction='row' xs={6} justifyContent='flex-start'>
          <Grid item style={{ marginRight: '.2em', display: 'flex', alignItems: 'flex-end' }}>
            <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>Display</Typography>
          </Grid>
          <Grid item style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Select
              variant='outlined'
              size='small'
              defaultValue={10}
              value={rowsPerPage}
              onChange={(e) => handleChangeRowsPerPage(e)}
              classes={{ root: classes.searchInput, selectMenu: classes.statusSelect }}
              style={{ padding: 0 }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </Grid>
          <Grid item style={{ marginLeft: '.4em', display: 'flex', alignItems: 'flex-end' }}>
            <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>entries</Typography>
          </Grid>
        </Grid>
        {/* Pages/Pagination Display */}
        <Grid item container xs={6} justifyContent='flex-end'>
          <Pagination
            color='primary'
            classes={{ root: classes.pagination }}
            count={totalSchools ? Math.ceil(totalSchools / rowsPerPage) : 0}
            page={page === 0 ? 1 : page + 1}
            onChange={handleChangePage}
            shape='rounded'
          />
        </Grid>
      </Grid>

    </Grid>
  )
}

export default AdminSchools
