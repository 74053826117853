import React from 'react'
import Loader from 'react-loader-spinner'

const LoadingScreen = () => {
  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 4,
        background: 'black',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: 0.6
      }}
    >
      <Loader type='ThreeDots' color='#B94197' height={100} width={100} />
    </div>
  )
}

export default LoadingScreen
