import React, { useState, useCallback, useEffect } from 'react'
import { FileDrop } from 'react-file-drop'
import { TimePicker, DatePicker } from '@material-ui/pickers'

import {
  Paper, Grid, Typography, OutlinedInput, Select, MenuItem, useTheme,
  RadioGroup, FormControlLabel, Radio, Button, Tooltip
} from '@material-ui/core'

import {
  SearchRounded as SearchRoundedIcon,
  Replay as ReplayIcon
} from '@material-ui/icons'

import {
  gradeCategoriesFormat, efrSubTypesFormat, efrSubTypes,
  uploadType, uploadFileFormatter
} from '../../../../utils'

import { PublishButton } from '../../tools'

// Icon for photo upload display
const UploadPhoto = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='30'
      height='30'
      viewBox='0 0 475.078 475.077'
    >
      <g>
        <g>
          <path
            d='M467.081,327.767c-5.321-5.331-11.797-7.994-19.411-7.994h-121.91c-3.994,10.657-10.705,19.411-20.126,26.262
            c-9.425,6.852-19.938,10.28-31.546,10.28h-73.096c-11.609,0-22.126-3.429-31.545-10.28c-9.423-6.851-16.13-15.604-20.127-26.262
            H27.408c-7.612,0-14.083,2.663-19.414,7.994C2.664,333.092,0,339.563,0,347.178v91.361c0,7.61,2.664,14.089,7.994,19.41
            c5.33,5.329,11.801,7.991,19.414,7.991h420.266c7.61,0,14.086-2.662,19.41-7.991c5.332-5.328,7.994-11.8,7.994-19.41v-91.361
            C475.078,339.563,472.416,333.099,467.081,327.767z M360.025,423.978c-3.621,3.617-7.905,5.428-12.854,5.428
            s-9.227-1.811-12.847-5.428c-3.614-3.613-5.421-7.898-5.421-12.847s1.807-9.236,5.421-12.847c3.62-3.613,7.898-5.428,12.847-5.428
            s9.232,1.814,12.854,5.428c3.613,3.61,5.421,7.898,5.421,12.847S363.638,420.364,360.025,423.978z M433.109,423.978
            c-3.614,3.617-7.898,5.428-12.848,5.428c-4.948,0-9.229-1.811-12.847-5.428c-3.613-3.613-5.42-7.898-5.42-12.847
            s1.807-9.236,5.42-12.847c3.617-3.613,7.898-5.428,12.847-5.428c4.949,0,9.233,1.814,12.848,5.428
            c3.617,3.61,5.427,7.898,5.427,12.847S436.729,420.364,433.109,423.978z'
            fill='#025676'
          />
          <path
            d='M109.632,173.59h73.089v127.909c0,4.948,1.809,9.232,5.424,12.847c3.617,3.613,7.9,5.427,12.847,5.427h73.096
            c4.948,0,9.227-1.813,12.847-5.427c3.614-3.614,5.421-7.898,5.421-12.847V173.59h73.091c7.997,0,13.613-3.809,16.844-11.42
            c3.237-7.422,1.902-13.99-3.997-19.701L250.385,14.562c-3.429-3.617-7.706-5.426-12.847-5.426c-5.136,0-9.419,1.809-12.847,5.426
            L96.786,142.469c-5.902,5.711-7.233,12.275-3.999,19.701C96.026,169.785,101.64,173.59,109.632,173.59z'
            fill='#025676'
          />
        </g>
      </g>
    </svg>
  )
}

const EFRDetails = (props) => {
  const {
    classes, styles, newEFRForm, setNewEFRForm, setEventDate, setEventStartTime, setEventEndTime,
    startTime, setStartTime, endTime, setEndTime, displayEventDate, setDisplayEventDate, isDraft = false,
    artifactNotCompleteCheck = false, setPublishModalOpen = () => { }, handleSubmit = () => { },
    artifactType, resourceLinkInvalid
  } = props

  const theme = useTheme()

  // Values to set the select component popper and value based on the user's selection
  const [audienceOpen, setAudienceOpen] = useState(false)
  const [audienceValue, setAudienceValue] = useState(newEFRForm && newEFRForm.resourceAudience ? newEFRForm.resourceAudience : '')

  // Image upload/file drop state values
  const [areaEntered, setAreaEntered] = useState(false)
  const [newImage, setNewImage] = useState('')

  const handleDateChange = (eventDate) => {
    // eventDate = moment object
    // If eventDate prop is null the user has cleared the date
    if (eventDate !== null) {
      // format the moment object for the submission form
      const formatEventDate = eventDate.format('l')
      setEventDate(formatEventDate)
    } else {
      // if null be sure to clear the submission form so the error checks will catch
      setEventDate('')
    }
    // either way set the picker value to the eventDate prop
    setDisplayEventDate(eventDate)
  }

  // Callback/useeffect to ensure the audience value is set when editing
  const handleAudienceSet = useCallback(() => {
    if (newEFRForm && newEFRForm.artifactID) {
      setAudienceValue(newEFRForm.resourceAudience)
    }
  }, [newEFRForm])

  useEffect(() => {
    handleAudienceSet()
  }, [handleAudienceSet])

  // Callback/useeffect to ensure the correct image path (if relevant) is set when editing
  const handleImageSet = useCallback(() => {
    if (newEFRForm && newEFRForm.bannerImagePath) {
      if (!newEFRForm.bannerUpdated) {
        setNewImage(newEFRForm.bannerImagePath)
      }
    }
  }, [newEFRForm])

  useEffect(() => {
    handleImageSet()
  }, [handleImageSet])

  // Handler for the resourceAudience select component
  const handleAudienceChange = (value) => {
    setAudienceValue(value)
    setNewEFRForm({ ...newEFRForm, resourceAudience: value })
    setAudienceOpen(false)
  }

  // FileDrop state changes
  const handleUploadEnter = () => { setAreaEntered(true) }

  const handleUploadLeave = () => { setAreaEntered(false) }

  const [fileSizeError, setFileSizeError] = useState(false)
  const [fileExtensionError, setFileExtensionError] = useState(false)

  const fileErrorReset = () => { setFileSizeError(false); setFileExtensionError(false) }

  const handleImageUpload = async (e, file) => {
    e.preventDefault()

    // Reset errors on initial upload to clear out previous
    fileErrorReset()

    const handleFileSizeError = (error) => setFileSizeError(error)
    const handleFileExtensionError = (error) => setFileExtensionError(error)

    const type = uploadType.PROFILE_IMAGE
    const attachments = []
    const imageResult = await uploadFileFormatter(file, type, attachments, handleFileSizeError, handleFileExtensionError)

    if (imageResult) {
      const { imageData, fileExtension } = imageResult
      setNewImage(imageData)

      // add the base64 string to the form
      const newForm = {
        ...newEFRForm,
        bannerData: imageData,
        bannerType: fileExtension
      }

      // Check if we are editing and be sure to set the banner updated prop
      if (newEFRForm && newEFRForm.artifactID) {
        newForm.bannerUpdated = true
      }

      setNewEFRForm(newForm)
      setAreaEntered(false)
    }
  }

  // Handles when an image is cleared
  const handleImageChange = () => {
    // first clear the bannerData in the form
    const newForm = {
      ...newEFRForm,
      bannerImagePath: '',
      bannerData: '',
      bannerType: ''
    }

    // Check if we are editing (via an existing artifactID) and set the bannerUpdated prop to true
    if (newEFRForm && newEFRForm.artifactID) {
      newForm.bannerUpdated = true
    }

    setNewEFRForm({ ...newForm })
    setNewImage('')
  }

  const handleStartTimeChange = (startTime) => {
    // startTime = moment object
    // If startTime prop is null the user has cleared the start time
    if (startTime !== null) {
      // Format the object for the submission form
      const formatTime = startTime.format('HH:mm')
      setEventStartTime(formatTime)
    } else {
      // Else be sure to reset the form so the form errors will catch
      setEventStartTime(0)
    }
    // either way set the picker value to the prop
    setStartTime(startTime)
  }

  const handleEndTimeChange = (endTime) => {
    // endTime = moment object
    // If endTime prop is null the user has cleared the end time
    if (endTime !== null) {
      // Format the object for the submission form
      const formatTime = endTime.format('HH:mm')
      setEventEndTime(formatTime)
    } else {
      // Else be sure to reset the form so the form errors will catch
      setEventEndTime(0)
    }
    // either way set the picker value to the prop
    setEndTime(endTime)
  }

  // Handles changing the sub type (not available on edit) and reseting the subtype specific values
  const handleTypeChange = (e) => {
    const resetForm = {
      ...newEFRForm,
      resourceType: e.target.value,
      resourceLink: ''
    }
    setNewEFRForm(resetForm)
    setEventDate(0)
    setEventStartTime(0)
    setEventEndTime(0)
  }

  return (
    <Paper className={classes.paperRoot}>
      <Grid container direction='column'>
        {/* Step Header */}
        <Grid container item direction='row' spacing={1} style={{ marginBottom: '1em' }}>
          <Grid item>
            {styles ? styles.bannerIcon : ''}
          </Grid>
          <Grid item>
            <Typography variant='h4'>Resource Details</Typography>
          </Grid>
        </Grid>
        <Grid item container style={{ marginBottom: '.5em' }}>
          <Typography variant='caption'><span style={{ color: 'red', fontSize: '14px' }}>*</span> indicates a required field.</Typography>
        </Grid>

        {/* Resource Name */}
        <Grid item container direction='column' style={{ marginBottom: '1em' }}>
          <Grid item>
            <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}><span style={{ color: 'red', fontSize: '14px' }}>*</span> Resource Name</Typography>
          </Grid>
          <Grid item style={{ paddingRight: '.5em' }}>
            <OutlinedInput
              variant='outlined'
              fullWidth
              inputProps={{
                maxLength: 128
              }}
              margin='dense'
              value={newEFRForm.resourceName}
              onChange={(e) => setNewEFRForm({ ...newEFRForm, resourceName: e.target.value })}
              placeholder='Name your resource...'
              classes={{ input: classes.inputPlaceholder }}
            />
          </Grid>
          <Grid item>
            <Typography variant='caption' style={{ color: '#adadad', paddingLeft: '.3em' }}>{`${newEFRForm && newEFRForm.resourceName && newEFRForm.resourceName.length ? newEFRForm.resourceName.length : 0}/ 128`}</Typography>
          </Grid>
        </Grid>

        {/* Resource Audience */}
        <Grid item xs={12} style={{ marginBottom: '1.5em' }}>
          <Grid item container direction='row' justifyContent='flex-start'>
            <Grid item>
              <Typography style={{ color: 'red', fontSize: '14px', marginRight: '.2em' }}>*</Typography>
            </Grid>
            <Grid item>
              <Typography variant='body1' style={{ fontWeight: '600' }}>Resource Audience</Typography>
            </Grid>
          </Grid>

          <Grid item container direction='row' justifyContent='flex-start' style={{ marginTop: '.5rem' }}>
            <Select
              variant='outlined'
              open={audienceOpen}
              onOpen={() => setAudienceOpen(true)}
              onClose={() => setAudienceOpen(false)}
              fullWidth
              margin='dense'
              value={audienceValue}
              startAdornment={<SearchRoundedIcon style={{ fontSize: '20px', color: theme.palette.grey.dark }} />}
              onChange={(e) => handleAudienceChange(e.target.value)}
              defaultValue=''
            >
              {Object.entries(gradeCategoriesFormat).map(([key, value]) => {
                return (
                  <MenuItem key={`audience-type-${key}`} value={key}>{value}</MenuItem>
                )
              })}
            </Select>
          </Grid>
        </Grid>

        {/* Resource Description */}
        <Grid item container direction='column' style={{ marginBottom: '2em' }}>
          <Grid item>
            <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}><span style={{ color: 'red', fontSize: '14px' }}>*</span>Description</Typography>
          </Grid>
          <Grid item>
            <OutlinedInput
              variant='outlined'
              fullWidth
              multiline
              rows={4}
              inputProps={{
                maxLength: 500
              }}
              margin='dense'
              value={newEFRForm.resourceDescription}
              onChange={(e) => setNewEFRForm({ ...newEFRForm, resourceDescription: e.target.value })}
              placeholder=''
              classes={{ input: classes.inputPlaceholder }}
            />
          </Grid>
          <Grid item>
            <Typography variant='caption' style={{ color: '#adadad', paddingLeft: '.3em' }}>{`${newEFRForm && newEFRForm.resourceDescription && newEFRForm.resourceDescription.length ? newEFRForm.resourceDescription.length : 0}/ 500`}</Typography>
          </Grid>
        </Grid>

        {/* Resource Type */}
        <Grid item container direction='column' style={{ marginBottom: '2em' }}>
          <Grid item>
            <Typography variant='body1' style={{ fontWeight: '600' }}><span style={{ color: 'red', fontSize: '14px', lineHeight: 1 }}>*</span> Type of Resource</Typography>
          </Grid>
          <Grid item container direction='row' style={{ paddingRight: '.5em', maxWidth: '60%', marginTop: '.5em' }}>
            <RadioGroup
              row
              value={newEFRForm.resourceType}
              onChange={(e) => handleTypeChange(e)}
            >
              {Object.entries(efrSubTypesFormat).map(([key, value]) => {
                return (
                  <Tooltip
                    key={`efr-resource-type-${key}`}
                    arrow
                    placement='bottom-start'
                    title={newEFRForm && newEFRForm.artifactID ? 'Type cannot be changed once a resource has been created.' : ''}
                  >
                    <FormControlLabel
                      style={{ marginRight: '2em' }}
                      value={key}
                      defaultChecked=''
                      disabled={Boolean(newEFRForm && newEFRForm.artifactID)}
                      control={<Radio size='small' checked={newEFRForm.resourceType === key} />}
                      label={
                        <Typography variant='body1' style={{ fontWeight: '400', color: theme.palette.purple.darkest }}>{value}</Typography>
                      }
                    />
                  </Tooltip>
                )
              })}
            </RadioGroup>
          </Grid>
        </Grid>

        {newEFRForm && newEFRForm.resourceType && newEFRForm.resourceType === efrSubTypes.EFR_EVENT &&
          <>
            <Grid item container direction='column' style={{ marginBottom: '1em' }}>
              <Grid item>
                <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}><span style={{ color: 'red', fontSize: '14px' }}>*</span> Event Date</Typography>
              </Grid>
              <Grid item style={{ paddingRight: '.5em' }}>
                <DatePicker
                  value={displayEventDate}
                  onChange={date => handleDateChange(date)}
                />
              </Grid>
            </Grid>

            <Grid item container direction='column' style={{ marginBottom: '1em' }}>
              <Grid item>
                <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}><span style={{ color: 'red', fontSize: '14px' }}>*</span> Start Time</Typography>
              </Grid>
              <Grid item style={{ paddingRight: '.5em' }}>
                <TimePicker
                  clearable
                  value={!startTime ? null : startTime}
                  onChange={(e) => handleStartTimeChange(e)}
                />
              </Grid>
            </Grid>

            <Grid item container direction='column' style={{ marginBottom: '1em' }}>
              <Grid item>
                <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}><span style={{ color: 'red', fontSize: '14px' }}>*</span> End Time</Typography>
              </Grid>
              <Grid item style={{ paddingRight: '.5em' }}>
                <TimePicker
                  clearable
                  value={!endTime ? null : endTime}
                  onChange={(e) => handleEndTimeChange(e)}
                />
              </Grid>
            </Grid>
          </>}

        {/* Resource Link/RSVP and Featured Photo */}
        {newEFRForm && newEFRForm.resourceType && newEFRForm.resourceType !== '' &&
          <>
            <Grid item container direction='column' style={{ marginBottom: '1em' }}>
              <Grid item>
                <Typography variant='body1' style={{ fontWeight: '600' }}><span style={{ color: 'red', fontSize: '14px' }}>*</span>{newEFRForm.resourceType !== efrSubTypes.EFR_EVENT ? 'Link' : 'RSVP Link'}</Typography>
                {newEFRForm && newEFRForm.resourceType && newEFRForm.resourceType === efrSubTypes.EFR_EVENT &&
                  <Typography gutterBottom variant='caption' style={{ color: theme.palette.grey.dark }}>Upload the link that people will need to RSVP for the event. Examples: Eventbrite, Facebook, Meetup</Typography>}
              </Grid>
              <Grid item style={{ paddingRight: '.5em' }}>
                <OutlinedInput
                  variant='outlined'
                  fullWidth
                  inputProps={{
                    maxLength: 128
                  }}
                  margin='dense'
                  value={newEFRForm.resourceLink}
                  onChange={(e) => setNewEFRForm({ ...newEFRForm, resourceLink: e.target.value })}
                  classes={{ input: classes.inputPlaceholder }}
                />
              </Grid>
              <Grid item>
                <Typography variant='caption' style={{ color: '#adadad', paddingLeft: '.3em' }}>{`${newEFRForm && newEFRForm.resourceName && newEFRForm.resourceName.length ? newEFRForm.resourceName.length : 0}/ 128`}</Typography>
              </Grid>
            </Grid>

            {/* Featured Photo */}
            <Grid item xs={12}>
              <Grid item container direction='row' justifyContent='flex-start' style={{ marginTop: '.2rem' }}>
                <Typography variant='body1' style={{ fontWeight: '600' }}>Featured Photo</Typography>
              </Grid>

              <Grid item container direction='column' justifyContent='center' style={{ marginTop: '1em' }}>
                {newImage
                  ? (
                    <>
                      <Grid item container direction='row' justifyContent='center' style={{ minHeight: 'fit-content', height: 'fit-content' }}>
                        <img src={newEFRForm.bannerImagePath && newEFRForm.bannerImagePath !== '' ? newEFRForm.bannerImagePath : newImage} alt='feature-resource' style={{ maxWidth: '100%' }} />
                      </Grid>
                      <Grid item container direction='row' justifyContent='center' style={{ marginTop: '1em' }}>
                        <Button
                          variant='outlined'
                          color='primary'
                          size='small'
                          startIcon={<ReplayIcon />}
                          onClick={handleImageChange}
                        >
                          Clear Image
                        </Button>
                      </Grid>
                    </>
                  )
                  : (
                    // If no files have been chosen yet:
                    <div>
                      <Grid item container direction='row' justifyContent='center'>
                        <Grid
                          item
                          xs={12}
                          style={{
                            border: areaEntered ? '2px dashed #293669' : '2px dashed #adadad',
                            borderRadius: '5px',
                            width: '600',
                            color: 'black',
                            padding: '3rem'
                          }}
                        >
                          <FileDrop
                            onFrameDragEnter={(e) => handleUploadEnter()}
                            onFrameDragLeave={(e) => handleUploadLeave()}
                            onDrop={(files, e) => handleImageUpload(e, files)}
                          >
                            <Grid item container xs={12} justifyContent='center'>
                              <UploadPhoto />
                            </Grid>

                            <Grid item container xs={12} justifyContent='center' style={{ marginTop: '.3em' }}>
                              <Typography variant='h6' style={{ fontWeight: '600', textTransform: 'none' }}>Drag and Drop Here</Typography>
                            </Grid>

                            <Grid item xs={8} style={{ marginTop: '.5rem', marginBottom: '.5rem', marginLeft: 'auto', marginRight: 'auto' }}>
                              <Grid item className={classes.container}>
                                <Grid item className={classes.border} />
                                <Typography variant='h5' className={classes.text}>or</Typography>
                                <Grid item className={classes.border} />
                              </Grid>
                            </Grid>

                            <Grid item xs={12} style={{ marginTop: '.3em' }}>
                              <input
                                accept='image/*'
                                style={{ display: 'none' }}
                                id='raised-button-file'
                                multiple
                                type='file'
                                onChange={(e) => handleImageUpload(e, e.target.files)}
                              />
                              <label htmlFor='raised-button-file'>
                                <Grid item container direction='row' justifyContent='center'>
                                  <Button
                                    variant='outlined'
                                    style={{ borderColor: '#29356a', color: '#29356a', fontWeight: '700', padding: '7px 12px' }}
                                    component='span'
                                  >
                                    Browse Files
                                  </Button>
                                </Grid>
                                <Grid item container direction='row' justifyContent='center' style={{ marginTop: '.5rem' }}>
                                  <Typography variant='caption' style={{ color: 'grey', fontSize: '.8rem' }}>Max file size of 3MB.</Typography>
                                </Grid>
                              </label>
                            </Grid>
                            {fileSizeError &&
                              <Grid item container direction='row' justifyContent='center'>
                                <Typography variant='caption' style={{ color: 'red' }}>The selected file is over the max file size of 3MB.</Typography>
                              </Grid>}

                            {fileExtensionError &&
                              <Grid item container direction='row' justifyContent='center'>
                                <Typography variant='caption' style={{ color: 'red' }}>The selected file type is not allowed.</Typography>
                              </Grid>}
                          </FileDrop>
                        </Grid>
                      </Grid>
                    </div>
                  )}
              </Grid>
            </Grid>
          </>}

        {/* Publish Button (only appears while artifact is still a draft) */}
        <PublishButton
          styles={styles}
          theme={theme}
          classes={classes}
          isDraft={isDraft}
          artifactNotCompleteCheck={artifactNotCompleteCheck}
          setPublishModalOpen={setPublishModalOpen}
          handleSubmit={handleSubmit}
          artifactType={artifactType}
        />

        {resourceLinkInvalid &&
          <Grid item container direction='row' justifyContent='flex-end'>
            <Typography variant='caption' style={{ color: 'red' }}>* Provided Link is Invalid.</Typography>
          </Grid>}

      </Grid>
    </Paper>
  )
}

export default EFRDetails
