import React from 'react'

import {
  Grid, TextField, InputLabel, Button, Typography
} from '@material-ui/core'
import { loginForms } from '../../../../utils/variables'

const ForgotPassword = (props) => {
  const {
    resetEmail, setResetEmail, setCurrentForm, handlePasswordReset,
    errors, resetErrors, resetInputs, handleFormEnter
  } = props

  return (
    <>
      <Grid item container direction='row' alignItems='center' justifyContent='center' style={{ display: 'flex', marginTop: '18vh' }}>
        <Grid item xs={8}>
          <Grid item container direction='row' justifyContent='center'>
            <Typography variant='h2'>Reset Password</Typography>
          </Grid>
        </Grid>

        <Grid item xs={8} style={{ marginTop: '2rem' }}>
          <InputLabel>Please enter your email address</InputLabel>
          <TextField
            id='reset-email'
            variant='outlined'
            size='small'
            fullWidth
            type='email'
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
            onKeyDown={handleFormEnter}
          />
        </Grid>

        {errors.fieldsMissingError &&
          <Grid item xs={8} style={{ marginTop: '1rem', marginBottom: '-1rem' }}>
            <Typography variant='caption' style={{ color: 'red' }}>* Please be sure you have provided an email.</Typography>
          </Grid>}

        {errors.noEmailFoundError &&
          <Grid item xs={8} style={{ marginTop: '1rem', marginBottom: '-1rem' }}>
            <Typography variant='caption' style={{ color: 'red' }}>* No account found.</Typography>
          </Grid>}

        {errors.emailUnavailableError &&
          <Grid item xs={8} style={{ marginTop: '1rem', marginBottom: '-1rem' }}>
            <Typography variant='caption' style={{ color: 'red' }}>* Account unavailable.</Typography>
          </Grid>}

        {errors.sendError &&
          <Grid item xs={8} style={{ marginTop: '1rem', marginBottom: '-1rem' }}>
            <Typography variant='caption' style={{ color: 'red' }}>* Unable to send reset email.</Typography>
          </Grid>}

        <Grid item xs={8} style={{ marginTop: '2rem' }}>
          <Button
            fullWidth
            color='primary'
            variant='contained'
            onClick={(e) => {
              e.preventDefault()
              handlePasswordReset()
            }}
          >
            Email me a recovery link
          </Button>
        </Grid>

        <Grid item xs={8} style={{ marginTop: '2rem' }}>
          <Grid item container direction='row' justifyContent='center'>
            <Button
              color='primary'
              variant='text'
              style={{ textTransform: 'none', fontWeight: '600', color: '#29356a', fontSize: '16px' }}
              onClick={(e) => {
                e.preventDefault()
                setCurrentForm(loginForms.MAIN_LOGIN)
                resetErrors()
                resetInputs()
              }}
            >
              Return to Log In
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default ForgotPassword
