import React, { } from 'react'
import { SvgIcon } from '@material-ui/core'

const EditIcon = ({ fillColor = '#29356a' }) => {
  return (
    <SvgIcon viewBox='0 0 24 21.337' style={{ height: '100%', width: '100%' }}>
      <g>
        <path
          id='edit-regular_1_'
          data-name='edit-regular (1)'
          d='M16.763,14.3,18.1,12.966a.335.335,0,0,1,.571.238v6.058a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V4.595a2,2,0,0,1,2-2H13.4a.335.335,0,0,1,.238.571L12.3,4.5a.33.33,0,0,1-.238.1H2V19.262H16.667V14.533A.328.328,0,0,1,16.763,14.3Zm6.525-8.409L12.346,16.833l-3.767.417a1.721,1.721,0,0,1-1.9-1.9L7.1,11.583,18.038.641a2.432,2.432,0,0,1,3.446,0l1.8,1.8a2.441,2.441,0,0,1,0,3.45ZM19.171,7.178,16.75,4.757,9.009,12.5l-.3,2.721,2.721-.3Zm2.7-3.321-1.8-1.8a.435.435,0,0,0-.617,0L18.167,3.345l2.421,2.421,1.288-1.288A.444.444,0,0,0,21.871,3.857Z'
          transform='translate(0 0.075)'
          fill={fillColor}
        />
      </g>
    </SvgIcon>
  )
}

export default EditIcon
