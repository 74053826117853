import React from 'react'
import {
  Grid, Typography, Popover, Button
} from '@material-ui/core'

const DeleteUserPopover = (props) => {
  const {
    confirmAnchorEl, handleConfirmPopClose, openConfirmPopover, handleConfirmDeleteUser, userType, classes
  } = props

  return (
    <Popover
      id={openConfirmPopover ? 'confirm-popover' : undefined}
      open={openConfirmPopover}
      anchorEl={confirmAnchorEl}
      onClose={handleConfirmPopClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
    >
      <Grid container direction='column' style={{ padding: '1em' }}>
        <Typography
          variant='h4'
          style={{ color: '#e24646', marginBottom: '0.6em' }}
          gutterBottom
        >
          Delete User
        </Typography>

        <Typography variant='body1' style={{ fontWeight: 600 }} gutterBottom>Are you sure you want to delete this user account?</Typography>
        <Typography variant='body1' gutterBottom>This action cannot be undone - the user will no longer be able to log in and all data associated with this account will be permanently removed.</Typography>

        <Grid item container direction='row' justifyContent='flex-end' alignItems='center' style={{ marginTop: '.5em' }}>
          <Button onClick={handleConfirmPopClose} style={{ marginRight: '.3em', textTransform: 'none', fontWeight: '600' }}>Cancel</Button>
          <Button variant='contained' className={classes.deleteButton} onClick={(e) => { e.preventDefault(); handleConfirmPopClose(); handleConfirmDeleteUser(userType) }}>Continue</Button>
        </Grid>
      </Grid>
    </Popover>
  )
}

export default DeleteUserPopover
