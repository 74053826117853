import {
  GET_ADMIN_ARTIFACTS, LOGOUT, GET_ADMIN_COHORTS, GET_ADMIN_FELLOWS, GET_ADMIN_SIGNUPS,
  GET_ADMIN_CERTS, GET_ADMIN_SCHOOLS, GET_ADMIN_SUBJECTS, SET_REVIEW_ARTIFACT, GET_ADMIN_USER_LIST,
  GET_ADMIN_EF_RESOURCES, UPDATE_REVIEW_ARTIFACT, RESET_REVIEW_ARTIFACT, GET_REVIEW_ARTIFACT_STATUS,
  GET_FEATURED_LED_OPTIONS, GET_ADMIN_DISTRICTS, GET_ADMIN_ASSESSORS, GET_ADMIN_EVALUATIONS, GET_ADMIN_MCES
} from '../types'

const initialState = {
  adminArtifacts: {
    artifacts: [],
    totalSubmissions: null,
    totalToReview: 0
  },
  adminCohorts: {
    cohorts: [],
    totalCohorts: null
  },
  adminDistricts: {
    districts: [],
    districtCount: null
  },
  adminFellows: {
    teacherFellows: [],
    totalFellows: null
  },
  adminSignUps: {
    signUps: [],
    totalPending: null
  },
  adminSchools: {
    schoolCount: null,
    districtOptions: [],
    districtCount: null,
    schools: []
  },
  adminSubjects: {
    subjects: [],
    subjectCount: null
  },
  adminCertifications: {
    certifications: [],
    certCount: null
  },
  adminUserList: {
    adminUsers: [],
    totalAdmins: null
  },
  adminEfResources: {
    resources: [],
    resourceCount: null
  },
  adminFeaturedLEDs: {
    artifacts: [],
    artifactTotal: null
  },
  adminMCEAssessors: {
    assessors: [],
    totalAssessors: null
  },
  adminMCEEvaluations: {
    evaluations: [],
    totalEvaluations: null
  },
  adminMCEs: {
    microcredentials: [],
    totalMCEs: null
  },
  reviewArtifact: {}
}

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMIN_ARTIFACTS: return {
      ...state,
      adminArtifacts: action.payload
    }
    case GET_ADMIN_COHORTS: return {
      ...state,
      adminCohorts: action.payload
    }
    case GET_ADMIN_DISTRICTS: return {
      ...state,
      adminDistricts: action.payload
    }
    case GET_ADMIN_FELLOWS: return {
      ...state,
      adminFellows: action.payload
    }
    case GET_ADMIN_SIGNUPS: return {
      ...state,
      adminSignUps: action.payload
    }
    case GET_ADMIN_SCHOOLS: return {
      ...state,
      adminSchools: action.payload
    }
    case GET_ADMIN_SUBJECTS: return {
      ...state,
      adminSubjects: action.payload
    }
    case GET_ADMIN_CERTS: return {
      ...state,
      adminCertifications: action.payload
    }
    case SET_REVIEW_ARTIFACT: return {
      ...state,
      reviewArtifact: action.payload
    }
    case UPDATE_REVIEW_ARTIFACT: return {
      ...state,
      reviewArtifact: {
        ...state.reviewArtifact,
        status: action.payload
      }
    }
    case GET_REVIEW_ARTIFACT_STATUS: return {
      ...state,
      reviewArtifact: {
        ...state.reviewArtifact,
        status: action.payload.status,
        denialComment: action.payload.denialComment
      }
    }
    case RESET_REVIEW_ARTIFACT: return {
      ...state,
      reviewArtifact: {}
    }
    case GET_ADMIN_USER_LIST: return {
      ...state,
      adminUserList: action.payload
    }
    case GET_ADMIN_EF_RESOURCES: return {
      ...state,
      adminEfResources: action.payload
    }
    case GET_FEATURED_LED_OPTIONS: return {
      ...state,
      adminFeaturedLEDs: action.payload
    }
    case GET_ADMIN_ASSESSORS: return {
      ...state,
      adminMCEAssessors: action.payload
    }
    case GET_ADMIN_EVALUATIONS: return {
      ...state,
      adminMCEEvaluations: action.payload
    }
    case GET_ADMIN_MCES: return {
      ...state,
      adminMCEs: action.payload
    }
    case LOGOUT: return {
      ...initialState,
      error: action.payload.error || false
    }
    default: return state
  }
}

export default adminReducer
