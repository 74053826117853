import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Popover, Grid, Typography, List, ListItem, ListItemIcon, ListItemText, ListItemAvatar,
  Avatar, useTheme, Button, Collapse, Switch, FormControlLabel, withStyles
} from '@material-ui/core'
import {
  ExpandMore, ExpandLess, CheckCircle, FiberManualRecord, EditRounded, LoopRounded
} from '@material-ui/icons'
import moment from 'moment'
import { translatedActionTypes, mceActionTypes, defaultMCEDetails } from '../../../../utils/variables'
import { getMCEDetails } from '../../../../redux/actions'
import BuilderVersionDetailsModal from './BuilderVersionDetailsModal'

const singleActionTypes = [mceActionTypes.PUBLISHED, mceActionTypes.DEACTIVATED, mceActionTypes.RESTORED, mceActionTypes.REACTIVATED]
const originActions = [mceActionTypes.DEACTIVATED, mceActionTypes.REACTIVATED]
const versionActionTypes = Object.values(mceActionTypes).filter(x => !originActions.includes(x))

const FilterSwitch = withStyles((theme) => ({
  root: {
    width: 32,
    height: 18,
    padding: '.1em',
    margin: theme.spacing(1)
  },
  switchBaseStyle: {
    padding: 3,
    color: theme.palette.grey.medium,
    '&$checked': {
      transform: 'translateX(12px)',
      color: 'white',
      '& + $track': {
        backgroundColor: theme.palette.purple.darkest,
        borderColor: theme.palette.grey.medium
      }
    },
    '&$focusVisible $thumb': {
      color: 'white !important'
    },
    '& + $track': {
      backgroundColor: theme.palette.grey.light
    }
  },
  thumbStyle: {
    width: 14,
    height: 14,
    color: 'white !important'
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey.medium}`,
    opacity: '1 !important',
    transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {},
  disabled: {},
  thumb: {},
  focusVisible: {}
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBaseStyle,
        thumb: classes.thumbStyle,
        track: classes.track,
        checked: classes.checked,
        disabled: classes.disabled
      }}
      {...props}
    />
  )
})

const BuilderVersionHistoryList = (props) => {
  const {
    classes = {}, versionHistory = [], openChangeLogPopover, changeLogAnchorEl, setChangeLogAnchorEl = () => { },
    handleOriginStatus = () => { }
  } = props
  const theme = useTheme()
  const dispatch = useDispatch()

  const [openHistoryIndex, setOpenHistoryIndex] = useState(null)
  const [localFullDetails, setLocalFullDetails] = useState({ ...defaultMCEDetails })
  const [localActionData, setLocalActionData] = useState({ fullName: '', profileAvatarPath: '', actionDate: 0 })
  const [detailModalOpen, setDetailModalOpen] = useState(false)
  const [viewEditsOnly, setViewEditsOnly] = useState(false)

  // Display either the full history or just the version publications, based on the status of the filter toggle
  const displayHistoryArr = !viewEditsOnly ? versionHistory : versionHistory.filter(x => x.versionActions.filter(y => singleActionTypes.includes(y.actionType)).length === 0 || x.versionActions.findIndex(z => z.actionType === mceActionTypes.PUBLISHED) !== -1)

  // expand the menu items (when they meet the conditions of exapnsion)
  const handleExpandHistory = (enableExpansion, index) => {
    if (!enableExpansion) {
      return false
    }

    // determine the index within the history that is being opened, and if not already open, set it as the local state
    let newIndex = null
    if ((index || index === 0) && openHistoryIndex !== index) {
      newIndex = index
    }
    setOpenHistoryIndex(newIndex)
  }

  // translate the action in the history log into a more user friendly text
  const translateActionType = (actionObj, versionNum) => {
    const { actionType, section = null } = actionObj
    const translation = translatedActionTypes[actionType] || 'Unknown Action'
    const subAction = section ? `in Part ${section}` : actionType === mceActionTypes.RESTORED || actionType === mceActionTypes.PUBLISHED || actionType === mceActionTypes.REACTIVATED ? `v${versionNum}` : ''

    return `${translation} ${subAction}`
  }

  // reset the local state on close
  const handleChangeLogPopClose = () => {
    setOpenHistoryIndex(null)
    setChangeLogAnchorEl(null)
  }

  // set the details returned from the redux action to the local state and open the details modal
  const fireFetchSuccess = (data) => {
    setLocalFullDetails(data)
    setDetailModalOpen(true)
  }

  // fetch the full details of a selected version
  const handleFetchFullDetails = (mceID, actionData) => {
    // pull the details of the selected mceID without updating redux
    if (mceID) {
      dispatch(getMCEDetails({ mceID, includeHistoryBool: false, noDispatchBool: true }, fireFetchSuccess))
    }

    // set the action specific data (not returned in redux data) to local state
    if (actionData) {
      setLocalActionData({ ...actionData })
    }
  }

  // close/reset the full details modal
  const handleCloseDetailModal = () => {
    setDetailModalOpen(false)
    setLocalFullDetails({ ...defaultMCEDetails })
    setLocalActionData({ fullName: '', profileAvatarPath: '', actionDate: 0 })
  }

  // determine via several conditions which menu item should display the 'active version' icon
  const findActiveVersionIndex = () => {
    let index = 0

    if (versionHistory && versionHistory.length) {
      // find all history records pertaining to the active version
      const activeVersionRecords = versionHistory?.filter(x => x?.isActiveVersion && x?.versionActions.findIndex(y => y?.actionType === mceActionTypes.DEACTIVATED) === -1)
      // find the most recent obj within that sub array
      const mostRecentDateObj = activeVersionRecords?.length ? activeVersionRecords.reduce((prev, current) => prev?.actionDate > current?.actionDate ? prev : current) : []
      // reduce that object's version actions to an array of historyIDs
      const historyIDs = Object.keys(mostRecentDateObj).length ? mostRecentDateObj?.versionActions.map(x => x?.historyID) : []
      // find the index within the full version history array that has those historyIDs
      const mostRecentIndex = versionHistory?.findIndex(x => x?.versionActions.findIndex(y => historyIDs?.includes(y?.historyID)) !== -1)

      if (mostRecentIndex) {
        index = mostRecentIndex
      }
    }

    return index
  }

  return (
    <>
      <Popover
        id={openChangeLogPopover ? 'changelog-popover' : undefined}
        open={openChangeLogPopover}
        anchorEl={changeLogAnchorEl}
        onClose={handleChangeLogPopClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Grid container direction='column' style={{ padding: '1em' }}>
          {!versionHistory?.length &&
            <Typography style={{ fontWeight: 600, color: theme.palette.grey.medium }}>No Change History Found...</Typography>}
          {Boolean(versionHistory?.length) &&
            <List className={classes.listRoot}>
              <ListItem className={classes.listSection}>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant='subtitle1' style={{ fontWeight: 600, color: theme.palette.purple.darkest }}>Change Log History</Typography>
                  }
                />
                <ListItemIcon>
                  <FormControlLabel
                    control={
                      <FilterSwitch checked={viewEditsOnly} onChange={() => setViewEditsOnly(!viewEditsOnly)} />
                    }
                    label='Versions Only'
                    labelPlacement='start'
                  />
                </ListItemIcon>
              </ListItem>
              {displayHistoryArr.map((version, i) => {
                const { mceID, isActiveVersion = 0, actionDate, versionNum, versionActions = [], actionUser: { fullName = '', profileAvatarPath = '', deletedAt = 0 } } = version

                const formattedActionDate = moment(actionDate * 1000).format('MMMM Do YYYY, h:mm a')
                const mostRecentActiveIndex = findActiveVersionIndex()
                const trueActiveVersion = viewEditsOnly ? Boolean(isActiveVersion) : Boolean(i === mostRecentActiveIndex)
                const expanded = Boolean((openHistoryIndex || openHistoryIndex === 0) && openHistoryIndex === i)
                const isSingularAction = Boolean(versionActions.filter(x => singleActionTypes.includes(x.actionType)).length)
                const isVersionAction = Boolean(versionActions.filter(x => versionActionTypes.includes(x.actionType)).length)
                const singleActionObj = isSingularAction ? versionActions[0] : null
                const icon = isVersionAction ? <EditRounded className={classes.listIcon} /> : <LoopRounded className={classes.listIcon} />
                const primaryText = !isSingularAction ? `Published v${versionNum}` : translateActionType(singleActionObj, versionNum)
                const enableExpansion = Boolean(!isSingularAction || (versionActions.findIndex(x => x.actionType === mceActionTypes.PUBLISHED) !== -1 && versionNum === 1))

                return (
                  <div key={`version-${i}-${mceID}`}>
                    <ListItem button disableRipple={!enableExpansion} style={{ cursor: !enableExpansion ? 'default' : 'pointer', padding: 'auto 0' }} onClick={() => { handleExpandHistory(enableExpansion, i) }}>
                      <ListItemAvatar style={{ minWidth: 48 }}>
                        <Avatar style={{ height: 30, width: 30, backgroundColor: theme.palette.purple.darkest }}>{icon}</Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        disableTypography
                        primary={
                          <Grid container direction='row'>
                            <Typography variant='body1' style={{ fontWeight: 600, fontSize: 16, lineHeight: 1 }}>{primaryText}</Typography>
                            {Boolean(trueActiveVersion) && <CheckCircle style={{ fontSize: 14, color: theme.palette.pink.dark, marginLeft: '5px' }} />}
                          </Grid>
                        }
                        secondary={<Typography variant='body1'>{formattedActionDate}</Typography>}
                      />
                      {enableExpansion &&
                        <ListItemIcon style={{ justifyContent: 'center' }}>
                          {expanded ? <ExpandLess /> : <ExpandMore />}
                        </ListItemIcon>}
                    </ListItem>
                    <Collapse in={expanded} timeout='auto' unmountOnExit classes={{ root: classes.listCollapse }}>
                      <List component='div' disablePadding>
                        {Boolean(versionNum !== 1) && versionActions.map((action) => {
                          const { historyID } = action

                          return (
                            <ListItem key={`history-${historyID}`} style={{ marginLeft: theme.spacing(4), padding: 0 }}>
                              <ListItemIcon style={{ minWidth: 20 }}>
                                <FiberManualRecord style={{ fontSize: 10, color: theme.palette.pink.dark }} />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography variant='caption' style={{ fontWeight: 600, color: theme.palette.grey.dark }}>{translateActionType(action)}</Typography>
                                }
                              />
                            </ListItem>
                          )
                        })}
                        <ListItem style={{ justifyContent: 'flex-end' }}>
                          <ListItemIcon>
                            <Button
                              style={{ textTransform: 'none', fontWeight: 600, fontSize: 12, color: theme.palette.purple.darkest }}
                              onClick={() => { handleFetchFullDetails(mceID, { fullName, profileAvatarPath, actionDate, deletedAt }) }}
                            >
                              View full version details...
                            </Button>
                          </ListItemIcon>
                        </ListItem>
                      </List>
                    </Collapse>
                  </div>
                )
              })}
            </List>}
        </Grid>
      </Popover>

      <BuilderVersionDetailsModal
        detailModalOpen={detailModalOpen}
        handleCloseDetailModal={handleCloseDetailModal}
        localFullDetails={localFullDetails}
        localActionData={localActionData}
        classes={classes}
        handleOriginStatus={handleOriginStatus}
        handleChangeLogPopClose={handleChangeLogPopClose}
      />
    </>
  )
}

export default BuilderVersionHistoryList
