import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
  cardBack: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '6px'
  }
}))

// NOTE: renders a loading skeleton in the resource section of the search results popper
//  --- Parent Component: ui/Search/SearchPopper.js
const SearchResourceLoadSkel = () => {
  const classes = useStyles()

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item style={{ paddingRight: '.2em' }}>
        <Skeleton variant='rect' height='5em' width='100%' animation='wave' className={classes.cardBack} />
      </Grid>
      <Grid item style={{ paddingRight: '.2em' }}>
        <Skeleton variant='rect' height='5em' width='100%' animation='wave' className={classes.cardBack} />
      </Grid>
      <Grid item style={{ paddingRight: '.2em' }}>
        <Skeleton variant='rect' height='5em' width='100%' animation='wave' className={classes.cardBack} />
      </Grid>
    </Grid>
  )
}

export default SearchResourceLoadSkel
