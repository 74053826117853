import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, withRouter } from 'react-router-dom'

import {
  Grid, Card, CardHeader, CardContent, Typography, Avatar, makeStyles, useTheme
} from '@material-ui/core'

import { EmailRounded, SchoolRounded, LocalLibraryRounded } from '@material-ui/icons'

import EFLogo from '../../../assets/edFarmLogo.png'
import { userRoles } from '../../../utils'
import GradesCheck from './GradesCheck'

import { getConversationID, setActiveConversationUsers, setConversations, getConversations } from '../../../redux/actions'

import moment from 'moment'

const useStyles = makeStyles(theme => ({
  userCardHeaderRoot: {
    backgroundColor: theme.palette.purple.darkest
  },
  userCardAvatarArea: {
    display: 'contents'
  },
  userAvatarStyle: {
    height: 60,
    width: 60,
    border: '2px solid white',
    borderRadius: '50%'
  },
  logoStyle: {
    height: '22px',
    width: '22px',
    backgroundImage: `url(${EFLogo})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },
  headerActionArea: {
    marginRight: 0,
    marginTop: '-4px'
  },
  userCardIcons: {
    color: theme.palette.purple.darkest,
    backgroundColor: '#ADB8DC'
  }
}))

const templateUser = {
  userID: '',
  profileID: '',
  roleID: '',
  roleName: 'N/A',
  profileAvatarKey: '',
  fullName: '',
  nickName: '',
  yearsTeaching: 0,
  schoolID: '',
  schoolName: 'N/A',
  schoolDistrictID: '',
  districtName: 'N/A',
  cohortID: null,
  cohortName: null,
  inFellowTypeID: null,
  inFellowTypeName: null,
  createdAt: 0,
  lastLoginDate: null,
  certification: [],
  grade: [],
  skill: [],
  subject: [],
  tool: [],
  biography: 'This user has not written a biography yet.',
  profileAvatarPath: ''
}

const ExploreUserCard = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()

  const { userInfo = templateUser } = props

  const { conversations = [] } = useSelector(state => state.websocket)

  const getUserRoleOrSchool = () => {
    let nameToReturn = 'N/A'
    if (userIsInFellow && userInfo.inFellowTypeName) {
      nameToReturn = userInfo.inFellowTypeName
    }

    if (!userIsInFellow && userInfo.schoolName) {
      nameToReturn = userInfo.schoolName
    }

    return nameToReturn
  }

  const history = useHistory()

  const handleUserCardClick = async () => {
    history.push({
      pathname: '/profile',
      search: `?user=${userInfo.profileID}`
    })
  }

  // Maps through array of objects that contain each returned user's grades and creates an array of the grade names
  const gradesArray = userInfo && userInfo.grade && Boolean(userInfo.grade.length) ? userInfo.grade.map(grade => grade.gradeName) : []

  const formattedBio = (text) => {
    let bio = ''

    if (text && text.length) {
      bio = text

      if (text.length > 150) {
        bio = `${text.substring(0, 150)}...`
      }
    }

    return bio
  }

  // Take user to messages and open conversation with the selected user
  const handleUserMessage = async (e, userObject) => {
    e.preventDefault()
    e.stopPropagation()

    if (userObject && userObject.userID) {
      // get conversationID for the selected user to pull up conversation
      const conversationID = await new Promise((resolve, reject) => {
        dispatch(getConversationID([userObject.userID], (payload) => resolve(payload), () => reject(new Error('failed to acquire conversation id'))))
      }).catch(err => console.error(err))

      if (conversationID) {
        let conversationList = []

        // if conversations are not set in redux already, grab them and set conversationList to results, otherwise set to conversations from redux
        if (conversations && conversations.length === 0) {
          const newConversations = await dispatch(getConversations())

          conversationList = newConversations
        } else {
          conversationList = conversations
        }

        // if the conversationList is correctly set, run checks
        if (conversationList) {
          // check conversations array to see if the conversation already exists there
          const conversationCheck = conversations.some(conversation => conversation.conversationID === conversationID)

          // if the conversation is not in the array, copy and add to the begnining of the array
          if (!conversationCheck) {
            const now = moment().unix()

            // build temporary object for conversation list
            // mostRecentMessageContent changed from empty string
            const newConversation = {
              conversationID,
              userListDetails: [userObject],
              mostRecentMessageID: '',
              mostRecentCreatedAt: now,
              mostRecentIsDraft: 0,
              mostRecentMessageContent: JSON.stringify([{ children: [{ text: '' }] }]),
              convoHasDraftFromUser: false,
              unreadMessages: false,
              hiddenAt: false,
              blocked: false
            }

            // copy conversation array and push new conversation to first index
            const tempConversations = [...conversationList]

            tempConversations.unshift(newConversation)

            // set new conversation array to redux for conversation list
            dispatch(setConversations(tempConversations))
          }
        }

        // update active conversation users array with selected user object
        dispatch(setActiveConversationUsers([userObject]))

        // take user to message view with conversationID set to selected
        props.history.push(`/messages?view=message&conversationID=${conversationID}`)
      } else {
        // take user to default message view if something goes wrong when getting conversationID
        props.history.push({
          pathname: '/messages'
        })
      }
    } else {
      // take user to default message view if something goes wrong when with user object provided
      props.history.push({
        pathname: '/messages'
      })
    }
  }

  const userIsInFellow = userInfo && userInfo.roleName === userRoles.INNOVATION_FELLOW

  return (
    <Card elevation={2} style={{ width: '100%', height: 'inherit', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', position: 'relative' }}>
      <div style={{ cursor: 'pointer' }} onClick={() => handleUserCardClick()}>
        <CardHeader
          avatar={
            // The left margin on this grid is determined by font size of the action icon, plus the right margin of the action section, plus and the right padding of the card header area
            <Grid container direction='column' alignItems='center' style={{ marginLeft: '44px' }}>
              {/* User Avatar */}
              <Grid container item direction='row' justifyContent='center' style={{ marginBottom: 5 }}>
                <Avatar className={classes.userAvatarStyle} src={userInfo.profileAvatarPath} />
              </Grid>
              {/* User Full Name */}
              <Grid container direction='row' justifyContent='center'>
                <Typography variant='h3' align='center' style={{ color: 'white', fontSize: 28 }}>{userInfo.fullName}</Typography>
              </Grid>
            </Grid>
          }
          action={
            <EmailRounded style={{ color: 'white', fontSize: 28, cursor: 'pointer' }} onClick={(e) => { handleUserMessage(e, userInfo) }} />
          }
          classes={{ avatar: classes.userCardAvatarArea, root: classes.userCardHeaderRoot, action: classes.headerActionArea }}
        />

        <Grid container direction='row' justifyContent='center' style={{ backgroundColor: '#1D2A5C', padding: '.2em 0', alignItems: 'center' }}>
          <div className={classes.logoStyle} />
          {/* User Role (Admins display as Teacher Fellow by Design) */}
          <Typography
            variant='body1'
            style={{ color: 'white', fontWeight: '400', textTransform: 'capitalize', fontSize: '12px', letterSpacing: '0em' }}
          >
            {userInfo.roleName ? !userIsInFellow ? userRoles.TEACHER_FELLOW : userRoles.INNOVATION_FELLOW : ''}
          </Typography>
        </Grid>
        <CardContent>
          {/* Card Body Container */}
          <Grid item xs={12} container direction='row' justifyContent='center' style={{ paddingBottom: '1em' }}>

            {/* Left Details */}
            <Grid item xs={8} container direction='column'>

              {/* Schools/District/inFellowType */}
              <Grid item container direction={userIsInFellow ? 'column-reverse' : 'column'} spacing={1}>
                {/* School/inFellowType */}
                <Grid item container direction='column'>
                  <Typography variant='h5' style={{ fontWeight: 600 }}>{userIsInFellow ? 'Role:' : 'School:'}</Typography>
                  <Typography variant='h5' style={{ fontWeight: 400 }}>{getUserRoleOrSchool()}</Typography>
                </Grid>
                {/* District */}
                <Grid item container direction='column'>
                  <Typography variant='h5' style={{ fontWeight: 600 }}>District:</Typography>
                  <Typography variant='h5' style={{ fontWeight: 400 }}>{userInfo.districtName ? userInfo.districtName : 'N/A'}</Typography>
                </Grid>
              </Grid>

              {/* User Summary */}
              <Grid item container direction='column' style={{ marginTop: '1em' }}>
                <Typography style={{ color: userInfo.biography ? theme.palette.grey.dark : theme.palette.grey.medium }}>
                  {userInfo.biography ? formattedBio(userInfo.biography) : 'This user has not written a biography yet.'}
                </Typography>
              </Grid>

            </Grid>

            {/* Right Details */}
            <Grid
              item
              xs={4}
              container
              direction={!userIsInFellow ? 'column' : 'column-reverse'}
              justifyContent='space-around'
            >
              {/* Grades Taught Bubble */}
              <Grid item container direction='column' alignItems='center' style={{ paddingBottom: '.7em', visibility: userIsInFellow ? 'hidden' : 'visibile' }}>
                <Avatar className={classes.userCardIcons}>
                  <LocalLibraryRounded />
                </Avatar>
                <Typography variant='h5' style={{ fontWeight: 600, paddingTop: '.2em', color: theme.palette.grey.dark }}>{GradesCheck(userInfo.grade, gradesArray)}</Typography>
              </Grid>
              {/* Years Teachin Bubble */}
              <Grid item container direction='column' alignItems='center'>
                <Avatar className={classes.userCardIcons}>
                  <SchoolRounded />
                </Avatar>
                <Typography variant='h5' style={{ fontWeight: 600, paddingTop: '.2em', color: theme.palette.grey.dark }}>{userInfo.yearsTeaching ? `${userInfo.yearsTeaching} yrs` : '1 yr'}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </div>
    </Card>
  )
}

export default withRouter(ExploreUserCard)
