import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import {
  Grid, Typography, useTheme, OutlinedInput, Table, TableBody, TableContainer, TableCell, TableHead, TableRow,
  Paper, Select, MenuItem, TableSortLabel, Button, Tooltip
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import { SearchRounded, ExpandMoreRounded } from '@material-ui/icons'

import { isEqual } from 'lodash'

import { getArtifactDetails, setReviewArtifact } from '../../../redux/actions'
import { getArtifactType } from '../../../lib'
import { statusTypes, statusLabels } from '../../../utils'

const defaultReviewFilter = {
  status: '',
  cohortID: '',
  districtID: '',
  schoolID: ''
}

const AdminArtifacts = (props) => {
  const { classes, artifactSubmissions, totalArtifacts, filter, setFilter, defaultFilter, getArtifacts, activeButton } = props
  const theme = useTheme()
  const dispatch = useDispatch()

  const {
    adminSchools: { schools: Schools = [], districtOptions: Districts = [] } = {},
    adminCohorts: { cohorts: cohortList = [] } = {}
  } = useSelector(state => state.admin)

  // ********************** Data Creation ************************** //

  // If the filter was changed, fetch the artifacts with the new filter
  useEffect(() => {
    if (filter && defaultFilter && activeButton === 'artifacts') {
      if (!isEqual(filter, defaultFilter)) {
        dispatch(getArtifacts(filter))
      }
    }
  }, [dispatch, filter, defaultFilter, getArtifacts, setFilter, activeButton])

  const createData = (userID, profileID, profileAvatar, name, artifactID, artifactName, submitDate, cohort, district, school, status, statusUpdated, denialComment) => {
    return { userID, profileID, profileAvatar, name, artifactID, artifactName, submitDate, cohort, district, school, status, statusUpdated, denialComment }
  }

  // Data rows for the displayed table
  const [rows, setRows] = useState([])

  // Take the artifactSubmissions array and format it into the data rows
  useEffect(() => {
    if (artifactSubmissions) {
      if (artifactSubmissions.length) {
        const newRows = []
        artifactSubmissions.forEach(artifact => {
          const { userID, profileID, profileAvatarPath, name, artifactType, artifactName, dateSubmitted, cohortName, districtName, schoolName, status, statusUpdated, denialComment } = artifact
          const formatSubmitDate = moment(dateSubmitted * 1000).format('L')
          newRows.push(createData(userID, profileID, profileAvatarPath, name, artifactType, artifactName, formatSubmitDate, cohortName, districtName, schoolName, status, statusUpdated, denialComment))
        })
        setRows(newRows)
      } else {
        setRows([])
      }
    }
  }, [artifactSubmissions])

  // Column Headers
  const headCells = [
    { id: 'name', label: 'Name' },
    { id: 'artifactName', label: 'Artifact' },
    { id: 'dateSubmitted', label: 'Date Submitted' },
    { id: 'cohortName', label: 'Cohort' },
    { id: 'districtName', label: 'District' },
    { id: 'schoolName', label: 'School' },
    { id: 'status', label: 'Status' }
  ]

  const getFormatStatus = (dbStatus) => {
    if (dbStatus === null || dbStatus === 'NEEDS_REVIEW') {
      return 'Needs Review'
    } else if (dbStatus === 'NEEDS_CHANGES') {
      return 'Changes Requested'
    } else {
      return `${dbStatus.charAt(0)}${dbStatus.slice(1).toLowerCase()}`
    }
  }

  // ********************* Pagination Logic: ********************** //
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const handleChangePage = (event, value) => {
    if (value >= 0) {
      setPage(value - 1)
      setFilter({
        ...filter,
        sortCount: rowsPerPage,
        page: value
      })
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: event.target.value
    })
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalArtifacts - page * rowsPerPage)

  // ********************** Filter Logic: ************************** //

  // Groups a full list of all available schools, regardless of districts
  const schoolsArray = Schools && Schools.length && Districts && Districts.length
    ? Schools.reduce((arr, district) => { if (district && district.schools) { arr.push(...district.schools) } return arr }, []) : []

  // Options for the school select (begins with full list but reduces by district when a specific district ID is chosen)
  const [schoolOptions, setSchoolOptions] = useState(schoolsArray)

  // Filter state Items
  const [districtsValue, setDistrictsValue] = useState('')
  const [schoolsValue, setSchoolsValue] = useState([])
  const [cohortValue, setCohortValue] = useState('')
  const [statusValue, setStatusValue] = useState('')

  /* Handle the filters being selected.
      setValue: the state property to be changed
      key: the reviewFilter object key to be changed
      value: the name of the filter selection (used for display)
      id: the id of the chosen selection (used in sending to the api)
  */
  const handleFilterChange = (setValue, key, value, id = null) => {
    // Isolate the review filter, default to default object
    const { reviewFilter = { ...defaultReviewFilter } } = filter

    // Use the provided keys to distinguish which filter keu to update.
    // When filtering by district, also reset the schoolID in the filter
    setFilter({
      ...filter,
      reviewFilter: { ...reviewFilter, [key]: id, ...(key === 'districtID' ? { schoolID: '' } : {}) },
      page: 1,
      sortCount: rowsPerPage
    })
    // reset local pagination
    setPage(0)
    // set the local state to the selected filter's display value
    setValue(value)

    // If choosing a district filter, run additional functionality to adjust the school logic
    if (key === 'districtID') {
      // Reset the local state for school
      setSchoolsValue('')

      // If requesting a specific district (vs reseting to the 'any' option)
      if (id) {
        // Find the selected schools object for the selected districtID
        const districtsSchoolsIndex = Schools.findIndex(x => x.schoolDistrictID === value.schoolDistrictID)

        // If it exists, set the local selectatble school options to that districts specific schools list
        if (districtsSchoolsIndex !== -1) {
          const districtsSchools = Schools[districtsSchoolsIndex].schools

          // Sets the district to the selected option, and repopulates the school options to the new district
          setSchoolOptions(districtsSchools)
        } else {
          // Clear school options if the district object is not found
          setSchoolOptions([])
        }
      } else {
        // Otherise, if choosing the 'any' option, return the local selectable schools to the full list
        setSchoolOptions(schoolsArray)
      }
    }
  }

  // Reset all filters and local state objects related to filtering
  const handleClearFilter = () => {
    setFilter({
      ...filter,
      searchPhrase: '',
      reviewFilter: { ...defaultReviewFilter }
    })
    setCohortValue('')
    setSchoolsValue('')
    setSchoolOptions(schoolsArray)
    setDistrictsValue('')
    setStatusValue('')
    setSearchInput('')
  }

  // **************************** Column Sort Logic ************************ //

  const [orderBy, setOrderBy] = useState('')
  const [order, setOrder] = useState('desc')

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: rowsPerPage,
      sortDirection: isAsc ? 'DESC' : 'ASC',
      sortType: property
    })
  }

  // ********************** Search Logic *********************** //
  const [searchInput, setSearchInput] = useState('')

  const handleSearch = (e) => {
    setSearchInput(e.target.value)
    if (searchInput && searchInput.length >= 3) {
      // Previously, issues were occuring if the user was on a page that was greater than 1 and made a search. The page is now being reset beforehand to avoid that.
      setPage(0)
      setFilter({
        ...filter,
        page: 1,
        sortCount: rowsPerPage,
        searchPhrase: searchInput
      })
    }

    if (e.target.value === '') {
      setPage(0)
      // Set the sortCount to current rowsPerPage instead of the default
      setFilter({
        ...defaultFilter,
        sortCount: rowsPerPage
      })
    }
  }

  // ********************* Artifact Review Logic **************** //

  const handleReviewArtifact = (row) => {
    const { artifactID } = row
    const { type: reviewType = '' } = getArtifactType(artifactID)

    if (reviewType) {
      // NOTE: artifact cannot be private, or this will come back as an empty array
      dispatch(getArtifactDetails(artifactID, '', reviewType, () => {}, () => {}, ''))
      dispatch(setReviewArtifact(false, row))

      props.history.push({
        pathname: '/artifact',
        search: `?review=${reviewType}`
      })
    }
  }

  // Common props used in filter's select components
  const commonFilterSelectProps = {
    displayEmpty: true,
    IconComponent: ExpandMoreRounded,
    classes: { root: classes.filterSelect, icon: classes.selectIcon },
    MenuProps: {
      variant: 'menu',
      style: { maxWidth: '40%' }
    },
    inputProps: { className: classes.reviewSelectInput },
    disableUnderline: true,
    defaultValue: '',
    style: { width: '100%' }
  }

  return (
    <Grid container direction='column'>
      <Grid item container style={{ marginBottom: '2em' }}>
        {/* Page Title */}
        <Typography variant='h4' style={{ fontSize: '20px' }}>Artifacts</Typography>
      </Grid>

      {/* Top Pagination display and search input */}
      <Grid item container direction='row' style={{ marginTop: '1em' }}>
        <Grid item container alignContent='flex-end' xs={8}>
          <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none', fontWeight: 400, fontSize: '16px' }}>
            Displaying {
              totalArtifacts === 0
                ? 0
                : (page * rowsPerPage) + 1
            } to {
              rows && page * rowsPerPage + rowsPerPage > (totalArtifacts - 1)
                ? (totalArtifacts) : page * rowsPerPage + rowsPerPage
            } of {
              rows && page * rowsPerPage + rowsPerPage > (totalArtifacts - 1)
                ? (totalArtifacts) : (totalArtifacts - 1)
            }
          </Typography>
        </Grid>
        {/* Search Filter */}
        <Grid item container justifyContent='flex-end' alignItems='center' xs={4}>
          <OutlinedInput
            className={classes.searchInput}
            style={{ height: 'fit-content' }}
            size='small'
            margin='dense'
            fullWidth
            inputProps={{ style: { border: 'none', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '5px' } }}
            placeholder='Search...'
            classes={{ input: classes.inputPlaceholder }}
            startAdornment={
              <SearchRounded style={{ color: theme.palette.grey.dark, fontSize: '20px' }} />
            }
            value={searchInput}
            onChange={(e) => handleSearch(e)}
          />
        </Grid>
      </Grid>

      {/* Filter Row */}
      <Grid item container direction='row' style={{ margin: '1em 0' }}>
        <Grid item xs={8} container direction='row' alignItems='center' justifyContent='flex-start'>
          {/* Status */}
          <Grid item container direction='row' alignItems='baseline' style={{ paddingRight: '1em', maxWidth: '100% !important' }} xs={3}>
            <Typography variant='caption' className={classes.filterLabel}>Status:</Typography>
            <Select
              value={statusValue}
              onChange={(e, child) => handleFilterChange(setStatusValue, 'status', e.target.value, child.props.id)}
              {...commonFilterSelectProps}
            >
              <MenuItem value='' id=''>Any</MenuItem>
              {Object.keys(statusTypes).map(status => {
                return (
                  <MenuItem key={`status-${statusTypes[status]}`} id={statusTypes[status]} value={statusTypes[status]}>
                    <Typography variant='inherit' noWrap>{statusLabels[statusTypes[status]]}</Typography>
                  </MenuItem>
                )
              })}
            </Select>
          </Grid>
          {/* District */}
          <Grid item container direction='row' alignItems='baseline' style={{ paddingRight: '1em', maxWidth: '100% !important' }} xs={3}>
            <Typography variant='caption' className={classes.filterLabel}>District:</Typography>
            <Select
              value={districtsValue}
              onChange={(e, child) => handleFilterChange(setDistrictsValue, 'districtID', e.target.value, child.props.id)}
              {...commonFilterSelectProps}
            >
              <MenuItem value='' id=''>Any</MenuItem>
              {Districts &&
                Districts.map(district => {
                  const { schoolDistrictID, districtName } = district
                  return (
                    <MenuItem key={`district ${schoolDistrictID}`} id={schoolDistrictID} value={district}>
                      <Typography variant='inherit' noWrap>{districtName}</Typography>
                    </MenuItem>
                  )
                })}
            </Select>
          </Grid>
          {/* Schools */}
          <Grid item container direction='row' alignItems='baseline' style={{ paddingRight: '1em', maxWidth: '100% !important' }} xs={3}>
            <Typography variant='caption' className={classes.filterLabel}>School:</Typography>
            <Select
              value={schoolsValue}
              onChange={(e, child) => handleFilterChange(setSchoolsValue, 'schoolID', e.target.value, child.props.id)}
              disabled={schoolOptions && schoolOptions.length === 0}
              {...commonFilterSelectProps}
            >
              <MenuItem value='' id=''>Any</MenuItem>
              {schoolOptions &&
                schoolOptions.map(school => {
                  const { schoolID, schoolName } = school
                  return (
                    <MenuItem key={`school ${schoolID}`} id={schoolID} value={school}>
                      <Typography variant='inherit' noWrap>{schoolName}</Typography>
                    </MenuItem>
                  )
                })}
            </Select>
          </Grid>
          {/* Cohort */}
          <Grid item container direction='row' alignItems='baseline' style={{ paddingRight: '1em', maxWidth: '100% !important' }} xs={3}>
            <Typography variant='caption' className={classes.filterLabel}>Cohort:</Typography>
            <Select
              value={cohortValue}
              onChange={(e, child) => handleFilterChange(setCohortValue, 'cohortID', e.target.value, child.props.id)}
              {...commonFilterSelectProps}
            >
              <MenuItem value='' id=''>Any</MenuItem>
              {cohortList &&
                cohortList.map(cohort => {
                  const { cohortID, cohortName } = cohort
                  return (
                    <MenuItem key={`cohort ${cohortID}`} id={cohortID} value={cohort}>
                      <Typography variant='inherit' noWrap>{cohortName}</Typography>
                    </MenuItem>
                  )
                })}
            </Select>
          </Grid>
        </Grid>
        {/* Reset Filters Button */}
        <Grid item container xs={4} direction='row' justifyContent='flex-end' alignItems='flex-end'>
          <Button
            variant='text'
            style={{ textTransform: 'none', fontWeight: 600, color: isEqual(filter?.reviewFilter, defaultReviewFilter) ? theme.palette.grey.light : theme.palette.grey.dark }}
            disabled={isEqual(filter?.reviewFilter, defaultReviewFilter)}
            onClick={handleClearFilter}
          >
            Clear All
          </Button>
        </Grid>
      </Grid>
      {/* Data Table */}
      <Grid item container direction='column' style={{ marginBottom: '1em' }}>
        <TableContainer elevation={0} style={{ border: `solid 1px ${theme.palette.grey.medium}` }} component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: theme.palette.grey.lighter }}>
              <TableRow>
                {headCells.map(header => {
                  return (
                    <TableCell
                      key={header.id}
                      align='left'
                      style={{ padding: '16px' }}
                      sortDirection={orderBy === header.id ? 'asc' : false}
                    >
                      <TableSortLabel
                        active={orderBy === header.id}
                        direction={orderBy === header.id ? order : 'asc'}
                        onClick={() => handleRequestSort(header.id)}
                      >
                        <Typography variant='h5' style={{ color: theme.palette.grey.dark }}>{header.label}</Typography>
                        {orderBy === header.id ? (
                          <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow key={`index-${row.artifactID}`}>
                    <TableCell>
                      <Typography variant='body1' style={{ fontWeight: 600 }}>{row.name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        placement='top'
                        title={row.artifactName && row.artifactName.length > 25 ? (
                          <Typography>{row.artifactName}</Typography>
                        ) : ''}
                      >
                        <Button
                          variant='text'
                          color='primary'
                          className={classes.artifactNameBtn}
                          onClick={(e) => { e.preventDefault(); handleReviewArtifact(row) }}
                        >
                          {row.artifactName && row.artifactName.length > 25 ? `${row.artifactName.substring(0, 25)}...` : row.artifactName}
                        </Button>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body1'>{row.submitDate}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body1'>{row.cohort}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body1'>{row.district}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body1'>{row.school}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body1'>{getFormatStatus(row.status)}</Typography>
                    </TableCell>
                  </TableRow>
                )
              })}
              {/* The Data Array has reached it's length: */}
              {emptyRows > 0 && (
                <TableRow style={{ height: 10 }}>
                  <TableCell colSpan={7}>
                    <Typography variant='body1' style={{ color: theme.palette.grey.medium, textAlign: 'center' }}>End of List</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* Bottom Pagination Controls */}
      <Grid item container direction='row' style={{ marginBottom: '4em' }}>
        {/* Rows Per page selection: */}
        <Grid item container direction='row' xs={6} justifyContent='flex-start'>
          <Grid item style={{ marginRight: '.2em', display: 'flex', alignItems: 'flex-end' }}>
            <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>Display</Typography>
          </Grid>
          <Grid item style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Select
              variant='outlined'
              size='small'
              defaultValue={10}
              value={rowsPerPage}
              onChange={(e) => handleChangeRowsPerPage(e)}
              classes={{ root: classes.searchInput, selectMenu: classes.statusSelect }}
              style={{ padding: 0 }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </Grid>
          <Grid item style={{ marginLeft: '.4em', display: 'flex', alignItems: 'flex-end' }}>
            <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>entries</Typography>
          </Grid>
        </Grid>
        {/* Pagination/Page Selection */}
        <Grid item container xs={6} justifyContent='flex-end'>
          <Pagination
            color='primary'
            classes={{ root: classes.pagination }}
            count={totalArtifacts ? Math.ceil((totalArtifacts - 1) / rowsPerPage) : 0}
            page={page === 0 ? 1 : page + 1}
            onChange={handleChangePage}
            shape='rounded'
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withRouter(AdminArtifacts)
