import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Accordion, AccordionDetails, AccordionSummary, Grid, Typography, Paper,
  Button, useTheme, Dialog, DialogTitle, DialogContent, DialogActions, Divider,
  IconButton
} from '@material-ui/core'
import {
  ExpandMore, CloseRounded
} from '@material-ui/icons'
import { sanitizedHTML, renderSkeletons } from '../../../utils'
import { MCE_DETAILS } from '../../../redux/types'

const IntroductionAccordion = (props) => {
  const {
    classes, navHeight = 0, isSummaryView = true,
    infoExpanded = false, setInfoExpanded = () => { }, currentStyle = {}
  } = props
  const theme = useTheme()
  const { mceDetails: { resources = '', methodComponents = '', supportResearch = '' } = {} } = useSelector(state => state.microcredentials)
  const loadingState = useSelector(state => state.loadingState)

  const summaryText = !isSummaryView ? 'Additional Information' : 'Introduction and Supporting Research'

  const [researchOpen, setResearchOpen] = useState(false)

  // sanitized values for dangerously set inner hhtml values
  const textHtml = sanitizedHTML(resources)
  const methodHTML = sanitizedHTML(methodComponents)
  const researchHTML = sanitizedHTML(supportResearch)

  return (
    <>
      <Accordion
        elevation={0}
        style={{ width: 'inherit', backgroundColor: 'transparent' }}
        expanded={Boolean(isSummaryView || infoExpanded)}
        onChange={() => { setInfoExpanded(!infoExpanded) }}
      >
        {/* SECTION TITLE */}
        <AccordionSummary
          expandIcon={
            <ExpandMore
              style={{
                display: isSummaryView ? 'none' : 'flex',
                color: isSummaryView ? theme.palette.grey.darkest : 'white'
              }}
              disabled={isSummaryView}
            />
          }
          style={{
            cursor: isSummaryView ? 'auto' : 'pointer', backgroundColor: isSummaryView ? 'white' : currentStyle.mainColor
          }}
          classes={{ content: classes.accordionSummaryContent, root: classes.accordionSummaryRoot }}
        >
          <Typography variant='h4' style={{ color: isSummaryView ? theme.palette.grey.darkest : 'white', textTransform: 'uppercase', fontSize: 16 }}>{summaryText || 'Introduction and Supporting Research'}</Typography>
        </AccordionSummary>

        {/*  SECTION CONTENT */}
        <AccordionDetails style={{ padding: 0 }}>
          <Grid container direction='row' className={classes.defaultPadding}>
            {/* METHOD COMPONENTS AND RESEARCH BUTTON */}
            <Grid item xs={6} container direction='column' style={{ padding: '2em 0' }}>
              {/* METHOD COMPONENTS */}
              <Paper elevation={0} style={{ padding: '1em', backgroundColor: theme.palette.grey.lighter }}>
                <Grid container direction='column'>
                  <Grid item>
                    <Typography variant='body1' style={{ fontWeight: 600, fontSize: 16 }}>Method Components</Typography>
                    <Typography>To demonstrate proficiency in the competency identified in this micro-credential, the following action steps must be completed:</Typography>
                  </Grid>
                  <Grid item style={{ marginTop: '.5em' }}>
                    {loadingState.type === MCE_DETAILS && loadingState.loading === true
                      ? (
                        <>
                          {renderSkeletons(4, 'wave', 40)}
                        </>
                      )
                      : <div dangerouslySetInnerHTML={{ __html: methodHTML }} className={classes.methodComp} />}
                  </Grid>
                </Grid>
              </Paper>
              {/* RESEARCH BUTTON */}
              <Grid item container direction='column' justifyContent='center' alignItems='flex-start' style={{ marginTop: '1em' }}>
                <Button
                  variant='contained'
                  size='small'
                  color='secondary'
                  style={{ fontWeight: 700, backgroundColor: theme.palette.pink.dark }}
                  onClick={() => setResearchOpen(true)}
                >
                  View Supporting Rationale and Research
                </Button>
              </Grid>
            </Grid>
            {/* RESOURCES */}
            <Grid item xs={6} container direction='column' style={{ padding: '2em 1em 0 2em' }}>
              <Grid item container direction='column'>
                <Grid item>
                  <Typography variant='body1' style={{ fontWeight: 600, fontSize: 16 }}>Resources</Typography>
                </Grid>
                <Grid item>
                  {loadingState.type === MCE_DETAILS && loadingState.loading === true
                    ? (
                      <>
                        {renderSkeletons(6, 'wave', 40)}
                      </>
                    )
                    : <div dangerouslySetInnerHTML={{ __html: textHtml }} className={classes.resourceHTML} />}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {/* Research Dialog */}
      <Dialog
        fullWidth
        maxWidth='md'
        open={researchOpen}
        onClose={() => setResearchOpen(false)}
        style={{ zIndex: 11, marginTop: navHeight, overflowY: 'unset' }}
      >
        <DialogTitle disableTypography>
          <Grid container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid item>
              <Typography
                variant='h4'
                style={{ textTransform: 'none', color: theme.palette.purple.darkest, fontSize: '1.3rem' }}
              >
                Supporting Rationale and Research
              </Typography>
            </Grid>

            <Grid item>
              <IconButton style={{ padding: '0px' }} onClick={(e) => { e.preventDefault(); setResearchOpen(false) }}>
                <CloseRounded className={classes.customizedButton} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent dividers>
          {loadingState.type === MCE_DETAILS && loadingState.loading === true
            ? (
              <>
                {renderSkeletons(5, 'wave', 40)}
              </>
            )
            : <div dangerouslySetInnerHTML={{ __html: researchHTML }} className={classes.researchHTML} />}
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='primary' onClick={() => setResearchOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default IntroductionAccordion
