/**
 * @typedef {Object} imageInfo
 * @property {number} imageWidth - The width of the image
 * @property {number} imageHeight - The height of the image
 * @property {string} imageData - Encoded base64 string of image
 */

/**
 * Processes raw image data into parsable information
 * @param {Object} imageFile - Object regarding image information
 * @param {Number} imageFile.lastModified - unix date of image last modified
 * @param {Date} imageFile.lastModifiedDate - timestamp of image last modified
 * @param {String} imageFile.
 * @param {Boolean} checkRatio - Flag for getting width and height of image
 * @returns {imageInfo} - Image information such as image width, height, and base64 data
 */
export const processImage = async (imageFile, checkRatio = false) => {
  try {
    const imageData = await new Promise((resolve, reject) => {
      const reader = new window.FileReader()
      reader.readAsDataURL(imageFile)

      reader.onloadend = () => {
        if (reader && reader.result) {
          resolve(reader.result)
        } else {
          reject(new Error('Failed to read Image.'))
        }
      }
    })

    if (!imageData) { return false }
    if (imageData && !checkRatio) { return ({ imageData }) }

    const imageRatio = await new Promise((resolve, reject) => {
      const i = new window.Image()

      i.onload = () => {
        if (i && i.width && i.height) {
          resolve({
            imageWidth: i.width,
            imageHeight: i.height
          })
        } else {
          reject(new Error('Failed to acquire image ratio.'))
        }
      }

      i.src = imageData
    })

    const { imageWidth = 0, imageHeight = 0 } = imageRatio

    return ({ imageWidth, imageHeight, imageData })
  } catch (err) {
    console.error(err)
    return false
  }
}
