import React from 'react'
import { withRouter } from 'react-router-dom'
import { Paper, Grid, Typography, Avatar, useTheme } from '@material-ui/core'
import { esRecordTypeFormat, esRecordType } from '../../../../utils'
import { GradesCheck } from '../../tools'

const ModifiedGradesIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 60 60'
    >
      <path
        className='b'
        d='M33,9a9,9,0,1,0-9,9A9,9,0,0,0,33,9ZM21.9,22.6C16.337,19.2,7.327,18.262,2.794,18A2.594,2.594,0,0,0,0,20.544V41.432a2.578,2.578,0,0,0,2.483,2.536c4.093.215,12.374,1,18.1,3.884a1.305,1.305,0,0,0,1.92-1.113V23.677a1.247,1.247,0,0,0-.6-1.074ZM45.206,18c-4.533.257-13.543,1.193-19.1,4.6a1.271,1.271,0,0,0-.6,1.09V46.736a1.309,1.309,0,0,0,1.926,1.116c5.722-2.88,14-3.667,18.091-3.881A2.58,2.58,0,0,0,48,41.434V20.544A2.6,2.6,0,0,0,45.206,18Z'
        fill='#66666e'
      />
    </svg>
  )
}

const SolidChalkboardModifiedIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='16'
      viewBox='0 0 70 60'
    >
      <path
        data-name='chalkboard-solid'
        d='M10.317,6.878H58.465v37.83h6.878V4.3a4.3,4.3,0,0,0-4.3-4.3H7.738a4.3,4.3,0,0,0-4.3,4.3v40.41h6.878ZM67.063,48.148H51.587V41.269H30.952v6.878H1.72A1.719,1.719,0,0,0,0,49.867v3.439a1.719,1.719,0,0,0,1.72,1.72H67.063a1.719,1.719,0,0,0,1.72-1.72V49.867A1.719,1.719,0,0,0,67.063,48.148Z'
        fill='#66666e'
      />
    </svg>
  )
}

/* NOTES: Card to display artifact information retruned from a global search
  -- Parent Component: ui/Search/ResultAreas/ArtifactResults.js

  In depth render tree loacated in SearchBarNav.js
*/
const SearchArtifactCard = (props) => {
  const theme = useTheme()

  // Props:
  // - SearchBarNav.js: handleReset
  // - ArtifactResults.js: artifact
  const { artifact, smScreen, handleReset } = props

  const {
    record_type: artifactType, title: artifactTitle, subjectName, grades, profileAvatarPath,
    description: artifactDescription, userProfileID, objectID
  } = artifact

  // Maps through array of objects that contain each returned artifact's grades and creates an array of the grade names
  const gradesArray = grades && Boolean(grades.length) ? grades.map(grade => grade.gradeName) : []

  // Click handler to direct the user to the public view of an artifact
  const handlePublicView = async (authorID, artifactType, artifactID) => {
    const formatType = esRecordTypeFormat[artifactType]
    if (artifactID) {
      handleReset()
      props.history.push({
        pathname: '/artifact',
        search: `?public=${formatType}&user=${authorID}&artifact=${artifactID}`
      })
    }
  }

  return (
    <Paper
      style={{
        width: '100%',
        padding: '.3em .5em',
        border: `1px solid ${theme.palette.grey.medium}`,
        cursor: 'pointer'
      }}
      elevation={0}
      onClick={() => handlePublicView(userProfileID, artifactType, objectID)}
    >
      <Grid container direction={smScreen ? 'column' : 'row'}>
        <Grid item xs={null} sm={null} md={artifactType && artifactType === esRecordType.PR ? 6 : 5} container direction='row' alignItems='center'>
          <Avatar src={!profileAvatarPath ? '' : profileAvatarPath} style={{ height: '16px', width: '16px', marginRight: '.5em' }} />
          <Typography variant='body1' style={{ color: theme.palette.purple.darkest, fontWeight: 600 }}>
            {!artifactTitle ? '' : artifactTitle.length > 18 ? smScreen ? `${artifactTitle.slice(0, 30)}...` : `${artifactTitle.slice(0, 17)}...` : artifactTitle}
          </Typography>
        </Grid>
        {/* If the artifact type is not a PRO display the subject and grade information */}
        {artifactType && artifactType !== esRecordType.PR &&
          <Grid item xs={null} md={7} container direction='row'>
            <Grid item xs={6} container direction='row' alignItems='center' justifyContent='flex-start'>
              <ModifiedGradesIcon />
              <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: 600, paddingLeft: '.5em' }}>{GradesCheck(grades, gradesArray)}</Typography>
            </Grid>
            <Grid item xs={6} container direction='row' alignItems='center' justifyContent='flex-start'>
              <SolidChalkboardModifiedIcon />
              <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: 600, paddingLeft: '.5em' }}>{!subjectName ? '' : subjectName.length > 12 ? `${subjectName.slice(0, 11)}...` : subjectName}</Typography>
            </Grid>
          </Grid>}
        {/* If it is a PRO, display the description instead */}
        {artifactType && artifactType === esRecordType.PR &&
          <Grid item xs={null} sm={null} md={6} container direction='row' alignItems='center' justifyContent='flex-start'>
            <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: 600, paddingLeft: '.5em' }}>{!artifactDescription ? '' : artifactDescription.length > 25 ? `${artifactDescription.slice(0, 24)}...` : artifactDescription}</Typography>
          </Grid>}
      </Grid>
    </Paper>
  )
}

export default withRouter(SearchArtifactCard)
