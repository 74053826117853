import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  Grid, Typography, Paper, Divider, OutlinedInput, Button
} from '@material-ui/core'
import { DeviceHub } from '@material-ui/icons'
import AddCommentIcon from '@material-ui/icons/AddComment'
import theme from '../styles/MuiTheme'

import { ArtifactComment, NotificationToast } from '../tools'
import { getArtifactDetails, postAdminArtifactComment } from '../../../redux/actions'

const ReviewProject = (props) => {
  const { classes, artifactType, styles, reviewInfo, formattedInfo, setFormattedInfo } = props
  const dispatch = useDispatch()

  // ******************************* Data Formatting ******************************* //

  // Redux Items needed to translate the ID's provided in the artifact information into readable names
  const { ledList, learnerWorkList } = useSelector(state => state.artifacts)

  // Formats the information returned from the reviewInfo into readable text
  useEffect(() => {
    if (reviewInfo) {
      const tempCopy = Object.assign({}, reviewInfo)

      // Asscociated LEDs
      if (tempCopy && tempCopy.projectLEDs && tempCopy.projectLEDs.length) {
        if (ledList && ledList.length) {
          const ledNames = []
          tempCopy.projectLEDs.forEach(led => {
            const index = ledList.findIndex(x => x.ledID === led)
            if (index !== -1) {
              const { ledID, title } = ledList[index]
              ledNames.push({ title, ledID })
            }
          })
          tempCopy.projectLEDs = ledNames
        }
      }

      // Asscociated LWs
      if (tempCopy && tempCopy.projectLWs && tempCopy.projectLWs.length) {
        if (learnerWorkList && learnerWorkList.length) {
          const lwNames = []
          tempCopy.projectLWs.forEach(lw => {
            const index = learnerWorkList.findIndex(x => x.learnerWorkID === lw)
            if (index !== -1) {
              const { learnerWorkID, learnerWorkTitle } = learnerWorkList[index]
              lwNames.push({ learnerWorkID, learnerWorkTitle })
            }
          })
          tempCopy.projectLWs = lwNames
        }
      }

      setFormattedInfo({ ...tempCopy })
    }
  }, [reviewInfo, artifactType, learnerWorkList, ledList, setFormattedInfo])

  // ************************ Comment Logic ****************** //
  const defaultCommentState = {
    details: false,
    leds: false,
    lws: false
  }

  const [commentState, setCommentState] = useState(defaultCommentState)

  const fireSuccess = async () => {
    await dispatch(getArtifactDetails(formattedInfo.artifactID, '', 'Project', () => {}, () => {}, ''))
  }

  // *********************** Notes Logic ******************** //

  const [noteValue, setNoteValue] = useState('')

  // Add Note
  const handleAddNote = (artifactID) => {
    const tempCommentInfo = {
      comment: noteValue,
      artifactID,
      artifactSection: 'notes'
    }

    dispatch(postAdminArtifactComment(tempCommentInfo, fireAddNoteSuccess, fireAddNoteFailure))
  }

  const fireAddNoteSuccess = async () => {
    await dispatch(getArtifactDetails(formattedInfo.artifactID, '', 'Project', () => {}, () => {}, ''))
    setNoteValue('')
  }

  const fireAddNoteFailure = () => {
    NotificationToast(true, 'Falied to Create Note')
  }

  return (
    <Paper className={classes.paperRoot}>
      <Grid container direction='column'>
        {/* Step Header */}
        <Grid container item direction='row' spacing={1} style={{ marginBottom: '1em' }}>
          <Grid item>
            <DeviceHub />
          </Grid>
          <Grid item>
            <Typography variant='h4'>{artifactType}</Typography>
          </Grid>
        </Grid>

        <Grid container item direction='column' style={{ marginTop: '.5em', paddingRight: '2em' }}>
          {/* ----------------------- Notes Input ----------------------- */}
          <Grid item container direction='column' style={{ marginBottom: '2em', width: '104%' }}>
            <Grid item>
              <Typography variant='h6' style={{ color: theme.palette.black, fontWeight: 600, textTransform: 'none' }}>Notes</Typography>
            </Grid>
            <Grid item style={{ width: '100%', marginTop: '.5em' }}>
              <OutlinedInput
                variant='outlined'
                value={noteValue}
                fullWidth
                margin='dense'
                placeholder='Enter a new note for the teacher...'
                classes={{ input: classes.inputPlaceholder }}
                onChange={(e) => setNoteValue(e.target.value)}
              />
            </Grid>
            {/* Only Display the save button after the user has begun typing */}
            {noteValue && noteValue.length > 0 &&
              <Grid item container justifyContent='flex-end' style={{ marginTop: '.3em' }}>
                <Button
                  variant='contained'
                  style={{ backgroundColor: styles.mainColor }}
                  onClick={() => handleAddNote(formattedInfo.artifactID)}
                >
                  Save
                </Button>
              </Grid>}
            {formattedInfo.commentList && formattedInfo.commentList.length !== 0 &&
              formattedInfo.commentList.map(comment => {
                const { section } = comment
                if (section === 'notes') {
                  return (
                    <Grid key={comment.commentID} container item style={{ marginBottom: '.5em', marginTop: '1.5em' }}>
                      <ArtifactComment
                        commentState={commentState}
                        setCommentState={setCommentState}
                        styles={styles}
                        classes={classes}
                        section='notes'
                        artifactID={formattedInfo.artifactID}
                        commentInfo={comment}
                        fireSuccess={fireSuccess}
                      />
                    </Grid>
                  )
                }
                return ''
              })}

          </Grid>

          {/* **************** Artifact Details ***************** */}
          <Paper elevation={0} style={{ backgroundColor: theme.palette.grey.lighter, padding: '1em', width: '100%' }}>
            <Grid container item direction='row'>
              {/* Left Side Column */}
              <Grid item container direction='column' xs={3} sm={2}>
                <Grid item>
                  <Typography variant='h5' style={{ fontWeight: '600' }}>Artifact Details</Typography>
                </Grid>
              </Grid>

              <Divider flexItem variant='middle' orientation='vertical' />

              {/* Right Side Column */}
              <Grid item container direction='column' xs={8} sm={9} spacing={3}>

                {/* Name */}
                <Grid item container direction='column'>
                  <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>
                    * What's the name of your artifact?
                  </Typography>
                  <Typography
                    variant='body1'
                    style={{
                      color: theme.palette.black,
                      fontWeight: '600',
                      fontSize: '13px',
                      wordBreak: 'break-word'
                    }}
                  >
                    {formattedInfo && formattedInfo.projectTitle ? formattedInfo.projectTitle : 'No information available'}
                  </Typography>
                </Grid>

                {/* Description */}
                <Grid item container direction='column'>
                  <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>Description</Typography>
                  <Typography
                    variant='body1'
                    style={{
                      color: theme.palette.black,
                      fontWeight: '600',
                      fontSize: '13px',
                      wordBreak: 'break-word'
                    }}
                  >
                    {formattedInfo && formattedInfo.projectDescription ? formattedInfo.projectDescription : 'No information available.'}
                  </Typography>
                </Grid>

                {/* Project Length */}
                <Grid item container direction='column'>
                  <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>* Project Length</Typography>
                  <Typography
                    variant='body1'
                    style={{
                      color: theme.palette.black,
                      fontWeight: '600',
                      fontSize: '13px'
                    }}
                  >
                    {formattedInfo && formattedInfo.projectLength ? `${formattedInfo.projectLength} week(s)` : 'No information available.'}
                  </Typography>
                </Grid>

                {/* Comments */}
                <Grid item container direction='column' alignContent='flex-start' style={{ marginTop: '1em', marginBottom: '.5em' }}>
                  {formattedInfo.commentList && formattedInfo.commentList.length !== 0 &&
                    formattedInfo.commentList.map(comment => {
                      const { section } = comment
                      if (section === 'details') {
                        return (
                          <Grid key={comment.commentID} container item style={{ marginBottom: '.5em' }}>
                            <ArtifactComment
                              key={comment.commentID}
                              commentState={commentState}
                              artifactID={formattedInfo.artifactID}
                              setCommentState={setCommentState}
                              styles={styles}
                              classes={classes}
                              section='details'
                              commentInfo={comment}
                              fireSuccess={fireSuccess}
                            />
                          </Grid>
                        )
                      }
                      return ''
                    })}
                  {commentState && commentState.details
                    ? (
                      <ArtifactComment
                        commentState={commentState}
                        setCommentState={setCommentState}
                        styles={styles}
                        classes={classes}
                        section='details'
                        fireSuccess={fireSuccess}
                        artifactID={formattedInfo.artifactID}
                      />
                    )
                    : (
                      <Grid container item justifyContent='flex-start' style={{ marginTop: '.5em' }}>
                        <Button
                          variant='contained'
                          style={{ margin: 'auto', marginLeft: 0, fontWeight: '600', backgroundColor: styles.mainColor }}
                          startIcon={
                            <AddCommentIcon />
                          }
                          onClick={() => setCommentState({ ...commentState, details: true })}
                        >
                          Add Comment
                        </Button>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            </Grid>
          </Paper>

          {/* **************** Items ***************** */}
          <Paper elevation={0} style={{ backgroundColor: theme.palette.grey.lighter, padding: '1em', width: '100%', marginTop: '1em' }}>
            <Grid container item direction='row'>
              {/* Left Side Column */}
              <Grid item container direction='column' xs={3} sm={2}>
                <Grid item>
                  <Typography variant='h5' style={{ fontWeight: '600' }}>Project Items</Typography>
                </Grid>
              </Grid>

              <Divider flexItem variant='middle' orientation='vertical' />

              {/* Right Side Column */}
              <Grid item container direction='column' xs={8} sm={9} spacing={3}>
                {/* LEDs */}
                <Grid item container direction='column'>
                  <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>* Learning Experience Designs</Typography>
                  {formattedInfo && formattedInfo.projectLEDs && formattedInfo.projectLEDs.length !== 0
                    ? (
                      formattedInfo.projectLEDs.map((led) => {
                        const { ledID, title } = led
                        return (
                          <Typography key={`link-${ledID}`} variant='body1' style={{ color: theme.palette.purple.darkest, fontWeight: '600', fontSize: '13px', textDecoration: 'underline' }}>
                            {title}
                          </Typography>
                        )
                      })

                    )
                    : (
                      <Typography variant='body1' style={{ color: theme.palette.black, fontWeight: '600', fontSize: '13px' }}>No information available</Typography>
                    )}
                </Grid>

                {/* LWs */}
                <Grid item container direction='column'>
                  <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>* Learner Works</Typography>
                  {formattedInfo && formattedInfo.projectLWs && formattedInfo.projectLWs.length !== 0
                    ? (
                      formattedInfo.projectLWs.map((lw) => {
                        const { learnWorkID, learnerWorkTitle: title } = lw
                        return (
                          <Typography key={`link-${learnWorkID}`} variant='body1' style={{ color: theme.palette.purple.darkest, fontWeight: '600', fontSize: '13px', textDecoration: 'underline' }}>
                            {title}
                          </Typography>
                        )
                      })

                    )
                    : (
                      <Typography variant='body1' style={{ color: theme.palette.black, fontWeight: '600', fontSize: '13px' }}>No information available.</Typography>
                    )}
                </Grid>
                {/* Comments */}
                <Grid item container direction='column' alignContent='flex-start' style={{ marginTop: '1em', marginBottom: '.5em' }}>
                  {formattedInfo.commentList && formattedInfo.commentList.length !== 0 &&
                    formattedInfo.commentList.map(comment => {
                      const { section } = comment
                      if (section === 'items') {
                        return (
                          <Grid key={comment.commentID} container item style={{ marginBottom: '.5em' }}>
                            <ArtifactComment
                              key={comment.commentID}
                              commentState={commentState}
                              setCommentState={setCommentState}
                              styles={styles}
                              classes={classes}
                              section='items'
                              commentInfo={comment}
                              fireSuccess={fireSuccess}
                              artifactID={formattedInfo.artifactID}
                            />
                          </Grid>
                        )
                      }
                      return ''
                    })}
                  {commentState && commentState.leds
                    ? (
                      <ArtifactComment
                        commentState={commentState}
                        setCommentState={setCommentState}
                        styles={styles}
                        classes={classes}
                        section='items'
                        fireSuccess={fireSuccess}
                        artifactID={formattedInfo.artifactID}
                      />
                    )
                    : (
                      <Grid container item justifyContent='flex-start' style={{ marginTop: '.5em' }}>
                        <Button
                          variant='contained'
                          style={{ margin: 'auto', marginLeft: 0, fontWeight: '600', backgroundColor: styles.mainColor }}
                          startIcon={
                            <AddCommentIcon />
                          }
                          onClick={() => setCommentState({ ...commentState, leds: true })}
                        >
                          Add Comment
                        </Button>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            </Grid>
          </Paper>

        </Grid>
      </Grid>
    </Paper>
  )
}

export default ReviewProject
