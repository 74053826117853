import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  Grid, Typography, useTheme, Button, OutlinedInput, Table, TableRow, TableSortLabel, TableContainer, TableCell, TableHead,
  TableBody, IconButton, Paper, Select, MenuItem, Avatar, Popover
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'

import { isEqual } from 'lodash'

import { AddRounded, SearchRounded } from '@material-ui/icons'

import {
  getCertifications, createCertification, updateCertification, deleteCertification
} from '../../../../redux/actions'
import { NotificationToast } from '../../tools'
import { ModifiedEditIcon } from '../../custom'

import moment from 'moment'

import { uploadType, uploadFileFormatter, defaultErrors } from '../../../../utils'
import AdminCertModal from './AdminCertModal'

const AdminCerts = (props) => {
  const {
    classes, defaultFilter, filter, setFilter, activeButton, allCerts, totalCerts,
    setAllCerts, setTotalCerts, adminCertifications, navHeight
  } = props
  const theme = useTheme()
  const dispatch = useDispatch()

  // const [now] = useState(moment().unix())

  // ********************* New/Edit Certification logic: ******************** //
  const defaultCertForm = {
    certName: '',
    certDesc: 'N/A',
    certImageKey: '',
    originalCertImage: '',
    originalCertImageType: ''
  }
  const [newCertForm, setNewCertForm] = useState(defaultCertForm)

  // Local state for the cropped version of a selected / uploaded cert image
  const [croppedCertImage, setCroppedCertImage] = useState('')

  const [isImageChanged, setIsImageChanged] = useState(false)

  const [isCropChanged, setIsCropChanged] = useState(false)

  // const [isChanging, setIsChanging] = useState(false)
  const [createOpen, setCreateOpen] = useState(false)

  const defaultAdminCertsErr = defaultErrors.adminCertsErr
  const [errors, setErrors] = useState(defaultAdminCertsErr)

  const [editID, setEditID] = useState('')
  const [editingCert, setEditingCert] = useState(false)

  // Local state for completed crop
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()

  // Data rows for the displayed table
  const [rows, setRows] = useState([])

  const updateRows = () => {
    setErrors(defaultAdminCertsErr)
    setNewCertForm(defaultCertForm)
    setCreateOpen(false)

    setAllCerts(adminCertifications.certifications)
    setTotalCerts(adminCertifications.certCount)
    setTimeout(() => {
      const newRows = []
      adminCertifications.certifications.forEach(cert => {
        const { certificationID, certName, certDesc, certImageKey, certImageUrl, croppedCertImageUrl } = cert
        newRows.push(createData(certificationID, certName, certDesc, certImageKey, `${certImageUrl}?${moment().add(5, 'minute').unix()}`, `${croppedCertImageUrl}?${moment().add(5, 'minute').unix()}`))
      })
      setRows(newRows)
      if (newRows && newRows.length) {
        if (isImageChanged || isCropChanged) {
          dispatch(getCertifications())
          setFilter({ ...filter })
        }
      }
    }, 2000)
  }

  const translateFullImage = (certImage) => {
    try {
      return new Promise((resolve, reject) => {
        const img = document.createElement('img')
        img.src = certImage
        img.setAttribute('crossOrigin', 'anonymous')
        img.style.height = '100%'
        img.style.width = '100%'
        img.style.objectFit = 'contain'

        img.onload = () => {
          const canvas = document.createElement('canvas')

          const ctx = canvas.getContext('2d')

          canvas.width = img.naturalWidth
          canvas.height = img.naturalHeight

          ctx.drawImage(img, 0, 0)

          canvas.toBlob(async (blob) => {
            let fullBase64 = null

            const reader = new window.FileReader()
            reader.readAsDataURL(blob)
            const test = await new Promise((resolve, reject) => {
              reader.onloadend = () => { resolve(reader.result) }
              reader.onerror = (error) => { reject(error) }
            })

            fullBase64 = test

            ctx.restore()

            resolve(fullBase64)
          })
        }

        img.onerror = (error) => {
          reject(error)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleOpenModal = async (certInfo) => {
    if (certInfo) {
      const { certificationID, certificationName, certDesc, certImageKey, certImageUrl } = certInfo

      setEditID(certificationID)
      setEditingCert(true)

      // Translate image to base64
      const translate = await translateFullImage(`${certImageUrl}?${moment().unix()}`)

      setNewCertForm({
        certName: certificationName,
        certDesc,
        certImageKey,
        originalCertImage: !translate ? `${certImageUrl}?${moment().unix()}` : translate
      })
    } else {
      setEditID('')
      setEditingCert(false)
      setNewCertForm(defaultCertForm)
    }
    setCreateOpen(true)
  }

  const errorCheck = newCertForm.certName !== ''

  const fireCreateSuccess = () => {
    NotificationToast(false, 'Sucessfully Created New Certification')
    updateRows()
  }

  const fireCreateFailure = (code) => {
    if (code === 409) {
      return NotificationToast(true, 'Certification already exists.', true)
    } else {
      return NotificationToast(true, 'Unable to Create New Certification')
    }
  }

  const fireUpdateSuccess = () => {
    NotificationToast(false, 'Sucessfully Updated Certification')
    updateRows()
  }

  const fireUpdateFailure = (code) => {
    if (code === 409) {
      return NotificationToast(true, 'Certification already exists.', true)
    } else {
      return NotificationToast(true, 'Unable to Update Certification')
    }
  }

  const handleSave = () => {
    const { certName, certDesc, originalCertImage, originalCertImageType } = newCertForm
    if (errorCheck) {
      if (editID === '') {
        // CREATE
        const newCertForm = {
          certName,
          certDesc,
          certImage: originalCertImage,
          certImageType: originalCertImageType,
          croppedCertImage,
          croppedCertImageType: originalCertImageType
        }

        dispatch(createCertification(newCertForm, fireCreateSuccess, fireCreateFailure))
      } else {
        // EDIT
        const editCertForm = {
          certificationID: editID,
          certName,
          certDesc,
          isImageChanged,
          isCropChanged
        }

        if (isImageChanged) {
          // If the original uploaded image is changed, update it and the cropped version
          editCertForm.certImage = originalCertImage
          editCertForm.certImageType = originalCertImageType
          editCertForm.croppedCertImage = croppedCertImage
          editCertForm.croppedCertImageType = originalCertImageType
        } else if (!isImageChanged && isCropChanged) {
          // If the original uploaded has not been changed but the crop has been changed, only update the cropped version
          editCertForm.croppedCertImage = croppedCertImage
          editCertForm.croppedCertImageType = 'jpeg'
        }

        dispatch(updateCertification(editCertForm, fireUpdateSuccess, fireUpdateFailure))
      }
    } else {
      setErrors({
        ...errors,
        notComplete: true
      })
    }
  }

  const handleDelete = (certificationID) => {
    if (certificationID) {
      dispatch(deleteCertification(certificationID, fireDeleteSuccess, fireDeleteFailure))
    } else { NotificationToast(true, 'Unable to Delete Certification') }
  }

  const fireDeleteSuccess = () => { NotificationToast(false, 'Sucessfully Deleted Certification'); updateRows(); handleCancel() }

  const fireDeleteFailure = () => { return NotificationToast(true, 'Unable to Delete Certification') }

  const handleCancel = () => {
    setErrors(defaultAdminCertsErr)
    setNewCertForm(defaultCertForm)
    setCroppedCertImage('')
    fileErrorReset()
    setCreateOpen(false)
    setEditingCert(false)
    setConfirmAnchorEl(null)
    setIsImageChanged(false)
    setIsCropChanged(false)
  }

  // Confirm popper related elements --- //
  const [confirmAnchorEl, setConfirmAnchorEl] = useState(null)

  const handleConfirmPopover = (e) => {
    setConfirmAnchorEl(e.currentTarget)
  }
  const handleConfirmPopClose = () => {
    setConfirmAnchorEl(null)
  }
  const openConfirmPopover = Boolean(confirmAnchorEl)
  // ----- *** ----- //

  // ********************* File Handling logic: ******************** //
  const [fileSizeError, setFileSizeError] = useState(false)
  const [fileExtensionError, setFileExtensionError] = useState(false)

  const fileErrorReset = () => { setFileSizeError(false); setFileExtensionError(false) }

  const handleImageUpload = async (e, file) => {
    e.preventDefault()

    // Reset errors on initial upload to clear out previous
    fileErrorReset()

    const handleFileSizeError = (error) => setFileSizeError(error)
    const handleFileExtensionError = (error) => setFileExtensionError(error)

    const type = uploadType.PROFILE_IMAGE
    const attachments = []
    const imageResult = await uploadFileFormatter(file, type, attachments, handleFileSizeError, handleFileExtensionError)

    if (imageResult) {
      const { imageData, fileExtension } = imageResult
      setNewCertForm({ ...newCertForm, originalCertImage: imageData, originalCertImageType: fileExtension })
    }
  }

  // ******************************** Table Data Logic: ******************************* //

  const createData = (certificationID, certificationName, certDesc, certImageKey, certImageUrl, croppedCertImageUrl) => {
    return { certificationID, certificationName, certDesc, certImageKey, certImageUrl, croppedCertImageUrl }
  }

  // If the filter was changed, fetch the pending Users with the new filter
  useEffect(() => {
    if (filter && defaultFilter && activeButton === 'certs') {
      if (!isEqual(filter, defaultFilter)) {
        dispatch(getCertifications(filter))
      }
    }
  }, [dispatch, filter, defaultFilter, setFilter, activeButton])

  // Take the allCerts array and format it into the data rows
  useEffect(() => {
    if (allCerts) {
      if (allCerts.length) {
        const newRows = []
        allCerts.forEach(cert => {
          const { certificationID, certName, certDesc, certImageKey, certImageUrl, croppedCertImageUrl } = cert

          newRows.push(createData(certificationID, certName, certDesc, certImageKey, certImageUrl, croppedCertImageUrl))
        })
        setRows(newRows)
      } else {
        setRows([])
      }
    }
  }, [allCerts])

  // const rows = [createData('CE123', 'Certification Name')]

  const headCells = [
    { id: 'certNameHeader', label: 'Name' },
    { id: 'certLogoHeader', label: 'Logo' }
  ]

  // ******************************* Pagination logic: ******************************* //
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const handleChangePage = (event, value) => {
    if (value >= 0) {
      setPage(value - 1)
      setFilter({
        ...filter,
        sortCount: rowsPerPage,
        page: value
      })
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: event.target.value
    })
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalCerts - page * rowsPerPage)

  // **************************** Column Sort Logic ************************ //

  const [orderBy, setOrderBy] = useState('certificationName')
  const [order, setOrder] = useState('asc')

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: rowsPerPage,
      sortDirection: isAsc ? 'DESC' : 'ASC',
      sortType: property
    })
  }

  // ********************** Search Logic *********************** //
  const [searchInput, setSearchInput] = useState('')

  const handleSearch = (e) => {
    setSearchInput(e.target.value)
    if (searchInput && searchInput.length >= 3) {
      // Previously, issues were occuring if the user was on a page that was greater than 1 and made a search. The page is now being reset beforehand to avoid that.
      setPage(0)
      setFilter({
        ...filter,
        page: 1,
        sortCount: rowsPerPage,
        searchPhrase: searchInput
      })
    }

    if (e.target.value === '') {
      setPage(0)
      // Set the sortCount to current rowsPerPage instead of the default
      setFilter({
        ...defaultFilter,
        sortCount: rowsPerPage
      })
    }
  }

  return (
    <Grid container direction='column'>

      {/* ----------Certification Create/edit Modal--------- */}
      <AdminCertModal
        classes={classes}
        newCertForm={newCertForm}
        setNewCertForm={setNewCertForm}
        handleCancel={handleCancel}
        handleConfirmPopover={handleConfirmPopover}
        handleImageUpload={handleImageUpload}
        handleSave={handleSave}
        createOpen={createOpen}
        editID={editID}
        editingCert={editingCert}
        errors={errors}
        fileExtensionError={fileExtensionError}
        fileSizeError={fileSizeError}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        setCroppedCertImage={setCroppedCertImage}
        setIsImageChanged={setIsImageChanged}
        isImageChanged={isImageChanged}
        setIsCropChanged={setIsCropChanged}
        navHeight={navHeight}
      />

      {/* ------- Delete Certification Confirm ------- */}

      <Popover
        id={openConfirmPopover ? 'confirm-popover' : undefined}
        open={openConfirmPopover}
        anchorEl={confirmAnchorEl}
        onClose={handleConfirmPopClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <Grid container direction='column' style={{ padding: '1em' }}>
          <Typography
            variant='h4'
            style={{ color: theme.palette.purple.darkest }}
            gutterBottom
          >
            You have chosen to delete this certification. Are you sure you wish to continue?
          </Typography>

          <Typography variant='body1' gutterBottom>This will remove it from any profile it is currently set on.</Typography>

          <Grid item container direction='row' justifyContent='flex-end' alignItems='center' style={{ marginTop: '.5em' }}>
            <Button onClick={handleConfirmPopClose} style={{ marginRight: '.3em', textTransform: 'none', fontWeight: '600' }}>Cancel</Button>
            <Button variant='contained' color='primary' onClick={(e) => { e.preventDefault(); handleDelete(editID) }}>Confirm</Button>
          </Grid>
        </Grid>
      </Popover>

      {/* ---------------------- Main Component Area ----------------------- */}
      <Grid item container style={{ marginBottom: '2em' }}>
        {/* Page Title */}
        <Grid item container xs={9} justifyContent='flex-start'>
          <Typography variant='h4' style={{ fontSize: '20px' }}>Certifications</Typography>
        </Grid>
        <Grid item container xs={3} justifyContent='flex-end'>
          <Button
            color='primary'
            variant='contained'
            style={{ fontWeight: '600' }}
            startIcon={
              <AddRounded className={classes.addIcon} />
            }
            onClick={(e) => { e.preventDefault(); handleOpenModal() }}
          >
            Add Certification
          </Button>
        </Grid>
      </Grid>

      {/* Top Pagination display and search input */}
      <Grid item container direction='row' style={{ marginBottom: '1em', marginTop: '1em' }}>
        <Grid item container alignContent='flex-end' xs={7}>
          <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none', fontWeight: 400, fontSize: '16px' }}>
            Displaying {totalCerts === 0 ? 0 : (page * rowsPerPage) + 1} to {rows && page * rowsPerPage + rowsPerPage > totalCerts ? totalCerts : page * rowsPerPage + rowsPerPage} of {totalCerts}
          </Typography>
        </Grid>
        <Grid item container justifyContent='flex-end' xs={5}>
          <OutlinedInput
            className={classes.searchInput}
            size='small'
            margin='dense'
            fullWidth
            inputProps={{ style: { border: 'none', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '5px' } }}
            placeholder='Search...'
            classes={{ input: classes.inputPlaceholder }}
            startAdornment={
              <SearchRounded style={{ color: theme.palette.grey.dark, fontSize: '20px' }} />
            }
            value={searchInput}
            onChange={(e) => handleSearch(e)}
          />
        </Grid>
      </Grid>

      {/* Data Table */}
      <Grid item container direction='column' style={{ marginBottom: '1em' }}>
        <TableContainer elevation={0} style={{ border: `solid 1px ${theme.palette.grey.medium}` }} component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: theme.palette.grey.lighter }}>
              <TableRow>
                {headCells.map(header => {
                  return (
                    <TableCell
                      key={header.id}
                      align='left'
                      style={{ padding: '16px' }}
                      sortDirection={orderBy === header.id ? 'asc' : false}
                    >
                      <TableSortLabel
                        active={orderBy === header.id}
                        direction={orderBy === header.id ? order : 'asc'}
                        onClick={() => handleRequestSort(header.id)}
                      >
                        <Typography variant='h5' style={{ color: theme.palette.grey.dark }}>{header.label}</Typography>
                        {orderBy === header.id ? (
                          <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  )
                })}
                <TableCell align='left' style={{ padding: '16px' }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow key={`index-${row.certificationID}`}>
                    <TableCell>
                      <Typography variant='body1' style={{ fontWeight: 600 }}>{row.certificationName}</Typography>
                    </TableCell>
                    <TableCell>
                      <Avatar
                        src={
                          !row.croppedCertImageUrl ? row.certImageUrl : `${row.croppedCertImageUrl}?${moment().unix()}`
                        }
                      />
                    </TableCell>
                    <TableCell align='right'>
                      <Grid item container direction='row' justifyContent='flex-end'>
                        <IconButton
                          className={classes.editIcon}
                          onClick={(e) => { e.preventDefault(); handleOpenModal(row) }}
                        >
                          <ModifiedEditIcon />
                        </IconButton>
                        {/* Ed Farm has requested only manual delete for now. */}
                        {/* <IconButton
                          className={classes.deleteIcon}
                        >
                          <DeleteOutline />
                        </IconButton> */}
                      </Grid>
                    </TableCell>
                  </TableRow>
                )
              })}
              {/* When the data has reached its length: */}
              {emptyRows > 0 && (
                <TableRow style={{ height: 10 }}>
                  <TableCell colSpan={5}>
                    <Typography variant='body1' style={{ color: theme.palette.grey.medium, textAlign: 'center' }}>End of List</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* Bottom Pagination Controls */}
      <Grid item container direction='row' style={{ marginBottom: '4em' }}>
        {/* Rows Per page selection */}
        <Grid item container direction='row' xs={6} justifyContent='flex-start'>
          <Grid item style={{ marginRight: '.2em', display: 'flex', alignItems: 'flex-end' }}>
            <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>Display</Typography>
          </Grid>
          <Grid item style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Select
              variant='outlined'
              size='small'
              defaultValue={10}
              value={rowsPerPage}
              onChange={(e) => handleChangeRowsPerPage(e)}
              classes={{ root: classes.searchInput, selectMenu: classes.statusSelect }}
              style={{ padding: 0 }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </Grid>
          <Grid item style={{ marginLeft: '.4em', display: 'flex', alignItems: 'flex-end' }}>
            <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>entries</Typography>
          </Grid>
        </Grid>
        {/* Pages/Pagination Display */}
        <Grid item container xs={6} justifyContent='flex-end'>
          <Pagination
            color='primary'
            classes={{ root: classes.pagination }}
            count={totalCerts ? Math.ceil(totalCerts / rowsPerPage) : 0}
            page={page === 0 ? 1 : page + 1}
            onChange={handleChangePage}
            shape='rounded'
          />
        </Grid>
      </Grid>

    </Grid>
  )
}

export default AdminCerts
