const notifications = {
  ARTIFACT_APPROVED: 'ARTIFACT_APPROVED',
  ARTIFACT_DENIED: 'ARTIFACT_DENIED',
  ARTIFACT_COMMENT: 'ARTIFACT_COMMENT',
  ADMIN_COMMENT: 'ADMIN_COMMENT',
  ARTIFACT_LIKED: 'ARTIFACT_LIKED',
  ARTIFACT_SAVED: 'ARTIFACT_SAVED',
  ARTIFACT_CHANGES_REQUESTED: 'ARTIFACT_CHANGES_REQUESTED',
  ARTIFACT_RESUBMITTED: 'ARTIFACT_RESUBMITTED',
  USER_ARTIFACT_COMMENT: 'USER_ARTIFACT_COMMENT'
}

const getActionWording = (actionType) => {
  switch (actionType) {
    case notifications.ARTIFACT_APPROVED:
      return 'approved'
    case notifications.ARTIFACT_DENIED:
      return 'denied'
    case notifications.ADMIN_COMMENT:
      return 'admin commented on'
    case notifications.ARTIFACT_COMMENT:
      return 'commented on'
    case notifications.USER_ARTIFACT_COMMENT:
      return 'commented on your artifact'
    case notifications.ARTIFACT_CHANGES_REQUESTED:
      return 'requested changes on'
    case notifications.ARTIFACT_RESUBMITTED:
      return 'resubmitted'
    case notifications.ARTIFACT_LIKED:
      return 'liked'
    case notifications.ARTIFACT_SAVED:
      return 'saved'
    default: return ''
  }
}

export { notifications, getActionWording }
