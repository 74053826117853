import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Grid, Typography, Paper, Avatar, useTheme } from '@material-ui/core'
import { EmailRounded } from '@material-ui/icons'

import { getConversations, setConversations, getConversationID, setActiveConversationUsers } from '../../../../redux/actions'

import moment from 'moment'

/* NOTES: Card to display user information retruned from a global search
  Rendered in 2 locations:
  -- Parent Component: ui/Search/ResultAreas/UserResults.js
  -- Parent Component: ui/Search/ResultAreas/ExpandedUserResults.js

  In depth render tree loacated in SearchBarNav.js
*/
const SearchUserCard = (props) => {
  // Props:
  // - SearchBarNav.js: handleReset
  // - UserResults.js: user
  // - ExpandedUserResults.js: expanded
  const { user, handleReset, expanded } = props

  const { fullName, profileAvatarPath, profileID, schoolName, cohortName } = user

  const theme = useTheme()
  const dispatch = useDispatch()

  const { conversations = [] } = useSelector(state => state.websocket)

  // Click handler to navigate to the profile of the selected user
  const viewUserProfile = (id) => {
    if (id) {
      handleReset()
      props.history.push({
        pathname: '/profile',
        search: `?user=${id}`
      })
    }
  }

  // Take user to messages and open conversation with the selected user
  const handleUserMessage = async (e, userObject) => {
    e.preventDefault()
    e.stopPropagation()

    if (userObject && userObject.userID) {
      // get conversationID for the selected user to pull up conversation
      const conversationID = await new Promise((resolve, reject) => {
        dispatch(getConversationID([userObject.userID], (payload) => resolve(payload), () => reject(new Error('failed to acquire conversation id'))))
      }).catch(err => console.error(err))

      if (conversationID) {
        let conversationList = []

        // if conversations are not set in redux already, grab them and set conversationList to results, otherwise set to conversations from redux
        if (conversations && conversations.length === 0) {
          const newConversations = await dispatch(getConversations())

          conversationList = newConversations
        } else {
          conversationList = conversations
        }

        // if the conversationList is correctly set, run checks
        if (conversationList) {
          // check conversations array to see if the conversation already exists there
          const conversationCheck = conversations.some(conversation => conversation.conversationID === conversationID)

          // if the conversation is not in the array, copy and add to the begnining of the array
          if (!conversationCheck) {
            const now = moment().unix()

            // build temporary object for conversation list
            // mostRecentMessageContent changed from empty string
            const newConversation = {
              conversationID,
              userListDetails: [userObject],
              mostRecentMessageID: '',
              mostRecentCreatedAt: now,
              mostRecentIsDraft: 0,
              mostRecentMessageContent: JSON.stringify([{ children: [{ text: '' }] }]),
              convoHasDraftFromUser: false,
              unreadMessages: false,
              hiddenAt: false,
              blocked: false
            }

            // copy conversation array and push new conversation to first index
            const tempConversations = [...conversationList]

            tempConversations.unshift(newConversation)

            // set new conversation array to redux for conversation list
            dispatch(setConversations(tempConversations))
          }
        }

        // update active conversation users array with selected user object
        dispatch(setActiveConversationUsers([userObject]))

        // take user to message view with conversationID set to selected
        props.history.push(`/messages?view=message&conversationID=${conversationID}`)
      } else {
        // take user to default message view if something goes wrong when getting conversationID
        props.history.push({
          pathname: '/messages'
        })
      }
    } else {
      // take user to default message view if something goes wrong when with user object provided
      props.history.push({
        pathname: '/messages'
      })
    }
  }

  return (
    <Paper
      style={{
        padding: '1em .5em',
        border: `1px solid ${theme.palette.grey.medium}`,
        cursor: 'pointer'
      }}
      elevation={0}
      onClick={() => viewUserProfile(profileID)}
    >
      <Grid container direction='row'>
        <Grid container item xs={3} style={{ alignContent: 'center', justifyContent: 'center' }}>
          <Avatar src={!profileAvatarPath ? null : profileAvatarPath} style={{ height: '45px', width: '45px' }} />
        </Grid>
        <Grid container item xs={expanded ? 6 : 9} direction='column' style={{ paddingLeft: '.5em' }}>
          <Typography variant='body1' style={{ fontWeight: 600, color: theme.palette.purple.darkest, fontSize: '16px' }}>{!fullName ? '' : fullName.length > 15 ? `${fullName.slice(0, 14)}...` : fullName}</Typography>
          <Typography variant='body1' style={{ fontWeight: 400, lineHeight: '1.2' }}>{!schoolName ? '--' : schoolName.length > 12 ? `${schoolName.slice(0, 11)}...` : schoolName}</Typography>
          <Typography variant='caption' style={{ fontWeight: 400, color: theme.palette.grey.dark }}>{!cohortName ? '--' : cohortName}</Typography>
        </Grid>

        {expanded &&
          <Grid container item xs={3} style={{ alignContent: 'center', justifyContent: 'center' }}>
            <Avatar style={{ height: '40px', width: '40px', backgroundColor: `${theme.palette.purple.light}75`, cursor: 'pointer' }}>
              <EmailRounded style={{ color: theme.palette.purple.darkest }} onClick={(e) => { handleUserMessage(e, user) }} />
            </Avatar>
          </Grid>}

      </Grid>
    </Paper>
  )
}

export default withRouter(SearchUserCard)
