import React from 'react'

import { Grid } from '@material-ui/core'

// *** Child Components:
import NoResourceResults from '../NoResults/NoResourceResults'
import SearchResourceCard from '../ResultCards/SearchResourceCard'

/* NOTE: If resource results are found, maps through results, otherwise renders no results component
  -- Parent Component: ui/Search/SearchPopper.js
    -- Child Components:
      - ../NoResults/NoResourceResults
      - ../ResultCards/SearchResourceCard
*/
const ResourceResults = (props) => {
  // Props:
  // SearchPopper.js: resourceResults (deconstructed from searchResults passed to the parent from SearchBarNav.js)
  // SearchBarNav.js: handleReset
  const { resourceResults, handleReset, handleNoResultsViewAll } = props

  return (
    <>
      {/* If results were found in the api call, render the user cards for each */}
      {resourceResults && Boolean(resourceResults.length)
        ? (
          <>
            {/* display the first three results. if more than 3 results are returned, a 'view all' button will render in the parent component */}
            {resourceResults.slice(0, 3).map(resource => {
              return (
                <Grid key={`ef-resource-${resource.objectID}`} container item>
                  <SearchResourceCard resource={resource} handleReset={handleReset} />
                </Grid>
              )
            })}
          </>
        )
        : (
          // otherwise display no results message
          <NoResourceResults handleNoResultsViewAll={handleNoResultsViewAll} />
        )}
    </>
  )
}

export default ResourceResults
