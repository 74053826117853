import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  Grid, Dialog, DialogTitle, DialogContent, IconButton, Divider,
  InputLabel, OutlinedInput, FormControl, Button, Typography, useTheme
} from '@material-ui/core'

import {
  CloseRounded, Visibility, VisibilityOff
} from '@material-ui/icons'

import { makeStyles } from '@material-ui/core/styles'

import { updateUserData } from '../../../redux/actions'
import { formValidationTypes } from '../../../lib'

import { NotificationToast } from '../tools'

import log from 'loglevel'

import { defaultErrors } from '../../../utils'

const useStyles = makeStyles((theme) => ({
  paper: {
    overflowY: 'unset'
  },
  customizedButton: {
    backgroundColor: 'none',
    color: 'grey',
    stroke: 'grey',
    strokeWidth: '2px',
    height: '2rem',
    width: '2rem',
    '&:hover': {
      stroke: '#616161'
    }
  }
}))

const PasswordResetModal = (props) => {
  const { passwordResetModalOpen, setPasswordResetModalOpen, navHeight = 0 } = props

  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()

  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')

  const [showPassword, setShowPassword] = useState({ currentPassword: false, newPassword: false })

  const defaultPassResetModalErr = defaultErrors.passwordResetModalErr
  const [errors, setErrors] = useState(defaultPassResetModalErr)

  const handlePasswordResetModalClose = () => {
    setCurrentPassword('')
    setNewPassword('')
    setShowPassword({ ...showPassword, currentPassword: false, newPassword: false })
    setErrors(defaultPassResetModalErr)
    setPasswordResetModalOpen(false)
  }

  const handlePasswordReset = async () => {
    if (currentPassword === '' || newPassword === '') {
      setErrors({ ...defaultPassResetModalErr, fieldsMissingError: true })
    } else {
      setErrors(defaultPassResetModalErr)

      const data = { currentPassword, newPassword }
      await dispatch(updateUserData('reset-password', data, fireNotification, fireValidation))
        .catch(err => {
          log.error(err)
        })
    }
  }

  const fireValidation = (errorType) => {
    switch (errorType) {
      case formValidationTypes.PASSWORD_UPDATE_ERROR:
        setErrors({ ...defaultPassResetModalErr, passwordUpdateError: true })
        break
      case formValidationTypes.PASSWORD_MATCH_ERROR:
        setErrors({ ...defaultPassResetModalErr, passwordMatchError: true })
        break
      default:
        break
    }
  }

  const fireNotification = (response) => { NotificationToast(response.error, response.error ? response.message : 'Password updated successfully!'); handlePasswordResetModalClose() }

  const handleEnter = (e) => {
    if (e.key) {
      if (e.key.toUpperCase() === 'ENTER') {
        handlePasswordReset()
      }
    }
  }

  return (
    <>
      <Dialog
        classes={{ paper: classes.paper }}
        style={{ marginTop: navHeight }}
        fullWidth
        maxWidth='xs'
        open={passwordResetModalOpen}
        onClose={() => handlePasswordResetModalClose()}
      >
        <DialogTitle disableTypography>
          <Grid container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid item>
              <Typography
                variant='h4'
                style={{ textTransform: 'none', color: theme.palette.purple.darkest, fontSize: '1.3rem' }}
              >
                Reset Password
              </Typography>
            </Grid>

            <Grid item>
              <IconButton style={{ padding: '0px' }} onClick={(e) => { e.preventDefault(); handlePasswordResetModalClose() }}>
                <CloseRounded className={classes.customizedButton} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />

        <DialogContent>
          <Grid item xs={12} style={{ marginTop: '1rem' }}>
            <InputLabel>Current Password</InputLabel>
            <FormControl fullWidth margin='dense'>
              <OutlinedInput
                id='current-password'
                variant='outlined'
                size='small'
                type={showPassword.currentPassword ? 'text' : 'password'}
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                onKeyDown={handleEnter}
                inputProps={{ style: { border: 'none' } }}
                endAdornment={
                  <IconButton
                    aria-label='toggle password visibility'
                    style={{ padding: '8px' }}
                    tabIndex='-1'
                    onClick={(e) => {
                      e.preventDefault()
                      setShowPassword({ ...showPassword, currentPassword: !showPassword.currentPassword })
                    }}
                  >
                    {showPassword.currentPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} style={{ marginTop: '2rem' }}>
            <InputLabel>New Password</InputLabel>
            <FormControl fullWidth margin='dense'>
              <OutlinedInput
                id='new-password'
                variant='outlined'
                size='small'
                type={showPassword.newPassword ? 'text' : 'password'}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                onKeyDown={handleEnter}
                inputProps={{ style: { border: 'none' } }}
                endAdornment={
                  <IconButton
                    aria-label='toggle password visibility'
                    style={{ padding: '8px' }}
                    tabIndex='-1'
                    onClick={(e) => {
                      e.preventDefault()
                      setShowPassword({ ...showPassword, newPassword: !showPassword.newPassword })
                    }}
                  >
                    {showPassword.newPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                }
              />
            </FormControl>
          </Grid>

          {errors.fieldsMissingError &&
            <Grid item xs={12} style={{ marginBottom: '-1rem' }}>
              <Typography variant='caption' style={{ color: 'red' }}>* Please be sure you have provided both passwords.</Typography>
            </Grid>}

          {errors.passwordMatchError &&
            <Grid item xs={12} style={{ marginBottom: '-1rem' }}>
              <Typography variant='caption' style={{ color: 'red' }}>* Current password does not match.</Typography>
            </Grid>}

          {errors.passwordUpdateError &&
            <Grid item xs={12} style={{ marginBottom: '-1rem' }}>
              <Typography variant='caption' style={{ color: 'red' }}>* Unable to update password.</Typography>
            </Grid>}

          <Grid item xs={12} style={{ marginTop: '2rem', marginBottom: '2rem' }}>
            <Button
              // disabled
              fullWidth
              color='primary'
              variant='contained'
              onClick={(e) => {
                e.preventDefault()
                handlePasswordReset()
              }}
            >
              Reset Password
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default PasswordResetModal
