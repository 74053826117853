/* eslint no-useless-escape: off */

import React, { useMemo, useState, useCallback } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { Grid, Typography, Avatar, useMediaQuery } from '@material-ui/core'
import BlankAvatar from '../../../../../assets/blank-person.jpeg'

/* ********** The content of message ************* //
    Rendered from MessageChain.js
    - displays the individual message content
    - uses the 'user' boolean prop based to determine grid justification
*/
const MessageContent = (props) => {
  const { theme, message, dividerStamp, classes, userPath, smScreen, messageSender = '' } = props
  const { Content = '', createdAt = 0, messageUnread = 0, userID: messageUserID = '', deletedAt = 0 } = message

  const { userID: authUserID = '', profileAvatarPath: authAvatarPath = '' } = useSelector(state => state.auth)

  const upSmScreen = useMediaQuery(theme.breakpoints.up('sm'))

  const [messageContent, setMessageContent] = useState(<></>)
  const [justifyText, setJustifyText] = useState('flex-start')
  const [justifyFlex, setJustifyFlex] = useState('row')

  const formatContent = useCallback((content) => {
    const messageAlignment = authUserID === messageUserID ? 'flex-end' : 'flex-start'

    // Cesar: If the message that we get back from socket is an array, meaning from slate editor
    //        then get slate editors properties
    if (content && Array.isArray(content)) {
      // Cesar: Way this works is that for every paragraph in slate editor, we get an entry.
      //        Each entry has children and a type
      const formattedContent = content.reduce((arr, messageInfo, messageIndex) => {
        const { children = [] } = messageInfo

        // Cesar: Sort through children's paragraphs. Each children has text properties.
        const subContent = children.reduce((arr, subInfo, subIndex) => {
          const { italic = false, bold = false, underline = false, link = false } = subInfo
          let { text = '' } = subInfo

          // format italicized text
          if (italic) {
            text = (<i>{text}</i>)
          }

          // format bold text
          if (bold) {
            text = (<strong>{text}</strong>)
          }

          // format underlined text
          if (underline) {
            text = (<u>{text}</u>)
          }

          // format link text
          if (link) {
            const linkText = subInfo.text
            const path = linkText.startsWith('http://') || linkText.startsWith('https://') ? linkText : `http://${linkText}`

            text = (<a href={path} rel='noopener noreferrer' target='_blank'>{text}</a>)
          }

          text = (<React.Fragment key={`message-${messageUserID}-content-${subIndex}`}>{text}</React.Fragment>)

          arr.push(text)

          return arr
        }, [])

        if (subContent && Boolean(subContent.length)) {
          arr.push(
            <Grid
              item container direction='row'
              justifyContent={messageAlignment}
              key={`message-${messageUserID}-paragraph-${messageIndex}`}
            >
              <Grid item style={{ maxWidth: '100%' }}>
                <Typography
                  variant='body1'
                  className={classes.messageContent}
                >
                  {subContent}
                </Typography>
              </Grid>
            </Grid>
          )
        }

        return arr
      }, [])

      return formattedContent
    } else if (content && typeof content === 'string') {
      // Cesar: Else this means that the message we got back from socket is purely a string, just render
      return (
        <Grid item container direction='row' justifyContent={messageAlignment}>
          <Grid item>
            <Typography variant='body1' className={classes.messageContent}>
              {content}
            </Typography>
          </Grid>
        </Grid>
      )
    }

    return ''
  }, [authUserID, messageUserID, classes.messageContent])

  const getMessageContent = useCallback(async (content) => {
    try {
      const parsedContent = await JSON.parse(content)
      if (parsedContent) {
        setMessageContent(formatContent(parsedContent))
      }
    } catch (err) {
      console.log(err)

      // In case of error provide the message area with error message to display
      const unavailableError = 'Message Content Unavailable'
      setMessageContent(formatContent(unavailableError))
    }
  }, [formatContent])

  // Cesar: Memo is needed to only evaluate content of message once
  useMemo(() => {
    // Cesar: Function is used to properly display content of message payload
    if (Content && authUserID && messageUserID) {
      getMessageContent(Content)
    }
  }, [Content, authUserID, messageUserID, getMessageContent])

  useMemo(() => {
    if (messageUserID && authUserID) {
      setJustifyText(authUserID === messageUserID ? 'flex-end' : 'flex-start')
      setJustifyFlex(authUserID === messageUserID ? 'row' : 'row-reverse')
    }
  }, [messageUserID, authUserID])

  return (
    <>
      <Grid item container direction={justifyFlex} justifyContent={justifyText} style={{ padding: '.2em', marginBottom: smScreen ? '0.3em' : '1em' }}>
        <Grid item container direction='column' xs={upSmScreen ? 11 : null}>

          {
            Boolean(messageUnread && messageUnread > 0) && (
              <Grid container item direction='row' justifyContent={justifyText}>
                <Grid item>
                  <Typography variant='h6' className={classes.messageNew}>NEW</Typography>
                </Grid>
              </Grid>
            )
          }

          {!smScreen &&
            <Grid container item direction='row' justifyContent={justifyText}>
              <Grid item>
                <Typography variant='h6' className={classes.messageName}>{messageSender}</Typography>
              </Grid>
            </Grid>}

          <Grid container item direction='row' justifyContent={justifyText}>
            <Grid item>
              <Typography variant='caption' className={classes.messageDate}>{moment.unix(createdAt).format('h:mm a')}</Typography>
            </Grid>
          </Grid>

          {messageContent}
        </Grid>
        {upSmScreen &&
          <Grid container justifyContent='center' item xs={1}>
            <Avatar src={authUserID && messageUserID && authUserID === messageUserID ? authAvatarPath : deletedAt ? BlankAvatar : userPath} />
          </Grid>}
      </Grid>

      {/* Add in a divider timestamp if the message has occured a day after it's previous message */}
      {Boolean(dividerStamp && dividerStamp !== 0) &&
        <Grid container direction='row' justifyContent='center' className={classes.dividerContainer}>
          <Grid item className={classes.dividerBorder} />
          <Typography variant='body1' className={classes.dividerContent}>{dividerStamp}</Typography>
          <Grid item className={classes.dividerBorder} />
        </Grid>}
    </>
  )
}

export default MessageContent
