import React, { useMemo, useState } from 'react'
import { chunk } from 'lodash'
import { useSelector } from 'react-redux'
import { Grid, Typography, Button, Paper, useTheme, useMediaQuery, makeStyles } from '@material-ui/core'
import { BubbleChartRounded, Dashboard, EcoRounded } from '@material-ui/icons'
import ArtifactCard from '../../ArtifactCard'
import { artifactAbbrev, bgTypes, bgColors } from '../../../../../utils'

// Cloudfront url path
const cloudfrontPath = process.env.REACT_APP_CLOUD_FRONT_BASE
// LED Background bucket
const ledBgPrefix = process.env.REACT_APP_AWS_S3_DEFAULT_LED_BG_PREFIX

const useStyles = makeStyles(theme => ({
  colorButtons: {
    minHeight: '3em',
    minWidth: '3em',
    [theme.breakpoints.down('sm')]: {
      minHeight: '2.2em',
      minWidth: '1em'
    }
  },
  buildSection: {
    padding: '0 1em',
    [theme.breakpoints.down('sm')]: {
      padding: '0 .5em 0 0'
    }
  },
  saveCancelButton: {
    margin: '0 0 .5em 0'
  }
}))

// MUI default button background boxShadow
const muiDefaultShadow = '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'

// Builder Component for designing and saving a new LED background for the artifact card
const LEDDesignBuilder = (props) => {
  const { ledInfo = {}, setLEDInfo = () => {}, gradeNames = '' } = props
  const theme = useTheme()
  const localClasses = useStyles()
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const { fullName = '', profileAvatarPath = '' } = useSelector(state => state.auth)
  const { subjects = [] } = useSelector(state => state.profileData)

  // The currently saved value for artifact, defaults to fallback key if none provided, and additional ledInfo
  const {
    backgroundKey = 'circles-default', backgroundObj: { color = 'default', type = 'circles' },
    title = '', learnerWorkID = '', isDraft, userID = '', techNeeded = '', workType = '', completionTime = 0, coreSubjectID = ''
  } = ledInfo

  // Set the building elements using the fallback key if none has been acquired
  const [bgType, setBgType] = useState(!type ? 'circles' : type)
  const [bgColor, setBgColor] = useState(!color ? 'default' : color)

  // The local key being built
  const buildKey = useMemo(() => { return `${bgType}-${bgColor}` }, [bgType, bgColor])

  // Reset to the fallback key
  const handleFullReset = () => {
    setBgColor(bgColors.DEFAULT)
    setBgType(bgTypes.CIRCLES)
  }

  // Change the appropriate key based on the type and value of the button selected
  const handleBgChange = (type, val) => {
    const typeFunc = type === 'type' ? setBgType : setBgColor
    typeFunc(val)
  }

  // Reset to the last saved version
  const handleCancel = () => {
    if (color && type) {
      setBgType(type)
      setBgColor(color)
    }
  }

  // Save the current build to the ledInfo
  const handleSaveBackground = () => {
    const newBGObj = { type: bgType, color: bgColor }
    setLEDInfo({ ...ledInfo, backgroundKey: buildKey, backgroundObj: newBGObj })
  }

  // The button to display the color options
  const ColorButton = ({ color, value }) => {
    return (
      <Button
        variant='contained'
        onClick={() => handleBgChange('color', value)}
        className={localClasses.colorButtons}
        style={{
          backgroundColor: color,
          boxShadow: bgColor !== value ? 'none' : muiDefaultShadow,
          border: bgColor !== value ? 'none' : `${theme.palette.purple.darkest} 1px solid`
        }}
      />
    )
  }

  // Bg TYPE styles/values
  const typeButtons = [
    {
      value: bgTypes.CIRCLES,
      icon: <BubbleChartRounded />,
      label: 'Circles'
    },
    {
      value: bgTypes.SQUARES,
      icon: <Dashboard />,
      label: 'Squares'
    },
    {
      value: bgTypes.NATURE,
      icon: <EcoRounded />,
      label: 'Nature'
    }
  ]

  // Bg COLOR styles/values
  const typeColors = [
    {
      value: bgColors.DEFAULT,
      color: theme.palette.logo.pink
    },
    {
      value: bgColors.BLUE,
      color: theme.palette.logo.blue
    },
    {
      value: bgColors.ORANGE,
      color: theme.palette.logo.orange
    },
    {
      value: bgColors.GREEN,
      color: theme.palette.logo.green
    }
  ]

  // Find the selected subject's name
  const subjectObj = subjects.find(x => x.subjectID === coreSubjectID)
  // If available, set the name in the temp ledInfo, else use default
  const tempSubject = subjectObj ? subjectObj.subjectName : 'None Chosen'
  // When to disable the action (save/cancel) buttons (when no changes have been made)
  const disableActionButtons = Boolean(buildKey === backgroundKey)

  // Build an object for the preview card based ont he user's current selections
  const cardTempInfo = {
    fullName,
    title: !title ? 'None Chosen' : title,
    profileAvatarPath,
    learnerWorkID,
    isDraft,
    authorID: userID,
    techNeeded: !techNeeded ? 'None Chosen' : techNeeded,
    workType,
    completionTime,
    grades: gradeNames,
    subjectName: tempSubject,
    backgroundObj: { type: bgType, color: bgColor },
    LEDBackgroundPath: `${cloudfrontPath}/${ledBgPrefix}/${buildKey}`
  }

  return (
    <Grid container item direction='column' style={{ marginTop: '1.5em', marginBottom: '2em' }}>

      {/* Header and Reset Button */}
      <Grid item container direction='row'>
        {/* Header/Title */}
        <Grid item xs={8} container direction='column'>
          <Grid item>
            <Typography variant='h4' style={{ color: theme.palette.purple.darkest, lineHeight: 1 }}>Card Design</Typography>
          </Grid>
          <Grid item>
            <Typography variant='subtitle2' style={{ lineHeight: 1.5, color: theme.palette.grey.dark }}>Choose the background design for your artifact.</Typography>
          </Grid>
        </Grid>

        {/* Reset button */}
        <Grid item container justifyContent='flex-end' xs={4}>
          <Button variant='text' onClick={handleFullReset} style={{ color: theme.palette.purple.darkest, textTransform: 'none', fontWeight: 600 }}>Reset Default</Button>
        </Grid>
      </Grid>

      {/* Builder */}
      <Grid item container direction='row' style={{ marginTop: '2em' }}>

        {/* Background Type/Save/Cancel */}
        <Grid item xs={4} container direction='column' justifyContent='space-between' style={{ padding: smScreen ? 0 : '0 2em' }}>

          {/* Background Type buttons */}
          <Grid item container direction='column' className={localClasses.buildSection}>
            {typeButtons.map((button) => {
              const { value = bgTypes.DEFAULT, icon = '', label = 'Circles' } = button

              return (
                <Button key={`led-bg-type-${value}`} variant={bgType === value ? 'contained' : 'outlined'} color='primary' onClick={() => handleBgChange('type', value)} size='small' style={{ margin: '0 0 .5em 0' }} startIcon={icon}>
                  {label}
                </Button>
              )
            })}
          </Grid>

          {/* On small Screen, display the color buttons on the same side as the type buttons */}
          {smScreen &&
            <Grid item container direction='column' alignItems='center' className={localClasses.buildSection}>
              <Paper style={{ width: '100%' }}>
                {/* Chunk the colors into a column of 2 per row */}
                {chunk(typeColors, 2).map((subArr, i) => {
                  return (
                    <Grid key={`builder-colors-subarr-${i}`} item justifyContent='center' container direction='row'>
                      {subArr.map(tyColor => {
                        const { value = bgColors.DEFAULT, color = theme.palette.logo.pink } = tyColor
                        return (
                          <Grid key={`led-smScreen-bg-color-${value}`} item style={{ margin: '.2em' }}>
                            <ColorButton color={color} value={value} />
                          </Grid>
                        )
                      })}
                    </Grid>
                  )
                })}
              </Paper>
            </Grid>}

          {/* Save/Cancel buttons */}
          <Grid item container direction='column' className={localClasses.buildSection}>
            {[{ variant: 'contained', text: 'Save', handler: handleSaveBackground }, { variant: 'outlined', text: 'Cancel', handler: handleCancel }].map(button => {
              const { variant = 'contained', text = '', handler = () => { return console.log('Action Button Failed') } } = button
              return (
                <Button key={`action-button=${text}`} disabled={disableActionButtons} onClick={handler} variant={variant} color='primary' size='small' className={localClasses.saveCancelButton}>
                  {text}
                </Button>
              )
            })}
          </Grid>
        </Grid>

        {/* Card Display */}
        <Grid item xs={smScreen ? 8 : 4} container direction='column' alignItems='center' style={{ }}>
          <ArtifactCard
            classes={{}}
            type={artifactAbbrev.LED}
            artifactInfo={cardTempInfo}
            noSocial
          />
        </Grid>

        {/* Color buttons */}
        {!smScreen &&
          <Grid item xs={4} container direction='column' alignItems='center' style={{ padding: '0 1em' }}>
            <Paper style={{ padding: '.5em' }}>
              <Grid container direction='column'>
                {typeColors.map(tyColor => {
                  const { value = bgColors.DEFAULT, color = theme.palette.logo.pink } = tyColor

                  return (
                    <Grid key={`led-bg-color-${value}`} item style={{ margin: '.5em' }}>
                      <ColorButton color={color} value={value} />
                    </Grid>
                  )
                })}

              </Grid>
            </Paper>
          </Grid>}
      </Grid>
    </Grid>
  )
}

export default LEDDesignBuilder
