import React from 'react'

import {
  Grid, makeStyles, useTheme, Typography, Paper,
  Card, CardHeader,
  CardContent, Divider,
  List, ListItem, ListItemText, ListItemIcon
} from '@material-ui/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import Logo from '../../../assets/edFarmLogo.png'

const useStyles = makeStyles(theme => ({
  templateBody: {
    height: '11in',
    width: '8.5in'
  },
  sideMenu: {
    background: 'linear-gradient(0deg, #29356A 21.34%, rgba(41, 53, 106, 0) 87.58%)',
    padding: '1.5em'
  },
  targetList: {
    listStyle: 'none'
  },
  cardRoot: {
    padding: 8,
    height: '100%',
    '&:last-child': {
      paddingBottom: 8
    }
  }
}))

// Subject Icon
const ModifiedChalkboardIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='30'
      viewBox='0 0 111 111'
    >
      <g transform='translate(-628 -124)'>
        <circle className='a' cx='50' cy='50' r='50' transform='translate(628 124)' fill='#F3F5FB' />
        <g transform='translate(645 149)'>
          <path className='b' d='M19.5,33a2.145,2.145,0,0,0-.662.1A12.367,12.367,0,0,1,15,33.75a12.378,12.378,0,0,1-3.839-.648A2.139,2.139,0,0,0,10.5,33,10.5,10.5,0,0,0,0,43.558,4.488,4.488,0,0,0,4.5,48h21A4.488,4.488,0,0,0,30,43.558,10.5,10.5,0,0,0,19.5,33ZM15,30a9,9,0,1,0-9-9A9,9,0,0,0,15,30ZM55.5,0h-36A4.582,4.582,0,0,0,15,4.649V9a11.864,11.864,0,0,1,6,1.669V6H54V33H48V27H36v6H28.851a11.949,11.949,0,0,1,3.721,6H55.5A4.582,4.582,0,0,0,60,34.351V4.649A4.582,4.582,0,0,0,55.5,0Z' transform='translate(0.002)' fill='#29356A' />
        </g>
      </g>
    </svg>
  )
}

// Grade Icon
const ModifiedGradesIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='30'
      viewBox='0 0 111 111'
    >
      <g transform='translate(-620 -124)'>
        <circle className='a' cx='50' cy='50' r='50' transform='translate(628 124)' fill='#F3F5FB' />
        <g transform='translate(654 147)'>
          <path className='b' d='M33,9a9,9,0,1,0-9,9A9,9,0,0,0,33,9ZM21.9,22.6C16.337,19.2,7.327,18.262,2.794,18A2.594,2.594,0,0,0,0,20.544V41.432a2.578,2.578,0,0,0,2.483,2.536c4.093.215,12.374,1,18.1,3.884a1.305,1.305,0,0,0,1.92-1.113V23.677a1.247,1.247,0,0,0-.6-1.074ZM45.206,18c-4.533.257-13.543,1.193-19.1,4.6a1.271,1.271,0,0,0-.6,1.09V46.736a1.309,1.309,0,0,0,1.926,1.116c5.722-2.88,14-3.667,18.091-3.881A2.58,2.58,0,0,0,48,41.434V20.544A2.6,2.6,0,0,0,45.206,18Z' fill='#29356A' />
        </g>
      </g>
    </svg>
  )
}

const LEDtoPDFTemplate = (props) => {
  const classes = useStyles()
  const theme = useTheme()

  const { ledInfo } = props

  const {
    actContent = '', createContent = '', engageContent = '', investigateContent = '', overview = '',
    coreSubject = '', stateStandards = '', techNeeded = '', title = '', workType = '', completionTime = 0,
    grades = [], ISTEStandards = [], targets = [], authorDistrictName = '', authorFullName = '',
    authorSchoolName = '', learnerWorkTitle = '', authorAvatarImg = ''
  } = ledInfo

  // Array outlining the Individual Content Cards
  const cardInfo = [
    {
      cardTitle: 'Engage',
      cardContent: !engageContent ? '' : engageContent
    },
    {
      cardTitle: 'Investigate',
      cardContent: !investigateContent ? '' : investigateContent
    },
    {
      cardTitle: 'Create',
      cardContent: !createContent ? '' : createContent
    },
    {
      cardTitle: 'Act',
      cardContent: !actContent ? '' : actContent
    }
  ]

  return (
    <Grid container direction='column' className={classes.templateBody}>

      {/* Nav Area */}
      <Grid container item direction='row' style={{ backgroundColor: theme.palette.purple.darkest, padding: '1em 1em', height: '8%' }}>

        {/* Logo Area */}
        <Grid item container direction='row' alignItems='center' xs={3} style={{ paddingTop: '.2em' }}>
          <Grid item xs={4}>
            <img src={Logo} style={{ maxWidth: '4em' }} alt='edFarmLogo' />
          </Grid>
          <Grid item xs={8} style={{ paddingBottom: '.2em' }}>
            <Typography variant='body1' style={{ color: 'white', fontSize: '18px' }}>Ed Farm Learn</Typography>
          </Grid>
        </Grid>

        {/* Header */}
        <Grid item container direction='row' justifyContent='flex-end' alignContent='center' style={{ paddingRight: '2em' }} xs={9}>
          <Typography variant='h3' style={{ color: 'white', fontSize: '26px' }}>Learning Experience Design</Typography>
        </Grid>
      </Grid>

      {/* Content Body */}
      <Grid container item direction='row' style={{ height: '92%' }}>

        {/* Side Menu Area */}
        <Grid item xs={3} container direction='column' justifyContent='space-between' className={classes.sideMenu}>
          {/* Top Details */}
          <Grid item container direction='column'>
            <Grid item container direction='column' style={{ marginBottom: '1em' }}>
              <Typography variant='subtitle1' style={{ color: theme.palette.purple.darkest, fontWeight: 600, lineHeight: '1.2' }}>Completion Time:</Typography>
              <Typography
                variant='subtitle2'
                style={{
                  color: ledInfo && completionTime ? theme.palette.purple.darkest : theme.palette.purple.medium,
                  fontWeight: 400
                }}
              >
                {ledInfo && completionTime ? `${completionTime} weeks` : 'None Provided'}
              </Typography>
            </Grid>
            <Grid item container direction='column' style={{ marginBottom: '1em' }}>
              <Typography variant='subtitle1' style={{ color: theme.palette.purple.darkest, fontWeight: 600, lineHeight: '1.2' }}>Type of Work:</Typography>
              <Typography variant='subtitle2' style={{ color: ledInfo && workType ? theme.palette.purple.darkest : theme.palette.purple.medium, fontWeight: 400 }}>
                {ledInfo && workType ? workType === 'both' ? 'Individual & Group' : workType : ''}
              </Typography>
            </Grid>
            <Grid item container direction='column' style={{ marginBottom: '1em' }}>
              <Typography variant='subtitle1' style={{ color: theme.palette.purple.darkest, fontWeight: 600, lineHeight: '1.2' }}>
                Technology Needed:
              </Typography>
              <Typography
                variant='subtitle2'
                style={{
                  color: ledInfo && techNeeded ? theme.palette.purple.darkest : theme.palette.purple.dark,
                  fontWeight: 400,
                  fontSize: techNeeded.length > 100 ? '12px' : '14px',
                  wordBreak: 'break-word'
                }}
              >
                {ledInfo && techNeeded ? techNeeded : 'None Provided'}
              </Typography>
            </Grid>
            {ledInfo && ISTEStandards && Boolean(ISTEStandards.length) &&
              <Grid item container direction='column' style={{ marginBottom: '1em' }}>
                <Typography
                  variant='subtitle1'
                  style={{
                    color: theme.palette.purple.darkest,
                    fontWeight: 600,
                    lineHeight: '1.2',
                    fontSize: ISTEStandards.length > 2 ? '12px' : '14px'
                  }}
                >
                  ISTE Standards:
                </Typography>
                {ISTEStandards.map((standard, i) => {
                  return (
                    <Typography key={`iste-standard-${i}`} variant='subtitle2' style={{ color: theme.palette.purple.darkest, fontWeight: 400 }}>{standard}</Typography>
                  )
                })}
              </Grid>}
          </Grid>
          {/* Bottom Details */}
          <Grid item container direction='column' style={{ marginBottom: '2em' }}>
            <Grid item container direction='row' alignItems='center' style={{ marginBottom: '1em' }}>
              <ModifiedGradesIcon />
              <Typography
                variant='h4'
                style={{
                  fontWeight: 600,
                  color: ledInfo && grades && grades.length ? 'white' : theme.palette.purple.darker,
                  marginLeft: '.2em'
                }}
              >
                {ledInfo && grades && grades.length ? grades[0] : 'None Provided'}
              </Typography>
            </Grid>
            <Grid item container direction='row' alignItems='center'>
              <ModifiedChalkboardIcon />
              <Typography
                variant='h4'
                style={{
                  fontWeight: 600,
                  color: ledInfo && coreSubject ? 'white' : theme.palette.purple.darker,
                  marginLeft: '.2em'
                }}
              >
                {ledInfo && coreSubject ? coreSubject : 'None Provided'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Main Information Area */}
        <Grid item xs={9} container direction='column' style={{ paddingRight: '2em', position: 'relative' }}>

          {/* Name and Author Section */}
          <Grid item container direction='row' style={{ padding: '.5em 1em 0 1em' }}>
            {/* Name */}
            <Grid item container direction='column' xs={7} style={{ marginTop: '1em' }}>
              <Typography variant='h6' style={{ fontWeight: 400, textTransform: 'none', color: theme.palette.grey.dark, fontSize: 16 }}>Artifact Name:</Typography>
              <Typography
                variant='h3'
                style={{
                  color: ledInfo && title ? '' : theme.palette.purple.lighter,
                  fontWeight: 600,
                  fontSize: ledInfo && title ? title.length > 20 ? 14 : 24 : 18
                }}
              >
                {ledInfo && title ? title : 'None Provided'}
              </Typography>
            </Grid>

            {/* Author Card */}
            <Grid item container direction='column' xs={5} style={{ marginTop: '.5em' }}>
              <Paper elevation={0} style={{ border: `solid 1px ${theme.palette.grey.medium}`, padding: '.5em', display: 'flex', alignItems: 'center' }}>
                <Grid container direction='column' alignItems='center'>
                  <Grid item container direction='row'>
                    <Grid item container direction='column' xs={9}>
                      <Typography variant='subtitle2' style={{ color: theme.palette.grey.dark, lineHeight: 1 }}>Author:</Typography>
                      <Typography
                        variant='body1'
                        style={{ color: ledInfo && authorFullName ? '' : theme.palette.purple.lighter }}
                      >
                        {ledInfo && authorFullName ? authorFullName : 'None Provided'}
                      </Typography>
                    </Grid>
                    <Grid item container direction='column' alignItems='center' xs={3}>
                      {authorAvatarImg}
                    </Grid>
                  </Grid>
                  {ledInfo && (authorSchoolName !== '' || authorDistrictName !== '') &&
                    <Grid item container direction='column' style={{ marginTop: '.5em' }}>
                      <Typography variant='subtitle2' style={{ color: theme.palette.grey.dark, lineHeight: 1, fontSize: 12 }}>{authorSchoolName !== '' ? 'School:' : 'District:'}</Typography>
                      <Typography variant='body1' style={{ fontSize: 14 }}>{authorSchoolName !== '' ? authorSchoolName : authorDistrictName}</Typography>
                    </Grid>}
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          {/* Learner Work Section (optional) */}
          {ledInfo && learnerWorkTitle &&
            <Grid item container direction='column' style={{ padding: '0 1em' }}>
              <Grid item container style={{ marginBottom: '.2em' }}>
                <Typography variant='h6' style={{ fontWeight: 400, textTransform: 'none', color: theme.palette.grey.dark, fontSize: 14 }}>Learner Work:</Typography>
              </Grid>
              <Grid container direction='row' justifyContent='space-around' style={{ padding: '.3em 1em', backgroundColor: '#1A7FA4', borderRadius: '5px', width: '50%' }}>
                <Grid item>
                  <Typography variant='h4' style={{ fontWeight: 600, color: 'white', textTransform: 'none' }}>{ledInfo && learnerWorkTitle ? learnerWorkTitle : ''}</Typography>
                </Grid>
              </Grid>
            </Grid>}

          {/* Overview and Purpose */}
          <Grid item container direction='column' style={{ margin: '1em 0 .5em 1em' }}>
            <Grid item container>
              <Typography variant='h6' style={{ fontWeight: 600, textTransform: 'none', fontSize: 16 }}>
                Overview and Purpose:
              </Typography>
            </Grid>
            <Grid item container>
              <Paper elevation={0} style={{ width: '100%', backgroundColor: theme.palette.grey.lighter, padding: '.5em', marginRight: '2em' }}>
                <Grid container>
                  <Typography
                    variant='caption'
                    style={{
                      color: ledInfo && overview ? theme.palette.grey.dark : theme.palette.grey.medium,
                      fontSize: ledInfo && overview && overview.length > 120 ? 12 : 14,
                      lineHeight: '1.5',
                      wordBreak: 'break-word',
                      height: ledInfo && overview ? 'auto' : '6rem'
                    }}
                  >
                    {ledInfo && overview ? overview : 'None Provided'}
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          {/* Learning Targets */}
          <Grid item container direction='column' style={{ margin: '0 0 0 1em' }}>
            <Grid item container>
              <Typography variant='h6' style={{ fontWeight: 600, textTransform: 'none', fontSize: 16 }}>Learning Targets:</Typography>
            </Grid>
            <Grid item container direction='column'>
              <List dense style={{ paddingTop: '.5em' }}>
                {ledInfo && targets && Boolean(targets.length)
                  ? targets.map((target, i) => {
                    const { targetName = '' } = target
                    return (
                      <ListItem key={`learning-target-${i}`} style={{ padding: 0 }}>
                        <ListItemIcon style={{ minWidth: '1.5em', justifyContent: 'center' }}>
                          <FiberManualRecordIcon
                            style={{ color: theme.palette.pink.dark, fontSize: targets.length > 3 ? '10px' : '12px' }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              style={{ fontSize: targetName.length > 160 ? 12 : 14 }}
                            >
                              {targetName.length > 160 ? `${targetName.substring(0, 160)}...` : targetName}
                            </Typography>
                          }
                          style={{ margin: '0 0 3px 0' }}
                        />
                      </ListItem>
                    )
                  })
                  : [1, 2, 3].map(i =>
                    <ListItem key={`learning-target-${i}`} style={{ padding: 0 }}>
                      <ListItemIcon style={{ minWidth: '1.5em', justifyContent: 'center' }}>
                        <FiberManualRecordIcon style={{ fontSize: '12px', color: theme.palette.pink.dark }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={<Typography />}
                        style={{ margin: '0 0 3px 0', height: '3rem' }}
                      />
                    </ListItem>
                  )}
              </List>
            </Grid>
          </Grid>

          {/* State Standards */}
          <Grid item container direction='column' style={{ margin: '0 0 .5em 1em' }}>
            <Grid item container>
              <Typography variant='h6' style={{ fontWeight: 600, textTransform: 'none', fontSize: 16 }}>State Standards:</Typography>
            </Grid>
            <Grid item container direction='column'>
              <Typography
                style={{
                  height: ledInfo && stateStandards ? 'auto' : '2rem',
                  color: theme.palette.grey.dark
                }}
              >
                {ledInfo && stateStandards
                  ? stateStandards.length > 150
                    ? `${stateStandards.substring(0, 150)}...` : stateStandards : 'None Provided'}
              </Typography>
            </Grid>
          </Grid>

          {/* Engage/Act/Investigate/Create Cards */}
          <Grid item container direction='row' spacing={1} style={{ margin: '.5em 1em .5em 1em', width: 'auto' }}>
            {cardInfo && cardInfo.length &&
              cardInfo.map((info) => {
                const { cardTitle = '', cardContent = '' } = info
                return (
                  <Grid key={`cardInfo-${cardTitle}`} item container xs={3}>
                    <Card style={{ width: '100%' }}>
                      <CardHeader
                        title={cardTitle}
                        style={{
                          backgroundColor: theme.palette.pink.dark,
                          color: 'white',
                          textAlign: 'center',
                          padding: '10px'
                        }}
                      />
                      <CardContent style={{ backgroundColor: theme.palette.grey.lighter }} classes={{ root: classes.cardRoot }}>
                        <Grid container item direction='column'>
                          <Typography
                            variant='caption'
                            style={{
                              color: theme.palette.grey.dark,
                              height: cardContent ? 'auto' : '10rem',
                              fontSize: 12,
                              lineHeight: '1.4',
                              wordBreak: 'break-word'
                            }}
                          >
                            {cardContent ? info.cardContent : 'None Provided'}
                          </Typography>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                )
              })}
          </Grid>

          {/* Notes Section  */}
          <Grid item container direction='column' style={{ margin: '.5em 8 0 1em', paddingLeft: '1em', paddingRight: '3em', paddingBottom: '3em', position: 'absolute', bottom: 10 }}>
            <Paper elevation={0} style={{ width: '100%', border: `solid 1px ${theme.palette.grey.medium}`, padding: '.5em' }}>
              <Grid container direction='column'>
                <Grid item container direction='row' justifyContent='center' style={{ marginBottom: '.5em' }}>
                  <Typography variant='body1' style={{ textTransform: 'none', fontWeight: 600, color: theme.palette.grey.dark }}>Notes</Typography>
                </Grid>
                <Grid item container direction='column'>
                  <Divider variant='middle' style={{ marginBottom: '1em' }} />
                  <Divider variant='middle' style={{ marginBottom: '1em' }} />
                  <Divider variant='middle' style={{ marginBottom: '1em' }} />
                  <Divider variant='middle' style={{ marginBottom: '1em' }} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LEDtoPDFTemplate
