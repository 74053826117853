import React, { useRef } from 'react'
import {
  Paper, Grid, Typography, useTheme, Tooltip, IconButton
} from '@material-ui/core'

import {
  SaveRounded, CloseRounded
} from '@material-ui/icons'

import { Editor } from '@tinymce/tinymce-react'

const BuilderRubricEditor = (props) => {
  const {
    scriptSrc, editorInitDefaults, rubricForm, setRubricForm = () => {},
    handleRubricEditorClose = () => {}, handleSaveRubricChange = () => {}, classes = {},
    disableSaveRubric = false
  } = props
  const theme = useTheme()

  const profTextRef = useRef(null)
  const basicTextRef = useRef(null)
  const devTextRef = useRef(null)

  const missingReqText = Boolean(!rubricForm.proficientText.length || !rubricForm.basicText.length)
  const profWhiteSpaceCheck = Boolean(profTextRef.current && profTextRef.current.getContent().length && !profTextRef.current.getContent({ format: 'text' }).trim().length)
  const basicWhiteSpaceCheck = Boolean(basicTextRef.current && basicTextRef.current.getContent().length && !basicTextRef.current.getContent({ format: 'text' }).trim().length)
  const devWhiteSpaceCheck = Boolean(devTextRef.current && (!devTextRef.current.getContent().length || devTextRef.current.getContent({ format: 'text' }).trim().length))
  const whiteSpaceError = Boolean(profWhiteSpaceCheck || basicWhiteSpaceCheck || !devWhiteSpaceCheck)

  return (
    <Paper style={{ backgroundColor: theme.palette.grey.lightest, marginTop: '.5em', padding: '1em' }}>
      <Grid item container direction='column' style={{}}>
        <Grid item container direction='row' alignItems='center' style={{ marginBottom: '1em' }}>
          <Grid item container direction='column' xs={8}>
            <Typography variant='subtitle2' style={{ color: theme.palette.grey.dark, fontWeight: 600 }}>New Row:</Typography>
            <Typography variant='caption' style={{ color: theme.palette.grey.dark }}>Use the inputs below to provide the necessary requirements for a user to fufill each level of the rubric.</Typography>
          </Grid>
          <Grid item container direction='row' justifyContent='flex-end' style={{ paddingRight: '.5em' }} xs={4}>
            <Tooltip arrow placement='top' title='Cancel' enterDelay={750} enterNextDelay={750}>
              <IconButton size='small' style={{ backgroundColor: theme.palette.grey.medium, padding: 0, height: 28, width: 28 }} onClick={() => handleRubricEditorClose()}>
                <CloseRounded fontSize='inherit' style={{ color: theme.palette.grey.lightest }} />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement='top' title={whiteSpaceError ? 'Values cannot contain only white space characters.' : missingReqText ? 'Must have both Proficient and Basic levels to save.' : 'Save'} enterDelay={750} enterNextDelay={750}>
              <span>
                <IconButton
                  size='small'
                  className={classes.rubricEditorSaveButton}
                  onClick={() => handleSaveRubricChange(rubricForm)}
                  disabled={disableSaveRubric || whiteSpaceError}
                >
                  <SaveRounded fontSize='inherit' />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item container direction='row' spacing={4}>
          <Grid item xs={4} container direction='column'>
            <Typography gutterBottom variant='caption' style={{ fontWeight: 600, color: theme.palette.grey.dark, marginLeft: '5px' }}>Proficient Level</Typography>
            <Editor
              {...scriptSrc}
              onInit={(evt, editor) => { profTextRef.current = editor }}
              value={rubricForm?.proficientText}
              init={{
                height: 200,
                ...editorInitDefaults
              }}
              onEditorChange={(value, editor) => { setRubricForm({ ...rubricForm, proficientText: value }) }}
            />
          </Grid>
          <Grid item xs={4} container direction='column'>
            <Typography gutterBottom variant='caption' style={{ fontWeight: 600, color: theme.palette.grey.dark, marginLeft: '5px' }}>Basic Level</Typography>
            <Editor
              {...scriptSrc}
              onInit={(evt, editor) => { basicTextRef.current = editor }}
              value={rubricForm?.basicText}
              init={{
                height: 200,
                ...editorInitDefaults
              }}
              onEditorChange={(value, editor) => { setRubricForm({ ...rubricForm, basicText: value }) }}
            />
          </Grid>
          <Grid item xs={4} container direction='column'>
            <Typography gutterBottom variant='caption' style={{ fontWeight: 600, color: theme.palette.grey.dark, marginLeft: '5px' }}>Developing Level</Typography>
            <Editor
              {...scriptSrc}
              onInit={(evt, editor) => { devTextRef.current = editor }}
              value={rubricForm?.developingText}
              init={{
                height: 200,
                ...editorInitDefaults
              }}
              onEditorChange={(value, editor) => { setRubricForm({ ...rubricForm, developingText: value }) }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default BuilderRubricEditor
