import { trackPromise } from 'react-promise-tracker'
import { parseResponse } from '../../lib'
import {
  GET_TECH_LIST, RESET_TECH_LIST,
  DATA_REQUEST, DATA_SUCCESS, DATA_ERROR
} from '../types'

import log from 'loglevel'

// UI Tech Debt: Removed api variable that wasn't being used anywhere

const api = process.env.REACT_APP_API_URL

export const getTechList = () => {
  return async (dispatch, getState) => {
    const { token } = getState().auth

    dispatch({ type: DATA_REQUEST, payload: { type: GET_TECH_LIST } })

    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }

    const response = await trackPromise(window.fetch(`${api}/v1/tech`, options))
    const parsedResponse = parseResponse(response, dispatch)
    if (!parsedResponse) { return false }

    if (parsedResponse.error) {
      log.info(response.status)

      dispatch({ type: DATA_ERROR, payload: { type: GET_TECH_LIST } })
    } else {
      const { data } = await response.json()

      dispatch({ type: DATA_SUCCESS, payload: { type: GET_TECH_LIST } })

      dispatch({ type: GET_TECH_LIST, payload: data })
    }
  }
}

export const updateUserTech = (techInfo, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { userID, token } = getState().auth

      const { currentTechToAdd = [], newTechToAdd = [], techToDelete = [] } = techInfo

      // currentTechToAdd: array of techIDs
      // newTechToAdd: array of names
      // techToDelete: array of techIDs to do delete
      const body = { currentTechToAdd, newTechToAdd, techToDelete }
      const totalAdd = currentTechToAdd.length + newTechToAdd.length

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
      }

      // The user can only add 5 skills at a time.
      if (totalAdd <= 10) {
        const response = await trackPromise(window.fetch(`${api}/v1/user/${userID}/tech`, options))
        const parsedResponse = parseResponse(response, dispatch)
        if (!parsedResponse) { return false }

        if (parsedResponse.error) {
          log.info(response.status)
          fireFailure()
        } else {
          fireSuccess()
        }
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export function resetTechList () {
  return async function (dispatch) {
    dispatch({ type: RESET_TECH_LIST })
  }
}
