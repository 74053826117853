import React from 'react'
import { useSelector } from 'react-redux'
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography, IconButton, Button, useTheme
} from '@material-ui/core'
import {
  CloseRounded
} from '@material-ui/icons'
import PublicArtifactContent from '../../artifacts/PublicArtifactContent'
import { artifactTypes } from '../../../../utils'

const MCEArtifactPreviewModal = (props) => {
  const {
    classes = {}, openLEDModal = false, setOpenLEDModal = () => {}, navHeight, currentStyle = {},
    formattedInfo = {}, setFormattedInfo = () => {}, artDetails = {}, setArtDetails = () => {}
  } = props
  const theme = useTheme()
  const { profileID } = useSelector(state => state.auth)

  return (
    <Dialog
      open={openLEDModal}
      fullWidth
      maxWidth='md'
      onClose={() => { setOpenLEDModal(false) }}
      style={{ zIndex: 11, marginTop: navHeight, overflowY: 'unset' }}
    >
      <DialogTitle disableTypography>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography
              variant='h4'
              gutterBottom
              style={{ textTransform: 'none', color: theme.palette.purple.darkest, fontSize: '1.3rem' }}
            >
              Artifact Preview
            </Typography>
          </Grid>
          <Grid item>
            <IconButton style={{ padding: '0px' }} onClick={(e) => { e.preventDefault(); setOpenLEDModal(false) }}>
              <CloseRounded className={classes.customizedButton} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ paddingTop: 0, marginBottom: '2em' }}>
        <Grid container item direction='column'>
          <PublicArtifactContent
            classes={classes}
            artifactType={artifactTypes.LED}
            styles={currentStyle}
            formattedInfo={formattedInfo}
            setFormattedInfo={setFormattedInfo}
            publicArtifactCheck
            artDetails={artDetails}
            setArtDetails={setArtDetails}
            creatorID={profileID}
            isForMCE
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item container direction='row' justifyContent='flex-end'>
          <Button
            variant='text'
            style={{ color: theme.palette.purple.darkest, fontWeight: 600, textTransform: 'none', marginRight: '1em' }}
            onClick={(e) => { e.preventDefault(); setOpenLEDModal(false) }}
          >
            Exit
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default MCEArtifactPreviewModal
