import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { registerUser, loginGoogle } from '../../../redux/actions'
import clsx from 'clsx'
import log from '../../../utils/logger'
import { BaseToast } from '../tools'

import {
  Grid, TextField, InputLabel, Button, Typography, Checkbox, OutlinedInput, FormControl,
  IconButton, Link
} from '@material-ui/core'

import { Visibility, VisibilityOff } from '@material-ui/icons'

import googleIcon from '../../../assets/google-logo.png'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import { defaultErrors } from '../../../utils'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  border: {
    borderBottom: '1px solid lightgray',
    width: '100%'
  },
  text: {
    marginRight: '1rem',
    marginLeft: '1rem',
    fontWeight: '400'
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#e3e3e3',
    '$.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)'
    }
  },
  checkedIcon: {
    backgroundColor: '#293669',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#293669'
    }
  }
}))

const RegisterForm = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()

  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [agreementChecked, setAgreementChecked] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const defaultRegisterFormErr = defaultErrors.registerFormErr

  const [errors, setErrors] = useState(defaultRegisterFormErr)

  const handleCheck = () => {
    setAgreementChecked(!agreementChecked)
  }

  const errorCheck = () => {
    const regex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/
    if (fullName === '' || email === '' || password === '') {
      setErrors({
        ...defaultRegisterFormErr,
        fieldsMissingError: true
      })
    } else if (!agreementChecked) {
      setErrors({
        ...defaultRegisterFormErr,
        notCheckedError: true
      })
    } else if (regex.test(fullName)) {
      setErrors({
        ...defaultRegisterFormErr,
        specialCharacters: true
      })
    } else {
      setErrors(defaultRegisterFormErr)
      handleRegister()
    }
    return false
  }
  const handleRegister = () => {
    const registerInfo = { fullName, email, password }
    dispatch(registerUser(registerInfo, fireNotification))
  }

  const handleGoogleLogin = async () => {
    await dispatch(loginGoogle())
      .catch(err => {
        log.error(err)
      })
  }

  const fireNotification = (response) => {
    // TODO handle success/response here
    log.debug('request was successful. user should be redirected shortly')
    if (response.error) {
      if (response.code === 409) {
        BaseToast(true,
          'This email is already associated with an Ed Farm Learn account.',
          null)
      } else {
        BaseToast(true,
          response.message,
          null)
      }
    }
  }

  const handleFormEnter = (e) => {
    if (e.key) {
      if (e.key.toUpperCase() === 'ENTER') {
        errorCheck()
      }
    }
  }

  return (
    <>
      <Grid item container direction='row' alignItems='center' justifyContent='center' className={classes.loginFormContainer} style={{ marginTop: '10vh' }}>
        <Grid item xs={8}>
          <Grid item container direction='row' justifyContent='center' style={{ marginTop: '1em' }}>
            <Typography variant='h2' style={{ textAlign: 'center' }}>Sign Up to Ed Farm Learn</Typography>
          </Grid>
        </Grid>

        <Grid item xs={8} style={{ marginTop: '2rem' }}>
          <Grid item container direction='row'>
            <Button
              variant='outlined'
              style={{ borderColor: '#29356a', color: '#29356a', fontWeight: '600' }}
              fullWidth
              startIcon={<img src={googleIcon} alt='google-icon' />}
              onClick={() => handleGoogleLogin()}
            >
              Sign Up or Log In with Google
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={8} style={{ marginTop: '2rem' }}>
          <Grid item className={classes.container}>
            <Grid item className={classes.border} />
            <Typography variant='h5' className={classes.text}>Or</Typography>
            <Grid item className={classes.border} />
          </Grid>
        </Grid>

        <Grid item xs={8} style={{ marginTop: '2rem' }}>
          <InputLabel>Full Name</InputLabel>
          <TextField
            variant='outlined'
            size='small'
            fullWidth
            type='text'
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            onKeyDown={handleFormEnter}
          />
        </Grid>

        <Grid item xs={8} style={{ marginTop: '1.5rem' }}>
          <InputLabel>Email</InputLabel>
          <TextField
            variant='outlined'
            size='small'
            fullWidth
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleFormEnter}
          />
        </Grid>

        <Grid item xs={8} style={{ marginTop: '1.5rem' }}>
          <InputLabel>Password</InputLabel>
          <FormControl margin='dense' fullWidth>
            <OutlinedInput
              size='small'
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              inputProps={{ style: { border: 'none' } }}
              endAdornment={
                <IconButton
                  aria-label='toggle password visibility'
                  style={{ padding: '9px' }}
                  tabIndex='-1'
                  onClick={(e) => {
                    e.preventDefault()
                    setShowPassword(!showPassword)
                  }}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              }
            />
          </FormControl>
        </Grid>

        <Grid item container direction='column' xs={8} style={{ marginTop: '1rem' }}>
          <Grid item container direction='row' alignItems='center'>
            <Grid item container xs={12}>
              <Typography
                variant='caption'
                style={{ fontSize: '14px', color: theme.palette.black }}
              >
                <Checkbox size='small' style={{ paddingLeft: '0px' }} disableRipple icon={<span className={classes.icon} />} checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />} checked={agreementChecked} onChange={handleCheck} />
                By creating an account you agree to the terms of use and our <Link href='http://edfarm.org/privacy-policy/' target='_blank' rel='noopener'>privacy policy</Link>
              </Typography>
            </Grid>
          </Grid>
          {errors.notCheckedError &&
            <Typography variant='caption' style={{ color: 'red' }}>* You must agree to the terms of use before creating an account.</Typography>}
          {errors.fieldsMissingError &&
            <Typography variant='caption' style={{ color: 'red' }}>* Please be sure you have provided all the necessary information.</Typography>}
          {errors.specialCharacters &&
            <Typography variant='caption' style={{ color: 'red' }}>* Name cannot contain special characters.</Typography>}
        </Grid>

        <Grid item xs={8} style={{ marginTop: '1rem' }}>
          <Button
            fullWidth
            color='primary'
            variant='contained'
            onClick={(e) => {
              e.preventDefault()
              errorCheck()
            }}
          >
            Sign Up
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default RegisterForm
