import {
  GET_ASSESSOR_ASSIGNMENTS, LOGOUT
} from '../types'

const initialState = {
  currentAssignments: []
}

const mceAssessorReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSESSOR_ASSIGNMENTS: return {
      ...state,
      currentAssignments: action.payload
    }
    case LOGOUT: return {
      ...initialState
    }
    default: return state
  }
}

export default mceAssessorReducer
