import React, { useState } from 'react'
import moment from 'moment'
import EFRLogo from '../../../../assets/EFlogoAvatar.png'
import { GradesCheck } from '../../../ui/tools'
import { artifactTypes, efrSubTypes, gradeCategoriesFormat, efrSubTypesFormat, getFormatStatus } from '../../../../utils'
import EFRDefaultImage from '../../../../assets/EFRDefaultPhoto.png'

import {
  Grid, CardHeader, Tooltip, Avatar, Typography, IconButton, CardContent,
  useTheme, withStyles, Checkbox
} from '@material-ui/core'
import {
  Edit as EditIcon, BookmarkBorderRounded, TurnedInRounded, ListAlt, AccessTime, AttachFile,
  CalendarTodayRounded, AddCircle, BuildRounded, FolderSharedRounded, PublicRounded,
  PeopleRounded, ListAltRounded, RemoveCircleOutline
} from '@material-ui/icons'

// Icons and labels for the status banners, based on submission Status
const publishStatusBanners = {
  DRAFT: {
    icon: BuildRounded,
    name: 'Draft'
  },
  PRIVATE: {
    icon: FolderSharedRounded,
    name: 'Private'
  },
  PUBLIC: {
    icon: PublicRounded,
    name: 'Public'
  }
}

const CustomCheckbox = withStyles((theme) => ({
  root: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: theme.spacing(0.5)
  },
  checked: {
    color: '#29356a'
  },
  indeterminate: {
    color: 'rgba(0, 0, 0, 0.54)'
  }
}))((props) => <Checkbox color='default' {...props} />)

const ModifiedGradesIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='50'
      height='50'
      viewBox='0 0 111 111'
    >
      <g transform='translate(-620 -124)'>
        <circle className='a' cx='50' cy='50' r='50' transform='translate(628 124)' fill='#F3F5FB' />
        <g transform='translate(654 147)'>
          <path className='b' d='M33,9a9,9,0,1,0-9,9A9,9,0,0,0,33,9ZM21.9,22.6C16.337,19.2,7.327,18.262,2.794,18A2.594,2.594,0,0,0,0,20.544V41.432a2.578,2.578,0,0,0,2.483,2.536c4.093.215,12.374,1,18.1,3.884a1.305,1.305,0,0,0,1.92-1.113V23.677a1.247,1.247,0,0,0-.6-1.074ZM45.206,18c-4.533.257-13.543,1.193-19.1,4.6a1.271,1.271,0,0,0-.6,1.09V46.736a1.309,1.309,0,0,0,1.926,1.116c5.722-2.88,14-3.667,18.091-3.881A2.58,2.58,0,0,0,48,41.434V20.544A2.6,2.6,0,0,0,45.206,18Z' fill='#29356A' />
        </g>
      </g>
    </svg>
  )
}

const ModifiedChalkboardIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='50'
      height='50'
      viewBox='0 0 111 111'
    >
      <g transform='translate(-628 -124)'>
        <circle className='a' cx='50' cy='50' r='50' transform='translate(628 124)' fill='#F3F5FB' />
        <g transform='translate(645 149)'>
          <path className='b' d='M19.5,33a2.145,2.145,0,0,0-.662.1A12.367,12.367,0,0,1,15,33.75a12.378,12.378,0,0,1-3.839-.648A2.139,2.139,0,0,0,10.5,33,10.5,10.5,0,0,0,0,43.558,4.488,4.488,0,0,0,4.5,48h21A4.488,4.488,0,0,0,30,43.558,10.5,10.5,0,0,0,19.5,33ZM15,30a9,9,0,1,0-9-9A9,9,0,0,0,15,30ZM55.5,0h-36A4.582,4.582,0,0,0,15,4.649V9a11.864,11.864,0,0,1,6,1.669V6H54V33H48V27H36v6H28.851a11.949,11.949,0,0,1,3.721,6H55.5A4.582,4.582,0,0,0,60,34.351V4.649A4.582,4.582,0,0,0,55.5,0Z' transform='translate(0.002)' fill='#29356A' />
        </g>
      </g>
    </svg>
  )
}

const SolidChalkboardModifiedIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='26' height='16' viewBox='0 0 68.782 55.026'>
      <path data-name='chalkboard-solid' d='M10.317,6.878H58.465v37.83h6.878V4.3a4.3,4.3,0,0,0-4.3-4.3H7.738a4.3,4.3,0,0,0-4.3,4.3v40.41h6.878ZM67.063,48.148H51.587V41.269H30.952v6.878H1.72A1.719,1.719,0,0,0,0,49.867v3.439a1.719,1.719,0,0,0,1.72,1.72H67.063a1.719,1.719,0,0,0,1.72-1.72V49.867A1.719,1.719,0,0,0,67.063,48.148Z' fill='#66666e' />
    </svg>
  )
}
const DefaultCardView = (props) => {
  const {
    artifactInfo, type, gradesArray = [], handleSaveArtifact = () => {},
    disableAllButtons = false, classes, statusCheck = false, onArtifactEdit = () => {}, canEditArtifact, editMode = false, artifactSaved = false,
    miniCard = false, isSelected = false, handleSelection = () => {}, linkedLearnerWorkID = false, handleArtifactNotPublicCheck = () => {},
    getTypeStyle = () => {}
  } = props

  const theme = useTheme()
  const [now] = useState(moment().unix())

  const EFRCheck = type && type === 'EFR'

  const {
    fullName = '', subjectName = '', grades = '',
    isDraft = 0, artifactID = ''
  } = artifactInfo

  // Formats the start and end time of an EFR event subtype
  const getEventTimeFormat = (startTime, endTime) => {
    if (startTime && endTime && startTime > 0 && endTime > 0) {
      return `${moment(startTime * 1000).format('hh:mm A')} - ${moment(endTime * 1000).format('hh:mm A')}`
    } else {
      return 'N/A'
    }
  }

  // Return the readble version of the artifact type - if EFR and currently featured, adds 'Featured'
  const getAbbrevTranslation = (artType) => {
    if (artType !== 'EFR') {
      return artifactTypes[artType]
    } else {
      if (artifactInfo) {
        const { featuredResourceID, activeStart } = artifactInfo
        const currentTime = moment().unix()
        if (featuredResourceID && activeStart && activeStart <= currentTime) {
          return `Featured ${artifactTypes[artType]}`
        } else {
          return artifactTypes[artType]
        }
      }
    }
  }

  // Fetch the correct banner/subtitle for the card
  const getSubtitleSection = (info = {}) => {
    const { isPrivate, isDraft, submissionStatus } = info

    if (editMode) {
      // If artifact is or was submitted, display the status banner of the artifact
      if (statusCheck) {
        if (submissionStatus && !isDraft && !isPrivate) {
          // If the artifact if for submission fetch it's current status

          return (
            getFormatStatus(!submissionStatus ? null : submissionStatus, theme.palette.grey.light)
          )
        }
      }

      // Other wise display its published status
      const pubStatDetails = !isDraft ? isPrivate ? publishStatusBanners.PRIVATE : publishStatusBanners.PUBLIC : publishStatusBanners.DRAFT
      const { icon: BannerIcon, name } = pubStatDetails

      return (
        <>
          <Grid item container direction='row' alignItems='center'>
            <BannerIcon style={{ fontSize: '16px', color: theme.palette.grey.light, marginRight: '.2em' }} />
            <Typography variant='h6' style={{ color: theme.palette.grey.light, textTransform: 'none', fontWeight: '400' }}>{name}</Typography>
          </Grid>
        </>
      )
    }

    return ''
  }

  // Decide the title section of the card based on if rendered in the 'mini' card or not
  const getCardTitle = (cardType) => {
    // card type refers to the miniCard boolean. If we are not viewing the mini card, then display the title as usual
    if (!cardType) {
      return (
        <Grid
          item
          container
          justifyContent='space-between'
          alignItems={EFRCheck ? 'center' : 'flex-start'}
          wrap='nowrap'
          className={classes.fixSpacing}
        >
          <Grid item xs={10} container justifyContent='flex-start' style={{ paddingtop: 0, paddingBottom: 0 }}>
            <Tooltip
              placement='top'
              title={
                artifactInfo.title && artifactInfo.title.length > 25 ? (
                  <Typography>
                    {artifactInfo.title}
                  </Typography>) : ''
              }
            >
              <Typography variant='h4' style={{ color: 'white', textTransform: 'none', textAlign: 'left', wordBreak: 'break-word' }}>
                {artifactInfo.title && artifactInfo.title.length > 25 ? `${artifactInfo.title.substring(0, 25)}...` : artifactInfo.title}
              </Typography>
            </Tooltip>
          </Grid>

          <Grid item style={{ paddingtop: 0, paddingRight: 0 }}>
            {
              canEditArtifact && editMode && (
                <IconButton
                  className={classes.editBtn}
                  onClick={
                    (e) => { e.preventDefault(); onArtifactEdit(artifactInfo.artifactID, artifactTypes[type]) }
                  }
                >
                  <EditIcon style={{ height: '100%', width: '100%' }} />
                </IconButton>
              )
            }
            {EFRCheck && !editMode &&
              <CustomCheckbox
                onClick={(e) => { if (artifactInfo && artifactInfo.artifactID) { handleSaveArtifact(e, artifactInfo) } }}
                icon={<BookmarkBorderRounded style={{ fontSize: '20px', cursor: 'pointer', color: 'white' }} />}
                checkedIcon={<TurnedInRounded style={{ fontSize: '20px', cursor: 'pointer', color: 'white' }} />}
                checked={Boolean(artifactSaved && !disableAllButtons)}
                disabled={Boolean(disableAllButtons)}
              />}
          </Grid>
        </Grid>
      )
    }

    // Otherwise render the selection icon and title without the user information
    return (
      <Grid item container justifyContent='space-between' wrap='nowrap'>
        <Grid item xs={10} container justifyContent='flex-start' style={{ paddingtop: 0, paddingBottom: 0 }}>
          <Tooltip title={artifactInfo.title && artifactInfo.title.length > 20 ? artifactInfo.title : ''}>
            <Typography variant='h5' style={{ color: 'white', textTransform: 'none', textAlign: 'left' }}>
              {artifactInfo.title && artifactInfo.title.length > 20 ? `${artifactInfo.title.substring(0, 20)}...` : artifactInfo.title}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid>
          {isSelected
            ? (
              <IconButton onClick={(e) => handleSelection(e, artifactID, linkedLearnerWorkID)} style={{ padding: 0 }}>
                <RemoveCircleOutline style={{ color: '#FFFFFF', fontSize: '32px' }} />
              </IconButton>
            )
            : (
              <IconButton onClick={(e) => handleArtifactNotPublicCheck(e, artifactID, linkedLearnerWorkID)} style={{ padding: 0 }}>
                <AddCircle style={{ color: '#FFFFFF', fontSize: '32px' }} />
              </IconButton>
            )}
        </Grid>
      </Grid>
    )
  }

  // Fetch the relevant information display details based on the artifact type
  const getArtifactSpecifics = (type) => {
    switch (type) {
      case 'LW': {
        return (
          <>
            <Grid item container direction='row' alignItems='center'>
              <ListAlt style={{ fontSize: '22px', color: theme.palette.grey.dark, marginRight: '.3em' }} />
              <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>Type of Work</Typography>
            </Grid>

            <Grid
              item
              container
              style={{
                marginTop: '.1em',
                marginBottom: '1em'
              }}
            >
              <Typography variant='h5' style={{ overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: 2, lineClamp: 2, textOverflow: 'ellipsis', WebkitBoxOrient: 'vertical', wordBreak: 'break-word', color: theme.palette.black, textTransform: 'none' }}>
                {artifactInfo.workType}
              </Typography>
            </Grid>
            <Grid item container direction='row' alignItems='center' style={{ marginBottom: '1em' }}>
              <AttachFile style={{ fontSize: '18px', transform: 'rotate(45deg)', color: theme.palette.grey.dark, marginRight: '.3em' }} />
              <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>{`${artifactInfo.attachmentCount === 0 ? 'No' : artifactInfo.attachmentCount} attachment(s)`}</Typography>
            </Grid>

            {/* Do not display attached LED's on mini card */}
            {!miniCard &&
              <>
                <Grid item container direction='row' alignItems='center'>
                  <SolidChalkboardModifiedIcon />
                  <Typography variant='h5' style={{ color: theme.palette.grey.dark, textTransform: 'none', marginLeft: '.5em' }}>Learning Experience Designs</Typography>
                </Grid>
                <Grid item container direction='column' alignContent='flex-start' style={{ marginTop: '.5em' }}>
                  {artifactInfo.ledTitles && artifactInfo.ledTitles.length > 0
                    ? (
                      artifactInfo.ledTitles.slice(0, 2).map((attachment, i) => {
                        return (
                          <Grid
                            item
                            container
                            direction='row'
                            justifyContent='flex-start'
                            key={`led-attachment-${i}-${artifactInfo.artifactID}`}
                          >
                            <Typography gutterBottom variant='h5' style={{ overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: 1, lineClamp: 1, textOverflow: 'ellipsis', WebkitBoxOrient: 'vertical', wordBreak: 'break-word', color: theme.palette.black, textTransform: 'none', lineHeight: 1 }}>
                              {attachment}
                            </Typography>
                          </Grid>
                        )
                      })
                    )
                    : (
                      <Typography>N/A</Typography>
                    )}
                </Grid>
                {artifactInfo.ledTitles && artifactInfo.ledTitles.length > 2 &&
                  <Grid item container justifyContent='flex-start'>
                    <Typography gutterBottom variant='caption' style={{ color: theme.palette.grey.dark }}>{`+${artifactInfo.ledTitles.slice(2, artifactInfo.ledTitles.length).length} More`}</Typography>
                  </Grid>}
              </>}

          </>
        )
      }
      case 'PRO': {
        return (
          <>
            <Grid item container direction='row' alignItems='center' style={{ marginTop: '1em' }}>
              <AccessTime style={{ fontSize: '24px', color: theme.palette.grey.dark, marginRight: '.3em' }} />
              <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>Completion Time</Typography>
            </Grid>

            <Grid item container style={{ marginTop: '.1em', marginBottom: '1em' }}>
              <Typography variant='h5' style={{ color: theme.palette.black, textTransform: 'none' }}>
                {`${artifactInfo.completionTime} week(s)`}
              </Typography>
            </Grid>
            <Grid item container direction='row' alignItems='center'>
              <SolidChalkboardModifiedIcon />
              <Typography variant='h5' style={{ color: theme.palette.grey.dark, textTransform: 'none', marginLeft: '.5em' }}>Learning Experience Designs</Typography>
            </Grid>

            <Grid item container direction='column' alignContent='flex-start' style={{ marginTop: '.5em' }}>
              {artifactInfo.ledAttachments
                ? (
                  artifactInfo.ledAttachments.slice(0, 2).map((attachment, i) => {
                    return (
                      <Grid item container direction='row' justifyContent='flex-start' key={`led-attachment-${i}-${artifactInfo.artifactID}`}>
                        <Typography gutterBottom variant='h5' style={{ overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: 2, lineClamp: 2, textOverflow: 'ellipsis', WebkitBoxOrient: 'vertical', wordBreak: 'break-word', color: theme.palette.black, textTransform: 'none' }}>
                          {attachment}
                        </Typography>
                      </Grid>
                    )
                  })
                )
                : (
                  <Typography>N/A</Typography>
                )}
            </Grid>
            {artifactInfo.ledAttachments && artifactInfo.ledAttachments.length > 2 &&
              <Grid item container justifyContent='flex-start'>
                <Typography gutterBottom variant='caption' style={{ color: theme.palette.grey.dark }}>{`+${artifactInfo.ledAttachments.slice(2, artifactInfo.ledAttachments.length).length} More`}</Typography>
              </Grid>}
          </>
        )
      }
      case 'EFR': {
        return (
          <>
            <Grid container item direction='column'>
              <Grid item xs={12} container style={{ width: '100%' }}>
                {artifactInfo.bannerImagePath
                  ? (
                    <div style={{ backgroundImage: `url(${artifactInfo.bannerImagePath})` }} className={classes.bannerImageStyle} />
                  )
                  : (
                    <div style={{ backgroundImage: `url(${EFRDefaultImage})` }} className={classes.bannerImageStyle} />
                  )}
              </Grid>
              {artifactInfo.resourceType && artifactInfo.resourceType === efrSubTypes.EFR_EVENT &&
                <Grid item container direction='column' style={{ marginTop: '1em' }}>
                  <Grid item container direction='row' alignItems='center'>
                    <CalendarTodayRounded style={{ fontSize: '18px', color: theme.palette.grey.dark, marginRight: '.3em' }} />
                    <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>Event Date</Typography>
                  </Grid>
                  <Grid item container direction='column' alignItems='flex-start' style={{ marginTop: '.5em' }}>
                    <Typography variant='body1' style={{ fontWeight: 600 }}>{artifactInfo.startDate && artifactInfo.startDate > 0 ? moment(artifactInfo.startDate * 1000).format('MMM. Do, YYYY') : 'N/A'}</Typography>
                    <Typography variant='body1' style={{ fontWeight: 400 }}>{getEventTimeFormat(artifactInfo.startDate, artifactInfo.endDate)}</Typography>
                  </Grid>
                </Grid>}
              <Grid
                item
                container
                justifyContent='center'
                style={{ marginTop: '1em' }}
              >
                {artifactInfo.description && artifactInfo.description.length > 150
                  ? (
                    <Typography variant='h6' style={{ textTransform: 'none', fontWeight: 400, wordBreak: 'break-word' }}>{`${artifactInfo.description.substring(0, 125)}...`}</Typography>
                  )
                  : (
                    <Typography variant='h6' style={{ textTransform: 'none', fontWeight: 400, wordBreak: 'break-word' }}>{artifactInfo.description}</Typography>
                  )}
              </Grid>
              <Grid item container direction='column' style={{ marginTop: '1em' }}>
                <Grid item container direction='row' alignItems='center'>
                  <PeopleRounded style={{ fontSize: '18px', color: theme.palette.grey.dark, marginRight: '.3em' }} />
                  <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>Audience</Typography>
                </Grid>
                <Grid item container direction='column' alignItems='flex-start'>
                  <Typography variant='body1' style={{ fontWeight: 600 }}>{artifactInfo.resourceAudience ? gradeCategoriesFormat[artifactInfo.resourceAudience] : 'No Information Available'}</Typography>
                </Grid>
              </Grid>
              <Grid item container direction='column' style={{ marginTop: '.5em' }}>
                <Grid item container direction='row' alignItems='center'>
                  <ListAltRounded style={{ fontSize: '18px', color: theme.palette.grey.dark, marginRight: '.3em' }} />
                  <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>Type of Resource</Typography>
                </Grid>
                <Grid item container direction='column' alignItems='flex-start'>
                  <Typography variant='body1' style={{ fontWeight: 600 }}>{artifactInfo.resourceType ? efrSubTypesFormat[artifactInfo.resourceType] : 'No Information Available'}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
        )
      }
      default: {
        return (
          <Grid item container direction='row' alignItems='center'>
            <Typography variant='h5' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>Artifact Information Unavailable.</Typography>
          </Grid>
        )
      }
    }
  }

  return (
    <>
      <CardHeader
        avatar={
          <>
            {!miniCard &&
              <Grid item container alignItems='flex-start'>
                <Avatar src={type !== 'EFR' ? `${artifactInfo.profileAvatarPath}?${now}` : EFRLogo} style={{ backgroundColor: !artifactInfo.profileAvatarPath && type !== 'EFR' ? '#bdbdbd' : 'white' }} />
              </Grid>}
          </>
        }
        title={
          getCardTitle(miniCard)
        }
        subheader={
          <Grid
            item
            container
            alignContent='flex-start'
            alignItems='flex-start'
            direction='row'
            style={{ display: EFRCheck ? 'none' : '', marginBottom: '0em', marginRight: '0em' }}
          >
            <Typography
              variant='h6'
              style={{
                color: 'white',
                textTransform: 'none',
                textAlign: 'start',
                fontWeight: '400',
                display: EFRCheck ? 'none' : ''
              }}
            >
              {EFRCheck || miniCard ? '' : fullName}
            </Typography>

            {getSubtitleSection(artifactInfo)}
          </Grid>
        }
        style={{
          backgroundColor: `${getTypeStyle(type)}${isDraft ? '97' : ''}`,
          alignItems: EFRCheck ? 'center' : 'flex-start'
        }}
        classes={{
          subheader: EFRCheck ? classes.efrSubHeader : classes.regularSubHeader
        }}
      />

      <Grid container direction='row' justifyContent='center' style={{ backgroundColor: theme.palette.purple.lightest, padding: '.2em 0' }}>
        <Grid item>
          <Typography
            variant='body1'
            style={{ color: isDraft ? theme.palette.purple.dark : theme.palette.purple.darkest, fontWeight: '600', textTransform: 'uppercase', fontSize: '12px', letterSpacing: '0em' }}
          >
            {getAbbrevTranslation(type)}
          </Typography>
        </Grid>
      </Grid>

      <CardContent>
        <Grid container direction='column'>
          <Grid item container direction='row' justifyContent='center' style={{ margin: '.5em 0' }}>
            {type !== 'PRO' && type !== 'EFR'
              ? (

                <>
                  <Grid item container direction='column' justifyContent='center' alignContent='center' xs={6}>
                    <Grid item container justifyContent='center'>
                      <ModifiedGradesIcon />
                    </Grid>

                    <Typography variant='h6' style={{ textAlign: 'center', color: theme.palette.black, textTransform: 'none' }}>
                      {GradesCheck(grades, gradesArray)}
                    </Typography>
                  </Grid>

                  <Grid item container direction='column' justifyContent='center' xs={6}>
                    <Grid item container justifyContent='center'>
                      <ModifiedChalkboardIcon />
                    </Grid>

                    <Typography variant='h6' noWrap style={{ textAlign: 'center', color: theme.palette.black, textTransform: 'none', textOverflow: 'ellipsis', width: 'inherit' }}>
                      {!subjectName ? 'N/A' : subjectName}
                    </Typography>
                  </Grid>
                </>
              )
              : (
                type !== 'EFR' &&
                  <Grid
                    item
                    container
                    justifyContent='center'
                  >
                    {artifactInfo.description && artifactInfo.description.length > 325
                      ? (
                        <Typography variant='h6' style={{ textTransform: 'none', wordBreak: 'break-word' }}>{`${artifactInfo.description.substring(0, 325)}...`}</Typography>
                      )
                      : (
                        <Typography variant='h6' style={{ textTransform: 'none', wordBreak: 'break-word' }}>{artifactInfo.description}</Typography>
                      )}
                  </Grid>
              )}
          </Grid>
          <Grid item container direction='column' style={{ paddingLeft: '.5em', paddingRight: '.5em' }}>
            {getArtifactSpecifics(type)}
          </Grid>
        </Grid>
      </CardContent>
    </>
  )
}

export default DefaultCardView
