import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { makeStyles, useTheme } from '@material-ui/core'

import LEDDetails from './LEDForms/LEDDetails'
import LWDetails from './LWForms/LWDetails'
import ProjectDetails from './ProjectForms/ProjectDetails'

const useStyles = makeStyles((theme) => ({
  inputPlaceholder: {
    '&::placeholder': {
      fontStyle: 'italic'
    }
  },
  textField: {
    '& .MuiInput-underline:before': {
      borderBottom: `1px dashed ${theme.palette.purple.medium}`
    },
    '& .MuiInput-underline:after': {
      borderBottom: `1px solid ${theme.palette.purple.medium}`
    }
  },
  customCheckbox: {
    borderRadius: 3,
    width: 14,
    height: 14,
    boxShadow: `inset 0 0 0 1px ${theme.palette.grey.medium}, inset 0 -1px 0 rgba(16,22,26,.1)`,
    backgroundColor: theme.palette.grey.medium,
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)'
    }
  },
  customCheckRoot: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  customCheckChecked: {
    backgroundColor: theme.palette.purple.darkest,
    boxShadow: 'none',
    '&:before': {
      display: 'block',
      width: 14,
      height: 14,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.purple.darker
    }
  },
  paperRoot: {
    width: '80%',
    padding: '1.5em',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      '@media only screen and (orientation: landscape)': {
        marginLeft: '1em'
      }
    }
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  border: {
    borderBottom: '1px solid lightgray',
    width: '100%'
  },
  text: {
    marginRight: '1rem',
    marginLeft: '1rem',
    color: 'grey'
  },
  publishArtifactButton: {
    fontWeight: '600',
    padding: '.5em 2.5em',
    '&:disabled': {
      backgroundColor: `${theme.palette.grey.light} !important`,
      color: '#babac1'
    }
  },
  artCardListItemItem: {
    display: 'flex',
    alignItems: 'stretch',
    overflow: 'visible'
  }
}))

const ArtifactDetails = (props) => {
  const {
    submit, ledInfo, setLEDInfo, artifactType,
    newLearnerWorkForm, setNewLearnerWorkForm, newProjectForm, setNewProjectForm,
    onUpdateEdit = () => { },
    isEditing,
    selectedLEDs, setSelectedLEDs,
    editLWInfo, setEditLWInfo, styles, targetArtifactComments,
    artifactNotCompleteCheck = false, isDraft = false,
    setPublishModalOpen = () => { }
  } = props
  const classes = useStyles()
  const theme = useTheme()
  const { grades = [], subjects = [] } = useSelector(state => state.profileData)
  const standards = useSelector(state => state.artifacts.standards)

  const [sectionComments, setSectionComments] = useState([])

  useEffect(() => {
    if (targetArtifactComments) {
      if (targetArtifactComments.length) {
        const tempArr = targetArtifactComments.reduce((arr, comment) => {
          if (comment) {
            if (comment.section === 'details' || (artifactType !== 'Learning Experience Design' && comment.section === 'notes')) {
              arr.push({ ...comment })
            }
          }
          return arr
        }, [])
        setSectionComments(tempArr)
      }
    }
  }, [targetArtifactComments, artifactType])

  return (
    <>
      {artifactType === 'Learning Experience Design' &&
        <>
          <LEDDetails
            classes={classes}
            submit={submit}
            ledInfo={ledInfo}
            setLEDInfo={setLEDInfo}
            grades={grades}
            subjects={subjects}
            standards={standards}
            styles={styles}
            sectionComments={sectionComments}
          />
        </>}

      {artifactType === 'Learner Work' &&
        <>
          <LWDetails
            theme={theme}
            classes={classes}
            submit={submit}
            grades={grades}
            subjects={subjects}
            standards={standards}
            newLearnerWorkForm={newLearnerWorkForm}
            setNewLearnerWorkForm={setNewLearnerWorkForm}
            onUpdateEdit={onUpdateEdit}
            isEditing={isEditing}
            selectedLEDs={selectedLEDs}
            setSelectedLEDs={setSelectedLEDs}
            artifactType={artifactType}
            editLWInfo={editLWInfo}
            setEditLWInfo={setEditLWInfo}
            styles={styles}
            sectionComments={sectionComments}
            isDraft={isDraft}
            artifactNotCompleteCheck={artifactNotCompleteCheck}
            setPublishModalOpen={setPublishModalOpen}
          />
        </>}
      {artifactType === 'Project' &&
        <>
          <ProjectDetails
            theme={theme}
            classes={classes}
            submit={submit}
            newProjectForm={newProjectForm}
            setNewProjectForm={setNewProjectForm}
            styles={styles}
            sectionComments={sectionComments}
          />
        </>}
    </>
  )
}

export default ArtifactDetails
