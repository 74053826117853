import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { withRouter } from 'react-router-dom'

import { Grid, Paper, Typography, Button } from '@material-ui/core'
import { Refresh, ErrorOutline } from '@material-ui/icons'

import queryString from 'query-string'

import NavBar from '../ui/tools/NavBar'
import Onboard2Modal from '../ui/modals/Onboard2Modal'
import { UserGeneral, UserAbout, UserSkills, UserTech, UserArtifacts } from '../ui/profile'

import { getUserDetails, updateUserProfile } from '../../redux/actions'
import { GET_USER_DETAILS } from '../../redux/types'

import { NotificationToast } from '../ui/tools'
import { modalTypes } from '../../utils'

const Profile = (props) => {
  const dispatch = useDispatch()
  const theme = useTheme()

  const loadingState = useSelector(state => state.loadingState)
  const { type, error } = loadingState

  const parsedProps = queryString.parse(props.location.search)

  // This checks to see if the screen is bigger than 'small'
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  // This checks to see if the screen is 315px wide or bellow
  const matchesXS = useMediaQuery(theme.breakpoints.down(315))

  // The profileID of the user being viewed
  const profileID = parsedProps.user

  // Defines a requested edit modal to open
  const modalType = parsedProps.modal

  // Determines if the user is in their private profile view
  const [editMode, setEditMode] = useState(true)

  // Default object used when fetching artifacts
  const artifactFilter = {
    creatorID: profileID,
    grades: [],
    subjects: [],
    status: '',
    type: 'all',
    sortDirection: 'DESC',
    sortField: 'createdAt',
    sortCount: 12,
    offset: 0
  }

  // Used when changing the filter of artifacts
  const [filter, setFilter] = useState(artifactFilter)

  const { onboardingStep, userID: currentUser, profileID: userProfileID } = useSelector(state => state.auth)

  // Details of the profile's respective user
  const UserDetails = useSelector(state => state.userDetails)

  const AllSkills = useSelector(state => state.profileData.skills)
  const AllTech = useSelector(state => state.profileData.tech)

  const [onboarding2Open, setOnboarding2Open] = useState(false)
  const [userSkills, setUserSkills] = useState([])
  const [userTech, setUserTech] = useState([])
  const [canEdit, setCanEdit] = useState(false)

  // Opens the various editing modals
  const [editSkillsOpen, setEditSkillsOpen] = useState(false)
  const [editTechOpen, setEditTechOpen] = useState(false)
  const [openProfile, setOpenProfile] = useState(false)
  const [openAvatarModal, setOpenAvatarModal] = useState(false)

  const checkProfileID = (id) => {
    const regex = /[!@#$%^&*()_+=[\]{};':"\\|,.<>/?\s]+/
    if (id && typeof id === 'string' && id.length && id.length <= 55 && id.length > 8 && !regex.test(id)) { return true }
    return false
  }

  useEffect(() => {
    const validID = checkProfileID(profileID)
    if (validID) {
      dispatch(getUserDetails(profileID, () => { }, () => NotificationToast(true, 'Failed to get user details!')))
    } else {
      props.history.push({
        pathname: '/error'
      })
    }
  }, [profileID, props.history, dispatch])

  useEffect(() => {
    if (profileID === userProfileID) {
      if (onboardingStep < 2) { setOnboarding2Open(true) }
      setCanEdit(true)
    }
    if (profileID !== userProfileID) {
      setCanEdit(false)
      setEditMode(false)
    }
  }, [profileID, userProfileID, onboardingStep])

  useEffect(() => {
    if (UserDetails) {
      setUserSkills(UserDetails.skillsList)
      setUserTech(UserDetails.techList)
    }
  }, [dispatch, UserDetails])

  // Callback that checks to see if the user artifacts need to be refreshed
  const triggerRefresh = useCallback(() => {
    if (profileID && filter) {
      // If you have changed to a new profile, but the artifact filter has not changed, fix it
      if (profileID !== filter.creatorID) {
        // If you have returned to your own profile, set the editMode to true (this matches the artifacts that will be returned)
        if (profileID === userProfileID) {
          setEditMode(true)
        }

        setFilter({ ...filter, creatorID: profileID })
      }
    }
  }, [profileID, filter, userProfileID])

  // Fire the callback
  useEffect(() => {
    triggerRefresh()
  }, [triggerRefresh])

  const handleArtifactWalkthrough = (type, submission, editing = false, isDraft = false) => {
    const editParam = editing ? '&editing=true' : ''
    const draftParam = isDraft ? '&draft=true' : ''

    props.history.push({
      pathname: '/artifact',
      search: `?create=${type}&submission=${submission}${editParam}${draftParam}`
    })
  }

  const handleDispatchSave = (profileInfo, fireSaveSuccess, fireSaveFailure, fireImageSuccess) => {
    dispatch(updateUserProfile(profileInfo, fireSaveSuccess, fireSaveFailure, fireImageSuccess))
  }

  // Used to remove the modal param after the user has closed the corresponding modal
  const removeModalType = () => {
    if (modalType) {
      props.history.replace({
        search: `?user=${userProfileID}`
      })
    }
  }

  const [navRef, setNavRef] = useState(null)

  const navHeight = navRef ? navRef.clientHeight : 0

  return (
    <>
      <NavBar location={parsedProps} profileID={profileID} setNavRef={setNavRef} navHeight={navHeight} />

      <Onboard2Modal onboarding2Open={onboarding2Open} setOnboarding2Open={setOnboarding2Open} />

      <Grid item container direction={matches ? 'row' : 'column'} justifyContent='center' style={{ marginTop: '4em' }}>
        {
          type === GET_USER_DETAILS && error === true
            ? (
              <>
                <Grid item container direction='column' xs={12}>
                  <Grid item container direction='row' justifyContent='center'>
                    <Paper elevation={3} style={{ padding: '4rem', position: 'absolute', top: '50%', transform: 'translateY(-50%)' }}>
                      <Grid item container direction='row' justifyContent='center'>
                        <ErrorOutline
                          style={{ fontSize: '4rem', color: theme.palette.purple.darkest }}
                        />
                      </Grid>
                      <Grid item container direction='row' justifyContent='center'>
                        <Typography variant='h2' style={{ color: theme.palette.purple.darkest }}>Something went wrong.</Typography>
                      </Grid>
                      <Grid item container direction='row' justifyContent='center'>
                        <Typography variant='h5' style={{ marginTop: '1rem' }}>Please try refreshing the page to fix the issue.</Typography>
                      </Grid>
                      <Grid item container direction='row' justifyContent='center'>
                        <Button
                          variant='contained'
                          color='primary'
                          startIcon={<Refresh />}
                          style={{ marginTop: '1.5rem' }}
                          onClick={(e) => { e.preventDefault(); dispatch(getUserDetails(profileID, () => { }, () => NotificationToast(true, 'Failed to get user details!'))) }}
                        >
                          Refresh
                        </Button>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid item container direction='column' xs={matches ? 8 : 12}>
                  <Grid item container direction='row' justifyContent='center'>
                    <UserGeneral
                      matches={matches}
                      matchesXS={matchesXS}
                      userDetails={UserDetails}
                      canEdit={canEdit}
                      editMode={editMode}
                      currentUser={currentUser}
                      setEditMode={setEditMode}
                      loadingState={loadingState}
                      userProfileID={userProfileID}
                      handleDispatchSave={handleDispatchSave}
                      filter={filter}
                      setFilter={setFilter}
                      modalTypes={modalTypes}
                      modalType={modalType}
                      removeModalType={removeModalType}
                      openProfile={openProfile}
                      setOpenProfile={setOpenProfile}
                      openAvatarModal={openAvatarModal}
                      setOpenAvatarModal={setOpenAvatarModal}
                      navHeight={navHeight}
                    />
                  </Grid>
                  {!matches &&
                    <>
                      <Grid item container direction='row' justifyContent='center' style={{ marginTop: '.5em' }}>
                        <UserAbout
                          matches={matches}
                          canEdit={canEdit}
                          editMode={editMode}
                          currentUser={currentUser}
                          loadingState={loadingState}
                          userProfileID={userProfileID}
                          modalTypes={modalTypes}
                          removeModalType={removeModalType}
                          navHeight={navHeight}
                        />
                      </Grid>
                      <Grid item container direction='row' justifyContent='center'>
                        <UserSkills
                          matches={matches}
                          allSkills={AllSkills}
                          userSkills={userSkills}
                          canEdit={canEdit}
                          editMode={editMode}
                          currentUser={currentUser}
                          loadingState={loadingState}
                          userProfileID={userProfileID}
                          modalTypes={modalTypes}
                          removeModalType={removeModalType}
                          modalType={modalType}
                          editSkillsOpen={editSkillsOpen}
                          setEditSkillsOpen={setEditSkillsOpen}
                        />
                      </Grid>
                      <Grid item container direction='row' justifyContent='center'>
                        <UserTech
                          matches={matches}
                          allTech={AllTech}
                          userTech={userTech}
                          canEdit={canEdit}
                          editMode={editMode}
                          currentUser={currentUser}
                          loadingState={loadingState}
                          userProfileID={userProfileID}
                          modalTypes={modalTypes}
                          modalType={modalType}
                          removeModalType={removeModalType}
                          editTechOpen={editTechOpen}
                          setEditTechOpen={setEditTechOpen}
                        />
                      </Grid>
                    </>}
                  <Grid item container direction='row' justifyContent='center'>
                    <UserArtifacts
                      matches={matches}
                      loadingState={loadingState}
                      userDetails={UserDetails}
                      handleArtifactWalkthrough={handleArtifactWalkthrough}
                      currentUser={currentUser}
                      canEdit={canEdit}
                      editMode={editMode}
                      filter={filter}
                      setFilter={setFilter}
                      artifactFilter={artifactFilter}
                      modalTypes={modalTypes}
                      modalType={modalType}
                      removeModalType={removeModalType}
                    />
                  </Grid>
                </Grid>
                {matches &&
                  <Grid item container direction='column' xs={matches ? 4 : 12}>
                    <Grid item container direction='row' justifyContent='center' style={{ marginTop: '.5em' }}>
                      <UserAbout
                        matches={matches}
                        canEdit={canEdit}
                        editMode={editMode}
                        currentUser={currentUser}
                        loadingState={loadingState}
                        userProfileID={userProfileID}
                        modalTypes={modalTypes}
                        removeModalType={removeModalType}
                        navHeight={navHeight}
                      />
                    </Grid>
                    <Grid item container direction='row' justifyContent='center'>
                      <UserSkills
                        matches={matches}
                        allSkills={AllSkills}
                        userSkills={userSkills}
                        canEdit={canEdit}
                        editMode={editMode}
                        currentUser={currentUser}
                        loadingState={loadingState}
                        userProfileID={userProfileID}
                        modalTypes={modalTypes}
                        removeModalType={removeModalType}
                        editSkillsOpen={editSkillsOpen}
                        setEditSkillsOpen={setEditSkillsOpen}
                        navHeight={navHeight}
                      />
                    </Grid>
                    <Grid item container direction='row' justifyContent='center'>
                      <UserTech
                        matches={matches}
                        allTech={AllTech}
                        userTech={userTech}
                        canEdit={canEdit}
                        editMode={editMode}
                        currentUser={currentUser}
                        loadingState={loadingState}
                        userProfileID={userProfileID}
                        modalTypes={modalTypes}
                        modalType={modalType}
                        removeModalType={removeModalType}
                        editTechOpen={editTechOpen}
                        setEditTechOpen={setEditTechOpen}
                        navHeight={navHeight}
                      />
                    </Grid>
                  </Grid>}
              </>
            )
        }
      </Grid>
    </>
  )
}

export default withRouter(Profile)
