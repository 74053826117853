import React from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/actions'

import edFarmLogo from '../../assets/edFarmLogo.png'
import teacherImg from '../../assets/teacher.svg'
// Assets
import { makeStyles, useTheme } from '@material-ui/core/styles'

// MUI
import {
  Grid, Typography
} from '@material-ui/core'

// themes are set in app.js wrapping routes with theme provider
const useStyles = makeStyles((theme) => ({
  darkBackground: {
    backgroundColor: theme.palette.purple.darkest
  },
  formContainer: {
    margin: '0 9em 3em',
    paddingRight: '20em',
    marginTop: '6vh',
    [theme.breakpoints.down('sm')]: {
      margin: '0 1em 2em',
      paddingRight: '1em'
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: '.2em'
    }
  },
  teacherImage: {
    height: '100%',
    width: '150%',
    marginLeft: '-7em',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  sloganScript: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  logoContainer: {
    marginLeft: '2em',
    marginTop: '2em',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '.5em',
      marginTop: '2em'
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '.5em',
      marginTop: '2em'
    }
  }
}))

const NotFound = props => {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const authObject = useSelector(state => state.auth)
  const { isAuthenticated, isValidated, isAccountApproved } = authObject

  // Handle returning the user to their profile (if logged in, validated, and approved), log them out if not validated or approved, and push them to the main route otherwise.
  const handleReturnClick = () => {
    if (isAuthenticated) {
      if (!isValidated || !isAccountApproved) {
        dispatch(logout())
      }
    }
    props.history.push('/')
  }
  // ************ Main Render ************ //
  return (
    <>
      <Grid container direction='row' style={{ overflow: 'hidden' }}>
        <Grid item xs={3} md={4} className={classes.darkBackground}>
          <Grid container direction='column' style={{ justifyContent: 'space-between', height: '100vh' }}>
            <Grid item className={classes.logoContainer}>
              <Grid item>
                <img src={edFarmLogo} alt='logo' style={{ maxHeight: '3.7em' }} />
              </Grid>
              <Grid item style={{ marginTop: '3em' }} className={classes.sloganScript} container xs={10}>
                <Typography variant='h1' style={{ color: 'white', fontWeight: '600' }}>Gain inspiration and learn from other</Typography>
                <Typography variant='h1' style={{ color: 'white', fontWeight: '600' }}>educators</Typography>
              </Grid>
            </Grid>
            <Grid item container alignContent='flex-end'>
              <Grid item container style={{ width: '100%', height: 'auto' }}>
                {/* TODO: Will not display with overhang on large screen */}
                <img className={classes.teacherImage} src={teacherImg} alt='Teacher Fellow' />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={8}>
          <Grid item container direction='column' alignContent='center' justifyContent='center' className={classes.formContainer}>
            <Grid item container direction='row' alignItems='center' justifyContent='center' style={{ display: 'flex', marginTop: '25vh', marginLeft: '10vw' }}>
              <Grid item container direction='column'>
                <Grid item xs={9}>
                  <Typography variant='h2'>404 Not Found</Typography>
                </Grid>
                <Grid item container style={{ marginTop: '2em' }} direction='column'>
                  <Typography variant='body1'>Oh no! Looks like we couldn't find the page you were looking for.</Typography>
                </Grid>
                <Grid item container style={{ marginTop: '1em' }} direction='column'>
                  <Typography variant='body1'>Click <span style={{ fontWeight: '600', color: theme.palette.purple.darkest, cursor: 'pointer' }} onClick={handleReturnClick}>here</span> to return to your profile or login.</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default withRouter(NotFound)
