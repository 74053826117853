import React, { useState, useCallback, useContext, useMemo, useRef } from 'react'
import { debounce } from 'lodash'
import { withRouter } from 'react-router-dom'
import { Offline, Online } from 'react-detect-offline'
import { useSelector } from 'react-redux'
import { Typography, Grid, Paper, Divider, Avatar, Chip, Snackbar, makeStyles, Button, Popover, IconButton, Collapse, Fab } from '@material-ui/core'
import { ChevronLeft as OpenLeft, AddRounded as OpenIcon, ExpandMore as CloseIcon } from '@material-ui/icons'
import { AvatarGroup } from '@material-ui/lab'
import { MessageContent, SlateEditor } from '../MessageUtils'
import ErrorIcon from '@material-ui/icons/Error'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { WebsocketErrors, WebsocketContext } from '../../utils/WebSocket'
import stringAvatar from '../../../tools/AvatarFormat'
import moment from 'moment'
import queryString from 'query-string'

/* ********** The content of a user/recipient conversation ************* //
    Rendered from MessageArea.js when the 'view.message' is 'chain'
    - displays the individual messages in a thread
    - renders MessageContent.js and passes the 'user' boolean prop based on if the message author is the user or the recipient
    - renders the text editor
*/

const useStyles = makeStyles((theme) => ({
  snackBarPosition: {
    position: 'sticky',
    left: '0',
    bottom: 0,
    display: 'flex',
    transform: 'inherit'
  }
}))

const MessageChain = (props) => {
  const {
    theme, smScreen, view, classes, messagesInitialLoad, loadNewView = () => {},
    confirmAnchorEl, setConfirmAnchorEl, openConfirmPopover,
    deleteDraftID, setDeleteDraftID,
    setCurrentForm,
    editorOpen, setEditorOpen, openIconRef, setOpenIconRef
  } = props

  const parsedProps = queryString.parse(props.location.search)

  const openBtnHeight = openIconRef ? openIconRef.clientHeight : 0

  // Websocket
  const [ws = {}] = [useContext(WebsocketContext)]

  // Local Styles
  const newClasses = useStyles()

  /*  *********************** */
  /*  *** REDUX SELECTORS *** */
  /*  *********************** */

  // Auth user in the application
  const { userID } = useSelector(state => state.auth)
  // Websocket Redux Items
  const { messages = [], LastEvaluatedKey = null, socketClosedCode, activeConversationUsers = [] } = useSelector(state => state.websocket)
  // if any users have returned as not available, we will consider the conversation inactive and will disable the slate editor
  const conversationInactive = Boolean(activeConversationUsers?.some(userObj => (Object.keys(userObj).includes('userAvailable') && !userObj.userAvailable) || userObj.deletedAt))

  /*  **************************** */
  /*  *** LOCAL STATE AND REFS *** */
  /*  **************************** */

  // Conversation Users Display State
  const [userAvatars, setUserAvatars] = useState([])
  const [userNames, setUserNames] = useState('')
  const [userSchools, setUserSchools] = useState('')

  // Websocket error handlers
  const [messageError, setMessageError] = useState(false)
  const [offlineStatus, setOfflineStatus] = useState(false)

  // Div of the message chain area
  const [messageAreaRef, setMessageAreaRef] = useState(null)

  // New Messages Notification Chip
  const [newNotificationOpen, setNewNotificationOpen] = useState(false)

  const [activeConversationID, setActiveConversationID] = useState(null)

  // memoizing the state of newNotificationOpen to prevent the animation blinking between state updates
  const noteChange = useMemo(() => { return newNotificationOpen }, [newNotificationOpen])

  useMemo(() => {
    if (typeof parsedProps.conversationID !== 'undefined') { setActiveConversationID(parsedProps.conversationID) }
  }, [parsedProps.conversationID])

  // Ref controlled my mouse over events on the message chain div
  const mouseOverChainRef = useRef(false)

  // Scrolling checks and Refs for message Chain Area
  const messageLength = messages && messages.length ? messages.length : 0
  const scrollPositionRef = useRef(null)
  const scrollingRef = useRef(false)

  /*  *************************** */
  /*  *** MESSAGE FETCH LOGIC *** */
  /*  *************************** */

  // Callback to fetch the messages for a conversation
  const fetchNewMessages = useCallback(() => {
    if (activeConversationID && ws.fetchMessages && Boolean(userID)) {
      ws.fetchMessages(activeConversationID, userID, null)
    }
  }, [ws, activeConversationID, userID])

  // Handler for When the user has scrolled back to the oldest available message and wishes to see more
  const handleLoadMore = () => {
    if (ws && ws.fetchMessages) {
      ws.fetchMessages(activeConversationID, userID, LastEvaluatedKey, true)
    }
  }

  // Fetch messages listener
  useMemo(() => {
    // If we are fetching for da first time
    if (messagesInitialLoad.current) {
      fetchNewMessages()
    }
  }, [fetchNewMessages, messagesInitialLoad])

  // Check to see there is a 24 difference between one message and the next
  const getFormatStamp = (createdAt, nextCreatedAt) => {
    if (Boolean(createdAt && nextCreatedAt) && Boolean(createdAt !== nextCreatedAt)) {
      const startCurrent = moment.unix(createdAt).startOf('day')
      const startNext = moment.unix(nextCreatedAt).startOf('day')
      const diff = moment(startNext).diff(startCurrent, 'days')

      if (diff && diff > 0) {
        const formatStamp = moment.unix(nextCreatedAt).format('MMM DD')
        return formatStamp
      }
    }
    return 0
  }

  /*  ************************ */
  /*  ***** SCROLL LOGIC ***** */
  /*  ************************ */

  // Action once scroll is done
  const debounceFunc = (e) => {
    scrollPositionRef.current = e.target.scrollTop
    scrollingRef.current = false
  }

  // Fire debounce action after 750ms/ ignore react-hooks dependency array warning, does not play well with lodash debounce
  // eslint-disable-next-line
  const debounceScroll = useCallback(debounce((e) => debounceFunc(e), 750), [])

  /* Listen for the user to scroll and record their scroll position within the message chain ref */
  useMemo(() => {
    if (messageAreaRef) {
      messageAreaRef.addEventListener('scroll', (e) => { scrollingRef.current = true; debounceScroll(e) })
    }

    return () => {
      messageAreaRef.removeEventListener('scroll', (e) => { debounceScroll(e) })
    }
  }, [messageAreaRef, debounceScroll])

  /*  Will scroll to the bottom of the message div when called  */
  const handleScrollToNew = useCallback((ref) => {
    scrollingRef.current = true

    if (newNotificationOpen) { setNewNotificationOpen(false) }
    if (messages && messages.length) {
      if (messageAreaRef) {
        setTimeout(() => {
          messageAreaRef.scrollTo({
            top: 0, behavior: 'smooth'
          })
        }, 200)

        /* Accepts a ref and will set it to false.
            (This is used specifically in the instance of the inital message loading)
          Else, be sure to call the fetchMessages and remove any unread notifications
            (this handles the 'new messages' button properly removing unread notifications)
        */
        if (ref) {
          setTimeout(() => {
            ref.current = false
          }, 2000)
        } else { fetchNewMessages() }
      }
    }
    setTimeout(() => {
      scrollingRef.current = false
    }, 750)
  }, [messages, messageAreaRef, newNotificationOpen, fetchNewMessages])

  /* Updates as messages comes in, and will open the new message notification if we are not already scrolled to the bottom */
  useMemo(() => {
    if (messageLength) {
      // check if the message area div has overflow
      const contentHasOverflow = messageAreaRef && messageAreaRef.clientHeight < messageAreaRef.scrollHeight

      // Checks to be sure we are on the most recent message
      const scrolledToMostRecent = Math.round(scrollPositionRef.current) === 0

      // If we are not at the most recent message, and it is not the inital message load, and the content has overflow
      if (!scrolledToMostRecent && !messagesInitialLoad.current && contentHasOverflow) {
        // set the notification open, if not currently scrolling
        if (!scrollingRef.current && !newNotificationOpen) { setNewNotificationOpen(true) }
      } else {
        // If we are scrolled to the bottom, but a new message comes in, be sure to scroll to the new 'bottom'
        if (!scrollingRef.current) {
          setTimeout(() => { handleScrollToNew() }, 300)
        }
      }
    }
  }, [messageLength, handleScrollToNew, messagesInitialLoad, messageAreaRef, newNotificationOpen])

  // Scroll the user to the newest messages in the conversation, when a user is not currently hovering the area
  useMemo(() => {
    // If we are loading the messages for the first time for a conversation, be sure we are at the most recent without scrolling
    // defaulted to true, and sets false once scrolled, then true again when changing conversations
    if (messagesInitialLoad.current) {
      if (messageAreaRef && messageAreaRef.scrollTop !== 0) {
        messageAreaRef.scrollTop = 0
      }

      setTimeout(() => {
        messagesInitialLoad.current = false
      }, 2000)
    }
  }, [messagesInitialLoad, messageAreaRef])

  /*  ************************** */
  /*  *** USER DISPLAY LOGIC *** */
  /*  ************************** */

  // The conversation's associated user information
  useMemo(() => {
    if (activeConversationUsers && Array.isArray(activeConversationUsers)) {
      const avatars = activeConversationUsers.reduce((arr, user) => {
        if (user?.profileAvatarPath) {
          arr.push({ name: user.fullName, path: user.profileAvatarPath })
        } else {
          arr.push({ name: user.fullName })
        } return arr
      }, [])

      setUserAvatars(avatars)
      if (activeConversationUsers.length > 1) {
        setUserNames('Multiple Users')
        setUserSchools('')
      } else {
        const [{ fullName = '', schoolName = '' } = {}] = activeConversationUsers
        setUserNames(fullName)
        setUserSchools(schoolName)
      }
    }
  }, [activeConversationUsers])

  /*  *************************** */
  /*  *** MESSAGE SEND LOGIC *** */
  /*  *************************** */

  /*
  handleScrollToSentMessage checks if the user is not currently scrolled to the newest message in the conversation they are in. If they are not, then it will scroll them to the newest message. It is currently being called in two places - an onEntered event and the handleSendMessage function.

  1. When called in the onEntered event on the Collapse component, it will automatically set the scrollTop position to 0 when the editor is not collapsed on mobile.
  2. In the handleSendMessage function, after sending a message, it will scroll them to the new message they just sent.
  */
  const handleScrollToSentMessage = () => {
    if (messageAreaRef && messageAreaRef.scrollTop !== 0) {
      scrollingRef.current = true
      messageAreaRef.scrollTop = 0

      // Setting a timeout here to prevent the 'New Message' Notification from appearing as soon as they send a new message.
      setTimeout(() => {
        scrollingRef.current = false
      }, 450)
    }
  }

  const handleSendMessage = async (messageContext, resetContext = () => { }) => {
    const text = messageContext
    const otherRecipients = activeConversationUsers.map((user) => user.userID)
    const allRecipients = [...otherRecipients, userID]

    if (ws && ws.sendMessage) {
      console.log('started', moment().format())
      ws.sendMessage(activeConversationID, text, allRecipients, () => resetContext(), fireSendFailure)
      handleScrollToSentMessage()
    } else {
      console.log('websocket not intialized')
    }
  }

  // Handle Saving or deleting a draft for a user based on the message context length
  const handleDraftMessages = async (messageContext, draftID = '') => {
    const text = messageContext
    if (ws && ws.handleDrafts) {
      // Check if the draft ID exists to delete draft message if it does, otherwise use the activeConversationID
      const ID = draftID && draftID.length ? draftID : activeConversationID
      ws.handleDrafts(ID, text)
    } else {
      console.log('websocket not intialized')
    }
  }

  // Sets a generic message for the casual user, with detailed log information for debugging
  const fireSendFailure = (message = '') => {
    // Resets to false in send message success function
    setMessageError(true)
    switch (message) {
      case WebsocketErrors.MESSAGE_FAILURE: {
        console.error('Failed to send message')
        break
      }
      case WebsocketErrors.MESSAGE_SOCKET_MISSING: {
        console.error('Attempting to re-establish socket connection')
        break
      }
      case WebsocketErrors.CONVERSATION_UPDATE_FAILURE: {
        console.error('Sent message but failed to update chat log')
        break
      }
      case WebsocketErrors.MESSAGE_SOCKET_CLOSED: {
        console.error('Failed due to closed or closing socket connection')
        break
      }
      default: {
        // Should not happen, should always have a message reason
        console.log('Unknown failure')
      }
    }
  }

  // Take the draft ID set in ConveyMessage from DraftConversationCard, delete the draft, reset the anchor element to close the popper, and reset draft ID
  const handleDeleteDraft = (ID) => {
    handleDraftMessages([], ID)
    setConfirmAnchorEl(null)
    setDeleteDraftID('')
  }

  return (
    <>
      {/* DELETE DRAFT POPOVER (START) */}
      <Popover
        id={openConfirmPopover ? 'confirm-popover' : undefined}
        open={openConfirmPopover && openConfirmPopover !== undefined ? openConfirmPopover : false}
        anchorEl={confirmAnchorEl}
        onClose={(e) => { e.preventDefault(); setConfirmAnchorEl(null) }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <Grid container position='absolute' direction='column' style={{ padding: '1em' }}>
          <Typography
            variant='h4'
            style={{ color: theme.palette.purple.darkest }}
            gutterBottom
          >
            Are you sure you want to delete this draft message?
          </Typography>

          <Grid item container direction='row' justifyContent='flex-end' alignItems='center' style={{ marginTop: '.5em' }}>
            <Button
              onClick={(e) => { e.preventDefault(); setConfirmAnchorEl(null) }}
              style={{ marginRight: '.3em', textTransform: 'none', fontWeight: '600' }}
            >
              Cancel
            </Button>
            <Button variant='contained' color='primary' onClick={(e) => { e.preventDefault(); handleDeleteDraft(deleteDraftID) }}>Confirm</Button>
          </Grid>
        </Grid>
      </Popover>
      {/* DELETE DRAFT POPOVER (END) */}

      <Grid item container direction='column' style={{ padding: '.5em 1em 0 1em', height: '100%', maxWidth: smScreen ? '92vw' : '100vw' }} alignItems='center'>
        <Paper
          elevation={smScreen ? 0 : 1}
          style={{ width: '100%', display: 'flex', height: '100%' }}
        >
          <Grid container justifyContent='space-between' direction='column' style={{ display: 'block', position: 'relative' }}>
            {/* Header/Recipient Information */}
            <Grid
              item
              container
              direction='row'
              alignItems='center'
              justifyContent={smScreen ? 'flex-start' : 'center'}
              style={{ padding: smScreen ? '.5em .5em' : '1em .5em' }}
              className={newClasses.headerXSLandscape}
            >
              {smScreen &&
                <Grid item xs={3}>
                  <IconButton onClick={(e) => { e.preventDefault(); setCurrentForm('conversation-area') }}>
                    <OpenLeft style={{ height: '1.8em', width: '1.8em', color: theme.palette.purple.darkest }} />
                  </IconButton>
                </Grid>}

              <Grid
                item
                container
                direction='column'
                alignItems='center'
                justifyContent='center'
                xs={smScreen ? 6 : userAvatars.length && userAvatars.length > 1 ? 4 : 2}
                md={userAvatars.length && userAvatars.length > 1 ? 4 : 1}
              >
                <Grid item>
                  <AvatarGroup max={3}>
                    {userAvatars.map((avatar, i) => {
                      const hasPath = avatar.path && avatar.path !== undefined ? true : !true
                      return <Avatar key={i} src={avatar.path} {...(!hasPath && { ...stringAvatar(`${avatar.name}`, hasPath) })} />
                    })}
                  </AvatarGroup>
                </Grid>

                {smScreen &&
                  <>
                    <Grid item>
                      <Typography variant='h5' style={{ fontWeight: 600, lineHeight: 1, color: theme.palette.purple.darkest }}>
                        {userNames}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant='body1'>{userSchools}</Typography>
                    </Grid>
                  </>}
              </Grid>

              {!smScreen &&
                <Grid
                  item
                  container
                  direction='column'
                  alignItems={smScreen ? 'center' : 'flex-start'}
                  justifyContent={smScreen ? 'center' : 'flex-start'}
                  xs={smScreen ? 8 : userAvatars.length && userAvatars.length > 1 ? 8 : 10}
                  md={userAvatars.length && userAvatars.length > 1 ? 4 : 11}
                >
                  <Grid item>
                    <Typography variant='h5' style={{ fontWeight: 600, lineHeight: 1, color: theme.palette.purple.darkest }}>
                      {userNames}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='body1'>{userSchools}</Typography>
                  </Grid>
                </Grid>}
            </Grid>

            <Divider />

            {/* *** Message Chain *** */}
            {/* if network is available, render expected content */}
            <Online
              onChange={() => setOfflineStatus(prev => !prev)}
            >
              <Grid
                item
                container
                direction='column-reverse'
                onMouseOver={() => { mouseOverChainRef.current = true }}
                onMouseLeave={() => { mouseOverChainRef.current = false }}
                ref={setMessageAreaRef}
                style={{ height: smScreen && !editorOpen ? '70vh' : '45vh', overflowY: 'auto', padding: '1em', paddingBottom: openBtnHeight }}
              >
                {Boolean(messages && messages.length) &&
                  // {/* Message Thread */}
                  <Grid item container justifyContent='center'>
                    {LastEvaluatedKey &&
                      <Grid item>
                        <Typography
                          variant='h6'
                          style={{
                            color: theme.palette.grey.dark,
                            fontWeight: 400,
                            textTransform: 'none'
                          }}
                        >
                          <span
                            style={{ color: theme.palette.purple.darkest, fontWeight: 600, cursor: 'pointer' }}
                            onClick={(e) => { e.preventDefault(); handleLoadMore() }}
                          >
                            Load More
                          </span>
                        </Typography>
                      </Grid>}
                    {messages.map((message, i) => {
                      const { createdAt = 0, userID = '' } = message
                      const nextIndex = i + 1
                      const nextCreatedAt = nextIndex <= messages.length - 1 ? messages[nextIndex].createdAt : createdAt

                      // Returns as 0 if the messages are within 24 hours of each other
                      const dividerStamp = getFormatStamp(createdAt, nextCreatedAt)

                      const messageUser = activeConversationUsers && activeConversationUsers.length ? activeConversationUsers.find(x => x.userID === userID) : ''
                      const userPath = messageUser?.profileAvatarPath || ''
                      const messageSender = messageUser?.fullName || message.Name || 'Unavailable'

                      return (
                        <MessageContent
                          key={`${message.messageID}-${message.userID}`}
                          theme={theme}
                          message={message}
                          userPath={userPath}
                          dividerStamp={dividerStamp}
                          classes={classes}
                          openBtnHeight={!editorOpen ? openBtnHeight : 0}
                          smScreen={smScreen}
                          messageSender={messageSender}
                        />
                      )
                    })}
                  </Grid>}

              </Grid>
              {/* New Messages Notification */}
              <Snackbar
                open={noteChange}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                classes={{ anchorOriginBottomCenter: newClasses.snackBarPosition }}
              >
                <Chip
                  label='New Messages'
                  clickable
                  onClick={() => handleScrollToNew()}
                  onDelete={() => handleScrollToNew()}
                  deleteIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                  style={{ backgroundColor: theme.palette.aqua, color: 'white' }}
                />
              </Snackbar>
            </Online>

            {/* If we have lost network connection, inform the user */}
            <Offline>
              <Grid item container direction='column' justifyContent='center' alignItems='center' style={{ height: '39vh', overflowY: 'auto', padding: '1em' }}>
                <Typography variant='body1' style={{ color: theme.palette.purple.darkest }}>Internet Offline.</Typography>
                <Typography variant='caption' style={{ color: theme.palette.grey.dark }}>Your browser is currently offline.</Typography>
              </Grid>
            </Offline>

            {/* Generic Error Message Display */}
            {messageError &&
              <Grid item container direction='row' justifyContent='center' alignItems='center'>
                <ErrorIcon fontSize='small' style={{ color: 'red', fontSize: '14px', marginRight: '.3em' }} />
                <Typography variant='body1' style={{ color: 'red', fontWeight: 600 }}>Unable to send message. Please try again.</Typography>
              </Grid>}

            {Boolean(!smScreen || (smScreen && editorOpen)) &&
              <Divider />}

            {smScreen &&
              <Collapse onEntered={() => handleScrollToSentMessage()} in={editorOpen} timeout={{ enter: 600, exit: 200 }}>
                {/* Compose Message */}
                <Grid item container spacing={2} style={{ padding: '1em', maxWidth: '90vw' }}>
                  <Grid item container direction='row' justifyContent='space-between' alignItems='center' style={{ paddingBottom: 0 }}>
                    <Typography variant='h5' style={{ color: theme.palette.purple.darkest, fontWeight: 600 }}>New Message:</Typography>
                    <IconButton onClick={(e) => { e.preventDefault(); setEditorOpen(false) }}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                  <Grid item container direction='row' justifyContent='flex-end' style={{ alignItems: 'stretch', height: '85%' }}>
                    <SlateEditor
                      parsedProps={parsedProps}
                      activeConversationID={activeConversationID}
                      view={view}
                      theme={theme}
                      onSendMessage={handleSendMessage}
                      onHandleDrafts={handleDraftMessages}
                      messageError={messageError}
                      setMessageError={setMessageError}
                      offlineStatus={Boolean(offlineStatus)}
                      socketClosedCode={socketClosedCode}
                      loadNewView={loadNewView}
                      smScreen={smScreen}
                      editorOpen={editorOpen}
                    />
                  </Grid>
                </Grid>
              </Collapse>}

            {!smScreen &&
              <Grid item container spacing={2} style={{ padding: '1em', bottom: 0, position: 'absolute' }}>
                <Grid item container direction='row' justifyContent='flex-start' style={{ paddingBottom: 0 }}>
                  <Typography variant='h5' style={{ color: theme.palette.purple.darkest, fontWeight: 600 }}>New Message:</Typography>
                </Grid>
                <Grid item container direction='row' justifyContent='flex-end' style={{ alignItems: 'stretch', height: '85%' }}>
                  <SlateEditor
                    parsedProps={parsedProps}
                    activeConversationID={activeConversationID}
                    view={view}
                    theme={theme}
                    onSendMessage={handleSendMessage}
                    onHandleDrafts={handleDraftMessages}
                    messageError={messageError}
                    setMessageError={setMessageError}
                    offlineStatus={Boolean(offlineStatus)}
                    socketClosedCode={socketClosedCode}
                    loadNewView={loadNewView}
                    smScreen={smScreen}
                    editorOpen={editorOpen}
                    conversationInactive={conversationInactive}
                  />
                </Grid>
              </Grid>}

          </Grid>
        </Paper>

        {
          smScreen && !editorOpen &&
            <Grid item container direction='row' justifyContent='flex-end'>
              <Fab
                color='primary'
                size='medium'
                ref={setOpenIconRef}
                style={{
                  backgroundColor: theme.palette.purple.darkest,
                  bottom: '1em',
                  position: !editorOpen ? 'fixed' : 'inherit'
                }}
                onClick={(e) => {
                  e.preventDefault(); setEditorOpen(true)
                }}
              >
                <OpenIcon style={{ color: 'white' }} />
              </Fab>
            </Grid>
        }
      </Grid>
    </>
  )
}

export default withRouter(MessageChain)
