import { createTheme } from '@material-ui/core/styles'

/* ******* The default MUI properties for the following component variants have been overrided by this new theme: ******
          *--- Typography:
            - Variants: h1, h2, h3, h4, h5, h6, body1, and caption.
          *--- Button:
            - Variants: Contained, outlined
          *--- Input Base:
            - Variants: Outlined Input

** The remaining variant types for these components retain the default MUI styling properties **

*** To use these overrides follow the typical inline styling for the MUI components and assign the corresponding variant type. ***

----- For the palette colors:
 Use the makeStyles classes to access the colors, e.g:
    const useStyles = makeStyles((theme) => ({
      [ yourClassName]: {
        color: theme.palette.purple.darkest
      },
      [yourOtherClassName]: {
        backgroundColor: theme.palette.grey.medium
      }
    }))
  Format: theme.palette.[parent group color].[specific shade]
  The colors are grouped in shades of Purple, Grey, and Pink, with solitary colors(no specific shades) for black, and aqua.
  For refrence to the colors described here, please see the Ed Farm Learn Style Guide.

----- For the Typography:
  Use the typical inline " variant='h1' " in the typography components to access the styled variants.
  Further visual refrence can be seen in the Ed Farm Style Guide.
  ** Note 1: These styles can be still be modified using classes and inline styling when needed.
  ** Note 2: The MUI default typography colors have not been modified and will need to be adjusted using the palette colors.
  ** Note 3: The MUI variant called 'body1' is called 'body text' in the Ed Farm documentation
  ** Note 4: The MUI variant called 'caption' is called 'small' in the Ed Farm documentation

  Font Family: Source Sans Pro
  Available Font Weights: 400, 600, 900

----- For the Buttons:
  Use the typical inline styling for 'contained' and 'primary' to access these stylings.
  Further visual refrence can be seen in the Ed Farm Style Guide.

  ** Note 1: Margins and size may need to be adjusted depending on the use of the button
  ** Note 2: You CAN use color='primary' and color='secondary' with inline button styling to access these styles

---- For the Inputs:
    Do NOT use the 'label' prop in the input/textfield/input base components. Instead wrap the input in a Grid or FormControl tag, and use a InputLabel above your input. e.g:
        <InputLabel>Test Field</InputLabel>
        <TextField
          variant='outlined'
          size='small'
        />
    This will ensure the correct label formatting is used.

    ** Note 1: You will need to add the variant='outlined' to access the correct styling.

This function is called in App.js and should therefore be passed to all child components without need to be imported.
Further global styling may be added later.
All modified and customized componants shoudld retain all other properties and classes typically provided through the MUI default theme.

*/

const theme = createTheme({
  palette: {
    purple: {
      lightest: '#f3f5fb',
      lighter: '#e5e9f5',
      light: '#dfe4f8',
      medium: '#9fafea',
      dark: '#7086d4',
      darker: '#4c5788',
      darkest: '#29356a'
    },
    grey: {
      lightest: '#fafafa',
      lighter: '#f5f6f7',
      light: '#e3e3e3',
      medium: '#d3d6d9',
      dark: '#66666e',
      darker: '#383838'
    },
    pink: {
      light: '#fcf5fa',
      dark: '#b94197'
    },
    logo: {
      pink: '#B94799',
      blue: '#55C0DD',
      green: '#7DC251',
      orange: '#F48C2A'
    },
    black: '#0d0c22',
    aqua: '#25b3da'
  },
  typography: {
    h1: {
      fontSize: '36px',
      fontWeight: '900',
      fontStyle: 'normal'
    },
    h2: {
      fontSize: '30px',
      fontWeight: '600',
      fontStyle: 'normal'
    },
    h3: {
      fontSize: '24px',
      fontWeight: '600',
      fontStyle: 'normal'
    },
    h4: {
      fontSize: '18px',
      fontWeight: '600',
      fontStyle: 'normal'
    },
    h5: {
      fontSize: '16px',
      fontWeight: '600',
      fontStyle: 'normal'
    },
    h6: {
      fontSize: '14px',
      fontWeight: '600',
      textTransform: 'uppercase',
      fontStyle: 'normal'
    },
    caption: {
      fontSize: '12px',
      fontWeight: '400'
    },
    body1: {
      fontSize: '14px',
      fontWeight: '400'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      min320: 320,
      min568: 568,
      min640: 640,
      min667: 667,
      min731: 731,
      min736: 736,
      min768: 768,
      min1024: 1024,
      min1366: 1366
    }
  },
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: 'Source Sans Pro !important'
      },
      colorTextSecondary: {
        color: 'black',
        fontSize: '16px',
        marginBottom: '.45em',
        marginRight: '.4em'
      }
    },
    MuiInputLabel: {
      root: {
        color: '#0d0c22',
        fontSize: '14px',
        fontWeight: '600',
        marginBottom: '5px'
      }
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: '#29356a'
        }
      }
    },
    MuiButton: {
      root: {
        fontFamily: 'Source Sans Pro'
      },
      contained: {
        color: 'white',
        textTransform: 'none',
        // margin: 'auto',
        borderRadius: '8px',
        '&:disabled': {
          color: '#66666e',
          backgroundColor: '#d3d6d9'
        }
      },
      containedPrimary: {
        backgroundColor: '#29356a',
        fontWeight: 'normal'
      },
      containedSecondary: {
        backgroundColor: '#b94197',
        fontWeight: 'normal'
      },
      outlined: {
        textTransform: 'none',
        margin: 'auto',
        borderRadius: '8px',
        '&:disabled': {
          color: '#d3d6d9',
          borderColor: '#d3d6d9'
        }
      },
      outlinedPrimary: {
        color: '#29356a',
        borderColor: '#29356a',
        fontWeight: 'normal'
      }
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          border: '2px solid',
          borderColor: '#29356a'
        },
        '&$disabled:not($focused):not($error) $notchedOutline': {
          border: 'none'
        },
        '&:disabled': {
          backgroundColor: '#e3e3e3'
        }
      },
      focused: {}
    },
    MuiInput: {
      root: {
        '&$underline:after': {
          borderBottom: '2px solid #29356a'
        }
      }
    },
    MuiInputBase: {
      input: {
        border: '1px solid #ffff',
        borderRadius: '6px',
        maxHeight: '36px'
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: '6px'
      }
    },
    MuiMobileStepper: {
      root: {
        padding: '0'
      },
      dot: {
        height: '20px',
        width: '20px',
        marginRight: '8px',
        marginLeft: '8px'
      },
      dotActive: {
        backgroundColor: '#29356a'
      }
    }
  }
}
)

export default theme
