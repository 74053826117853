import React, { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Typography, Chip } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { DraftConversationCard } from '../ConversationUtils'
import { setActiveConversationUsers } from '../../../../../redux/actions'

/* ********** Lists the current list of drafts for a user ************* //
    Rendered from ConversationArea.js when the 'view.conversation' is 'drafts'
    --- Contains an action button to navigate back to the 'list' view
    --- Maps through the drafts data object and renders a DraftConversationCard.js for each one
*/
const DraftInbox = (props) => {
  const {
    theme, smScreen, viewTypes, loadNewView, activeConversationID, view, parsedProps, classes,
    handleConfirmPopover, setDeleteDraftID,
    setCurrentForm,
    setEditorOpen
  } = props

  const dispatch = useDispatch()

  const { drafts = [], conversations = [] } = useSelector(state => state.websocket)

  const handleMessageView = (e) => {
    e.preventDefault()

    if (conversations && conversations.length) {
      const firstConversationID = conversations[0].conversationID

      dispatch(setActiveConversationUsers(conversations[0].userListDetails))
      loadNewView(viewTypes.MESSAGE, firstConversationID)
    }
  }

  // If the user Hides or completes all of their drafts, send them to an empty draft view
  useMemo(() => {
    if (drafts) {
      const { view, conversationID } = parsedProps
      if (!drafts.length) {
        if (view && view !== 'empty_draft') {
          loadNewView(viewTypes.EMPTY_DRAFT)
        }
      } else {
        const conversationInDraftList = drafts.find(x => x.conversationID === conversationID)
        if (conversationID && !conversationInDraftList) {
          const userInfo = conversations.find(conversation => conversation.conversationID === drafts[0].conversationID)

          dispatch(setActiveConversationUsers(userInfo.userListDetails))
          loadNewView(viewTypes.DRAFT, drafts[0].conversationID)
        }
      }
    }
  }, [drafts, viewTypes, loadNewView, parsedProps, conversations, dispatch])

  return (
    <Grid item container direction='column'>
      {/* Action Buttons */}
      <Grid
        item
        container
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        style={smScreen ? { marginBottom: '1em' } : { marginBottom: '2em' }}
      >
        <Grid item>
          <Chip
            style={{
              backgroundColor: theme.palette.purple.darkest
            }}
            onClick={handleMessageView}
            label={
              <Typography variant='body1' style={{ fontWeight: 600, color: 'white' }}>
                <ArrowBackIosIcon style={{ fontSize: '12px' }} />
                Messages
              </Typography>
            }
          />
        </Grid>
      </Grid>
      {drafts.length > 0 &&
        drafts.map((conversation) => {
          const { conversationID, Content } = conversation
          // Checks to see which conversation in the list matches the currently selected active conversation
          const activeCard = activeConversationID && conversationID && conversationID === activeConversationID
          return (
            <DraftConversationCard
              key={conversation.conversationID}
              conversationID={conversationID}
              activeConversationID={activeConversationID}
              content={Content}
              theme={theme}
              activeCard={activeCard}
              loadNewView={loadNewView}
              viewTypes={viewTypes}
              view={view}
              classes={classes}
              parsedProps={parsedProps}
              handleConfirmPopover={handleConfirmPopover}
              setDeleteDraftID={setDeleteDraftID}
              setCurrentForm={setCurrentForm}
              setEditorOpen={setEditorOpen}
            />
          )
        })}
      {!drafts.length &&
        <Grid container item direction='row' justifyContent='center' alignItems='center'>
          <Typography variant='caption' style={{ color: theme.palette.grey.dark }}>You do not have any drafts.</Typography>
        </Grid>}

    </Grid>
  )
}

export default DraftInbox
