import React from 'react'
import {
  AppBar, Toolbar, Grid, Button
} from '@material-ui/core'

const BuilderBottomNav = (props) => {
  const {
    activeStep = 0, setActiveStep = () => {},
    steps = [], exitToAdmin = () => {}
  } = props

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    exitToAdmin()
  }

  return (
    <AppBar style={{ top: 'auto', bottom: 0, backgroundColor: 'white' }}>
      <Toolbar>
        <Grid container direction='row' style={{ padding: '1.5em 2em' }}>
          <Grid item container direction='row' justifyContent='flex-start' xs={8}>
            <Grid item>
              <Button variant='outlined' color='primary' style={{ fontWeight: 600, borderWidth: '2px' }} onClick={handleReset}>Cancel</Button>
            </Grid>
          </Grid>
          <Grid item container direction='row' justifyContent='flex-end' xs={4}>
            <Grid item container direction='row' justifyContent='flex-end' xs={10}>
              <Button
                variant='outlined'
                color='primary'
                style={{ marginRight: '2em', fontWeight: 600, borderWidth: '2px' }}
                onClick={handleBack}
                disabled={Boolean(!activeStep)}
              >
                Back
              </Button>
            </Grid>
            <Grid item container xs={2}>
              <Button
                variant='contained'
                color='primary'
                style={{ fontWeight: 600 }}
                disabled={Boolean(activeStep === steps.length - 1)}
                onClick={handleNext}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default BuilderBottomNav
