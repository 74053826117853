import React from 'react'
import {
  Grid, Typography, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions, Divider,
  useTheme
} from '@material-ui/core'
import { CloseRounded, HelpOutlineRounded } from '@material-ui/icons'

// TEMP IMAGES
import topHelpImage from '../../../assets/teamMeeting.svg'
import bottomHelpImage from '../../../assets/happyNews.svg'

const MVPHelpModal = (props) => {
  const { classes, helpModalOpen = false, navHeight, onModalClose = () => { } } = props
  const theme = useTheme()

  return (
    <>
      {/* INFORMATION MODAL (TEMPORARY MVP VERSION) */}
      <Dialog
        open={helpModalOpen}
        maxWidth='md'
        onClose={() => onModalClose()}
        style={{ zIndex: 1500, marginTop: navHeight, overflowY: 'unset' }}
      >
        <DialogTitle disableTypography>
          <Grid container direction='row' alignItems='center'>
            <Grid item xs={11} container direction='row'>
              <HelpOutlineRounded style={{ fontSize: 24, color: theme.palette.purple.darkest, paddingRight: '.5em' }} />
              <Typography
                variant='h4'
                style={{ textTransform: 'none', color: theme.palette.purple.darkest, fontSize: '1.3rem' }}
              >
                What Are Micro-Credentials?
              </Typography>
            </Grid>

            <Grid item xs={1} container direction='row' justifyContent='flex-end'>
              <IconButton style={{ padding: '0px' }} onClick={(e) => { e.preventDefault(); onModalClose() }}>
                <CloseRounded className={classes.customizedButton} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent dividers>
          <Grid container direction='column' spacing={3} style={{ marginTop: '1em' }}>
            {/* top row */}
            <Grid item container direction='row' style={{ paddingBottom: '1em' }}>
              <Grid item xs={7} container direction='column'>
                <Typography gutterBottom variant='h6' style={{ fontWeight: 600, textTransform: 'none', fontSize: 18, color: theme.palette.purple.darkest }}>
                  Welcome to Ed Farm's Exciting New Feature!
                </Typography>
                <Typography variant='h6' style={{ fontWeight: 600, textTransform: 'none', fontSize: 16, color: theme.palette.grey.darker }}>
                  Ed Farm has partnered with the Center for Teaching Quality to design a micro-credential system that can now support competency-based growth for Teacher Fellows and seed innovation at scale for Ed Farm programs.
                </Typography>
              </Grid>
              <Grid item xs={5} container justifyContent='center'>
                <img src={topHelpImage} alt='mc-help-question' style={{ width: '18rem' }} />
              </Grid>
            </Grid>
            <Grid container direction='row' item>
              <Typography gutterBottom style={{ fontSize: 16 }}>
                Micro-credentials assess a discrete skill of an educator that is job-embedded and competency-based. Educators who earn micro-credentials gain credibility, increase opportunities and obtain recognition for skills attained.
                Our micro-credentials support and enhance the Ed Farm Fellowship, offering additional personalized professional development and the opportunity to experience the future of learning.
              </Typography>
            </Grid>

            {/*  bottom row */}
            <Grid item container direction='row' alignItems='center' style={{ margin: '.5em 0', paddingTop: 0 }}>
              <Grid item xs={5} container justifyContent='center'>
                <img src={bottomHelpImage} alt='mc-help-question' style={{ width: '14rem' }} />
              </Grid>
              <Grid item xs={7} container direction='column' style={{ padding: '0 2em' }}>
                <Typography variant='h6' gutterBottom style={{ fontSize: 18, fontWeight: 600, color: theme.palette.purple.darkest, textTransform: 'none', textAlign: 'center' }}>So How Do I Get A Micro-Credential?</Typography>
                <Typography gutterBottom variant='body2' style={{ textAlign: 'justify' }}>
                  <span style={{ fontWeight: 600, fontSize: 16, color: theme.palette.grey.darker }}>1)</span> After selecting the micro-credential you would like to pursue, you will be given a series of questions and requests for demonstrable samples of your work within the micro-credential skillset.
                  You can preview these questions and samples from the summary page for each credential.
                </Typography>
                <Typography gutterBottom variant='body2' style={{ textAlign: 'justify' }}>
                  <span style={{ fontWeight: 600, fontSize: 16, color: theme.palette.grey.darker }}>2)</span> When you have submitted your attempt, an assessor designated by Ed Farm will review your responses and determine if you have satisfactorily met the credentials requirements.
                  <span style={{ fontWeight: 600 }}> You will have three total chances at obtaining the micro-credential.</span> If the assessor feels like your pursuit needs additional work they
                  will provide you feedback to help you on your next attempt.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='primary' onClick={() => onModalClose()}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MVPHelpModal
