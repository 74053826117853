import React, { useState } from 'react'
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography,
  useTheme, Button, IconButton, Avatar, Chip, Paper, Divider, Popover
} from '@material-ui/core'
import {
  CloseRounded, CheckCircle
} from '@material-ui/icons'
import moment from 'moment'
import { sanitizedHTML } from '../../../../utils/functions'
import { mceActionTypes } from '../../../../utils/variables'
import BlankAvatar from '../../../../assets/blank-person.jpeg'

const BuilderVersionDetailsModal = (props) => {
  const {
    classes = {}, handleCloseDetailModal = () => { }, detailModalOpen = false, localFullDetails, localActionData,
    handleOriginStatus = () => { }, handleChangeLogPopClose = () => { }
  } = props
  const theme = useTheme()

  const { mceID = '', versionNum, isActiveVersion = 0, title = '', keyMethod = '', stackName = '', methodComponents = '', resources = '', supportResearch = '', P1 = [], P2 = [], P3 = [] } = localFullDetails
  const { fullName = '', profileAvatarPath = '', actionDate, deletedAt = 0 } = localActionData
  const formatActionDate = moment(actionDate * 1000).format('LL')

  // ******** CHANGE LOG LOGIC ********* //
  // Confirm popper related elements --- //
  const [confirmRestoreAnchorEl, setConfirmRestoreAnchorEl] = useState(null)

  const handleConfirmRestorePopover = (e) => {
    setConfirmRestoreAnchorEl(e.currentTarget)
  }

  const handleConfirmRestorePopClose = () => {
    setConfirmRestoreAnchorEl(null)
  }

  const openConfirmRestorePopover = Boolean(confirmRestoreAnchorEl)

  const restoreVersion = () => {
    handleOriginStatus(mceActionTypes.RESTORED, mceID)
    handleConfirmRestorePopClose()
    handleCloseDetailModal()
    handleChangeLogPopClose()
  }

  return (
    <Dialog
      open={detailModalOpen}
      onClose={handleCloseDetailModal}
      maxWidth='lg'
      fullWidth
    >
      <DialogTitle>
        <Grid container direction='column'>
          <Grid item container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid item xs={11} container direction='row' alignItems='center'>
              <Typography variant='h4' style={{ textTransform: 'none', marginRight: '.5em', fontSize: 22 }}>{`Version ${versionNum}`}</Typography>
              <Typography variant='h4' style={{ textTransform: 'none', marginRight: '1em', fontSize: 18 }}>{formatActionDate}</Typography>
              {Boolean(isActiveVersion) &&
                <Chip
                  icon={<CheckCircle style={{ color: theme.palette.pink.dark }} />}
                  label='Active Version'
                  size='small'
                  variant='outlined'
                  style={{ borderColor: theme.palette.pink.dark, color: theme.palette.pink.dark, fontSize: 12, textTransform: 'none', height: 20 }}
                  classes={{ iconSmall: classes.activeChip, labelSmall: classes.activeLabel }}
                />}
            </Grid>
            <Grid item container direction='row' xs={1} justifyContent='flex-end'>
              <IconButton size='small' onClick={handleCloseDetailModal}>
                <CloseRounded />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item container direction='row' justifyContent='space-between' alignItems='flex-end' style={{ marginTop: '.5em' }}>
            <Grid item xs={9} container direction='column'>
              <Grid item>
                <Typography variant='caption' style={{ textTransform: 'none', fontWeight: 600, color: theme.palette.grey.dark }}>Modified By:</Typography>
              </Grid>
              <Grid item container direction='row' alignItems='center'>
                <Avatar src={deletedAt ? BlankAvatar : profileAvatarPath} style={{ height: 30, width: 30 }} />
                <Typography variant='body1' style={{ textTransform: 'none', fontWeight: 600, color: theme.palette.grey.darker, paddingLeft: '.5em' }}>{deletedAt ? 'Ed Farm User' : fullName}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={3} container direction='row' justifyContent='flex-end'>
              <Button
                variant='contained'
                size='small'
                color='secondary'
                style={{ fontWeight: 600, margin: 'auto 0 0 auto' }}
                disabled={Boolean(isActiveVersion)}
                onClick={(e) => handleConfirmRestorePopover(e)}
              >
                Restore This Version
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid item container direction='column'>
          {/* ********* GENERAL DETAILS *************** */}
          <Grid item container direction='column' style={{ marginBottom: '2em' }}>
            <Paper style={{ width: '-webkit-fill-available', backgroundColor: theme.palette.grey.lighter, padding: '1em' }} elevation={0}>
              <Grid container direction='row'>
                <Grid xs={1} item container direction='column'>
                  <Typography style={{ wordBreak: 'break-word', fontWeight: 600, fontSize: 16, color: theme.palette.grey.darker }}>General Details</Typography>
                </Grid>
                <Grid item container xs={1}>
                  <Divider flexItem variant='middle' orientation='vertical' />
                </Grid>
                <Grid xs={10} item container direction='column' spacing={2}>
                  <Grid item container direction='row' spacing={2}>
                    <Grid item xs={6} container direction='column' spacing={1}>
                      <Grid item container direction='column'>
                        <Typography variant='caption' style={{ color: theme.palette.grey.dark, fontWeight: 600, textDecoration: 'underline' }}>Title</Typography>
                        <Typography variant='body1' style={{ color: theme.palette.grey.dark }}>
                          {title}
                        </Typography>
                      </Grid>
                      <Grid item container direction='column'>
                        <Typography variant='caption' style={{ color: theme.palette.grey.dark, fontWeight: 600, textDecoration: 'underline' }}>Stack</Typography>
                        <Typography variant='body1' style={{ color: theme.palette.grey.dark }}>
                          {stackName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} container direction='column'>
                      <Typography variant='caption' style={{ color: theme.palette.grey.dark, fontWeight: 600, textDecoration: 'underline' }}>Key Method</Typography>
                      <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(keyMethod) }} className={classes.dangerHtml} />
                    </Grid>
                  </Grid>
                  <Grid item container direction='row' spacing={2}>
                    <Grid item xs={6} container direction='column' spacing={1}>
                      <Grid item container direction='column'>
                        <Typography variant='caption' style={{ color: theme.palette.grey.dark, fontWeight: 600, textDecoration: 'underline' }}>Method Components</Typography>
                        <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(methodComponents) }} className={classes.dangerHtml} />
                      </Grid>
                      <Grid item container direction='column'>
                        <Typography variant='caption' style={{ color: theme.palette.grey.dark, fontWeight: 600, textDecoration: 'underline' }}>Resources</Typography>
                        <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(resources) }} className={classes.dangerHtml} />
                      </Grid>
                    </Grid>
                    <Grid item xs={6} container direction='column' spacing={1}>
                      <Typography variant='caption' style={{ color: theme.palette.grey.dark, fontWeight: 600, textDecoration: 'underline' }}>Support Research</Typography>
                      <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(supportResearch) }} className={classes.dangerHtml} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* ********* PART 1 *************** */}
          <Grid item container direction='column' style={{ marginBottom: '2em' }}>
            <Paper style={{ width: '-webkit-fill-available', backgroundColor: theme.palette.grey.lighter, padding: '1em' }} elevation={0}>
              <Grid container direction='row'>
                <Grid xs={1} item container direction='column'>
                  <Typography style={{ wordBreak: 'break-word', fontWeight: 900, fontSize: 16, color: theme.palette.grey.darker }}>Part 1</Typography>
                  <Typography style={{ wordBreak: 'break-word', fontWeight: 600, color: theme.palette.grey.darker }}>Overview Questions</Typography>
                </Grid>
                <Grid item container xs={1}>
                  <Divider flexItem variant='middle' orientation='vertical' />
                </Grid>
                <Grid xs={10} item container direction='column' spacing={3}>
                  {!P1.length &&
                    <Grid item container justifyContent='center' alignItems='center' style={{ height: '100%' }}>
                      <Typography variant='caption' style={{ fontWeight: 600, color: theme.palette.grey.medium }}>No Questions Found...</Typography>
                    </Grid>}
                  {P1.map((p1question) => {
                    const { questionID, questionOrder, question = '', evalCriteria = '' } = p1question
                    return (
                      <Grid item key={`p1-question-${questionID}`} container direction='row' spacing={2}>
                        <Grid item container direction='column' xs={6}>
                          <Typography variant='caption' style={{ color: theme.palette.grey.dark, fontWeight: 600, textDecoration: 'underline' }}>{`${questionOrder}) Question:`}</Typography>
                          <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(question) }} className={classes.dangerHtml} />
                        </Grid>
                        <Grid item container direction='column' xs={6}>
                          <Typography variant='caption' style={{ color: theme.palette.grey.dark, fontWeight: 600, textDecoration: 'underline' }}>Evaluation Criteria</Typography>
                          <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(evalCriteria) }} className={classes.dangerHtml} />
                        </Grid>
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* ********* PART 2 *************** */}
          <Grid item container direction='column' style={{ marginBottom: '2em' }}>
            <Paper style={{ width: '-webkit-fill-available', backgroundColor: theme.palette.grey.lighter, padding: '1em' }} elevation={0}>
              <Grid container direction='row'>
                <Grid xs={1} item container direction='column'>
                  <Typography style={{ wordBreak: 'break-word', fontWeight: 900, fontSize: 16, color: theme.palette.grey.darker }}>Part 2</Typography>
                  <Typography style={{ wordBreak: 'break-word', fontWeight: 600, color: theme.palette.grey.darker }}>Work Samples</Typography>
                </Grid>
                <Grid item container xs={1}>
                  <Divider flexItem variant='middle' orientation='vertical' />
                </Grid>
                <Grid xs={10} item container direction='column' spacing={2}>
                  {!P2.length &&
                    <Grid item container justifyContent='center' alignItems='center' style={{ height: '100%' }}>
                      <Typography variant='caption' style={{ fontWeight: 600, color: theme.palette.grey.medium }}>No Samples Found...</Typography>
                    </Grid>}
                  {P2.sort((a, b) => a.sampleOrder > b.sampleOrder).map((sample, i) => {
                    const { workSampleID, title: sampleTitle = '', sampleOrder, sampleDesc, sampleType, rubricRows = [] } = sample
                    const sampleTypeValue = sampleType && sampleType === 'U' ? 'Upload' : 'Learning Experience Design'
                    return (
                      <Grid key={`sample-${workSampleID}`} item container direction='column' spacing={3} style={{ marginBottom: '.5em' }}>
                        <Grid item container direction='column' spacing={1}>
                          <Grid item>
                            <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: 600 }}>{`Sample ${sampleOrder}:`}</Typography>
                          </Grid>
                          <Grid item container direction='row'>
                            <Grid item container direction='column' xs={4} style={{ marginBottom: '.5em' }}>
                              <Typography variant='caption' style={{ color: theme.palette.grey.dark, fontWeight: 600, textDecoration: 'underline' }}>Title</Typography>
                              <Typography variant='body1' style={{ color: theme.palette.grey.dark }}>
                                {sampleTitle}
                              </Typography>
                            </Grid>
                            <Grid item container direction='column' xs={2} style={{ marginBottom: '.5em' }}>
                              <Typography variant='caption' style={{ color: theme.palette.grey.dark, fontWeight: 600, textDecoration: 'underline' }}>Type</Typography>
                              <Typography variant='body1' style={{ color: theme.palette.grey.dark }}>
                                {sampleTypeValue}
                              </Typography>
                            </Grid>
                            <Grid item container direction='column' xs={6}>
                              <Typography variant='caption' style={{ color: theme.palette.grey.dark, fontWeight: 600, textDecoration: 'underline' }}>Description</Typography>
                              <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(sampleDesc) }} className={classes.dangerHtml} />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item container direction='column' spacing={1}>
                          <Grid item>
                            <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: 600 }}>Rubric</Typography>
                          </Grid>

                          {!rubricRows.length &&
                            <Grid item container justifyContent='center' alignItems='center' style={{ height: '100%' }}>
                              <Typography variant='caption' style={{ fontWeight: 600, color: theme.palette.grey.medium }}>No rubric rows found...</Typography>
                            </Grid>}
                          {rubricRows.map(row => {
                            const { rubricID, proficientText = '', basicText = '', developingText = '' } = row

                            return (
                              <Grid key={`sample-${workSampleID}rubric-${rubricID}`} item container direction='row' spacing={3}>
                                <Grid item container direction='column' xs={4}>
                                  <Typography variant='caption' style={{ color: theme.palette.grey.dark, fontWeight: 600, textDecoration: 'underline' }}>Proficient</Typography>
                                  <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(proficientText) }} className={classes.dangerHtml} />
                                </Grid>
                                <Grid item container direction='column' xs={4}>
                                  <Typography variant='caption' style={{ color: theme.palette.grey.dark, fontWeight: 600, textDecoration: 'underline' }}>Basic</Typography>
                                  <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(basicText) }} className={classes.dangerHtml} />
                                </Grid>
                                <Grid item container direction='column' xs={4}>
                                  <Typography variant='caption' style={{ color: theme.palette.grey.dark, fontWeight: 600, textDecoration: 'underline' }}>Developing</Typography>
                                  {developingText
                                    ? <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(developingText) }} className={classes.dangerHtml} />
                                    : <Typography variant='body1' style={{ color: theme.palette.grey.dark }}>None</Typography>}
                                </Grid>
                              </Grid>
                            )
                          })}
                        </Grid>

                        {i !== P2.length - 1 && <Divider variant='middle' style={{ marginTop: '1em' }} />}
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* ********* PART 3 *************** */}
          <Grid item container direction='column' style={{ marginBottom: '2em' }}>
            <Paper style={{ width: '-webkit-fill-available', backgroundColor: theme.palette.grey.lighter, padding: '1em' }} elevation={0}>
              <Grid container direction='row'>
                <Grid xs={1} item container direction='column'>
                  <Typography style={{ wordBreak: 'break-word', fontWeight: 900, fontSize: 16, color: theme.palette.grey.darker }}>Part 3</Typography>
                  <Typography style={{ wordBreak: 'break-word', fontWeight: 600, color: theme.palette.grey.darker }}>Reflection Questions</Typography>
                </Grid>
                <Grid item container xs={1}>
                  <Divider flexItem variant='middle' orientation='vertical' />
                </Grid>
                <Grid xs={10} item container direction='column' spacing={2}>
                  {!P3.length &&
                    <Grid item container justifyContent='center' alignItems='center' style={{ height: '100%' }}>
                      <Typography variant='caption' style={{ fontWeight: 600, color: theme.palette.grey.medium }}>No Questions Found...</Typography>
                    </Grid>}
                  {P3.map((p3question) => {
                    const { questionID, questionOrder, question = '', evalCriteria = '' } = p3question
                    return (
                      <Grid item key={`p1-question-${questionID}`} container direction='row' spacing={1}>
                        <Grid item container direction='column' xs={6}>
                          <Typography variant='caption' style={{ color: theme.palette.grey.dark, fontWeight: 600, textDecoration: 'underline' }}>{`${questionOrder}) Question:`}</Typography>
                          <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(question) }} className={classes.dangerHtml} />
                        </Grid>
                        <Grid item container direction='column' xs={6}>
                          <Typography variant='caption' style={{ color: theme.palette.grey.dark, fontWeight: 600, textDecoration: 'underline' }}>Evaluation Criteria</Typography>
                          <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(evalCriteria) }} className={classes.dangerHtml} />
                        </Grid>
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='primary'
          style={{ fontWeight: 600 }}
          onClick={handleCloseDetailModal}
        >
          Close
        </Button>
      </DialogActions>

      <Popover
        id={openConfirmRestorePopover ? 'confirmRestore-popover' : undefined}
        open={openConfirmRestorePopover}
        anchorEl={confirmRestoreAnchorEl}
        onClose={handleConfirmRestorePopClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Grid container direction='column' style={{ padding: '1em' }}>
          <Typography
            variant='h4'
            style={{ color: theme.palette.purple.darkest }}
            gutterBottom
          >
            {`Are you sure you wish to restore version ${versionNum}?`}
          </Typography>

          <Typography variant='body1' gutterBottom>This action will immediately activate this version of the micro-credential and make it available to new pursuers.</Typography>
          <Typography variant='body2' gutterBottom style={{ color: 'red' }}>Important: This action will lose any changes you have made to the current form! Be sure you wish to proceed before continuing.</Typography>

          <Grid item container direction='row' justifyContent='flex-end' alignItems='center' style={{ marginTop: '.5em' }}>
            <Button onClick={handleConfirmRestorePopClose} style={{ marginRight: '.3em', textTransform: 'none', fontWeight: '600' }}>Cancel</Button>
            <Button variant='contained' color='primary' style={{ fontWeight: 600 }} onClick={(e) => { e.preventDefault(); restoreVersion() }}>Restore</Button>
          </Grid>
        </Grid>
      </Popover>
    </Dialog>
  )
}

export default BuilderVersionDetailsModal
