import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
  cardBack: {
    borderRadius: '6px'
  }
}))

// NOTE: renders a loading skeleton in the artifact section of the search results popper
//  --- Parent Component: ui/Search/SearchPopper.js
const SearchArtLoadingSkel = () => {
  const classes = useStyles()

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item container direction='column' spacing={1} style={{ paddingRight: '.2em', marginBottom: '.5em' }}>
        <Grid item>
          <Skeleton variant='rect' height='1.5em' width='30%' animation='wave' className={classes.cardBack} />
        </Grid>
        <Grid item>
          <Skeleton variant='rect' height='2.5em' width='100%' animation='wave' className={classes.cardBack} />
        </Grid>
        <Grid item>
          <Skeleton variant='rect' height='2.5em' width='100%' animation='wave' className={classes.cardBack} />
        </Grid>
        <Grid item>
          <Skeleton variant='rect' height='2.5em' width='100%' animation='wave' className={classes.cardBack} />
        </Grid>
      </Grid>
      <Grid item container direction='column' spacing={1} style={{ paddingRight: '.2em', marginBottom: '.5em' }}>
        <Grid item>
          <Skeleton variant='rect' height='1.5em' width='30%' animation='wave' className={classes.cardBack} />
        </Grid>
        <Grid item>
          <Skeleton variant='rect' height='2.5em' width='100%' animation='wave' className={classes.cardBack} />
        </Grid>
        <Grid item>
          <Skeleton variant='rect' height='2.5em' width='100%' animation='wave' className={classes.cardBack} />
        </Grid>
        <Grid item>
          <Skeleton variant='rect' height='2.5em' width='100%' animation='wave' className={classes.cardBack} />
        </Grid>
      </Grid>
      <Grid item container direction='column' spacing={1} style={{ paddingRight: '.2em', marginBottom: '.5em' }}>
        <Grid item>
          <Skeleton variant='rect' height='1.5em' width='30%' animation='wave' className={classes.cardBack} />
        </Grid>
        <Grid item>
          <Skeleton variant='rect' height='2.5em' width='100%' animation='wave' className={classes.cardBack} />
        </Grid>
        <Grid item>
          <Skeleton variant='rect' height='2.5em' width='100%' animation='wave' className={classes.cardBack} />
        </Grid>
        <Grid item>
          <Skeleton variant='rect' height='2.5em' width='100%' animation='wave' className={classes.cardBack} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SearchArtLoadingSkel
