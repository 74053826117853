import React from 'react'
import {
  Typography, Chip, useTheme
} from '@material-ui/core'
import { Cancel, Done } from '@material-ui/icons'

const MCGradingDisplay = (props) => {
  const { gradingValues: { sectionFullyGraded = false, sectionPassed = {}, gradedCount = 0, questionCount = 0 }, classes = {}, fullyGradedEvaluation = false, isResultsView = false } = props
  const theme = useTheme()

  return (
    sectionFullyGraded || fullyGradedEvaluation
      ? (
        <Chip
          label={`Section ${sectionPassed ? 'Passed' : 'Not Passed'}`}
          variant='outlined'
          classes={{ outlined: sectionPassed ? classes.sectionPassedChip : classes.sectionFailedChip }}
          deleteIcon={
            !sectionPassed ? <Cancel style={{ color: '#d32508' }} /> : <Done style={{ color: '#478d05' }} />
          }
          onDelete={() => {}}
        />
      )
      : (
        !isResultsView &&
          <Typography variant='h6' style={{ color: theme.palette.grey.dark }}>{`Completed ${gradedCount} of ${questionCount}`}</Typography>
      )
  )
}

export default MCGradingDisplay
