/* eslint camelcase: off */

import { GET_ONBOARDING_DATA, UPDATE_AUTH_AVATAR } from '../types'
import { trackPromise } from 'react-promise-tracker'
import { parseResponse } from '../../lib'
import log from '../../utils/logger'
import { buildUserAvatarPath, inFellowOtherTypeID } from '../../utils'

// UI Tech Debt: Removed commented out api variable that wasn't being used anywhere

const api = process.env.REACT_APP_API_URL
const apiversion = process.env.REACT_APP_API_VERSION

export const getOnboardingData = (fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { userID, token } = getState().auth

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${api}/v1/onboarding/${userID}`, options))
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) {
        log.info(response.status)
        fireFailure()
      } else {
        const { data: { grades = [], subjects = [], schools = [], defaultAvatars = [] } } = await response.json()

        dispatch({
          type: GET_ONBOARDING_DATA,
          payload: { grades, subjects, schools, defaultAvatars }
        })

        fireSuccess()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const onboardingStepOne = (onboardingData, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { avatarImage, schoolDistrictID, yearsTeaching, isCustom, inFellowTypeID, inFellowTypeOther } = onboardingData

      // Fail inFellowTypeOther if inFellowTypeID comes back as 'Other' and there's no value provided for the name
      const inFellowOtherNameCheck = Boolean(inFellowTypeID && inFellowTypeID === inFellowOtherTypeID && (inFellowTypeOther == null || inFellowTypeOther === ''))

      if (
        !avatarImage || !schoolDistrictID || !yearsTeaching || isCustom == null || inFellowTypeID == null || inFellowOtherNameCheck
      ) {
        return fireFailure()
      }

      const { userID, token } = getState().auth

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ type: 'onboarding-one', data: { ...onboardingData } })
      }

      const response = await trackPromise(window.fetch(`${api}/v1/user/${userID}`, options))
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) {
        log.info(response.status)
        fireFailure()
      } else {
        /* Built a new avatar path and dispatched it to update the auth profileAvatarPath in the Navbar */
        const avatarPath = buildUserAvatarPath(avatarImage, userID)

        dispatch({
          type: UPDATE_AUTH_AVATAR,
          payload: avatarPath
        })

        fireSuccess()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const getInFellowTypes = (fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token } = getState().auth

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await window.fetch(`${api}/${apiversion}/roles/infellows/types`, options)
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        const { data } = await response.json()
        if (data) {
          fireSuccess()
          return data
        }
        fireFailure()
      } else {
        fireFailure()
        log.info(response.status)
      }
      return false
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}
