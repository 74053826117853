import React from 'react'
import { withRouter } from 'react-router-dom'

import { Grid, Typography, Button } from '@material-ui/core'
import { Description as ArtifactIcon } from '@material-ui/icons'
import theme from '../../styles/MuiTheme'

/* NOTES: Message displayed when no results are found for any artifacts in a global search
  -- Parent Component: ui/Search/ResultAreas/ArtifactResults.js
  - Props: handleNoResultsViewAll from SearchBarNav.js

  In depth render tree loacated in SearchBarNav.js
*/
const NoArtifactsAll = (props) => {
  const { handleNoResultsViewAll } = props

  return (
    <Grid item container direction='column' justifyContent='center' style={{ height: '100%', backgroundColor: `${theme.palette.purple.lightest}75` }}>
      <Grid item container direction='row' justifyContent='center' style={{ marginBottom: '1em' }}>
        <Grid item container alignContent='center' justifyContent='center' style={{ backgroundColor: theme.palette.purple.light, borderRadius: '50%', height: '3em', width: '3em' }}>
          <ArtifactIcon style={{ color: theme.palette.purple.darkest, fontSize: '35px' }} />
        </Grid>
      </Grid>

      <Grid item container direction='row' justifyContent='center'>
        <Typography variant='h5' style={{ color: theme.palette.purple.darkest }}>No Results Found</Typography>
      </Grid>

      <Grid item container direction='row' justifyContent='center'>
        <Typography style={{ textAlign: 'center' }}>There were no artifacts matching your criteria.</Typography>
      </Grid>

      <Grid item container direction='row' justifyContent='center'>
        <Button
          color='primary'
          variant='text'
          style={{ textTransform: 'none', fontWeight: '400', color: '#29356a', fontSize: '12px' }}
          onClick={(e) => {
            handleNoResultsViewAll(e, 'all-posts')
          }}
        >
          Click Here to Explore All Artifacts
        </Button>
      </Grid>
    </Grid>
  )
}

export default withRouter(NoArtifactsAll)
