import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'

import { Grid, Typography, Paper, Avatar, useTheme, Chip, Tooltip } from '@material-ui/core'

import { PeopleRounded, CalendarTodayRounded, EventAvailableRounded, VideocamRounded } from '@material-ui/icons'

import { efrSubTypesFormat, efrSubTypes, gradeCategoriesFormat, artifactTypes } from '../../../../utils'

import EFRLogo from '../../../../assets/EFlogoAvatar.png'

// Potential use for card background, subject to EF approval:
// const backgroundPath = 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'100%25\' height=\'100%25\' viewBox=\'0 0 800 400\'%3E%3Cdefs%3E%3CradialGradient id=\'a\' cx=\'396\' cy=\'281\' r=\'514\' gradientUnits=\'userSpaceOnUse\'%3E%3Cstop offset=\'0\' stop-color=\'%23D18\'/%3E%3Cstop offset=\'1\' stop-color=\'%23330000\'/%3E%3C/radialGradient%3E%3ClinearGradient id=\'b\' gradientUnits=\'userSpaceOnUse\' x1=\'400\' y1=\'148\' x2=\'400\' y2=\'333\'%3E%3Cstop offset=\'0\' stop-color=\'%23FA3\' stop-opacity=\'0\'/%3E%3Cstop offset=\'1\' stop-color=\'%23FA3\' stop-opacity=\'0.5\'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect fill=\'url(%23a)\' width=\'800\' height=\'400\'/%3E%3Cg fill-opacity=\'0.4\'%3E%3Ccircle fill=\'url(%23b)\' cx=\'267.5\' cy=\'61\' r=\'300\'/%3E%3Ccircle fill=\'url(%23b)\' cx=\'532.5\' cy=\'61\' r=\'300\'/%3E%3Ccircle fill=\'url(%23b)\' cx=\'400\' cy=\'30\' r=\'300\'/%3E%3C/g%3E%3C/svg%3E")'

const EFRExploreCard = (props) => {
  const { mdScreen, lgScreen, resource } = props
  const theme = useTheme()

  const {
    bannerImagePath = null,
    description = '',
    endDate = 0,
    fullName = '',
    profileAvatarPath = '',
    profileID = '',
    resourceAudience = '',
    artifactID = '',
    resourceType = '',
    startDate = 0,
    title = '',
    userUnavailable
  } = resource

  const isUserUnavailable = Boolean(userUnavailable)

  // Formats the start and end time of the unix timestamp for start and end date of a event subtype resource
  const getEventTimeFormat = (startTime, endTime) => {
    const eventDate = startTime && startTime > 0 ? moment(startTime * 1000).format('MMM. Do, YYYY') : 'N/A'
    if (startTime && endTime && startTime > 0 && endTime > 0) {
      return `${eventDate} @ ${moment(startTime * 1000).format('hh:mm A')} - ${moment(endTime * 1000).format('hh:mm A')}`
    } else {
      return 'N/A'
    }
  }

  // Handles routing the user to a public/read only view of an artifact
  const handlePublicView = async () => {
    const formatType = artifactTypes.EFR

    props.history.push({
      pathname: '/artifact',
      search: `?public=${formatType}&user=${profileID}&artifact=${artifactID}`
    })
  }

  // Formats the resource type into a chip
  const getChipIcon = (type) => {
    switch (type) {
      case efrSubTypes.EFR_EVENT: return <EventAvailableRounded style={{ color: 'white', fontSize: '16px' }} />
      case efrSubTypes.EFR_WEBINAR: return <VideocamRounded style={{ color: 'white', fontSize: '16px' }} />
      case efrSubTypes.EFR_VIDEO: return <VideocamRounded style={{ color: 'white', fontSize: '16px' }} />
      default: return ''
    }
  }

  const getDescriptionFormat = (description) => {
    if (description && Boolean(description.length)) {
      if (description.length > 100) {
        return `${description.substring(0, 99)}...`
      } else {
        return description
      }
    }
  }

  const getTitleFormat = (title) => {
    if (title && Boolean(title.length)) {
      if (mdScreen) {
        if (title.length > 20) {
          return `${title.substring(0, 19)}...`
        } else {
          return title
        }
      } else {
        if (title.length > 45) {
          return `${title.substring(0, 44)}...`
        } else {
          return title
        }
      }
    }
  }

  return (
    <Paper
      style={{
        padding: '1em',
        display: 'flex',
        cursor: 'pointer',
        width: mdScreen ? '50vw' : '100%'
      }}
      onClick={handlePublicView}
      elevation={4}
    >
      <Grid
        container
        direction={mdScreen ? 'column' : 'row'}
        style={{ minHeight: 'fit-content', minWidth: 'fit-content', width: lgScreen ? '40vw' : '30vw' }}
      >
        {/* Left Side Details of resource */}
        <Grid item container direction='column' justifyContent='space-between' xs={null} sm={null} md={null} lg={6} style={{ height: '100%' }}>

          {/* Title/Audience/Time/Description */}
          <Grid item container direction='column'>
            {/* Title */}
            <Grid item container direction='column' alignContent='flex-start' style={{ marginBottom: '1em', marginTop: '.5em' }}>
              {/* Add Tooltip for desktop */}
              <Tooltip
                placement='top'
                title={
                  title && title.length > 59 ? (
                    <Typography>
                      {title}
                    </Typography>) : ''
                }
              >
                <Typography gutterBottom variant='h3' style={{ fontWeight: 600, wordBreak: 'break-word' }}>{getTitleFormat(title)}</Typography>
              </Tooltip>
              <Chip
                label={
                  <Typography style={{ color: 'white', fontWeight: 600 }}>{efrSubTypesFormat[resourceType]}</Typography>
                }
                style={{ marginRight: 'auto', backgroundColor: theme.palette.pink.dark }}
                icon={
                  getChipIcon(resourceType)
                }
              />
            </Grid>
            <Grid item container direction='column' style={{ marginBottom: '.5em' }}>
              {/* If an event: list the time and date */}
              {resource && resourceType && resourceType === efrSubTypes.EFR_EVENT &&
                <Grid item container direction='column' style={{ marginBottom: '.4em' }}>
                  <Grid item container direction='row' alignItems='center'>
                    <CalendarTodayRounded style={{ fontSize: '18px', color: theme.palette.grey.dark, marginRight: '.3em' }} />
                    <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>{getEventTimeFormat(startDate, endDate)}</Typography>
                  </Grid>
                </Grid>}
              {/* Audience Type */}
              <Grid item container direction='column'>
                <Grid item container direction='row' alignItems='center' style={{ marginBottom: '.5em' }}>
                  <PeopleRounded style={{ fontSize: '22px', color: theme.palette.grey.dark, marginRight: '.5em' }} />
                  <Typography variant='h5' style={{ color: theme.palette.grey.dark }}>{gradeCategoriesFormat[resourceAudience]}</Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* Description */}
            <Grid item container direction='row' style={{ marginBottom: '1.5em', width: 'auto', maxWidth: '100%' }}>
              <Typography variant='h5' style={{ fontWeight: 400, wordBreak: 'break-word' }}>
                {getDescriptionFormat(description)}
              </Typography>
            </Grid>
          </Grid>

          {/* Author Name/Avatar */}
          <Grid item container direction='column' alignItems='flex-end'>
            <Grid item container direction='row' alignItems='center'>
              <Avatar src={!profileAvatarPath ? EFRLogo : profileAvatarPath} style={{ height: '2em', width: '2em', marginRight: '.5em' }} />
              <Typography variant='h5' style={{ fontWeight: 600 }}>
                {isUserUnavailable || !fullName ? 'Ed Farm User' : fullName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Right Side Featured Image */}
        {!mdScreen &&
          <Grid
            item
            container
            direction='column'
            xs={null}
            sm={null}
            md={6}
            lg={6}
            justifyContent='center'
            alignItems='center'
            style={{
              padding: '1em',
              position: 'relative',
              display: 'inline-block',
              overflow: 'hidden',
              backgroundColor: bannerImagePath ? 'white' : theme.palette.purple.darkest
            }}
          >
            {bannerImagePath
              ? (
                <div
                  style={{
                    backgroundImage: `url(${bannerImagePath})`,
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat'
                  }}
                />
              )
              : (
                <img src={EFRLogo} alt='ed-farm-default-logo' style={{ position: 'absolute', top: '50%', left: '50%', maxHeight: '100%', minWidth: '100%', transform: 'translate(-50%, -50%)' }} />
              )}
          </Grid>}

      </Grid>
    </Paper>
  )
}

export default withRouter(EFRExploreCard)
