import React from 'react'
import { Popper, Grid, Typography, useTheme, Button, useMediaQuery, Paper, Avatar, Backdrop, ClickAwayListener } from '@material-ui/core'

import { esRecordType } from '../../../utils'

// ** Child Components:
// Loading Skeletons
import SearchUserLoadingSkel from './LoadingSkeletons/SearchUserLoadingSkel'
import SearchResourceLoadSkel from './LoadingSkeletons/SearchResourceLoadSkel'
import SearchArtLoadingSkel from './LoadingSkeletons/SearchArtLoadingSkel'

// Results areas
import UserResults from './ResultAreas/UserResults'
import ArtifactResults from './ResultAreas/ArtifactResults'
import ResourceResults from './ResultAreas/ResourceResults'
import ExpandedUserResults from './ResultAreas/ExpandedUserResults'
// import SearchInputNeeded from './SearchInputNeeded'
// ***

/* NOTES: The base popper element; renders the 3 search sections components or their loading skels inside popper element:
  -- Parent Component: ui/Search/SearchBarNav.js
    -- Child Components:
        - ./LoadingSkeletons/SearchUserLoadingSkel
        - ./LoadingSkeletons/SearchResourceLoadSkel
        - ./LoadingSkeletons/SearchArtLoadingSkel
        - ./ResultAreas/UserResults
        - ./ResultAreas/ExpandedUserResults
        - ./ResultAreas/ArtifactResults
        - ./ResultAreas/ResourceResults
        - ./SearchInputNeeded

  In depth render tree loacated in SearchBarNav.js
*/
const SearchPopper = (props) => {
  // All props received from SearchBarNav.js
  const {
    classes, openSearchPop, popAnchorEl, searchPopoverID,
    popperRef, loadingState, searchResults, arrowRef, setArrowRef,
    handleReset, handleExpand, userView, filterMenuRef, handleSearchPopClose,
    handleViewAllArts, inputRef, handleSearchUsers, handleNoResultsViewAll
  } = props

  // Determines the width of the search input element
  const inputWidth = inputRef ? inputRef.clientWidth : 0

  const theme = useTheme()

  // Deconstruct the seach results
  const {
    userResults = [],
    resourceResults = [],
    artifactResults = {},
    totals: {
      totalUserResults = 0,
      totalResourceResults = 0,
      totalLEDResults = 0,
      totalLWResults = 0,
      totalPROResults = 0,
      totalArtifactResults = 0,
      fullResultsTotal = 0
    } = {}
  } = searchResults

  // Media Queries for mobile/screen size responsive styling:
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const xsScreen = useMediaQuery(theme.breakpoints.down('xs'))

  // **** Ternary Checks:
  // - condition to render user section 'view all' button
  const userViewAllCheck = userResults && Boolean(totalUserResults) && totalUserResults > 4
  // - condition to render resource section 'view all' button
  const resourceViewAllCheck = resourceResults && Boolean(totalResourceResults) && totalResourceResults > 3

  return (
    <Backdrop open={openSearchPop} style={{ zIndex: 0 }}>
      <Popper
        id={searchPopoverID}
        open={openSearchPop}
        anchorEl={popAnchorEl}
        ref={popperRef}
        role={undefined}
        disablePortal
        className={classes.popper}
        modifiers={{
          flip: {
            enabled: false
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'window'
          },
          arrow: {
            enabled: true,
            element: arrowRef
          }
        }}
      >
        <span className={classes.arrow} ref={setArrowRef} />
        <ClickAwayListener onClickAway={(e) => { handleSearchPopClose(e) }}>
          <Paper
            className={classes.popPaperInput}
            style={{ width: !inputWidth ? 'fit-content' : inputWidth }}
            id='paper-search'
          >
            <Grid container direction='column' style={{ padding: '1.5em 2em' }}>
              {/* If the user has chosen to 'view all' user results, display the expanded version */}
              {userView
                ? (
                  <ExpandedUserResults
                    userResults={userResults}
                    smScreen={smScreen}
                    handleReset={handleReset}
                    handleExpand={handleExpand}
                    classes={classes}
                    filterMenuRef={filterMenuRef}
                    xsScreen={xsScreen}
                    handleSearchUsers={handleSearchUsers}
                    totalUserResults={totalUserResults}

                  />

                )
                : (
                  <>
                    {/* Else show the full list of categories */}

                    {/* Search Header */}
                    <Grid item container direction='row' style={{ marginBottom: '1em' }}>
                      <Grid item container xs={6}>
                        <Typography variant='h3' style={{ color: 'black' }}>Search</Typography>
                      </Grid>
                      <Grid item container xs={6} justifyContent='flex-end'>
                        {/* If results are found display a total number form all categories */}
                        {Boolean(fullResultsTotal) && fullResultsTotal > 0 && !loadingState &&
                          <Typography variant='h6' style={{ textTransform: 'none', color: theme.palette.grey.dark }}>{`${fullResultsTotal} Results`}</Typography>}
                      </Grid>
                    </Grid>

                    {/* Search Results */}
                    <Grid container item direction={smScreen ? 'column' : 'row'} spacing={4}>

                      {/* Left Column: Users/Resources */}
                      <Grid item container xs={smScreen ? null : 6} direction='column'>

                        {/* **** Users ***** */}
                        <Grid item container direction='column' style={{ marginBottom: '.5em' }}>
                          <Grid item container direction='row' alignItems='center' style={{ marginBottom: '.5em' }}>
                            <Typography variant='h4' style={{ color: 'black' }}>Users</Typography>
                            {/* If results are found display a total number of user results */}
                            {userResults && Boolean(userResults.length) && !loadingState &&
                              <Avatar className={classes.sectionCounter}>{totalUserResults}</Avatar>}
                          </Grid>
                          <Grid item container direction='column' style={{ marginBottom: '.2em' }}>
                            {/* Render the user section skeleton until the api call is complete, then display the results section */}
                            {loadingState ? <SearchUserLoadingSkel smScreen={smScreen} /> : <UserResults smScreen={smScreen} xsScreen={xsScreen} userResults={userResults} handleReset={handleReset} handleNoResultsViewAll={handleNoResultsViewAll} />}
                          </Grid>
                          <Grid item container direction='row' justifyContent='flex-end'>
                            {/* if the user results are greater than 4, display the view all button */}
                            {!loadingState && userViewAllCheck &&
                              // Testing using both touch and click event handlers
                              // Update: added e.preventDefault to onTouchEnd to prevent the issue that was causing the user to be directed to the last search result (for users) when clicking the View All... button
                              <Button style={{ textTransform: 'none', fontWeight: '600', cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); handleExpand(e) }} onTouchEnd={(e) => { e.preventDefault(); handleExpand(e) }}>View All...</Button>}
                          </Grid>
                        </Grid>

                        {/* ***** Resources ***** */}
                        <Grid item container direction='column'>
                          <Grid item container direction='row' alignItems='center' style={{ marginBottom: '.5em' }}>
                            <Typography variant='h4' style={{ color: 'black' }}>Resources</Typography>
                            {/* If results are found display a total number of resource results */}
                            {resourceResults && Boolean(resourceResults.length) && !loadingState &&
                              <Avatar className={classes.sectionCounter}>{totalResourceResults}</Avatar>}
                          </Grid>
                          <Grid item container direction='column' spacing={1} style={{ marginBottom: '.2em' }}>
                            {/* Render the resource section skeleton until the api call is complete, then display the results section */}
                            {loadingState ? <SearchResourceLoadSkel smScreen={smScreen} /> : <ResourceResults resourceResults={resourceResults} handleReset={handleReset} handleViewAllArts={handleViewAllArts} handleNoResultsViewAll={handleNoResultsViewAll} />}
                          </Grid>
                          <Grid item container direction='row' justifyContent='flex-end'>
                            {!loadingState && resourceViewAllCheck &&
                              <Button onClick={() => handleViewAllArts(esRecordType.EF)} style={{ textTransform: 'none', fontWeight: '600' }}>View All...</Button>}
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Right Column: Artifacts */}
                      <Grid item container direction='column' xs={smScreen ? null : 6}>
                        <Grid item container direction='row' alignItems='center' style={{ marginBottom: '.5em', width: '100%' }}>
                          <Typography variant='h4' style={{ color: 'black' }}>Artifacts</Typography>
                          {/*  If the subarrays for the artifact results contain any results, display the total */}
                          {artifactResults && Boolean(Object.keys(artifactResults).length) && totalArtifactResults > 0 && !loadingState &&
                            <Avatar className={classes.sectionCounter}>{totalArtifactResults}</Avatar>}
                        </Grid>
                        {/* Render the artifact section skeleton until the api call is complete, then display the results section */}
                        {loadingState
                          ? (
                            <SearchArtLoadingSkel smScreen={smScreen} />
                          )
                          : (
                            <ArtifactResults
                              artifactResults={artifactResults}
                              handleReset={handleReset}
                              smScreen={smScreen}
                              xsScreen={xsScreen}
                              handleViewAllArts={handleViewAllArts}
                              totalLEDResults={totalLEDResults}
                              totalLWResults={totalLWResults}
                              totalPROResults={totalPROResults}
                              handleNoResultsViewAll={handleNoResultsViewAll}
                            />
                          )}
                      </Grid>
                    </Grid>
                  </>
                )}
              {/* </>
                )} */}
            </Grid>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Backdrop>
  )
}

export default SearchPopper
