import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingScreen from '../ui/tools/LoadingScreen'
import { validateUserEmail, logout } from '../../redux/actions'
import { BaseToast } from '../ui/tools'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import log from '../../utils/logger'
import { responseTypes } from '../../lib'

/**
 * Reads from url params to get authentication token, accountID, and user/supervisorID
 * @returns redirect to application if all params are found, if not then redirects to login
 */
const Validation = (props) => {
  const dispatch = useDispatch()
  const authObject = useSelector(state => state.auth)
  const { profileID: authProfileID } = authObject

  useEffect(() => {
    const fireNotification = async (response) => {
      // fire a notification and route appropriate to the response received from the api
      await BaseToast(response.error,
        response.error ? 'Error validating email' : 'Email successfully validated!',
        response.error ? response.message : null)
      props.history.push({
        pathname: '/'
      })
      if (authProfileID && response.type === responseTypes.SUCCESS) { dispatch(logout()) }
    }

    const validationFailure = async () => {
      await BaseToast(true,
        'Error validating email',
        'Your email validation link is missing some information. Please check the link you copied from your email and try again, or generate a new one.')

      props.history.push({
        pathname: '/'
      })
    }

    const getValidationParams = async () => {
      try {
        if (props.location && props.location.search) {
          const parsedProps = queryString.parse(props.location.search)

          const { profileID, validationKey } = parsedProps

          if (!profileID || !validationKey) {
            log.warn('Could not find params from link')
            // route back to confirmation needed page
            await validationFailure()
          } else {
            log.debug('we have all params')
            log.debug(profileID)
            log.debug(validationKey)
            dispatch(validateUserEmail(profileID, validationKey, fireNotification))
              .catch(err => log.error(err))
          }
        } else {
          await validationFailure()
        }
      } catch (err) {
        console.error(err)
        await validationFailure()
      }
    }

    getValidationParams()
  }, [dispatch, props.location, props.history, authProfileID])

  return (<LoadingScreen />)
}

export default withRouter(Validation)
