import React, { useState } from 'react'
import {
  Grid, Paper, Typography, useTheme, AppBar, Tab, Tabs, Card, CardHeader, CardContent, Chip, Button, Tooltip, withStyles, makeStyles, useMediaQuery
} from '@material-ui/core'
import { AttachFile, Link as LinkIcon, ListAlt, Image, InsertDriveFile } from '@material-ui/icons'
import { chunk } from 'lodash'
import { handleExternalLinkClick } from '../../../utils'
import { contentOptions, artifactTypes } from '../../../utils/variables'
import { artifactImageFileExtensions } from '../../../utils/uploadFunctions'
import { ExpandedGrades } from '../tools'

// Icon for 'Subjects'
const ModifiedChalkboardIcon = (props) => {
  const { styles } = props
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='40'
      viewBox='0 0 111 111'
    >
      <g transform='translate(-628 -124)'>
        <circle className='a' cx='50' cy='50' r='50' transform='translate(628 124)' fill={`${styles.mainColor}12`} />
        <g transform='translate(645 149)'>
          <path
            className='b'
            d='M19.5,33a2.145,2.145,0,0,0-.662.1A12.367,12.367,0,0,1,15,33.75a12.378,12.378,0,0,1-3.839-.648A2.139,2.139,0,0,0,10.5,33,10.5,10.5,0,0,0,0,43.558,4.488,4.488,0,0,0,4.5,48h21A4.488,4.488,0,0,0,30,43.558,10.5,10.5,0,0,0,19.5,33ZM15,30a9,9,0,1,0-9-9A9,9,0,0,0,15,30ZM55.5,0h-36A4.582,4.582,0,0,0,15,4.649V9a11.864,11.864,0,0,1,6,1.669V6H54V33H48V27H36v6H28.851a11.949,11.949,0,0,1,3.721,6H55.5A4.582,4.582,0,0,0,60,34.351V4.649A4.582,4.582,0,0,0,55.5,0Z'
            transform='translate(0.002)'
            fill={styles.mainColor}
          />
        </g>
      </g>
    </svg>
  )
}

const useStyles = makeStyles((theme) => ({
  sectionSpacing: {
    marginTop: '2em'
  },
  resourceHeader: {
    position: 'sticky',
    top: 0,
    left: 0,
    bottom: 0,
    display: 'flex',
    width: '100%',
    transform: 'inherit',
    backgroundColor: 'white',
    zIndex: 1
  },
  linkButtons: {
    textTransform: 'none',
    textAlign: 'left',
    color: theme.palette.purple.darkest,
    fontWeight: '600',
    fontSize: '15px',
    textDecoration: 'underline',
    wordBreak: 'break-all',
    padding: 0
  }
}))

// Tab Controller Styling
const ContentTabs = withStyles({
  root: {
    justifyContent: 'center'
  },
  indicator: {
    backgroundColor: 'white',
    marginBottom: '.5em'
  },
  flexContainer: {
    justifyContent: 'space-around'
  }
})(Tabs)

// Individual Tab Styles
const ContentTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: 600,
    '&:hover': {
      color: 'white',
      opacity: 1
    },
    '&$selected': {
      color: 'white'
    },
    '&:focus': {
      color: 'white'
    }
  },
  selected: {}
}))((props) => <Tab disableRipple {...props} />)

// Main component displaying the styled content of an LED artifact
const LEDDisplayDetails = (props) => {
  const {
    styles, formattedInfo, handleArtifactViewChange = () => { }, creatorID = '', artifactType = '', publicPreview = false,
    isForMCE = false
  } = props

  const theme = useTheme()
  const classes = useStyles()

  // Small screen size check
  const smScreenDown = useMediaQuery(theme.breakpoints.down('sm'))

  // Small/Medium screen size check
  const smScreenUp = useMediaQuery(theme.breakpoints.up('sm'))
  const mdScreenUp = useMediaQuery(theme.breakpoints.up('md'))

  // Set which content tab is diplayed
  const [tabValue, setTabValue] = useState(contentOptions.ENGAGE)

  const [gradesOpen, setGradesOpen] = useState(false)

  // LED learning targets default length and local state that sets the length
  const defaultTargetLength = 3
  const [targetLength, setTargetLength] = useState(defaultTargetLength)

  // Displays LED's LW if it has one, otherwise renders the default 'no LW' component
  const handleLEDLWDisplay = () => {
    if (formattedInfo && formattedInfo.learnerWorkDetails) {
      const { learnerWorkTitle = '', learnerWorkID = '', workType = '', attachments = [] } = formattedInfo.learnerWorkDetails
      const attachmentCount = attachments?.length || 0

      return (
        <Card style={{ width: '100%' }}>
          <div
            style={{ cursor: publicPreview || isForMCE ? 'default' : 'pointer' }}
            onClick={(e) => {
              if (!publicPreview && !isForMCE) {
                e.preventDefault()
                handleArtifactViewChange(e, artifactTypes.LW, learnerWorkID, creatorID)
              }
            }}
          >
            <CardHeader
              title={
                <Grid item container justifyContent='flex-start'>
                  <Typography variant='h5' style={{ color: 'white', fontSize: '1.1rem', textTransform: 'none', textAlign: 'left', marginLeft: '.2em', wordBreak: 'break-word' }}>
                    {smScreenUp && learnerWorkTitle && learnerWorkTitle.length > 30 ? `${learnerWorkTitle.substring(0, 30)}...`
                      : smScreenDown && learnerWorkTitle && learnerWorkTitle.length > 20 ? `${learnerWorkTitle.substring(0, 20)}...`
                        : learnerWorkTitle}
                  </Typography>
                </Grid>
              }
              style={{
                backgroundColor: '#1A7FA4',
                alignItems: 'flex-start',
                padding: '0.6em'
              }}
            />
            <CardContent>
              <Grid container direction='column'>
                <Grid item container direction='column'>
                  <Grid item container direction='row' alignItems='center'>
                    <ListAlt style={{ fontSize: '18px', color: theme.palette.grey.dark, marginRight: '.2em' }} />
                    <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>Type of Work:</Typography>
                  </Grid>
                  <Grid item container style={{ marginTop: '.1em' }}>
                    <Typography variant='h6' style={{ color: theme.palette.grey.dark, fontWeight: 400, textTransform: 'none', wordBreak: 'break-word' }}>
                      {workType || 'None'}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item container direction='column' style={{ marginTop: '1em' }}>
                  <Grid item container direction='row' alignItems='center'>
                    <AttachFile style={{ fontSize: '16px', transform: 'rotate(-25deg)', color: theme.palette.grey.dark, marginRight: '.2em' }} />
                    <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>{`${attachmentCount === 0 ? 'No' : attachmentCount} attachment(s)`}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </div>
        </Card>
      )
    } else {
      return (
        <Paper elevation={0} style={{ padding: '2.5em', border: `1px solid ${theme.palette.grey.medium}` }}>
          <Grid item container alignContent='center' justifyContent='center'>
            <Typography variant='h6' style={{ color: theme.palette.grey.medium, fontweight: 400, textTransform: 'none', textAlign: 'center' }}>This artifact has no associated Learner Work.</Typography>
          </Grid>
        </Paper>
      )
    }
  }

  // Takes the ISTE Standards, if they are provided, and splits them into two arrays in order to format them as 2 columns. If there are no ISTE Standards, it shows nothing
  const handleFormatISTEStandards = (standards) => {
    if (formattedInfo && standards && Boolean(standards.length)) {
      if (standards.length > 1) {
        return (
          <>
            {chunk(standards, 2).map((chunkArr, i) => {
              return (
                <Grid key={`iste-arr-${i}`} item container direction='row' justifyContent='space-between' style={{ marginTop: '.2em' }}>
                  {chunkArr.map(standard => {
                    return (
                      // Temporary placeholder key to prevent error that was occuring with standards sharing the same key
                      <Grid key={`iste-${standard.replace(' ', '-')}`} item xs={6}>
                        <Typography variant='h6' style={{ color: theme.palette.grey.dark, fontWeight: 400, textTransform: 'none' }}>{standard}</Typography>
                      </Grid>
                    )
                  })}
                </Grid>
              )
            })}
          </>
        )
      } else {
        return (
          <Grid item>
            <Typography variant='h6' style={{ color: theme.palette.grey.dark, fontWeight: 400, textTransform: 'none' }}>{standards[0]}</Typography>
          </Grid>
        )
      }
    }

    return (
      <Grid item>
        <Typography variant='h6' style={{ color: theme.palette.grey.dark, fontWeight: 400, textTransform: 'none' }}>N/A</Typography>
      </Grid>
    )
  }

  // Function that determines whether to show the 'See More' or 'See Less' button in learning targets
  const handleToggleLength = () => {
    let newLength = defaultTargetLength
    if (targetLength === defaultTargetLength) {
      newLength = formattedInfo.targets.length
    }
    setTargetLength(newLength)
  }

  // Determines how to format learning targets based on their length
  const handleFormatTargets = () => {
    if (formattedInfo && formattedInfo.targets && formattedInfo.targets.length !== 0) {
      return (
        <Grid item container>
          <ul
            style={{
              paddingInlineStart: '1em',
              marginBottom: 0,
              marginTop: '0.3em'
            }}
          >
            {formattedInfo.targets.slice(0, targetLength).map((target, i) => {
              return (
                <li key={`target-${target.targetID}`} style={{ color: theme.palette.pink.dark, marginBottom: '0.5em' }}>
                  <span style={{ color: 'black' }}>
                    <Typography style={{ color: theme.palette.grey.darker, fontWeight: 400, textTransform: 'none', wordBreak: 'break-word' }}>{target.targetName}</Typography>
                  </span>
                </li>
              )
            })}
          </ul>

          {formattedInfo.targets.length > defaultTargetLength &&
            <Grid item container justifyContent='center'>
              <Button style={{ color: theme.palette.grey.dark, fontWeight: 600, textTransform: 'none', padding: 0 }} onClick={() => handleToggleLength()}>{targetLength <= defaultTargetLength ? 'See more...' : 'See Less...'}</Button>
            </Grid>}
        </Grid>
      )
    } else {
      return (
        <Grid item container>
          <ul
            style={{
              paddingInlineStart: '1em',
              marginBottom: 0,
              marginTop: '0.3em'
            }}
          >
            <li style={{ color: theme.palette.pink.dark }}>
              <span style={{ color: 'black' }}>
                <Typography>N/A</Typography>
              </span>
            </li>
          </ul>
        </Grid>
      )
    }
  }

  // Determines which icon do display in attachments based on their file type
  // Make array of file type and use .includes() to check instead of the ||s
  const handleAttachmentIcon = (attachment) => {
    const fileType = attachment.fileName.slice(attachment.fileName.lastIndexOf('.') + 1)

    if (fileType) {
      if (Object.keys(artifactImageFileExtensions).includes(fileType.toUpperCase())) {
        return (
          <Image style={{ color: theme.palette.pink.dark, height: '15px' }} />
        )
      } else {
        return (
          <InsertDriveFile style={{ color: theme.palette.pink.dark, height: '15px' }} />
        )
      }
    }
  }

  // Formatting and displaying attachments, if there are any
  const handleFormatAttachments = () => {
    if (formattedInfo && formattedInfo.attachments && formattedInfo.attachments.length !== 0) {
      return (
        <Grid container alignItems='center' justifyContent='flex-start' spacing={1} direction='row' style={{ marginTop: '0.4em' }}>
          {formattedInfo.attachments.map((attachment) => {
            return (
              <Grid key={`attachment-${attachment.attachmentID}`} item style={{ marginBottom: '0.3em' }}>
                <a href={attachment.filePath} target='blank' rel='noopener' style={{ textDecoration: 'none' }}>
                  <Chip
                    variant='outlined'
                    clickable
                    size='small'
                    style={{ cursor: 'pointer', padding: '1em', borderRadius: '0.5em', border: `2px solid ${theme.palette.pink.dark}` }}
                    icon={handleAttachmentIcon(attachment)}
                    label={
                      <Tooltip title={attachment && attachment.fileName && attachment.fileName.length > 15 ? attachment.fileName : ''}>
                        <Typography variant='caption' style={{ color: theme.palette.pink.dark, fontWeight: '600', fontSize: '15px' }}>{attachment && attachment.fileName && attachment.fileName.length > 10 ? `${attachment.fileName.substring(0, 7)}...` : attachment.fileName}</Typography>
                      </Tooltip>
                    }
                  />
                </a>
              </Grid>

            )
          })}
        </Grid>
      )
    } else {
      return (
        <Grid item container justifyContent='center' alignItems='center' style={{ padding: '3em' }}>
          <Typography variant='caption' style={{ textAlign: 'center', color: theme.palette.grey.medium }}>No Attachments.</Typography>
        </Grid>
      )
    }
  }

  // Retrieve links attached to an LED if there are any and format the content, else show default no content view
  const handleFormatLinks = () => {
    if (formattedInfo && formattedInfo.links && formattedInfo.links.length !== 0) {
      return (
        <Grid item container direction='column' style={{ padding: '0.6em' }}>
          {formattedInfo.links.map((link) => {
            return (
              <Grid key={`link-${link.linkID}`} item style={{ marginBottom: '0.2em' }}>
                <Button
                  variant='text'
                  className={classes.linkButtons}
                  onClick={async () => await handleExternalLinkClick(link.link)}
                >
                  {link.link}
                </Button>
              </Grid>

            )
          })}
        </Grid>
      )
    } else {
      return (
        <Grid item container justifyContent='center' alignItems='center' style={{ padding: '3em' }}>
          <Typography variant='caption' style={{ textAlign: 'center', color: theme.palette.grey.medium }}>No Links.</Typography>
        </Grid>
      )
    }
  }

  // Renders full content or no content based on which condition each section meets
  const handleContentDisplay = (content) => {
    switch (content) {
      // Grades
      case contentOptions.GRADES: {
        return <ExpandedGrades grades={formattedInfo.grades} artifactType={artifactType} gradesOpen={gradesOpen} setGradesOpen={setGradesOpen} styles={styles} />
      }
      // Subject Name
      case contentOptions.SUBJECT:
        if (formattedInfo && formattedInfo.coreSubject) {
          const { coreSubject = '' } = formattedInfo
          return coreSubject
        } else {
          return 'N/A'
        }

        // Completion Time
      case contentOptions.COMPLETED_AT:
        if (formattedInfo && formattedInfo.completionTime) {
          return `${formattedInfo.completionTime} week(s)`
        } else {
          return 'N/A'
        }

        // Work Type
      case contentOptions.WORK_TYPE:
        if (formattedInfo && formattedInfo.workType) {
          if (formattedInfo.workType === 'both') {
            return 'Individual & Group'
          }
          return formattedInfo.workType
        } else {
          return 'N/A'
        }

        // Tech Needed
      case contentOptions.TECH:
        if (formattedInfo && formattedInfo.techNeeded) {
          return formattedInfo.techNeeded
        } else {
          return 'N/A'
        }

        // ISTE Standards
      case contentOptions.ISTE_STANDARDS: {
        return handleFormatISTEStandards(formattedInfo.ISTEStandards)
      }
      // Overview & Purpose
      case contentOptions.OVERVIEW:
        if (formattedInfo && formattedInfo.overview) {
          return formattedInfo.overview
        } else {
          return 'N/A'
        }

        // Learning Targets
      case contentOptions.TARGETS: {
        return handleFormatTargets()
      }

      // State Standards
      case contentOptions.STATE_STANDARDS:
        if (formattedInfo && formattedInfo.stateStandards) {
          return formattedInfo.stateStandards
        } else {
          return 'None Provided.'
        }

        // Engage Tab
      case contentOptions.ENGAGE:
        if (formattedInfo && formattedInfo.engageContent) {
          return formattedInfo.engageContent
        } else {
          return 'N/A'
        }

        // Investigate Tab
      case contentOptions.INVESTIGATE:
        if (formattedInfo && formattedInfo.investigateContent) {
          return formattedInfo.investigateContent
        } else {
          return 'N/A'
        }

        // Create Tab
      case contentOptions.CREATE:
        if (formattedInfo && formattedInfo.createContent) {
          return formattedInfo.createContent
        } else {
          return 'N/A'
        }

        // Act Tab
      case contentOptions.ACT:
        if (formattedInfo && formattedInfo.actContent) {
          return formattedInfo.actContent
        } else {
          return 'N/A'
        }

        // Attachments
      case contentOptions.ATTACHMENTS: {
        return handleFormatAttachments()
      }

      // Links
      case contentOptions.LINKS: {
        return handleFormatLinks()
      }

      default: return 'N/A'
    }
  }

  return (
    <Grid item container direction='column'>
      {/* ************ BLOCK 1: grades/subjects/small details/lw ************* */}
      <Grid item container direction={smScreenDown ? 'column' : 'row'} spacing={3}>

        {/* Grades/Subjects/LW */}
        <Grid xs={smScreenDown ? null : 6} item container direction='column' style={{ marginBottom: '0.1em' }}>
          {/* Grades and Subjects */}
          <Grid item container direction='row'>

            {/* Grades */}
            <Grid xs={6} item container direction='row'>
              {handleContentDisplay(contentOptions.GRADES)}
            </Grid>

            {/* Subject */}
            <Grid xs={6} item container direction='row'>
              <Grid item container direction='column'>
                <Grid item container direction='row' alignItems='center'>
                  {/* Subject Icon */}
                  <Grid item xs={3}>
                    <ModifiedChalkboardIcon styles={styles} />
                  </Grid>
                  {/* Subject Value */}
                  <Grid item xs={9} style={{ paddingBottom: '0.3em' }}>
                    <Typography variant='h6' noWrap style={{ textOverflow: 'ellipsis', textTransform: 'none', color: 'black', fontWeight: '600', paddingLeft: '.5em' }}>
                      {handleContentDisplay(contentOptions.SUBJECT)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Learner Work */}
          <Grid item container direction='column' style={{ marginTop: '.5em' }}>
            <Grid item container direction='row'>
              <Typography variant='h6' style={{ color: theme.palette.grey.dark, fontWeight: 400, textTransform: 'none' }}>
                Learner Work:
              </Typography>
            </Grid>
            <Grid item container style={{ marginTop: '.5em', paddingRight: mdScreenUp ? '2.5em' : 0 }}>
              {handleLEDLWDisplay()}
            </Grid>
          </Grid>
        </Grid>

        {/* Small Details */}
        <Grid xs={12} sm={12} md={6} item container direction='column'>
          <Paper elevation={0} style={{ height: '100%', border: `1px solid ${theme.palette.grey.medium}`, padding: '.8em' }}>

            {/* Comp Time/WorkType */}
            <Grid item container direction='row' style={{ marginBottom: '.2em' }}>
              {/* Completion Time */}
              <Grid xs={12} sm={6} item container direction='column'>
                <Grid item>
                  <Typography variant='h6' style={{ color: styles.mainColor, fontWeight: 600, textTransform: 'none' }}>Completion Time:</Typography>
                </Grid>
                <Grid item>
                  <Typography variant='h6' style={{ color: theme.palette.grey.dark, fontWeight: 400, textTransform: 'none' }}>{handleContentDisplay(contentOptions.COMPLETED_AT)}</Typography>
                </Grid>
              </Grid>
              {/* Work type */}
              <Grid xs={12} sm={6} item container direction='column'>
                <Grid item>
                  <Typography variant='h6' style={{ color: styles.mainColor, fontWeight: 600, textTransform: 'none' }}>Work Type:</Typography>
                </Grid>
                <Grid item>
                  <Typography variant='h6' style={{ color: theme.palette.grey.dark, fontWeight: 400, textTransform: 'capitalize' }}>{handleContentDisplay(contentOptions.WORK_TYPE)}</Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* Tech Needed */}
            <Grid item container direction='column' style={{ marginBottom: '.2em' }}>
              <Grid item>
                <Typography variant='h6' style={{ color: styles.mainColor, fontWeight: 600, textTransform: 'none' }}>Technology Needed:</Typography>
              </Grid>
              <Grid item>
                <Typography variant='h6' style={{ color: theme.palette.grey.dark, fontWeight: 400, textTransform: 'none', wordBreak: 'break-word' }}>{handleContentDisplay(contentOptions.TECH)}</Typography>
              </Grid>
            </Grid>

            {/* ISTE Standards */}
            <Grid item container direction='column' style={{ marginBottom: '.2em' }}>
              <Grid item>
                <Typography variant='h6' style={{ color: styles.mainColor, fontWeight: 600, textTransform: 'none' }}>ISTE Standards:</Typography>
              </Grid>
              {/* ISTE Formatting */}
              {handleContentDisplay(contentOptions.ISTE_STANDARDS)}
            </Grid>

          </Paper>
        </Grid>
      </Grid>

      {/* ************ BLOCK 2: Overview and Purpose ***********  */}
      <Grid item container direction='column' className={classes.sectionSpacing}>
        {/* Overview Header */}
        <Grid item container>
          <Typography variant='h6' style={{ fontWeight: 600, textTransform: 'none' }}>Overview and Purpose:</Typography>
        </Grid>
        <Grid item container style={{ marginTop: '.5em' }}>
          {/* Overview Content */}
          <Paper elevation={0} style={{ backgroundColor: theme.palette.purple.lightest, padding: '.8em', maxHeight: '12vh', overflowY: 'auto', width: '100%' }}>
            <Typography style={{ color: theme.palette.grey.dark, wordBreak: 'break-word' }}>{handleContentDisplay(contentOptions.OVERVIEW)}</Typography>
          </Paper>
        </Grid>
      </Grid>

      {/* ***************** BLOCK 3: Learning Targets **************** */}
      <Grid item container direction='column' className={classes.sectionSpacing}>
        {/* Targets Header */}
        <Grid item container>
          <Typography variant='h6' style={{ fontWeight: 600, textTransform: 'none' }}>Learning Targets:</Typography>
        </Grid>

        {/* Targets Bullet List */}
        {handleContentDisplay(contentOptions.TARGETS)}
      </Grid>

      {/* **************** BLOCK 4: State Standards ***************** */}
      <Grid item container direction='column' style={{ marginTop: '1.1em', marginBottom: '0.1em' }}>
        {/* Standards Header */}
        <Grid item container>
          <Typography variant='h6' style={{ fontWeight: 600, textTransform: 'none' }}>State Standards:</Typography>
        </Grid>
        {/* Standards - No Content */}
        <Grid item container>
          <Typography style={{ color: theme.palette.grey.dark, marginTop: '0.2em', wordBreak: 'break-word' }}>{handleContentDisplay(contentOptions.STATE_STANDARDS)}</Typography>
        </Grid>
      </Grid>

      {/* *************** BLOCK 5: Engage/Investigate/Create/Act Tabs ****** */}
      <Grid item container direction='column' className={classes.sectionSpacing}>
        <Paper style={{ width: '100%', flexGrow: 1 }}>
          {/* App bar containing the tab headers */}
          <AppBar elevation={0} position='static' style={{ backgroundColor: theme.palette.pink.dark, borderRadius: '6px 6px 0 0' }}>
            {/* Styled tab controller */}
            <ContentTabs
              value={tabValue}
              onChange={(e, newValue) => { setTabValue(newValue) }}
              variant='scrollable'
              scrollButtons='auto'
              aria-label='scrollable auto tabs example'
            >
              {/* Individual Styled Tabs */}
              <ContentTab label='Engage' value={contentOptions.ENGAGE} onClick={() => { setTabValue(contentOptions.ENGAGE) }} />
              <ContentTab label='Investigate' value={contentOptions.INVESTIGATE} onClick={() => { setTabValue(contentOptions.INVESTIGATE) }} />
              <ContentTab label='Create' value={contentOptions.CREATE} onClick={() => { setTabValue(contentOptions.CREATE) }} />
              <ContentTab label='Act' value={contentOptions.ACT} onClick={() => { setTabValue(contentOptions.ACT) }} />
            </ContentTabs>
          </AppBar>

          {/* Tab Content */}
          <Grid item container style={{ padding: '1.1em', maxHeight: '30vh', overflowY: 'auto', scrollbarGutter: 'stable both-edges' }}>
            <Typography style={{ wordBreak: 'break-word' }}>{handleContentDisplay(tabValue)}</Typography>
          </Grid>
        </Paper>
      </Grid>

      {/* ******************* BLOCK 6: Resources *************** */}
      <Grid item container direction='column' className={classes.sectionSpacing}>
        {/* Resource Header */}
        <Grid item container style={{ marginBottom: '.5em' }}>
          <Typography variant='h6' style={{ fontWeight: 600, textTransform: 'none' }}>Resources:</Typography>
        </Grid>

        {/* Resource Boxes */}
        <Grid item container direction='row' spacing={3}>

          {/* Attachments */}
          <Grid xs={smScreenDown ? 12 : 6} item container direction='column'>
            <Paper style={{ maxHeight: '23vh', height: '100%', padding: '.5em', overflowY: 'auto', paddingTop: 0 }}>
              <Grid item container direction='column'>
                {/* Attachment Header */}
                <Grid item container direction='row' alignItems='center' className={classes.resourceHeader} style={{ paddingTop: '0.5em' }}>
                  <AttachFile style={{ fontSize: '14px', transform: 'rotate(45deg)', color: theme.palette.grey.dark, marginRight: '.3em', marginTop: '.2em' }} />
                  <Typography variant='caption' style={{ color: theme.palette.grey.dark, fontSize: '13px', marginBottom: '0.1em' }}>Attachments</Typography>
                </Grid>
                {/* Attachments - No Content */}
                {handleContentDisplay(contentOptions.ATTACHMENTS)}
              </Grid>
            </Paper>
          </Grid>

          {/* Links */}
          <Grid xs={smScreenDown ? 12 : 6} item container direction='column'>
            <Paper style={{ maxHeight: '23vh', height: '100%', padding: '.5em', overflowY: 'auto', paddingTop: 0 }}>
              <Grid item container direction='column'>
                {/* Links Header */}
                <Grid item container direction='row' alignItems='center' className={classes.resourceHeader} style={{ paddingTop: '0.5em' }}>
                  <LinkIcon style={{ fontSize: '14px', color: theme.palette.grey.dark, marginRight: '.3em', marginTop: '.3em' }} />
                  <Typography variant='caption' style={{ color: theme.palette.grey.dark, fontSize: '13px' }}>Links</Typography>
                </Grid>
                {/* Links - No Content */}
                {handleContentDisplay(contentOptions.LINKS)}
              </Grid>
            </Paper>
          </Grid>

        </Grid>
      </Grid>
    </Grid>
  )
}

export default LEDDisplayDetails
