import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Grid, Paper, Typography, IconButton, Button, Avatar,
  Accordion, AccordionSummary
} from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import CameraAltIcon from '@material-ui/icons/CameraAlt'
import BubbleBack from '../../../assets/default-cover-photo.png'
import Logo from '../../../assets/edFarmLogo.png'
import BlankAvatar from '../../../assets/blank-person.jpeg'
import VisibilityIcon from '@material-ui/icons/Visibility'
import ExpandMore from '@material-ui/icons/ExpandMore'

import { Skeleton } from '@material-ui/lab'

import { EditProfileModal, ImageModal, EditAvatarModal } from '../modals'
import {
  getProfileEditData, getUserDetails, getSchoolsAndDistricts,
  getConversationID, getConversations, setConversations, setActiveConversationUsers
} from '../../../redux/actions'
import { GET_USER_DETAILS } from '../../../redux/types'
import { userRoleIDs } from '../../../utils'

import { NotificationToast, EditIcon } from '../tools'

import moment from 'moment'

const ModifiedSchoolIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='30'
      viewBox='0 0 100 100'
    >
      <path className='b' d='M0,21V46.5A1.5,1.5,0,0,0,1.5,48H9V18H3A3,3,0,0,0,0,21Zm33.75-4.5H31.5V12.75a.75.75,0,0,0-.75-.75h-1.5a.75.75,0,0,0-.75.75v6a.75.75,0,0,0,.75.75h4.5a.75.75,0,0,0,.75-.75v-1.5A.75.75,0,0,0,33.75,16.5Zm12.914-6-15-10a3,3,0,0,0-3.328,0l-15,10A3,3,0,0,0,12,13V48H24V34.5A1.5,1.5,0,0,1,25.5,33h9A1.5,1.5,0,0,1,36,34.5V48H48V13A3,3,0,0,0,46.664,10.5ZM30,24a7.5,7.5,0,1,1,7.5-7.5A7.5,7.5,0,0,1,30,24Zm27-6H51V48h7.5A1.5,1.5,0,0,0,60,46.5V21A3,3,0,0,0,57,18Z' transform='translate(0 0)' fill='#66666e' />
    </svg>
  )
}

const useStyles = makeStyles((theme) => ({
  fixSpacing: {
    width: '100%',
    margin: 'auto'
  },
  coverImageContainer: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  userAvatar: {
    height: '9em',
    maxHeight: '9em',
    width: '9em',
    maxWidth: '9em',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px'
  },
  avatarBottomBadge: {
    width: '9em',
    maxWidth: '9em',
    backgroundColor: theme.palette.purple.darkest,
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    padding: '.3em 0 .3em 0'
  },
  nameSchoolGrid: {
    paddingTop: '1em',
    // [theme.breakpoints.down('sm')]: {
    //   marginLeft: '1.5em'
    // },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '1em'
    },
    // iPad landscape and iPad Pro portrait
    '@media only screen and (min-width: 1024px) and (max-height: 768px), (min-width: 1024px) and (max-height: 1366px)': {
      paddingLeft: '1em'
    },
    '@media only screen and (min-width: 812px) and (max-height: 375px)': {
      paddingLeft: '0 !important'
    },
    // phone sizes and iPad Pro landscape
    '@media only screen and (min-width: 640px) and (max-height: 360px), (min-width: 320px) and (max-height: 568px), (min-width: 1366px) and (max-height: 1024px)': {
      paddingLeft: '1.5em'
    }
  },
  visIcon: {
    color: theme.palette.purple.darkest,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  editMessageContainer: {
    paddingTop: '1em',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0
    }
  },
  editModeMessBtn: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '.3em 1em 0 0',
    paddingLeft: '1.7em',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end',
      paddingLeft: 0
    },
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-end',
      paddingLeft: 0
    }
  },
  schoolGrid: {
    [theme.breakpoints.down('sm')]: {
      maxHeight: '.2em'
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: '1em'
    }
  },
  userName: {
    color: theme.palette.purple.darkest,
    '@media only screen and (min-width: 320px) and (max-height: 568px) and (orientation: portrait)': {
      fontSize: '18px'
    }
  },
  editBtn: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    padding: theme.spacing(1)
  }
}))

const UserGeneral = (props) => {
  const {
    matches, matchesXS, userDetails, canEdit, editMode, setEditMode, userProfileID,
    loadingState, handleDispatchSave,
    filter, setFilter, openProfile, setOpenProfile, modalTypes, modalType, removeModalType,
    openAvatarModal, setOpenAvatarModal, navHeight = 0
  } = props
  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()

  const { type, loading } = loadingState

  const { schools, defaultAvatars, certifications } = useSelector(state => state.profileData)
  const { profileID, userID } = useSelector(state => state.auth)
  const { conversations = [] } = useSelector(state => state.websocket)

  const { certList, roleID = '', districtName = '', schoolName = '' } = userDetails

  const [openImage, setOpenImage] = useState(false)

  const [expandAccor, setExpandAccor] = useState(false)

  const handleProfileOpen = () => {
    if (!schools || !schools.length || !defaultAvatars || !defaultAvatars.length || !certifications || !certifications.length) {
      dispatch(getProfileEditData())
    }

    setOpenProfile(true)
  }

  useEffect(() => {
    if (modalType) {
      if (!openProfile && modalType === modalTypes.PROFILE) {
        setOpenProfile(true)
      }
    }
  }, [modalType, openProfile, modalTypes, setOpenProfile])

  useEffect(() => {
    if (!schools || !schools.length) { dispatch(getProfileEditData(() => { }, () => NotificationToast(true, 'Failed to get profile edit data!'))) }
  }, [dispatch, schools])

  useEffect(() => {
    dispatch(getSchoolsAndDistricts({ allResults: true }))
  }, [dispatch])

  const getUserSchool = () => {
    let nameToReturn = ''
    if (schoolName || districtName) {
      nameToReturn = !schoolName ? districtName : schoolName
    }
    return nameToReturn
  }

  // Sets the editMode back and forth and triggers the artifact call to get the private vs public artifacts
  const handleEditModeChange = (oldFilter) => {
    setEditMode(prev => !prev)
    const tempFilter = { ...oldFilter, forcePublic: editMode }
    setFilter(tempFilter)
  }

  // Get the current user's role to determine which role to display in the profile avatar banner
  const getUserRole = (roleID) => {
    switch (roleID) {
      case userRoleIDs.ADMIN_ROLE:
        return 'Ed Farm'
      case userRoleIDs.TEACHER_FELLOW:
        return 'Teacher Fellow'
      case userRoleIDs.INNOVATION_FELLOW:
        return 'Innovation Fellow'
      default:
        return ''
    }
  }

  // Take user to messages and open conversation with the selected user
  const handleUserMessage = async (e, userObject) => {
    e.preventDefault()
    e.stopPropagation()

    if (userObject && userObject.userID) {
      // get conversationID for the selected user to pull up conversation
      const conversationID = await new Promise((resolve, reject) => {
        dispatch(getConversationID([userObject.userID], (payload) => resolve(payload), () => reject(new Error('failed to acquire conversation id'))))
      }).catch(err => console.error(err))

      if (conversationID) {
        let conversationList = []

        // if conversations are not set in redux already, grab them and set conversationList to results, otherwise set to conversations from redux
        if (conversations && conversations.length === 0) {
          const newConversations = await dispatch(getConversations())

          conversationList = newConversations
        } else {
          conversationList = conversations
        }

        // if the conversationList is correctly set, run checks
        if (conversationList) {
          // check conversations array to see if the conversation already exists there
          const conversationCheck = conversations.some(conversation => conversation.conversationID === conversationID)

          // if the conversation is not in the array, copy and add to the begnining of the array
          if (!conversationCheck) {
            const now = moment().unix()

            // build temporary object for conversation list
            // mostRecentMessageContent changed from empty string
            const newConversation = {
              conversationID,
              userListDetails: [userObject],
              mostRecentMessageID: '',
              mostRecentCreatedAt: now,
              mostRecentIsDraft: 0,
              mostRecentMessageContent: JSON.stringify([{ children: [{ text: '' }] }]),
              convoHasDraftFromUser: false,
              unreadMessages: false,
              hiddenAt: false,
              blocked: false
            }

            // copy conversation array and push new conversation to first index
            const tempConversations = [...conversationList]

            tempConversations.unshift(newConversation)

            // set new conversation array to redux for conversation list
            dispatch(setConversations(tempConversations))
          }
        }

        // update active conversation users array with selected user object
        dispatch(setActiveConversationUsers([userObject]))

        // take user to message view with conversationID set to selected
        props.history.push(`/messages?view=message&conversationID=${conversationID}`)
      } else {
        // take user to default message view if something goes wrong when getting conversationID
        props.history.push({
          pathname: '/messages'
        })
      }
    } else {
      // take user to default message view if something goes wrong when with user object provided
      props.history.push({
        pathname: '/messages'
      })
    }
  }

  return (
    <Grid container direction='column' style={{ margin: matches ? '0 0 1em 3em' : '0 0 1em 1em' }}>
      {
        type === GET_USER_DETAILS && loading === true
          ? (
            <>
              <Skeleton animation='wave' height={235} style={{ marginTop: '-3.2rem' }} />
              <Paper style={{ width: '100%', marginTop: '-1.5em', zIndex: 1 }}>
                <Grid container>
                  <Grid container item direction='row'>
                    <Grid container item xs={matches ? 3 : 5}>
                      <Paper style={{ margin: '-2em 2em 1em 1em', width: '100%', minWidth: '8em' }}>
                        <Grid item container direction='column' justifyContent='flex-end'>
                          <Skeleton animation='wave' height={250} style={{ marginTop: '-3.7rem', marginBottom: '-3rem' }} />
                          <Skeleton animation='wave' height={50} style={{ marginBottom: '-.5rem' }} />
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid container item direction={matches ? 'row' : 'column'} xs={matches ? 9 : 7}>
                      <Grid container direction='column' item xs={matches ? 5 : null} style={{ paddingTop: '1em' }}>
                        <Skeleton animation='wave' height={100} style={{ marginTop: '-2rem' }} />
                      </Grid>
                      <Grid container direction='row' justifyContent='flex-end' item xs={matches ? 7 : null} style={{ paddingTop: '1em' }}>
                        <Skeleton animation='wave' height={100} width={200} style={{ marginTop: '-2rem' }} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </>
          ) : (
            <>
              <EditProfileModal
                {...props}
                isOpen={openProfile}
                onProfileClose={() => { removeModalType(); setOpenProfile(false); dispatch(getUserDetails(profileID)) }}
                openProfile={openProfile}
                userProfileID={userProfileID}
                getUserDetails={getUserDetails}
                loadingState={loadingState}
                handleDispatchSave={handleDispatchSave}
                navHeight={navHeight}
              />

              <ImageModal
                userDetails={userDetails}
                isOpen={openImage}
                onImageClose={() => setOpenImage(false)}
                userProfileID={userProfileID}
                currentUserID={userID}
                navHeight={navHeight}
              />

              <EditAvatarModal
                isOpen={openAvatarModal}
                userDetails={userDetails}
                setOpenAvatarModal={setOpenAvatarModal}
                classes={classes}
                handleDispatchSave={handleDispatchSave}
                theme={theme}
                navHeight={navHeight}
              />

              <Grid style={{ backgroundImage: `url(${userDetails.profileImagePath ? userDetails.profileImagePath : BubbleBack})` }} className={classes.coverImageContainer}>
                <Grid item container direction='row' justifyContent='flex-end' alignContent='flex-start' style={{ height: '12em' }}>
                  {canEdit && editMode &&
                    <IconButton onClick={() => setOpenImage(true)} style={{ paddingTop: '.5em' }}>
                      <CameraAltIcon style={{ fontSize: '.9em', color: theme.palette.purple.darkest, padding: '.1em', backgroundColor: 'white', borderRadius: '50%' }} />
                    </IconButton>}
                </Grid>
              </Grid>

              <Paper style={{ width: '100%', marginTop: '-1.5em', zIndex: 1 }}>
                <Grid container direction='row' justifyContent='space-between'>

                  <Grid item xs={matchesXS ? 'auto' : 5} sm={5} md={3} lg={2}>
                    <Paper style={{ margin: '-2em 2em 1em 1em', width: '9em', minWidth: '9em', height: 'fit-content' }}>
                      <Grid item container direction='column' justifyContent='flex-end'>
                        <Grid style={{ backgroundImage: `url(${!userDetails || !userDetails.profileAvatarPath ? BlankAvatar : userDetails.profileAvatarPath})` }} className={classes.userAvatar}>
                          {canEdit && editMode &&
                            <IconButton style={{ paddingTop: '4.7em', paddingLeft: '4.5em' }} onClick={() => setOpenAvatarModal(true)}>
                              <CameraAltIcon style={{ fontSize: '.9em', color: theme.palette.purple.darkest, padding: '.1em', backgroundColor: 'white', borderRadius: '50%' }} />
                            </IconButton>}
                        </Grid>
                        <Grid item container direction='row' justifyContent='center' className={classes.avatarBottomBadge}>
                          <img src={Logo} style={{ maxWidth: '1.5em' }} alt='edFarmLogo' />
                          <Typography style={{ color: 'white' }}>{getUserRole(roleID)}</Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>

                  <Grid item xs={matchesXS ? 'auto' : 7} sm={7} md={9} lg={10}>
                    <Grid container item direction='row' alignItems='flex-start' justifyContent='space-between' wrap='wrap' spacing={4} className={classes.fixSpacing}>

                      <Grid item className={classes.nameSchoolGrid}>
                        <Grid container direction='row'>

                          <Grid container item direction='column'>
                            <Grid item>
                              <Typography variant='h3' className={classes.userName}>
                                {!userDetails.fullName ? '' : userDetails.fullName}
                              </Typography>
                            </Grid>

                            <Grid item>
                              <Grid container direction='row' className={editMode ? null : classes.schoolGrid}>
                                <Grid item style={{ paddingTop: 2, maxWidth: '1.5em' }}>
                                  <ModifiedSchoolIcon />
                                </Grid>
                                <Typography variant='h5' style={{ fontWeight: '700', color: theme.palette.grey.dark }}>
                                  {getUserSchool()}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>

                        </Grid>
                      </Grid>

                      {canEdit
                        ? (
                          <Grid item className={classes.editMessageContainer} style={{ padding: '10px' }}>
                            <Grid container direction='row' justifyContent='space-between' alignItems='center' spacing={1} className={classes.fixSpacing}>
                              <Grid item style={{ paddingTop: 0, paddingBottom: 0 }}>
                                <Button
                                  variant='text'
                                  style={{ textTransform: 'none', fontWeight: '600', color: theme.palette.purple.darkest }}
                                  startIcon={
                                    <VisibilityIcon className={classes.visIcon} />
                                  }
                                  onClick={() => handleEditModeChange(filter)}
                                >
                                  {editMode ? 'View Public Profile' : 'Exit Public View'}
                                </Button>

                                {editMode &&
                                  <IconButton onClick={handleProfileOpen} className={classes.editBtn}>
                                    <EditIcon />
                                  </IconButton>}
                              </Grid>
                            </Grid>
                          </Grid>
                        )
                        : (
                          <Grid item style={{ paddingTop: '1em' }}>
                            <Button
                              variant='contained'
                              color='primary'
                              style={{ fontWeight: '600', padding: '.5em 3.5em .5em 3.5em' }}
                              onClick={(e) => { handleUserMessage(e, userDetails) }}
                            >
                              Message
                            </Button>
                          </Grid>
                        )}

                      {
                        certList && certList.length > 0
                          ? (
                            <>
                              <Grid item xs={12} style={{ padding: '12px' }}>
                                <Accordion expanded={matches || expandAccor} style={{ width: '100%', boxShadow: 'none' }}>
                                  <AccordionSummary
                                    expandIcon={
                                      <ExpandMore style={{ display: matches || certList.length <= 1 ? 'none' : 'flex' }} />
                                    }
                                    style={{
                                      height: '3.2em',
                                      minHeight: 0,
                                      cursor: matches ? 'default' : 'pointer',
                                      padding: '0px',
                                      display: matches ? 'none' : 'flex'
                                    }}
                                    onClick={() => setExpandAccor(prev => !prev)}
                                  >
                                    {
                                      !matches
                                        ? (
                                          <>
                                            <Grid item container direction='row' alignItems='center' className={classes.accordionHeader} xs={12}>
                                              {certList.slice(0, 1).map((cert, i) => {
                                                return (
                                                  <Button
                                                    key={`${cert.certificationID}-${i}`}
                                                    startIcon={<Avatar alt='cert' src={!cert.croppedCertImageUrl ? `${cert.certImageUrl}?${moment().unix()}` : `${cert.croppedCertImageUrl}?${moment().unix()}`} />}
                                                    style={{
                                                      cursor: 'default',
                                                      textAlign: 'left',
                                                      // maxWidth: '10rem',
                                                      textTransform: 'none',
                                                      fontSize: '.9rem',
                                                      fontWeight: '600',
                                                      marginRight: '1rem'
                                                    }}
                                                    disableFocusRipple
                                                    disableRipple
                                                  >
                                                    {cert.certName}
                                                  </Button>
                                                )
                                              })}
                                            </Grid>
                                          </>
                                        ) : (
                                          <>
                                          </>
                                        )
                                    }
                                  </AccordionSummary>
                                  {/* <Divider /> */}
                                  <Grid item container direction='row' justifyContent='flex-start'>
                                    {certList.slice(matches ? 0 : 1).map((cert, i) => {
                                      return (
                                        <Button
                                          key={`${cert.certificationID}-${i}`}
                                          startIcon={<Avatar alt='cert' src={!cert.croppedCertImageUrl ? `${cert.certImageUrl}?${moment().unix()}` : `${cert.croppedCertImageUrl}?${moment().unix()}`} />}
                                          style={{
                                            cursor: 'default',
                                            textAlign: 'left',
                                            textTransform: 'none',
                                            fontSize: '.9rem',
                                            fontWeight: '600',
                                            marginRight: '1rem'
                                          }}
                                          disableFocusRipple
                                          disableRipple
                                        >
                                          {cert.certName}
                                        </Button>
                                      )
                                    })}
                                  </Grid>
                                </Accordion>
                              </Grid>
                            </>
                          ) : (
                            <>
                            </>
                          )
                      }

                    </Grid>
                  </Grid>

                </Grid>
              </Paper>
            </>
          )
      }
    </Grid>
  )
}

export default withRouter(UserGeneral)
