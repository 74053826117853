import React, { useRef } from 'react'

import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import Logo from '../../../assets/edFarmLogo.png'
import { useDebounceEffect, canvasPreview } from '../../../utils'

const ImageCroppingTool = (props) => {
  // Props: originalImage (uploaded base64 string), setCroppedImage (function to set state for cropped version of originalImage)
  const {
    uploadedImage = '', previewCanvasRef,
    setCompletedCrop = () => { }, completedCrop,
    setCrop = () => { }, crop,
    setCroppedCertImage = () => { },
    setIsCropChanged = () => { }
  } = props

  // Uses built in react-image-crop helper functions which center the crop and set it's aspect ratio by percentage
  const centerAspectCrop = (unit, mediaWidth, mediaHeight, aspect) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit,
          width: mediaWidth
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    )
  }

  const imgRef = useRef(null)

  // Sets position of cropped area and it's aspect ratio on load
  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget

    setCrop(centerAspectCrop('%', width, height, 1))

    setCompletedCrop(centerAspectCrop('px', width, height, 1))
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        const croppedImage = canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop
        )

        setCroppedCertImage(croppedImage)
      }
    },
    50,
    [completedCrop]
  )

  return (
    <>
      {uploadedImage &&
        <ReactCrop
          crop={crop}
          onChange={(crop, percentCrop) => { setCrop(percentCrop) }}
          onComplete={(c) => { setCompletedCrop(c); setIsCropChanged(true) }}
          crossorigin='anonymous' // to avoid CORS-related problems
          aspect={1}
          circularCrop
        >
          <img
            ref={imgRef}
            crossOrigin='anonymous'
            src={uploadedImage || Logo}
            alt='cropped-img'
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain'
            }}
            onLoad={onImageLoad}
          />
        </ReactCrop>}
    </>
  )
}

export default ImageCroppingTool
