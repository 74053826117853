import React from 'react'
import { withRouter } from 'react-router-dom'

import { Grid, Typography, Button } from '@material-ui/core'
import theme from '../../styles/MuiTheme'

/* NOTES: Message displayed when no results are found for an individual artifact type in a global search
  -- Parent Component: ui/Search/ResultAreas/ArtifactResults.js
  - Props: handleNoResultsViewAll from SearchBarNav.js

  In depth render tree loacated in SearchBarNav.js
*/
const NoArtifactSingle = (props) => {
  const { handleNoResultsViewAll } = props

  return (
    <div style={{ backgroundColor: `${theme.palette.purple.lightest}75`, padding: '2em 1em', marginTop: '.5em' }}>
      <Grid item container direction='row' justifyContent='center'>
        <Typography variant='h6' style={{ color: theme.palette.purple.darkest, textTransform: 'none', fontSize: '16px', lineHeight: 1.4 }}>No Results Found</Typography>
      </Grid>

      <Grid item container direction='row' justifyContent='center'>
        <Typography variant='body1' style={{ textAlign: 'center', color: theme.palette.grey.dark }}>There were no artifacts of this type matching your criteria.</Typography>
      </Grid>

      <Grid item container direction='row' justifyContent='center'>
        <Button
          color='primary'
          variant='text'
          style={{ textTransform: 'none', fontWeight: '400', color: '#29356a', fontSize: '12px', padding: 0 }}
          onClick={(e) => {
            handleNoResultsViewAll(e, 'all-posts')
          }}
        >
          Click here to explore all artifacts
        </Button>
      </Grid>
    </div>
  )
}

export default withRouter(NoArtifactSingle)
