import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'

import {
  Grid, Typography, Paper, IconButton, OutlinedInput, Select,
  TextField, RadioGroup, Radio, FormControlLabel, MenuItem,
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Divider, Checkbox,
  FormGroup, InputAdornment, Fab
} from '@material-ui/core'
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import theme from '../../styles/MuiTheme'

import { ArtifactComment } from '../../tools'

const ModifiedLEDIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 68.782 55.026'>
      <path data-name='chalkboard-solid' d='M10.317,6.878H58.465v37.83h6.878V4.3a4.3,4.3,0,0,0-4.3-4.3H7.738a4.3,4.3,0,0,0-4.3,4.3v40.41h6.878ZM67.063,48.148H51.587V41.269H30.952v6.878H1.72A1.719,1.719,0,0,0,0,49.867v3.439a1.719,1.719,0,0,0,1.72,1.72H67.063a1.719,1.719,0,0,0,1.72-1.72V49.867A1.719,1.719,0,0,0,67.063,48.148Z' fill={theme.palette.purple.darkest} />
    </svg>
  )
}

const LEDDetails = (props) => {
  const {
    classes, submit, ledInfo, setLEDInfo, grades, subjects, standards, styles, sectionComments
  } = props

  const [gradesValue, setGradesValue] = useState([])
  const [gradesOpen, setGradesOpen] = useState(false)
  const [coreSubject, setCoreSubject] = useState('')
  const [secondarySubject, setSecondarySubject] = useState('')
  const [exampleOpen, setExampleOpen] = useState(false)

  const { targetArtifactLED: { forkedFrom = '', originalArtifact = {} } } = useSelector(state => state.artifacts)

  const coreSubjectDeletedCheck = ledInfo && ledInfo.coreSubjectID && subjects && subjects.findIndex(x => x.subjectID === ledInfo.coreSubjectID) === -1

  const secondarySubjectDeletedCheck = ledInfo && ledInfo.secondarySubjectID && subjects && subjects.findIndex(x => x.subjectID === ledInfo.secondarySubjectID) === -1

  useEffect(() => {
    if (ledInfo && subjects && subjects.length && ledInfo.coreSubjectID && !coreSubjectDeletedCheck) { setCoreSubject(ledInfo.coreSubjectID) }

    if (ledInfo && subjects && subjects.length && ledInfo.secondarySubjectID && !secondarySubjectDeletedCheck) { setSecondarySubject(ledInfo.secondarySubjectID) }
  }, [ledInfo, subjects, coreSubjectDeletedCheck, secondarySubjectDeletedCheck])

  useEffect(() => {
    if (ledInfo && grades && grades.length && ledInfo.grades && ledInfo.grades.length) { setGradesValue(ledInfo.grades) }
  }, [ledInfo, grades])

  const handleGradesChange = (options) => {
    setGradesValue(options)
    setLEDInfo({ ...ledInfo, grades: options })
    setGradesOpen(false)
  }

  const handleCoreSubjectChange = (newSubject) => {
    setCoreSubject(newSubject)
    setLEDInfo({
      ...ledInfo,
      coreSubjectID: newSubject
    })
  }

  const handleSecondarySubject = (newSubject) => {
    setSecondarySubject(newSubject)
    setLEDInfo({
      ...ledInfo,
      secondarySubjectID: newSubject
    })
  }

  const handleISTEChange = (standard) => {
    const tempArray = [...ledInfo.ISTEStandards]
    const index = tempArray.indexOf(standard)
    if (index === -1) {
      tempArray.push(standard)
    } else {
      tempArray.splice(index, 1)
    }
    setLEDInfo({
      ...ledInfo,
      ISTEStandards: tempArray
    })
  }

  // Check value and set to max 52 if value is higher. Also handle input value if numbers removed to enter manually.
  const handleWeeksChange = (value) => {
    if (Number(value) > 52) {
      setLEDInfo({ ...ledInfo, completionTime: parseInt(52) })
    } else if (!value) {
      setLEDInfo({ ...ledInfo, completionTime: '' })
    } else {
      setLEDInfo({ ...ledInfo, completionTime: parseInt(value) })
    }
  }

  return (
    <Paper className={classes.paperRoot}>
      <Grid container direction='column'>
        {/* Step Header */}
        <Grid container item direction='row' spacing={1} style={{ marginBottom: '.5em' }}>
          <Grid item>
            <ModifiedLEDIcon />
          </Grid>
          <Grid item>
            <Typography variant='h4'>Artifact Details</Typography>
          </Grid>
          <Grid item>
            <IconButton size='small' style={{ paddingTop: 0, paddingLeft: 0 }} onClick={() => setExampleOpen(true)}>
              <HelpOutlineRoundedIcon style={{ fontSize: '19px', color: theme.palette.purple.darkest }} />
            </IconButton>
          </Grid>
        </Grid>

        {forkedFrom && originalArtifact &&
          <Grid item container direction='row' style={{ marginBottom: '.5em' }}>
            <Typography variant='caption' style={{ color: theme.palette.grey.dark, fontWeight: 600, marginRight: '.2em' }}>
              {`Inspired by ${originalArtifact.deletedAt ? 'another Ed Farm User' : originalArtifact.fullName}'s`}
            </Typography>

            <Typography variant='caption' style={{ color: theme.palette.purple.darkest, fontWeight: 600 }}>
              {`"${originalArtifact.title.substring(0, 55)}${originalArtifact.title.length > 55 ? '...' : ''}"`}
            </Typography>
          </Grid>}

        <Grid item container style={{ marginBottom: '.5em' }}>
          <Typography variant='caption'><span style={{ color: 'red', fontSize: '14px' }}>*</span> indicates a required field.</Typography>
        </Grid>

        {/* Artifact Name */}
        <Grid item container direction='column' style={{ marginBottom: '2em' }}>
          <Grid item>
            <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}><span style={{ color: 'red', fontSize: '14px' }}>*</span> Whats the name of your artifact?</Typography>
          </Grid>
          <Grid item style={{ paddingRight: '.5em' }}>
            <OutlinedInput
              variant='outlined'
              fullWidth
              inputProps={{
                maxLength: 100
              }}
              margin='dense'
              value={ledInfo.title}
              onChange={(e) => setLEDInfo({ ...ledInfo, title: e.target.value })}
              placeholder='Enter a name for your artifact...'
              classes={{ input: classes.inputPlaceholder }}
            />
          </Grid>
          <Grid item>
            <Typography variant='caption' style={{ color: '#adadad', paddingLeft: '.3em' }}>{`${ledInfo && ledInfo.title && ledInfo.title.length ? ledInfo.title.length : 0}/ 100`}</Typography>
          </Grid>
        </Grid>

        {/* Grade Selection */}
        <Grid item container direction='column' style={{ marginBottom: '2em' }}>
          <Grid item>
            <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}><span style={{ color: 'red', fontSize: '14px' }}>*</span> Grade(s)?</Typography>
          </Grid>
          <Grid item style={{ paddingRight: '.5em' }}>
            <Select
              variant='outlined'
              open={gradesOpen}
              onOpen={() => setGradesOpen(true)}
              onClose={() => setGradesOpen(false)}
              fullWidth
              margin='dense'
              value={gradesValue}
              multiple
              startAdornment={<SearchRoundedIcon style={{ fontSize: '20px', color: theme.palette.grey.dark }} />}
              onChange={(e) => handleGradesChange(e.target.value)}
              defaultValue={[]}
            >
              {grades.map(gradeInfo => {
                const { gradeID, gradeName } = gradeInfo
                return (
                  <MenuItem key={`grade ${gradeID}`} value={gradeID}>{gradeName.replace('Grade', '')}</MenuItem>
                )
              })}
            </Select>
          </Grid>
        </Grid>

        {/* Completion Time */}
        <Grid item container direction='column' style={{ marginBottom: '2em' }}>
          <Grid item>
            <Typography variant='body1' style={{ fontWeight: '600' }}><span style={{ color: 'red', fontSize: '14px', lineHeight: 1 }}>*</span> Completion Time</Typography>
            <Typography variant='caption' style={{ color: theme.palette.grey.dark }}>How many weeks will it take students to complete the learning experience?</Typography>
          </Grid>
          <Grid item container direction='row' justifyContent='flex-start' style={{ paddingRight: '.5em', maxWidth: '60%' }}>
            <Grid item xs={12} style={{ paddingLeft: '.3em' }}>
              <TextField
                type='number'
                size='small'
                inputProps={{
                  min: 0,
                  max: 52
                }}
                InputProps={{ endAdornment: <InputAdornment position='end'>week(s)</InputAdornment> }}
                classes={{ root: classes.textField }}
                style={{ width: '7.3em' }}
                value={ledInfo.completionTime}
                onChange={(e) => handleWeeksChange(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Work Type */}
        <Grid item container direction='column' style={{ marginBottom: '2em' }}>
          <Grid item>
            <Typography variant='body1' style={{ fontWeight: '600' }}><span style={{ color: 'red', fontSize: '14px', lineHeight: 1 }}>*</span> Type of Work</Typography>
            <Typography variant='caption' style={{ color: theme.palette.grey.dark }}>Will students complete the learning experience individually or as a group?</Typography>
          </Grid>
          <Grid item container direction='row' style={{ paddingRight: '.5em', maxWidth: '60%', marginTop: '.5em' }}>
            <RadioGroup
              row
              value={ledInfo.workType}
              defaultChecked='individual'
              onChange={(e) => setLEDInfo({ ...ledInfo, workType: e.target.value })}
            >
              <FormControlLabel
                style={{ marginRight: '2em' }}
                value='individual'
                control={<Radio size='small' checked={ledInfo.workType === 'individual'} />}
                label={
                  <Typography variant='body1' style={{ fontWeight: '400', color: theme.palette.purple.darkest }}>Individual</Typography>
                }
              />
              <FormControlLabel
                style={{ marginRight: '2em' }}
                value='group'
                control={<Radio size='small' checked={ledInfo.workType === 'group'} />}
                label={
                  <Typography variant='body1' style={{ fontWeight: '400', color: theme.palette.purple.darkest }}>Group</Typography>
                }
              />
              <FormControlLabel
                style={{ marginRight: '5em' }}
                value='both'
                control={<Radio size='small' checked={ledInfo.workType === 'both'} />}
                label={
                  <Typography variant='body1' style={{ fontWeight: '400', color: theme.palette.purple.darkest }}>Individual & Group</Typography>
                }
              />
            </RadioGroup>
          </Grid>
        </Grid>

        {/* Technology Needed */}
        <Grid item container direction='column' style={{ marginBottom: '2em' }}>
          <Grid item container direction='row' alignContent='center'>
            <Grid item>
              <Typography variant='body1' style={{ fontWeight: '600' }}><span style={{ color: 'red', fontSize: '14px' }}>*</span> Technology Needed?</Typography>
            </Grid>
            <Grid item>
              <IconButton size='small' style={{ paddingTop: 0, paddingLeft: 5, paddingBottom: 5 }} onClick={() => setExampleOpen(true)}>
                <HelpOutlineRoundedIcon style={{ fontSize: '16px', color: theme.palette.purple.darkest }} />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item style={{ marginBottom: '.5em' }}>
            <Typography variant='caption' style={{ color: theme.palette.grey.dark }}>Add any technology needed for artifact completion. Please seperate items with a comma. <span style={{ fontStyle: 'italic' }}>Example: Clips, Garageband, Jamboard</span></Typography>
          </Grid>
          <Grid item style={{ paddingRight: '.5em' }}>
            <OutlinedInput
              variant='outlined'
              fullWidth margin='dense'
              placeholder='Add any technology needed'
              inputProps={{
                maxLength: 256
              }}
              classes={{ input: classes.inputPlaceholder }}
              value={ledInfo.techNeeded}
              onChange={(e) => setLEDInfo({ ...ledInfo, techNeeded: e.target.value })}
            />
          </Grid>
          <Grid item>
            <Typography variant='caption' style={{ color: '#adadad', paddingLeft: '.3em' }}>{`${ledInfo && ledInfo.techNeeded && ledInfo.techNeeded.length ? ledInfo.techNeeded.length : 0}/ 256`}</Typography>
          </Grid>
        </Grid>

        {/* Core Subject */}
        <Grid item container direction='column' style={{ marginBottom: '2em' }}>
          <Grid item>
            <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}><span style={{ color: 'red', fontSize: '14px' }}>*</span> Core Subject</Typography>
            {coreSubjectDeletedCheck &&
              <Typography gutterBottom variant='caption' style={{ fontWeight: '400', color: 'red' }}>**Heads up! The core subject you chose for this artifact is no longer available. You may want to select another.</Typography>}
          </Grid>
          <Grid item style={{ paddingRight: '.5em' }}>
            <Select
              variant='outlined'
              fullWidth margin='dense'
              placeholder='Choose a core subject'
              value={coreSubject}
              onChange={(e) => handleCoreSubjectChange(e.target.value)}
              startAdornment={<SearchRoundedIcon style={{ fontSize: '20px', color: theme.palette.grey.dark }} />}
              defaultValue=''
            >
              {subjects.map(subjectInfo => {
                const { subjectID, subjectName } = subjectInfo

                return (
                  <MenuItem key={`subject ${subjectID}`} value={subjectID}>{subjectName}</MenuItem>
                )
              })}
            </Select>
          </Grid>
        </Grid>

        {/* Secondary Subject */}
        <Grid item container direction='column' style={{ marginBottom: '2em' }}>
          <Grid item>
            <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}>Secondary Subject</Typography>
            {secondarySubjectDeletedCheck &&
              <Typography gutterBottom variant='caption' style={{ fontWeight: '400', color: 'red' }}>**Heads up! The secondary subject you chose for this artifact is no longer available. You may want to select another.</Typography>}
          </Grid>
          <Grid item style={{ paddingRight: '.5em' }}>
            <Select
              variant='outlined'
              fullWidth margin='dense'
              placeholder='Choose a secondary subject'
              value={secondarySubject}
              onChange={(e) => handleSecondarySubject(e.target.value)}
              startAdornment={<SearchRoundedIcon style={{ fontSize: '20px', color: theme.palette.grey.dark }} />}
              defaultValue=''
            >
              {subjects.map(subjectInfo => {
                const { subjectID, subjectName } = subjectInfo
                return (
                  <MenuItem key={`subject ${subjectID}`} value={subjectID}>{subjectName}</MenuItem>
                )
              })}
            </Select>
          </Grid>
        </Grid>

        {/* ISTE Standards */}
        <Grid item container direction='column' style={{ marginBottom: '2em' }}>
          <Grid item container direction='row' alignContent='center'>
            <Grid item>
              <Typography variant='body1' style={{ fontWeight: '600' }}>ISTE Standards</Typography>
            </Grid>
            <Grid item>
              <IconButton size='small' style={{ paddingTop: 0, paddingLeft: 5, paddingBottom: 5 }} onClick={() => setExampleOpen(true)}>
                <HelpOutlineRoundedIcon style={{ fontSize: '16px', color: theme.palette.purple.darkest }} />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item style={{ marginBottom: '.5em' }}>
            <Typography variant='caption' style={{ color: theme.palette.grey.dark }}>Want to learn more about ISTE Standards? <span><a href='https://www.iste.org/standards/for-students' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'underline', fontWeight: '600', color: theme.palette.purple.darkest, cursor: 'pointer' }}>Click Here</a></span></Typography>
          </Grid>
          <Grid item style={{ paddingRight: '.5em', paddingLeft: '.2em' }}>
            <FormGroup
              row
              onChange={(e) => handleISTEChange(e.target.name)}
            >
              {standards && standards.length !== 0 &&
                standards.map(standard => {
                  return (
                    <FormControlLabel
                      key={`standard ${standard.standardID}`}
                      control={
                        <Checkbox
                          size='small'
                          disableRipple
                          checked={ledInfo ? ledInfo.ISTEStandards.includes(standard.standardID) : false}
                          className={classes.customCheckRoot}
                          checkedIcon={<span className={clsx(classes.customCheckbox, classes.customCheckChecked)} />}
                          icon={<span className={classes.customCheckbox} />}
                          name={standard.standardID}
                        />
                      }
                      label={
                        <Typography variant='body1' style={{ fontWeight: 400 }}>{standard.standardName}</Typography>
                      }
                    />
                  )
                })}
            </FormGroup>
          </Grid>
        </Grid>

        {/* Comments */}
        {sectionComments && sectionComments.length !== 0 &&
          <>
            <Divider />
            <Grid item container direction='column' style={{ marginBottom: '2em', marginTop: '1em' }}>
              <Grid item container direction='row' alignContent='center'>
                <Grid item>
                  <Typography variant='body1' style={{ fontWeight: '600' }}>Comments</Typography>
                </Grid>
                <Grid item style={{ marginLeft: '.5em' }}>
                  <Fab style={{ height: '15px', width: '15px', minHeight: '15px', backgroundColor: styles.mainColor, boxShadow: 'none' }}>
                    <Typography variant='body1' style={{ fontSize: '10px', color: 'white', fontWeight: 600 }}>{sectionComments ? sectionComments.length : 0}</Typography>
                  </Fab>
                </Grid>
              </Grid>
              <Grid item container direction='column' spacing={2} style={{ marginTop: '1em' }}>
                {sectionComments && sectionComments.length !== 0 &&
                  sectionComments.map(comment => {
                    return (
                      <ArtifactComment
                        key={comment.commentID}
                        classes={classes}
                        commentInfo={comment}
                        artifactID={comment.artifactID}
                        section='details'
                        styles={styles}
                        fromCreate
                      />
                    )
                  })}
              </Grid>
            </Grid>
          </>}
      </Grid>

      {/* Example Dialog */}
      <Dialog
        open={exampleOpen}
        onClose={() => setExampleOpen(false)}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle disableTypography>
          <Typography variant='h4' style={{ margin: '.5em' }}>Example: Artifact Details</Typography>
        </DialogTitle>
        <DialogContent
          dividers
        >
          <Grid container direction='column' style={{ margin: '1em' }}>
            <Grid item container direction='column' style={{ marginTop: submit ? '2em' : 0 }}>
              <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}>What's the name of your artifact?</Typography>
              <Typography variant='body1' style={{ fontWeight: '400', marginTop: '.5em' }}>HBCU Digital Ad Challenge</Typography>
              <Divider variant='middle' style={{ marginLeft: 0 }} />
            </Grid>
            <Grid item container direction='column' style={{ marginTop: '2em' }}>
              <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}>Grade(s)?</Typography>
              <Typography variant='body1' style={{ fontWeight: '400', marginTop: '.5em' }}>6, 7, 8</Typography>
              <Divider variant='middle' style={{ marginLeft: 0 }} />
            </Grid>
            <Grid item container direction='column' style={{ marginTop: '2em' }}>
              <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}>Completion Time</Typography>
              <Typography variant='body1' style={{ fontWeight: '400', marginTop: '.5em' }}>3 weeks</Typography>
              <Divider variant='middle' style={{ marginLeft: 0 }} />
            </Grid>
            <Grid item container direction='column' style={{ marginTop: '2em' }}>
              <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}>Type of Work</Typography>
              <Typography variant='body1' style={{ fontWeight: '400', marginTop: '.5em' }}>Individual</Typography>
              <Divider variant='middle' style={{ marginLeft: 0 }} />
            </Grid>
            <Grid item container direction='column' style={{ marginTop: '2em' }}>
              <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}>Technology Needed</Typography>
              <Typography variant='body1' style={{ fontWeight: '400', marginTop: '.5em' }}>Clips, GarageBand, Notes, iMOvie, Schoology, Jamboard</Typography>
              <Divider variant='middle' style={{ marginLeft: 0 }} />
            </Grid>
            <Grid item container direction='column' style={{ marginTop: '2em' }}>
              <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}>Core Subject</Typography>
              <Typography variant='body1' style={{ fontWeight: '400', marginTop: '.5em' }}>Other</Typography>
              <Divider variant='middle' style={{ marginLeft: 0 }} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='primary'
            style={{
              margin: '.5em 1em',
              fontWeight: '600'
            }}
            onClick={() => setExampleOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}

export default LEDDetails
