import { parseResponse } from '../../lib'
import { explorerTypes, esRecordType, artifactAbbrev, formatLEDBackgroundObj } from '../../utils'

// UI Tech Debt: Removed api variable that wasn't being used anywhere.

const api = process.env.REACT_APP_API_URL
const apiversion = process.env.REACT_APP_API_VERSION

export const getExploreArtifacts = (explorerFilter, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { userID, token = '' } = getState().auth

      if (!explorerFilter) {
        explorerFilter = { explorerType: 'featured' }
      }

      if (!token) {
        fireFailure()
        return false
      }

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ filter: explorerFilter })
      }

      const response = await window.fetch(`${api}/${apiversion}/user/${userID}/explorer`, options)
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) {
        fireFailure()
      } else {
        const { data } = await response.json()

        if (data) {
          const translatedData = formatResponse(data, explorerFilter.explorerType)
          return translatedData
        }

        fireFailure()
        return false
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

const formatResponse = (data, searchType) => {
  if (searchType) {
    const { new: newArts = [], subjects: subjectArts = [], posts: resPosts = [], postCount = 0 } = data

    switch (searchType) {
      case explorerTypes.FEATURED: {
        const formattedNew = newArts && newArts.length ? newArts.map((obj) => { return formatArtifact(obj) }) : []
        const formattedSubjects = subjectArts && subjectArts.length ? subjectArts.map((obj) => { return formatArtifact(obj) }) : []

        return { new: formattedNew, subjects: formattedSubjects }
      }
      case explorerTypes.ALL_POSTS: {
        const formattedPosts = resPosts && resPosts.length ? resPosts.map((obj) => { return formatArtifact(obj) }) : []
        return { posts: formattedPosts, postCount }
      }
      case explorerTypes.USERS: {
        return { posts: resPosts, postCount }
      }
      case explorerTypes.FEATURED_RESOURCES: {
        const formattedEFRs = resPosts && resPosts.length ? resPosts.map((obj) => { return formatArtifact(obj) }) : []

        return { posts: formattedEFRs, postCount }
      }
      default: return { }
    }
  }
  return false
}

const formatArtifact = (artifactData) => {
  const {
    record_type: recordType = '', objectID = '', userID = '', userProfileID = '', userFullName = '', userNickName = '',
    le_techNeeded = '', le_workType = '', lw_workType = '', title = '', createdAt = 0, updatedAt = 0, profileAvatarPath = '', likeCount = 0, saveCount = 0,
    commentCount = 0, subjectID = '', subjectName = '', grades = [], completionTime = 0, lw_attachmentCount = 0, lw_ledTitles: lwLedTitles = '', pr_leds: prLeds = '', pr_lws: prLws = '',
    description = '', er_resourceType = '', er_resourceAudience = '', bannerImagePath = '', startDate = 0, endDate = 0, le_backgroundKey: backgroundKey = 'circles-default',
    userRole: roleID = '', le_learnerWorkID: ledLearnerWorkID = ''
  } = artifactData

  const commonObj = {
    artifactID: objectID,
    authorID: userID,
    profileID: userProfileID,
    fullName: userFullName,
    nickName: userNickName,
    profileAvatarPath,
    createdAt,
    updatedAt,
    publicCommentCount: commentCount,
    likeCount,
    saveCount,
    title
  }

  if (recordType) {
    switch (recordType) {
      case esRecordType.LE: {
        let backgroundObj = { type: 'circles', color: 'default' }
        // If the artifact has a backgroundKey, split and set the object to use the selected key
        if (backgroundKey) {
          backgroundObj = formatLEDBackgroundObj(backgroundKey)
        }
        return {
          ...commonObj,
          artifactType: artifactAbbrev.LED,
          techNeeded: le_techNeeded,
          workType: le_workType,
          description,
          subjectID,
          subjectName,
          grades: grades && grades.length ? grades.map(gradeObj => gradeObj.gradeName).join(',') : '',
          completionTime,
          backgroundKey: !backgroundKey ? 'circles-default' : backgroundKey,
          backgroundObj,
          roleID,
          learnerWorkID: ledLearnerWorkID
        }
      }
      case esRecordType.LW: {
        return {
          ...commonObj,
          artifactType: artifactAbbrev.LW,
          workType: lw_workType,
          subjectID,
          subjectName,
          attachmentCount: lw_attachmentCount,
          grades: grades && grades.length ? grades.map(gradeObj => gradeObj.gradeName).join(',') : '',
          ledTitles: lwLedTitles && lwLedTitles.length ? lwLedTitles.split(',') : []
        }
      }
      case esRecordType.PR: {
        return {
          ...commonObj,
          artifactType: artifactAbbrev.PRO,
          completionTime,
          ledAttachments: prLeds && prLeds.length ? prLeds.split(',') : [],
          lwAttachments: prLws && prLws.length ? prLws.split(',') : [],
          description
        }
      }
      case esRecordType.EF: {
        return {
          ...commonObj,
          artifactType: artifactAbbrev.EFR,
          description,
          resourceType: er_resourceType,
          resourceAudience: er_resourceAudience,
          bannerImagePath,
          startDate,
          endDate
        }
      }
      default: return { }
    }
  }
}
