import {
  GET_USER_NOTIFICATIONS_LARGE, GET_USER_NOTIFICATIONS_SMALL, DATA_REQUEST, DATA_ERROR, DATA_SUCCESS,
  RESET_USER_NOTIFICATIONS_LARGE
} from '../types'
import { trackPromise } from 'react-promise-tracker'
import { parseResponse } from '../../lib'

// UI Tech Debt: Removed commented out api variable that wasn't being used anywhere, logger import that wasn't being used, resetUserNotificationsSmall action which wasn't being used

const api = process.env.REACT_APP_API_URL
const apiversion = process.env.REACT_APP_API_VERSION

const limitNumber = {
  smallBatch: 3,
  largeBatch: 100
}

// Get list of user notifications (small batch)
export const getNotificationsSmallBatch = (fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { userID, token = '' } = getState().auth

      if (!token) {
        fireFailure()
        dispatch({ type: DATA_ERROR, payload: { type: GET_USER_NOTIFICATIONS_SMALL } })
        return false
      }
      dispatch({ type: DATA_REQUEST, payload: { type: GET_USER_NOTIFICATIONS_SMALL } })

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await window.fetch(`${api}/${apiversion}/user/${userID}/notifications/limit/${limitNumber.smallBatch}`, options)
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) {
        fireFailure()
      } else {
        const { data } = await response.json()
        dispatch({ type: DATA_SUCCESS, payload: { type: GET_USER_NOTIFICATIONS_SMALL } })

        dispatch({
          type: GET_USER_NOTIFICATIONS_SMALL,
          payload: data
        })
        fireSuccess()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

// Get list of user notifications (large batch)
export const getNotificationsLargeBatch = (fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { userID, token = '' } = getState().auth

      if (!token) {
        fireFailure()
        dispatch({ type: DATA_ERROR, payload: { type: GET_USER_NOTIFICATIONS_LARGE } })
        return false
      }
      dispatch({ type: DATA_REQUEST, payload: { type: GET_USER_NOTIFICATIONS_LARGE } })

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/user/${userID}/notifications/limit/${limitNumber.largeBatch}`, options))
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) {
        fireFailure()
      } else {
        const { data } = await response.json()
        dispatch({ type: DATA_SUCCESS, payload: { type: GET_USER_NOTIFICATIONS_LARGE } })

        dispatch({
          type: GET_USER_NOTIFICATIONS_LARGE,
          payload: data
        })
        fireSuccess()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const clearNotifications = (notifications, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { userID, token = '' } = getState().auth

      const options = {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ notifications })
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/user/${userID}/notifications`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse || parsedResponse.error) {
        fireFailure()
      } else {
        fireSuccess()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const resetUserNotificationsLarge = () => {
  return async function (dispatch) {
    dispatch({ type: RESET_USER_NOTIFICATIONS_LARGE })
  }
}
