import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Typography, Grid, Paper, Divider, Input,
  TextField, Avatar, IconButton
} from '@material-ui/core'

import { Autocomplete } from '@material-ui/lab'

import { getConversationID, setConversations, getSearchResults, setActiveConversationUsers } from '../../../../../redux/actions'

import stringAvatar from '../../../tools/AvatarFormat'

import moment from 'moment'

// The Ed Farm specific 'close icon'. To Do: add to a tools folder with options to pass in color and size
const CloseIcon = () => {
  return (
    <svg
      id='close'
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='14'
      viewBox='0 0 32 32'
    >
      <path
        id='times-solid_1_'
        data-name='times-solid (1)'
        d='M22.065,96l9.1-9.1a2.86,2.86,0,0,0,0-4.044l-2.022-2.022a2.86,2.86,0,0,0-4.044,0l-9.1,9.1-9.1-9.1a2.86,2.86,0,0,0-4.044,0L.837,82.859a2.86,2.86,0,0,0,0,4.044l9.1,9.1-9.1,9.1a2.86,2.86,0,0,0,0,4.044l2.022,2.022a2.86,2.86,0,0,0,4.044,0l9.1-9.1,9.1,9.1a2.86,2.86,0,0,0,4.044,0l2.022-2.022a2.86,2.86,0,0,0,0-4.044Z'
        transform='translate(0 -80)'
        fill='#29356a'
      />
    </svg>
  )
}

/* ********** The new conversation form ************* //
    Rendered from MessageArea.js when the 'view.message' is 'compose'
    - displays a single input form to select a user to compose the message to
    - ToDO: render a popper/popover to display the recipient options based on the user's input
    - renders a disabled text editor
*/
const NewMessage = (props) => {
  const { theme, loadNewView, viewTypes, uiLoading, currentForm, setCurrentForm } = props

  const dispatch = useDispatch()

  const { conversations = [] } = useSelector(state => state.websocket)

  const [userList, setUserList] = useState([])

  // useMemo(() => {
  //   const fireSuccess = (list) => { setUserList(list) }

  //   if (userList && userList.length === 0) {
  //     dispatch(getUsersList(fireSuccess))
  //   }
  // }, [dispatch, userList])

  // useEffect(() => {
  //   const fireSuccess = () => { setUserList() }

  //   if (userList && userList.length === 0) {
  //     console.log('dispatch: get user list')
  //   }
  // }, [dispatch, userList])

  // Input state
  const [input, setInput] = useState('')
  const [resetComplete, setResetComplete] = useState(false)

  const [loadingState, setLoadingState] = useState(true)

  // Input functions
  const handleOptionSelect = async (option) => {
    // set ui loading to true so message area doesn't update before everything is complete
    uiLoading.current = true

    // used to reset autocomplete
    setResetComplete(prev => !prev)
    setInput('')

    if (option && option.userID) {
      // Single ID in an array of users for now until we do group conversations
      const conversationID = await new Promise((resolve, reject) => {
        dispatch(getConversationID([option.userID], (payload) => resolve(payload), () => reject(new Error('failed to acquire conversation id'))))
      }).catch(err => console.error(err))

      // if a conversation ID is correctly returned
      if (conversationID) {
        // check conversations array to see if the conversation already exists there
        const conversationCheck = conversations.some(conversation => conversation.conversationID === conversationID)

        // if the conversation is not in the array, copy and add to the begnining of the array
        if (!conversationCheck) {
          const now = moment().unix()

          // build temporary object for conversation list
          const newConversation = {
            conversationID,
            userListDetails: [option],
            mostRecentMessageID: '',
            mostRecentCreatedAt: now,
            mostRecentIsDraft: 0,
            mostRecentMessageContent: JSON.stringify([{ children: [{ text: '' }] }]),
            convoHasDraftFromUser: false,
            unreadMessages: false,
            hiddenAt: false,
            blocked: false
          }

          // copy conversation array and push new conversation to first index
          const tempConversations = [...conversations]

          tempConversations.unshift(newConversation)

          // set new conversation array to redux for conversation list
          dispatch(setConversations(tempConversations, () => loadNewView(viewTypes.MESSAGE, conversationID)))
        }

        // setting active conversation users array to single selected user
        dispatch(setActiveConversationUsers([option]))

        loadNewView(viewTypes.MESSAGE, conversationID)
      }

      // makes sure to set ui loading back to initial state once done
      uiLoading.current = false
    }
  }

  // ************************************ GET NEW SEARCH RESULTS ******************************************* //
  // ************* Api call that will return a set of results based on specific inputs ************** //
  // ************* One of either 'users', 'resource', 'artifacts', or 'all results' ************** //

  const getNewResults = useCallback(async (filter) => {
    const newResults = await dispatch(getSearchResults(filter))
    setUserList(newResults.userResults)
    // give the new results time to load before removing the loading state
    setTimeout(() => {
      if (loadingState) {
        setLoadingState(false)
        uiLoading.current = false
      }
    }, 1000)
  }, [dispatch, loadingState, uiLoading])

  // ********************************************** HANDLE SEARCH *********************************************** //
  // ************* If the enter key is pressed or the search icon is clicked, search the es record ************** //

  const handleSearch = useCallback(async (value) => {
    const filter = {
      record_type: 'users',
      searchText: value
    }
    // fetch the new results
    if (value && value.length && value.length > 0) {
      uiLoading.current = true
      getNewResults(filter)
    }
  }, [getNewResults, uiLoading])

  // ********************************************** HANDLE TIMED SEARCH *********************************************** //
  // ************* Looks for the user to stop typing before firing the search handler ************** //
  const handleTimedSearch = (e) => {
    setTimeout(() => {
      handleSearch(e.target.value)
    }, 1000)
  }

  const handleOptionName = (option) => {
    if (typeof option === 'string') {
      return option
    } else {
      return option.fullName
    }
  }

  // ********************************************** HANDLE CLOSE COMPOSE *********************************************** //
  // ************* Looks for the user to stop typing before firing the search handler ************** //

  const handleCloseCompose = () => {
    // Close icon will load new view with first active conversation if there are conversations, else it will show default view
    if (currentForm === 'message-area') setCurrentForm('conversation-area')

    if (conversations.length) {
      if (conversations[0].conversationID) {
        dispatch(setActiveConversationUsers(conversations[0].userListDetails))
        return loadNewView(viewTypes.MESSAGE, conversations[0].conversationID)
      }
    }

    return loadNewView(viewTypes.DEFAULT)
  }

  useEffect(() => {
    return () => {
      setInput('')
      setResetComplete(false)
      setLoadingState(true)
    }
  }, [])

  return (
    <Grid item container direction='row' style={{ padding: '.5em 1em 0 1em', height: '85vh' }}>
      <Paper style={{ width: '100%', height: '100%', overflowY: 'scroll' }}>
        <Grid container direction='column' style={{ height: '100%' }}>
          {/* Header */}
          <Grid item container direction='row' justifyContent='space-between' alignItems='center' style={{ padding: '1em' }}>
            <Grid item xs={10}>
              <Typography variant='h4' style={{ color: theme.palette.purple.darkest, fontWeight: 600, fontSize: '24px' }}>Compose New Message</Typography>
            </Grid>
            <Grid item onClick={handleCloseCompose} container xs={2} justifyContent='flex-end' style={{ paddingRight: '.5em', cursor: 'pointer' }}>
              <CloseIcon />
            </Grid>
          </Grid>

          <Divider />

          {/* Recipient Search */}
          <Grid item container direction='row' alignItems='baseline' style={{ padding: '1em' }}>
            {/* <Typography variant='h5' style={{ color: theme.palette.purple.darkest, fontWeight: 600 }}>To:</Typography>
            <Input
              placeholder='Search for recipients...'
              inputProps={{ style: { fontStyle: 'italic', paddingBottom: 0 } }}
              disableUnderline
              style={{ paddingLeft: '.5em' }}
            /> */}

            <Autocomplete
              freeSolo
              disableClearable
              key={resetComplete}
              debug
              options={userList}
              getOptionLabel={(option) => handleOptionName(option)}
              onChange={(e, newValue) => handleOptionSelect(newValue)}
              onKeyUp={(e) => handleTimedSearch(e)}
              open={input && input.length >= 2 ? !false : false}
              noOptionsText='No Users Available'
              style={{ width: '100%' }}
              renderOption={option => {
                const hasPath = option.profileAvatarPath && option.profileAvatarPath !== undefined ? true : !true
                return (
                  <>
                    <Grid item xs={4} md={1}>
                      <IconButton color='primary'>
                        <Avatar src={option.profileAvatarPath} {...stringAvatar(`${option.fullName}`, hasPath)} />
                      </IconButton>
                    </Grid>
                    <Grid item xs={8} md={12}>
                      <Grid item container direction='row'>
                        <Typography variant='h5'>{option.fullName}</Typography>
                      </Grid>
                      <Grid item container direction='row'>
                        <Typography variant='caption'>{option.schoolName}</Typography>
                      </Grid>
                    </Grid>
                  </>
                )
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    placeholder='Search for recipients...'
                    style={{
                      fontSize: '14px',
                      fontFamily: 'Source Sans Pro',
                      width: '100%',
                      padding: 0
                    }}
                    margin='dense'
                    onChange={(e) => setInput(e.target.value)}
                    value={input}
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                      disableUnderline: true,
                      startAdornment: (
                        <Typography
                          variant='h5'
                          style={{
                            color: theme.palette.purple.darkest,
                            fontWeight: 600,
                            marginRight: '.5rem',
                            marginBottom: '.2rem'
                          }}
                        >
                          To:
                        </Typography>
                      )
                    }}
                  />
                )
              }}
            />

          </Grid>

          <Divider />
          {/* Blank Body */}
          <Grid container item style={{ height: '45vh' }} justifyContent='center' alignItems='center'>
            <Typography variant='caption' style={{ color: theme.palette.grey.dark }}>No recipient selected.</Typography>
          </Grid>
          <Divider />

          {/* Compose Message (disabled) */}
          <Grid item container direction='column' style={{ padding: '1em' }}>
            <Grid item>
              <Typography variant='h5' style={{ color: theme.palette.grey.medium, fontWeight: 600 }}>New Message:</Typography>
            </Grid>
            <Grid item>
              <Input
                placeholder='Type your message here...'
                inputProps={{ style: { fontStyle: 'italic', paddingBottom: 0 } }}
                fullWidth
                disableUnderline
                disabled
                style={{ paddingLeft: '.5em' }}
              />
            </Grid>
          </Grid>

        </Grid>
      </Paper>
    </Grid>
  )
}

export default NewMessage
