import React, { useState, useRef, useMemo, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { OutlinedInput, useTheme, makeStyles } from '@material-ui/core'
import { SearchRounded, CancelRounded } from '@material-ui/icons'
import SearchPopper from './SearchPopper'

import {
  getSearchResults, setExploreActiveTab, setSearchExploreFilter, getSchoolsAndDistricts, getCohorts
} from '../../../redux/actions'
import { esRecordType } from '../../../utils'

/*
  Render Tree:
  -NavBar:
    - SearchBarNav (input):
      - SearchPopper (main display areas):
        - SearchInputNeeded.js
        - UserLoadingSkel
        - UserResults (maps results if available, else renders child):
          - UserNoResults
        - ArtifactLoadingSkel
        - ArtifactResults (maps results if available, else renders child):
          - NoArtifactsSingle
          - NoArtifactsAll
        - ResourceLoadingSkel
        - ResourceResults (maps results if available, else renders child):
          - ResourceNoResults
*/

const useStyles = makeStyles(theme => ({
  inputPlaceholder: {
    '&::placeholder': {
      fontStyle: 'italic',
      fontSize: '14px',
      color: 'white'
    }
  },
  searchInput: {
    paddingLeft: '8px',
    backgroundColor: '#ffffff1f',
    maxWidth: '40vw',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '80vw'
    },
    height: '2em',
    color: 'white',
    zIndex: 1,
    '& fieldset': {
      borderColor: '#ffffff1f'
    },
    '&:hover fieldset': {
      borderColor: '#ffffff1f !important'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#ffffff1f !important'
    }
  },
  focusedInput: {
    paddingLeft: '8px',
    backgroundColor: 'white',
    borderColor: '#ffffff1f !important',
    color: 'black'
  },
  popPaperInput: {
    maxWidth: '90vw',
    minWidth: '80vw',
    [`${theme.breakpoints.down('sm')}`]: {
      minWidth: '20vw'
    },
    maxHeight: '90vh !important',
    overflowX: 'scroll',
    marginTop: '.4em',
    [`${theme.breakpoints.down('sm')}`]: {
      minWidth: '90vw'
    }
  },
  popPaperInputNeeded: {
    maxWidth: '70vw',
    minWidth: 'fit-content',
    maxHeight: '90vh !important',
    overflowX: 'scroll',
    marginTop: '.4em'
  },
  popper: {
    zIndex: 3,
    display: 'flex',
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '0',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: 'transparent transparent white transparent'
      }
    }
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    marginTop: '.2em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid'
    }
  },
  sectionCounter: {
    height: '18px',
    width: '18px',
    marginLeft: '.5em',
    backgroundColor: theme.palette.purple.darkest,
    color: 'white',
    fontWeight: 600,
    fontSize: '10px'
  },
  filterSelect: {
    fontSize: '14px',
    fontWeight: '600',
    color: theme.palette.grey.dark,
    fontFamily: 'Source Sans Pro',
    padding: '.1em !important'
  },
  filterDisabledSelect: {
    fontSize: '14px',
    fontWeight: '600',
    color: theme.palette.grey.medium,
    fontFamily: 'Source Sans Pro',
    padding: '.1em !important'
  }
}))

/* NOTES: Renders the search bar and popper element:
    --- Parent Component: currently rendered in tools/NavBar.js, but is designed to not rely on the parent component so as to be rendered in any nav area.
      --- Child Components: ui/Search/SearchPopper.js (see render tree at the top of this page for more details)
*/
const SearchBarNav = (props) => {
  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { setToggleDrawer = () => { } } = props

  // The three keys and sub arrays expected for the result categories
  const defaultResults = useMemo(() => ({
    userResults: [],
    resourceResults: [],
    artifactResults: {
      LEDs: [],
      LWs: [],
      PROs: []
    }
  }), [])

  // ************ Local State ************
  // Results returned from the dispatch
  const [searchResults, setSearchResults] = useState(defaultResults)
  // Expanded User Results View
  const [userView, setUserView] = useState(false)
  // This is used in testing but may remain in some capacity when the api is complete
  const [loadingState, setLoadingState] = useState(true)
  // Search Input
  const [searchInput, setSearchInput] = useState('')

  // ************** Local Refs and Variables ************** //
  // Controls the some of the expanded user view logic without re-rendering
  const expandUser = useRef(false)
  // ID for the input element that anchors the popper
  const searchInputID = 'search-input'
  // Refers to the expanded user-view's filter menus open/close state -
  // (sets to true when a menu is opened, and back again when returning to all results)
  const filterMenuRef = useRef(false)

  // ************ Popper specific refs and state **************** //
  // Refers to the popper element and used to determine if the user is clicking inside the popper or not
  const popperRef = useRef(null)
  // The anchor point of the popper (closes the popper when set to null)
  const [popAnchorEl, setPopAnchorEl] = useState(null)
  // The ref to apply an arrow tot he top of the popper
  const [arrowRef, setArrowRef] = useState(null)
  // Uses the state of the popperAnchorEl to determine if the popper should be open or closed
  const openSearchPop = Boolean(popAnchorEl)
  // Provides the ID of the popper element when rendered
  const searchPopoverID = openSearchPop ? 'search-popover' : undefined
  // Refrences the outlined input component, used to determine width of element
  const [inputRef, setInputRef] = useState(null)
  // Used to help throttle state changes
  const uiLoading = useRef(false)

  // ********************************** POPPER AND SEARCH LOGIC FUNCTIONS ***************************** //

  // ******************* HANDLE SEARCH POPPER OPEN ****************** //
  // ******************* Opens the popper ***************** //

  const handleSearchPopOpen = (target) => {
    setPopAnchorEl(target)
  }

  // ******************* HANDLE USER VIEW EXPAND ****************** //
  // ********** Refrences the expanded user results view ********* //
  // **** (sets to true when either the 'view all' or 'back to results' is clicked) ***** //

  const handleExpand = (e) => {
    expandUser.current = true
    dispatch(getSchoolsAndDistricts({ allResults: true }))
    dispatch(getCohorts({ allResults: true }))
    setLoadingState(true)

    const recordType = userView ? 'all' : 'users'

    const filter = {
      searchText: searchInput,
      record_type: recordType
    }

    // If we are currently in the userview and switching back, we need to set the limit on the filter back to 4
    if (userView) {
      filter.limit = 4
    }

    getNewResults(filter)
    setUserView(!userView)
    // If we are switching back to all results, be sure the filter menu ref is set to false
    // (or the popper will not close when expected)
    if (filterMenuRef.current) {
      filterMenuRef.current = false
    }
  }

  // ********************** HANDLE SEARCH USERS ONLY ********************* //
  // **** searches es for just the users and looks for additional filters ***** //

  const handleSearchUsers = (schoolID, districtID, cohortID, userRoleIDs, inFellowTypeID) => {
    const filter = {
      record_type: 'users',
      schoolIDs: schoolID ? [schoolID] : [],
      schoolDistrictIDs: districtID ? [districtID] : [],
      cohortIDs: cohortID ? [cohortID] : [],
      roleIDs: userRoleIDs && userRoleIDs.length ? userRoleIDs : [],
      inFellowTypeIDs: inFellowTypeID ? [inFellowTypeID] : [],
      searchText: searchInput
    }

    getNewResults(filter)
  }

  // ******************* HANDLE RESET ****************** //
  // ********** Resets the entire popper state ********** //

  const handleReset = useCallback(() => {
    // State Reset
    setPopAnchorEl(null)
    setSearchInput('')
    setSearchResults(defaultResults)
    setUserView(false)
    setToggleDrawer(false)

    // Ref Reset
    expandUser.current = false
    popperRef.current = null
    filterMenuRef.current = false
    uiLoading.current = false

    setLoadingState(true)
  }, [defaultResults, setToggleDrawer])

  // *************************************** CLOSE THE POPPER ******************************************* //
  // ******* If the popper is open: Decides if we have clicked inside the popper, and if not, will reset the popper ***** //
  // ****** Also looks for the expanded user ref and sets it to false
  // ****** Also will catch if a sub menu is open and not unexpectedly close the popper

  const handleSearchPopClose = (e) => {
    // If the popper is not open, do not do anything
    if (popAnchorEl) {
      // Checks to see if we have clicked inside the popper element
      const clickedPopper = popperRef.current.contains(e.target)
      // Checks to be sure we clicked inside the search input
      const clickedInput = e.target.id === searchInputID

      // Checks to see if we are viewing the expanded user section
      if (expandUser.current) {
        // Set the user results ref back to false
        expandUser.current = false
      }

      // If the popper is open, but we did not click inside the popper, or inside the input when it had length,  and there is not a sub menu open, close the popper
      if (popperRef && popperRef.current && !clickedPopper && !filterMenuRef.current && Boolean(!clickedInput || (clickedInput && searchInput.length === 0))) {
        handleReset()
      }
    }
  }

  // ************************************ GET NEW SEARCH RESULTS ******************************************* //
  // ************* Api call that will return a set of results based on specific inputs ************** //
  // ************* One of either 'users', 'resource', 'artifacts', or 'all results' ************** //

  const getNewResults = useCallback(async (filter) => {
    const newResults = await dispatch(getSearchResults(filter))
    setSearchResults(newResults)
    // give the new results time to load before removing the loading state
    setTimeout(() => {
      if (loadingState) {
        setLoadingState(false)
        uiLoading.current = false
      }
    }, 1000)
  }, [dispatch, loadingState])

  // ********************************************** HANDLE SEARCH *********************************************** //
  // ************* If the enter key is pressed or the search icon is clicked, search the es record ************** //

  const handleSearch = useCallback(async (value) => {
    // Check if we are in the expanded user view and return us to the all-results view
    if (userView) {
      setUserView(false)
      expandUser.current = false
    }

    const filter = {
      limit: 4,
      searchText: value
    }
    // fetch the new results
    if (value && value.length && value.length > 0 && !uiLoading.current) {
      uiLoading.current = true
      getNewResults(filter)
    }
  }, [userView, getNewResults])

  // ********************************************** HANDLE TIMED SEARCH *********************************************** //
  // ************* Looks for the user to stop typing before firing the search handler ************** //
  const handleTimedSearch = (e) => {
    setTimeout(() => {
      handleSearch(e.target.value)
    }, 1000)
  }

  // ********************************************** VIEW ALL (ARTIFACTS) *********************************************** //
  // ************* When clicking the 'View All' buttons, route to explore with a temporary query param ************** //
  const handleViewAllArts = (artType) => {
    const filter = {
      type: artType,
      search: searchInput
    }
    if (artType === esRecordType.EF) {
      dispatch(setExploreActiveTab('ed-farm-resources'))
    } else {
      dispatch(setExploreActiveTab('all-posts'))
    }
    dispatch(setSearchExploreFilter(filter))
    handleReset()
    props.history.push({
      pathname: '/explore',
      search: '?search=artifacts'
    })
  }

  // ****************************************************** VIEW ALL (no results) ********************************************************* //
  // ************* Redirects the user to a specific tab of the explore page when there were no results matching their search ************** //

  const handleNoResultsViewAll = (e, tab) => {
    e.preventDefault()
    dispatch(setExploreActiveTab(tab))
    props.history.push('/explore')
    handleReset()
  }

  // **************************************** HANDLE INPUT CHANGE ***************************************** //
  // ************* Control the state of the popper and history state when altering the input ************** //

  const handleInputChange = (value, target) => {
    // If the input is changing be sure the loading screen is viewed
    if (!loadingState) { setLoadingState(true) }

    const newVal = value
    setSearchInput(newVal)

    // If the input is changing but the popper is not open, then open it.
    if (newVal.length !== 0 && !popAnchorEl) {
      handleSearchPopOpen(target)
    }

    // If the user has deleted their input, reset state
    if (newVal.length === 0) {
      handleReset()
    }
  }

  return (
    <>
      <OutlinedInput
        size='small'
        margin='dense'
        ref={setInputRef}
        inputProps={{ id: 'search-input', style: { border: 'none', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '5px' } }}
        fullWidth
        autoComplete='off'
        placeholder='Search...'
        value={searchInput}
        onKeyUp={(e) => handleTimedSearch(e)}
        classes={{ root: classes.searchInput, input: classes.inputPlaceholder, focused: classes.focusedInput }}
        endAdornment={
          searchInput && Boolean(searchInput.length)
            ? <CancelRounded onClick={(e) => handleReset()} style={{ color: theme.palette.grey.medium, fontSize: '20px', cursor: 'pointer' }} />
            : <SearchRounded style={{ color: theme.palette.grey.medium, fontSize: '20px' }} />
        }
        onChange={(e) => { handleInputChange(e.target.value, e.currentTarget) }}
      />

      <SearchPopper
        classes={classes}
        openSearchPop={openSearchPop}
        popAnchorEl={popAnchorEl}
        searchPopoverID={searchPopoverID}
        handleSearchPopClose={handleSearchPopClose}
        popperRef={popperRef}
        arrowRef={arrowRef}
        setArrowRef={setArrowRef}
        handleReset={handleReset}
        searchResults={searchResults}
        loadingState={loadingState}
        expandUser={expandUser}
        userView={userView}
        handleExpand={handleExpand}
        filterMenuRef={filterMenuRef}
        handleViewAllArts={handleViewAllArts}
        handleSearchUsers={handleSearchUsers}
        handleNoResultsViewAll={handleNoResultsViewAll}
        inputRef={inputRef}
      />
    </>
  )
}

export default withRouter(SearchBarNav)
