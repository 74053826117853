import React, { } from 'react'
import { SvgIcon } from '@material-ui/core'

const ApproveIcon = (props) => {
  return (
    <SvgIcon viewBox='0 0 9.674 7.214' {...props}>
      <path
        id='approve-icon'
        data-name='approve-icon'
        d='M3.286,72.171.142,69.027a.484.484,0,0,1,0-.684l.684-.684a.484.484,0,0,1,.684,0l2.118,2.118L8.165,65.24a.484.484,0,0,1,.684,0l.684.684a.484.484,0,0,1,0,.684L3.97,72.171a.484.484,0,0,1-.684,0Z'
        transform='translate(0 -65.098)'
        fill='current'
      />
    </SvgIcon>
  )
}

export default ApproveIcon
