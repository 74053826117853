import React, { useState, useCallback, useEffect, useRef, useMemo, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import queryString from 'query-string'
import { getConversations, setActiveConversationUsers } from '../../redux/actions'
import { debounce } from 'lodash'
import { useTheme, useMediaQuery, makeStyles } from '@material-ui/core'

import NavBar from '../ui/tools/NavBar'
import ConveyMessage from '../ui/Convey/ConveyMessage'
import ConveyMessageMobile from '../ui/Convey/ConveyMessageMobile'

import { WebsocketContext } from '../ui/Convey/utils/WebSocket'

const useStyles = makeStyles((theme) => ({
  // messages
  messageContent: {
    textTransform: 'none',
    fontWeight: 400,
    minHeight: theme.typography.body1.fontSize,
    wordWrap: 'break-word'
  },
  messageNew: {
    textTransform: 'none',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: 1,
    color: theme.palette.purple.darkest
  },
  messageName: {
    textTransform: 'none',
    fontWeight: 600,
    lineHeight: 1
  },
  messageDate: {
    textTransform: 'none',
    fontWeight: 400,
    color: theme.palette.grey.dark,
    lineHeight: 1
  },
  // ConveyMessage
  convoAreaGrid: {
    paddingTop: '.5em',
    height: '80vh',
    [theme.breakpoints.down('sm')]: {
      height: '20vh'
    }
  },
  // NoConversations
  noConvosText: {
    color: theme.palette.grey.medium,
    textAlign: 'center',
    fontWeight: 400,
    textTransform: 'none'
  },
  noConvosTextGrid: {
    marginTop: '4em',
    [theme.breakpoints.down('sm')]:
    {
      marginTop: '1em'
    }
  },
  // ConversationCard
  cardAvi: {
    justifyContent: 'center',
    alignContent: 'flex-start',
    [theme.breakpoints.down('md')]: { display: 'none' }
  },
  avi: {
    [theme.breakpoints.between('md', 'lg')]: {
      height: '1.3em',
      width: '1.3em'
    },
    [theme.breakpoints.up('lg')]: {
      height: '1.7em',
      width: '1.7em'
    },
    [theme.breakpoints.down('sm')]: { display: 'none' }
  },
  unreadBadge: {
    height: '12px',
    width: '12px',
    borderRadius: '6px'
  },
  badgeColor: {
    backgroundColor: '#25b3da'
  },
  dividerContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  dividerBorder: {
    borderBottom: '1px solid lightgray',
    width: '30%'
  },
  dividerContent: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    fontWeight: 600,
    fontSize: 16,
    color: 'lightgray'
  }
}))

const MessageInbox = (props) => {
  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()

  const smScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const parsedProps = queryString.parse(props.location.search)

  // *** NOTE: WebSocket context is mainly being used in MessageChain. Only here for closing connection. ***
  // Websocket
  const [ws = {}] = [useContext(WebsocketContext)]

  // Intentionally Close the connection
  const closeWSConnection = useCallback(() => {
    if (ws && ws.closeConnection) {
      ws.closeConnection()
    }
  }, [ws])

  // *** NOTE: This was initially in MessageChain. Moved when making changes for mobile to prevent connections each time MessageArea is rendered. ***
  // Close the connection on component unmount
  useEffect(() => {
    return () => {
      closeWSConnection()
    }
  }, [closeWSConnection])

  // redux state
  const { conversations = [] } = useSelector(state => state.websocket)

  // Sets a storage event listener across a browser's tabs/windows on messaging mount, and removes it on unmount
  useEffect(() => {
    // Callback fired when the storage changes
    const triggerGetConversations = () => {
      // Grab the conversation list in localStorage
      const localStorageList = JSON.parse(JSON.parse(window.localStorage.getItem('persist:root')).websocket).conversations

      // If the local storage length does not match the length of the conversation list in redux
      if (localStorageList && localStorageList.length !== conversations.length) {
        // Fire the get conversations call to get the correct list of conversations
        dispatch(getConversations())
      }
    }

    // Debounce the callback by two seconds
    const debounceListener = debounce(triggerGetConversations, 2000)

    // Set the event listener
    window.addEventListener('storage', debounceListener)

    // Remove it on unmount of the convey area
    return () => {
      window.removeEventListener('storage', debounceListener)
    }
  }, [dispatch, conversations.length])

  const [activeConversationID, setActiveConversationID] = useState(null)

  // Uses the url params to determine the current selected view
  const [storedView, setStoredView] = useState(null)

  useMemo(() => {
    if (typeof parsedProps.conversationID !== 'undefined') { setActiveConversationID(parsedProps.conversationID) }
  }, [parsedProps.conversationID, setActiveConversationID])

  useMemo(() => {
    if (parsedProps.view && typeof parsedProps.view === 'string') { setStoredView(parsedProps.view.toUpperCase()) }
  }, [parsedProps.view, setStoredView])

  const [navRef, setNavRef] = useState(null)
  const [fetchNew, setFetchNew] = useState(false)
  const navHeight = navRef ? navRef.clientHeight : 0

  // Used to keep state from reloading when starting a new conversation
  const uiLoading = useRef(Boolean(false))
  const messagesInitialLoad = useRef(Boolean(true))

  const firePollSuccess = useCallback((data = 0, unread = 0) => {
    // if the umber do not match, trigger getting the conversation list again
    if (data !== unread) {
      setFetchNew(true)
    } else {
      setFetchNew(false)
    }
  }, [])

  // Reset values on unmount
  useEffect(() => {
    return () => {
      uiLoading.current = false
      messagesInitialLoad.current = true
      setNavRef(null)
      setFetchNew(false)
      setActiveConversationID(null)
      dispatch(setActiveConversationUsers([]))
      setStoredView(null)
    }
  }, [dispatch])

  return (
    <>
      <NavBar setNavRef={setNavRef} navHeight={navHeight} fireNewMessagesSuccess={firePollSuccess} />
      {
        smScreen
          ? (
            <>
              <ConveyMessageMobile
                parsedProps={parsedProps}
                classes={classes}
                theme={theme}
                smScreen={smScreen}
                activeConversationID={activeConversationID}
                setActiveConversationID={setActiveConversationID}
                storedView={storedView}
                setStoredView={setStoredView}
                navHeight={navHeight}
                fetchNew={fetchNew}
                setFetchNew={setFetchNew}
                uiLoading={uiLoading}
                messagesInitialLoad={messagesInitialLoad}
              />
            </>
          ) : (
            <>
              <ConveyMessage
                parsedProps={parsedProps}
                classes={classes}
                theme={theme}
                smScreen={smScreen}
                activeConversationID={activeConversationID}
                setActiveConversationID={setActiveConversationID}
                storedView={storedView}
                setStoredView={setStoredView}
                navHeight={navHeight}
                fetchNew={fetchNew}
                setFetchNew={setFetchNew}
                uiLoading={uiLoading}
                messagesInitialLoad={messagesInitialLoad}
              />
            </>
          )
      }
    </>
  )
}

export default withRouter(MessageInbox)
