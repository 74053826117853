import { parseResponse } from '../../lib'
import { buildUserAvatarPath } from '../../utils'

// UI Tech Debt: Removed api variable that wasn't being used anywhere

const api = process.env.REACT_APP_API_URL
const apiversion = process.env.REACT_APP_API_VERSION

// default response of no results returned
const searchNone = {
  userResults: [],
  resourceResults: [],
  artifactResults: {
    LEDs: [],
    LWs: [],
    PROs: []
  }
}

/* Used in the search results bar/popper to fetch results based on the search input across 3 main cateogires (user, resource, artifacts),
   and splits the artifact category into the three major types.
*/
export const getSearchResults = (filter, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      if (!token) {
        fireFailure()
        return false
      }

      if (!filter.record_type) {
        filter.record_type = 'all'
      }

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ filter: filter })
      }

      const response = await window.fetch(`${api}/${apiversion}/search`, options)
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) {
        return false
      }

      if (parsedResponse.error) {
        fireFailure()
      } else {
        // Full ES Results
        const { data } = await response.json()

        const { userResults, efrResults, ledResults, lwResults, prResults } = data

        // Format the user results
        const users = userResults ? userResults.hits.reduce((arr, hit) => {
          if (hit._source) {
            const { profileAvatarKey, userID } = hit._source
            if (profileAvatarKey) {
              const profileAvatarPath = buildUserAvatarPath(profileAvatarKey, userID)
              arr.push({ ...hit._source, profileAvatarPath })
            } else {
              arr.push(hit._source)
            }
          }
          return arr
        }, []) : []

        // Format the efr results
        const resources = efrResults ? efrResults.hits.reduce((arr, hit) => {
          if (hit._source) {
            const { userProfileAvatarKey, userID } = hit._source
            if (userProfileAvatarKey) {
              const profileAvatarPath = buildUserAvatarPath(userProfileAvatarKey, userID)
              arr.push({ ...hit._source, profileAvatarPath })
            } else {
              arr.push(hit._source)
            }
          }
          return arr
        }, []) : []

        // Format the LED results
        const LEDs = ledResults ? ledResults.hits.reduce((arr, hit) => {
          if (hit._source) {
            const { userProfileAvatarKey, userID } = hit._source
            if (userProfileAvatarKey) {
              const profileAvatarPath = buildUserAvatarPath(userProfileAvatarKey, userID)
              arr.push({ ...hit._source, profileAvatarPath })
            } else {
              arr.push(hit._source)
            }
          }
          return arr
        }, []) : []

        // Format the LW results
        const LWs = lwResults ? lwResults.hits.reduce((arr, hit) => {
          if (hit._source) {
            const { userProfileAvatarKey, userID } = hit._source
            if (userProfileAvatarKey) {
              const profileAvatarPath = buildUserAvatarPath(userProfileAvatarKey, userID)
              arr.push({ ...hit._source, profileAvatarPath })
            } else {
              arr.push(hit._source)
            }
          }
          return arr
        }, []) : []

        // Format the PRO results
        const PROs = prResults ? prResults.hits.reduce((arr, hit) => {
          if (hit._source) {
            const { userProfileAvatarKey, userID } = hit._source
            if (userProfileAvatarKey) {
              const profileAvatarPath = buildUserAvatarPath(userProfileAvatarKey, userID)
              arr.push({ ...hit._source, profileAvatarPath })
            } else {
              arr.push(hit._source)
            }
          }
          return arr
        }, []) : []

        // Format the total hits from each es query
        const totalUserResults = userResults ? userResults.total.value || 0 : 0
        const totalResourceResults = efrResults ? efrResults.total.value || 0 : 0
        const totalLEDResults = ledResults ? ledResults.total.value || 0 : 0
        const totalLWResults = lwResults ? lwResults.total.value || 0 : 0
        const totalPROResults = prResults ? prResults.total.value || 0 : 0
        const totalArtifactResults = totalLEDResults + totalLWResults + totalPROResults || 0
        const fullResultsTotal = totalUserResults + totalResourceResults + totalArtifactResults || 0

        const totals = {
          totalUserResults,
          totalResourceResults,
          totalLEDResults,
          totalLWResults,
          totalPROResults,
          totalArtifactResults,
          fullResultsTotal
        }

        // Fully formattted results:
        const formattedData = {
          userResults: userResults && Boolean(Object.keys(userResults).length) ? users : [],
          resourceResults: efrResults && Boolean(Object.keys(efrResults).length) ? resources : [],
          artifactResults: {
            LEDs: ledResults && Boolean(Object.keys(ledResults).length) ? LEDs : [],
            LWs: lwResults && Boolean(Object.keys(lwResults).length) ? LWs : [],
            PROs: prResults && Boolean(Object.keys(prResults).length) ? PROs : []
          },
          totals
        }
        fireSuccess()
        return (formattedData)
      }

      // If something goes wrong, return the 'no results' format
      return searchNone
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

// Fetches the user's five most recent search terms
export const getUserSearchHistory = (fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '', userID } = getState().auth

      if (!token) {
        fireFailure()
        return false
      }

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await window.fetch(`${api}/${apiversion}/search/${userID}/history`, options)
      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) {
        return false
      }

      if (parsedResponse.error) {
        fireFailure()
      } else {
        const { data } = await response.json()

        fireSuccess(data)
      }

      return false
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

// Inserts or updates the user's search history when they search for a new term
export const postUserSearchHistory = (searchPhrase, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '', userID } = getState().auth

      if (!token) {
        fireFailure()
        return false
      }

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ searchPhrase: searchPhrase })
      }

      const response = await window.fetch(`${api}/${apiversion}/search/${userID}/history`, options)
      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) {
        return false
      }

      if (parsedResponse.error) {
        fireFailure()
      } else {
        fireSuccess()
      }

      return false
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}
