import React, { useEffect, useCallback, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'

import {
  Grid, Typography, IconButton, Slide
} from '@material-ui/core'

import {
  NavigateBeforeRounded, NavigateNextRounded
} from '@material-ui/icons'

import { getExploreArtifacts } from '../../../redux/actions'
import EFRExploreCard from '../artifacts/EdFarmResourceForms/EFRExploreCard'
import LEDExploreCard from '../artifacts/EdFarmResourceForms/LEDExploreCard'

const ExploreFeaturedResources = (props) => {
  const { smScreen, xsScreen, xsScreenBelow, classes, edFarmFeaturedRef, scroll, mdScreen, lgScreen } = props
  const dispatch = useDispatch()

  const [featuredResources, setFeaturedResources] = useState([])

  // Fetch the list of featured EFRs
  // TODO: this may need to be sorted later by which ones are 'isActive = 1' only, and if none are found, then display the others
  const getFeaturedEFR = useCallback(async () => {
    const newFeatured = await dispatch(getExploreArtifacts({ explorerType: 'featured-resources' }))
    if (newFeatured) {
      const { posts = [] } = newFeatured
      setFeaturedResources(posts)
    }
  }, [dispatch])

  useEffect(() => {
    getFeaturedEFR()
  }, [getFeaturedEFR])

  // Used to determine if a user is hovering over a resource
  const [hovering, setHovering] = useState(false)

  // Used to determine the scroll direction of the auto scroll
  const endSection = useRef(false)

  // Auto scrolls the featured resources on an interval as long as the user is not hovering over a resource
  useEffect(() => {
    const interval = setInterval(() => {
      // As long as the user is not hovering over a resource
      if (!hovering) {
        if (!edFarmFeaturedRef || !edFarmFeaturedRef.current) { return false }
        const { scrollLeft = 0, scrollWidth = 0, offsetWidth = 0 } = edFarmFeaturedRef.current

        // divEnd indicates that the scroll width has been reached and divEndPercentage will be used in the case of the scrollWidth being less than the screen size
        const divEnd = scrollWidth - offsetWidth
        const divEndPercentage = Math.round(+Math.abs(100 - scrollWidth / offsetWidth * 100))

        // If we are at the start of the section, or the end of the section, flip the boolean value of endSection ref
        if (scrollLeft === 0 || scrollLeft === divEnd) {
          endSection.current = !endSection.current
        }

        // If the divEnd percentage is less than 15 percent, stop the auto scroll from happening
        if (divEndPercentage < 15) { return false }

        // Depending on the value of endSection, set the scroll to move forward or backwards
        const offset = endSection.current ? -750 : 750
        scroll(edFarmFeaturedRef, offset)
      }
    }, 7000)
    return () => clearInterval(interval)
  }, [edFarmFeaturedRef, scroll, hovering])

  return (
    <>
      {/* Title and Navi Buttons */}
      <Grid
        item
        container
        direction='column'
        style={{ padding: xsScreen ? '2.5em 1.5em 0 1.5em' : '2.5em 2.5em 0 2.5em' }}
      >
        <Grid item container style={{ marginBottom: '.5em' }}>
          <Typography variant='h1' style={{ color: 'white', fontWeight: 600, fontSize: '42px' }}>Explore</Typography>
        </Grid>
        <Grid item container direction='row' justifyContent='space-between' style={{ paddingRight: '1em', marginBottom: featuredResources && featuredResources.length ? '0' : '2em' }}>
          <Grid item xs={xsScreenBelow ? 12 : 8} sm={9} container direction='row' alignItems='flex-end'>
            <Typography variant='h5' style={{ textTransform: 'none', fontWeight: 400, color: 'white' }}>Explore high-quality learning articles and resources from Ed Farm and other educators.</Typography>
          </Grid>
          {featuredResources && Boolean(featuredResources.length) &&
            <Grid item xs={xsScreenBelow ? 12 : 4} sm={3} container direction='row' justifyContent='flex-end'>
              <Grid item style={{ marginRight: '1em' }}>
                <IconButton className={classes.exploreFabs} onClick={(e) => scroll(edFarmFeaturedRef, -750)}>
                  <NavigateBeforeRounded style={{ fontSize: '2.5rem' }} />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton className={classes.exploreFabs} onClick={(e) => scroll(edFarmFeaturedRef, 750)}>
                  <NavigateNextRounded style={{ fontSize: '2.5rem' }} />
                </IconButton>
              </Grid>
            </Grid>}
        </Grid>
      </Grid>

      {/* Ed Farm Featured Cards */}
      <Slide
        in={Boolean(featuredResources && featuredResources.length)}
        direction='left'
        timeout={{
          enter: 500,
          exit: 10
        }}
        exit={false}
        unmountOnExit
        appear
        mountOnEnter
      >
        <Grid
          direction='row'
          container
          ref={edFarmFeaturedRef}
          spacing={2}
          style={{ flexWrap: 'nowrap', overflowX: 'scroll', scrollBehavior: 'smooth', padding: '2em 2.5em', justifyContent: 'space-between' }}
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
        >
          {featuredResources && Boolean(featuredResources.length) &&
            featuredResources.map(resource => {
              const { artifactType = '' } = resource
              return (
                <Grid
                  item
                  key={resource.artifactID}
                  style={{
                    display: 'flex',
                    width: '100%',
                    height: 'auto',
                    minHeight: 'fit-content'
                  }}
                >
                  {artifactType && artifactType === 'EFR' &&
                    <EFRExploreCard
                      resource={resource}
                      xsScreenBelow={xsScreenBelow}
                      xsScreen={xsScreen}
                      smScreen={smScreen}
                      mdScreen={mdScreen}
                      lgScreen={lgScreen}
                    />}
                  {artifactType && artifactType === 'LED' &&
                    <LEDExploreCard
                      resource={resource}
                      xsScreenBelow={xsScreenBelow}
                      xsScreen={xsScreen}
                      smScreen={smScreen}
                      mdScreen={mdScreen}
                      lgScreen={lgScreen}
                    />}
                </Grid>
              )
            })}
        </Grid>
      </Slide>
    </>
  )
}

export default ExploreFeaturedResources
