import React, { useState, useMemo } from 'react'
import {
  Grid, Typography, Avatar, Paper, OutlinedInput, Popover, Button, useTheme
} from '@material-ui/core'
import {
  MoreVert, DeleteOutlineRounded, AddOutlined
} from '@material-ui/icons'
import { ModifiedEditIcon } from '../../custom'
import EFLogo from '../../../../assets/edFarmLogo.png'

const AssessorComment = (props) => {
  const {
    currentStyle = {}, isAssessorView = false, defaultOpen = false, modifyQuestionComment = () => { }, currentQuestion = {}, handleGradeQuestion = () => { }, questionSection = null,
    isPursuerView = false
  } = props
  const theme = useTheme()

  const { assessorGrade, attemptComment, lastAttemptComment } = currentQuestion

  const [openComment, setOpenComment] = useState(false)
  const [commentSubmitted, setCommentSubmitted] = useState(false)
  const [localInput, setLocalInput] = useState('')

  useMemo(() => {
    if (defaultOpen) {
      if (attemptComment || (isPursuerView && lastAttemptComment)) {
        setCommentSubmitted(true)
      } else {
        setOpenComment(true)
      }
    }
  }, [defaultOpen, attemptComment, isPursuerView, lastAttemptComment])

  useMemo(() => {
    if (assessorGrade === 0 && !attemptComment) {
      setOpenComment(true)
      setLocalInput('')
    }
  }, [assessorGrade, attemptComment])

  const handleAddComment = (e) => {
    if (openComment) {
      if (!localInput.length && assessorGrade === 0) {
        // trigger pop up
        handleCommentRequiredPopover(e)
      }
      modifyQuestionComment(currentQuestion, localInput, questionSection)
      setCommentSubmitted(Boolean(localInput.length))
    }
    setOpenComment(!openComment)
  }

  const handleEditComment = () => {
    handleOptionsPopClose()
    setLocalInput(currentQuestion?.attemptComment)
    setCommentSubmitted(false)
    setOpenComment(true)
  }

  const handleDeleteCheck = (e) => {
    if (assessorGrade === 0) {
      handleCommentRequiredPopover(e)
    } else {
      handleDeleteComment()
    }
  }

  const handleDeleteComment = (e) => {
    handleOptionsPopClose()
    modifyQuestionComment(currentQuestion, '', questionSection)
    setCommentSubmitted(false)
    setOpenComment(false)
    setLocalInput('')
  }

  const handleReset = () => {
    handleOptionsPopClose()
    setOpenComment(false)
    setLocalInput('')
  }

  const handleCancel = (e) => {
    if (!attemptComment) {
      if (assessorGrade === 0) {
        handleCommentRequiredPopover(e)
      } else {
        setCommentSubmitted(false)
        handleReset()
      }
    } else {
      setCommentSubmitted(true)
      handleReset()
    }
  }

  const handleConfirmNoComment = () => {
    handleCommentRequiredPopClose()
    const id = currentQuestion.questionID || currentQuestion.workSampleID
    handleGradeQuestion(questionSection, id, null)
    handleDeleteComment()
  }

  const [optionsAnchorEl, setOptionsAnchorEl] = useState(null)
  const handleOptionsPopover = (e) => {
    setOptionsAnchorEl(e.currentTarget)
  }
  const handleOptionsPopClose = () => {
    setOptionsAnchorEl(null)
  }
  const openOptionsPopover = Boolean(optionsAnchorEl)
  const optionsPopoverID = openOptionsPopover ? 'options-popover' : undefined

  const [commentRequiredAnchorEl, setCommentRequiredAnchorEl] = useState(null)

  const handleCommentRequiredPopover = (e) => {
    setCommentRequiredAnchorEl(e.currentTarget)
  }
  const handleCommentRequiredPopClose = () => {
    if (optionsAnchorEl) {
      handleOptionsPopClose()
    }
    setCommentRequiredAnchorEl(null)
  }
  const openCommentRequiredPopover = Boolean(commentRequiredAnchorEl)
  const commentRequiredPopoverID = openCommentRequiredPopover ? 'comment-required-popover' : undefined

  return (
    <>
      <Grid item container direction='column' style={{ marginTop: '1em' }}>
        {Boolean(openComment || commentSubmitted) &&
          <Grid item container>
            <Paper elevation={commentSubmitted ? 3 : 0} style={{ width: '100%', padding: '1em' }}>
              <Grid container direction='column'>
                <Grid item container direction='row' alignItems='center' style={{ marginBottom: '.3em' }}>
                  <Grid item container direction='row' alignItems='center' xs={11}>
                    {/* User Avatar */}
                    <Avatar
                      src={EFLogo}
                      style={{ height: '26px', width: '26px', marginRight: '.3em' }}
                    />

                    {/* User Name */}
                    <Typography
                      variant='h6'
                      style={{ color: theme.palette.purple.darkest, fontWeight: 600, textTransform: 'none', marginRight: '1em' }}
                    >
                      {`${isPursuerView ? 'Previous ' : ''}Ed Farm Assessor Feedback`}
                    </Typography>
                  </Grid>
                  {Boolean(isAssessorView && commentSubmitted) &&
                    <Grid item container justifyContent='flex-end' sm={1} md={1}>
                      <MoreVert
                        style={{ cursor: 'pointer' }}
                        aria-describedby={optionsPopoverID}
                        onClick={handleOptionsPopover}
                      />
                    </Grid>}
                </Grid>
                <Grid item container style={{ marginTop: '.5em' }}>
                  {!commentSubmitted
                    ? (
                      <OutlinedInput
                        variant='outlined'
                        fullWidth
                        multiline
                        minRows={10}
                        inputProps={{
                          maxLength: 5000
                        }}
                        margin='dense'
                        placeholder='Enter comment...'
                        style={{ backgroundColor: 'white' }}
                        value={localInput}
                        onChange={(e) => setLocalInput(e.target.value)}
                      />
                    )
                    : (
                      <Typography
                        variant='body1'
                        style={{ fontWeight: '400', wordBreak: 'break-word' }}
                      >
                        {isPursuerView ? currentQuestion?.lastAttemptComment : currentQuestion?.attemptComment || 'None'}
                      </Typography>
                    )}

                </Grid>
              </Grid>
            </Paper>
          </Grid>}

        {!commentSubmitted && isAssessorView &&
          <Grid item container direction='row' spacing={3} style={{ marginTop: '1em', paddingLeft: openComment ? '1em' : 0 }}>
            <Grid item>
              <Button
                variant='contained'
                size='small'
                style={{ backgroundColor: currentStyle?.mainColor, fontWeight: 600 }}
                startIcon={<AddOutlined />}
                onClick={(e) => handleAddComment(e)}
              >
                {!openComment ? 'Add Comment' : 'Submit Comment'}
              </Button>
            </Grid>
            {openComment &&
              <Grid item>
                <Button
                  variant='outlined'
                  size='small'
                  style={{ borderColor: currentStyle?.mainColor, color: currentStyle?.mainColor, fontWeight: 600 }}
                  onClick={(e) => { handleCancel(e) }}
                >
                  Cancel
                </Button>
              </Grid>}
          </Grid>}
      </Grid>

      {/* EDIT/DELETE ACTION POPOVER */}
      <Popover
        id={optionsPopoverID}
        open={openOptionsPopover}
        anchorEl={optionsAnchorEl}
        onClose={handleOptionsPopClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Grid container direction='column' style={{ padding: '.3em 1em' }}>
          <Grid item container justifyContent='flex-start'>
            <Button
              startIcon={
                <ModifiedEditIcon style={{ color: currentStyle?.mainColor }} />
              }
              style={{
                textTransform: 'none',
                fontWeight: '600'
              }}
              onClick={() => handleEditComment()}
            >
              Edit
            </Button>
          </Grid>
          <Grid item container justifyContent='flex-start'>
            <Button
              startIcon={
                <DeleteOutlineRounded style={{ color: currentStyle?.mainColor }} />
              }
              style={{
                textTransform: 'none',
                fontWeight: '600'
              }}
              onClick={(e) => handleDeleteCheck(e)}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      </Popover>

      {/* COMMENT REQUIRED ON DENIAL POPOVER */}
      <Popover
        id={commentRequiredPopoverID}
        open={openCommentRequiredPopover}
        anchorEl={commentRequiredAnchorEl}
        onClose={handleCommentRequiredPopClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <Grid container direction='column' style={{ padding: '1em' }}>
          <Grid item container direction='column'>
            <Typography variant='h6' style={{ color: theme.palette.purple.darkest }}>Heads up!</Typography>
            <Typography gutterBottom style={{ fontWeight: 600 }}>An assessor comment is required when a question or sample does not pass.</Typography>
            <Typography>If you exit this commenting process it will mark this question as incomplete.</Typography>
            <Typography gutterBottom>Do you wish to proceed?</Typography>
          </Grid>
          <Grid item container direction='row' justifyContent='flex-end' spacing={3}>
            <Grid item>
              <Button
                variant='text'
                style={{ textTransform: 'none', fontWeight: 600, color: theme.palette.purple.darkest }}
                onClick={() => handleConfirmNoComment()}
              >
                Continue
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                size='small'
                onClick={() => handleCommentRequiredPopClose()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Popover>
    </>
  )
}

export default AssessorComment
