import React from 'react'

import { Grid, Typography, List, ListItem, Collapse, useMediaQuery, useTheme } from '@material-ui/core'
import { LocalLibraryRounded, ExpandLess, ExpandMore } from '@material-ui/icons'
import { gradeNameCategories, artifactTypes } from '../../../utils'

const ExpandedGrades = (props) => {
  const { grades = [], gradesOpen, setGradesOpen, artifactType, styles } = props

  const theme = useTheme()
  const mdScreenDown = useMediaQuery(theme.breakpoints.down('md'))

  if (grades) {
    const keys = Object.keys(gradeNameCategories)
    const currentGradeCategories = []

    if (grades && grades.length) {
      keys.forEach(key => {
        if (Array.isArray(gradeNameCategories[key])) {
          if (gradeNameCategories[key].some(x => grades.includes(x))) {
            currentGradeCategories.push(key)
          }
        }
      })
      // If the grades array only has more than one grade, show all of them instead of just 'Multiple Grades'
      if (grades.length > 1) {
        return (
          <Grid container item direction='column'>
            <List style={{ padding: 0, paddingBottom: '0.3em' }}>
              <Grid item container direction='row' alignItems='center'>
                <Grid item xs={artifactType === artifactTypes.LW ? 1 : 2}>
                  <LocalLibraryRounded style={{ color: styles.mainColor, fontSize: 24, backgroundColor: `${styles.mainColor}12`, borderRadius: '50%', padding: '.2em' }} />
                </Grid>
                <Grid item xs={artifactType === artifactTypes.LW ? 11 : 10}>
                  <ListItem
                    onClick={() => setGradesOpen(!gradesOpen)}
                    style={{ padding: 0, cursor: 'pointer', textOverflow: 'ellipsis' }}
                  >
                    <Typography variant='h6' noWrap style={{ textTransform: 'none', color: 'black', fontWeight: '600', paddingLeft: mdScreenDown ? artifactType === artifactTypes.LW ? '1.5em' : '1em' : '0.7em' }}>
                      {currentGradeCategories && currentGradeCategories.length === 1
                        ? currentGradeCategories.includes('Pre_School') ? currentGradeCategories[0].replace(/_/g, '-')
                          : currentGradeCategories[0].replace(/_/g, ' ')
                        : 'Multiple Grades'}
                    </Typography>
                    {gradesOpen ? <ExpandLess style={{ color: styles.mainColor, padding: 0 }} /> : <ExpandMore style={{ color: styles.mainColor, padding: 0 }} />}
                  </ListItem>
                </Grid>
              </Grid>
              <Grid item container>
                <Collapse in={gradesOpen} unmountOnExit style={{ padding: 0 }}>
                  <ListItem style={{ paddingTop: 0 }}>
                    <ul style={{ listStyle: 'outside' }}>
                      {grades.map((grade, i) => {
                        return (
                          <li key={`grades-${grade.replace(' ', '-')}`} style={{ color: artifactType === artifactTypes.LW ? styles.mainColor : theme.palette.pink.dark }}>
                            <span style={{ color: 'black' }}>
                              <Typography variant='body1' style={{ textTransform: 'none', color: 'black' }}>
                                {grade}
                              </Typography>
                            </span>
                          </li>
                        )
                      })}
                    </ul>
                  </ListItem>
                </Collapse>
              </Grid>
            </List>
          </Grid>
        )
      } else {
        return (
          <Grid item container direction='column'>
            <Grid item container direction='row' alignItems='center'>
              <Grid item xs={artifactType === artifactTypes.LW ? 1 : 2}>
                <LocalLibraryRounded style={{ color: styles.mainColor, fontSize: 24, backgroundColor: `${styles.mainColor}12`, borderRadius: '50%', padding: '.2em' }} />
              </Grid>
              <Grid item xs={artifactType === artifactTypes.LW ? 11 : 10}>
                <Typography variant='h6' style={{ textTransform: 'none', color: 'black', fontWeight: '600', paddingLeft: '1em' }}>{grades[0]}</Typography>
              </Grid>
            </Grid>
          </Grid>
        )
      }
    }
  }

  return 'N/A'
}

export default ExpandedGrades
