import React, { } from 'react'
import { SvgIcon } from '@material-ui/core'

const ResubmitIcon = (props) => {
  return (
    <SvgIcon viewBox='0 0 32 32' {...props}>
      <path
        id='resubmit-icon'
        data-name='resubmit-icon'
        d='M24.029,8a15.945,15.945,0,0,1,11.023,4.431l2.3-2.3A1.548,1.548,0,0,1,40,11.222v8.649a1.548,1.548,0,0,1-1.548,1.548H29.8a1.548,1.548,0,0,1-1.095-2.643L31.4,16.082a10.838,10.838,0,1,0-.264,16.075.773.773,0,0,1,1.056.036l2.559,2.559a.776.776,0,0,1-.031,1.125A16,16,0,1,1,24.029,8Z'
        transform='translate(-8 -8)'
      />
    </SvgIcon>
  )
}

export default ResubmitIcon
