import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import {
  Grid, Typography, List, ListItem, makeStyles, Collapse, useTheme, alpha, Badge, styled
} from '@material-ui/core'
import { Security, ExpandLess, ExpandMore } from '@material-ui/icons'
import { isEqual } from 'lodash'

import NavBar from '../ui/tools/NavBar'

import {
  TeacherFellows, SignUps, AdminArtifacts, CohortsAdmin, AdminSchools, AdminDistricts,
  AdminSubjects, AdminCerts, AdminUserList, FeaturedResources, FeaturedLEDs, InnovationFellows, AdminMCEAssessors, AdminMCEEvaluations,
  AdminMicroCredentials, AdminMCEBadges
} from '../ui/admin'

import {
  getTeacherFellows, getSignUps, getCohorts, getSubjects, getCertifications, getSchoolsAndDistricts,
  getArtifacts, getEFRAdminList, getAdminUserList, getFeaturedLEDOptions, getInnovationFellows, districtsList,
  getAssessorsList, getEvaluationsList, getMicroCredentialList, deleteUser, getMCEStacks
} from '../../redux/actions'

import { adminPageOptions, updateUserActionTypes } from '../../utils'
import { ConfirmToast, NotificationToast } from '../ui/tools'

const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  navSideMain: {
    padding: '3em 0 0 2em'
  },
  mainContentSide: {
    height: 'fit-content',
    minHeight: '100vh',
    backgroundColor: theme.palette.grey.lightest,
    padding: '3em 6em 0 5.5em'
  },
  activeButton: {
    color: theme.palette.purple.darkest,
    fontWeight: 600,
    letterSpacing: '.02em'
  },
  activeMenu: {
    color: theme.palette.grey.dark,
    fontWeight: 600,
    letterSpacing: '.02em'
  },
  inputPlaceholder: {
    '&::placeholder': {
      fontStyle: 'italic',
      fontSize: '14px'
    }
  },
  inactiveButton: {
    color: theme.palette.grey.dark,
    fontWeight: 400
  },
  searchInput: {
    paddingLeft: '8px',
    backgroundColor: 'white'
  },
  statusSelect: {
    padding: '8px 0 8px 8px',
    color: theme.palette.grey.dark
  },
  filterLabel: {
    fontWeight: '400',
    color: theme.palette.grey.dark,
    fontSize: '14px'
  },
  filterSelect: {
    fontSize: '14px',
    fontWeight: '600',
    color: theme.palette.grey.dark,
    fontFamily: 'Source Sans Pro',
    paddingRight: '0px !important'
  },
  reviewSelect: {
    border: `${theme.palette.grey.medium} solid`,
    borderRadius: 4,
    color: theme.palette.grey.dark
  },
  reviewSelectInput: {
    border: 'none'
  },
  selectIcon: {
    fontSize: '14px',
    top: 'auto',
    position: 'inherit'
  },
  avatarSize: {
    height: '1.5em',
    width: '1.5em'
  },
  editIcon: {
    padding: '0 1em 0 0',
    color: '#24890E',
    '&:hover': {
      backgroundColor: 'transparent !important'
    }
  },
  addIcon: {
    fontSize: '26px',
    strokeWidth: '2px'
  },
  addNewIcon: {
    fontSize: '26px',
    stroke: theme.palette.purple.darkest,
    strokeWidth: '2px',
    marginRight: '.2em'
  },
  addAnotherBtn: {
    color: theme.palette.purple.darkest,
    textTransform: 'none',
    fontWeight: 600
  },
  deleteIcon: {
    padding: '0 1em 0 0',
    color: 'red',
    '&:hover': {
      backgroundColor: 'transparent !important'
    }
  },
  pagination: {
    '& .MuiPaginationItem-textPrimary': {
      '&.Mui-selected': {
        backgroundColor: theme.palette.purple.darkest
      }
    }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  textField: {
    '& .MuiOutlinedInput-inputMarginDense': {
      borderBottom: 'none !important',
      padding: 3,
      backgroundColor: 'white',
      border: `2px solid ${theme.palette.grey.lighter}`
    },
    '& .MuiOutlinedInput-underline:before': {
      borderBottom: 'none'
    }
  },
  aprroveButton: {
    color: 'green',
    borderColor: 'green'
  },
  denyButton: {
    color: 'red',
    borderColor: 'red'
  },
  inputRoot: {
    padding: `${theme.spacing(1, 0.5)} !important`
  },
  inputInput: {
    padding: `${theme.spacing(0, 4, 0, 4)} !important`,
    paddingLeft: `calc(1em + ${theme.spacing(5)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    color: theme.palette.purple.darkest,
    fontFamily: 'Source Sans Pro',
    fontWeight: '600'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha('#fff', 0.15),
    '&:hover': {
      backgroundColor: alpha('#fff', 0.25)
    },
    width: '100%'
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
    color: theme.palette.purple.darkest
  },
  searchSvg: {
    color: '#000',
    zIndex: 1
  },
  artifactNameBtn: {
    textTransform: 'none',
    fontWeight: '600',
    textDecoration: 'underline',
    padding: 0,
    textAlign: 'start',
    justifyContent: 'flex-start'
  },
  activeCohortRow: {
    backgroundColor: theme.palette.purple.lightest
  },
  activeCohortRowText: {
    color: theme.palette.purple.darkest,
    fontWeight: 600
  },
  deleteButton: {
    color: 'white',
    backgroundColor: '#e24646',
    '&:hover': {
      backgroundColor: '#bf3c3c'
    }
  },
  inactiveEditIcon: {
    color: '#24890E'
  },
  activeEditIcon: {
    color: '#29356a'
  },
  uploadIcon: {
    fontSize: `${theme.spacing(3.75)}px !important`
  },
  dialogPaper: {
    minWidth: '55vw'
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#e3e3e3',
    '$.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)'
    }
  },
  checkboxLabel: {
    fontSize: '13px'
  },
  checkedIcon: {
    backgroundColor: '#293669',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#293669'
    }
  },
  badgeAccordion: {
    marginBottom: theme.spacing(2),
    '&:before': {
      display: 'none'
    }
  },
  badgeAccordionSummary: {
    backgroundColor: theme.palette.purple.darkest,
    color: 'white'
  },
  badgePaper: {
    width: 'fit-content',
    minWidth: '-webkit-fill-available',
    padding: '1.5em',
    cursor: 'pointer'
  },
  badgeChip: {
    border: `2px solid ${theme.palette.grey.light}`,
    borderRadius: 20,
    padding: '0.5em 1em',
    cursor: 'pointer'
  },
  badgeColorDots: {
    display: 'flex',
    marginLeft: theme.spacing(1)
  },
  badgeColorDot: {
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '50%',
    marginLeft: '0.4rem'
  },
  badgeMicroCredential: {
    display: 'flex',
    alignItems: 'center'
  },
  badgeUploadButton: {
    padding: '0.1em 1.5em 0.1em 1.5em',
    borderRadius: 5,
    fontWeight: 600
  }
}))

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -20,
    top: 12,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    color: '#fff',
    backgroundColor: theme.palette.pink.dark
  }
}))

// Views contained within parent menus
const profileManagementViews = [adminPageOptions.SCHOOLS, adminPageOptions.DISTRICTS, adminPageOptions.SUBJECTS, adminPageOptions.CERTS]
const allUserViews = [adminPageOptions.ADMIN_USERS, adminPageOptions.TEACHER_FELLOWS, adminPageOptions.INNOVATION_FELLOWS]
const featuredArtifactViews = [adminPageOptions.FEATURED_RESOURCES, adminPageOptions.FEATURED_LEDS]
const microCredentialViews = [adminPageOptions.MCE_ASSESSORS, adminPageOptions.MCE_EVALUATIONS, adminPageOptions.MICRO_CREDENTIALS, adminPageOptions.MCE_BADGES]

const AdminView = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()
  const parsedProps = queryString.parse(props.location.search)
  const activeTab = parsedProps.tab

  const {
    adminSchools, adminSubjects, adminCertifications, adminCohorts,
    adminSignUps, adminFellows, adminArtifacts, adminUserList, adminEfResources, adminFeaturedLEDs, adminDistricts,
    adminMCEAssessors, adminMCEEvaluations, adminMCEs
  } = useSelector(state => state.admin)
  const { superAdmin = 0 } = useSelector(state => state.auth)
  const { mceStacks = [] } = useSelector(state => state.microcredentials)

  // Base Filter needed for pagination
  const defaultFilter = useMemo(() => ({
    page: 1,
    sortCount: 10,
    sortDirection: '',
    sortType: '',
    searchPhrase: '',
    reviewFilter: {
      status: '',
      cohortID: '',
      districtID: '',
      schoolID: ''
    }
  }), [])

  // Set the filter initially
  const [filter, setFilter] = useState(defaultFilter)

  // The active page the user is viewing: dictates the rendered component and which redux call is made
  const [activeButton, setActiveButton] = useState(activeTab)

  // ********************** Teacher Fellow Logic: ************************ //
  const [teacherFellows, setTeacherFellows] = useState([])
  const [totalFellows, setTotalFellows] = useState(0)

  // Will fire initially before the filter has been changed and when the user is viewing the teacher fellows tab.
  // Fires again if the user returns to the first page of data results (and thus the filter returns to the default)
  useEffect(() => {
    if (filter && defaultFilter && isEqual(filter, defaultFilter) && activeButton === adminPageOptions.TEACHER_FELLOWS) {
      dispatch(getTeacherFellows())
    }
  }, [dispatch, activeButton, filter, defaultFilter])

  // Checks the returned array from the dispatch against the local storage and resets teacherFellows if they do not match
  useEffect(() => {
    if (
      adminFellows && adminFellows.teacherFellows && adminFellows.teacherFellows &&
      teacherFellows !== adminFellows.teacherFellows
    ) {
      setTeacherFellows(adminFellows.teacherFellows)
      setTotalFellows(adminFellows.totalFellows)
    }
  }, [adminFellows, teacherFellows])

  // ********************** Innovation Fellow Logic: ************************ //
  const [innovationFellows, setInnovationFellows] = useState([])
  const [totalIfFellows, setTotalIfFellows] = useState(0)

  // Will fire initially before the filter has been changed and when the user is viewing the innovation fellows tab.
  // Fires again if the user returns to the first page of data results (and thus the filter returns to the default)
  useEffect(() => {
    if (filter && defaultFilter && isEqual(filter, defaultFilter) && activeButton === adminPageOptions.INNOVATION_FELLOWS) {
      dispatch(getInnovationFellows())
    }
  }, [dispatch, activeButton, filter, defaultFilter])

  // Checks the returned array from the dispatch against the local storage and resets innovationFellows if they do not match
  // --- Removed length check to allow "No results"
  useEffect(() => {
    if (
      adminFellows && adminFellows.innovationFellows && adminFellows.innovationFellows &&
      innovationFellows !== adminFellows.innovationFellows
    ) {
      setInnovationFellows(adminFellows.innovationFellows)
      setTotalIfFellows(adminFellows.totalIfFellows)
    }
  }, [adminFellows, innovationFellows])

  // ********************** Featured Resource Logic: ************************ //
  const [featuredResources, setFeaturedResources] = useState([])
  const [totalResources, setTotalResources] = useState(0)

  // Will fire initially before the filter has been changed and when the user is viewing the featuredResource tab.
  // Fires again if the user returns to the first page of data results (and thus the filter returns to the default)
  useEffect(() => {
    if (filter && defaultFilter && isEqual(filter, defaultFilter) && activeButton === adminPageOptions.FEATURED_RESOURCES) {
      dispatch(getEFRAdminList())
    }
  }, [dispatch, activeButton, filter, defaultFilter])

  // Checks the returned array from the dispatch against the local storage and resets featuredResources if they do not match
  useEffect(() => {
    if (adminEfResources && adminEfResources.resources && featuredResources !== adminEfResources.resources) {
      setFeaturedResources(adminEfResources.resources)
      setTotalResources(adminEfResources.resourceCount)
    }
  }, [adminEfResources, featuredResources])

  // ********************** Featured Resource Logic: ************************ //
  const [featuredLEDs, setFeaturedLEDs] = useState([])
  const [totalLEDs, setTotalLEDs] = useState(0)

  useEffect(() => {
    if (filter && defaultFilter && isEqual(filter, defaultFilter) && activeButton === adminPageOptions.FEATURED_LEDS) {
      dispatch(getFeaturedLEDOptions())
    }
  }, [dispatch, activeButton, filter, defaultFilter])

  useEffect(() => {
    if (adminFeaturedLEDs && adminFeaturedLEDs.artifacts && featuredLEDs !== adminFeaturedLEDs.artifacts) {
      setFeaturedLEDs(adminFeaturedLEDs.artifacts)
      setTotalLEDs(adminFeaturedLEDs.artifactTotal)
    }
  }, [adminFeaturedLEDs, featuredLEDs])

  // ********************* Sign Up/Pending Users Logic: ******************* //
  const [pendingUsers, setPendingUsers] = useState([])
  const [totalPending, setTotalPending] = useState(0)

  useEffect(() => {
    if (filter && defaultFilter && isEqual(filter, defaultFilter)) {
      dispatch(getSignUps())
    }
  }, [dispatch, activeButton, filter, defaultFilter])

  useEffect(() => {
    if (
      adminSignUps && adminSignUps.signUps && pendingUsers !== adminSignUps.signUps
    ) {
      setPendingUsers(adminSignUps.signUps)
      setTotalPending(adminSignUps.totalPending)
    }
  }, [adminSignUps, pendingUsers])

  // ********************* Cohort Logic ************************ //
  const [allCohorts, setAllCohorts] = useState([])
  const [totalCohorts, setTotalCohorts] = useState(0)

  useEffect(() => {
    if (filter && defaultFilter && isEqual(filter, defaultFilter) && activeButton === adminPageOptions.COHORTS) {
      dispatch(getCohorts())
    }
  }, [dispatch, filter, defaultFilter, activeButton])

  useEffect(() => {
    if (
      adminCohorts && adminCohorts.cohorts && allCohorts !== adminCohorts.cohorts
    ) {
      setAllCohorts(adminCohorts.cohorts)
      setTotalCohorts(adminCohorts.totalCohorts)
    }
  }, [adminCohorts, allCohorts])

  // ********************** Schools Logic: ************************ //
  const [schools, setSchools] = useState([])
  const [totalSchools, setTotalSchools] = useState(0)

  useEffect(() => {
    if (filter && defaultFilter && isEqual(filter, defaultFilter) && activeButton === adminPageOptions.SCHOOLS) {
      dispatch(getSchoolsAndDistricts())
    }
  }, [dispatch, activeButton, filter, defaultFilter])

  useEffect(() => {
    if (adminSchools && adminSchools.schools && schools !== adminSchools.schools) {
      const {
        schools: adSchools = [], schoolCount: adSchoolCount = 0, districtCount: adDistrictCount = 0
      } = adminSchools

      setSchools(adSchools)
      setTotalSchools(adSchoolCount)
      setTotalDistricts(adDistrictCount)
    }
  }, [adminSchools, schools])

  // *********************** Districts Logic **************************** //
  const [districts, setDistricts] = useState([])
  const [totalDistricts, setTotalDistricts] = useState(0)

  useEffect(() => {
    if (filter && defaultFilter && isEqual(filter, defaultFilter) && activeButton === adminPageOptions.DISTRICTS) {
      dispatch(districtsList(filter))
    }
  }, [activeButton, defaultFilter, dispatch, filter])

  useEffect(() => {
    if (adminDistricts && adminDistricts.districts && districts !== adminDistricts.districts) {
      setDistricts(adminDistricts.districts)
      setTotalDistricts(adminDistricts.districtCount)
    }
  }, [adminDistricts, districts])

  // *********************** Subject Logic **************************** //
  const [allSubjects, setAllSubjects] = useState([])
  const [totalSubjects, setTotalSubjects] = useState(0)

  useEffect(() => {
    if (filter && defaultFilter && isEqual(filter, defaultFilter) && activeButton === adminPageOptions.SUBJECTS) {
      dispatch(getSubjects())
    }
  }, [dispatch, filter, defaultFilter, activeButton])

  useEffect(() => {
    if (adminSubjects && adminSubjects.subjects && allSubjects !== adminSubjects.subjects) {
      setAllSubjects(adminSubjects.subjects)
      setTotalSubjects(adminSubjects.subjectCount)
    }
  }, [adminSubjects, allSubjects])

  // *********************** Admin Management Logic **************************** //
  const [allAdminUsers, setAllAdminUsers] = useState([])
  const [totalAdminUsers, setTotalAdminUsers] = useState(0)

  useEffect(() => {
    if (filter && defaultFilter && isEqual(filter, defaultFilter) && activeButton === adminPageOptions.ADMIN_USERS) {
      dispatch(getAdminUserList())
    }
  }, [dispatch, filter, defaultFilter, activeButton])

  useEffect(() => {
    if (adminUserList && adminUserList.adminUsers && allAdminUsers !== adminUserList.adminUsers) {
      setAllAdminUsers(adminUserList.adminUsers)
      setTotalAdminUsers(adminUserList.totalAdmins)
    }
  }, [allAdminUsers, adminUserList])

  // *********************** Certifications Logic **************************** //
  const [allCerts, setAllCerts] = useState([])
  const [totalCerts, setTotalCerts] = useState(0)

  useEffect(() => {
    if (filter && defaultFilter && isEqual(filter, defaultFilter) && activeButton === adminPageOptions.CERTS) {
      dispatch(getCertifications())
    }
  }, [dispatch, filter, defaultFilter, activeButton])

  useEffect(() => {
    if (
      adminCertifications && adminCertifications.certifications &&
      allCerts !== adminCertifications.certifications
    ) {
      setAllCerts(adminCertifications.certifications)
      setTotalCerts(adminCertifications.certCount)
    }
  }, [adminCertifications, allCerts])

  // *********************** Artifact Submissions Logic **************************** //
  const [artifactSubmissions, setArtifactSubmissions] = useState([])
  const [totalArtifacts, setTotalArtifacts] = useState(0)

  useEffect(() => {
    if (filter && defaultFilter && isEqual(filter, defaultFilter)) {
      dispatch(getArtifacts())
    }
  }, [dispatch, filter, defaultFilter, activeButton])

  useEffect(() => {
    if (
      adminArtifacts && adminArtifacts.artifacts &&
      artifactSubmissions !== adminArtifacts.artifactList
    ) {
      setArtifactSubmissions(adminArtifacts.artifacts)
      setTotalArtifacts(adminArtifacts.totalSubmissions)
    }
  }, [adminArtifacts, artifactSubmissions])

  // *********************** MCE Assessor Logic **************************** //
  const [mceAssessors, setMCEAssessors] = useState([])
  const [totalAssessors, setTotalAssessors] = useState(0)

  useEffect(() => {
    if (filter && defaultFilter && isEqual(filter, defaultFilter) && activeButton === adminPageOptions.MCE_ASSESSORS) {
      dispatch(getAssessorsList(filter))
    }
  }, [activeButton, defaultFilter, dispatch, filter])

  useEffect(() => {
    if (adminMCEAssessors && adminMCEAssessors.assessors && mceAssessors !== adminMCEAssessors.assessors) {
      setMCEAssessors(adminMCEAssessors.assessors)
      setTotalAssessors(adminMCEAssessors.totalAssessors)
    }
  }, [adminMCEAssessors, mceAssessors])

  // *********************** MCE Badge Logic **************************** //
  const [localMCEStacks, setLocalMCEStacks] = useState([])

  useEffect(() => {
    if ((!Object.keys(localMCEStacks).length || !Object.keys(mceStacks).length) && activeButton === adminPageOptions.MCE_BADGES) {
      dispatch(getMCEStacks(true))
    }
  }, [activeButton, dispatch, localMCEStacks, mceStacks])

  useEffect(() => {
    if ((mceStacks?.stacks && !isEqual(localMCEStacks?.stacks, mceStacks?.stacks)) || (mceStacks?.designs && !isEqual(localMCEStacks?.designs, mceStacks?.designs))) {
      setLocalMCEStacks(mceStacks)
    }
  }, [localMCEStacks, mceStacks])

  // *********************** MCE Evaluations Logic **************************** //
  const [mceEvaluations, setMCEEvaluations] = useState([])
  const [totalEvaluations, setTotalEvaluations] = useState(0)

  useEffect(() => {
    if (filter && defaultFilter && isEqual(filter, defaultFilter) && activeButton === adminPageOptions.MCE_EVALUATIONS) {
      dispatch(getEvaluationsList(filter))
    }
  }, [activeButton, defaultFilter, dispatch, filter])

  useEffect(() => {
    if (adminMCEEvaluations && adminMCEEvaluations.evaluations && mceEvaluations !== adminMCEEvaluations.evaluations) {
      setMCEEvaluations(adminMCEEvaluations.evaluations)
      setTotalEvaluations(adminMCEEvaluations.totalEvaluations)
    }
  }, [adminMCEEvaluations, mceEvaluations])

  // ***************************** MCE Logic ********************************** //
  const [mceList, setMCEList] = useState([])
  const [totalMCEs, setTotalMCEs] = useState(0)

  useEffect(() => {
    if (filter && defaultFilter && isEqual(filter, defaultFilter) && activeButton === adminPageOptions.MICRO_CREDENTIALS) {
      dispatch(getMicroCredentialList(filter))
    }
  }, [activeButton, defaultFilter, dispatch, filter])

  useEffect(() => {
    if (adminMCEs && adminMCEs.microcredentials && mceList !== adminMCEs.microcredentials) {
      setMCEList(adminMCEs.microcredentials)
      setTotalMCEs(adminMCEs.totalMCEs)
    }
  }, [adminMCEs, mceList])

  // *********************** Admin Navigation Logic: ****************** //

  // Opens/Closes the Profile Management Sub Menu
  const [profileOpen, setProfileOpen] = useState(false)
  const [mceOpen, setMCEOpen] = useState(false)
  const [featuredOpen, setFeaturedOpen] = useState(false)
  const [allUsersOpen, setAllUsersOpen] = useState(true)

  const setNewActiveTab = useCallback((newTab) => {
    props.history.push({
      pathname: '/admin',
      search: `?tab=${newTab}`
    })
    setActiveButton(newTab)
    setFilter(defaultFilter)
  }, [defaultFilter, props.history])

  useEffect(() => {
    if (activeTab === adminPageOptions.MCE_ASSESSORS && !superAdmin) {
      setNewActiveTab(adminPageOptions.TEACHER_FELLOWS)
    }
  }, [activeTab, superAdmin, setNewActiveTab])

  // Triggers the correct component to load and decides which useEffect is fired
  const handleListItemClick = (event, selection) => {
    if (!profileManagementViews.includes(selection)) {
      setProfileOpen(false)
    }
    if (!featuredArtifactViews.includes(selection)) {
      setFeaturedOpen(false)
    }

    if (!allUserViews.includes(selection)) {
      setAllUsersOpen(false)
    }

    if (!microCredentialViews.includes(selection)) {
      setMCEOpen(false)
    }

    setNewActiveTab(selection)
  }

  // UpdateUserDialog selected update action state
  const [selectedAction, setSelectedAction] = useState(updateUserActionTypes.UPDATE_EMAIL)

  // Delete user state/handler //
  const [selectedUser, setSelectedUser] = useState(null)
  const [confirmAnchorEl, setConfirmAnchorEl] = useState(null)
  const openConfirmPopover = Boolean(confirmAnchorEl)

  const handleConfirmPopover = (e, userID) => {
    setConfirmAnchorEl(e.currentTarget)
    setSelectedUser(userID)
  }

  const handleConfirmDeleteUser = (userType) => { if (selectedUser && superAdmin && userType) { handleDeleteUser(selectedUser, userType) } }

  const fireDeleteUserSuccess = async (userType) => {
    NotificationToast(false, 'Sucessfully Deleted User')
    setSelectedUser(null)
    if (userType === adminPageOptions.TEACHER_FELLOWS) { await dispatch(getTeacherFellows()) }
    if (userType === adminPageOptions.INNOVATION_FELLOWS) { await dispatch(getInnovationFellows()) }
    if (userType === adminPageOptions.ADMIN_USERS) { await dispatch(getAdminUserList(filter)) }
  }

  const fireDeleteUserFailure = () => { NotificationToast(true, 'Unable to Update User') }

  const handleDeleteUser = async (userID, userType) => {
    if (userID && userType) {
      const toastMessage = {
        msg: 'Are you sure you want to delete this user?',
        txt: 'Deleting a user account is an irreversible action. Once you confirm this deletion, there is no way to undo the action after it is completed.'
      }
      const confirm = await ConfirmToast(toastMessage)

      if (confirm) {
        dispatch(deleteUser(userID, () => fireDeleteUserSuccess(userType), fireDeleteUserFailure))
      } else {
        setSelectedUser(null)
      }
    }
  }

  // Renders the desired component
  const getActiveContent = (button) => {
    switch (button) {
      case adminPageOptions.TEACHER_FELLOWS:
        return (
          <TeacherFellows
            classes={classes}
            teacherFellows={teacherFellows}
            totalFellows={totalFellows}
            filter={filter}
            setFilter={setFilter}
            getTeacherFellows={getTeacherFellows}
            defaultFilter={defaultFilter}
            activeButton={activeButton}
            selectedUser={selectedUser}
            handleDeleteUser={handleDeleteUser}
            confirmAnchorEl={confirmAnchorEl}
            openConfirmPopover={openConfirmPopover}
            setConfirmAnchorEl={setConfirmAnchorEl}
            handleConfirmPopover={handleConfirmPopover}
            handleConfirmDeleteUser={handleConfirmDeleteUser}
            selectedAction={selectedAction}
            setSelectedAction={setSelectedAction}
          />)
      case adminPageOptions.INNOVATION_FELLOWS:
        return (
          <InnovationFellows
            classes={classes}
            innovationFellows={innovationFellows}
            totalIfFellows={totalIfFellows}
            filter={filter}
            setFilter={setFilter}
            getInnovationFellows={getInnovationFellows}
            defaultFilter={defaultFilter}
            activeButton={activeButton}
            selectedUser={selectedUser}
            handleDeleteUser={handleDeleteUser}
            confirmAnchorEl={confirmAnchorEl}
            openConfirmPopover={openConfirmPopover}
            setConfirmAnchorEl={setConfirmAnchorEl}
            handleConfirmPopover={handleConfirmPopover}
            handleConfirmDeleteUser={handleConfirmDeleteUser}
            selectedAction={selectedAction}
            setSelectedAction={setSelectedAction}
          />)
      case adminPageOptions.FEATURED_RESOURCES:
        return (
          <FeaturedResources
            classes={classes}
            featuredResources={featuredResources}
            totalResources={totalResources}
            filter={filter}
            setFilter={setFilter}
            getEFRAdminList={getEFRAdminList}
            defaultFilter={defaultFilter}
            activeButton={activeButton}
            navHeight={navHeight}
          />)
      case adminPageOptions.FEATURED_LEDS:
        return (
          <FeaturedLEDs
            classes={classes}
            featuredLEDs={featuredLEDs}
            totalLEDs={totalLEDs}
            filter={filter}
            setFilter={setFilter}
            getFeaturedLEDOptions={getFeaturedLEDOptions}
            defaultFilter={defaultFilter}
            activeButton={activeButton}
            navHeight={navHeight}
          />)
      case adminPageOptions.SIGN_UPS:
        return (
          <SignUps
            classes={classes}
            pendingUsers={pendingUsers}
            totalPending={totalPending}
            filter={filter}
            setFilter={setFilter}
            getSignUps={getSignUps}
            defaultFilter={defaultFilter}
            activeButton={activeButton}
          />
        )
      case adminPageOptions.ARTIFACTS:
        return (
          <AdminArtifacts
            classes={classes}
            artifactSubmissions={artifactSubmissions}
            totalArtifacts={totalArtifacts}
            filter={filter}
            setFilter={setFilter}
            getArtifacts={getArtifacts}
            defaultFilter={defaultFilter}
            activeButton={activeButton}
          />
        )
      case adminPageOptions.COHORTS:
        return (
          <CohortsAdmin
            classes={classes}
            allCohorts={allCohorts}
            totalCohorts={totalCohorts}
            filter={filter}
            setFilter={setFilter}
            getCohorts={getCohorts}
            defaultFilter={defaultFilter}
            activeButton={activeButton}
            getSchoolsAndDistricts={getSchoolsAndDistricts}
          />
        )
      case adminPageOptions.SCHOOLS:
        return (
          <AdminSchools
            classes={classes}
            filter={filter}
            setFilter={setFilter}
            defaultFilter={defaultFilter}
            activeButton={activeButton}
            schools={schools}
            totalSchools={totalSchools}
            getSchoolsAndDistricts={getSchoolsAndDistricts}
          />
        )
      case adminPageOptions.DISTRICTS:
        return (
          <AdminDistricts
            classes={classes}
            filter={filter}
            setFilter={setFilter}
            defaultFilter={defaultFilter}
            districts={districts}
            totalDistricts={totalDistricts}
            activeButton={activeButton}
          />
        )
      case adminPageOptions.SUBJECTS:
        return (
          <AdminSubjects
            classes={classes}
            allSubjects={allSubjects}
            totalSubjects={totalSubjects}
            filter={filter}
            setFilter={setFilter}
            getSubjects={getSubjects}
            defaultFilter={defaultFilter}
            activeButton={activeButton}
          />
        )
      case adminPageOptions.CERTS:
        return (
          <AdminCerts
            classes={classes}
            navHeight={navHeight}
            allCerts={allCerts}
            totalCerts={totalCerts}
            filter={filter}
            setFilter={setFilter}
            getCertifications={getCertifications}
            defaultFilter={defaultFilter}
            activeButton={activeButton}
            setAllCerts={setAllCerts}
            setTotalCerts={setTotalCerts}
            adminCertifications={adminCertifications}
          />
        )
      case adminPageOptions.ADMIN_USERS:
        return (
          <AdminUserList
            classes={classes}
            allAdminUsers={allAdminUsers}
            totalAdminUsers={totalAdminUsers}
            filter={filter}
            setFilter={setFilter}
            getAdminUserList={getAdminUserList}
            defaultFilter={defaultFilter}
            activeButton={activeButton}
            selectedUser={selectedUser}
            handleDeleteUser={handleDeleteUser}
            confirmAnchorEl={confirmAnchorEl}
            openConfirmPopover={openConfirmPopover}
            setConfirmAnchorEl={setConfirmAnchorEl}
            handleConfirmPopover={handleConfirmPopover}
            handleConfirmDeleteUser={handleConfirmDeleteUser}
            selectedAction={selectedAction}
            setSelectedAction={setSelectedAction}
          />
        )
      case adminPageOptions.MCE_ASSESSORS:
        return (
          <AdminMCEAssessors
            classes={classes}
            filter={filter}
            setFilter={setFilter}
            defaultFilter={defaultFilter}
            activeButton={activeButton}
            mceAssessors={mceAssessors}
            totalAssessors={totalAssessors}
          />
        )
      case adminPageOptions.MCE_EVALUATIONS:
        return (
          <AdminMCEEvaluations
            classes={classes}
            filter={filter}
            setFilter={setFilter}
            defaultFilter={defaultFilter}
            activeButton={activeButton}
            mceEvaluations={mceEvaluations}
            totalEvaluations={totalEvaluations}
          />
        )
      case adminPageOptions.MICRO_CREDENTIALS:
        return (
          <AdminMicroCredentials
            classes={classes}
            filter={filter}
            setFilter={setFilter}
            defaultFilter={defaultFilter}
            activeButton={activeButton}
            mceList={mceList}
            totalMCEs={totalMCEs}
            localMCEStacks={localMCEStacks}
          />
        )
      case adminPageOptions.MCE_BADGES:
        return (
          <AdminMCEBadges
            classes={classes}
            localMCEStacks={localMCEStacks}
          />
        )
      default:
        return console.log('no button found')
    }
  }

  const [navRef, setNavRef] = useState(null)

  const navHeight = navRef ? navRef.clientHeight : 0

  return (
    <>
      <NavBar setNavRef={setNavRef} navHeight={navHeight} />
      <div className={classes.toolbar} />
      <Grid container direction='row'>
        <Grid item container xs={2} direction='column' className={classes.navSideMain}>
          <Grid item container direction='row' alignItems='center' style={{ marginBottom: '2em' }}>
            <Security style={{ marginRight: '.2em' }} />
            <Typography variant='h3'>Admin</Typography>
          </Grid>
          <Grid item container>
            <List>
              <ListItem
                onClick={() => setAllUsersOpen(!allUsersOpen)}
                button
                style={{ paddingLeft: 0 }}
              >
                <Typography variant='h5' className={allUsersOpen ? classes.activeMenu : classes.inactiveButton}>All Users</Typography>
                {allUsersOpen ? <ExpandLess style={{ color: theme.palette.grey.medium }} /> : <ExpandMore style={{ color: theme.palette.grey.medium }} />}
              </ListItem>

              {/* All Users Sub Menu */}
              <Collapse in={allUsersOpen} unmountOnExit>
                <List component='div' disablePadding>
                  {/* Teacher Fellows */}
                  <ListItem
                    onClick={(e) => handleListItemClick(e, adminPageOptions.TEACHER_FELLOWS)}
                    button
                    selected={activeButton === adminPageOptions.TEACHER_FELLOWS}
                  >
                    <Typography variant='h5' className={activeButton === adminPageOptions.TEACHER_FELLOWS ? classes.activeButton : classes.inactiveButton}>Teacher Fellows</Typography>
                  </ListItem>
                  {/* Innovation Fellows */}
                  <ListItem
                    onClick={(e) => handleListItemClick(e, adminPageOptions.INNOVATION_FELLOWS)}
                    button
                    selected={activeButton === adminPageOptions.INNOVATION_FELLOWS}
                  >
                    <Typography variant='h5' className={activeButton === adminPageOptions.INNOVATION_FELLOWS ? classes.activeButton : classes.inactiveButton}>Innovation Fellows</Typography>
                  </ListItem>
                  {/* Admin Users */}
                  <ListItem
                    onClick={(e) => handleListItemClick(e, adminPageOptions.ADMIN_USERS)}
                    button
                    selected={activeButton === adminPageOptions.ADMIN_USERS}
                  >
                    <Typography variant='h5' className={activeButton === adminPageOptions.ADMIN_USERS ? classes.activeButton : classes.inactiveButton}>Admin Users</Typography>
                  </ListItem>
                </List>
              </Collapse>

              <ListItem
                onClick={(e) => handleListItemClick(e, adminPageOptions.SIGN_UPS)}
                button
                selected={activeButton === adminPageOptions.SIGN_UPS}
                style={{ paddingLeft: 0 }}
              >
                {
                  adminSignUps && adminSignUps.totalPending && adminSignUps.totalPending > 0
                    ? (
                      <>
                        <StyledBadge badgeContent={adminSignUps.totalPending}>
                          <Typography
                            variant='h5'
                            className={activeButton === adminPageOptions.SIGN_UPS ? classes.activeButton : classes.inactiveButton}
                          >
                            Sign Ups
                          </Typography>
                        </StyledBadge>
                      </>
                    ) : (
                      <>
                        <Typography
                          variant='h5'
                          className={activeButton === adminPageOptions.SIGN_UPS ? classes.activeButton : classes.inactiveButton}
                        >
                          Sign Ups
                        </Typography>
                      </>
                    )
                }
              </ListItem>
              <ListItem
                onClick={(e) => handleListItemClick(e, adminPageOptions.ARTIFACTS)}
                button
                selected={activeButton === adminPageOptions.ARTIFACTS}
                style={{ paddingLeft: 0 }}
              >
                {
                  adminArtifacts && adminArtifacts.totalToReview && adminArtifacts.totalToReview > 0
                    ? (
                      <>
                        <StyledBadge badgeContent={adminArtifacts.totalToReview}>
                          <Typography
                            variant='h5'
                            className={activeButton === adminPageOptions.ARTIFACTS ? classes.activeButton : classes.inactiveButton}
                          >
                            Artifacts
                          </Typography>
                        </StyledBadge>
                      </>
                    ) : (
                      <>
                        <Typography
                          variant='h5'
                          className={activeButton === adminPageOptions.ARTIFACTS ? classes.activeButton : classes.inactiveButton}
                        >
                          Artifacts
                        </Typography>
                      </>
                    )
                }
              </ListItem>
              <ListItem
                onClick={(e) => handleListItemClick(e, adminPageOptions.COHORTS)}
                button
                selected={activeButton === adminPageOptions.COHORTS}
                style={{ paddingLeft: 0 }}
              >
                <Typography variant='h5' className={activeButton === adminPageOptions.COHORTS ? classes.activeButton : classes.inactiveButton}>Cohorts</Typography>
              </ListItem>
              <ListItem
                onClick={() => setProfileOpen(!profileOpen)}
                button
                style={{ paddingLeft: 0 }}
              >
                <Typography variant='h5' className={profileOpen ? classes.activeMenu : classes.inactiveButton}>Profile Management</Typography>
                {profileOpen ? <ExpandLess style={{ color: theme.palette.grey.medium }} /> : <ExpandMore style={{ color: theme.palette.grey.medium }} />}
              </ListItem>

              {/* Profile Management Sub Menu */}
              <Collapse in={profileOpen} unmountOnExit>
                <List component='div' disablePadding>
                  {/* SCHOOLS */}
                  <ListItem
                    onClick={(e) => handleListItemClick(e, adminPageOptions.SCHOOLS)}
                    button
                    selected={activeButton === adminPageOptions.SCHOOLS}
                  >
                    <Typography variant='h5' className={activeButton === adminPageOptions.SCHOOLS ? classes.activeButton : classes.inactiveButton}>Schools</Typography>
                  </ListItem>
                  {/* DISTRICTS */}
                  <ListItem
                    onClick={(e) => handleListItemClick(e, adminPageOptions.DISTRICTS)}
                    button
                    selected={activeButton === adminPageOptions.DISTRICTS}
                  >
                    <Typography variant='h5' className={activeButton === adminPageOptions.DISTRICTS ? classes.activeButton : classes.inactiveButton}>Districts</Typography>
                  </ListItem>
                  {/* SUBJECTS */}
                  <ListItem
                    onClick={(e) => handleListItemClick(e, adminPageOptions.SUBJECTS)}
                    button
                    selected={activeButton === adminPageOptions.SUBJECTS}
                  >
                    <Typography variant='h5' className={activeButton === adminPageOptions.SUBJECTS ? classes.activeButton : classes.inactiveButton}>Subjects</Typography>
                  </ListItem>
                  {/* CERTIFICATIONS */}
                  <ListItem
                    onClick={(e) => handleListItemClick(e, adminPageOptions.CERTS)}
                    button
                    selected={activeButton === adminPageOptions.CERTS}
                  >
                    <Typography variant='h5' className={activeButton === adminPageOptions.CERTS ? classes.activeButton : classes.inactiveButton}>Certifications</Typography>
                  </ListItem>
                </List>
              </Collapse>

              <ListItem
                onClick={() => setFeaturedOpen(!featuredOpen)}
                button
                style={{ paddingLeft: 0 }}
              >
                <Typography variant='h5' className={featuredOpen ? classes.activeMenu : classes.inactiveButton}>Featured Artifacts</Typography>
                {featuredOpen ? <ExpandLess style={{ color: theme.palette.grey.medium }} /> : <ExpandMore style={{ color: theme.palette.grey.medium }} />}
              </ListItem>

              {/* Featured Artifacts Sub Menu */}
              <Collapse in={featuredOpen} unmountOnExit>
                <List component='div' disablePadding>
                  {/* FEATURED RESOURCES */}
                  <ListItem
                    onClick={(e) => handleListItemClick(e, adminPageOptions.FEATURED_RESOURCES)}
                    button
                    selected={activeButton === adminPageOptions.FEATURED_RESOURCES}
                  >
                    <Typography variant='h5' className={activeButton === adminPageOptions.FEATURED_RESOURCES ? classes.activeButton : classes.inactiveButton}>Featured Resources</Typography>
                  </ListItem>
                  {/* FEATURED LED'S */}
                  <ListItem
                    onClick={(e) => handleListItemClick(e, adminPageOptions.FEATURED_LEDS)}
                    button
                    selected={activeButton === adminPageOptions.FEATURED_LEDS}
                  >
                    <Typography variant='h5' className={activeButton === adminPageOptions.FEATURED_LEDS ? classes.activeButton : classes.inactiveButton}>Featured LED's</Typography>
                  </ListItem>
                </List>
              </Collapse>

              <ListItem
                onClick={() => setMCEOpen(!mceOpen)}
                button
                style={{ paddingLeft: 0 }}
              >
                <Typography variant='h5' className={mceOpen ? classes.activeMenu : classes.inactiveButton}>MCE Management</Typography>
                {mceOpen ? <ExpandLess style={{ color: theme.palette.grey.medium }} /> : <ExpandMore style={{ color: theme.palette.grey.medium }} />}
              </ListItem>

              {/* Micro-Credential Sub Menu */}
              <Collapse in={mceOpen} unmountOnExit>
                <List component='div' disablePadding>
                  {/* MCE ASSESSORS (super admin only) */}
                  {Boolean(superAdmin) &&
                    <ListItem
                      onClick={(e) => handleListItemClick(e, adminPageOptions.MCE_ASSESSORS)}
                      button
                      selected={activeButton === adminPageOptions.MCE_ASSESSORS}
                    >
                      <Typography variant='h5' className={activeButton === adminPageOptions.MCE_ASSESSORS ? classes.activeButton : classes.inactiveButton}>Assessors</Typography>
                    </ListItem>}
                  {/* MCE BADGES */}
                  <ListItem
                    onClick={(e) => handleListItemClick(e, adminPageOptions.MCE_BADGES)}
                    button
                    selected={activeButton === adminPageOptions.MCE_BADGES}
                  >
                    <Typography variant='h5' className={activeButton === adminPageOptions.MCE_BADGES ? classes.activeButton : classes.inactiveButton}>Badges</Typography>
                  </ListItem>
                  {/* MICRO-CREDENTIALS */}
                  <ListItem
                    onClick={(e) => handleListItemClick(e, adminPageOptions.MICRO_CREDENTIALS)}
                    button
                    selected={activeButton === adminPageOptions.MICRO_CREDENTIALS}
                  >
                    <Typography variant='h5' className={activeButton === adminPageOptions.MICRO_CREDENTIALS ? classes.activeButton : classes.inactiveButton}>Micro-Credentials</Typography>
                  </ListItem>
                  {/* MCE EVALUATIONS */}
                  <ListItem
                    onClick={(e) => handleListItemClick(e, adminPageOptions.MCE_EVALUATIONS)}
                    button
                    selected={activeButton === adminPageOptions.MCE_EVALUATIONS}
                  >
                    <Typography variant='h5' className={activeButton === adminPageOptions.MCE_EVALUATIONS ? classes.activeButton : classes.inactiveButton}>Evaluations</Typography>
                  </ListItem>
                </List>
              </Collapse>
            </List>
          </Grid>
        </Grid>
        <Grid item container xs={10} className={classes.mainContentSide}>
          {getActiveContent(activeTab)}
        </Grid>
      </Grid>
    </>
  )
}

export default withRouter(AdminView)
