import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import edFarmLogo from '../../assets/edFarmLogo.png'
import teacherImg from '../../assets/teacher.svg'

import {
  Grid, Typography, Button
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import { LoginForms, RegisterForm } from '../ui/forms'
import { SSOLoginToast } from '../ui/tools/Toasts'

import queryString from 'query-string'
import { loginSSO } from '../../redux/actions'
import { loginForms } from '../../utils/variables'

// themes are set in app.js wrapping routes with theme provider
const useStyles = makeStyles((theme) => ({
  heading: {
    color: theme.palette.purple.darkest
  },
  darkBackground: {
    backgroundColor: theme.palette.purple.darkest
  },
  formContainer: {
    margin: '0 9em 3em',
    paddingRight: '20em',
    marginTop: '6vh',
    [theme.breakpoints.down('sm')]: {
      margin: '0 1em 2em',
      paddingRight: '1em'
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: '.2em'
    }
  },
  teacherImage: {
    height: '100%',
    width: '150%',
    marginLeft: '-7em',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  sloganScript: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  logoContainer: {
    marginLeft: '2em',
    marginTop: '2em',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '.5em',
      marginTop: '2em'
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '.5em',
      marginTop: '2em'
    }
  },
  versionGrid: {
    marginTop: '12em',
    paddingRight: '3em',
    [`${theme.breakpoints.down('sm')} and (orientation: portrait)`]: {
      marginTop: '10em',
      paddingRight: '1em'
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      marginTop: '12em',
      paddingRight: '3em'
    }
  },
  version: {
    color: theme.palette.grey.dark,
    fontWeight: 'normal',
    fontSize: '15px'
  }
}))

const version = process.env.REACT_APP_VERSION

// Login options: props and form
const loginOpts = {
  LOGIN: {
    prop: 'login',
    form: 'login-form'
  },
  REGISTER: {
    prop: 'register',
    form: 'register-form'
  }
}

// SSO Login failure messages
const failSSOMessages = {
  DEFAULT: 'Unable to login right now. Please check your credentials and try again later.',
  REDIRECT: 'Connection to google has failed. Please try again, or contact Ed Farm support.',
  MISSING_INFO: 'Did not receive enough information from third party to successfully login.',
  USER_UNAVAILABLE: 'User Account Currently Unavailable.'
}

const Login = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  // Logging-In vs Signing-Up
  const [selectedForm, setSelectedForm] = useState(loginOpts.LOGIN.form)
  // Existing User views
  const [currentForm, setCurrentForm] = useState(loginForms.MAIN_LOGIN)

  useEffect(() => {
    if (props.location && props.location.search) {
      const parsedProps = queryString.parse(props.location.search)
      const { token = null, username = null, userid: userID = null, ssologin: ssoLogin = null, redirect = null, form = null } = parsedProps

      // If the user is not attempting an sso login, load the correct form
      if (form && (!ssoLogin || !redirect)) {
        if (form === loginOpts.REGISTER.prop) {
          setSelectedForm(loginOpts.REGISTER.form)
        } else {
          setSelectedForm(loginOpts.LOGIN.form)
        }
      }

      // If the user is attempting a sso login
      if (!form && (ssoLogin || redirect)) {
        const fireLoginFailure = (code) => {
          let message = failSSOMessages.DEFAULT
          if (code === 403) {
            message = failSSOMessages.USER_UNAVAILABLE
          }
          SSOLoginToast(message)
        }

        // If the user was redirected with an error after sso attempt
        if (redirect && redirect === 'failure') {
          return SSOLoginToast(failSSOMessages.REDIRECT)
        }

        // If the user was returned from google with insufficient information
        if (ssoLogin && (!userID || !username || !token)) {
          return SSOLoginToast(failSSOMessages.MISSING_INFO)
        }

        // Otherwise,dispatch sso login with correct information from the props
        dispatch(loginSSO(userID, username, token, () => { }, fireLoginFailure))
      }
    } else {
      // If no props are present, direct the user to the login form
      setSelectedForm(loginOpts.LOGIN.form)
    }
  }, [props.location, props.location.search, dispatch])

  const Form = () => {
    switch (selectedForm) {
      case loginOpts.LOGIN.form: return (
        <LoginForms currentForm={currentForm} setCurrentForm={setCurrentForm} />
      )
      case loginOpts.REGISTER.form: return (
        <RegisterForm />
      )
      default: return (<></>)
    }
  }

  const handleSwitchForm = () => {
    if (selectedForm === loginOpts.LOGIN.form) {
      props.history.push({
        pathname: '/',
        search: `?form=${loginOpts.REGISTER.prop}`
      })
    } else {
      props.history.push({
        pathname: '/',
        search: `?form=${loginOpts.LOGIN.prop}`
      })
    }
  }

  return (
    <>
      <Grid container direction='row' style={{ overflow: 'hidden' }}>
        <Grid item xs={3} md={4} className={classes.darkBackground}>
          <Grid container direction='column' style={{ justifyContent: 'space-between', height: '100vh' }}>
            <Grid item className={classes.logoContainer}>
              <Grid item>
                <img src={edFarmLogo} alt='logo' style={{ maxHeight: '3.7em' }} />
              </Grid>
              <Grid item style={{ marginTop: '3em' }} className={classes.sloganScript} container xs={10}>
                <Typography variant='h1' style={{ color: 'white', fontWeight: '600' }}>Gain inspiration and learn from other</Typography>
                <Typography variant='h1' style={{ color: 'white', fontWeight: '600' }}>educators</Typography>
              </Grid>
            </Grid>
            <Grid item container alignContent='flex-end'>
              <Grid item container style={{ width: '100%', height: 'auto' }}>
                {/* TODO: Will not display with overhang on large screen */}
                <img className={classes.teacherImage} src={teacherImg} alt='Teacher Fellow' />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={8}>
          <Grid item container direction='row' justifyContent='flex-end' alignItems='center' style={{ marginTop: '2em', paddingRight: '3em' }}>
            <Typography variant='h6' style={{ textTransform: 'none', fontWeight: 'normal', fontSize: '16px' }}>{selectedForm === loginOpts.LOGIN.form ? 'Not a member?' : 'Already a Member?'}</Typography>
            <Button
              color='primary'
              variant='text'
              style={{ textTransform: 'none', fontWeight: '600', color: '#29356a', fontSize: '16px' }}
              onClick={(e) => {
                e.preventDefault()
                handleSwitchForm()
              }}
            >
              {selectedForm === loginOpts.LOGIN.form ? 'Sign Up' : 'Log In'}
            </Button>
          </Grid>
          <Grid item container direction='column' alignContent='center' justifyContent='center' className={classes.formContainer}>
            <Form />
          </Grid>
          <Grid className={classes.versionGrid} item container justifyContent='flex-end' alignItems='flex-end'>
            <Typography className={classes.version}>{version}</Typography>
          </Grid>
        </Grid>
      </Grid>

    </>
  )
}

export default withRouter(Login)
