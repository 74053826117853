import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Grid, Typography, Select, MenuItem, Button, Switch, withStyles,
  useTheme, OutlinedInput, FormGroup, FormControlLabel, Card
} from '@material-ui/core'
import {
  Add
} from '@material-ui/icons'
import BuilderStepper from './BuilderTools/BuilderStepper'
import BuilderVersionHistoryList from './BuilderTools/BuilderVersionHistoryList'
import { cloneDeep } from 'lodash'
import { mceActionTypes } from '../../../utils/variables'
import { CreateOrEditStackModal, UploadBadgeModal } from '../modals'

import moment from 'moment'

// Active/Deactivated Switch toggle for an omce status
const ActiveSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 22,
    padding: '.1em',
    margin: theme.spacing(1)
  },
  switchBaseStyle: {
    padding: 3,
    color: theme.palette.grey.medium,
    '&$checked': {
      transform: 'translateX(18px)',
      color: 'white',
      '& + $track': {
        backgroundColor: theme.palette.logo.green,
        borderColor: theme.palette.grey.medium
      },
      '& + $thumb': {
        color: 'red !important'
      }
    },
    '&$disabled': {
      color: theme.palette.purple.dark,
      '& + $track': {
        backgroundColor: `${theme.palette.purple.dark} !important`,
        borderColor: `${theme.palette.purple.dark} !important`
      }
    },
    '&$focusVisible $thumb': {
      color: theme.palette.grey.dark
    },
    '& + $track': {
      backgroundColor: 'white'
    }
  },
  thumbStyle: {
    width: 18,
    height: 18
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey.medium}`,
    opacity: '1 !important',
    transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {},
  disabled: {},
  thumb: {},
  focusVisible: {}
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBaseStyle,
        thumb: classes.thumbStyle,
        track: classes.track,
        checked: classes.checked,
        disabled: classes.disabled
      }}
      {...props}
    />
  )
})

const defaultSaveStackInfo = {
  stackID: '',
  colorPrimary: '',
  colorSecondary: '',
  saveAsDesign: false,
  existingStack: false
}

const BuilderHeader = (props) => {
  const {
    classes = {}, activeStep, setActiveStep = () => { }, stackList = [], setStackList = () => { }, steps,
    draftMCE = true, versionForm = {}, setVersionForm = () => { }, handleOriginStatus = () => { }, deactivatedOrigin = false
  } = props
  const theme = useTheme()
  const showStackButton = Boolean(stackList?.stacks.every(s => Boolean(s.stackID)) || versionForm?.newStack)
  const { mceDetails = {} } = useSelector(state => state.microcredentials)
  const versionHistory = mceDetails?.versionHistory || []

  const currentTimeStamp = moment.now()

  // Create new stack state
  const [stackInfo, setStackInfo] = useState({ ...defaultSaveStackInfo })
  const [missingColorsErr, setMissingColorsErr] = useState(false)

  // --- memo to keep up with the stack select changes and prevent the pesky 'out of range value' error
  const selectValue = useMemo(() => {
    if (versionForm?.stackID || (versionForm?.newStack && versionForm?.stackName)) {
      const versionKey = versionForm?.newStack ? 'stackName' : 'stackID'
      const chosenStack = stackList?.stacks.find(x => x[versionKey] === versionForm[versionKey]) || ''

      return chosenStack
    }

    return ''
  }, [versionForm, stackList])

  // ******** NEW STACK DIALOG HANDLERS ******* //
  const [newStackOpen, setNewStackOpen] = useState(false)
  const [newStackValue, setNewStackValue] = useState('')

  // --- handle changing the stack (draft only!)
  const handleStackChange = (value) => {
    const { stackID = '', stackName = '' } = value

    // set the local version form to have the new stack.
    // (NOTE: the user has the ability to locally add a new stack (only 1 at a time) but still switch to another one before saving. Therefore we need to keep track in the form as to if we finally landed on a new one or not.)
    setVersionForm({ ...versionForm, stackID, stackName, newStack: Boolean(!stackID) })
    setStackInfo({ ...defaultSaveStackInfo })
    setNewStackValue('')
  }

  // --- open the dialog
  const handleOpenNewStack = () => {
    // if the user has already locally added a new stack, find and set the input to their chosen stack name
    if (versionForm?.stackID) {
      const existingStackColors = stackList?.stacks.find(x => x.stackID === versionForm?.stackID)
      if (existingStackColors) {
        const existingPalette = Boolean(existingStackColors?.colorPrimary && existingStackColors?.colorSecondary)
        setStackInfo({
          ...stackInfo,
          stackID: existingStackColors?.stackID,
          colorPrimary: existingStackColors?.colorPrimary,
          colorSecondary: existingStackColors?.colorSecondary,
          existingPalette: existingPalette
        })
      }
    }

    if (versionForm?.newStack) {
      const newStackObj = stackList?.stacks.find(x => !x.stackID)
      if (newStackObj) {
        const newInputVal = newStackObj?.stackName || ''
        setNewStackValue(newInputVal)
        setStackInfo({
          ...stackInfo,
          stackID: '',
          colorPrimary: versionForm?.colorPrimary,
          colorSecondary: versionForm?.colorSecondary
        })
      }
    }

    setNewStackOpen(true)
  }

  // --- close and reset the stack dialog
  const handleCloseNewStack = () => { setNewStackOpen(false) }

  // --- handle saving the created or editied local 'new' stack
  const handleSaveNewStack = (stackInfo) => {
    const currentStackList = cloneDeep(stackList?.stacks)

    if (!stackInfo?.colorPrimary && !stackInfo?.colorSecondary) { setMissingColorsErr(true); return false } else setMissingColorsErr(false)

    const newValue = {
      stackID: '',
      stackName: newStackValue,
      colorPrimary: stackInfo?.colorPrimary,
      colorSecondary: stackInfo?.colorSecondary,
      saveAsDesign: stackInfo?.saveAsDesign
    }

    // if we have not already created a local new stack, push the value to the local stack list
    if (!versionForm.newStack) {
      currentStackList.push(newValue)
    } else {
      // otherwise we need to find the one we already created and modify the name
      const currentNewIndex = stackList?.stacks?.findIndex(x => !x.stackID)
      if (currentNewIndex !== -1) {
        currentStackList[currentNewIndex] = newValue
      }
    }

    // set the form to use the new stack data
    setVersionForm({ ...versionForm, ...newValue, newStack: true })
    // set the local stack list to the updated version
    setStackList(currentStackList)
    // close the modal
    handleCloseNewStack()
  }

  // ******** CHANGE LOG LOGIC ********* //
  // Confirm popper related elements --- //
  const [changeLogAnchorEl, setChangeLogAnchorEl] = useState(null)

  const handleChangeLogPopover = (e) => {
    setChangeLogAnchorEl(e.currentTarget)
  }

  const openChangeLogPopover = Boolean(changeLogAnchorEl)

  const [badgeUploadOpen, setBadgeUploadOpen] = useState(false)

  const handleCloseBadgeUploadModal = () => {
    setBadgeUploadOpen(false)
  }

  return (
    <Grid item container direction='column'>
      <Grid item container direction='row' style={{ padding: '1em' }}>
        {/* Title/Stack/Activity */}
        <Grid item container direction='column' xs={6}>
          {/* TITLE INPUT */}
          <Grid item container direction='column' style={{ paddingBottom: '1em' }}>
            <Typography variant='h5' style={{ color: theme.palette.purple.darkest }} gutterBottom>Title</Typography>
            <OutlinedInput
              margin='dense'
              style={{ backgroundColor: 'white' }}
              fullWidth={false}
              value={versionForm.title}
              onChange={(e) => { setVersionForm({ ...versionForm, title: e.target.value }) }}
            />
            {Boolean(versionForm?.title.length && !versionForm.title.trim().length) &&
              <Typography variant='caption' style={{ color: 'red' }} gutterBottom>*Title cannot contain only white space.</Typography>}
          </Grid>
          <Grid item container direction='column'>
            {/* STACK SELECTION/CREATION (draft only) */}
            {draftMCE
              ? (
                <>
                  <Grid item container direction='column'>
                    <Typography variant='h5' style={{ color: theme.palette.purple.darkest }} gutterBottom>Stack</Typography>
                  </Grid>
                  <Grid item container direction='row' alignItems='center'>
                    <Grid item xs={8}>
                      <Select
                        variant='outlined'
                        margin='dense'
                        fullWidth
                        disabled={!draftMCE}
                        displayEmpty
                        classes={{ root: classes.searchInput, selectMenu: classes.statusSelect }}
                        value={selectValue}
                        renderValue={() => versionForm.stackName ? versionForm.stackName : 'Choose a stack...'}
                        onChange={(e) => handleStackChange(e.target.value)}
                        style={{ padding: 0 }}
                        defaultValue=''
                      >
                        <MenuItem value='' disabled>Choose a stack...</MenuItem>
                        {stackList?.stacks.length &&
                          stackList?.stacks.map((stack, i) => {
                            const { stackID = '', stackName = '' } = stack
                            return (
                              <MenuItem key={'stack-' + i + '-' + stackID} value={stack}>{(stackName + ((!stackID) ? ' *' : ''))}</MenuItem>
                            )
                          })}
                      </Select>
                    </Grid>
                    {showStackButton &&
                      <Grid item container direction='row' xs={4}>
                        <Button
                          variant='contained'
                          color='secondary'
                          style={{ margin: 'auto 1em auto 1em', fontWeight: 600 }}
                          startIcon={<Add />}
                          disabled={!draftMCE}
                          onClick={handleOpenNewStack}
                        >
                          {`${versionForm.newStack ? 'Edit' : 'Create'} New Stack`}
                        </Button>
                      </Grid>}
                    <Grid item container direction='row'>
                      {versionForm.newStack &&
                        <Typography variant='caption' style={{ fontWeight: 600, color: theme.palette.grey.dark }}>*New Stack</Typography>}
                    </Grid>
                  </Grid>
                </>
              )
              : (
                <Grid item container direction='column' style={{ paddingBottom: '1em' }}>
                  {/* STATIC STACK NAME DISPLAY (not draft) */}
                  <Typography variant='h5' style={{ color: theme.palette.purple.darkest }} gutterBottom>Stack Name</Typography>
                  <Typography variant='h5' style={{ color: theme.palette.grey.darker }} gutterBottom>{versionForm.stackName}</Typography>
                </Grid>
              )}
          </Grid>
        </Grid>
        {/* OMCE STATUS INDICATOR */}
        <Grid item container direction='column' alignItems='flex-end' xs={6} style={{ paddingRight: '4em' }}>
          {/* DRAFT DISPLAY */}
          {draftMCE
            ? (
              <Grid item>
                <Button disabled variant='outlined' style={{ fontWeight: 600, borderWidth: '2px', color: theme.palette.grey.dark, borderColor: theme.palette.grey.dark }}>DRAFT</Button>
              </Grid>
            )
            : (
              <>
                {/* OMCE ACTIVE STATUS */}
                <FormGroup row>
                  <FormControlLabel
                    control={<ActiveSwitch />}
                    checked={!deactivatedOrigin}
                    onChange={() => { const actionType = deactivatedOrigin ? mceActionTypes.REACTIVATED : mceActionTypes.DEACTIVATED; handleOriginStatus(actionType) }}
                    disabled={!versionForm?.omceID}
                    label={
                      <Typography variant='h5' style={{ color: theme.palette.grey.darkest, marginTop: '3px' }}>Active Status</Typography>
                    }
                    labelPlacement='start'
                  />
                </FormGroup>
                {/* CHANGE LOG BUTTON HANDLER */}
                <Button variant='text' onClick={(e) => handleChangeLogPopover(e)} style={{ textTransform: 'none', padding: 0, fontWeight: 600 }}>View Change Log</Button>
              </>
            )}
          <Grid item container direction='column' alignItems='flex-end' style={{ marginTop: '1.5em' }} spacing={1}>
            <Grid item>
              <Card style={{ padding: mceDetails?.badgeUrl ? '0.5em 1.5em' : '2em' }}>
                {mceDetails?.badgeUrl ? (
                  <img src={`${mceDetails?.badgeUrl}?${currentTimeStamp}`} style={{ maxHeight: '5.5rem' }} alt='mceBadge' />
                ) : <Typography variant='h5' style={{ color: theme.palette.purple.darkest }}>No Image</Typography>}
              </Card>
            </Grid>
            <Grid item>
              {mceDetails?.badgeUrl &&
                <Button variant='text' style={{ padding: 0, justifyContent: 'flex-end', background: 'none' }} onClick={() => setBadgeUploadOpen(true)}>
                  <Typography variant='body1' style={{ fontWeight: 600, fontSize: '1.1em', textTransform: 'none', color: theme.palette.purple.darkest }}>Edit</Typography>
                </Button>}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/*  STEPPER COMPONENT */}
      <Grid item container direcrtion='column' style={{ padding: '1em', paddingTop: '2em' }}>
        <Grid item style={{ width: '100%', margin: 'auto 6em auto 6em' }}>
          <BuilderStepper steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} />
        </Grid>
      </Grid>

      {/* New Stack Modal  */}
      <CreateOrEditStackModal
        classes={classes}
        localMCEStacks={stackList}
        createOrEditStackOpen={newStackOpen}
        handleCloseCreateOrEditStack={handleCloseNewStack}
        handleSaveStack={handleSaveNewStack}
        newStackValue={newStackValue}
        stackInfo={stackInfo}
        setStackInfo={setStackInfo}
        setNewStackValue={setNewStackValue}
        missingColorsErr={missingColorsErr}
      />

      <UploadBadgeModal
        classes={classes}
        badgeUploadOpen={badgeUploadOpen}
        handleCloseBadgeUploadModal={handleCloseBadgeUploadModal}
        handleOriginStatus={handleOriginStatus}
      />

      {/* CHANGE LOG POPOVER */}
      <BuilderVersionHistoryList
        versionHistory={versionHistory}
        classes={classes}
        openChangeLogPopover={openChangeLogPopover}
        changeLogAnchorEl={changeLogAnchorEl}
        setChangeLogAnchorEl={setChangeLogAnchorEl}
        handleOriginStatus={handleOriginStatus}
      />
    </Grid>
  )
}

export default BuilderHeader
