import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import Slide from '@material-ui/core/Slide'

import { makeStyles } from '@material-ui/core/styles'

import { login, forgotPassword, loginGoogle } from '../../../redux/actions'

import { NotificationToast } from '../tools'
import { formValidationTypes } from '../../../lib'

import MainLogin from './login/MainLogin'
import ForgotPassword from './login/ForgotPassword'
import log from '../../../utils/logger'
import { defaultErrors } from '../../../utils'
import { loginForms } from '../../../utils/variables'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  border: {
    borderBottom: '1px solid lightgray',
    width: '100%'
  },
  text: {
    marginRight: '1rem',
    marginLeft: '1rem'
  },
  loginFormContainer: {
    display: 'flex',
    marginTop: '18vh',
    [theme.breakpoints.down('xs')]: {
      marginTop: '8vh'
    }
  }
}))

const LoginForms = (props) => {
  const { currentForm, setCurrentForm = () => { } } = props
  const classes = useStyles()
  const dispatch = useDispatch()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [resetEmail, setResetEmail] = useState('')

  const [activeForm, setActiveForm] = useState(loginForms.MAIN_LOGIN)

  const defaultLoginFormsErr = defaultErrors.loginFormsErr

  const [errors, setErrors] = useState(defaultLoginFormsErr)

  const resetErrors = () => { setErrors(defaultLoginFormsErr) }
  const resetInputs = () => { setEmail(''); setPassword(''); setResetEmail('') }

  const fireLoginNotification = (res) => {
    let message = 'Success!'
    if (res.error) {
      switch (res.code) {
        case 400: {
          message = 'This account can only be logged into with Google, or by resetting the password with "Forgot Password".'
          break
        }
        case 403: {
          message = 'User Account Currently Unavailable.'
          break
        }
        default:
          message = 'Invalid Login'
          break
      }
    }

    NotificationToast(res.error, message)
  }

  const handleLogin = async () => {
    const storage = window.localStorage.getItem('persist:root')
    const storageAuth = storage ? JSON.parse(storage).auth : ''
    const storageToken = storageAuth ? JSON.parse(storageAuth).token : ''

    if (storageToken && Boolean(storageToken.length)) {
      setErrors({ ...defaultLoginFormsErr, userAlreadyLoggedIn: true })
    } else if (email === '' || password === '') {
      setErrors({ ...defaultLoginFormsErr, fieldsMissingError: true })
    } else {
      setErrors(defaultLoginFormsErr)

      const userName = email
      await dispatch(login(userName, password, fireLoginNotification))
        .catch(err => {
          log.error(err)
        })
    }
  }

  const handleGoogleLogin = async () => {
    await dispatch(loginGoogle())
      .catch(err => {
        log.error(err)
      })
  }

  const handlePasswordReset = async () => {
    if (resetEmail === '') {
      setErrors({ ...defaultLoginFormsErr, fieldsMissingError: true })
    } else {
      setErrors(defaultLoginFormsErr)

      const emailAddress = resetEmail
      await dispatch(forgotPassword(emailAddress, fireValidation, fireNotification))
        .catch(err => {
          log.error(err)
        })
    }
  }

  const fireValidation = (errorType) => {
    if (errorType === formValidationTypes.NO_EMAIL_FOUND) { setErrors({ ...defaultLoginFormsErr, noEmailFoundError: true }) }
    if (errorType === formValidationTypes.SEND_ERROR) { setErrors({ ...defaultLoginFormsErr, sendError: true }) }
    if (errorType === formValidationTypes.EMAIL_UNAVAILABLE) { setErrors({ ...defaultLoginFormsErr, emailUnavailableError: true }) }
  }

  const fireNotification = (response) => {
    NotificationToast(response.error, response.error ? response.message : 'An email has been sent! Please click the link to reset password.')
    if (response.error) { return false }
    setCurrentForm(loginForms.MAIN_LOGIN)
    resetInputs()
    resetErrors()
  }

  const handleFormEnter = (e) => {
    if (e.key) {
      if (e.key.toUpperCase() === 'ENTER') {
        if (currentForm === loginForms.MAIN_LOGIN) { handleLogin() } else if (currentForm === loginForms.FORGOT_PASSWORD) { handlePasswordReset() }
      }
    }
  }

  return (
    <>
      <Slide
        in={currentForm === loginForms.MAIN_LOGIN}
        onEntered={() => setActiveForm(loginForms.MAIN_LOGIN)}
        exit={false}
        unmountOnExit
        appear
        mountOnEnter
        timeout={{
          enter: activeForm !== loginForms.MAIN_LOGIN ? 500 : 0,
          exit: 10
        }}
        direction='left'
      >
        <div>
          <MainLogin
            classes={classes}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            handleFormEnter={handleFormEnter}
            handleLogin={handleLogin}
            setCurrentForm={setCurrentForm}
            errors={errors}
            resetErrors={resetErrors}
            resetInputs={resetInputs}
            handleGoogleLogin={handleGoogleLogin}
          />
        </div>
      </Slide>

      <Slide
        in={currentForm === loginForms.FORGOT_PASSWORD}
        onEntered={() => setActiveForm(loginForms.FORGOT_PASSWORD)}
        exit={false}
        unmountOnExit
        appear
        mountOnEnter
        timeout={{
          enter: 500,
          exit: 10
        }}
        direction='left'
      >
        <div>
          <ForgotPassword
            resetEmail={resetEmail}
            setResetEmail={setResetEmail}
            setCurrentForm={setCurrentForm}
            handlePasswordReset={handlePasswordReset}
            handleFormEnter={handleFormEnter}
            errors={errors}
            resetErrors={resetErrors}
            resetInputs={resetInputs}
          />
        </div>
      </Slide>
    </>
  )
}

export default LoginForms
