import React, { useState } from 'react'
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography, IconButton, Button,
  Divider, OutlinedInput, FormControlLabel, Checkbox, Card, Popover
} from '@material-ui/core'
import { CloseRounded, Edit } from '@material-ui/icons'

import { ChromePicker } from 'react-color'
import theme from '../styles/MuiTheme'

import { isEqual } from 'lodash'

const colorTypes = {
  PRIMARY: 'colorPrimary',
  SECONDARY: 'colorSecondary'
}

const CreateOrEditStackModal = (props) => {
  const {
    classes = {},
    createOrEditStackOpen = false,
    handleCloseCreateOrEditStack = () => { },
    selectedStack,
    stackInfo,
    setStackInfo = () => { },
    editMode = false,
    handleSaveStack = () => { },
    newStackValue,
    setNewStackValue = () => { },
    localMCEStacks,
    defaultUpdateStackInfo,
    missingColorsErr

  } = props

  const [editColor, setEditColor] = useState('')

  const handleChangeStackName = (value) => {
    if (!editMode) {
      setNewStackValue(value)
    }
  }

  const handleSelectExistingStack = (design) => {
    setStackInfo({ ...stackInfo, colorPrimary: design?.colorPrimary, colorSecondary: design?.colorSecondary, existingPalette: true, saveAsDesign: false })
  }

  // Palette picker popper related elements --- //
  const [colorPickerAnchorEl, setColorPickerAnchorEl] = useState(null)
  const openColorPickerPopover = Boolean(colorPickerAnchorEl)

  const handleColorPickerPopover = (e, type) => { setEditColor(type); setColorPickerAnchorEl(e.currentTarget) }
  const handleColorPickerPopClose = () => { setColorPickerAnchorEl(null) }

  const handleChangePalette = (color, type) => {
    const hexColor = color?.hex || ''

    let primaryColor = stackInfo?.colorPrimary || ''
    let secondaryColor = stackInfo?.colorSecondary || ''

    if (type === colorTypes.PRIMARY) { primaryColor = hexColor }
    if (type === colorTypes.SECONDARY) { secondaryColor = hexColor }

    setStackInfo({ ...stackInfo, colorPrimary: primaryColor, colorSecondary: secondaryColor, existingPalette: false })
  }

  const disableSaveBtn = Boolean((editMode && isEqual(stackInfo, defaultUpdateStackInfo)) || (!editMode && (!newStackValue || !newStackValue.length || !newStackValue.trim().length)))

  const handleSave = () => {
    if (stackInfo) {
      handleSaveStack(stackInfo)
    }
  }

  const handleClose = () => {
    setEditColor('')
    handleColorPickerPopClose()
    handleCloseCreateOrEditStack()
  }

  return (
    <Dialog
      open={createOrEditStackOpen}
      onClose={handleClose}
      maxWidth='md'
      fullWidth
    >
      <DialogTitle>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Typography variant='h4'>
            {editMode ? 'Edit Stack Colors' : 'Create New Stack'}
          </Typography>
          <IconButton onClick={handleClose} size='small'>
            <CloseRounded />
          </IconButton>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ marginTop: '1.5em', marginBottom: '1.5em' }}>
        <Grid container direction='column' spacing={2}>
          <Grid item style={{ paddingBottom: '1.5em' }}>
            <Typography variant='h6' style={{ marginBottom: '0.2em' }}>Stack Name</Typography>
            <OutlinedInput
              fullWidth
              size='small'
              value={!editMode ? (newStackValue || '') : selectedStack?.stackName}
              className={classes.searchInput}
              classes={{ input: classes.inputPlaceholder }}
              inputProps={{
                style: {
                  border: 'none',
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  paddingLeft: '5px'
                }
              }}
              onChange={(e) => handleChangeStackName(e.target.value)}
              placeholder={!editMode && 'A Name for the New Stack Goes Here'}
              readOnly={editMode}
            />
            {Boolean(!editMode && newStackValue.length && !newStackValue.trim().length) &&
              <Typography variant='caption' style={{ color: 'red' }} gutterBottom>*Name cannot contain only white space.</Typography>}
          </Grid>
          <Grid item>
            <Grid container direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant='body2' style={{ fontWeight: 600, marginBottom: '1em' }}>Color Palette</Typography>
                <Grid item container direction='row' alignItems='center' justifyContent='space-between'>
                  <Grid item xs={6}>
                    <Grid container alignItems='center' justifyContent='flex-start'>
                      <Typography variant='body2'>Primary</Typography>
                      <Grid item container alignItems='center' spacing={1}>
                        <Grid item>
                          <div
                            style={{
                              height: '1em',
                              width: '8em',
                              backgroundColor: stackInfo?.colorPrimary,
                              cursor: 'pointer',
                              border: '1px solid #ccc',
                              borderRadius: '20px'
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <IconButton style={{ border: '1px solid #ccc', padding: '0.2em', borderRadius: '0.3em', color: theme.palette.purple.darkest }} size='small' onClick={(e) => { handleColorPickerPopover(e, colorTypes.PRIMARY) }}>
                            <Edit fontSize='small' />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container alignItems='center' justifyContent='flex-start'>
                      <Typography variant='body2'>Secondary</Typography>
                      <Grid item container alignItems='center' spacing={1}>
                        <Grid item>
                          <div
                            style={{
                              height: '1em',
                              width: '8em',
                              backgroundColor: stackInfo?.colorSecondary,
                              cursor: 'pointer',
                              border: '1px solid #ccc',
                              borderRadius: '20px'
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <IconButton style={{ border: '1px solid #ccc', padding: '0.2em', borderRadius: '0.3em', color: theme.palette.purple.darkest }} size='small' onClick={(e) => { handleColorPickerPopover(e, colorTypes.SECONDARY) }}>
                            <Edit fontSize='small' />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Popover
                    id={openColorPickerPopover ? 'color-picker-popover-secondary' : undefined}
                    open={openColorPickerPopover}
                    anchorEl={colorPickerAnchorEl}
                    onClose={handleColorPickerPopClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                    }}
                  >
                    <ChromePicker disableAlpha color={stackInfo?.[editColor] || ''} onChange={(color) => handleChangePalette(color, editColor)} />
                  </Popover>
                </Grid>
                <Grid container direction='column' style={{ marginTop: '0.5em' }}>
                  <Typography variant='body2'>Gradient</Typography>
                  <div
                    style={{
                      width: '80%',
                      height: '1.5em',
                      border: '1px solid #ccc',
                      background: `linear-gradient(to right, ${stackInfo?.colorPrimary}, ${stackInfo?.colorSecondary})`,
                      borderRadius: '0.5em'
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant='body2' style={{ fontWeight: 600, marginBottom: '0.6em' }} gutterBottom>Use Existing Palette</Typography>
                {/* Add existing palette options here */}
                <Card variant='outlined' style={{ padding: '1em' }}>
                  <Grid container direction='row' spacing={1}>
                    {localMCEStacks && localMCEStacks?.designs && localMCEStacks.designs.map((design, i) => {
                      return (
                        <Grid item key={'design-' + design?.designID + i}>
                          <Button variant='outlined' onClick={() => handleSelectExistingStack(design)} style={{ backgroundColor: stackInfo?.colorPrimary === design?.colorPrimary && stackInfo?.colorSecondary === design?.colorSecondary ? theme.palette.grey.light : '' }}>
                            <div style={{ width: 24, height: 24, backgroundColor: design?.colorPrimary, borderRadius: '50%', margin: 2 }} />
                            <div style={{ width: 24, height: 24, backgroundColor: design?.colorSecondary, borderRadius: '50%', margin: 2 }} />
                          </Button>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions style={{ padding: '1em' }}>
        <Grid item container direction='column' alignItems='flex-end' justifyContent='flex-end' spacing={1}>
          {missingColorsErr &&
            (
              <Grid item>
                <Typography variant='caption' style={{ color: 'red', marginTop: '.5rem' }}>* You must select a primary and secondary color.</Typography>
              </Grid>
            )}
          <Grid item>
            {!stackInfo?.existingPalette && stackInfo?.colorPrimary && stackInfo?.colorSecondary &&
              <FormControlLabel
                control={
                  <Checkbox
                    checked={stackInfo?.saveAsDesign}
                    onChange={(e) => setStackInfo({ ...stackInfo, saveAsDesign: e.target.checked })}
                    color='primary'
                  />
                }
                label='Save Palette for future'
              />}
          </Grid>
          <Grid item>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSave} variant='contained' color='primary' disabled={disableSaveBtn}>
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogActions>

    </Dialog>
  )
}

export default CreateOrEditStackModal
