import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'

import {
  Grid, Paper, Typography, Divider, IconButton, Chip, Accordion, AccordionSummary, Tooltip
} from '@material-ui/core'

import { Facebook, Instagram, LinkedIn, ExpandMore, Twitter, ErrorOutlineRounded, AssignmentIndRounded } from '@material-ui/icons'

import { useTheme, makeStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'

import AboutModal from '../modals/AboutModal'
import { GET_USER_DETAILS } from '../../../redux/types'
import EditIcon from '../../../assets/edit-icon.svg'

import { userRoles } from '../../../utils/variables'

const useStyles = makeStyles((theme) => ({
  editIcon: {
    padding: '0 1em 0 0',
    '&:hover': {
      backgroundColor: 'transparent !important'
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0'
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '.5em'
    }
  },
  accordionHeader: {
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start'
    }
  }
}))

const ModifiedGradCapIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='55'
      height='55'
      viewBox='0 0 111 111'
    >
      <path className='b' d='M77.8,75.148,42.929,64.435a9.986,9.986,0,0,0-5.849,0L2.212,75.148a2.943,2.943,0,0,0,0,5.7l6.079,1.867a9.85,9.85,0,0,0-2.235,5.862A3.857,3.857,0,0,0,5.737,95.2l-3.191,14.36A2,2,0,0,0,4.5,112h7.014a2,2,0,0,0,1.952-2.434L10.272,95.2a3.847,3.847,0,0,0-.225-6.565,5.9,5.9,0,0,1,2.586-4.59L37.079,91.56a10,10,0,0,0,5.849,0L77.8,80.848a2.943,2.943,0,0,0,0-5.7ZM44.1,95.384a13.994,13.994,0,0,1-8.2,0L17.777,89.815,16,104c0,4.419,10.745,8,24,8s24-3.581,24-8L62.231,89.814Z' transform='translate(-0.005 -63.997)' fill='#4c5788' />
    </svg>
  )
}

const ModifiedGradesIcon = (color) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='60'
      height='60'
      viewBox='0 0 111 111'
    >
      <path className='b' d='M33,9a9,9,0,1,0-9,9A9,9,0,0,0,33,9ZM21.9,22.6C16.337,19.2,7.327,18.262,2.794,18A2.594,2.594,0,0,0,0,20.544V41.432a2.578,2.578,0,0,0,2.483,2.536c4.093.215,12.374,1,18.1,3.884a1.305,1.305,0,0,0,1.92-1.113V23.677a1.247,1.247,0,0,0-.6-1.074ZM45.206,18c-4.533.257-13.543,1.193-19.1,4.6a1.271,1.271,0,0,0-.6,1.09V46.736a1.309,1.309,0,0,0,1.926,1.116c5.722-2.88,14-3.667,18.091-3.881A2.58,2.58,0,0,0,48,41.434V20.544A2.6,2.6,0,0,0,45.206,18Z' fill={color.color} />
    </svg>
  )
}

const UserAbout = (props) => {
  const { matches, canEdit, editMode, userProfileID, loadingState, modalTypes, removeModalType, navHeight = 0 } = props
  const theme = useTheme()
  const classes = useStyles()

  const { type, loading } = loadingState
  const parsedProps = queryString.parse(props.location.search)

  // Defines a modal being open
  const modalType = parsedProps.modal

  const [aboutModalOpen, setAboutModalOpen] = useState(false)

  // handles the user being routed directly to the edit about modal
  useEffect(() => {
    if (modalType) {
      if (!aboutModalOpen && modalType === modalTypes.ABOUT) {
        setAboutModalOpen(true)
      }
    }
  }, [modalType, aboutModalOpen, modalTypes])

  const [expandAccor, setExpandAccor] = useState(false)

  const userDetails = useSelector(state => state.userDetails)

  const {
    yearsTeaching, summary, roleName, inFellowTypeName,
    facebookProfile, instagramProfile, linkedInProfile, twitterProfile,
    subjectsList, gradesList
  } = userDetails

  const newGradesList = () => {
    const tempArray = []
    gradesList.forEach(grade => {
      const { gradeName } = grade
      if (gradeName === 'Pre-K') { tempArray.push('Pre-K') }
      if (gradeName === 'K - 6') { tempArray.push('K - 6') }
      if (gradeName === 'K - 12') { tempArray.push('K - 12') }
      if (gradeName === 'Kindergarten') { tempArray.push('Kindergarten') }
      if (gradeName === 'Grade 1' || gradeName === 'Grade 2' || gradeName === 'Grade 3' || gradeName === 'Grade 4' || gradeName === 'Grade 5' || gradeName === 'Grade 6') {
        if (!tempArray.includes('Elementary School')) {
          tempArray.push('Elementary School')
        }
      }
      if (gradeName === 'Grade 7' || gradeName === 'Grade 8') {
        if (!tempArray.includes('Middle School')) {
          tempArray.push('Middle School')
        }
      }
      if (gradeName === 'Grade 9' || gradeName === 'Grade 10' || gradeName === 'Grade 11' || gradeName === 'Grade 12') {
        if (!tempArray.includes('High School')) {
          tempArray.push('High School')
        }
      }
    })
    if (tempArray.includes('Elementary School') && tempArray.includes('Middle School') && tempArray.includes('High School')) {
      return ['K-12']
    }

    return tempArray.join(', ')
  }

  const handleAboutModalOpen = (e) => {
    setAboutModalOpen(!aboutModalOpen)
  }

  const alertSubjectsDeleted = subjectsList && Boolean(subjectsList.length) && Boolean(subjectsList.findIndex(x => x.deletedAt > 0) !== -1)

  // If the user has the Innovation Fellow role, display their type. Otherwise, display grades / grades.
  const typeOrGradeRenderCheck = () => {
    if (roleName && roleName === userRoles.INNOVATION_FELLOW) {
      return (
        <>
          <Grid item container alignItems='center' xs={6}>
            <Grid item container direction='row' justifyContent='flex-start' style={{ maxHeight: '2em', paddingLeft: '.7em', marginBottom: '.5em' }}>
              <AssignmentIndRounded style={{ color: theme.palette.purple.darker, height: '1.5em', width: '1.5em' }} />
            </Grid>
            <Grid item container direction='row' justifyContent='flex-start' style={{ paddingLeft: '1em' }}>
              <Typography variant='h5' style={{ fontSize: '14px' }}>{inFellowTypeName}</Typography>
            </Grid>
          </Grid>
        </>
      )
    } else {
      return (
        <>
          {/* Check if the user has any grades selected. */}
          {
            gradesList && gradesList.length > 0
              ? (
                <>
                  <Grid item xs={6} style={{ paddingLeft: '1em' }}>
                    <Grid item container direction='row' justifyContent='flex-start' style={{ maxHeight: '2em', marginBottom: '.5em' }}>
                      <ModifiedGradesIcon color={theme.palette.purple.darker} />
                    </Grid>
                    <Grid item container direction='row' justifyContent='flex-start'>
                      <Typography variant='h5'>{newGradesList()}</Typography>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={6} style={{ paddingLeft: '1em' }}>
                    <Grid item container direction='row' justifyContent='flex-start' style={{ maxHeight: '2em', marginBottom: '.5em' }}>
                      <ModifiedGradesIcon color={theme.palette.grey.dark} />
                    </Grid>
                    <Grid item container direction='row' justifyContent='flex-start'>
                      <Typography variant='h5' style={{ color: theme.palette.grey.dark }}>None</Typography>
                    </Grid>
                  </Grid>
                </>
              )
          }
        </>
      )
    }
  }

  // If the user has the Innovation Fellow role, return nothing. Otherwise, return subject / subjects.
  const subjectRenderCheck = () => {
    if (roleName && roleName === userRoles.INNOVATION_FELLOW) {
      return (<></>)
    } else {
      return (
        <>
          {/* Check if the user has any subjects selected. */}
          {
            subjectsList && subjectsList.length > 0
              ? (
                <>
                  <Grid item xs={12}>
                    <Grid item container direction='row' justifyContent='flex-start' style={{ padding: '0 1rem' }}>
                      {subjectsList.map((subject, i) => {
                        const { subjectID, subjectName, deletedAt } = subject
                        return (
                          deletedAt && deletedAt > 0
                            ? (
                              canEdit && editMode &&
                                <Tooltip
                                  key={`${subjectID}-${i}`}
                                  arrow
                                  title='This subject is no longer viewable to the public. Please edit this section to remove it from your profile.'
                                >
                                  <Chip
                                    label={subjectName}
                                    size='small'
                                    style={{
                                      borderRadius: '5px',
                                      marginRight: '.5rem',
                                      backgroundColor: theme.palette.grey.medium,
                                      color: '#a1a1aa',
                                      padding: '1em .5em',
                                      marginBottom: '.5em',
                                      fontWeight: '600',
                                      fontFamily: 'Source Sans Pro'
                                    }}
                                  />
                                </Tooltip>
                            )
                            : (
                              <Chip
                                key={`${subjectID}-${i}`}
                                label={subjectName}
                                style={{
                                  borderRadius: '5px',
                                  marginRight: '.5rem',
                                  backgroundColor: theme.palette.pink.dark,
                                  color: '#ffff',
                                  padding: '1em .5em',
                                  marginBottom: '.5em',
                                  fontWeight: '600',
                                  fontFamily: 'Source Sans Pro'
                                }}
                              />
                            )
                        )
                      })}
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Grid item container direction='row' justifyContent='flex-start' style={{ padding: '0 1rem' }}>
                      <Typography
                        variant='h6'
                        style={{ color: theme.palette.grey.dark, textTransform: 'none', textAlign: 'center' }}
                      >
                        {canEdit && editMode ? 'Click the edit Icon to add subjects you teach to your profile.' : 'This User has not chosen any subjects.'}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )
          }
        </>
      )
    }
  }

  useEffect(() => {
    return () => {
      setAboutModalOpen(false)
      setExpandAccor(false)
    }
  }, [])

  // --- MAIN COMPONENT RETURN --- //
  return (
    <>
      {
        type === GET_USER_DETAILS && loading === true
          ? (
            <>
              <Grid container style={{ margin: matches ? '1em 2em 1em 1em' : '1em 1em 1em 1em' }}>
                <Paper style={{ width: '100%' }}>
                  <Grid item container direction='row' alignItems='center' justifyContent='space-between' style={{ margin: '.2em .5em', padding: '.5em' }} xs={12}>
                    <Skeleton animation='wave' width={100} />
                  </Grid>
                  <Divider />

                  <Grid item container direction='row' justifyContent='flex-start' style={{ margin: '1em 0' }}>
                    <Grid item xs={3}>
                      <Grid item container direction='row' justifyContent='center' style={{ maxHeight: '2em' }}>
                        <Skeleton animation='wave' width={65} height={50} />
                      </Grid>
                    </Grid>

                    <Grid item xs={8} style={{ marginLeft: '1em' }}>
                      <Grid item container direction='row' justifyContent='flex-start' style={{ maxHeight: '2em' }}>
                        <Skeleton animation='wave' width={65} height={50} />
                      </Grid>
                    </Grid>

                    <Grid item container direction='row' style={{ margin: '.5em 0' }}>
                      <Grid item xs={12}>
                        <Grid item container direction='row' justifyContent='flex-start' style={{ padding: '.5rem 1rem 1rem 1rem' }}>
                          <Skeleton animation='wave' width={350} height={60} />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item container direction='row' style={{ margin: '.5em 0' }}>
                      <Grid item xs={12}>
                        <Grid item container direction='row' justifyContent='flex-start' style={{ padding: '0 1rem' }}>
                          <Skeleton animation='wave' width={170} height={60} style={{ marginTop: '-2rem' }} />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item container direction='row' style={{ margin: '.5em .5em' }}>
                      <Grid item xs={12}>
                        <Grid item container direction='row' justifyContent='flex-start'>
                          <Skeleton animation='wave' width={120} height={40} style={{ marginTop: '-.5rem', marginLeft: '.5rem' }} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </>
          ) : (
            <>
              <AboutModal
                aboutModalOpen={aboutModalOpen}
                setAboutModalOpen={setAboutModalOpen}
                userProfileID={userProfileID}
                userDetails={userDetails}
                modalType={modalType}
                removeModalType={removeModalType}
                navHeight={navHeight}
              />

              <Grid container style={{ margin: matches ? '1em 2em 1em 1em' : '1em 1em 1em 1em' }}>
                <Accordion expanded={matches || expandAccor} style={{ width: '100%' }}>
                  <AccordionSummary expandIcon={<ExpandMore style={{ display: matches ? 'none' : 'flex' }} />} style={{ height: '3.2em', minHeight: 0, cursor: matches ? 'default' : 'pointer' }} onClick={() => setExpandAccor(prev => !prev)}>
                    <Grid item container direction='row' alignItems='center' className={classes.accordionHeader} xs={12}>
                      <Grid item container xs={6}>
                        <Typography variant='h4'>About</Typography>
                        {canEdit && editMode && alertSubjectsDeleted &&
                          <ErrorOutlineRounded style={{ color: 'red', fontSize: '16px', paddingLeft: '.3em' }} />}
                      </Grid>
                      <Grid item>
                        {canEdit && editMode &&
                          <IconButton
                            onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleAboutModalOpen(e) }}
                            className={classes.editIcon}
                          >
                            <img src={EditIcon} alt='edit-icon' />
                          </IconButton>}
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <Divider />

                  {/* YEARS TEACHING & GRADES / TYPE SECTION */}
                  <Grid item container direction='row' justifyContent='flex-start' style={{ margin: '1em 0' }}>
                    {
                      Number.isInteger(yearsTeaching)
                        ? (
                          <>
                            <Grid item xs={5}>
                              <Grid item container direction='row' justifyContent='flex-start' style={{ maxHeight: '2em', marginLeft: '1.5em', marginBottom: '.5em' }}>
                                <ModifiedGradCapIcon />
                              </Grid>
                              <Grid item container direction='row' justifyContent='flex-start' style={{ paddingLeft: '1.7em' }}>
                                <Typography variant='h5'>{yearsTeaching === 1 ? `${yearsTeaching} yr` : `${yearsTeaching} yrs`}</Typography>
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )
                    }

                    {/* This checks what to render depending on the users role. */}
                    {typeOrGradeRenderCheck()}

                  </Grid>

                  <Grid item container direction='row' style={{ margin: '.5em 0' }}>
                    {
                      summary
                        ? (
                          <>
                            <Grid item xs={12}>
                              <Grid item container direction='row' justifyContent='flex-start' style={{ padding: '.5rem 1rem 1rem 1rem' }}>
                                <Typography variant='body1'>{summary}</Typography>
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item xs={12}>
                              <Grid item container direction='row' justifyContent='flex-start' style={{ padding: '.5rem 1rem 1rem 1rem' }}>
                                <Typography
                                  variant='caption'
                                  style={{ textTransform: 'none', fontWeight: '600', fontFamily: 'Source Sans Pro' }}
                                >
                                  {canEdit && editMode ? 'Click the Edit icon to add a description of who you are!' : 'This user has not added a summary yet.'}
                                </Typography>
                              </Grid>
                            </Grid>
                          </>
                        )
                    }
                  </Grid>

                  {/* SUBJECT SECTION */}
                  <Grid item container direction='row' style={{ margin: '.5em 0' }}>
                    {/* This checks what to render depending on the users role. */}
                    {subjectRenderCheck()}
                  </Grid>

                  <Grid item container direction='row' style={{ margin: '.5em .5em' }}>
                    <Grid item xs={12}>
                      <Grid item container direction='row' justifyContent='flex-start'>
                        {
                          facebookProfile
                            ? (
                              <>
                                <IconButton
                                  style={{ padding: '8px' }}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    window.open(facebookProfile, '_blank')
                                  }}
                                >
                                  <Facebook style={{ height: '1.2em', width: '1.2em', color: '#1977F3' }} />
                                </IconButton>
                              </>
                            ) : (
                              <>
                                <IconButton style={{ padding: '8px' }} disabled>
                                  <Facebook style={{ height: '1.2em', width: '1.2em', color: theme.palette.grey.medium }} />
                                </IconButton>
                              </>
                            )
                        }

                        {
                          instagramProfile
                            ? (
                              <>
                                <IconButton
                                  style={{ padding: '8px' }}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    window.open(instagramProfile, '_blank')
                                  }}
                                >
                                  <Instagram style={{ height: '1.2em', width: '1.2em', color: '#CF4BA6' }} />
                                </IconButton>
                              </>
                            ) : (
                              <>
                                <IconButton style={{ padding: '8px' }} disabled>
                                  <Instagram style={{ height: '1.2em', width: '1.2em', color: theme.palette.grey.medium }} />
                                </IconButton>
                              </>
                            )
                        }

                        {
                          linkedInProfile
                            ? (
                              <>
                                <IconButton
                                  style={{ padding: '8px' }}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    window.open(linkedInProfile, '_blank')
                                  }}
                                >
                                  <LinkedIn style={{ height: '1.2em', width: '1.2em', color: '#0E76A8' }} />
                                </IconButton>
                              </>
                            ) : (
                              <>
                                <IconButton style={{ padding: '8px' }} disabled>
                                  <LinkedIn style={{ height: '1.2em', width: '1.2em', color: theme.palette.grey.medium }} />
                                </IconButton>
                              </>
                            )
                        }

                        {
                          twitterProfile
                            ? (
                              <>
                                <IconButton
                                  style={{ padding: '8px' }}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    window.open(twitterProfile, '_blank')
                                  }}
                                >
                                  <Twitter style={{ height: '1.2em', width: '1.2em', color: '#1DA1F2' }} />
                                </IconButton>
                              </>
                            ) : (
                              <>
                                <IconButton style={{ padding: '8px' }} disabled>
                                  <Twitter style={{ height: '1.2em', width: '1.2em', color: theme.palette.grey.medium }} />
                                </IconButton>
                              </>
                            )
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </Accordion>
              </Grid>
            </>
          )
      }
    </>
  )
}

export default withRouter(UserAbout)
