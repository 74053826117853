import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import NoMessages from '../../assets/noMessagesImage.png'

/* ********** Empty Inbox Message ************* //
    Rendered from MessageArea.js when the 'view.message' is 'empty'
    - contains an action button to switch the view to 'compose'
*/
const EmptyInbox = (props) => {
  const { theme, viewTypes, loadNewView, parsedProps } = props

  // Check to see if we are in empty inbox, or empty draft inbox view
  const emptyDraftsCheck = parsedProps && parsedProps.view && parsedProps.view === 'empty_draft'

  // Depending on the view we are in, send the user to the correct place
  const changeView = () => {
    if (emptyDraftsCheck) {
      loadNewView(viewTypes.MESSAGE)
    } else {
      loadNewView(viewTypes.COMPOSE)
    }
  }

  return (
    <Grid item container direction='column' alignItems='center' style={{ height: '100vh', width: '100%' }}>
      <Grid item container justifyContent='center' style={{ height: '40%', width: 'auto', marginTop: '10%' }}>
        <img src={NoMessages} alt='empty-inbox' style={{ maxWidth: '100%', maxHeight: '100%' }} />
      </Grid>
      <Grid item container justifyContent='center'>
        <Typography variant='h2' style={{ color: theme.palette.purple.darkest, fontWeight: 600 }}>
          {emptyDraftsCheck ? 'No More Draft Messages.' : 'Oh No! Looks Like Your Inbox Is Empty.'}
        </Typography>
      </Grid>
      <Grid item container justifyContent='center'>
        <Typography
          variant='h4'
          style={{
            color: theme.palette.grey.dark,
            fontWeight: 400
          }}
        >
          Click <span style={{ color: theme.palette.purple.darkest, fontWeight: 600, cursor: 'pointer' }} onClick={changeView}>Here</span> to {!emptyDraftsCheck ? 'start a new conversation.' : 'return to your inbox.'}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default EmptyInbox
