import React from 'react'
import { EmptyInbox, MessageChain, NewMessage } from './MessageViews'
import WebsocketProvider from '../../Convey/utils/WebSocket'

/* ********** Switch the view within the "message Area" based on the view state ************* //
    Uses the 'view' object and reads the 'message' key to determine the desired view
    --- TO DO: create a loading/skeleton to render as the 'default' of the switch case
    --- this will need to be accompanied by a timeout that sets the view to 'EmptyInbox.js' if no other state is set after a period of time
*/
const MessageArea = (props) => {
  const {
    theme, smScreen, view, viewTypes, loadNewView, activeConversationID, parsedProps = {},
    connectionActive, uiLoading, classes, socketClosedCode, messagesInitialLoad,
    confirmAnchorEl, setConfirmAnchorEl, openConfirmPopover,
    deleteDraftID, setDeleteDraftID,
    currentForm, setCurrentForm,
    editorOpen, setEditorOpen, openIconRef, setOpenIconRef = () => { }
  } = props

  // Use the view config object to decide which component should render in the 'message' area.
  const messageView = view && view.message ? view.message : ''

  const getView = (view) => {
    switch (view) {
      case 'chain':
        return (
          <WebsocketProvider>
            <MessageChain
              theme={theme}
              smScreen={smScreen}
              loadNewView={loadNewView}
              viewTypes={viewTypes}
              view={view}
              activeConversationID={activeConversationID}
              connectionActive={connectionActive}
              classes={classes}
              socketClosedCode={socketClosedCode}
              messagesInitialLoad={messagesInitialLoad}
              confirmAnchorEl={confirmAnchorEl}
              setConfirmAnchorEl={setConfirmAnchorEl}
              openConfirmPopover={openConfirmPopover}
              deleteDraftID={deleteDraftID}
              setDeleteDraftID={setDeleteDraftID}
              setCurrentForm={setCurrentForm}
              editorOpen={editorOpen}
              setEditorOpen={setEditorOpen}
              openIconRef={openIconRef}
              setOpenIconRef={setOpenIconRef}
            />
          </WebsocketProvider>
        )
      case 'compose':
        return (
          <NewMessage
            theme={theme}
            loadNewView={loadNewView}
            viewTypes={viewTypes}
            view={view}
            uiLoading={uiLoading}
            currentForm={currentForm}
            setCurrentForm={setCurrentForm}
          />
        )
      default:
        return (
          <EmptyInbox
            theme={theme}
            loadNewView={loadNewView}
            viewTypes={viewTypes}
            parsedProps={parsedProps}
          />
        )
    }
  }

  return getView(messageView)
}

export default MessageArea
