import React from 'react'
import { withRouter } from 'react-router-dom'

import { Grid, Typography, Button } from '@material-ui/core'
import { Assignment as ResourceIcon } from '@material-ui/icons'
import theme from '../../styles/MuiTheme'

/* NOTES: Message displayed when no results are found for any resources in a global search
  -- Parent Component: ui/Search/ResultAreas/ResourceResults.js
    - Props: handleNoResultsViewAll from SearchBarNav.js

  In depth render tree loacated in SearchBarNav.js
*/
const NoResourceResults = (props) => {
  const { handleNoResultsViewAll } = props

  return (
    <div style={{ backgroundColor: `${theme.palette.purple.lightest}75`, padding: '1em' }}>
      <Grid item container direction='row' justifyContent='center' style={{ marginBottom: '1em' }}>
        <Grid item container alignContent='center' justifyContent='center' style={{ backgroundColor: '#DFE4F8', borderRadius: '50%', height: '3em', width: '3em' }}>
          <ResourceIcon style={{ color: theme.palette.purple.darkest, fontSize: '35px' }} />
        </Grid>
      </Grid>

      <Grid item container direction='row' justifyContent='center'>
        <Typography variant='h5' style={{ color: theme.palette.purple.darkest }}>No Results Found</Typography>
      </Grid>

      <Grid item container direction='row' justifyContent='center'>
        <Typography style={{ textAlign: 'center' }}>There were no resources matching your criteria.</Typography>
      </Grid>

      <Grid item container direction='row' justifyContent='center'>
        <Button
          color='primary'
          variant='text'
          style={{ textTransform: 'none', fontWeight: '400', color: '#29356a', fontSize: '12px' }}
          onClick={(e) => {
            handleNoResultsViewAll(e, 'ed-farm-resources')
          }}
        >
          Click here to see all Ed Farm Resources
        </Button>
      </Grid>
    </div>
  )
}

export default withRouter(NoResourceResults)
