import { LOGOUT } from '../redux/types'

/**
 * @typedef {Object} responseInfo
 * @property {Number} code - Response code from api request
 * @property {String} type - Type of response from api request
 * @property {Boolean} error - Is type of response from api request classified as an error
 * @property {String} message - Message from request
 *
 * @typedef {Object} unhandledInfo
 * @property {Boolean} error - Is type of response from api request classified as an error
 * @property {String} message - Message from unhandled error
 *
 * @callback reduxDispatch - dispatch redux
 *
 * @param {Object} response - response from api request
 * @param {reduxDispatch} dispatch - redux function for dispatching calls
 * @param {Boolean} publicEndpoint - Is endpoint public
 * @returns {(responseInfo|unhandledInfo)} - parsed response from api
 */
export const parseResponse = (response, dispatch, publicEndpoint = false) => {
  const unhandledErrorResponse = {
    error: true,
    message: 'There was an unhandled error. Please try again'
  }
  if (response.status && response.status === 401 && !publicEndpoint) {
    // If user is attemtping to hit a non-public endpoint with invalid credentials, log them out
    dispatch({
      type: LOGOUT,
      payload: { error: true }
    })
    return false
  } else if (response.status) {
    const { status } = response
    return responses[status] || unhandledErrorResponse
  } else {
    return unhandledErrorResponse
  }
}

export const formValidationTypes = {
  SEND_ERROR: 'send-error',
  NO_EMAIL_FOUND: 'no-email-found',
  EMAIL_UNAVAILABLE: 'email-unavailable',
  PASSWORD_RESET_ERROR: 'password-reset-error',
  VALIDATION_KEY_ERROR: 'validation-key-error',
  PASSWORD_UPDATE_ERROR: 'password-update-error',
  PASSWORD_MATCH_ERROR: 'password-match-error',
  DDOS_ERROR: 'ddos-error',
  INVALID_FIELDS_ERROR: 'invalid-fields-error'
}

export const responseTypes = {
  SUCCESS: 'SUCCESS',
  NO_CONTENT: 'NO_CONTENT',
  PARTIAL_SUCCESS: 'PARTIAL_SUCCESS',
  BAD_REQUEST: 'BAD_REQUEST',
  UNAUTHORIZED: 'UNAUTHORIZED',
  FORBIDDEN: 'FORBIDDEN',
  NOT_FOUND: 'NOT_FOUND',
  DUPLICATE_ENTRY: 'DUPLICATE_ENTRY',
  INTERNAL_ERROR: 'INTERNAL_ERROR',
  UNIMPLEMENTED: 'UNIMPLEMENTED'
}

export const responses = {
  200: {
    code: 200,
    type: responseTypes.SUCCESS,
    error: false,
    message: 'OK'
  },
  204: {
    code: 204,
    type: responseTypes.NO_CONTENT,
    error: true,
    message: 'The requested action has already been performed.'
  },
  206: {
    code: 206,
    type: responseTypes.PARTIAL_SUCCESS,
    error: true,
    message: 'Your request completed successfully, but there was an issue sending out the notification.'
  },
  400: {
    code: 400,
    type: responseTypes.BAD_REQUEST,
    error: true,
    message: 'There was a problem with your request. Please try again.'
  },
  401: {
    code: 401,
    type: responseTypes.UNAUTHORIZED,
    error: true,
    message: 'Unauthorized.'
  },
  403: {
    code: 403,
    type: responseTypes.FORBIDDEN,
    error: true,
    message: 'Forbidden.'
  },
  404: {
    code: 404,
    type: responseTypes.NOT_FOUND,
    error: true,
    message: 'The requested resource could not be found. Please try again.'
  },
  409: {
    code: 409,
    type: responseTypes.DUPLICATE_ENTRY,
    error: true,
    message: 'A resource already exists with those specifications. Please adjust your input and try again.'
  },
  500: {
    code: 500,
    type: responseTypes.INTERNAL_ERROR,
    error: true,
    message: 'There was a problem completing your request. Please try again.'
  },
  501: {
    code: 501,
    type: responseTypes.UNIMPLEMENTED,
    error: true,
    message: 'The requested resource has not yet been implemented. Please try again later.'
  }
}
