import React from 'react'
import { Grid, useTheme, Avatar, Link } from '@material-ui/core'

const ArtifactSocialUserDisplay = (props) => {
  const { userInfo, handleUserProfileView, handleModalClose } = props
  const theme = useTheme()

  return (
    <Grid item container direction='row' alignItems='center' xs={12} style={{ marginBottom: '.5em' }}>
      <Avatar
        src={userInfo && userInfo.profileAvatarPath ? userInfo.profileAvatarPath : ''}
        style={{ height: '40px', width: '40px', marginRight: '.3em', cursor: 'pointer' }}
        onClick={(e) => { e.preventDefault(); handleUserProfileView(userInfo.profileID, handleModalClose) }}
      />
      <Link
        variant='h6'
        style={{ color: theme.palette.purple.darkest, fontWeight: 600, textTransform: 'none', marginRight: '1em', cursor: 'pointer' }}
        onClick={(e) => { e.preventDefault(); handleUserProfileView(userInfo.profileID, handleModalClose) }}
      >
        {userInfo && userInfo.fullName ? userInfo.fullName : ''}
      </Link>
    </Grid>
  )
}

export default ArtifactSocialUserDisplay
