import { LOGOUT, GET_USER_DETAILS, GET_USER_NOTIFICATIONS_SMALL, GET_USER_NOTIFICATIONS_LARGE, RESET_USER_NOTIFICATIONS_LARGE, UPDATE_AVATAR, UPDATE_IMAGE, UPDATE_USER_PROFILE } from '../types'

// UI Tech Debt: moved notifications into user reducer and deleted it's respective file

const initialState = {
  userID: '',
  fullName: '',
  nickName: '',
  roleID: '',
  roleName: '',
  inFellowTypeID: '',
  inFellowTypeName: '',
  emailAddress: '',
  yearsTeaching: '',
  summary: '',
  profileAvatarKey: '',
  profileAvatarPath: null,
  authProfileAvatarPath: null,
  profileImagePath: null,
  profileImageKey: '',
  schoolID: '',
  schoolName: '',
  schoolDistrictID: '',
  districtName: '',
  facebookProfile: '',
  linkedInProfile: '',
  instagramProfile: '',
  twitterProfile: '',
  subjectsList: [],
  gradesList: [],
  certificationIDs: [],
  skillsList: [],
  techList: [],
  certList: [],
  userNotificationsLarge: [],
  userNotificationsSmall: [],
  notificationCount: 0,
  deletedAt: 0
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_DETAILS: return {
      ...state,
      userID: action.payload.userID,
      fullName: action.payload.fullName,
      nickName: action.payload.nickName,
      roleID: action.payload.roleID,
      roleName: action.payload.roleName,
      inFellowTypeID: action.payload.inFellowTypeID,
      inFellowTypeName: action.payload.inFellowTypeName,
      emailAddress: action.payload.emailAddress,
      yearsTeaching: action.payload.yearsTeaching,
      summary: action.payload.summary,
      profileAvatarKey: action.payload.profileAvatarKey,
      profileAvatarPath: action.payload.profileAvatarPath,
      authProfileAvatarPath: action.payload.authProfileAvatarPath,
      profileImagePath: action.payload.profileImagePath,
      profileImageKey: action.payload.profileImageKey,
      schoolID: action.payload.schoolID,
      schoolName: action.payload.schoolName,
      schoolDistrictID: action.payload.schoolDistrictID,
      districtName: action.payload.districtName,
      facebookProfile: action.payload.facebookProfile,
      linkedInProfile: action.payload.linkedInProfile,
      instagramProfile: action.payload.instagramProfile,
      twitterProfile: action.payload.twitterProfile,
      subjectsList: action.payload.subjectsList,
      gradesList: action.payload.gradesList,
      certificationIDs: action.payload.certificationIDs ? action.payload.certificationIDs : [],
      skillsList: action.payload.skillsList,
      techList: action.payload.techList,
      certList: action.payload.certList,
      deletedAt: action.payload.deletedAt
    }
    case GET_USER_NOTIFICATIONS_LARGE: return {
      ...state,
      userNotificationsLarge: action.payload.notifications,
      notificationCount: action.payload.notificationCount
    }
    case GET_USER_NOTIFICATIONS_SMALL: return {
      ...state,
      userNotificationsSmall: action.payload.notifications,
      notificationCount: action.payload.notificationCount
    }
    case UPDATE_IMAGE: return {
      ...state,
      profileImagePath: action.payload
    }
    case UPDATE_AVATAR: return {
      ...state,
      profileAvatarPath: action.payload,
      authProfileAvatarPath: action.payload
    }
    case UPDATE_USER_PROFILE: return {
      ...state,
      certList: action.payload.certifications,
      nickName: action.payload.nickName,
      schoolID: action.payload.schoolID
    }
    case RESET_USER_NOTIFICATIONS_LARGE: return {
      ...state,
      userNotificationsLarge: []
    }
    case LOGOUT: return {
      ...initialState,
      error: action.payload.error || false
    }
    default: return state
  }
}

export default userReducer
