import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Grid, Typography
} from '@material-ui/core'
import WorkSampleTemplate from './MCETools/WorkSampleTemplate'
import MCGradingDisplay from './MCETools/MCGradingDisplay'
import MCEArtifactPreviewModal from './MCETools/MCEArtifactPreviewModal'
import { mceSections } from '../../../utils'

const MCEPartTwo = (props) => {
  const {
    currentStyle = {}, isSummaryView = true, classes = {}, isAssessorView = false, isResultsView = false, attemptDetailsForm = {},
    submissionReview = false, questionGradingValues = {}, fullyGradedEvaluation = false, handleGradeQuestion = () => { }, currentSection = {},
    setAttemptDetailsForm = () => { }, handleSaveAttempt = () => { }, navHeight = 0, isPursuerView = false, modifyComment = () => { }
  } = props

  const { mceDetails: { P2 = [] } = {} } = useSelector(state => state.microcredentials)

  // decide if we need to render the grading and commenting actions/views
  const renderGrades = Boolean(isAssessorView || isResultsView)

  // ARTIFACT STATE CONTROLS
  const [openLEDModal, setOpenLEDModal] = useState(false)
  const [formattedInfo, setFormattedInfo] = useState({})
  const [artDetails, setArtDetails] = useState({})

  return (
    <Grid item container direction='column' className={isSummaryView ? classes.summarySectionPadding : classes.defaultSectionPadding}>
      {/* SECTION TITLE AND GRADING TOOLS */}
      <Grid item container direction='row' style={{ marginBottom: '1em' }}>
        {/* TITLE */}
        <Grid item xs={10} container direction='column'>
          <Typography variant='h4' style={{ fontWeight: 900, fontSize: 22 }}>Part 2</Typography>
          <Typography gutterBottom variant='h5' style={{ fontSize: 18 }}>Work Samples</Typography>
        </Grid>
        {/* GRADE COMPLETION COUNTER/FULLY GRADED DISPLAY */}
        {renderGrades &&
          <Grid item xs={2} container direction='row' justifyContent='flex-end'>
            <MCGradingDisplay
              gradingValues={questionGradingValues[mceSections.PART2] || {}}
              classes={classes}
              fullyGradedEvaluation={fullyGradedEvaluation}
              isResultsView={isResultsView}
            />
          </Grid>}
      </Grid>

      {/* MAPPED SAMPLES */}
      {P2.sort((a, b) => { return a.sampleOrder - b.sampleOrder }).map((question, i) => {
        const { workSampleID, questionOrder } = question

        return (
          <WorkSampleTemplate
            key={`question-p2-${workSampleID}`}
            currentStyle={currentStyle}
            isSummaryView={isSummaryView}
            isAssessorView={isAssessorView}
            classes={classes}
            submissionReview={submissionReview}
            questionDetails={question}
            order={questionOrder}
            handleGradeQuestion={handleGradeQuestion}
            attemptDetailsForm={attemptDetailsForm}
            isResultsView={isResultsView}
            setAttemptDetailsForm={setAttemptDetailsForm}
            handleSaveAttempt={handleSaveAttempt}
            setOpenLEDModal={setOpenLEDModal}
            isPursuerView={isPursuerView}
            currentSection={currentSection}
            renderGrades={renderGrades}
            modifyComment={modifyComment}
          />
        )
      })}

      {/* ARTIFACT PREVIEW MODAL */}
      <MCEArtifactPreviewModal
        classes={classes}
        openLEDModal={openLEDModal}
        setOpenLEDModal={setOpenLEDModal}
        formattedInfo={formattedInfo}
        setFormattedInfo={setFormattedInfo}
        artDetails={artDetails}
        setArtDetails={setArtDetails}
        navHeight={navHeight}
        currentStyle={currentStyle}
      />
    </Grid>
  )
}

export default MCEPartTwo
