const { v4: uuidv4 } = require('uuid')

const createGuid = (prefix) => {
  if (Object.values(prefixTypes).indexOf(prefix) < 0) { return false }
  const uuid = uuidv4().replace(/-/g, '')
  return `${prefix}${uuid}`
}

const prefixTypes = {
  MESSAGES: 'ME'
}

export { createGuid, prefixTypes }
