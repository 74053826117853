import React, { } from 'react'
import { SvgIcon } from '@material-ui/core'

const SaveIcon = (props) => {
  return (
    <SvgIcon viewBox='0 0 384 512' {...props}>
      <path
        id='save-icon'
        data-name='save-icon'
        d='M0 512V48C0 21.49 21.49 0 48 0h288c26.51 0 48 21.49 48 48v464L192 400 0 512z'
      />
    </SvgIcon>
  )
}

export default SaveIcon
