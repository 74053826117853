import React from 'react'
import { withRouter } from 'react-router-dom'

import { Grid, Typography, Chip, useTheme, Avatar, makeStyles, Button } from '@material-ui/core'

import { esRecordType } from '../../../../utils'

// *** Child Components:
import NoArtifactsAll from '../NoResults/NoArtifactsAll'
import NoArtifactSingle from '../NoResults/NoArtifactSingle'
import SearchArtifactCard from '../ResultCards/SearchArtifactCard'

const useStyles = makeStyles((theme) => ({
  innerCounterChip: {
    height: '16px',
    width: '16px',
    fontSize: '10px',
    marginLeft: '1em',
    fontWeight: 600,
    backgroundColor: 'white'
  }
}))

/* NOTE: If results are found, will render the three artifact type sections (with their respective results, or no results single comp.), otherwise renders the no results all comp.
  -- Parent Component: ui/Search/SearchPopper.js
    -- Child Components:
      - ../NoResults/NoArtifactsAll
      - ../NoResults/NoArtifactSingle
      - ../ResultCards/SearchArtifactCard
*/
const ArtifactResults = (props) => {
  const theme = useTheme()
  const classes = useStyles()

  // Props:
  // SearchPopper.js: artifactResults (deconstructed from searchResults passed to the parent from SearchBarNav.js)
  // SearchBarNav.js: handleReset
  const { artifactResults, handleReset, xsScreen, smScreen, handleViewAllArts, totalLEDResults, totalLWResults, totalPROResults, handleNoResultsViewAll } = props

  // *** Artifact Results Checks
  // - Checks if no results at all have been returned
  const noArtifactsReturnedCheck = !artifactResults || (artifactResults && artifactResults.LEDs && !artifactResults.LEDs.length && artifactResults.LWs && !artifactResults.LWs.length && artifactResults.PROs && !artifactResults.PROs.length)
  // - Checks if any LED results have been returned
  const ledsReturnedCheck = artifactResults && artifactResults.LEDs && Boolean(artifactResults.LEDs.length)
  // - Checks if any LW results have been returned
  const lwsReturnedCheck = artifactResults && artifactResults.LWs && Boolean(artifactResults.LWs.length)
  // - Checks if any PRO results have been returned
  const prosReturnedCheck = artifactResults && artifactResults.PROs && Boolean(artifactResults.PROs.length)

  return (
    <Grid container item direction='column' xs={11} spacing={2} style={{ maxWidth: 'none' }}>
      {
        // If there are no artifact of any type returned, render the no artifacts all message
        noArtifactsReturnedCheck
          ? (
            <NoArtifactsAll handleNoResultsViewAll={handleNoResultsViewAll} />
          ) : (
            <>
              {/* Else render all three artifact type sections: */}

              {/* LED REsults */}
              <Grid item container direction='column'>
                {/* LED Header Chip/Counter */}
                <Grid item>
                  <Chip
                    label={
                      <Grid item container direction='row' alignItems='center'>
                        <Typography variant='body1' style={{ color: 'white', fontWeight: 600 }}>Learning Experience Designs</Typography>
                        {/* Inner Counter Chip */}
                        {ledsReturnedCheck &&
                          <Avatar className={classes.innerCounterChip} style={{ color: theme.palette.pink.dark }}>{totalLEDResults}</Avatar>}
                      </Grid>
                    }
                    size='small'
                    style={{ backgroundColor: theme.palette.pink.dark }}
                  />
                </Grid>
                <Grid item container direction='column' spacing={1} style={{ marginTop: '.5em' }}>
                  {/* IF leds were returned, render the cards for the first three */}
                  {ledsReturnedCheck
                    ? (
                      <>
                        {artifactResults.LEDs.slice(0, 3).map((led) => {
                          return (
                            <Grid key={`led-result-${led.objectID}`} container item>
                              <SearchArtifactCard smScreen={smScreen} xs={xsScreen} artifact={led} handleReset={handleReset} />
                            </Grid>
                          )
                        })}
                        {/* If more than three results are returned, render a 'view all' button */}
                        {totalLEDResults > 3 &&
                          <Grid item container direction='row' justifyContent='flex-end'>
                            <Button onClick={() => handleViewAllArts(esRecordType.LE)} style={{ textTransform: 'none', fontWeight: '600', padding: 0 }}>View All...</Button>
                          </Grid>}
                      </>
                    )
                    : (
                      // Else render a section-specific 'no results' message
                      <NoArtifactSingle handleNoResultsViewAll={handleNoResultsViewAll} />
                    )}
                </Grid>
              </Grid>

              {/* LW REsults */}
              <Grid item container direction='column'>
                {/* LW Header Chip/Counter */}
                <Grid item>
                  <Chip
                    label={
                      <Grid item container direction='row' alignItems='center'>
                        <Typography variant='body1' style={{ color: 'white', fontWeight: 600 }}>Learner Work</Typography>
                        {/* Inner Counter Chip */}
                        {lwsReturnedCheck &&
                          <Avatar className={classes.innerCounterChip} style={{ color: '#1A7FA4' }}>{totalLWResults}</Avatar>}
                      </Grid>
                    }
                    size='small'
                    style={{ backgroundColor: '#1A7FA4' }}
                  />
                </Grid>
                <Grid item container direction='column' spacing={1} style={{ marginTop: '.5em' }}>
                  {/* IF lws were returned, render the cards for the first three */}
                  {lwsReturnedCheck
                    ? (
                      <>
                        {artifactResults.LWs.slice(0, 3).map((lw, i) => {
                          return (
                            <Grid key={`lw-result-${lw.objectID}-${i}`} container item>
                              <SearchArtifactCard smScreen={smScreen} xs={xsScreen} artifact={lw} handleReset={handleReset} />
                            </Grid>
                          )
                        })}
                        {/* If more than three results are returned, render a 'view all' button */}
                        {totalLWResults > 3 &&
                          <Grid item container direction='row' justifyContent='flex-end'>
                            <Button onClick={() => handleViewAllArts(esRecordType.LW)} style={{ textTransform: 'none', fontWeight: '600', padding: 0 }}>View All...</Button>
                          </Grid>}
                      </>
                    )
                    : (
                      // Else render a section-specific 'no results' message
                      <NoArtifactSingle handleNoResultsViewAll={handleNoResultsViewAll} />
                    )}
                </Grid>
              </Grid>

              {/* PRO REsults */}
              <Grid item container direction='column'>
                {/* PRO Header Chip/Counter */}
                <Grid item>
                  <Chip
                    label={
                      <Grid item container direction='row' alignItems='center'>
                        <Typography variant='body1' style={{ color: 'white', fontWeight: 600 }}>Projects</Typography>
                        {/* Inner Counter Chip */}
                        {prosReturnedCheck &&
                          <Avatar className={classes.innerCounterChip} style={{ color: '#5D40BE' }}>{totalPROResults}</Avatar>}
                      </Grid>
                    }
                    size='small'
                    style={{ backgroundColor: '#5D40BE' }}
                  />
                </Grid>
                <Grid item container direction='column' spacing={1} style={{ marginTop: '.5em' }}>
                  {/* IF pros were returned, render the cards for the first three */}
                  {prosReturnedCheck
                    ? (
                      <>
                        {artifactResults.PROs.slice(0, 3).map(pro => {
                          return (
                            <Grid key={`pro-result-${pro.objectID}`} container item>
                              <SearchArtifactCard smScreen={smScreen} xs={xsScreen} artifact={pro} handleReset={handleReset} />
                            </Grid>
                          )
                        })}
                        {/* If more than three results are returned, render a 'view all' button */}
                        {totalPROResults > 3 &&
                          <Grid item container direction='row' justifyContent='flex-end'>
                            <Button onClick={() => handleViewAllArts(esRecordType.PR)} style={{ textTransform: 'none', fontWeight: '600', padding: 0 }}>View All...</Button>
                          </Grid>}
                      </>
                    )
                    : (
                      // Else render a section-specific 'no results' message
                      <NoArtifactSingle handleNoResultsViewAll={handleNoResultsViewAll} />
                    )}
                </Grid>
              </Grid>

            </>
          )
      }
    </Grid>
  )
}

export default withRouter(ArtifactResults)
