export const defaultTargetArtifactLED = {
  actContent: '',
  createContent: '',
  engageContent: '',
  forkedFrom: '',
  investigateContent: '',
  overview: '',
  secondarySubjectID: '',
  secondarySubjectName: '',
  coreSubjectID: '',
  coreSubjectName: '',
  artifactID: '',
  learnerWorkID: '',
  artifactType: '',
  stateStandards: '',
  techNeeded: '',
  title: '',
  userID: '',
  workType: '',
  completionTime: 0,
  createdAt: 0,
  isDraft: 0,
  isForSubmission: 0,
  isPrivate: 0,
  grades: [],
  ISTEStandards: [],
  attachments: [],
  links: [],
  commentList: [],
  submissionStatus: null,
  unviewedComments: {},
  likeCount: 0,
  saveCount: 0,
  publicCommentCount: 0,
  adminSelected: 0,
  activeFeatured: 0,
  duplicates: [],
  originalArtifact: null,
  backgroundKey: null,
  backgroundObj: {},
  LEDBackgroundPath: '',
  targets: [],
  learnerWorkDetails: null
}

export const defaultEditLEDinfo = {
  gradesToAdd: [],
  gradesToDelete: [],
  linksToAdd: [],
  linksToUpdate: [],
  linksToDelete: [],
  attachmentsToAdd: [],
  attachmentsToDelete: [],
  targetsToAdd: [],
  targetsToUpdate: [],
  targetsToDelete: [],
  ISTEStandardsToAdd: [],
  ISTEStandardsToDelete: []
}

export const defaultTargetArtifactLW = {
  artifactID: '',
  authorID: '',
  learnerWorkTitle: '',
  workType: '',
  notes: '',
  dateCompleted: 0,
  coreSubjectID: '',
  coreSubjectName: '',
  isPrivate: 0,
  artifactType: '',
  grades: [],
  attachments: [],
  links: [],
  commentList: [],
  submissionStatus: null,
  unviewedComments: {},
  ledIDs: [],
  isDraft: 0,
  isForSubmission: 0,
  likeCount: 0,
  saveCount: 0,
  publicCommentCount: 0
}

export const defaultEditLWInfo = {
  ledIDsToAdd: [],
  ledIDsToDelete: [],
  gradesToAdd: [],
  gradesToDelete: [],
  linksToAdd: [],
  linksToUpdate: [],
  linksToDelete: [],
  attachmentsToAdd: [],
  attachmentsToDelete: []
}

export const defaultTargetArtifactPRO = {
  artifactID: '',
  authorID: '',
  artifactType: '',
  projectTitle: '',
  projectDescription: '',
  projectLength: 0,
  projectLEDs: [],
  projectLWs: [],
  commentList: [],
  isPrivate: 0,
  submissionStatus: null,
  unviewedComments: {},
  isDraft: 0,
  isForSubmission: 0,
  likeCount: 0,
  saveCount: 0,
  publicCommentCount: 0
}

export const defaultEditProInfo = {
  ledsToAdd: [],
  ledsToDelete: [],
  lwsToAdd: [],
  lwsToDelete: []
}

export const defaultTargetArtifactEFR = {
  artifactID: '',
  authorID: '',
  artifactType: '',
  resourceName: '',
  resourceType: '',
  resourceAudience: '',
  resourceLink: '',
  workType: '',
  resourceDescription: '',
  startDate: 0,
  endDate: 0,
  creatorName: '',
  isPrivate: 0,
  isForSubmisison: 0,
  creatorProfileID: '',
  creatorAvatarPath: '',
  createdAt: 0,
  updatedAt: 0,
  updatedBy: '',
  editorName: '',
  editorProfileID: '',
  editorAvatarPath: '',
  bannerImagePath: '',
  isDraft: 0,
  isActive: 0,
  commentList: []
}

export const artifactTypes = {
  LED: 'Learning Experience Design',
  LW: 'Learner Work',
  PRO: 'Project',
  PROJ: 'Project',
  EFR: 'Ed Farm Resource'
}

export const exploreArtifactTypes = {
  LED: 'learning-experience-designs',
  LW: 'learner-works',
  PRO: 'projects',
  EFR: 'efr'
}

export const artifactAbbrev = {
  LED: 'LED',
  LW: 'LW',
  PRO: 'PRO',
  EFR: 'EFR'
}

export const esRecordTypeFormat = {
  LE: 'Learning Experience Design',
  LW: 'Learner Work',
  PR: 'Project',
  EF: 'Ed Farm Resource'
}

export const esRecordType = {
  LE: 'LE',
  LW: 'LW',
  PR: 'PR',
  EF: 'EF'
}

export const statusTypes = {
  needReview: 'NEEDS_REVIEW',
  approved: 'APPROVED',
  denied: 'DENIED',
  needsChanges: 'NEEDS_CHANGES'
}

export const statusLabels = {
  [statusTypes.needReview]: 'Needs Review',
  [statusTypes.approved]: 'Approved',
  [statusTypes.denied]: 'Denied',
  [statusTypes.needsChanges]: 'Changes Requested'
}

export const userRoles = {
  TEACHER_FELLOW: 'Teacher Fellow',
  ADMIN_ROLE: 'Admin',
  INNOVATION_FELLOW: 'Innovation Fellow'
}

export const userRoleIDs = {
  TEACHER_FELLOW: 'ROb4efa6ca393d477d9a857d814c8c61b9',
  ADMIN_ROLE: 'RO1f0da5ee348c41f4ac73ec54762dbe16',
  INNOVATION_FELLOW: 'ROf4e5f69458794e6a8d7671657303cf3e'
}

// inFellowType 'other' ID
export const inFellowOtherTypeID = 'FTff0a83f0069b4e8db84c3739f9293ce5'

// api resource types
export const efrSubTypes = {
  EFR_EVENT: 'edfarm_event',
  EFR_WEBINAR: 'edfarm_webinar',
  EFR_VIDEO: 'edFarm_video'
}

export const efrSubTypesFormat = {
  edfarm_event: 'Event',
  edfarm_webinar: 'Webinar',
  edFarm_video: 'Instructional Video'
}

// api resource audience types
export const gradeCategories = {
  ELEMENTARY: 'ELEMENTARY',
  MIDDLE_SCHOOL: 'MIDDLE_SCHOOL',
  HIGH_SCHOOL: 'HIGH_SCHOOL',
  PRE_SCHOOL: 'PRE_SCHOOL',
  KINDERGARTEN: 'KINDERGARTEN',
  K_12: 'K_12',
  K_6: 'K_6'
}

export const gradeCategoriesFormat = {
  ELEMENTARY: 'Elementary',
  MIDDLE_SCHOOL: 'Middle School',
  HIGH_SCHOOL: 'High School',
  PRE_SCHOOL: 'Pre-School',
  KINDERGARTEN: 'Kindergarten',
  K_12: 'K-12',
  K_6: 'K-6'
}

export const modalTypes = {
  ABOUT: 'about',
  SKILLS: 'skills',
  TECH: 'tech',
  PROFILE: 'profile',
  ARTIFACT: 'artifact'
}

export const explorerTypes = {
  FEATURED: 'featured',
  ALL_POSTS: 'all-posts',
  FEATURED_RESOURCES: 'featured-resources',
  USERS: 'users'
}

// Actions used when updating the status of a featured LED artifact (admins only)
export const featuredLEDActions = {
  // Removes both activeFeatured AND adminSelected status
  removeSelected: 'removeSelected',
  // Sets the artifact to admin selected only (used when adding a featured LED to the slected list, and when deactivating its activeFeatured status)
  selectedOnly: 'selectedOnly',
  // Used when setting the activeFeatured status on an LED that has already been chosen as adminSelected
  activateSelected: 'activateSelected'
}

export const gradeIDCategories = {
  Elementary: [
    'GRd173b057d21a4f05921951f5d98c2612',
    'GRd173b057d21a4f05921951f5d98c2611',
    'GRd173b057d21a4f05921951f5d98c2614',
    'GRd173b057d21a4f05921951f5d98c2615',
    'GRd173b057d21a4f05921951f5d98c2616',
    'GRd173b057d21a4f05921951f5d98c2617',
    'GRd173b057d21a4f05921951f5d98c2618',
    'GRd173b057d21a4f05921951f5d98c2619'
  ],
  Middle_School: [
    'GRd173b057d21a4f05921951f5d98c2612',
    'GRd173b057d21a4f05921951f5d98c2620',
    'GRd173b057d21a4f05921951f5d98c2621'
  ],
  High_School: [
    'GRd173b057d21a4f05921951f5d98c2612',
    'GRd173b057d21a4f05921951f5d98c2622',
    'GRd173b057d21a4f05921951f5d98c2623',
    'GRd173b057d21a4f05921951f5d98c2624',
    'GRd173b057d21a4f05921951f5d98c2625'
  ],
  Pre_School: [
    'GRd173b057d21a4f05921951f5d98c2610'
  ],
  Kindergarten: [
    'GRd173b057d21a4f05921951f5d98c2612',
    'GRd173b057d21a4f05921951f5d98c2611',
    'GRd173b057d21a4f05921951f5d98c2613'
  ],
  K_12: 'GRd173b057d21a4f05921951f5d98c2612',
  K_6: 'GRd173b057d21a4f05921951f5d98c2611'
}

// Used to check grade categories by name in the absence of grade ids
export const gradeNameCategories = {
  Elementary: [
    'K - 12',
    'K - 6',
    'Grade 1',
    'Grade 2',
    'Grade 3',
    'Grade 4',
    'Grade 5',
    'Grade 6'
  ],
  Middle_School: [
    'K - 12',
    'Grade 7',
    'Grade 8'
  ],
  High_School: [
    'K - 12',
    'Grade 9',
    'Grade 10',
    'Grade 11',
    'Grade 12'
  ],
  Pre_School: [
    'Pre-K'
  ],
  Kindergarten: [
    'K - 12',
    'K - 6',
    'Kindergarten'
  ],
  K_12: 'K - 12',
  K_6: 'K - 6'
}

export const adminPageOptions = {
  TEACHER_FELLOWS: 'teacher-fellows',
  INNOVATION_FELLOWS: 'innovation-fellows',
  FEATURED_RESOURCES: 'featured-resources',
  FEATURED_LEDS: 'featured-leds',
  SIGN_UPS: 'sign-ups',
  ARTIFACTS: 'artifacts',
  COHORTS: 'cohorts',
  SCHOOLS: 'schools',
  DISTRICTS: 'districts',
  SUBJECTS: 'subjects',
  CERTS: 'certs',
  ADMIN_USERS: 'admin-users',
  MCE_ASSESSORS: 'mce-assessors',
  MCE_EVALUATIONS: 'mce-evaluations',
  MCE_BADGES: 'mce-badges',
  MICRO_CREDENTIALS: 'micro-credentials'
}

// Default errors for components
export const defaultErrors = {
  cohortsAdminErr: {
    notComplete: false,
    endBeforeStart: false
  },
  adminCertsErr: {
    notComplete: false
  },
  adminSubjectsErr: {
    notComplete: false
  },
  loginFormsErr: {
    fieldsMissingError: false,
    noEmailFoundError: false,
    emailUnavailableError: false,
    sendError: false,
    userAlreadyLoggedIn: false
  },
  registerFormErr: {
    fieldsMissingError: false,
    specialCharacters: false,
    notCheckedError: false
  },
  validateEmailFormErr: {
    fieldsMissingError: false,
    emailValidError: false,
    notCheckedError: false
  },
  aboutModalErr: {
    aboutUpdateError: false,
    socialMediaError: false,
    socialType: '',
    otherTypeError: false
  },
  passwordResetModalErr: {
    fieldsMissingError: false,
    passwordUpdateError: false,
    passwordMatchError: false
  },
  reviewSideMenuErr: {
    notComplete: false
  },
  passwordResetErr: {
    fieldsMissingError: false,
    passwordResetError: false,
    validationKeyError: false,
    missingKeys: false,
    emailNotFound: false
  }
}

// Checks if an input does not contain either special characters or only blank spaces
export const validInputCheck = {
  specialChars: /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
  onlyBlankSpaces: /^\s*$/
}

// Content options for LED Summary Page
export const contentOptions = {
  GRADES: 'grades',
  SUBJECT: 'coreSubject',
  COMPLETED_AT: 'completionTime',
  WORK_TYPE: 'workType',
  TECH: 'techNeeded',
  ISTE_STANDARDS: 'ISTEStandards',
  OVERVIEW: 'overview',
  TARGETS: 'targets',
  STATE_STANDARDS: 'stateStandards',
  ENGAGE: 'engageContent',
  INVESTIGATE: 'investigateContent',
  CREATE: 'createContent',
  ACT: 'actContent',
  ATTACHMENTS: 'attachments',
  LINKS: 'links'
}

export const bgColors = {
  DEFAULT: 'default',
  BLUE: 'blue',
  ORANGE: 'orange',
  GREEN: 'green'
}

export const bgTypes = {
  CIRCLES: 'circles',
  SQUARES: 'squares',
  NATURE: 'nature'
}

export const mceViews = {
  SUMMARY: 'summary',
  PURSUE: 'pursue',
  ASSESS: 'assess',
  RESULTS: 'results',
  DASHBOARD: 'dashboard'
}

export const mceSections = {
  PART1: 'P1',
  PART2: 'P2',
  PART3: 'P3',
  PART4: 'P4'
}

export const rubricValues = {
  PROFICIENT: 'P',
  BASIC: 'B',
  DEVELOPING: 'D'
}

export const attemptStatuses = {
  DRAFT: 'D',
  SUBMITTED: 'S',
  UNDER_REVIEW: 'U',
  PASS: 'P',
  FAIL: 'F'
}

export const mceStatusLabels = {
  D: 'In Progress',
  S: 'Submitted',
  U: 'Under Review',
  P: 'Achieved!',
  F: 'Failed'
}

export const workSampleTypes = {
  LED: 'L',
  UPLOAD: 'U'
}

export const defaultAttemptDetailsForm = {
  attemptID: null,
  mceID: null,
  userID: null,
  assessorID: null,
  attemptStatus: 'D',
  attemptCount: 1,
  attemptCommentID: null,
  attemptComment: null,
  P1: [
    {
      questionID: null,
      partNum: 1,
      answerID: null,
      questionAnswer: null,
      assessorGrade: null,
      attemptCommentID: null,
      attemptComment: null
    }
  ],
  P2: [
    {
      workSampleID: null,
      sampleType: 'L',
      attemptSampleID: null,
      artifactID: null,
      fileName: null,
      fileType: null,
      s3Key: null,
      assessorGrade: null,
      title: null,
      isDraft: null,
      attemptCommentID: null,
      attemptComment: null,
      rubricResponses: [
        {
          rubricID: null,
          rubricResponseID: null,
          assessorSelection: null
        }
      ]
    }
  ],
  P3: [
    {
      questionID: null,
      partNum: 3,
      answerID: null,
      questionAnswer: null,
      assessorGrade: null,
      attemptCommentID: null,
      attemptComment: null
    }
  ]
}

export const defaultMCEDetails = {
  P1: [],
  P2: [],
  P3: [],
  keyMethod: '',
  methodComponents: '',
  resources: '',
  stackName: '',
  supportResearch: '',
  title: '',
  stackID: '',
  isDraft: 1,
  isActiveVersion: 0,
  versionNum: 1,
  versionHistory: [],
  mceID: '',
  omceID: '',
  createdAt: 0,
  createdBy: '',
  updatedAt: 0,
  updatedBy: '',
  deactivatedAt: 0,
  deactivatedBy: '',
  publishedAt: 0,
  publishedBy: '',
  deletedAt: 0
}

export const mceActionTypes = {
  PUBLISHED: 'PUBLISHED',
  RESTORED: 'RESTORED',
  DEACTIVATED: 'DEACTIVATED',
  REACTIVATED: 'REACTIVATED',
  MODIFIED_TITLE: 'MODIFIED_TITLE',
  MODIFIED_KEY_METHOD: 'MODIFIED_KEY_METHOD',
  MODIFIED_METHOD_COMP: 'MODIFIED_METHOD_COMP',
  MODIFIED_RESOURCES: 'MODIFIED_RESOURCES',
  MODIFIED_RESEARCH: 'MODIFIED_RESEARCH',
  MODIFIED_QUESTION: 'MODIFIED_QUESTION',
  MODIFIED_WORKSAMP: 'MODIFIED_WORKSAMP',
  MODIFIED_BADGE: 'MODIFIED_BADGE',
  MODIFIED_RUBRIC: 'MODIFIED_RUBRIC',
  ADDED_QUESTION: 'ADDED_QUESTION',
  ADDED_WORKSAMP: 'ADDED_WORKSAMP',
  ADDED_RUBRIC: 'ADDED_RUBRIC',
  REMOVED_QUESTION: 'REMOVED_QUESTION',
  REMOVED_WORKSAMP: 'REMOVED_WORKSAMP',
  REMOVED_RUBRIC: 'REMOVED_RUBRIC'
}

export const translatedActionTypes = {
  PUBLISHED: 'Published',
  RESTORED: 'Restored',
  DEACTIVATED: 'Deactivated Credential',
  REACTIVATED: 'Reactivated at',
  MODIFIED_TITLE: 'Modified Title',
  MODIFIED_KEY_METHOD: 'Modified Key Method',
  MODIFIED_METHOD_COMP: 'Modified Method Components',
  MODIFIED_RESOURCES: 'Modified Resources',
  MODIFIED_RESEARCH: 'Modified Research',
  MODIFIED_QUESTION: 'Modifed a Question',
  MODIFIED_WORKSAMP: 'Modified a Work Sample',
  MODIFIED_RUBRIC: 'Modified a Rubric Row',
  MODIFIED_BADGE: 'Modified Badge',
  ADDED_QUESTION: 'Added a Question',
  ADDED_WORKSAMP: 'Added a Work Sample',
  ADDED_RUBRIC: 'Added a Rubric Row',
  REMOVED_QUESTION: 'Removed a Question',
  REMOVED_WORKSAMP: 'Removed a Work Sample',
  REMOVED_RUBRIC: 'Removed a Rubric Row'
}

export const pageUnavailableTypes = {
  USER_UNAVAILABLE: 'user'
}

export const loginForms = {
  MAIN_LOGIN: 'main-login',
  FORGOT_PASSWORD: 'forgot-password'
}

export const updateUserActionTypes = {
  UPDATE_EMAIL: 'updateEmail',
  DELETE_USER: 'deleteUser',
  UPDATE_USER: 'updateUser'
}
