import React, { useState } from 'react'

import {
  Grid, Paper, Typography, IconButton, useTheme, OutlinedInput, Button,
  Dialog, DialogTitle, DialogActions, DialogContent, TextField, InputAdornment, Divider,
  Fab
} from '@material-ui/core'

import { DeviceHub, HelpOutlineRounded } from '@material-ui/icons'

import { ArtifactComment } from '../../tools'

const ProjectDetails = (props) => {
  const { classes, newProjectForm, setNewProjectForm, styles, sectionComments } = props
  const theme = useTheme()

  const [exampleOpen, setExampleOpen] = useState(false)

  // Check value and set to max 52 if value is higher. Also handle input value if numbers removed to enter manually.
  const handleWeeksChange = (value) => {
    if (Number(value) > 52) {
      setNewProjectForm({ ...newProjectForm, projectLength: parseInt(52) })
    } else if (!value) {
      setNewProjectForm({ ...newProjectForm, projectLength: '' })
    } else {
      setNewProjectForm({ ...newProjectForm, projectLength: parseInt(value) })
    }
  }

  return (
    <Paper className={classes.paperRoot}>
      <Grid container direction='column'>
        {/* Step Header */}
        <Grid container item direction='row' spacing={1} style={{ marginBottom: '1em' }}>
          <Grid item>
            <DeviceHub />
          </Grid>
          <Grid item>
            <Typography variant='h4'>Project Details</Typography>
          </Grid>
          <Grid item>
            <IconButton size='small' style={{ paddingTop: 0, paddingLeft: 0 }} onClick={() => setExampleOpen(true)}>
              <HelpOutlineRounded style={{ fontSize: '19px', color: '#3d2b79' }} />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item container style={{ marginBottom: '.5em' }}>
          <Typography variant='caption'><span style={{ color: 'red', fontSize: '14px' }}>*</span> indicates a required field.</Typography>
        </Grid>

        {/* Notes */}
        {sectionComments && sectionComments.length !== 0 && sectionComments.findIndex(x => x.section === 'notes') !== -1 &&
          <>
            <Grid item container direction='column' style={{ marginBottom: '2em' }}>
              <Grid item container direction='row' alignContent='center'>
                <Grid item>
                  <Typography variant='body1' style={{ fontWeight: '600' }}>Notes</Typography>
                </Grid>
              </Grid>
              <Grid item container direction='column' spacing={2} style={{ marginTop: '1em' }}>
                {sectionComments && sectionComments.length !== 0 &&
                  sectionComments.map(comment => {
                    if (comment.section === 'notes') {
                      return (
                        <ArtifactComment
                          key={comment.commentID}
                          classes={classes}
                          commentInfo={comment}
                          artifactID={comment.artifactID}
                          section='notes'
                          styles={styles}
                          fromCreate
                        />
                      )
                    }
                    return ''
                  })}
              </Grid>
            </Grid>
          </>}

        {/* Project Name */}
        <Grid item container direction='column' style={{ marginBottom: '2em' }}>
          <Grid item>
            <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}><span style={{ color: 'red', fontSize: '14px' }}>*</span> Project Name</Typography>
          </Grid>
          <Grid item style={{ paddingRight: '.5em' }}>
            <OutlinedInput
              variant='outlined'
              fullWidth
              inputProps={{
                maxLength: 128
              }}
              margin='dense'
              value={newProjectForm.projectTitle}
              onChange={(e) => setNewProjectForm({ ...newProjectForm, projectTitle: e.target.value })}
              placeholder='Name your project...'
              classes={{ input: classes.inputPlaceholder }}
            />
          </Grid>
          <Grid item>
            <Typography variant='caption' style={{ color: '#adadad', paddingLeft: '.3em' }}>{`${newProjectForm && newProjectForm.projectTitle && newProjectForm.projectTitle.length ? newProjectForm.projectTitle.length : 0}/ 128`}</Typography>
          </Grid>
        </Grid>

        {/* Project Description */}
        <Grid item container direction='column' style={{ marginBottom: '2em' }}>
          <Grid item>
            <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}><span style={{ color: 'red', fontSize: '14px' }}>*</span>Description</Typography>
          </Grid>
          <Grid item style={{ paddingRight: '.5em' }}>
            <OutlinedInput
              variant='outlined'
              fullWidth
              multiline
              rows={4}
              inputProps={{
                maxLength: 500
              }}
              margin='dense'
              value={newProjectForm.projectDescription}
              onChange={(e) => setNewProjectForm({ ...newProjectForm, projectDescription: e.target.value })}
              placeholder='Tell us what your project is about...'
              classes={{ input: classes.inputPlaceholder }}
            />
          </Grid>
          <Grid item>
            <Typography variant='caption' style={{ color: '#adadad', paddingLeft: '.3em' }}>{`${newProjectForm && newProjectForm.projectDescription && newProjectForm.projectDescription.length ? newProjectForm.projectDescription.length : 0}/ 500`}</Typography>
          </Grid>
        </Grid>

        {/* Completion Time */}
        <Grid item container direction='column' style={{ marginBottom: '2em' }}>
          <Grid item>
            <Typography variant='body1' style={{ fontWeight: '600' }}><span style={{ color: 'red', fontSize: '14px', lineHeight: 1 }}>*</span> Project Length</Typography>
            <Typography variant='caption' style={{ color: theme.palette.grey.dark }}>What's the length of the project in weeks?</Typography>
          </Grid>
          <Grid item container direction='row' justifyContent='flex-start' style={{ paddingRight: '.5em', maxWidth: '60%' }}>
            <Grid item xs={12} style={{ paddingLeft: '.3em' }}>
              <TextField
                type='number'
                size='small'
                inputProps={{
                  min: 0,
                  max: 52
                }}
                InputProps={{ endAdornment: <InputAdornment position='end' style={{ borderBottom: 'none' }}>week(s)</InputAdornment> }}
                classes={{ root: classes.textField }}
                style={{ width: '7.3em' }}
                value={newProjectForm.projectLength}
                onChange={(e) => handleWeeksChange(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Comments */}
        {sectionComments && sectionComments.length !== 0 &&
          <>
            <Divider />
            <Grid item container direction='column' style={{ marginBottom: '2em', marginTop: '1em' }}>
              <Grid item container direction='row' alignContent='center'>
                <Grid item>
                  <Typography variant='body1' style={{ fontWeight: '600' }}>Comments</Typography>
                </Grid>
                <Grid item style={{ marginLeft: '.5em' }}>
                  <Fab style={{ height: '15px', width: '15px', minHeight: '15px', backgroundColor: styles.mainColor, boxShadow: 'none' }}>
                    <Typography variant='body1' style={{ fontSize: '10px', color: 'white', fontWeight: 600 }}>{sectionComments ? sectionComments.filter(comment => comment.section === 'details').length : 0}</Typography>
                  </Fab>
                </Grid>
              </Grid>
              <Grid item container direction='column' spacing={2} style={{ marginTop: '1em' }}>
                {sectionComments && sectionComments.length !== 0 &&
                  sectionComments.map(comment => {
                    if (comment.section !== 'notes') {
                      return (
                        <ArtifactComment
                          key={comment.commentID}
                          classes={classes}
                          commentInfo={comment}
                          artifactID={comment.artifactID}
                          section='details'
                          styles={styles}
                          fromCreate
                        />
                      )
                    }
                    return ''
                  })}
              </Grid>
            </Grid>
          </>}
      </Grid>

      {/* Example Dialog */}
      <Dialog
        open={exampleOpen}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle disableTypography>
          <Typography variant='h4' style={{ margin: '.5em' }}>Example: Artifact Details</Typography>
        </DialogTitle>
        <DialogContent
          dividers
        >
          <Grid container direction='column' style={{ margin: '1em' }}>
            <Grid item container direction='column' style={{ marginTop: 0 }}>
              <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}>Project Name</Typography>
              <Typography variant='body1' style={{ fontWeight: '400', marginTop: '.5em' }}>HBCU Digital Ad Challenge</Typography>
              <Divider variant='middle' style={{ marginLeft: 0 }} />
            </Grid>
            <Grid item container direction='column' style={{ marginTop: '2em', paddingRight: '2em' }}>
              <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}>Description</Typography>
              <Typography variant='body1' style={{ fontWeight: '400', marginTop: '.5em', border: `1px solid ${theme.palette.grey.medium}`, borderRadius: '4px', padding: '.5em' }}>
                During this project, students will learn about Alabama's history of Civil Rights, Women's Rights, and notable inventors.
              </Typography>
            </Grid>
            <Grid item container direction='column' style={{ marginTop: '2em', marginBottom: '2em' }}>
              <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}>Completion Time</Typography>
              <Typography variant='body1' style={{ fontWeight: '400', marginTop: '.5em' }}>12 weeks</Typography>
              <Divider variant='middle' style={{ marginLeft: 0 }} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='primary'
            style={{
              margin: '.5em 1em',
              fontWeight: '600'
            }}
            onClick={() => setExampleOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}

export default ProjectDetails
