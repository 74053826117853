import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import {
  Grid, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Button, useTheme,
  IconButton, Divider
} from '@material-ui/core'
import { CameraAltRounded, CloseRounded } from '@material-ui/icons'

import { uploadType, uploadFileFormatter } from '../../../utils'
import { mceActionTypes } from '../../../utils/variables'

const UploadBadgeModal = (props) => {
  const { classes = {}, badgeUploadOpen, handleCloseBadgeUploadModal = () => { }, handleOriginStatus = () => { }, adminPageView = false, badgeUploadInfo = {} } = props

  const { mceDetails = {} } = useSelector(state => state.microcredentials)
  const theme = useTheme()

  const [newImage, setNewImage] = useState('')
  const [newImageType, setNewImageType] = useState('')

  const [fileSizeError, setFileSizeError] = useState(false)
  const [fileExtensionError, setFileExtensionError] = useState(false)

  const fileErrorReset = () => { setFileSizeError(false); setFileExtensionError(false) }

  const handleImageUpload = async (e, file) => {
    e.preventDefault()

    // Reset errors on initial upload to clear out previous
    fileErrorReset()

    // error handlers
    const handleFileSizeError = (error) => setFileSizeError(error)
    const handleFileExtensionError = (error) => setFileExtensionError(error)

    const type = uploadType.PROFILE_IMAGE
    const attachments = []
    const imageResult = await uploadFileFormatter(file, type, attachments, handleFileSizeError, handleFileExtensionError)

    if (imageResult) {
      const { imageData, fileExtension } = imageResult
      setNewImage(imageData)
      setNewImageType(fileExtension)
    }
  }

  const handleClose = () => {
    setNewImage('')
    setNewImageType('')
    setFileSizeError(false)
    setFileExtensionError(false)
    handleCloseBadgeUploadModal()
  }

  const handleImageSave = () => {
    const adminViewBadgeObj = adminPageView && Object.keys(badgeUploadInfo).length ? { newImage, newImageType, mceID: badgeUploadInfo?.mceID, omceID: badgeUploadInfo?.omceID } : { newImage, newImageType }
    handleOriginStatus(mceActionTypes.MODIFIED_BADGE, null, adminViewBadgeObj)
    handleClose()
  }

  return (
    <Dialog
      open={badgeUploadOpen}
      onClose={handleClose}
      fullWidth
      maxWidth='sm'
      TransitionProps={{
        onEnter: () => {
          if (!adminPageView) {
            if (mceDetails?.badgeUrl) { setNewImage(mceDetails?.badgeUrl) }
          } else {
            if (badgeUploadInfo?.badgeUrl) { setNewImage(badgeUploadInfo?.badgeUrl) }
          }
        }
      }}
      disableEnforceFocus
    >
      <DialogTitle disableTypography>
        <Grid container direction='row' justifyContent='space-between' alignItems='center' style={{ paddingTop: '0.5em' }}>
          <Grid item>
            <Typography
              variant='h4'
              style={{ textTransform: 'none', color: theme.palette.purple.darkest, fontSize: '1.3rem' }}
            >
              Edit Badge
            </Typography>
          </Grid>

          <Grid item>
            <IconButton style={{ padding: '0px' }} onClick={handleClose}>
              <CloseRounded className={classes.customizedButton} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider style={{ marginTop: '0.3em' }} />
      <DialogContent className={classes.dialogContent}>
        <Grid
          item
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          style={{ padding: '1em' }}
        >
          {newImage ? (
            <Grid item>
              <img src={newImage} alt='badge-upload' style={{ height: '13em' }} />
            </Grid>
          ) : (
            'adasda'
          )}
        </Grid>
        <Grid item container justifyContent='center'>
          <input
            accept='image/png'
            style={{ display: 'none' }}
            id='raised-button-file'
            multiple
            type='file'
            onChange={(e) => { handleImageUpload(e, e.target.files) }}
          />
          <label htmlFor='raised-button-file'>
            <Button
              startIcon={<CameraAltRounded />}
              style={{
                color: theme.palette.purple.darkest,
                textTransform: 'none',
                fontWeight: 600
              }}
              component='span'
            >
              Upload Badge
            </Button>
          </label>
        </Grid>
        <Grid item container justifyContent='center'>
          <Typography variant='caption' style={{ fontWeight: 600 }}>
            <span style={{ color: 'red' }}>*</span> Only PNG files are accepted
          </Typography>
        </Grid>
        {fileSizeError &&
          <Grid item container direction='row' justifyContent='center'>
            <Typography variant='caption' style={{ color: 'red' }}>The selected file is over the max file size of 3MB.</Typography>
          </Grid>}

        {fileExtensionError &&
          <Grid item container direction='row' justifyContent='center'>
            <Typography variant='caption' style={{ color: 'red' }}>The selected file type is not allowed.</Typography>
          </Grid>}
      </DialogContent>
      <DialogActions>
        <Grid item container justifyContent='flex-end' style={{ padding: '0.6em' }} spacing={1}>
          <Grid item>
            <Button onClick={handleClose} color='primary'>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant='contained'
              color='primary'
              disabled={fileSizeError || fileExtensionError}
              style={{ fontWeight: 600, textTransform: 'capitalize' }}
              onClick={handleImageSave}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default UploadBadgeModal
