import { trackPromise } from 'react-promise-tracker'
import { parseResponse } from '../../lib'
import {
  GET_MCE_LIST, MCE_DETAILS, DATA_REQUEST, DATA_SUCCESS, DATA_ERROR,
  RESET_CURRENT_MCE_DETAILS, GET_MCE_STACKS
} from '../types'

import log from 'loglevel'

const api = process.env.REACT_APP_API_URL

export const getMCEList = () => {
  return async (dispatch, getState) => {
    const { token } = getState().auth

    dispatch({ type: DATA_REQUEST, payload: { type: GET_MCE_LIST } })

    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }

    const response = await trackPromise(window.fetch(`${api}/v1/microcredentials`, options))
    const parsedResponse = parseResponse(response, dispatch)
    if (!parsedResponse) { return false }

    if (parsedResponse.error) {
      log.info(response.status)

      dispatch({ type: DATA_ERROR, payload: { type: GET_MCE_LIST } })
    } else {
      const { data } = await response.json()

      dispatch({ type: DATA_SUCCESS, payload: { type: GET_MCE_LIST } })

      dispatch({ type: GET_MCE_LIST, payload: data })
    }
  }
}

export const getMCEStacks = (fullDataBool, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    const { token } = getState().auth

    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }

    const response = await trackPromise(window.fetch(`${api}/v1/microcredentials/stacks?fullData=${fullDataBool}`, options))
    const parsedResponse = parseResponse(response, dispatch)
    if (!parsedResponse) { return false }

    if (parsedResponse.error) {
      log.info(response.status)

      fireFailure()
    } else {
      const { data } = await response.json()

      dispatch({ type: GET_MCE_STACKS, payload: data })
      fireSuccess()
    }
  }
}

export const getMCEDetails = ({ mceID, includeHistoryBool = false, noDispatchBool = false }, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token } = getState().auth

      let historyQuery = ''
      if (includeHistoryBool) {
        historyQuery = '?includeVersionHistory=true'
      }

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${api}/v1/microcredentials/${mceID}${historyQuery}`, options))
      const parsedResponse = parseResponse(response)

      if (parsedResponse && !parsedResponse.error) {
        const { data } = await response.json()
        const rtrnData = noDispatchBool ? data : mceID

        if (!noDispatchBool) {
          dispatch({
            type: MCE_DETAILS,
            payload: data
          })
        }

        fireSuccess(rtrnData)
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const resetMCEDetails = () => {
  return async function (dispatch) {
    dispatch({ type: RESET_CURRENT_MCE_DETAILS })
  }
}

export const postMCEVersion = (mceDetails, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async function (dispatch, getState) {
    try {
      const { token } = getState().auth
      const body = JSON.stringify({ ...mceDetails })

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/v1/microcredentials`, options))
      const parsedResponse = parseResponse(response)

      if (parsedResponse && !parsedResponse.error) {
        fireSuccess()
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const updateMCEOriginStatus = (mceInfo, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async function (dispatch, getState) {
    try {
      const { token, userID } = getState().auth
      const { mceID, action } = mceInfo
      const body = JSON.stringify({ ...mceInfo, userID })

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/v1/microcredentials/${mceID}`, options))
      const parsedResponse = parseResponse(response)

      if (parsedResponse && !parsedResponse.error) {
        fireSuccess(action, mceID)
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const updateMCEStack = (mceInfo, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async function (dispatch, getState) {
    try {
      const { token } = getState().auth
      const { stackID } = mceInfo
      const body = JSON.stringify(mceInfo)

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/v1/microcredentials/stacks/${stackID}`, options))
      const parsedResponse = parseResponse(response)

      if (parsedResponse && !parsedResponse.error) {
        fireSuccess()
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}
