export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const REGISTER_USER = 'REGISTER_USER'
export const UPDATE_EMAIL = 'UPDATE_EMAIL'
export const UPDATE_ONBOARD_LATEST = 'UPDATE_ONBOARD_LATEST'
export const REMOVE_ERROR = 'REMOVE_ERROR'
export const UPDATE_AUTH_AVATAR = 'UPDATE_AUTH_AVATAR'
export const ADD_LIKE = 'ADD_LIKE'
export const REMOVE_LIKE = 'REMOVE_LIKE'
export const ADD_SAVE = 'ADD_SAVE'
export const REMOVE_SAVE = 'REMOVE_SAVE'
export const UPDATE_ASSESSOR_STATUS = 'UPDATE_ASSESSOR_STATUS'
