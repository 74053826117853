import React, { useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import {
  Grid, withStyles, Checkbox, IconButton, Button, useTheme
} from '@material-ui/core'

import {
  ChatBubbleOutlineRounded, SentimentSatisfiedRounded, BookmarkBorderRounded,
  TurnedInRounded, SentimentSatisfiedTwoTone
} from '@material-ui/icons'

import { postArtifactLike, deleteArtifactLike, postArtifactSave, deleteArtifactSave } from '../../../redux/actions'

const CustomCheckbox = withStyles((theme) => ({
  root: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: theme.spacing(0.5)
  },
  checked: {
    color: '#29356a'
  },
  indeterminate: {
    color: 'rgba(0, 0, 0, 0.54)'
  }
}))((props) => <Checkbox color='default' {...props} />)

const SocialIconSection = (props) => {
  const {
    commentCount, artifactInfo, classes,
    onSocialButtonClick, artifactLiked, artifactSaved, publicView,
    handlePublicView, isPrivate, isDraft, submissionStatus, artifactType, type,
    fromExplore = false
  } = props

  const { likeCount, saveCount } = artifactInfo
  const theme = useTheme()
  const dispatch = useDispatch()

  const [displayLikeCount, setDisplayLikeCount] = useState(0)
  const [displaySaveCount, setDisplaySaveCount] = useState(0)

  useMemo(() => { if (likeCount) { setDisplayLikeCount(likeCount) } else { setDisplayLikeCount(0) } }, [likeCount])
  useMemo(() => { if (saveCount) { setDisplaySaveCount(saveCount) } else { setDisplaySaveCount(0) } }, [saveCount])

  const handleLikeSuccess = () => {
    const newCount = displayLikeCount + 1

    if (newCount >= 0) { setDisplayLikeCount(newCount) } else { setDisplayLikeCount(0) }
  }

  const handleDeleteLikeSuccess = () => {
    const newCount = displayLikeCount - 1

    if (newCount >= 0) { setDisplayLikeCount(newCount) } else { setDisplayLikeCount(0) }
  }

  const handleLikeArtifact = (artifact) => {
    const { artifactID: artID, authorID = '' } = artifact

    // IF the artifact has already been 'liked' by the user, then delete
    if (artifactLiked) {
      dispatch(deleteArtifactLike(artID, handleDeleteLikeSuccess))
    }

    // If The artifact has not been 'liked', then post
    if (!artifactLiked) {
      dispatch(postArtifactLike({ artifactID: artID, authorID }, handleLikeSuccess))
    }
  }

  const handleSaveSuccess = () => {
    const newCount = displaySaveCount + 1

    if (newCount >= 0) { setDisplaySaveCount(newCount) } else { setDisplaySaveCount(0) }
  }

  const handleDeleteSaveSuccess = () => {
    const newCount = displaySaveCount - 1

    if (newCount >= 0) { setDisplaySaveCount(newCount) } else { setDisplaySaveCount(0) }
  }

  const handleSaveArtifact = async (artifact) => {
    const { artifactID: artID, authorID = '' } = artifact

    // If the artifact has already been 'saved' by the user
    if (artifactSaved) {
      await dispatch(deleteArtifactSave(artID, handleDeleteSaveSuccess))
    }

    // The artifact has not been 'saved'
    if (!artifactSaved) {
      await dispatch(postArtifactSave({ artifactID: artID, authorID }, handleSaveSuccess))
    }
  }

  const disableAllButtons = !fromExplore && !publicView && (isPrivate || isDraft || (!isDraft && (!submissionStatus || (submissionStatus !== 'APPROVED' && submissionStatus !== 'NA'))))

  /*
  buttons are disabled if:
   - the artifact is private
   - the artifact is a draft
   - the artifact is not a draft, but its submission status does not exist or is not either NA or approved
  */

  return (
    <>
      {/* COMMENT BUTTON */}
      <Grid item style={{ display: publicView ? 'none' : 'flex', paddingRight: '.5em' }}>
        <Button
          disabled={Boolean(!commentCount || commentCount === 0 || disableAllButtons)}
          variant='text'
          classes={{ root: classes.socialNumbersButtonRoot }}
          startIcon={<ChatBubbleOutlineRounded style={{ fontSize: '20px' }} />}
          onClick={(e) => { e.preventDefault(); handlePublicView(artifactType) }}
        >
          {disableAllButtons ? '-' : commentCount}
        </Button>
      </Grid>
      {/* LIKE BUTTON */}
      <Grid item style={{ paddingRight: '.5em' }}>
        <IconButton
          style={{ padding: 0 }}
          onClick={(e) => { if (artifactInfo && artifactInfo.artifactID) { handleLikeArtifact(artifactInfo) } }}
          disabled={Boolean(disableAllButtons)}
        >
          {artifactLiked && !disableAllButtons ? <SentimentSatisfiedTwoTone style={{ fontSize: '20px', color: theme.palette.purple.dark }} /> : <SentimentSatisfiedRounded style={{ fontSize: '20px' }} />}
        </IconButton>
        <Button
          variant='text'
          disabled={Boolean(displayLikeCount === 0 || disableAllButtons)}
          onClick={(e) => onSocialButtonClick('Likes')}
          classes={{ root: classes.socialNumbersButtonRoot }}
          style={{ minWidth: 0 }}
        >
          {disableAllButtons ? '-' : displayLikeCount}
        </Button>
      </Grid>
      {/* SAVE BUTTON */}
      {type !== 'EFR' &&
        <Grid item>
          <CustomCheckbox
            onClick={(e) => { if (artifactInfo && artifactInfo.artifactID) { handleSaveArtifact(artifactInfo) } }}
            icon={<BookmarkBorderRounded style={{ fontSize: '20px', cursor: 'pointer' }} />}
            checkedIcon={<TurnedInRounded style={{ fontSize: '20px', cursor: 'pointer', color: theme.palette.purple.dark }} />}
            checked={Boolean(artifactSaved && !disableAllButtons)}
            disabled={Boolean(disableAllButtons)}
          />
          <Button
            variant='text'
            disabled={Boolean(displaySaveCount < 1 || disableAllButtons)}
            onClick={(e) => { onSocialButtonClick('Saves') }}
            classes={{ root: classes.socialNumbersButtonRoot }}
            style={{ minWidth: 0 }}

          >
            {disableAllButtons ? '-' : displaySaveCount}
          </Button>
        </Grid>}
    </>
  )
}

export default SocialIconSection
