import React, { useState, useEffect, useMemo } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import {
  Divider, Grid,
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, IconButton, Typography, Avatar,
  TextField, OutlinedInput,
  makeStyles, darken, alpha, InputAdornment, useTheme,
  Select, MenuItem
} from '@material-ui/core'

import { Skeleton } from '@material-ui/lab'
import {
  CloseRounded as CloseIcon, Search as SearchIcon
} from '@material-ui/icons'

import { resetProfileEditData } from '../../../redux/actions'

import { NotificationToast } from '../tools'

import { GET_PROFILE_EDIT_DATA } from '../../../redux/types'

import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  fixSpacing: {
    width: '100%',
    margin: 'auto'
  },
  paper: {
    overflowY: 'unset'
  },
  searchInput: {
    paddingLeft: '8px',
    backgroundColor: 'white'
  },
  statusSelect: {
    padding: '8px 0 8px 8px',
    color: theme.palette.grey.dark
  },
  accountAvatar: {
    width: '100%',
    height: 0,
    paddingTop: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    borderRadius: '50%'
  },
  customizedButton: {
    backgroundColor: 'none',
    color: 'grey',
    stroke: 'grey',
    strokeWidth: '2px',
    height: '2rem',
    width: '2rem',
    '&:hover': {
      stroke: '#616161'
    }
  },
  avatarBtn: {
    backgroundColor: '#efefef',
    padding: theme.spacing(0.5),
    width: '100%',
    '&:hover': {
      backgroundColor: darken('#efefef', 0.10)
    }
  },
  certBtn: {
    backgroundColor: '#efefef',
    '&:hover': {
      backgroundColor: darken('#efefef', 0.10)
    }
  },
  inputField: {
    '& .MuiInputBase-input': {
      '&.MuiOutlinedInput-input': {
        padding: theme.spacing(1),
        color: '#29356a',
        fontWeight: '600',
        fontFamily: 'Source Sans Pro'
      }
    }
  },
  autoField: {
    '& .MuiInputBase-root': {
      '&.MuiOutlinedInput-root': {
        '&.MuiAutocomplete-inputRoot': {
          padding: 0
        }
      }
    },
    '& .MuiInputBase-input': {
      '&.MuiOutlinedInput-input': {
        padding: theme.spacing(1)
      }
    }
  },
  saveBtn: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  certClose: {
    padding: theme.spacing(0.5)
  },
  addBtn: {
    backgroundColor: alpha('#E3E3E3', 0.5),
    minWidth: 0,
    height: '100%'
  },
  addCertTitle: {
    textTransform: 'none',
    color: theme.palette.text.secondary
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha('#fff', 0.15),
    '&:hover': {
      backgroundColor: alpha('#fff', 0.25)
    },
    width: '100%'
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
    color: theme.palette.purple.darkest
  },
  searchSvg: {
    color: '#000',
    zIndex: 1
  },
  inputRoot: {
    padding: `${theme.spacing(1, 0.5)} !important`
  },
  inputInput: {
    padding: `${theme.spacing(0, 4, 0, 4)} !important`,
    paddingLeft: `calc(1em + ${theme.spacing(5)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    color: theme.palette.purple.darkest,
    fontFamily: 'Source Sans Pro',
    fontWeight: '600'
  },
  inactiveContainer: {
    border: '2px dashed #adadad',
    padding: theme.spacing(1, 1, 2, 1),
    borderRadius: theme.shape.borderRadius
  },
  activeContainer: {
    border: '2px dashed #293669',
    padding: theme.spacing(1, 1, 2, 1),
    borderRadius: theme.shape.borderRadius
  },
  certPaper: {
    padding: theme.spacing(1)
  },
  certSearch: {
    color: '#4C5788'
  },
  certSelected: {
    color: '#4C5788'
  },
  certUnselected: {
    color: theme.palette.text.secondary
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  border: {
    borderBottom: '1px solid lightgray',
    width: '100%'
  },
  text: {
    marginRight: '1rem',
    marginLeft: '1rem',
    color: 'grey'
  }
}))

const EditProfile = (props) => {
  const { userDetails, isOpen, onProfileClose, loadingState, openProfile, handleDispatchSave, navHeight = 0 } = props

  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()

  const { type, loading } = loadingState

  const { certifications: certOptions = [] } = useSelector(state => state.profileData)

  // Certifcations IDs for users currently set certifcations on modal open
  const [certificationIDs, setCertificationIDs] = useState([])

  const {
    adminSchools: { schools: Schools = [], districtOptions: Districts = [] } = {}
  } = useSelector(state => state.admin)

  const [prefName, setPrefName] = useState('')

  const [certSearch, setCertSearch] = useState('')

  const [certifications, setCertifications] = useState([])
  const [certificationsToAdd, setCertificationsToAdd] = useState([])
  const [certificationsToDelete, setCertificationsToDelete] = useState([])

  // District Options and Selected District
  const districtOptions = useMemo(() => { if (!Districts) { return [] } else { return Districts } }, [Districts])
  const [selectedDistrict, setSelectedDistrict] = useState('')

  // School options (based on district) and the selected school
  const [schoolListOptions, setSchoolOptions] = useState(Schools && Schools.length ? [...Schools[0].schools] : [])
  const [selectedSchool, setSelectedSchool] = useState('')

  const handleModalClose = () => {
    onProfileClose()
    setSelectedDistrict('')
    setSelectedSchool('')
    setCertificationsToAdd([])
    setCertificationsToDelete([])
    setCertificationIDs([])
  }

  useEffect(() => { if (userDetails.nickName) { setPrefName(userDetails.nickName) } }, [userDetails.nickName])

  // Only fires when the modal is open and both selected district and selected school are === ''
  useEffect(() => {
    if (openProfile) {
      if (districtOptions && districtOptions.length) {
        // if the user's schoolDistrict has loaded and the select values are empty:
        if (userDetails.schoolDistrictID && selectedDistrict === '' && selectedSchool === '') {
          // Find the user's district in the options
          const districtIndex = districtOptions.findIndex(x => x.schoolDistrictID === userDetails.schoolDistrictID)

          if (districtIndex !== -1) {
            // If available, set the select value to the district object
            const newDistrict = districtOptions[districtIndex]
            setSelectedDistrict(newDistrict)

            // Find the list of schools associated with their school district:
            const schoolIndex = Schools.findIndex(x => x.schoolDistrictID === userDetails.schoolDistrictID)

            if (schoolIndex !== -1) {
              // If available, set the school options to the array
              const districtSchools = Schools[schoolIndex].schools
              setSchoolOptions(districtSchools)

              if (userDetails.schoolID) {
                // Set the school object to the user's schoolID
                const newSchool = districtSchools[districtSchools.findIndex(x => x.schoolID === userDetails.schoolID)]
                setSelectedSchool(newSchool)
              } else {
                /* If the user has a districtID, but they don't have a schoolID, default them to the 'I do not work at a school' option. Note that we need to find a better way to handle this value. Currently, there are 2 value types to handle the user not having a school: the 0 and the empty string. Ideally, we should only use one value type to handle these cases. */
                setSelectedSchool(0)
              }
            } else {
              /* This else statement runs if there are no schools currently in the selected district. The value of selectedSchool is set to an empty string which will render a value of 'No Schools Available'. The value of schoolOptions is set to an empty array to keep the select menu disabled in the case that there are no schools in that district. */
              setSelectedSchool('')
              setSchoolOptions([])
            }
          }
        }
      }
    }
  }, [userDetails, districtOptions, selectedDistrict, selectedSchool, openProfile, Schools])

  useEffect(() => {
    if (userDetails.certificationIDs && certOptions && certOptions.length) {
      const targetCerts = certOptions.filter((certInfo) =>
        userDetails.certificationIDs.findIndex((certKey) => certKey === certInfo.certificationID) !== -1)

      const certIDs = userDetails.certificationIDs.reduce((arr, certID) => {
        if (certID) { arr.push(certID) }
        return arr
      }, [])

      if (targetCerts && targetCerts.length) { setCertifications(targetCerts) }

      if (certIDs && certIDs.length) { setCertificationIDs(certIDs) }
    }
  }, [userDetails.certificationIDs, certOptions])

  const handleReset = () => {
    if (userDetails && userDetails.nickName) { setPrefName(userDetails.nickName) }

    setSelectedDistrict('')
    setSelectedSchool('')

    dispatch(resetProfileEditData())

    handleModalClose()
  }

  const handleOnCert = (certification) => {
    const tempCerts = [...certifications]

    if (!certification || !certification.certificationID) {
      // Shouldn't really happen
      return NotificationToast(false, 'Error with certifications, please refresh.', true)
    }

    if (tempCerts.findIndex((tempCert) => tempCert.certificationID === certification.certificationID) !== -1) {
      const newCerts = tempCerts.filter((tempCert) => tempCert.certificationID !== certification.certificationID)
      setCertifications(newCerts)
    } else {
      tempCerts.push(certification)
      setCertifications(tempCerts)
    }

    if (userDetails && userDetails.certificationIDs) {
      const { certificationIDs } = userDetails

      if (certificationIDs.findIndex((certKey) => certKey === certification.certificationID) !== -1) {
        const tempDelCerts = [...certificationsToDelete]

        if (certificationsToDelete.findIndex((certKey) => certKey === certification.certificationID) !== -1) {
          const newDelCerts = tempDelCerts.filter((tempCert) => tempCert !== certification.certificationID)
          setCertificationsToDelete(newDelCerts)
        } else {
          tempDelCerts.push(certification.certificationID)
          setCertificationsToDelete(tempDelCerts)
        }
      } else {
        const tempAddCerts = [...certificationsToAdd]

        if (certificationsToAdd.findIndex((certKey) => certKey === certification.certificationID) !== -1) {
          const newAddCerts = tempAddCerts.filter((tempCert) => tempCert !== certification.certificationID)
          setCertificationsToAdd(newAddCerts)
        } else {
          tempAddCerts.push(certification.certificationID)
          setCertificationsToAdd(tempAddCerts)
        }
      }
    }
  }

  const handleDistrictChange = (value) => {
    const index = districtOptions.findIndex(x => x.schoolDistrictID === value.schoolDistrictID)
    if (index !== -1) {
      // Sets the district to the selected option, and repopulates the school options to the new district
      const newDistrict = districtOptions[index]
      setSelectedDistrict(newDistrict)

      const districtSchoolsIndex = Schools.findIndex(x => x.schoolDistrictID === value.schoolDistrictID)
      const schoolsList = Schools[districtSchoolsIndex]

      if (schoolsList && schoolsList.schools && Boolean(schoolsList.schools.length)) {
        setSchoolOptions(schoolsList.schools)
        // If the current selected school is not in the new district, then reset it
        const schoolIndex = [...schoolsList.schools].findIndex(x => x.schoolID === selectedSchool.schoolID)
        if (schoolIndex === -1) {
          // This is set to an empty string in order to display 'Choose school...' as the default rendered value after selecting a new district.
          setSelectedSchool('')
        }
      } else {
        setSchoolOptions([])
        setSelectedSchool('')
      }
    } else {
      // Should not happen
      console.log('Can\'t find district')
    }
  }

  const handleSchoolChange = (value) => {
    const index = value && schoolListOptions && Boolean(schoolListOptions.length) ? schoolListOptions.findIndex(x => x.schoolID === value.schoolID) : -1

    if (index !== -1) {
      setSelectedSchool(value)
    } else {
      // Render the 'I do not teach at a school' option if they didn't select a school.
      setSelectedSchool(0)
    }
  }

  const handleSave = () => {
    const districtNotSelected = !selectedDistrict || (typeof selectedDistrict === 'object' && !Object.keys(selectedDistrict).length) || !selectedDistrict.schoolDistrictID

    if (districtNotSelected) {
      return NotificationToast(false, 'Missing District!', true)
    }

    const districtSchools = Schools.findIndex(x => x.schoolDistrictID === selectedDistrict.schoolDistrictID)

    const schoolNotFromDistrict = districtSchools !== -1 &&
      Schools[districtSchools].schools &&
      Schools[districtSchools].schools.length &&
      Schools[districtSchools].schools.findIndex(x => x.schoolID === selectedSchool.schoolID) === -1

    // Only show the 'selected school must be from selected district' if there is actually a school selected.
    if (selectedSchool) {
      if (schoolNotFromDistrict) {
        return NotificationToast(false, 'Selected school must be from selected district.', true)
      }
    }

    const profileInfo = {
      avatar: userDetails.profileAvatarPath,
      avatarType: '',
      isCustomAvatar: false,
      isAvatarChanged: false,
      nickName: prefName,
      schoolID: selectedSchool ? selectedSchool.schoolID : '',
      schoolDistrictID: selectedDistrict.schoolDistrictID,
      certificationsToAdd,
      certificationsToDelete,
      certifications
    }

    handleDispatchSave(profileInfo, fireSaveSuccess, fireSaveFailure)
  }

  const fireSaveSuccess = () => {
    handleModalClose()
    NotificationToast(false, 'Successfully updated user profile!')
  }

  const fireSaveFailure = () => {
    NotificationToast(true, 'Failed to update user profile')
  }

  const isSaveEnabled = userDetails && Object.keys(userDetails).length && selectedDistrict && Object.keys(selectedDistrict).length
    ? prefName !== userDetails.nickName || selectedSchool.schoolID !== userDetails.schoolID ||
    certificationsToAdd.length !== 0 || certificationsToDelete.length !== 0
    : true

  return (
    <>
      <Dialog
        fullWidth
        maxWidth='sm'
        style={{ zIndex: 11, marginTop: navHeight }}
        open={isOpen}
        disableEnforceFocus
        classes={{ paper: classes.paper }}
      >

        <DialogTitle disableTypography>
          <Grid container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid item>
              <Typography
                variant='h4'
                style={{ textTransform: 'none', color: theme.palette.purple.darkest, fontSize: '1.3rem' }}
              >
                Edit Profile
              </Typography>
            </Grid>

            <Grid item>
              <IconButton style={{ padding: '0px' }} onClick={handleReset}>
                <CloseIcon className={classes.customizedButton} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />

        <DialogContent>
          <Grid container direction='row'>
            <Grid container direction='column' item spacing={2} className={classes.fixSpacing}>

              <Grid container item direction='row'>
                <Grid container item direction='column' spacing={1} className={classes.fixSpacing}>

                  <Grid item>
                    <Typography variant='h6' style={{ textTransform: 'none', color: theme.palette.purple.darkest }}>Preferred Name</Typography>
                  </Grid>

                  <Grid item>
                    <TextField
                      variant='outlined'
                      value={prefName}
                      onChange={(e) => setPrefName(e.target.value)}
                      fullWidth
                      className={classes.inputField}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item direction='row'>
                <Grid container item direction='column' spacing={1} className={classes.fixSpacing}>

                  <Grid item>
                    <Typography variant='h6' style={{ textTransform: 'none', color: theme.palette.purple.darkest }}>School</Typography>
                  </Grid>

                  <Grid item>
                    {
                      type === GET_PROFILE_EDIT_DATA && loading === true
                        // type === ''
                        ? (
                          <>
                            <Skeleton animation='wave' height={55} width={525} style={{ marginTop: '-.5rem' }} />
                          </>
                        ) : (
                          <>
                            {districtOptions && districtOptions.length &&
                              <>
                                <Select
                                  variant='outlined'
                                  margin='dense'
                                  fullWidth
                                  displayEmpty
                                  classes={{ root: classes.searchInput, selectMenu: classes.statusSelect }}
                                  defaultValue=''
                                  value={selectedDistrict}
                                  onChange={(e) => handleDistrictChange(e.target.value)}
                                  style={{ padding: 0, marginBottom: '1em' }}
                                  renderValue={(selected) => !selected ? 'Choose District...' : selected.districtName}
                                >
                                  {Schools && Schools.length && districtOptions && districtOptions.length &&
                                    districtOptions.map(district => {
                                      return (
                                        <MenuItem key={district.schoolDistrictID} value={district}>{district.districtName}</MenuItem>
                                      )
                                    })}
                                </Select>
                                <Select
                                  variant='outlined'
                                  margin='dense'
                                  fullWidth
                                  classes={{ root: classes.searchInput, selectMenu: classes.statusSelect }}
                                  displayEmpty
                                  value={selectedSchool}
                                  onChange={(e) => handleSchoolChange(e.target.value)}
                                  style={{ padding: 0 }}
                                  disabled={schoolListOptions.length === 0}
                                  renderValue={(selected) => {
                                    /* A conditional used to determine which rendered value to show based on: 1. whether there are selections to choose from, 2. if the user selected the 'I do not work at a school' option, or 3. if they selected one of the available schools. *Note that all cases of setSelectedSchool(0) means the rendered value will be 'I do not work at a school' but the actual schoolID and schoolName are equal to null
                                    */
                                    if (typeof selected === 'string' && Boolean(!selected.length)) {
                                      if (schoolListOptions.length) {
                                        return 'Choose School...'
                                      } else {
                                        return 'None Available'
                                      }
                                    } else if (typeof selected === 'number' && Boolean(selected === 0)) {
                                      return 'I do not work at a school'
                                    } else {
                                      if (selected.schoolName) {
                                        return selected.schoolName
                                      }
                                      return ''
                                    }
                                  }}
                                >
                                  {/* Choose School... is the default value (set to an empty string) and I do not work at a school is the option for fellows to select if they don't teach at a school; it's value is set to 0 */}
                                  <MenuItem value='' disabled>Choose School...</MenuItem>
                                  <MenuItem value={0}>I do not work at a school</MenuItem>
                                  {districtOptions && districtOptions.length && schoolListOptions && schoolListOptions.length &&
                                    schoolListOptions.map(school => {
                                      return (
                                        <MenuItem key={school.schoolID} value={school}>{school.schoolName}</MenuItem>
                                      )
                                    })}
                                </Select>
                              </>}
                          </>
                        )
                    }
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item direction='row'>
                <Grid item xs={12}>
                  <Grid container direction='column' spacing={1} className={classes.fixSpacing}>

                    <Grid
                      item
                      container
                      direction='row'
                      justifyContent='space-between'
                      alignItems='center'
                      style={{ marginBottom: '.5rem' }}
                    >
                      <Grid item xs={12} sm={6}>
                        <Typography
                          variant='h6'
                          style={{ textTransform: 'none', color: theme.palette.black }}
                        >
                          Which certifications do you have?
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={6} style={{ paddingLeft: 0, width: '100%' }}>
                        <OutlinedInput
                          fullWidth
                          style={{ height: '2.5rem' }}
                          startAdornment={
                            <InputAdornment position='start'>
                              <SearchIcon className={classes.certSearch} />
                            </InputAdornment>
                          }
                          value={certSearch}
                          onChange={(e) => setCertSearch(e.target.value)}
                          placeholder='Distinguished Educator'
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      direction='row'
                      style={{ marginTop: '.5rem' }}
                      alignItems='stretch'
                      justifyContent='flex-start'
                      spacing={1}
                      className={classes.fixSpacing}
                    >

                      {
                        certOptions.sort((a, b) => {
                          return certificationIDs.indexOf(b.certificationID) - certificationIDs.indexOf(a.certificationID)
                        }).filter((certInfo) => {
                          return certInfo.certName.toLowerCase().includes(certSearch.toLowerCase())
                        }).map((certInfo) => {
                          if (certInfo && certInfo.certificationID && certInfo.certName) {
                            const { certificationID, certName, certImageUrl, croppedCertImageUrl } = certInfo

                            const isSelected = certifications.findIndex((cert) => cert.certificationID === certInfo.certificationID) !== -1

                            return (
                              <Button
                                key={`${certificationID}`}
                                startIcon={<Avatar alt='cert' src={!croppedCertImageUrl ? `${certImageUrl}?${moment().unix()}` : `${croppedCertImageUrl}?${moment().unix()}`} />}
                                className={classes.certBtn}
                                style={{
                                  textAlign: 'left',
                                  textTransform: 'none',
                                  fontSize: '.8rem',
                                  fontWeight: '600',
                                  marginRight: '1rem',
                                  marginBottom: '1rem',
                                  border: isSelected ? '2px solid #293669' : ''
                                }}
                                onClick={(e) => { e.preventDefault(); handleOnCert(certInfo) }}
                              >
                                {certName}
                              </Button>
                            )
                          } else {
                            return (<></>)
                          }
                        })
                      }
                    </Grid>

                  </Grid>
                </Grid>

              </Grid>

            </Grid>
          </Grid>
        </DialogContent>

        <Divider />
        <DialogActions>
          <Button onClick={handleSave} disabled={!isSaveEnabled} variant='contained' color='primary' className={classes.saveBtn}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EditProfile
