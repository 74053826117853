import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  Popover, Grid, Typography, List, ListItem, ListItemIcon, ListItemText, useTheme
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { SwapHorizontalCircle } from '@material-ui/icons'

import moment from 'moment'
import { isEqual } from 'lodash'

import { GET_USER_NOTIFICATIONS_SMALL } from '../../../redux/types'
import { getNotificationsLargeBatch, setExploreActiveTab } from '../../../redux/actions'
import { CommentIcon, LikeIcon, ApproveIcon, DenyIcon, SaveIcon, ResubmitIcon } from '../custom'
import { notifications as EdfarmNotifications, getActionWording } from '../../../lib'

const NotificationsPopper = (props) => {
  const {
    notificationPopoverID, openNotificationPop, notiAnchorEl,
    handleNotificationPopClose, profileID, classes, notificationsArray, userNotifications,
    setNotificationsArray, handleArtifactView
  } = props
  const theme = useTheme()
  const dispatch = useDispatch()
  const loadingState = useSelector(state => state.loadingState)

  // Active explore tab
  const activeTab = useSelector(state => state.select.exploreActiveTab)

  const { type, loading } = loadingState

  useEffect(() => {
    let isMounted = true
    if (notiAnchorEl !== null && isMounted) {
      if (userNotifications && userNotifications.length !== 0) {
        if (!isEqual(userNotifications, notificationsArray)) {
          setNotificationsArray(userNotifications)
        }
      }
    }

    return () => { isMounted = false }
  }, [userNotifications, setNotificationsArray, notificationsArray, notiAnchorEl])

  const handleNotificationsView = () => {
    props.history.push({
      pathname: '/notifications',
      search: `?user=${profileID}`
    })
    dispatch(getNotificationsLargeBatch())
    handleNotificationPopClose()

    // Set explore tab back to featured if it's not already on there when moving from explore page
    if (activeTab !== 'featured') { dispatch(setExploreActiveTab('featured')) }
  }

  const getActionIcon = (actionType) => {
    switch (actionType) {
      case EdfarmNotifications.ADMIN_COMMENT:
        return (
          <CommentIcon className={classes.notificationIcon} />
        )
      case EdfarmNotifications.ARTIFACT_APPROVED:
        return (
          <ApproveIcon className={classes.notificationIcon} />
        )
      case EdfarmNotifications.ARTIFACT_COMMENT:
        return (
          <CommentIcon className={classes.notificationIcon} />
        )
      case EdfarmNotifications.ARTIFACT_CHANGES_REQUESTED:
        return (
          <SwapHorizontalCircle className={classes.horizontalIcon} />
        )
      case EdfarmNotifications.ARTIFACT_DENIED:
        return (
          <DenyIcon className={classes.notificationIcon} />
        )
      case EdfarmNotifications.ARTIFACT_LIKED:
        return (
          <LikeIcon className={classes.notificationIcon} />
        )
      case EdfarmNotifications.ARTIFACT_RESUBMITTED:
        return (
          <ResubmitIcon className={classes.notificationIcon} />
        )
      case EdfarmNotifications.ARTIFACT_SAVED:
        return (
          <SaveIcon className={classes.notificationIcon} />
        )
      case EdfarmNotifications.USER_ARTIFACT_COMMENT:
        return (
          <CommentIcon className={classes.notificationIcon} />
        )
      default: return ''
    }
  }

  return (
    <Popover
      id={notificationPopoverID}
      open={openNotificationPop}
      anchorEl={notiAnchorEl}
      onClose={handleNotificationPopClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      classes={{
        paper: classes.popPaper
      }}
      style={{ zIndex: 2000 }}
    >
      <List style={{ paddingTop: '1em' }}>

        {
          type === GET_USER_NOTIFICATIONS_SMALL && loading === true
            ? (
              <>
                <ListItem
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                >
                  <Grid container direction='row'>
                    <Grid item xs={1} container direction='row'>
                      <Skeleton variant='circle' height={14} width={14} animation='wave' />
                    </Grid>
                    <Grid item xs={11} container direction='column' style={{ paddingLeft: '1em' }}>
                      <Skeleton animation='wave' width={50} />
                      <Skeleton animation='wave' width={30} />
                    </Grid>
                  </Grid>
                </ListItem>

                <ListItem
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                >
                  <Grid container direction='row'>
                    <Grid item xs={1} container direction='row'>
                      <Skeleton variant='circle' height={14} width={14} animation='wave' />
                    </Grid>
                    <Grid item xs={11} container direction='column' style={{ paddingLeft: '1em' }}>
                      <Skeleton animation='wave' width={50} />
                      <Skeleton animation='wave' width={30} />
                    </Grid>
                  </Grid>
                </ListItem>

                <ListItem
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                >
                  <Grid container direction='row'>
                    <Grid item xs={1} container direction='row'>
                      <Skeleton variant='circle' height={14} width={14} animation='wave' />
                    </Grid>
                    <Grid item xs={11} container direction='column' style={{ paddingLeft: '1em' }}>
                      <Skeleton animation='wave' width={50} />
                      <Skeleton animation='wave' width={30} />
                    </Grid>
                  </Grid>
                </ListItem>
              </>
            )
            : (
              notificationsArray && Boolean(notificationsArray.length) && !loading
                ? (
                  <>
                    {notificationsArray.map((notification) => {
                      const { actionType, artifactComment, publicComment, artifactNotificationID = '' } = notification

                      return (
                        <ListItem
                          key={`notification-${artifactNotificationID}`}
                          button
                          alignItems='flex-start'
                          disableGutters
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          onClick={() => handleArtifactView(notification)}
                        >
                          <ListItemIcon>
                            {getActionIcon(actionType)}
                          </ListItemIcon>

                          <ListItemText disableTypography>

                            <Grid container direction='row' spacing={4} style={{ width: '100%', margin: 'auto' }}>
                              <Grid item container direction='column' style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0 }}>
                                <Typography variant='body1' style={{ color: theme.palette.black, textTransform: 'none', fontWeight: 600 }}>
                                  {`${notification.fullName} ${getActionWording(actionType)}`}
                                </Typography>
                                <Typography variant='body1' style={{ color: theme.palette.purple.darkest, textTransform: 'none', fontWeight: 600 }}>{notification.artifactTitle}</Typography>
                                <Typography variant='body1' style={{ color: theme.palette.grey.dark, textTransform: 'none', fontWeight: 400 }}>{`${moment(notification.createdAt * 1000).fromNow()}`}</Typography>
                                {notification.artifactComment && notification.artifactComment !== '' &&
                                  <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontStyle: 'italic' }}>
                                    {`"${artifactComment && artifactComment.length && artifactComment.length > 25 ? `${artifactComment.substring(0, 25)}...` : artifactComment}"`}
                                  </Typography>}
                                {notification.publicComment && notification.publicComment !== '' &&
                                  <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontStyle: 'italic' }}>
                                    {`"${publicComment && publicComment.length && publicComment.length > 25 ? `${publicComment.substring(0, 25)}...` : publicComment}"`}
                                  </Typography>}
                              </Grid>
                            </Grid>
                          </ListItemText>
                        </ListItem>
                      )
                    })}

                    <ListItem
                      button
                      style={{ paddingBottom: 0 }}
                      onClick={handleNotificationsView}
                    >
                      <ListItemText
                        primary='See All Notifications...'
                        primaryTypographyProps={{
                          style: {
                            color: theme.palette.purple.darkest,
                            fontWeight: 600
                          }
                        }}
                      />
                    </ListItem>
                  </>
                ) : (
                  <ListItem style={{ paddingBottom: '1em' }}>
                    <Grid container item direction='row' justifyContent='center'>
                      <Typography variant='body1' style={{ color: theme.palette.grey.medium, textTransform: 'none', fontWeight: 600, textAlign: 'center' }}>
                        {!loading ? 'No new notifications' : ''}
                      </Typography>
                    </Grid>
                  </ListItem>
                )
            )
        }

      </List>
    </Popover>
  )
}

export default withRouter(NotificationsPopper)
