import { useSelector } from 'react-redux'

import {
  Grid, Typography, OutlinedInput, Button,
  Dialog, DialogTitle, DialogContent, DialogActions,
  FormControl, Radio
} from '@material-ui/core'

import { updateUserActionTypes } from '../../../../utils'

const UpdateUserDialog = (props) => {
  const {
    classes, theme, editRow,
    modalOpen, handleCloseModal, handleConfirmPopover,
    newEmailAddress, setNewEmailAddress,
    handleSaveEmailChange,
    isEmailValid, selectedAction, setSelectedAction = () => { }
  } = props

  const { userID } = useSelector(state => state.auth)

  return (
    <>
      <Dialog
        open={modalOpen}
        onClose={(e) => { e.preventDefault(); setSelectedAction(updateUserActionTypes.UPDATE_EMAIL); handleCloseModal() }}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle disableTypography>
          <Typography variant='h4' style={{ color: theme.palette.purple.darkest }}>Advanced Settings</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid item container direction='row'>
            <Grid item container xs={12} style={{ marginBottom: '2em', marginTop: '1em' }}>

              <Grid item container direction='row' alignItems='center'>
                <Radio
                  color='primary'
                  size='small'
                  checked={selectedAction === updateUserActionTypes.UPDATE_EMAIL}
                  onChange={(e) => setSelectedAction(e.target.value)}
                  value={updateUserActionTypes.UPDATE_EMAIL}
                />

                <Typography variant='h4'>Change User Email</Typography>
              </Grid>

              <Grid item container direction='row' style={{ paddingLeft: '2.5em', paddingRight: '2.5em' }}>
                <Typography variant='caption'>
                  Caution! Updating the user's email will require them to validate the new email, and they will no longer be able to log in with the previous email.
                </Typography>
              </Grid>

              <Grid item container direction='row' alignItems='center' style={{ paddingLeft: '2.5em', paddingRight: '2.5em' }}>
                <FormControl margin='dense' fullWidth>
                  <OutlinedInput
                    size='small'
                    margin='dense'
                    fullWidth
                    placeholder='email'
                    inputProps={{ style: { border: 'none', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '5px' } }}
                    classes={{ input: classes.inputPlaceholder }}
                    value={newEmailAddress}
                    onChange={(e) => setNewEmailAddress(e.target.value)}
                    error={!isEmailValid}
                    disabled={selectedAction !== updateUserActionTypes.UPDATE_EMAIL}
                  />
                </FormControl>
              </Grid>

              {!isEmailValid && selectedAction === updateUserActionTypes.UPDATE_EMAIL &&
                <Grid item container direction='row' alignItems='center' style={{ paddingLeft: '2.5em', paddingRight: '2.5em' }}>
                  <Typography style={{ color: 'red' }}>* New email address must be valid and different than current email address.</Typography>
                </Grid>}
            </Grid>

            <Grid item container xs={12} style={{ marginTop: '1em', marginBottom: '.5em' }}>
              <Grid item container direction='row' alignItems='center'>
                <Radio
                  color='primary'
                  size='small'
                  checked={selectedAction === updateUserActionTypes.DELETE_USER}
                  onChange={(e) => setSelectedAction(e.target.value)}
                  value={updateUserActionTypes.DELETE_USER}
                />

                <Typography variant='h4'>Delete User</Typography>
              </Grid>

              <Grid item container direction='row' style={{ paddingLeft: '2.5em', paddingRight: '2.5em' }}>
                <Typography variant='caption'>
                  Caution! Deleting the user will remove all associated data and they will no longer be able to log in. Please be sure you have checked thoroughly before continuing.
                </Typography>
              </Grid>

              <Grid item container direction='row' alignItems='center' style={{ marginTop: '.5em', paddingLeft: '2.5em', paddingRight: '2.5em' }}>
                <Button
                  variant='contained'
                  className={classes.deleteButton}
                  onClick={(e) => handleConfirmPopover(e, editRow?.userID)}
                  disabled={selectedAction !== updateUserActionTypes.DELETE_USER || editRow.userID === userID}
                >
                  Delete
                </Button>
              </Grid>

              {selectedAction === updateUserActionTypes.DELETE_USER && editRow.userID === userID &&
                <Grid item container direction='row' alignItems='center' style={{ paddingLeft: '2.5em', paddingRight: '2.5em' }}>
                  <Typography style={{ color: 'red' }}>* You can not delete your own user.</Typography>
                </Grid>}
            </Grid>

          </Grid>

        </DialogContent>

        <DialogActions>
          <Button
            variant='outlined'
            color='primary'
            style={{
              margin: '.5em 1em',
              fontWeight: '600'
            }}
            onClick={(e) => { e.preventDefault(); setSelectedAction(updateUserActionTypes.UPDATE_EMAIL); handleCloseModal() }}
          >
            Cancel
          </Button>

          <Button
            variant='contained'
            color='primary'
            style={{
              margin: '.5em 1em',
              fontWeight: '600'
            }}
            onClick={(e) => { e.preventDefault(); handleSaveEmailChange() }}
            disabled={!isEmailValid || selectedAction !== updateUserActionTypes.UPDATE_EMAIL}
          >
            Save
          </Button>

        </DialogActions>
      </Dialog>
    </>
  )
}

export default UpdateUserDialog
