import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import {
  Grid, Typography, useTheme, OutlinedInput, Table, TableBody, TableContainer, TableCell, TableHead, TableRow,
  Paper, IconButton, Button, Divider, Dialog, DialogContent, DialogActions, DialogTitle, Select, MenuItem, TableSortLabel
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import {
  SearchRounded, AddRounded, DeleteOutline
} from '@material-ui/icons'

import { isEqual } from 'lodash'
import { createCohort, updateCohort, removeCohort } from '../../../redux/actions'
import { NotificationToast } from '../tools'
import { ModifiedEditIcon } from '../custom'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { SingleDatePicker } from 'react-dates'
import clsx from 'clsx'

import { defaultErrors } from '../../../utils'
import DeleteCohortModal from './DeleteCohortModal'

const CohortsAdmin = (props) => {
  const { classes, allCohorts, totalCohorts, filter, setFilter, defaultFilter, getCohorts, activeButton } = props
  const theme = useTheme()
  const dispatch = useDispatch()

  const { superAdmin = 0 } = useSelector(state => state.auth)

  // ********************* New/Edit/Delete cohort logic: ******************** //
  const defaultCohortForm = {
    cohortName: '',
    startDate: '',
    endDate: ''
  }
  const [newCohortForm, setNewCohortForm] = useState(defaultCohortForm)

  const defaultCohortsAdminErr = defaultErrors.cohortsAdminErr

  const [errors, setErrors] = useState(defaultCohortsAdminErr)

  const [createOpen, setCreateOpen] = useState(false)
  const [editID, setEditID] = useState('')

  // State items and handlers for cohort removal
  const defaultDeleteCohortInfo = {
    cohortID: '',
    replacementCohort: ''
  }

  const [deleteCohortModalOpen, setDeleteCohortModalOpen] = useState(false)
  const [deleteCohortInfo, setDeleteCohortInfo] = useState({ ...defaultDeleteCohortInfo })

  const handleOpenDeleteCohortModal = (cohortInfo) => {
    if (cohortInfo) {
      const { cohortID, cohortName, participantCount } = cohortInfo

      setDeleteCohortInfo({ ...deleteCohortInfo, cohortID, cohortName, participantCount })
      setDeleteCohortModalOpen(true)
    }
  }

  const fireDeleteCohortSuccess = () => {
    NotificationToast(false, 'Successfully Deleted Cohort')
    handleReset()
  }

  const fireDeleteCohortFailure = () => {
    return NotificationToast(true, 'Unable to Delete Cohort')
  }

  const handleDeleteCohort = () => {
    if (deleteCohortInfo?.cohortID) {
      const tempForm = {
        cohortID: deleteCohortInfo?.cohortID,
        replacementCohort: deleteCohortInfo?.replacementCohort
      }
      dispatch(removeCohort(tempForm, fireDeleteCohortSuccess, fireDeleteCohortFailure))
    }
  }

  const handleOpenModal = (cohortInfo) => {
    if (cohortInfo) {
      const { cohortID, cohortName, startDate, endDate } = cohortInfo
      setEditID(cohortID)

      const formattedStart = startDate ? moment(startDate * 1000) : ''
      const formattedEnd = endDate ? moment(endDate * 1000) : ''

      setNewCohortForm({
        cohortName,
        startDate: formattedStart,
        endDate: formattedEnd
      })

      setStartDateValue(formattedStart)
      setEndDateValue(formattedEnd)
    } else {
      setEditID('')
    }
    setCreateOpen(true)
  }

  const errorCheck = newCohortForm.cohortName !== '' && newCohortForm.startDate !== '' && newCohortForm.endDate !== ''

  const handleReset = () => {
    setErrors(defaultCohortsAdminErr)
    setNewCohortForm(defaultCohortForm)
    setCreateOpen(false)
    setStartDateValue(null)
    setEndDateValue(null)
    setFilter({ ...filter })
    setEditID('')
    setDeleteCohortModalOpen(false)
    setDeleteCohortInfo({ ...defaultDeleteCohortInfo })
  }

  const fireCreateSuccess = () => {
    NotificationToast(false, 'Successfully Created New Cohort')
    handleReset()
  }

  const fireCreateFailure = () => {
    return NotificationToast(true, 'Unable to Create New Cohort')
  }

  const fireUpdateSuccess = () => {
    NotificationToast(false, 'Successfully Updated Cohort')
    handleReset()
  }

  const fireUpdateFailure = () => {
    return NotificationToast(true, 'Unable to Update Cohort')
  }

  const handleSave = () => {
    const { cohortName, startDate, endDate } = newCohortForm
    const startStamp = startDate !== '' ? parseInt(moment(startDate).format('X')) : ''
    const endStamp = endDate !== '' ? parseInt(moment(endDate).format('X')) : ''

    if (endStamp !== '' && startStamp !== '' && endStamp < startStamp) {
      setErrors({
        notComplete: errorCheck ? false : !false,
        endBeforeStart: true
      })
    } else {
      if (errorCheck) {
        const finalForm = {
          cohortName: cohortName,
          startDate: startStamp,
          endDate: endStamp
        }
        if (editID === '') {
          dispatch(createCohort(finalForm, fireCreateSuccess, fireCreateFailure))
        } else {
          finalForm.cohortID = editID
          dispatch(updateCohort(finalForm, fireUpdateSuccess, fireUpdateFailure))
        }
      } else {
        setErrors({
          ...errors,
          notComplete: true
        })
      }
    }
  }

  const handleCancel = () => {
    setErrors(defaultCohortsAdminErr)
    setNewCohortForm(defaultCohortForm)
    setStartDateValue(null)
    setEndDateValue(null)
    setCreateOpen(false)
    setEditID('')
    setDeleteCohortModalOpen(false)
    setDeleteCohortInfo({ ...defaultDeleteCohortInfo })
  }

  // ******************************** Table Data Logic: ******************************* //

  // If the filter was changed, fetch the fellows with the new filter
  useEffect(() => {
    if (filter && defaultFilter && activeButton === 'cohorts') {
      if (!isEqual(filter, defaultFilter)) {
        dispatch(getCohorts(filter))
      }
    }
  }, [dispatch, filter, defaultFilter, getCohorts, setFilter, activeButton])

  const createData = (cohortID, cohortName, startDate, endDate, participantCount) => {
    return { cohortID, cohortName, startDate, endDate, participantCount }
  }

  // Data rows for the displayed table
  const [rows, setRows] = useState([])

  // Take the allCohorts array and format it into the data rows
  useEffect(() => {
    if (allCohorts) {
      if (allCohorts.length) {
        const newRows = []
        allCohorts.forEach(cohort => {
          const { cohortID, cohortName, startDate, endDate, numOfFellows } = cohort
          newRows.push(createData(cohortID, cohortName, startDate, endDate, numOfFellows))
        })
        setRows(newRows)
      } else {
        setRows([])
      }
    }
  }, [allCohorts])

  // Column Headers
  const headCells = [
    { id: 'cohortName', label: 'Cohort Name' },
    { id: 'startDate', label: 'Start Date' },
    { id: 'endDate', label: 'End Date' },
    { id: 'numOfFellows', label: 'Num. of Fellows' }
  ]

  // ******************************* Pagination logic: ******************************* //
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const handleChangePage = (event, value) => {
    if (value >= 0) {
      setPage(value - 1)
      setFilter({
        ...filter,
        sortCount: rowsPerPage,
        page: value
      })
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: event.target.value
    })
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalCohorts - page * rowsPerPage)

  // **************************** Column Sort Logic ************************ //

  const [orderBy, setOrderBy] = useState('')
  const [order, setOrder] = useState('desc')

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
    setFilter({
      ...filter,
      page: 1,
      sortCount: rowsPerPage,
      sortDirection: isAsc ? 'DESC' : 'ASC',
      sortType: property
    })
  }

  // ********************** Search Logic *********************** //
  const [searchInput, setSearchInput] = useState('')

  const handleSearch = (e) => {
    setSearchInput(e.target.value)
    if (searchInput && searchInput.length >= 3) {
      // Previously, issues were occurring if the user was on a page that was greater than 1 and made a search. The page is now being reset beforehand to avoid that.
      setPage(0)
      setFilter({
        ...filter,
        page: 1,
        sortCount: rowsPerPage,
        searchPhrase: searchInput
      })
    }

    if (e.target.value === '') {
      setPage(0)
      // Set the sortCount to current rowsPerPage instead of the default
      setFilter({
        ...defaultFilter,
        sortCount: rowsPerPage
      })
    }
  }

  // ***************** Date Selector Logic ********************* //

  const [singleFocusedInputStart, setSingleFocusedStart] = useState(null)
  const [startDateValue, setStartDateValue] = useState(null)

  const [singleFocusedInputEnd, setSingleFocusedEnd] = useState(null)
  const [endDateValue, setEndDateValue] = useState(null)

  const handleSingleFocusStart = (focus) => {
    setSingleFocusedStart(focus.focused)
  }

  const handleDateChangeStart = (value) => {
    setStartDateValue(value)
    setNewCohortForm({ ...newCohortForm, startDate: value })
  }

  const handleSingleFocusEnd = (focus) => {
    setSingleFocusedEnd(focus.focused)
  }

  const handleDateChangeEnd = (value) => {
    setEndDateValue(value)
    setNewCohortForm({ ...newCohortForm, endDate: value })
  }

  const outsideRange = () => false

  return (
    <Grid container direction='column'>

      {/* ----------Cohort Create Modal--------- */}
      <Dialog
        open={createOpen}
        onClose={handleCancel}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle disableTypography>
          <Grid item container direction='row' justifyContent='space-between' style={{ margin: '.5em' }}>
            <Typography variant='h4'>{`${editID === '' ? 'Add' : 'Edit'} Cohort`}</Typography>
          </Grid>
        </DialogTitle>
        <DialogContent
          dividers
        >
          <Grid container direction='column' style={{ margin: '1em', paddingRight: '2em' }}>
            <Grid item container direction='column' style={{ marginTop: 0 }}>
              <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}>Cohort Name <span style={{ color: 'red', fontSize: '14px' }}>*</span></Typography>
              <OutlinedInput
                fullWidth
                margin='dense'
                size='small'
                value={newCohortForm ? newCohortForm.cohortName : ''}
                onChange={(e) => setNewCohortForm({ ...newCohortForm, cohortName: e.target.value })}
                placeholder='Enter cohort name...'
                classes={{ input: classes.inputPlaceholder }}
              />
            </Grid>
            <Grid item container direction='column' style={{ marginTop: '2em' }}>
              <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}>Start Date <span style={{ color: 'red', fontSize: '14px' }}>*</span></Typography>

              <SingleDatePicker
                small
                id='SingleDatePickerInputStart'
                placeholder=''
                showDefaultInputIcon
                showClearDate
                isOutsideRange={outsideRange}
                date={startDateValue}
                onDateChange={date => handleDateChangeStart(date)}
                focused={singleFocusedInputStart}
                onFocusChange={(focused) => handleSingleFocusStart(focused)}
                orientation='horizontal'
                daySize={35}
                numberOfMonths={1}
              />
            </Grid>
            <Grid item container direction='column' style={{ marginTop: '2em' }}>
              <Typography gutterBottom variant='body1' style={{ fontWeight: '600' }}>End Date <span style={{ color: 'red', fontSize: '14px' }}>*</span></Typography>

              <SingleDatePicker
                small
                id='SingleDatePickerInputEnd'
                placeholder=''
                showDefaultInputIcon
                showClearDate
                isOutsideRange={outsideRange}
                date={endDateValue}
                onDateChange={date => handleDateChangeEnd(date)}
                focused={singleFocusedInputEnd}
                onFocusChange={(focused) => handleSingleFocusEnd(focused)}
                numberOfMonths={1}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container direction='column'>
            <Grid item container direction='column' alignContent='flex-end'>
              {errors.notComplete &&
                <Typography variant='caption' style={{ color: 'red' }}>* Please check that name and dates are entered. </Typography>}
              {errors.endBeforeStart &&
                <Typography variant='caption' style={{ color: 'red' }}>* End Date cannot be before Start Date </Typography>}
            </Grid>
            <Grid container item direction='row' justifyContent='flex-end'>
              <Button
                variant='outlined'
                color='primary'
                style={{
                  margin: '.5em 1em',
                  fontWeight: '600'
                }}
                onClick={handleCancel}
              >
                Cancel
              </Button>

              <Button
                variant='contained'
                color='primary'
                style={{
                  margin: '.5em 1em',
                  fontWeight: '600'
                }}
                onClick={handleSave}
              >
                Save
              </Button>
            </Grid>
          </Grid>

        </DialogActions>
      </Dialog>

      {/* Delete Cohort Modal */}
      <DeleteCohortModal
        deleteCohortModalOpen={deleteCohortModalOpen}
        handleCancel={handleCancel}
        classes={classes}
        allCohorts={allCohorts}
        setDeleteCohortInfo={setDeleteCohortInfo}
        deleteCohortInfo={deleteCohortInfo}
        handleDeleteCohort={handleDeleteCohort}
      />

      {/* --------------------- Main Component Content -------------------- */}
      <Grid item container direction='row' alignItems='center' style={{ marginBottom: '1em' }}>
        <Grid item container xs={9} direction='row' alignItems='center'>
          <Grid item style={{ marginRight: '.5em' }}>
            {/* Page Title */}
            <Typography variant='h4' style={{ fontSize: '20px' }}>Cohorts</Typography>
          </Grid>
        </Grid>
        <Grid item container xs={3} justifyContent='flex-end'>
          <Button
            color='primary'
            variant='contained'
            style={{ fontWeight: '600' }}
            startIcon={
              <AddRounded className={classes.addIcon} />
            }
            onClick={() => handleOpenModal()}
          >
            Add Cohort
          </Button>
        </Grid>
      </Grid>

      <Divider />

      {/* Top Pagination display and search input */}
      <Grid item container direction='row' style={{ marginBottom: '1em', marginTop: '1em' }}>
        <Grid item container alignContent='flex-end' xs={7}>
          <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none', fontWeight: 400, fontSize: '16px' }}>
            Displaying {totalCohorts === 0 ? 0 : (page * rowsPerPage) + 1} to {rows && page * rowsPerPage + rowsPerPage > totalCohorts ? totalCohorts : page * rowsPerPage + rowsPerPage} of {totalCohorts}
          </Typography>
        </Grid>
        <Grid item container justifyContent='flex-end' xs={5}>
          <OutlinedInput
            className={classes.searchInput}
            size='small'
            margin='dense'
            fullWidth
            inputProps={{ style: { border: 'none', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '5px' } }}
            placeholder='Search...'
            classes={{ input: classes.inputPlaceholder }}
            startAdornment={
              <SearchRounded style={{ color: theme.palette.grey.dark, fontSize: '20px' }} />
            }
            value={searchInput}
            onChange={(e) => handleSearch(e)}
          />
        </Grid>
      </Grid>

      {/* Data Table */}
      <Grid item container direction='column' style={{ marginBottom: '1em' }}>
        <TableContainer elevation={0} style={{ border: `solid 1px ${theme.palette.grey.medium}` }} component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: theme.palette.grey.lighter }}>
              <TableRow>
                {headCells.map(header => {
                  return (
                    <TableCell
                      key={header.id}
                      align='left'
                      style={{ padding: '16px' }}
                      sortDirection={orderBy === header.id ? 'asc' : false}
                    >
                      <TableSortLabel
                        active={orderBy === header.id}
                        direction={orderBy === header.id ? order : 'asc'}
                        onClick={() => handleRequestSort(header.id)}
                      >
                        <Typography variant='h5' style={{ color: theme.palette.grey.dark }}>{header.label}</Typography>
                        {orderBy === header.id ? (
                          <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  )
                })}
                <TableCell align='left' style={{ padding: '16px' }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow key={`index-${row.cohortID}`}>
                    <TableCell>
                      <Typography
                        variant='body1'
                        style={{ fontWeight: 600 }}
                      >
                        {row.cohortName}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant='body1'
                      >
                        {moment(row.startDate * 1000).format('L')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant='body1'
                      >
                        {moment(row.endDate * 1000).format('L')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant='body1'
                      >
                        {row.participantCount}
                      </Typography>
                    </TableCell>
                    <TableCell align='right'>
                      <Grid item container direction='row' justifyContent='space-evenly'>
                        <IconButton
                          className={classes.editIcon}
                          onClick={() => handleOpenModal(row)}
                        >
                          <ModifiedEditIcon className={clsx(classes.inactiveEditIcon)} />
                        </IconButton>
                        {Boolean(superAdmin) &&
                          <IconButton
                            onClick={() => handleOpenDeleteCohortModal(row)}
                            className={classes.deleteIcon}
                          >
                            <DeleteOutline />
                          </IconButton>}
                      </Grid>
                    </TableCell>
                  </TableRow>
                )
              })}
              {/* When the data has reached its length: */}
              {emptyRows > 0 && (
                <TableRow style={{ height: 10 }}>
                  <TableCell colSpan={5}>
                    <Typography variant='body1' style={{ color: theme.palette.grey.medium, textAlign: 'center' }}>{totalCohorts === 0 ? 'No Results' : 'End of List'}</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* Bottom Pagination Controls */}
      <Grid item container direction='row' style={{ marginBottom: '4em' }}>
        {/* Rows Per page selection */}
        <Grid item container direction='row' xs={6} justifyContent='flex-start'>
          <Grid item style={{ marginRight: '.2em', display: 'flex', alignItems: 'flex-end' }}>
            <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>Display</Typography>
          </Grid>
          <Grid item style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Select
              variant='outlined'
              size='small'
              defaultValue={10}
              value={rowsPerPage}
              onChange={(e) => handleChangeRowsPerPage(e)}
              classes={{ root: classes.searchInput, selectMenu: classes.statusSelect }}
              style={{ padding: 0 }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </Grid>
          <Grid item style={{ marginLeft: '.4em', display: 'flex', alignItems: 'flex-end' }}>
            <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none' }}>entries</Typography>
          </Grid>
        </Grid>
        {/* Pages/Pagination Display */}
        <Grid item container xs={6} justifyContent='flex-end'>
          <Pagination
            color='primary'
            classes={{ root: classes.pagination }}
            count={totalCohorts ? Math.ceil(totalCohorts / rowsPerPage) : 0}
            page={page === 0 ? 1 : page + 1}
            onChange={handleChangePage}
            shape='rounded'
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CohortsAdmin
