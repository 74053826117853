import React, { } from 'react'
import { useDispatch } from 'react-redux'

import {
  Grid, Typography, CircularProgress, Grow, Button
} from '@material-ui/core'

import {
  LibraryAddCheckRounded
} from '@material-ui/icons'

import { getSocialDetails } from '../../../../redux/actions'

import { modalTypes } from '../../../../utils'

const ModifiedChalkboardIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='70'
      height='70'
      viewBox='0 0 111 111'
    >
      <g transform='translate(-628 -124)'>
        <circle className='a' cx='50' cy='50' r='50' transform='translate(628 124)' fill='#29356A' />
        <g transform='translate(645 149)'>
          <path
            className='b'
            d='M19.5,33a2.145,2.145,0,0,0-.662.1A12.367,12.367,0,0,1,15,33.75a12.378,12.378,0,0,1-3.839-.648A2.139,2.139,0,0,0,10.5,33,10.5,10.5,0,0,0,0,43.558,4.488,4.488,0,0,0,4.5,48h21A4.488,4.488,0,0,0,30,43.558,10.5,10.5,0,0,0,19.5,33ZM15,30a9,9,0,1,0-9-9A9,9,0,0,0,15,30ZM55.5,0h-36A4.582,4.582,0,0,0,15,4.649V9a11.864,11.864,0,0,1,6,1.669V6H54V33H48V27H36v6H28.851a11.949,11.949,0,0,1,3.721,6H55.5A4.582,4.582,0,0,0,60,34.351V4.649A4.582,4.582,0,0,0,55.5,0Z'
            transform='translate(0.002)'
            fill='#F3F5FB'
          />
        </g>
      </g>
    </svg>
  )
}

const ExploreAll = ({ props }) => {
  const {
    classes, theme, loading,
    ArtifactCard, likedArtifacts, savedArtifacts,
    allPostsCount, allArtifacts, hasResults,
    setSocialType, setSocialModalOpen, setSocialArtifactInfo,
    chosenType = '', subjectsValue = [], gradesValue = [], searchInput = '', filter = {},
    handleChooseModal
  } = props

  const defaultNoResultsText = ' your search parameters yet.'

  const noTypeResultsText = {
    'learning-experience-design': 'Learning Experience Design artifacts yet',
    'learner-works': 'Learner Work artifacts yet',
    projects: 'Project artifacts yet',
    all: ''
  }

  const noSubjectResultText =
    subjectsValue && subjectsValue.length
      ? subjectsValue.length > 1
        ? ' your chosen subjects yet.'
        : subjectsValue[0].subjectName
          ? `'${subjectsValue[0].subjectName}' yet`
          : defaultNoResultsText
      : defaultNoResultsText

  const noGradeResultText =
  gradesValue && gradesValue.length
    ? gradesValue.length > 1
      ? ' your chosen grades yet.'
      : gradesValue[0].gradeName
        ? `'${gradesValue[0].gradeName}' yet`
        : defaultNoResultsText
    : defaultNoResultsText

  const filterKeyOptions = {
    subjects: noSubjectResultText,
    grades: noGradeResultText,
    type: noTypeResultsText[chosenType],
    search: searchInput
  }

  const getNoResultText = () => {
    const fullFilterKeys = filter ? Object.keys(filter) : []
    const filterOptions = fullFilterKeys.filter(key => key !== 'explorerType')

    if (filterOptions && Boolean(filterOptions.length)) {
      if (filterOptions.length > 1) {
        return defaultNoResultsText
      } else {
        const searchKey = filterOptions[0]
        const textKeyOptions = Object.keys(filterKeyOptions)

        if (textKeyOptions.includes(searchKey)) {
          const keyText = filterKeyOptions[searchKey]

          return ` ${keyText}...`
        } else {
          return defaultNoResultsText
        }
      }
    }
    return defaultNoResultsText
  }

  const dispatch = useDispatch()

  return (
    <>
      {/* Section Header/ Nav */}
      <Grid item container direction='row' justifyContent='space-between'>
        <Grid item xs={9} container alignItems='flex-end' direction='row'>
          <Grid item container alignContent='center' justifyContent='center' style={{ backgroundColor: theme.palette.purple.darkest, borderRadius: '50%', height: '2em', width: '2em', marginRight: '.5em' }}>
            <LibraryAddCheckRounded style={{ color: 'white', fontSize: '22px' }} />
          </Grid>
          <Typography variant='h3' style={{ color: theme.palette.purple.darkest, textTransform: 'none', fontWeight: 600, marginRight: '1em' }}>All Posts</Typography>
          <Typography variant='h5' style={{ color: theme.palette.grey.dark, fontWeight: 600, textTransform: 'none' }}>Dive in to all of the great content created by your fellow teachers and educators.</Typography>
        </Grid>
        <Grid item container justifyContent='flex-end' xs={3}>
          <Typography variant='h6' style={{ color: theme.palette.grey.dark, fontWeight: 400, textTransform: 'none' }}>{`${allPostsCount > 100 ? '+100' : allPostsCount} Total`}</Typography>
        </Grid>
      </Grid>

      {/* Section Content/Results */}
      <Grid item container direction='row' spacing={2} style={{ marginTop: '2em' }}>
        {/* Card Render */}
        {loading
          ? (
            <Grow
              in={loading}
              {...(loading ? { timeout: 1000 } : {})}
            >
              <Grid item container direction='row' justifyContent='center' alignContent='center' style={{ margin: '8em 0' }}>
                <CircularProgress />
              </Grid>
            </Grow>
          )
          : (
            allArtifacts && allArtifacts && allArtifacts.length
              ? (
                allArtifacts.map((artifact, i) => {
                  const current = Boolean(i === allArtifacts.findIndex(x => x.artifactID === artifact.artifactID))
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={artifact.artifactID}>
                      <Grow
                        in={current}
                        style={{ transformOrigin: '0 0 0' }}
                        {...(current ? { timeout: 200 } : {})}
                      >
                        <Grid item style={{ height: '100%', minWidth: '100%' }} container>
                          <ArtifactCard
                            type={artifact.artifactType}
                            classes={classes}
                            artifactInfo={artifact}
                            canEditArtifact={false}
                            artifactLiked={likedArtifacts && likedArtifacts.length && likedArtifacts.includes(artifact.artifactID)}
                            artifactSaved={savedArtifacts && savedArtifacts.length && savedArtifacts.includes(artifact.artifactID)}
                            fromExplore
                            onSocialButtonClick={(status) => { dispatch(getSocialDetails(artifact.artifactID)); setSocialType(status); setSocialArtifactInfo(artifact); setSocialModalOpen(true) }}
                          />
                        </Grid>
                      </Grow>
                    </Grid>
                  )
                })
              )
              : (
                <Grid item container justifyContent='center' alignContent='center'>
                  {
                    !hasResults
                      ? (
                        <CircularProgress />
                      )
                      : (
                        <Grid container direction='column' justifyContent='center' alignItems='center' style={{ margin: '4em' }}>
                          <ModifiedChalkboardIcon />
                          <Button
                            variant='text'
                            style={{ textTransform: 'none', fontSize: '16px', textDecoration: 'underline', color: theme.palette.purple.darkest, fontWeight: 600 }}
                            onClick={() => handleChooseModal(modalTypes.ARTIFACT)}
                          >
                            Create an Artifact
                          </Button>
                          <Typography variant='body1' style={{ color: theme.palette.grey.dark }}>
                            {`Sorry! No one has created an artifact related to ${getNoResultText()}`}
                          </Typography>
                          <Typography variant='body1' style={{ color: theme.palette.grey.dark }}>
                            Get started creating artifacts to share with other educators!
                          </Typography>
                        </Grid>
                      )
                  }
                </Grid>
              )
          )}
      </Grid>
      {Boolean(allPostsCount) && allPostsCount >= 100 &&
        <Grid item container direction='column' justifyContent='center' alignContent='center' style={{ marginTop: '2em' }}>
          <Typography variant='body1' style={{ color: theme.palette.grey.dark, textAlign: 'center', fontWeight: 600 }}>This view is limited to 100 artifacts.</Typography>
          <Typography variant='body1' style={{ color: theme.palette.grey.dark, textAlign: 'center' }}>Don't see what you are looking for? Try using the filters above to refine your search.</Typography>
        </Grid>}
    </>
  )
}

export default ExploreAll
