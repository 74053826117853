import { LOGOUT, GET_ABOUT_EDIT_DATA, GET_PROFILE_EDIT_DATA, GET_SKILLS_LIST, GET_TECH_LIST, RESET_TECH_LIST, RESET_SKILLS_LIST, RESET_ABOUT_EDIT_DATA, RESET_PROFILE_EDIT_DATA } from '../types'

// UI Tech Debt: moved skills and tech into profile reducer and deleted their respective files

const initialState = {
  grades: [],
  subjects: [],
  schools: [],
  defaultAvatars: [],
  certifications: [],
  skills: [],
  tech: []
}

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE_EDIT_DATA: return {
      ...state,
      schools: action.payload.schools,
      defaultAvatars: action.payload.defaultAvatars,
      certifications: action.payload.certifications
    }
    case RESET_PROFILE_EDIT_DATA: return {
      ...state,
      schools: [],
      defaultAvatars: [],
      certifications: []
    }
    case GET_ABOUT_EDIT_DATA: return {
      ...state,
      grades: action.payload.grades,
      subjects: action.payload.subjects
    }
    case RESET_ABOUT_EDIT_DATA: return {
      ...state,
      grades: [],
      subjects: []
    }
    case GET_SKILLS_LIST: return {
      ...state,
      skills: action.payload
    }
    case RESET_SKILLS_LIST: return {
      ...state,
      skills: []
    }
    case GET_TECH_LIST: return {
      ...state,
      tech: action.payload
    }
    case RESET_TECH_LIST : return {
      ...state,
      tech: []
    }
    case LOGOUT: return {
      ...initialState,
      error: action.payload.error || false
    }
    default: return { ...state }
  }
}

export default profileReducer
