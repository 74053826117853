import React, { useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'
import moment from 'moment'

import {
  Grid, Typography, useTheme, Button, Divider, Avatar, Badge, makeStyles, IconButton, Tooltip
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'

import { CommentIcon, LikeIcon, ApproveIcon, DenyIcon, SaveIcon, ResubmitIcon } from '../ui/custom'
import { CancelRounded, SwapHorizontalCircle } from '@material-ui/icons'

import {
  getArtifactDetails,
  setReviewArtifact, clearNotifications, getNotificationsLargeBatch,
  getNotificationsSmallBatch, getUserLEDOptions, getProfileEditData, getUserLWOptions
} from '../../redux/actions'
import { NotificationToast, NavBar } from '../ui/tools'
import { notifications as EdfarmNotifications, getActionWording } from '../../lib'
import { userRoleIDs as EdfarmRoles } from '../../utils'

const useStyles = makeStyles((theme) => ({
  clearBtn: {
    color: theme.palette.purple.darkest,
    textDecoration: 'none',
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '16px'
  },
  artifactNameSpan: {
    color: theme.palette.purple.darkest,
    fontWeight: 600,
    textTransform: 'none',
    marginLeft: '.2em',
    cursor: 'pointer'
  },
  notificationTime: {
    color: theme.palette.grey.dark,
    fontWeight: 400,
    textTransform: 'none',
    marginLeft: '.8em',
    cursor: 'pointer'
  },
  notificationIconBadge: {
    backgroundColor: theme.palette.background.paper,
    padding: 0,
    maxWidth: theme.spacing(2.0),
    maxHeight: theme.spacing(2.0),
    minWidth: 0
  },
  horizontalIcon: {
    fontSize: theme.spacing(3),
    color: theme.palette.purple.darkest,
    borderRadius: '50%'
  },
  notificationIcon: {
    fontSize: theme.spacing(1.25),
    backgroundColor: theme.palette.purple.darkest,
    border: `3px solid ${theme.palette.purple.darkest}`,
    borderRadius: '50%',
    padding: theme.spacing(0.25),
    fill: theme.palette.background.paper
  },
  resubmitIcon: {
    fontSize: theme.spacing(1.5),
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.purple.darkest,
    borderRadius: '50%',
    padding: theme.spacing(0.5)
  },
  iconClearBtn: {
    padding: theme.spacing(1)
  }
}))

const NotificationsView = (props) => {
  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()
  const parsedProps = queryString.parse(props.location.search)
  const profileID = parsedProps.user

  const userNotificationsLarge = useSelector(state => state.userDetails.userNotificationsLarge)
  const { roleID: authRole, userID: authUserID } = useSelector(state => state.auth)

  const [notificationsArray, setNotificationsArray] = useState([])

  useMemo(() => {
    if (userNotificationsLarge && Boolean(userNotificationsLarge.length)) { setNotificationsArray(userNotificationsLarge) }
  }, [userNotificationsLarge])

  const getBadgeContent = (actionType) => {
    switch (actionType) {
      case EdfarmNotifications.ADMIN_COMMENT:
        return (
          <CommentIcon className={classes.notificationIcon} />
        )
      case EdfarmNotifications.ARTIFACT_APPROVED:
        return (
          <ApproveIcon className={classes.notificationIcon} />
        )
      case EdfarmNotifications.ARTIFACT_COMMENT:
        return (
          <CommentIcon className={classes.notificationIcon} />
        )
      case EdfarmNotifications.ARTIFACT_CHANGES_REQUESTED:
        return (
          <SwapHorizontalCircle color='inherit' className={classes.horizontalIcon} />
        )
      case EdfarmNotifications.ARTIFACT_DENIED:
        return (
          <DenyIcon className={classes.notificationIcon} />
        )
      case EdfarmNotifications.ARTIFACT_LIKED:
        return (
          <LikeIcon className={classes.notificationIcon} />
        )
      case EdfarmNotifications.ARTIFACT_RESUBMITTED:
        return (
          <ResubmitIcon className={classes.notificationIcon} />
        )
      case EdfarmNotifications.ARTIFACT_SAVED:
        return (
          <SaveIcon className={classes.notificationIcon} />
        )
      case EdfarmNotifications.USER_ARTIFACT_COMMENT:
        return (
          <CommentIcon className={classes.notificationIcon} />
        )
      default: return ''
    }
  }

  const handleClickAway = () => {
    dispatch(getNotificationsSmallBatch())
  }

  const handleUserView = (profileID) => {
    props.history.push({
      pathname: '/profile',
      search: `?user=${profileID}`
    })
    handleClickAway()
  }

  const defaultFilter = {
    creatorID: profileID,
    sortCount: null,
    offset: 0
  }
  const getProjectOptions = () => {
    dispatch(getUserLWOptions({ ...defaultFilter, type: 'lw' }, () => { }, () => NotificationToast(true, 'Failed to get Learner Works for Project creation!')))
    dispatch(getUserLEDOptions({ ...defaultFilter, type: 'led' }, () => { }, () => NotificationToast(true, 'Failed to get Learner Experience Design for Project creation!')))
  }

  const handleArtifactView = (notification) => {
    const {
      artifactID, authorID, authorProfileID, artifactType, artifactNotificationID, actionType
    } = notification
    let searchPath = ''

    // First check to see if they are dealing with a public comment
    if (!actionType || actionType === EdfarmNotifications.ARTIFACT_COMMENT || actionType === EdfarmNotifications.USER_ARTIFACT_COMMENT || actionType === EdfarmNotifications.ARTIFACT_LIKED || actionType === EdfarmNotifications.ARTIFACT_SAVED) {
      // route to public view for comments
      props.history.push({
        pathname: '/artifact',
        search: `?public=${artifactType}&user=${authorProfileID}&artifact=${artifactID}`
      })
      return true
    }

    /*  NOTE: authorID here refers to the author of the artifact the notification is referring to, not the notification generator */
    if (authorID === authUserID) {
      searchPath = `?create=${artifactType}&submission=yes&editing=true`
    } else if (authRole === EdfarmRoles.ADMIN_ROLE) {
      searchPath = `?review=${artifactType}`
      dispatch(setReviewArtifact(true, artifactNotificationID))
    }

    if (artifactID) {
      if (artifactType && artifactType === 'Learning Experience Design') { getUserLWOptions({ ...defaultFilter, type: 'lw' }) }

      if (artifactType && artifactType === 'Learner Work') {
        const defaultLEDFilter = { creatorID: profileID, type: 'led', sortCount: null, offset: 0 }
        dispatch(getUserLEDOptions(defaultLEDFilter))
      }

      if (artifactType && artifactType === 'Project') { getProjectOptions() }

      dispatch(getProfileEditData(() => { }, () => NotificationToast(true, 'Failed to get profile edit data!')))
    }

    props.history.push({
      pathname: '/artifact',
      search: searchPath
    })

    dispatch(getArtifactDetails(artifactID, '', artifactType, () => { }, () => { }, ''))
    handleClickAway()
  }

  const handleClearAll = () => {
    const notificationVals = notificationsArray.reduce((arr, notificationInfo) => {
      if (notificationInfo && notificationInfo.artifactNotificationID) { arr.push(notificationInfo.artifactNotificationID) }
      return arr
    }, [])

    if (notificationVals && Boolean(notificationVals.length)) {
      dispatch(clearNotifications(notificationVals, () => fireSuccessAll(notificationVals), fireFailureAll))
    } else {
      fireFailureAll()
    }
  }

  const handleClearNotification = (notificationID) => {
    if (notificationID) {
      dispatch(clearNotifications([notificationID], () => fireSuccess(notificationID), fireFailure))
    } else {
      fireFailure()
    }
  }

  const fireSuccessAll = (notificationVals = []) => {
    NotificationToast(false, 'Cleared all notifications!')
    const tempNotifications = [...notificationsArray]
    const newNotifications = tempNotifications.filter(
      (notificationInfo) => !notificationVals.find((notificationID) => notificationID === notificationInfo.artifactNotificationID)
    )
    setNotificationsArray(newNotifications)
    dispatch(getNotificationsLargeBatch())
    dispatch(getNotificationsSmallBatch())
  }
  const fireFailureAll = () => NotificationToast(true, 'Failed to clear all notifications!')

  const fireSuccess = (notificationID) => {
    NotificationToast(false, 'Done!')
    const tempNotifications = [...notificationsArray]
    const newNotifications = tempNotifications.filter((notificationInfo) => notificationInfo.artifactNotificationID !== notificationID)
    setNotificationsArray(newNotifications)
    dispatch(getNotificationsLargeBatch())
    dispatch(getNotificationsSmallBatch())
  }
  const fireFailure = () => NotificationToast(true, 'Failed to clear!')

  const [navRef, setNavRef] = useState(null)

  const navHeight = navRef ? navRef.clientHeight : 0
  return (
    <>
      {/* Nav */}
      <NavBar location={parsedProps} profileID={profileID} setNavRef={setNavRef} navHeight={navHeight} />

      {/* Page Content */}
      <Grid container direction='row' justifyContent='center' style={{ marginTop: '4em', marginBottom: '4em' }}>

        {/* Main Notification Area */}
        <Grid item container direction='column' xs={10} md={8} style={{ marginTop: '3em' }}>

          {/* Header */}
          <Grid item>
            <Grid container direction='row' justifyContent='space-between'>
              <Grid item>
                <Typography variant='h2'>Notifications</Typography>
              </Grid>
              <Grid item>
                <Button
                  variant='text'
                  disabled={notificationsArray ? notificationsArray.length === 0 : false}
                  className={classes.clearBtn}
                  onClick={handleClearAll}
                >
                  Clear All
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Divider style={{ marginTop: '1.5em' }} />

          {/* Notification Area */}
          {
            notificationsArray && Boolean(notificationsArray.length)
              ? (
                // Map through notifications, if present
                notificationsArray.map((notification) => {
                  const {
                    artifactNotificationID, actionType, fullName, artifactTitle,
                    artifactComment, publicComment, profileAvatarPath, profileID, createdAt: notificationCreatedAt
                  } = notification

                  return (
                    <Grid item key={`large-batch-notification-${artifactNotificationID}`}>
                      <Grid container direction='row' style={{ marginTop: '1em' }}>
                        {/* Notification Originator's Avatar with overlapping action-type badge */}
                        <Grid item xs={1}>
                          <Badge
                            overlap='circular'
                            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                            color='primary'
                            classes={{
                              colorPrimary: classes.notificationIconBadge
                            }}
                            badgeContent={
                              getBadgeContent(actionType)
                            }
                          >
                            <Avatar
                              src={`${profileAvatarPath && profileAvatarPath !== '' ? profileAvatarPath : ''}`}
                              onClick={() => handleUserView(profileID)}
                              style={{
                                cursor: 'pointer'
                              }}
                            />
                          </Badge>
                        </Grid>

                        <Grid item xs={10}>
                          <Grid container direction='row'>
                            <Grid container item direction='column'>

                              {/* Originator's Full Name */}
                              <Grid item>
                                <Typography variant='h5' style={{ color: theme.palette.black, textTransform: 'none', fontWeight: 600 }}>{fullName}</Typography>
                              </Grid>

                              <Grid item>
                                <Grid container direction='row'>

                                  {/* Action Type */}
                                  <Grid item>
                                    <Typography>
                                      {getActionWording(actionType)}
                                      {/* Artifact Name */}
                                      <Typography
                                        variant='h6'
                                        display='inline'
                                        component='span'
                                        className={classes.artifactNameSpan}
                                        onClick={() => handleArtifactView(notification)}
                                      >
                                        {artifactTitle}
                                      </Typography>
                                      {/* Time since action taken */}
                                      <Typography
                                        variant='body1'
                                        display='inline'
                                        component='span'
                                        className={classes.notificationTime}
                                      >
                                        {`${moment(notificationCreatedAt * 1000).fromNow()}`}
                                      </Typography>
                                    </Typography>
                                  </Grid>

                                </Grid>
                              </Grid>

                              {/* If the action type is a comment, preview the comment text */}
                              {artifactComment && artifactComment !== '' &&
                                <Grid item container direction='row'>
                                  <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontStyle: 'italic' }}>
                                    {`"${artifactComment && artifactComment.length && artifactComment.length > 20 ? `${artifactComment.substring(0, 20)}...` : notification.artifactComment}"`}
                                  </Typography>
                                </Grid>}
                              {publicComment && publicComment !== '' &&
                                <Grid item container direction='row'>
                                  <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontStyle: 'italic' }}>
                                    {`"${publicComment && publicComment.length && publicComment.length > 20 ? `${publicComment.substring(0, 20)}...` : notification.publicComment}"`}
                                  </Typography>
                                </Grid>}

                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={1}>
                          <Grid container direction='row' justifyContent='flex-end' alignContent='center'>
                            <Tooltip enterDelay={500} placement='top' title='Clear?' arrow>
                              <IconButton className={classes.iconClearBtn} onClick={() => handleClearNotification(artifactNotificationID)}>
                                <CancelRounded style={{ color: theme.palette.grey.medium, fontSize: '20px' }} />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Divider style={{ marginTop: '1em' }} />
                    </Grid>
                  )
                })
              ) : (
                // No Notifications Found
                <Grid item container direction='row' justifyContent='center' style={{ marginTop: '3em' }}>
                  <Typography variant='h5' style={{ color: theme.palette.grey.medium }}>No Notifications</Typography>
                </Grid>
              )
          }
        </Grid>

      </Grid>
    </>
  )
}

export default withRouter(NotificationsView)
