import React from 'react'
import { useSelector } from 'react-redux'
import {
  Grid, Radio
} from '@material-ui/core'
import { sanitizedHTML, rubricValues, renderSkeletons } from '../../../../utils'
import { MCE_DETAILS } from '../../../../redux/types'

const RubricRow = (row) => {
  const {
    proficientText = '', basicText = '', developingText = '', autoFailed = false, autoFailRowIDs = [], isAssessorView = false, isSummaryView = true, handleGradeRow = () => { }, rubricID = '',
    classes = {}, formRow = {}, submissionReview = false, isResultsView = false
  } = row
  const assessorSelection = formRow?.assessorSelection || null

  const loadingState = useSelector(state => state.loadingState)
  const textColumns = [{ value: rubricValues.PROFICIENT, text: proficientText }, { value: rubricValues.BASIC, text: basicText }, { value: rubricValues.DEVELOPING, text: developingText }]
  const disableSelections = Boolean(submissionReview || isResultsView || (autoFailed && !autoFailRowIDs.includes(rubricID)))

  return (
    <Grid item container direction='row'>
      {textColumns.map((col, i) => {
        const { value, text } = col
        const sanitizedText = text ? sanitizedHTML(text) : ''
        return (
          <Grid key={`${i}-rubric-text`} item container xs={4} direction='row' alignItems='flex-start' style={{ padding: '0 .5em' }}>
            <Grid item xs={(isAssessorView || isResultsView) ? 11 : null} style={{ padding: '.5em' }}>
              {loadingState.type === MCE_DETAILS && loadingState.loading === true
                ? (
                  <>
                    {renderSkeletons(2, 'wave', 40, isSummaryView ? 350 : 200)}
                  </>
                )
                : <div dangerouslySetInnerHTML={{ __html: sanitizedText }} className={classes.workSampleHTML} />}
            </Grid>
            {(isAssessorView || isResultsView) && text &&
              <Grid item container justifyContent='center' style={{ margin: 0 }} xs={1}>
                <Radio
                  size='small'
                  checked={assessorSelection === value}
                  disabled={disableSelections}
                  onClick={(e) => { e.preventDefault(); handleGradeRow(rubricID, value) }}
                  classes={{ checked: classes.readOnlyRadios }}
                />
              </Grid>}
          </Grid>
        )
      })}
    </Grid>
  )
}

export default RubricRow
