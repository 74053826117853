export const GET_ADMIN_ARTIFACTS = 'GET_ADMIN_ARTIFACTS'
export const GET_ADMIN_COHORTS = 'GET_ADMIN_COHORTS'
export const GET_ADMIN_DISTRICTS = 'GET_ADMIN_DISTRICTS'
export const GET_ADMIN_SIGNUPS = 'GET_ADMIN_SIGNUPS'
export const GET_ADMIN_FELLOWS = 'GET_ADMIN_FELLOWS'
export const GET_ADMIN_SCHOOLS = 'GET_ADMIN_SCHOOLS'
export const GET_ADMIN_SUBJECTS = 'GET_ADMIN_SUBJECTS'
export const GET_ADMIN_CERTS = 'GET_ADMIN_CERTS'
export const SET_REVIEW_ARTIFACT = 'SET_REVIEW_ARTIFACT'
export const GET_REVIEW_ARTIFACT_STATUS = 'GET_REVIEW_ARTIFACT_STATUS'
export const UPDATE_REVIEW_ARTIFACT = 'UPDATE_REVIEW_ARTIFACT'
export const RESET_REVIEW_ARTIFACT = 'RESET_REVIEW_ARTIFACT'
export const GET_ADMIN_USER_LIST = 'GET_ADMIN_USER_LIST'
export const GET_ADMIN_EF_RESOURCES = 'GET_ADMIN_EF_RESOURCES'
export const GET_FEATURED_LED_OPTIONS = 'GET_FEATURED_LED_OPTIONS'
export const UPDATE_BANNER_IMAGE = 'UPDATE_BANNER_IMAGE'
export const GET_ADMIN_ASSESSORS = 'GET_ADMIN_ASSESSORS'
export const GET_ADMIN_EVALUATIONS = 'GET_ADMIN_EVALUATIONS'
export const GET_ADMIN_MCES = 'GET_ADMIN_MCES'
