import React from 'react'

import {
  Grid, Dialog, DialogTitle, DialogContent, IconButton, Typography, Button
} from '@material-ui/core'

import { CloseRounded } from '@material-ui/icons'

import { artifactTypes } from '../../../utils'

const ArtifactCancelModal = (props) => {
  const {
    classes, theme, isEditing = false, isForMCE = false, artifactType = '',
    artifactCancelModalOpen = false, setArtifactCancelModalOpen = () => {},
    handleCancelConfirm = () => {}, handleSaveAndExit = () => {},
    nameError, setNameError, artifactNoNameCheck
  } = props

  return (
    <>
      <Dialog
        classes={{ paper: classes.paper }}
        fullWidth
        maxWidth='xs'
        open={artifactCancelModalOpen}
        onClose={(e) => { e.preventDefault(); setArtifactCancelModalOpen(false); setNameError(false) }}
      >

        <DialogTitle disableTypography>
          <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
            <Grid item>
              <IconButton style={{ padding: '0px' }} onClick={(e) => { e.preventDefault(); setArtifactCancelModalOpen(false); setNameError(false) }}>
                <CloseRounded className={classes.customizedButton} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Grid item container direction='row'>
            <Typography variant='h3' style={{ color: theme.palette.purple.darkest }}>
              {!isEditing && artifactType === artifactTypes.LED ? 'This artifact is not complete.' : `Are you sure you want to cancel ${isEditing ? 'editing' : 'creating'} this artifact?`}
            </Typography>
          </Grid>

          <Grid item container direction='row' style={{ marginBottom: '1rem' }}>
            <Typography variant='h5'>
              {
                !isEditing && artifactType === artifactTypes.LED ? 'Would you like to save it as a draft?'
                  : `No ${isEditing ? 'changes' : 'entries'} will be saved.`
              }
            </Typography>
          </Grid>

          <Grid item container direction='row' justifyContent='flex-end'>
            <Button
              onClick={(e) => { e.preventDefault(); handleCancelConfirm() }}
              style={{
                color: theme.palette.purple.darkest,
                marginRight: artifactType === (artifactTypes.LED || artifactType === artifactTypes.PRO) && !isEditing && !isForMCE ? '1rem' : '',
                textTransform: 'none',
                textDecoration: 'underline',
                fontWeight: '600',
                fontSize: '.9rem'
              }}
            >
              Exit without Saving
            </Button>

            {
              (artifactType === artifactTypes.LED || artifactType === artifactTypes.PRO) && !isEditing && (
                <Button
                  onClick={(e) => { e.preventDefault(); artifactNoNameCheck ? setNameError(true) : handleSaveAndExit() }}
                  variant='contained'
                  style={{ backgroundColor: theme.palette.purple.darkest }}
                >
                  Save
                </Button>
              )
            }

          </Grid>
          {nameError &&
            <Grid item container direction='row' justifyContent='flex-end' style={{ paddingBottom: '1em' }}>
              <Typography variant='caption' style={{ color: 'red' }}>* Must include artifact name to save & exit.</Typography>
            </Grid>}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ArtifactCancelModal
