import React from 'react'
import { Grid, Typography, Button } from '@material-ui/core'

/* ********** Empty Inbox Message ************* //
    Rendered from ConversationArea.js when the 'view.conversation' is 'empty' or (currently) ''
    --- Contains action buttons for composing a new message
*/

const NoConversations = (props) => {
  const { classes, loadNewView, viewTypes } = props

  return (
    <Grid item container direction='column' alignItems='center'>
      <Grid item className={classes.noConvosTextGrid}>
        <Typography variant='h6' className={classes.noConvosText}>No Conversations Available</Typography>
      </Grid>
      <Grid item style={{ marginTop: '1em' }}>
        <Button variant='contained' color='primary' onClick={() => loadNewView(viewTypes.COMPOSE)}>New Message</Button>
      </Grid>
    </Grid>
  )
}

export default NoConversations
