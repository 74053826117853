import React from 'react'

import { AppBar, Toolbar, Grid, Typography, Avatar, IconButton } from '@material-ui/core'

import {
  HowToReg as HowToRegIcon, CloseRounded as ExitIcon, Lock as PrivateIcon
} from '@material-ui/icons'

const ArtifactNav = (props) => {
  const {
    styles, parsedProps, artifactType = '', statusBanner = '', authProfileAvatarPath = '',
    creatingArtifact = false, reviewingArtifact = false, publicArtifact = false, isEditing = false,
    handleProfileView = () => {}, setArtifactCancelModalOpen, reviewInfo = {}
  } = props

  // Check to render draft status for artifact
  const renderDraftStatus = parsedProps && parsedProps.draft ? JSON.parse(parsedProps.draft) : false
  // Check to render the lock icon on private artifacts
  const renderLockIcon = isEditing && reviewInfo && Boolean(Number(reviewInfo.isPrivate))

  return (
    <>
      {/* Top Nav  */}
      <AppBar style={{ zIndex: 10 }}>
        <Toolbar style={{ backgroundColor: styles.mainColor }}>
          <Grid container direction='row'>
            <Grid item xs={10} sm={8} md={6} container direction='row' alignContent='center' style={{ paddingLeft: '1em' }}>
              <Grid item>

                {creatingArtifact &&
                  <IconButton onClick={(e) => { e.preventDefault(); setArtifactCancelModalOpen(true) }}>
                    <ExitIcon style={{ color: 'white' }} />
                  </IconButton>}

                {publicArtifact && styles.bannerIcon}

                {reviewingArtifact && <HowToRegIcon style={{ color: 'white' }} />}

              </Grid>
              <Grid item style={{ marginLeft: '.5em', display: 'flex', alignItems: 'center' }}>
                {renderLockIcon && <PrivateIcon style={{ height: '.6em', width: '.6em', marginRight: '.5rem' }} />}

                {creatingArtifact || publicArtifact
                  ? (
                    <Typography variant='h4' style={{ color: 'white' }}>{artifactType}</Typography>
                  )
                  : (
                    <Typography variant='h4' style={{ color: 'white' }}>Artifact Review</Typography>
                  )}
              </Grid>
              {renderDraftStatus
                ? <Grid item style={{ marginLeft: '.5em', display: 'flex', alignItems: 'center' }}><Typography variant='body1' style={{ fontWeight: 600, color: styles.inactiveText }}>Draft</Typography></Grid>
                : !publicArtifact && statusBanner && statusBanner !== null ? statusBanner
                  : ''}

            </Grid>
            <Grid container item xs={2} sm={4} md={6} justifyContent='flex-end' style={{ paddingRight: '1em' }}>
              <IconButton disabled={!publicArtifact} onClick={handleProfileView}>
                <Avatar src={!authProfileAvatarPath ? '' : authProfileAvatarPath} style={{ backgroundColor: 'white' }} />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default ArtifactNav
