import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import queryString from 'query-string'

import {
  Grid, Typography, OutlinedInput, InputLabel, FormControl, Button, IconButton
} from '@material-ui/core'

import { Visibility, VisibilityOff } from '@material-ui/icons'

import { makeStyles } from '@material-ui/core/styles'

import { NotificationToast } from '../ui/tools'
import { resetPassword } from '../../redux/actions'
import edFarmLogo from '../../assets/edFarmLogo.png'
import teacherImg from '../../assets/teacher.svg'
import { ValidateEmail, formValidationTypes } from '../../lib'
import { defaultErrors } from '../../utils'

const useStyles = makeStyles((theme) => ({
  heading: {
    color: theme.palette.purple.darkest
  },
  darkBackground: {
    backgroundColor: theme.palette.purple.darkest
  }
}))

const PasswordReset = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const parsedProps = queryString.parse(props.location.search)

  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const defaultPasswordResetErr = defaultErrors.passwordResetErr

  const [errors, setErrors] = useState({ ...defaultPasswordResetErr })

  const handleReset = async () => {
    try {
      const { passwordKey, emailAddress } = parsedProps

      if (!passwordKey || !emailAddress || (passwordKey && passwordKey.length !== 20) || !ValidateEmail(emailAddress)) {
        setErrors({ ...defaultPasswordResetErr, missingKeys: true })
        return false
      }

      if (password === '') {
        setErrors({ ...defaultPasswordResetErr, fieldsMissingError: true })
        return false
      }

      setErrors(defaultPasswordResetErr)

      dispatch(resetPassword(emailAddress, password, passwordKey, fireSuccess, fireValidation))
    } catch (err) {
      setErrors({ ...defaultPasswordResetErr, missingKeys: true })
    }
  }

  const fireSuccess = () => {
    props.history.push('/')
    NotificationToast(false, 'Password reset!')
  }

  const fireValidation = (errorType) => {
    if (errorType === formValidationTypes.PASSWORD_RESET_ERROR) { setErrors({ ...defaultPasswordResetErr, passwordResetError: true }) }
    if (errorType === formValidationTypes.VALIDATION_KEY_ERROR) { setErrors({ ...defaultPasswordResetErr, validationKeyError: true }) }
    if (errorType === formValidationTypes.NO_EMAIL_FOUND) { setErrors({ ...defaultPasswordResetErr, emailNotFound: true }) }
  }

  const handleEnter = (e) => {
    if (e.key) {
      if (e.key.toUpperCase() === 'ENTER') {
        handleReset()
      }
    }
  }

  return (
    <>
      <Grid container direction='row'>
        <Grid item xs={4} className={classes.darkBackground} style={{ height: '100vh', width: '100%' }}>
          <Grid container direction='column' style={{ justifyContent: 'space-between', height: '100vh' }}>
            <Grid item style={{ width: '100%', marginLeft: '2em', marginTop: '2em' }}>
              <Grid item>
                <img src={edFarmLogo} alt='logo' style={{ maxHeight: '3.7em' }} />
              </Grid>
              <Grid item style={{ marginTop: '3em' }} container xs={10}>
                <Typography variant='h1' style={{ color: 'white', fontWeight: '600' }}>Gain inspiration and learn from other</Typography>
                <Typography variant='h1' style={{ color: 'white', fontWeight: '600' }}>educators</Typography>
              </Grid>
            </Grid>
            <Grid item container alignContent='flex-end'>
              <Grid item container style={{ width: '100%', height: 'auto' }}>
                <img style={{ height: '100%', width: '150%', marginLeft: '-7em' }} src={teacherImg} alt='Teacher Fellow' />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={8}>
          <Grid item container direction='column' alignContent='center' justifyContent='center' style={{ margin: '0 9em 3em', paddingRight: '20em', marginTop: '6vh' }}>
            <Grid item container direction='row' alignItems='center' justifyContent='center' style={{ display: 'flex', marginTop: '25vh' }}>
              <Grid item xs={8}>
                <Grid item container direction='row' justifyContent='center'>
                  <Typography variant='h2'>Reset Password</Typography>
                </Grid>
              </Grid>

              <Grid item xs={8} style={{ marginTop: '4rem' }}>
                <InputLabel>New Password</InputLabel>
                <FormControl fullWidth margin='dense'>
                  <OutlinedInput
                    id='login-password'
                    variant='outlined'
                    size='small'
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleEnter}
                    inputProps={{ style: { border: 'none' } }}
                    endAdornment={
                      <IconButton
                        aria-label='toggle password visibility'
                        style={{ padding: '8px' }}
                        tabIndex='-1'
                        onClick={(e) => {
                          e.preventDefault()
                          setShowPassword(!showPassword)
                        }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={8} style={{ marginTop: '1rem', marginBottom: '-1rem' }}>
                {
                  errors.fieldsMissingError && (
                    <Typography variant='caption' style={{ color: 'red', marginTop: '.5rem' }}>* Please be sure you have provided a new password.</Typography>
                  )
                }

                {
                  errors.passwordResetError && (
                    <Typography variant='caption' style={{ color: 'red', marginTop: '.5rem' }}>* Unable to reset password.</Typography>
                  )
                }

                {
                  errors.missingKeys && (
                    <Typography variant='caption' style={{ color: 'red', marginTop: '.5rem' }}>* Invalid link properties.</Typography>
                  )
                }

                {
                  errors.emailNotFound && (
                    <Typography variant='caption' style={{ color: 'red', marginTop: '.5rem' }}>* No account found.</Typography>
                  )
                }

                {
                  errors.validationKeyError && (
                    <>
                      <Typography variant='caption' style={{ color: 'red', marginTop: '.5rem' }}>* Password reset key has expired.</Typography>
                    </>
                  )
                }
              </Grid>

              <Grid item xs={8} style={{ marginTop: '2rem' }}>
                <Button
                  fullWidth
                  color='primary'
                  variant='contained'
                  onClick={(e) => {
                    e.preventDefault()
                    handleReset()
                  }}
                >
                  Reset Password
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default withRouter(PasswordReset)
