import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Grid, Typography, Button, makeStyles, useTheme,
  useMediaQuery, Paper, Divider
} from '@material-ui/core'
import { Apps, CollectionsBookmark } from '@material-ui/icons'
import queryString from 'query-string'
import { NavBar } from '../ui/tools'
import BlueBannerImage from '../../assets/BlueAquaBanner.svg'
import { MVPHelpModal } from '../ui/MCE'
import { getMCEList, getUserCurrentMCEAttempts, getCurrentMCEAssignments, updateAssessorAttemptStatus, updateUserData } from '../../redux/actions'
import { attemptStatuses, mceViews, mceStatusLabels } from '../../utils/variables'
import moment from 'moment'
import DashboardStackAccordion from '../ui/MCE/MCETools/DashboardStackAccordion'

const useStyles = makeStyles((theme) => {
  return ({
    sidebarSection: {
      width: 'inherit',
      margin: '.5em 0'
    },
    sideBarSectionTitle: {
      paddingRight: '.5em'
    },
    bannerTextAreaTop: {
      padding: '3em 6em .5em 0',
      [theme.breakpoints.down('md')]: {
        padding: '3em 1em .5em 0'
      }
    },
    bannerTextAreaBottom: {
      padding: '1em 6em 3em 0',
      [theme.breakpoints.down('md')]: {
        padding: '1em 1em 3em 0'
      }
    },
    bannerSubText: {
      textAlign: 'end',
      marginLeft: '60%',
      fontWeight: 400,
      flexWrap: 'wrap-reverse',
      color: 'white',
      fontSize: 24,
      [theme.breakpoints.down('md')]: {
        marginLeft: '10%',
        fontSize: 18
      }
    },
    topParentContainer: {

    },
    headerContainer: {
      backgroundImage: `url(${BlueBannerImage})`,
      backgroundRepeat: 'none',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      zIndex: 1100
    },
    bodyContent: {

    },
    mainContentShift: {
      padding: '1em',
      [theme.breakpoints.up('md')]: {
        padding: '1em 6em 1em 1.5em'
      }
    },
    dashboard: {
      padding: '1.5em',
      backgroundColor: theme.palette.grey.lighter,
      overflowY: 'scroll',
      minHeight: '100vh'
    }
  })
})

const CredentialDashboard = (props) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const parsedProps = queryString.parse(props.location.search)
  const mdScreenUp = useMediaQuery(theme.breakpoints.up('md'))
  const { isAssessor = 0, onboardingStep } = useSelector(state => state.auth)
  const { mceList = [] } = useSelector(state => state.microcredentials)
  const { currentAssignments = [] } = useSelector(state => state.mceAssessor)
  const { currentAttempts = [] } = useSelector(state => state.mceAttempts)

  // ******************* NAV CONDITIONALS **************************** //
  const [navRef, setNavRef] = useState(null)
  const navHeight = navRef ? navRef.clientHeight : 0

  const [helpModalOpen, setHelpModalOpen] = useState(false)

  const classes = useStyles()

  // when first landing on the page, check to see if the user has previously seen the mce page via the onboardingStep
  useEffect(() => {
    // if they have not, auto open the welcome modal
    if (onboardingStep < 3) {
      setHelpModalOpen(true)
    }
  }, [onboardingStep])

  // if we are in the dashboard view, be sure to fetch the user's current attmepts
  useEffect(() => {
    if (!parsedProps?.view) {
      // replace the current set with the new set
      props.history.replace({
        search: `&view=${mceViews.DASHBOARD}`
      })
    }
  }, [parsedProps.view, props.history])

  // if we are in the dashboard view, be sure to fetch the user's current attmepts
  useEffect(() => {
    if (parsedProps?.view && parsedProps.view === mceViews.DASHBOARD) {
      dispatch(getMCEList())
      dispatch(getUserCurrentMCEAttempts())
    }
  }, [parsedProps.view, dispatch])

  // ref to indicate we are pulling an assessor current assignments
  const fetchingAssignments = useRef(false)
  // initial ref to fetch assessor assignments as soon as we hit the page
  const firstFetch = useRef(true)

  // if we are in the summary view, fetch asssessor status and any current assignments for the assessor
  useEffect(() => {
    if (!fetchingAssignments.current && firstFetch.current) {
      dispatch(getCurrentMCEAssignments(() => { firstFetch.current = false }))
    }
  }, [dispatch])

  // Check again for these assignments every 60 seconds
  useEffect(() => {
    if (!parsedProps && !firstFetch.current) {
      fetchingAssignments.current = true
      const interval = setInterval(() => {
        dispatch(getCurrentMCEAssignments())
      }, 10000)
      return () => { clearInterval(interval); fetchingAssignments.current = false }
    }
  }, [dispatch, parsedProps])

  // reset local refs on unmount
  useEffect(() => { return () => { fetchingAssignments.current = false; firstFetch.current = true } }, [])

  // uses the values determined in either handlePusuerAction or the post new attempt fireSuccess to decide which params should be pushed
  const handleViewChange = (view, mceID, attemptID = null) => {
    window.scrollTo(0, 0)
    props.history.push({
      pathname: '/microcredentials',
      search: `?view=${view}&mce=${mceID}${attemptID ? `&attempt=${attemptID}` : ''}`
    })
  }

  // move to the attempt process as an assessor
  const handleAssessorView = (mceID, attemptID) => {
    // dispatch to set the attempt status as 'U'
    dispatch(updateAssessorAttemptStatus(attemptID, () => { handleViewChange(mceViews.ASSESS, mceID, attemptID) }))
  }

  // when closing the modal, set their onboardingStep to reflect that they have now viewed the modal
  const onModalClose = () => {
    setHelpModalOpen(false)
    const firstViewBool = onboardingStep < 3

    if (firstViewBool) {
      return dispatch(updateUserData('onboarding-latest', { onboardingStep: 3 }))
    }
  }

  const getCompletedButtonLabel = (status) => {
    if (status !== attemptStatuses.SUBMITTED && status !== attemptStatuses.UNDER_REVIEW) {
      return 'View Results'
    }

    const label = mceStatusLabels[status] || 'Pending'
    return label
  }

  const inProgressAttempts = currentAttempts && currentAttempts.length ? currentAttempts.filter(att => att.attemptStatus === attemptStatuses.DRAFT) : []
  const completedAttempts = currentAttempts && currentAttempts.length ? currentAttempts.filter(att => att.attemptStatus !== attemptStatuses.DRAFT) : []

  return (
    <>
      <NavBar setNavRef={setNavRef} navHeight={navHeight} />
      <Grid container direction='column' className={classes.topParentContainer} style={{ marginTop: navHeight || '4em' }}>
        {/* Header section */}
        <Grid
          item
          container
          direction='column'
          alignItems='flex-end'
          className={classes.headerContainer}
        >
          <Grid item className={classes.bannerTextAreaTop}>
            <Typography variant='h1' gutterBottom style={{ textAlign: 'end', fontWeight: 600, color: 'white', fontSize: mdScreenUp ? 48 : 36 }}>Welcome to Micro-Credentials!</Typography>
            <Typography variant='h4' className={classes.bannerSubText}>
              Browse the options below to take advantage of Ed Farm's exciting new feature that supports and promotes competency-based growth for educators.
            </Typography>
          </Grid>
          <Grid item className={classes.bannerTextAreaBottom}>
            <Button size='large' variant='contained' color='primary' style={{ fontWeight: 600 }} onClick={() => setHelpModalOpen(true)}>Learn More</Button>
          </Grid>
        </Grid>

        {/* Main content */}
        <Grid
          item
          container
          direction={mdScreenUp ? 'row' : 'column'}
          className={classes.bodyContent}
        >
          <Grid
            container
            className={classes.dashboard}
            item xs={mdScreenUp ? 3 : null}
            direction='column'
          >
            {/* Drawer header (controls collapsable state) */}
            <Grid item container direction='row' style={{ paddingBottom: '1em' }}>
              <Grid item container alignContent='center' justifyContent='center' style={{ backgroundColor: theme.palette.purple.darkest, borderRadius: '50%', height: '2em', width: '2em' }}>
                <Apps style={{ color: 'white', fontSize: '22px' }} />
              </Grid>
              <Grid item style={{ marginLeft: '1em' }}>
                <Typography variant='h5' style={{ fontSize: 24, color: theme.palette.grey.darkest }}>My Dashboard</Typography>
              </Grid>
            </Grid>

            {/* Dashboard sections (not viewable when drawer is closed) */}
            <Grid item container direction='column' className={classes.sidebarSection}>
              {/* ******* ASSESSOR ASSIGNMENTS ******* */}
              {Boolean(isAssessor) &&
                <Grid item className={classes.sidebarSection}>
                  <Paper style={{ width: 'auto', border: `1px solid ${theme.palette.grey.light}` }}>
                    <Grid container direction='column'>
                      <Grid item container style={{ padding: '1em' }}>
                        <Typography variant='h4'>Assessor Assignments</Typography>
                      </Grid>
                      <Divider />
                      {currentAssignments && currentAssignments.length
                        ? (
                          currentAssignments.map((assignment) => {
                            const { attemptID, mceTitle, shortID, mceID } = assignment
                            return (
                              <Grid key={`assignment-attempt-${attemptID}`} item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ padding: '1em' }}>
                                <Grid item container xs={8} direction='column' className={classes.sideBarSectionTitle}>
                                  <Typography noWrap style={{ fontWeight: 600, width: 'inherit' }}>{mceTitle}</Typography>
                                  <Typography>{shortID}</Typography>
                                </Grid>
                                <Grid item container direction='row' xs={4} justifyContent='flex-end'>
                                  <Button
                                    variant='contained'
                                    color='primary'
                                    size='small'
                                    style={{ fontWeight: 600 }}
                                    onClick={() => handleAssessorView(mceID, attemptID)}
                                  >
                                    Begin
                                  </Button>
                                </Grid>
                              </Grid>
                            )
                          })
                        )
                        : (
                          <Grid item container direction='row' justifyContent='center' alignItems='center' style={{ padding: '1.5em 0' }}>
                            <Typography style={{ color: theme.palette.grey.medium, fontWeight: 600 }}>No Current Assignments...</Typography>
                          </Grid>
                        )}
                    </Grid>
                  </Paper>
                </Grid>}

              {/* ******* IN PROGRESS ******* */}
              <Grid item className={classes.sidebarSection}>
                <Paper style={{ width: 'auto', border: `1px solid ${theme.palette.grey.light}` }}>
                  <Grid container direction='column'>
                    <Grid item container style={{ padding: '1em' }}>
                      <Typography variant='h4'>In Progress</Typography>
                    </Grid>
                    <Divider />
                    {
                      inProgressAttempts && inProgressAttempts.length
                        ? (
                          inProgressAttempts.map((att) => {
                            const { attemptID, mceID, title } = att
                            return (
                              <Grid key={`in-progress-${attemptID}`} item container direction='row' justifyContent='space-between' alignItems='center' style={{ padding: '1em' }}>
                                <Grid item container xs={8} direction='column' className={classes.sideBarSectionTitle}>
                                  <Typography noWrap style={{ fontWeight: 600, width: 'inherit' }}>{title}</Typography>
                                </Grid>
                                <Grid item container direction='row' xs={4} justifyContent='flex-end'>
                                  <Button
                                    variant='contained'
                                    color='secondary'
                                    size='small'
                                    style={{ fontWeight: 600 }}
                                    onClick={() => handleViewChange(mceViews.PURSUE, mceID, attemptID)}
                                  >
                                    Continue
                                  </Button>
                                </Grid>
                              </Grid>
                            )
                          })
                        )
                        : (
                          <Grid item container direction='row' justifyContent='center' alignItems='center' style={{ padding: '1.5em 0' }}>
                            <Typography style={{ color: theme.palette.grey.medium, fontWeight: 600 }}>No Attempts In Progress...</Typography>
                          </Grid>
                        )
                    }
                  </Grid>
                </Paper>
              </Grid>
              {/* ******** COMPLETED ATTEMPTS ******** */}
              <Grid item className={classes.sidebarSection}>
                <Paper style={{ width: 'auto', border: `1px solid ${theme.palette.grey.light}` }}>
                  <Grid container direction='column'>
                    <Grid item container style={{ padding: '1em' }}>
                      <Typography variant='h4'>Completed</Typography>
                    </Grid>
                    <Divider />
                    {completedAttempts && completedAttempts.length
                      ? (
                        completedAttempts.map((att) => {
                          const { attemptID, mceID, title, submittedAt, attemptCount, attemptStatus } = att
                          return (
                            <Grid key={`completed-attempt-${attemptID}`} item container direction='row' justifyContent='space-between' alignItems='center' style={{ padding: '1em' }}>
                              <Grid item container xs={7} direction='column' className={classes.sideBarSectionTitle}>
                                <Typography noWrap style={{ fontWeight: 600, width: 'inherit' }}>{title}</Typography>
                                <Typography variant='caption' style={{ color: theme.palette.grey.dark }}>{`Attempt ${attemptCount}`}</Typography>
                                <Typography variant='caption'>{`Submitted on ${moment(submittedAt * 1000).format('MM-DD-YYYY')}`}</Typography>
                              </Grid>
                              <Grid item container direction='row' xs={5} justifyContent='flex-end'>
                                <Button
                                  variant='contained'
                                  disabled={!attemptStatus || attemptStatus === attemptStatuses.UNDER_REVIEW || attemptStatus === attemptStatuses.SUBMITTED}
                                  color='primary'
                                  size='small'
                                  style={{ fontWeight: 600 }}
                                  onClick={() => handleViewChange(mceViews.RESULTS, mceID, attemptID)}
                                >
                                  {getCompletedButtonLabel(attemptStatus)}
                                </Button>
                              </Grid>
                            </Grid>
                          )
                        })
                      )
                      : (
                        <Grid item container direction='row' justifyContent='center' alignItems='center' style={{ padding: '1.5em 0' }}>
                          <Typography style={{ color: theme.palette.grey.medium, fontWeight: 600 }}>No Completed Attempts...</Typography>
                        </Grid>
                      )}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>

          {/* Stack accordions and mce lists */}
          <Grid
            item
            container
            direction='column'
            className={classes.mainContentShift}
            xs={mdScreenUp ? 9 : null}
          >
            <Grid item container direction='row' alignItems='center' style={{ marginTop: '.5em' }}>
              <Grid item container alignContent='center' justifyContent='center' style={{ backgroundColor: theme.palette.purple.darkest, borderRadius: '50%', height: '2em', width: '2em', marginRight: '.5em' }}>
                <CollectionsBookmark style={{ color: 'white', fontSize: '22px' }} />
              </Grid>
              <Typography variant='h4' style={{ fontSize: 26, color: theme.palette.grey.darker }}>Browse Micro-Credentials</Typography>
            </Grid>
            <Grid item container direction='column' spacing={3} style={{ marginTop: '.5em' }}>
              {mceList.map((stack) => {
                const { stackID } = stack
                const stackLength = mceList.length
                return (
                  <DashboardStackAccordion key={`stack-card-${stackID}`} stack={{ ...stack, stackLength }} handleViewChange={handleViewChange} />
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Addtional Information Modal  */}
      <MVPHelpModal
        classes={classes}
        onModalClose={onModalClose}
        helpModalOpen={helpModalOpen}
        navHeight={navHeight}
      />
    </>
  )
}

export default CredentialDashboard
