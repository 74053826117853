import {
  SET_EXPLORE_ACTIVE_TAB,
  SET_EXPLORE_SCROLL_POSITION,
  SET_SEARCH_EXPLORE_FILTER
} from '../types'

export const setExploreActiveTab = (tab) => {
  return async function (dispatch) {
    dispatch({
      type: SET_EXPLORE_ACTIVE_TAB,
      payload: tab
    })
  }
}

export const setExploreScrollPosition = (position) => {
  return async function (dispatch) {
    dispatch({
      type: SET_EXPLORE_SCROLL_POSITION,
      payload: position
    })
  }
}

export const setSearchExploreFilter = (filter) => {
  return async function (dispatch) {
    dispatch({
      type: SET_SEARCH_EXPLORE_FILTER,
      payload: filter
    })
  }
}
