/* eslint no-useless-escape: off */

export const patterns = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  facebook: /^(?:https?:\/\/)?(?:www\.|m\.|touch\.)?(?:facebook\.com|fb(?:\.me|\.com))\/(?!$)(?:(?:\w)*#!\/)?(?:pages\/)?(?:photo\.php\?fbid=)?(?:[\w\-]*\/)*?(?:\/)?(?:profile\.php\?id=)?([^\/?\s]*)(?:\/|&|\?)?.*$/i,
  instagram: /^(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/i,
  linkedin: /^(?:(?:http|https):\/\/)?(?:www.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i,
  twitter: /^(?:(?:http|https):\/\/)?(?:www.)?twitter.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i
}

export const ValidateEmail = (email = '') => {
  const isValid = patterns.email.test(email)
  return isValid
}

export const ValidateFacebook = (facebook = '') => {
  return patterns.facebook.test(facebook)
}

export const ValidateInstagram = (instagram = '') => {
  return patterns.instagram.test(instagram)
}

export const ValidateLinkedin = (linkedin = '') => {
  return patterns.linkedin.test(linkedin)
}

export const ValidateTwitter = (twitter = '') => {
  return patterns.twitter.test(twitter)
}
