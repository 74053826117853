import React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import { ConversationCard } from '../ConversationUtils'
import ButtonArea from '../../utils/ButtonArea'

/* ********** Lists the current list of conversations for a user ************* //
    Rendered from ConversationArea.js when the 'view.conversation' is 'list'
    --- Contains action buttons for composing a new message and switching to the 'drafts' view
    --- Maps through the conversation data object and renders a ConversationCard.js for each one
*/

const ConversationList = (props) => {
  const {
    theme, loadNewView, viewTypes, activeConversationID, view, parsedProps, classes,
    messagesInitialLoad, fetchNew, setFetchNew,
    setCurrentForm,
    editorOpen, setEditorOpen
  } = props

  const { conversations = [] } = useSelector(state => state.websocket)

  // Add restored conversation back into a temp array until user refreshes
  const updateRestoredMsgContent = (convoObj) => {
    const tempConversations = [...conversations]

    const restoredConvo = conversations.findIndex(x => x.conversationID === convoObj.conversationID)

    if (restoredConvo !== -1) {
      tempConversations[restoredConvo].mostRecentMessageContent = convoObj.msgContent
    }
  }

  return (
    <Grid item container direction='column'>
      <Grid item container>
        <ButtonArea theme={theme} loadNewView={loadNewView} viewTypes={viewTypes} setCurrentForm={setCurrentForm} />
      </Grid>

      <Grid item>
        {/* Checks to see which conversation in the list matches the currently selected active conversation */}
        {conversations.map((conversation) => {
          const { conversationID } = conversation
          const activeCard = activeConversationID && conversationID && conversationID === activeConversationID
          return (
            <ConversationCard
              key={conversation.conversationID}
              conversation={conversation}
              theme={theme}
              classes={classes}
              activeCard={activeCard}
              loadNewView={loadNewView}
              viewTypes={viewTypes}
              view={view}
              parsedProps={parsedProps}
              messagesInitialLoad={messagesInitialLoad}
              fetchNew={fetchNew}
              setFetchNew={setFetchNew}
              setCurrentForm={setCurrentForm}
              editorOpen={editorOpen}
              setEditorOpen={setEditorOpen}
              updateRestoredMsgContent={updateRestoredMsgContent}
            />
          )
        })}
      </Grid>

    </Grid>
  )
}

export default ConversationList
