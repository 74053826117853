/* eslint camelcase: off */

import { LOGIN, LOGOUT, REMOVE_ERROR } from '../types'
import { trackPromise } from 'react-promise-tracker'
import { parseResponse, responseTypes, formValidationTypes } from '../../lib'
import { getNotificationsSmallBatch } from './notifications'

import log from '../../utils/logger'

// UI Tech Debt: Removed api variable that wasn't being used anywhere.

const api = process.env.REACT_APP_API_URL
const apiversion = process.env.REACT_APP_API_VERSION

// Formulate a payload for redux from the data returned either from sso or regular login attempt
const handleLoginData = (data) => {
  const {
    token = '', userID = '', roleID = '', superAdmin = 0, inFellowTypeID = '',
    fullName = '', nickName = '', emailAddress = '',
    onboardingStep = 0, isActive = 0, isValidated = 0, isAccountApproved = 0,
    profileID = '', profileAvatarPath = '',
    likedArtifacts = [], savedArtifacts = [], isAssessor = 0
  } = data

  const payload = {
    token,
    userID,
    roleID,
    superAdmin,
    inFellowTypeID,
    fullName,
    nickName,
    emailAddress,
    onboardingStep,
    isActive,
    isValidated,
    isAccountApproved,
    profileID,
    profileAvatarPath,
    likedArtifacts,
    savedArtifacts,
    isAssessor
  }
  return payload
}

// Login using the user input form the form
export const login = (userName, password, fireNotification = () => { }) => {
  return async (dispatch) => {
    const body = { userName, password }

    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }

    const response = await trackPromise(window.fetch(`${api}/${apiversion}/auth/login`, options))
    const parsedResponse = parseResponse(response, dispatch, true)
    if (!parsedResponse) { return false }

    if (parsedResponse.type === responseTypes.SUCCESS) {
      const { data } = await response.json()

      const payloadData = handleLoginData(data)

      dispatch({
        type: LOGIN,
        payload: payloadData
      })
      dispatch(getNotificationsSmallBatch())
    } else {
      fireNotification(parsedResponse)
    }
  }
}

// Requests user info from google sso
export const loginGoogle = () => {
  return async function (dispatch) {
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      }
    }

    // Redirect to the registered request url
    await window.location.assign(`${api}/sso/google`, options)
  }
}

// Used to login a user who has been authorized through google sso
export const loginSSO = (userID, username, token, fireLoginSuccess = () => { }, fireLoginFailure = () => { }) => {
  return async function (dispatch) {
    try {
      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/auth/ssologin/${userID}/${username}`, options))
      const parsedResponse = parseResponse(response, dispatch, true)
      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const { data } = await response.json()

        const payloadData = handleLoginData(data)

        dispatch({
          type: LOGIN,
          payload: payloadData
        })

        fireLoginSuccess()
        dispatch(getNotificationsSmallBatch())
      } else {
        fireLoginFailure(parsedResponse?.code)
      }
    } catch (error) {
      log.error(error)
    }
  }
}

export const logout = () => {
  return async (dispatch, getState) => {
    try {
      // Before dispatch reset of state, trigger any other open tabs logged in to fire a 'strorage' event (see useEffect in routes.js)
      window.localStorage.removeItem('token')

      // reset state
      dispatch({
        type: LOGOUT,
        payload: { error: false }
      })
    } catch (err) {
      log.error(err)
    }
  }
}

export const forgotPassword = (emailAddress, fireValidation = () => { }, fireNotification = () => { }) => {
  return async (dispatch) => {
    const body = { emailAddress }

    const options = {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }

    // 403 if email doesn't exist
    const response = await trackPromise(window.fetch(`${api}/${apiversion}/user/forgot`, options))
    const parsedResponse = parseResponse(response, dispatch, true)
    if (!parsedResponse) { return false }

    if (parsedResponse.type === responseTypes.SUCCESS) {
      fireNotification(parsedResponse)
    } else if (parsedResponse.type === responseTypes.NOT_FOUND) {
      fireValidation(formValidationTypes.NO_EMAIL_FOUND)
    } else if (parsedResponse.type === responseTypes.FORBIDDEN) {
      fireValidation(formValidationTypes.EMAIL_UNAVAILABLE)
    } else {
      fireValidation(formValidationTypes.SEND_ERROR)
    }
  }
}

export const resetPassword = (emailAddress, newPassword, passwordKey, fireNotification = () => { }, fireValidation = () => { }) => {
  return async (dispatch) => {
    const body = { emailAddress, newPassword, passwordKey }

    const options = {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }

    // 403 if key is expired
    const response = await trackPromise(window.fetch(`${api}/${apiversion}/user/reset`, options))
    const parsedResponse = parseResponse(response, dispatch, true)
    if (!parsedResponse) { return false }

    if (parsedResponse.type === responseTypes.SUCCESS) {
      fireNotification(parsedResponse)
    } else if (parsedResponse.type === responseTypes.NOT_FOUND) {
      fireValidation(formValidationTypes.NO_EMAIL_FOUND)
    } else if (parsedResponse.type === responseTypes.FORBIDDEN) {
      fireValidation(formValidationTypes.VALIDATION_KEY_ERROR)
    } else {
      log.info(response.status)
      fireValidation(formValidationTypes.PASSWORD_RESET_ERROR)
    }
  }
}

export const removeErrors = () => {
  return (dispatch) => {
    dispatch({ type: REMOVE_ERROR })
  }
}
