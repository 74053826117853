import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

import authReducer from './auth'
import selectReducer from './select'
import userReducer from './user'
import profileReducer from './profile'
import loadingReducer from './loading'
import artifactsReducer from './artifacts'
import adminReducer from './admin'
import websocketReducer from './websocket'
import mceReducer from './microcredentials'
import mceAttemptsReducer from './mceAttempts'
import mceAssessorReducer from './mceAssessor'

export const reduxReducer = () => ({
  auth: {},
  select: {},
  userDetails: {},
  profileData: {},
  loadingState: {},
  artifacts: {},
  admin: {},
  websocket: {},
  mceReducer: {},
  mceAttempts: {},
  mceAssessor: {}
})

// Specific auth config persist reducer (per documentation) to handle auth related events
const authPersistConfig = {
  key: 'auth',
  storage: storage
}
const persistedAuthReducer = persistReducer(authPersistConfig, authReducer)

export default combineReducers({
  auth: persistedAuthReducer,
  select: selectReducer,
  userDetails: userReducer,
  profileData: profileReducer,
  loadingState: loadingReducer,
  artifacts: artifactsReducer,
  admin: adminReducer,
  websocket: websocketReducer,
  microcredentials: mceReducer,
  mceAttempts: mceAttemptsReducer,
  mceAssessor: mceAssessorReducer
})
