import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { NavBar, NotificationToast } from '../ui/tools'
import userNotFoundImg from '../../assets/userNotFound.svg'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { getUserDetails } from '../../redux/actions'

import {
  Grid, Typography
} from '@material-ui/core'

import queryString from 'query-string'

// themes are set in app.js wrapping routes with theme provider
const useStyles = makeStyles((theme) => ({
  pageUnavailableImage: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '200px'
    }
  },
  pageContainer: {
    paddingRight: '15em',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      margin: '0 1em 2em',
      paddingRight: '3em',
      width: '95%'
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: '3em'
    }
  }
}))

const PageUnavailable = props => {
  const { pageType, history } = props
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const authObject = useSelector(state => state.auth)
  const userDetails = useSelector(state => state.userDetails)
  const { profileID, userID } = authObject
  const parsedProps = queryString.parse(props.location.search)

  // If we are viewing the profile of the auth user (who is not deleted) and the userDetails are currently for a different user (who is deleted) then fetch the user details for auth user to show correct profile view
  useEffect(() => {
    if (profileID === parsedProps?.user && userID !== userDetails?.userID && userDetails?.deletedAt) {
      dispatch(getUserDetails(profileID, () => { }, () => NotificationToast(true, 'Failed to get user details!')))
    }
  }, [dispatch, parsedProps?.user, profileID, userDetails?.deletedAt, userDetails?.userID, userID])

  // Handle returning the user to the previous view they were on
  const handleReturnToPreviousView = () => { if (history) { history.goBack() } else history.push('/') }

  const [navRef, setNavRef] = useState(null)

  const navHeight = navRef ? navRef.clientHeight : 0

  const formattedPageTypeHeader = pageType ? pageType.replace(/\b\w+\b/gi, (match) => match[0].toUpperCase() + match.slice(1)) : 'Page'

  return (
    <>
      <NavBar location={parsedProps} profileID={profileID} setNavRef={setNavRef} navHeight={navHeight} />
      <Grid item container direction='column' alignContent='center' justifyContent='center' className={classes.pageContainer} style={{ overflow: 'hidden' }}>
        <Grid item container direction='row' alignItems='center' justifyContent='center' style={{ display: 'flex', marginTop: '22vh', marginLeft: '10vw' }}>
          <Grid item container direction='column' alignItems='center' justifyContent='center'>
            <Grid item>
              <Typography variant='h2'>{formattedPageTypeHeader} Unavailable</Typography>
            </Grid>
            <Grid item style={{ marginTop: '1.5em' }}>
              <Typography variant='body1'>Sorry about that! The {pageType || 'page'} you're trying to reach is not available right now. Please try again later or contact Ed Farm support if you need further assistance.</Typography>
            </Grid>
            <Grid item style={{ marginTop: '1em' }}>
              <Typography variant='body1'>Click <span style={{ fontWeight: '600', color: theme.palette.purple.darkest, cursor: 'pointer' }} onClick={handleReturnToPreviousView}>here</span> to return to the previous page.</Typography>
            </Grid>
            <Grid item style={{ marginTop: '3em' }}>
              <img className={classes.pageUnavailableImage} src={userNotFoundImg} alt='User Unavailable' />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default withRouter(PageUnavailable)
