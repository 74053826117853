import React, { } from 'react'

import {
  Grid, Typography, Grow, CircularProgress
} from '@material-ui/core'

import {
  PeopleAltRounded, FindInPageRounded
} from '@material-ui/icons'

import { ExploreUserCard } from '../../tools'

const ExploreUsers = ({ props }) => {
  const {
    classes, theme, loading, hasResults,
    allUsers, allUsersCount
  } = props

  return (
    <>
      {/* Section Header/ Nav */}
      <Grid item container direction='row' justifyContent='space-between'>
        <Grid item xs={9} container alignItems='flex-end' direction='row'>
          <Grid item container alignContent='center' justifyContent='center' style={{ backgroundColor: theme.palette.purple.darkest, borderRadius: '50%', height: '2em', width: '2em', marginRight: '.5em' }}>
            <PeopleAltRounded style={{ color: 'white', fontSize: '22px' }} />
          </Grid>
          <Typography variant='h3' style={{ color: theme.palette.purple.darkest, textTransform: 'none', fontWeight: 600, marginRight: '1em' }}>All People</Typography>
          <Typography variant='h5' style={{ color: theme.palette.grey.dark, fontWeight: 600, textTransform: 'none' }}>Explore and find other educators on the platform.</Typography>
        </Grid>
        <Grid item container justifyContent='flex-end' xs={3}>
          <Typography variant='h6' style={{ color: theme.palette.grey.dark, fontWeight: 400, textTransform: 'none' }}>{`${allUsersCount > 100 ? '+100' : allUsersCount} Total`}</Typography>
        </Grid>
      </Grid>

      {/* Section Content/Results */}
      <Grid item container direction='row' spacing={2} style={{ marginTop: '2em' }}>
        {/* Card Render */}
        {loading
          ? (
            <Grow
              in={loading}
              {...(loading ? { timeout: 1000 } : {})}
            >
              <Grid item container direction='row' justifyContent='center' alignContent='center' style={{ margin: '8em 0' }}>
                <CircularProgress />
              </Grid>
            </Grow>
          )
          : (
            allUsers && allUsers && allUsers.length
              ? (
                allUsers.map((user, i) => {
                  const current = Boolean(i === allUsers.findIndex(x => x.userID === user.userID))
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={user.userID}>
                      <Grow
                        in={current}
                        style={{ transformOrigin: '0 0 0' }}
                        {...(current ? { timeout: 200 } : {})}
                      >
                        <Grid item style={{ height: '100%', minWidth: '100%' }} container>
                          <ExploreUserCard
                            classes={classes}
                            userInfo={user}
                            fromExplore
                          />
                        </Grid>
                      </Grow>
                    </Grid>
                  )
                })
              )
              : (
                <>
                  <Grid item container justifyContent='center' alignContent='center'>
                    {
                      !hasResults
                        ? (
                          <CircularProgress />
                        )
                        : (
                          <Grid container direction='column' justifyContent='center' alignItems='center' style={{ margin: '4em' }}>
                            <FindInPageRounded style={{ color: 'white', fontSize: '40px', backgroundColor: theme.palette.purple.darkest, borderRadius: '50%', padding: '.3em' }} />
                            <Typography style={{ textTransform: 'none', fontSize: '24px', color: theme.palette.purple.darkest, fontWeight: 600 }}>Oh No!</Typography>
                            <Typography variant='body1' style={{ color: theme.palette.grey.dark }}>
                              So sorry, but it looks like we could not find any users related to your search parameters.
                            </Typography>
                            <Typography variant='body1' style={{ color: theme.palette.grey.dark }}>
                              Try clearing your filters, or refresh the page to see all users.
                            </Typography>
                          </Grid>
                        )
                    }
                  </Grid>
                </>
              )
          )}
      </Grid>
      {Boolean(allUsersCount) && allUsersCount >= 100 &&
        <Grid item container direction='column' justifyContent='center' alignContent='center' style={{ marginTop: '2em' }}>
          <Typography variant='body1' style={{ color: theme.palette.grey.dark, textAlign: 'center', fontWeight: 600 }}>This view is limited to 100 users.</Typography>
          <Typography variant='body1' style={{ color: theme.palette.grey.dark, textAlign: 'center' }}>Don't see what you are looking for? Try using the filters above to refine your search.</Typography>
        </Grid>}
    </>
  )
}

export default ExploreUsers
