import React, { useEffect, useState, useMemo, useRef } from 'react'
import { withRouter, Prompt } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'
import { getMCEStacks, postMCEVersion, updateMCEOriginStatus, getMCEDetails, resetMCEDetails } from '../../redux/actions'
import {
  Grid, useTheme, makeStyles, Paper, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions,
  Typography
} from '@material-ui/core'
import { CloseRounded } from '@material-ui/icons'
import { adminPageOptions, defaultMCEDetails, mceActionTypes } from '../../utils/variables'
import { createTempID, formatStringForDisplay, formatStringForEditor } from '../../utils/functions'
import BuilderNav from '../ui/MCEBuilder/BuilderTools/BuilderNav'
import BuilderHeader from '../ui/MCEBuilder/BuilderHeader'
import BuilderBottomNav from '../ui/MCEBuilder/BuilderTools/BuilderBottomNav'
import BuilderGeneralSection from '../ui/MCEBuilder/BuilderGeneralSection'
import BuilderQuestionSection from '../ui/MCEBuilder/BuilderQuestionSection'
import BuilderSampleSection from '../ui/MCEBuilder/BuilderSampleSection'
import BuilderSummarySection from '../ui/MCEBuilder/BuilderSummarySection'
import { isEqual, cloneDeep } from 'lodash'
import { NotificationToast } from '../ui/tools'

// default version form
const defaultVersionForm = {
  newOrigin: false,
  newStack: false,
  saveAndPublish: false,
  omceID: '',
  mceID: '',
  isDraft: 1,
  stackID: '',
  stackName: '',
  colorPrimary: '',
  colorSecondary: '',
  historyActions: [],
  title: '',
  keyMethod: '',
  methodComponents: '',
  resources: '',
  supportResearch: '',
  isActiveVersion: 0,
  displayP1: [],
  displayP2: [],
  displayP3: [],
  questionsToAdd: [],
  questionsToUpdate: [],
  questionsToRemove: [],
  questionOrderChanges: [],
  workSamplesToAdd: [],
  workSamplesToUpdate: [],
  workSamplesToRemove: [],
  workSampleOrderChanges: [],
  rubricsToAdd: [],
  rubricsToUpdate: [],
  rubricsToRemove: [],
  rubricOrderChanges: []
}

// ref used to track any changes in the general details (changes in these will populate in the history of a non-draft mce)
const defaultOriginalGenInfo = { keyMethod: '', title: '', resources: '', supportResearch: '', methodComponents: '' }

// tinyMce rich text editor init default
const editorInitDefaults = {
  menubar: false,
  plugins: [
    'autolink', 'lists', 'link'
  ],
  toolbar: 'bold italic bullist numlist link',
  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
  statusbar: false,
  link_default_target: '_blank',
  link_assume_external_targets: 'https',
  link_context_toolbar: true,
  link_title: false,
  link_target_list: false,
  anchor_top: false,
  anchor_bottom: false
}

// tinyMce editor src
const scriptSrc = { tinymceScriptSrc: process.env.PUBLIC_URL + '/tinymce/tinymce.min.js' }

// default error obj
const defaultErrors = {
  missingContent: false,
  generalError: false,
  missingDevText: false,
  noRubric: false,
  whiteSpaceOnly: false
}

const getSteps = () => {
  return ['General Information', 'Overview Questions', 'Work Samples', 'Reflection Questions', 'Summary']
}

const useStyles = makeStyles((theme) => ({
  searchInput: {
    paddingLeft: '8px',
    backgroundColor: 'white'
  },
  statusSelect: {
    padding: '8px 0 8px 8px',
    color: theme.palette.grey.dark
  },
  logMenuButton: {
    '&:disabled': {
      color: theme.palette.grey.dark
    }
  },
  listRoot: {
    position: 'relative',
    overflow: 'auto',
    maxHeight: '20em',
    width: '100%',
    minWidth: '20em',
    paddingTop: 0
  },
  listSection: {
    backgroundColor: '#fff !important',
    opacity: 1,
    paddingTop: 0,
    position: 'sticky',
    top: 0,
    zIndex: 1
  },
  activeChip: {
    height: 14,
    width: 14
  },
  activeLabel: {
    paddingTop: 3
  },
  dangerHtml: {
    fontSize: 14,
    color: theme.palette.grey.dark,
    fontWeight: 400,
    '& p': {
      margin: '0',
      height: theme.typography.body1.height,
      lineHeight: theme.typography.body1.lineHeight,
      letterSpacing: theme.typography.body1.letterSpacing
    },
    '& ol': {
      paddingInlineStart: 12,
      margin: 0
    },
    '& a': {
      color: theme.palette.grey.dark,
      fontWeight: 600
    }
  },
  dangerHtmlSections: {
    fontSize: 14,
    color: theme.palette.grey.dark,
    fontWeight: 400,
    '& p': {
      margin: '0',
      height: theme.typography.body1.height,
      lineHeight: theme.typography.body1.lineHeight,
      letterSpacing: theme.typography.body1.letterSpacing
    },
    '& ol': {
      paddingInlineStart: 12,
      margin: 0
    },
    '& a': {
      color: theme.palette.grey.dark,
      fontWeight: 600
    }
  },
  dangerHtmlRubricSections: {
    fontSize: 12,
    color: theme.palette.grey.dark,
    fontWeight: 400,
    '& p': {
      margin: '0',
      height: theme.typography.caption.height,
      lineHeight: theme.typography.caption.lineHeight,
      letterSpacing: theme.typography.caption.letterSpacing
    },
    '& ol': {
      paddingInlineStart: 12,
      margin: 0
    },
    '& a': {
      color: theme.palette.grey.dark,
      fontWeight: 600
    }
  },
  listIcon: {
    fontSize: 18
  },
  textField: {
    maxWidth: '5em',
    '& .MuiOutlinedInput-inputMarginDense': {
      padding: '6px 10px'
    },
    '& .MuiInput-underline:before': {
      borderBottom: `1px dashed ${theme.palette.purple.medium}`
    },
    '& .MuiInput-underline:after': {
      borderBottom: `1px solid ${theme.palette.purple.medium}`
    }
  },
  questionOrderArrows: {
    margin: theme.spacing(1),
    color: 'white',
    backgroundColor: theme.palette.purple.darkest,
    '&:disabled': {
      color: theme.palette.grey.medium,
      backgroundColor: theme.palette.grey.lighter
    }
  },
  rubricEditorSaveButton: {
    marginLeft: '.5em',
    backgroundColor: theme.palette.purple.darkest,
    padding: 0,
    height: 28,
    width: 28,
    color: 'white',
    '&:disabled': {
      backgroundColor: theme.palette.grey.medium
    },
    '&:hover': {
      backgroundColor: theme.palette.purple.darker
    }
  },
  rubricEditorSubActions: {
    marginLeft: '.5em',
    padding: 0,
    height: 20,
    width: 20,
    color: theme.palette.purple.darkest,
    '&:disabled': {
      color: theme.palette.grey.dark
    }
  },
  summarySectionBlock: {
    paddingRight: '2.5em'
  },
  summarySectionHeader: {
    fontWeight: 600
  },
  summarySectionContent: {
    padding: '1em 2em !important'
  },
  summarySectionLabel: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.purple.darkest
  },
  summarySectionSubLabel: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.grey.dark
  },
  summarySectionPaper: {
    border: `1px solid ${theme.palette.grey.medium}`,
    padding: '.75em'
  },
  completeChip: {
    backgroundColor: 'green',
    color: 'white',
    fontWeight: 600,
    '&:focus': {
      backgroundColor: 'green',
      color: 'white',
      fontWeight: 600
    }
  },
  incompleteChip: {
    borderColor: '#d80505',
    color: '#d80505',
    fontWeight: 600,
    '&:focus': {
      borderColor: '#d80505',
      color: '#d80505',
      fontWeight: 600
    }
  }
}))

// formatter for the two questions sections (P1, P3) when saving/publishing
const formatQuestionArr = (arr, section, draftStatus) => {
  // init arrays to return to save/publish action
  const orderArr = []
  const historyArr = []
  const addArr = []
  const updateArr = []

  // for the provided 'display' question array, perform actions for each object
  arr.forEach((q, i) => {
    if (q) {
      const { questionID = '', tempID = '', question, evalCriteria, answerLimit = 400 } = q

      // use the index of the final display arr to determine the desired question order
      const position = i + 1

      // be sure the strings are formatted for db storage and not editor display
      const formattedQuestion = formatStringForDisplay(question)
      const formattedCriteria = formatStringForDisplay(evalCriteria)
      // generate a new tempID (used in newly created questions, or old questions being duplicated into new mce versions)
      const newTempID = !draftStatus && !tempID ? createTempID() : tempID

      // push the desired order for each question with the questionID if not a draft, and the tempID
      orderArr.push({ questionID: draftStatus ? questionID : null, tempID: newTempID, position })

      // if not a draft, OR we do not have a questionID yet (newly generated questions), add it to the questions to add array
      if (!draftStatus || !questionID) {
        addArr.push({ tempID: newTempID, question: formattedQuestion, evalCriteria: formattedCriteria, partNum: section, answerLimit })
        // if not a draft AND there is no questionID, then add a history action for a newly added question
        if (!draftStatus && !questionID) { historyArr.push({ action: mceActionTypes.ADDED_QUESTION, section: section }) }
      } else {
        // otherwise, double check we have a questionID and add it to the updated questions array
        if (questionID) {
          updateArr.push({ questionID, question: formattedQuestion, evalCriteria: formattedCriteria, partNum: section, answerLimit })
        }
      }
    }
  })

  // return all the modified arrays
  return ({ orderArr, historyArr, addArr, updateArr })
}

const MCEBuilderView = (props) => {
  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()
  const parsedProps = queryString.parse(props.location.search)
  const mceParam = parsedProps?.mce || null
  const revisionParam = Boolean(parsedProps?.revision && parsedProps.revision === 'true')

  // -- nav bar refs
  const [appBarRef, setAppBarRef] = useState(null)
  const appBarHeight = appBarRef ? appBarRef.clientHeight : 0

  // -- redux values
  const { mceStacks = [], mceDetails = {} } = useSelector(state => state.microcredentials)

  // -- boolean to determine draft status of mce
  const draftMCE = Boolean(!mceParam || !revisionParam || !Object.keys(mceDetails).length || mceDetails.isDraft)

  // -- Local Version Modifier State
  const [versionForm, setVersionForm] = useState({ ...defaultVersionForm })
  // ref to store the original general information values from the database (used to track changes)
  const originalGenValues = useRef({ ...defaultOriginalGenInfo })

  // -- local error Obj state
  const [errorObj, setErrorObj] = useState({ ...defaultErrors })

  // -- local state to display the activated status of an mce origin
  const [deactivatedOrigin, setDeactivatedOrigin] = useState(false)

  // -- use the redux state to find the initial activated state of the mce origin
  useMemo(() => {
    if (mceDetails) {
      const { deactivatedAt } = mceDetails

      if (deactivatedAt) {
        setDeactivatedOrigin(true)
      }
    }
  }, [mceDetails])

  // -- the local stack list
  const [stackList, setStackList] = useState([])

  // -- fetch all available stacks for selection (draft only)
  useEffect(() => {
    dispatch(getMCEStacks(true))
  }, [dispatch])

  // -- set the stacks in redux to a local state
  useMemo(() => {
    if ((mceStacks?.stacks && !isEqual(stackList?.stacks, mceStacks?.stacks))) {
      setStackList(mceStacks)
    }
  }, [mceStacks, stackList])

  // -- use the params and details from redux to set the local version form
  useMemo(() => {
    // if we are revising an mce's details, and have not yet set the form, format the details in redux to the local display
    if (revisionParam && mceDetails && !isEqual(mceDetails, defaultMCEDetails) && isEqual(versionForm, defaultVersionForm)) {
      const { isDraft, keyMethod = '', methodComponents = '', supportResearch = '', resources = '', title = '', mceID = '' } = mceDetails

      // set the initial general information values to the local ref (used to track changes for the history record)
      originalGenValues.current = {
        title,
        keyMethod: formatStringForEditor(keyMethod),
        methodComponents: formatStringForEditor(methodComponents),
        resources: formatStringForEditor(resources),
        supportResearch: formatStringForEditor(supportResearch)
      }

      // format and set the local version form
      setVersionForm({
        ...defaultVersionForm,
        omceID: mceDetails.omceID || '',
        mceID: isDraft ? mceID : '',
        stackName: mceDetails.stackName || '',
        stackID: mceDetails.stackID || '',
        isDraft,
        title: mceDetails.title || '',
        keyMethod: formatStringForEditor(keyMethod),
        methodComponents: formatStringForEditor(methodComponents),
        resources: formatStringForEditor(resources),
        supportResearch: formatStringForEditor(supportResearch),
        displayP1: mceDetails.P1 || [],
        displayP2: mceDetails.P2 || [],
        displayP3: mceDetails.P3 || []
      })
    }
  }, [mceDetails, versionForm, revisionParam])

  // -- navigate back to the admin page
  const exitToAdmin = () => {
    props.history.push({
      pathname: '/admin',
      search: `?tab=${adminPageOptions.MICRO_CREDENTIALS}`
    })
  }

  // -- generic failure message/notification
  const fireFailure = () => {
    NotificationToast(true, 'Something went wrong. Please try again later.')
  }

  // -- successful save/publish action
  const fireBuildSuccess = () => {
    // inform the user of the success and navigate back tot he admin page
    NotificationToast(false, 'Successfully Saved Micro-Credential!')
    setConfirmNavigate(true)
    exitToAdmin()
  }

  // -- save and/or publish changes to the mce version
  const handlePublishMCE = async (publishBool, setActive = false) => {
    const tempForm = cloneDeep(versionForm)
    const {
      isDraft, omceID, stackID, title, keyMethod, methodComponents, resources, supportResearch, displayP1, displayP2, displayP3, historyActions,
      questionsToAdd, questionsToUpdate, questionsToRemove, workSamplesToAdd, workSamplesToUpdate, workSamplesToRemove, rubricsToAdd, rubricsToUpdate, rubricsToRemove
    } = tempForm

    // Set the form booleans as required
    tempForm.newStack = Boolean(isDraft && !stackID)
    tempForm.newOrigin = Boolean(isDraft && !omceID)
    tempForm.saveAndPublish = Boolean(isDraft && publishBool)
    tempForm.isActiveVersion = Boolean(setActive)

    // init order and action arrays
    const questionOrder = []
    const workSampleOrder = []
    const rubricOrder = []
    const historyActionArr = [...historyActions]

    // if not a draft, compare with original ref and add any modifications to the history array
    if (!isDraft) {
      const originalVals = originalGenValues.current

      if (title !== originalVals?.title) { historyActionArr.push({ action: mceActionTypes.MODIFIED_TITLE }) }
      if (keyMethod !== originalVals?.keyMethod) { historyActionArr.push({ action: mceActionTypes.MODIFIED_KEY_METHOD }) }
      if (resources !== originalVals?.resources) { historyActionArr.push({ action: mceActionTypes.MODIFIED_RESOURCES }) }
      if (supportResearch !== originalVals?.supportResearch) { historyActionArr.push({ action: mceActionTypes.MODIFIED_RESEARCH }) }
      if (methodComponents !== originalVals?.methodComponents) { historyActionArr.push({ action: mceActionTypes.MODIFIED_METHOD_COMP }) }
    }

    // if draft but has been slated for publishing, add a history action for publishing (this should be the only history action every associated with a draft omce)
    if (isDraft && publishBool) {
      historyActionArr.push({ action: mceActionTypes.PUBLISHED })
    }

    // if any questions, samples, or rubrics were slated for removal, add history actions for each (when not a draft), and if currently an array of objects, reformat the array to be an array of id strings
    if (questionsToRemove.length) {
      if (!isDraft) {
        questionsToRemove.forEach((qObj) => historyActionArr.push({ action: mceActionTypes.REMOVED_QUESTION, questionID: qObj.questionID, section: qObj.section }))
      }
      tempForm.questionsToRemove = questionsToRemove.map(q => q.questionID)
    }

    if (workSamplesToRemove.length) {
      if (!isDraft) {
        workSamplesToRemove.forEach((wsID) => historyActionArr.push({ action: mceActionTypes.REMOVED_WORKSAMP, workSampleID: wsID }))
      }
    }

    if (rubricsToRemove.length) {
      if (!isDraft) {
        rubricsToRemove.forEach((ruObj) => historyActionArr.push({ action: mceActionTypes.REMOVED_RUBRIC, rubricID: ruObj.rubricID, workSampleID: ruObj.workSampleID }))
      }
      tempForm.rubricsToRemove = rubricsToRemove.map(ru => ru.rubricID)
    }

    // format the question arrays for each section individually (to preserve the array order for each) and concat the results into the local arrays
    const P1ArrObjs = formatQuestionArr(displayP1, 1, isDraft)
    const P3ArrObjs = formatQuestionArr(displayP3, 3, isDraft)

    questionOrder.push(...P1ArrObjs.orderArr, ...P3ArrObjs.orderArr)
    historyActionArr.push(...P1ArrObjs.historyArr, ...P3ArrObjs.historyArr)
    questionsToAdd.push(...P1ArrObjs.addArr, ...P3ArrObjs.addArr)
    questionsToUpdate.push(...P1ArrObjs.updateArr, ...P3ArrObjs.updateArr)

    // format the work samples and rubrics into their respective arrays
    displayP2.forEach((ws, wsi) => {
      if (ws) {
        const { workSampleID, tempID: wsTempID, rubricRows, title: wsTitle, sampleType, sampleDesc } = ws
        // use the index of the display array to determine the desired 'sample order'
        const wsPosition = wsi + 1

        // if not a draft, all samples will be considered 'new' and we will therefore give them a new tempID
        const newTempID = !isDraft && !wsTempID ? createTempID() : wsTempID

        workSampleOrder.push({ workSampleID: isDraft ? workSampleID : null, tempID: newTempID, position: wsPosition })

        // init the formatted rubric rows array
        const formattedRubricRows = []
        // NOTE: if grading rubrics contain rows with no developing text, then any rows WITH developing text (at least one is required) are considered 'auto fails'
        // the array below will find all indices in the rubric rows array that meet this condition
        const autoFailIndices = rubricRows.length && rubricRows.some(x => !x.developingText?.length) ? rubricRows.reduce((arr, val, i) => { if (val?.developingText?.length) { arr.push(i) } return arr }, []) : []

        // -- fromat the rubric rows
        rubricRows.forEach((r, ri) => {
          if (r) {
            const { rubricID, tempID: tempRuID, developingText, proficientText, basicText } = r
            // use the index of the array to determine the desired 'row order'
            const ruPosition = ri + 1
            // determine if the row is considered an auto fail
            const autoFailVal = autoFailIndices.includes(ri) ? 1 : 0
            // if not a draft, all rubrics will be considered 'new' and we will therefore give them a new tempID
            const newRuTempID = !isDraft && !tempRuID ? createTempID() : tempRuID

            // format all of the rich text strings to store correctly in the db
            const formattedProfText = formatStringForDisplay(proficientText)
            const formattedBasicText = formatStringForDisplay(basicText)
            const formattedDevText = formatStringForDisplay(developingText)

            // push the rubric and its position into the order array
            rubricOrder.push({ rubricID: isDraft ? rubricID : null, tempID: newRuTempID, position: ruPosition })
            // if not a draft, with an existing worksa kple ID but NO rubric ID, we need to add a history action to indicate we added a row to the grading rubric for this work sample
            if (!isDraft && workSampleID && !rubricID) { historyActionArr.push({ action: mceActionTypes.ADDED_RUBRIC }) }

            // format the row to match the conditions for the api
            formattedRubricRows.push({ rubricID, tempID: newRuTempID, autoFail: autoFailVal, profText: formattedProfText, basicText: formattedBasicText, devText: formattedDevText })
          }
        })

        // if not a draft omce, or there is no work sampleID (aka, a brand new sample), add the sample to the 'samplesToAdd' array (NOTE: this SHOULD include the rubric rows)
        if (!isDraft || !workSampleID) {
          // format the rich text strings to store correctly in the db
          const formattedSampleDesc = formatStringForDisplay(sampleDesc)
          // push the sample and its position into the order array
          workSamplesToAdd.push({ tempID: newTempID, title: wsTitle, sampleType, sampleDesc: formattedSampleDesc, rubric: [...formattedRubricRows] })
          // if not a draft but we also do not have a sample ID, add a history action to indicate we added a new sample
          if (!isDraft && !workSampleID) { historyActionArr.push({ action: mceActionTypes.ADDED_WORKSAMP }) }
        } else {
          // otherwise we need to update any samples in a draft mce (note: this should NOT include the rubric rows)
          workSamplesToUpdate.push({ workSampleID, title: wsTitle, sampleType, sampleDesc })

          // when updating a draft the rows are handled in their own arrays, push the formatted rows into the respective arrays based on the presence of a rubricID
          formattedRubricRows.forEach((row) => {
            if (row.rubricID) {
              rubricsToUpdate.push(row)
            } else {
              rubricsToAdd.push({ ...row, workSampleID })
            }
          })
        }
      }
    })

    // remove the display arrays from the form going to the api to reduce the data load
    delete tempForm.displayP1
    delete tempForm.displayP2
    delete tempForm.displayP3

    // set all locally modified arrays to the temp form, formatting any rich text string to the correct format to store in the db
    const finalForm = {
      ...tempForm,
      keyMethod: formatStringForDisplay(keyMethod),
      resources: formatStringForDisplay(resources),
      methodComponents: formatStringForDisplay(methodComponents),
      supportResearch: formatStringForDisplay(supportResearch),
      historyActions: historyActionArr,
      questionOrderChanges: questionOrder,
      workSampleOrderChanges: workSampleOrder,
      rubricOrderChanges: rubricOrder
    }

    dispatch(postMCEVersion(finalForm, fireBuildSuccess, fireFailure))
  }

  // -- default props for each builder section
  const defaultSectionProps = {
    classes,
    versionForm,
    setVersionForm,
    editorInitDefaults,
    scriptSrc,
    errorObj,
    setErrorObj,
    defaultErrors
  }

  // -- determine the component to render based on the selected step
  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0: return <BuilderGeneralSection {...defaultSectionProps} />
      case 1: return <BuilderQuestionSection {...defaultSectionProps} section='P1' />
      case 2: return <BuilderSampleSection {...defaultSectionProps} />
      case 3: return <BuilderQuestionSection {...defaultSectionProps} section='P3' />
      case 4: return <BuilderSummarySection {...defaultSectionProps} setActiveStep={setActiveStep} handlePublishMCE={handlePublishMCE} />
      default: return 'Something went wrong.'
    }
  }

  // -- active step and stepper options
  const [activeStep, setActiveStep] = useState(0)
  const steps = getSteps()

  // -- on unmount reset the local version form
  useEffect(() => {
    return () => {
      setVersionForm({ ...defaultVersionForm })
    }
  }, [])

  // -- success function for when a version of an mce has been restored
  const fireRestoreSuccess = (mceID) => {
    // reset the form and replace the params with the new active mceID in order to trigger a new fetch of mce details
    setVersionForm({ ...defaultVersionForm })
    props.history.replace({
      search: `?revision=true&mce=${mceID}`
    })
  }

  // -- success function for when the origin activation status has been updated
  const fireUpdateSuccess = (action, mceID) => {
    NotificationToast(false, 'Successfully Updated Micro-Credential Status')

    // update mceDetails after changing the mce badge
    if (action === mceActionTypes.MODIFIED_BADGE) {
      dispatch(getMCEDetails({ mceID, includeHistoryBool: true, noDispatchBool: false }))
    }

    // determine the correct success functionality based on the original action
    if (action !== mceActionTypes.RESTORED) {
      // if activating or deactivating, set the local toggel to the correct state
      const wasDeactivated = Boolean(action === mceActionTypes.DEACTIVATED)
      setDeactivatedOrigin(wasDeactivated)
    } else {
      // if restoring and we correctly received an mceID from the redux action, fetch the newly restored mce details
      if (mceID) {
        dispatch(getMCEDetails({ mceID, includeHistoryBool: true, noDispatchBool: false }, fireRestoreSuccess))
      } else {
        // should not happen
        console.warn('did not receive mceID')
      }
    }
  }

  // -- handler to update the origin or active version status
  const handleOriginStatus = (action, newID = null, badgeInfo) => {
    // information for body of api call, with the selected mceID (if provided) or the current mceID if not
    const info = { action, omceID: versionForm.omceID, mceID: newID || mceDetails.mceID }

    // if we are restoring a version, set the 'oldID' to the current mceID in redux
    if (action === mceActionTypes.RESTORED && newID) {
      info.oldID = mceDetails.mceID
    }

    if (action === mceActionTypes.MODIFIED_BADGE && Object.keys(badgeInfo).length) {
      info.badgeImage = badgeInfo?.newImage
      info.badgeImageType = badgeInfo?.newImageType
    }

    dispatch(updateMCEOriginStatus(info, fireUpdateSuccess, fireFailure))
  }

  // ************************ EXIT NAVIGATION STATE *********************** //
  const [openNavigationConfirm, setOpenNavigationConfirm] = useState(false)
  const [confirmNavigate, setConfirmNavigate] = useState(false)
  const lastLocation = useRef(null)

  // -- listener use effect to catch when a user has confirmed navigation and we have a location set
  useEffect(() => {
    if (confirmNavigate && lastLocation.current) {
      const { pathname, search } = lastLocation.current
      props.history.push({
        pathname,
        search
      })

      dispatch(resetMCEDetails())

      // once navigation has been acted on, reset the local states triggering the use effect
      lastLocation.current = null
      setConfirmNavigate(false)
    }
  }, [confirmNavigate, dispatch, props.history])

  // -- Listener for react-router Prompt that fires when a user attempts to exit the builder process
  const navigationBlock = (nextLocation, action) => {
    // if the confirmNavigate state has not been set
    if (!confirmNavigate) {
      // set the requested location in a ref
      lastLocation.current = nextLocation
      // open the confirm modal
      setOpenNavigationConfirm(true)

      // block navigation until user has confirmed navigation
      return false
    }

    // otherwise allow the navigation
    return true
  }

  // -- handler when the user has selected an option from the prompt modal
  const handleNavigateConfirm = (confirmExit) => {
    // if we had no other location stored in a ref, store the admin page
    if (!lastLocation.current) {
      lastLocation.current = {
        pathname: '/admin',
        search: `?tab=${adminPageOptions.MICRO_CREDENTIALS}`
      }
    }

    if (confirmExit) {
      setConfirmNavigate(true)
    }
    // close the modal
    setOpenNavigationConfirm(false)
  }

  return (
    <>
      {/* Prompt listener that triggers when a user attempts to exit the page while in the pursuer/assessor process */}
      <Prompt when={!isEqual(versionForm, defaultVersionForm)} message={navigationBlock} />
      {/* Top Nav Bar */}
      <BuilderNav setAppBarRef={setAppBarRef} exitToAdmin={exitToAdmin} />

      <Grid container direction='column' style={{ padding: '2em', marginTop: appBarHeight, backgroundColor: theme.palette.grey.lighter, minHeight: '100vh', marginBottom: '5em' }}>
        {/* HEADER/STEPPER AREA */}
        <BuilderHeader
          classes={classes}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          stackList={stackList}
          setStackList={setStackList}
          steps={steps}
          draftMCE={draftMCE}
          versionForm={versionForm}
          setVersionForm={setVersionForm}
          handleOriginStatus={handleOriginStatus}
          deactivatedOrigin={deactivatedOrigin}
        />
        <Grid item container direction='column' style={{ padding: '1em' }}>
          <Paper style={{ padding: '2em' }}>
            {getStepContent(activeStep)}
          </Paper>
        </Grid>
      </Grid>

      {/* Bottom Navigation */}
      <BuilderBottomNav
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        steps={steps}
        exitToAdmin={exitToAdmin}
      />

      {/* Navigation Confirmation Prompt */}
      <Dialog
        open={openNavigationConfirm}
        fullWidth
        maxWidth='sm'
        onClose={() => { setOpenNavigationConfirm(false) }}
      >
        <DialogTitle disableTypography>
          <Grid container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid item>
              <Typography
                variant='h4'
                style={{ textTransform: 'none', color: theme.palette.purple.darkest, fontSize: '1.3rem' }}
              >
                Are you sure you want to leave this page?
              </Typography>
            </Grid>
            <Grid item>
              <IconButton style={{ padding: '0px' }} onClick={(e) => { e.preventDefault(); setOpenNavigationConfirm(false) }}>
                <CloseRounded className={classes.customizedButton} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ paddingTop: 0, marginBottom: '2em' }}>
          <Grid container item direction='column'>
            <Typography>Any changes you have made will not be saved. To save any changes please close out of this modal and navigate to the 'Summary' step.</Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid item container direction='row' justifyContent='flex-end'>
            <Button
              variant='text'
              style={{ color: theme.palette.purple.darkest, fontWeight: 600, textTransform: 'none', marginRight: '1em' }}
              onClick={(e) => { e.preventDefault(); handleNavigateConfirm(false) }}
            >
              Cancel
            </Button>

            <Button
              variant='contained'
              size='small'
              style={{ backgroundColor: theme.palette.purple.darkest, color: 'white', fontWeight: 600, textRansform: 'none' }}
              onClick={(e) => { e.preventDefault(); handleNavigateConfirm(true) }}
            >
              Leave Page
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default withRouter(MCEBuilderView)
