import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { chunk } from 'lodash'

import { Grid, Typography, Tooltip, Button, Select, Paper, useTheme, MenuItem } from '@material-ui/core'
import { ChevronLeft, ExpandMoreRounded, PortraitRounded } from '@material-ui/icons'

import { userRoles, userRoleIDs } from '../../../../utils/variables'
import { getInFellowTypes } from '../../../../redux/actions'

// ****** Child Components ****
import SearchUserCard from '../ResultCards/SearchUserCard'

/* NOTES: Expanded display of users retruned from a global search
  -- Parent Component: ui/Search/SearchPopper.js
  In depth render tree loacated in SearchBarNav.js
*/
const ExpandedUserResults = (props) => {
  // Props:
  // - SearchBarNav.js: classes, smScreen, handleReset, handleExpand, filterMenuRef, handleSearchUsers
  // - UserResults.js: userResults (deconstructed from searchResults in parent component)
  const { classes, userResults, smScreen, handleReset, handleExpand, filterMenuRef, xsScreen, handleSearchUsers } = props
  const theme = useTheme()
  const dispatch = useDispatch()

  // Redux State
  const {
    adminSchools: { schools: Schools = [], districtOptions: Districts = [] } = {}, adminCohorts: { cohorts: Cohorts = [] } = {}
  } = useSelector(state => state.admin)

  const userRoleOptions = [
    { roleName: userRoles.TEACHER_FELLOW, roleID: userRoleIDs.TEACHER_FELLOW },
    { roleName: userRoles.INNOVATION_FELLOW, roleID: userRoleIDs.INNOVATION_FELLOW }
  ]

  // District Options and Selected District, defaults to the first ditrict in the array
  const districtOptions = !Districts ? [] : Districts
  const [selectedDistrict, setSelectedDistrict] = useState('')

  // School options (based on ditrict) and the selected school, defaults to the schools of the first district in the array
  const [schoolOptions, setSchoolOptions] = useState([])
  const [selectedSchool, setSelectedSchool] = useState('')

  // Cohort Options and the selected cohort
  const cohortOptions = Cohorts && Cohorts.length ? [...Cohorts] : []
  const [selectedCohort, setSelectedCohort] = useState('')

  const [selectedUserRole, setSelectedUserRole] = useState('')

  // Innovation Fellow Options and the selected innovation fellow role
  const [innovationFellowOptions, setInnovationFellowOptions] = useState([])
  const [selectedInnovationFellowRole, setSelectedInnovationFellowRole] = useState('')

  // Fetches a list of all Innovation Fellow types
  const fetchInFellowTypes = useCallback(async () => {
    const fellowTypes = await dispatch(getInFellowTypes())

    if (fellowTypes) {
      setInnovationFellowOptions(fellowTypes)
    }
  }, [dispatch])

  useEffect(() => {
    fetchInFellowTypes()
  }, [fetchInFellowTypes])

  // Triggers the call to es with enhanced filtering
  const fireSearch = (school, district, cohort, userRole, inFellowType) => {
    const schoolID = !school.schoolID ? '' : school.schoolID
    const districtID = !district.schoolDistrictID ? '' : district.schoolDistrictID
    const cohortID = !cohort.cohortID ? '' : cohort.cohortID
    const userRoleID = userRole && userRole.roleName && userRole.roleName === userRoles.INNOVATION_FELLOW
      ? [userRole.roleID] : userRole && userRole.roleName && userRole.roleName === userRoles.TEACHER_FELLOW ? [userRole.roleID, userRoleIDs.ADMIN_ROLE] : ''
    const inFellowTypeID = !inFellowType.inFellowTypeID ? '' : inFellowType.inFellowTypeID

    handleSearchUsers(schoolID, districtID, cohortID, userRoleID, inFellowTypeID)
  }

  // MUI select handlers
  const handleDistrictChange = async (value) => {
    setSelectedDistrict(value)

    const districtsSchoolsIndex = Schools.findIndex(x => x.schoolDistrictID === value.schoolDistrictID)
    if (districtsSchoolsIndex !== -1) {
      const districtsSchools = Schools[districtsSchoolsIndex].schools
      // Sets the district to the selected option, and repopulates the school options to the new district
      setSchoolOptions(districtsSchools)

      // If the current selected school is not in the new district, then reset it
      const schoolIndex = [...districtsSchools].findIndex(x => x.schoolID === selectedSchool.schoolID)
      if (schoolIndex === -1) {
        setSelectedSchool('')
      }
    } else {
      // District does not have any schools
      setSchoolOptions([])
      setSelectedSchool('')
    }

    // Trigger es call
    fireSearch(selectedSchool, value, selectedCohort, selectedUserRole, selectedInnovationFellowRole)
  }

  const handleSchoolChange = (value) => {
    const index = schoolOptions.findIndex(x => x.schoolID === value.schoolID)

    if (index !== -1) {
      setSelectedSchool(value)
    }
    // Trigger es call
    fireSearch(value, selectedDistrict, selectedCohort, selectedUserRole, selectedInnovationFellowRole)
  }

  const handleCohortSelect = (value) => {
    const index = cohortOptions.findIndex(x => x.cohortID === value.cohortID)

    if (index !== -1) {
      setSelectedCohort(value)
    }
    // Trigger es call
    fireSearch(selectedSchool, selectedDistrict, value, selectedUserRole, selectedInnovationFellowRole)
  }

  const handleChangeUserRole = (value) => {
    const index = userRoleOptions.findIndex(x => x.roleID === value.roleID)

    if (index !== -1) {
      setSelectedUserRole(value)
    }

    // Reset selected innovation fellow role if changing from innovation fellow
    if (value && value.roleName) {
      if (value.roleName !== userRoles.INNOVATION_FELLOW) {
        setSelectedInnovationFellowRole('')
      }
    }

    // Trigger es call
    fireSearch(selectedSchool, selectedDistrict, selectedCohort, value, selectedInnovationFellowRole)
  }

  const handleChangeInnovationFellowRole = (value) => {
    setSelectedInnovationFellowRole(value)

    // Trigger es call
    fireSearch(selectedSchool, selectedDistrict, selectedCohort, selectedUserRole, value)
  }

  // Clear all filters and refresh results
  const clearFilters = () => {
    setSelectedSchool('')
    setSchoolOptions([])
    setSelectedDistrict('')
    setSelectedCohort('')
    setSelectedUserRole('')
    setSelectedInnovationFellowRole('')
    fireSearch('', '', '', [], '')
  }

  // Check to see if the in fellow role select should be disabled
  const inFellowRoleDisabledCheck = selectedUserRole === '' || (selectedUserRole && selectedUserRole.roleName && selectedUserRole.roleName !== userRoles.INNOVATION_FELLOW)

  return (
    <Grid item container direction='column'>

      {/* Back Navigation */}
      <Grid item container direction='row'>
        <Tooltip arrow title='Return to all Search Results'>
          <Button
            variant='text'
            startIcon={<ChevronLeft />}
            style={{
              color: theme.palette.grey.dark,
              textTransform: 'none',
              fontWeight: 600,
              paddingLeft: 0
            }}
            // Testing using both touch and click event handlers
            onClick={(e) => { e.preventDefault(); handleExpand(e) }}
            onTouchEnd={(e) => handleExpand(e)}
          >
            Back to Search
          </Button>
        </Tooltip>
      </Grid>

      {/* User Content */}
      <Grid item container direction='row' style={{ marginTop: '.5em' }}>
        {/* Header and # of results Section */}
        <Grid item container direction='row' style={{ marginBottom: '1em' }}>
          <Grid item container xs={6}>
            <Typography variant='h3' style={{ color: 'black' }}>Users</Typography>
          </Grid>
          <Grid item container xs={6} justifyContent='flex-end'>
            {/* If results are found display a total number form all categories */}
            {Boolean(userResults.length) && userResults.length > 0 &&
              <Typography variant='h6' style={{ textTransform: 'none', color: theme.palette.grey.dark }}>{`${userResults.length} Results`}</Typography>}
          </Grid>
        </Grid>

        {/* If the user has selected any filters, display the clear all button  */}
        {(selectedSchool !== '' || selectedDistrict !== '' || selectedCohort !== '' || selectedUserRole !== '' || selectedInnovationFellowRole !== '') &&
          <Grid direction='row' item container justifyContent='flex-end'>
            <Button style={{ textTransform: 'none', color: theme.palette.grey.dark, fontWeight: 600 }} onClick={clearFilters}>Clear Filters</Button>
          </Grid>}

        {/* User Filter Menus */}
        <Grid item container direction='row'>
          <Paper style={{ width: '100%', padding: '.5em' }}>
            <Grid item container direction={xsScreen ? 'column' : 'row'} spacing={2} xs={null} sm={12} lg={12}>

              {/* District Filter */}
              <Grid item container direction='row' alignItems='center' xs={null} sm={4}>
                <Typography
                  variant='body1'
                  style={{
                    color: theme.palette.grey.dark,
                    fontWeight: 700,
                    paddingLeft: '.5em',
                    marginRight: '.5em',
                    pointerEvents: 'none'
                  }}
                >
                  District:
                </Typography>
                <Select
                  value={selectedDistrict}
                  defaultValue=''
                  displayEmpty
                  onOpen={(e) => { e.preventDefault(); filterMenuRef.current = true }}
                  IconComponent={() => (
                    <ExpandMoreRounded style={{ fontSize: '16px', color: theme.palette.grey.dark }} onClick={(e) => { e.preventDefault(); filterMenuRef.current = true }} />
                  )}
                  classes={{ root: classes.filterSelect }}
                  onChange={(e) => handleDistrictChange(e.target.value)}
                  disableUnderline
                  MenuProps={{ style: { zIndex: 1400 }, disablePortal: false, TransitionProps: { onExited: () => { filterMenuRef.current = false } } }}
                  renderValue={(selected) => !selected ? `${districtOptions.length === 0 ? '--' : 'Choose District...'}` : selected.districtName}
                >
                  <MenuItem value='' disabled>Choose District...</MenuItem>
                  {districtOptions && districtOptions.length &&
                    districtOptions.map(district => {
                      return (
                        <MenuItem key={district.schoolDistrictID} value={district}>{district.districtName}</MenuItem>
                      )
                    })}
                </Select>
              </Grid>

              {/* School Filter */}
              <Grid item container direction='row' alignItems='center' xs={null} sm={4}>
                <Typography
                  variant='body1'
                  style={{
                    color: schoolOptions.length === 0 ? theme.palette.grey.medium : theme.palette.grey.dark,
                    fontWeight: 700,
                    paddingLeft: '.5em',
                    marginRight: '.5em',
                    pointerEvents: 'none'
                  }}
                >
                  School:
                </Typography>
                <Select
                  value={selectedSchool}
                  defaultValue=''
                  disabled={schoolOptions.length === 0}
                  displayEmpty
                  onOpen={() => { filterMenuRef.current = true }}
                  IconComponent={() => (
                    <ExpandMoreRounded
                      style={{
                        fontSize: '16px',
                        color: schoolOptions.length === 0 ? theme.palette.grey.medium : theme.palette.grey.dark
                      }}
                    />
                  )}
                  classes={{ root: classes.filterSelect, disabled: classes.filterDisabledSelect }}
                  onChange={(e) => handleSchoolChange(e.target.value)}
                  disableUnderline
                  MenuProps={{ style: { zIndex: 1400 }, disablePortal: false, TransitionProps: { onExited: () => { filterMenuRef.current = false } } }}
                  renderValue={(selected) => !selected ? `${schoolOptions.length === 0 ? '--' : 'Choose School...'}` : selected.schoolName}
                >
                  <MenuItem value='' disabled>Choose School...</MenuItem>
                  {districtOptions && districtOptions.length && schoolOptions && schoolOptions.length &&
                    schoolOptions.map(school => {
                      return (
                        <MenuItem key={school.schoolID} value={school}>{school.schoolName}</MenuItem>
                      )
                    })}
                </Select>
              </Grid>

              {/* Cohort Filter */}
              <Grid item container direction='row' alignItems='center' xs={null} sm={4}>
                <Typography
                  variant='body1'
                  style={{
                    color: theme.palette.grey.dark,
                    fontWeight: 700,
                    paddingLeft: '.5em',
                    marginRight: '.5em',
                    pointerEvents: 'none'
                  }}
                >
                  Cohort:
                </Typography>
                <Select
                  value={selectedCohort}
                  defaultValue=''
                  displayEmpty
                  onOpen={() => { filterMenuRef.current = true }}
                  IconComponent={() => (
                    <ExpandMoreRounded style={{ fontSize: '16px', color: theme.palette.grey.dark }} />
                  )}
                  classes={{ root: classes.filterSelect }}
                  disableUnderline
                  MenuProps={{ style: { zIndex: 1400 }, TransitionProps: { onExited: () => { filterMenuRef.current = false } } }}
                  onChange={(e) => { handleCohortSelect(e.target.value) }}
                  renderValue={(selected) => !selected ? `${cohortOptions.length === 0 ? '--' : 'Choose Cohort...'}` : selected.cohortName}
                >
                  <MenuItem value='' disabled>Choose Cohort...</MenuItem>
                  {cohortOptions && cohortOptions.length &&
                    cohortOptions.map(cohort => {
                      return (
                        <MenuItem key={cohort.cohortID} value={cohort}>{cohort.cohortName}</MenuItem>
                      )
                    })}

                </Select>
              </Grid>

            </Grid>

            <Grid item container direction={xsScreen ? 'column' : 'row'} spacing={2} xs={null} sm={12} lg={12}>
              {/* User Role Filter */}
              <Grid item container direction='row' alignItems='center' xs={null} sm={4}>
                <Typography
                  variant='body1'
                  style={{
                    color: theme.palette.grey.dark,
                    fontWeight: 700,
                    paddingLeft: '.5em',
                    marginRight: '.5em',
                    pointerEvents: 'none'
                  }}
                >
                  User Role:
                </Typography>
                <Select
                  displayEmpty
                  defaultValue=''
                  value={selectedUserRole ? userRoleOptions.find(role => role.roleID === selectedUserRole.roleID) : ''}
                  onOpen={() => { filterMenuRef.current = true }}
                  IconComponent={() => (
                    <ExpandMoreRounded style={{ fontSize: '16px', color: theme.palette.grey.dark }} onClick={(e) => { e.preventDefault(); filterMenuRef.current = true }} />
                  )}
                  classes={{ root: classes.filterSelect }}
                  onChange={(e) => handleChangeUserRole(e.target.value)}
                  disableUnderline
                  MenuProps={{ style: { zIndex: 1400 }, disablePortal: false, TransitionProps: { onExited: () => { filterMenuRef.current = false } } }}
                  renderValue={(selected) => !selected ? 'Choose User Role...' : selected.roleName}
                >
                  <MenuItem value='' disabled>Choose User Role...</MenuItem>
                  {userRoleOptions && userRoleOptions.length &&
                    userRoleOptions.map(userRole => {
                      return (
                        <MenuItem key={userRole.roleID} value={userRole}>{userRole.roleName}</MenuItem>
                      )
                    })}
                </Select>
              </Grid>

              {/* Innovation Fellow Role Filter */}
              <Grid item container direction='row' alignItems='center' justifyContent='flex-start' xs={null} sm={8}>
                <Typography
                  variant='body1'
                  style={{
                    color: inFellowRoleDisabledCheck ? theme.palette.grey.medium : theme.palette.grey.dark,
                    fontWeight: 700,
                    paddingLeft: '.5em',
                    marginRight: '.5em',
                    pointerEvents: 'none'
                  }}
                >
                  Innovation Fellow Role:
                </Typography>
                <Select
                  value={selectedInnovationFellowRole}
                  defaultValue=''
                  disabled={inFellowRoleDisabledCheck}
                  displayEmpty
                  onOpen={() => { filterMenuRef.current = true }}
                  IconComponent={() => (
                    <ExpandMoreRounded
                      style={{
                        fontSize: '16px',
                        color: inFellowRoleDisabledCheck ? theme.palette.grey.medium : theme.palette.grey.dark
                      }}
                    />
                  )}
                  classes={{ root: classes.filterSelect, disabled: classes.filterDisabledSelect }}
                  onChange={(e) => handleChangeInnovationFellowRole(e.target.value)}
                  disableUnderline
                  MenuProps={{ style: { zIndex: 1400 }, disablePortal: false, TransitionProps: { onExited: () => { filterMenuRef.current = false } } }}
                  renderValue={(selected) => !selected ? `${inFellowRoleDisabledCheck ? '--' : 'Choose Innovation Fellow Role...'}` : selected.inFellowTypeName}
                >
                  <MenuItem value='' disabled>Choose Innovation Fellow Role...</MenuItem>
                  {innovationFellowOptions && innovationFellowOptions.length &&
                    innovationFellowOptions.map(inFellowRole => {
                      return (
                        <MenuItem key={inFellowRole.inFellowTypeID} value={inFellowRole}>{inFellowRole.inFellowTypeName}</MenuItem>
                      )
                    })}
                </Select>
              </Grid>
            </Grid>

          </Paper>
        </Grid>

        {/* User Cards */}
        <Grid container direction='column' style={{ marginTop: '.5em' }} spacing={1}>
          {/* chunk the results into arrays of three results each */}
          {userResults && Boolean(userResults.length)
            ? (
              chunk(userResults, 3).map((chunkArr, i) => {
                return (
                  <Grid key={`user-arr-${i}`} item container direction={smScreen ? 'column' : 'row'} spacing={2} style={{ maxHeight: '70%' }}>
                    {chunkArr.map(user => {
                      return (
                        <Grid key={`user-result-${user.profileID}`} item xs={smScreen ? null : 4} style={{ paddingRight: '.5em' }}>
                          <SearchUserCard user={user} handleReset={handleReset} smScreen={smScreen} xsScreen={xsScreen} expanded />
                        </Grid>
                      )
                    })}
                  </Grid>
                )
              })
            )
            : (
              <Grid item container direction='column' alignItems='center' style={{ padding: '2em', marginTop: '1em' }}>
                <Grid item>
                  <PortraitRounded fontSize='large' style={{ color: theme.palette.purple.darkest }} />
                </Grid>
                <Grid item>
                  <Typography variant='h5' style={{ color: theme.palette.purple.darkest, fontWeight: 600 }}>No Results Found</Typography>
                </Grid>
                <Grid item>
                  <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: 400 }}>There were no results matching your request.</Typography>
                </Grid>
              </Grid>
            )}

        </Grid>
      </Grid>
    </Grid>
  )
}

export default ExpandedUserResults
