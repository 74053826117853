import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography, Avatar, Divider, Paper, IconButton, Tooltip, Fade, useMediaQuery, Badge } from '@material-ui/core'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import moment from 'moment'
import { hideConversation, getConversations, setActiveConversationUsers } from '../../../../../redux/actions'
import stringAvatar from '../../../tools/AvatarFormat'

const ModifiedEditIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16' height='14.337'
      viewBox='0 0 24 21.337'
    >
      <path
        id='edit-regular_1_'
        data-name='edit-regular (1)'
        d='M16.763,14.3,18.1,12.966a.335.335,0,0,1,.571.238v6.058a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V4.595a2,2,0,0,1,2-2H13.4a.335.335,0,0,1,.238.571L12.3,4.5a.33.33,0,0,1-.238.1H2V19.262H16.667V14.533A.328.328,0,0,1,16.763,14.3Zm6.525-8.409L12.346,16.833l-3.767.417a1.721,1.721,0,0,1-1.9-1.9L7.1,11.583,18.038.641a2.432,2.432,0,0,1,3.446,0l1.8,1.8a2.441,2.441,0,0,1,0,3.45ZM19.171,7.178,16.75,4.757,9.009,12.5l-.3,2.721,2.721-.3Zm2.7-3.321-1.8-1.8a.435.435,0,0,0-.617,0L18.167,3.345l2.421,2.421,1.288-1.288A.444.444,0,0,0,21.871,3.857Z'
        transform='translate(0 0.075)'
        fill='#29356a'
      />
    </svg>
  )
}

/* ********** Conversation Preview Details************* //
    Rendered from ConversationList.js when the 'view.conversation' is 'list'
*/
const ConversationCard = (props) => {
  const { messages = [] } = useSelector(state => state.websocket)

  const {
    theme, conversation, activeCard, loadNewView, viewTypes,
    parsedProps = {}, classes,
    messagesInitialLoad,
    setCurrentForm,
    editorOpen, setEditorOpen,
    updateRestoredMsgContent
  } = props

  const dispatch = useDispatch()

  const smScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const mdScreen = useMediaQuery(theme.breakpoints.down('md'))
  const { drafts = [] } = useSelector(state => state.websocket)

  const {
    userListDetails = [],
    mostRecentCreatedAt = 0,
    unreadMessages = false,
    conversationID = ''
  } = conversation

  // Grab the details of the first user in the conversation to display on the conversation card
  const [userName, setUserName] = useState('')
  const [userSchool, setUserSchool] = useState('')
  const [userAvatar, setUserAvatar] = useState(null)
  const [avatarProps, setAvatarProps] = useState({})
  const [messageContent, setMessageContent] = useState('')

  useMemo(() => {
    if (userListDetails && Array.isArray(userListDetails) && typeof unreadMessages !== 'undefined') {
      if (userListDetails.length) {
        const [{ fullName = '', nickName, schoolName = '', profileAvatarPath = null } = {}] = userListDetails

        setUserName(nickName || fullName)
        setUserSchool(schoolName)
        setUserAvatar(profileAvatarPath)
        setAvatarProps(stringAvatar(nickName || fullName, Boolean(profileAvatarPath), unreadMessages))
      } else {
        setUserName('')
        setUserSchool('')
        setUserAvatar('')
        setAvatarProps(stringAvatar('', false, unreadMessages))
      }
    }
  }, [userListDetails, unreadMessages])

  useMemo(() => {
    // Take most recent message content, parse it, check length, return it for display
    const parsedMessageContent = (conversation) => {
      if (conversation && conversation.mostRecentMessageContent) {
        try {
          const { mostRecentMessageContent } = conversation
          const parsedContent = JSON.parse(mostRecentMessageContent)

          if (parsedContent && Boolean(parsedContent.length) && parsedContent[0].children) {
            const [{ children: [{ text: content = '' } = {}] = [] } = {}] = parsedContent

            // If conversation has no content
            if (!content && !conversation.mostRecentMessageID) {
              // If conversation has messages and conversationID's match
              if (messages.length && messages[0].conversationID === conversationID) {
                const { Content: mostRecentRestoredMsgContent = '' } = messages[messages.length - 1]
                const { conversationID } = conversation

                // Create a new object to add pre-existing convo back to ConversationList
                const restoredConvo = {
                  conversationID,
                  msgContent: mostRecentRestoredMsgContent
                }

                updateRestoredMsgContent(restoredConvo)
              }
            }

            const checkedContent = content.length > 25 ? `${content.slice(0, 25)}...` : content
            if (checkedContent) {
              return checkedContent
            }
          }
        } catch (err) {
          console.error(err)
          return ''
        }
      }
    }

    if (conversation && Boolean(conversationID)) {
      setMessageContent(parsedMessageContent(conversation))
    }
  }, [conversation, conversationID, messages, updateRestoredMsgContent])

  // Switch to a new conversation
  const handleConversationChange = async (convo) => {
    // pull the view from the url params
    const { view } = parsedProps

    // If we have a conversationID
    if (convo.conversationID && ((view && view === viewTypes.MESSAGE.toLowerCase()) || (view && view === viewTypes.COMPOSE.toLowerCase()))) {
      // If the socket has been closed, reopen it
      if (!messagesInitialLoad.current) { messagesInitialLoad.current = true }

      // Set the users to those in the new conversation
      dispatch(setActiveConversationUsers(convo.userListDetails))

      // If we are clicking on a conversation but are not currently in the message view (i.e in compose view), switch back to message view
      loadNewView(viewTypes.MESSAGE, convo.conversationID)

      if (smScreen) {
        // swap from conversation area to message area to display message list
        setCurrentForm('message-area')

        // if there is a draft message for a conversation, set the editor to be open, otherwise set it to clsoe
        if (drafts && drafts.length && drafts.length > 0 && drafts.find(x => x.conversationID === convo.conversationID)) { setEditorOpen(true) } else if (editorOpen) { setEditorOpen(false) }
      }
    }
  }

  // This is using the first conversation returned in array from get conversations to set user list and active covnersation ID
  const fireConversationSuccess = (conversation) => {
    if (conversation) {
      const { conversationID, userListDetails } = conversation
      dispatch(setActiveConversationUsers(userListDetails))
      loadNewView(viewTypes.MESSAGE, conversationID)
    } else { loadNewView(viewTypes.DEFAULT) }
  }

  const hideSuccess = async () => {
    // get conversation list after hiding conversation
    dispatch(getConversations(fireConversationSuccess))
  }

  const handleHideConversation = (conversationID) => {
    dispatch(hideConversation(conversationID, hideSuccess))
  }

  const findCardIcon = () => {
    if (conversationID) {
      const hasDraft = drafts.find(x => x.conversationID === conversationID)

      if (hasDraft) {
        return (
          <Tooltip title='Draft' TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} arrow placement='right' enterDelay={750}>
            <div>
              <ModifiedEditIcon style={{ fontSize: 16, color: theme.palette.grey.medium, zIndex: 1 }} />
            </div>
          </Tooltip>
        )
      } else {
        return (
          <Tooltip title='Hide Conversation' TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} arrow placement='right' enterDelay={750}>
            <IconButton size='small' onClick={(e) => { e.stopPropagation(); handleHideConversation(conversationID) }}>
              <HighlightOffRoundedIcon style={{ fontSize: 16, color: theme.palette.grey.medium, zIndex: 1 }} />
            </IconButton>
          </Tooltip>
        )
      }
    }
    return ''
  }

  return (
    <>
      <Paper elevation={0} style={{ backgroundColor: activeCard ? theme.palette.purple.lightest : 'white', padding: '.5em' }}>
        <Grid item container direction='row'>
          {/* User Avatar */}
          <Grid item container xs={2} style={{ display: mdScreen ? 'none' : 'initial' }}>
            <Badge
              overlap='circular'
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              invisible={!unreadMessages}
              color='primary'
              variant='dot'
              classes={{ dot: classes.unreadBadge, colorPrimary: classes.badgeColor }}
            >
              <Avatar src={userAvatar} {...avatarProps} className={classes.avi} />
            </Badge>
          </Grid>
          {/* Message Information */}
          <Grid
            item
            container
            direction='column'
            xs={mdScreen ? 12 : 10}
            style={{ cursor: 'pointer', overflow: 'hidden' }}
            onClick={(e) => { e.preventDefault(); handleConversationChange(conversation) }}
          >
            {/* Name and Time Sent */}
            <Grid item container direction='row'>
              <Grid item container xs={8} alignItems='baseline' direction='row'>
                <Grid item container xs={6} sm={6} md={12}>
                  <Tooltip arrow title={!userName ? '' : userName} placement='top' enterDelay={1000} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
                    <Typography
                      variant='h5'
                      noWrap
                      style={{ fontWeight: 600, textTransform: 'none', paddingRight: '.2em' }}
                    >
                      {!userName ? '' : userName}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item container xs={12}>
                  <Tooltip arrow title={!userSchool ? '' : userSchool} placement='left-end' enterDelay={1000} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
                    <Typography
                      variant='caption'
                      noWrap
                      style={{ fontWeight: 400, textTransform: 'none', color: theme.palette.grey.dark }}
                    >
                      {!userSchool ? '' : userSchool}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid item container justifyContent='flex-end' xs={4}>
                <Typography
                  variant='caption'
                  noWrap
                  style={{ fontWeight: unreadMessages ? 600 : 400, textTransform: 'none', color: unreadMessages ? 'black' : theme.palette.grey.dark, textAlign: 'end' }}
                >
                  {moment.unix(mostRecentCreatedAt).format('ddd h:mm a')}
                </Typography>
              </Grid>
            </Grid>

            {/* Message Preview and Delete/Edit Icon */}
            <Grid item container direction='row' alignItems='center' style={{ marginBottom: '1em', marginTop: '.2em' }}>
              <Grid item container direction='row' xs={10}>
                <Typography style={{ color: unreadMessages ? 'black' : theme.palette.grey.dark, fontWeight: unreadMessages ? 600 : 400 }}>
                  {!messageContent ? '' : messageContent}
                </Typography>
              </Grid>
              <Grid item container direction='row' xs={2} justifyContent='flex-end'>
                {findCardIcon()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Divider variant='middle' style={{ margin: '.2em 0' }} />
    </>
  )
}

export default ConversationCard
