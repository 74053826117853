import React from 'react'

import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Grid, Typography, IconButton, Button, useTheme
} from '@material-ui/core'

import { CloseRounded } from '@material-ui/icons'

import { statusTypes } from '../../../utils'

const SubmitToEdFarmModal = (props) => {
  const {
    setEfSubmit = () => { }, isPrivate = false, setIsPrivate = () => { },
    setEFSubmitModalOpen = () => { }, efSubmitModalOpen = false, classes = {}, styles = {},
    resubmitConfirmed, artifactStatus = ''
  } = props

  const theme = useTheme()

  const handleModalClose = () => {
    setEFSubmitModalOpen(false)
  }

  const handleSubmitConfirm = () => {
    if (isPrivate) {
      setIsPrivate(false)
    }

    if (artifactStatus && artifactStatus === statusTypes.needsChanges) {
      resubmitConfirmed.current = true
    }

    setEfSubmit(true)

    handleModalClose()
  }

  return (
    <Dialog
      fullWidth
      maxWidth='xs'
      open={efSubmitModalOpen}
      onClose={() => handleModalClose()}
    >
      <DialogTitle disableTypography>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography
              variant='h4'
              style={{ textTransform: 'none', color: styles.mainColor, fontSize: '1.3rem' }}
            >
              Confirm Submission to Ed Farm
            </Typography>
          </Grid>

          <Grid item>
            <IconButton style={{ padding: '0px' }} onClick={() => setEFSubmitModalOpen(false)}>
              <CloseRounded className={classes.customizedButton} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid container direction='column' style={{ marginBottom: '.5em' }}>
          <Typography variant='h4' style={{ color: theme.palette.grey.dark, fontWeight: 400 }}>
            This action cannot be undone and will permanently set your artifact to public.
          </Typography>
          <Typography variant='h6' style={{ color: theme.palette.grey.dark, textTransform: 'none', fontWeight: 400, marginTop: '1em', fontStyle: 'italic' }}>
            * You will need to save your artifact to complete your submission.
          </Typography>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={(e) => { e.preventDefault(); handleModalClose() }}
          style={{
            color: styles.mainColor,
            textTransform: 'none',
            textDecoration: 'underline',
            fontWeight: '600',
            fontSize: '.9rem'
          }}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          style={{ backgroundColor: styles.mainColor }}
          onClick={handleSubmitConfirm}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SubmitToEdFarmModal
