import { trackPromise } from 'react-promise-tracker'
import { GET_APPROVAL_STATUSES, LOGIN } from '../types'
import { formValidationTypes, parseResponse, responseTypes } from '../../lib'
import log from '../../utils/logger'
import moment from 'moment'

// UI Tech Debt: Removed api variable that wasn't being used anywhere.

const api = process.env.REACT_APP_API_URL

export const registerUser = (registerInfo, fireNotification) => {
  return async function (dispatch, getState) {
    const { email, fullName, password } = registerInfo

    const body = {
      userName: email,
      fullName: fullName,
      password: password
    }

    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }

    const response = await trackPromise(window.fetch(`${api}/v1/user`, options))
    const parsedResponse = parseResponse(response, dispatch)
    if (!parsedResponse) { return false }
    if (parsedResponse.type === responseTypes.SUCCESS) {
      const data = await response.json()
      const { token, userID, fullName, nickName, emailAddress, onboardingStep, isActive, isValidated, profileID } = data

      const payload = { token, userID, fullName, nickName, emailAddress, onboardingStep, isActive, isValidated, profileID }
      dispatch({
        type: LOGIN,
        payload
      })
    } else {
      log.debug('Bad response')
      log.error(response)
      try {
        const data = await response.json()
        const { token, userID, fullName, nickName, emailAddress, onboardingStep, isActive, isValidated, profileID } = data

        const payload = { token, userID, fullName, nickName, emailAddress, onboardingStep, isActive, isValidated, profileID }
        dispatch({
          type: LOGIN,
          payload
        })
      } catch (err) { log.error(err); parsedResponse.error = true }
      fireNotification(parsedResponse)
    }
  }
}

export const resendValidationEmail = (userName, fireResponse = () => {}) => {
  return async function (dispatch, getState) {
    const { userID, emailAddress, token, profileID = null } = getState().auth
    if (userName !== emailAddress) { return false }

    const body = { userName: emailAddress, userID, profileID }

    const options = {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(body)
    }

    let error = false
    const response = await trackPromise(window.fetch(`${api}/v1/user/validate/resend`, options))
    const parsedResponse = parseResponse(response, dispatch)

    if (!parsedResponse || parsedResponse?.error) {
      log.debug('Bad response')
      error = true
    }

    fireResponse(error)
  }
}

export const validateUserEmail = (profileID, validationKey, fireNotification = () => { }) => {
  return async function (dispatch) {
    const body = { validationKey, profileID }
    const errorObj = { error: true }
    const options = {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }

    const response = await trackPromise(window.fetch(`${api}/v1/user/validate`, options))
    const parsedResponse = parseResponse(response, dispatch)

    fireNotification(parsedResponse || errorObj)
  }
}

export const getApprovalStatus = (fireSuccess = () => { }, fireNotification = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { userID, token, waitTime } = getState().auth

      if (waitTime !== null && waitTime > moment().unix()) { return fireNotification(formValidationTypes.DDOS_ERROR) }

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${api}/v1/user/${userID}/awaiting`, options))
      const parsedResponse = parseResponse(response, dispatch)
      if (!parsedResponse) { return false }

      if (parsedResponse.error) { return fireNotification(formValidationTypes.SEND_ERROR) }

      const { data: { isAccountApproved, isValidated, isAccountDenied, approvedDate, deniedDate, roleID } } = await response.json()
      const newWaitTime = moment().add(5, 'seconds').unix()

      const payload = { isAccountApproved, isValidated, isAccountDenied, approvedDate, deniedDate, waitTime: newWaitTime, roleID }

      dispatch({
        type: GET_APPROVAL_STATUSES,
        payload
      })
    } catch (err) {
      console.error(err)
      fireNotification(formValidationTypes.SEND_ERROR)
    }
  }
}
