import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter, Prompt } from 'react-router-dom'
import queryString from 'query-string'

import { NavBar, NotificationToast } from '../ui/tools'
import { defaultAttemptDetailsForm, defaultMCEDetails, mceViews, mceSections, attemptStatuses } from '../../utils/variables'
import {
  Grid, Typography, useTheme, makeStyles, Button, useMediaQuery
} from '@material-ui/core'

import {
  CredentialHeader, IntroductionAccordion, MCEPartTwo, MCEQuestions
} from '../ui/MCE'

import MCESubmitAndResults from '../ui/MCE/MCETools/MCESubmitAndResults'
import MCENavgiagteExitModal from '../ui/MCE/MCETools/MCENavigateExitModal'

import {
  getMCEDetails, getUserCurrentMCEAttempts, getUserMCEAttemptDetails,
  postMCEAttempt, updateMCEAttempt, resetCurrentAttemptDetails, updateAssessorGrade
} from '../../redux/actions'
import { isEqual, cloneDeep, omit } from 'lodash'

const useStyles = makeStyles(theme => ({
  customizedButton: {
    backgroundColor: 'none',
    color: theme.palette.grey.dark,
    stroke: theme.palette.grey.dark,
    strokeWidth: '2px',
    height: '1em',
    width: '1em',
    '&:hover': {
      stroke: theme.palette.grey.dark
    }
  },
  defaultPadding: {
    padding: '0 4em'
  },
  filterSelect: {
    fontSize: '14px',
    fontWeight: '600',
    color: theme.palette.grey.dark,
    fontFamily: 'Source Sans Pro',
    paddingRight: '0px !important',
    '&:focus': {
      backgroundColor: 'white'
    }
  },
  selectIcon: {
    fontSize: '14px',
    top: 'auto',
    position: 'inherit'
  },
  reviewSelectInput: {
    border: 'none'
  },
  sectionSpacing: {
    padding: '2em 4em'
  },
  badgeColor: {
    backgroundColor: theme.palette.logo.orange
  },
  headerButtons: {
    fontWeight: 600,
    padding: '.2em 2em',
    fontSize: 18
  },
  backToSummaryButton: {
    fontWeight: 600,
    textTransform: 'none',
    padding: 0,
    color: theme.palette.purple.darkest,
    textDecoration: 'underline'
  },
  assessorButton: {
    fontWeight: 600,
    padding: '.2em 2em',
    fontSize: 18
  },
  accordionSummaryContent: {
    margin: 0,
    '&.Mui-expanded': {
      margin: 0
    }
  },
  accordionSummaryRoot: {
    padding: '0 4em',
    minHeight: '.5em',
    '&.Mui-expanded': {
      minHeight: '.5em'
    }
  },
  keyMethod: {
    lineHeight: 1,
    color: 'white',
    '& p': {
      margin: '0.5em 0'
    }
  },
  resourceHTML: {
    color: theme.palette.purple.darkest,
    fontSize: 14,
    '& a': {
      color: theme.palette.purple.darkest,
      fontWeight: 600
    }
  },
  questionHTML: {
    fontSize: 16,
    fontWeight: 600,
    '&:nth-child(1)': {
      '& p': { display: 'inline' }
    }
  },
  evalHTML: {
    fontSize: 14,
    fontWeight: 400,
    '& p': {
      margin: '.2em 0'
    }
  },
  researchHTML: {
    fontSize: 14,
    fontWeight: 400
  },
  workSampleHTML: {
    fontWeight: 600,
    '& p': {
      margin: '.2em 0'
    }
  },
  sampleDescHTML: {
    fontSize: 14,
    fontWeight: 400,
    textAlign: 'left',
    '& a': {
      color: theme.palette.purple.darkest,
      fontWeight: 600,
      textDecoration: 'none'
    }
  },
  methodComp: {
    fontSize: 14
  },
  defaultSectionPadding: {
    padding: '0 15%'
  },
  summarySectionPadding: {
    padding: '0'
  },
  sectionPassedChip: {
    border: '2px solid #478d05',
    color: '#478d05',
    fontWeight: 600
  },
  sectionFailedChip: {
    border: '2px solid #d32508',
    color: '#d32508',
    fontWeight: 600
  },
  attemptPassedChip: {
    backgroundColor: '#478d05',
    color: 'white',
    fontWeight: 600
  },
  attemptFailedChip: {
    backgroundColor: '#d32508',
    color: 'white',
    fontWeight: 600
  },
  selectGroupHeader: {
    color: theme.palette.purple.darkest,
    fontWeight: 600
  },
  readOnlyQuestionView: {
    '&:disabled': {
      color: theme.palette.purple.darkest,
      height: 'inherit',
      fontWeight: 500,
      textAlign: 'left'
    }
  },
  noInputQuestion: {
    '&:disabled': {
      color: theme.palette.grey.medium,
      minHeight: 'auto !important',
      height: 'auto',
      fontWeight: 400,
      fontStyle: 'italic',
      textAlign: 'center'
    }
  },
  reviewResultsButton: {
    '&:disabled': {
      backgroundColor: theme.palette.purple.darkest,
      color: 'white'
    }
  },
  readOnlyRadios: {
    '&.Mui-disabled': {
      color: '#f50057'
    }
  }
}))

const CredentialView = (props) => {
  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { mceDetails = {} } = useSelector(state => state.microcredentials)
  const { currentAttemptDetails = {} } = useSelector(state => state.mceAttempts)

  // ******************* NAV CONDITIONALS **************************** //
  const [navRef, setNavRef] = useState(null)
  const navHeight = navRef ? navRef.clientHeight : 0

  // ******************** SCREEN SIZE CONDITIONALS *************************** //
  const mdScreen = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md))

  // ********************** URL AND SEARCH QUERY PARAMS ************************* //
  const parsedProps = queryString.parse(props.location.search)
  const mceView = parsedProps?.view || mceViews.SUMMARY
  const mceParamID = parsedProps?.mce || null
  const mceAttemptID = parsedProps?.attempt || null
  const artifactBooleanParam = parsedProps?.fromArtifact || null
  const propsRef = useRef({ ...parsedProps })
  const fromArtifactView = useRef(false)

  // *********************** VIEW CONDITIONALS ************************ //
  const isSummaryView = mceView && mceView === mceViews.SUMMARY
  const isAssessorView = mceView && mceView === mceViews.ASSESS
  const isResultsView = mceView && mceView === mceViews.RESULTS
  const isPursuerView = mceView && mceView === mceViews.PURSUE

  // ************************ EXIT NAVIGATION STATE *********************** //
  const [openNavigationConfirm, setOpenNavigationConfirm] = useState(false)
  const [confirmNavigate, setConfirmNavigate] = useState(false)
  const lastLocation = useRef(null)

  // ************************ LOCAL STATE *********************** //
  // The current section of the process being viewed
  const [currentSection, setCurrentSection] = useState(mceSections.PART1)
  // introduction accordion state
  const [infoExpanded, setInfoExpanded] = useState(false)
  // state determining if we are reviewing current responses (pursuer and assessor views)
  const [submissionReview, setSubmissionReview] = useState(false)
  // the official local form for an attempt being taken
  const [attemptDetailsForm, setAttemptDetailsForm] = useState(cloneDeep(defaultAttemptDetailsForm))

  // *********************** USE MEMO/EFFECTS ************************ //

  // sets a local ref based on a query param set when returning to the mce process from the artifact view
  useMemo(() => { if (artifactBooleanParam) { fromArtifactView.current = true } }, [artifactBooleanParam])

  // be sure we have the correct mce details when in the summary view
  useMemo(() => {
    if (isSummaryView) {
      // if we have no mce details, or the mceID in the params does not match the mce details reset everything to match
      if ((isEqual(mceDetails, defaultMCEDetails)) || (mceParamID && mceParamID !== mceDetails.mceID)) {
        dispatch(getMCEDetails({ mceID: mceParamID, includeHistoryBool: false, noDispatchBool: false }))
      }
    }
  }, [mceDetails, dispatch, isSummaryView, mceParamID])

  // callback to handle moving between sections
  const handleChangeSection = useCallback((section) => {
    // scroll to the top
    window.scrollTo(0, 0)

    // be sure the introduction accordion is closed
    if (infoExpanded) {
      setInfoExpanded(false)
    }

    // be sure we are no longer in the submission review state (if not in part 4)
    let reviewState = false
    if (section === mceSections.PART4) {
      reviewState = true
    }
    setSubmissionReview(reviewState)

    // move tot he selected section
    setCurrentSection(section)
  }, [infoExpanded])

  // If reviewing results, be sure to navigate directly to section 4
  useMemo(() => {
    if (isResultsView && currentSection !== mceSections.PART4) {
      handleChangeSection(mceSections.PART4)
    }
  }, [isResultsView, currentSection, handleChangeSection])

  // if we are not in the summary view, check for new params to pull attempt/mce details
  useMemo(() => {
    if (mceView && mceView !== mceViews.SUMMARY) {
      if (mceParamID && mceAttemptID) {
        if (mceDetails.mceID !== mceParamID) {
          dispatch(getMCEDetails({ mceID: mceParamID, includeHistoryBool: false, noDispatchBool: false }))
        }
        dispatch(getUserMCEAttemptDetails({ attemptID: mceAttemptID, mceID: mceParamID }))
      }
    }
  }, [mceView, mceAttemptID, mceParamID, mceDetails, dispatch])

  // if the current attempt details (in redux) is not in it's default state, and does not match the local attempt detail form, set the local form to the redux value
  useMemo(() => {
    if (currentAttemptDetails && currentAttemptDetails.attemptID &&
      (!attemptDetailsForm ||
        !attemptDetailsForm?.attemptID ||
        !isEqual(currentAttemptDetails?.attemptID, attemptDetailsForm?.attemptID))
    ) {
      setAttemptDetailsForm(cloneDeep(currentAttemptDetails))
    }
  }, [currentAttemptDetails, attemptDetailsForm])

  // remove the param indicating we have returned to the mce process from an artifact view
  const replaceParams = useCallback((section) => {
    // use the local ref as a starting point for the param manipulation
    let newProps = propsRef.current

    // remove the key in the object for the artifact param
    newProps = omit(newProps, 'fromArtifact')

    // use the manipulated object to generate a new set of search params
    const newParams = new URLSearchParams(newProps)

    // replace the current set with the new set
    props.history.replace({
      search: newParams.toString()
    })

    // reset local refs when complete
    propsRef.current = { ...newProps }
    fromArtifactView.current = false

    // change section
    handleChangeSection(section)
  }, [propsRef, props.history, handleChangeSection])

  // If returning to the microcredential page from the artifact views, use the values in the params to set everything to what it needs to be
  useMemo(() => {
    // if the 'fromArtifact' param is true and we also recived a mceParamID
    if (artifactBooleanParam && mceParamID) {
      // use the mceID in the params, to fetch the correct mce details
      dispatch(getMCEDetails({ mceID: mceParamID, includeHistoryBool: false, noDispatchBool: false }))

      const newSection = artifactBooleanParam === 'create' ? mceSections.PART2 : mceSections.PART4

      if (!submissionReview && artifactBooleanParam === 'public') {
        setSubmissionReview(true)
      }

      // now determine the current props, remove the fromArtifact one, and replace the search params without the omitted value
      if (fromArtifactView.current) {
        replaceParams(newSection)
      }
    }
  }, [mceParamID, artifactBooleanParam, dispatch, replaceParams, submissionReview])

  // *************************** ASSESSOR LOGIC ***************************** //
  // ************************************************************************ //

  // boolean to check if an attempt has been 'passed' or 'failed'. (excludes section 4 as that section of for local purposes and is not associated with the actual form)
  const passedEval = Boolean(attemptDetailsForm && Object.values(mceSections).every(x => x === mceSections.PART4 || (x !== mceSections.PART4 && attemptDetailsForm[x].every(q => q.assessorGrade))))

  // handle navigating to a new section, mce, attempt, or view
  const handleDetailViews = (attemptID, mceID, section = mceSections.PART1, view = mceViews.PURSUE) => {
    props.history.push({
      pathname: '/microcredentials',
      search: `?view=${view}&mce=${mceID}&attempt=${attemptID}`
    })
    handleChangeSection(section)
  }

  // fires after successfull post of new attempt
  const firePostSuccess = (attemptID, mceID) => {
    // if we now have an attemptID, proceed with navigating to the pusuer view
    if (attemptID && mceID) {
      handleDetailViews(attemptID, mceID)
    }
  }

  const firePostFailure = () => {
    NotificationToast(true, 'Something went wrong. Please try again later.')
  }

  // Handler to decide, based on the user's current attempt at an mce, wether we are viewing results or posting a new attempt
  const handlePursuerAction = (attemptDetails, nextAttempt = 1) => {
    // if no attempt details have been provided then we need to create a new attempt record before proceeding with changing to pursuer view
    if (!Object.keys(attemptDetails).length) {
      return dispatch(postMCEAttempt({ mceID: mceParamID, attemptCount: nextAttempt }, firePostSuccess, firePostFailure))
    }

    // otherwise, if we have a current attempt, extract the details needed to decide what sections and view we need to navigate to
    const { attemptStatus, attemptID, mceID } = attemptDetails

    let view = mceViews.PURSUE
    let section = mceSections.PART1

    // if the attempt is post grading process, take the user to the review instead of pursuer process
    if (attemptStatus === attemptStatuses.PASS || attemptStatus === attemptStatuses.FAIL) {
      view = mceViews.RESULTS
      section = mceSections.PART4
    }

    handleDetailViews(attemptID, mceID, section, view)
  }

  // *********** GRADING LOGIC ************ //
  // memo to check if a work sample was graded but then had a rubric row value removed and change the graded value to null
  useMemo(() => {
    if (isAssessorView) {
      // the current attempt form
      const { P2 = [] } = attemptDetailsForm

      // the details of the micro cred
      const { P2: mceP2 = [] } = mceDetails

      // isolate the part 2 array
      if (P2 && P2.length) {
        // deep clone to avoid unintentional modification of the original
        const clonedP2 = cloneDeep(P2)

        // look for any values in the cloned arr to see if any work samples have been graded
        const modifiedP2 = P2.reduce((arr, workSample) => {
          const { assessorGrade, rubricResponses = [], workSampleID = '' } = workSample

          // find the same sample and its rows in the mce deets
          const mceSample = mceP2.find(x => x.workSampleID === workSampleID)
          const { rubricRows = [] } = mceSample || {}

          if (assessorGrade !== null) {
            // if graded, be sure every rubric row also has a response
            const rubricFullyGraded = rubricRows?.length === rubricResponses?.length && rubricResponses.every(r => r.assessorSelection)

            // if not, change the grade of that works sample back to null
            if (!rubricFullyGraded) {
              workSample.assessorGrade = null
            }
          }

          arr.push(workSample)

          return arr
        }, [])

        // if after performing these checks we have different values for the original and modified arrays
        // set the modified array t state
        if (!isEqual(clonedP2, modifiedP2)) {
          setAttemptDetailsForm({ ...attemptDetailsForm, P2: modifiedP2 })
        }
      }
    }
  }, [attemptDetailsForm, mceDetails, isAssessorView])

  // build an object every time a question/sample is graded and keeps updated tabs for each section's graded values
  const questionGradingValues = useMemo(() => {
    const { P1 = [], P2 = [], P3 = [] } = attemptDetailsForm

    // init an object to return
    const sectionGradeValues = {
      P1: {},
      P2: {},
      P3: {}
    }

    // go through each section and build its values
    Object.keys({ P1, P2, P3 }).forEach(sectionKey => {
      // the section's questions or samples
      const questions = attemptDetailsForm[sectionKey] || []
      // how many questions or samples are in the section
      const questionCount = questions.length || 0
      // the number of questions that have an assessorGrade
      const gradedCount = questions.reduce((acc, qu) => qu.assessorGrade !== null ? acc + 1 : acc, 0)
      // Boolean to see if that section is fully graded
      const sectionFullyGraded = Boolean(questionCount !== 0 && gradedCount === questionCount)
      // Boolean to determine if after being fully graded it has passed
      const sectionPassed = Boolean(questions.every(x => x.assessorGrade))

      // set the new values into the appropriate section of the sectionGradeValues arr
      sectionGradeValues[sectionKey] = { questionCount, gradedCount, sectionFullyGraded, sectionPassed }
    })

    return sectionGradeValues
  }, [attemptDetailsForm])

  // logic to handle modifying a grade for a question or sample
  const handleGradeQuestion = (section, quID, grade) => {
    const questions = attemptDetailsForm[section] || []
    const tempQuestions = [...questions]
    const currentQuestion = tempQuestions.find(x => (x.questionID === quID) || (x.workSampleID === quID))
    if (currentQuestion) {
      currentQuestion.assessorGrade = grade
    }

    setAttemptDetailsForm({ ...attemptDetailsForm, [section]: tempQuestions })
  }

  // Determines if an evaluation has been fully graded
  const fullyGradedEvaluation = attemptDetailsForm && Object.values(mceSections).every(x => (x !== mceSections.PART4 && attemptDetailsForm[x].every(q => q.assessorGrade !== null)) || x === mceSections.PART4)

  const fireFailGrading = () => {
    NotificationToast(true, 'Something went wrong.')
  }

  // trigger navigation by setting the confirm state to true
  const fireSuccessReset = () => {
    setConfirmNavigate(true)
    resetToDashboard()
  }

  // Handle the submission of a graded attempt
  const handleGradeAttempt = () => {
    const clonedAttempt = cloneDeep(attemptDetailsForm)

    const { attemptID, attemptCommentID, attemptComment, P1, P2, P3 } = clonedAttempt
    const gradedInfo = {
      attemptStatus: passedEval ? attemptStatuses.PASS : attemptStatuses.FAIL,
      attemptCommentID,
      attemptComment,
      P1,
      P2,
      P3
    }

    dispatch(updateAssessorGrade(gradedInfo, attemptID, fireSuccessReset, fireFailGrading))
  }

  // ****************** COMMENTING LOGIC ********************* //
  const modifyComment = (question, commentInput, questionSection) => {
    // if the input came in as a falsy value, set it to a strict null
    const newComment = !commentInput ? null : commentInput

    // if we are modifying a specific section's comments, instead of the general comment (located in section 4)
    if (questionSection) {
      // find the correct section, and the correct question/sample in that section and assign the comment value to that index
      const tempArr = cloneDeep(attemptDetailsForm[questionSection])
      const tempQuestion = { ...question }
      tempQuestion.attemptComment = newComment
      const objKey = questionSection === mceSections.PART2 ? 'workSampleID' : 'questionID'

      const qIndex = tempArr.findIndex(x => x[objKey] === tempQuestion[objKey])

      if (qIndex !== -1) {
        tempArr[qIndex] = tempQuestion
      }
      setAttemptDetailsForm({ ...attemptDetailsForm, [questionSection]: tempArr })
    } else {
      // otherwise the comment is the general comment and is on the highest level of the form obj
      setAttemptDetailsForm({ ...attemptDetailsForm, attemptComment: newComment })
    }
  }

  // *************************** PURSUER LOGIC ****************************** //
  // ************************************************************************ //

  // Save any changes to the attempt
  const handleSaveAttempt = (submitBool, leaveProcess = false) => {
    const successFunc = !leaveProcess ? () => { } : fireSuccessReset
    const updateAttemptForm = {
      attemptStatus: submitBool ? attemptStatuses.SUBMITTED : attemptStatuses.DRAFT,
      answersToAdd: [],
      answersToUpdate: [],
      samplesToAdd: [],
      samplesToRemove: []
    }

    const tempLocalForm = { ...attemptDetailsForm }

    const { attemptID, mceID, P1, P3 } = tempLocalForm
    const allQuestions = [...P1, ...P3]

    allQuestions.forEach((question) => {
      const { questionID, answerID = null, questionAnswer = null } = question
      const newObj = { questionAnswer, ...(!answerID ? { questionID } : { answerID }) }
      const arr = answerID ? updateAttemptForm.answersToUpdate : updateAttemptForm.answersToAdd

      if (answerID || (questionAnswer && questionAnswer.length && !answerID)) {
        arr.push(newObj)
      }
    })

    dispatch(updateMCEAttempt(updateAttemptForm, attemptID, mceID, successFunc))
  }

  // *************************** NAVIGATION LOGIC ***************************** //
  // ************************************************************************** //

  // listener use effect to catch when a user has confirmed navigation and we have a location set
  useEffect(() => {
    if (confirmNavigate && lastLocation.current) {
      const { pathname, search } = lastLocation.current
      props.history.push({
        pathname,
        search
      })

      // once navigation has been acted on, reset the local states triggering the use effect
      lastLocation.current = null
      setConfirmNavigate(false)
    }
  }, [confirmNavigate, props.history])

  // check to be sure (if in assessor view) that there are no missing comments on failed question/samples before allowing to move to next section
  const checkProceedPermisison = (section) => {
    const checkSection = currentSection !== mceSections.PART4 ? attemptDetailsForm[currentSection] : null
    const deniedCommentCheck = checkSection && Object.keys(checkSection) && checkSection.every(q => q.assessorGrade === null || q.assessorGrade || (q.assessorGrade === 0 && q.attemptComment))

    if (currentSection !== mceSections.PART4 && isAssessorView && !deniedCommentCheck) {
      return NotificationToast(true, 'You must provide a comment for the question or sample that did not pass before you can proceed.')
    }

    handleChangeSection(section)
  }
  // move to next section
  const handleNextSection = () => {
    const nextSectionIndex = Object.values(mceSections).indexOf(currentSection) + 1
    const nextSection = Object.values(mceSections)[nextSectionIndex]

    checkProceedPermisison(nextSection)
  }

  // move to previous section
  const handleBackSection = () => {
    const backSectionIndex = Object.values(mceSections).indexOf(currentSection) - 1
    const backSection = Object.values(mceSections)[backSectionIndex && backSectionIndex >= 0 ? backSectionIndex : 0]

    checkProceedPermisison(backSection)
  }

  // Fires after a successful PUT call to update form OR when the user exits without saving
  const resetToDashboard = () => {
    window.scrollTo(0, 0)
    dispatch(resetCurrentAttemptDetails())
    dispatch(getUserCurrentMCEAttempts())
    setAttemptDetailsForm(cloneDeep(defaultAttemptDetailsForm))
    handleChangeSection(mceSections.PART1)
  }

  // Exit the pursue or review process (will trigger a navigation block until the user confirm they do or do not want to save)
  const handleBackToDashboard = () => {
    // if we are in the results view, there is no need to save
    if (isResultsView) {
      // set a ref location to navigate to after navigation confirmed (setting this way bypasses the navigation prompt trigger)
      if (!lastLocation.current) {
        lastLocation.current = {
          pathname: '/microcredentials',
          search: `?view=${mceViews.DASHBOARD}`
        }
      }

      // directly confirm navigation and reset
      fireSuccessReset()
    } else {
      // otherwise directly attempt to navigate and trigger the prompt for saving
      props.history.push({
        pathname: '/microcredentials',
        search: `?view=${mceViews.DASHBOARD}`
      })
    }
  }

  // Listener for react-router Prompt that fires when a user attempts to exit the Pursuer process
  const navigationBlock = (nextLocation, action) => {
    // if the confirmNavigate state has not been set and we are not specifically moving to the artifacts page
    if (!confirmNavigate && nextLocation?.pathname !== '/artifact') {
      // set the requested location in a ref
      lastLocation.current = nextLocation
      // open the confirm modal
      setOpenNavigationConfirm(true)

      // block navigation until user has confirmed navigation
      return false
    }

    // otherwise allow the navigation
    return true
  }

  // handler for when the user has selected an option in the confirm navigation modal
  const handleNavigateConfirm = (saveBool, submitBool = false) => {
    // if we had no other location stored in a ref, store the summary view page
    if (!lastLocation.current) {
      lastLocation.current = {
        pathname: '/microcredentials',
        search: `?view=${mceViews.DASHBOARD}`
      }
    }
    // close the modal
    setOpenNavigationConfirm(false)

    // if they chose to save, run the action.
    if (saveBool) {
      // use the correct handler for is we are the pursuer or the assessor
      if (isAssessorView) {
        handleGradeAttempt()
      } else {
        handleSaveAttempt(submitBool, true)
      }
    } else {
      // otherwise go straight to the reset
      fireSuccessReset()
    }
  }

  // *************************** STYLING LOGIC ******************************** //
  // ************************************************************************** //

  // Styles for artifact pages
  const sectionStyles = useMemo(() => ({
    P1: {
      mainColor: theme.palette.aqua,
      secondaryColor: `${theme.palette.aqua}0D`
    },
    P2: {
      mainColor: theme.palette.pink.dark,
      secondaryColor: `${theme.palette.pink.dark}1A`
    },
    P3: {
      mainColor: theme.palette.logo.orange,
      secondaryColor: `${theme.palette.logo.orange}1F`
    },
    P4: {
      mainColor: theme.palette.purple.darkest,
      secondaryColor: theme.palette.grey.lightest
    }
  }), [theme])

  // Isolate the current style based on the section being viewed
  const currentStyle = useMemo(() => { return sectionStyles[currentSection] || sectionStyles?.P1 }, [sectionStyles, currentSection])

  // *************************** COMMON PROPERTIES/BOOLEANS ***************************** //
  // ************************************************************************** //
  const commonSectionBooleans = Boolean(isSummaryView || submissionReview)

  // Props shared between sections
  const commonSectionProps = {
    classes,
    currentStyle,
    isSummaryView,
    isAssessorView,
    isPursuerView,
    isResultsView,
    submissionReview,
    currentSection,
    questionGradingValues,
    handleGradeQuestion,
    fullyGradedEvaluation,
    attemptDetailsForm,
    setAttemptDetailsForm,
    handleSaveAttempt,
    navHeight,
    modifyComment
  }

  return (
    <>
      {/* Prompt listener that triggers when a user attempts to exit the page while in the pursuer/assessor process */}
      <Prompt when={isPursuerView || isAssessorView} message={navigationBlock} />

      {/* nav bar (set state may be causing render issues) */}
      <NavBar setNavRef={setNavRef} navHeight={navHeight} />

      <Grid container direction='column' style={{ marginTop: navHeight || '4em', marginBottom: '4em' }}>
        {/* HEADER */}
        <CredentialHeader
          classes={classes}
          sectionStyles={sectionStyles}
          navHeight={navHeight}
          mdScreen={mdScreen}
          mceView={mceView}
          isSummaryView={isSummaryView}
          isPursuerView={isPursuerView}
          isAssessorView={isAssessorView}
          handleBackToDashboard={handleBackToDashboard}
          currentSection={currentSection}
          submissionReview={submissionReview}
          isResultsView={isResultsView}
          attemptDetailsForm={attemptDetailsForm}
          checkProceedPermisison={checkProceedPermisison}
          handlePursuerAction={handlePursuerAction}
        />

        {/* INTRODUCTION */}
        <Grid container item style={{ margin: '2em 0 1em 0' }}>
          <IntroductionAccordion
            classes={classes}
            navHeight={navHeight}
            isSummaryView={isSummaryView}
            infoExpanded={infoExpanded}
            setInfoExpanded={setInfoExpanded}
            currentStyle={currentStyle}
          />
        </Grid>

        {/* GUIDELINES  */}
        {isSummaryView &&
          <Grid container item style={{ padding: '2em 4em', backgroundColor: theme.palette.purple.darkest }}>
            <Grid item>
              <Typography gutterBottom variant='h4' style={{ color: 'white', textTransform: 'uppercase', fontSize: 16 }}>Submission Guidelines and Evaluation Criteria</Typography>
            </Grid>
            <Grid item>
              <Typography variant='body1' style={{ color: 'white', fontSize: 16, maxWidth: '60%' }}>
                To earn the micro-credential, the Pursuer must demonstrate proficiency in all three parts of this micro-credential. Responses to each part are limited to no more than 400 words. Please do not include any information that will make you or others identifiable to Assessors.
              </Typography>
            </Grid>
          </Grid>}

        {/* SUBMISSION */}
        {!isSummaryView && submissionReview &&
          <Grid container item className={classes.sectionSpacing}>
            <MCESubmitAndResults
              classes={classes}
              isAssessorView={isAssessorView}
              isResultsView={isResultsView}
              isPursuerView={isPursuerView}
              mceView={mceView}
              attemptDetailsForm={attemptDetailsForm}
              setAttemptDetailsForm={setAttemptDetailsForm}
              handleNavigateConfirm={handleNavigateConfirm}
              fullyGradedEvaluation={fullyGradedEvaluation}
              currentStyle={currentStyle}
              passedEval={passedEval}
              handlePursuerAction={handlePursuerAction}
              questionGradingValues={questionGradingValues}
            />
          </Grid>}

        {/* PART 1 */}
        {Boolean(commonSectionBooleans || currentSection === mceSections.PART1) &&
          <Grid container item className={classes.sectionSpacing}>
            <MCEQuestions
              {...commonSectionProps}
              section={mceSections.PART1}
            />
          </Grid>}

        {/* PART 2 */}
        {Boolean(commonSectionBooleans || currentSection === mceSections.PART2) &&
          <Grid container item className={classes.sectionSpacing} style={{ backgroundColor: isSummaryView ? theme.palette.grey.lighter : 'white', borderRadius: 5 }}>
            <MCEPartTwo
              {...commonSectionProps}
            />
          </Grid>}

        {/* PART 3 */}
        {Boolean(commonSectionBooleans || currentSection === mceSections.PART3) &&
          <Grid container item className={classes.sectionSpacing}>
            <MCEQuestions
              {...commonSectionProps}
              section={mceSections.PART3}
            />
          </Grid>}

        {/* BOTTOM ROW BUTTONS */}
        {!isSummaryView && !submissionReview &&
          <Grid item container direction='row' justifyContent='flex-end' alignItems='center' spacing={4} className={classes.sectionSpacing} style={{ backgroundColor: 'white', position: 'fixed', bottom: 0, paddingTop: 0 }}>
            <Grid item>
              <Button variant='text' className={classes.backToSummaryButton} onClick={() => isPursuerView ? handleNavigateConfirm(true) : handleBackToDashboard()}>{`${isPursuerView ? 'Save and ' : ''}Exit`}</Button>
            </Grid>
            <Grid item>
              <Button
                variant='outlined'
                disabled={Boolean(currentSection === mceSections.PART1)}
                onClick={() => handleBackSection()}
                style={{ borderColor: currentSection === mceSections.PART1 ? theme.palette.grey.medium : currentStyle?.mainColor, color: currentSection === mceSections.PART1 ? theme.palette.grey.medium : currentStyle?.mainColor }}
              >
                Back
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={() => handleNextSection()} variant='contained' style={{ backgroundColor: currentStyle?.mainColor }}>Next</Button>
            </Grid>
          </Grid>}
      </Grid>

      {/* Navigation Save and Exit Modal */}
      <MCENavgiagteExitModal
        openNavigationConfirm={openNavigationConfirm}
        setOpenNavigationConfirm={setOpenNavigationConfirm}
        classes={classes}
        isAssessorView={isAssessorView}
        isPursuerView={isPursuerView}
        handleNavigateConfirm={handleNavigateConfirm}
      />

    </>
  )
}

export default withRouter(CredentialView)
