import React from 'react'
import {
  Grid, Accordion, AccordionSummary, AccordionDetails, Typography, useTheme, useMediaQuery
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import DashboardMCECard from './DashboardMCECard'
import OrangeBannerImage from '../../../../assets/BlueAquaBanner.svg'

const DashboardStackAccordion = (props) => {
  const { stack: { stackName = '', stackMCEs = [], stackLength = 0 }, handleViewChange } = props
  const theme = useTheme()
  const mdScreenUp = useMediaQuery(theme.breakpoints.up('md'))
  const totalMCEs = stackMCEs.length
  const totalAttempted = stackMCEs.reduce((acc, x) => { if (x.attemptStatus) { acc += 1 } return acc }, 0)

  return (
    <Grid item style={{ width: '100%' }}>
      <Accordion
        elevation={1}
        defaultExpanded={stackLength <= 2}
        style={{
          width: 'auto',
          padding: '.5em',
          backgroundImage: `url(${OrangeBannerImage})`,
          backgroundRepeat: 'none',
          backgroundPosition: 'initial'
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore style={{ color: 'white' }} />}>
          <Grid container item direction='column'>
            <Typography variant='h5' style={{ color: 'white', fontSize: 18 }}>{stackName}</Typography>
            <Typography variant='h5' style={{ color: 'white', fontWeight: 400 }}>{`${totalAttempted} of ${totalMCEs} attempted...`}</Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item container spacing={mdScreenUp ? 3 : 1} direction={mdScreenUp ? 'row' : 'column'} alignItems='stretch'>
            {stackMCEs.sort((a, b) => {
              if (Number(Boolean(a.attemptStatus)) < Number(Boolean(b.attemptStatus))) {
                return -1
              }
              if (Number(Boolean(a.attemptStatus)) > Number(Boolean(b.attemptStatus))) {
                return 1
              }

              return 0
            }).map((mce) => {
              const { mceID } = mce
              return (
                <DashboardMCECard key={`mce-card${mceID}`} mce={mce} handleViewChange={handleViewChange} />
              )
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}

export default DashboardStackAccordion
