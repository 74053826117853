import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'

import moment from 'moment'
import {
  Button, Grid, OutlinedInput, Typography, Link,
  useTheme, Avatar, Paper, Popover, Badge
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded'

import {
  postAdminArtifactComment, deleteAdminArtifactComment, updateAdminArtifactComment,
  insertArtifactComment, deleteArtifactComment, updateArtifactComment
} from '../../../redux/actions'
import { NotificationToast } from '../tools'

const ModifiedEditIcon = (props) => {
  const { color } = props
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20' height='18.337'
      viewBox='0 0 24 21.337'
    >
      <path
        id='edit-regular_1_'
        data-name='edit-regular (1)'
        d='M16.763,14.3,18.1,12.966a.335.335,0,0,1,.571.238v6.058a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V4.595a2,2,0,0,1,2-2H13.4a.335.335,0,0,1,.238.571L12.3,4.5a.33.33,0,0,1-.238.1H2V19.262H16.667V14.533A.328.328,0,0,1,16.763,14.3Zm6.525-8.409L12.346,16.833l-3.767.417a1.721,1.721,0,0,1-1.9-1.9L7.1,11.583,18.038.641a2.432,2.432,0,0,1,3.446,0l1.8,1.8a2.441,2.441,0,0,1,0,3.45ZM19.171,7.178,16.75,4.757,9.009,12.5l-.3,2.721,2.721-.3Zm2.7-3.321-1.8-1.8a.435.435,0,0,0-.617,0L18.167,3.345l2.421,2.421,1.288-1.288A.444.444,0,0,0,21.871,3.857Z'
        transform='translate(0 0.075)'
        fill={color}
      />
    </svg>
  )
}

const ArtifactComment = (props) => {
  const {
    commentState, setCommentState, fromPublic, section,
    styles, classes, commentInfo, artifactID, fireSuccess, fromCreate
  } = props
  const theme = useTheme()
  const dispatch = useDispatch()

  const { userID: authorID, profileAvatarPath, fullName } = useSelector(state => state.auth)

  const defaultCommentInfo = {
    comment: ''
  }

  // Checks to see if the user has reached the comment input from the admin review or from the artifact Author's edit artifact view
  const fellowView = !fromCreate ? false : fromCreate

  /* Used for setting the render of either the comment input or the comment itself. */
  const [commentSubmitted, setCommentSubmitted] = useState(false)

  // Helps transition state between POST updates.
  useEffect(() => {
    if (commentInfo) {
      setCommentSubmitted(true)
    }
  }, [commentInfo])

  const [newCommentInfo, setNewCommentInfo] = useState(defaultCommentInfo)
  const [editComment, setEditComment] = useState(false)

  // ***** Options Popover elements *******
  const [optionsAnchorEl, setOptionsAnchorEl] = useState(null)
  const handleOptionsPopover = (e) => {
    setOptionsAnchorEl(e.currentTarget)
  }
  const handleOptionsPopClose = () => {
    setOptionsAnchorEl(null)
  }
  const openOptionsPopover = Boolean(optionsAnchorEl)
  const optionsPopoverID = openOptionsPopover ? 'options-popover' : undefined

  // ******** Delete Comment ********
  const handleDeleteComment = () => {
    const tempCommentInfo = {
      commentID: fromPublic ? commentInfo.publicArtifactCommentID : commentInfo.commentID,
      artifactID: artifactID
    }

    // If the user has reached the comment input from the public view, delete from the regular artifact table
    if (fromPublic) {
      dispatch(deleteArtifactComment(tempCommentInfo, fireSuccess, fireDeleteFailure))
    } else {
      // Otherwise delete from the admin comment table
      dispatch(deleteAdminArtifactComment(tempCommentInfo, fireSuccess, fireDeleteFailure))
    }
    setNewCommentInfo(defaultCommentInfo)
    handleOptionsPopClose()
  }

  const fireDeleteFailure = () => {
    NotificationToast(true, 'Falied to Delete Comment')
  }

  // ******** Add Comment ********

  // Post Call click handler
  const handleAddComment = (artifactID) => {
    const tempCommentInfo = {
      ...newCommentInfo,
      artifactID,
      artifactSection: section
    }

    // If the user has reached the comment input from the public view, post to the regular artifact table
    if (fromPublic) {
      dispatch(insertArtifactComment(tempCommentInfo, fireSuccess, fireAddFailure))
    } else {
      // Otherwisepost to the admin comment table
      dispatch(postAdminArtifactComment(tempCommentInfo, fireSuccess, fireAddFailure))
    }
    setNewCommentInfo(defaultCommentInfo)
    setCommentState({ ...commentState, [section]: false })
  }

  const fireAddFailure = () => {
    NotificationToast(true, 'Falied to Create Comment')
  }

  // ********* Edit Comment *********

  // Transitions back to an input with the current comment as the value and changes the button's onClick handler
  const handleOpenCommentEditor = () => {
    setEditComment(true)
    setNewCommentInfo({ ...newCommentInfo, comment: commentInfo.comment })
    setCommentSubmitted(false)
    handleOptionsPopClose()
  }

  // Update Call click handler
  const handleEditComment = () => {
    const tempCommentInfo = {
      ...newCommentInfo,
      artifactID,
      commentID: fromPublic ? commentInfo.publicArtifactCommentID : commentInfo.commentID
    }

    // If the user has reached the comment input from the public view, updte on the regular artifact table
    if (fromPublic) {
      dispatch(updateArtifactComment(tempCommentInfo, fireSuccess, fireUpdateFailure))
    } else {
      // Otherwisepost to the admin comment table
      dispatch(updateAdminArtifactComment(tempCommentInfo, fireSuccess, fireUpdateFailure))
    }
    setNewCommentInfo(defaultCommentInfo)
  }

  const fireUpdateFailure = () => {
    NotificationToast(true, 'Falied to Update Comment')
  }

  // Handles cancel button
  const handleCancelComment = () => {
    if (editComment) {
      setEditComment(false)
      setCommentSubmitted(true)
    } else {
      setCommentState({ ...commentState, [section]: false })
    }
    setNewCommentInfo(defaultCommentInfo)
  }

  const handleUserProfileView = (id) => {
    props.history.push({
      pathname: '/profile',
      search: `?user=${id}`
    })
  }

  return (
    <Grid container item direction='column' style={{ marginTop: !commentInfo ? '1em' : 0 }}>
      {commentSubmitted &&
        <Badge
          variant='dot'
          color='secondary'
          overlap='rectangular'
          invisible={!fellowView || commentInfo.userHasViewed === 1 || fromPublic}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <Grid item container>
            <Paper elevation={fellowView || fromPublic ? 0 : 3} style={{ width: '100%', padding: '1em', backgroundColor: fellowView || fromPublic ? theme.palette.grey.lighter : 'white', border: fellowView || fromPublic ? `1px solid ${theme.palette.grey.light}` : 'none' }}>
              <Grid container direction='column'>
                <Grid item container direction='row' alignItems='center' style={{ marginBottom: '.3em' }}>
                  <Grid item container direction='row' alignItems='center' xs={11}>
                    {/* User Avatar */}
                    <Avatar
                      src={commentInfo && commentInfo.profileAvatarPath ? commentInfo.profileAvatarPath : ''}
                      style={{ height: '26px', width: '26px', marginRight: '.3em', cursor: 'pointer' }}
                      onClick={(e) => { e.preventDefault(); handleUserProfileView(commentInfo.profileID) }}
                    />

                    {/* User Name */}
                    <Link
                      variant='h6'
                      style={{ color: theme.palette.purple.darkest, fontWeight: 600, textTransform: 'none', marginRight: '1em', cursor: 'pointer' }}
                      onClick={(e) => { e.preventDefault(); handleUserProfileView(commentInfo.profileID) }}
                    >
                      {commentInfo && commentInfo.fullName ? commentInfo.fullName : ''}
                    </Link>

                    {/* Comment Timestamp */}
                    <Typography
                      variant='h6'
                      style={{ color: theme.palette.black, fontWeight: 400, textTransform: 'none' }}
                    >
                      {commentInfo && commentInfo.createdAt ? moment(commentInfo.createdAt * 1000).format('MM-DD-YYYY, h:mm A') : ''}
                    </Typography>
                  </Grid>
                  {commentInfo && commentInfo.userID === authorID && !fellowView &&
                    <Grid item container justifyContent='flex-end' sm={1} md={1}>
                      <MoreVertIcon
                        style={{ cursor: 'pointer' }}
                        aria-describedby={optionsPopoverID}
                        onClick={handleOptionsPopover}
                      />
                    </Grid>}
                </Grid>
                <Grid item container style={{ marginTop: '.5em' }}>
                  <Typography
                    variant='body1'
                    style={{ fontWeight: '400', wordBreak: 'break-word' }}
                  >
                    {commentInfo && commentInfo.comment ? commentInfo.comment : ''}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Badge>}
      {/* Comment Input Open */}
      {!commentSubmitted &&
        <>
          <Grid item container direction='column' style={{ marginBottom: '1em' }}>
            <Grid item container direction='row' alignItems='center' style={{ marginBottom: '.3em' }}>
              <Avatar src={!profileAvatarPath ? '' : profileAvatarPath} style={{ height: '26px', width: '26px', marginRight: '.2em' }} />
              <Typography variant='h6' style={{ color: theme.palette.black, fontWeight: 600, textTransform: 'none' }}>{!fullName ? '' : fullName}</Typography>
            </Grid>
            <Grid item style={{ width: '100%', marginTop: '.5em' }}>
              <OutlinedInput
                variant='outlined'
                fullWidth
                inputProps={{
                  maxLength: 5000
                }}
                margin='dense'
                placeholder='Enter comments...'
                classes={{ input: classes.inputPlaceholder }}
                style={{ backgroundColor: 'white' }}
                value={newCommentInfo.comment}
                onChange={(e) => setNewCommentInfo({ ...newCommentInfo, comment: e.target.value })}
              />
            </Grid>
          </Grid>
          <Grid item container direction='row' justifyContent='flex-start'>
            <Button
              variant='contained'
              onClick={editComment ? () => handleEditComment(artifactID) : () => handleAddComment(artifactID)}
              style={{
                margin: 0,
                marginRight: '1em',
                backgroundColor: styles.mainColor
              }}
            >
              {editComment ? `Edit ${section === 'notes' ? 'Note' : 'Comment'}` : 'Comment'}
            </Button>
            <Button
              variant='outlined'
              onClick={handleCancelComment}
              style={{
                margin: 0,
                borderColor: styles.mainColor,
                color: styles.mainColor
              }}
            >
              Cancel
            </Button>
          </Grid>
        </>}
      {/* Create Popover */}
      <Popover
        id={optionsPopoverID}
        open={openOptionsPopover}
        anchorEl={optionsAnchorEl}
        onClose={handleOptionsPopClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Grid container direction='column' style={{ padding: '.3em 1em' }}>
          <Grid item container justifyContent='flex-start'>
            <Button
              startIcon={
                <ModifiedEditIcon color={styles.mainColor} />
              }
              style={{
                textTransform: 'none',
                color: styles.mainColor,
                fontWeight: '600'
              }}
              onClick={() => handleOpenCommentEditor()}
            >
              Edit
            </Button>
          </Grid>
          <Grid item container justifyContent='flex-start'>
            <Button
              startIcon={
                <DeleteOutlineRoundedIcon />
              }
              style={{
                textTransform: 'none',
                color: styles.mainColor,
                fontWeight: '600'
              }}
              onClick={() => handleDeleteComment()}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </Grid>
  )
}

export default withRouter(ArtifactComment)
