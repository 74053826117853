import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Grid, Typography, useTheme, Accordion, AccordionSummary, AccordionDetails, Button, Paper,
  Avatar
} from '@material-ui/core'
import { AvatarGroup } from '@material-ui/lab'
import { ExpandMore } from '@material-ui/icons'

import { CreateOrEditStackModal, UploadBadgeModal } from '../../modals'
import { createGradient } from '../../../../utils'
import { mceActionTypes } from '../../../../utils/variables'
import { NotificationToast } from '../../tools'

import { isEqual } from 'lodash'
import moment from 'moment'

import { updateMCEStack, getMCEStacks, updateMCEOriginStatus } from '../../../../redux/actions'

const defaultUpdateStackInfo = {
  stackID: '',
  colorPrimary: '',
  colorSecondary: '',
  saveAsDesign: false,
  existingPalette: false
}

const defaultBadgeUploadInfo = {
  omceID: '',
  mceID: '',
  badgeImage: '',
  badgeImageType: ''
}

const AdminMCEBadges = (props) => {
  const {
    classes, localMCEStacks
  } = props

  const dispatch = useDispatch()
  const theme = useTheme()
  const currentTimeStamp = moment.now()

  const [expanded, setExpanded] = useState(false)
  const [createOrEditStackOpen, setCreateOrEditStackOpen] = useState(false)
  const [selectedStack, setSelectedStack] = useState('')

  const [stackInfo, setStackInfo] = useState({ ...defaultUpdateStackInfo })
  const [badgeUploadInfo, setBadgeUploadInfo] = useState({ ...defaultBadgeUploadInfo })

  const [badgeUploadOpen, setBadgeUploadOpen] = useState(false)

  const handleOpenBadgeUploadModal = (mce) => { setBadgeUploadOpen(true); setBadgeUploadInfo(mce) }
  const handleCloseBadgeUploadModal = () => { setBadgeUploadOpen(false); setBadgeUploadInfo({ ...defaultBadgeUploadInfo }) }

  const handleOpenCreateOrEditStack = (stack) => {
    if (stack) {
      const existingPalette = Boolean(stack?.colorPrimary && stack?.colorSecondary)
      setStackInfo({
        ...stackInfo,
        stackID: stack?.stackID,
        colorPrimary: stack?.colorPrimary,
        colorSecondary: stack?.colorSecondary,
        existingPalette: existingPalette
      })
      setSelectedStack(stack)
      setCreateOrEditStackOpen(true)
    }
  }

  const handleCloseCreateOrEditStack = () => {
    setSelectedStack('')
    setStackInfo({ ...defaultUpdateStackInfo })
    setCreateOrEditStackOpen(false)
  }

  const handleChange = (panel) => (event, isExpanded) => { setExpanded(isExpanded ? panel : false) }

  const fireSaveStackSuccess = () => {
    NotificationToast(false, 'Successfully updated stack colors!')
    dispatch(getMCEStacks(true))
    handleCloseCreateOrEditStack()
  }

  const fireSaveStackFailure = () => {
    NotificationToast(false, 'Failed to update stack colors!')
  }

  const handleSaveStack = (stackInfo) => {
    if (stackInfo && !isEqual(stackInfo, defaultUpdateStackInfo)) {
      const tempForm = {
        stackID: stackInfo?.stackID,
        colorPrimary: stackInfo?.colorPrimary,
        colorSecondary: stackInfo?.colorSecondary,
        saveAsDesign: stackInfo?.saveAsDesign
      }
      dispatch(updateMCEStack(tempForm, fireSaveStackSuccess, fireSaveStackFailure))
    }
  }

  const fireUpdateSuccess = () => {
    NotificationToast(false, 'Successfully Updated Micro-Credential Badge')
    dispatch(getMCEStacks(true))
  }

  const fireFailure = () => {
    NotificationToast(true, 'Something went wrong. Please try again later.')
  }

  const handleOriginStatus = (action, newID = null, badgeInfo) => {
    if (action === mceActionTypes.MODIFIED_BADGE && Object.keys(badgeInfo).length) {
      const info = {
        action,
        omceID: badgeInfo?.omceID,
        mceID: badgeInfo?.mceID,
        badgeImage: badgeInfo?.newImage,
        badgeImageType: badgeInfo?.newImageType
      }
      dispatch(updateMCEOriginStatus(info, fireUpdateSuccess, fireFailure))
    }
  }

  return (
    <>
      <Grid container direction='column'>
        <Grid item container style={{ marginBottom: '2em' }}>
          {/* Page Title */}
          <Typography variant='h4' style={{ fontSize: '20px' }}>Badges and Media</Typography>
        </Grid>
        {/* Currently filtering out stacks without any active credentials, if we want to show all stacks (empty or not) then we take out the filter */}
        {/* NOTE: Checking localMCEStacks via localMCEStacks?.stacks causes the "cannot read propert 'range' of null" error so changed this until we figure out what is causing this */}
        {localMCEStacks && localMCEStacks.stacks && localMCEStacks.stacks.length && localMCEStacks.stacks.filter((stack) => stack?.credentials.length).map((stack, index) => {
          const isExpanded = Boolean(expanded === index)
          const stackColors = stack?.colorPrimary && stack?.colorSecondary ? [stack.colorPrimary, stack.colorSecondary, createGradient('right', stack.colorPrimary, stack.colorSecondary)] : []
          const stackID = stack?.stackID
          return (
            <Accordion
              key={stackID}
              expanded={isExpanded}
              onChange={handleChange(index)}
              className={classes.badgeAccordion}
              style={{ borderRadius: !isExpanded ? '16px' : '16px 16px 0px 0px !important' }}
            >
              {/* NOTE: using template literals in this file for component props causes the "cannot read propert 'range' of null" error so changed them until we figure out what is causing this  */}
              <AccordionSummary
                expandIcon={<ExpandMore style={{ color: 'white', paddingRight: '0.5rem' }} />}
                aria-controls={'panels' + index + 'bh-content'}
                id={'panels' + index + 'bh-header'}
                className={classes.badgeAccordionSummary}
                style={{ padding: '0.7rem', borderRadius: isExpanded ? '16px 16px 0px 0px' : 'inherit' }}
              >
                <Grid item container direction='row' justifyContent='space-between' alignItems='center'>
                  <Grid item style={{ paddingLeft: '0.5rem' }}>
                    <Typography variant='h4'>{stack?.stackName}</Typography>
                    <Typography className={classes.badeCredentialCount}>{`${stack?.credentials?.length} Active Credential${stack?.credentials?.length !== 1 ? 's' : ''}`}</Typography>
                  </Grid>
                  <Grid item className={classes.badgeColorDots}>
                    {stackColors?.map((color, i) => {
                      const stackKey = stackID + '-badge-color-' + i
                      return (
                        <Grid
                          item
                          key={stackKey}
                          className={classes.badgeColorDot}
                          style={{ background: color }}
                        />
                      )
                    })}
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails style={{ padding: '8px 20px 20px' }}>
                <Grid item container direction='column'>
                  <Grid item container direction='column' alignItems='flex-end' justifyContent='flex-end' style={{ paddingTop: '1em' }}>
                    <Grid item>
                      <Button className={classes.badgeChip} onClick={() => handleOpenCreateOrEditStack(stack)}>
                        <Grid item container direction='row' alignItems='center' justifyContent='space-between'>
                          <Grid item>
                            <Typography style={{ fontWeight: 600, color: theme.palette.purple.darkest, marginRight: stackColors?.length ? '0.3em' : 0 }}>Edit Colors</Typography>
                          </Grid>
                          <AvatarGroup max={3}>
                            {stackColors?.map((color, i) => {
                              const stackKey = stackID + '-badge-dot-color-' + i
                              return (
                                <Avatar
                                  key={stackKey}
                                  style={{
                                    background: color,
                                    color: 'transparent',
                                    width: 25,
                                    height: 25
                                  }}
                                />
                              )
                            })}
                          </AvatarGroup>
                        </Grid>
                      </Button>
                    </Grid>
                  </Grid>
                  <Typography variant='h6' style={{ color: theme.palette.purple.darkest, marginTop: '0.5em' }}>Media</Typography>

                  <Grid item container direction='row' alignItems='center' spacing={2} style={{ paddingTop: '1rem' }}>
                    {stack?.credentials?.map((mce, i) => (
                      <Grid item container direction='column' md={3} xs={12} key={mce.mceID} className={classes.badgeMicroCredential}>
                        <Paper variant='outlined' className={classes.badgePaper} style={{ height: '18rem' }}>
                          <Grid item container direction='column' alignItems='center' justifyContent='center' spacing={1} style={{ height: '100%' }}>
                            <Grid item container direction='column' alignItems='center' justifyContent='center'>
                              <Grid item>
                                <img src={mce.badgeUrl + '?' + currentTimeStamp} style={{ height: '10rem', width: '10rem' }} alt='mceBadge' />
                              </Grid>
                            </Grid>
                            <Grid item container direction='column' alignItems='center' justifyContent='center'>
                              <Grid item>
                                <Typography variant='h4' style={{ paddingBottom: '0.5em', color: theme.palette.purple.darkest, textAlign: 'center' }}>{mce.title}</Typography>
                              </Grid>
                              <Grid item>
                                <Button variant='contained' size='small' onClick={() => handleOpenBadgeUploadModal(mce)} className={classes.badgeUploadButton} style={{ backgroundColor: stack?.colorPrimary, border: 'none' }}>
                                  Upload
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          )
        })}
      </Grid>
      <CreateOrEditStackModal
        classes={classes}
        localMCEStacks={localMCEStacks}
        createOrEditStackOpen={createOrEditStackOpen}
        handleCloseCreateOrEditStack={handleCloseCreateOrEditStack}
        handleSaveStack={handleSaveStack}
        selectedStack={selectedStack}
        stackInfo={stackInfo}
        setStackInfo={setStackInfo}
        defaultUpdateStackInfo={defaultUpdateStackInfo}
        editMode
      />
      <UploadBadgeModal
        classes={classes}
        adminPageView
        badgeUploadOpen={badgeUploadOpen}
        badgeUploadInfo={badgeUploadInfo}
        handleCloseBadgeUploadModal={handleCloseBadgeUploadModal}
        handleOriginStatus={handleOriginStatus}
      />
    </>
  )
}

export default AdminMCEBadges
