import React from 'react'
import { useSelector } from 'react-redux'
import {
  Grid, Typography
} from '@material-ui/core'
import QuestionEvalTemplate from './MCETools/QuestionEvalTemplate'
import MCGradingDisplay from './MCETools/MCGradingDisplay'
import { mceSections } from '../../../utils'

// choose the text for the header based on the section being rendered
const sectionTextOptions = {
  [mceSections.PART1]: {
    partText: 'Part 1',
    headerText: 'Overview Questions',
    descText: 'Answer the following overview questions (400-word limit).'
  },
  [mceSections.PART3]: {
    partText: 'Part 3',
    headerText: 'Reflection',
    descText: 'Provide insight relating to the following considerations:'
  }
}

const MCEQuestions = (props) => {
  const {
    currentStyle = {}, isSummaryView = true, classes = {}, isAssessorView = false, isResultsView = false, submissionReview = false,
    questionGradingValues = {}, handleGradeQuestion = () => { }, fullyGradedEvaluation = false, attemptDetailsForm = {}, setAttemptDetailsForm = () => { },
    currentSection = {}, modifyComment = () => { }, isPursuerView = false, section = ''
  } = props

  const { mceDetails = {} } = useSelector(state => state.microcredentials)

  // be sure we have received a valid section from the section options
  const validSection = section && Object.values(mceSections).includes(section)

  // based on the section provided, get the questions, text, and grading values for each area
  const sectionQuestions = validSection ? mceDetails[section] : []
  const sectionText = validSection ? sectionTextOptions[section] : { partText: 'Unavilable', headerText: 'Unavailable', descText: 'Unavailable' }
  const gradeValObj = questionGradingValues[section]

  // decide if we need to render the grading and commenting actions/views
  const renderGrades = Boolean(isAssessorView || isResultsView)

  return (
    <Grid item container direction='column' className={isSummaryView ? classes.summarySectionPadding : classes.defaultSectionPadding}>
      <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start'>
        <Grid item xs={10} container direction='column' style={{ marginBottom: '1em' }}>
          <Typography variant='h4' style={{ fontWeight: 900, fontSize: 22 }}>{sectionText?.partText}</Typography>
          <Typography variant='h5' style={{ fontSize: 18 }}>{sectionText?.headerText}</Typography>
          <Typography variant='body1' style={{ fontSize: 16 }}>{sectionText?.descText}</Typography>
        </Grid>
        {/* GRADE COMPLETION COUNTER/FULLY GRADED DISPLAY */}
        {renderGrades &&
          <Grid item xs={2} container direction='row' justifyContent='flex-end'>
            <MCGradingDisplay
              gradingValues={gradeValObj || {}}
              classes={classes}
              fullyGradedEvaluation={fullyGradedEvaluation}
              isResultsView={isResultsView}
            />
          </Grid>}
      </Grid>

      {/* QUESTIONS */}
      <Grid item container direction='column' spacing={8}>
        {sectionQuestions.map((question, i) => {
          const { questionID, questionOrder } = question
          return (
            <QuestionEvalTemplate
              key={`question-p1-${questionID}`}
              questionDetails={question}
              setAttemptDetailsForm={setAttemptDetailsForm}
              attemptDetailsForm={attemptDetailsForm}
              order={questionOrder}
              currentSection={currentSection}
              currentStyle={currentStyle}
              isSummaryView={isSummaryView}
              isAssessorView={isAssessorView}
              isResultsView={isResultsView}
              isPursuerView={isPursuerView}
              submissionReview={submissionReview}
              gradedCount={questionGradingValues[section]?.gradedCount || 0}
              handleGradeQuestion={handleGradeQuestion}
              renderGrades={renderGrades}
              classes={classes}
              modifyComment={modifyComment}
            />
          )
        })}
      </Grid>
    </Grid>
  )
}

export default MCEQuestions
