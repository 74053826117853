import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  Grid, Dialog, DialogActions, DialogContent, IconButton, Button, TextField, DialogTitle,
  Checkbox, FormControl, Typography, FormGroup, FormControlLabel, OutlinedInput, Popover, Divider, InputAdornment,
  Chip, Select, MenuItem, Tooltip, useMediaQuery
} from '@material-ui/core'

import { Skeleton } from '@material-ui/lab'

import { chunk } from 'lodash'

import {
  CloseRounded, Facebook, Instagram, LinkedIn, Twitter, CheckCircle as CheckIcon, Error as ErrorIcon, ErrorOutlineRounded,
  CancelRounded
} from '@material-ui/icons'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'

import { SocialLinks } from 'social-links'

import { updateAboutSection, getUserDetails, resetAboutEditData, getAboutEditData, getInFellowTypes } from '../../../redux/actions'

import { NotificationToast } from '../../ui/tools'
import { GET_ABOUT_EDIT_DATA } from '../../../redux/types'

import { ValidateInstagram, ValidateFacebook, ValidateLinkedin, ValidateTwitter } from '../../../lib'
import { userRoles, defaultErrors, inFellowOtherTypeID, validInputCheck } from '../../../utils'

const useStyles = makeStyles((theme) => ({
  paper: {
    overflowY: 'unset'
  },
  customizedButton: {
    backgroundColor: 'none',
    color: 'grey',
    stroke: 'grey',
    strokeWidth: '2px',
    height: '2rem',
    width: '2rem',
    '&:hover': {
      stroke: '#616161'
    }
  },
  checkboxLabel: {
    fontSize: '13px'
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#e3e3e3',
    '$.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)'
    }
  },
  checkedIcon: {
    backgroundColor: '#293669',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#293669'
    }
  },
  mediaCheck: {
    fill: theme.palette.success.main
  },
  mediaError: {
    fill: theme.palette.error.main
  },

  otherTypeEntrance: {
    opacity: 1,
    animation: '$fade-in 0.5s ease-in-out'
  },

  '@keyframes fade-in': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  }

}))

const AboutModal = (props) => {
  const { aboutModalOpen, setAboutModalOpen, userProfileID, removeModalType, navHeight = 0 } = props

  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const socialLinks = new SocialLinks()

  const smScreenDown = useMediaQuery(theme.breakpoints.down('sm'))

  const loadingState = useSelector(state => state.loadingState)

  const defaultAboutModalErr = defaultErrors.aboutModalErr
  const [errors, setErrors] = useState(defaultErrors)

  const {
    yearsTeaching, roleName, summary, facebookProfile, instagramProfile, linkedInProfile, twitterProfile,
    subjectsList = [], gradesList = [], inFellowTypeID, inFellowTypeName
  } = useSelector(state => state.userDetails)

  const getData = useCallback(async () => {
    await dispatch(getAboutEditData())
  }, [dispatch])

  useEffect(() => {
    if (aboutModalOpen) {
      getData()
    }
  }, [getData, aboutModalOpen])

  const { subjects = [] } = useSelector(state => state.profileData)
  const [formatGridSubjects, setFormatGridSubjects] = useState([])
  const [deletedSubjects, setDeletedSubjects] = useState([])
  const [deletedSubjectsDisplay, setDeletedSubjectsDisplay] = useState([])
  const [subjectIDs, setSubjectIDs] = useState([])
  const [subjectsToDisplay, setSubjectsToDisplay] = useState([])

  const grades = useSelector(state => state.profileData.grades)
  const [formatGridGrades, setFormatGridGrades] = useState([])
  const [gradeIDs, setGradeIDs] = useState([])
  const [gradesToDisplay, setGradesToDisplay] = useState([])

  const [yearsTeachingValue, setYearsTeachingValue] = useState('')
  const [summaryValue, setSummaryValue] = useState('')
  const [facebookProfileValue, setFacebookProfileValue] = useState('')
  const [instagramProfileValue, setInstagramProfileValue] = useState('')
  const [linkedInProfileValue, setLinkedInProfileValue] = useState('')
  const [twitterProfileValue, setTwitterProfileValue] = useState('')
  const [inFellowTypeOtherVal, setInFellowTypeOtherVal] = useState('')

  // Fellow type options gets all fellow types for the select menu and inFellowTypeObj will get the user's updated fellow type.
  const [fellowTypeOptions, setFellowTypeOptions] = useState([])
  const [inFellowTypeObj, setinFellowTypeObj] = useState({})

  // Checks if the inFellow type is 'Other'
  const inFellowTypeOtherCheck = Boolean(inFellowTypeObj && inFellowTypeObj.inFellowTypeID && inFellowTypeObj.inFellowTypeID === inFellowOtherTypeID)

  // Handles checking 'Other' input for special characters and only blank spaces
  const { specialChars, onlyBlankSpaces } = validInputCheck
  const specialCharactersCheck = specialChars.test(inFellowTypeOtherVal)
  const onlyBlankSpaceCheck = onlyBlankSpaces.test(inFellowTypeOtherVal)

  useMemo(() => { if (yearsTeaching) { setYearsTeachingValue(yearsTeaching) } else { setYearsTeachingValue(0) } }, [yearsTeaching])
  useMemo(() => { if (summary) { setSummaryValue(summary) } else { setSummaryValue('') } }, [summary])
  useMemo(() => { if (facebookProfile) { setFacebookProfileValue(facebookProfile) } else { setFacebookProfileValue('') } }, [facebookProfile])
  useMemo(() => { if (instagramProfile) { setInstagramProfileValue(instagramProfile) } else { setInstagramProfileValue('') } }, [instagramProfile])
  useMemo(() => { if (linkedInProfile) { setLinkedInProfileValue(linkedInProfile) } else { setLinkedInProfileValue('') } }, [linkedInProfile])
  useMemo(() => { if (twitterProfile) { setTwitterProfileValue(twitterProfile) } else { setTwitterProfileValue('') } }, [twitterProfile])

  // Fetches a list of all Innovation Fellow types. Used to populate the select menu and initially set the new fellow type to the current one if there is one.
  const fetchInFellowTypes = useCallback(async () => {
    const fellowTypes = await dispatch(getInFellowTypes())

    if (fellowTypes) {
      setFellowTypeOptions(fellowTypes)

      // If user has a fellow type, set local state to it.
      const currentFellowType = fellowTypes.find((fellow) => fellow.inFellowTypeID === inFellowTypeID)

      if (currentFellowType) {
        setinFellowTypeObj(currentFellowType)
      } else {
        setinFellowTypeObj({})
      }
    }
  }, [dispatch, inFellowTypeID])

  useEffect(() => {
    if (roleName && roleName === userRoles.INNOVATION_FELLOW) {
      fetchInFellowTypes()
    }
  }, [fetchInFellowTypes, roleName])

  // Checks if the user already has an 'Other' innovation fellow id && name. If they do, then the local state will be set to it initially.
  useMemo(() => {
    if (inFellowTypeID && inFellowTypeID === inFellowOtherTypeID) {
      if (inFellowTypeName && inFellowTypeName !== '') {
        setInFellowTypeOtherVal(inFellowTypeName)
      }
    } else {
      setInFellowTypeOtherVal('')
    }
  }, [inFellowTypeID, inFellowTypeName])

  // Format the various local state related to subjects
  const handleSubjectData = useCallback(() => {
    // Use the list of subject returned from the api and parse it into sub arrays for grid mapping
    if (subjects && Boolean(subjects.length)) {
      const tempArr = chunk(subjects, 4)
      if (tempArr.length) {
        setFormatGridSubjects(tempArr)
      }
    }

    // Use the user's currently selected subjects to format the local state related to display and editing
    if (subjectsList && subjectsList.length) {
      const subjectIDs = subjectsList.reduce((arr, subject) => {
        const { subjectID, deletedAt } = subject
        if (subject && subjectID) { if (!deletedAt) { arr.push(subjectID) } } return arr
      }, [])

      const deletedSubs = subjectsList.reduce((arr, subject) => {
        const { subjectID, deletedAt, subjectName } = subject
        if (subject && subjectID && deletedAt && deletedAt > 0) { arr.push({ subjectID, subjectName }) } return arr
      }, [])

      setSubjectIDs(subjectIDs)
      setSubjectsToDisplay(subjectIDs)
      setDeletedSubjectsDisplay(deletedSubs)
    } else {
      setSubjectIDs([])
      setSubjectsToDisplay([])
      setDeletedSubjects([])
      setDeletedSubjectsDisplay([])
    }
  }, [subjectsList, subjects])

  // Fire a callback to format the local state for subject related elements
  useEffect(() => {
    handleSubjectData()
  }, [handleSubjectData])

  // Use the user's currently selected grades to format the local state related to display and editing
  const handleGradesData = useCallback(() => {
    // Use the list of subject returned from the api and parse it into sub arrays for grid mapping
    if (grades && Boolean(grades.length)) {
      const tempArr = chunk(grades, 4)
      if (tempArr.length) {
        setFormatGridGrades(tempArr)
      }
    }
    if (gradesList && gradesList.length) {
      const gradeIDs = gradesList.reduce((arr, grade) => {
        if (grade && grade.gradeID) { arr.push(grade.gradeID) } return arr
      }, [])

      setGradeIDs(gradeIDs)
      setGradesToDisplay(gradeIDs)
    } else {
      setGradeIDs([])
      setGradesToDisplay([])
    }
  }, [gradesList, grades])

  // Fire a callback to format the local state related to grades
  useEffect(() => {
    handleGradesData()
  }, [handleGradesData])

  const handleAboutModalClose = () => {
    removeModalType()
    setAboutModalOpen(false)
    setInFellowTypeOtherVal('')
    setErrors(defaultAboutModalErr)
    dispatch(resetAboutEditData())
  }

  const handleYearsChange = (value) => {
    if (Number(value) > 100) {
      setYearsTeachingValue(100)
    } else if (Number(value) < 0 || !Number(value)) {
      setYearsTeachingValue('')
    } else {
      setYearsTeachingValue(value)
    }
  }

  const handleSummaryChange = (value) => {
    setSummaryValue(value)
  }

  const handleGradesChange = (value) => {
    const tempArray = [...gradesToDisplay]
    const isDisplayed = Boolean(tempArray.find((tempVal) => tempVal === value))

    if (isDisplayed) {
      const newDisplayed = tempArray.filter((tempVal) => tempVal !== value)
      setGradesToDisplay(newDisplayed)
    } else {
      tempArray.push(value)
      setGradesToDisplay(tempArray)
    }
  }

  const handleSubjectsChange = (value) => {
    const tempArray = [...subjectsToDisplay]
    const isDisplayed = Boolean(tempArray.find((tempVal) => tempVal === value))

    if (isDisplayed) {
      const newDisplayed = tempArray.filter((tempVal) => tempVal !== value)
      setSubjectsToDisplay(newDisplayed)
    } else {
      tempArray.push(value)
      setSubjectsToDisplay(tempArray)
    }
  }

  // Handles updating the inFellow type when a user selects a new value
  const handleInFellowTypeChange = (value) => {
    if (value && value.inFellowTypeID && value.inFellowTypeID !== '') {
      // If the user selects a fellow type that is not 'Other', clear the input that was entered
      if (value.inFellowTypeID !== inFellowOtherTypeID) {
        setInFellowTypeOtherVal('')
      }
      setinFellowTypeObj(value)
    } else {
      setinFellowTypeObj({})
    }
  }

  // Handles input for the 'Other' fellow type
  const handleInFellowOtherInput = (value) => {
    setInFellowTypeOtherVal(value)
  }

  const handleRemoveDeletedSubs = (value) => {
    const tempArr = [...deletedSubjects]

    if (!tempArr.includes(value.subjectID)) {
      tempArr.push(value.subjectID)
    }

    const tempDisplayArr = [...deletedSubjectsDisplay]
    const index = deletedSubjectsDisplay.findIndex(x => x.subjectID === value.subjectID)

    if (index !== -1) {
      tempDisplayArr.splice(index, 1)
    }

    // The displayed Array with the value removed
    setDeletedSubjectsDisplay(tempDisplayArr)

    // The array containing the ids of the subjects being removed
    setDeletedSubjects(tempArr)
  }

  // Popper related elements ****
  const [confirmAnchorEl, setConfirmAnchorEl] = useState(null)
  const [popperType, setPopperType] = useState('')

  const handleConfirmPopover = (e, type) => {
    setConfirmAnchorEl(e.currentTarget)
    setPopperType(type)
  }
  const handleConfirmPopClose = () => {
    setConfirmAnchorEl(null)
    setPopperType('')
  }
  const openConfirmPopover = Boolean(confirmAnchorEl)
  const confirmPopoverID = openConfirmPopover ? 'confirm-popover' : undefined

  // **** End popper related elements

  // This will check to see if the user has selected no subjects and/or grades and open the popper, otherwise will update as usual
  const handleEmptyCheck = (e) => {
    if ((subjectsToDisplay && subjectsToDisplay.length === 0) || (gradesToDisplay && gradesToDisplay.length === 0)) {
      if (gradeIDs && gradeIDs.length === 0 && subjectIDs && subjectIDs.length === 0) {
        handleAboutSave()
      } else {
        if (gradeIDs && gradeIDs.length !== 0 && subjectIDs && subjectIDs.length !== 0 && subjectsToDisplay && subjectsToDisplay.length === 0 && gradesToDisplay && gradesToDisplay.length === 0) {
          handleConfirmPopover(e, 'both')
        } else if (subjectIDs && subjectIDs.length !== 0 && subjectsToDisplay && subjectsToDisplay.length === 0) {
          handleConfirmPopover(e, 'subject')
        } else if (gradeIDs && gradeIDs.length !== 0 && gradesToDisplay && gradesToDisplay.length === 0) {
          handleConfirmPopover(e, 'grades')
        } else {
          // this case has occurred when a user has 0 of one array selected, but attempts to change another. in that case let's just save
          handleAboutSave()
        }
      }
    } else {
      handleAboutSave()
    }
  }

  const handleConfirm = () => {
    handleConfirmPopClose()
    handleAboutSave()
  }

  const handleAboutSave = () => {
    const tempForm = {
      yearsTeaching: (isNaN(yearsTeachingValue) || yearsTeachingValue === '') ? 0 : yearsTeachingValue,
      summary: summaryValue,
      facebookProfile: facebookProfileValue,
      instagramProfile: instagramProfileValue,
      linkedInProfile: linkedInProfileValue,
      twitterProfile: twitterProfileValue,
      gradesList: gradesToDisplay,
      subjectsList: subjectsToDisplay,
      oldFellowTypeID: roleName && roleName === userRoles.INNOVATION_FELLOW && inFellowTypeID ? inFellowTypeID : '',
      newFellowTypeID: roleName && roleName === userRoles.INNOVATION_FELLOW && inFellowTypeObj ? inFellowTypeObj.inFellowTypeID : '',
      inFellowTypeOther: roleName && roleName === userRoles.INNOVATION_FELLOW && inFellowTypeObj && inFellowTypeObj.inFellowTypeID && inFellowTypeObj.inFellowTypeID === inFellowOtherTypeID ? inFellowTypeOtherVal : ''
    }

    const facebookPath = tempForm.facebookProfile
    const facebookCheck = facebookPath === '' || facebookPath === 'facebook.com/'
    const facebookLengthCheck = facebookPath.length < 13 && facebookPath.length !== 0
    const facebookValid = socialLinks.isValid('facebook', facebookPath)

    const instagramPath = tempForm.instagramProfile
    const instagramCheck = instagramPath === '' || instagramPath === 'instagram.com/'
    const instagramLengthCheck = instagramPath.length < 14 && instagramPath.length !== 0
    const instagramValid = socialLinks.isValid('instagram', instagramPath) && isInstagramValid

    const linkedInPath = tempForm.linkedInProfile
    const linkedInCheck = linkedInPath === '' || linkedInPath === 'linkedin.com/in/'
    const linkedInLengthCheck = linkedInPath.length < 16 && linkedInPath.length !== 0
    const linkedInValid = socialLinks.isValid('linkedin', linkedInPath) && isLinkedinValid

    const twitterPath = tempForm.twitterProfile
    const twitterCheck = twitterPath === '' || twitterPath === 'twitter.com/'
    const twitterLengthCheck = twitterPath.length < 13 && twitterPath.length !== 0
    const twitterValid = socialLinks.isValid('twitter', twitterPath) && isTwitterValid

    // Checks if the 'Other' name input contains only blank spaces or any special characters. Sets the otherTypeError to true if it does.
    if (tempForm.inFellowTypeOther && Boolean(tempForm.inFellowTypeOther)) {
      if (specialCharactersCheck || onlyBlankSpaceCheck) {
        setErrors({ ...defaultAboutModalErr, otherTypeError: true })
        return false
      }
    } else {
      tempForm.inFellowTypeOther = ''
    }

    if (facebookLengthCheck === false && facebookValid === true) {
      tempForm.facebookProfile = socialLinks.sanitize('facebook', facebookPath)
    } else if (facebookLengthCheck === true) {
      setErrors({ ...defaultAboutModalErr, socialMediaError: true, socialType: 'facebook' })
      return false
    } else if (facebookValid === false && facebookCheck === false) {
      setErrors({ ...defaultAboutModalErr, socialMediaError: true, socialType: 'facebook' })
      return false
    } else if (facebookCheck === true) {
      tempForm.facebookProfile = ''
    }

    if (instagramLengthCheck === false && instagramValid === true) {
      tempForm.instagramProfile = socialLinks.sanitize('instagram', instagramPath)
    } else if (instagramLengthCheck === true) {
      setErrors({ ...defaultAboutModalErr, socialMediaError: true, socialType: 'instagram' })
      return false
    } else if (instagramValid === false && instagramCheck === false) {
      setErrors({ ...defaultAboutModalErr, socialMediaError: true, socialType: 'instagram' })
      return false
    } else if (instagramCheck === true) {
      tempForm.instagramProfile = ''
    }

    if (linkedInLengthCheck === false && linkedInValid === true) {
      tempForm.linkedInProfile = socialLinks.sanitize('linkedin', linkedInPath)
    } else if (linkedInLengthCheck === true) {
      setErrors({ ...defaultAboutModalErr, socialMediaError: true, socialType: 'linkedIn' })
      return false
    } else if (linkedInValid === false && linkedInCheck === false) {
      setErrors({ ...defaultAboutModalErr, socialMediaError: true, socialType: 'linkedIn' })
      return false
    } else if (linkedInCheck === true) {
      tempForm.linkedInProfile = ''
    }

    if (twitterLengthCheck === false && twitterValid === true) {
      tempForm.twitterProfile = socialLinks.sanitize('twitter', twitterPath)
    } else if (twitterLengthCheck === true) {
      setErrors({ ...defaultAboutModalErr, socialMediaError: true, socialType: 'twitter' })
      return false
    } else if (twitterValid === false && twitterCheck === false) {
      setErrors({ ...defaultAboutModalErr, socialMediaError: true, socialType: 'twitter' })
      return false
    } else if (twitterCheck === true) {
      tempForm.twitterProfile = ''
    }

    tempForm.gradesToAdd = gradesToDisplay.reduce((arr, gradeInfo) => {
      if (gradeInfo) {
        const gradeExists = Boolean(gradeIDs.find((gradeVal) => gradeVal === gradeInfo))

        if (!gradeExists) { arr.push(gradeInfo) }
      }

      return arr
    }, [])

    tempForm.gradesToDelete = gradeIDs.reduce((arr, gradeInfo) => {
      if (gradeInfo) {
        const gradeExists = Boolean(gradesToDisplay.find((gradeVal) => gradeVal === gradeInfo))

        if (!gradeExists) { arr.push(gradeInfo) }
      }
      return arr
    }, [])

    tempForm.subjectsToAdd = subjectsToDisplay.reduce((arr, subjectInfo) => {
      if (subjectInfo) {
        const subjectExists = Boolean(subjectIDs.find((subjectVal) => subjectVal === subjectInfo))

        if (!subjectExists) { arr.push(subjectInfo) }
      }

      return arr
    }, [])

    tempForm.subjectsToDelete = subjectIDs.reduce((arr, subjectInfo) => {
      if (subjectInfo) {
        const subjectExists = Boolean(subjectsToDisplay.find((subjectVal) => subjectVal === subjectInfo))

        if (!subjectExists) { arr.push(subjectInfo) }
      }
      return arr
    }, [])

    if (deletedSubjects && deletedSubjects.length) {
      deletedSubjects.forEach(sub => { if (!tempForm.subjectsToDelete.includes(sub)) { tempForm.subjectsToDelete.push(sub) } })
    }
    dispatch(updateAboutSection(tempForm, fireSuccess, fireFailure))
  }

  const fireSuccess = () => {
    dispatch(getUserDetails(userProfileID, () => { }, () => NotificationToast(true, 'Failed to get user details!')))
    handleAboutModalClose()
  }

  const fireFailure = () => {
    NotificationToast(true, 'Unable to update about info.')
  }

  const isFacebookValid = ValidateFacebook(facebookProfileValue)
  const isInstagramValid = ValidateInstagram(instagramProfileValue)
  const isLinkedinValid = ValidateLinkedin(linkedInProfileValue)
  const isTwitterValid = ValidateTwitter(twitterProfileValue)

  // Checks the user's role using a switch case to determine whether to render subjects/grades or inFellow types
  const renderByRoleCheck = (roleName) => {
    switch (roleName) {
      case userRoles.ADMIN_ROLE:
      case userRoles.TEACHER_FELLOW:
        return (
          <>
            <Grid item xs={12}>
              <Typography
                variant='h5'
              >
                Grades
              </Typography>

              <Grid
                item
                container
                direction='column'
                style={{ paddingRight: '1rem', paddingLeft: '1rem' }}
              >
                {
                  loadingState.type === GET_ABOUT_EDIT_DATA && loadingState.loading === true
                    ? (
                      <>
                        <Skeleton animation='wave' height={40} width='auto' />

                        <Skeleton animation='wave' height={40} width='auto' />

                        <Skeleton animation='wave' height={40} width='auto' />

                        <Skeleton animation='wave' height={40} width='auto' />
                      </>
                    ) : (
                      <>
                        {formatGridGrades.map((subArr, i) => {
                          return (
                            <Grid key={`grades-subArray-${i}`} item container alignContent='flex-start' direction='row'>
                              {subArr.map(grade => {
                                return (
                                  <Grid key={`checkbox ${grade.gradeID} ${i}`} item container alignContent={!theme.breakpoints.up('sm') ? 'center' : 'flex-start'} direction='column' xs={6} sm={3}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          size='small'
                                          disableRipple
                                          checked={gradesToDisplay.includes(grade.gradeID)}
                                          icon={<span className={classes.icon} />}
                                          checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                        />
                                      }
                                      label={grade.gradeName}
                                      value={grade.gradeID}
                                      onChange={(e) => handleGradesChange(grade.gradeID)}
                                      classes={{ label: classes.checkboxLabel }}
                                      style={{ marginBottom: '.2em' }}
                                    />
                                  </Grid>
                                )
                              })}
                            </Grid>
                          )
                        })}
                      </>
                    )
                }
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant='h5'
                style={{ marginTop: '1rem' }}
              >
                Subjects
              </Typography>

              <Grid
                item
                container
                direction='column'
                style={{ paddingRight: '1rem', paddingLeft: '1rem' }}
              >
                {
                  loadingState.type === GET_ABOUT_EDIT_DATA && loadingState.loading === true
                    ? (
                      <>
                        <Skeleton animation='wave' height={40} width='auto' />

                        <Skeleton animation='wave' height={40} width='auto' />

                        <Skeleton animation='wave' height={40} width='auto' />
                      </>
                    ) : (
                      <>
                        {/* Chunk the subjects array into sub arrays of 4, then map through the subarrays in grid items of 3 to keep an even look */}
                        {formatGridSubjects.map((subArr, i) => {
                          return (
                            <Grid key={`subjects-subArray-${i}`} item container alignContent='flex-start' direction='row'>
                              {subArr.map(subject => {
                                const { subjectName = '', subjectID = '' } = subject

                                return (
                                  <Grid key={`checkbox ${subjectID} ${i}`} item container alignContent={!theme.breakpoints.up('sm') ? 'center' : 'flex-start'} direction='column' xs={6} sm={3}>
                                    <Tooltip title={subjectName && subjectName.length >= 12 ? subjectName : ''}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            size='small'
                                            disableRipple
                                            checked={subjectsToDisplay.includes(subjectID)}
                                            icon={<span className={classes.icon} />}
                                            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                          />
                                        }
                                        label={subjectName}
                                        value={subjectID}
                                        onChange={(e) => handleSubjectsChange(subjectID)}
                                        classes={{ label: classes.checkboxLabel }}
                                        style={{ marginBottom: '.2em', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '90%', display: 'block', overflow: 'hidden' }}
                                      />
                                    </Tooltip>
                                  </Grid>
                                )
                              })}
                            </Grid>
                          )
                        })}
                      </>
                    )
                }
              </Grid>

              {deletedSubjectsDisplay && Boolean(deletedSubjectsDisplay.length) &&
                <>
                  <Grid item container direction='column' style={{ margin: '.5rem 0', paddingLeft: '1em' }}>
                    <Grid item container direction='column' style={{ marginBottom: '.5em' }}>
                      <Grid item container direction='row' alignItems='center'>
                        <ErrorOutlineRounded style={{ color: 'red', fontSize: '16px', paddingRight: '.3em' }} />
                        <Typography
                          variant='h6'
                          style={{ textTransform: 'none', color: 'red' }}
                        >
                          Heads Up!
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant='caption'
                          style={{ textTransform: 'none', color: 'red' }}
                        >
                          The following subjects are no longer available and will not be viewable to the public. Click the buttons below to remove them from your profile.
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction='row'
                    >
                      {chunk(deletedSubjectsDisplay, 4).map((subArr, i) => {
                        return (
                          <Grid key={`deleted-subjects-subArray-${i}`} item container alignContent={!theme.breakpoints.up('sm') ? 'center' : 'flex-start'} direction='row'>
                            <FormGroup row>
                              {subArr.map(subject => {
                                return (
                                  <Chip
                                    key={`deleted-chip-${subject.subjectID}`}
                                    label={subject.subjectName}
                                    size='small'
                                    style={{ color: 'red', backgroundColor: '#ff00002e', marginRight: '.5em' }}
                                    onDelete={() => handleRemoveDeletedSubs(subject)}
                                    deleteIcon={<CancelRounded style={{ color: '#e68f8f' }} />}
                                  />
                                )
                              })}
                            </FormGroup>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>
                </>}
            </Grid>
          </>
        )

      case userRoles.INNOVATION_FELLOW:
        return (
          <>
            <Grid item xs={12}>
              <Typography
                variant='h5'
              >
                Innovation Fellow Role
              </Typography>

              {
                loadingState.type === GET_ABOUT_EDIT_DATA && loadingState.loading === true
                  ? (
                    <>
                      <Skeleton animation='wave' height={40} width='auto' />
                    </>
                  ) : (
                    <>
                      <Grid item container direction='column' justifyContent='flex-start' alignContent='flex-start' style={{ marginTop: '0.5em', marginBottom: inFellowTypeObj.inFellowTypeID === inFellowOtherTypeID ? 0 : '0.5em' }} spacing={1}>
                        <Select
                          variant='outlined'
                          margin='dense'
                          displayEmpty
                          fullWidth
                          classes={{ root: classes.searchInput, selectMenu: classes.statusSelect }}
                          defaultValue=''
                          value={inFellowTypeObj}
                          onChange={(e) => handleInFellowTypeChange(e.target.value)}
                          style={{ padding: 0, margin: 'auto auto 1em auto' }}
                          renderValue={(selected) => !selected ? inFellowTypeObj.inFellowTypeName : !selected.inFellowTypeName ? '' : selected.inFellowTypeName}

                        >
                          <MenuItem value='' disabled>Choose Role...</MenuItem>
                          {fellowTypeOptions && fellowTypeOptions.length &&
                            fellowTypeOptions.map(fellow => {
                              return (
                                <MenuItem key={fellow.inFellowTypeID} value={fellow}>{fellow.inFellowTypeName}</MenuItem>
                              )
                            })}
                        </Select>
                        {/* If the user chose the 'Other' fellow type, render the input field for them to create the role name. If the name is empty, contains only blank spaces, or special characters it will not be sent through and will visually show them their error via an endAdornment icon and red outline */}
                        {inFellowTypeOtherCheck &&
                          <>
                            <Grid className={classes.otherTypeEntrance} item container direction='column' justifyContent='flex-start' alignContent='flex-start' style={{ marginTop: 0, marginBottom: '0.5em' }}>
                              <FormControl fullWidth margin='dense' style={{ marginTop: 0, marginBottom: 0 }}>
                                <OutlinedInput
                                  variant='outlined'
                                  size='small'
                                  type='text'
                                  placeholder='Enter other role...'
                                  value={inFellowTypeOtherVal}
                                  error={Boolean(inFellowTypeOtherVal && inFellowTypeOtherVal.length && (specialCharactersCheck || onlyBlankSpaceCheck))}
                                  onChange={(e) => handleInFellowOtherInput(e.target.value)}
                                  endAdornment={
                                    <InputAdornment position='end' variant='outlined'>
                                      {
                                        inFellowTypeOtherVal && Boolean(inFellowTypeOtherVal.length)

                                          ? specialCharactersCheck || onlyBlankSpaceCheck
                                            ? (<ErrorIcon className={classes.mediaError} />)
                                            : (<CheckIcon className={classes.mediaCheck} />)
                                          : (<></>)
                                      }
                                    </InputAdornment>
                                  }
                                />
                              </FormControl>
                            </Grid>

                            {/* Returns the specific error as small red text if the user tries to send their name with only blank spaces or any special characters */}
                            {errors.otherTypeError && inFellowTypeOtherVal && Boolean(inFellowTypeOtherVal.length) &&
                              <Grid item xs={12}>
                                <Grid item container direction='row' justifyContent='flex-end'>
                                  <Typography
                                    variant='caption'
                                    style={{ color: 'red' }}
                                  >
                                    {
                                      onlyBlankSpaceCheck ? '* Role name cannot contain only spaces.'
                                        : specialCharactersCheck ? '* Role name cannot contain special characters.' : ''
                                    }
                                  </Typography>
                                </Grid>
                              </Grid>}
                          </>}
                      </Grid>
                    </>
                  )
              }
            </Grid>
          </>
        )

      default:
        return (
          <></>
        )
    }
  }

  useEffect(() => {
    return () => {
      setFormatGridSubjects([])
      setDeletedSubjects([])
      setDeletedSubjectsDisplay([])
      setSubjectIDs([])
      setSubjectsToDisplay([])
      setFormatGridGrades([])
      setGradeIDs([])
      setGradesToDisplay([])
      setYearsTeachingValue('')
      setSummaryValue('')
      setFacebookProfileValue('')
      setInstagramProfileValue('')
      setLinkedInProfileValue('')
      setTwitterProfileValue('')
      setFellowTypeOptions([])
      setinFellowTypeObj({})
    }
  }, [])

  return (
    <>
      <Dialog
        classes={{ paper: classes.paper }}
        style={{ marginTop: navHeight }}
        fullWidth
        maxWidth={smScreenDown ? 'sm' : 'md'}
        open={aboutModalOpen}
        onClose={() => handleAboutModalClose()}
      >

        <DialogTitle disableTypography>
          <Grid container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid item>
              <Typography
                variant='h4'
                style={{ textTransform: 'none', color: theme.palette.purple.darkest, fontSize: '1.3rem' }}
              >
                Edit About
              </Typography>
            </Grid>

            <Grid item>
              <IconButton style={{ padding: '0px' }} onClick={(e) => { e.preventDefault(); handleAboutModalClose() }}>
                <CloseRounded className={classes.customizedButton} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />

        <DialogContent dividers>
          <Grid item xs={12}>
            <Typography
              variant='h5'
              style={{ marginBottom: '.5rem' }}
            >
              Years Teaching
            </Typography>
            <FormControl margin='dense' style={{ marginTop: '0' }}>
              <TextField
                variant='outlined'
                size='small'
                value={yearsTeachingValue}
                placeholder='Years'
                onChange={(e) => handleYearsChange(e.target.value)}
                style={{ margin: 'auto', marginBottom: '1rem', width: '5em' }}
                type='number'
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                InputLabelProps={{ className: classes.inputLabel }}
              />
            </FormControl>
          </Grid>

          {/* renders either grades/subjects or inFellow types depending on the user's role */}
          {renderByRoleCheck(roleName)}

          <Grid item xs={12}>
            <Typography
              variant='h5'
              style={{ marginBottom: '.5rem', marginTop: '.5rem' }}
            >
              Summary
            </Typography>

            <TextField
              variant='outlined'
              placeholder='Add a description of who you are'
              size='small'
              fullWidth
              multiline
              rows={3}
              value={summaryValue}
              onChange={(e) => handleSummaryChange(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant='h5'
              style={{ marginTop: '1rem' }}
            >
              Social Media Accounts
            </Typography>

            <FormControl fullWidth margin='dense'>
              <OutlinedInput
                id='facebook-profile'
                variant='outlined'
                size='small'
                type='text'
                value={facebookProfileValue}
                placeholder='https://www.facebook.com/edfarmbhm'
                error={!isFacebookValid && facebookProfileValue !== ''}
                onChange={(e) => setFacebookProfileValue(e.target.value)}
                inputProps={{ style: { border: 'none' } }}
                startAdornment={
                  <Facebook style={{ height: '1.2em', width: '1.2em', marginRight: '1rem', color: '#1977F3' }} />
                }
                endAdornment={
                  <InputAdornment position='end' variant='outlined'>
                    {
                      isFacebookValid && facebookProfileValue !== ''
                        ? (<CheckIcon className={classes.mediaCheck} />)
                        : !isFacebookValid && facebookProfileValue !== ''
                          ? (<ErrorIcon className={classes.mediaError} />)
                          : (<></>)

                    }
                  </InputAdornment>
                }
              />
            </FormControl>

            <FormControl fullWidth margin='dense'>
              <OutlinedInput
                id='instagram-profile'
                variant='outlined'
                size='small'
                type='text'
                placeholder='https://www.instagram.com/_edfarm'
                error={!isInstagramValid && instagramProfileValue !== ''}
                value={instagramProfileValue}
                onChange={(e) => setInstagramProfileValue(e.target.value)}
                inputProps={{ style: { border: 'none' } }}
                startAdornment={
                  <Instagram style={{ height: '1.2em', width: '1.2em', marginRight: '1rem', color: '#CF4BA6' }} />
                }
                endAdornment={
                  <InputAdornment position='end' variant='outlined'>
                    {
                      isInstagramValid && instagramProfileValue !== ''
                        ? (<CheckIcon className={classes.mediaCheck} />)
                        : !isInstagramValid && instagramProfileValue !== ''
                          ? (<ErrorIcon className={classes.mediaError} />)
                          : (<></>)

                    }
                  </InputAdornment>
                }
              />
            </FormControl>

            <FormControl fullWidth margin='dense'>
              <OutlinedInput
                id='linkedIn-profile'
                variant='outlined'
                size='small'
                type='text'
                placeholder='https://www.linkedin.com/company/edfarm/'
                value={linkedInProfileValue}
                error={!isLinkedinValid && linkedInProfileValue !== ''}
                onChange={(e) => setLinkedInProfileValue(e.target.value)}
                inputProps={{ style: { border: 'none' } }}
                startAdornment={
                  <LinkedIn style={{ height: '1.2em', width: '1.2em', marginRight: '1rem', color: '#0E76A8' }} />
                }
                endAdornment={
                  <InputAdornment position='end' variant='outlined'>
                    {
                      isLinkedinValid && linkedInProfileValue !== ''
                        ? (<CheckIcon className={classes.mediaCheck} />)
                        : !isLinkedinValid && linkedInProfileValue !== ''
                          ? (<ErrorIcon className={classes.mediaError} />)
                          : (<></>)

                    }
                  </InputAdornment>
                }
              />
            </FormControl>

            <FormControl fullWidth margin='dense'>
              <OutlinedInput
                id='twitter-profile'
                variant='outlined'
                size='small'
                type='text'
                placeholder='https://www.twitter.com/edfarm/'
                value={twitterProfileValue}
                error={!isTwitterValid && twitterProfileValue !== ''}
                onChange={(e) => setTwitterProfileValue(e.target.value)}
                inputProps={{ style: { border: 'none' } }}
                startAdornment={
                  <Twitter style={{ height: '1.2em', width: '1.2em', marginRight: '1rem', color: '#1DA1F2' }} />
                }
                endAdornment={
                  <InputAdornment position='end' variant='outlined'>
                    {
                      isTwitterValid && twitterProfileValue !== ''
                        ? (<CheckIcon className={classes.mediaCheck} />)
                        : !isTwitterValid && twitterProfileValue !== ''
                          ? (<ErrorIcon className={classes.mediaError} />)
                          : (<></>)

                    }
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>

          {errors.socialMediaError &&
            <Grid item xs={12}>
              <Grid item container direction='row' justifyContent='flex-end'>
                <Typography
                  variant='caption'
                  style={{ color: 'red' }}
                >
                  {
                    errors.socialType === 'facebook' ? '* Facebook path is invalid. Leave the input blank or provide a valid path.'
                      : errors.socialType === 'instagram' ? '* Instagram path is invalid. Leave the input blank or provide a valid path.'
                        : '* LinkedIn path is invalid. Leave the input blank or provide a valid path.'
                  }
                </Typography>
              </Grid>
            </Grid>}

          <Popover
            id={confirmPopoverID}
            open={openConfirmPopover}
            anchorEl={confirmAnchorEl}
            onClose={handleConfirmPopClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
          >
            <Grid container direction='column' style={{ padding: '1em' }}>
              <Typography variant='h4' style={{ color: theme.palette.purple.darkest }} gutterBottom>Hold up!</Typography>
              <Typography variant='h5' style={{ textTransform: 'none' }} gutterBottom>{`You've not chosen any ${popperType !== 'both' ? popperType === 'subject' ? ' subjects.' : 'grades.' : 'grades or subjects.'}`}</Typography>
              <Typography variant='body1' gutterBottom> Without this information Ed Farm will not be able to provide tailored suggestions in your feed.</Typography>
              <Typography variant='body1' gutterBottom>If you would like to continue anyway, please click confirm. Otherwise, hit cancel to continue editing.</Typography>
              <Grid item container direction='row' justifyContent='flex-end' alignItems='center' style={{ marginTop: '.5em' }}>
                <Button onClick={handleConfirmPopClose} style={{ marginRight: '.3em', textTransform: 'none', fontWeight: '600' }}>Cancel</Button>
                <Button variant='contained' color='primary' onClick={handleConfirm}>Confirm</Button>
              </Grid>
            </Grid>
          </Popover>

        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            aria-describedby={confirmPopoverID}
            disabled={openConfirmPopover}
            variant='contained'
            onClick={(e) => {
              e.preventDefault()
              handleEmptyCheck(e)
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AboutModal
