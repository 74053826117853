import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Grid, Typography, useTheme, Paper, OutlinedInput
} from '@material-ui/core'

import MCSectionGrading from './MCSectionGrading'
import AssessorComment from './AssessorComment'
import { sanitizedHTML, renderSkeletons, mceSections } from '../../../../utils'
import { MCE_DETAILS } from '../../../../redux/types'

const minInputHeight = 220

const QuestionEvalTemplate = (props) => {
  const {
    order = 1, currentStyle = {}, isSummaryView = true, isAssessorView = false, submissionReview = false, isPursuerView = false,
    handleGradeQuestion = () => { }, questionDetails = {}, renderGrades = false, classes = {},
    setAttemptDetailsForm = () => { }, attemptDetailsForm = {}, modifyComment = () => { }, isResultsView = false
  } = props
  const theme = useTheme()
  const loadingState = useSelector(state => state.loadingState)
  const [wordLimitError, setWordLimitError] = useState(false)
  const [inputHeight, setInputHeight] = useState(minInputHeight)

  const { questionID = '', partNum, answerLimit = 400 } = questionDetails

  const questionSection = partNum && partNum === 1 ? mceSections.PART1 : mceSections.PART3

  const currentQuestion = attemptDetailsForm?.[questionSection]?.find(x => x.questionID === questionID)

  const itemGraded = currentQuestion && currentQuestion.assessorGrade !== null

  // Removed extra set of double quotes using a util function for now
  const questionValue = sanitizedHTML(questionDetails?.question)
  const evalValue = questionDetails?.evalCriteria ? sanitizedHTML(questionDetails?.evalCriteria) : 'None'

  const handleGrade = (newGrade) => {
    const { questionID, assessorGrade } = currentQuestion
    const checkedSame = assessorGrade === newGrade
    const gradeValue = assessorGrade !== null && checkedSame ? null : newGrade
    handleGradeQuestion(questionSection, questionID, gradeValue)
  }

  const handleAnswer = (value, id) => {
    const maxCount = answerLimit || 400
    const wordCount = value.trim().split(' ').length

    if (wordCount <= maxCount) {
      if (wordLimitError) { setWordLimitError(false) }
      // check that the value is not strictly white space before proceeding, or allow for the user to have deleted their entire response
      if (!/^\s*$/.test(value) || !value) {
        if (id === questionID) {
          if (currentQuestion) {
            const currentAnswers = [...attemptDetailsForm?.[questionSection]]
            const newQuestionIndex = currentAnswers.findIndex(x => x.questionID === id)

            if (newQuestionIndex !== -1) {
              currentAnswers[newQuestionIndex] = { ...currentQuestion, questionAnswer: value }
            }

            setAttemptDetailsForm({ ...attemptDetailsForm, [questionSection]: [...currentAnswers] })
          }
        }
      }
    } else {
      setWordLimitError(true)
    }
  }
  const disableInput = Boolean(isAssessorView || submissionReview || isResultsView)
  const inputValue = disableInput ? currentQuestion?.questionAnswer ? `"${currentQuestion?.questionAnswer}"` : 'No answer provided...' : `${currentQuestion?.questionAnswer || ''}`
  const inputLength = currentQuestion?.questionAnswer && !/^\s*$/.test(currentQuestion?.questionAnswer) ? currentQuestion?.questionAnswer.trim().split(/\s+/).length : 0

  // extend the height of the input as the user types
  const extendHeight = (e) => {
    if (e.target.clientHeight < e.target.scrollHeight) {
      setInputHeight(e.target.scrollHeight)
    }
  }

  return (
    <Grid item container direction='column'>
      {/* QUESTION TEXT */}
      <Grid item container direction='row' alignItems='flex-start'>
        <Grid item xs={8} style={{ paddingBottom: '0.5em' }}>
          {loadingState.type === MCE_DETAILS && loadingState.loading === true
            ? (
              <>
                {renderSkeletons(4, 'wave', 40)}
              </>
            )
            : <div dangerouslySetInnerHTML={{ __html: `<span>${order}.</span> ${questionValue}` }} className={classes.questionHTML} />}
        </Grid>

        {renderGrades &&
          <MCSectionGrading
            itemGraded={itemGraded}
            question={currentQuestion}
            handleGrade={handleGrade}
            submissionReview={submissionReview}
            isAssessorView={isAssessorView}
            isResultsView={isResultsView}
          />}
      </Grid>

      {!isSummaryView &&
        <>
          <OutlinedInput
            multiline
            minRows={5}
            disabled={disableInput}
            onChange={(e) => { extendHeight(e); handleAnswer(e.target.value, questionID) }}
            value={inputValue}
            classes={{ disabled: currentQuestion?.questionAnswer ? classes.readOnlyQuestionView : classes.noInputQuestion }}
            style={{ minHeight: inputHeight }}
            inputProps={{
              style: {
                minHeight: inputHeight,
                overflow: 'visible'
              }
            }}
          />
          {isPursuerView && !submissionReview &&
            <Grid item>
              {wordLimitError && <Typography variant='caption' style={{ color: 'red', paddingLeft: '.3em', fontWeight: 600 }}>Maximum Words Exceeded</Typography>}
              <Typography variant='caption' style={{ color: '#adadad', paddingLeft: '.3em' }}>{`${currentQuestion?.questionAnswer ? inputLength : 0}/${answerLimit || '400'} Words`}</Typography>
            </Grid>}
        </>}

      {/*  EVAL CRITERIA BOX */}
      <Grid item container direction='column' style={{ marginTop: '1em' }}>
        <Paper elevation={0} style={{ backgroundColor: isSummaryView ? theme.palette.grey.lightest : currentStyle?.secondaryColor, padding: '.5em' }}>
          <Grid container direction='column'>
            <Grid item>
              <Typography style={{ fontWeight: 600 }}>Evaluation Criteria</Typography>
            </Grid>
            {loadingState.type === MCE_DETAILS && loadingState.loading === true
              ? (
                <>
                  {renderSkeletons(2, 'wave', 40)}
                </>
              )
              : <div dangerouslySetInnerHTML={{ __html: `${evalValue}` }} className={classes.evalHTML} />}
          </Grid>
        </Paper>
      </Grid>

      {/* COMMENT BOX */}
      {Boolean(!isPursuerView || (isPursuerView && currentQuestion?.lastAttemptComment)) &&
        <AssessorComment
          currentStyle={currentStyle}
          isAssessorView={isAssessorView}
          defaultOpen={Boolean((!isPursuerView && currentQuestion?.attemptComment) || (isPursuerView && currentQuestion?.lastAttemptComment))}
          modifyQuestionComment={modifyComment}
          currentQuestion={currentQuestion}
          questionSection={questionSection}
          handleGradeQuestion={handleGradeQuestion}
          isPursuerView={isPursuerView}
        />}

    </Grid>
  )
}

export default QuestionEvalTemplate
