import React, { useMemo } from 'react'

import { useSelector } from 'react-redux'

import {
  Divider, Grid,
  Dialog, DialogTitle, DialogContent,
  IconButton, Typography,
  makeStyles, darken, useTheme
} from '@material-ui/core'
import {
  CloseRounded as CloseIcon
} from '@material-ui/icons'

import { ArtifactSocialUserDisplay } from '../tools'

const useStyles = makeStyles((theme) => ({
  fixSpacing: {
    width: '100%',
    margin: 'auto'
  },
  paper: {
    overflowY: 'unset'
  },
  accountAvatar: {
    width: '100%',
    height: 0,
    paddingTop: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    borderRadius: '50%'
  },
  customizedButton: {
    backgroundColor: 'none',
    color: 'grey',
    stroke: 'grey',
    strokeWidth: '2px',
    height: '2rem',
    width: '2rem',
    '&:hover': {
      stroke: '#616161'
    }
  },
  avatarBtn: {
    backgroundColor: '#efefef',
    padding: theme.spacing(0.5),
    width: '100%',
    '&:hover': {
      backgroundColor: darken('#efefef', 0.10)
    }
  },
  inputRoot: {
    padding: `${theme.spacing(1, 0.5)} !important`
  },
  inputInput: {
    padding: `${theme.spacing(0, 4, 0, 4)} !important`,
    paddingLeft: `calc(1em + ${theme.spacing(5)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    color: theme.palette.purple.darkest,
    fontFamily: 'Source Sans Pro',
    fontWeight: '600'
  },
  inactiveContainer: {
    border: '2px dashed #adadad',
    padding: theme.spacing(1, 1, 2, 1),
    borderRadius: theme.shape.borderRadius
  },
  activeContainer: {
    border: '2px dashed #293669',
    padding: theme.spacing(1, 1, 2, 1),
    borderRadius: theme.shape.borderRadius
  }
}))

const SocialModal = (props) => {
  const { artifactDetails, isOpen, handleUserProfileView, actionType, onModalClose } = props

  const classes = useStyles()
  const theme = useTheme()

  const { artifactLikeList = [], artifactSaveList = [] } = useSelector(state => state.artifacts)

  const artifactTitle = useMemo(() => {
    if (artifactDetails) {
      if (artifactDetails.title) {
        return artifactDetails.title
      }
      if (artifactDetails.learnerWorkTitle) {
        return artifactDetails.learnerWorkTitle
      }
      if (artifactDetails.projectTitle) {
        return artifactDetails.projectTitle
      }
    }
    return ''
  }, [artifactDetails])

  const artifactLikes = useMemo(() => {
    if (artifactLikeList && artifactLikeList.length) {
      return [...artifactLikeList]
    } else {
      return []
    }
  }, [artifactLikeList])

  const artifactSaves = useMemo(() => {
    if (artifactSaveList && artifactSaveList.length) {
      return [...artifactSaveList]
    } else {
      return []
    }
  }, [artifactSaveList])

  const handleModalClose = () => {
    artifactLikes.length = 0
    artifactSaves.length = 0
    onModalClose()
  }

  return (
    <>
      <Dialog
        fullWidth
        maxWidth='xs'
        style={{ zIndex: 11 }}
        open={isOpen}
        disableEnforceFocus
        classes={{ paper: classes.paper }}
      >

        <DialogTitle disableTypography>
          <Grid container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid item container justifyContent='flex-start' xs={10}>
              <Typography
                variant='h4'
                style={{ textTransform: 'none', color: theme.palette.purple.darkest, fontSize: '1.3rem' }}
              >
                {actionType} for {`'${artifactTitle}'`}
              </Typography>
            </Grid>

            <Grid item container justifyContent='flex-end' xs={2}>
              <IconButton style={{ padding: '0px' }} onClick={handleModalClose}>
                <CloseIcon className={classes.customizedButton} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />

        <DialogContent style={{ marginBottom: '1em' }}>
          {actionType === 'Likes' && artifactLikes && artifactLikes.length !== 0 && isOpen &&
            <>
              <Grid item container direction='column' spacing={2} style={{ marginTop: '1em' }}>
                {artifactLikes.map(user => {
                  return (
                    <ArtifactSocialUserDisplay
                      userInfo={user}
                      key={user.userID}
                      handleUserProfileView={handleUserProfileView}
                      handleModalClose={handleModalClose}
                    />
                  )
                })}
              </Grid>
            </>}
          {actionType === 'Saves' && artifactSaves && artifactSaves.length !== 0 &&
            <>
              <Grid item container direction='column' spacing={2} style={{ marginTop: '1em' }}>
                {artifactSaves.map(user => {
                  return (
                    <ArtifactSocialUserDisplay
                      userInfo={user}
                      key={user.userID}
                      handleUserProfileView={handleUserProfileView}
                      handleModalClose={handleModalClose}
                    />
                  )
                })}
              </Grid>
            </>}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default SocialModal
