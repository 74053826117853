import React, { useMemo, useState, useRef } from 'react'
import {
  Grid, Button, Typography, useTheme, TextField, IconButton,
  Dialog, DialogTitle, DialogContent, DialogActions, Popover
} from '@material-ui/core'
import { AddRounded, EditRounded, DeleteForeverRounded, CloseRounded, ArrowDownwardRounded, ArrowUpwardRounded } from '@material-ui/icons'
import { sanitizedHTML, createTempID, formatStringForEditor, formatStringForDisplay } from '../../../utils/functions'
import { Editor } from '@tinymce/tinymce-react'
import { mceActionTypes } from '../../../utils/variables'

const defaultLocalForm = { questionID: null, tempID: null, question: '', evalCriteria: '' }
const sectionArrKeys = {
  P1: 'displayP1',
  P3: 'displayP3'
}

const BuilderQuestionSection = (props) => {
  const {
    versionForm, setVersionForm = () => {}, classes = {}, editorInitDefaults = {}, scriptSrc = {},
    errorObj = {}, setErrorObj = () => {}, defaultErrors = {}, section
  } = props

  const theme = useTheme()

  // grab the versionForm key to use the correct set of questions based on the section we are veiwing
  const sectionArrKey = sectionArrKeys[section] || sectionArrKeys.P1

  // set the local questions array to the correct version form array
  const questions = useMemo(() => {
    if (versionForm && versionForm[sectionArrKey]) {
      return versionForm[sectionArrKey]
    } else {
      return []
    }
  }, [versionForm, sectionArrKey])

  // --- Add/Edit question modal controls
  const [questionModalOpen, setQuestionModalOpen] = useState(false)
  const [localQuestionForm, setLocalQuestionForm] = useState({ ...defaultLocalForm })
  const editingBool = Boolean(localQuestionForm?.questionID || localQuestionForm?.tempID)
  // editor refs
  const questionRef = useRef(null)
  const evalCriteriaRef = useRef(null)

  // content refs (to detect editing of current questions)
  const questionContentRef = useRef(null)
  const criteriaContentRef = useRef(null)
  const disableSaveQuestion = Boolean(!localQuestionForm.question || !localQuestionForm.evalCriteria || (questionContentRef.current && questionContentRef.current === localQuestionForm.question && criteriaContentRef.current && criteriaContentRef.current === localQuestionForm.evalCriteria))

  // -- close/reset the question modal
  const handleQuestionClose = () => {
    setQuestionModalOpen(false)
    setLocalQuestionForm({ ...defaultLocalForm })
    setErrorObj({ ...defaultErrors })
    questionRef.current = null
    evalCriteriaRef.current = null
    questionContentRef.current = null
    criteriaContentRef.current = null
  }

  // -- open the question modal
  const handleOpenQuestion = (questionObj = null) => {
    if (questionObj) {
      const { questionID = null, tempID = null, question = '', evalCriteria = '' } = questionObj

      // format the question obj to display correctly in the editors
      const newForm = {
        questionID,
        tempID,
        question: formatStringForEditor(question),
        evalCriteria: formatStringForEditor(evalCriteria)
      }

      // set the local content refs to the content as it was at the time of opening the modal (used to detect changes)
      questionContentRef.current = newForm.question
      criteriaContentRef.current = newForm.evalCriteria

      // set it to the local form
      setLocalQuestionForm({ ...newForm })
    }

    setQuestionModalOpen(true)
  }

  // --- handle adding or editing a question in either P1 or P3
  const handleAddEditQuestion = (questionInfo, removeBool = false) => {
    const { question = '', evalCriteria = '', questionID = null, tempID = null } = questionInfo
    // based on the provided section, decide which array we are modifying
    const tempDisplayQ = [...versionForm[sectionArrKey]]
    const tempRemovedQuestions = [...versionForm.questionsToRemove]
    const tempHistoryActions = [...versionForm.historyActions]
    const draftStatus = versionForm.isDraft

    // if we are dealing with a brand new question it will need a temporary ID
    const newTempID = createTempID()

    // check for only white space content
    const questionWhiteSpaceCheck = Boolean(!questionRef.current || questionRef.current?.getContent({ format: 'text' }).trim().length)
    const evalCriteriaWhiteSpaceCheck = Boolean(!evalCriteriaRef.current || evalCriteriaRef.current?.getContent({ format: 'text' }).trim().length)

    // if we have reached this handler with missing content, or could not find the array to manipulate, then resturn false with a missing content error
    if (!removeBool && (!question.length || !evalCriteria.length || tempDisplayQ == null)) {
      console.warn('no content or no display arr')
      setErrorObj({ ...errorObj, missingContent: true })
      return false
    }

    if (!removeBool && (!questionWhiteSpaceCheck || !evalCriteriaWhiteSpaceCheck)) {
      console.warn('contents contained only white space')
      setErrorObj({ ...errorObj, whiteSpaceOnly: true })
      return false
    }

    // set the provided strings from the rich text editor to be formatted as readable html
    questionInfo.question = formatStringForDisplay(question)
    questionInfo.evalCriteria = formatStringForDisplay(evalCriteria)

    // if we are dealing with a brand new question and not requesting to delete it, we need to add it to the display questions
    if (!questionID && !tempID && !removeBool) {
      const newQuestionInfo = { ...questionInfo, questionID: null, tempID: newTempID }
      tempDisplayQ.push({ ...newQuestionInfo })
    } else {
      // otherwise we are dealing with something already in the display array and need to find and modify it (could be with by the questionID, or the tempID)
      const findByKey = questionID ? 'questionID' : 'tempID'
      const findVal = questionID || tempID
      const currentQuestionIndex = tempDisplayQ.findIndex(x => x[findByKey] === findVal)

      // if not a draft but we have modified a currently stored question, add the modification to the actions array (if it not already there)
      if (questionID && !draftStatus) {
        if (tempHistoryActions.findIndex(x => x.questionID === questionID) === -1) {
          tempHistoryActions.push({ action: mceActionTypes.MODIFIED_QUESTION, questionID, section: section === 'P1' ? 1 : 3 })
        }
      }

      // if something went wrong trying to find the question to modify, return false with a general error but log the specific problem
      if (currentQuestionIndex === -1) {
        console.warn('could not find question to modify')
        setErrorObj({ ...errorObj, generalError: true })
        return false
      }

      // if we are deleting a question remove it from the display, otherwise modify it
      if (removeBool) {
        // remove the question from the displayed array
        tempDisplayQ.splice(currentQuestionIndex, 1)

        // add the questionID to the questionsToRemove arr (NOTE: this only moves to the api if the original mce is still a draft)
        if (questionID) {
          const numericSection = section === 'P1' ? 1 : 3
          tempRemovedQuestions.push({ questionID, section: numericSection })
        }
      } else {
        // reassign the index to the new content
        tempDisplayQ[currentQuestionIndex] = { ...questionInfo }
      }
    }

    // the versionform to the new display array for the appropriate section
    setVersionForm({ ...versionForm, [sectionArrKey]: tempDisplayQ, questionsToRemove: tempRemovedQuestions, historyActions: tempHistoryActions })

    if (questionModalOpen) {
      handleQuestionClose()
    }
  }

  // modify the limit val of the chosen question
  const handleLimitChange = (newVal, questionObj) => {
    const newObj = { ...questionObj, answerLimit: newVal }
    handleAddEditQuestion(newObj)
  }

  // move the index of the question
  const handleOrderChange = (currentIndex, newIndex) => {
    const tempDisplayArr = [...versionForm[sectionArrKey]]
    const tempHistoryArr = [...versionForm.historyActions]
    const draftStatus = versionForm.isDraft

    const removedArr = tempDisplayArr.splice(currentIndex, 1)
    const removedEl = removedArr[0]
    tempDisplayArr.splice(newIndex, 0, removedEl)

    const { questionID } = removedEl
    if (questionID && !draftStatus) {
      if (tempHistoryArr.findIndex(x => x.questionID === questionID) === -1) {
        tempHistoryArr.push({ action: mceActionTypes.MODIFIED_QUESTION, questionID, section: section === 'P1' ? 1 : 3 })
      }
    }

    setVersionForm({ ...versionForm, [sectionArrKey]: tempDisplayArr, historyActions: tempHistoryArr })
  }

  // Confirm Remove Question popper related elements --- //
  const [confirmAnchorEl, setConfirmAnchorEl] = useState(null)

  const handleConfirmPopover = (e, questionObj) => {
    setLocalQuestionForm({ ...questionObj })
    setConfirmAnchorEl(e.currentTarget)
  }
  const handleConfirmPopClose = () => {
    setConfirmAnchorEl(null)
    setLocalQuestionForm({ ...defaultLocalForm })
  }
  const openConfirmPopover = Boolean(confirmAnchorEl)

  return (
    <Grid container direction='column'>
      {/* Header */}
      <Grid item container direction='row'>
        <Grid item xs={10} container direction='column'>
          <Grid item>
            <Typography variant='h5' style={{ lineHeight: 1, fontSize: 18 }}>{`Part ${section === 'P1' ? 1 : 3}`}</Typography>
          </Grid>
          <Grid item>
            <Typography variant='subtitle2'>{section === 'P1' ? 'Overview Questions' : 'Reflection Questions'}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={2} container direction='column'>
          <Button
            variant='contained'
            color='primary'
            startIcon={<AddRounded />}
            style={{ margin: 'auto 0 auto auto', fontWeight: 600 }}
            onClick={() => handleOpenQuestion()}
          >
            Add Question
          </Button>
        </Grid>
      </Grid>

      {/* Body */}
      <Grid item container direction='column' style={{ marginTop: '1em', padding: '20px 80px' }} spacing={5}>
        {!questions || !questions.length
          ? (
            <Grid item container direction='column' justifyContent='center' alignItems='center' style={{ marginTop: '1em' }}>
              <Grid item>
                <Typography style={{ fontWeight: 600, color: theme.palette.grey.dark }}>No Questions Have Been Added.</Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: theme.palette.grey.dark }}>Use the button above to start adding questions.</Typography>
              </Grid>
            </Grid>
          )
          : (
            questions.map((questionObj, i) => {
              const { questionID = '', question = '', evalCriteria, answerLimit = 400 } = questionObj
              const apparentQuestionOrder = i + 1
              return (
                <Grid item container direction='column' key={`question-p1-${questionID}-${i}`} style={{ border: `solid 1px ${theme.palette.grey.light}`, borderRadius: 7, marginBottom: i !== questions.length - 1 ? '2em' : 0 }}>
                  <Grid item container direction='row' key={`question-p1-${questionID}-${i}`} alignItems='center'>
                    <Grid item xs={7} container direction='column' spacing={1}>
                      <Grid item>
                        <Typography variant='caption' gutterBottom style={{ fontWeight: 600 }}>{`Question ${apparentQuestionOrder}:`}</Typography>
                        <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(question) }} className={classes.dangerHtmlSections} />
                      </Grid>

                      <Grid item>
                        <Typography variant='caption' style={{ fontWeight: 600, color: theme.palette.grey.darker }}>Evaluation Criteria</Typography>
                        <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(evalCriteria) }} className={classes.dangerHtmlSections} />
                      </Grid>
                    </Grid>
                    <Grid item xs={5} container direction='row' justifyContent='flex-end'>
                      <Grid item xs={5} />
                      <Grid item xs={3} container direction='column' alignItems='flex-end' style={{ paddingRight: '1.5em' }}>
                        <Typography variant='caption' style={{ fontWeight: 600 }}>Change Order:</Typography>
                        <Grid item container direction='row' justifyContent='flex-end'>
                          <IconButton
                            size='small'
                            disabled={i === questions.length - 1}
                            className={classes.questionOrderArrows}
                            onClick={() => handleOrderChange(i, i + 1)}
                          >
                            <ArrowDownwardRounded fontSize='inherit' />
                          </IconButton>
                          <IconButton
                            size='small'
                            disabled={i === 0}
                            className={classes.questionOrderArrows}
                            onClick={() => handleOrderChange(i, i - 1)}
                          >
                            <ArrowUpwardRounded fontSize='inherit' />
                          </IconButton>
                        </Grid>
                      </Grid>

                      <Grid item xs={2} container direction='column'>
                        <Typography variant='caption' style={{ fontWeight: 600 }}>Answer Limit</Typography>
                        <TextField
                          type='number'
                          size='small'
                          variant='outlined'
                          inputProps={{
                            min: 1
                          }}
                          fullWidth={false}
                          classes={{ root: classes.textField }}
                          value={answerLimit}
                          onChange={(e) => handleLimitChange(e.target.value, questionObj)}
                        />
                      </Grid>

                      <Grid item container direction='column' style={{ marginTop: '10px' }} xs={1}>
                        <IconButton onClick={() => handleOpenQuestion(questionObj)}>
                          <EditRounded style={{ color: theme.palette.purple.darkest }} />
                        </IconButton>
                      </Grid>
                      <Grid item container direction='column' style={{ marginTop: '10px' }} xs={1}>
                        <IconButton onClick={(e) => handleConfirmPopover(e, questionObj)}>
                          <DeleteForeverRounded style={{ color: '#d80505' }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )
            })
          )}
      </Grid>

      <Dialog
        open={questionModalOpen}
        onClose={handleQuestionClose}
        fullWidth
        maxWidth='md'
        disableEnforceFocus
      >
        <DialogTitle>
          <Grid item container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid item xs={11} container direction='row' alignItems='center'>
              <Typography variant='h4' style={{ textTransform: 'none', marginRight: '1em', fontSize: 22 }}>{`${editingBool ? 'Edit' : 'Add'} Question`}</Typography>
            </Grid>
            <Grid item container direction='row' xs={1} justifyContent='flex-end'>
              <IconButton size='small' onClick={handleQuestionClose}>
                <CloseRounded />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid item container direction='column' spacing={3}>
            <Grid item container direction='column'>
              <Typography gutterBottom variant='h5' style={{ paddingLeft: '5px' }}>Question</Typography>
              <Editor
                {...scriptSrc}
                onInit={(evt, editor) => { questionRef.current = editor }}
                value={localQuestionForm?.question}
                init={{
                  height: 150,
                  ...editorInitDefaults
                }}
                onEditorChange={(value, editor) => { setLocalQuestionForm({ ...localQuestionForm, question: value }) }}
              />
            </Grid>
            <Grid item container direction='column'>
              <Typography gutterBottom variant='h5' style={{ paddingLeft: '5px' }}>Evaluation Criteria</Typography>
              <Editor
                {...scriptSrc}
                onInit={(evt, editor) => { evalCriteriaRef.current = editor }}
                value={localQuestionForm?.evalCriteria}
                init={{
                  height: 150,
                  ...editorInitDefaults
                }}
                onEditorChange={(value, editor) => { setLocalQuestionForm({ ...localQuestionForm, evalCriteria: value }) }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: '8px 24px' }}>
          <Grid item container direction='column'>
            <Grid item container justifyContent='flex-end'>
              {errorObj?.missingContent && <Typography gutterBottom variant='caption' style={{ color: 'red' }}>*Must have input for both a question and evaluation criteria.</Typography>}
              {errorObj?.whiteSpaceOnly && <Typography gutterBottom variant='caption' style={{ color: 'red' }}>*Values cannot contain only white space characters.</Typography>}
              {errorObj?.generalError && <Typography gutterBottom variant='caption' style={{ color: 'red' }}>*Something went wrong, please close this modal and try again.</Typography>}
            </Grid>
            <Grid item container direction='row' justifyContent='space-between'>
              <Grid item>
                <Button variant='outlined' color='primary' onClick={handleQuestionClose}>Cancel</Button>
              </Grid>
              <Grid item>
                <Button
                  variant='contained'
                  color='primary'
                  disabled={disableSaveQuestion}
                  onClick={() => handleAddEditQuestion(localQuestionForm)}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <Popover
        id={openConfirmPopover ? 'confirm-popover' : undefined}
        open={openConfirmPopover}
        anchorEl={confirmAnchorEl}
        onClose={handleConfirmPopClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <Grid container direction='column' style={{ padding: '1em' }}>
          <Typography
            variant='h4'
            style={{ color: theme.palette.purple.darkest }}
            gutterBottom
          >
            Are you sure you wish to delete this question?
          </Typography>

          <Typography variant='body1' gutterBottom>This cannot be undone.</Typography>

          <Grid item container direction='row' justifyContent='flex-end' alignItems='center' style={{ marginTop: '.5em' }}>
            <Button onClick={handleConfirmPopClose} style={{ marginRight: '.3em', textTransform: 'none', fontWeight: '600' }}>Cancel</Button>
            <Button variant='contained' style={{ backgroundColor: '#d80505', fontWeight: 600 }} onClick={(e) => { e.preventDefault(); handleAddEditQuestion(localQuestionForm, true); handleConfirmPopClose() }}>Confirm</Button>
          </Grid>
        </Grid>
      </Popover>
    </Grid>
  )
}

export default BuilderQuestionSection
