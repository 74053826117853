import React from 'react'
import { Grid, Typography, Chip, Button, useTheme, Avatar, Paper } from '@material-ui/core'
import { AssignmentTurnedInRounded, CheckCircleOutlineRounded, Cancel, Done, HighlightOffRounded, RadioButtonUncheckedRounded } from '@material-ui/icons'
import { mceSections, mceViews } from '../../../../utils'
import { NotificationToast } from '../../tools'
import AssessorComment from './AssessorComment'
import { cloneDeep } from 'lodash'

const MCESubmitAndResults = (props) => {
  const {
    classes = {}, isAssessorView = false, isResultsView = false,
    isPursuerView = false, mceView, attemptDetailsForm = {}, setAttemptDetailsForm = () => { }, handleNavigateConfirm = () => { },
    fullyGradedEvaluation = false, currentStyle = {}, passedEval = false, handlePursuerAction = () => { }, questionGradingValues = {}
  } = props

  const theme = useTheme()

  const { attemptCount = 1, P1 = [], P2 = [], P3 = [] } = attemptDetailsForm

  // section or assessing completeion boolean checks
  const p1Complete = Boolean(P1 && (isPursuerView && P1.every(q => q.questionAnswer)))
  const p2Complete = Boolean(P2 && (isPursuerView && P2.every(s => (s.sampleType === 'L' && s.artifactID && !s.isDraft) || (s.sampleType === 'U' && s.fileName && s.fileType))))
  const p3Complete = Boolean(P3 && (isPursuerView && P3.every(q => q.questionAnswer)))
  const readyToSubmit = Boolean((p1Complete && p2Complete && p3Complete) || (isAssessorView && fullyGradedEvaluation))

  // icons to render in section feedback areas
  const sectionIcons = {
    passed: <CheckCircleOutlineRounded style={{ color: 'green' }} />,
    failed: <HighlightOffRounded style={{ color: 'red' }} />,
    incomplete: <RadioButtonUncheckedRounded style={{ color: theme.palette.grey.dark }} />,
    complete: <CheckCircleOutlineRounded style={{ color: theme.palette.purple.darkest }} />
  }

  // when grading is complete, show the assessor the results of their grading in a chip
  const getPassFailChip = () => {
    return (
      <Chip
        label={`Attempt ${!passedEval ? 'Not' : ''} Passed`}
        className={passedEval ? classes.attemptPassedChip : classes.attemptFailedChip}
        deleteIcon={
          !passedEval ? <Cancel style={{ color: 'white' }} /> : <Done style={{ color: 'white' }} />
        }
        onDelete={() => { }}
      />
    )
  }

  // determine the text needed in the instruction area based on the view, and the current level of completion or result
  const getInstructionalText = (view) => {
    switch (view) {
      case mceViews.PURSUE: {
        return `Please review your responses below. ${readyToSubmit ? 'If you are ready to submit your attempt please choose the \'Submit\' option below. Or you' : 'You'} can choose to save your current
        changes and come back later. ${readyToSubmit ? 'Once you hit the submit button you will not be able to make any further changes.' : 'Once all sections have been completed you will be able to submit your attempt.'}`
      }
      case mceViews.ASSESS: {
        return `Please review your grading responses and any comments for each section below. ${readyToSubmit ? 'When you are ready to submit your grade, please choose the submit option.' : 'To complete the evaluation of this attempt, please return to each section and be sure you have selected a grade value for each question and work sample.'} You may also use this page to generate any overall comments on the evaluation. ${readyToSubmit ? 'Once you hit submit you will not be able to return to this page or make any further changes.' : ''}`
      }
      case mceViews.RESULTS: {
        const primaryText = passedEval ? 'Congratulations! You have successfully achieved this Micro-Credential!' : `Your ${attemptCount < 3 ? 'current' : 'final'} attempt did not qualify you receive this Micro-Credential.`
        const subText = 'You can review feedback from your assessor and see the graded results of each section below.'

        return `${primaryText} ${subText}`
      }
      default: return ''
    }
  }

  // determine the section's header text
  const getHeaderText = () => {
    let text = ''

    if (!isResultsView) {
      if (readyToSubmit) {
        text = `Ready to Submit Evaluation${isAssessorView ? '' : ` Attempt ${attemptCount || 1}`}?`
      } else {
        text = `${isAssessorView ? 'Grading' : 'Attempt'} Not Complete`
      }
    } else {
      text = `Micro-Credential ${!passedEval ? 'Not' : ''} Achieved${passedEval ? '!' : ''}`
    }

    return (
      <Typography variant='h2' style={{ color: theme.palette.purple.darkest }}>{text}</Typography>
    )
  }

  // handle changing the gernal comment
  const modifyAttemptComment = (qid, input) => {
    const newInput = !input ? null : input
    setAttemptDetailsForm({ ...attemptDetailsForm, attemptComment: newInput })
  }

  // handle the submit action (changes between pursuer and assessor view)
  const handleSubmit = () => {
    if (isAssessorView) {
      // check for denial comment if required
      if (!passedEval && !attemptDetailsForm.attemptComment) {
        return NotificationToast(true, 'You must submit an overall comment before you can proceed.')
      }
    }

    handleNavigateConfirm(true, true)
  }

  // create a new attempt for the user
  const handleNextAttempt = () => {
    const nextAttempt = attemptCount + 1
    if (nextAttempt <= 3) {
      handlePursuerAction({}, nextAttempt)
    }
  }

  // get the subtext for each section review's feedback for when in the pursuer section
  const getPursuerSubText = (section, sectionValues) => {
    if (section && sectionValues?.length) {
      if ((section === mceSections.PART1 && p1Complete) || (section === mceSections.PART2 && p2Complete) || (section === mceSections.PART3 && p3Complete)) {
        return 'Complete!'
      }

      if (section !== mceSections.PART2) {
        return `Missing answers for ${sectionValues?.filter(x => !x.questionAnswer).length} of ${sectionValues?.length || 0} questions.`
      } else {
        return 'Be sure all samples have been uploaded and artifacts are not drafts.'
      }
    }

    return 'N/A'
  }

  // determine the icon needed in the section review feedback for when in the pursuer view
  const getPursuerIcon = (section) => {
    if (section) {
      if (
        (section === mceSections.PART1 && p1Complete) ||
        (section === mceSections.PART2 && p2Complete) ||
        (section === mceSections.PART3 && p3Complete)
      ) {
        return sectionIcons.complete
      }
    }

    return sectionIcons.incomplete
  }

  // render the section feedback areas based on section, view, and results
  const renderSectionInfo = () => {
    // isolate just the three ncessessary areas
    const sectionArr = [mceSections.PART1, mceSections.PART2, mceSections.PART3]

    // get the titles for each
    const titles = {
      [mceSections.PART1]: 'Part 1',
      [mceSections.PART2]: 'Part 2',
      [mceSections.PART3]: 'Part 3'
    }

    // determine the value object we need to reference based on the view
    const values = isAssessorView || isResultsView ? { ...questionGradingValues } : cloneDeep(attemptDetailsForm)

    // map through each one
    return sectionArr.map(section => {
      // find the values we need to check within the reference object
      const valueSection = values[section] || {}

      // determine the subtext
      const subText = isPursuerView
        ? getPursuerSubText(section, valueSection)
        : `${valueSection.sectionFullyGraded
          ? `Section ${valueSection.sectionPassed ? 'Passed' : 'Did Not Pass'}`
          : `Completed ${valueSection.gradedCount} of ${valueSection.questionCount}`}`

      // determine the icon
      const icon = isPursuerView
        ? getPursuerIcon(section)
        : valueSection.sectionFullyGraded
          ? valueSection.sectionPassed
            ? sectionIcons.passed
            : sectionIcons.failed
          : sectionIcons.incomplete

      return (
        <Paper key={`final-data-${section}`} style={{ width: '60%', padding: '.5em', marginBottom: '.5em' }} variant='outlined'>
          <Grid container direction='row' alignItems='center'>
            <Grid item container direction='column' xs={10}>
              <Typography variant='h5' style={{ lineHeight: 1 }}>{titles[section] || ''}</Typography>
              <Typography variant='caption' style={{}}>{subText}</Typography>
            </Grid>
            <Grid item container xs={2} justifyContent='center'>
              {icon}
            </Grid>
          </Grid>
        </Paper>
      )
    })
  }

  return (
    <Grid container item direction='column' className={classes.defaultSectionPadding} style={{ margin: '2em 0' }}>
      <Grid item container direction='row'>
        {/* Left Side (header and actions) */}
        <Grid item container direction='column' xs={6} justifyContent='center'>
          <Grid item container direction='column'>
            <Grid item container direction='row' alignItems='baseline' spacing={1}>
              {isResultsView && passedEval &&
                <Grid item xs={1} container direction='row' justifyContent='flex-start' style={{ marginBottom: '.5em', paddingRight: '1em' }}>
                  <Avatar style={{ height: '2em', width: '2em', backgroundColor: `${theme.palette.purple.darkest}45` }}>
                    <Avatar style={{ height: '1.5em', width: '1.5em', backgroundColor: theme.palette.purple.darkest }}>
                      <AssignmentTurnedInRounded style={{ fontSize: 22 }} />
                    </Avatar>
                  </Avatar>
                </Grid>}
              <Grid item xs={isResultsView && passedEval ? 11 : null}>
                {getHeaderText()}
              </Grid>
            </Grid>
            {isAssessorView && readyToSubmit &&
              <Grid item style={{ margin: '.5em 0' }}>
                {getPassFailChip()}
              </Grid>}

            <Grid item>
              <Typography variant='h5' style={{ color: theme.palette.grey.dark, fontWeight: 400 }}>
                {getInstructionalText(mceView)}
              </Typography>
            </Grid>
          </Grid>

          {/* Action buttons */}
          <Grid item container direction='row' spacing={2} style={{ marginTop: '1.5em' }}>
            {/* if viewing failed attempt results, render the 'try again' button (disabled if they are on their third attempt) */}
            {isResultsView && !passedEval &&
              <Grid item container direction='column'>
                <Button color='primary' onClick={() => { handleNextAttempt() }} disabled={Boolean(attemptCount === 3)} variant='contained' style={{ color: 'white', fontWeight: 600, marginRight: 'auto', padding: '6px 2em' }}>Try Again</Button>
                <Typography variant='body2' style={{ textAlign: 'left', color: theme.palette.grey.dark, marginTop: '.5em' }}>{`You have ${attemptCount && attemptCount < 3 ? 3 - attemptCount : 0} remaining attempt(s).`}</Typography>
              </Grid>}

            {/* when not in the results view, determine the action and the buttons needed depending on the view, and if its ready to submit. */}
            {!isResultsView &&
              <>
                {/* FOR PILOT ONLY: do not allow assessor to save a draft */}
                {!isAssessorView &&
                  <Grid item>
                    <Button variant='outlined' color='primary' onClick={() => handleNavigateConfirm(true)}>Save and Exit</Button>
                  </Grid>}
                {readyToSubmit &&
                  <Grid item>
                    <Button variant='contained' color='primary' onClick={() => handleSubmit()} style={{ padding: '.4em 2em' }}>Submit</Button>
                  </Grid>}
              </>}
          </Grid>

          {/* Warning text when pursuer is on third and final attempt */}
          {isPursuerView && readyToSubmit && attemptCount === 3 &&
            <Grid item style={{ marginTop: '.5em' }}>
              <Typography variant='h6' style={{ textTransform: 'none', color: 'red', fontWeight: 400, textAlign: 'center' }}><span style={{ fontWeight: 600 }}>Warning!</span> This is your final attempt. Please ensure you are happy with your answers before proceeding.</Typography>
            </Grid>}
        </Grid>

        {/* Right Side (section feedback area) */}
        <Grid item container direction='column' alignItems='flex-end' xs={6}>
          {renderSectionInfo()}
        </Grid>
      </Grid>

      {/* Assessor Comment section */}
      {((isAssessorView && fullyGradedEvaluation) || (isResultsView && attemptDetailsForm.attemptCommentID) || (isPursuerView && attemptDetailsForm?.lastAttemptComment)) &&
        <Grid item container direction='column' style={{ marginTop: '2em' }}>
          {isAssessorView &&
            <Typography variant='body2' style={{ fontWeight: 600, textAlign: 'left' }}>Use the input below to provide any additional feedback you have for this attempt. <span style={{ fontWeight: 400, fontSize: 12, color: passedEval ? 'black' : 'red' }}>{`(${passedEval ? 'Optional' : 'Required'})`}</span></Typography>}
          <AssessorComment
            currentStyle={currentStyle}
            isAssessorView={isAssessorView}
            defaultOpen={Boolean(!isPursuerView || (isPursuerView && attemptDetailsForm?.lastAttemptComment))}
            currentQuestion={{ attemptComment: attemptDetailsForm?.attemptComment, lastAttemptComment: attemptDetailsForm?.lastAttemptComment }}
            modifyQuestionComment={modifyAttemptComment}
            isPursuerView={isPursuerView}
          />
        </Grid>}
    </Grid>
  )
}

export default MCESubmitAndResults
