import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  Grid, Typography, Divider, Paper, Tooltip, Fade, useMediaQuery, IconButton
} from '@material-ui/core'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'

import { setActiveConversationUsers } from '../../../../../redux/actions'

import moment from 'moment'

/* ********** Draft Conversation Preview Details************* //
    Rendered from ConversationList.js when the 'view.conversation' is 'drafts'
*/
const DraftConversationCard = (props) => {
  const {
    theme, activeCard, conversationID, content, loadNewView, viewTypes,
    handleConfirmPopover, setDeleteDraftID,
    setCurrentForm,
    setEditorOpen
  } = props

  const dispatch = useDispatch()

  const smScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const { drafts = [], conversations = [] } = useSelector(state => state.websocket)

  const [conversationUserInfo, setConversationUserInfo] = useState({})
  const { fullName = '', schoolName = '' } = conversationUserInfo
  const [createdAt, setCreatedAt] = useState('')
  const [parsedContent, setParsedContent] = useState('')

  useEffect(() => {
    const conversationMatch = conversations.find(conversation => conversation.conversationID === conversationID)

    if (conversationMatch) {
      setConversationUserInfo(conversationMatch.userListDetails[0])
      setCreatedAt(conversationMatch.mostRecentCreatedAt)
    } else {
      setConversationUserInfo({})
      setCreatedAt('')
    }

    // taking the draft message content and parsing it for display
    if (content) {
      const parse = JSON.parse(content)

      setParsedContent(parse[0].children[0].text)
    } else {
      setParsedContent('')
    }
  }, [conversations, drafts, content, conversationID])

  // Switch to a new draft conversation
  const handleConversationChange = async () => {
    if (conversationID) {
      // set the conversation clicked to the active conversation and user details
      dispatch(setActiveConversationUsers([conversationUserInfo]))
      loadNewView(viewTypes.DRAFT, conversationID)

      if (smScreen) { setCurrentForm('message-area'); setEditorOpen(true) }
    }
  }

  return (
    <>
      <Paper elevation={0} style={{ cursor: 'pointer', backgroundColor: activeCard ? theme.palette.purple.lightest : 'white', padding: '.5em' }}>
        <Grid item container direction='row'>
          {/* Message Information */}
          <Grid
            item
            container
            direction='column'
            style={{ cursor: 'pointer', overflow: 'hidden' }}
            onClick={(e) => { e.preventDefault(); handleConversationChange() }}
          >
            {/* Recipient NickName */}
            <Grid item container direction='row'>
              <Grid item container xs={8} alignItems='baseline' direction='row'>
                <Grid item container xs={6} sm={6} md={12}>
                  <Tooltip arrow title={fullName} placement='top' enterDelay={1000} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
                    <Typography
                      variant='h5'
                      noWrap
                      style={{ fontWeight: 600, textTransform: 'none', paddingRight: '.2em' }}
                    >
                      {fullName}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item container xs={12}>
                  <Tooltip arrow title={schoolName} placement='top' enterDelay={1000} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
                    <Typography
                      variant='caption'
                      style={{ fontWeight: 400, textTransform: 'none', color: theme.palette.grey.dark }}
                    >
                      {schoolName}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid item container justifyContent='flex-end' xs={4}>
                <Typography
                  variant='caption'
                  noWrap
                  style={{ fontWeight: 400, textTransform: 'none', color: theme.palette.grey.dark, textAlign: 'end' }}
                >
                  {createdAt ? moment.unix(createdAt).format('ddd h:mm a') : ''}
                </Typography>
              </Grid>
            </Grid>

            {/* Message Preview and Delete/Edit Icon */}
            <Grid item container direction='row' alignItems='center' style={{ marginBottom: '1em', marginTop: '.2em' }}>
              <Grid item container direction='row' xs={10}>
                <Typography style={{ color: theme.palette.grey.dark }}>
                  {parsedContent && parsedContent.length > 25 ? `${parsedContent.slice(0, 25)}...` : parsedContent}
                </Typography>
              </Grid>

              {/* Currently not rendering on smaller screens. */}
              {/* With the way we're handling the conversation and message area on mobile, the popover to delete will not render correctly. */}
              {
                !smScreen &&
                  <Grid item container direction='row' xs={2} justifyContent='flex-end'>
                    <Tooltip title='Hide Conversation' TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} arrow placement='right' enterDelay={750}>
                      <IconButton size='small' onClick={(e) => { e.stopPropagation(); handleConfirmPopover(e); setDeleteDraftID(conversationID) }}>
                        <HighlightOffRoundedIcon style={{ fontSize: 16, color: theme.palette.grey.medium, zIndex: 1 }} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Divider variant='middle' style={{ margin: '.2em 0' }} />
    </>
  )
}

export default DraftConversationCard
