import React, { } from 'react'
import { SvgIcon } from '@material-ui/core'

const CommentIcon = (props) => {
  return (
    <SvgIcon viewBox='0 0 9.807 8.581' {...props}>
      <path
        id='comment-icon'
        data-name='comment-icon'
        d='M4.9,32C2.2,32,0,33.783,0,35.984a3.536,3.536,0,0,0,1.092,2.5,4.873,4.873,0,0,1-1.05,1.835.152.152,0,0,0-.029.167.15.15,0,0,0,.14.092A4.38,4.38,0,0,0,2.846,39.6a5.841,5.841,0,0,0,2.057.372c2.708,0,4.9-1.783,4.9-3.984S7.612,32,4.9,32Z'
        transform='translate(0 -32)'
      />
    </SvgIcon>
  )
}

export default CommentIcon
