import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Grid, useTheme, Typography, Chip, IconButton, Avatar, makeStyles } from '@material-ui/core'
import {
  Edit, CheckCircleOutlineRounded, AccessTimeRounded, PeopleAltRounded,
  BuildRounded, FolderSharedRounded, PublicRounded, PlaylistAddCheckRounded,
  RemoveCircleOutline, AddCircle
} from '@material-ui/icons'
import { GradesCheck } from '../../../ui/tools'
import { artifactTypes, getFormatStatus, userRoleIDs, userRoles } from '../../../../utils'

import moment from 'moment'

const publishStatusBanners = {
  DRAFT: {
    icon: BuildRounded,
    name: 'Draft'
  },
  PRIVATE: {
    icon: FolderSharedRounded,
    name: 'Private'
  },
  PUBLIC: {
    icon: PublicRounded,
    name: 'Public'
  }
}

const useStyles = makeStyles(theme => ({
  wtOrCTText: {
    textAlign: 'end',
    fontSize: '14px',
    textOverflow: 'ellipsis',
    color: theme.palette.purple.darkest,
    fontWeight: 600,
    paddingLeft: '.3em',
    paddingRight: '.3em',
    lineHeight: '1.1'
  },
  wtORCtIcon: {
    fontSize: 13,
    color: theme.palette.purple.darkest
  }
}))

const LEDCardView = (props) => {
  const {
    editMode = false, canEditArtifact = false, classes, statusCheck = false, miniCard = false,
    privateCheck = false, isSelected = false, noneSelected = false, artifactInfo = {},
    gradesArray = [], onArtifactEdit = () => { }, type = '', handleSelection = () => { },
    handleArtifactNotPublicCheck = () => { }
  } = props

  const theme = useTheme()
  const localClasses = useStyles()
  const { userID = '' } = useSelector(state => state.auth)

  // Check to see if the view is in both editMode and the artifact is editable by the current user
  const displayEditStyles = Boolean(editMode && canEditArtifact)

  const [now] = useState(moment().unix())

  // Deconstructed elements of the artifact information
  const {
    fullName = '', title = '', profileAvatarPath = '', learnerWorkID = '',
    isDraft = 0, submissionStatus, authorID = '',
    techNeeded = '', workType = '', completionTime = '', grades, subjectName = '',
    artifactID = '', roleID = ''
  } = artifactInfo

  // Find the correct display name based on the user's role
  const getDisplayRoleText = () => {
    const displayKey = roleID && Object.values(userRoleIDs).includes(roleID) ? Object.keys(userRoleIDs).find(key => userRoleIDs[key] === roleID) : ''
    const displayValue = userRoles[displayKey]
    if (displayValue) {
      if (displayValue === userRoles.ADMIN_ROLE) {
        return userRoles.TEACHER_FELLOW
      }

      return displayValue
    }

    // If something goes wrong display the following default
    return 'Ed Farm User'
  }

  // Return either status banner or author information depending on the card view
  const getSubtitleSection = () => {
    // If viewing on personal profile, display the status banner of the artifact
    if ((displayEditStyles && userID === authorID) || miniCard) {
      // If the artifact if for submission fetch it's current status
      if (statusCheck) {
        return (
          getFormatStatus(!submissionStatus ? null : submissionStatus, theme.palette.purple.darkest)
        )
      }

      // Other wise display its published status
      const pubStatDetails = !isDraft ? privateCheck ? publishStatusBanners.PRIVATE : publishStatusBanners.PUBLIC : publishStatusBanners.DRAFT
      const { icon: BannerIcon, name } = pubStatDetails

      return (
        <>
          <Grid item container direction='row' alignItems='center' style={{ paddingTop: '.2em' }}>
            <BannerIcon style={{ fontSize: '14px', color: theme.palette.purple.darkest, marginRight: '.3em' }} />
            <Typography variant='h6' style={{ color: theme.palette.purple.darkest, textTransform: 'none', fontWeight: '400', fontSize: '14px' }}>{name}</Typography>
          </Grid>
        </>
      )
    }

    // Otherwise display the author information
    return (
      <>
        <Grid item container xs={1}>
          <Avatar src={profileAvatarPath ? `${profileAvatarPath}?${now}` : ''} style={{ height: 26, width: 26 }} />
        </Grid>
        <Grid item container direction='column' alignItems='flex-start' xs={11} style={{ paddingLeft: '.5em' }}>
          <Grid item>
            <Typography style={{ lineHeight: 1, color: '#454545', fontSize: 12 }}>{getDisplayRoleText()}</Typography>
          </Grid>
          <Grid item>
            <Typography variant='subtitle2' style={{ lineHeight: 1, fontWeight: 600, color: '#2F2E41' }}>{fullName}</Typography>
          </Grid>
        </Grid>
      </>
    )
  }

  const GridChip = ({ textDisplay = '', textColor = '', backgroundColor = '' }) => {
    return (
      <Grid item style={{ padding: 6, maxWidth: '100%' }}>
        <Chip
          label={<Typography variant='h5' style={{ color: textColor, overflow: 'hidden', textOverflow: 'ellipsis' }}>{textDisplay}</Typography>}
          style={{ backgroundColor: backgroundColor, padding: '.5em', filter: 'drop-shadow(1px 2px 1px rgba(0, 0, 0, 0.2))', maxWidth: 'inherit' }}
        />
      </Grid>
    )
  }

  const justifyEndStart = `flex-${displayEditStyles || miniCard ? 'end' : 'start'}`
  const dynamicTextAlign = displayEditStyles || miniCard ? 'left' : 'right'
  const formattedWT = !workType ? 'N/A' : workType === 'both' ? 'Individual & Group' : `${workType} Work`
  const fadeColorCheck = (!miniCard && isDraft) || (miniCard && !noneSelected && !isSelected)
  const editOrMiniStyles = Boolean(displayEditStyles || miniCard)
  const MiniCardIcon = isSelected ? RemoveCircleOutline : AddCircle
  const miniCardIconHandler = isSelected ? handleSelection : handleArtifactNotPublicCheck

  // ========================================= Main Return =========================================== //
  return (
    <Grid container direction='column' style={{ padding: '1em 0', backgroundColor: fadeColorCheck ? '#ffffffb5' : '', minHeight: '100%', justifyContent: 'space-between' }}>
      {/* Top Half */}
      <Grid item container direction='column' spacing={1} style={{ marginBottom: '1em', padding: '0 1em' }}>

        {/* LW indicator and Icons (row reverse on public views) */}
        <Grid item container alignItems='center' style={{ padding: 0, marginTop: '.5em' }} direction={editOrMiniStyles ? 'row' : 'row-reverse'} justifyContent={justifyEndStart}>
          {/* LW indicator (hidden when no LW connected to LED) */}
          {learnerWorkID &&
            <Grid item container direction={editOrMiniStyles ? 'row-reverse' : 'row'} xs={10} alignItems='center' justifyContent={justifyEndStart}>
              <Grid item xs={11}>
                <Typography
                  variant='subtitle2'
                  noWrap
                  style={{ textAlign: dynamicTextAlign, fontSize: '10px', textOverflow: 'ellipsis', color: theme.palette.grey.darker, textTransform: 'uppercase' }}
                >
                  Learner Work Included
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <CheckCircleOutlineRounded style={{ fontSize: '12px', color: theme.palette.grey.darker }} />
              </Grid>
            </Grid>}

          {/* Edit (for profile view) icons, hidden on public view */}
          {displayEditStyles && !miniCard &&
            <Grid item container xs={2} justifyContent='flex-end' style={{ marginBottom: !learnerWorkID ? '-1em' : '' }}>
              <IconButton
                className={classes.editBtn}
                style={{ color: theme.palette.purple.darkest }}
                onClick={
                  (e) => { e.preventDefault(); onArtifactEdit(artifactID, artifactTypes[type]) }
                }
              >
                <Edit
                  style={{ height: '100%', width: '100%' }}
                />
              </IconButton>
            </Grid>}

          {/* Plus (for artifact selection), hidden when not viewing the 'mini' version of the card */}
          {miniCard &&
            <Grid item container xs={2} justifyContent='flex-end' style={{ marginBottom: '-1.5em' }}>
              <IconButton onClick={(e) => miniCardIconHandler(e, artifactID, null)} style={{ padding: 0 }}>
                <MiniCardIcon style={{ color: theme.palette.purple.darkest, fontSize: '32px' }} />
              </IconButton>
            </Grid>}
        </Grid>

        {/* Title/Author/Status Banner  */}
        <Grid item container spacing={1} direction='column' style={{ paddingTop: !learnerWorkID ? '1em' : '.5em' }}>
          {/* Artifact Type */}
          <Grid item container>
            <Typography
              variant='subtitle2'
              noWrap
              style={{ textAlign: 'end', fontSize: '12px', textOverflow: 'ellipsis', color: theme.palette.grey.darker, textTransform: 'uppercase' }}
            >
              Learning Experience Design
            </Typography>
          </Grid>

          {/* Artifact Title */}
          <Grid item container justifyContent='flex-start' style={{ maxWidth: 'fit-content' }}>
            <Typography variant='h4' style={{ textAlign: 'left', color: '#2F2E41', wordBreak: 'break-word' }}>
              {title}
            </Typography>
          </Grid>

          {/* Display either Statuus Banner or Author Information */}
          <Grid item container direction='row' alignItems='center' style={{ marginTop: '.5em' }}>
            {getSubtitleSection()}
          </Grid>
        </Grid>
      </Grid>

      {/* Bottom Half */}
      <Grid item container spacing={2} direction='column'>
        {/* Grade/Subject/Small Deets */}
        <Grid item container spacing={2} direction='column' style={{ padding: '0 1.5em' }}>
          <Grid item container spacing={2} direction='column' alignItems='flex-end'>

            {/* Grades Chip */}
            <GridChip textDisplay={GradesCheck(grades, gradesArray)} textColor='white' backgroundColor='#3F4A6E' />

            {/* Subjects Chip */}
            <GridChip textDisplay={subjectName} textColor='#3F4A6E' backgroundColor='white' />
          </Grid>

          {/* Completion Time/ Work Type */}
          <Grid item container direction='row'>
            {[{ Icon: AccessTimeRounded, text: `${completionTime} week(s)` }, { Icon: PeopleAltRounded, text: formattedWT }].map((info, i) => {
              const { Icon = AccessTimeRounded, text = '' } = info
              return (
                <Grid key={`wt-grid-display-${i}`} item container direction='row' xs={6} alignItems='center' justifyContent='flex-end' style={{ flexWrap: 'nowrap' }}>
                  <Icon className={localClasses.wtORCtIcon} />
                  <Typography
                    variant='subtitle2'
                    noWrap
                    className={localClasses.wtOrCTText}
                    style={{ textTransform: i === 1 ? 'capitalize' : 'none' }}
                  >
                    {text}
                  </Typography>
                </Grid>
              )
            })}
          </Grid>
        </Grid>

        {/* Tech Needed */}
        <Grid item container direction='row' style={{ backgroundColor: '#ffffffb5', marginTop: '.5em' }}>
          <Grid item xs={2} container direction='column' alignItems='flex-end'>
            <PlaylistAddCheckRounded style={{ color: '#4D4D4D' }} />
          </Grid>
          <Grid
            item
            xs={10}
            container
            direction='column'
            alignItems='flex-start'
            style={{ paddingLeft: '.3em', paddingRight: '.5em', width: 'min-content' }}
          >
            <Grid item>
              <Typography style={{ fontWeight: 600, color: '#4D4D4D' }}>Tech Needed</Typography>
            </Grid>
            <Grid item style={{ maxWidth: '80%', textAlign: 'left', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden' }}>
              <Typography
                variant='subtitle2'
                style={{
                  wordBreak: 'break-word',
                  fontSize: '12px',
                  color: '#4D4D4D'
                }}
              >
                {techNeeded}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LEDCardView
