import React, { useState, useRef, useMemo } from 'react'
import {
  Grid, Button, Typography, useTheme, Dialog, DialogTitle, DialogContent, DialogActions,
  IconButton, OutlinedInput, RadioGroup, FormControlLabel, Radio, Popover, Avatar,
  Chip
} from '@material-ui/core'
import {
  AddRounded, ArrowDownwardRounded, ArrowUpwardRounded, CloseRounded,
  DeleteForeverRounded, MoreVertRounded, EditRounded
} from '@material-ui/icons'
import { Editor } from '@tinymce/tinymce-react'
import { cloneDeep, isEqual } from 'lodash'
import { sanitizedHTML, createTempID, formatStringForEditor, formatStringForDisplay } from '../../../utils/functions'
import { workSampleTypes, mceActionTypes } from '../../../utils/variables'
import BuilderRubricEditor from './BuilderTools/BuilderRubricEditor'

const defaultSamplesForm = {
  workSampleID: null,
  tempID: null,
  title: '',
  sampleType: 'L',
  sampleDesc: '',
  rubricRows: [],
  rubricsToRemove: [],
  localHistoryActions: []
}

const defaultRubricForm = {
  rubricID: null,
  tempID: null,
  proficientText: '',
  basicText: '',
  developingText: '',
  autoFail: 0
}

const rubricSubActions = {
  DELETE: 'delete',
  MOVE_UP: 'move-up',
  MOVE_DOWN: 'move-down',
  EDIT: 'edit'
}

const BuilderSampleSection = (props) => {
  const {
    scriptSrc, editorInitDefaults, classes, versionForm = {}, setVersionForm = () => {},
    errorObj = {}, setErrorObj = () => {}, defaultErrors = {}
  } = props
  const theme = useTheme()

  // set the local samples array to the correct version form array
  const samples = useMemo(() => {
    if (versionForm && versionForm.displayP2) {
      return versionForm.displayP2
    } else {
      return []
    }
  }, [versionForm])

  const [sampleModalOpen, setSampleModalOpen] = useState(false)
  const [localSamplesForm, setLocalSamplesForm] = useState({ ...defaultSamplesForm })
  const [rubricEditorOpen, setRubricEditorOpen] = useState(false)
  const [rubricForm, setRubricForm] = useState({ ...defaultRubricForm })
  const [newRow, setNewRow] = useState(false)

  // local refs
  const sampleDescRef = useRef(null)
  const editRubricIndexRef = useRef(null)

  // content refs to track changes
  const sampleContentRef = useRef(null)
  const sampleRubricRowContentRef = useRef(null)

  // -- CLOSE/RESET THE RUBRIC EDITOR
  const handleRubricEditorClose = () => {
    setRubricForm({ ...defaultRubricForm })
    setRubricEditorOpen(false)
    setNewRow(false)
    setErrorObj({ ...defaultErrors })
    sampleRubricRowContentRef.current = null
  }

  // -- SAVE RUBRIC EDITOR CHANGES
  const handleSaveRubricChange = (ruForm) => {
    const { rubricID, tempID, proficientText, basicText, developingText } = ruForm
    const currentRows = [...localSamplesForm.rubricRows]
    const draftStatus = versionForm.isDraft

    const tempHistory = [...localSamplesForm.localHistoryActions]

    // if not a draft but we have modified a currently stored rubric, add the modification to the actions array (if it not already there)
    if (rubricID && !draftStatus) {
      if (tempHistory.findIndex(x => x.rubricID === rubricID) === -1) {
        tempHistory.push({ action: mceActionTypes.MODIFIED_RUBRIC, rubricID, workSampleID: localSamplesForm.workSampleID || null })
      }
    }

    // set the html fields to be readable as html
    ruForm.proficientText = formatStringForDisplay(proficientText)
    ruForm.basicText = formatStringForDisplay(basicText)
    ruForm.developingText = formatStringForDisplay(developingText)

    // if we are dealing with a new row, create a temporary ID and push it to the local array
    if (newRow) {
      const newTempID = createTempID()
      currentRows.push({ ...ruForm, tempID: newTempID })
    } else {
      // otherwise be sure we have either a rubricID or tempID
      if (!rubricID && !tempID) {
        setErrorObj({ ...errorObj, generalError: true })
        console.warn('could not find row to modify')
        return false
      }

      // use the appropriate key to find the index within the local array of the rubric obj being modified
      const findKey = rubricID ? 'rubricID' : 'tempID'
      const findVal = rubricID || tempID
      const currentIndex = currentRows.findIndex(x => x[findKey] === findVal)

      // if we have it, then reset it contents to the supplied information
      if (currentIndex !== -1) {
        currentRows[currentIndex] = { ...ruForm }
      } else {
        // should not happen
        console.warn('could not find index of obj')
      }
    }

    // set the local form to the new configuration and close/reset the rubric editor
    setLocalSamplesForm({ ...localSamplesForm, rubricRows: currentRows, localHistoryActions: tempHistory })
    handleRubricEditorClose()
  }

  // -- CLOSE AND RESET THE ADD/EDIT SAMPLE MODAL
  const handleSampleClose = () => {
    // close the modal
    setSampleModalOpen(false)

    // if the rubric editor was open, reset it
    if (rubricEditorOpen) {
      handleRubricEditorClose()
    }

    // reset the local form and error obj
    setLocalSamplesForm({ ...defaultSamplesForm })
    setErrorObj({ ...defaultErrors })
    sampleContentRef.current = null
  }

  // -- OPEN THE ADD/EDIT SAMPLE MODAL
  const handleOpenSampleModal = (sampObj) => {
    // if editing a current sample, format the html keys to be readable by the editor and set to the local form
    if (sampObj) {
      const { workSampleID = null, tempID = null, title = '', rubricRows = [], sampleType = 'L', sampleDesc = '' } = sampObj

      // format the sample obj to display correctly in the editors
      const newForm = {
        ...defaultSamplesForm,
        workSampleID,
        tempID,
        title,
        sampleType,
        rubricRows,
        sampleDesc: formatStringForEditor(sampleDesc)
      }

      sampleContentRef.current = { title, sampleDesc: newForm.sampleDesc, rubricRows, sampleType }
      setLocalSamplesForm(newForm)
    }

    // open the modal
    setSampleModalOpen(true)
  }

  // -- BOOLEAN: disable the save sample button in the modal if there is no title or sample desc, or if the original is NOT a draft and there are no rubric rows OR if no edits are recognized
  const disableSave = Boolean(
    !localSamplesForm.title || !localSamplesForm.title.trim().length ||
      !localSamplesForm.sampleDesc ||
        rubricEditorOpen ||
          (!versionForm.isDraft && !localSamplesForm.rubricRows.length) ||
            (sampleContentRef.current && sampleContentRef.current === { title: localSamplesForm.title, sampleDesc: localSamplesForm.sampleDesc, rubricRows: localSamplesForm.rubricRows, sampleType: localSamplesForm.sampleType })
  )

  // -- MODIFY THE SAMPLE
  const handleSampleModify = (sampleInfo, removeBool = false) => {
    const { workSampleID, tempID, title, sampleDesc, rubricRows = [], rubricsToRemove = [], localHistoryActions = [] } = sampleInfo
    // closne the current array of samples
    const currentDisplayArr = cloneDeep(versionForm.displayP2)
    // clone the current set of samples slated for deletion
    const currentRemovedArr = [...versionForm.workSamplesToRemove]

    const tempHistory = [...versionForm.historyActions]
    tempHistory.push(...localHistoryActions)
    const draftStatus = versionForm.isDraft

    // check that the content of the editor does not include only white space
    const descWhiteSpaceCheck = Boolean(sampleDescRef.current?.getContent({ format: 'text' }).trim().length)
    // the requirements for a sample (title and description, and either a draft with no rubric OR it has a rubric and at least one row contains dev text)
    const requirementsCheck = Boolean(title.length && sampleDesc.length && descWhiteSpaceCheck && ((draftStatus && !rubricRows) || (rubricRows.length && !rubricRows.every(x => !x.developingText.length))))
    // if the requirement check fails, set the correct reason to the displayed error obj, and stop the function
    if (!removeBool && !requirementsCheck) {
      let errKey = 'generalError'
      if (!descWhiteSpaceCheck) { errKey = 'whiteSpaceOnly' }
      if (!title.length || !sampleDesc.length || !descWhiteSpaceCheck) { errKey = 'missingContent' }
      if (!draftStatus && !rubricRows.length) { errKey = 'noRubric' }
      if (rubricRows.length && rubricRows.every(x => !x.developingText.length)) { errKey = 'missingDevText' }
      setErrorObj({ ...errorObj, [errKey]: true })
      return false
    }

    // set the provided strings from the rich text editor to be formatted as readable html
    sampleInfo.sampleDesc = formatStringForDisplay(sampleDesc)

    // if we are dealing with a brand new sample, and not deleting one, push it to the local array
    if (!workSampleID && !tempID && !removeBool) {
      // create a new temporary ID
      const newTempID = createTempID()
      // push to local array
      currentDisplayArr.push({ ...sampleInfo, tempID: newTempID })
    } else {
      // otherwise we are dealing with something already in the display array and need to find and modify it (could be with by the workSampleID, or the tempID)
      const findByKey = workSampleID ? 'workSampleID' : 'tempID'
      const findVal = workSampleID || tempID
      const currentSampleIndex = currentDisplayArr.findIndex(x => x[findByKey] === findVal)

      // if something went wrong trying to find the sample to modify, return false with a general error but log the specific problem
      if (currentSampleIndex === -1) {
        console.warn('could not find sample to modify')
        setErrorObj({ ...errorObj, generalError: true })
        return false
      }

      // if we are deleting a sample remove it from the display, otherwise modify it
      if (removeBool) {
        // remove the sample from the displayed array
        currentDisplayArr.splice(currentSampleIndex, 1)

        // add the workSampleID to the workSamplesToRemove arr (NOTE: this only moves to the api if the original mce is still a draft)
        if (workSampleID) {
          currentRemovedArr.push(workSampleID)
        }
      } else {
        // reassign the index to the new content
        currentDisplayArr[currentSampleIndex] = { ...sampleInfo }

        // if not a draft but we have modified a currently stored rubric, add the modification to the actions array (if it not already there)
        if (workSampleID && !draftStatus) {
          if (tempHistory.findIndex(x => x.workSampleID === workSampleID) === -1) {
            tempHistory.push({ action: mceActionTypes.MODIFIED_WORKSAMP, workSampleID })
          }
        }
      }
    }

    // set the form to the new configuration
    setVersionForm({ ...versionForm, displayP2: currentDisplayArr, workSamplesToRemove: currentRemovedArr, rubricsToRemove, historyActions: tempHistory })

    // close/reset the modal if open
    if (sampleModalOpen) {
      handleSampleClose()
    }
  }

  // -- MODIFY THE POSITION OF THE WORK SAMPLE
  const handleSampleOrderChange = (currentIndex, newIndex) => {
    // clone the current array
    const tempDisplayArr = [...versionForm.displayP2]
    const tempHistoryArr = [...versionForm.historyActions]
    const draftStatus = versionForm.isDraft

    // remove the chosen element and store it in 'removedArr'
    const removedArr = tempDisplayArr.splice(currentIndex, 1)
    // isolate the object
    const removedEl = removedArr[0]
    // insert it at the desired index
    tempDisplayArr.splice(newIndex, 0, removedEl)

    const { workSampleID } = removedEl
    if (workSampleID && !draftStatus) {
      if (tempHistoryArr.findIndex(x => x.workSampleID === workSampleID) === -1) {
        tempHistoryArr.push({ action: mceActionTypes.MODIFIED_WORKSAMP, workSampleID })
      }
    }

    // set the version form to the new configuration
    setVersionForm({ ...versionForm, displayP2: tempDisplayArr, historyActions: tempHistoryArr })
  }

  const disableSaveRubric = Boolean(!rubricForm.proficientText.length || !rubricForm.basicText.length || (sampleRubricRowContentRef.current && isEqual(sampleRubricRowContentRef.current, rubricForm)))

  // -- OPEN THE RUBRIC EDITOR
  const handleRubricEditorOpen = (rubricInfo) => {
    // if no rubric info was provided we are generating a new rubric row
    if (!rubricInfo) {
      setNewRow(true)
    } else {
      // otherwise we need to format and set the info to the local form
      setNewRow(false)
      const { proficientText = '', basicText = '', developingText = '' } = rubricInfo

      // format the html related keys to be displayed in the editors
      const newForm = {
        ...rubricInfo,
        proficientText: formatStringForEditor(proficientText),
        basicText: formatStringForEditor(basicText),
        developingText: formatStringForEditor(developingText)
      }

      sampleRubricRowContentRef.current = { ...newForm }

      // set it to the local form
      setRubricForm({ ...newForm })
    }

    // open the editors
    setRubricEditorOpen(true)
  }

  // ----- Confirm Rubric sub actions popper related elements ------ //
  const [subActionsAnchorEl, setSubActionsAnchorEl] = useState(null)

  // -- OPEN THE RUBRIC SUB ACTIONS
  const handleSubActionsPopover = (e, rubricIndex) => {
    // set the index of the selected obj to a local ref
    editRubricIndexRef.current = rubricIndex
    setSubActionsAnchorEl(e.currentTarget)
  }

  // -- CLOSE THE RUBRIC SUB ACTIONS
  const handleSubActionsPopClose = () => {
    setSubActionsAnchorEl(null)
    editRubricIndexRef.current = null
  }

  const openSubActionsPopover = Boolean(subActionsAnchorEl)

  // -- RUBRIC SUB ACTION HANDLER
  const handleRubricSubAction = (action) => {
    const currentRows = [...localSamplesForm.rubricRows]
    const currentIndex = editRubricIndexRef.current
    const removeRows = [...localSamplesForm.rubricsToRemove] || []
    const tempHistory = [...localSamplesForm.localHistoryActions]
    const draftStatus = versionForm.isDraft
    const rowContent = currentRows[currentIndex] || { ...defaultRubricForm }

    // if we have both an action and a valid index to edit
    if (action && (currentIndex || currentIndex === 0)) {
      // if editing a rubric, open the editor and populate the content of the selected index tot he editors
      if (action === rubricSubActions.EDIT) {
        handleRubricEditorOpen(rowContent)
      } else {
        // otherwise modify the  current rows to remove the selected index and store it in 'removedEl'
        const removedEl = currentRows.splice(currentIndex, 1)

        // if we are changing the order (the only remaining actionas after edit and delete)
        if (action !== rubricSubActions.DELETE) {
          // find the new index based on the direction of the action
          const newIndex = action === rubricSubActions.MOVE_UP ? currentIndex - 1 : currentIndex + 1

          // if we successfully stored the removed object, reinsert it at the selected index.
          if (removedEl.length) {
            currentRows.splice(newIndex, 0, removedEl[0])
          }

          if (rowContent.rubricID && !draftStatus) {
            if (tempHistory.findIndex(x => x.rubricID === rowContent.rubricID) === -1) {
              tempHistory.push({ action: mceActionTypes.MODIFIED_RUBRIC, rubricID: rowContent.rubricID, workSampleID: localSamplesForm.workSampleID })
            }
          }
        } else {
          // if row was slated to be deleted, and it had a rubricID, add it to the array to be removed in the api
          if (rowContent.rubricID) {
            removeRows.push({ rubricID: rowContent.rubricID, workSampleID: localSamplesForm.workSampleID })
          }
        }
      }

      // set the new rubric row configuration to the local form
      setLocalSamplesForm({ ...localSamplesForm, rubricRows: currentRows, localHistoryActions: tempHistory })
    }

    handleSubActionsPopClose()
  }

  // ---- Confirm Remove Sample popper related elements --- //
  const [confirmAnchorEl, setConfirmAnchorEl] = useState(null)

  // -- OPEN CONFIRM DELETE POPOVER
  const handleConfirmPopover = (e, sampObj) => {
    // set the selected sample to state
    setLocalSamplesForm({ ...sampObj })
    // open the popover
    setConfirmAnchorEl(e.currentTarget)
  }

  // -- CLOSE DELETE POPOVER
  const handleConfirmPopClose = () => {
    setConfirmAnchorEl(null)
    // reset the local form
    setLocalSamplesForm({ ...defaultSamplesForm })
  }

  const openConfirmPopover = Boolean(confirmAnchorEl)

  return (
    <Grid container direction='column'>
      {/* Header */}
      <Grid item container direction='row'>
        <Grid item xs={10} container direction='column'>
          <Grid item>
            <Typography variant='h5' style={{ lineHeight: 1, fontSize: 18 }}>Part 2</Typography>
          </Grid>
          <Grid item>
            <Typography variant='subtitle2'>Work Samples</Typography>
          </Grid>
        </Grid>
        <Grid item xs={2} container direction='column'>
          <Button
            variant='contained'
            color='primary'
            startIcon={<AddRounded />}
            style={{ margin: 'auto', fontWeight: 600 }}
            onClick={() => handleOpenSampleModal()}
          >
            Add Sample
          </Button>
        </Grid>
      </Grid>

      {/* Body */}
      <Grid item container direction='column' style={{ marginTop: '1em', padding: '20px 80px' }} spacing={5}>
        {!samples.length &&
          <Grid item container direction='column' justifyContent='center' alignItems='center' style={{ marginTop: '1em' }}>
            <Grid item>
              <Typography style={{ fontWeight: 600, color: theme.palette.grey.dark }}>No Work Samples Have Been Added.</Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: theme.palette.grey.dark }}>Use the button above to start adding samples.</Typography>
            </Grid>
          </Grid>}
        <Grid item container direction='column' spacing={5}>
          {samples.map((samp, wsi) => {
            const { workSampleID = '', title = '', sampleType = 'L', sampleDesc = '', rubricRows = [] } = samp
            return (
              <Grid key={`samp-${workSampleID}-${wsi}`} item container direction='column' style={{ border: `1px solid ${theme.palette.grey.medium}`, borderRadius: 7, marginBottom: wsi !== samples.length - 1 ? '2em' : 0 }}>
                <Grid item container direction='column' style={{ marginBottom: '1em' }}>
                  <Grid item container direction='row'>
                    <Typography variant='subtitle1' gutterBottom style={{ fontWeight: 600, color: theme.palette.grey.darker }}>{title || 'Sample Title'}</Typography>
                  </Grid>
                  <Grid item container direction='row' alignItems='flex-start'>
                    <Grid item xs={4} container direction='column'>
                      <Typography variant='caption' gutterBottom style={{ fontWeight: 600, color: theme.palette.grey.dark }}>Description</Typography>
                      <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(sampleDesc) }} className={classes.dangerHtmlSections} />

                    </Grid>
                    <Grid item xs={4} container direction='column' style={{ paddingLeft: '3em' }}>
                      <Grid item>
                        <Typography variant='caption' gutterBottom style={{ fontWeight: 600, color: theme.palette.grey.dark }}>Sample Type</Typography>
                      </Grid>
                      <Grid item>
                        <Chip label={sampleType === 'L' ? 'Learning Experience Design' : 'Upload'} size='small' style={{ backgroundColor: theme.palette.purple.darkest, color: 'white', fontWeight: 600 }} />
                      </Grid>
                    </Grid>
                    <Grid item xs={4} container direction='row' justifyContent='flex-end'>
                      <Grid item xs={7} />
                      <Grid item xs={3} container direction='column' alignItems='flex-end' style={{ paddingRight: '1.5em' }}>
                        <Typography variant='caption' style={{ fontWeight: 600 }}>Change Order:</Typography>
                        <Grid item container direction='row' justifyContent='flex-end'>
                          <IconButton
                            size='small'
                            disabled={wsi === samples.length - 1}
                            className={classes.questionOrderArrows}
                            onClick={() => handleSampleOrderChange(wsi, wsi + 1)}
                          >
                            <ArrowDownwardRounded fontSize='inherit' />
                          </IconButton>
                          <IconButton
                            size='small'
                            disabled={wsi === 0}
                            className={classes.questionOrderArrows}
                            onClick={() => handleSampleOrderChange(wsi, wsi - 1)}
                          >
                            <ArrowUpwardRounded fontSize='inherit' />
                          </IconButton>
                        </Grid>
                      </Grid>

                      <Grid item container direction='column' justifyContent='center' xs={1}>
                        <IconButton size='small' onClick={() => handleOpenSampleModal(samp)}>
                          <Avatar variant='rounded' style={{ backgroundColor: theme.palette.purple.darkest, height: 28, width: 28 }}>
                            <EditRounded style={{ color: 'white', fontSize: 'inherit' }} />
                          </Avatar>
                        </IconButton>
                      </Grid>
                      <Grid item container direction='column' justifyContent='center' xs={1}>
                        <IconButton size='small' onClick={(e) => handleConfirmPopover(e, samp)}>
                          <Avatar variant='rounded' style={{ backgroundColor: '#d80505', height: 28, width: 28 }}>
                            <DeleteForeverRounded style={{ color: 'white' }} />
                          </Avatar>
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container direction='column'>
                  <Grid item container direction='row'>
                    <Typography variant='subtitle2' gutterBottom style={{ fontWeight: 600, color: theme.palette.grey.darker }}>Grading Rubric</Typography>
                  </Grid>
                  <Grid item container direction='column'>
                    {!rubricRows.length &&
                      <Grid item container direction='column' justifyContent='center' alignItems='center'>
                        <Grid item>
                          <Typography variant='caption' style={{ fontWeight: 600, color: theme.palette.grey.medium }}>No Rubric Rows Have Been Added.</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant='caption' style={{ color: theme.palette.grey.medium }}>Use the edit button to begin adding a grading rubric.</Typography>
                        </Grid>
                      </Grid>}
                    {rubricRows.sort((a, b) => { return a.rubricOrder - b.rubricOrder }).map((row, ri) => {
                      const { rubricID, proficientText, basicText, developingText } = row

                      return (
                        <Grid key={`samp-${wsi}-ru-row-${rubricID}-${ri}`} item container direction='row' style={{ border: `1px solid ${theme.palette.grey.light}`, borderRadius: 7, padding: '.5em', marginBottom: '.5em' }}>
                          <Grid item xs={4} container direction='column' style={{ padding: '0 .5em' }}>
                            <Typography variant='caption' style={{ fontWeight: 600, color: theme.palette.grey.dark }}>Proficient Level</Typography>
                            <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(proficientText) }} className={classes.dangerHtmlRubricSections} />
                          </Grid>

                          <Grid item xs={4} container direction='column' style={{ padding: '0 .5em' }}>
                            <Typography variant='caption' style={{ fontWeight: 600, color: theme.palette.grey.dark }}>Basic Level</Typography>
                            <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(basicText) }} className={classes.dangerHtmlRubricSections} />
                          </Grid>

                          <Grid item xs={4} container direction='column' style={{ padding: '0 .5em' }}>
                            <Typography variant='caption' style={{ fontWeight: 600, color: theme.palette.grey.dark }}>Developing Level</Typography>
                            {developingText?.length
                              ? <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(developingText) }} className={classes.dangerHtmlRubricSections} />
                              : <Typography variant='caption' style={{ color: theme.palette.grey.dark }}>None</Typography>}
                          </Grid>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </Grid>

      {/* ADD/EDIT SAMPLE MODAL */}
      <Dialog
        open={sampleModalOpen}
        onClose={handleSampleClose}
        fullWidth
        maxWidth='md'
        disableEnforceFocus
        style={{ overflowX: 'hidden' }}
      >
        <DialogTitle>
          <Grid item container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid item xs={11} container direction='row' alignItems='center'>
              <Typography variant='h4' style={{ textTransform: 'none', marginRight: '1em', fontSize: 22 }}>Add Sample</Typography>
            </Grid>
            {/* Close Modal Trigger */}
            <Grid item container direction='row' xs={1} justifyContent='flex-end'>
              <IconButton size='small' onClick={handleSampleClose}>
                <CloseRounded />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent dividers>
          <Grid item container direction='column' spacing={3}>
            <Grid item container direction='row'>
              {/* Sample Title */}
              <Grid item xs={6} container direction='column'>
                <Typography variant='body1' style={{ fontWeight: 600 }} gutterBottom>Sample Title</Typography>
                <OutlinedInput
                  margin='dense'
                  fullWidth={false}
                  value={localSamplesForm?.title}
                  onChange={(e) => { setLocalSamplesForm({ ...localSamplesForm, title: e.target.value }) }}
                />
                {Boolean(localSamplesForm?.title.length && !localSamplesForm.title.trim().length) &&
                  <Typography variant='caption' style={{ color: 'red' }} gutterBottom>*Sample title cannot contain only white space.</Typography>}
              </Grid>
              {/* Sample Type */}
              <Grid item xs={6} container direction='column' style={{ paddingLeft: '2em' }}>
                <Typography variant='body1' style={{ fontWeight: 600 }} gutterBottom>Sample Type</Typography>
                <RadioGroup row onChange={(e) => setLocalSamplesForm({ ...localSamplesForm, sampleType: e.target.value })}>
                  <FormControlLabel label='Upload' labelPlacement='end' value={workSampleTypes.UPLOAD} checked={localSamplesForm?.sampleType === workSampleTypes.UPLOAD} control={<Radio />} />
                  <FormControlLabel label='Learning Experience Design' value={workSampleTypes.LED} checked={localSamplesForm?.sampleType === workSampleTypes.LED} labelPlacement='end' control={<Radio />} />
                </RadioGroup>
              </Grid>
            </Grid>
            {/* Sample Desciption */}
            <Grid item container direction='column'>
              <Typography gutterBottom variant='body1' style={{ fontWeight: 600 }}>Sample Description</Typography>
              <Editor
                {...scriptSrc}
                onInit={(evt, editor) => { sampleDescRef.current = editor }}
                value={localSamplesForm?.sampleDesc}
                init={{
                  height: 200,
                  ...editorInitDefaults
                }}
                onEditorChange={(value, editor) => { setLocalSamplesForm({ ...localSamplesForm, sampleDesc: value }) }}
              />
            </Grid>

            {/* Grading Rubric */}
            <Grid item container direction='column'>
              <Grid item container direction='row' style={{ marginBottom: '.5em' }}>
                <Typography gutterBottom variant='body1' style={{ fontWeight: 600 }}>Grading Rubric</Typography>
              </Grid>
              {/* Default text scenario when no rubric rows have been or are being added */}
              {!rubricEditorOpen && !localSamplesForm.rubricRows.length &&
                <Grid item container direction='column' justifyContent='center' alignItems='center' style={{ marginTop: '1em' }}>
                  <Grid item>
                    <Typography variant='caption' style={{ fontWeight: 600, color: theme.palette.grey.dark }}>No Rubric Rows Have Been Added.</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='caption' style={{ color: theme.palette.grey.dark }}>Use the button below to start adding a grading rubric.</Typography>
                  </Grid>
                </Grid>}

              {/* Map thorugh each rubric row (if exists) and display either a read only card with subaction menu, OR if editing, the builder rubric editor */}
              {localSamplesForm.rubricRows.sort((a, b) => { return a.rubricOrder - b.rubricOrder }).map((ruRow, i) => {
                const { rubricID, tempID, proficientText = '', basicText = '', developingText = '' } = ruRow
                const openEditor = Boolean(rubricEditorOpen && ((rubricID && rubricForm.rubricID === rubricID) || (tempID && rubricForm.tempID === tempID)))
                return (
                  openEditor
                    ? (
                      <BuilderRubricEditor
                        key={`ru-editor-${rubricID}-row-${i}`}
                        scriptSrc={scriptSrc}
                        editorInitDefaults={editorInitDefaults}
                        rubricForm={rubricForm}
                        setRubricForm={setRubricForm}
                        handleRubricEditorClose={handleRubricEditorClose}
                        handleSaveRubricChange={handleSaveRubricChange}
                        classes={classes}
                        disableSaveRubric={disableSaveRubric}
                      />
                    )
                    : (
                      <Grid key={`ru-${rubricID}-row-${i}`} item container direction='column' style={{ border: `1px solid ${theme.palette.grey.light}`, borderRadius: 7, padding: '.5em', marginBottom: '.5em' }}>
                        <Grid item container direction='row' justifyContent='flex-end'>
                          <IconButton size='small' onClick={(e) => handleSubActionsPopover(e, i)}>
                            <MoreVertRounded fontSize='inherit' />
                          </IconButton>
                        </Grid>
                        <Grid item container direction='row'>
                          <Grid item xs={4} container direction='column' style={{ padding: '0 .5em' }}>
                            <Typography variant='caption' style={{ fontWeight: 600, color: theme.palette.grey.dark }}>Proficient Level</Typography>
                            <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(proficientText) }} className={classes.dangerHtmlRubricSections} />
                          </Grid>

                          <Grid item xs={4} container direction='column' style={{ padding: '0 .5em' }}>
                            <Typography variant='caption' style={{ fontWeight: 600, color: theme.palette.grey.dark }}>Basic Level</Typography>
                            <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(basicText) }} className={classes.dangerHtmlRubricSections} />
                          </Grid>

                          <Grid item xs={4} container direction='column' style={{ padding: '0 .5em' }}>
                            <Typography variant='caption' style={{ fontWeight: 600, color: theme.palette.grey.dark }}>Developing Level</Typography>
                            {developingText?.length
                              ? <div dangerouslySetInnerHTML={{ __html: sanitizedHTML(developingText) }} className={classes.dangerHtmlRubricSections} />
                              : <Typography variant='caption' style={{ color: theme.palette.grey.dark }}>None</Typography>}
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                )
              })}

              {/* if creating a new row, open just the builder rubric editor under the current rows  */}
              {rubricEditorOpen && newRow &&
                <BuilderRubricEditor
                  scriptSrc={scriptSrc}
                  editorInitDefaults={editorInitDefaults}
                  rubricForm={rubricForm}
                  setRubricForm={setRubricForm}
                  handleRubricEditorClose={handleRubricEditorClose}
                  handleSaveRubricChange={handleSaveRubricChange}
                  classes={classes}
                  disableSaveRubric={disableSaveRubric}
                />}

              <Grid item container direction='row' justifyContent='flex-start' style={{ marginTop: '1em' }}>
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  style={{ margin: 'auto auto auto 0', fontWeight: 600 }}
                  onClick={() => handleRubricEditorOpen()}
                  disabled={rubricEditorOpen}
                >
                  Add Rubric Row
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions style={{ padding: '8px 24px' }}>
          <Grid item container direction='column'>
            <Grid item container justifyContent='flex-end'>
              {errorObj?.missingContent && <Typography gutterBottom variant='caption' style={{ color: 'red' }}>*Missing required information. Must have title, description, and a minimum of one rubric row.</Typography>}
              {errorObj?.generalError && <Typography gutterBottom variant='caption' style={{ color: 'red' }}>*Something went wrong, please close this modal and try again.</Typography>}
              {errorObj?.missingDevText && <Typography gutterBottom variant='caption' style={{ color: 'red' }}>*At least one rubric row must have a developing level.</Typography>}
              {errorObj?.whiteSpaceOnly && <Typography gutterBottom variant='caption' style={{ color: 'red' }}>*Values cannot contain only white space characters.</Typography>}
            </Grid>
            <Grid item container direction='row' justifyContent='space-between'>
              <Grid item>
                <Button variant='outlined' color='primary' onClick={handleSampleClose}>Cancel</Button>
              </Grid>
              <Grid item>
                <Button
                  variant='contained'
                  color='primary'
                  disabled={disableSave}
                  onClick={() => handleSampleModify(localSamplesForm)}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      {/* RUBRIC EDITOR SUB ACTIONS */}
      <Popover
        id={openSubActionsPopover ? 'subActions-popover' : undefined}
        open={openSubActionsPopover}
        anchorEl={subActionsAnchorEl}
        onClose={handleSubActionsPopClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <Grid container direction='row' style={{ padding: '.2em' }}>
          {/* move up */}
          <IconButton
            size='small'
            className={classes.rubricEditorSubActions}
            onClick={() => handleRubricSubAction(rubricSubActions.MOVE_UP)}
            disabled={editRubricIndexRef.current === 0}
          >
            <ArrowUpwardRounded fontSize='inherit' />
          </IconButton>
          {/* move down */}
          <IconButton
            size='small'
            className={classes.rubricEditorSubActions}
            onClick={() => handleRubricSubAction(rubricSubActions.MOVE_DOWN)}
            disabled={editRubricIndexRef.current === localSamplesForm.rubricRows.length - 1}
          >
            <ArrowDownwardRounded fontSize='inherit' />
          </IconButton>
          {/* edit */}
          <IconButton
            size='small'
            className={classes.rubricEditorSubActions}
            onClick={() => handleRubricSubAction(rubricSubActions.EDIT)}
          >
            <EditRounded fontSize='inherit' />
          </IconButton>
          {/* delete */}
          <IconButton
            size='small'
            className={classes.rubricEditorSubActions}
            onClick={() => handleRubricSubAction(rubricSubActions.DELETE)}
          >
            <DeleteForeverRounded fontSize='inherit' style={{ color: 'red' }} />
          </IconButton>
        </Grid>
      </Popover>

      {/* DELETE SAMPLE CONFIRMATION POPOVER */}
      <Popover
        id={openConfirmPopover ? 'confirm-popover' : undefined}
        open={openConfirmPopover}
        anchorEl={confirmAnchorEl}
        onClose={handleConfirmPopClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <Grid container direction='column' style={{ padding: '1em' }}>
          <Typography
            variant='h4'
            style={{ color: theme.palette.purple.darkest }}
            gutterBottom
          >
            Are you sure you wish to delete this Work Sample?
          </Typography>

          <Typography variant='body1' gutterBottom>This cannot be undone.</Typography>

          <Grid item container direction='row' justifyContent='flex-end' alignItems='center' style={{ marginTop: '.5em' }}>
            <Button onClick={handleConfirmPopClose} style={{ marginRight: '.3em', textTransform: 'none', fontWeight: '600' }}>Cancel</Button>
            <Button variant='contained' style={{ backgroundColor: '#d80505', fontWeight: 600 }} onClick={(e) => { e.preventDefault(); handleSampleModify(localSamplesForm, true); handleConfirmPopClose() }}>Confirm</Button>
          </Grid>
        </Grid>
      </Popover>
    </Grid>
  )
}

export default BuilderSampleSection
