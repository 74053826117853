import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  Grid, Typography, Paper, Divider, OutlinedInput, Button, Link
} from '@material-ui/core'
import AddCommentIcon from '@material-ui/icons/AddComment'
import theme from '../styles/MuiTheme'

import { handleExternalLinkClick } from '../../../utils'
import { ArtifactComment, NotificationToast } from '../tools'
import { getArtifactDetails, postAdminArtifactComment } from '../../../redux/actions'

const ModifiedLEDIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 68.782 55.026'>
      <path data-name='chalkboard-solid' d='M10.317,6.878H58.465v37.83h6.878V4.3a4.3,4.3,0,0,0-4.3-4.3H7.738a4.3,4.3,0,0,0-4.3,4.3v40.41h6.878ZM67.063,48.148H51.587V41.269H30.952v6.878H1.72A1.719,1.719,0,0,0,0,49.867v3.439a1.719,1.719,0,0,0,1.72,1.72H67.063a1.719,1.719,0,0,0,1.72-1.72V49.867A1.719,1.719,0,0,0,67.063,48.148Z' fill={theme.palette.purple.darkest} />
    </svg>
  )
}

const ReviewLED = (props) => {
  const { classes, artifactType, styles, reviewInfo, formattedInfo, setFormattedInfo } = props
  const dispatch = useDispatch()

  // ******************************* Data Formatting ******************************* //

  // Redux Items needed to translate the ID's provided in the artifact information into readable names
  const { grades = [], subjects = [] } = useSelector(state => state.profileData)
  const standards = useSelector(state => state.artifacts.standards)
  const { learnerWorkList } = useSelector(state => state.artifacts)

  // Formats the information returned from the reviewInfo into readable text
  useEffect(() => {
    if (reviewInfo) {
      const tempCopy = Object.assign({}, reviewInfo)

      // Targets
      if (tempCopy && tempCopy.targets) {
        tempCopy.targets = tempCopy.targets.reduce((arr, targetInfo) => {
          if (targetInfo && targetInfo.targetID && targetInfo.targetName) {
            const { targetID, targetName } = targetInfo
            arr.push({ targetID, targetName })
          }
          return arr
        }, [])
      }

      // Links
      if (tempCopy && tempCopy.links) {
        tempCopy.links = tempCopy.links.reduce((arr, linkInfo) => {
          if (linkInfo && linkInfo.link && linkInfo.linkID) {
            const { link, linkID } = linkInfo
            arr.push({ link, linkID })
          }
          return arr
        }, [])
      }

      // Attached Documents
      if (tempCopy && tempCopy.attachments) {
        tempCopy.attachments = tempCopy.attachments.reduce((arr, attachmentInfo) => {
          if (attachmentInfo && attachmentInfo.attachmentID && attachmentInfo.fileName) {
            const { attachmentID = '', file = '', fileName = '', filePath = '', fileType = '' } = attachmentInfo
            arr.push({ attachmentID, file, fileName, filePath, fileType })
          }
          return arr
        }, [])
      }

      // Grades
      if (tempCopy && tempCopy.grades && tempCopy.grades.length) {
        tempCopy.grades = tempCopy.grades.reduce((arr, gradeID) => {
          if (grades && grades.length) {
            const index = grades.findIndex(x => x.gradeID === gradeID)
            if (index !== -1) {
              const gradeName = grades[index].gradeName
              arr.push(gradeName)
            }
          }
          return arr
        }, [])
      }

      // ISTE Standards
      if (tempCopy && tempCopy.ISTEStandards && tempCopy.ISTEStandards.length) {
        tempCopy.ISTEStandards = tempCopy.ISTEStandards.reduce((arr, standardID) => {
          if (standards && standards.length) {
            const index = standards.findIndex(x => x.standardID === standardID)
            if (index !== -1) {
              const standardName = standards[index].standardName
              arr.push(standardName)
            }
          }
          return arr
        }, [])
      }

      // Core Subject
      if (tempCopy && tempCopy.coreSubjectID) {
        if (subjects && subjects.length) {
          const index = subjects.findIndex(x => x.subjectID === tempCopy.coreSubjectID)
          if (index !== -1) {
            tempCopy.coreSubject = subjects[index].subjectName
          }
        }
      }

      // Secondary Subject
      if (tempCopy && tempCopy.secondarySubjectID) {
        if (subjects && subjects.length) {
          const index = subjects.findIndex(x => x.subjectID === tempCopy.secondarySubjectID)
          if (index !== -1) {
            tempCopy.secondarySubject = subjects[index].subjectName
          }
        }
      }

      // Asscociated LW
      if (tempCopy && tempCopy.learnerWorkID) {
        if (learnerWorkList && learnerWorkList.length) {
          const index = learnerWorkList.findIndex(x => x.learnerWorkID === tempCopy.learnerWorkID)
          if (index !== -1) {
            tempCopy.learnerWorkTitle = learnerWorkList[index].learnerWorkTitle
          }
        }
      }

      setFormattedInfo({ ...tempCopy })
    }
  }, [reviewInfo, artifactType, grades, standards, subjects, learnerWorkList, setFormattedInfo])

  // ************************ Comment Logic ****************** //

  // Controls the open/close state for individual sections
  const defaultCommentState = {
    details: false,
    led1: false,
    led2: false,
    lw: false,
    resources: false
  }

  const [commentState, setCommentState] = useState(defaultCommentState)

  const fireSuccess = async () => {
    await dispatch(getArtifactDetails(formattedInfo.artifactID, '', 'Learning Experience Design', () => {}, () => {}, ''))
  }

  // *********************** Notes Logic ******************** //

  const [noteValue, setNoteValue] = useState('')

  // Add Note
  const handleAddNote = (artifactID) => {
    const tempCommentInfo = {
      comment: noteValue,
      artifactID,
      artifactSection: 'notes'
    }

    dispatch(postAdminArtifactComment(tempCommentInfo, fireSuccess, fireAddNoteFailure))
  }

  const fireAddNoteFailure = () => {
    NotificationToast(true, 'Falied to Create Note')
  }

  return (
    <Paper className={classes.paperRoot}>
      <Grid container direction='column'>
        {/* ----------------- Review Header ------------------ */}
        <Grid container item direction='row' spacing={1} style={{ marginBottom: '1em' }}>
          <Grid item>
            <ModifiedLEDIcon />
          </Grid>
          <Grid item>
            <Typography variant='h4'>{artifactType}</Typography>
          </Grid>
        </Grid>

        <Grid container item direction='column' style={{ marginTop: '.5em', paddingRight: '2em' }}>

          {/* ----------------------- Notes Input ----------------------- */}
          <Grid item container direction='column' style={{ marginBottom: '2em', width: '104%' }}>
            <Grid item>
              <Typography variant='h6' style={{ color: theme.palette.black, fontWeight: 600, textTransform: 'none' }}>Notes</Typography>
            </Grid>
            <Grid item style={{ width: '100%', marginTop: '.5em' }}>
              <OutlinedInput
                variant='outlined'
                value={noteValue}
                fullWidth
                margin='dense'
                placeholder='Enter a new note for the teacher...'
                classes={{ input: classes.inputPlaceholder }}
                onChange={(e) => setNoteValue(e.target.value)}
              />
            </Grid>
            {/* Only Display the save button after the user has begun typing */}
            {noteValue && noteValue.length > 0 &&
              <Grid item container justifyContent='flex-end' style={{ marginTop: '.3em' }}>
                <Button
                  variant='contained'
                  style={{ backgroundColor: styles.mainColor }}
                  onClick={() => handleAddNote(formattedInfo.artifactID)}
                >
                  Save
                </Button>
              </Grid>}
            {formattedInfo.commentList && formattedInfo.commentList.length !== 0 &&
              formattedInfo.commentList.map(comment => {
                const { section } = comment
                if (section === 'notes') {
                  return (
                    <Grid key={comment.commentID} container item style={{ marginBottom: '.5em', marginTop: '1.5em' }}>
                      <ArtifactComment
                        commentState={commentState}
                        setCommentState={setCommentState}
                        styles={styles}
                        classes={classes}
                        section='notes'
                        artifactID={formattedInfo.artifactID}
                        commentInfo={comment}
                        fireSuccess={fireSuccess}
                      />
                    </Grid>
                  )
                }
                return ''
              })}

          </Grid>

          {/* ------------------------- Artifact Details ------------------------- */}
          <Paper elevation={0} style={{ backgroundColor: theme.palette.grey.lighter, padding: '1em', width: '100%' }}>
            <Grid container item direction='row'>
              {/* Left Side Column */}
              <Grid item container direction='column' xs={3} sm={2}>
                <Grid item>
                  <Typography variant='h5' style={{ fontWeight: '600' }}>Artifact Details</Typography>
                </Grid>
              </Grid>

              <Divider flexItem variant='middle' orientation='vertical' />

              {/* Right Side Column */}
              <Grid item container direction='column' xs={8} sm={9} spacing={3}>

                {/* Name */}
                <Grid item container direction='column'>
                  <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>* What's the name of your artifact?</Typography>
                  <Typography
                    variant='body1'
                    style={{
                      color: theme.palette.black,
                      fontWeight: '600',
                      fontSize: '13px',
                      wordBreak: 'break-word'
                    }}
                  >
                    {formattedInfo && formattedInfo.title ? formattedInfo.title : 'No Information available'}
                  </Typography>
                </Grid>

                {/* Grades */}
                <Grid item container direction='column'>
                  <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>* Grade(s)?</Typography>
                  <Typography variant='body1' style={{ color: theme.palette.black, fontWeight: '600', fontSize: '13px' }}>
                    {formattedInfo && formattedInfo.grades && formattedInfo.grades.length ? formattedInfo.grades.join(', ') : 'No information available'}
                  </Typography>
                </Grid>

                {/* Completion Time */}
                <Grid item container direction='column'>
                  <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>* Completion Time</Typography>
                  <Typography
                    variant='body1'
                    style={{
                      color: theme.palette.black,
                      fontWeight: '600',
                      fontSize: '13px'
                    }}
                  >
                    {formattedInfo && formattedInfo.completionTime ? `${formattedInfo.completionTime} week(s)` : 'No information available.'}
                  </Typography>
                </Grid>

                {/* Type of Work */}
                <Grid item container direction='column'>
                  <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>* Type of Work</Typography>
                  <Typography
                    variant='body1'
                    style={{
                      color: theme.palette.black,
                      fontWeight: '600',
                      fontSize: '13px',
                      textTransform: 'capitalize'
                    }}
                  >
                    {formattedInfo && formattedInfo.workType ? formattedInfo.workType === 'both' ? 'Individual & Group' : formattedInfo.workType : 'No information available.'}
                  </Typography>
                </Grid>

                {/* Technology Needed */}
                <Grid item container direction='column'>
                  <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>* Technology Needed</Typography>
                  <Typography
                    variant='body1'
                    style={{ color: theme.palette.black, fontWeight: '600', fontSize: '13px', wordBreak: 'break-word' }}
                  >
                    {formattedInfo && formattedInfo.techNeeded ? formattedInfo.techNeeded : 'No information available.'}
                  </Typography>
                </Grid>

                {/* Core Subject */}
                <Grid item container direction='column'>
                  <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>* Core Subject</Typography>
                  <Typography variant='body1' style={{ color: theme.palette.black, fontWeight: '600', fontSize: '13px' }}>
                    {formattedInfo && formattedInfo.coreSubject ? formattedInfo.coreSubject : 'No information available.'}
                  </Typography>
                </Grid>

                {/* Secondary Subject */}
                <Grid item container direction='column'>
                  <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>Secondary Subject</Typography>
                  <Typography variant='body1' style={{ color: theme.palette.black, fontWeight: '600', fontSize: '13px' }}>
                    {formattedInfo && formattedInfo.secondarySubject ? formattedInfo.secondarySubject : 'N/A'}
                  </Typography>
                </Grid>

                {/* ISTE Standards */}
                <Grid item container direction='column'>
                  <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>ISTE Standards</Typography>
                  <Typography variant='body1' style={{ color: theme.palette.black, fontWeight: '600', fontSize: '13px' }}>
                    {formattedInfo && formattedInfo.ISTEStandards && formattedInfo.ISTEStandards.length ? formattedInfo.ISTEStandards.join(', ') : 'No information available'}
                  </Typography>
                </Grid>

                {/* Comments */}
                <Grid item container direction='column' alignContent='flex-start' style={{ marginTop: '1em', marginBottom: '.5em' }}>
                  {formattedInfo.commentList && formattedInfo.commentList.length !== 0 &&
                    formattedInfo.commentList.map(comment => {
                      const { section } = comment
                      if (section === 'details') {
                        return (
                          <Grid key={comment.commentID} container item style={{ marginBottom: '.5em' }}>
                            <ArtifactComment
                              commentState={commentState}
                              setCommentState={setCommentState}
                              styles={styles}
                              classes={classes}
                              section='details'
                              artifactID={formattedInfo.artifactID}
                              commentInfo={comment}
                              fireSuccess={fireSuccess}
                            />
                          </Grid>
                        )
                      }
                      return ''
                    })}
                  {commentState && commentState.details
                    ? (
                      <ArtifactComment
                        commentState={commentState}
                        setCommentState={setCommentState}
                        styles={styles}
                        classes={classes}
                        artifactID={formattedInfo.artifactID}
                        section='details'
                        fireSuccess={fireSuccess}
                      />
                    )
                    : (
                      <Grid container item justifyContent='flex-start' style={{ marginTop: '.5em' }}>
                        <Button
                          variant='contained'
                          style={{ margin: 'auto', marginLeft: 0, fontWeight: '600', backgroundColor: styles.mainColor }}
                          startIcon={
                            <AddCommentIcon />
                          }
                          onClick={() => setCommentState({ ...commentState, details: true })}
                        >
                          Add Comment
                        </Button>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            </Grid>
          </Paper>

          {/* **************** LED 1 ***************** */}
          <Paper elevation={0} style={{ backgroundColor: theme.palette.grey.lighter, padding: '1em', width: '100%', marginTop: '1em' }}>
            <Grid container item direction='row'>
              {/* Left Side Column */}
              <Grid item container direction='column' xs={3} sm={2}>
                <Grid item>
                  <Typography variant='h5' style={{ fontWeight: '600' }}>Learning Experience Design I</Typography>
                </Grid>
              </Grid>

              <Divider flexItem variant='middle' orientation='vertical' />

              {/* Right Column */}
              <Grid item container direction='column' xs={8} sm={9} spacing={3}>
                {/* Overview */}
                <Grid item container direction='column'>
                  <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>* Overview + Purpose</Typography>
                  <Typography variant='body1' style={{ color: theme.palette.black, fontWeight: '600', fontSize: '13px', wordBreak: 'break-word' }}>{formattedInfo && formattedInfo.overview ? formattedInfo.overview : 'No information available.'}</Typography>
                </Grid>

                {/* Learning Targets */}
                <Grid item container direction='column'>
                  <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>* Learning Targets</Typography>
                  {formattedInfo && formattedInfo.targets && formattedInfo.targets.length
                    ? (
                      formattedInfo.targets.map((target) => {
                        const { targetID = '', targetName = '' } = target
                        return (
                          <Typography key={`target-${targetID}`} variant='body1' style={{ color: theme.palette.black, fontWeight: '600', fontSize: '13px', wordBreak: 'break-word' }}>{`I can ${targetName}`}</Typography>
                        )
                      })
                    )
                    : (
                      <Typography variant='body1' style={{ color: theme.palette.black, fontWeight: '600', fontSize: '13px' }}>No information available.</Typography>
                    )}
                </Grid>

                {/* State Standards */}
                <Grid item container direction='column'>
                  <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>State Standards</Typography>
                  <Typography variant='body1' style={{ color: theme.palette.black, fontWeight: '600', fontSize: '13px', wordBreak: 'break-word' }}>{formattedInfo && formattedInfo.stateStandards ? formattedInfo.stateStandards : 'N/A'}</Typography>
                </Grid>

                {/* Comments */}
                <Grid item container direction='column' alignContent='flex-start' style={{ marginTop: '1em', marginBottom: '.5em' }}>
                  {formattedInfo.commentList && formattedInfo.commentList.length !== 0 &&
                      formattedInfo.commentList.map(comment => {
                        const { section } = comment
                        if (section === 'led1') {
                          return (
                            <Grid key={comment.commentID} container item style={{ marginBottom: '.5em' }}>
                              <ArtifactComment
                                key={comment.commentID}
                                artifactID={formattedInfo.artifactID}
                                commentState={commentState}
                                setCommentState={setCommentState}
                                styles={styles}
                                classes={classes}
                                section='led1'
                                commentInfo={comment}
                                fireSuccess={fireSuccess}
                              />
                            </Grid>
                          )
                        }
                        return ''
                      })}
                  {commentState && commentState.led1
                    ? (
                      <ArtifactComment
                        artifactID={formattedInfo.artifactID}
                        commentState={commentState}
                        setCommentState={setCommentState}
                        styles={styles}
                        classes={classes}
                        section='led1'
                        fireSuccess={fireSuccess}
                      />
                    )
                    : (
                      <Grid container item justifyContent='flex-start' style={{ marginTop: '.5em' }}>
                        <Button
                          variant='contained'
                          style={{ margin: 'auto', marginLeft: 0, fontWeight: '600', backgroundColor: styles.mainColor }}
                          startIcon={
                            <AddCommentIcon />
                          }
                          onClick={() => setCommentState({ ...commentState, led1: true })}
                        >
                          Add Comment
                        </Button>
                      </Grid>
                    )}
                </Grid>

              </Grid>
            </Grid>
          </Paper>

          {/* **************** LED 2 ***************** */}
          <Paper elevation={0} style={{ backgroundColor: theme.palette.grey.lighter, padding: '1em', width: '100%', marginTop: '1em' }}>
            <Grid container item direction='row'>
              {/* Left Side Column */}
              <Grid item container direction='column' xs={3} sm={2}>
                <Grid item>
                  <Typography variant='h5' style={{ fontWeight: '600' }}>Learning Experience Design II</Typography>
                </Grid>
              </Grid>

              <Divider flexItem variant='middle' orientation='vertical' />

              {/* Right Side Column */}
              <Grid item container direction='column' xs={8} sm={9} spacing={3}>
                {/* Engage */}
                <Grid item container direction='column'>
                  <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>* Engage</Typography>
                  <Typography
                    variant='body1'
                    style={{ color: theme.palette.black, fontWeight: '600', fontSize: '13px', wordBreak: 'break-word' }}
                  >
                    {formattedInfo && formattedInfo.engageContent ? formattedInfo.engageContent : 'No information available.'}
                  </Typography>
                </Grid>

                {/* Investigate */}
                <Grid item container direction='column'>
                  <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>* Investigate</Typography>
                  <Typography
                    variant='body1'
                    style={{ color: theme.palette.black, fontWeight: '600', fontSize: '13px', wordBreak: 'break-word' }}
                  >
                    {formattedInfo && formattedInfo.investigateContent ? formattedInfo.investigateContent : 'No information available.'}
                  </Typography>
                </Grid>

                {/* Create */}
                <Grid item container direction='column'>
                  <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>* Create</Typography>
                  <Typography
                    variant='body1'
                    style={{ color: theme.palette.black, fontWeight: '600', fontSize: '13px', wordBreak: 'break-word' }}
                  >
                    {formattedInfo && formattedInfo.createContent ? formattedInfo.createContent : 'No information available.'}
                  </Typography>
                </Grid>

                {/* Act */}
                <Grid item container direction='column'>
                  <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>Act</Typography>
                  <Typography
                    variant='body1'
                    style={{ color: theme.palette.black, fontWeight: '600', fontSize: '13px', wordBreak: 'break-word' }}
                  >
                    {formattedInfo ? formattedInfo.actContent ? formattedInfo.actContent : 'N/A' : 'N/A'}
                  </Typography>
                </Grid>

                {/* Comments */}
                <Grid item container direction='column' alignContent='flex-start' style={{ marginTop: '1em', marginBottom: '.5em' }}>
                  {formattedInfo.commentList && formattedInfo.commentList.length !== 0 &&
                      formattedInfo.commentList.map(comment => {
                        const { section } = comment
                        if (section === 'led2') {
                          return (
                            <Grid key={comment.commentID} container item style={{ marginBottom: '.5em' }}>
                              <ArtifactComment
                                key={comment.commentID}
                                artifactID={formattedInfo.artifactID}
                                commentState={commentState}
                                setCommentState={setCommentState}
                                styles={styles}
                                classes={classes}
                                section='led2'
                                commentInfo={comment}
                                fireSuccess={fireSuccess}
                              />
                            </Grid>
                          )
                        }
                        return ''
                      })}
                  {commentState && commentState.led2
                    ? (
                      <ArtifactComment
                        artifactID={formattedInfo.artifactID}
                        commentState={commentState}
                        setCommentState={setCommentState}
                        styles={styles}
                        classes={classes}
                        section='led2'
                        fireSuccess={fireSuccess}
                      />
                    )
                    : (
                      <Grid container item justifyContent='flex-start' style={{ marginTop: '.5em' }}>
                        <Button
                          variant='contained'
                          style={{ margin: 'auto', marginLeft: 0, fontWeight: '600', backgroundColor: styles.mainColor }}
                          startIcon={
                            <AddCommentIcon />
                          }
                          onClick={() => setCommentState({ ...commentState, led2: true })}
                        >
                          Add Comment
                        </Button>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            </Grid>
          </Paper>

          {/* **************** Learner Work ***************** */}
          <Paper elevation={0} style={{ backgroundColor: theme.palette.grey.lighter, padding: '1em', width: '100%', marginTop: '1em' }}>
            <Grid container item direction='row'>
              {/* Left Side Column */}
              <Grid item container direction='column' xs={3} sm={2}>
                <Grid item>
                  <Typography variant='h5' style={{ fontWeight: '600' }}>Learner Work</Typography>
                </Grid>
              </Grid>

              <Divider flexItem variant='middle' orientation='vertical' />

              {/* Right Side Column */}
              <Grid item container direction='column' xs={8} sm={9} spacing={3}>
                {/* LW artifact */}
                <Grid item container direction='column'>
                  <Typography variant='body1' style={{ color: theme.palette.black, fontWeight: '600', fontSize: '13px' }}>
                    {formattedInfo && formattedInfo.learnerWorkTitle ? formattedInfo.learnerWorkTitle : 'N/A'}
                  </Typography>
                </Grid>

                {/* Comments */}
                <Grid item container direction='column' alignContent='flex-start' style={{ marginTop: '1em', marginBottom: '.5em' }}>
                  {formattedInfo.commentList && formattedInfo.commentList.length !== 0 &&
                      formattedInfo.commentList.map(comment => {
                        const { section } = comment
                        if (section === 'lw') {
                          return (
                            <Grid key={comment.commentID} container item style={{ marginBottom: '.5em' }}>
                              <ArtifactComment
                                key={comment.commentID}
                                artifactID={formattedInfo.artifactID}
                                commentState={commentState}
                                setCommentState={setCommentState}
                                styles={styles}
                                classes={classes}
                                section='lw'
                                commentInfo={comment}
                                fireSuccess={fireSuccess}
                              />
                            </Grid>
                          )
                        }
                        return ''
                      })}
                  {commentState && commentState.lw
                    ? (
                      <ArtifactComment
                        artifactID={formattedInfo.artifactID}
                        commentState={commentState}
                        setCommentState={setCommentState}
                        styles={styles}
                        classes={classes}
                        section='lw'
                        fireSuccess={fireSuccess}
                      />
                    )
                    : (
                      <Grid container item justifyContent='flex-start' style={{ marginTop: '.5em' }}>
                        <Button
                          variant='contained'
                          style={{ margin: 'auto', marginLeft: 0, fontWeight: '600', backgroundColor: styles.mainColor }}
                          startIcon={
                            <AddCommentIcon />
                          }
                          onClick={() => setCommentState({ ...commentState, lw: true })}
                        >
                          Add Comment
                        </Button>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            </Grid>
          </Paper>

          {/* **************** Resources ***************** */}
          <Paper elevation={0} style={{ backgroundColor: theme.palette.grey.lighter, padding: '1em', width: '100%', marginTop: '1em' }}>
            <Grid container item direction='row'>
              {/* Left Side Column */}
              <Grid item container direction='column' xs={3} sm={2}>
                <Grid item>
                  <Typography variant='h5' style={{ fontWeight: '600' }}>Resources</Typography>
                </Grid>
              </Grid>

              <Divider flexItem variant='middle' orientation='vertical' />

              {/* Right Side Column */}
              <Grid item container direction='column' xs={8} sm={9} spacing={3}>
                {/* Documents */}
                <Grid item container direction='column'>
                  <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>Documents</Typography>
                  {formattedInfo && formattedInfo.attachments && formattedInfo.attachments.length !== 0
                    ? (
                      formattedInfo.attachments.map((attachment) => {
                        return (
                          <Typography
                            key={`attachment-${attachment.attachmentID}`}
                            variant='body1'
                            style={{ color: theme.palette.purple.darkest, fontWeight: '600', fontSize: '13px', textDecoration: 'underline', wordWrap: 'break-word' }}
                          >
                            <Link href={attachment.filePath} target='_blank' rel='noopener'>{attachment.fileName}</Link>
                          </Typography>
                        )
                      })

                    )
                    : (
                      <Typography variant='body1' style={{ color: theme.palette.black, fontWeight: '600', fontSize: '13px' }}>N/A</Typography>
                    )}
                </Grid>

                {/* Links */}
                <Grid item container direction='column'>
                  <Typography variant='body1' style={{ color: theme.palette.grey.dark, fontWeight: '600' }}>Links</Typography>
                  {formattedInfo && formattedInfo.links && formattedInfo.links.length !== 0
                    ? (
                      formattedInfo.links.map((linkInfo) => {
                        const { linkID, link: linkName } = linkInfo
                        return (
                          <Typography
                            key={`link-${linkID}`}
                            variant='body1'
                            onClick={() => handleExternalLinkClick(linkName)}
                            style={{
                              color: theme.palette.purple.darkest,
                              fontWeight: '600',
                              fontSize: '13px',
                              textDecoration: 'underline',
                              cursor: 'pointer'
                            }}
                          >
                            {linkName}
                          </Typography>
                        )
                      })

                    )
                    : (
                      <Typography variant='body1' style={{ color: theme.palette.black, fontWeight: '600', fontSize: '13px' }}>N/A</Typography>
                    )}
                </Grid>

                {/* Comments */}
                <Grid item container direction='column' alignContent='flex-start' style={{ marginTop: '1em', marginBottom: '.5em' }}>
                  {formattedInfo.commentList && formattedInfo.commentList.length !== 0 &&
                      formattedInfo.commentList.map(comment => {
                        const { section } = comment
                        if (section === 'resources') {
                          return (
                            <Grid key={comment.commentID} container item style={{ marginBottom: '.5em' }}>
                              <ArtifactComment
                                key={comment.commentID}
                                artifactID={formattedInfo.artifactID}
                                commentState={commentState}
                                setCommentState={setCommentState}
                                styles={styles}
                                classes={classes}
                                section='resources'
                                commentInfo={comment}
                                fireSuccess={fireSuccess}
                              />
                            </Grid>
                          )
                        }
                        return ''
                      })}
                  {commentState && commentState.resources
                    ? (
                      <ArtifactComment
                        artifactID={formattedInfo.artifactID}
                        commentState={commentState}
                        setCommentState={setCommentState}
                        styles={styles}
                        classes={classes}
                        section='resources'
                        fireSuccess={fireSuccess}
                      />
                    )
                    : (
                      <Grid container item justifyContent='flex-start' style={{ marginTop: '.5em' }}>
                        <Button
                          variant='contained'
                          style={{ margin: 'auto', marginLeft: 0, fontWeight: '600', backgroundColor: styles.mainColor }}
                          startIcon={
                            <AddCommentIcon />
                          }
                          onClick={() => setCommentState({ ...commentState, resources: true })}
                        >
                          Add Comment
                        </Button>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            </Grid>
          </Paper>

        </Grid>
      </Grid>
    </Paper>
  )
}

export default ReviewLED
