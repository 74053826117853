import {
  GET_ADMIN_ARTIFACTS, GET_ADMIN_COHORTS, GET_ADMIN_FELLOWS,
  GET_ADMIN_SIGNUPS, GET_ADMIN_SCHOOLS, GET_ADMIN_SUBJECTS, GET_ADMIN_CERTS, SET_REVIEW_ARTIFACT,
  GET_ADMIN_USER_LIST, GET_ADMIN_EF_RESOURCES, UPDATE_REVIEW_ARTIFACT, GET_REVIEW_ARTIFACT_STATUS,
  GET_FEATURED_LED_OPTIONS,
  GET_ADMIN_DISTRICTS, UPDATE_BANNER_IMAGE, GET_ADMIN_ASSESSORS,
  GET_ADMIN_EVALUATIONS, GET_ADMIN_MCES
} from '../types'
import { trackPromise } from 'react-promise-tracker'
import { parseResponse, responseTypes } from '../../lib'
import { gradeCategories, efrSubTypes, featuredLEDActions } from '../../utils/variables'
import { reduxReducer } from '../reducers'
import moment from 'moment'

const api = process.env.REACT_APP_API_URL
const apiversion = process.env.REACT_APP_API_VERSION
const cloudfrontPath = process.env.REACT_APP_CLOUD_FRONT_BASE

const adminFilters = {
  FELLOWS: 'fellows',
  TEACHER_FELLOWS: 'teacher-fellows',
  INNOVATION_FELLOWS: 'innovation-fellows',
  SIGN_UP: 'sign-up',
  ARTIFACTS: 'artifacts',
  COHORTS: 'cohorts',
  SCHOOLS: 'schools',
  ADMIN_USERS: 'admin-user'
}

// ************************************** TEACHER FELLOWS **************************************** //

/**
 * Function to get teacher fellows with searching and sorting
 * @param {Object} filter - Object with filter data for teacher fellows
 * @param {number} [filter.page] - Pagination number of current search results
 * @param {number} [filter.sortCount] - Number of results to be displayed
 * @param {number} [filter.sortDirection] - Direction of sorting {DESC | ASC}
 * @param {string} [filter.sortType] - Column to be sorted by {name | schoolName | districtName, cohortName}
 * @param {string} [filter.searchPhrase] - Phrase to be searched for in results
 * @param {requestSuccess} fireSuccess - callback on success
 * @param {requestFailure} fireFailure - callback on failure
 */
export const getTeacherFellows = (filter = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    const { token = '', userID = '' } = getState().auth

    const fellowFilter = { ...filter }
    fellowFilter.role = adminFilters.TEACHER_FELLOWS
    const { page = 1, sortCount = 10 } = fellowFilter
    fellowFilter.offset = (page - 1) * sortCount
    fellowFilter.sortDirection = !filter.sortDirection ? 'DESC' : filter.sortDirection

    const fellowData = await processFilter(dispatch, token, userID, adminFilters.FELLOWS, fellowFilter)

    if (fellowData) {
      const { fellows: teacherFellows = [], totalFellows = null } = fellowData
      dispatch({ type: GET_ADMIN_FELLOWS, payload: { teacherFellows, totalFellows } })
      fireSuccess()
    } else {
      fireFailure()
    }
  }
}

/**
 * Function for updating teacher fellow school and cohort
 * @param {Object} configInfo - Object for updating teacher fellow
 * @param {string} configInfo.fellowID - ID of teacher fellow to update
 * @param {string} configInfo.schoolID - ID of new school for teacher fellow
 * @param {string} configInfo.cohortID - ID of new cohort for teacher fellow
 * @param {requestSuccess} fireSuccess - callback on success
 * @param {requestFailure} fireFailure - callback on failure
 */
export const updateFellowConfig = (configInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '', userID = '' } = getState().auth

      const body = JSON.stringify({ ...configInfo, userID })

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/fellows`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        fireSuccess(configInfo.fellowID)
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

// ************************************** INNOVATION FELLOWS **************************************** //

/**
 * Function to get innovation fellows with searching and sorting
 * @param {Object} filter - Object with filter data for innovation fellows
 * @param {number} [filter.page] - Pagination number of current search results
 * @param {number} [filter.sortCount] - Number of results to be displayed
 * @param {number} [filter.sortDirection] - Direction of sorting {DESC | ASC}
 * @param {string} [filter.sortType] - Column to be sorted by {name | schoolName | districtName, cohortName}
 * @param {string} [filter.searchPhrase] - Phrase to be searched for in results
 * @param {requestSuccess} fireSuccess - callback on success
 * @param {requestFailure} fireFailure - callback on failure
 */
export const getInnovationFellows = (filter = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    const { token = '', userID = '' } = getState().auth

    const fellowFilter = { ...filter }
    fellowFilter.role = adminFilters.INNOVATION_FELLOWS
    const { page = 1, sortCount = 10 } = fellowFilter
    fellowFilter.offset = (page - 1) * sortCount
    fellowFilter.sortDirection = !filter.sortDirection ? 'DESC' : filter.sortDirection

    const fellowData = await processFilter(dispatch, token, userID, adminFilters.FELLOWS, fellowFilter)
    if (fellowData) {
      const { fellows: innovationFellows = [], totalFellows: totalIfFellows = null } = fellowData
      dispatch({ type: GET_ADMIN_FELLOWS, payload: { innovationFellows, totalIfFellows } })
      fireSuccess()
    } else {
      fireFailure()
    }
  }
}

// ************************************ SIGN UPS/APPROVE/DENY ************************************ //

/**
 * Function to get sign ups with searching and sorting
 * @param {Object} filter - Object with filter data for sign ups
 * @param {number} [filter.page] - Pagination number of current search results
 * @param {number} [filter.sortCount] - Number of results to be displayed
 * @param {number} [filter.sortDirection] - Direction of sorting {DESC | ASC}
 * @param {string} [filter.sortType] - Column to be sorted by {name | emailAddress | pendingSince}
 * @param {string} [filter.searchPhrase] - Phrase to be searched for in results
 * @param {requestSuccess} fireSuccess - callback on success
 * @param {requestFailure} fireFailure - callback on failure
 */
export const getSignUps = (filter = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    const { token = '', userID = '' } = getState().auth

    const signFilter = { ...filter }
    const { page = 1, sortCount = 10 } = signFilter
    signFilter.offset = (page - 1) * sortCount
    signFilter.sortDirection = !filter.sortDirection ? 'DESC' : filter.sortDirection

    const signData = await processFilter(dispatch, token, userID, adminFilters.SIGN_UP, signFilter)

    if (signData) {
      const { signRes: signUps = [], totalPending = null } = signData
      dispatch({ type: GET_ADMIN_SIGNUPS, payload: { signUps, totalPending } })
      fireSuccess()
    } else {
      fireFailure()
    }
  }
}

/**
 * Function to either approve or deny a teacher fellow
 * @param {Object} approvalInfo - Approval info object for approving/denying fellow
 * @param {string} approvalInfo.fellowID - ID of fellow to approve/deny
 * @param {boolean} approvalInfo.approvalStatus - Boolean for approval status
 * @param {string} approvalInfo.roleKey - key to the roleName for fellow
 * @param {requestSuccess} fireSuccess - callback on success
 * @param {requestFailure} fireFailure - callback on failure
 */
export const updateApproval = (approvalInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '', userID = '' } = getState().auth

      const body = JSON.stringify({ userID, ...approvalInfo })

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/users/status`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        // const { data } = await response.json()
        const { fellowID = '' } = approvalInfo
        fireSuccess(fellowID)
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

// *************************************** ADMIN USER MANAGEMENT **************************************** //

export const getAdminUserList = (filter = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '', userID = '' } = getState().auth

      const adminUserFilter = { ...filter, search: filter.searchPhrase }
      const { page = 1, sortCount = 10 } = adminUserFilter
      adminUserFilter.offset = (page - 1) * sortCount
      adminUserFilter.sortDirection = !filter.sortDirection ? 'DESC' : filter.sortDirection
      const body = JSON.stringify({ filter: { ...adminUserFilter }, userID })

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/list`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        const { data } = await response.json()
        const { admins: adminUsers = [], adminCount: totalAdmins = null } = data
        dispatch({ type: GET_ADMIN_USER_LIST, payload: { adminUsers, totalAdmins } })
        fireSuccess()
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

// ******************************************** COHORTS ************************************************ //

/**
 * Function to get cohorts with searching and sorting
 * @param {object} filter - Object with filter data for cohorts
 * @param {number} [filter.page] - Pagination number of current search results
 * @param {number} [filter.sortCount] - Number of results to be displayed
 * @param {number} [filter.sortDirection] - Direction of sorting {DESC | ASC}
 * @param {string} [filter.sortType] - Column to be sorted by {cohortName | startDate | endDate | numOfFellows}
 * @param {string} [filter.searchPhrase] - Phrase to be searched for in results
 * @param {requestSuccess} fireSuccess - callback on success
 * @param {requestFailure} fireFailure - callback on failure
 */
export const getCohorts = (filter = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    const { token = '', userID = '' } = getState().auth

    const cohortFilter = { ...filter }
    const { page = 1, sortCount = 10 } = cohortFilter
    cohortFilter.offset = (page - 1) * sortCount
    cohortFilter.sortDirection = !filter.sortDirection ? 'DESC' : filter.sortDirection

    const cohortData = await processFilter(dispatch, token, userID, adminFilters.COHORTS, cohortFilter)

    if (cohortData) {
      const { cohortsRes: cohorts = [], totalCohorts = null } = cohortData
      dispatch({ type: GET_ADMIN_COHORTS, payload: { cohorts, totalCohorts } })
      fireSuccess()
    } else {
      fireFailure()
    }
  }
}

/**
 * Function to create new teacher fellow cohort
 * @param {Object} cohortInfo - Object detailing cohort info
 * @param {string} cohortInfo.cohortName - Name of new cohort
 * @param {number} cohortInfo.startDate - Cohort start date unix
 * @param {number} cohortInfo.endDate - Cohort end date unix
 * @param {requestSuccess} fireSuccess - callback on success
 * @param {requestFailure} fireFailure - callback on failure
 */
export const createCohort = (cohortInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '', userID = '' } = getState().auth

      const body = JSON.stringify({ ...cohortInfo, userID })

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/cohorts`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        fireSuccess()
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

/**
 * Function for editing current cohort
 * @param {Object} cohortInfo - Object for cohort edit info
 * @param {string} cohortInfo.cohortID - ID of current cohort being edited
 * @param {string} cohortInfo.cohortName - New name for current cohort
 * @param {string} cohortInfo.startDate - New unix start date for current cohort
 * @param {string} cohortInfo.endDate - New unix end date for current cohort
 * @param {requestSuccess} fireSuccess - callback on success
 * @param {requestFailure} fireFailure - callback on failure
 */
export const updateCohort = (cohortInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '', userID = '' } = getState().auth

      const body = JSON.stringify({ ...cohortInfo, userID })

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/cohorts`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        fireSuccess()
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const removeCohort = (cohortInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '' } = getState().auth
      const { cohortID = '' } = cohortInfo

      const body = JSON.stringify(cohortInfo)

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/cohorts/${cohortID}/remove`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        fireSuccess()
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

// ************************************************ ARTIFIACTS **************************************************** //

/**
 * Function to get artifacts for submission with searching and sorting
 * @param {Object} filter - Object with filter data for artifacts
 * @param {number} [filter.page] - Pagination number of current search results
 * @param {number} [filter.sortCount] - Number of results to be displayed
 * @param {number} [filter.sortDirection] - Direction of sorting {DESC | ASC}
 * @param {string} [filter.sortType] - Column to be sorted by {name | artifactName | schoolName | cohortName | districtName | dateSubmitted | status}
 * @param {string} [filter.searchPhrase] - Phrase to be searched for in results
 * @param {requestSuccess} fireSuccess - callback on success
 * @param {requestFailure} fireFailure - callback on failure
 */

// Gets a list of artifacts submitted to ed farm and their associated status
export const getArtifacts = (filter = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    const { token = '', userID = '' } = getState().auth

    const artifactFilter = { ...filter }
    const { page = 1, sortCount = 10 } = artifactFilter
    artifactFilter.offset = (page - 1) * sortCount
    artifactFilter.sortDirection = !filter.sortDirection ? 'DESC' : filter.sortDirection

    if (!artifactFilter.sortType) {
      artifactFilter.sortType = 'dateSubmitted'
    }

    const artifactData = await processFilter(dispatch, token, userID, adminFilters.ARTIFACTS, artifactFilter)

    if (artifactData) {
      const { artifactList: artifacts = [], totalSubmissions = null, totalToReview = 0 } = artifactData
      dispatch({ type: GET_ADMIN_ARTIFACTS, payload: { artifacts, totalSubmissions, totalToReview } })
      fireSuccess()
    } else {
      fireFailure()
    }
  }
}

// Sets the author information and base artifact information in redux (used in the side menu in artifact review)
export const setReviewArtifact = (notificationBoolean, info = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      if (notificationBoolean) {
        const { token = '', userID = '' } = getState().auth
        const notificationID = info

        const options = {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        }

        const response = await trackPromise(window.fetch(`${api}/${apiversion}/user/${userID}/notifications/${notificationID}`, options))
        const parsedResponse = parseResponse(response, dispatch)

        if (parsedResponse && !parsedResponse.error) {
          const { data } = await response.json()

          const newPayload = {
            ...data,
            cohort: data.cohortName,
            profileAvatar: data.profileAvatarPath,
            district: data.districtName,
            school: data.schoolName,
            status: data.submissionStatus,
            submitDate: data.submitDate * 1000
          }
          dispatch({ type: SET_REVIEW_ARTIFACT, payload: newPayload })
          fireSuccess()
        } else {
          fireFailure()
        }
      } else {
        const {
          artifactID = '', artifactName = '', cohort = null, denialComment = null, district = '', userID = '',
          name = '', profileAvatar = '', profileID = '', school = '', status = '', statusUpdated = '', submitDate = ''
        } = info

        const artifactInfo = {
          artifactID,
          artifactName,
          cohort,
          denialComment,
          district,
          userID,
          name,
          profileAvatar,
          profileID,
          school,
          status,
          statusUpdated,
          submitDate
        }
        dispatch({ type: SET_REVIEW_ARTIFACT, payload: artifactInfo })
        fireSuccess()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const getReviewArtifactStatus = (artifactID, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/artifacts/${artifactID}/status`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        const { data: { submissionStatus: status = '', denialComment = null } = {} } = await response.json()

        const payload = { status, denialComment }
        dispatch({ type: GET_REVIEW_ARTIFACT_STATUS, payload })
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const resetReviewArtifact = () => {
  return async (dispatch = () => { }) => {
    dispatch({ type: SET_REVIEW_ARTIFACT, payload: {} })
  }
}

// Update the status of an artifact
export const updateArtifactStatus = (
  artifactInfo = {}, fireSuccess = () => { }, fireFailure = () => { }, fireAdminFailure = () => { }
) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '', userID: signedUser } = getState().auth
      const { artifactID, status, denialComment = null, authorID } = artifactInfo
      const isAuthor = Boolean(signedUser === authorID)

      const body = JSON.stringify({ status, denialComment, authorID, isAuthor })

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/artifacts/${artifactID}/status`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        fireSuccess(status)
      } else {
        if (parsedResponse && parsedResponse.type === responseTypes.FORBIDDEN) {
          fireAdminFailure()
        } else {
          fireFailure()
        }
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const updateReviewArtifact = (status) => {
  return (dispatch = () => { }) => {
    dispatch({
      type: UPDATE_REVIEW_ARTIFACT,
      payload: status
    })
  }
}

export const postAdminArtifactComment = (commentInfo, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '' } = getState().auth
      const { artifactID, comment, artifactSection = null } = commentInfo

      const body = JSON.stringify({ comment, artifactSection })

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/artifacts/${artifactID}/comment`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        fireSuccess()
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const updateAdminArtifactComment = (commentInfo, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '' } = getState().auth
      const { artifactID, commentID, comment } = commentInfo

      const body = JSON.stringify({ comment })

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/artifacts/${artifactID}/comment/${commentID}`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        fireSuccess()
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const updateArtifactSectionComments = (sectionInfo, fireSuccess = () => { }, fireFailure = () => { }, exitingArtifact) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '', userID } = getState().auth
      const { artifactID, artifactSection } = sectionInfo

      // not quite sure how to handle this
      if (!artifactID) { return false }

      const body = JSON.stringify({ userID, artifactSection })

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/artifacts/${artifactID}/section/viewed`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        fireSuccess(artifactID, exitingArtifact)
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const deleteAdminArtifactComment = (commentInfo, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '' } = getState().auth
      const { artifactID, commentID } = commentInfo

      const options = {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/artifacts/${artifactID}/comment/${commentID}`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        fireSuccess()
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

// ********************************************** SCHOOLS/DISTRICTS **************************************** //

/**
 * Function to get schools and their districts with searching and sorting
 * @param {Object} filter - Object with filter data for schools
 * @param {number} [filter.page] - Pagination number of current search results
 * @param {number} [filter.sortCount] - Number of results to be displayed
 * @param {number} [filter.sortDirection] - Direction of sorting {DESC | ASC}
 * @param {string} [filter.sortType] - Column to be sorted by { schoolName | districtName }
 * @param {string} [filter.searchPhrase] - Phrase to be searched for in results
 * @param {requestSuccess} fireSuccess - callback on success
 * @param {requestFailure} fireFailure - callback on failure
 */
export const getSchoolsAndDistricts = (filter = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '', userID = '' } = getState().auth

      const schoolsAndDistrictsFilter = { ...filter }
      const { page = 1, sortCount = 10 } = schoolsAndDistrictsFilter
      schoolsAndDistrictsFilter.offset = (page - 1) * sortCount
      schoolsAndDistrictsFilter.sortDirection = !filter.sortDirection ? 'DESC' : filter.sortDirection

      const body = JSON.stringify({ filter: { ...schoolsAndDistrictsFilter }, userID })

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/schools/search`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        const { data: { schools = [], schoolCount = null, districtCount = null, districtOptions = [] } = {} } = await response.json()
        dispatch({ type: GET_ADMIN_SCHOOLS, payload: { schools, schoolCount, districtCount, districtOptions } })
        fireSuccess()
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

/**
 * Function to create a new school
 * @param {Object} schoolInfo - Object detailing school info
 * @param {string} schoolInfo.schoolName - Name of new school
 * @param {string} schoolInfo.address - school's address line 1
 * @param {string} schoolInfo.address2 - school's address line 2
 * @param {string} schoolInfo.city - school's city location
 * @param {string} schoolInfo.stateCode - school's state location
 * @param {number} schoolInfo.postalCode - school's zip code
 * @param {string} schoolInfo.schoolDistrictID - the id for the district associated with the school
 * @param {requestSuccess} fireSuccess - callback on success
 * @param {requestFailure} fireFailure - callback on failure
 */
export const createSchool = (schoolInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '', userID = '' } = getState().auth

      const body = JSON.stringify({ ...schoolInfo, userID })

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/schools`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        fireSuccess()
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

/**
 * Function to update a new school
 * @param {Object} schoolInfo - Object detailing school info
 * @param {string} schoolInfo.schoolName - Name of new school
 * @param {string} schoolInfo.schoolID - ID of the School being updated
 * @param {string} schoolInfo.address - school's address line 1
 * @param {string} schoolInfo.address2 - school's address line 2
 * @param {string} schoolInfo.city - school's city location
 * @param {string} schoolInfo.stateCode - school's state location
 * @param {number} schoolInfo.postalCode - school's zip code
 * @param {string} schoolInfo.schoolDistrictID - the id for the district associated with the school
 * @param {requestSuccess} fireSuccess - callback on success
 * @param {requestFailure} fireFailure - callback on failure
 */
export const updateSchool = (schoolInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '', userID = '' } = getState().auth

      const body = JSON.stringify({ ...schoolInfo, userID })

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/schools`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        fireSuccess()
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const deleteSchool = (schoolID, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/schools/${schoolID}`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        fireSuccess()
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

// ***************************************************** SUBJECTS ******************************************************* //

/**
 * Function to get schools and their districts with searching and sorting
 * @param {Object} filter - Object with filter data for schools
 * @param {number} [filter.page] - Pagination number of current search results
 * @param {number} [filter.sortCount] - Number of results to be displayed
 * @param {number} [filter.sortDirection] - Direction of sorting {DESC | ASC}
 * @param {string} [filter.sortType] - Column to be sorted by { schoolName | districtName }
 * @param {string} [filter.searchPhrase] - Phrase to be searched for in results
 * @param {requestSuccess} fireSuccess - callback on success
 * @param {requestFailure} fireFailure - callback on failure
 */
export const getSubjects = (filter = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '', userID = '' } = getState().auth

      const subjectsFilter = { ...filter }
      const { page = 1, sortCount = 10 } = subjectsFilter
      subjectsFilter.offset = (page - 1) * sortCount
      subjectsFilter.sortDirection = !filter.sortDirection ? 'DESC' : filter.sortDirection

      const body = JSON.stringify({ filter: { ...subjectsFilter }, userID })

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/subjects/search`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        const { data: { subjects = [], subjectCount = null } = {} } = await response.json()
        dispatch({ type: GET_ADMIN_SUBJECTS, payload: { subjects, subjectCount } })
        fireSuccess()
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

/**
 * Function to create a new subject
 * @param {Object} subjectInfo - Object detailing subject info
 * @param {string} subjectInfo.subjectName - Name of new subject
 * @param {string} subjectInfo.subjectDesc - subject's description
 * @param {requestSuccess} fireSuccess - callback on success
 * @param {requestFailure} fireFailure - callback on failure
 */
export const createSubject = (subjectInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '', userID = '' } = getState().auth

      const body = JSON.stringify({ ...subjectInfo, userID })

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/subjects`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        fireSuccess()
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

/**
 * Function to update a new subject
 * @param {Object} subjectInfo - Object detailing subject info
 * @param {string} subjectInfo.subjectName - Name of new subject
 * @param {string} subjectInfo.subjectID - ID of the subject being updated
 * @param {string} subjectInfo.subjectDesc - subject's description
 * @param {requestSuccess} fireSuccess - callback on success
 * @param {requestFailure} fireFailure - callback on failure
 */
export const updateSubject = (subjectInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '', userID = '' } = getState().auth

      const body = JSON.stringify({ ...subjectInfo, userID })

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/subjects`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        fireSuccess()
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const deleteSubject = (subjectID, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/subjects/${subjectID}`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        fireSuccess()
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

// ****************************************************** CERTS ***************************************************** //

/**
 * Function to get schools and their districts with searching and sorting
 * @param {Object} filter - Object with filter data for schools
 * @param {number} [filter.page] - Pagination number of current search results
 * @param {number} [filter.sortCount] - Number of results to be displayed
 * @param {number} [filter.sortDirection] - Direction of sorting {DESC | ASC}
 * @param {string} [filter.sortType] - Column to be sorted by { schoolName | districtName }
 * @param {string} [filter.searchPhrase] - Phrase to be searched for in results
 * @param {requestSuccess} fireSuccess - callback on success
 * @param {requestFailure} fireFailure - callback on failure
 */
export const getCertifications = (filter = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '', userID = '' } = getState().auth

      const certificationFilter = { ...filter }
      const { page = 1, sortCount = 10 } = certificationFilter
      certificationFilter.offset = (page - 1) * sortCount
      certificationFilter.sortDirection = !filter.sortDirection ? 'DESC' : filter.sortDirection

      const body = JSON.stringify({ filter: { ...certificationFilter }, userID })

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/certifications/search`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        const { data: { certifications = [], certCount = null } } = await response.json()
        dispatch({ type: GET_ADMIN_CERTS, payload: { certifications, certCount } })
        fireSuccess()
        return true
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

/**
 * Function to create a new certification
 * @param {Object} certInfo - Object detailing cert info
 * @param {string} certInfo.certName - Name of new cert
 * @param {string} certInfo.certDesc - cert's description
 * @param {requestSuccess} fireSuccess - callback on success
 * @param {requestFailure} fireFailure - callback on failure
 */
export const createCertification = (certInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '', userID = '' } = getState().auth

      const body = JSON.stringify({ ...certInfo, userID })

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/certifications`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        fireSuccess()
      } else {
        fireFailure(parsedResponse.code)
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

/**
 * Function to update a new cert
 * @param {Object} certInfo - Object detailing cert info
 * @param {string} certInfo.certName - Name of new cert
 * @param {string} certInfo.certificationID - ID of the cert being updated
 * @param {string} certInfo.certDesc - cert's description
 * @param {string} certInfo.certImage - cert's avatar
 * @param {string} certInfo.isImageChanged - check to see if the image has changed
 * @param {requestSuccess} fireSuccess - callback on success
 * @param {requestFailure} fireFailure - callback on failure
 */
export const updateCertification = (certInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '', userID = '' } = getState().auth

      const body = JSON.stringify({ ...certInfo, userID })

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/certifications`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        fireSuccess()
      } else {
        fireFailure(parsedResponse.code)
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

/**
 * Function to delete a cert
 * @param {string} certificationID - ID of the cert being deleted
 * @param {requestSuccess} fireSuccess - callback on success
 * @param {requestFailure} fireFailure - callback on failure
 */
export const deleteCertification = (certificationID, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/certifications/${certificationID}/delete`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        fireSuccess()
      } else {
        fireFailure(parsedResponse.code)
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

// ****************************************************** EF RESOURCES (EFR) ***************************************************** //

// NOTE: All EFR creation and management tasks can ONLY be preformed by an Admin User!

// ---- Individual/Specific EFR detail managment --- //

/**
 * Function to create a new EFR
 * @param {Object} resourceInfo - Object with resourceInfo data for schools
 * @param {string} [resourceInfo.resourceName] - The title of the resource
 * @param {string} [resourceInfo.resourceType] - The subtype of the resource (must reference a value in the efrSubTypes object)
 * @param {string} [resourceInfo.resourceDescription] - The description of the resource
 * @param {string} [resourceInfo.resourceAudience] - the audience reached by the resource (must reference a value in the gradeCategories object)
 * @param {number} [resourceInfo.startDate] - specific to the event subtype - timestamp when the event starts (represents a date and specific time)
 * @param {number} [resourceInfo.endDate] - specific to the event subtype - timestamp when the event ends (represents a date and specific time)
 * @param {string} [resourceInfo.resourceLink] - A link verified on in the UI to an RSVP third party app or relative resource (webinar, instructional video etc.)
 * @param {base64} [resourceInfo.bannerData] - The featured photo (optional) for the event subtype
 * @param {base64} [resourceInfo.bannerType] - The featured photo (optional) image type for the event subtype
 * @param {requestSuccess} fireSuccess - callback on success
 * @param {requestFailure} fireFailure - callback on failure
 */
export const insertNewEFR = (resourceInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '' } = getState().auth

      // Auto Assigning isActive and isDraft for now (currently active must be set in the admin table, and we are not allowing drafts as of 9/3/2021)
      resourceInfo.isDraft = 0
      resourceInfo.isActive = 0

      const { resourceAudience, resourceType } = resourceInfo

      // Verifies the audience/type value provided matches one of the value options expected on the api
      if (!Object.values(efrSubTypes).includes(resourceType)) { fireFailure() }
      if (!Object.values(gradeCategories).includes(resourceAudience)) { fireFailure() }

      const body = JSON.stringify(resourceInfo)

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/efresource`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        fireSuccess()
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

/**
 * Function to Update the specific details of an EFR. (** Note: EFR sub-type CANNOT be altered once created.)
 * @param {Object} resourceInfo - Object with resourceInfo data for schools
 * @param {string} [resourceInfo.resourceName] - The title of the resource
 * @param {string} [resourceInfo.resourceType] - The subtype of the resource (cannot be altered but is used to format data in the API)
 * @param {string} [resourceInfo.resourceDescription] - The description of the resource
 * @param {string} [resourceInfo.resourceAudience] - the audience reached by the resource (must reference a value in the gradeCategories object)
 * @param {number} [resourceInfo.startDate] - specific to the event subtype - timestamp when the event starts (represents a date and specific time)
 * @param {number} [resourceInfo.endDate] - specific to the event subtype - timestamp when the event ends (represents a date and specific time)
 * @param {string} [resourceInfo.resourceLink] - A link verified on in the UI to an RSVP third party app or relative resource (webinar, instructional video etc.)
 * @param {base64} [resourceInfo.bannerData] - The featured photo (optional) for the event subtype
 * @param {boolean} [resourceInfo.bannerUpdated] - alerts the api if the banner photo has changed
 * @param {requestSuccess} fireSuccess - callback on success
 * @param {requestFailure} fireFailure - callback on failure
 */
export const updateEFRDetails = (resourceID, resourceInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '' } = getState().auth

      const { resourceAudience, bannerUpdated, bannerData, artifactID } = resourceInfo

      // Verifies the audience value provided matches one of the value options expected on the api
      if (!Object.values(gradeCategories).includes(resourceAudience)) { fireFailure() }

      const body = JSON.stringify(resourceInfo)

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/efresource/${resourceID}`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        // Signal the success func to fire if the banner was updated (will let the user know it will take a moment to update.)
        fireSuccess(bannerUpdated)

        // Set a timeout to update the image after a moment
        if (bannerUpdated) {
          setTimeout(() => {
            dispatch({ type: UPDATE_BANNER_IMAGE, payload: bannerData ? `${cloudfrontPath}/banners/${artifactID}?${moment().add(5, 'minute').unix()}` : '' })
          }, 5000)
        }
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

// Delete a specific EFR. (** NOTE: can be preformed both in the admin table and within the details of the artifact itself.)
// -- Soft deletes an EFR, but will hard delete from the featured table if the resource is active or set to become active
export const deleteEFR = (resourceID, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/efresource/${resourceID}`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        fireSuccess()
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

// ----------------- Admin Table/Featured EFR Management --------------- //

/**
 * Function to Get/Search the current EFR's and their active/featured status with searching and sorting
 * @param {Object} filter - Object with filter data for schools
 * @param {number} [filter.page] - Pagination number of current search results
 * @param {number} [filter.sortCount] - Number of results to be displayed
 * @param {number} [filter.sortDirection] - Direction of sorting {DESC | ASC}
 * @param {string} [filter.sortType] - Column to be sorted by { createdBy, resourceName, isActive, resourceType, activeStartDate, endStartDate }
 * @param {string} [filter.searchPhrase] - Phrase to be searched for in results
 * @param {requestSuccess} fireSuccess - callback on success
 * @param {requestFailure} fireFailure - callback on failure
 */
export const getEFRAdminList = (filter = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '' } = getState().auth

      const resourceFilter = { ...filter }
      const { page = 1, sortCount = 10 } = resourceFilter
      resourceFilter.offset = (page - 1) * sortCount
      resourceFilter.sortDirection = !filter.sortDirection ? 'DESC' : filter.sortDirection

      const body = JSON.stringify({ filter: { ...resourceFilter } })

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/efresource/search`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        const { data: { resources = [], resourceCount = null } } = await response.json()
        dispatch({ type: GET_ADMIN_EF_RESOURCES, payload: { resources, resourceCount } })
        fireSuccess()
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

/**
 * Function to Update the EFR's featured/active status and the date range associated with it's active state period. (** NOTE: this date range does now correlate to the event subtype's date range.)
 * @param {Object} featuredInfo - Object with the data pertaining the EFR's featured/active status
 * @param {number} [featuredInfo.isActive] - 0/1 boolean for whether the EFR is being featured or not
 * @param {number} [featuredInfo.activeStart] - timestamp for the start date of the featured period
 * @param {number} [featuredInfo.activeEnd] - timestamp for the end date of the featured period
 * @param {string} [featuredInfo.featuredResourceID] - if the resource has already been set to active it will have an ID related to that table
 * @param {requestSuccess} fireSuccess - callback on success
 * @param {requestFailure} fireFailure - callback on failure
 */
export const updateEFRActiveStatus = (resourceID, featuredInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '' } = getState().auth

      const body = JSON.stringify(featuredInfo)

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/efresource/${resourceID}/featured`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        fireSuccess()
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

// ---- Active Status Update Check for All Featured FR (typically used in daily cron function)

// This is a unique call that is primarily used in a once a day cron function that manages activation/deactivation of featured EFR based on their active period an the current date.
export const featuredEFRStatusCheck = (fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/resources/activate`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        fireSuccess()
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

// ************************************************* FEATURED LED ******************************************************* //

/**
 * Function to get all LEDs selected by admins with searching and sorting
 * @param {Object} filter - Object with filter data for featured LEDs
 * @param {number} [filter.page] - Pagination number of current search results
 * @param {number} [filter.sortCount] - Number of results to be displayed
 * @param {number} [filter.sortDirection] - Direction of sorting {DESC | ASC}
 * @param {string} [filter.sortType] - Column to be sorted by
 * @param {string} [filter.searchPhrase] - Phrase to be searched for in results
 * @param {requestSuccess} fireSuccess - callback on success
 * @param {requestFailure} fireFailure - callback on failure
 */
export const getFeaturedLEDOptions = (filter = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const ledFilter = { ...filter }

      if (ledFilter.sortType && ledFilter.sortType !== 'createdAt' && ledFilter.sortType !== 'activeFeatured') {
        ledFilter.sortType = `${filter.sortType}.keyword`
      }

      const { page = 1, sortCount = 10 } = ledFilter
      ledFilter.offset = (page - 1) * sortCount
      ledFilter.sortDirection = !filter.sortDirection ? 'desc' : filter.sortDirection

      const body = JSON.stringify({ filter: { ...ledFilter } })

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/featuredLed/search`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        const { data = {} } = await response.json()
        const { artifacts = [], artifactTotal = null } = data
        dispatch({ type: GET_FEATURED_LED_OPTIONS, payload: { artifacts, artifactTotal } })
        fireSuccess()
      } else {
        fireFailure()
        return false
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

/**
 * Function to Update the LED's selected/active status
 * @param {string} artifactID - the ID of the artifact being updated
 * @param {string} actionType - the action type to be preformed on the selected artifact
 * @param {requestSuccess} fireSuccess - callback on success
 * @param {requestFailure} fireFailure - callback on failure
 */
export const updateLEDActiveStatus = (artifactID, actionType = '', fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '' } = getState().auth

      const body = JSON.stringify({ actionType })

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/featuredLed/${artifactID}/status`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        // Return new activeValue of artifactID in success func (only used when toggling "activeFeatured" status)
        const activeValue = Boolean(actionType === featuredLEDActions.activateSelected)
        fireSuccess(artifactID, activeValue)
      } else {
        fireFailure()
        return false
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

// ****************************************************** DISTRICTS ***************************************************** //

/**
 * Function to get/search the districts list
 * @param {Object} filter - Object with filter data for districts
 * @param {number} [filter.page] - Pagination number of current search results
 * @param {number} [filter.sortCount] - Number of results to be displayed
 * @param {number} [filter.sortDirection] - Direction of sorting {DESC | ASC}
 * @param {string} [filter.sortType] - Column to be sorted by
 * @param {string} [filter.searchPhrase] - Phrase to be searched for in results
 * @param {requestSuccess} fireSuccess - callback on success
 * @param {requestFailure} fireFailure - callback on failure
 */
export const districtsList = (filter = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '' } = getState().auth

      const districtFilter = { ...filter }
      const { page = 1, sortCount = 10 } = districtFilter
      districtFilter.offset = (page - 1) * sortCount
      districtFilter.sortDirection = !filter.sortDirection ? 'DESC' : filter.sortDirection

      const body = JSON.stringify({ filter: { ...districtFilter } })

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/districts/list`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        // Added adminDistrict type, reducer, and action for AdminDistricts.js
        const { data: { districts = [], districtCount = null } = {} } = await response.json()
        dispatch({ type: GET_ADMIN_DISTRICTS, payload: { districts, districtCount } })

        fireSuccess()
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

/**
 * Function to create a new district
 * @param {Object} districtInfo - Object with districtInfo data for districts
 * @param {string} [districtInfo.districtName] - The name of the district
 * @param {string} [districtInfo.city] - The city the district is in
 * @param {string} [districtInfo.stateCode] - The state the district is in
 * @param {requestSuccess} fireSuccess - callback on success
 * @param {requestFailure} fireFailure - callback on failure
 */
export const createDistrict = (districtInfo = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    const { token = '' } = getState().auth

    const body = JSON.stringify(districtInfo)

    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body
    }

    const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/districts`, options))
    const parsedResponse = parseResponse(response, dispatch)

    if (parsedResponse && !parsedResponse.error) {
      fireSuccess()
    } else {
      fireFailure()
    }
  }
}

/**
 * Function to update a specific district
 * @param {Object} districtInfo - Object with districtInfo data for districts
 * @param {string} [districtInfo.districtName] - The name of the district
 * @param {string} [districtInfo.city] - The city the district is in
 * @param {string} [districtInfo.stateCode] - The state the district is in
 * @param {requestSuccess} fireSuccess - callback on success
 * @param {requestFailure} fireFailure - callback on failure
 */
export const updateDistrict = (districtID, districtInfo, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    const { token = '' } = getState().auth

    const body = JSON.stringify(districtInfo)

    const options = {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body
    }

    const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/districts/${districtID}`, options))
    const parsedResponse = parseResponse(response, dispatch)

    if (parsedResponse && !parsedResponse.error) {
      fireSuccess()
    } else {
      fireFailure()
    }
  }
}

// Delete a specific district
// -- Soft deletes a district
export const deleteDistrict = (districtID, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    const { token = '' } = getState().auth

    const options = {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }

    const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/districts/${districtID}`, options))
    const parsedResponse = parseResponse(response, dispatch)

    if (parsedResponse && !parsedResponse.error) {
      fireSuccess()
    } else {
      fireFailure()
    }
  }
}

// ***** END ****** //

// ****************************************************** MCE ASSESSORS ***************************************************** //

// fetch the current list of mce assessors
export const getAssessorsList = (filter = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '' } = getState().auth

      const assessorFilter = { ...filter }
      const { page = 1, sortCount = 10 } = assessorFilter
      assessorFilter.offset = (page - 1) * sortCount
      assessorFilter.sortDirection = !filter.sortDirection ? 'DESC' : filter.sortDirection

      if (assessorFilter.fetchAll) { assessorFilter.sortCount = 'ALL' }

      const body = JSON.stringify({ filter: { ...assessorFilter } })

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/assessors/list`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        const { data: { assessors = [], totalAssessors = null } = {} } = await response.json()
        dispatch({ type: GET_ADMIN_ASSESSORS, payload: { assessors, totalAssessors } })
        fireSuccess()
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

// add new assessors
export const addNewAssessors = (assessorIDs, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '' } = getState().auth

      const body = JSON.stringify({ assessorIDs })

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/assessors`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        fireSuccess()
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

// remove assessor
export const removeAssessor = (assessorID, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/assessors/${assessorID}`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        fireSuccess(true)
      } else {
        fireFailure(true)
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

// ****************************************************** MCE EVALUATIONS ***************************************************** //

// fetch the current list of mce evaluations
export const getEvaluationsList = (filter = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '' } = getState().auth

      const evaluationFilter = { ...filter }
      const { page = 1, sortCount = 10 } = evaluationFilter
      evaluationFilter.offset = (page - 1) * sortCount
      evaluationFilter.sortDirection = !filter.sortDirection ? 'DESC' : filter.sortDirection

      const body = JSON.stringify({ filter: { ...evaluationFilter } })

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/evaluations/list`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        const {
          data: { evaluationRes = [], totalEvaluations = null } = {}
        } = await response.json()

        dispatch({
          type: GET_ADMIN_EVALUATIONS,
          payload: { evaluations: evaluationRes, totalEvaluations }
        })

        fireSuccess()
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

export const getEvaluationExports = (fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    const { token } = getState().auth
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }

    const response = await trackPromise(window.fetch(`${api}/v1/admin/evaluations/export`, options))
    const parsedResponse = parseResponse(response, dispatch)
    if (!parsedResponse) { return false }

    if (parsedResponse.error) {
      fireFailure()
    } else {
      const { data } = await response.json()
      fireSuccess(data)
    }
  }
}

// assign assessor to an evaluation
export const assignEvaluationAssessor = (attemptID, assessorID, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/evaluations/${attemptID}/${assessorID}`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        fireSuccess()
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

// ****************************************************** MICRO-CREDENTIALS ***************************************************** //

// fetch the current list of mce evaluations
export const getMicroCredentialList = (filter = {}, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '' } = getState().auth

      const credentialFilter = { ...filter }
      const { page = 1, sortCount = 10 } = credentialFilter
      credentialFilter.offset = (page - 1) * sortCount
      credentialFilter.sortDirection = !filter.sortDirection ? 'DESC' : filter.sortDirection
      credentialFilter.noPagination = typeof filter.noPagination === 'undefined' ? false : filter.noPagination

      const body = JSON.stringify({ filter: { ...credentialFilter } })

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/microcredentials/list`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        const {
          data: { credentialRes = [], totalMCEs = null } = {}
        } = await response.json()

        dispatch({
          type: GET_ADMIN_MCES,
          payload: { microcredentials: credentialRes, totalMCEs }
        })

        fireSuccess()
      } else {
        fireFailure()
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}

// Function to help format the different admin search types of either teacher-fellows, sign-ups, or cohorts
const processFilter = async (dispatch, token, userID, type, filter) => {
  try {
    const body = JSON.stringify({ filter: { ...filter, type }, userID })

    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body
    }

    const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/user/search`, options))
    const parsedResponse = parseResponse(response, dispatch)

    if (parsedResponse && !parsedResponse.error) {
      const { data = {} } = await response.json()
      return data
    } else {
      return false
    }
  } catch (err) {
    console.error(err)
    return false
  }
}

// (Super Admin Only) update a user's email address and validation
export const updateUserEmailAndValidation = (userID, newEmailAddress, oldEmailAddress, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch = () => { }, getState = reduxReducer) => {
    try {
      const { token = '' } = getState().auth

      const body = JSON.stringify({ newEmailAddress, oldEmailAddress })

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      const response = await trackPromise(window.fetch(`${api}/${apiversion}/admin/users/${userID}`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && !parsedResponse.error) {
        fireSuccess(userID, newEmailAddress)
      } else {
        const isDuplicate = parsedResponse.type === responseTypes.DUPLICATE_ENTRY

        fireFailure(isDuplicate ? 'This email is already in use. Please try a different email.' : 'Unable to update user. Please try again.')
      }
    } catch (err) {
      console.error(err)
      fireFailure()
    }
  }
}
