import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import queryString from 'query-string'

import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  Grid, Button, Typography, Paper, Select, MenuItem, OutlinedInput
} from '@material-ui/core'

import {
  ExpandMoreRounded, SearchRounded
} from '@material-ui/icons'

import { setExploreActiveTab, setExploreScrollPosition } from '../../../redux/actions'

import ArtifactCard from '../artifacts/ArtifactCard'

import { ExploreFeatured, ExploreAll, ExploreEdFarmResources, ExploreUsers } from './tabs'

import { esRecordType, explorerTypes, userRoles, userRoleIDs } from '../../../utils'

const ExploreArtifactTabs = (props) => {
  const {
    classes, theme, grades, subjects, xsScreenBelow, subjectsFeaturedRef, scroll,
    xsScreen, likedArtifacts, savedArtifacts, socialModalOpen, setSocialType, setSocialArtifactInfo,
    setSocialModalOpen, newFeaturedRef, smScreen, mdScreen, lgScreen, getExploreArtifacts
  } = props

  const dispatch = useDispatch()
  const parsedProps = queryString.parse(props.location.search)

  const { profileID } = useSelector(state => state.auth)

  const {
    adminSchools: { schools: Schools = [], districtOptions: Districts = [] } = {}
  } = useSelector(state => state.admin)

  const userRoleOptions = [
    { roleName: userRoles.TEACHER_FELLOW, roleID: userRoleIDs.TEACHER_FELLOW },
    { roleName: userRoles.INNOVATION_FELLOW, roleID: userRoleIDs.INNOVATION_FELLOW }
  ]

  // NOT IN USE: allowing multiple selections for districts
  // const schoolsListIndex = Schools && Schools.length && Districts && Districts.length ? Schools.findIndex(x => x.schoolDistrictID === Districts[0].schoolDistrictID) : -1
  // const [schoolOptions, setSchoolOptions] = useState(schoolsListIndex !== -1 ? [...Schools[schoolsListIndex].schools] : [])

  // Creating array of schools for initial schoolOptions state, also used to reset list
  const schoolsArray = Schools && Schools.length && Districts && Districts.length
    ? Schools.reduce((arr, district) => { if (district && district.schools) { arr.push(...district.schools) } return arr }, []) : []
  const [schoolOptions, setSchoolOptions] = useState(schoolsArray)

  const activeTab = useSelector(state => state.select.exploreActiveTab)
  const savedScrollPosition = useSelector(state => state.select.exploreScrollPosition)

  // State and Refs for when a user has reached explore from their search results
  const searchFilter = useSelector(state => state.select.searchExploreFilter)
  const globalSearch = parsedProps.search
  const fromSearch = useRef(Boolean(globalSearch))

  // Throttles re-rendering when loading different artifacts
  const uiLoading = useRef(false)

  // Checks if global search props have been added back into the params
  useMemo(() => { if (globalSearch) { fromSearch.current = true } }, [globalSearch])

  // default Local State
  const defaultFeatured = {
    new: [],
    subjects: []
  }

  // Default filter used in filtering all posts
  const defaultAllPostsFilter = useMemo(() => ({
    explorerType: explorerTypes.ALL_POSTS,
    type: esRecordType.LE
  }), [])

  // Local State for artifact Display, filtering, and loading state
  const [featuredSections, setFeaturedSections] = useState(defaultFeatured)

  const [allArtifacts, setAllArtifacts] = useState([])
  const [allPostsCount, setAllPostsCount] = useState(0)

  const [allEFR, setAllEFR] = useState([])
  const [allEFRCount, setAllEFRCount] = useState(0)

  const [allUsers, setAllUsers] = useState([])
  const [allUsersCount, setAllUsersCount] = useState(0)

  const [hasResults, setHasResults] = useState(false)

  const [filter, setFilter] = useState(defaultAllPostsFilter)
  const [loading, setLoading] = useState(false)

  // ***** Filtering State Items ***** //

  // Artifact Type
  const [typeOpen, setTypeOpen] = useState(false)
  const [chosenType, setChosenType] = useState(esRecordType.LE)

  // Resource Type
  const [typeEFROpen, setTypeEFROpen] = useState(false)
  const [chosenTypeEFR, setChosenTypeEFR] = useState('')

  // User Type
  const [typeUserOpen, setTypeUserOpen] = useState(false)
  const [userTypeValue, setUserTypeValue] = useState('')

  // Resource Type
  const [audienceEFROpen, setAudienceEFROpen] = useState(false)
  const [chosenAudienceEFR, setChosenAudienceEFR] = useState('')

  // Grades Select and Values
  const [gradesOpen, setGradesOpen] = useState(false)
  const [gradesValue, setGradesValue] = useState([])

  // Search Select and Values
  const [subjectsOpen, setSubjectsOpen] = useState(false)
  const [subjectsValue, setSubjectsValue] = useState([])

  // District Select and Values
  const [districtSelectOpen, setDistrictSelectOpen] = useState(false)
  const [districtsValue, setDistrictsValue] = useState('')

  // School Select and Values
  const [schoolSelectOpen, setSchoolSelectOpen] = useState(false)
  const [schoolsValue, setSchoolsValue] = useState([])

  // Searchbar input
  const [searchInput, setSearchInput] = useState('')

  const setScrollPosition = useCallback(async () => {
    if (activeTab === explorerTypes.ALL_POSTS && allArtifacts && allArtifacts.length > 0 && savedScrollPosition && !loading) {
      window.scrollTo({ top: savedScrollPosition, behavior: 'smooth' })
      dispatch(setExploreScrollPosition(0))
    } else if (activeTab === 'ed-farm-resources' && allEFR && allEFR.length > 0 && savedScrollPosition && !loading) {
      window.scrollTo({ top: savedScrollPosition, behavior: 'smooth' })
      dispatch(setExploreScrollPosition(0))
    }
  }, [dispatch, activeTab, savedScrollPosition, allArtifacts, allEFR, loading])

  useEffect(() => {
    // scroll position
    setScrollPosition()
  }, [setScrollPosition])

  const setResults = useCallback(async () => {
    if (activeTab === explorerTypes.ALL_POSTS || activeTab === 'ed-farm-resources' || activeTab === explorerTypes.USERS) {
      setTimeout(() => {
        setHasResults(true)
      }, 5000)
    } else { setHasResults(false) }
  }, [activeTab])

  useEffect(() => {
    setResults()
  }, [setResults])

  // Clear the filtering values
  const handleReset = useCallback(() => {
    setTypeOpen(false)
    setTypeEFROpen(false)
    setGradesValue([])
    setGradesOpen(false)
    setSubjectsValue([])
    setSubjectsOpen(false)
    setAudienceEFROpen(false)
    setChosenType(esRecordType.LE)
    setChosenTypeEFR('')
    setChosenAudienceEFR('')
    setDistrictsValue('')
    setDistrictSelectOpen(false)
    setSchoolsValue([])
    setSchoolSelectOpen(false)
    setUserTypeValue('')
    setTypeUserOpen(false)
    setSearchInput('')
    setFilter({ ...defaultAllPostsFilter })
  }, [defaultAllPostsFilter])

  useEffect(() => {
    return () => handleReset()
  }, [handleReset])

  // Callback to fetch and set the featured arrays of artifacts
  const setFeatured = useCallback(async () => {
    setLoading(true)
    const newList = await dispatch(getExploreArtifacts())

    setTimeout(() => {
      if (newList) {
        const { new: newArts = [], subjects = [] } = newList

        setFeaturedSections({
          new: newArts,
          subjects
        })
      }
      setLoading(false)
      uiLoading.current = false
    }, 500)
  }, [dispatch, getExploreArtifacts])

  // Callback to fetch and set the list of all artifacts
  const setAllPosts = useCallback(async (newFilter, successFunction = () => { }) => {
    // Checks for a supplied filter, otherwise just uses the explorerType: 'all-posts'
    const allList = await dispatch(getExploreArtifacts(!newFilter ? filter : newFilter, successFunction))

    if (allList) {
      const { posts, postCount } = allList
      setAllArtifacts(posts)
      setAllPostsCount(postCount)
      setLoading(false)

      // looks for the global search result ref and replaces the temporary search param
      if (fromSearch.current) {
        props.history.replace({
          search: ''
        })
        fromSearch.current = false
      }
      uiLoading.current = false
    }
  }, [dispatch, filter, getExploreArtifacts, props.history])

  // Callback to fetch and set the list of all artifacts
  const setEFR = useCallback(async (newFilter, successFunction = () => { }) => {
    // Checks for a supplied filter, otherwise just uses the explorerType: 'all-posts'
    const allEFRList = await dispatch(getExploreArtifacts(!newFilter ? { ...filter, explorerType: explorerTypes.ALL_POSTS, type: esRecordType.EF } : newFilter, successFunction))

    if (allEFRList) {
      const { posts, postCount } = allEFRList
      setAllEFR(posts)
      setAllEFRCount(postCount)
      setLoading(false)

      // looks for the global search result ref and replaces the temporary search param
      if (fromSearch.current) {
        props.history.replace({
          search: ''
        })
        fromSearch.current = false
      }
      uiLoading.current = false
    }
  }, [dispatch, filter, getExploreArtifacts, props.history])

  // Callback to fetch and set the list of all artifacts
  const setUsers = useCallback(async (newFilter, successFunction = () => { }) => {
    // Checks for a supplied filter, otherwise just uses the explorerType: 'all-posts'
    const allUsersList = await dispatch(getExploreArtifacts(!newFilter ? { ...filter, explorerType: explorerTypes.USERS } : newFilter, successFunction))

    if (allUsersList) {
      const { posts, postCount } = allUsersList
      setAllUsers(posts)
      setAllUsersCount(postCount)
      setLoading(false)

      // looks for the global search result ref and replaces the temporary search param
      if (fromSearch.current) {
        props.history.replace({
          search: ''
        })
        fromSearch.current = false
      }
      uiLoading.current = false
    }
  }, [dispatch, filter, getExploreArtifacts, props.history])

  // Fires based on active tab
  useEffect(() => {
    if (activeTab) {
      if (activeTab === explorerTypes.FEATURED && !uiLoading.current) {
        uiLoading.current = true
        setFeatured()
      }

      if (activeTab === explorerTypes.ALL_POSTS && !uiLoading.current) {
        uiLoading.current = true
        // If we have reached explore from the global search, set the local state to match the user's search query
        if (fromSearch.current) {
          const { search, type } = searchFilter
          setSearchInput(search)
          setChosenType(type)
          setFilter({ ...searchFilter, explorerType: explorerTypes.ALL_POSTS })
        }

        setAllPosts(fromSearch.current ? { ...searchFilter, explorerType: explorerTypes.ALL_POSTS } : filter)
      }

      if (activeTab === 'ed-farm-resources' && !uiLoading.current) {
        uiLoading.current = true
        if (fromSearch.current) {
          const { search } = searchFilter
          setSearchInput(search)

          setFilter({ ...searchFilter, explorerType: explorerTypes.ALL_POSTS })
          setEFR(fromSearch.current ? { ...searchFilter, explorerType: explorerTypes.ALL_POSTS } : filter)
        } else {
          setEFR()
        }
      }

      if (activeTab === explorerTypes.USERS && !uiLoading.current) {
        uiLoading.current = true
        if (fromSearch.current) {
          const { search } = searchFilter
          setSearchInput(search)

          setFilter({ ...searchFilter, explorerType: explorerTypes.USERS })
          setUsers(fromSearch.current ? { ...searchFilter, explorerType: explorerTypes.USERS } : filter)
        } else {
          setUsers()
        }
      }
    }
  }, [setFeatured, setAllPosts, setEFR, setUsers, activeTab, searchFilter, filter, globalSearch])

  // **************************** Filter Logic ****************************** //

  // Once we have filtered, make sure to reset the hasMore to match the new artifactList array, and to reset the offset
  const fireTypeSuccess = () => {
    setLoading(false)
  }

  // Handler for the searchbar
  const handleSearchArtifacts = async (e) => {
    const input = e.target.value

    // Prevent the ui from reloading before the search results can return
    if (loading) {
      return false
    } else {
      // First set the user's input as the value
      setSearchInput(input)
      // If the user has typed at least 4 characters:
      if (input.length >= 4) {
        setLoading(true)
        if (activeTab === explorerTypes.ALL_POSTS) { handleFilterChange('search', input) }

        if (activeTab === 'ed-farm-resources') { handleFilterChangeEFR('search', input) }

        if (activeTab === explorerTypes.USERS) { handleFilterChangeUser('search', input) }
      }
      // If the user has deleted their search input, reset the artifactList:
      if (input === '') {
        setLoading(true)
        handleFilterChange('search', '')
      }
    }
  }

  // Clear the filtering and fetch original results
  const handleFilterReset = () => {
    const tempFilter = { ...defaultAllPostsFilter }
    setLoading(true)
    handleReset()
    setFilter(activeTab === explorerTypes.ALL_POSTS
      ? tempFilter : activeTab === 'ed-farm-resources'
        ? { ...tempFilter, type: esRecordType.EF } : activeTab === explorerTypes.USERS
          ? { ...tempFilter, type: explorerTypes.USERS } : tempFilter)
  }

  // ALL POSTS & ALL PEOPLE (Grades & Subjects Select / Search)
  // Handler for filtering by type/grades/subjects and/or a search greater than 4 chars
  const handleFilterChange = async (filterKey, options) => {
    // Let the UI know we are doing things
    setLoading(true)

    let filterVal = []

    // Filtering by grades or subjects:
    if (filterKey !== 'type' && filterKey !== 'search') {
      // The options value is '' when the user has removed their selection or set it to 'All'
      if (options.includes('')) {
        // Reset the select value display
        if (filterKey === 'grades') {
          setGradesValue([])
        }
        if (filterKey === 'subjects') {
          setSubjectsValue([])
        }
      } else {
        // We are filtering by something specific, and need to reduce the objects sent in from the select to an array of IDs
        const gradeIDs = options.reduce((arr, grade) => {
          if (grade) {
            arr.push(grade.gradeID)
          }
          return arr
        }, [])

        const subIDs = options.reduce((arr, sub) => {
          if (sub) {
            arr.push(sub.subjectID)
          }
          return arr
        }, [])

        // Set the select display value to the original array { gradeID, gradeName }, and set the filter val to the reduced array
        if (filterKey === 'grades') {
          setGradesValue(options)

          filterVal = gradeIDs
        } else if (filterKey === 'subjects') {
          setSubjectsValue(options)
          filterVal = subIDs
        }
      }
    } else {
      // Filtering by search or Type
      if (filterKey !== 'search') {
        // If filtering by Type, we need to set the select display value
        setChosenType(options)
      }
      // Either way the filter value will be the options prop
      if (options !== 'all') {
        filterVal = options
      }
    }

    // Make sure the select menu's close
    setSubjectsOpen(false)
    setGradesOpen(false)

    // Set the filter to whatever the current filter is, and add in the new key/value
    setFilter({
      ...filter,
      [filterKey]: filterVal
    })

    setAllPosts({ ...filter, [filterKey]: filterVal }, fireTypeSuccess)
    setLoading(false)
  }

  // ED FARM RESOURCES (Type & Audience Select)
  const handleFilterChangeEFR = async (filterKey, options) => {
    // Let the UI know we are doing things
    setLoading(true)

    let filterVal = []

    if (filterKey !== 'search') {
      // If filtering by Type or Audience, we need to set the display value
      if (filterKey === 'resourceType') { setChosenTypeEFR(options) }

      if (filterKey === 'resourceAudience') { setChosenAudienceEFR(options) }
    }

    filterVal = options

    // Set the filter to whatever the current filter is, and add in the new key/value
    const tempFilter = { ...filter }
    if (options === 'all' || options === '') {
      delete tempFilter.resourceType
      delete tempFilter.resourceAudience

      setFilter(tempFilter)
    } else {
      setFilter({
        ...tempFilter,
        [filterKey]: filterVal
      })
    }

    setAllEFR({ ...tempFilter, [filterKey]: filterVal })
    setLoading(false)
  }

  // ALL PEOPLE (School Select && Search)
  const handleFilterChangeUser = (filterKey, value) => {
    setLoading(true)

    // Set the filter to whatever the current filter is, and add in the new key/value
    const tempFilter = { ...filter }

    let filterVal = []

    // Filtering by schools
    if (filterKey !== 'search') {
      // The options value is '' when the user has removed their selection or set it to 'All'
      if (value.includes('')) {
        // Reset the select value display
        if (filterKey === 'schoolIDs') {
          setSchoolsValue([])
        }
      } else {
        // We are filtering by something specific, and need to reduce the objects sent in from the select to an array of IDs
        const schoolIDs = value.reduce((arr, school) => {
          if (school) {
            arr.push(school.schoolID)
          }
          return arr
        }, [])

        // Set the select display value to the original array { gradeID, gradeName }, and set the filter val to the reduced array
        if (filterKey === 'schoolIDs') {
          setSchoolsValue(value)
          filterVal = schoolIDs
        }
      }
    } else {
      if (value !== 'all') {
        filterVal = value && typeof value === 'string' ? value : ''
      }
    }

    tempFilter.explorerType = 'users'

    setSchoolSelectOpen(false)

    setFilter({
      ...tempFilter,
      [filterKey]: filterVal
    })

    setAllPosts({ ...tempFilter, [filterKey]: filterVal })
    setLoading(false)
  }

  // ************* Select Display Formatting ************* //

  const renderSelectsValue = (selectType, selected) => {
    if (!selected || selected.length === 0) {
      return 'All'
    } else {
      const newList = []

      // NOT IN USE: district name select (when allowing multiple districts select)
      selected.forEach(index => {
        newList.push(
          selectType === 'grade' ? index.gradeName.replace('Grade', '')
            : selectType === 'subject' ? index.subjectName
              : selectType === 'school' ? index.schoolName : ''
        )
      })
      return newList.join(', ')
    }
  }

  // ALL PEOPLE (District Select)
  const handleDistrictChange = (value) => {
    // Let the UI know we are doing things
    setLoading(true)

    let districtFilterVal = []
    const districtKey = 'schoolDistrictIDs'
    const schoolKey = 'schoolIDs'

    // Reset school value when changing districts before updating bellow
    setSchoolsValue([])

    // Check for value
    if (value) {
      setSchoolOptions([])

      districtFilterVal = [value.schoolDistrictID]

      const districtsSchoolsIndex = Schools.findIndex(x => x.schoolDistrictID === value.schoolDistrictID)

      if (districtsSchoolsIndex !== -1) {
        const districtsSchools = Schools[districtsSchoolsIndex].schools

        // Sets the district to the selected option, and repopulates the school options to the new district
        setSchoolOptions(districtsSchools)
      } else {
        // Clear school options
        setSchoolOptions([])
      }
    } else {
      // Sets school options to all available when district is not selected
      setSchoolOptions(schoolsArray)
    }

    // Set selected district value object with ID and name
    setDistrictsValue(value)

    // Set the filter to whatever the current filter is, and add in the new key/value
    setFilter({
      ...filter,
      [districtKey]: districtFilterVal,
      [schoolKey]: []
    })

    setAllPosts({ ...filter, [districtKey]: districtFilterVal }, fireTypeSuccess)
    setLoading(false)
  }

  // ALL PEOPLE (Type / Role Select)
  const handleChangeUserRole = (value) => {
    setLoading(true)

    setUserTypeValue(value)

    const roleKey = 'roleIDs'
    let roleFilterVal = []

    if (value && value.roleName) {
      if (value.roleName === userRoles.TEACHER_FELLOW) {
        roleFilterVal = [value.roleID, userRoleIDs.ADMIN_ROLE]
      } else {
        roleFilterVal = [value.roleID]
      }
    }

    setFilter({
      ...filter,
      [roleKey]: roleFilterVal
    })

    setAllPosts({ ...filter, [roleKey]: roleFilterVal }, fireTypeSuccess)

    setLoading(false)
  }

  // Will route the user to their edit about modal inside their profile (available when no results in the subjects section are returned)
  const handleChooseModal = (modal) => {
    props.history.push({
      pathname: '/profile',
      search: `?user=${profileID}&modal=${modal}`
    })
  }

  // Used to decide the displayed section's content based on the active tab
  const getSectionContent = (type) => {
    switch (type) {
      case explorerTypes.FEATURED: {
        return (
          // ------------ Featured-Posts ----------- //
          <ExploreFeatured
            props={{
              classes,
              theme,
              xsScreenBelow,
              xsScreen,
              smScreen,
              mdScreen,
              lgScreen,
              loading,
              scroll,
              ArtifactCard,
              featuredSections,
              subjectsFeaturedRef,
              newFeaturedRef,
              likedArtifacts,
              savedArtifacts,
              socialModalOpen,
              setSocialModalOpen,
              setSocialArtifactInfo,
              setSocialType,
              handleChooseModal
            }}
          />
        )
      }
      case explorerTypes.ALL_POSTS: {
        return (
          // ------------ All-Posts ----------- //
          <ExploreAll
            props={{
              classes,
              theme,
              loading,
              ArtifactCard,
              likedArtifacts,
              savedArtifacts,
              allPostsCount,
              hasResults,
              allArtifacts,
              setSocialType,
              setSocialModalOpen,
              setSocialArtifactInfo,
              chosenType,
              subjectsValue,
              gradesValue,
              searchInput,
              filter,
              handleChooseModal
            }}
          />
        )
      }
      case 'ed-farm-resources': {
        return (
          // ------------ Ed-Farm-Resources ----------- //
          <ExploreEdFarmResources
            props={{
              classes,
              theme,
              loading,
              ArtifactCard,
              likedArtifacts,
              savedArtifacts,
              allEFR,
              allEFRCount,
              hasResults,
              setSocialType,
              setSocialModalOpen,
              setSocialArtifactInfo,
              filter,
              chosenAudienceEFR,
              chosenTypeEFR,
              searchInput,
              handleChooseModal
            }}
          />
        )
      }
      case explorerTypes.USERS: {
        return (
          // ------------ Ed-Farm-Resources ----------- //
          <ExploreUsers
            props={{
              classes,
              theme,
              loading,
              hasResults,
              allUsers,
              allUsersCount
            }}
          />
        )
      }
      default: {
        return (
          <Grid item container direction='row' style={{ marginBottom: '5em', marginTop: '3em' }}>
            <Grid item container justifyContent='center' alignItems='center' style={{ marginTop: '2em' }}>
              <Typography variant='body1' style={{ color: theme.palette.grey.medium }}>Something Went Wrong.</Typography>
            </Grid>
          </Grid>
        )
      }
    }
  }

  return (
    <>
      {/* Filters and Search */}
      <Grid container item direction='column'>
        {/* Tabs */}
        <Grid item container direction='row' style={{ marginBottom: '1em' }}>
          {/* All-Posts */}
          <Grid item>
            <Button variant='text' onClick={(e) => { e.preventDefault(); dispatch(setExploreActiveTab(explorerTypes.ALL_POSTS)); handleReset() }}>
              <Typography className={activeTab === explorerTypes.ALL_POSTS ? classes.activeTab : classes.inactiveTab}>All Posts</Typography>
            </Button>
          </Grid>
          {/* Featured-Posts */}
          <Grid item>
            <Button variant='text' onClick={(e) => { e.preventDefault(); dispatch(setExploreActiveTab(explorerTypes.FEATURED)) }}>
              <Typography className={activeTab === explorerTypes.FEATURED ? classes.activeTab : classes.inactiveTab}>Featured</Typography>
            </Button>
          </Grid>
          {/* Users */}
          <Grid item>
            <Button variant='text' onClick={(e) => { e.preventDefault(); dispatch(setExploreActiveTab(explorerTypes.USERS)); handleReset() }}>
              <Typography className={activeTab === explorerTypes.USERS ? classes.activeTab : classes.inactiveTab}>All People</Typography>
            </Button>
          </Grid>
          {/* Ed-Farm-Resources */}
          <Grid item>
            <Button variant='text' onClick={(e) => { e.preventDefault(); dispatch(setExploreActiveTab('ed-farm-resources')); handleReset() }}>
              <Typography className={activeTab === 'ed-farm-resources' ? classes.activeTab : classes.inactiveTab}>Ed Farm Resources</Typography>
            </Button>
          </Grid>
        </Grid>

        {/* Sub Filters/Search Bar */}
        {activeTab && (activeTab !== explorerTypes.FEATURED) &&
          <>
            {/* Clear All (Start) */}
            <Grid item container direction='row' justifyContent='flex-end' style={{ paddingRight: '.5em' }}>
              <Button
                variant='text'
                style={{ textTransform: 'none', color: theme.palette.grey.dark, fontWeight: 600, fontSize: '14px' }}
                onClick={handleFilterReset}
              >
                Clear All
              </Button>
            </Grid>
            {/* Clear All (End) */}

            <Grid item container direction='row' style={{ marginTop: '.2em' }}>
              <Paper elevation={0} style={{ width: '100%', padding: '1.5em', border: `2px solid ${theme.palette.grey.lighter}` }}>
                {/* Filter Items */}
                <Grid item container direction='row' alignItems='center' justifyContent='space-between'>

                  {/* Type Filter (Start) */}
                  <Grid item style={{ marginRight: '1em' }}>
                    <Typography variant='caption' className={classes.filterLabel}>Type:</Typography>

                    {/* All Posts Type (Start) */}
                    {activeTab === explorerTypes.ALL_POSTS &&
                      <Select
                        displayEmpty
                        defaultValue=''
                        value={chosenType}
                        open={typeOpen}
                        onOpen={() => !typeOpen ? setTypeOpen(true) : false}
                        onClose={() => setTypeOpen(false)}
                        onChange={(e) => handleFilterChange('type', e.target.value)}
                        IconComponent={() => (
                          <ExpandMoreRounded
                            style={{ fontSize: '16px', color: theme.palette.grey.dark, cursor: 'pointer' }}
                            onClick={() => !typeOpen ? setTypeOpen(true) : false}
                          />
                        )}
                        classes={{ root: classes.filterSelect }}
                        disableUnderline
                      >
                        <MenuItem value=''>All</MenuItem>
                        <MenuItem value={esRecordType.LE}>Learning Experience Design</MenuItem>
                        <MenuItem value={esRecordType.LW}>Learner Work</MenuItem>
                        <MenuItem value={esRecordType.PR}>Project</MenuItem>
                      </Select>}
                    {/* All Posts Type (End) */}

                    {/* All People Type (Start) */}
                    {activeTab === explorerTypes.USERS &&
                      <Select
                        displayEmpty
                        defaultValue=''
                        value={userTypeValue ? userRoleOptions.find(role => role.roleID === userTypeValue.roleID) : ''}
                        open={typeUserOpen}
                        onOpen={() => !typeUserOpen ? setTypeUserOpen(true) : false}
                        onClose={() => setTypeUserOpen(false)}
                        IconComponent={() => (
                          <ExpandMoreRounded
                            style={{ fontSize: '16px', color: theme.palette.grey.dark, cursor: 'pointer' }}
                            onClick={() => !typeUserOpen ? setTypeUserOpen(true) : false}
                          />
                        )}
                        classes={{ root: classes.filterSelect }}
                        onChange={(e) => handleChangeUserRole(e.target.value)}
                        disableUnderline
                        renderValue={(selected) => !selected ? 'All' : selected.roleName}
                      >
                        <MenuItem value=''>All</MenuItem>
                        {userRoleOptions && userRoleOptions.length &&
                          userRoleOptions.map(userRole => {
                            return (
                              <MenuItem key={userRole.roleID} value={userRole}>{userRole.roleName}</MenuItem>
                            )
                          })}
                      </Select>}
                    {/* All People Type (End) */}

                    {/* Ed Farm Resources Type (Start) */}
                    {activeTab === 'ed-farm-resources' &&
                      <Select
                        displayEmpty
                        defaultValue=''
                        value={chosenTypeEFR}
                        open={typeEFROpen}
                        onOpen={() => !typeEFROpen ? setTypeEFROpen(true) : false}
                        onClose={() => setTypeEFROpen(false)}
                        onChange={(e) => handleFilterChangeEFR('resourceType', e.target.value)}
                        IconComponent={() => (
                          <ExpandMoreRounded
                            style={{ fontSize: '16px', color: theme.palette.grey.dark, cursor: 'pointer' }}
                            onClick={() => !typeEFROpen ? setTypeEFROpen(true) : false}
                          />
                        )}
                        classes={{ root: classes.filterSelect }}
                        disableUnderline
                      >
                        <MenuItem value=''>All</MenuItem>
                        <MenuItem value='edfarm_event'>Event</MenuItem>
                        <MenuItem value='edfarm_webinar'>Webinar</MenuItem>
                        <MenuItem value='edFarm_video'>Video</MenuItem>
                      </Select>}
                    {/* Ed Farm Resources Type (End) */}

                  </Grid>
                  {/* Type Filter (End) */}

                  {/* Grade Filter -> All Posts & All People (Start) */}
                  {(activeTab === explorerTypes.ALL_POSTS || activeTab === explorerTypes.USERS) &&
                    <Grid item style={{ marginRight: '1em' }}>
                      <Typography variant='caption' className={classes.filterLabel}>Grade(s):</Typography>
                      <Select
                        open={gradesOpen}
                        onOpen={() => !gradesOpen ? setGradesOpen(true) : false}
                        onClose={() => setGradesOpen(false)}
                        value={gradesValue}
                        displayEmpty
                        onChange={(e) => handleFilterChange('grades', e.target.value)}
                        IconComponent={() => (
                          <ExpandMoreRounded
                            style={{ fontSize: '16px', color: theme.palette.grey.dark, cursor: 'pointer' }}
                            onClick={() => !gradesOpen ? setGradesOpen(true) : false}
                          />
                        )}
                        classes={{ root: classes.filterSelect }}
                        disableUnderline
                        multiple
                        defaultValue=''
                        renderValue={(selected) => renderSelectsValue('grade', selected)}
                      >
                        <MenuItem value=''>All</MenuItem>
                        {grades &&
                          grades.map(grade => {
                            const { gradeID, gradeName } = grade
                            return (
                              <MenuItem key={`grade ${gradeID}`} value={grade}>{gradeName.replace('Grade', '')}</MenuItem>
                            )
                          })}
                      </Select>
                    </Grid>}
                  {/* Grade Filter -> All Posts & All People (End) */}

                  {/* Subject Filter -> All Posts & All People (Start) */}
                  {(activeTab === explorerTypes.ALL_POSTS || activeTab === explorerTypes.USERS) &&
                    <Grid item style={{ marginRight: '1em' }}>
                      <Typography variant='caption' className={classes.filterLabel}>Subject(s):</Typography>
                      <Select
                        open={subjectsOpen}
                        onOpen={() => !subjectsOpen ? setSubjectsOpen(true) : false}
                        onClose={() => setSubjectsOpen(false)}
                        value={subjectsValue}
                        displayEmpty
                        onChange={(e) => handleFilterChange('subjects', e.target.value)}
                        IconComponent={() => (
                          <ExpandMoreRounded
                            style={{ fontSize: '16px', color: theme.palette.grey.dark, cursor: 'pointer' }}
                            onClick={() => !subjectsOpen ? setSubjectsOpen(true) : false}
                          />
                        )}
                        classes={{ root: classes.filterSelect }}
                        disableUnderline
                        multiple
                        defaultValue=''
                        renderValue={(selected) => renderSelectsValue('subject', selected)}
                      >
                        <MenuItem value=''>All</MenuItem>
                        {subjects &&
                          subjects.map(subject => {
                            const { subjectID, subjectName } = subject
                            return (
                              <MenuItem key={`subject ${subjectID}`} value={subject}>{subjectName}</MenuItem>
                            )
                          })}
                      </Select>
                    </Grid>}
                  {/* Subject Filter -> All Posts & All People (End) */}

                  {/* District Filter -> All People (Start) */}
                  {activeTab === explorerTypes.USERS &&
                    <Grid item style={{ marginRight: '1em' }}>
                      <Typography variant='caption' className={classes.filterLabel}>District:</Typography>
                      <Select
                        displayEmpty
                        defaultValue=''
                        value={districtsValue}
                        open={districtSelectOpen}
                        onOpen={() => !districtSelectOpen ? setDistrictSelectOpen(true) : false}
                        onClose={() => setDistrictSelectOpen(false)}
                        onChange={(e) => handleDistrictChange(e.target.value)}
                        IconComponent={() => (
                          <ExpandMoreRounded
                            style={{ fontSize: '16px', color: theme.palette.grey.dark, cursor: 'pointer' }}
                            onClick={() => !districtSelectOpen ? setDistrictSelectOpen(true) : false}
                          />
                        )}
                        classes={{ root: classes.filterSelect }}
                        disableUnderline
                        // multiple
                        renderValue={(selected) => !selected ? 'All' : selected.districtName}
                      >
                        <MenuItem value=''>All</MenuItem>
                        {Districts &&
                          Districts.map(district => {
                            const { schoolDistrictID, districtName } = district
                            return (
                              <MenuItem key={`district ${schoolDistrictID}`} value={district}>{districtName}</MenuItem>
                            )
                          })}
                      </Select>
                    </Grid>}
                  {/* District Filter -> All People (End) */}

                  {/* School Filter -> All People (Start) */}
                  {activeTab === explorerTypes.USERS &&
                    <Grid item style={{ marginRight: '1em' }}>
                      <Typography variant='caption' className={classes.filterLabel}>School:</Typography>
                      <Select
                        open={schoolSelectOpen}
                        onOpen={() => !schoolSelectOpen ? setSchoolSelectOpen(true) : false}
                        onClose={() => setSchoolSelectOpen(false)}
                        value={schoolsValue}
                        displayEmpty
                        onChange={(e) => { e.preventDefault(); handleFilterChangeUser('schoolIDs', e.target.value) }}
                        IconComponent={() => (
                          <ExpandMoreRounded
                            style={{ fontSize: '16px', color: theme.palette.grey.dark, cursor: 'pointer' }}
                            onClick={() => !schoolSelectOpen ? setSchoolSelectOpen(true) : false}
                          />
                        )}
                        classes={{ root: classes.filterSelect }}
                        disableUnderline
                        multiple
                        defaultValue=''
                        renderValue={(selected) => renderSelectsValue('school', selected)}
                        disabled={schoolOptions && schoolOptions.length === 0}
                      >
                        <MenuItem value=''>All</MenuItem>
                        {schoolOptions &&
                          schoolOptions.map(school => {
                            const { schoolID, schoolName } = school
                            return (
                              <MenuItem key={`school ${schoolID}`} value={school}>{schoolName}</MenuItem>
                            )
                          })}
                      </Select>
                    </Grid>}
                  {/* School Filter -> All People (End) */}

                  {/* Audience Filter -> Ed Farm Resources (Start) */}
                  {activeTab === 'ed-farm-resources' &&
                    <Grid item style={{ marginRight: '1em' }}>
                      <Typography variant='caption' className={classes.filterLabel}>Audience:</Typography>
                      <Select
                        displayEmpty
                        defaultValue=''
                        value={chosenAudienceEFR}
                        open={audienceEFROpen}
                        onOpen={() => !audienceEFROpen ? setAudienceEFROpen(true) : false}
                        onClose={() => setAudienceEFROpen(false)}
                        onChange={(e) => handleFilterChangeEFR('resourceAudience', e.target.value)}
                        IconComponent={() => (
                          <ExpandMoreRounded
                            style={{ fontSize: '16px', color: theme.palette.grey.dark, cursor: 'pointer' }}
                            onClick={() => !audienceEFROpen ? setAudienceEFROpen(true) : false}
                          />
                        )}
                        classes={{ root: classes.filterSelect }}
                        disableUnderline
                      >
                        <MenuItem value=''>All</MenuItem>
                        <MenuItem value='ELEMENTARY'>Elementary</MenuItem>
                        <MenuItem value='HIGH_SCHOOL'>High School</MenuItem>
                        <MenuItem value='PRE_SCHOOL'>Pre-School</MenuItem>
                        <MenuItem value='KINDERGARTEN'>Kindergarten</MenuItem>
                        <MenuItem value='K_12'>K-12</MenuItem>
                        <MenuItem value='K_6'>K-6</MenuItem>
                      </Select>
                    </Grid>}
                  {/* Audience Filter -> Ed Farm Resources (End) */}

                  {/* Search Bar -> All Posts, All People, Ed Farm Resources (Start) */}
                  <Grid item container xs={6} justifyContent='flex-end' style={{ height: 'fit-content' }}>
                    <OutlinedInput
                      placeholder='Search...'
                      style={{
                        fontSize: '14px',
                        fontFamily: 'Source Sans Pro',
                        padding: 0
                      }}
                      fullWidth
                      inputProps={{
                        style: {
                          padding: 0
                        }
                      }}
                      value={searchInput}
                      onChange={(e) => handleSearchArtifacts(e)}
                      size='small'
                      startAdornment={
                        <SearchRounded style={{ color: theme.palette.grey.dark, padding: '0em .3em' }} />
                      }
                    />
                  </Grid>
                  {/* Search Bar -> All Posts, All People, Ed Farm Resources (End) */}

                </Grid>

              </Paper>
            </Grid>
          </>}
      </Grid>

      {/* Card Sections Area  */}
      <Grid item container direction='column' style={{ marginTop: activeTab !== 'featured' ? '3em' : '1em', marginBottom: '2em' }}>

        {getSectionContent(activeTab)}

      </Grid>
    </>
  )
}

export default withRouter(ExploreArtifactTabs)
