export const viewConfig = {
  DEFAULT: {
    conversation: '',
    message: ''
  },
  COMPOSE: {
    conversation: 'list',
    message: 'compose'
  },
  DRAFT: {
    conversation: 'drafts',
    message: 'chain'
  },
  EMPTY_DRAFT: {
    conversation: 'drafts',
    message: ''
  },
  MESSAGE: {
    conversation: 'list',
    message: 'chain'
  }
}

export const viewTypes = {
  DEFAULT: 'DEFAULT',
  COMPOSE: 'COMPOSE',
  DRAFT: 'DRAFT',
  EMPTY_DRAFT: 'EMPTY_DRAFT',
  MESSAGE: 'MESSAGE'
}
