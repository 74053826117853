import React from 'react'
import { Grid, Button, Typography } from '@material-ui/core'
import { artifactTypes } from '../../../utils'

const PublishButton = (props) => {
  const {
    isDraft, artifactNotCompleteCheck, classes, styles, setPublishModalOpen,
    theme, artifactType = '', handleSubmit = () => { }, isForMCE = false,
    handleSaveArtifact = () => {}
  } = props

  const handleButtonPublish = () => {
    if (artifactType && artifactType === artifactTypes.EFR) {
      handleSubmit()
    } else {
      if (!isForMCE) {
        setPublishModalOpen(true)
      } else {
        handleSaveArtifact(true)
      }
    }
  }
  return (
    <>
      {isDraft &&
        <Grid container item direction='row' style={{ padding: '1em', width: '100%', marginTop: '3em' }}>
          <Grid item xs={9} container direction='column'>
            <Typography variant='h5' style={{ fontWeight: '600', color: artifactNotCompleteCheck ? theme.palette.grey.dark : styles.mainColor }}>
              {artifactNotCompleteCheck ? 'Your Artifact is not complete just yet.' : 'Your Artifact is Complete!'}
            </Typography>
            <Typography variant='body1' style={{ color: artifactNotCompleteCheck ? '#9b9d9f' : theme.palette.grey.dark, fontWeight: '400' }}>
              {artifactNotCompleteCheck ? 'Complete the fields above to publish your artifact.' : `Are you ready to ${isForMCE ? 'submit' : 'publish'} it?`}
            </Typography>
          </Grid>
          <Grid item container justifyContent='flex-end' xs={3}>
            <Button
              variant='contained'
              disabled={artifactNotCompleteCheck}
              className={classes.publishArtifactButton}
              style={{
                backgroundColor: styles.mainColor
              }}
              onClick={() => handleButtonPublish()}
            >
              {isForMCE ? 'Submit' : 'Publish'}
            </Button>
          </Grid>
        </Grid>}
    </>
  )
}

export default PublishButton
